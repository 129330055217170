<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="`${size}px`"
    :height="`${size}px`" viewBox="0 0 24 24" fill="none"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :stroke="color"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
</template>

<script>
export default {
  name: 'XMarkIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: '#6E706D',
    },
  },
};
</script>

<style></style>
