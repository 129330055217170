<template>
  <label class="v-switch flex ai-c fgap1">
    <span v-if="labelPosition === 'left'">{{ value ? labels.checked : labels.unchecked }}</span>

    <div :class="[
      'switch',
      value ? 'active' : ''
    ]">
      <div class="switch-indicator"></div>
    </div>

    <input
      :disabled="disabled"
      type="checkbox"
      :name="name"
      :id="name"
      :checked="value"
      @input="onInput($event.target.checked)"
    >
    <span v-if="labelPosition === 'right'">{{ value ? labels.checked : labels.unchecked }}</span>
  </label>
</template>

<script>
export default {
  name: 'VSwitch',
  props: {
    name: {
      type: String,
      required: true,
      default: 'input',
    },
    labels: {
      type: Object,
      default: () => ({
        checked: 'Sim',
        unchecked: 'Não',
      }),
    },
    labelPosition: {
      type: String,
      default: 'right',
    },
    value: {},
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
      this.$emit('blur', e);
    },
  },
};
</script>

<style scoped>
.v-switch {
  cursor: pointer;
}

.switch {
  background-color: #e2e2e2;
  border-radius: 25px;
  height: 15px;
  width: 40px;
  position: relative;
}

.switch.active {
  background-color: #98ccfc;
}

.switch-indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  transform: translate3d(0, -2.5px, 0);
  border-radius: 50%;
  background-color: #1B84E7;
  transition: all .2s ease-in-out 0s;
}

.switch.active .switch-indicator {
  left: calc(100% - 20px);
}

input {
  display: none;
}
</style>
