<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0C5.59609 0 0 5.59609 0 12.5C0 19.4031 5.59609 25 12.5 25C19.4039 25 25 19.4031 25 12.5C25 5.59609 19.4039 0 12.5 0Z"
      fill="#104375"
    />
    <path
      d="M5.09216 17.043L9.14607 11.9461L5.09216 9.46797V17.043Z"
      fill="white"
    />
    <path
      d="M15.2344 12.3016L12.4914 13.9906L9.74375 12.3109L5.55469 17.5781H19.4273L15.2344 12.3016Z"
      fill="white"
    />
    <path
      d="M12.4906 13.175L19.9078 8.60703V7.42188H5.09216V8.65313L12.4906 13.175Z"
      fill="white"
    />
    <path
      d="M15.8304 11.9344L19.9078 17.0648V9.42343L15.8304 11.9344Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmailIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
