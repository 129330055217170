<template>
  <svg
    :width="`${size}px`"
    :height="`${size}px`"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      d="M7 13.875L1.75 8.625L0 10.375L7 17.375L22 2.375L20.25 0.625L7 13.875Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: '#6E706D',
    },
  },
};
</script>

<style></style>
