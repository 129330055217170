<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_431_752)">
      <path d="M30 0H0V30H30V0Z" fill="white" fill-opacity="0.01" />
      <path d="M5 25H25V15H5V25Z" :fill="color" />
      <path
        d="M25 25V15H5V25M25 25H5H25ZM25 25H2.5H5H25ZM25 25H27.5H25Z"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 21.25L22.5 20L20 21.25L17.5 20L15 21.25L12.5 20L10 21.25L7.5 20L5 21.25"
        stroke="white"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 15V9.375"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5.625V0"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 15V9.375"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 6.25V5"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 6.25V5"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6.25V5"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 15V25"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 15V25"
        :stroke="color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_431_752">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CakeIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: '#6E706D',
    },
  },
};
</script>

<style>
</style>
