<template>
  <div class="text-pill" :class="status.toLowerCase()">
    {{ statuses.find((stat) => stat.name === status).label }}
  </div>
</template>

<script>
export default {
  name: 'PillComponent',
  props: {
    status: {
      type: String,
      default: 'AWAITING',
      required: true,
      validator(value) {
        return ['AWAITING', 'COMPLETE', 'APPROVED', 'REJECTED', 'CREATED'].includes(value);
      },
    },
    type: {
      type: String,
      default: 'invoices',
      required: true,
      validator(value) {
        return ['invoices', 'purchase_orders'].includes(value);
      },
    },
  },
  data() {
    return {
      statuses: [
        {
          name: 'AWAITING',
          label: 'Extraindo Dados',
        },
        {
          name: 'COMPLETE',
          label: 'Pronto p/ Revisão',
        },
        {
          name: 'APPROVED',
          label: 'Aprovado',
        },
        {
          name: 'REJECTED',
          label: 'Rejeitado',
        },
        {
          name: 'CREATED',
          label: this.type === 'invoices' ? 'Invoice Criada' : 'PO Criada',
        },
      ],
    };
  },
};
</script>

<style scoped>
.text-pill {
  display: inline-block;
  white-space: nowrap;
  padding: 0.35rem 0.75rem;
  border-radius: 100px;
  font-size: 0.8rem;
}

.text-pill.awaiting {
  background-color: #DEE2E6;
  color: #6C6C6C;
}

.text-pill.approved {
  background-color: #EBFFEE;
  color: #33941F;
  font-weight: bold;
}

.text-pill.complete {
  background-color: #FFF1C1;
  color: #CA8903;
}

.text-pill.rejected {
  background-color: #FFF1F1;
  color: #C95E5E;
  font-weight: bold;
}

.text-pill.created {
  background-color: #104375;
  color: #ffffff;
  font-weight: bold;
}
</style>
