<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Conta</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Habilitada <span class="tx-danger">*</span>
            </label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="appointmentType.enabled"
                    value="true"
                    v-validate="'required'"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="appointmentType.enabled"
                    value="false"
                    v-validate="'required'"
                  />
                  <span>Não</span>
                </label>
              </div>
              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
                >Selecione uma opção</span
              >
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Operacional </label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-orange">
                <input
                  type="checkbox"
                  id="enabledToImportProcess"
                  v-model="appointmentType.enabledToImportProcess"
                  value="true"
                /><span>Processo de Importação</span>
              </label>
              <label class="ckbox ckbox-primary">
                <input
                  type="checkbox"
                  id="enabledToExportProcess"
                  v-model="appointmentType.enabledToExportProcess"
                  value="true"
                /><span>Processo de Exportação</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Financeiro </label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-danger">
                <input
                  type="checkbox"
                  id="expense"
                  name="typeOfFinancial"
                  v-model="appointmentType.expense"
                  value="true"
                /><span>Saída/Despesa</span>
              </label>

              <label class="ckbox ckbox-success">
                <input
                  type="checkbox"
                  id="income"
                  name="typeOfFinancial"
                  v-model="appointmentType.income"
                  value="true"
                /><span>Entrada/Receita</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Descrição <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              placeholder="Descrição"
              name="description"
              class="form-control"
              v-model="appointmentType.description"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Digite a Descrição</span
            >
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Categoria / Plano de Contas
              <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="appointmentType.chartOfAccount"
              name="chartOfAccount"
              :on-search="getChartOfAccounts"
              label="description"
              :options="this.chartOfAccounts"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.description }}
              </template>
              <span slot="no-options"
                >Nenhuma Categoria / Plano de Contas foi encontrada!</span
              >
            </v-select>
            <span
              v-show="errors.has('chartOfAccount')"
              class="parsley-errors-list filled"
              >Selecione uma categoria</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

export default {
  name: "appointmentType-form-general",
  props: ["appointmentType", , "oldModel", "isNewRecord"],
  data() {
    return {
      chartOfAccounts: [],
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) {
            this.addModel();
          } else {
            this.updateModel();
          }
        }
      });
    },
    addModel() {
      this.manageModel();

      Axios.post(
        `${config.env.API_DEFAULT.host}/appointmentTypes/`,
        this.appointmentType
      )
        .then((response) => {
          if (response.status === 201) {
            this.appointmentType = response.data;
            this.$swal({
              title: `O serviço ${this.appointmentType.description} foi cadastrado com sucesso .`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            this.$router.push({
              name: "billing-appointmentTypes-form",
              params: { action: "update", id: response.data.id },
            });
          } else {
            this.$swal({
              title: `O serviço ${this.appointmentType.description} não foi cadastrado.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: `O serviço ${this.appointmentType.description} não foi cadastrada. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    updateModel() {
      const self = this;
      this.manageModel();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/appointmentTypes/${this.appointmentType.id}`,
        this.appointmentType
      )
        .then((response) => {
          this.appointmentType.chartOfAccount = this.oldModel.chartOfAccount;

          this.$swal(
            "Serviço de Fatura",
            `O ${response.data.description} foi atualizado com sucesso no banco de dados.`,
            "success"
          );
        })
        .catch((error) => {
          this.$swal(
            "Serviço de Fatura",
            `Não foi possível atualizar a empresa ${this.appointmentType.description} no banco de dados.`,
            "error"
          );
        });
    },
    getChartOfAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/chartOfAccounts/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          this.chartOfAccounts = response.data._embedded.chartOfAccounts;
          loading(false);
        })
        .catch((error) => {
          swal("Categorias", `Ops, houve algum erro: ${error}`, "error");
        });
    },

    manageModel() {
      this.oldModel.chartOfAccount = this.appointmentType.chartOfAccount;
      this.appointmentType.chartOfAccount =
        this.oldModel.chartOfAccount._links.self.href;
    },
  },
  mounted() {},
};
</script>
