<template>
  <div>
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Conquistas do processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i>Listagem das
          conquistas do processo.
        </p>
      </div>

      <div>
        <a href="#" @click="openModal" class="btn btn-info"
          ><i class="icon ion-plus tx-22"></i> ADICIONAR
        </a>

        <VModal
          title="Cadastrar Conquista"
          :description="`Processo de Importação ${getTypeOfProcessReferenceByProcess(
            process
          )}`"
          :height="70"
          :width="800"
          :handler="isOpenModal"
          @requestClose="closeModal"
        >
          <template #content>
            <div>
              <div class="row mb-4">
                <div class="col-md-4">
                  <DateWrapper
                    label="Data da conquista"
                    name="date"
                    v-model="form.date"
                    required
                  ></DateWrapper>
                  <div class="field-error">
                    {{
                      fieldErrors.find((err) => err.path == "date")
                        ? fieldErrors.find((err) => err.path == "date").message
                        : ""
                    }}
                  </div>
                </div>
                <div class="col-md-4">
                  <SelectWrapper
                    label="Origem"
                    name="source"
                    v-model="form.source"
                    required
                    :options="[
                      {
                        label: 'Interna',
                        value: 'INTERNA',
                      },
                      {
                        label: 'Fornecedor',
                        value: 'FORNECEDOR',
                      },
                      {
                        label: 'Cliente',
                        value: 'CLIENTE',
                      },
                    ]"
                  />
                </div>
                <div class="col-md-4">
                  <SelectWrapper
                    label="Tipo"
                    name="type"
                    v-model="form.type"
                    required
                    :options="[
                      {
                        label: 'Operacional',
                        value: 'OPERACIONAL',
                      },
                      {
                        label: 'Cobrança',
                        value: 'COBRANCA',
                      },
                      {
                        label: 'Atendimento',
                        value: 'ATENDIMENTO',
                      },
                      {
                        label: 'Financeiro',
                        value: 'FINANCEIRO',
                      },
                    ]"
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-md-4">
                  <v-input
                    name="value"
                    label="Valor"
                    v-model="form.value"
                    :moneyMask="true"
                    required
                  ></v-input>
                </div>
                <div class="col-md-4">
                  <SelectWrapper
                    label="Etapa"
                    name="step"
                    v-model="form.processStepId"
                    :options="steps"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label for="">Descrição da conquista</label>
                  <TextEditor v-model="form.description" />
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <div class="d-flex align-items-center justify-content-end">
              <button
                class="btn btn-primary"
                @click.prevent="
                  isEditConquest ? updateConquest(conquestId) : saveConquest()
                "
              >
                Salvar Conquista
              </button>
            </div>
          </template>
        </VModal>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table
        class="table display responsive nowrap dataTable no-footer dtr-inline tx-13 table-hover"
      >
        <thead>
          <tr>
            <th>Data</th>
            <th>Origem</th>
            <th>Tipo</th>
            <th>Valor</th>
            <th>Etapa</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="conquests.length === 0">
            <td colspan="6" class="text-center">
              Nenhuma conquista cadastrada.
            </td>
          </tr>
          <tr v-for="conquest in conquests" :key="conquest.id">
            <td>{{ conquest.date | formatDate }}</td>
            <td>{{ conquest.source }}</td>
            <td>{{ conquest.type }}</td>
            <td>R$ {{ conquest.value }}</td>
            <td>{{ conquest.step ? conquest.step.alias : "-" }}</td>
            <td class="text-center">
              <div class="row justify-content-center">
                <a
                  @click.prevent="editConquest(conquest.id)"
                  href="#"
                  class="text-primary mr-4"
                >
                  <h5><i class="fas fa-edit"></i></h5>
                </a>
                <a
                  @click.prevent="deleteConquest(conquest.id)"
                  href="#"
                  class="text-danger"
                >
                  <h5><i class="fas fa-trash"></i></h5>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import moment from "moment";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import DateWrapper from "@/components/DateWrapper.vue";
import TextEditor from "@/components/TextEditor.vue";
import Swal from "sweetalert2";
import ProcessService from "@/services/ProcessService.js";

export default {
  name: "OpConquests",
  props: {
    process: {
      type: Object,
    },
  },
  components: {
    VModal,
    DateWrapper,
    SelectWrapper,
    VInput,
    TextEditor,
  },
  data() {
    return {
      isEditConquest: false,
      isOpenModal: false,
      conquestId: "",
      conquests: [],
      form: {
        date: "",
        source: "",
        type: "",
        value: "",
        processStepId: "",
        description: "",
      },
      steps: [],
      fieldErrors: [],
    };
  },
  methods: {
    getError(path) {
      if (this.fieldErrors.length > 0) {
        const error = this.fieldErrors.find((err) => err.path === path);
        return error.message;
      }
      return "";
    },
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    async getConquests() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Buscando conquistas",
        description: "Carregando...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/conquests/listByProcessId/${this.process.id}`
        );

        this.conquests = response.data.data;
      } catch (e) {
        console.error(e);
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    clearForm() {
      this.isEditConquest = false;

      this.form = {
        date: "",
        source: "",
        type: "",
        value: "",
        processStepId: "",
        description: "",
      };
    },
    async saveConquest() {
      try {
        if (
          this.form.date === "" ||
          this.form.date === null ||
          this.form.date === "undefined"
        ) {
          Swal.fire(
            "Cadastro de Conquista",
            "Data da conquista não foi preenchida!",
            "error"
          );
        } else if (this.form.source === "" || this.form.source === null) {
          Swal.fire(
            "Cadastro de Conquista",
            "Origem da conquista não foi preenchida!",
            "error"
          );
        } else if (this.form.type === "" || this.form.type === null) {
          Swal.fire(
            "Cadastro de Conquista",
            "Tipo da conquista não foi preenchida!",
            "error"
          );
        } else {
          this.form.date = moment(this.form.date).format("YYYY-MM-DD");
          this.form.value = parseFloat(
            String(this.form.value).replace(".", "").replace(",", ".")
          );
          await Axios.post(
            `${config.env.API_DEFAULT.host}/conquests/saveConquestByProcessId/${this.process.id}`,
            this.form
          );

          Swal.fire(
            "Cadastro de Conquista",
            "Conquista cadastrada com sucesso!",
            "success"
          );

          this.form = {
            date: "",
            source: "",
            type: "",
            value: "",
            processStepId: "",
            description: "",
          };

          await this.getConquests();

          this.isOpenModal = false;
        }
      } catch (e) {
        Swal.fire("Cadastro de Conquista", e.response.data.message, "success");
      }
    },
    async editConquest(id) {
      try {
        this.isEditConquest = true;

        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/conquests/${id}`
        );

        const conquest = response.data.data;

        this.conquestId = conquest.id;
        this.form.date = conquest.date;
        this.form.source = conquest.source;
        this.form.type = conquest.type;
        this.form.value = conquest.value;
        this.form.processStepId = conquest.step ? conquest.step.id : null;
        this.form.description = conquest.description;

        this.isOpenModal = true;
      } catch (e) {
        Swal.fire(
          "Edição de conquista",
          "Erro ao buscar os dados da Conquista para edição!",
          "error"
        );
      }
    },
    async updateConquest(id) {
      this.form.date = moment(this.form.date).format("YYYY-MM-DD");
      this.form.value = parseFloat(
        String(this.form.value).replace(".", "").replace(",", ".")
      );

      await Axios.put(
        `${config.env.API_DEFAULT.host}/conquests/updateConquest/${id}`,
        this.form
      ).then((result) => {
        Swal.fire(
          "Cadastro de Conquista",
          "Conquista atualizada com sucesso!",
          "success"
        );

        this.getConquests();
      });

      this.isOpenModal = false;
    },
    async deleteConquest(id, closeModal) {
      Swal.fire({
        title: "Excluir Conquista?",
        html: "<p>Tem certeza que deseja excluir esta conquista do processo?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Excluir",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Excluindo a Conquista do contato",
          });

          const response = await Axios.delete(
            `${config.env.API_DEFAULT.host}/conquests/${id}`
          );

          if (response.data.data) {
            this.getConquests();
            Swal.fire(
              "Exclusão de Conquista",
              "Conquista excluída com sucesso!",
              "success"
            );
          }
        }

        this.$store.commit("setLoading", { show: false });
      });
    },
    async getSteps() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/processSteps`
        );

        response.data.data.map((step) => {
          this.steps.push({
            label: step.alias,
            value: step.id,
          });
        });
      } catch (e) {
        console.error(e);
      }
    },
    openModal() {
      this.clearForm();
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.getSteps();

    if (this.process && this.process.id != null) {
      this.getConquests();
    }
  },
};
</script>
