import Axios from 'axios';
import config from '@/config.js';

const FinancesStore = {
  namespaced: true,
  state: {
    chartOfAccountProjects: [],
    movimentTermsCATEGORY: [],
    movimentTermsCOST_CENTER: [],
    movimentTermsDOCUMENT: [],
    movimentTermsPAYMENT: [],
  },

  getters: {},

  mutations: {
    SET_CHART_OF_ACCOUNT_PROJECTS(state, payload) {
      state.chartOfAccountProjects = payload;
    },
    SET_MOVIMENT_TERMS_CATEGORY(state, payload) {
      state.movimentTermsCATEGORY = payload;
    },
    SET_MOVIMENT_TERMS_COST_CENTER(state, payload) {
      state.movimentTermsCOST_CENTER = payload;
    },
    SET_MOVIMENT_TERMS_DOCUMENT(state, payload) {
      state.movimentTermsDOCUMENT = payload;
    },
    SET_MOVIMENT_TERMS_PAYMENT(state, payload) {
      state.movimentTermsPAYMENT = payload;
    },
  },

  actions: {
    async getMovimentTerms({
      commit,
    }, type) {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByType?type=${type}`);
        if (response.data._embedded.movimentTerms) {
          const list = [];
          const { movimentTerms } = response.data._embedded;
          movimentTerms.forEach((item) => {
            item.shortname = `${item.description.substring(0, 15)}...`;
            list.push(item);
          });

          commit(`SET_MOVIMENT_TERMS_${type}`, list);

          return {
            success: true,
          };
        }
        return {
          success: false,
          error: `Não foi possível carregar os itens do termo ${type}.`,
        };
      } catch (error) {
        console.error(`error: ${error.code}`);
        return {
          success: false,
          error,
        };
      }
    },

    async getChartOfAccountProjects({
      commit,
    }) {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/chartOfAccountProjects`);
        commit('SET_CHART_OF_ACCOUNT_PROJECTS', response.data._embedded.chartOfAccountProjects);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async getSingleChartOfAccountProject({
      commit,
    }, id) {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/finances/chartOfAccountProject/${id}`);
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async setChartOfAccountProjects({
      commit,
    }, data) {
      try {
        const url = data._links ? data._links.self.href : `${config.env.API_DEFAULT.host}/chartOfAccountProjects${data.id ? `/${data.id}` : ''}`;
        let response;
        if (data.id) {
          response = await Axios.put(url, data);
        } else {
          response = await Axios.post(url, data);
        }
        return response;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async deleteSingleChartOfAccountProject({
      dispatch,
      commit,
    }, id) {
      try {
        const projectCategories = await dispatch('getSingleChartOfAccountProject', id);

        for (const item of projectCategories.chartOfAccounts) {
          await dispatch('deleteChartOfAccountCategories', item.id);
        }

        const projectCategories2 = await dispatch('getSingleChartOfAccountProject', id);

        const response = await Axios.delete(`${config.env.API_DEFAULT.host}/chartOfAccountProjects/${id}`);
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async setChartOfAccountCategories({
      commit,
    }, data) {
      try {
        const url = data._links ? data._links.self.href : `${config.env.API_DEFAULT.host}/chartOfAccounts${data.id ? `/${data.id}` : ''}`;
        let response;
        if (data.id) {
          response = await Axios.put(url, data);
        } else {
          response = await Axios.post(url, data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async deleteChartOfAccountCategories({
      commit,
    }, data) {
      try {
        const response = await Axios.delete(`${config.env.API_DEFAULT.host}/chartOfAccounts/${data}`);
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};

export default FinancesStore;
