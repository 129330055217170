<template>
<div>
    <div class="report-summary-header mg-t-0 mg-b-0">
        <div>
            <h4 class="tx-inverse mg-b-3 mg-t-20">Declaração de Importação</h4>
            <p class="mg-b-0"><i class="icon ion-information-circled mg-r-3"></i> Adições da Declaração de Importação para inclusa de Ato Concessório. </p>
        </div>
        <div> </div>
    </div>
    <hr />
    <div class="row mg-t-20">
        <div class="col-md">
            <label class="section-label-sm tx-gray-800 tx-14">Informações da DI</label>
            <p class="invoice-info-row">
                <span>Número da DI</span>
                <span>{{process.importDeclaration.registryNumber}}</span>
            </p>
            <p class="invoice-info-row">
                <span>Quem salvou?</span>
                <span>{{process.importDeclaration.modifiedBy}}</span>
            </p>
        </div>
        <div class="col-md">
            <label class="section-label-sm tx-gray-800 tx-14">&nbsp;</label>
            <p class="invoice-info-row">
                <span>Data de Registro</span>
                <span>{{process.importDeclaration.registryDate | moment("DD/MM/YYYY")}}</span>
            </p>
            <p class="invoice-info-row">
                <span>Data de Desembaraço</span>
                <span>{{process.importDeclaration.resourcefulnessDate | moment("DD/MM/YYYY")}}</span>
            </p>
        </div>
        <div class="col-md">
            <label class="section-label-sm tx-gray-800 tx-14">&nbsp;</label>
            <p class="invoice-info-row">
                <span>Origem</span>
                <span>{{process.importDeclaration.cargoCountryOriginName}}</span>
            </p>
            <p class="invoice-info-row">
                <span>Total de Adições</span>
                <span>{{process.importDeclaration.additions.length}}</span>
            </p>
        </div>
    </div>
    <hr />

    <div class="section-wrapper mg-t-20 pd-0 bd-0">
        <label class="section-title mg-b-20 mg-sm-b-0">
            Dados do Frete
        </label>

        <form @change="calculateTotal" @submit.prevent="saveDiFreigthInfo">
            <div class="row no-gutters mt-3">
                <div class="col-md-2">
                    <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                        <label class="form-control-label">Frete Collect</label>
                        <input class="form-control" type="number" name="freeteCollect" v-model="diFreightForm.freteCollect">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                        <label class="form-control-label">Frete Prepaid</label>
                        <input class="form-control" type="number" name="freetePrepaid" v-model="diFreightForm.fretePrepaid">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                        <label class="form-control-label">Frete em território nacional</label>
                        <input class="form-control" type="number" name="nationalTerritoryShipping" v-model="diFreightForm.nationalTerritoryShipping">
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                        <label class="form-control-label">Moeda</label>
                        <input class="form-control" type="number" name="coinCode" v-model="diFreightForm.coinCode">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group bd-t-1-force bd-r-0-force mg-md-t--1">
                        <label class="form-control-label">Taxa de câmbio</label>
                        <input class="form-control" type="number" name="exchangeRate" v-model="diFreightForm.exchangeRate">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group bd-t-1-force bd-r-1-force mg-md-t--1 bg-light">
                        <label class="form-control-label">Valor total em Reais</label>
                        <input class="form-control disabled" type="number" name="total" v-model="diFreightForm.total" disabled>
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-teal mt-2 ml-2">Salvar</button>
                </div>
            </div>
        </form>
    </div>

    <div class="section-wrapper mg-t-20 pd-0 bd-0">
        <label class="section-title mg-b-20 mg-sm-b-0">Adições Disponíveis
            <span class="tx-12">({{isAddictionWithLI.length}} LIs)</span>
            <span class="float-right tx-normal tx-12 bg-warning pd-5 tx-white mg-b-5">Clique duas vezes na célula <strong>Nº Ato</strong> ou <strong>Item</strong> para editar</span>
        </label>

        <div class="table-responsive">
            <table class="table table-hover mg-b-0 tx-12">
                <thead>
                    <tr>
                        <th class="tx-center">Número</th>
                        <th class="tx-center">Nº LI</th>
                        <th class="tx-center wd-100">Nº Ato Concessório</th>
                        <th class="tx-center wd-100">Item PO</th>
                        <th class="tx-center">Peso Líquido</th>
                        <th class="tx-center">NCM</th>
                        <th class="tx-center">Fabricante</th>
                        <th class="tx-center">Itens</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="addition in process.importDeclaration.additions" :key="addition.number">
                        <td class="tx-center"> {{addition.number}} </td>
                        <td class="tx-center"> {{addition.liNumber}} </td>
                        <td class="tx-center bg-gray-200 tx-bold" @dblclick="editConcessionActNumber(addition)"> {{addition.concessionActNumber}} </td>
                        <td class="tx-center bg-gray-100 tx-bold" @dblclick="editItemPO(addition)"> {{addition.itemPO}} </td>
                        <td class="tx-right"> {{addition.netWeightMerchandise | numeralFormat('0,0.00')}} </td>
                        <td class="tx-center"> {{addition.ncm}} <br /> {{addition.ncmDescription}} </td>
                        <td class="tx-left"> {{addition.provider}} </td>
                        <td class="tx-center"> {{addition.totalItems}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import config from '@/config.js';
import { mapState } from 'vuex';
import Axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'operation-integration-duasrodas-outras',
  components: {

  },
  data() {
    return {
      importDeclaration: {},
      model: null,
      initialModel: null,
      diFreightForm: {
        freteCollect: this.importDeclaration ? this.importDeclaration.freteCollect : 0,
        fretePrepaid: this.importDeclaration ? this.importDeclaration.fretePrepaid : 0,
        nationalTerritoryShipping: this.importDeclaration ? this.importDeclaration.nationalTerritoryShipping : 0,
        coinCode: this.importDeclaration ? this.importDeclaration.coinCode : 0,
        exchangeRate: this.importDeclaration ? this.importDeclaration.exchangeRate : 0,
        total: this.importDeclaration ? this.importDeclaration.totalFreight : 0,
      },
    };
  },
  methods: {
    async editConcessionActNumber(data) {
      let html = '';
      const value = data.concessionActNumber !== null ? data.concessionActNumber : '';
      html = '<label class="tx-left">Atualizando Nº do Ato Concessório:</label>';
      html += `<input id="swal-input1" class="swal2-input" value="${value}">`;

      const { value: formValues } = await Swal.fire({
        title: `Atualização da Adição Nº ${data.number}`,
        html,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Salvar',
        preConfirm: () => document.getElementById('swal-input1').value,
      });

      if (formValues) {
        Axios.patch(`${config.env.API_DEFAULT.host}/processImportDeclarationAdditions/${data.id}`, {
          concessionActNumber: formValues,
        }).then((response) => {
          data.concessionActNumber = formValues;
          this.$store.dispatch('ProcessStore/findProcessByIdentification', this.process.identification);
          Swal.fire('Atualizar Nº do Ato Concessório', 'Nº do Ato Concessório atualizado com sucesso!', 'success');
        }).catch((error) => {
          Swal.fire('Atualizar Nº do Ato Concessório', `Não foi possível atualizar o Nº do Ato Concessório: ${error}`, 'error');
        });
      }

      return false;
    },
    async editItemPO(data) {
      let html = '';
      const value = data.itemPO !== null ? data.itemPO : '';
      html = '<label class="tx-left">Atualizando Item da PO:</label>';
      html += `<input id="swal-input1" class="swal2-input" value="${value}">`;

      const { value: formValues } = await Swal.fire({
        title: `Atualização da Adição Nº ${data.number}`,
        html,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Salvar',
        preConfirm: () => document.getElementById('swal-input1').value,
      });

      if (formValues) {
        Axios.patch(`${config.env.API_DEFAULT.host}/processImportDeclarationAdditions/${data.id}`, {
          itemPO: formValues,
        }).then((response) => {
          data.itemPO = formValues;
          this.$store.dispatch('ProcessStore/findProcessByIdentification', this.process.identification);
          Swal.fire('Atualizar Item da PO', 'Item da PO atualizado com sucesso!', 'success');
        }).catch((error) => {
          Swal.fire('Atualizar Item da PO', `Não foi possível atualizar o Item da PO: ${error}`, 'error');
        });
      }

      return false;
    },

    calculateTotal() {
      this.diFreightForm.total = (
        (this.diFreightForm.freteCollect + this.diFreightForm.fretePrepaid) - this.diFreightForm.nationalTerritoryShipping
      ) * this.diFreightForm.exchangeRate;
    },
    saveDiFreigthInfo() {
      this.$store.commit('setLoading', {
        show: true,
      });
      Axios.put(`${config.env.API_DEFAULT.host}/importDeclaration/saveImportDeclaration/${this.process.importDeclaration.id}`, this.diFreightForm)
        .then((result) => {
          this.$store.commit('setLoading', {
            show: false,
          });

          Swal.fire('Sucesso!', 'Dados de frete salvos com sucesso!', 'success');
        });
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    isAddictionWithLI() {
      const li = this.process.importDeclaration !== null ? this.process.importDeclaration.additions.filter((a) => a.liNumber !== null && a.liNumber !== '0000000000') : [];
      return li;
    },
  },
  watch: {},
  created() {},
  mounted() {
    Axios.get(`${config.env.API_DEFAULT.host}/importDeclaration/getImportDeclarationById/${this.process.importDeclaration.id}`)
      .then((result) => {
        const importDeclaration = result.data.data;
        this.diFreightForm.freteCollect = importDeclaration.freteCollect;
        this.diFreightForm.fretePrepaid = importDeclaration.fretePrepaid;
        this.diFreightForm.nationalTerritoryShipping = importDeclaration.nationalTerritoryShipping;
        this.diFreightForm.coinCode = importDeclaration.coinCode;
        this.diFreightForm.exchangeRate = importDeclaration.exchangeRate;
        this.diFreightForm.total = importDeclaration.totalFreight;
      });
  },
};
</script>

<style>

</style>
