var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-mainpanel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"slim-pageheader"},[_c('ol',{staticClass:"breadcrumb slim-breadcrumb"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.mainTitle)+" ")])]),_c('h6',{staticClass:"slim-pagetitle"},[_vm._v("Listagem de "+_vm._s(_vm.modelTitle))])]),_c('div',{staticClass:"card card-table"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"report-summary-header mg-t-0 mg-b-0 mg-l-100"},[_c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3"},[_vm._v("Listagem de "+_vm._s(_vm.modelTitle))]),_c('p',{staticClass:"mg-b-0"},[_vm._v(" Foram encontrados "+_vm._s(_vm.data.length)+" registros de "),_c('b',[_vm._v(_vm._s(_vm.modelTitle))]),_vm._v(" cadastradas. ")])]),_c('div',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'operational-imp-declaration-form',
                params: { action: 'create' },
              }}},[_c('i',{staticClass:"icon ion-plus tx-22"}),_vm._v(" ADICIONAR ")])],1)])]),(_vm.listOfLIs._embedded !== undefined)?_c('v-client-table',{attrs:{"columns":_vm.columns,"data":_vm.listOfLIs._embedded.typedDIs,"options":_vm.options},scopedSlots:_vm._u([{key:"criacao",fn:function(props){return [_c('div',{staticClass:"enabled"},[_c('div',{staticClass:"tx-center tx-13"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.criacao,"DD/MM/YYYY"))+" ")])])]}},{key:"dataRegistroDI",fn:function(props){return [_c('div',{staticClass:"enabled"},[(props.row.di !== undefined)?_c('div',{staticClass:"tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.di.dataRegistroDI,"DD/MM/YYYY"))+" ")]):_vm._e()])]}},{key:"identificacaoDeclaracaoImportacao",fn:function(props){return [_c('div',{staticClass:"enabled"},[_c('div',{staticClass:"tx-center"},[_c('span',{staticClass:"badge badge-teal pd-x-10 pd-y-5 tx-14 tx-thin"},[_vm._v(" "+_vm._s(props.row.identificacaoDeclaracaoImportacao)+" ")])])])]}},{key:"numero",fn:function(props){return [_c('div',{staticClass:"enabled"},[(
                props.row.di !== null &&
                props.row.di !== undefined &&
                props.row.di.identification !== undefined
              )?_c('div',{staticClass:"tx-center"},[_c('span',{staticClass:"badge badge-primary pd-x-10 pd-y-5 tx-14 tx-thin"},[_vm._v(" "+_vm._s(props.row.di.identification)+" ")])]):_vm._e()])]}},{key:"processoImportacao",fn:function(props){return [_c('div',{staticClass:"enabled"},[(
                props.row.processoImportacao !== null &&
                props.row.processoImportacao !== undefined &&
                props.row.processoImportacao.identification !== undefined
              )?_c('div',{staticClass:"tx-center"},[_c('span',{staticClass:"badge badge-primary pd-x-10 pd-y-5 tx-14 tx-thin"},[_vm._v(" "+_vm._s(props.row.processoImportacao.identification)+" ")])]):_vm._e()])]}},{key:"importador",fn:function(props){return [_c('div',{staticClass:"enabled"},[(
                props.row.importador !== null &&
                props.row.importador !== undefined
              )?_c('div',{staticClass:"tx-left"},[_vm._v(" "+_vm._s(props.row.importador.name)+" / "+_vm._s(props.row.importador.federalID)+" ")]):_vm._e()])]}},{key:"adquirente",fn:function(props){return [_c('div',{staticClass:"enabled"},[(
                props.row.adquirente !== null &&
                props.row.adquirente !== undefined
              )?_c('div',{staticClass:"tx-left"},[_vm._v(" "+_vm._s(props.row.adquirente.name)+" / "+_vm._s(props.row.adquirente.federalID)+" ")]):_vm._e()])]}},{key:"actions",fn:function(props){return [_c('router-link',{staticClass:"btn btn-primary btn-icon rounded-circle mg-r-5",attrs:{"to":{
              name: 'operational-imp-declaration-form',
              params: { action: 'update', id: props.row.id },
            }}},[_c('div',[_c('i',{staticClass:"icon ion-edit"})])]),_c('a',{staticClass:"btn btn-danger btn-icon rounded-circle",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteModel(props.row)}}},[_c('div',[_c('i',{staticClass:"icon ion-trash-a"})])])]}}],null,false,2388750780)}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Operacional")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Licenças de Importação (LIs)")])])
}]

export { render, staticRenderFns }