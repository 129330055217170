<template>
  <div class="card card-invoice bd-0">
    <div class="card-body">
      <printable-header></printable-header>

      <br />
      <br />

      <h1 class="slim-pagetitle">
        Demonstrativo de Conta Corrente do Processo #{{ model.identification }}
        <span class="float-right tx-14 tx-normal">
          {{ new Date() | moment("DD/MM/YYYY") }}
        </span>
      </h1>

      <hr />

      <printable-operational-header :model="model" />

      <h6 class="slim-card-title tx-primary mg-t-20">
        Lançamentos Financeiros na Conta Corrente
      </h6>
      <hr />

      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th class="wd-200">Descrição</th>
              <th class="wd-120">Movimento</th>
              <th>Vencimento</th>
              <th>Valor</th>
              <th>Pagamento</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="appointment in items"
              :key="'appointment-' + appointment.id"
            >
              <template
                v-if="
                  appointment.moviment
                    .enabledMovimentCheckingAccountOfProcess === true
                "
              >
                <td class="valign-middle">
                  <a href="" class="tx-inverse tx-14 tx-medium d-block"
                    >{{ appointment.description }}
                  </a>
                  <span class="tx-11 d-block">
                    {{ appointment.moviment.company.name }}
                  </span>
                </td>

                <td class="valign-middle">
                  <template
                    v-if="appointment.typeOfMovimentation === 'PROCESS_EXPENSE'"
                  >
                    <span
                      class="square-8 bg-danger mg-r-5 rounded-circle"
                    ></span>
                    Saída
                  </template>
                  <template v-else>
                    <span
                      class="square-8 bg-success mg-r-5 rounded-circle"
                    ></span>
                    Entrada
                  </template>
                </td>
                <td class="valign-middle tx-center">
                  {{ appointment.dueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="valign-middle tx-right">
                  {{ appointment.originalValue | numeralFormat("0,0.00") }}
                </td>
                <td colspan="3" class="valign-middle">
                  <table class="wd-100p bd-t-0">
                    <template v-if="appointment.receipts.length > 0">
                      <tr
                        v-for="receipt in appointment.receipts"
                        :key="'receipt-' + receipt.id"
                      >
                        <td class="wd-100p bd-t-0--force">
                          {{ receipt.paymentDate | moment("DD/MM/YYYY") }}
                        </td>
                        <td class="wd-100p bd-t-0--force">
                          {{ receipt.paidValue | numeralFormat("0,0.00") }}
                        </td>
                      </tr>
                    </template>
                    <template v-else> </template>
                  </table>
                </td>
              </template>
            </tr>

            <tr>
              <td colspan="3" class="tx-right tx-normal">TOTAIS</td>
              <td colspan="1" class="tx-right">
                <span>{{ balance | currency }}</span>
              </td>

              <td colspan="2" class="tx-right">
                <span>{{ balanceRealized | currency }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="tx-right tx-uppercase tx-bold tx-inverse">
                Saldo do Processo
              </td>
              <td class="tx-right">
                <h4
                  class="tx-bold tx-18"
                  :class="{
                    'tx-success': balanceRealized > 0,
                    'tx-danger': balanceRealized < 0,
                  }"
                >
                  R$ {{ balanceRealized | currency }}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      <!-- table-responsive -->
    </div>
    <!-- card-body -->

    <span class="tx-12 float-right">
      Demonstrativo gerado em
      {{ new Date() | moment("DD/MM/YYYY \\à\\s H:m:s") }} por
      {{ this.$store.state.credentials.data.person.name }}.
    </span>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import { mapState } from "vuex";
import PrintableOperationalHeader from "./Printable.Operational.Import.Header.vue";
import PrintableHeader from "./Printable.Header.vue";

export default {
  props: ["model", "title", "items"],
  components: {
    "printable-header": PrintableHeader,
    "printable-operational-header": PrintableOperationalHeader,
  },
  data() {
    return {
      // model: {},
    };
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    inputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    outputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    balance() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.originalValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.originalValue;
        return value + 0;
      }, 0);
    },
    inputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    outputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    balanceRealized() {
      return this.items.reduce((value, item) => {
        const totalValue = item.receipts.reduce((value, item) => {
          if (item.paidValue !== undefined) return item.paidValue;
          return 0;
        }, 0);

        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + totalValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - totalValue;
        return value + 0;
      }, 0);
    },
  },
  watch: {
    process() {
      this.model = { ...this.process };
      this.loadData();
    },
  },
};
</script>
