<template>
  <div class="card card-invoice bd-0">
    <div class="card-body">
      <printable-header></printable-header>

      <br />
      <br />

      <h1 class="slim-pagetitle">
        Demonstrativo de Conta Corrente do Processo #{{ model.identification }}
        <span class="float-right tx-14 tx-normal">
          {{ new Date() | moment("DD/MM/YYYY") }}
        </span>
      </h1>

      <hr />

      <printable-operational-header :model="model" />

      <h6 class="slim-card-title tx-primary mg-t-20">
        Laçamentos Manuais na Contato Corrente
      </h6>
      <hr />

      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th class="tx-16 wd-40p">Descrição</th>
              <th class="tx-16 wd-15p tx-center">Vencimento</th>
              <th class="tx-16 wd-15p tx-right">Valor Previsto</th>
              <th class="tx-16 wd-15p tx-center">Pagamento</th>
              <th class="tx-16 wd-15p tx-right">Valor Realizado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <template>
                <td class="tx-14">{{ item.description }}</td>
                <td class="tx-center">
                  {{ item.estimatedValueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tx-right wd-150">
                  <template
                    v-if="item.typeOfMovimentation === 'PROCESS_INCOME'"
                  >
                    {{ item.estimatedValue | currency }}
                  </template>
                  <template v-else>
                    - {{ item.estimatedValue | currency }}
                  </template>
                </td>
                <td class="tx-center">
                  {{ item.paidValueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tx-right wd-150">
                  <template
                    v-if="item.typeOfMovimentation === 'PROCESS_INCOME'"
                  >
                    {{ item.paidValue | currency }}
                  </template>
                  <template v-else>
                    - {{ item.paidValue | currency }}
                  </template>
                </td>
              </template>
            </tr>

            <tr>
              <td colspan="2" class="tx-right tx-normal">TOTAIS</td>
              <td colspan="1" class="tx-right">
                <span>{{ balance | currency }}</span>
              </td>

              <td colspan="2" class="tx-right">
                <span>{{ balanceRealized | currency }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="tx-right tx-uppercase tx-bold tx-inverse">
                Saldo do Processo
              </td>
              <td class="tx-right">
                <h4
                  class="tx-bold tx-18"
                  :class="{
                    'tx-success': balanceRealized > 0,
                    'tx-danger': balanceRealized < 0,
                  }"
                >
                  R$ {{ balanceRealized | currency }}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      <!-- table-responsive -->
    </div>
    <!-- card-body -->

    <span class="tx-12 float-right">
      Demonstrativo gerado em
      {{ new Date() | moment("DD/MM/YYYY \\à \\s H:m:s") }} por
      {{ this.$store.state.credentials.data.person.name }}.
    </span>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import { mapState } from "vuex";
import PrintableOperationalHeader from "./Printable.Operational.Import.Header.vue";
import PrintableHeader from "./Printable.Header.vue";

export default {
  props: ["model", "title", "items"],
  components: {
    "printable-header": PrintableHeader,
    "printable-operational-header": PrintableOperationalHeader,
  },
  data() {
    return {
      // model: {},
    };
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    inputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    outputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    balance() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.estimatedValue;
        return value + 0;
      }, 0);
    },
    inputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    outputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    balanceRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.paidValue;
        return value + 0;
      }, 0);
    },
  },
  watch: {
    process() {
      this.model = { ...this.process };
      this.loadData();
    },
  },
};
</script>
