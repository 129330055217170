<template>
  <div>
    <div class="config-title">
      <h2 class="text-dark text-thin">
        Listagem de Unidades da Empresa
      </h2>

      <div>
        <button @click.prevent="modalOpen = true" class="crm-button">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor" />
            Adicionar
          </div>
        </button>
      </div>
    </div>

    <hr class="mb-4" />

    <config-table :table-config="tableConfig" :tableData="accounts" :columns="tableColumns">
      <!-- Column Enabled -->
      <template #enabled="{ item }">
        <span @click.prevent="toggleStatus(item, !item.enabled, 'enabled')" class="config-table-tag cursor-pointer"
          :class="{ success: item.enabled, danger: !item.enabled }">
          {{ item.enabled ? 'Sim' : 'Não' }}
        </span>
      </template>
      <!-- Column Enabled -->

      <!-- Column Principal -->
      <template #principal="{ item }">
        <span @click.prevent="toggleStatus(item, !item.principal, 'principal')" class="config-table-tag cursor-pointer"
          :class="{ success: !item.principal, danger: item.principal }">
          {{ item.principal ? 'Não' : 'Sim' }}
        </span>
      </template>
      <!-- Column Enabled -->

      <!-- Column Actions -->
      <template #actions="{ item }">
        <div class="actions-table">
          <a class="edit-button" href="#" @click.prevent="editAccount(item.id)">
            <config-edit-icon :size="16" />
          </a>
          <a class="remove-button" href="#" @click.prevent="destroyAccount(item.id)">
            <config-trash-icon :size="16" />
          </a>
        </div>
      </template>
    </config-table>

    <v-modal :width="1600" :handler="modalOpen"
      :title="`${modalData.id ? 'Atualizar Unidade da Empresa' : 'Adicionar Unidade da Empresa'}`" description=""
      @requestClose="closeModal">
      <template #content>
        <form @submit.prevent class="config-user-form">
          <div class="span-12">
            <h4 class="text-dark">Dados Gerais da Empresa</h4>
          </div>

          <div class="span-12">
            <label>Habilitado?</label>
            <v-switch name="enabled" :labels="{
              checked: `Sim`,
              unchecked: `Não`,
            }" v-model="modalData.enabled" />
          </div>

          <div class="span-6">
            <v-input type="text" name="federalID" label="CNPJ" v-model="modalData.federalID"
              :mask="['##.###.###/####-##']" @blur="newFetchDataFromCNPJ"></v-input>
            <div class="field-error" v-if="getError('federalID')">
              {{ getError('federalID') }}
            </div>
          </div>

          <div class="span-6">
            <v-input type="text" name="completeName" label="Razão Social" v-model="modalData.completeName"></v-input>
            <div class="field-error" v-if="getError('completeName')">
              {{ getError('completeName') }}
            </div>
          </div>

          <div class="span-6">
            <v-input type="text" name="name" label="Nome Fantasia" v-model="modalData.name"></v-input>
            <div class="field-error" v-if="getError('name')">
              {{ getError('name') }}
            </div>
          </div>

          <div class="span-6">
            <v-input type="date" name="openingDate" label="Data de Fundação" v-model="modalData.openingDate"></v-input>
            <div class="field-error" v-if="getError('openingDate')">
              {{ getError('openingDate') }}
            </div>
          </div>

          <div class="span-4">
            <label>Inscrição Estadual?</label>
            <v-switch name="enabled" :labels="{
              checked: `Sim`,
              unchecked: `Não`,
            }" v-model="modalData.stateRegistrationExempt" />
          </div>

          <div class="span-4" v-if="modalData.stateRegistrationExempt">
            <v-input type="text" name="stateRegistration" label="Inscrição Estadual"
              v-model="modalData.stateRegistration"></v-input>
          </div>

          <div :class="{
            'span-4': modalData.stateRegistrationExempt,
            'span-8': !modalData.stateRegistrationExempt
          }">
            <v-input type="text" name="municipalRegistration" label="Inscrição Municipal"
              v-model="modalData.municipalRegistration"></v-input>
            <div class="field-error" v-if="getError('municipalRegistration')">
              {{ getError('municipalRegistration') }}
            </div>
          </div>

          <div class="span-12 config-user-form">
            <div class="span-12">
              <label>Regime Fiscal</label>
            </div>

            <div class="span-4">
              <v-radio label="Lucro Presumido" radio-value="LUCRO_PRESUMIDO" name="taxRegime"
                v-model="modalData.taxRegime" />
            </div>

            <div class="span-4">
              <v-radio label="Lucro Real" radio-value="LUCRO_REAL" name="taxRegime" v-model="modalData.taxRegime" />
            </div>

            <div class="span-4">
              <v-radio label="Simples" radio-value="SIMPLES" name="taxRegime" v-model="modalData.taxRegime" />
            </div>

            <div class="span-12" v-if="getError('taxRegime')">
              <div class="field-error">
                {{ getError('taxRegime') }}
              </div>
            </div>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações de Contato / Endereço</h4>
          </div>

          <div class="span-6">
            <select-wrapper v-model="modalData.principal" :options="[
              {
                label: 'Matriz da Empresa',
                value: 'true',
              },
              {
                label: 'Unidade Filial',
                value: 'false',
              },
            ]" label="Tipo de Unidade"></select-wrapper>
          </div>

          <div class="span-6">
            <v-input type="text" name="telephone1" label="Telefone Principal" v-model="modalData.telephone1"
              :mask="['(##) ####-####', '(##) #####-####']"></v-input>
            <div class="field-error" v-if="getError('telephone1')">
              {{ getError('telephone1') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="telephone2" label="Telefone Secundário" v-model="modalData.telephone2"
              :mask="['(##) ####-####', '(##) #####-####']"></v-input>
          </div>

          <div class="span-4">
            <v-input type="email" name="email" label="E-mail" v-model="modalData.email"></v-input>
            <div class="field-error" v-if="getError('email')">
              {{ getError('email') }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="postalCode"
              label="CEP"
              v-model="modalData.postalCode"
              :mask="['#####-###']"
              @blur="findCEP"
            ></v-input>
            <div class="field-error" v-if="getError('postalCode')">
              {{ getError('postalCode') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="federalUnity" label="Estado" v-model="modalData.federalUnity"></v-input>
            <div class="field-error" v-if="getError('federalUnity')">
              {{ getError('federalUnity') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="city" label="Cidade" v-model="modalData.city"></v-input>
            <div class="field-error" v-if="getError('city')">
              {{ getError('city') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="district" label="Bairro" v-model="modalData.district"></v-input>
            <div class="field-error" v-if="getError('district')">
              {{ getError('district') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="address" label="Logradouro" v-model="modalData.address"></v-input>
            <div class="field-error" v-if="getError('address')">
              {{ getError('address') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="addressNumber" label="Número" v-model="modalData.addressNumber"></v-input>
            <div class="field-error" v-if="getError('addressNumber')">
              {{ getError('addressNumber') }}
            </div>
          </div>

          <div class="span-4">
            <v-input type="text" name="complement" label="Complemento" v-model="modalData.complement"></v-input>
          </div>

          <div class="span-12">
            <v-textarea label="Observações" name="observation" v-model="modalData.observation" />
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações Operacionais</h4>
          </div>

          <div class="span-12 config-user-form">
            <div class="span-12">
              <label>Tipo de Processo de Importação</label>
            </div>

            <div class="span-4">
              <v-checkbox label="Conta e Ordem" name="doImportProcessTypeAccountAndOrder"
                v-model="modalData.doImportProcessTypeAccountAndOrder" />
            </div>

            <div class="span-4">
              <v-checkbox label="Encomenda" name="doImportProcessTypeOrder"
                v-model="modalData.doImportProcessTypeOrder" />
            </div>

            <div class="span-4">
              <v-checkbox label="Acessoria" name="doImportProcessTypeAdvisory"
                v-model="modalData.doImportProcessTypeAdvisory" />
            </div>

            <div class="span-12" v-if="getError('doImport')">
              <div class="field-error">
                {{ getError('doImport') }}
              </div>
            </div>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações Bancárias</h4>
          </div>

          <div class="span-4">
            <select-wrapper v-model="modalData.bankAccountBank" :options="banks" label="Banco"></select-wrapper>
          </div>

          <div class="span-4">
            <v-input type="text" name="bankAccountAgency" label="Agência" v-model="modalData.bankAccountAgency"></v-input>
          </div>

          <div class="span-4">
            <v-input type="text" name="bankAccountNumber" label="Conta Corrente"
              v-model="modalData.bankAccountNumber"></v-input>
          </div>
        </form>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="closeModal">Cancelar</button>
          <button class="crm-button" v-if="modalData.id" @click.prevent="updateAccount">Salvar</button>
          <button class="crm-button" v-else @click.prevent="storeAccount">Adicionar</button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import PlusIcon from '@/components/crm/Icons/Plus.vue';
import Axios from 'axios';
import config from '@/config';
import ConfigTable from '@/components/Configuration/ConfigTable.vue';
import ConfigEditIcon from '@/components/Configuration/icons/ConfigEditIcon.vue';
import ConfigTrashIcon from '@/components/Configuration/icons/ConfigTrashIson.vue';
import Swal from 'sweetalert2';
import VModal from '@/components/VModal.vue';
import VInput from '@/components/VInput.vue';
import UtilsService from '@/services/UtilsService';
import VSwitch from '@/components/VSwitch.vue';
import VRadio from '@/components/VRadio.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import VTextarea from '@/components/VTextarea.vue';
import VCheckbox from '@/components/VCheckbox.vue';
import * as yup from 'yup';

const companySchema = yup.object().shape({
  federalID: yup.string().required('O CNPJ é obrigatório').typeError('Campo inválido'),
  completeName: yup.string().required('A Razão Social é obrigatória').typeError('Campo inválido'),
  name: yup.string().required('O Nome Fantasia é obrigatório').typeError('Campo inválido'),
  openingDate: yup.string().required('A Data de Fundação é obrigatória').typeError('Campo inválido'),
  stateRegistration: yup.string().when('stateRegistrationExempt', {
    is: true,
    then: yup.string().required('A Inscrição Municipal é obrigatória caso esteja ativada').typeError('Campo inválido'),
  }).typeError('Campo inválido'),
  municipalRegistration: yup.string().required('A Inscrição Municipal é obrigatória').typeError('Campo inválido'),
  taxRegime: yup.string().required('O Regime Fiscal é obrigatório').typeError('Campo inválido'),
  telephone1: yup.string().required('O Telefone Principal é obrigatório').typeError('Campo inválido'),
  email: yup.string().email('E-mail inválido').required('O E-mail é obrigatório').typeError('Campo inválido'),
  postalCode: yup.string().required('O CEP é obrigatório').typeError('Campo inválido'),
  federalUnity: yup.string().required('O Estado é obrigatório').typeError('Campo inválido'),
  city: yup.string().required('A Cidade é obrigatória').typeError('Campo inválido'),
  district: yup.string().required('O Bairro é obrigatório').typeError('Campo inválido'),
  address: yup.string().required('O Logradouro é obrigatório').typeError('Campo inválido'),
  addressNumber: yup.string().required('O Número é obrigatório').typeError('Campo inválido'),
  doImport: yup.string().when(['doImportProcessTypeAccountAndOrder', 'doImportProcessTypeAdvisory', 'doImportProcessTypeAdvisory'], {
    is: false,
    then: yup.string().required('Ao menos um tipo de processo deve ser selecionado'),
  }),
});

export default {
  name: 'ConfigCompanyTab',
  components: {
    VCheckbox,
    VTextarea,
    SelectWrapper,
    VRadio,
    VSwitch,
    VInput,
    VModal,
    ConfigTrashIcon,
    ConfigEditIcon,
    ConfigTable,
    PlusIcon,
  },
  data() {
    return {
      accounts: [],
      modalData: {
        federalID: '',
        completeName: '',
        name: '',
        openingDate: '',
        enabled: false,
        stateRegistrationExempt: false,
        stateRegistration: '',
        municipalRegistration: '',
        taxRegime: '',
        principal: false,
        telephone1: '',
        telephone2: '',
        email: '',
        postalCode: '',
        address: '',
        addressNumber: '',
        complement: '',
        district: '',
        city: '',
        federalUnity: '',
        observation: '',
        doImportProcessTypeAccountAndOrder: false,
        doImportProcessTypeOrder: false,
        doImportProcessTypeAdvisory: false,
        bankAccountBank: '',
        bankAccountAgency: '',
        bankAccountNumber: '',
      },
      formErrors: [],
      modalOpen: false,
      banks: [],
      tableConfig: {
        perPage: 10,
        useSearch: true,
        centeredCell: false,
        showRecords: true,
        showPerPage: true,
      },
      tableColumns: [
        {
          label: 'Habilitado',
          field: 'enabled',
        },
        {
          label: 'Filial',
          field: 'principal',
        },
        {
          label: 'Nome',
          field: 'name',
        },
        {
          label: 'CNPJ',
          field: 'federalID',
        },
        {
          label: 'Inscrição Municipal',
          field: 'municipalRegistration',
        },
        {
          label: 'Inscrição Estadual',
          field: 'stateRegistration',
        },
        {
          label: 'Telefone',
          field: 'telephone1',
        },
        {
          label: 'Cidade',
          field: 'city',
        },
        {
          label: 'UF',
          field: 'federalUnity',
        },
        {
          label: 'Ações',
          field: 'actions',
        },
      ],
    };
  },
  methods: {
    getError(path) {
      const err = this.formErrors.find((e) => e.path === path);

      if (err) {
        return err.message;
      }

      return '';
    },
    async getAccounts() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando listagem de unidades da empresa.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/accounts`);

        this.accounts = response.data._embedded.accounts;
      } catch (e) {
        Swal.fire({
          title: 'Unidades da Empresa',
          text: 'Erro ao listar unidades da empresa!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async editAccount(id) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando unidade da empresa.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/accounts/${id}`);

        this.modalData = {
          ...response.data,
          principal: response.data.principal ? 'true' : 'false',
        };
        this.modalOpen = true;
      } catch (e) {
        Swal.fire({
          title: 'Unidades da Empresa',
          text: 'Erro ao recuperar unidade da empresa!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async storeAccount() {
      this.formErrors = [];

      try {
        await companySchema.validate(this.modalData, {
          abortEarly: false,
        });

        const serializedData = this.modalData;

        if (serializedData.openingDate !== null) {
          serializedData.openingDate = this.$moment(serializedData.openingDate).format('Y-MM-DDTLTS');
        }

        await Axios.post(`${config.env.API_DEFAULT.host}/accounts`, serializedData);

        Swal.fire({
          title: 'Unidades da Empresa',
          text: 'Unidade da empresa cadastrada com sucesso!',
          type: 'success',
        });

        await this.closeModal(true);
      } catch (e) {
        if (e.name == 'ValidationError') {
          console.log(e);
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Unidades da Empresa',
            text: 'Erro ao cadastrar unidade da empresa!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async updateAccount() {
      this.formErrors = [];

      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Atualizando unidade da empresa.',
      });

      try {
        const serializedData = this.modalData;

        if (serializedData.openingDate !== null) {
          serializedData.openingDate = this.$moment(serializedData.openingDate).format('Y-MM-DDTLTS');
        }

        await Axios.patch(`${config.env.API_DEFAULT.host}/accounts/${this.modalData.id}`, serializedData);

        Swal.fire({
          title: 'Unidades da Empresa',
          text: 'Unidade da empresa atualizada com sucesso!',
          type: 'success',
        });

        await this.closeModal(true);
      } catch (e) {
        if (e.name == 'ValidationError') {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Unidades da Empresa',
            text: 'Erro ao atualizar unidade da empresa!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async destroyAccount(id) {
      try {
        const confirm = await this.$swal({
          title: 'Você tem certeza?',
          text: 'Se você excluir o time operacional, não será possível recuperá-lo para uso posterior.',
          icon: 'warning',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        });

        if (confirm) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Recuperando listagem de unidades da empresa.',
          });

          await Axios.delete(`${config.env.API_DEFAULT.host}/accounts/${id}`);

          Swal.fire({
            title: 'Unidades da Empresa',
            text: 'Unidade da empresa removida com sucesso!',
            type: 'success',
          });

          await this.getAccounts();
        }
      } catch (e) {
        Swal.fire({
          title: 'Unidades da Empresa',
          text: 'Erro ao listar unidades da empresa!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async toggleStatus(account, status, type) {
      const types = {
        enabled: 'Habilitado',
        principal: 'Filial',
      };

      try {
        const confirm = await this.$swal({
          title: `Você tem certeza que deseja alterar o status ${types[type]}?`,
          icon: 'warning',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        });

        if (confirm) {
          await Axios.patch(`${config.env.API_DEFAULT.host}/accounts/${account.id}`, {
            ...account,
            [type]: status,
          });

          await this.getAccounts();
        }
      } catch (e) {
        Swal.fire({
          title: 'Unidades da Empresa!',
          text: 'Erro ao atualizar status da unidade!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async closeModal(refresh = false) {
      this.formErrors = [];
      this.modalOpen = false;
      this.clearModalData();

      if (refresh) {
        await this.getAccounts();
      }
    },
    async getBanks() {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/banks`);

        this.banks = response.data._embedded.banks.map((item) => ({
          label: `${item.code} | ${item.name}`,
          value: item.code,
        }));
      } catch (e) {
        Swal.fire({
          title: 'Bancos',
          text: 'Não foi possível carregar os bancos!',
          type: 'error',
        });
      }
    },
    clearModalData() {
      this.formErrors = [];
      this.modalData = {
        federalID: '',
        completeName: '',
        name: '',
        openingDate: '',
        enabled: false,
        stateRegistrationExempt: false,
        stateRegistration: '',
        municipalRegistration: '',
        taxRegime: '',
        principal: false,
        telephone1: '',
        telephone2: '',
        email: '',
        postalCode: '',
        address: '',
        addressNumber: '',
        complement: '',
        district: '',
        city: '',
        federalUnity: '',
        observation: '',
        doImportProcessTypeAccountAndOrder: false,
        doImportProcessTypeOrder: false,
        doImportProcessTypeAdvisory: false,
        bankAccountBank: '',
        bankAccountAgency: '',
        bankAccountNumber: '',
      };
    },
    async findCEP() {
      if (this.modalData.postalCode.length === 9) {
        try {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Consultando CEP...',
            description: 'Recuperando dados do endereço.',
          });

          const response = await this.$viaCep.buscarCep(this.modalData.postalCode.replace('-', ''));
          this.modalData = {
            ...this.modalData,
            federalUnity: response.data.uf,
            city: response.data.localidade,
            district: response.data.bairro,
            address: response.data.logradouro,
          };
        } catch (e) {
          Swal.fire({
            title: 'Consultar CEP',
            html: '<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>',
            type: 'error',
          });
        } finally {
          this.$store.commit('setLoading', {
            show: false,
          });
        }
      }
    },
    async newFetchDataFromCNPJ() {
      if (this.modalData.federalID.length === 18) {
        try {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Consultando CNPJ...',
            description: 'Recuperando dados da empresa.',
          });

          const response = await Axios.get(`https://publica.cnpj.ws/cnpj/${this.modalData.federalID.replace(/[^\w\s]/gi, '')}`);
          this.modalData = {
            ...this.modalData,
            completeName: response.data.razao_social,
            name: response.data.estabelecimento.nome_fantasia,
            openingDate: response.data.estabelecimento.data_inicio_atividade,
            stateRegistrationExempt: response.data.estabelecimento.inscricoes_estaduais.length > 0,
            stateRegistration: response.data.estabelecimento.inscricoes_estaduais.length > 0 ? response.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual : '',
            postalCode: response.data.estabelecimento.cep,
            federalUnity: response.data.estabelecimento.estado.nome,
            city: response.data.estabelecimento.cidade.nome,
            district: response.data.estabelecimento.bairro,
            address: response.data.estabelecimento.logradouro,
            addressNumber: response.data.estabelecimento.numero,
          };
        } catch (e) {
          Swal.fire({
            title: 'Consultar CNPJ',
            html: '<p>Não foi possível encontrar este CNPJ, verifique se é válido</p>',
            type: 'error',
          });
        } finally {
          this.$store.commit('setLoading', {
            show: false,
          });
        }
      }
    },
  },
  async mounted() {
    await this.getAccounts();
    await this.getBanks();
  },
};
</script>

<style scoped>
.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.config-user-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  align-content: start;
}

.config-user-form .span-4 {
  grid-column: span 4;
}

.config-user-form .span-6 {
  grid-column: span 6;
}

.config-user-form .span-8 {
  grid-column: span 8;
}

.config-user-form .span-12 {
  grid-column: span 12;
}
</style>
