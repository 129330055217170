<template>
  <form
    id="di-form-importador"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <input
      type="hidden"
      class="form-control m-input"
      v-model="model.codigoTipoConsignatario"
      name="codigoTipoConsignatario"
    />
    <input
      type="hidden"
      class="form-control m-input"
      v-model="model.codigoTipoImportador"
      name="codigoTipoImportador"
    />

    <div class="form-layout form-layout-2">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            <i class="icon ion-home mg-r-3"></i> Informações do Importador /
            Básicas
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Informações do
            Processo de Importação, Importador e Básicas.
          </p>
        </div>
        <div>
          <div class="d-h-t-right"></div>
        </div>
      </div>

      <hr />

      <div class="row no-gutters">
        <div class="col-md-4">
          <label class="section-title mg-t-0">Processo de Importação</label>
          <div class="row no-gutters">
            <div class="col-md-6 ht-60">
              <div class="form-group bd-r-0-force mg-md-t--1">
                <label class="form-control-label">
                  Processo de Importação
                </label>
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <i class="fa fa-search op-5"></i>
                  </div>
                  <div class="input-group-solved-center">
                    <input
                      type="text"
                      class="form-control m-input wd-200"
                      v-model="model.identificacaoProcessoImportacao"
                      v-on:keyup.enter="findProcessByIdentification"
                      name="identificacaoProcessoImportacao"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 ht-60">
              <div class="form-group bd-r-0-force mg-md-t--1">
                <label class="form-control-label"> Identificação da DI </label>
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <i class="fa fa-info op-5"></i>
                  </div>
                  <div class="input-group-solved-center">
                    <input
                      type="text"
                      class="form-control m-input wd-200"
                      v-model="model.identificacaoDeclaracaoImportacao"
                      name="identificacaoDeclaracaoImportacao"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 ht-60">
              <div class="form-group bd-t-0-force bd-r-0-force">
                <label class="form-control-label"> Tipo de Declaração </label>
                <select
                  name="codigoTipoDeclaracao"
                  class="form-control tx-12"
                  v-model="model.codigoTipoDeclaracao"
                >
                  <option>Selecione</option>
                  <option value="01">01 - CONSUMO</option>
                  <option value="02">
                    02 - ADMISSAO EM ENTREPOSTO ADUANEIRO
                  </option>
                  <option value="03">
                    03 - ADMISSAO EM EIZOF - ENTREPOSTO INTERNACIONAL DA ZFM
                  </option>
                  <option value="04">
                    04 - ADMISSAO EM ENTREPOSTO INDUSTRIAL
                  </option>
                  <option value="05">05 - ADMISSAO TEMPORARIA</option>
                  <option value="06">
                    06 - ADMISSAO NA ZFM - ZONA FRANCA DE MANAUS
                  </option>
                  <option value="07">07 - ADMISSAO EM LOJA FRANCA</option>
                  <option value="08">
                    08 - ADMISSAO EM ALC - AREA DE LIVRE COMERCIO
                  </option>
                  <option value="09">
                    09 - ADMISSAO EM DAD - DEPOSITO ADUANEIRO DE DISTRIBUICAO
                  </option>
                  <option value="10">
                    10 - ADMISSAO EM DEA - DEPOSITO ESPECIAL ALFANDEGADO
                  </option>
                  <option value="11">
                    11 - ADMISSAO EM ZPE - ZONA DE PROCESSAMENTO DE EXPORTACAO
                  </option>
                  <option value="12">12 - CONSUMO E ADMISSAO TEMPORARIA</option>
                  <option value="13">
                    13 - NACIONALIZACAO DE ADMISSAO TEMPORARIA
                  </option>
                  <option value="14">
                    14 - NACIONALIZACAO DE ENTREPOSTO ADUANEIRO
                  </option>
                  <option value="15">
                    15 - SAIDA DE EIZOF - ENTREPOSTO INTERNACIONAL DA ZFM
                  </option>
                  <option value="16">
                    16 - SAIDA DE ENTREPOSTO INDUSTRIAL
                  </option>
                  <option value="17">
                    17 - NACIONALIZACAO DE DEA - DEPOSITO ESPECIAL ALFANDEGADO
                  </option>
                  <option value="18">
                    18 - NACIONALIZAÇÃO DE REGIME ADUANEIRO ESPECIAL - GERAL
                  </option>
                  <option value="19">
                    19 - INTERNACAO DA ZFM - PI (PRODUTO INDUSTRIALIZADO)
                  </option>
                  <option value="20">
                    20 - INTERNACAO DA ZFM - PE (PRODUTO ESTRANGEIRO)
                  </option>
                  <option value="21">
                    21 - INTERNACAO DE ALC - AREA DE LIVRE COMERCIO
                  </option>
                  <option value="22">
                    22 - INTERNACAO DA ZPE - ZONA DE PROCESSAMENTO DE EXPORTACAO
                  </option>
                  <option value="23">
                    23 - TRANSFERENCIA DA ZFM (PRODUTO INDUSTRIAL.) PARA ALC
                  </option>
                  <option value="24">
                    24 - TRANSFERENCIA DA ZFM (PRODUTO ESTRANGEIRO) PARA ALC
                  </option>
                  <option value="25">
                    25 - TRANSFERENCIA DA ZFM (PRODUTO INDUSTRIAL.) PARA ZPE
                  </option>
                  <option value="26">
                    26 - TRANSFERENCIA DA ZFM (PRODUTO ESTRANGEIRO) PARA ZPE
                  </option>
                  <option value="27">
                    27 - TRANSFERENCIA DA ALC (PRODUTO ESTRANGEIRO) PARA ZFM
                  </option>
                  <option value="78">
                    78 - ANTIGO COD 18 - NACIONALIZACAO DE DAD - DEPOSITO
                    ADUANEIRO DE DISTRIBUICAO
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12 tx-normal">
              <div class="form-group bd-t-0-force bd-r-0-force">
                <label class="form-control-label"> Operação FUNDAP </label>
                <div class="row">
                  <div class="col-lg-3">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        name="indicadorOperacaoFundap"
                        type="radio"
                        v-model="model.indicadorOperacaoFundap"
                        :value="'S'"
                      />
                      <span>Sim</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label class="rdiobox rdiobox-danger mg-t-0-force">
                      <input
                        name="indicadorOperacaoFundap"
                        type="radio"
                        v-model="model.indicadorOperacaoFundap"
                        :value="'N'"
                      />
                      <span>Não</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label class="section-title mg-t-0">Informações da Operação</label>
          <div class="row no-gutters">
            <div class="col-md-12 ht-60">
              <div class="form-group bd-r-0-force mg-md-t--1">
                <label class="form-control-label"> Tipo de Importação </label>
                <select
                  name="codigoTipoConsignatario"
                  class="form-control"
                  v-model="model.codigoTipoConsignatario"
                >
                  <option>Selecione</option>
                  <option value="1">1 – Importação Própria</option>
                  <option value="2">2 – Importação por Conta e Ordem</option>
                </select>
              </div>
            </div>
            <div class="col-md-12 ht-60">
              <div class="form-group bd-r-0-force bd-t-0-force bd-r-0-force">
                <label class="form-control-label"> Importador </label>
                <input
                  type="text"
                  class="form-control m-input"
                  v-model="model.nomeImportador"
                  name="nomeImportador"
                />
              </div>
            </div>
            <div class="col-md-12 ht-60">
              <div class="form-group bd-r-0-force mg-md-t--1 bd-t-0-force">
                <label class="form-control-label"> Adquirente </label>
                <input
                  type="text"
                  class="form-control m-input"
                  v-model="model.nomeAdquirente"
                  name="nomeAdquirente"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label class="section-title mg-t-0">Informações dos Despachos</label>
          <div class="row no-gutters">
            <div class="col-md-12 ht-60">
              <div class="form-group mg-md-t--1">
                <label class="form-control-label"> URF de Despacho </label>
                <v-select
                  v-model="model.codigoUrfDespacho"
                  :on-search="getCodigoURFs"
                  label="descricao"
                  :options="listOfURFs"
                  name="codigoUrfDespacho"
                >
                  <template slot="no-options">
                    URF de Despacho não encontrado</template
                  >
                </v-select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group bd-t-0-force bd-t-0-force">
                <label class="form-control-label">
                  Modalidade do Despacho
                </label>
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="1"
                  />
                  <span> 1 - Normal </span>
                </label>

                <label class="rdiobox rdiobox-purple mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="2"
                  />
                  <span> 2 - Antecipado </span>
                </label>

                <label class="rdiobox rdiobox-info mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="4"
                  />
                  <span> 4 - Antecipado e Simplificado </span>
                </label>

                <label class="rdiobox rdiobox-teal mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="5"
                  />
                  <span> 5 - Entrega Fracionada </span>
                </label>

                <label class="rdiobox rdiobox-pink mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="6"
                  />
                  <span> 6 - Antecipado c/ Entrega Fracionada </span>
                </label>

                <label class="rdiobox rdiobox-orange mg-t-0-force">
                  <input
                    name="codigoModalidadeDespacho"
                    type="radio"
                    v-model="model.codigoModalidadeDespacho"
                    :value="7"
                  />
                  <span> 7 - Sobre Águas OEA </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-6">
          <label class="section-title mg-t-20">
            DOCUMENTOS DE INSTRUÇÃO DE DESPACHO
          </label>
          <table id="tbl-documento-instrucao-despacho" class="table mg-b-0 bd">
            <thead class="thead-colored bg-teal">
              <tr>
                <th class="tx-center wd-50-p">Denominação</th>
                <th class="tx-center">Identificação</th>
                <th class="tx-center wd-100">Ações</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-if="
                  model.documentoInstrucaoDespacho !== undefined &&
                  model.documentoInstrucaoDespacho !== null &&
                  model.documentoInstrucaoDespacho.length > 0
                "
              >
                <tr-inline
                  v-for="(item, index) in model.documentoInstrucaoDespacho"
                  :key="'key-documentoInstrucaoDespacho-' + index"
                  :row="item"
                  @save="updateDocumentoInstrucaoDespacho"
                >
                  <td-inline
                    placeholder="Selecione um documento"
                    v-model="item.codigoTipoDocumentoInstrucaoDespacho"
                    type="select"
                    selectType="key[object]|value"
                    :options="codigosTipoDocumentoInstrucaoDespacho"
                  />
                  <td-inline
                    placeholder="Identificação"
                    type="string"
                    v-model="item.numeroDocumentoInstrucaoDespacho"
                  />
                  <template
                    slot="actions"
                    v-if="
                      model.numeroRegistro == null ||
                      model.numeroRegistro == undefined
                    "
                  >
                    <a
                      href="#"
                      @click.prevent="removeDocumentoInstrucaoDespacho(item)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div><i class="icon ion-trash-a"></i></div>
                    </a>
                  </template>
                </tr-inline>
              </template>

              <template
                v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
              >
                <tr-inline
                  class="new-item"
                  :isNew="true"
                  :row="newDocumentoInstrucaoDespacho"
                  @save="createDocumentoInstrucaoDespacho"
                >
                  <td-inline
                    placeholder="Selecione um documento"
                    v-model="
                      newDocumentoInstrucaoDespacho.codigoTipoDocumentoInstrucaoDespacho
                    "
                    type="select"
                    selectType="key[object]|value"
                    :options="codigosTipoDocumentoInstrucaoDespacho"
                  />
                  <td-inline
                    placeholder="Identificação"
                    type="string"
                    v-model="
                      newDocumentoInstrucaoDespacho.numeroDocumentoInstrucaoDespacho
                    "
                  />
                </tr-inline>
              </template>
            </tbody>
          </table>
        </div>

        <div class="col-md-6">
          <label class="section-title mg-t-20"> PROCESSOS VINCULADOS </label>
          <table id="tbl-processo" class="table mg-b-0 bd">
            <thead class="thead-colored bg-teal">
              <tr>
                <th class="tx-center">Tipo</th>
                <th class="tx-center">Identificação</th>
                <th class="tx-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-if="
                  model.processo !== undefined &&
                  model.processo !== null &&
                  model.processo.length > 0
                "
              >
                <tr-inline
                  v-for="item in model.processo"
                  :key="item.id"
                  :row="item"
                  @save="updateProcesso"
                >
                  <td-inline
                    placeholder="Selecione um tipo"
                    v-model="item.codigoTipoProcesso"
                    type="select"
                    selectType="key|value"
                    :options="codigosTipoProcesso"
                  />
                  <td-inline
                    placeholder="Identificação"
                    type="string"
                    v-model="item.numeroProcesso"
                  />

                  <template
                    slot="actions"
                    v-if="
                      model.numeroRegistro == null ||
                      model.numeroRegistro == undefined
                    "
                  >
                    <a
                      href="#"
                      @click.prevent="removeProcesso(item)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div><i class="icon ion-trash-a"></i></div>
                    </a>
                  </template>
                </tr-inline>
              </template>
              <template v-else>
                <tr>
                  <td class="tx-center" colspan="3">
                    Nenhum processo vinculado foi cadastrado.
                  </td>
                </tr>
              </template>

              <template
                v-if="
                  model.numeroRegistro == null ||
                  model.numeroRegistro == undefined
                "
              >
                <tr-inline
                  class="new-item"
                  :isNew="true"
                  :row="newProcesso"
                  @save="createProcesso"
                >
                  <td-inline
                    placeholder="Selecione um tipo"
                    v-model="newProcesso.codigoTipoProcesso"
                    type="select"
                    selectType="key|value"
                    :options="codigosTipoProcesso"
                  />
                  <td-inline
                    placeholder="Identificação"
                    type="string"
                    v-model="newProcesso.numeroProcesso"
                  />
                </tr-inline>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="mg-t-40" />
  </form>
</template>

<script>
import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Swal from "sweetalert2";

import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

export default {
  name: "di-form-importador",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      model: {},
      initialModel: {},
      newDocumentoInstrucaoDespacho: {
        codigoTipoDocumentoInstrucaoDespacho: {},
        numeroDocumentoInstrucaoDespacho: "",
      },
      newProcesso: {
        codigoTipoProcesso: "",
        numeroProcesso: "",
      },
      codigosTipoDocumentoInstrucaoDespacho: [
        ...importDeclarationLists.codigosTipoDocumentoInstrucaoDespacho,
      ],
      codigosTipoProcesso: [...importDeclarationLists.codigosTipoProcesso],
      codigosURFs: [],
    };
  },
  methods: {
    setProcesso() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Declaração de Importacão",
        description: "Carregando os dados do Processo de Importação...",
      });

      if (
        this.model.processoImportacao !== null &&
        this.model.processoImportacao !== undefined
      ) {
        if (
          this.model.identificacaoDeclaracaoImportacao === null ||
          this.model.identificacaoDeclaracaoImportacao === ""
        )
          this.model.identificacaoDeclaracaoImportacao =
            this.model.processoImportacao.identification;

        const process = this.model.processoImportacao;

        if (
          process.wayOfTransport !== null &&
          process.wayOfTransport !== undefined
        ) {
          switch (process.wayOfTransport) {
            case "AEREA":
              this.model.codigoViaTransporte = "04";
              break;
            case "MARITIMA":
              this.model.codigoViaTransporte = "01";
              break;
            case "FLUVIAL":
              this.model.codigoViaTransporte = "02";
              break;
            case "LACUSTRE":
              this.model.codigoViaTransporte = "03";
              break;
            case "POSTAL":
              this.model.codigoViaTransporte = "05";
              break;
            case "FERROVIARIA":
              this.model.codigoViaTransporte = "06";
              break;
            case "RODOVIARIA":
              this.model.codigoViaTransporte = "07";
              break;
            case "TUBO_CONDUTO":
              this.model.codigoViaTransporte = "08";
              break;
            case "MEIOS_PROPRIOS":
              this.model.codigoViaTransporte = "09";
              break;
            case "ENTRADA_FICTA":
              this.model.codigoViaTransporte = "10";
              break;
          }
        }

        if (process.incoterm !== null) {
          this.$set(this.model, "incoterm", process.incoterm);
        }

        if (process.placeOfLoading !== null) {
          this.$set(this.model, "nomeLocalEmbarque", process.placeOfLoading);
        }

        if (process.vehicle !== null) {
          this.$set(this.model, "nomeVeiculoViaTransporte", process.vehicle);
        }

        if (process.datesDepartureDate !== null) {
          const dataEmbarque = this.$moment(process.datesDepartureDate).format(
            "DD/MM/YYYY"
          );
          this.$set(
            this.model,
            "dataEmbarque",
            new Date(Date.parse(process.datesDepartureDate))
          );
        }

        if (process.wayOfTransport === "MARITIMA") {
          if (process.ceMercante !== null) {
            this.$set(
              this.model,
              "numeroDocumentoCargaMaster",
              process.ceMercante
            );
          }
        } else {
          // if (process.billOfLading !== null) {
          //     this.$set(this.model, 'numeroDocumentoCarga', process.billOfLading);
          // }
        }

        if (process.datesArrivalDate !== null) {
          this.$set(
            this.model,
            "dataChegada",
            new Date(Date.parse(process.datesArrivalDate))
          );
        }

        if (
          this.$store.state.credentials.data.account.accountType === "TRADING"
        ) {
          // Importador
          if (process.account !== null) {
            this.$set(this.model, "importador", process.account);
            this.$set(
              this.model,
              "nomeImportador",
              `${process.account.completeName} / ${process.account.federalID}`
            );
            // this.$set(this.model, "pagamentoBanco", process.account.bankAccountBank);
            // this.$set(this.model, "pagamentoAgencia", process.account.bankAccountAgency);
            // this.$set(this.model, "pagamentoContaCorrente", process.account.bankAccountNumber);
          }

          // Adquirente
          if (process.customer !== null) {
            this.$set(this.model, "adquirente", process.customer);
            this.$set(
              this.model,
              "nomeAdquirente",
              `${process.customer.completeName} / ${process.customer.federalID}`
            );
          }

          // Exportador
          if (process.exporter !== null) {
            this.$set(this.model, "exportador", process.exporter);
          }

          if (process.customsEnclosureURFEntrance !== null) {
            var data = {
              codigo: process.customsEnclosureURFEntrance.codeRFB,
              descricao: process.customsEnclosureURFEntrance.description,
            };

            this.$set(this.model, "codigoUrfCargaEntrada", data);
            // this.$set(this.model, "codigoUrfDespacho", data);
          }

          if (process.customsEnclosure !== null) {
            var data = {
              codigo: process.customsEnclosure.code,
              descricao: process.customsEnclosure.alias,
            };

            this.$set(this.model, "codigoRecintoAlfandegado", data);
          }

          if (
            this.model.importador !== null &&
            this.model.importador !== undefined &&
            this.model.importador.federalID === this.model.adquirente.federalID
          )
            this.$set(this.model, "codigoTipoConsignatario", 1);
          else this.$set(this.model, "codigoTipoConsignatario", 2);
        } else
          Swal.fire(
            "Erro",
            "Não implementado para despachantes aduaneiros.",
            "error"
          );

        this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);

        if (this.model.id !== null && this.model.id !== undefined) {
          this.$store.dispatch("MyIMPStore/diffObjects", {
            initialModel: this.initialModel,
            model: this.model,
            fieldName: "processoImportacao",
          });
        }
      } else {
        this.model.adquirente = "";
        this.model.nomeAdquirente = "";
        this.model.importador = "";
        this.model.exportador = "";
        this.model.nomeImportador = "";
        this.model.identificacaoDeclaracaoImportacao = null;
        this.model.codigoViaTransporte = "";
        this.model.nomeLocalEmbarque = "";
        this.model.nomeVeiculoViaTransporte = "";
        this.model.dataEmbarque = "";
        this.model.numeroDocumentoCarga = "";
        this.model.dataChegada = "";
        this.model.numeroDocumentoCarga = "";
        this.model.codigoUrfCargaEntrada = "";
        this.model.codigoUrfDespacho = "";
        this.model.codigoTipoConsignatario = "";

        this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "",
        description: "",
      });
    },
    async findProcessByIdentification() {
      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        this.model.identificacaoProcessoImportacao
      );
      if (response.success) {
        this.model.processoImportacao = response.data.data;
        this.model.identificacaoProcessoImportacao =
          this.model.identificacaoProcessoImportacao;
        this.model.identificacaoDeclaracaoImportacao =
          this.model.identificacaoProcessoImportacao;
        this.setProcesso();
      } else {
        Swal.fire(
          "Processos de Importação",
          "Não foi possível consultar os Processos de Importação.",
          "error"
        );
      }
    },
    async getCodigoURFs(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCodigoURF",
        search
      );
      if (!response)
        Swal.fire(
          "Consultar URF",
          `Não foi possível encontrar URFs cadastradas com o Código <b>${search}</b>.`,
          "error"
        );
    },
    async updateDocumentoInstrucaoDespacho(value) {
      this.submitDocumentoInstrucaoDespacho();
    },
    async createDocumentoInstrucaoDespacho(value) {
      if (
        !value.numeroDocumentoInstrucaoDespacho ||
        value.numeroDocumentoInstrucaoDespacho === ""
      ) {
        value.numeroDocumentoInstrucaoDespacho = "S/N";
      }

      this.model.documentoInstrucaoDespacho.push(value);
      this.submitDocumentoInstrucaoDespacho();
    },
    async removeDocumentoInstrucaoDespacho(value) {
      Swal.fire({
        title: "Excluir Documento de Instrução",
        html: `Você realmente deseja excluir o documento de instrução <strong>${value.numeroDocumentoInstrucaoDespacho}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.documentoInstrucaoDespacho.findIndex(
            (item) =>
              item.codigoTipoDocumentoInstrucaoDespacho ===
                value.codigoTipoDocumentoInstrucaoDespacho &&
              item.numeroDocumentoInstrucaoDespacho ===
                value.numeroDocumentoInstrucaoDespacho
          );

          if (index !== "" && index !== null) {
            this.model.documentoInstrucaoDespacho.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
            this.submitDocumentoInstrucaoDespacho();
            Swal.fire(
              "Excluir Documento de Instrução",
              ` O documento de instrução <strong>${value.numeroDocumentoInstrucaoDespacho}</strong> foi excluído com sucesso da lista de documentos de instrução.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Documento de Instrução",
              ` O documento de instrução <strong>${value.numeroDocumentoInstrucaoDespacho}</strong> não foi excluído da lista de documentos de instrução.`,
              "danger"
            );
          }
        }
      });
    },
    async submitDocumentoInstrucaoDespacho() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: {
          documentoInstrucaoDespacho: this.model.documentoInstrucaoDespacho,
        },
        newModel: this.model,
        fieldName: "documentoInstrucaoDespacho",
      });

      if (response) {
        Swal.fire(
          "Documentos de Instrução",
          "O documento de instrução foi salvo com sucesso na DI.",
          "success"
        );

        this.newDocumentoInstrucaoDespacho = {
          codigoTipoDocumentoInstrucaoDespacho: "",
          numeroDocumentoInstrucaoDespacho: "",
        };
      }
    },
    async updateProcesso(value) {
      // this.model.processo.push(value);
      this.submitProcesso();
    },
    async createProcesso(value) {
      this.model.processo.push(value);
      this.submitProcesso();
    },
    async removeProcesso(value) {
      Swal.fire({
        title: "Excluir Processo Vinculado",
        html: `Você realmente deseja excluir o Processo <strong>${value.numeroProcesso}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.processo.findIndex(
            (item) =>
              item.codigoTipoProcesso === value.codigoTipoProcesso &&
              item.numeroProcesso === value.numeroProcesso
          );
          this.model.processo.splice(index, 1);

          if (index !== "" && index !== null) {
            this.model.processo.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
            this.submitProcesso();
            Swal.fire(
              "Excluir Processo Vinculado",
              ` O Processo <strong>${value.numeroProcesso}</strong> foi excluído com sucesso da lista de processos vinculados.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Processo Vinculado",
              ` O Processo <strong>${value.numeroProcesso}</strong> não foi excluído da lista de processos vinculados.`,
              "danger"
            );
          }
        }
      });
    },

    async submitProcesso() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: {
          processo: this.model.processo,
        },
        newModel: this.model,
        fieldName: "processo",
      });

      if (response) {
        Swal.fire(
          "Declaração de Importação",
          "O processo foi salvo com sucesso na DI.",
          "success"
        );

        this.newProcesso = {
          codigoTipoProcesso: "",
          numeroProcesso: "",
        };
      }
    },

    async diffObjects(fieldName) {
      const self = this;
      if (
        this.model.identificacaoDeclaracaoImportacao !== null &&
        this.model.identificacaoDeclaracaoImportacao !== undefined
      ) {
        const response = await this.$store.dispatch(
          "MyIMPStore/findByIdentificacaoDeclaracaoImportacao",
          this.model.identificacaoDeclaracaoImportacao
        );
        if (response) {
          let thereIs = false;

          // if (response.data.code === 'ERROR' && response.data.data.id !== this.model.id) {
          if (
            (response.data.code === "ERROR" &&
              response.data.data !== null &&
              response.data.data.id !== this.model.id) ||
            (response.data.code === "ERROR" && response.data.data === null)
          ) {
            thereIs = true;
          }

          if (thereIs) {
            Swal.fire(
              "Declaração de Importação",
              response.data.message,
              "error"
            );

            this.model.processoImportacao = null;
          } else if (
            (this.model.id === null || this.model.id === undefined) &&
            this.model.identificacaoDeclaracaoImportacao !== null &&
            this.model.identificacaoDeclaracaoImportacao !== undefined
          ) {
            const response2 = await this.$store.dispatch(
              "MyIMPStore/post",
              this.model
            );
            if (response2) {
              this.initialModel = {
                ...this.model,
              };
              Swal.fire(
                "Nova Declaração de Importacão",
                `A Declaração de Importacão ${this.model.identificacaoDeclaracaoImportacao} foi criada com sucesso.`,
                "success"
              );
              self.$router.push({
                name: "operational-imp-declaration-form",
                params: {
                  action: "update",
                  id: this.activeDI.id,
                },
              });
            }
          } else {
            const response2 = await this.$store.dispatch(
              "MyIMPStore/diffObjects",
              {
                initialModel: this.initialModel,
                model: this.model,
                fieldName,
              }
            );
            if (response2) {
              this.initialModel = {
                ...this.model,
              };
            }
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      processesByIdentification: (state) =>
        state.ProcessStore.processesByIdentification,
      optionsCompanies: (state) => state.ProcessStore.optionsCompanies,
      optionsAccounts: (state) => state.ProcessStore.optionsAccounts,
      optionsReceitaFederalUnits: (state) =>
        state.ProcessStore.optionsReceitaFederalUnits,
      optionsCustomsEnclosures: (state) =>
        state.ProcessStore.optionsCustomsEnclosures,
      listOfURFs: (state) => state.MyIMPStore.listOfURFs,
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-importador");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.initialModel = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeDI,
    };
  },
  watch: {
    activeDI() {
      this.initialModel = {
        ...this.activeDI,
      };
      this.model = {
        ...this.activeDI,
      };
    },
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
