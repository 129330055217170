<template>
  <div>
    <OriginalDocumentsFilter />

    <OriginalDocumentsTabs />

    <div class="card">
      <OriginalDocumentsTable
          :companies="companies"
          :documents="documents"
          :loading="tableLoading"
          @massiveRevisionAction="revisionModal"
          @massiveRejectionAction="rejectOriginalDocument"
          @massiveInvoiceAction="createInvoice"
          @revisionAction="revisionModal"
          @rejectionAction="rejectOriginalDocument"
          @destroyAction="destroyOriginalDocument"
          @invoiceAction="createInvoice"
          @pageChange="(page) => getDocuments(page - 1)"
          @showCreatedAction="createInvoice"
          @showRejectedAction="revisionModal"
          @revisionPoAction="revisionPoModal"
          @poAction="createPO"
          @showCreatedPoAction="createPO"
          @showRejectedPoAction="revisionPoModal"
          @massivePoAction="createPO"
      />
    </div>

    <fab-modal
        :width="2560"
        :height="100"
        title="REVISAR DOCUMENTOS"
        description=""
        :handler="modalOpen"
        @requestClose="closeModal"
        :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">Código do Documento <span class="text-bold">#{{ selectedDocument.id }}</span></p>
            <p class="sub-gray mb-0">Enviado em {{ new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm") }}</p>
          </div>
          <pill-component :status="selectedDocument.extractionStatus" :type="selectedDocument.documentType" />
        </div>
      </template>

      <template #content>
        <OriginalDocumentsValidation
            :selected-document="selectedDocument"
            :selected-documents="selectedDocuments"
            @selectDocument="(index) => selectedDocument = selectedDocuments[index]"
            @updateField="([field, value]) => updateField(field, value)"
            @checkField="([field, value]) => checkField(field, value)"
            @approveDocument="(document) => approveDocument(document)"
            @cancelRevision="(id) => cancelRevision(id)"
            @rejectDocument="(document) => rejectDocumentInModal(document)"
            @createInvoice="(document) => createInvoice(document)"
            @approveGeneralData="() => approveGeneralData()"
            @approveExporterData="() => approveExporterData()"
            @approveInvoiceItems="() => approveInvoiceItems()"
            @approveBuyerData="() => approveBuyerData()"
            @addLineItem="addLineItem"
            @removeLineItem="(index) => removeLineItem(index)"
            :read-only="readOnly"
        />
      </template>
    </fab-modal>

    <fab-modal
        :width="2560"
        :height="100"
        title="CRIAR INVOICE"
        description=""
        :handler="modalInvoiceOpen"
        @requestClose="closeInvoiceModal"
        :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">Código do Documento <span class="text-bold">#{{ selectedDocument.id }}</span></p>
            <p class="sub-gray mb-0">Enviado em {{ new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm") }}</p>
          </div>
          <pill-component :status="selectedDocument.extractionStatus" :type="selectedDocument.documentType" />
        </div>
      </template>

      <template #content>
        <OriginalDocumentsInvoice
            :selected-document="selectedDocument"
            :selected-documents="selectedDocuments"
            @updateInvoiceField="([field, value]) => updateInvoiceField(field, value)"
            @updateField="([field, value]) => updateField(field, value)"
            @selectDocument="(index) => selectedDocument = selectedDocuments[index]"
            @addLineItem="addLineItem"
            @removeLineItem="(index) => removeLineItem(index)"
            @addInstallmentItem="addInstallmentItem"
            @removeInstallmentItem="(index) => removeInstallmentItem(index)"
            @storeInvoice="(document) => storeInvoice(document)"
            @cancelInvoice="(document) => cancelInvoice(document)"
            :read-only="readOnly"
        />
      </template>
    </fab-modal>

    <fab-modal
        :width="2560"
        :height="100"
        title="CRIAR PO"
        description=""
        :handler="modalPoOpen"
        @requestClose="closePoModal"
        :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">Código do Documento <span class="text-bold">#{{ selectedDocument.id }}</span></p>
            <p class="sub-gray mb-0">Enviado em {{ new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm") }}</p>
          </div>
          <pill-component :status="selectedDocument.extractionStatus" :type="selectedDocument.documentType" />
        </div>
      </template>

      <template #content>
        <original-documents-po
            :selected-document="selectedDocument"
            :selected-documents="selectedDocuments"
            @updateInvoiceField="([field, value]) => updateInvoiceField(field, value)"
            @updateField="([field, value]) => updateField(field, value)"
            @selectDocument="(index) => selectedDocument = selectedDocuments[index]"
            @addLineItem="addLineItem"
            @removeLineItem="(index) => removeLineItem(index)"
            @addInstallmentItem="addInstallmentItem"
            @removeInstallmentItem="(index) => removeInstallmentItem(index)"
            @storePo="(document) => storePo(document)"
            @cancelPo="(document) => cancelPo(document)"
            :read-only="readOnly"
        />
      </template>
    </fab-modal>

    <fab-modal
        :width="2560"
        :height="100"
        title="CRIAR PO"
        description=""
        :handler="modalPoOpen"
        @requestClose="closePoModal"
        :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">Código do Documento <span class="text-bold">#{{ selectedDocument.id }}</span></p>
            <p class="sub-gray mb-0">Enviado em {{ new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm") }}</p>
          </div>
          <pill-component :status="selectedDocument.extractionStatus" :type="selectedDocument.documentType" />
        </div>
      </template>

      <template #content>
        <original-documents-po
            :selected-document="selectedDocument"
            :selected-documents="selectedDocuments"
            @updateInvoiceField="([field, value]) => updateInvoiceField(field, value)"
            @updateField="([field, value]) => updateField(field, value)"
            @selectDocument="(index) => selectedDocument = selectedDocuments[index]"
            @addLineItem="addLineItem"
            @removeLineItem="(index) => removeLineItem(index)"
            @addInstallmentItem="addInstallmentItem"
            @removeInstallmentItem="(index) => removeInstallmentItem(index)"
            @storePo="(document) => storePo(document)"
            @cancelPo="(document) => cancelPo(document)"
            :read-only="readOnly"
        />
      </template>
    </fab-modal>
  </div>
</template>

<script>
import OriginalDocumentsTabs from '@/components/OriginalDocuments/OriginalDocumentsTabs.vue';
import OriginalDocumentsFilter from '@/components/OriginalDocuments/OriginalDocumentsFilter.vue';
import OriginalDocumentsTable from '@/components/OriginalDocuments/OriginalDocumentsTable.vue';

// Mixins
import { originalDocumentsMixin } from '@/mixins/originalDocuments/originalDocumentsMixin';
import FabModal from '@/components/VModal.vue';
import PillComponent from '@/components/OriginalDocuments/Pill.vue';
import OriginalDocumentsValidation from '@/components/OriginalDocuments/OriginalDocumentsValidation.vue';
import OriginalDocumentsInvoice from '@/components/OriginalDocuments/OriginalDocumentsInvoice.vue';
import OriginalDocumentsPo from '@/components/OriginalDocuments/OriginalDocumentsPo.vue';

export default {
  mixins: [originalDocumentsMixin],
  name: 'RecentOriginalDocuments',
  components: {
    OriginalDocumentsInvoice,
    OriginalDocumentsValidation,
    PillComponent,
    FabModal,
    OriginalDocumentsTable,
    OriginalDocumentsFilter,
    OriginalDocumentsTabs,
    OriginalDocumentsPo,
  },
};
</script>

<style scoped>

</style>
