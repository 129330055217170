<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.375V3.75C5 3.05964 5.55964 2.5 6.25 2.5H23.75C24.4404 2.5 25 3.05964 25 3.75V26.25C25 26.9404 24.4404 27.5 23.75 27.5H6.25C5.55964 27.5 5 26.9404 5 26.25V20.625"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.375 9.375H21.25"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
    />
    <path
      d="M17.5 14.375H21.25"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
    />
    <path
      d="M17.5 19.375H21.25"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
    />
    <path
      d="M13.75 9.375H2.5V20.625H13.75V9.375Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.25 13.125L10 16.875"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 13.125L6.25 16.875"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExcelIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
