export const version = '2.1.3';
export const HOSTAPI = 'http://api.prm.gescomex.com.br:9090';
export const APIUSERNAME = 'gescomexclient';
export const APIPASSWORD = 'gescomexclient';
export const CURRENT_TENANT_ID = 'Prime Internacional Comércio Exterior';
export const CURRENT_TENANT_NAME = 'Prime Internacional Comércio Exterior';

export const PROCESS_PREFIX = 'PRI';

export const API_DEFAULT = {
  host: 'https://webservice.gscmx.prime.com.vc',
  key: 'gescomexclient',
  secret: 'gescomexclient',
};

export const WEBSOCKET = {
  host: 'wss://websocket.prime.com.vc',
  actions: {
    OPEN_PROC: 'openProc',
    CLOSE_PROC: 'closeProc',
    REQUEST_PROC: 'reqProc',
    UPDATE_PROC: 'updProc',
    ACCEPT_PROC: 'accProc',
    REJECT_PROC: 'rejProc',
  },
};

export const setup = {
  company: {
    trading: true,
    alias: 'Prime Internacional',
    name: 'Prime Internacional Comércio Exterior LTDA',
    website: 'www.prime.com.vc',
    logo: {
      src: 'https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png',
      width: '240',
    },
    pages: {
      login: {
        bgClass: 'bg-night-sky',
      },
    },
  },
};

export const HUBDODESENVOLVEDOR = {
  host: 'http://ws.hubdodesenvolvedor.com.br/v2/',
  token: '&token=16567700tVguhdkECR29912480',
};

export const API_GESCOMEX_INTEGRATOR = {
  host: 'https://integration.gscmx.primeinternacional.com.br',
  credentials: {
    user: 'primeinternacional',
    password: 'DvZ@#78&cE!N%qjx',
    token: 'Basic cHJpbWVpbnRlcm5hY2lvbmFsOkR2WkAjNzgmY0UhTiVxang=',
  },
  configs: {
    duasrodas: {
      idbr: '19995418000104',
      idcl: '84430149000109',
      environment: 'PROD',
      shkey: 'INOVA@2019',
      endpoint: 'https://egtedxprd.duasrodas.com/XISOAPAdapter/MessageServlet?senderParty=EDX_BROKER&senderService=BC_EDX_BROKER&receiverParty=MY_COMPANY&receiverService=BC_MY_COMPANY&interface=SI_EDX_SYNC&interfaceNamespace=http://e-it.net/EDX',
      piusername: 'edxegt', // Producao
      pipassword: 'D1sc4*W8Ez$K', // Producao
      pienvironment: 'egtedxprd',
      str: {
        xmlvr: '1.00',
        envrm: 'DEV',
        intnr: '5115',
      },
    },
  },
};

export const API_MYIMP = {
  host: 'https://myimp.gscmx.primeinternacional.com.br',
  credentials: {
    user: 'maicon@primeinternacional.com.br',
    password: 'MyIMP@2018',
    token: 'Basic bXlpbXAuYXBwOm15aW1wLmFwcF8hUUBXI0UkUiVUMTIzNDU=',
  },
  configs: {
    user: {},
  },
};

export const API_UTILS = {
  host: 'http://api.prm.gescomex.com.br:9990',
};

export const API_JSREPORTONLINE = {
  host: 'https://primeinternacional.jsreportonline.net/',
  credentials: {
    user: 'allysson@primeinternacional.com.br',
    password: 'vJCZWG7mZV@Vtp3',
    token: 'Basic YWxseXNzb25AcHJpbWVpbnRlcm5hY2lvbmFsLmNvbS5icjp2SkNaV0c3bVpWQFZ0cDM=',
  },
};

export const FEDERALID_INTEGRATIONS = [
  '84430149000109', '83646984000614', '83646984006906',
];
export const URL_CLI_PAGES = 'http://forms.clientes.prime.com.vc';
