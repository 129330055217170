<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Operacional</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Declarações de Importação (DIs)</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">Listagem de {{ modelTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
              <p class="mg-b-0" v-if="listOfDIs !== null">
                Foram encontrados
                {{ listOfDIs._embedded.typedDIs.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas.
              </p>
            </div>
            <div>
              <!-- <router-link :to="{ name: 'operational-imp-declaration-form', params: {'action':'create'}}" class="btn btn-primary"> <i class="icon ion-plus tx-22"></i> ADICIONAR </router-link> -->
            </div>
          </div>
        </div>

        <v-client-table
          :columns="columns"
          :data="listOfDIs._embedded.typedDIs"
          :options="options"
          v-if="listOfDIs !== null && listOfDIs._embedded !== undefined"
          class="tx-gray-800"
        >
          <template slot="criacao" slot-scope="props">
            <div class="enabled">
              <div class="tx-center tx-12">
                {{ props.row.criacao | moment("DD/MM/YY HH:mm") }}
              </div>
            </div>
          </template>

          <template slot="dataRegistro" slot-scope="props">
            <div class="enabled">
              <div class="tx-center" v-if="props.row.dataRegistro !== null">
                <span
                  class="badge badge-success pd-x-10 pd-y-5 tx-14 tx-normal"
                  >{{ props.row.dataRegistro | moment("DD/MM/YY") }}</span
                >
              </div>
            </div>
          </template>

          <template slot="dataDesembaraco" slot-scope="props">
            <div class="enabled">
              <div class="tx-center" v-if="props.row.dataDesembaraco !== null">
                <span
                  class="badge badge-success pd-x-10 pd-y-5 tx-14 tx-normal"
                  >{{ props.row.dataDesembaraco | moment("DD/MM/YY") }}</span
                >
              </div>
            </div>
          </template>

          <template slot="numeroRegistro" slot-scope="props">
            <div class="enabled">
              <div class="tx-center" v-if="props.row.numeroRegistro !== null">
                <span
                  class="badge badge-success pd-x-10 pd-y-5 tx-14 tx-normal"
                  >{{ props.row.numeroRegistro }}</span
                >
              </div>
            </div>
          </template>

          <template slot="identificacaoDeclaracaoImportacao" slot-scope="props">
            <div class="enabled">
              <div class="tx-center">
                <span class="badge badge-info pd-x-10 pd-y-5 tx-14 tx-normal">
                  {{ props.row.identificacaoDeclaracaoImportacao }}
                </span>
              </div>
            </div>
          </template>

          <template slot="processoImportacao" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-center"
                v-if="
                  props.row.processoImportacao !== null &&
                  props.row.processoImportacao !== undefined &&
                  props.row.processoImportacao.identification !== undefined
                "
              >
                <span
                  class="badge badge-primary pd-x-10 pd-y-5 tx-14 tx-normal"
                >
                  {{ props.row.processoImportacao.identification }}
                </span>
              </div>
            </div>
          </template>

          <template slot="importador" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-left"
                v-if="
                  props.row.importador !== null &&
                  props.row.importador !== undefined
                "
              >
                {{ props.row.importador.name }}
                <br />
              </div>
              <small class="tx-bold">{{
                props.row.importador.federalID
              }}</small>
            </div>
          </template>

          <template slot="adquirente" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-left"
                v-if="
                  props.row.adquirente !== null &&
                  props.row.adquirente !== undefined
                "
              >
                {{ props.row.adquirente.name }}
              </div>
              <br />
              <small class="tx-bold">{{
                props.row.adquirente.federalID
              }}</small>
            </div>
          </template>

          <template slot="adicoes" slot-scope="props">
            <div class="enabled tx-center">
              <small class="tx-bold tx-center">{{
                props.row.adicoes.length
              }}</small>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <a
              href="#"
              @click="abrir(props.row)"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div>
                <i class="fa fa-pencil"></i>
              </div>
            </a>

            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
              v-if="props.row.numeroRegistro === null"
            >
              <div>
                <i class="icon ion-trash-a"></i>
              </div>
            </a>

            <a
              href="#"
              @click="duplicar(props.row)"
              class="btn btn-success btn-icon rounded-circle"
              v-else
            >
              <div>
                <i class="fa fa-copy"></i>
              </div>
            </a>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";
import { mapState } from "vuex";

import Swal from "sweetalert2";

export default {
  name: "configuration-accounts-list",

  data() {
    return {
      modelTitle: "Declarações de Importação",
      mainTitle: "Declarações de Importação",
      columns: [
        "criacao",
        "dataRegistro",
        "numeroRegistro",
        "dataDesembaraco",
        "identificacaoDeclaracaoImportacao",
        "processoImportacao",
        "importador",
        "adquirente",
        "adicoes",
        "actions",
      ],
      data: new Array(),
      options: {
        headings: {
          criacao: "Criação",
          dataRegistro: "Registro",
          dataDesembaraco: "Desembaraço",
          numeroRegistro: "Número",
          identificacaoDeclaracaoImportacao: "Identificação",
          processoImportacao: "Processo",
          importador: "Importador",
          adquirente: "Adquirente",
          adicoes: "Adições",
          actions: "Ações",
        },
        sortable: [
          "criacao",
          "dataRegistro",
          "numeroRegistro",
          "dataDesembaraco",
          "identificacaoDeclaracaoImportacao",
          "processoImportacao",
          "importador",
          "adicoes",
          "adquirente",
        ],
        filterable: [
          "criacao",
          "dataRegistro",
          "dataDesembaraco",
          "numeroRegistro",
          "identificacaoDeclaracaoImportacao",
          "processoImportacao",
          "importador",
          "adquirente",
        ],

        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "{column}",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 100,
        perPageValues: [25, 50, 100, 500],
      },
    };
  },
  methods: {
    async loadList() {
      this.$store.commit("MyIMPStore/reset");
      this.$store.commit("MyIMPStore/SET_ACTIVE", {});
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);
      const response = await this.$store.dispatch("MyIMPStore/consultarDIs");
      // this.data = response._embedded.typedDIs;
    },
    abrir(di) {
      this.$store.commit("MyIMPStore/reset");
      this.$store.commit("MyIMPStore/SET_ACTIVE", {});
      const routeData = this.$router.resolve({
        name: "operational-imp-declaration-form",
        params: {
          action: "update",
          id: di.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    async duplicar(row) {
      const willContinue = await Swal.fire({
        title: `Duplicar DI ${row.identificacaoDeclaracaoImportacao}`,
        html: `Você realmente deseja duplicar a DI ${row.identificacaoDeclaracaoImportacao}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      if (willContinue.value) {
        const response = await this.$store.dispatch("MyIMPStore/duplicarDI", {
          id: row.id,
          identification: row.identificacaoDeclaracaoImportacao,
        });

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            `A declaração de importação ${row.identificacaoDeclaracaoImportacao} foi duplicada com sucesso.`,
            "success"
          );
          this.loadList();
        }
      }
    },
    async deleteModel(row) {
      const willContinue = await Swal.fire({
        title: "Você tem certeza?",
        text: `Se você excluir a declaração de importação  ${row.identificacaoDeclaracaoImportacao} não será possível recuperá-la para uso posterior.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (willContinue.value) {
        const response = await this.$store.dispatch(
          "MyIMPStore/delete",
          row.id
        );

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            `A Declaração de Importação ${row.identificacaoDeclaracaoImportacao} foi excluída com sucesso.`,
            "success"
          );
          this.loadList();
        }
      }
    },
  },
  computed: {
    ...mapState({
      listOfDIs: (state) => state.MyIMPStore.listOfDIs,
    }),
  },
  mounted() {
    this.loadList();
  },
};
</script>
