<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2" v-if="this.company !== null">
      <label class="section-title mg-t-0">Dados Gerais da Empresa</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-control-label"
            >CNPJ <span class="tx-danger">*</span></label
            >
            <the-mask
              mask="##.###.###/####-##"
              name="federalID"
              placeholder="CNPJ"
              v-model="company.federalID"
              class="form-control"
              v-validate="'required'"
              @blur.native="findCNPJ()"
            />
            <span
              v-show="errors.has('federalID')"
              class="parsley-errors-list filled"
            >Digite o CNPJ</span
            >
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Razão Social <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="completeName"
              placeholder="Razão Social"
              v-model="company.completeName"
              :class="{ 'parsley-error': errors.has('completeName') }"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('completeName')"
              class="parsley-errors-list filled"
            >Digite a Razão Social</span
            >
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Nome Fantasia</label>
            <input
              class="form-control"
              type="text"
              name="name"
              placeholder="Nome Fantasia"
              v-model="company.name"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Data de Fundação <span class="tx-danger">*</span></label
            >
            <date-picker lang="pt-br"
                         v-model="company.openingDate"
                         class="form-control"
                         format="DD/MM/YYYY"
                         v-validate="'required'"
            ></date-picker>
            <span
              v-show="errors.has('openingDate')"
              class="parsley-errors-list filled"
            >Digite a Data de Fundação</span
            >
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Habilitado <span class="tx-danger">*</span>
            </label>

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="company.enabled"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="company.enabled"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Não</span>
                </label>
              </div>
              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
              >Selecione uma opção</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Estadual?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="stateRegistrationExempt"
                    type="radio"
                    v-model="company.stateRegistrationExempt"
                    :value="false"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="stateRegistrationExempt"
                    type="radio"
                    v-model="company.stateRegistrationExempt"
                    :value="true"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Estadual</label>
            <input
              class="form-control"
              type="text"
              name="stateRegistration"
              placeholder="Inscrição Estadual"
              v-model="company.stateRegistration"
            />
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Municipal</label>
            <input
              class="form-control"
              type="text"
              name="municipalRegistration"
              placeholder="Inscrição Municipal"
              v-model="company.municipalRegistration"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Regime Fiscal</label>
            <div class="row pd-l-15">
              <label class="rdiobox rdiobox-warning mg-t-0-force pd-r-15">
                <input
                  id="taxRegime-LUCRO_PRESUMIDO"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="LUCRO_PRESUMIDO"
                />
                <span> Lucro Presumido </span>
              </label>
              <label class="rdiobox rdiobox-primary mg-t-0-force pd-r-15">
                <input
                  id="taxRegime-LUCRO_REAL"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="LUCRO_REAL"
                />
                <span> Lucro Real </span>
              </label>
              <label class="rdiobox rdiobox-success mg-t-0-force">
                <input
                  id="taxRegime-SIMPLES"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="SIMPLES"
                />
                <span> Simples </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20"
      >Informações de Endereço / Contato</label
      >

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Tipo de Unidade <span class="tx-danger">*</span>
            </label>

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="principal"
                    type="radio"
                    v-model="company.principal"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Matriz da Empresa</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-orange mg-t-0-force">
                  <input
                    name="principal"
                    type="radio"
                    v-model="company.principal"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Unidade Filial</span>
                </label>
              </div>
              <span
                v-show="errors.has('principal')"
                class="parsley-errors-list filled"
              >Selecione uma opção</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Telefone Principal <span class="tx-danger">*</span></label
            >
            <the-mask
              :mask="['(##) ####-####', '(##) #####-####']"
              placeholder="Telefone Principal"
              v-model="company.telephone1"
              name="telephone1"
              class="form-control"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('telephone1')"
              class="parsley-errors-list filled"
            >Digite o Telefone Principal</span
            >
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Telefone Secundário</label>
            <the-mask
              :mask="['(##) ####-####', '(##) #####-####']"
              placeholder="Telefone Secundário"
              v-model="company.telephone2"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Email</label>
            <input
              class="form-control"
              type="text"
              name="email"
              placeholder="Email"
              v-model="company.email"
              v-validate="'email'"
            />
            <span
              v-show="errors.has('email')"
              class="parsley-errors-list filled"
            >Digite um Email válido</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label"
            >CEP <span class="tx-danger">*</span></label
            >
            <the-mask
              mask="#####-###"
              placeholder="CEP"
              v-model="company.postalCode"
              name="postalCode"
              class="form-control"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('postalCode')"
              class="parsley-errors-list filled"
            >Digite o CEP</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Logradouro <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="address"
              placeholder="Logradouro"
              v-model="company.address"
              :class="{ 'parsley-error': errors.has('address') }"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('address')"
              class="parsley-errors-list filled"
            >Digite o Logradouro</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Número <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="addressNumber"
              placeholder="Número"
              v-model="company.addressNumber"
              :class="{ 'parsley-error': errors.has('address') }"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('addressNumber')"
              class="parsley-errors-list filled"
            >Digite o Número</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Complemento</label>
            <input
              class="form-control"
              type="text"
              name="complement"
              placeholder="Complemento"
              v-model="company.complement"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label"
            >Bairro <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="district"
              placeholder="Bairro"
              v-model="company.district"
              :class="{ 'parsley-error': errors.has('district') }"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('district')"
              class="parsley-errors-list filled"
            >Digite o Bairro</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Cidade <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="city"
              placeholder="Cidade"
              v-model="company.city"
              :class="{ 'parsley-error': errors.has('city') }"
              v-validate="'required'"
            />
            <span v-show="errors.has('city')" class="parsley-errors-list filled"
            >Digite a Cidade</span
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Estado <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="federalUnity"
              placeholder="Estado"
              v-model="company.federalUnity"
              :class="{ 'parsley-error': errors.has('federalUnity') }"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('federalUnity')"
              class="parsley-errors-list filled"
            >Digite o Estado</span
            >
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="observation"
              placeholder="Observações"
              v-model="company.observation"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-t-20-force">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import {TheMask} from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";

export default {
  name: "configuration-previlegies-form-general",
  props: ["company", "isNewRecord"],
  components: {
    TheMask,
    DatePicker,
  },
  data() {
    return {};
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) this.addModel();
          else this.updateModel();
        }
      });
    },
    addModel() {
      const self = this;

      self.company.openingDate = self
        .$moment(self.company.openingDate)
        .format("Y-MM-DDTLTS");

      let account = self.$store.state.credentials.data?.account?.id ?? 1;
      self.company.account = `${config.env.API_DEFAULT.host}/accounts/${account}`;

      Axios.post(`${config.env.API_DEFAULT.host}/accounts`, self.company)
        .then((response) => {
          if (response.status === 201) {
            self.company = response.data;
            self.$swal({
              title: `A Unidade ${self.company.name} foi cadastrada com sucesso.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            self.$router.push({
              name: "accounts-form",
              params: {
                type: "update",
                id: response.data.id,
              },
            });
          } else {
            self.$swal({
              title: `A Unidade ${self.company.name} não foi cadastrada.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          self.$swal({
            title: `A Unidade ${self.company.name} não foi cadastrada. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    updateModel() {
      const self = this;

      if (self.company.openingDate !== null && self.company.openingDate) {
        self.company.openingDate = self
          .$moment(self.company.openingDate)
          .format("Y-MM-DDTLTS");
      }

      Axios.patch(
        `${config.env.API_DEFAULT.host}/accounts/${self.company.id}`,
        self.company
      )
        .then((response) => {
          self.$swal(
            "Unidades da Empresa",
            `A empresa ${self.company.name} foi atualizado com sucesso no banco de dados.`,
            "success"
          );
          self.company.customBrokerModel = self.oldModel.customBrokerModel;
          self.company.cargoStorage = self.oldModel.cargoStorage;
        })
        .catch((error) => {
          self.$swal(
            "Unidades da Empresa",
            `Não foi possível atualizar a empresa ${self.company.name} no banco de dados.`,
            "error"
          );
        });
    },
    findCNPJ() {
      const self = this;

      UtilsService.findCNPJ(self.company.federalID).then((response) => {
        if (response.data.status === true) {
          self.company.completeName = response.data.result.nome;
          self.company.name = response.data.result.fantasia;
          self.company.openingDate = UtilsService.convertPTStringToDate(
            `${response.data.result.abertura} 00:00:00.0`
          );
          self.company.email = response.data.result.email;
          self.company.telephone1 = response.data.result.telefone;
          self.company.postalCode = response.data.result.cep;
          self.company.address = response.data.result.logradouro;
          self.company.addressNumber = response.data.result.numero;
          self.company.complement = response.data.result.complemento;
          self.company.district = response.data.result.bairro;
          self.company.city = response.data.result.municipio;
          self.company.federalUnity = response.data.result.uf;
        } else {
          self.$swal(
            "Empresas",
            `O CNPJ ${self.company.federalID} não foi encontrado na busca automática.`,
            "error"
          );
        }
      });

      Axios.defaults.headers.common.Authorization = `Bearer ${self.$store.state.credentials.accessToken}`;
    },
  },
  mounted() {
  },
};
</script>
