<template>
  <transition name="modal">
    <div class="modal-mask">
      <!-- <Loading ref="loading" label="Baixar Parcela" description="Processando sua requisação."></Loading> -->
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="tx-xs-20 tx-inverse mg-b-5">
              EDITAR PARCELAR DO LANÇAMENTO
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="$emit('close')"
            >
              <span aria-hidden="true"> X </span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-layout form-layout-2">
              <div class="row no-gutters">
                <div class="col-md-12 pd-t-10">
                  <div class="form-group bd-t-0-force">
                    <h4
                      class="slim-card-title tx-primary"
                      v-if="model !== undefined"
                    >
                      #{{ model.index }} - {{ model.description }}
                    </h4>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label"
                    >Nº Parcela <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      placeholder="Nº Parcela"
                      name="index"
                      class="form-control tx-bold"
                      v-model="model.index"
                      v-validate="'required'"
                      maxlength="3"
                    />
                    <span
                      v-show="errors.has('index')"
                      class="parsley-errors-list filled"
                    >Digite uma Descrição</span
                    >
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label"
                    >Vencimento <span class="tx-danger">*</span></label
                    >
                    <date-picker lang="pt-br"
                                 v-model="model.dueDate"
                                 name="dueDate"
                                 class="form-control tx-bold"
                                 format="DD/MM/YYYY"
                                 v-validate="'required'"
                    ></date-picker>
                    <span
                      v-show="errors.has('dueDate')"
                      class="parsley-errors-list filled"
                    >Digite a Data de Vencimento.</span
                    >
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label"
                    >Descrição <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      placeholder="Descrição"
                      name="description"
                      class="form-control tx-bold"
                      v-model="model.description"
                      v-validate="'required'"
                    />
                    <span
                      v-show="errors.has('description')"
                      class="parsley-errors-list filled"
                    >Digite uma Descrição</span
                    >
                  </div>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label"
                    >Valor Original <span class="tx-danger">*</span></label
                    >
                    <money
                      v-model="model.originalValue"
                      v-bind="money"
                      class="form-control currency"
                      name="originalValue"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('originalValue')"
                      class="parsley-errors-list filled"
                    >Digite o Valor Original.</span
                    >
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label"
                    >Valor de Desconto <span class="tx-danger">*</span></label
                    >
                    <money
                      v-model="model.discountValue"
                      v-bind="money"
                      class="form-control currency tx-bold"
                      name="discountValue"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('origindiscountValuealValue')"
                      class="parsley-errors-list filled"
                    >Digite o Valor de Desconto.</span
                    >
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">Valor de Multa</label>
                    <money
                      v-model="model.fineValue"
                      v-bind="money"
                      class="form-control currency tx-bold"
                      name="fineValue"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('fineValue')"
                      class="parsley-errors-list filled"
                    >Digite o Valor de Multa.</span
                    >
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label"
                    >Outros Adicionais <span class="tx-danger">*</span></label
                    >
                    <money
                      v-model="model.additionsValue"
                      v-bind="money"
                      class="form-control currency tx-bold"
                      name="additionsValue"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('additionsValue')"
                      class="parsley-errors-list filled"
                    >Digite o Outros Adicionais.</span
                    >
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">
                      Alíquota IR <span class="tx-danger">*</span></label
                    >
                    <money
                      v-model="model.irAliquot"
                      v-bind="percentual"
                      class="form-control currency tx-bold"
                      name="irAliquot"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('irAliquot')"
                      class="parsley-errors-list filled"
                    >
                      Digite a Alíquota IR
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">
                      Alíquota PCC <span class="tx-danger">*</span></label
                    >
                    <money
                      v-model="model.pccAliquot"
                      v-bind="percentual"
                      class="form-control currency tx-bold"
                      name="pccAliquot"
                      v-validate="'required'"
                    ></money>
                    <span
                      v-show="errors.has('pccAliquot')"
                      class="parsley-errors-list filled"
                    >
                      Digite a Alíquota PCC
                    </span>
                  </div>
                </div>
              </div>

              <div class="row no-gutters" v-if="!isAdministrativeMoviment">
                <div
                  class="col-lg-4"
                  v-if="model.moviment.typeOfProcess === 'IMPORT'"
                >
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">
                      Processo de Importação
                    </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <template v-if="selectedProcessExists !== null">
                          <i
                            class="fa fa-check tx-success"
                            v-if="selectedProcessExists"
                          ></i>
                          <i class="fa fa-warning tx-danger" v-else></i>
                        </template>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          type="text"
                          placeholder="Referência "
                          name="process"
                          class="form-control tx-bold"
                          v-model="selectedProcess"
                          v-validate="'required'"
                          maxlength="6"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <a href="#" v-on:click.stop.prevent="getImportProcess">
                          BUSCAR
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4"
                  v-else-if="model.moviment.typeOfProcess === 'EXPORT'"
                >
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">
                      Processo de Exportação
                    </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <template v-if="selectedProcessExists !== null">
                          <i
                            class="fa fa-check tx-success"
                            v-if="selectedProcessExists"
                          ></i>
                          <i class="fa fa-warning tx-danger" v-else></i>
                        </template>
                      </div>
                      <div class="input-group-solved-center">
                        <input
                          type="text"
                          placeholder="Referência "
                          name="process"
                          class="form-control tx-bold"
                          v-model="selectedProcess"
                          v-validate="'required'"
                          maxlength="6"
                        />
                      </div>
                      <div class="input-group-solved-after">
                        <a href="#" v-on:click.stop.prevent="getExportProcess">
                          BUSCAR
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-8">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <div class="row">
                      <div class="col">
                        <h6 class="slim-card-title tx-success">Créditos</h6>
                        <h4 class="">R$ 0,00</h4>
                      </div>
                      <!-- col -->
                      <div class="col">
                        <h6 class="slim-card-title tx-danger">Débitos</h6>
                        <h4 class="">R$ 0,00</h4>
                      </div>
                      <!-- col -->
                      <div class="col">
                        <h6 class="slim-card-title tx-primary">SALDO</h6>
                        <h4 class="">R$ 0,00</h4>
                      </div>
                      <!-- col -->
                    </div>
                    <!-- row -->
                  </div>
                </div>
              </div>

              <div class="form-layout-footer bd pd-y-40 pd-x-40 bd-t-0">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click.prevent="submit()"
                    >
                      <i class="fa fa-edit mg-r-5"></i> SALVAR
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-danger btn-block"
                      @click.prevent="$emit('close')"
                    >
                      <i class="fa fa-reply mg-r-5"></i> VOLTAR
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Axios from "axios";
import {EventBus} from "@/events/EventBus.js";
import {Money} from "v-money";
import DatePicker from "vue2-datepicker";
import config from "@/config.js";
import Appointment from "@/models/Appointment";

export default {
  name: "moviments-appointments-modal",
  props: ["model", "isForm", "finish", "isAdministrativeMoviment"],
  components: {"date-picker": DatePicker, Money},
  watch: {},

  data() {
    return {
      modelTitle: "Baixar Parcela",
      oldModel: new Appointment(),
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      percentual: {
        decimal: ",",
        thousands: ".",
        prefix: "% ",
        precision: 2,
        masked: false,
      },
      receiptAppoitmentDescription: "",
      bankAccounts: [],
      typeOfPayments: [],
      selectedProcess: {},
      selectedProcessExists: null,
    };
  },

  methods: {
    isNewRecord() {
      if (this.model.id !== undefined && this.model.id !== null) return false;
      return true;
    },
    submit() {
      this.$validator.validateAll().then((response) => {
        if (response) this.save();
        else
          this.$swal(
            this.modelTitle,
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
      });
    },
    save() {
      this.beforeSave();

      if (this.isNewRecord()) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Baixar Parcela",
          description: "Processando sua requisição.",
        });
        Axios.post(`${config.env.API_DEFAULT.host}/appointments`, this.model)
          .then((response) => {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.$swal({
              title: this.modelTitle,
              text: `${response.data.description} foi adicionada com sucesso.`,
              icon: "success",
            });
            EventBus.$emit(
              "Finance.Moviments.Appointments.Form.General.loadModel"
            );
            this.$emit("finish");
            this.$emit("close");
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.$swal(
              this.modelTitle,
              `Ops, houve algum erro: ${error}`,
              "error"
            );
            this.afterSave();
          });
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Baixar Parcela",
          description: "Processando sua requisição.",
        });
        Axios.patch(
          `${config.env.API_DEFAULT.host}/appointments/${this.model.id}`,
          this.model
        )
          .then((response) => {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.$swal({
              title: this.modelTitle,
              text: `${response.data.description} foi atualizada com sucesso.`,
              icon: "success",
            });
            EventBus.$emit(
              "Finance.Moviments.Appointments.Form.General.loadModel"
            );
            this.$emit("finish");
            this.$emit("close");
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.$swal(
              this.modelTitle,
              `Ops, houve algum erro: ${error}`,
              "error"
            );
            this.afterSave();
          });
      }
    },

    beforeSave() {
      this.oldModel.account = this.model.account;
      this.oldModel.moviment = this.model.moviment;
      this.oldModel.process = this.model.process;
      this.oldModel.registryDate = this.model.registryDate;
      this.oldModel.dueDate = this.model.dueDate;

      if (this.model.account !== null && this.model.account !== undefined) {
        this.model.account = `${config.env.API_DEFAULT.host}/accounts/${this.model.account.id}`;
      }

      // if(this.model.process !== null && this.model.process !== undefined && this.model.process._links.self.href !== null){ this.model.process = this.model.process._links.self.href; }
      if (this.model.moviment !== null && this.model.moviment !== undefined) {
        this.model.moviment = `${config.env.API_DEFAULT.host}/moviments/${this.model.moviment.id}`;
      }
      if (
        this.model.registryDate !== null &&
        this.model.registryDate !== undefined
      ) {
        this.model.registryDate = this.$moment(new Date()).format(
          "Y-MM-DDT00:00:00"
        );
      }
      if (this.model.dueDate !== null && this.model.dueDate !== undefined) {
        this.model.dueDate = this.$moment(this.model.dueDate).format(
          "Y-MM-DDT00:00:00"
        );
      }
    },
    afterSave() {
      this.model.account = this.oldModel.account;
      this.model.moviment = this.oldModel.moviment;
      // this.model.process = this.oldModel.process;
      this.model.registryDate = this.oldModel.registryDate;
      this.model.dueDate = this.oldModel.dueDate;
    },

    calculateTotal() {
      this.model.paidValue =
        this.model.originalValue -
        this.model.discountValue +
        this.model.fineValue +
        this.model.additionsValue;
    },

    getImportProcess() {
      const self = this;
      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/search/findByIdentification?identification=${self.selectedProcess}`
      )
        .then((response) => {
          self.model.importProcess = response.data._links.self.href;
          self.selectedProcess = response.data.identification;

          self.selectedProcessExists = true;
          // this.$set(this, "importProcesses", response.data._embedded.importProcesses);
        })
        .catch((error) => {
          self.$swal({
            title: "Processos de Importação",
            text: `O Processo de Importação ${self.selectedProcess} não foi encontrado.`,
            icon: "error",
          });
          self.selectedProcess = "";
          self.selectedProcessExists = false;
        });
    },
    getExportProcess() {
      const self = this;
      Axios.get(
        `${config.env.API_DEFAULT.host}/exportProcesses/search/findByIdentification?identification=${this.selectedProcess}`
      )
        .then((response) => {
          self.model.exportProcess = response.data._links.self.href;
          self.selectedProcess = response.data.identification;
          self.selectedProcessExists = true;
          // this.$set(this, "importProcesses", response.data._embedded.importProcesses);
        })
        .catch((error) => {
          self.$swal({
            title: "Processos de Exportação",
            text: `O Processo de Exportação ${self.selectedProcess} não foi encontrado.`,
            icon: "error",
          });
          self.selectedProcess = "";
          self.selectedProcessExists = false;
        });
    },
  },

  computed: {},
  mounted() {
    const self = this;

    if (!self.isNewRecord()) {
      Axios.get(
        `${config.env.API_DEFAULT.host}/appointments/${this.model.id}/moviment`
      )
        .then((result) => {
          self.$set(this.model, "moviment", result.data);
        })
        .catch((error) => {
          self.$swal({
            title: "Lançamento",
            text: `Ops, houve algum erro: ${error}`,
            icon: "error",
          });
        });

      if (this.model.moviment.typeOfProcess === "IMPORT") {
        Axios.get(
          `${config.env.API_DEFAULT.host}/appointments/${this.model.id}/importProcess`
        )
          .then((response) => {
            self.$set(
              this.model,
              "importProcess",
              response.data._links.self.href
            );
            self.selectedProcess = response.data.identification;
            self.selectedProcessExists = true;
          })
          .catch((error) => {
            this.$swal({
              title: "Processo de Importação",
              text: `Ops, houve algum erro: ${error}`,
              icon: "error",
            });
          });
      } else if (this.model.moviment.typeOfProcess === "EXPORT") {
        Axios.get(
          `${config.env.API_DEFAULT.host}/appointments/${this.model.id}/exportProcess`
        )
          .then((response) => {
            self.$set(
              this.model,
              "exportProcess",
              response.data._links.self.href
            );
            self.selectedProcess = response.data.identification;
            self.selectedProcessExists = true;
          })
          .catch((error) => {
            this.$swal({
              title: "Processo de Exportação",
              text: `Ops, houve algum erro: ${error}`,
              icon: "error",
            });
          });
      }
    } else {
      self.selectedProcess = "";
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  background-color: #f8f9fa;
  padding: 20px 15px 10px 15px;
}

.modal-header .close {
  padding: 18px 20px 0px 20px !important;
}

.modal-body {
  margin: 0px !important;
  padding: 0px !important;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
