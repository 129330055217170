<template>
  <div>
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Licenças de Importação</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Consulta e
          Cadastro de Licenças de Importação do Processo.
        </p>
      </div>
      <div>
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            placeholder="Pesquisar"
            v-model="search"
          />
          <button class="btn btn-primary">
            <i class="fa fa-search" style="margin-left: -5px"></i>
          </button>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-lg-6 mg-t-20 mg-lg-t-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-info pd-x-15"
              type="button"
              @click.prevent="addLI()"
              :disabled="currentLI.nrLicenciamento === undefined"
            >
              <i class="fa fa-plus"></i> Adicionar
            </button>
          </div>
          <the-mask
            :mask="['##/#######-#']"
            class="wd-400 form-control tx-left"
            v-model="liIdentification"
          />
          <span class="input-group-btn">
            <button
              class="btn bd bg-white tx-gray-600 bd-l-0 pd-x-15"
              type="button"
              @click.prevent="liIdentification = {}"
            >
              <i class="fa fa-trash"></i>
            </button>
            <button
              class="btn bd bg-white tx-gray-600 bd-l-0 pd-x-15"
              type="button"
              @click.prevent="findLI()"
            >
              <i class="fa fa-search"></i> Pesquisar
            </button>
          </span>
        </div>
        <!-- input-group -->
      </div>
    </div>

    <div v-if="currentLI.situacao !== undefined">
      <hr />

      <div class="row mg-t-20">
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"
            >Dados da Lincença de Importação</label
          >
          <p class="invoice-info-row">
            <span>Nº da LI</span>
            <span>{{ currentLI.nrLicenciamento }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Nº da LI Substituído</span>
            <span>{{ currentLI.nrLISubstituido }}</span>
          </p>
          <p class="invoice-info-row">
            <span> Nº da LI Substitutivo</span>
            <span>{{ currentLI.nrLISubstitutivo }}</span>
          </p>
          <p class="invoice-info-row">
            <span> Situação </span>
            <span>{{ currentLI.situacao }}</span>
          </p>
        </div>
        <div class="col-md">
          <label class="section-label-sm tx-gray-500"> &#160; </label>
          <p class="invoice-info-row">
            <span> Data/Hora Registro </span>
            <span>{{ currentLI.dataRegistro | moment("DD/MM/YYYY") }}</span>
          </p>
          <p class="invoice-info-row">
            <span> Data/Hora da Situação </span>
            <span>{{ currentLI.dataSituacao | moment("DD/MM/YYYY") }}</span>
          </p>
          <p class="invoice-info-row">
            <span> NCM </span>
            <span>{{ currentLI.cdSubItemNCM }}</span>
          </p>
          <p class="invoice-info-row">
            <span> INCOTERM </span>
            <span>{{ currentLI.dmIncotermsVenda }}</span>
          </p>
        </div>

        <div class="col-md">
          <label class="section-label-sm tx-gray-500"> &#160; </label>
          <p class="invoice-info-row">
            <span> Validade da LI para Embarque</span>
            <span>{{
              currentLI.dataValidadeEmbarque | moment("DD/MM/YYYY")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span> Validade da LI para Despacho </span>
            <span>{{
              currentLI.dataValidadeDespacho | moment("DD/MM/YYYY")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span> Restrição de Embarque </span>
            <span
              v-if="
                currentLI.dataRestricaoEmbarque !== null &&
                currentLI.dataRestricaoEmbarque !== ''
              "
              >{{
                currentLI.dataRestricaoEmbarque | moment("DD/MM/YYYY")
              }}</span
            >
            <span v-else>{{ currentLI.dataRestricaoEmbarqueDescricao }}</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="mg-b-0 mg-t-25" />

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 mg-t-20">
          Licenças de Importação do Processo
        </h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Foram encontradas
          {{ filteredList.length }} Licenças de Importação.
        </p>
      </div>
      <div></div>
    </div>

    <div class="row no-gutters">
      <div class="card card-table wd-100p">
        <!-- card-header -->
        <div class="table-responsive">
          <table class="table mg-b-0 tx-13 tx-gray-800 tx-normal">
            <thead>
              <tr class="tx-10">
                <th class="wd-150 tx-center p pd-y-5">Número da LI</th>
                <th class="pd-y-5 tx-center">Data Registro</th>
                <th class="pd-y-5 tx-center">Val Despacho</th>
                <th class="pd-y-5 tx-center">Val Embarque</th>
                <th class="pd-y-5 tx-center">NCM</th>
                <th class="pd-y-5 tx-center">INCOTERM</th>
                <th class="pd-y-5 tx-center">Situação</th>
                <th class="pd-y-5 tx-center">Cancelamento</th>
                <th class="pd-y-5 tx-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredList.length > 0">
                <template v-for="li in filteredList">
                  <tr :key="li.identification + '-li'">
                    <td class="pd-l-20 tx-bold tx-center tx-14">
                      {{ li.identification }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      {{ li.dataRegistro | moment("DD/MM/YYYY") }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      {{ li.dataValidadeDespacho | moment("DD/MM/YYYY") }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      {{ li.dataValidadeEmbarque | moment("DD/MM/YYYY") }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      {{ li.cdSubItemNCM }}
                    </td>
                    <td class="pd-l-20 tx-left tx-bold">
                      {{ li.dmIncotermsVenda }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      <span class="tx-bold">{{
                        li.dataSituacao | moment("DD/MM/YYYY")
                      }}</span>
                      <br />
                      <span
                        class="square-8 bg-success mg-r-5 rounded-circle"
                        v-if="li.situacao == 'DEFERIDA'"
                      ></span>
                      <span
                        class="square-8 bg-info mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'DESEMBARACADA'"
                      ></span>
                      <span
                        class="square-8 bg-warning mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'PARA ANALISE'"
                      ></span>
                      <span
                        class="square-8 bg-success mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'DESEMBARAÇADA'"
                      ></span>
                      <span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'EM EXIGENCIA'"
                      ></span>
                      <span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'INDEFERIDO'"
                      ></span>
                      <span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                        v-else-if="li.situacao == 'CANCELADA'"
                      ></span>
                      {{ li.situacao }}
                    </td>
                    <td class="pd-l-20 tx-center">
                      {{ li.dataCancelamentoOpTrat | moment("DD/MM/YYYY") }}
                    </td>
                    <td class="tx-center wd-150">
                      <a
                        href="#"
                        @click.prevent="currentLI = li"
                        class="btn btn-primary btn-icon rounded-circle mg-r-5"
                      >
                        <div><i class="icon ion-edit tx-20"></i></div>
                      </a>

                      <a
                        href="#"
                        @click.prevent="deleteLI(li)"
                        class="btn btn-danger btn-icon rounded-circle"
                      >
                        <div><i class="icon ion-trash-a tx-20"></i></div>
                      </a>
                    </td>
                  </tr>
                  <tr
                    :key="li.identification + '-data'"
                    v-if="li.dataRestricaoEmbarque"
                  >
                    <td colspan="10" class="tx-center tx-danger">
                      {{ li.identification }} - {{ li.dataRestricaoEmbarque }}
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="10" class="tx-center tx-danger">
                    <i class="fa fa-flag tx-danger"></i> Nenhuma Licença da
                    Importação foi cadastrada neste processo até o momento.
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import { TheMask } from "vue-the-mask";
import Swal from "sweetalert2";

export default {
  name: "operational-importation-form-lis",
  components: {
    TheMask,
  },
  data() {
    return {
      importProcess: {},
      liIdentification: "",
      currentLI: {},
      lis: [],
      search: "",
    };
  },
  methods: {
    async findLI() {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarAndamentoLI",
        this.liIdentification
      );

      if (response) {
        this.currentLI = response;
      }
    },
    addLI() {
      try {
        const li = this.currentLI;

        li.json = JSON.stringify(this.currentLI);
        li.identification = this.currentLI.nrLicenciamento;
        li.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.importProcess.id}`;

        if (
          this.currentLI.dataRegistro !== undefined &&
          this.currentLI.dataRegistro !== null &&
          this.currentLI.dataRegistro !== ""
        ) {
          li.dataRegistro = this.$moment(
            `${this.currentLI.dataRegistro.split("/")[2]}-${
              this.currentLI.dataRegistro.split("/")[1]
            }-${this.currentLI.dataRegistro.split("/")[0]}`
          ).format("Y-MM-DDT00:00:00");
        }

        if (
          this.currentLI.dataSituacao !== undefined &&
          this.currentLI.dataSituacao !== null
        ) {
          li.dataSituacao = this.$moment(
            `${this.currentLI.dataSituacao.split("/")[2]}-${
              this.currentLI.dataSituacao.split("/")[1]
            }-${this.currentLI.dataSituacao.split("/")[0]}`
          ).format("Y-MM-DDT00:00:00");
        }

        if (
          this.currentLI.dataCancelamentoOpTrat !== undefined &&
          this.currentLI.dataCancelamentoOpTrat !== null &&
          this.currentLI.dataCancelamentoOpTrat
        ) {
          li.dataCancelamentoOpTrat = this.$moment(
            `${this.currentLI.dataCancelamentoOpTrat.split("/")[2]}-${
              this.currentLI.dataCancelamentoOpTrat.split("/")[1]
            }-${this.currentLI.dataCancelamentoOpTrat.split("/")[0]}`
          ).format("Y-MM-DDT00:00:00");
        }

        if (
          this.currentLI.dataValidadeDespacho !== undefined &&
          this.currentLI.dataValidadeDespacho !== null &&
          this.currentLI.dataValidadeDespacho
        ) {
          li.dataValidadeDespacho = this.$moment(
            `${this.currentLI.dataValidadeDespacho.split("/")[2]}-${
              this.currentLI.dataValidadeDespacho.split("/")[1]
            }-${this.currentLI.dataValidadeDespacho.split("/")[0]}`
          ).format("Y-MM-DDT00:00:00");
        }

        if (
          this.currentLI.dataValidadeEmbarque !== undefined &&
          this.currentLI.dataValidadeEmbarque !== null &&
          this.currentLI.dataValidadeEmbarque
        ) {
          li.dataValidadeEmbarque = this.$moment(
            `${this.currentLI.dataValidadeEmbarque.split("/")[2]}-${
              this.currentLI.dataValidadeEmbarque.split("/")[1]
            }-${this.currentLI.dataValidadeEmbarque.split("/")[0]}`
          ).format("Y-MM-DDT00:00:00");
        }

        this.$store.commit("setLoading", {
          show: true,
          label: "Licença de Importação",
          description: "Adicionar a licença de importação no processo...",
        });

        Axios.post(`${config.env.API_DEFAULT.host}/licencaImportacaos`, li)
          .then((result) => {
            this.loadLIs();
            this.currentLI = {};
            this.liIdentification = {};
            Swal.fire(
              "Adicionar LI",
              `A Licença de Importação foi adicionada com sucesso ao processo <b>${this.importProcess.identification}</b>.`,
              "success"
            );
          })
          .catch((error) => {
            Swal.fire(
              "Adicionar LI",
              `Não foi possível adicionar a Licença de Importação  ao processo <b>${this.importProcess.identification}</b>.${error}`,
              "error"
            );
          });

        this.$store.commit("setLoading", {
          show: false,
          label: "Licença de Importação",
          description: "Adicionar a licença de importação no processo...",
        });
      } catch (error) {
        Swal.fire(
          "Adicionar LI",
          `Não foi possível adicionar a Licença de Importação  ao processo <b>${this.importProcess.identification}</b>.${error}`,
          "error"
        );
      }
    },

    loadLIs() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Licença de Importação",
        description: "Consultando LIs do processo...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.importProcess.id}/lis`
      )
        .then((result) => {
          this.lis = result.data._embedded.licencaImportacaos;
        })
        .catch((error) => {
          Swal.fire(
            "Consultar LIs",
            `Não foi possível consultar as LIS do processo <b>${this.importProcess.identification}</b>.${error}`,
            "error"
          );
        });

      this.$store.commit("setLoading", {
        show: false,
        label: "",
        description: "",
      });
    },

    deleteLI(model) {
      const self = this;
      const description = model.identification;

      Swal.fire({
        title: "Excluir LI",
        html: `Se você excluir a LI '${description}', você precisará consultá-la e adicioná-la novamente ao processo.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((willContinue) => {
        if (willContinue) {
          Axios.delete(model._links.self.href)
            .then((response) => {
              Swal.fire(
                "Excluir LI",
                ` A LI ${description} foi excluída com sucesso!`,
                "success"
              );
              this.loadLIs();
            })
            .catch((error) => {
              Swal.fire(
                "Excluir LI",
                `Ops, houve um erro! \n\n${error}`,
                "error"
              );
            });
        }
      });
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      li: (state) => state.MyIMP.currentLI,
    }),
    filteredList() {
      if (this.lis !== undefined && this.lis !== null && this.lis.length > 0) {
        return this.lis.filter((li) => {
          if (li.identification !== undefined && li.identification !== null) {
            return li.identification
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }
        });
      }
      return this.lis;
    },
  },
  created() {
    this.importProcess = { ...this.process };
    // this.lis = this.importProcess.lis;

    this.loadLIs();
  },
  watch: {
    process() {
      this.importProcess = { ...this.process };
    },
    // li: function () {
    //     this.currentLI = { ...this.li};
    // },
  },
};
</script>

<style scoped>
</style>
