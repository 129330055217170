<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 5H3.99998V17H44V5Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="3"
      stroke-linejoin="round"
    />
    <path
      d="M3.99998 41.0301L16.1756 28.7293L22.7549 35.0301L30.7982 27L35.2786 31.368"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44 16.1719V42.1719"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M3.99998 16.1719V30.1719"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M13.0155 43H44"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M17 11H38"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M9.99998 10.9966H11"
      stroke="#343A40"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'NpsIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style></style>
