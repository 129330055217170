<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Importação</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="filter.date.start !== '' && filter.date.end !== ''"
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar"></i> Data de Entrega no Cliente
                      no Período:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-300">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20"
                  @click.prevent="generate('HTML')"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> GERAR
                </button>
                <button
                  type="button"
                  class="btn btn-teal pd-x-20"
                  @click.prevent="generate('PDF')"
                >
                  <i class="fa fa-file-pdf-o mg-r-5"></i> PDF
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta</label
            >
            <div class="row justify-content-between">
              <div>
                <p class="mg-b-0 ml-3">
                  Foram encontrados {{ listOfResults.length }} Processos de
                  Importação de acordo com o filtro acima.
                </p>
              </div>
              <div class="p-3 bg-info mr-3" v-if="averageDaysToDelivery > 0">
                <span class="text-white">Média de dias para entrega no período: {{ averageDaysToDelivery }}</span>
              </div>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th class="tx-center">Referência</th>
                  <th class="tx-center">Abertura</th>
                  <th class="tx-center">Entrega Cliente</th>
                  <th class="tx-center">Email</th>
                  <th class="tx-center">Dias para Entrega</th>
                  <th class="tx-center">Envio</th>
                  <th class="tx-center">Ref cliente</th>
                  <th class="tx-center">Cliente</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in listOfResults" :key="result.id">
                  <td class="tx-center tx-bold" scope="row">
                    <a
                      href="#"
                      @click.prevent="
                          findProcessByIdentificationSearch(
                            result.identification
                          )
                        "
                    >
                      {{ result.identification }}
                    </a>
                  </td>
                  <td class="tx-center">
                    {{ result.created | moment("DD/MM/YYYY") }}
                  </td>
                  <td class="tx-center">
                    {{
                    result.datesCustomerDeliverDate | moment("DD/MM/YYYY")
                    }}
                  </td>
                  <td class="tx-center">
                    {{ result.billingEmailDate | moment("DD/MM/YYYY") }}
                  </td>
                  <td class="tx-center">

                    <span v-if="result.datesCustomerDeliverDate && result.billingEmailDate">{{ diffDays(result.datesCustomerDeliverDate, result.billingEmailDate) }} dias</span>
                    <span v-else>-</span>
                  </td>
                  <td class="tx-center">
                    {{ result.billingSendDate | moment("DD/MM/YYYY") }}
                  </td>
                  <td class="tx-left tx-12">{{ result.customerRef }}</td>
                  <td class="tx-left tx-12">
                    <template v-if="result.customer !== null">
                      {{ result.customer.name }}
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import config from "@/config.js";
import * as labelsProcesses from "@/labels/labels.processes.js";

import {mapState} from "vuex";

import moment from "moment";

export default {
  name: "OperationalIpe",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      mainTitle: "Relatório Indicador de Prazo de Encerramento",
      averageDaysToDelivery: null,
      filter: {
        steps: [],
        exporters: [],
        customers: [],
        customsBrokers: [],
        date: {
          field: "datesCustomerDeliverDate",
          start: moment().startOf("week").toDate(),
          end: moment().endOf("week").toDate(),
        },
      },
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      listOfCustomsBrokers: [],
      listOfCustomers: [],
      listOfExporters: [],
      listOfResults: [],
    };
  },

  methods: {
    diffDays(date1, date2) {
      const firstDate = moment(date1);
      const secondDate = moment(date2);

      return secondDate.diff(firstDate, 'days');
    },
    generate(type) {
      const {filter} = this;

      if (filter.date.start === "" || filter.date.end === "") {
        Swal.fire(
          this.mainTitle,
          "Você precisa selecionar um período para conseguir gerar o relatório.",
          "error"
        );
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Relatório Indicador de Prazo de Encerramento",
          description: "Aguarde enquanto o relatório está sendo gerado...",
        });

        let params = "";

        const startDate = this.$moment(filter.date.start).format("Y-MM-DDTLTS");
        const endDate = this.$moment(filter.date.end).format("Y-MM-DDTLTS");

        params += `&from=${startDate}&to=${endDate}`;

        // if (filter.steps.length > 0) {
        //     var steps = new Array();
        //     filter.steps.filter(s => {
        //         steps.push(s.id);
        //     });
        //     params += "&stepsIds=" + steps.join();
        // }

        // if (filter.customers.length > 0) {
        //     var customers = new Array();
        //     filter.customers.filter(o => {
        //         customers.push(o.id);
        //     });
        //     params += "&customersIds=" + customers.join();
        // }

        // if (filter.customsBrokers.length > 0) {
        //     var customsBrokers = new Array();
        //     filter.customsBrokers.filter(o => {
        //         customsBrokers.push(o.id);
        //     });
        //     params += "&customsBrokersIds=" + customsBrokers.join();
        // }

        // if (filter.exporters.length > 0) {
        //     var exporters = new Array();
        //     filter.exporters.filter(o => {
        //         exporters.push(o.id);
        //     });
        //     params += "&exportersIds=" + exporters.join();
        // }

        Axios.get(
          `${config.env.API_DEFAULT.host}/process/import/report/general?field=datesCustomerDeliverDate${params}`
        )
          .then((response) => {
            if (response.data.code === "SUCCESS") {
              this.listOfResults = response.data.data;

              if (type === "PDF") {
                this.printDemonstrative(this.listOfResults);
              } else {
                this.$store.commit("setLoading", {
                  show: false,
                });
                Swal.fire(this.mainTitle, response.data.message, "success");
              }

              const daysToDelivery = response.data.data.map(closing => {
                return this.diffDays(closing.datesCustomerDeliverDate, closing.billingEmailDate);
              });

              const sumDaysToDelivery = daysToDelivery.reduce((item1, item2) => item1 + item2, 0);
              this.averageDaysToDelivery = Math.ceil((sumDaysToDelivery / daysToDelivery.length));

            } else {
              this.$store.commit("setLoading", {
                show: false,
              });
              Swal.fire(this.mainTitle, response.data.message, "error");
            }
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
            });
          });
      }
    },

    printDemonstrative(data) {
      const requestConfiguration = {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.env.API_JSREPORTONLINE.credentials.token,
          responseType: "arraybuffer",
        },
      };

      const {account} = this.$store.state.credentials.data;

      delete account.logo;
      const requestData = {
        template: {
          shortid: "zNNe8GP",
          recipe: "chrome-pdf",
        },
        data: {
          account: {
            ...account,
            logo: "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png",
          },
          data,
        },
      };

      jsreport.serverUrl = config.env.API_JSREPORTONLINE.host;
      jsreport.headers.Authorization =
        config.env.API_JSREPORTONLINE.credentials.token;

      jsreport
        .renderAsync(requestData)
        .then((res) => {
          // open download dialog
          res.download("Relatório Geral de Importações.pdf");
          res.xhr.getResponseHeader("Content-Type");
          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Relatório Geral de Importações",
            `Houve algum erro ao tentar gerar o demonstrativo desta DI: <strong>${error.response.data.message}</strong>`,
            "error"
          );
        });
    },

    async findProcessByIdentificationSearch(identification) {
      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        identification
      );
      if (response !== undefined && response !== null && response.success) {
        // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
        this.$router.push("/operational/imp/desk?showProcess=true");
      } else Swal.fire("Processo de Importação", response.message, "error");
    },
  },
  watch: {},
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
    }),
    listOfDatesFields() {
      const data = new Array();

      if (this.labels !== undefined) {
        for (const prop in this.labels) {
          if (this.labels[prop].type === "date") {
            data.push(this.labels[prop]);
          }
        }
      }

      return data;
    },
  },
  mounted() {
    this.$store.dispatch("ProcessStore/getSteps");

    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde...",
      description: "Carregando informações para filtro do relatório...",
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomBroker?isCustomBroker=true`
    ).then((response) => {
      this.listOfCustomsBrokers = response.data._embedded.companies;
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsExporter?isExporter=true`
    ).then((response) => {
      this.listOfExporters = response.data._embedded.companies;
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`
    ).then((response) => {
      this.listOfCustomers = response.data._embedded.companies;
    });

    this.$store.commit("setLoading", {
      show: false,
    });
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}
</style>
