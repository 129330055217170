<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Operacional</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Licenças de Importação (LIs)</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">Listagem de {{ modelTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
              <p class="mg-b-0">
                Foram encontrados {{ data.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas.
              </p>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'operational-imp-declaration-form',
                  params: { action: 'create' },
                }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <v-client-table
          :columns="columns"
          :data="listOfLIs._embedded.typedDIs"
          :options="options"
          v-if="listOfLIs._embedded !== undefined"
        >
          <template slot="criacao" slot-scope="props">
            <div class="enabled">
              <div class="tx-center tx-13">
                {{ props.row.criacao | moment("DD/MM/YYYY") }}
              </div>
            </div>
          </template>

          <template slot="dataRegistroDI" slot-scope="props">
            <div class="enabled">
              <div class="tx-center" v-if="props.row.di !== undefined">
                {{ props.row.di.dataRegistroDI | moment("DD/MM/YYYY") }}
              </div>
            </div>
          </template>

          <template slot="identificacaoDeclaracaoImportacao" slot-scope="props">
            <div class="enabled">
              <div class="tx-center">
                <span class="badge badge-teal pd-x-10 pd-y-5 tx-14 tx-thin">
                  {{ props.row.identificacaoDeclaracaoImportacao }}
                </span>
              </div>
            </div>
          </template>

          <template slot="numero" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-center"
                v-if="
                  props.row.di !== null &&
                  props.row.di !== undefined &&
                  props.row.di.identification !== undefined
                "
              >
                <span class="badge badge-primary pd-x-10 pd-y-5 tx-14 tx-thin">
                  {{ props.row.di.identification }}
                </span>
              </div>
            </div>
          </template>

          <template slot="processoImportacao" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-center"
                v-if="
                  props.row.processoImportacao !== null &&
                  props.row.processoImportacao !== undefined &&
                  props.row.processoImportacao.identification !== undefined
                "
              >
                <span class="badge badge-primary pd-x-10 pd-y-5 tx-14 tx-thin">
                  {{ props.row.processoImportacao.identification }}
                </span>
              </div>
            </div>
          </template>

          <template slot="importador" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-left"
                v-if="
                  props.row.importador !== null &&
                  props.row.importador !== undefined
                "
              >
                {{ props.row.importador.name }} /
                {{ props.row.importador.federalID }}
              </div>
            </div>
          </template>

          <template slot="adquirente" slot-scope="props">
            <div class="enabled">
              <div
                class="tx-left"
                v-if="
                  props.row.adquirente !== null &&
                  props.row.adquirente !== undefined
                "
              >
                {{ props.row.adquirente.name }} /
                {{ props.row.adquirente.federalID }}
              </div>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'operational-imp-declaration-form',
                params: { action: 'update', id: props.row.id },
              }"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div>
                <i class="icon ion-edit"></i>
              </div>
            </router-link>
            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div>
                <i class="icon ion-trash-a"></i>
              </div>
            </a>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import * as constants from "@/consts.js";
import config from "@/config.js";
import Axios from "axios";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";
import { mapState } from "vuex";

import Swal from "sweetalert2";

export default {
  name: "configuration-accounts-list",

  data() {
    return {
      modelTitle: "Licenças de Importação",
      mainTitle: "Licenças de Importação",
      columns: [
        "criacao",
        "registro",
        "numero",
        "identificacaoDeclaracaoImportacao",
        "processoImportacao",
        "importador",
        "adquirente",
        "actions",
      ],
      data: new Array(),
      options: {
        headings: {
          criacao: "Criação",
          registro: "Registro",
          numero: "Número",
          identificacaoDeclaracaoImportacao: "Identificação",
          processoImportacao: "Processo",
          importador: "Importador",
          adquirente: "Adquirente",
          actions: "Ações",
        },
        sortable: [
          "criacao",
          "registro",
          "numero",
          "identificacaoDeclaracaoImportacao",
          "processoImportacao",
          "importador",
          "adquirente",
        ],
        filterable: [
          "criacao",
          "registro",
          "numero",
          "identificacaoDeclaracaoImportacao",
          "processoImportacao",
          "importador",
          "adquirente",
        ],

        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "{column}",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 100,
        perPageValues: [25, 50, 100, 500],
      },
    };
  },
  methods: {
    async loadList() {
      this.$store.commit("MyIMPStore/SET_ACTIVE", null);
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);
      const response = await this.$store.dispatch("MyIMPStore/consultarLIs");
      // this.data = response._embedded.typedDIs;
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    async deleteModel(row) {
      const willContinue = await Swal.fire({
        title: "Você tem certeza?",
        text: `Se você excluir a declaração de importação  ${row.identificacaoDeclaracaoImportacao} não será possível recuperá-la para uso posterior.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (willContinue.value) {
        const response = await this.$store.dispatch(
          "MyIMPStore/delete",
          row.id
        );

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            `A declaração de importação ${row.identificacaoDeclaracaoImportacao} foi excluída com sucesso..`,
            "success"
          );
          this.loadList();
        }
      }
    },
  },
  computed: {
    ...mapState({
      listOfLIs: (state) => state.MyIMPStore.listOfLIs,
    }),
  },
  mounted() {
    this.loadList();
  },
};
</script>
