<template>
  <div class="contact-form">
    <div class="sp-3 light-bg p-3">
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">CONQUISTAS</h4>
          <p class="mb-0">{{ achievements.length }} conquistas cadastradas</p>
        </div>
      </div>
    </div>

    <div
      class="card-body pd-40 sp-3 text-center"
      v-if="achievements.length == 0"
    >
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
      <h6 class="tx-md-20 tx-inverse">Nenhuma Conquista Cadastrada</h6>
    </div>

    <div class="ph-2 sp-3" v-else>
      <div class="sp-3 table-overflow">
        <table>
          <thead>
            <tr>
              <th>Ref</th>
              <th>Data</th>
              <th>Origem</th>
              <th>Descrição / Tipo</th>
              <th>Valor R$</th>
              <th>Etapa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in achievements" :key="item.id">
              <td>
                {{
                  item.process
                    ? getTypeOfProcessReferenceByProcess(item.process)
                    : "-"
                }}
              </td>
              <td>
                {{ item.date | moment("DD/MM/YYYY") }}
              </td>
              <td>
                <div class="text-danger text-bold">
                  {{ item.source }}
                </div>
                <div>
                  {{ item.createdBy }}
                </div>
              </td>
              <td>
                <div v-html="item.description"></div>
                <div class="text-bold">
                  {{ item.type }}
                </div>
              </td>
              <td>
                {{ item.value | number }}
              </td>
              <td>
                {{ item.step ? item.step.alias : "-" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import Swal from 'sweetalert2';

import Axios from 'axios';
import ProcessService from '@/services/ProcessService.js';

export default {
  name: 'CustomerAchievements',
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      achievements: [],
    };
  },
  filters: {
    number(value) {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(value);
    },
  },
  mounted() {
    this.getConquests();
  },
  methods: {
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    async getConquests() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando conquistas do cliente.',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host
          }/conquests/listByCustomerId/${this.customerDetails.id}`,
        );

        this.achievements = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Erro interno',
          html: `<p>Não foi possível recuperar as conquistas do cliente</p> <div>${error}</div>`,
          type: 'error',
        });
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392a51a;
}
</style>
