<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 60 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M44 17H14V47H44V17Z" fill="white" fill-opacity="0.01" />
    <path
      d="M16.5 43.25H41.5"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.75 33.25H20.25C19.5596 33.25 19 33.8096 19 34.5V42C19 42.6904 19.5596 43.25 20.25 43.25H22.75C23.4404 43.25 24 42.6904 24 42V34.5C24 33.8096 23.4404 33.25 22.75 33.25Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path
      d="M21.5 38.25H22.125"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.75 19.5H25.25C24.5596 19.5 24 20.0596 24 20.75V42C24 42.6904 24.5596 43.25 25.25 43.25H37.75C38.4404 43.25 39 42.6904 39 42V20.75C39 20.0596 38.4404 19.5 37.75 19.5Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path d="M30.25 23.25H27.75V25.75H30.25V23.25Z" fill="#343A40" />
    <path d="M35.25 23.25H32.75V25.75H35.25V23.25Z" fill="#343A40" />
    <path d="M30.25 27.625H27.75V30.125H30.25V27.625Z" fill="#343A40" />
    <path d="M35.25 27.625H32.75V30.125H35.25V27.625Z" fill="#343A40" />
    <path d="M35.25 32H32.75V34.5H35.25V32Z" fill="#343A40" />
    <path d="M35.25 36.375H32.75V38.875H35.25V36.375Z" fill="#343A40" />
  </svg>
</template>

<script>
export default {
  name: 'CompaniesIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
