var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"light-bg p-3 bd-b"},[_c('div',[_c('div',{class:['flex ai-c jc-sb fw-w fgap2']},[_vm._m(0),_c('div',{staticClass:"flex ai-c fgap1"},[(_vm.filteredActivities.length > 0)?_c('download-excel',{staticClass:"plus-button bg-success",attrs:{"data":_vm.filteredActivities,"fields":_vm.excelFields,"name":`relatorio-geral-de-atividades.xls`}},[_vm._v(" Exportar para Excel ")]):_vm._e(),_c('button',{staticClass:"plus-button",attrs:{"type":"button"},on:{"click":function($event){_vm.addDialog = !_vm.addDialog}}},[_vm._v(" Filtros aplicados ")])],1)]),_c('transition',{attrs:{"name":"activity","mode":"out-in"}},[(_vm.addDialog)?_c('div',[_c('div',{staticClass:"four-grid mt-4"},[_c('div',{staticClass:"four-grid"},[_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"customersIds","label":"Clientes","options":_vm.customers,"multiple":true},model:{value:(_vm.formFilter.customersIds),callback:function ($$v) {_vm.$set(_vm.formFilter, "customersIds", $$v)},expression:"formFilter.customersIds"}})],1)]),_c('div',{staticClass:"four-grid"},[_c('div',{staticClass:"fg-4"},[_c('v-input',{attrs:{"name":"startDate","label":"Data inicial (Obrigatório)","type":"date"},model:{value:(_vm.formFilter.startDate),callback:function ($$v) {_vm.$set(_vm.formFilter, "startDate", $$v)},expression:"formFilter.startDate"}})],1)]),_c('div',{staticClass:"four-grid"},[_c('div',{staticClass:"fg-4"},[_c('v-input',{attrs:{"name":"endDate","label":"Data final (Obrigatório)","type":"date"},model:{value:(_vm.formFilter.endDate),callback:function ($$v) {_vm.$set(_vm.formFilter, "endDate", $$v)},expression:"formFilter.endDate"}})],1)]),(
                _vm.formFilter.customersIds && _vm.formFilter.customersIds.length > 0
              )?_c('div',{staticClass:"five-grid mt-1"},[_c('div',[_c('v-switch',{attrs:{"name":"isProvider","labels":{
                    checked: 'Com atividades dos contatos',
                    unchecked: 'Sem atividades dos contatos',
                  }},model:{value:(_vm.formFilter.getCustomerContactsActivities),callback:function ($$v) {_vm.$set(_vm.formFilter, "getCustomerContactsActivities", $$v)},expression:"formFilter.getCustomerContactsActivities"}})],1)]):_vm._e()]),_c('div',{staticClass:"flex ai-c jc-c fgap2 mt-4"},[_c('button',{staticClass:"report-button",on:{"click":function($event){$event.preventDefault();return _vm.generateInTable.apply(null, arguments)}}},[_vm._v(" GERAR EM TELA ")])])]):_vm._e()])],1)]),_c('div',{staticClass:"ph-2 sp-3 mt-4"},[_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-overflow"},[_c('table',[_vm._m(1),_c('tbody',[(_vm.filteredActivities.length === 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v(" Nenhuma atividade encontrada ")])]):_vm._l((_vm.filteredActivities),function(activity){return _c('tr',{key:activity.id},[_c('td',[_vm._v(_vm._s(activity.customer))]),_c('td',[_vm._v(_vm._s(activity.type))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(activity.date)))]),_c('td',[_vm._v(_vm._s(activity.subject))]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(activity.description)}})])])})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb-0"},[_vm._v("EXPORTAR ATIVIDADES DE CLIENTES")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Exportação de atividades em geral em Tela e Excel ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Cliente / Contato")]),_c('th',[_vm._v("Tipo")]),_c('th',[_vm._v("Data")]),_c('th',[_vm._v("Assunto")]),_c('th',[_vm._v("Descrição")])])])
}]

export { render, staticRenderFns }