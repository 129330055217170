var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-content p-4"},[_c('form',{staticClass:"groupForm columns md-gap",staticStyle:{"align-items":"start"},on:{"submit":function($event){$event.preventDefault();return _vm.updateGroup.apply(null, arguments)}}},[_c('div',{staticClass:"columns md-gap span-3"},[_vm._m(0),_c('v-input',{staticClass:"span-8",attrs:{"name":"nome","type":"text","label":"Nome"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-input',{staticClass:"span-8",attrs:{"name":"code","type":"text","label":"Código"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('select-wrapper',{staticClass:"span-8",attrs:{"options":[
          {
            label: 'Sim',
            value: true,
          },
          {
            label: 'Não',
            value: 'false',
          },
        ],"label":"Habilitado"},model:{value:(_vm.formData.enabled),callback:function ($$v) {_vm.$set(_vm.formData, "enabled", $$v)},expression:"formData.enabled"}})],1),_c('div',{staticClass:"columns md-gap span-5"},[_vm._m(1),_c('div',{staticClass:"span-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v(" PRIVILÉGIOS DISPONÍVEIS ")]),_c('div',{staticClass:"documents-search"},[_c('i',{staticClass:"fa fa-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscaPrivilegios),expression:"buscaPrivilegios"}],attrs:{"type":"text","name":"buscaPrivilegios","id":"buscaPrivilegios"},domProps:{"value":(_vm.buscaPrivilegios)},on:{"input":function($event){if($event.target.composing)return;_vm.buscaPrivilegios=$event.target.value}}})]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.filteredPrivileges),function(privilege){return [(privilege.selected !== null &&
                      privilege.selected !== true
                      )?_c('li',{key:privilege.id,on:{"click":function($event){$event.preventDefault();privilege.selected = true}}},[_c('span',[_c('strong',[_vm._v(_vm._s(privilege.name))])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-right"})])]):_vm._e()]})],2)])]),_c('div',{staticClass:"col-2 d-flex flex-column justify-content-center align-items-center",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"documents-button",on:{"click":function($event){$event.preventDefault();return _vm.selectAll.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-double-right"})]),_c('div',{staticClass:"documents-button",on:{"click":function($event){$event.preventDefault();return _vm.deselectAll.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v(" PRIVILÉGIOS SELECIONADOS ")]),_c('div',{staticClass:"documents-search"},[_c('p',[_vm._v(" "+_vm._s(_vm.selectedPrivileges)+" privilégio(s) selecionado(s) ")])]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.privileges),function(privilege){return [(privilege.selected !== null &&
                      privilege.selected !== false
                      )?_c('li',{key:privilege.id,on:{"click":function($event){$event.preventDefault();privilege.selected = false}}},[_c('span',[_c('strong',[_vm._v(_vm._s(privilege.name))])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])]):_vm._e()]})],2)])])])])]),_vm._m(2)])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content p-4"},[_c('div',{staticClass:"columns md-gap"},[_vm._m(3),_c('div',{staticClass:"span-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v("USUÁRIOS DISPONÍVEIS")]),_c('div',{staticClass:"documents-search"},[_c('i',{staticClass:"fa fa-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscaUsuarios),expression:"buscaUsuarios"}],attrs:{"type":"text","name":"buscaPrivilegios","id":"buscaPrivilegios"},domProps:{"value":(_vm.buscaUsuarios)},on:{"input":function($event){if($event.target.composing)return;_vm.buscaUsuarios=$event.target.value}}})]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.filteredUsers),function(user){return [(user.selected !== null && user.selected !== true
                      )?_c('li',{key:user.id,on:{"click":function($event){$event.preventDefault();return _vm.linkUserToGroup(user.id)}}},[_c('span',{staticClass:"tx-uppercase"},[(user.enabled)?_c('span',{staticClass:"square-8 bg-success mg-r-5 rounded-circle"}):_c('span',{staticClass:"square-8 bg-danger mg-r-5 rounded-circle"}),_c('span',{staticClass:"tx-semibold"},[_vm._v(_vm._s(user.name))]),_c('small',{staticClass:"mg-l-5"},[_vm._v("("+_vm._s(user.email1)+")")])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-right"})])]):_vm._e()]})],2)])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v("USUÁRIOS VINCULADOS")]),_c('div',{staticClass:"documents-search"},[_c('p',[_vm._v(_vm._s(_vm.selectedUsers)+" usuários(s) vinculados(s)")])]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.users),function(user){return [(user.selected !== null && user.selected !== false
                      )?_c('li',{key:user.id,on:{"click":function($event){$event.preventDefault();return _vm.unlinkUserFromGroup(user.id)}}},[_c('span',{staticClass:"tx-uppercase"},[_c('span',{staticClass:"tx-normal"},[_vm._v(_vm._s(user.name))]),_c('small',{staticClass:"mg-l-5"},[_vm._v("("+_vm._s(user.email1)+")")])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])]):_vm._e()]})],2)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-8"},[_c('h4',{staticClass:"tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18"},[_vm._v(" Informações do Grupo ")]),_c('p',{staticClass:"mg-b-3"},[_vm._v(" Informações principais para identificação deste Grupo de Acesso. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-8"},[_c('h4',{staticClass:"tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18"},[_vm._v(" Privilégios ")]),_c('p',{staticClass:"mg-b-3"},[_vm._v(" Privilégios que os usuários que estão neste Grupo de Acesso podem ter. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-gap span-8 row justify-content-center"},[_c('button',{staticClass:"crm-button mb-4 mt-3 wd-300",attrs:{"type":"submit"}},[_vm._v(" ATUALIZAR GRUPO DE ACESSO ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-8"},[_c('h4',{staticClass:"tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18"},[_vm._v(" Usuário do Grupo de Acesso ")]),_c('p',{staticClass:"mg-b-3"},[_vm._v(" Facilitador para Vincular ou Remover Usuários neste Grupo de Acesso. ")])])
}]

export { render, staticRenderFns }