<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Conta</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Habilitada <span class="tx-danger">*</span>
            </label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="bankAccount.enabled"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="bankAccount.enabled"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Não</span>
                </label>
              </div>
              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
              >Selecione uma opção</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Tipo de Conta Bancária: </label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-orange">
                <input
                  type="checkbox"
                  id="isChecking"
                  name="checking"
                  v-model="bankAccount.checking"
                /><span>Corrente</span>
              </label>

              <label class="ckbox ckbox-primary">
                <input
                  type="checkbox"
                  id="isSaving"
                  name="saving"
                  v-model="bankAccount.saving"
                /><span>Poupança</span>
              </label>

              <label class="ckbox ckbox-success">
                <input
                  type="checkbox"
                  id="isInvestment"
                  name="investment"
                  v-model="bankAccount.investment"
                /><span>Investimento</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Saldo em Conta <span class="tx-danger">*</span></label
            >
            <money
              class="form-control"
              v-model="bankAccount.openingBalance"
              v-bind="money"
              v-validate="'required'"
            ></money>
            <span
              v-show="errors.has('openingBalance')"
              class="parsley-errors-list filled"
            >Saldo em Conta</span
            >
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Data do Saldo <span class="tx-danger">*</span>
            </label>
            <date-picker lang="pt-br"
                         v-model="bankAccount.openingBalanceDate"
                         name="openingBalanceDate"
                         class="form-control"
                         format="DD/MM/YYYY"
                         v-validate="'required'"
            ></date-picker>
            <span
              v-show="errors.has('openingBalanceDate')"
              class="parsley-errors-list filled"
            >Selecione uma Data</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-6 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Descrição <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              placeholder="Descrição"
              name="description"
              class="form-control"
              v-model="bankAccount.description"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
            >Digite a Descrição</span
            >
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Banco <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="bankAccount.bank"
              :on-search="getBanks"
              label="name"
              :options="banks"
              name="bank"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.name }}
              </template>
            </v-select>
            <span v-show="errors.has('bank')" class="parsley-errors-list filled"
            >Selecione o Banco</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Agência <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              placeholder="Digite a agência"
              name="description"
              class="form-control"
              v-model="bankAccount.agencyNumber"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
            >Digite a agência</span
            >
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Conta <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              placeholder="Digite a conta"
              name="accountNumber"
              class="form-control"
              v-model="bankAccount.accountNumber"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('accountNumber')"
              class="parsley-errors-list filled"
            >Digite a Conta</span
            >
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Filial <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="bankAccount.account"
              :on-search="getAccounts"
              label="name"
              :options="accounts"
              name="account"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                <span>{{ option.federalID }} | {{ option.name }}</span>
              </template>
            </v-select>
            <span
              v-show="errors.has('accounts')"
              class="parsley-errors-list filled"
            >Selecione o Banco</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {Money} from "v-money";

export default {
  name: "bankAccount-form-general",
  props: ["bankAccount", , "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
    DatePicker,
    Money,
  },
  data() {
    return {
      banks: [],
      accounts: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) {
            this.addModel();
          } else {
            this.updateModel();
          }
        }
      });
    },
    addModel() {
      this.manageModel();

      Axios.post(
        `${config.env.API_DEFAULT.host}/bankAccounts`,
        this.bankAccount
      )
        .then((response) => {
          if (response.status === 201) {
            this.bankAccount = response.data;
            this.$swal({
              title: `O Banco ${this.bankAccount.description} foi cadastrada com sucesso.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            this.$router.push({
              name: "bankAccounts-form",
              params: {type: "update", id: response.data.id},
            });
          } else {
            this.$swal({
              title: `A empresa ${this.bankAccount.description} não foi cadastrada.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: `A empresa ${this.bankAccount.description} não foi cadastrada. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    updateModel() {
      this.manageModel();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/bankAccounts/${this.bankAccount.id}`,
        this.bankAccount
      )
        .then((response) => {
          this.$swal(
            "Contas Bancárias",
            `A empresa ${this.bankAccount.description} foi atualizado com sucesso no banco de dados.`,
            "success"
          );
          this.bankAccount.bank = this.oldModel.bank;
          this.bankAccount.account = this.oldModel.account;
        })
        .catch((error) => {
          this.$swal(
            "Contas Bancárias",
            `Não foi possível atualizar a empresa ${this.bankAccount.description} no banco de dados.`,
            "error"
          );
        });
    },
    getBankModel() {
      if (
        this.bankAccount.account !== undefined &&
        this.bankAccount.account !== null
      ) {
        this.bankAccount.account = this.bankAccount.account._links.self.href;
      }
    },
    manageModel() {
      this.bankAccount.openingBalanceDate = this.$moment(
        this.bankAccount.openingBalanceDate
      ).format("Y-MM-DDTLTS");

      this.bankAccount.openingBalanceDate = this.$moment(
        this.bankAccount.openingBalanceDate
      ).format("Y-MM-DDTLTS");

      if (
        self.bankAccount.bank !== undefined &&
        self.bankAccount.bank !== null
      ) {
        self.bankAccount.bank = self.bankAccount.bank._links.self.href;
      }

      if (
        self.bankAccount.account !== undefined &&
        self.bankAccount.account !== null
      ) {
        self.bankAccount.account = self.bankAccount.account._links.self.href;
      }
    },
    getBanks(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/banks/search/findByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.banks = response.data._embedded.banks;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Bancos",
            `Não foi possível carregar a listagem de Bancos disponíveis: ${error}`,
            "error"
          );
        });
    },
    getAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/accounts/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.accounts = response.data._embedded.accounts;
          loading(false);
        })
        .catch((error) => {
          swal(
            `Não foi possível carregar a listagem de Filiais disponíveis: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
  },
};
</script>
