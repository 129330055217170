<template>
  <form
    @submit.prevent="search"
    class="cont-filter bg-white pd-x-20 pd-t-20 pd-b-20 mb-2 d-flex align-items-center"
  >
    <span class="material-icons-outlined icon"> settings_input_component </span>

    <div class="input-comp">
      <label class="form-control-label">NOME DO ARQUIVO</label>
      <input @keydown="verifyEnter" v-model="filters.fileName" type="text" name="file_name" id="file_name"
             placeholder="Digite o nome do arquivo">
    </div>

    <div class="input-comp">
      <label for="grupo">STATUS DO ENVIO</label>
      <v-select
        v-model="filters.extractionStatus"
        placeholder="Selecione uma ou mais etapas"
        label="name"
        :options="extractionStatusOptions"
        name="startDate"
      >
      </v-select>
    </div>

    <div class="input-comp">
      <label for="etapas">DATA INICIAL</label>
      <date-picker lang="pt-br"
                   v-model="filters.startDate"
                   name="data-inicial"
                   class="input-date"
                   format="DD/MM/YY"

                   clear-button="true"
                   placeholder="Selecione"
      ></date-picker>
    </div>

    <div class="input-comp">
      <label for="etapas">DATA FINAL</label>
      <date-picker lang="pt-br"
                   v-model="filters.endDate"
                   name="data-final"
                   class="input-date"
                   format="DD/MM/YY"

                   clear-button="true"
                   placeholder="Selecione"
      ></date-picker>
    </div>

    <div>
      <button type="button" class="filter-btn" @click.prevent="search">
        <span class="material-icons-outlined"> cached </span>
        PESQUISAR
      </button>
    </div>
  </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

export default {
  name: 'OriginalDocumentsFilter',
  components: {
    'date-picker': DatePicker,
  },
  data() {
    return {
      filters: {
        fileName: '',
        extractionStatus: null,
        startDate: null,
        endDate: null,
      },
      extractionStatusOptions: [
        {
          name: 'Extraindo Dados',
          value: 'AWAITING',
        },
        {
          name: 'Pronto p/ Revisão',
          value: 'COMPLETE',
        },
        {
          name: 'Aprovado',
          value: 'APPROVED',
        },
        {
          name: 'Rejeitado',
          value: 'REJECTED',
        },
        {
          name: 'Invoice Criada',
          value: 'CREATED',
        },
      ],
    };
  },
  methods: {
    search() {
      this.$router.replace({
        name: 'SearchOriginalDocuments',
        query: {
          ...this.filters,
          extractionStatus: this.filters.extractionStatus ? this.filters.extractionStatus.value : '',
          startDate: this.filters.startDate ? `${moment(this.filters.startDate).format('YYYY-MM-DD')} 00:00:00` : '',
          endDate: this.filters.endDate ? `${moment(this.filters.endDate).format('YYYY-MM-DD')} 23:59:59` : '',
        },
      });
    },
    verifyEnter(e) {
      if (e.key === 'Enter') {
        this.search();
      }
    },
  },
  async mounted() {
    if (this.$route.query.fileName) {
      this.filters.fileName = this.$route.query.fileName;
    }
    if (this.$route.query.extractionStatus && this.$route.query.extractionStatus !== '' && this.$route.query.extractionStatus !== null) {
      this.filters.extractionStatus = this.extractionStatusOptions.find((opt) => opt.value === this.$route.query.extractionStatus);
    }
    if (this.$route.query.startDate && this.$route.query.startDate !== '' && this.$route.query.startDate !== null) {
      this.filters.startDate = moment(this.$route.query.startDate).toDate();
    }
    if (this.$route.query.endDate && this.$route.query.endDate !== '' && this.$route.query.endDate !== null) {
      this.filters.endDate = moment(this.$route.query.endDate).toDate();
    }
  },
};
</script>

<style>
.cont-filter {
  gap: 20px;
}

.cont-filter input {
  height: 36px !important;
}

.cont-filter select,
.cont-filter .mx-input-wrapper input {
  height: 40px !important;
}

.input-comp {
  flex: 1 1 auto;
}

.input-comp-limited {
  flex: 0 1 250px;
}

.input-comp.date {
  flex: 0 1 auto;
}

.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}

.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}

.cont-filter .icon {
  color: var(--gray-dark);
}
</style>
