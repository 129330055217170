<template>
  <div class="activity-card">
    <div class="activity-card-header flex jc-sb fgap1 mb-2">
      <div class="flex ai-c fgap1 fw-w">
        <div class="profile-img">
          <img
            :src="
              data.userWhoCreated
                ? `data:image/png;base64,${data.userWhoCreated.photo}`
                : ''
            "
            :alt="data.userWhoCreated ? data.userWhoCreated.name : ''"
          />
        </div>
        <div class="profile-info">
          <h6 class="text-bold mb-1">
            {{ data.userWhoCreated ? data.userWhoCreated.name : "-" }}
          </h6>
          <p class="mb-0">{{ data.date | moment("DD/MM/YYYY à\\s HH:mm") }}</p>
        </div>
      </div>
      <div class="actions">
        <a href="#" @click.prevent="actionsSubmenu = !actionsSubmenu">
          <dots-icon :size="24" />
        </a>

        <transition name="fade" mode="out-in">
          <div class="actions-submenu" v-if="actionsSubmenu">
            <ul>
              <li>
                <a href="#" @click.prevent="$emit('remove', data.id)"
                  >Excluir</a
                >
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>

    <div class="activity-card-content mb-4">
      <h4 class="text-bold flex ai-c fgap2 fw-w mb-2">
        {{ data.title }}
        <span class="tag">{{ data.type.name }}</span>
      </h4>
      <div class="acitivity-description" v-html="data.description"></div>
    </div>

    <div class="activity-card-files flex ai-c fgap2 fw-w">
      <div
        class="file text-bold flex ai-c fgap1"
        v-for="(file, index) in data.documents"
        :key="index"
      >
        <attachment-icon :size="24" />
        <a href="#" @click.prevent="download(file)">{{
          file.originalFilename
        }}</a>
      </div>
    </div>
    <!-- <h4 class="card-title flex ai-fe fw-w fgap2 mb-1">
      {{ data.title }}
      <attachment-icon :size="24" v-if="data.documents !== undefined && data.documents.length > 0"/>
    </h4>
    <div class="card-content">
      <p class="author">{{ data.author }}</p>
      <div class="description" v-html="data.description"></div>
    </div>
    <div class="card-dates flex ai-c fgap1">
      <calendar-icon style="color: #343A40;" />
      <p class="mb-0 text-bold">{{ data.date | moment('DD/MM/YYYY à\\s HH:mm') }}</p>
    </div> -->
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { AttachmentIcon, DotsIcon } from "@/components/crm/Icons";
import downloadjs from "downloadjs";
import Swal from "sweetalert2";

export default {
  name: "ActivityCard",
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  components: {
    AttachmentIcon,
    DotsIcon,
  },
  data() {
    return {
      actionsSubmenu: false,
    };
  },
  methods: {
    download(doc) {
      let filename = "";
      if (doc.originalFilename !== null && !doc.originalFilename) {
        filename = doc.originalFilename;
      } else {
        filename = doc.description;
      }
      this.downloadManager(doc.id, filename, doc.fileType);
    },
    downloadManager(id, name, type) {
      if (type === "application/pdf" || type === "application/octet-stream") {
        Axios.post(`${config.env.API_DEFAULT.host}/documents/download/${id}`)
          .then((response) => {
            const binaryString = window.atob(response.data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            const blob = new Blob([bytes], { type });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            link.click();
          })
          .catch((error) => {
            Swal.fire("Download de Documento", error.message, "error");
          });
      } else {
        Axios.post(
          `${config.env.API_DEFAULT.host}/documents/download/${id}`,
          null,
          { responseType: "blob" }
        )
          .then((response) => {
            downloadjs(response.data, name);
          })
          .catch((error) => {
            Swal.fire("Download de Documento", error.message, "error");
          });
      }
    },
  },
};
</script>

<style scoped>
.activity-card {
  padding: 30px 30px 15px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  color: #343a40;
}

.activity-card-header h6 {
  font-size: 16px;
  line-height: 1;
}

.activity-card-header p {
  font-size: 14px;
}

.profile-img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  background: #dee2e6;
}

.profile-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.activity-card-content h4 {
  font-size: 18px;
}

.activity-card-content h4 .tag {
  display: block;
  border-radius: 10px;
  padding: 7px 10px;
  font-size: 14px;
  background: #e6f3ff;
}

.acitivity-description {
  font-size: 16px;
}

.file {
  font-size: 14px;
}

.actions {
  position: relative;
}

.actions-submenu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.actions-submenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.actions-submenu ul a {
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

.actions-submenu ul a:hover {
  background-color: #f5f6f7;
}
</style>
