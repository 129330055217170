var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gtv columns"},[_c('div',{staticClass:"span-8"},[_c('vue-good-table',{ref:_vm.uniqueName,attrs:{"styleClass":`vgt-table vgt-table center text-sm text-table text-medium sm-pd auto-width`,"search-options":{
        enabled: _vm.searchable,
        placeholder: 'Pesquisar',
        trigger: 'enter',
      },"sort-options":{
        enabled: _vm.sortable,
      },"pagination-options":{
        enabled: _vm.pagination,
        perPage: _vm.perPageInitial,
        perPageDropdown: [1, 10, 20, 30, 40, 50, 100],
        jumpFirstOrLast: false,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próximo',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Resultados por paǵina',
        pageLabel: 'Página',
        ofLabel: 'de',
        allLabel: 'Todos',
      },"totalRows":_vm.totalRows,"compactMode":"","columns":_vm.columns,"rows":_vm.rows,"mode":"remote"},on:{"on-search":_vm.onSearch,"on-row-click":(params) => _vm.$emit('rowClick', params.row),"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("custom-row",null,{"props":props})]}}],null,true)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Nenhuma informação disponível.")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }