<template>
  <label
    :class="['v-checkbox', small ? 'small' : '']"
    :style="checked ? `background-color: ${color}2f; border: none; ` : ''"
  >
    <input
      type="radio"
      :name="name"
      :value="radioValue"
      :checked="radioValue === value ? true : false"
      @input="onInput($event.target.value)"
    />

    <span v-if="!checked" class="tx-normal">{{ label }}</span>
    <span v-else class="tx-normal tx-gray-800">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "VRadio",
  props: {
    name: {
      type: String,
      required: true,
      default: "input",
    },
    label: {
      type: String,
      required: true,
      default: "input",
    },
    value: {},
    radioValue: {
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#22c58b",
    },
  },
  data() {
    return {
      checked: false,
      emitter: false,
    };
  },
  mounted() {
    this.checkInput();
    this.$nextTick(function () {
      this.emitter = true;
    });
  },
  methods: {
    checkInput() {
      if (this.value === this.radioValue) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    onInput(e) {
      this.$emit("input", e);
      if (this.emitter) {
        this.$emit("blur", e);
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.checkInput();
      },
    },
  },
};
</script>

<style >
.v-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #e2e2e2;
  background: white;
  border-radius: 6px;

  transition: all 0.2s ease-in-out 0s;
}

.v-checkbox.small {
  height: 35px;
  padding: 0 10px;
  gap: 10px;
}

.v-checkbox input {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;

  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #6a6a6a;
  padding: 0;

  transition: all 0.2s ease-in-out 0s;
}

.v-checkbox.small input {
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
}

.v-checkbox input:checked {
  border: 6px solid #6a6a6a;
}
</style>
