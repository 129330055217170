<template>
  <div :class="{ 'no-click': noClick }">
    <table class="table table-striped mg-b-0">
      <thead>
        <tr>
          <th class="wd-300">Nome</th>
          <th class="wd-300">Email</th>
          <th class="text-right">Ação</th>
        </tr>
      </thead>
    </table>

    <perfect-scrollbar class="tbody-scroll">
      <table class="table table-striped mg-b-0">
        <tbody>
          <tr-inline
            v-for="contact in data"
            :key="contact.created"
            :row="contact"
            @save="update"
          >
            <td-inline v-model="contact.name"></td-inline>
            <td-inline v-model="contact.email" type="email"></td-inline>
            <template slot="actions">
              <a
                href="#"
                @click.prevent="remove(contact)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a"></i></div>
              </a>
            </template>
          </tr-inline>
        </tbody>
      </table>
    </perfect-scrollbar>

    <table class="table table-striped mg-b-0">
      <tfoot>
        <tr-inline class="new-item" :isNew="true" :row="newData" @save="create">
          <td-inline v-model="newData.name" placeholder="Nome"></td-inline>
          <td-inline
            v-model="newData.email"
            type="email"
            placeholder="Email"
          ></td-inline>
        </tr-inline>
      </tfoot>
    </table>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

export default {
  name: "FormModalContacts",
  components: {
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  props: ["noClick"],
  data() {
    return {
      data: [],
      newData: {
        name: "",
        email: "",
        importProcess: "",
      },
    };
  },
  methods: {
    async update(value) {
      this.submit(value, "put");
    },
    async create(value) {
      this.submit(value, "post");
    },
    async submit(value, method) {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.id}`,
        description: "Salvando dados, aguarde...",
      });

      try {
        let response;
        if (method == "put") {
          response = await Axios.put(value._links.self.href, value);
          let item = this.data.find(
            (item) => item._links.self.href === value._links.self.href
          );
          item = response.data;
          this.newData = {
            name: "",
            email: "",
            importProcess:
              (this.newData.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`),
          };
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/processContacts/`,
            value
          );
          this.data.push(response.data);
          this.newData = {
            name: "",
            email: "",
            importProcess:
              (this.newData.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`),
          };
        }
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", "", "error");
      }
    },
    async remove(value) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: `Processo ${this.process.id}`,
          description: "Processando requisição, aguarde...",
        });
        const response = await Axios.delete(value._links.self.href, value);
        const index = this.data.findIndex(
          (item) => item._links.self.href === value._links.self.href
        );
        this.data.splice(index, 1);
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", err, "error");
      }
    },

    async loadData() {
      this.newData.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/contacts/`
        );
        this.data = response.data._embedded.processContacts;
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", err, "error");
      }
    },
  },
  computed: {
    ...mapState({ process: (state) => state.ProcessStore.activeProcess }),
  },
  created() {
    this.model = { ...this.process };
    this.loadData();
  },
  watch: {
    process() {
      this.model = { ...this.process };
      this.loadData();
    },
  },
};
</script>

<style scoped>
.tbody-scroll {
  max-height: 450px;
}

.no-click {
  pointer-events: none;
}
</style>
