<template>
  <section class="todoapp" :class="{ 'no-click': noClick }">
    <input
      class="new-todo"
      autofocus
      autocomplete="off"
      placeholder="O que precisa ser feito?"
      v-model="newTodo"
      @keyup.enter="addTodo"
    />

    <section class="main" v-show="todos.length" v-cloak>
      <input
        id="toggle-all"
        class="toggle-all"
        type="checkbox"
        v-model="allDone"
      />
      <label for="toggle-all"></label>
      <ul class="todo-list">
        <li
          v-for="todo in filteredTodos"
          class="todo"
          @click="doneEdit(todo)"
          :key="todo.id"
          :class="{ completed: todo.completed, editing: todo == editedTodo }"
        >
          <div class="view">
            <input
              class="toggle"
              type="checkbox"
              v-model="todo.completed"
              @change="completeTodo(todo)"
            />
            <label @dblclick="editTodo(todo)">{{ todo.description }}</label>
            <button class="destroy" @click="removeTodo(todo)"></button>
          </div>
          <input
            class="edit"
            type="text"
            v-model="todo.description"
            v-todo-focus="todo == editedTodo"
            @blur="doneEdit(todo)"
            @keyup.enter="doneEdit(todo)"
            @keyup.esc="cancelEdit(todo)"
          />
        </li>
      </ul>
    </section>

    <footer
      class="footer"
      v-show="todos !== undefined && todos.length > 0"
      v-cloak
    >
      <span class="todo-count">
        <strong>{{ remaining }}</strong> {{ remaining | pluralize }}
      </span>

      <ul class="filters">
        <li>
          <a
            href="#"
            @click="visibility = 'all'"
            :class="{ selected: visibility == 'all' }"
          >
            Todas
          </a>
        </li>
        <li>
          <a
            href="#"
            @click="visibility = 'active'"
            :class="{ selected: visibility == 'active' }"
          >
            Ativas
          </a>
        </li>
        <li>
          <a
            href="#"
            @click="visibility = 'completed'"
            :class="{ selected: visibility == 'completed' }"
          >
            Concluídas
          </a>
        </li>
      </ul>

      <!-- <button class="clear-completed" @click="removeCompleted" > Limpar </button> -->
    </footer>
  </section>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import {mapState} from "vuex";

// visibility filters
const filters = {
  all(todos) {
    return todos;
  },
  active(todos) {
    return todos.filter((todo) => !todo.completed);
  },
  completed(todos) {
    return todos.filter((todo) => todo.completed);
  },
};

export default {
  name: "todo-list",
  props: ["data", "noClick"],
  components: {},
  data() {
    return {
      task: null,
      todos: [],
      newTodo: "",
      editedTodo: null,
      visibility: "all",
    };
  },
  methods: {
    async addTodo() {
      const value = this.newTodo && this.newTodo.trim();

      if (!value) return;

      this.task = {
        type: "IMPORT_PROCESS",
        description: value,
        estimatedDate: null,
        completed: false,
        completedDate: null,
        importProcess: `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`,
      };

      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Tarefas",
          description: "Salvanda a tarefa, aguarde...",
        });
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/tasks/`,
          this.task
        );
        this.todos.push({...response.data});
      } catch (err) {
        this.$swal(
          "Ops",
          `Houve algum erro ao tentar salvar a tarefa: ${err}`,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }

      this.newTodo = "";
    },

    async removeTodo(todo) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Tarefas",
          description: "Excluindo a tarefa, aguarde...",
        });
        const response = await Axios.delete(
          `${config.env.API_DEFAULT.host}/tasks/${todo.id}`
        );
        this.todos.splice(this.todos.indexOf(todo), 1);
      } catch (err) {
        this.$swal(
          "Ops",
          `Houve algum erro ao tentar excluir a tarefa: ${err}`,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }
    },

    editTodo(todo) {
      this.beforeEditCache = todo;
      this.editedTodo = todo;
    },

    async doneEdit(todo) {
      if (!this.editedTodo) return;
      this.editedTodo = null;

      todo.description = todo.description.trim();
      if (!todo.description) this.removeTodo(todo);

      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Tarefas",
          description: "Salvanda a tarefa, aguarde...",
        });
        const response = await Axios.put(todo._links.self.href, todo);
      } catch (err) {
        this.$swal(
          "Ops",
          `Houve algum erro ao tentar salvar a tarefa: ${err}`,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }
    },
    async completeTodo(todo) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Tarefas",
          description: "Salvanda a tarefa, aguarde...",
        });
        const response = await Axios.patch(todo._links.self.href, {
          completed: todo.completed,
          completedDatetime: this.$moment(new Date()).format(
            "YYYY-MM-DD[T]hh:mm:ss"
          ),
        });
      } catch (err) {
        this.$swal(
          "Ops",
          `Houve algum erro ao tentar salvar a tarefa: ${err}`,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }
    },
    async load() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Tarefas",
          description: "Salvanda a tarefa, aguarde...",
        });
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/tasks`
        );
        this.todos = response.data._embedded.tasks;
      } catch (err) {
        this.$swal(
          "Ops",
          `Houve algum erro ao tentar salvar a tarefa: ${err}`,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }
    },

    cancelEdit(todo) {
      this.editedTodo = null;
      todo = this.beforeEditCache;
    },

    removeCompleted() {
      this.todos = filters.active(this.todos);
    },
  },
  watch: {
    process() {
      this.model = {...this.process};
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    filteredTodos() {
      return filters[this.visibility](this.todos);
    },
    remaining() {
      return filters.active(this.todos).length;
    },
    allDone: {
      get() {
        return this.remaining === 0;
      },
      set(value) {
        this.todos.forEach((todo) => {
          todo.completed = value;
        });
      },
    },
  },
  filters: {
    pluralize(n) {
      return n === 1 ? "item restante" : "itens restantes";
    },
  },
  // a custom directive to wait for the DOM to be updated
  // before focusing on the input field.
  // http://vuejs.org/guide/custom-directive.html
  directives: {
    "todo-focus": function (el, binding) {
      if (binding.value) {
        el.focus();
      }
    },
  },
  created() {
    this.model = {...this.process};
    this.load();
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 100%;
  vertical-align: baseline;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: 0;
}

.hidden {
  display: none;
}

.todoapp {
  background: #fff;
  margin: 0px;
  width: 500px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
}

.todoapp input::-webkit-input-placeholder {
  color: #e6e6e6;
}

.todoapp input::-moz-placeholder {
  color: #e6e6e6;
}

.todoapp input::placeholder {
  color: #e6e6e6;
}

.new-todo,
.edit {
  position: relative;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-weight: inherit;
  border: 0;
  color: inherit;
  padding: 6px;
  border: 1px solid #999;
  box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-todo {
  padding: 16px 16px 16px 60px;
  border: none;
  background: rgba(0, 0, 0, 0.003);
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.03);
}

.main {
  position: relative;
  z-index: 2;
  border-top: 1px solid #e6e6e6;
}

.toggle-all {
  width: 1px;
  height: 1px;
  border: none;
  /* Mobile Safari */
  opacity: 0;
  position: absolute;
  right: 100%;
  bottom: 100%;
}

.toggle-all + label {
  width: 60px;
  height: 34px;
  font-size: 0;
  position: absolute;
  top: -52px;
  left: -13px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.toggle-all + label:before {
  content: "❯";
  font-size: 22px;
  color: #e6e6e6;
  padding: 10px 27px 10px 27px;
}

.toggle-all:checked + label:before {
  color: #737373;
}

.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.todo-list li {
  position: relative;
  border-bottom: 1px solid #ededed;
}

.todo-list li:last-child {
  border-bottom: none;
}

.todo-list li.editing {
  border-bottom: none;
  padding: 0;
}

.todo-list li.editing .edit {
  display: block;
  width: calc(100% - 43px);
  padding: 12px 16px;
  margin: 0 0 0 43px;
}

.todo-list li.editing .view {
  display: none;
}

.todo-list li .toggle {
  text-align: center;
  width: 40px;
  /* auto, since non-WebKit browsers doesn't support input styling */
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border: none;
  /* Mobile Safari */
  -webkit-appearance: none;
  appearance: none;
}

.todo-list li .toggle {
  opacity: 0;
}

.todo-list li .toggle + label {
  /*

Firefox requires `#` to be escaped - https://bugzilla.mozilla.org/show_bug.cgi?id=922433

IE and Edge requires *everything* to be escaped to render, so we do that instead of just the `#` - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7157459/
	*/
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23ededed%22%20stroke-width%3D%223%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center left;
}

.todo-list li .toggle:checked + label {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23bddad5%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%235dc2af%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22/%3E%3C/svg%3E");
}

.todo-list li label {
  word-break: break-all;
  padding: 15px 15px 15px 60px;
  display: block;
  line-height: 1.2;
  transition: color 0.4s;
}

.todo-list li.completed label {
  color: #d9d9d9;
  text-decoration: line-through;
}

.todo-list li .destroy {
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  font-size: 30px;
  color: #cc9a9a;
  margin-bottom: 11px;
  transition: color 0.2s ease-out;
}

.todo-list li .destroy:hover {
  color: #af5b5e;
}

.todo-list li .destroy:after {
  content: "×";
}

.todo-list li:hover .destroy {
  display: block;
}

.todo-list li .edit {
  display: none;
}

.todo-list li.editing:last-child {
  margin-bottom: -1px;
}

.footer {
  color: #777;
  padding: 10px 15px;
  height: 40px;
  text-align: center;
  border-top: 1px solid #e6e6e6;
}

.footer:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 8px 0 -3px #f6f6f6,
  0 9px 1px -3px rgba(0, 0, 0, 0.2), 0 16px 0 -6px #f6f6f6,
  0 17px 2px -6px rgba(0, 0, 0, 0.2);
}

.todo-count {
  float: left;
  text-align: left;
}

.todo-count strong {
  font-weight: 300;
}

.filters {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  left: 0;
}

.filters li {
  display: inline;
}

.filters li a {
  color: inherit;
  margin: 3px;
  padding: 3px 7px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 3px;
}

.filters li a:hover {
  border-color: rgba(175, 47, 47, 0.1);
}

.filters li a.selected {
  border-color: rgba(175, 47, 47, 0.2);
}

.clear-completed,
html .clear-completed:active {
  float: right;
  position: relative;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.clear-completed:hover {
  text-decoration: underline;
}

.info {
  margin: 65px auto 0;
  color: #bfbfbf;
  font-size: 10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-align: center;
}

.info p {
  line-height: 1;
}

.info a {
  color: inherit;
  text-decoration: none;
  font-weight: 400;
}

.info a:hover {
  text-decoration: underline;
}

/*
	Hack to remove background from Mobile Safari.
	Can't use it globally since it destroys checkboxes in Firefox
*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .toggle-all,
  .todo-list li .toggle {
    background: none;
  }

  .todo-list li .toggle {
    height: 40px;
  }
}

.no-click {
  pointer-events: none;
}
</style>
