<template>
  <div class="form-layout form-layout-2">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Numerário do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Gerenciamento de
          Numerário do Processo.
        </p>
      </div>
      <div>
        <a href="#" class="btn btn-secondary btn-sm" @click="printAppointment"
        ><i class="icon ion-document-text tx-22"></i> DEMONSTRATIVO
        </a>
        <a href="" class="btn btn-danger btn-sm"
        ><i class="icon ion-email tx-22"></i> ENVIAR P/ CLIENTE</a
        >
        <a href="" class="btn btn-info btn-sm"
        ><i class="icon ion-cash tx-22"></i> GERAR FINANCEIRO
        </a>
        <a href="" class="btn btn-primary btn-sm"
        ><i class="icon ion-archive tx-22"></i> IMPORTAR DI
        </a>
      </div>
    </div>

    <div class="row no-gutters">
      <table class="table table-striped mg-b-0">
        <thead>
        <tr>
          <th class="tx-center wd-250">Descrição</th>
          <th class="tx-center">Data</th>
          <th class="tx-center">Previsto</th>
          <th class="tx-center">Data</th>
          <th class="tx-center">Realizado</th>
          <th class="tx-center">Saldo</th>
          <th class="tx-center">Variação %</th>
          <th class="tx-center">
            Ações

            <a
              href="#"
              @click.prevent="removeAll"
              class="btn btn-icon rounded-circle"
            >
              <div><i class="icon ion-trash-a tx-danger tx-24"></i></div>
            </a>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr-inline
          v-for="item in data"
          :key="item.id"
          :row="item"
          @save="update"
        >
          <td-inline
            v-model="item.typeOfDocument"
            placeholder="Descrição"
            type="v-select"
            :options="categories"
            label="shortname"
            searchReturnKey="description"
          />
          <td-inline
            icon="fa-calendar"
            v-model="item.estimatedValueDate"
            type="date"
            placeholder="Vencimento"
          >
          </td-inline>
          <td-inline v-model="item.estimatedValue" type="money"></td-inline>
          <td-inline
            icon="fa-calendar"
            v-model="item.paidValueDate"
            type="date"
            placeholder="Pagamento"
            inputClass="tx-right"
          >
          </td-inline>
          <td-inline v-model="item.paidValue" type="money"></td-inline>
          <td-inline
            readonly
            :value="item.estimatedValue - item.paidValue"
            type="money"
          ></td-inline>
          <td-inline
            readonly
            :value="
                variation(
                  item.paidValue,
                  item.estimatedValue,
                  item.paidValueDate
                )
              "
            inputClass="tx-right"
          ></td-inline>

          <template slot="actions">
            <a
              href="#"
              class="btn btn-danger btn-icon rounded-circle"
              @click.prevent="remove(item)"
            >
              <div><i class="icon ion-trash-a"></i></div>
            </a>
          </template>
        </tr-inline>

        <tr-inline
          class="new-item"
          :isNew="true"
          :row="newData"
          @save="create"
        >
          <td-inline
            v-model="newData.typeOfDocument"
            placeholder="Descrição"
            type="v-select"
            :options="categories"
            label="shortname"
            searchReturnKey="description"
          />
          <td-inline
            icon="fa-calendar"
            v-model="newData.estimatedValueDate"
            type="date"
            placeholder="Vencimento"
          >
          </td-inline>
          <td-inline
            v-model="newData.estimatedValue"
            type="money"
          ></td-inline>
          <td-inline
            icon="fa-calendar"
            v-model="newData.paidValueDate"
            type="date"
            placeholder="Pagamento"
          >
          </td-inline>
          <td-inline v-model="newData.paidValue" type="money"></td-inline>
          <td-inline
            readonly
            :value="newData.estimatedValue - newData.paidValue"
            type="money"
          ></td-inline>
          <td-inline
            readonly
            :value="
                variation(
                  newData.paidValue,
                  newData.estimatedValue,
                  newData.paidValueDate
                )
              "
            inputClass="tx-right"
          ></td-inline>
        </tr-inline>
        </tbody>
        <tfoot>
        <tr>
          <th colspan="2" class="tx-right">Valores Totais</th>
          <th class="tx-right">
            R$ {{ estimatedValueTotal | numeralFormat("0,0.00") }}
          </th>
          <th></th>
          <th class="tx-right">
            R$ {{ new Number(paidValueTotal) | numeralFormat("0,0.00") }}
          </th>
          <th class="tx-right">
            R$
            {{
              (new Number(estimatedValueTotal) - new Number(paidValueTotal))
                | numeralFormat("0,0.00")
            }}
          </th>
          <th class="tx-right">
            {{
              variation(
                paidValueTotal,
                estimatedValueTotal,
                estimatedValueTotal
              )
            }}
          </th>
          <th></th>
        </tr>
        </tfoot>
      </table>
    </div>

    <printable-appointment
      id="printAppointment"
      :model="process"
      :items="data"
      :exchangeRateHistories="exchangeRateHistories"
      :estimatedValueTotal="estimatedValueTotal"
      :paidValueTotal="paidValueTotal"
      :coinsMap="coinsMap"
      :coinsMapURI="coinsMapURI"
      style="display: none"
    />

    <hr class="mg-b-25"/>

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Taxas de Conversão de Câmbio</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Gerenciamento de
          Numerário do Processo.
        </p>
      </div>
      <div></div>
    </div>

    <div class="row no-gutters">
      <table class="table table-striped mg-b-0">
        <thead>
        <tr>
          <th>Data</th>
          <th>Moeda</th>
          <th>Valor Taxa</th>
          <th class="text-right">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr-inline
          v-for="item in exchangeRateHistories"
          :key="item.id"
          :row="item"
          @save="updateExchange"
        >
          <td-inline
            icon="fa-calendar"
            v-model="item.date"
            type="date"
            placeholder="Escolha uma data"
          >
          </td-inline>
          <td-inline
            v-model="item.coin.id"
            type="select"
            :options="[...coinsMap]"
          />
          <td-inline
            v-model="item.value"
            type="money"
            icon="fa-money"
            :money="{
                decimal: ',',
                thousands: '.',
                precision: 7,
                masked: false,
              }"
          ></td-inline>
          <template slot="actions">
            <a
              href="#"
              @click.prevent="removeExchange(item)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div><i class="icon ion-trash-a"></i></div>
            </a>
          </template>
        </tr-inline>
        <tr-inline
          class="new-item"
          :isNew="true"
          :row="newExchange"
          @save="createExchange"
        >
          <td-inline
            v-model="newExchange.date"
            type="date"
            icon="fa-calendar"
            placeholder="Data"
          />
          <td-inline
            v-model="newExchange.coin"
            type="select"
            :options="[...coinsMapURI]"
            placeholder="Selecione uma moeda"
          />
          <td-inline
            v-model="newExchange.value"
            type="money"
            icon="fa-money"
            :money="{
                decimal: ',',
                thousands: '.',
                precision: 7,
                masked: false,
              }"
            placeholder="Valor da Taxa"
          />
        </tr-inline>
        </tbody>
      </table>
    </div>

    <hr class="mg-b-25"/>

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Dados Bancários do Numerário</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Dados da conta
          bancária que o cliente deve depositar o valor do numerário.
        </p>
      </div>
      <div>
        <a href="" class="btn btn-primary btn-sm"
        ><i class="icon ion-archive tx-22"></i> IMPORTAR DADOS BANCÁRIOS DA
          MATRIZ
        </a>
      </div>
    </div>

    <form id="formAppointments">
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label"> Banco </label>
            <div class="input-group-solved">
              <input
                class="form-control"
                type="text"
                name="processAppointmentPaymentBank"
                v-model="model.processAppointmentPaymentBank"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label"> Agência </label>
            <div class="input-group-solved">
              <input
                class="form-control"
                type="text"
                name="processAppointmentPaymentAgencyNumber"
                v-model="model.processAppointmentPaymentAgencyNumber"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-t--1">
            <label class="form-control-label"> Número </label>
            <div class="input-group-solved">
              <input
                class="form-control"
                type="text"
                name="processAppointmentPaymentAccountNumber"
                v-model="model.processAppointmentPaymentAccountNumber"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1">
            <label class="form-control-label"> CPF/CNPJ </label>
            <div class="input-group-solved">
              <input
                class="form-control"
                type="text"
                name="processAppointmentPaymentFederalID"
                v-model="model.processAppointmentPaymentFederalID"
              />
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group bd-t-0-force mg-md-t--1">
            <label class="form-control-label"> Favorecido </label>
            <div class="input-group-solved">
              <input
                class="form-control"
                type="text"
                name="processAppointmentPaymentName"
                v-model="model.processAppointmentPaymentName"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="mg-b-25"/>

      <div class="report-summary-header mg-b-5 mg-t-0">
        <div>
          <h4 class="tx-inverse mg-b-3">Informações Complementares</h4>
        </div>
        <div></div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group mg-md-t--1">
            <label class="form-control-label">
              Digite as informações complementares do numerário abaixo
            </label>
            <div class="input-group-solved">
              <textarea
                class="form-control"
                v-model="model.processAppointmentObservations"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import {mapState} from "vuex";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import PrintableAppointment from "@/views/operational/imp/printable/Printable.Appointment.vue";
import Swal from "sweetalert2";

export default {
  name: "FormAppointment",
  props: [],
  components: {
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
    "printable-appointment": PrintableAppointment,
  },
  data() {
    return {
      model: {},
      initialModel: {},
      data: [],
      categories: [],
      newData: {
        typeOfDocument: {},
        type: "NUMERARIO",
        typeOfMovimentation: "APPOINTMENT",
        description: "",
        estimatedValue: 0,
        estimatedValueDate: null,
        paidValue: 0,
        manual: true,
        paidValueDate: null,
        process: "",
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      exchangeRateHistories: [],
      newExchange: {
        date: "",
        coin: "",
        value: "",
        process: "",
      },
    };
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      coins: (state) => state.ProcessStore.coins,
      movimentTermsCATEGORY: (state) =>
        state.FinancesStore.movimentTermsCATEGORY,
    }),
    options() {
      return [
        "blur",
        (e) => {
          setTimeout(() => {
            this.diffObjects();
          }, 200);
        },
        true,
      ];
    },
    coinsMap() {
      return this.coins.map((item) => ({
        label: `${item.code} - (${item.swiftCode}) ${item.name}`,
        value: item.id,
      }));
    },
    coinsMapURI() {
      return this.coins.map((item) => ({
        label: `${item.code} - (${item.swiftCode}) ${item.name}`,
        value: item._links.self.href,
      }));
    },
    estimatedValueTotal() {
      return this.data.reduce(
        (value, item) => value + new Number(item.estimatedValue),
        0
      );
    },

    paidValueTotal() {
      return this.data.reduce(
        (value, item) => value + new Number(item.paidValue),
        0
      );
    },
  },
  methods: {
    async diffObjects() {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
      });

      if (response) this.initialModel = {...this.model};
    },
    printAppointment() {
      this.$htmlToPaper("printAppointment");
    },
    async updateExchange(value) {
      this.submitExchange(value, "put");
    },
    async createExchange(value) {
      const response = await this.submitExchange(value, "post");
    },
    async submitExchange(value, method) {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.id}`,
        description: "Salvando dados, aguarde...",
      });
      const cvalue = {...value};
      try {
        cvalue.date = moment(cvalue.date).format("YYYY-MM-DD[T]hh:mm:ss");

        let response;
        if (method == "put") {
          response = await Axios.put(cvalue._links.self.href, value);
          const index = this.exchangeRateHistories.findIndex(
            (item) => item._links.self.href === cvalue._links.self.href
          );
          this.exchangeRateHistories.splice(index, 1, response.data);
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/exchangeRateHistories/`,
            value
          );
          // this.exchangeRateHistories.push(response.data)
          this.loadData();
        }

        this.newExchange = {
          date: "",
          coin: "",
          value: "",
          process: `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`,
        };
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        return true;
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", "Aconteceu um erro", "error");
        return false;
      }
    },
    async removeExchange(value) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: `Processo ${this.process.id}`,
          description: "Processando requisição, aguarde...",
        });

        const response = await Axios.delete(value._links.self.href, value);
        const index = this.exchangeRateHistories.findIndex(
          (item) => item._links.self.href === value._links.self.href
        );
        this.exchangeRateHistories.splice(index, 1);

        this.$store.commit("setLoading", {show: false});
      } catch (err) {
        this.$store.commit("setLoading", {show: false});
        Swal.fire("Ops", "Aconteceu um erro!", "error");
        console.error(err);
      }
    },
    variation(paidValue = 0, estimatedValue = 0, paidValueDate) {
      if (estimatedValue !== null && estimatedValue > 0) {
        const variation = (
          ((parseFloat(paidValue) - parseFloat(estimatedValue)) * 100) /
          parseFloat(estimatedValue)
        ).toFixed(2);
        return isNaN(variation) ? "-" : `${variation}%`;
      }
      return "-";
    },
    async update(value) {
      this.submit(value, "put");
    },
    async create(value) {
      this.submit(value, "post");
    },
    async submit(value, method) {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.id}`,
        description: "Salvando dados, aguarde...",
      });

      const data = value;
      data.typeOfDocument.shortname = `${data.typeOfDocument.description.substring(
        0,
        20
      )}...`;

      delete value.typeOfDocument.createdBy;
      delete value.typeOfDocument.created;
      delete value.typeOfDocument.modifiedBy;
      delete value.typeOfDocument.modified;
      delete value.typeOfDocument.enabled;
      delete value.typeOfDocument.enabledToExpense;
      delete value.typeOfDocument.enabledToIncome;
      delete value.typeOfDocument.enabledToEvent;
      value.typeOfDocument = data.typeOfDocument;
      value.manual = true;

      if (value.estimatedValueDate !== null) {
        value.estimatedValueDate = moment(value.estimatedValueDate).format(
          "YYYY-MM-DD[T]hh:mm:ss"
        );
      }

      // if (value.paidValueDate !== '') {
      //     value.paidValueDate = moment().format('YYYY-MM-DD[T]hh:mm:ss')
      // }

      try {
        let response;
        if (method == "put") {
          response = await Axios.put(value._links.self.href, value);
          let item = this.data.find(
            (item) => item._links.self.href === value._links.self.href
          );
          item = response.data;
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/processAppointmentItems/`,
            value
          );
          this.data.push(response.data);
        }

        (this.newData = {
          manual: true,
          typeOfDocument: {},
          type: "NUMERARIO",
          typeOfMovimentation: "APPOINTMENT",
          description: "",
          estimatedValue: 0,
          estimatedValueDate: null,
          paidValue: 0,
          paidValueDate: null,
          process: `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`,
        }),
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", "", "error");
        console.error(err);
      }
    },
    remove(value) {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Se você excluir o item ${value.description}, não será possível recuperá-lo para uso posterior.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((willContinue) => {
        if (willContinue) {
          try {
            this.$store.commit("setLoading", {
              show: true,
              label: `Processo ${this.process.id}`,
              description: "Processando requisição, aguarde...",
            });

            Axios.delete(value._links.self.href, value).then((response) => {
              const index = this.data.findIndex(
                (item) => item._links.self.href === value._links.self.href
              );
              this.data.splice(index, 1);
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              });
            });
          } catch (err) {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            // Swal.fire('Ops', err, "error");
            console.error(err);
          }
        }
      });
    },

    async removeAll() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: `Processo ${this.process.id}`,
          description: "Processando requisição, aguarde...",
        });
        const response = await Axios.delete(value._links.self.href, value);
        const index = this.data.findIndex(
          (item) => item._links.self.href === value._links.self.href
        );
        this.data.splice(index, 1);
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", err, "error");
        console.error(err);
      }
    },

    async loadData() {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.idetification}`,
        description: "Carregando os itens do numerário...",
      });

      this.newData.process = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;
      this.newExchange.process = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;

      try {
        if (!this.coins.length > 0) {
          const coins = await Axios.get(`${config.env.API_DEFAULT.host}/coins`);
          this.$store.commit(
            "ProcessStore/SET_COINS",
            coins.data._embedded.coins
          );
        }

        const exchanges = await Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/exchangeRateHistory`
        );
        this.exchangeRateHistories =
          exchanges.data._embedded.exchangeRateHistories;

        Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/appointments/`
        ).then((response) => {
          const list = response.data._embedded.processAppointmentItems.filter(
            (item) => item.type == "NUMERARIO"
          );

          list.forEach((item) => {
            const newItem = {
              type: item.type,
              typeOfMovimentation: item.typeOfMovimentation,
              description: item.description,
              estimatedValue:
                item.estimatedValue !== null ? item.estimatedValue : 0,
              estimatedValueDate:
                item.estimatedValueDate !== null
                  ? item.estimatedValueDate
                  : null,
              paidValue: item.paidValue !== null ? item.paidValue : 0,
              paidValueDate:
                item.paidValueDate !== null ? item.paidValueDate : null,
              manual: item.manual !== null ? item.manual : "false",
              typeOfDocument: item.typeOfDocument,
              _links: item._links,
            };

            this.data.push(newItem);
          });

          // this.data = response.data._embedded.processAppointmentItems.filter(item => item.type == "NUMERARIO")
        });

        this.$store.commit("setLoading", {show: false});
      } catch (err) {
        this.$store.commit("setLoading", {show: false});
        Swal.fire("Ops", err, "error");
        console.error(err);
      }
    },
  },
  created() {
    this.model = {...this.process};
    this.initialModel = {...this.process};
    this.loadData();
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("formAppointments");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.categories = this.movimentTermsCATEGORY;
  },
  watch: {
    movimentTermsCATEGORY() {
      this.categories = this.movimentTermsCATEGORY;
    },
    process() {
      this.model = {...this.process};
    },
  },
};
</script>

<style scoped></style>
