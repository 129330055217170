<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'operational-steps-list' }"
              >Passos de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
              <p class="mg-b-0">
                Foram encontrados {{ data.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas
              </p>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'operational-steps-form',
                  params: { action: 'create' },
                }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <v-client-table :columns="columns" :data="data" :options="options">
          <template slot="enabled" slot-scope="props">
            <div class="enabled">
              <span v-if="props.row.enabled" class="badge badge-success"
                >SIM</span
              >
              <span v-if="!props.row.enabled" class="badge badge-danger"
                >NÃO</span
              >
            </div>
          </template>
          <template slot="color" slot-scope="props">
            <template v-if="props.row.textColor === '#000000'">
              <span
                class="badge"
                :style="
                  'background-color: ' + props.row.color + '; color: #000000;'
                "
              >
                Cor de Seleção
              </span>
            </template>
            <template v-else>
              <span
                class="badge"
                :style="
                  'background-color: ' +
                  props.row.color +
                  '; color: #FFFFFF; padding: 10px'
                "
              >
                Cor de Seleção
              </span>
            </template>
          </template>
          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'operational-steps-form',
                params: { action: 'update', id: props.row.id },
              }"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div>
                <i class="icon ion-edit"></i>
              </div>
            </router-link>
            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div>
                <i class="icon ion-trash-a"></i>
              </div>
            </a>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";

export default {
  name: "bankAccounts-list",

  data() {
    return {
      modelTitle: "Passo de Processo",
      mainTitle: "Listagem de Passos de Processos",
      columns: [
        "id",
        "enabled",
        "code",
        "description",
        "color",
        "position",
        "actions",
      ],
      data: new Array(),
      options: {
        listColumns: {
          enabled: [
            {
              id: true,
              text: "Sim",
            },
            {
              id: false,
              text: "Não",
            },
          ],
        },
        headings: {
          id: "ID",
          enabled: "Habilitado",
          code: "Código",
          description: "Descrição",
          color: "Cor",
          position: "Ordem",
          action: "Ações",
        },
        sortable: ["id", "enabled", "code", "description", "color", "position"],
        filterable: [
          "id",
          "enabled",
          "code",
          "description",
          "color",
          "position",
        ],

        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "{column}",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 25,
        perPageValues: [5, 10, 25, 50, 100, 500],
      },
    };
  },
  methods: {
    loadList() {
      Axios.get(`${config.env.API_DEFAULT.host}/processSteps`)
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          this.$swal(
            this.modelTitle,
            " Não foi possível carregar listagem.",
            "error"
          );
        });
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    deleteModel(row) {
      this.$swal({
        title: "Você tem certeza?",
        text: `Se você excluir a ${this.modelTitle}, não será possível recuperá-la para uso posterior.`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          Axios.delete(`${config.env.API_DEFAULT.host}/processSteps/${row.id}`)
            .then((response) => {
              this.$swal(
                this.modelTitle,
                `${this.modelTitle} foi removida c/ sucesso!`,
                "success"
              );
              this.loadList();
            })
            .catch((error) => {
              if (error.response.status === 409)
                this.$swal(
                  this.modelTitle,
                  `Não foi possível excluir a ${this.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                  "error"
                );
              else this.$swal(this.modelTitle, error.message, "error");
            });
        }
      });
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>
