<template>
  <label
    :class="[
      'v-checkbox',
      small ? 'small' : ''
    ]"
    :style="checked ? `background-color: ${color}2f; border: none;` : ``"
    @mouseenter="inputFocus"
    @mouseleave="inputBlur"
  >
    <input
      type="checkbox"
      :name="name"
      :checked="value"
      @input="handleInput($event.target.checked)"
    >
    <span v-if="!checked" class="tx-normal">{{ label }}</span>
    <span v-else class="tx-normal tx-gray-700">{{ label }}</span>

    <div
      v-if="hint.length > 0"
      :class="[
        'hint',
        hintActive ? 'active' : ''
      ]"
    >
      {{ hint }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    name: {
      type: String,
      required: true,
      default: 'input',
    },
    label: {
      type: String,
      required: true,
      default: 'input',
    },
    value: {},
    values: {
      type: Object,
      default: () => ({
        checked: true,
        unchecked: false,
      }),
    },
    hint: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#22c58b',
    },
  },
  data() {
    return {
      checked: false,
      hintActive: false,
      emitter: false,
    };
  },
  mounted() {
    this.handleInput(this.value);
    this.$nextTick(function () {
      this.emitter = true;
    });
  },
  methods: {
    handleInput(value) {
      if (value === true) {
        this.checked = true;
        this.$emit('input', this.values.checked);
        if (this.emitter) {
          this.$emit('blur', this.values.checked);
        }
      } else {
        this.checked = false;
        this.$emit('input', this.values.unchecked);
        if (this.emitter) {
          this.$emit('blur', this.values.unchecked);
        }
      }
    },
    inputFocus() {
      this.hintActive = true;
    },
    inputBlur() {
      this.hintActive = false;
    },
  },
};
</script>

<style>
  .v-checkbox {
    display: flex;
    align-items: center;
    gap: 20px;
     height: 40px;
    padding: 0 15px;
    border: 1px solid #e2e2e2;
    background: white;
    border-radius: 6px;
    transition: all .2s ease-in-out 0s;
    position: relative;
  }

  .v-checkbox.small {
    height: 35px;
    padding: 0 10px;
    gap: 10px;
    font-family: inherit;
    font-size: inherit;
  }

  .v-checkbox input {
    appearance: none;
    --webkit-appearance: none;
    --moz-appearance: none;

    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 50%;
    border: 1px solid #6a6a6a;

    transition: all .2s ease-in-out 0s;
  }

  .v-checkbox.small input {
    width: 15px;
    height: 15px;
    flex: 0 0 15px;
  }

  .v-checkbox input:checked {
    border: 6px solid #6a6a6a;
  }

  .v-checkbox .hint {
    transform: translate3d(0, -30px, 0);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .2s ease-in-out 0s;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    z-index: 99;
    border: 1px solid #e2e2e2;
  }

  .v-checkbox .hint.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
</style>
