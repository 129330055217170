<template>
  <div class="crm-page">
    <div class="contact-container">
      <div class="light-bg p-3 bd-b pd-2">
        <div :class="['flex ai-c jc-sb fw-w fgap2']">
          <div>
            <h4 class="mb-0">PESQUISA NPS</h4>
            <p class="mb-0">Listagem de pesquisas NPS cadastradas</p>
          </div>
          <div>
            <button
              type="button"
              @click.prevent="npsModalOpen = !npsModalOpen"
              class="plus-button"
            >
              <plus-icon color="#ffffff"/>
            </button>
          </div>
        </div>
      </div>

      <div class="nps-container">
        <v-table
          uniqueName="npsTable"
          :columns="tableColumns"
          :rows="tableData.content"
          :totalRows="tableData.totalElements"
          @onPageChange="getNps"
          @onSortChange="getNps"
          @onPerPageChange="getNps"
          @onSearch="getNps"
        >
          <!-- @rowClick="openNps" -->
          <template #custom-row="{ props }">
            <span v-if="props.column.field === 'questions'">
              {{ props.row.questions.length }}
            </span>
            <span v-else-if="props.column.field === 'answerLogs'">
              {{ props.row.answerLogs.length }}
            </span>
            <span v-else-if="props.column.field === 'actions'">
              <div class="flex ai-c fgap1">
                <button
                  class="crm-button sm"
                  @click.prevent="openSendModal(props.row.id)"
                >
                  Enviar
                </button>
                <button
                  class="crm-button sm"
                  @click.prevent="getNpsAnswers(props.row.id)"
                >
                  Respostas
                </button>
                <button
                  class="crm-button sm"
                  @click.prevent="openNps(props.row.id)"
                >
                  Editar
                </button>
                <button
                  v-if="props.row.answerLogs.length <= 0"
                  class="crm-button sm danger"
                  @click.prevent="destroyNps(props.row.id)"
                >
                  Remover
                </button>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </v-table>
      </div>
    </div>

    <!-- NPS Modal -->
    <v-modal
      :width="900"
      :title="npsData && npsData.id ? 'Atualizar NPS' : 'Adicionar NPS'"
      description=""
      :handler="npsModalOpen"
      @requestClose="closeAddModal"
    >
      <template #content>
        <div class="columns md-gap mb-4">
          <div class="span-3">
            <v-input name="name" label="Nome" v-model="npsData.name"></v-input>
          </div>

          <div class="span-3">
            <v-input
              type="number"
              name="npsYear"
              label="Ano"
              v-model="npsData.npsYear"
              :mask="['####']"
            ></v-input>
          </div>

          <div class="span-8">
            <label>Informações do NPS</label>
            <text-editor v-model="npsData.description"/>
          </div>
        </div>

        <hr class="divider"/>

        <div class="columns md-gap" v-if="npsData && npsData.id">
          <div class="span-8">
            <h3 class="text-dark">Selecione um componente</h3>
            <hr class="divider"/>
          </div>

          <div class="span-4">
            <ul class="components-list">
              <li v-for="field in inputFields" :key="field.type">
                <a href="#" @click.prevent="addQuestion(field.type)">
                  <PlusIcon/>
                  <div>
                    <span class="text-dark">{{ field.name }}</span>
                    <p class="text-sm mb-0">Clique para adicionar</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="span-4">
            <draggable
              v-model="npsData.questions"
              :move="onMove"
              @change="onDragChange"
              group="orderPosition"
            >
              <div
                class="columns md-gap form-component"
                v-for="question in npsData.questions"
                :key="question.id"
                style="align-items: start"
              >
                <div class="span-1 flex ai-c fgap1">
                  <DragIcon :size="16" class="cursor-pointer"/>
                  <div class="index-indicator">
                    {{ question.orderPosition }}
                  </div>
                </div>

                <div class="span-7 columns md-gap">
                  <div class="span-8">
                    <h4 class="mb-2 moveQuestion">
                      {{ getTypeName(question.type) }}
                    </h4>
                    <v-input
                      name="label"
                      label="Digite o nome / descrição do campo"
                      v-model="question.label"
                    />
                    <p class="text-sm mv-2">Digite uma descrição de ajuda</p>
                    <v-input
                      name="secondLabel"
                      label="Digite o texto de apoio do campo"
                      v-model="question.secondLabel"
                    />
                  </div>

                  <div class="span-8">
                    <label>Campo obrigatório?</label>
                    <v-switch
                      name="required-field"
                      :labels="{
                        checked: 'Sim',
                        unchecked: 'Não',
                      }"
                      v-model="question.required"
                    />
                  </div>

                  <div class="span-8" v-if="question.orderPosition !== 1">
                    <div class="group-buttons">
                      <button
                        class="crm-button danger"
                        @click.prevent="removeQuestion(question.orderPosition)"
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex jc-fe" v-if="npsData && npsData.id">
          <button class="crm-button" @click.prevent="updateNps">
            Salvar NPS
          </button>
        </div>
        <div class="flex jc-fe" v-else>
          <button class="crm-button" @click.prevent="createNps">
            Adicionar NPS
          </button>
        </div>
      </template>
    </v-modal>

    <!-- Answers Modal -->
    <v-modal
      :width="800"
      title="Respostas do NPS"
      description=""
      :handler="answersModalOpen"
      @requestClose="closeAnswersModal"
    >
      <template #content>
        <div class="columns md-gap mb-4">
          <div class="span-8">
            <v-table
              uniqueName="nanswersTable"
              :pagination="false"
              :columns="answersColumns"
              :rows="answers"
            >
              <template #custom-row="{ props }">
                <span v-if="props.column.field === 'actions'">
                  <div class="flex ai-c fgap1">
                    <button
                      class="crm-button sm"
                      @click.prevent="getSingleAnswers(props.row)"
                    >
                      Visualizar
                    </button>
                    <!-- <button class="crm-button sm danger" @click.prevent="">
                      Remover
                    </button> -->
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </v-table>
          </div>
        </div>
      </template>
    </v-modal>

    <!-- Single Answer Modal -->
    <v-modal
      :width="800"
      :title="`Respostas de ${selectedContact.contactName}`"
      description=""
      :handler="singleAsnwerModal"
      @requestClose="closeSingleAnswerModal"
      :arrows="false"
    >
      <template #content>
        <div
          class="columns md-gap mb-4"
          style="border-bottom: 1px solid #e2e2e2"
        >
          <div class="span-3">
            <p class="text-bold text-dark mb-0">Nome</p>
            <p>{{ selectedContact.contactName }}</p>
          </div>
          <div class="span-3">
            <p class="text-bold text-dark mb-0">Empresa</p>
            <p>{{ selectedContact.contactCompanyName }}</p>
          </div>
          <div class="span-2">
            <p class="text-bold text-dark mb-0">Data da Resposta</p>
            <p>
              {{
                new Date(selectedContact.sendDate) | date("dd/MM/yyyy HH:mm")
              }}
            </p>
          </div>
        </div>
        <div
          class="columns md-gap mb-4"
          v-for="answer in singleAnswer"
          :key="answer.questionLabel"
          style="max-width: 600px; margin: 0 auto"
        >
          <div class="span-1">
            <div class="index-indicator">
              {{ answer.questionOrder }}
            </div>
          </div>
          <div class="span-7">
            <p class="text-dark text-bold mb-1">{{ answer.questionLabel }}</p>
            <p class="answer-box">
              {{ answer.value }}
            </p>
          </div>
        </div>
      </template>
    </v-modal>

    <!-- Send Modal -->
    <v-modal
      :width="800"
      title="Enviar NPS"
      description=""
      :handler="sendModalOpen"
      @requestClose="closeSendModal"
    >
      <template #content>
        <div class="columns md-gap mb-4">
          <div class="span-8">
            <h3>Contatos que vão receber NPS</h3>
          </div>

          <div class="span-8">
            <v-table
              uniqueName="contactsTable"
              :pagination="false"
              :columns="[
                {
                  label: 'Nome',
                  field: 'name',
                },
                {
                  label: 'E-mail 1',
                  field: 'email1',
                },
                {
                  label: 'E-mail 2',
                  field: 'email2',
                },
              ]"
              :rows="contacts"
            >
              <template #custom-row="{ props }">
                <span v-if="props.column.field === 'actions'">
                  <div class="flex ai-c fgap1">
                    <button
                      class="crm-button sm"
                      @click.prevent="getSingleAnswers(props.row)"
                    >
                      Visualizar
                    </button>
                    <!-- <button class="crm-button sm danger" @click.prevent="">
                      Remover
                    </button> -->
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </v-table>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex ai-c fgap1 jc-c">
          <button class="crm-button danger" @click.prevent="closeSendModal">
            Cancelar
          </button>

          <button class="crm-button" @click.prevent="sendNps">Enviar</button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import VTable from "@/components/VTable.vue";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import TextEditor from "@/components/TextEditor.vue";
import VSwitch from "@/components/VSwitch.vue";
import config from "@/config.js";
import Axios from "axios";
import {inputFields, getTypeName} from "@/utils/formGeneratorFields";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import {dateFilter} from "vue-date-fns";

import {PlusIcon, DragIcon} from "@/components/crm/Icons";

export default {
  name: "CrmNps",
  filters: {
    date: dateFilter,
  },
  components: {
    PlusIcon,
    DragIcon,
    VTable,
    VModal,
    VInput,
    TextEditor,
    VSwitch,
    draggable,
  },
  data() {
    return {
      inputFields,
      getTypeName,
      tableColumns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Ano",
          field: "npsYear",
        },
        {
          label: "Qnt. Questões",
          field: "questions",
        },
        {
          label: "Qnt. Respostas",
          field: "answerLogs",
        },
        {
          label: "Ações",
          field: "actions",
        },
      ],
      answersColumns: [
        {
          label: "Código",
          field: "contactId",
        },
        {
          label: "Nome",
          field: "contactName",
        },
        {
          label: "Empresa",
          field: "contactCompanyName",
        },
        {
          label: "Data da Resposta",
          field: "sendDate",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SS",
          dateOutputFormat: "dd/MM/yyyy HH:mm",
          tdClass: "text-left",
        },
        {
          label: "Ações",
          field: "actions",
        },
      ],
      tableData: [],
      npsModalOpen: false,
      answersModalOpen: false,
      sendModalOpen: false,
      singleAsnwerModal: false,
      npsData: {},
      answers: [],
      userAnswers: [],
      contacts: [],
      sendNpsId: null,
      singleAnswer: [],
      selectedContact: {},
    };
  },
  methods: {
    async getNps(props) {
      let propsQuery;

      if (props) {
        propsQuery = {
          size: props.currentPerPage ? props.currentPerPage : 10,
          page: props.currentPage ? props.currentPage - 1 : 0,
        };
      }

      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando NPS",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/npsSearchs`,
          {
            params: propsQuery,
          }
        );

        this.tableData = response.data.data;
      } catch (err) {
        Swal.fire("Carregamento de NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Carregando NPS",
      });
    },
    async createNps() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Cadastrando NPS",
      });

      try {
        await Axios.post(
          `${config.env.API_DEFAULT.host}/npsSearchs`,
          this.npsData
        );

        this.closeAddModal();

        await this.getNps();

        Swal.fire("Cadastro de NPS", "NPS cadastrado com sucesso!", "success");
      } catch (err) {
        Swal.fire("Cadastro de NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Cadastrando NPS",
      });
    },
    async updateNps() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Atualizando NPS",
      });

      try {
        await Axios.put(
          `${config.env.API_DEFAULT.host}/npsSearchs/${this.npsData.id}`,
          this.npsData
        );
        await this.getNps();
        this.closeAddModal();
        Swal.fire(
          "Atualização de NPS",
          "NPS atualizada com sucesso!",
          "success"
        );
      } catch (err) {
        Swal.fire("Atualização de NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Atualizando NPS",
      });
    },
    async destroyNps(id) {
      Swal.fire({
        title: "Confirme a ação",
        html: "<p>Tem certeza que deseja excluir o NPS?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Excluir",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (response) => {
        if (response.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Excluir NPS",
          });

          try {
            await Axios.delete(`${config.env.API_DEFAULT.host}/npsSearchs/${id}`);

            this.tableData.content = this.tableData.content.filter(
              (nps) => nps.id !== id
            );

            Swal.fire({
              title: "NPS Excluído!",
              html: "<p>O NPS foi excluído com sucesso!</p>",
              type: "success",
            });
          } catch (e) {
            console.log(e)
          } finally {
            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
    openNps(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Recuperando dados do NPS",
      });

      try {
        const nps = this.tableData.content.find((nps) => nps.id === id);
        this.npsData = nps;
        this.npsModalOpen = true;
      } catch (err) {
        Swal.fire("Carregamento de NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Recuperando dados do NPS",
      });
    },
    async getNpsAnswers(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Recuperando respostas do NPS",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/npsAnswers/findUserWithAnswersInNps/${id}`
        );
        this.answers = response.data.data;
        this.answersModalOpen = true;
      } catch (err) {
        Swal.fire("Carregamento de respostas do NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Recuperando respostas do NPS",
      });
    },
    addQuestion(type) {
      this.npsData.questions.push({
        orderPosition: this.npsData.questions.length + 1,
        label: "",
        secondLabel: "",
        required: false,
        type,
      });
    },
    removeQuestion(orderPosition) {
      this.npsData.questions = this.npsData.questions.filter(
        (question) => question.orderPosition !== orderPosition
      );
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onDragChange(props) {
      this.npsData.questions[props.moved.newIndex].orderPosition =
        props.moved.newIndex + 1;
      this.npsData.questions[props.moved.oldIndex].orderPosition =
        props.moved.oldIndex + 1;
    },
    closeAddModal() {
      this.npsData = {};
      this.npsModalOpen = false;
    },
    closeAnswersModal() {
      this.answersModalOpen = false;
      this.answers = [];
    },
    async openSendModal(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando contatatos que recebem NPS",
      });

      this.sendNpsId = id;

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/getUsersReceiveNps`
        );
        this.contacts = response.data.data;
      } catch (err) {
        Swal.fire("Carregamento de respostas do NPS", err.message, "error");
      }

      this.sendModalOpen = true;

      this.$store.commit("setLoading", {
        show: false,
        label: "Carregando contatatos que recebem NPS",
      });
    },
    closeSendModal() {
      this.contacts = null;
      this.sendNpsId = null;
      this.sendModalOpen = false;
    },
    async sendNps() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Enviando NPS",
      });

      try {
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/npsRequests/dispatchMassiveNpsRequest/${this.sendNpsId}`
        );
        Swal.fire("Enviar NPS", "NPS enviado com sucesso!", "success");
      } catch (err) {
        Swal.fire("Enviar NPS", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Enviando NPS",
      });
    },
    async getSingleAnswers(props) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Recuperando respostas do Usuário",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/npsAnswers/findAnswersByContactIdAndNpsSearchId/${props.contactId}/nps/${props.npsSearch.id}`
        );
        this.singleAnswer = response.data.data;
        this.selectedContact = {
          contactCompanyName: props.contactCompanyName,
          contactId: props.contactId,
          contactName: props.contactName,
          sendDate: props.sendDate,
        };
        this.singleAsnwerModal = true;
      } catch (err) {
        Swal.fire("Carregamento de respostas do Usuário", err.message, "error");
      }

      this.$store.commit("setLoading", {
        show: false,
        label: "Recuperando respostas do Usuário",
      });
    },
    closeSingleAnswerModal() {
      this.singleAnswer = {};
      this.selectedContact = {};
      this.singleAsnwerModal = false;
    },
  },
  async mounted() {
    await this.getNps();
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover,
.index-indicator {
  background-color: #1768a7;
}

.components-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.components-list li:first-of-type a {
  border-top: 1px solid #e2e2e2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.components-list li:last-of-type a {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.components-list li a {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-top: 0;
  color: inherit;
}

.components-list li a:hover {
  background-color: #f5f6f7;
}

.index-indicator {
  width: 48px;
  height: 48px;
  border-radius: 99999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.form-component {
  padding: 1rem;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-bottom: 16px;
}

.group-buttons button {
  border-radius: 0;
}

.group-buttons button:not(:last-of-type) {
  border-right: 1px solid #093056;
}

.group-buttons button:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.group-buttons button:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.answer-box {
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 0.5rem;
  color: black;
}
</style>
