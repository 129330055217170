<template>
  <svg
   :width="size"
    :height="size"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
      fill="#104375"
    />
    <path d="M8.59375 8.59375H5.46875V19.5312H8.59375V8.59375Z" fill="white" />
    <path
      d="M16.0148 8.59375C13.8344 8.59375 13.4594 9.38906 13.2812 10.1562V8.59375H10.1562V19.5312H13.2812V13.2812C13.2812 12.268 13.8305 11.7188 14.8438 11.7188C15.8328 11.7188 16.4062 12.2562 16.4062 13.2812V19.5312H19.5312V14.0625C19.5312 10.9375 19.1242 8.59375 16.0148 8.59375Z"
      fill="white"
    />
    <path
      d="M7.03125 7.8125C7.8942 7.8125 8.59375 7.11295 8.59375 6.25C8.59375 5.38706 7.8942 4.6875 7.03125 4.6875C6.16831 4.6875 5.46875 5.38706 5.46875 6.25C5.46875 7.11295 6.16831 7.8125 7.03125 7.8125Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'LinkedinIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
