
<template>
  <div class="row">
    <div class="col-md-4 mb-4">
      <v-input
        name="subject"
        label="Assunto"
        :value="value.subject"
        @input="
          $emit('input', {
            ...value,
            subject: $event,
          })
        "
      ></v-input>
    </div>

    <div class="col-md-4 mb-4">
      <select-wrapper
        label="Tipo"
        :options="options"
        :value="value.type"
        @input="
          (event) => {
            $emit('input', {
              ...value,
              type: event,
            });

            getTypeTemplate(event);
          }
        "
      ></select-wrapper>
    </div>

    <div class="col-md-4 mb-4">
      <date-wrapper
        label="Data de encerramento"
        name="encerramento"
        :value="value.closingDate"
        @input="
          $emit('input', {
            ...value,
            closingDate: $event,
          })
        "
      ></date-wrapper>
    </div>

    <div
      class="col-md-4 mb-4"
      v-if="
        value.type === 'CLOSING_WITH_DEBIT' ||
        value.type === 'CLOSING_WITH_CREDIT' ||
        value.type === 'NEW_CUSTOMERS_WITHOUT_BANK_DATA_AND_NATHOR'
      "
    >
      <div class="outside-input">
        <div class="input-control active">
          <label for="value" class="no-events"> Valor </label>
          <Money id="value" v-model.lazy="value.value" v-bind="money"></Money>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-4" v-if="value.type === 'CLOSING_WITH_DEBIT'">
      <date-wrapper
        label="Vencimento"
        name="maturity"
        :value="value.maturity"
        @input="
          $emit('input', {
            ...value,
            maturity: $event,
          })
        "
      ></date-wrapper>
    </div>

    <div class="col-md-12 mb-4">
      <select-wrapper
        label="Contatos Principais"
        name="contacts"
        :multiple="true"
        :options="contacts"
        :value="value.contacts"
        @input="
          $emit('input', {
            ...value,
            contacts: $event,
          })
        "
      />
    </div>
    <div class="col-md-12">
      <text-editor v-model="value.description" ref="textEditor" />
    </div>

    <div class="col-md-12 mt-4">
      <p>
        Selecione abaixo um ou mais <strong>DOCUMENTOS DESTE PROCESSO</strong>,
        para incluir como anexo neste encerramento:
      </p>
    </div>

    <div class="col-5">
      <div class="documents-container">
        <div class="documents-header">DOCUMENTOS DISPONÍVEIS</div>
        <div class="documents-search">
          <i class="fa fa-search"></i>
          <input
            type="text"
            name="buscaDocumentos"
            id="buscaDocumentos"
            v-model="buscaDocumentos"
          />
        </div>
        <ul class="documents-list">
          <template v-for="document in filteredDocuments">
            <li
              :key="document.id"
              v-if="document.selected !== null && document.selected !== true"
              @click.prevent="document.selected = true"
            >
              <span
                ><strong>{{ document.documentTypeName }}</strong
                >: {{ document.originalFilename }}</span
              >
              <a href="#" @click.prevent class="documents-button small">
                <i class="fa fa-angle-double-right"></i>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div
      class="col-2 d-flex flex-column justify-content-center align-items-center"
      style="gap: 10px"
    >
      <div class="documents-button" @click.prevent="selectAll">
        <i class="fa fa-angle-double-right"></i>
      </div>
      <div class="documents-button" @click.prevent="deselectAll">
        <i class="fa fa-angle-double-left"></i>
      </div>
    </div>

    <div class="col-5">
      <div class="documents-container">
        <div class="documents-header">DOCUMENTOS SELECIONADOS</div>
        <div class="documents-search">
          <p>{{ selectedDocuments }} documento(s) selecionado(s) para envio</p>
        </div>
        <ul class="documents-list">
          <template v-for="document in documents">
            <li
              :key="document.id"
              v-if="document.selected !== null && document.selected !== false"
              @click.prevent="document.selected = false"
            >
              <span
                ><strong>{{ document.documentTypeName }}</strong
                >: {{ document.originalFilename }}</span
              >
              <a href="#" @click.prevent class="documents-button small">
                <i class="fa fa-angle-double-left"></i>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import TextEditor from "@/components/TextEditor.vue";
import DateWrapper from "@/components/DateWrapper.vue";
import { Money } from "v-money";

export default {
  name: "ModalClosure",
  components: {
    VInput,
    SelectWrapper,
    TextEditor,
    DateWrapper,
    Money,
  },
  props: ["dataModel", "value"],
  data() {
    return {
      closureData: {},
      documents: [],
      buscaDocumentos: "",
      contacts: [],
      options: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true,
      },
    };
  },
  mounted() {
    this.getDocuments();
    this.getContacts();

    // Opções padrão
    this.options = [
      { label: 'Encerramento c/ Débito', value: 'CLOSING_WITH_DEBIT' },
      { label: 'Encerramento c/ Crédito', value: 'CLOSING_WITH_CREDIT' },
      {
        label: 'Clientes novos sem dados bancários e nathor',
        value: 'NEW_CUSTOMERS_WITHOUT_BANK_DATA_AND_NATHOR',
      },
      { label: 'Devolução de numerário', value: 'CASH_DEVOLUTION' },
    ]

    // Verifica se é Sowport para colocar os tipos corretos
    if (this.dataModel.account.federalID === '49744122000181' || this.dataModel.account.id === 3) {
      this.options = [
        { label: 'Encerramento Sowport', value: 'CLOSING_SOWPORT' },
      ]
    }
  },
  computed: {
    selectedDocuments() {
      return this.documents.reduce(
        (acc, cur) => (cur.selected == true ? (acc += 1) : (acc += 0)),
        0
      );
    },
    filteredDocuments() {
      if (this.buscaDocumentos !== "") {
        const regExp = new RegExp(this.buscaDocumentos, "gi");
        const docs = this.documents.filter((doc) =>
          regExp.test(doc.originalFilename) || regExp.test(doc.documentTypeName)
        );
        return docs;
      }
      return this.documents;
    },
    contactsCC() {
      if (this.value.contacts) {
        return this.contacts.filter(
          (contact) =>
            !this.value.contacts.find((cont) => cont === contact.value)
        );
      }
      return this.contacts;
    },
  },
  methods: {
    getDocuments() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Dados",
        description: "Carregando...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.dataModel.id}/documents`
      )
        .then((response) => {
          const documents = response.data._embedded.documents
            .filter(doc => doc.deletedAt === null && (doc.deletedBy === null || doc.deletedBy === ''))
            .map((doc) => {
              doc.selected = false;
              return doc;
            });
          this.documents = documents;
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
        });

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getTypeTemplate(type) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando template do email",
        description: "Carregando...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/process-financial-closing/getProcessFinancialClosingTypeTemplate/${this.dataModel.id}/${type}`
        );

        if (response.data.data) {
          this.$emit("input", {
            ...this.value,
            description: response.data.data,
          });
          this.$refs.textEditor.updateContent();
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    getContacts() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Dados",
        description: "Carregando...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/process-financial-closing/get-users-who-receives-clousing/${this.dataModel.id}`
      )
        .then((response) => {
          const contacts = response.data.data.map((contact) => ({
            label: `${contact.name} - ${contact.email1}`,
            value: contact.email1,
          }));
          this.contacts = contacts;
          this.$emit("input", {
            ...this.value,
            contacts: contacts.map((contact) => contact.value),
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
        });

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    selectAll() {
      this.documents = this.documents.map((doc) => {
        doc.selected = true;
        return doc;
      });
    },
    deselectAll() {
      this.documents = this.documents.map((doc) => {
        doc.selected = false;
        return doc;
      });
    },
  },
  watch: {
    documents: {
      deep: true,
      handler(value) {
        const selected = value
          .filter((doc) => doc.selected === true)
          .map((item) => item.id);
        this.$emit("input", {
          ...this.value,
          documentsIds: selected,
        });
      },
    },
  },
};
</script>

<style scoped>
.documents-container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 300px;
  padding: 15px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.documents-header {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.documents-search {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}
.documents-search input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding-left: 45px;
}
.documents-search input:focus {
  background-color: white;
}
.documents-search i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.documents-list {
  padding: 0;
  list-style: none;
  margin: 0;
  overflow: auto;
  height: 100%;
}
.documents-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  word-break: break-all;
  gap: 10px;
}
.documents-list li a {
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}
.documents-list li:hover {
  background-color: #d2d2d2;
  color: black;
}
.documents-list li:hover a {
  opacity: 1;
}
.documents-list li:not(:last-of-type) {
  margin-bottom: 6px;
}
.documents-list li:not(:last-of-type)::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  right: 0;
}
.documents-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 45px;
  font-size: 24px;
  cursor: pointer;
}

.documents-button.small {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
}
</style>
