<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'chartOfAccounts-list' }"
              >Financeiro</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab == 'general' }"
                @click="activeTab = 'general'"
                href="#general"
                data-toggle="tab"
              >
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>

            <li class="nav-item" v-if="!isNewRecord()">
              <a
                class="nav-link"
                :class="{ active: activeTab == 'categories' }"
                @click="activeTab = 'categories'"
                href="#categories"
                data-toggle="tab"
              >
                <i class="fa fa-file-text-o mg-r-5"></i>
                Categorias
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div
              v-if="activeTab == 'general'"
              class="tab-pane active"
              id="general"
            >
              <chartOfAccounts-form-general :isNewRecord="isNewRecord()" />
            </div>

            <div
              v-if="activeTab == 'categories'"
              class="tab-pane active"
              id="categories"
            >
              <chartOfAccounts-form-categories />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartOfAccountsFormGeneral from "@/views/finances/chartOfAccounts/Form.General.vue";
import ChartOfAccountsFormCategories from "@/views/finances/chartOfAccounts/Form.Categories.vue";

export default {
  name: "chartOfAccounts-list",
  components: {
    "chartOfAccounts-form-general": ChartOfAccountsFormGeneral,
    "chartOfAccounts-form-categories": ChartOfAccountsFormCategories,
  },
  data() {
    return {
      modelTitle: "Plano de Contas",
      mainTitle: "Plano de Contas",
      activeTab: "general",
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
  },
};
</script>

<style scoped>
.sub-list-group {
  position: relative;
  top: 13px;
  left: 21px;
}

.sub-list-group > li {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-left: 0px;
  background: none !important;
}

.list-group-item > div.list-group-subitem:hover {
  background: #f9f9f9;
  box-shadow: 0 0 0 3px #f9f9f9;
}
</style>
