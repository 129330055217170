<template>
  <div
    class="signin-wrapper"
    style="background-image: url('img/background/bg-3.jpg')"
  >
    <modal-password-recovery
      v-if="modalPasswordRecovery.show"
      @close="modalPasswordRecovery.show = false"
    ></modal-password-recovery>

    <form
      @submit.prevent="onSubmit()"
      data-parsley-validate
      style="background: none !important"
    >
      <div
        class="signin-box bd-0 wd-450 tx-center"
        style="background: none !important"
      >
        <img :src="tenantLogo" alt="Logo" width="180" />

        <h2 class="signin-title-primary mg-t-40">Seja bem vindo!</h2>

        <h4 class="signin-title-secondary mg-b-20 mg-t-10">
          Digite seu dados para continuar.
        </h4>

        <p
          v-show="loginError != null"
          class="tx-normal mg-y-20 tx-14 tx-uppercase pd-10 tx-center bg-danger tx-white"
        >
          {{ loginError }}
        </p>

        <div class="form-group">
          <input
            type="text"
            v-bind:class="{ 'parsley-error': errors.has('username') }"
            class="form-control"
            placeholder="Seu email"
            v-model="user.username"
            name="username"
            v-validate="'required|email'"
          />
          <span
            v-show="errors.has('username')"
            class="parsley-errors-list filled"
            >Digite um endereço de email válido.</span
          >
        </div>

        <div class="form-group parsley-error">
          <input
            type="password"
            v-bind:class="{ 'parsley-error': errors.has('password') }"
            class="form-control"
            placeholder="Sua senha"
            v-model="user.password"
            name="password"
            v-validate="'required'"
          />
          <span
            v-show="errors.has('password')"
            class="parsley-errors-list filled"
            >Digite uma senha válida.</span
          >
        </div>

        <button
          class="btn btn-primary btn-block btn-signin bg-grandeur"
          v-if="tenantTrading"
        >
          ENTRAR
        </button>
        <button class="btn btn-primary btn-block btn-signin bg-reef" v-else>
          ENTRAR
        </button>
        <!-- <button class="btn btn-primary btn-block btn-signin"> ENTRAR </button> -->

        <!-- <p class="mg-t-20 mg-b-0 tx-black tx-normal">Você esqueceu a senha? <a href="#" @click.prevent="showModalPasswordRecovery()">CLIQUE AQUI PARA RECUPERAR</a>.</p> -->
        <p class="mg-t-20 mg-b-0 tx-black tx-normal">
          Você esqueceu a senha?
          <router-link :to="{ name: 'RecoveryPassword' }"
            >CLIQUE AQUI PARA RECUPERAR</router-link
          >.
        </p>
      </div>

      <p class="mg-t-20 mg-b-0 tx-12 tx-center">
        Copyright © {{ new Date() | moment("YYYY") }}
      </p>
      <p class="tx-11 tx-center">
        Todos os direitos reservados à {{ tenantName }}.
      </p>
    </form>
  </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";
import ModalPasswordRecovery from "@/views/configuration/users/RecoveryPassword.vue";

export default {
  components: {
    "modal-password-recovery": ModalPasswordRecovery,
  },
  name: "LoginView",
  data() {
    return {
      pingMsg: "",
      tenantName: "",
      tenantLogo: "",
      tenantTrading: true,
      // user: { username: 'allysson@primeinternacional.com.br', password: '1q2w3e4r5t'},
      user: {
        username: null,
        password: null,
      },
      loginError: null,
      connection: null,
      modalPasswordRecovery: {
        show: false,
        model: null,
      },
    };
  },
  methods: {
    showModalPasswordRecovery() {
      this.modalPasswordRecovery.show = true;
    },
    onSubmit() {
      const self = this;
      this.$validator.validate().then((result) => {
        if (result) {
          self.submitLoginForm();
        }
      });
    },
    submitLoginForm() {
      const self = this;
      const params = new URLSearchParams();
      params.append("grant_type", "password");
      params.append("username", self.user.username);
      params.append("password", self.user.password);

      this.$store.commit("setLoading", {
        show: true,
        label: "Login",
        description: "Verificando suas credenciais de acesso...",
      });

      axios({
        method: "post",
        url: `${config.env.API_DEFAULT.host}/oauth/token`,
        auth: {
          username: config.env.API_DEFAULT.key,
          password: config.env.API_DEFAULT.secret,
        },
        headers: {
          "Content-type": "application/x-www-form-urlencoded;",
        },
        data: params,
      })
        .then((response) => {
          if (response.status === 200) {
            self.$store.commit("includeCredentials", {
              userAuthenticated: false,
              accessToken: response.data.access_token,
              scope: response.data.scope,
              expires_in: response.data.expires_in,
              data: null,
            });

            axios({
              method: "get",
              url: `${config.env.API_DEFAULT.host}/credential`,
              headers: {
                Authorization: `Bearer ${self.$store.state.credentials.accessToken}`,
              },
            })
              .then((responseCredentialsInfo) => {
                window.localStorage.setItem(
                  "user",
                  JSON.stringify(responseCredentialsInfo.data)
                );
                self.$store.commit("includeCredentials", {
                  userAuthenticated: true,
                  accessToken: response.data.access_token,
                  scope: response.data.scope,
                  expires_in: response.data.expires_in,
                  data: responseCredentialsInfo.data.data,
                });

                self.$store.commit("defaultLayoutEnabled");
                self.$router.push({ name: "dashboard" });

                setTimeout(() => {
                  self.$store.dispatch("getAccounts");
                }, 3000);
                this.saveAccessLog();
              })
              .catch((error) => {
                this.$store.commit("setLoading", {
                  show: false,
                });
                self.$store.commit("includeCredentials", {
                  userAuthenticated: false,
                  accessToken: null,
                  scope: null,
                  expiresIn: null,
                  data: null,
                });
                self.$store.commit("defaultLayoutDisabled");
                self.loginError =
                  "Não foi possível buscar as credenciais do usuário autenticado: \n";
              });
          }
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          if (error.response && error.response.status === 400) {
            self.loginError = "Seu Usuário e/ou Senha estão incorretos.";
          } else {
            self.loginError = error.message;
          }
          self.$store.commit("includeCredentials", {
            userAuthenticated: false,
            accessToken: null,
            scope: null,
            expiresIn: null,
            data: null,
          });

          self.$store.commit("defaultLayoutDisabled");
        });
    },
    async saveAccessLog() {
      const response = await axios.get(
        "https://ipinfo.io/?token=66401ea11bcd92"
      );

      if (
        (response.data.ip !== null || response.data.ip !== "") &&
        response.data.city !== null &&
        response.data.region !== ""
      ) {
        try {
          const data = {
            ipAddress: response.data.ip,
            origin: `${response.data.city} - ${response.data.region} - ${response.data.country}`,
            tag: "GESCOMEX_OPERACIONAL",
            provider: response.data.org,
          };

          axios.post(`${config.env.API_DEFAULT.host}/userAccessLogs`, data);
        } catch {
          console.error("Erro ao salvar log de acesso!");
        }
      } else {
        console.log("Dados principais do log não foram encontrados!");
      }
    },
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      self.tenantTrading = config.env.setup.company.trading;
      self.tenantName = config.env.setup.company.name;
      self.tenantLogo = config.env.setup.company.logo.src;
    }, 500);

    // Ação Desconhcida - Reported by Luan Kohl
    // axios.get(config.env.API_DEFAULT.host + "/app/public/ping");
  },
  created() {
    this.$store.commit("defaultLayoutDisabled");
    this.$store.commit("ProcessStore/CLEAR_STATE");
    this.$store.commit("setLoading", {
      show: false,
      label: "",
      description: "",
    });
  },
};
</script>
