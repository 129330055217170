<template>
  <div>
    <PosTable @viewPo="(id) => showPo(id)" @destroyPo="destroyPo" />

    <fab-modal
      :width="2560"
      :height="100"
      title="VISUALIZAR ORDEM DE COMPRA"
      description=""
      :handler="modalInvoiceOpen"
      @requestClose="closeInvoiceModal"
      :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">
              Código do Documento
              <span class="text-bold">#{{ selectedDocument.id }}</span>
            </p>
            <p class="sub-gray mb-0">
              Enviado em
              {{
                new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm")
              }}
            </p>
          </div>
        </div>
      </template>

      <template #content>
        <OriginalDocumentsPoModal
          :selected-document="selectedDocument"
          :selected-documents="selectedDocuments"
          :read-only="false"
          @updateBuyerField="invoiceUpdateBuyerField"
          @updateVendorField="invoiceUpdateVendorField"
          @updatePo="updatePo"
          @destroyPo="destroyPo"
          @addLineItem="invoiceAddLineItem"
          @removeLineItem="invoiceRemoveLineItem"
          @addInstallmentItem="invoiceAddInstallmentItem"
          @removeInstallmentItem="invoiceRemoveInstallmentItem"
        />
      </template>
    </fab-modal>
  </div>
</template>

<script>
import OriginalDocumentsPoModal from "@/components/OriginalDocuments/purchaseOrders/OriginalDocumentPoModal.vue";
import FabModal from "@/components/VModal.vue";
import { originalDocumentsMixin } from "@/mixins/originalDocuments/originalDocumentsMixin";
import PosTable from "@/components/OriginalDocuments/purchaseOrders/PosTable.vue";

export default {
  name: "IndexPos",
  mixins: [originalDocumentsMixin],
  components: {
    PosTable,
    FabModal,
    OriginalDocumentsPoModal,
  },
};
</script>
