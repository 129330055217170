<template>
  <div class="table-responsive">
    <table class="table table-striped tx-14" v-if="this.moviments.length > 0">
      <thead class="thead-white">
        <tr class="tx-10">
          <th class="pd-y-5 wd-50">&nbsp;</th>
          <th class="pd-y-5 wd-120">Vencimento</th>
          <th class="pd-y-5 wd-120" v-if="!state.late && !state.toPay">
            Pagamento
          </th>
          <th class="pd-y-5 wd-300">Lançamento</th>
          <th class="pd-y-5 wd-200">Categoria / Processo</th>
          <th class="pd-y-5 wd-300" v-if="state.late || state.toPay">
            Empresa / Pessoa
          </th>
          <th class="pd-y-5" v-if="!state.late && !state.toPay">Conta</th>
          <th class="pd-y-5" v-if="!state.late && !state.toPay">
            Forma de Pagamento
          </th>
          <th class="pd-y-5 tx-right">Valor<small>(R$)</small></th>
          <th class="pd-y-5 tx-right" v-if="!state.late && !state.toPay">
            Valor Pago<small>(R$)</small>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="appointment in moviments">
          <template
            v-if="
              appointment.receipts.length === 0 ||
              appointment.receipts.length <= 0
            "
          >
            <tr :key="appointment.id" class="table-tr-moviments-content">
              <td class="pd-l-20">
                <label class="ckbox">
                  <input type="checkbox" checked="" /><span></span>
                </label>
              </td>
              <td>{{ appointment.dueDate | moment("DD/MM/YYYY") }}</td>
              <td v-if="!state.late && !state.toPay"></td>
              <td>
                <span class="tx-inverse tx-14 tx-medium d-block">{{
                  appointment.description
                }}</span>
                <span class="tx-11 d-block" v-if="!state.late && !state.toPay">
                  <template v-if="appointment.moviment.company">
                    {{ appointment.moviment.company.name }}
                  </template>
                </span>
              </td>
              <template
                v-if="
                  appointment.typeOfMovimentation == 'PROCESS_INCOME' ||
                  appointment.typeOfMovimentation == 'PROCESS_EXPENSE'
                "
              >
                <td class="tx-14">
                  <span
                    class="square-8 bg-purple mg-r-5 rounded-circle"
                    v-if="appointment.moviment.typeOfProcess === 'IMPORT'"
                  >
                  </span>
                  <span class="square-8 bg-pink mg-r-5 rounded-circle" v-else>
                  </span>

                  <template
                    v-if="
                      appointment.moviment.typeOfMovimentation ===
                        'PROCESS_INCOME' ||
                      appointment.moviment.typeOfMovimentation ===
                        'PROCESS_EXPENSE'
                    "
                  >
                    <template
                      v-if="
                        appointment.moviment.typeOfProcess === 'IMPORT' &&
                        appointment.importProcess !== undefined
                      "
                    >
                      {{ appointment.importProcess.identification }}
                    </template>
                    <template
                      v-else-if="
                        model.typeOfProcess === 'EXPORT' &&
                        appointment.exportProcess !== undefined
                      "
                    >
                      {{ appointment.appointment.moviment.identification }}
                    </template>
                  </template>
                </td>
              </template>
              <template v-else>
                <td class="tx-12">
                  <span class="square-8 bg-warning mg-r-5 rounded-circle">
                  </span>

                  <template
                    v-if="appointment.moviment.chartOfAccount !== undefined"
                  >
                    {{ appointment.moviment.chartOfAccount.description }}
                  </template>
                </td>
              </template>

              <td class="tx-12" v-if="state.late || state.toPay">
                <template v-if="appointment.moviment.company.name">
                  {{ appointment.moviment.company.name }}
                </template>
              </td>
              <td v-else colspan="2"></td>
              <td class="tx-right">
                {{ appointment.totalValue | numeralFormat("0,0.00") }}
              </td>
              <template v-if="!state.late && !state.toPay">
                <td class="tx-right">
                  {{ appointment.totalValue | numeralFormat("0,0.00") }}
                </td>
              </template>
            </tr>
            <tr
              :key="`receipt-${appointment.id}-actions`"
              class="table-tr-moviments-actions"
            >
              <td :colspan="colspan" align="center">
                <button
                  @click.prevent="openMoviment(appointment.moviment)"
                  class="btn btn-warning btn-icon pd-x-10 pd-y-2 tx-12 mg-r-10"
                >
                  <i class="icon ion-android-document tx-15 mg-r-5"> </i> EDITAR
                  LANÇAMENTO
                </button>
                <button
                  @click.prevent="payAppointment(appointment)"
                  class="btn btn-success btn-icon pd-x-10 pd-x-10 pd-y-2 tx-12"
                >
                  <i class="icon ion-cash tx-15 mg-r-5"> </i> BAIXAR
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-for="receipt in appointment.receipts">
              <tr
                :key="`receipt-${receipt.id}`"
                class="table-tr-moviments-content"
              >
                <td class="pd-l-20">
                  <label class="ckbox">
                    <input type="checkbox" checked="" /><span></span>
                  </label>
                </td>
                <td>{{ appointment.dueDate | moment("DD/MM/YYYY") }}</td>
                <td>{{ receipt.paymentDate | moment("DD/MM/YYYY") }}</td>
                <td>
                  <span class="tx-inverse tx-14 tx-medium d-block">{{
                    receipt.description
                  }}</span>
                  <span
                    class="tx-11 d-block"
                    v-if="!state.late && !state.toPay"
                    >{{ receipt.moviment.company.name }}</span
                  >
                </td>

                <template
                  v-if="
                    appointment.typeOfMovimentation == 'PROCESS_INCOME' ||
                    appointment.typeOfMovimentation == 'PROCESS_EXPENSE'
                  "
                >
                  <td class="tx-14">
                    <span class="square-8 bg-warning mg-r-5 rounded-circle">
                    </span>

                    <template
                      v-if="
                        appointment.moviment.typeOfMovimentation ===
                          'PROCESS_INCOME' ||
                        appointment.moviment.typeOfMovimentation ===
                          'PROCESS_EXPENSE'
                      "
                    >
                      <template
                        v-if="
                          appointment.moviment.typeOfProcess === 'IMPORT' &&
                          appointment.importProcess !== undefined
                        "
                      >
                        {{ appointment.importProcess.identification }}
                      </template>
                      <template
                        v-else-if="
                          model.typeOfProcess === 'EXPORT' &&
                          appointment.exportProcess !== undefined
                        "
                      >
                        {{ appointment.appointment.moviment.identification }}
                      </template>
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td class="tx-12">
                    <span class="square-8 bg-warning mg-r-5 rounded-circle">
                    </span>
                    {{ appointment.moviment.chartOfAccount.description }}
                  </td>
                </template>

                <td class="tx-12" v-if="state.late || state.toPay">
                  <template v-if="appointment.moviment.company">
                    {{ appointment.moviment.company.name }}
                  </template>
                </td>
                <td class="tx-12">
                  {{ receipt.bankAccountSource.description }}
                </td>
                <td class="tx-12">{{ receipt.typeOfPayment.description }}</td>
                <td class="tx-right">
                  {{ appointment.totalValue | numeralFormat("0,0.00") }}
                </td>
                <td class="tx-right" v-if="!state.late && !state.toPay">
                  {{ receipt.paidValue | numeralFormat("0,0.00") }}
                </td>
              </tr>

              <tr
                :key="`receipt-${receipt.id}-actions`"
                class="table-tr-moviments-actions"
              >
                <td :colspan="colspan" align="center">
                  <button
                    @click.prevent="openReceipt(receipt)"
                    class="btn btn-info btn-icon pd-x-10 pd-y-2 tx-12 mg-r-10"
                  >
                    <i class="icon ion-edit tx-15 tx-15 mg-r-5"> </i> EDITAR
                    BAIXA
                  </button>
                  <button
                    @click.prevent="deleteMoviment(receipt)"
                    class="btn btn-danger btn-icon pd-x-10 pd-y-2 tx-12 mg-r-10"
                  >
                    <i class="icon ion-android-delete tx-15 mg-r-5"> </i>
                    EXCLUIR BAIXA
                  </button>
                  <button
                    @click.prevent="openMoviment(receipt.moviment)"
                    class="btn btn-warning btn-icon pd-x-10 pd-y-2 tx-12"
                  >
                    <i class="icon ion-android-document tx-15 tx-15 mg-r-5">
                    </i>
                    EDITAR LANÇAMENTO
                  </button>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
      <tfoot>
        <tr class="tx-14 bd-b-10 bd-10">
          <th class="pd-y-5" :colspan="colspan - 4"></th>
          <th class="pd-y-5" colspan="2">VALOR TOTAL<small>R$</small></th>
          <th class="pd-y-5 tx-right">
            {{ totalValue | numeralFormat("0,0.00") }}
          </th>
          <th class="pd-y-5 tx-right" v-if="!state.late && !state.toPay">
            {{ totalPaid | numeralFormat("0,0.00") }}
          </th>
        </tr>
      </tfoot>
    </table>

    <div
      class="bd-l bd-5 bd-danger bg-gray-100 pd-x-40 pd-y-60 mg-y-20 mg-x-40"
      v-else
    >
      <h5 class="tx-danger timeline-date">
        Movimentações Financeiras | {{ label }}
      </h5>
      <p class="mg-b-0">
        Nenhuma movimentação financeira foi encontrada de acordo com o filtro no
        período
        <template v-if="this.selectedDateRange !== null">
          de
          <span class="tx-bold">{{
            this.selectedDateRange.from | moment("DD/MM/YYYY")
          }}</span>
          até
          <span class="tx-bold">{{
            this.selectedDateRange.to | moment("DD/MM/YYYY")
          }}</span
          >.
        </template>
        <template v-else> selecionado. </template>
      </p>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/events/EventBus.js";

export default {
  name: "ListItem",
  props: [
    "moviments",
    "totalValue",
    "totalPaid",
    "type",
    "state",
    "label",
    "colspan",
    "startBalance",
    "endBalance",
    "selectedDateRange",
    "isTransfers",
  ],
  components: {},
  data() {
    return {
      teste: null,
      selected: [],
    };
  },
  methods: {
    openMoviment(moviment, event) {
      EventBus.$emit("Finance.Moviments.List.openMoviment", moviment);
    },
    duplicateMoviment(moviment, event) {
      EventBus.$emit("Finance.Moviments.List.duplicateMoviment", moviment);
    },
    deleteMoviment(moviment, event) {
      EventBus.$emit("Finance.Moviments.List.deleteMoviment", moviment);
    },
    openReceipt(receipt, event) {
      EventBus.$emit("Finance.Moviments.List.openReceipt", receipt);
    },
    payAppointment(moviment, event) {
      EventBus.$emit("Finance.Moviments.List.payAppointment", moviment);
    },
    unpayMoviment(moviment, event) {
      EventBus.$emit("Finance.Moviments.List.unpayMoviment", moviment);
    },
    openActions() {},
  },

  mounted() {
    EventBus.$on(
      "Finance.Moviments.List.Item.manageSelectedMoviments",
      (selecteds) => {
        self.selected = selecteds;
      }
    );
  },
  computed: {
    selectAll: {
      get() {
        return this.moviments
          ? this.selected.length === this.moviments.length
          : false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.moviments.forEach((moviment) => {
            selected.push(moviment);
          });
        }

        this.selected = selected;
      },
    },
  },
  watch: {
    selected(val, oldVal) {
      EventBus.$emit("Finance.Moviments.List.manageSelectedMoviments", val);
    },
  },
};
</script>
<style>
tr.table-tr-moviments-actions {
  display: none;
  background: #ffffff;
}

tr.table-tr-moviments-content:hover + .table-tr-moviments-actions,
.table-tr-moviments-actions:hover {
  display: table-row;
  align-content: center;
}

tr.table-tr-moviments-content:hover {
  background: #ffffff;
}
</style>
