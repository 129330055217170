const CUSTOMER_COLUMNS = [
  {
    name: 'Nome', field: 'name', hide: false, type: 'string',
  },
  {
    name: 'Habilitado', field: 'enabled', hide: true, type: 'boolean',
  },
  {
    name: 'Regime Fiscal', field: 'taxRegime', format: 'taxRegime', hide: true,
  },
  {
    name: 'CNPJ', field: 'federalID', format: 'cnpj', hide: false,
  },
  { name: 'Razão Social', field: 'completeName', hide: true },
  {
    name: 'Data de Fundação da Empresa', field: 'openingDate', hide: true, type: 'date',
  },
  { name: 'Nome Fantasia', field: 'name', hide: true },
  {
    name: 'Isento IE?', field: 'stateRegistrationExempt', hide: true, type: 'boolean',
  },
  { name: 'Inscrição Estadual', field: 'stateRegistration', hide: true },
  { name: 'Inscrição Municipal', field: 'municipalRegistration', hide: true },
  { name: 'Telefone Principal', field: 'telephone1', hide: true },
  { name: 'Telefone Secundário', field: 'telephone2', hide: true },
  { name: 'Email', field: 'email', hide: false },
  { name: 'Website', field: 'website', hide: true },
  {
    name: 'Compliance', field: 'hasCompliance', hide: true, type: 'boolean',
  },
  { name: 'Descrição da Compliance', field: 'hasComplianceInformation', hide: true },
  {
    name: 'Comissão Mínima?', field: 'isMinimalComissionPercentual', hide: true, type: 'boolean',
  },
  {
    name: 'Comissão (%)', field: 'comissionPercentual', hide: true, type: 'percentual',
  },
  {
    name: 'Valor SDA', field: 'sdaValue', hide: true, type: 'money',
  },
  { name: 'Prazo de Envio Encerramento', field: 'billingTerm', hide: true },
  { name: 'CEP', field: 'postalCode', hide: true },
  { name: 'Logradouro', field: 'address', hide: true },
  { name: 'Número', field: 'addressNumber', hide: true },
  { name: 'Complemento', field: 'complement', hide: true },
  { name: 'Bairro', field: 'district', hide: true },
  { name: 'Cidade', field: 'city', hide: true },
  { name: 'Estado', field: 'federalUnity', hide: true },
  {
    name: 'Data de cadastro', field: 'createdAt', hide: false, type: 'date',
  },
];

export default CUSTOMER_COLUMNS;
