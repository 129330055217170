<template>
  <div class="slim-mainpanel">
    <!-- <Loading ref="loading" :label="this.modelTitle" :description="`Processando ${modelTitle}...`"></Loading> -->

    <modal-receipt
      v-if="this.modalReceipt.show"
      @close="closeModalReceipt('close')"
      @finish="closeModalReceipt('finish')"
      :model="this.modalReceipt.model"
      :isForm="false"
    ></modal-receipt>

    <div class="container">
      <div class="slim-pageheader pd-y-0">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item">
            <a href="#">{{ title1 }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ title2 }}
          </li>
        </ol>

        <div class="report-summary-header mg-b-10">
          <div class="d-h-t-right">
            <div>
              <h4 class="tx-inverse mg-b-3">
                {{ modelTitle }}: {{ currentTabTitle }}
              </h4>
              <p
                class="mg-b-0 wd-350 tx-14"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-dropdown-toggle="click"
                data-dropdown-persistent="true"
                data-dropdown-content
                tabindex="-1"
                aria-hidden="true"
                aria-autoclose="false"
              >
                <i class="fa fa-calendar tx-s-24"></i>
                {{ this.selectedDateRange.from | moment("ll") }} até
                {{ this.selectedDateRange.to | moment("ll") }}
              </p>
            </div>
            <!-- <div class="summary-item">
                            <h1>R$ {{totalToPay | numeralFormat('0,0.00')}}</h1>
                            <span class="tx-danger">Contas<br>a pagar</span>
                        </div>
                        <div class="summary-item">
                            <h1>R$ {{totalPaid | numeralFormat('0,0.00')}}</h1>
                            <span class="tx-success">Contas<br>pagas</span>
                        </div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-10" style="min-height: 500px">
          <div class="pd-x-0 pd-y-20">
            <ul
              class="nav nav-tabs nav-justified nav-tabs-dropdown bd-0"
              role="tablist"
            >
              <li class="nav-item pd-x-20 pd-t-5">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar"
                    v-model="search"
                  />
                </div>
              </li>

              <li class="nav-item bd-b-0">
                <a
                  href="#late"
                  data-toggle="tab"
                  role="tab"
                  class="nav-link pd-y-10"
                  @click.prevent="changeMovimentState('late')"
                >
                  <span class="tx-14 tx-uppercase">Em Atraso</span> <br/>
                  <h6 class="tx-12" :class="{ 'tx-danger': state.late }">
                    R$ {{ totalLate | numeralFormat("0,0.00") }}
                  </h6>
                </a>
              </li>

              <li class="nav-item">
                <a
                  href="#topay"
                  data-toggle="tab"
                  role="tab"
                  class="nav-link pd-y-10"
                  @click.prevent="changeMovimentState('toPay')"
                >
                  <span class="tx-14 tx-uppercase"
                  >A {{ movimentLabel.toPay }}</span
                  >
                  <br/>
                  <h6 class="tx-11" :class="{ 'tx-danger': state.toPay }">
                    R$ {{ totalToPay | numeralFormat("0,0.00") }}
                  </h6>
                </a>
              </li>

              <li class="nav-item">
                <a
                  href="#paid"
                  data-toggle="tab"
                  role="tab"
                  class="nav-link pd-y-10"
                  @click.prevent="changeMovimentState('paid')"
                >
                  <span class="tx-14 tx-uppercase"
                  >{{ movimentLabel.paid }}
                  </span>
                  <br/>
                  <h6 class="tx-12" :class="{ 'tx-success': state.paid }">
                    R$ {{ totalPaid | numeralFormat("0,0.00") }}
                  </h6>
                </a>
              </li>

              <li class="nav-item">
                <a
                  href="#all"
                  data-toggle="tab"
                  role="tab"
                  class="nav-link pd-y-10 active show"
                  @click.prevent="changeMovimentState('all')"
                >
                  <span class="tx-14 tx-uppercase">Todas</span> <br/>
                  <h6 class="tx-12" :class="{ 'tx-info': state.all }">
                    R$ {{ total | numeralFormat("0,0.00") }}
                  </h6>
                </a>
              </li>

              <li class="nav-item mg-t-10">
                <router-link
                  class="btn btn-primary float-right"
                  v-if="isTransfers()"
                  :to="{
                    name: 'moviments-transfers-form',
                    params: { action: 'create' },
                  }"
                >
                  <i class="icon ion-plus tx-15"></i> ADICIONAR
                </router-link>
                <router-link
                  class="btn btn-primary float-right"
                  v-if="!isTransfers()"
                  :to="{ name: 'moviments-form', params: { action: 'create' } }"
                >
                  <i class="icon ion-plus tx-15"></i> ADICIONAR
                </router-link>
              </li>
            </ul>

            <div
              class="tab-content bd bd-gray-300 section-wrapper pd-0 ht-400p"
            >
              <div id="late" class="tab-pane bd-0 mg-t-0 pd-0" role="tabpanel">
                <list-item
                  key="list-item-late"
                  :moviments="filteredMoviments"
                  :type="this.$route.params.moviments"
                  :totalValue="total"
                  :totalPaid="totalPaid"
                  :state="this.state"
                  colspan="7"
                  label="Em Atraso"
                  :startBalance="startBalance"
                  :endBalance="endBalance"
                  :selectedDateRange="selectedDateRange"
                  :isTransfers="isTransfers()"
                />
              </div>

              <div
                id="topay"
                class="tab-pane bd-0 bd-1 mg-t-0 pd-0"
                role="tabpanel"
              >
                <list-item
                  key="list-item-toPay"
                  :moviments="filteredMoviments"
                  :type="this.$route.params.moviments"
                  :totalValue="total"
                  :totalPaid="totalPaid"
                  :state="this.state"
                  colspan="7"
                  :label="'A ' + movimentLabel.toPay"
                  :startBalance="startBalance"
                  :endBalance="endBalance"
                  :selectedDateRange="selectedDateRange"
                  :isTransfers="isTransfers()"
                />
              </div>

              <div
                id="paid"
                class="tab-pane bd-0 bd-1 mg-t-0 pd-0"
                role="tabpanel"
              >
                <list-item
                  key="list-item-paid"
                  :moviments="filteredMoviments"
                  :type="this.$route.params.moviments"
                  :totalValue="total"
                  :totalPaid="totalPaid"
                  :state="this.state"
                  colspan="9"
                  :label="movimentLabel.paid"
                  :startBalance="startBalance"
                  :endBalance="endBalance"
                  :selectedDateRange="selectedDateRange"
                  :isTransfers="isTransfers()"
                />
              </div>

              <div
                id="all"
                class="tab-pane bd-0 mg-t-0 pd-0 active show"
                role="tabpanel"
              >
                <list-item
                  key="list-item-all"
                  :moviments="filteredMoviments"
                  :type="this.$route.params.moviments"
                  :totalValue="total"
                  :totalPaid="totalPaid"
                  :state="this.state"
                  colspan="9"
                  label="Todas"
                  :startBalance="startBalance"
                  :endBalance="endBalance"
                  :selectedDateRange="selectedDateRange"
                  :isTransfers="isTransfers()"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-2"
          style="
            height: 100%;
            width: 400px;
            position: fixed;
            top: 100px;
            right: 0;
            overflow-x: hidden;
            margin-right: 20px;
            padding-top: 20px;
          "
        >
          <div class="row pd-0">
            <div class="col-sm-12">
              <div class="date-pickers">
                <h6 class="tx-info tx-normal mg-t-15 mg-b-10">PERÍODO</h6>
                <div class="row">
                  <div class="col-sm-12 col-lg-5">
                    <date-picker lang="pt-br"
                                 v-model="selectedDateRange.from"
                                 @input="applyFilter"
                                 name="fromPickedDate"
                                 class="fromPickedDate"
                                 format="DD/MM/YYYY"
                                 placeholder="Data Inicial"
                    ></date-picker>
                  </div>
                  <div class="col-sm-12 col-lg-5">
                    <date-picker lang="pt-br"
                                 v-model="selectedDateRange.to"
                                 @input="applyFilter"
                                 name="toPickedDate"
                                 class="toPickedDate mg-l-10"
                                 format="DD/MM/YYYY"
                                 placeholder="Data Final"
                    ></date-picker>
                  </div>
                </div>
              </div>

              <h6 class="tx-info tx-normal mg-t-20 mg-b-10">PESQUISAR</h6>
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar"
                v-model="search"
              />

              <h6 class="tx-info tx-normal mg-t-20 mg-b-10">
                TIPOS DE LANÇAMENTOS
              </h6>
              <ul class="list-unstyled lh-8 tx-13">
                <li>
                  <label class="ckbox">
                    <input
                      name="showAdministratives"
                      type="checkbox"
                      v-model="show.administratives"
                    />
                    <span> Adminstrativo </span>
                  </label>
                </li>
                <li>
                  <label class="ckbox">
                    <input
                      name="showProcesses"
                      type="checkbox"
                      v-model="show.processes"
                    />
                    <span> Operacional </span>
                  </label>
                </li>
              </ul>

              <h6 class="tx-info tx-normal mg-t-20 mg-b-10">
                CONTAS BANCÁRIAS
              </h6>
              <ul class="list-unstyled lh-8 tx-13">
                <li
                  v-for="bankAccount in bankAccounts"
                  :key="'showBankAccountID' + bankAccount.id"
                >
                  <label class="ckbox">
                    <input
                      :name="'showBankAccount' + bankAccount.id"
                      type="checkbox"
                      v-model="bankAccountsSelected"
                      :value="bankAccount"
                      checked="checked"
                    />
                    <span> {{ bankAccount.description }} </span>
                  </label>
                </li>
              </ul>

              <h6 class="tx-info tx-normal mg-t-20 mg-b-10">
                CENTRO DE CUSTOS
              </h6>
              <ul class="list-unstyled lh-8 tx-13">
                <li
                  v-for="typeOfCostCenter in typeOfCostCenters"
                  :key="'showTypeOfCostCenter' + typeOfCostCenter.id"
                >
                  <label class="ckbox">
                    <input
                      :name="'showTypeOfCostCenter' + typeOfCostCenter.id"
                      type="checkbox"
                      v-model="typeOfCostCentersSelected"
                      :value="typeOfCostCenter"
                      checked="checked"
                    />
                    <span> {{ typeOfCostCenter.description }} </span>
                  </label>
                </li>
              </ul>

              <h6 class="tx-info tx-normal mg-t-20 mg-b-10">FILIAL</h6>
              <ul class="list-unstyled lh-8 tx-13"></ul>

              <a
                href="#"
                class="btn btn-primary d-block mg-t-0 mg-b-10 tx-12"
                @click.prevent="applyFilter"
              >APLICAR</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";
import {EventBus} from "@/events/EventBus.js";
import DatePicker from "vue2-datepicker";
import AppointmentService from "@/services/AppointmentService.js";
import moment from "moment";
import ListItem from "@/views/finances/moviments/List.Item.vue";
import ModalReceipt from "@/views/finances/moviments/receipts/Modal.vue";

export default {
  name: "moviments-list",
  components: {
    DatePicker,
    "list-item": ListItem,
    "modal-receipt": ModalReceipt,
  },
  data() {
    return {
      title:
        this.$route.params.moviments == "incomes"
          ? "Contas a Receber"
          : "Contas a Pagar",
      title1:
        this.$route.params.moviments == "incomes"
          ? "Contas a Receber"
          : "Contas a Pagar",
      title2:
        this.$route.params.moviments == "incomes"
          ? "Contas a Receber"
          : "Contas a Pagar",
      mainTitle: "",
      modelTitle:
        this.$route.params.moviments == "incomes"
          ? "Conta a Receber"
          : "Conta a Pagar",
      movimentLabel: {
        plural:
          this.$route.params.moviments == "incomes"
            ? "Contas Recebidas"
            : "Contas Pagas",
        singuar:
          this.$route.params.moviments == "incomes"
            ? "Conta Recebida"
            : "Conta Paga",
        toPay: this.$route.params.moviments == "incomes" ? "Receber" : "Pagar",
        paid: this.$route.params.moviments == "incomes" ? "Recebido" : "Pago",
        type:
          this.$route.params.moviments == "incomes"
            ? "Recebimento"
            : "Pagamento",
      },
      totals: {
        late: 9000.0,
        toPay: 105540.5,
        paid: 230000.0,
        total: 0.0,
      },
      show: {
        late: true,
        toPay: true,
        paid: true,
        all: true,
        administratives: true,
        processes: true,
      },
      state: {
        late: false,
        toPay: false,
        paid: false,
        all: true,
        current: null,
      },
      modalReceipt: {
        show: false,
        model: null,
      },
      search: null,
      currentTabTitle: "Todas",
      startBalance: 0,
      endBalance: 0,
      moviments: [],
      chartOfAccounts: [],
      bankAccounts: [],
      bankAccountsSelected: [],
      typeOfCostCenters: [],
      typeOfCostCentersSelected: [],
      selectedDateRange: {
        from: moment().startOf("week").toDate(),
        to: moment().endOf("week").toDate(),
      },
    };
  },
  methods: {
    applyFilter() {
      const self = this;
      self.loadMoviments(null);
    },
    clearFilter() {
      const self = this;
      self.selectedDateRange = {
        from: moment().startOf("week").toDate(),
        to: moment().endOf("week").toDate(),
      };
      self.bankAccountsSelected = [];
      self.search = "";
      self.show = {input: false, output: false, all: false};
      self.loadMoviments(null);
    },
    openMoviment(moviment) {
      this.$router.push(
        `/finances/moviments/${this.$route.params.moviments}/update/${moviment.id}`
      );
    },
    manageSelectedMoviments(moviments) {
      const self = this;
      self.selectedMoviments = moviments;

      if (moviments !== null && moviments !== "" && moviments.length > 0) {
        self.buttons.remove = true;
      } else {
        self.buttons.remove = false;
      }
    },
    deleteMoviment(receipt) {
      const self = this;

      AppointmentService.removeReceipt(
        receipt,
        this.$swal,
        function () {
          this.$store.commit("setLoading", {
            show: true,
            label: this.modelTitle,
            description: `Processando ${this.modelTitle}`,
          });
        },
        function () {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          self.$swal("Excluir Baixa", "Baixa excluída com sucesso!", "success");
          EventBus.$emit(
            "Finance.Moviments.Appointments.Form.General.loadModel"
          );
        },
        function () {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          self.$swal(
            "Excluir Baixa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        }
      );
    },
    payAppointment(appointment) {
      const receipt = AppointmentService.createNewModelReceipt(appointment);
      this.modalReceipt.show = true;
      this.modalReceipt.model = receipt;
    },
    openReceipt(receipt) {
      this.modalReceipt.show = true;
      this.modalReceipt.model = receipt;
    },
    closeModalReceipt(type) {
      this.modalReceipt.show = false;

      if (type === "finish") {
        this.applyFilter();
      }
    },

    unpayMoviment(item) {
      const self = this;

      const today = moment(new Date()).add(0, "days");

      swal({
        title: "Você tem certeza?",
        html: "Você realmente deseja cancelar o pagamento que já foi realizado para este lançamento?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-warning",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Não, cancelar!",
      }).then((result) => {
        if (result.value) {
          const moviment = {paid: false, paidValue: null, paymentDate: null};

          Axios.patch(
            `${config.env.API_DEFAULT.host}/moviments/${item.id}`,
            moviment
          )
            .then((response) => {
              item.paid = false;
              item.paidValue = null;
              item.paymentDate = null;
              swal({
                position: "top-right",
                type: "success",
                title: `A ${self.modelTitle} <strong>"${item.description}"</strong> teve o pagamento cancelado com sucesso.`,
                showConfirmButton: false,
                timer: 4000,
              });
            })
            .catch((error) => {
              swal(self.modelTitle, error.message, "error");
            });
        }
      });
    },
    exportData() {
      swal(this.modelTitle, "Função Indisponível", "error");
    },
    print() {
      swal(this.modelTitle, "Função Indisponível", "error");
    },
    duplicateMoviment(item) {
      const self = this;
      swal(self.modelTitle, "Função Indisponível", "error");
    },
    deleteMovimentFromMoviments(item) {
      const self = this;
      self.moviments.forEach((element, index) => {
        if (element === item) {
          self.$delete(self.moviments, index);
        }
      });
    },
    update(newTags) {
      this.chartOfAccounts = [];
      this.tags = newTags;
    },
    loadTypeOfCostCenters() {
      const self = this;

      Axios.get(
        `${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByType?type=COST_CENTER`
      )
        .then((response) => {
          self.$set(
            self,
            "typeOfCostCenters",
            response.data._embedded.movimentTerms
          );
        })
        .catch((error) => {
          self.$swal(
            self.modelTitle,
            "Não foi possível carregar os Centros de Custos.",
            "error"
          );
        });
    },
    loadBanks() {
      const self = this;
      Axios.get(`${config.env.API_DEFAULT.host}/bankAccounts`)
        .then((response) => {
          self.$set(self, "bankAccounts", response.data._embedded.bankAccounts);
        })
        .catch((error) => {
          self.$swal(
            self.modelTitle,
            "Não foi possível carregar as informações do Banco de Origem.",
            "error"
          );
        });
    },
    loadChartOfAccounts() {
      const self = this;
      Axios.get(`${config.env.API_DEFAULT.host}/chartOfAccounts`)
        .then((response) => {
          self.chartOfAccounts = response.data._embedded.chartOfAccounts.map(
            (item) => ({text: item.description})
          );
          // self.$set(self, 'chartOfAccounts', response.data._embedded.chartOfAccounts);
        })
        .catch((error) => {
          self.$swal(
            self.modelTitle,
            "Não foi possível carregar as informações do Banco de Origem.",
            "error"
          );
        });
    },
    loadMoviments(callback) {
      const self = this;
      this.$store.commit("setLoading", {
        show: true,
        label: this.modelTitle,
        description: `Processando ${this.modelTitle}`,
      });

      const from = moment(self.selectedDateRange.from).format(
        "Y-MM-DDT00:00:00"
      );
      const to = moment(self.selectedDateRange.to).format("Y-MM-DDT00:00:00");
      let url = "";

      const data = {
        from: null,
        to: null,
        financeTypes: new Array(),
      };

      if (self.$route.params.moviments === "expenses") {
        url = "/finances/moviments/appointments";
        data.from = from;
        data.to = to;
        data.financeTypes.push("FINANCE_EXPENSE");
        data.financeTypes.push("PROCESS_EXPENSE");
      } else if (self.$route.params.moviments === "incomes") {
        url = "/finances/moviments/appointments";
        data.from = from;
        data.to = to;
        data.financeTypes.push("FINANCE_INCOME");
        data.financeTypes.push("PROCESS_INCOME");
      }

      Axios.post(config.env.API_DEFAULT.host + url, data)
        .then((response) => {
          if (response.status == 200) {
            self.moviments = response.data;
            if (callback !== null) callback();
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          }
        })
        .catch((error) => {
          self.$swal(
            self.modelTitle,
            `As Movimentações de Lançamentos realizadas entre ${moment(
              this.selectedDateRange.from
            ).format("DD/MM/YYYY")} e ${moment(
              this.selectedDateRange.to
            ).format("DD/MM/YYYY")}, não foram carregadas`,
            "error"
          );
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        });
    },
    changeMovimentState(state) {
      if (state === "late") {
        this.state = {
          late: true,
          toPay: false,
          paid: false,
          all: false,
          current: "late",
        };
        this.currentTabTitle = " Em Atraso ";
      } else if (state === "toPay") {
        this.state = {
          late: false,
          toPay: true,
          paid: false,
          all: false,
          current: "toPay",
        };
        this.currentTabTitle = " A Pagar ";
      } else if (state === "paid") {
        this.state = {
          late: false,
          toPay: false,
          paid: true,
          all: false,
          current: "paid",
        };
        this.currentTabTitle = " Pago ";
      } else if (state === "all") {
        this.state = {
          late: false,
          toPay: false,
          paid: false,
          all: true,
          current: "all",
        };
        this.currentTabTitle = " Todas ";
      }
    },
    isTransfers() {
      if (this.$route.params.moviments === "transfers") {
        return true;
      }
      return false;
    },
    loadList() {
      const self = this;

      if (self.$route.params.moviments === "transfers" || self.status.paid) {
        Axios.get(`${config.env.API_DEFAULT.host}/finances/moviments/receipts`)
          .then((response) => {
            self.moviments = response.data;
          })
          .catch((error) => {
            swal(
              self.modelTitle,
              " Não foi possível carregar listagem.",
              "error"
            );
          });
      } else if (
        self.$route.params.moviments != "transfers" ||
        self.status.toPay ||
        self.status.late
      ) {
        Axios.get(
          `${config.env.API_DEFAULT.host}/finances/moviments/appointments`
        )
          .then((response) => {
            self.moviments = response.data;
          })
          .catch((error) => {
            swal(
              self.modelTitle,
              " Não foi possível carregar listagem.",
              "error"
            );
          });
      }
    },

    edit(moviment) {
      if (this.isTransfers) {
        this.$router.push({
          path: `/finances/moviments/transfers/update/${moviment.id}`,
        });
      } else {
        this.$router.push({
          path: `/finances/moviments/${$route.params.moviments}${moviment.id}`,
        });
      }
    },
    deleteModel(moviment) {
      const self = this;

      swal({
        title: "Você tem certeza?",
        text: `Se você excluir a ${moviment.description}, não será possível recuperá-la para uso posterior.`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (self.isTransfers) {
          if (willContinue) {
            Axios.delete(
              `${config.env.API_DEFAULT.host}/receipts/${moviment.id}`
            )
              .then((response) => {
                self.$swal(
                  self.modelTitle,
                  `${self.modelTitle} foi removida c/ sucesso!`,
                  "success"
                );
                self.loadList();
              })
              .catch((error) => {
                if (error.response.status === 409) {
                  self.$swal(
                    self.modelTitle,
                    `Não foi possível excluir a ${self.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                    "error"
                  );
                } else {
                  self.$swal(self.modelTitle, error.message, "error");
                }
              });
          }
        } else if (willContinue) {
          Axios.delete(
            `${config.env.API_DEFAULT.host}/appointments/${moviment.id}`
          )
            .then((response) => {
              self.$swal(
                self.modelTitle,
                `${self.modelTitle} foi removida c/ sucesso!`,
                "success"
              );
              self.loadList();
            })
            .catch((error) => {
              if (error.response.status === 409) {
                self.$swal(
                  self.modelTitle,
                  `Não foi possível excluir a ${self.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                  "error"
                );
              } else {
                self.$swal(self.modelTitle, error.message, "error");
              }
            });
        }
      });
    },
  },
  computed: {
    filteredMoviments() {
      const self = this;

      return self.moviments.filter((moviment) => {
        let foundBankAccountsSelected = true;
        let foundTypeOfCostCentersSelected = true;
        let administrativeMoviment = true;
        let processesMoviment = true;
        let search = true;
        let state = false;

        // Administrative
        if (
          moviment.typeOfMovimentation === "FINANCE_INCOME" ||
          moviment.typeOfMovimentation === "FINANCE_EXPENSE"
        ) {
          administrativeMoviment = self.show.administratives;
        }

        // Processes
        if (
          moviment.typeOfMovimentation === "PROCESS_INCOME" ||
          moviment.typeOfMovimentation === "PROCESS_EXPENSE"
        ) {
          processesMoviment = self.show.processes;
        }

        // Filteres by bank account source
        if (
          self.bankAccountsSelected !== null &&
          self.bankAccountsSelected.length > 0
        ) {
          for (var i = 0; i < self.bankAccountsSelected.length; i++) {
            if (moviment.receipts.length > 0) {
              moviment.receipts.filter((receipt) => {
                if (
                  self.bankAccountsSelected[i].id ===
                  receipt.bankAccountSource.id
                ) {
                  foundBankAccountsSelected = true;
                } else foundBankAccountsSelected = false;
              });
            } else foundBankAccountsSelected = false;
          }
        }

        if (
          self.typeOfCostCentersSelected !== null &&
          self.typeOfCostCentersSelected.length > 0
        ) {
          for (var i = 0; i < self.typeOfCostCentersSelected.length; i++) {
            if (
              self.typeOfCostCentersSelected[i].id ===
              moviment.moviment.typeOfCostCenter.id
            ) {
              foundTypeOfCostCentersSelected = true;
              break;
            } else {
              foundTypeOfCostCentersSelected = false;
            }
          }
        }

        // State Tab
        if (
          self.state.late &&
          moviment.receipts.length === 0 &&
          moment(moment().startOf("day").toDate()).isAfter(moviment.dueDate)
        ) {
          state = true;
        } else if (
          self.state.toPay &&
          moviment.receipts.length === 0 &&
          (moment(moviment.dueDate).isAfter(moment().startOf("day").toDate()) ||
            moment(moviment.dueDate).isSame(moment().startOf("day").toDate()))
        ) {
          state = true;
        } else if (self.state.paid && moviment.receipts.length > 0) {
          state = true;
        } else if (self.state.all) {
          state = true;
        }

        // Filtered by search field
        if (
          self.search !== null &&
          self.search !== "" &&
          moviment.description !== null &&
          moviment.description !== undefined &&
          moviment.description !== undefined
        ) {
          search =
            moviment.description
              .toLowerCase()
              .indexOf(self.search.toLowerCase()) > -1;

          // Chart of Account
          if (
            !search &&
            moviment.moviment.chartOfAccount !== null &&
            moviment.moviment.chartOfAccount.description !== undefined
          ) {
            search =
              moviment.moviment.chartOfAccount.description
                .toLowerCase()
                .indexOf(self.search.toLowerCase()) > -1;
          }

          // Company
          if (
            !search &&
            moviment.moviment.company !== null &&
            moviment.moviment.company.name !== undefined
          ) {
            search =
              moviment.moviment.company.name
                .toLowerCase()
                .indexOf(self.search.toLowerCase()) > -1;
          }

          // typeOfDocument
          if (
            !search &&
            moviment.moviment.typeOfDocument !== null &&
            moviment.moviment.typeOfDocument.description !== undefined
          ) {
            search =
              moviment.moviment.typeOfDocument.description
                .toLowerCase()
                .indexOf(self.search.toLowerCase()) > -1;
          }
        }

        // Filtered by process identification
        if (
          self.search !== null &&
          self.search !== "" &&
          moviment.process !== null &&
          moviment.process !== undefined
        ) {
          search =
            moviment.process.identification
              .toLowerCase()
              .indexOf(self.search.toLowerCase()) > -1;
        }

        return (
          foundBankAccountsSelected &&
          search &&
          state &&
          administrativeMoviment &&
          processesMoviment &&
          foundTypeOfCostCentersSelected
        );
      });
    },
    total() {
      let sum = 0;
      return this.filteredMoviments.reduce(
        (prev, item) => (sum += item.totalValue),
        0
      );

      return sum;
    },
    totalPaid() {
      let sum = 0;
      return this.filteredMoviments.reduce((prev, item) => {
        let sumPaids = 0;
        return (sum += item.receipts.reduce(
          (prev, item) => (sumPaids += item.paidValue),
          0
        ));
      }, 0);

      return sum;
    },
    totalToPay() {
      let sum = 0;
      return this.filteredMoviments.reduce((prev, item) => {
        if (
          item.receipts.length === 0 &&
          (moment(item.dueDate).isAfter(moment().startOf("day").toDate()) ||
            moment(item.dueDate).isSame(moment().startOf("day").toDate()))
        ) {
          return (sum += item.totalValue);
        }
        return (sum += 0);
      }, 0);

      return sum;
    },
    totalLate() {
      let sum = 0;
      return this.filteredMoviments.reduce((prev, item) => {
        if (
          item.receipts.length === 0 &&
          moment(moment().startOf("day").toDate()).isAfter(item.dueDate)
        ) {
          return (sum += item.totalValue);
        }
        return (sum += 0);
      }, 0);

      return sum;
    },
  },
  mounted() {
    $(".finances-dropdown").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });

    if (this.$route.params.moviments === "incomes") {
      this.modelTitle = "Contas a Receber";
      this.mainTitle = "Contas a Receber";
    } else if (this.$route.params.moviments === "expenses") {
      this.modelTitle = "Contas a Pagar";
      this.mainTitle = "Contas a Pagar";
    } else if (this.$route.params.moviments === "transfers") {
      this.modelTitle = "Transferencias";
      this.mainTitle = "Transferencias";
    }

    const self = this;
    self.loadBanks();
    self.loadChartOfAccounts();
    self.loadTypeOfCostCenters();
    self.selectedDateRange = {
      from: moment().startOf("month").toDate(),
      to: moment().endOf("month").toDate(),
    };
    // self.loadMoviments(null);

    EventBus.$on("Finance.Moviments.List.openMoviment", (moviment) => {
      self.openMoviment(moviment);
    });

    EventBus.$on("Finance.Moviments.List.openReceipt", (receipt) => {
      self.openReceipt(receipt);
    });

    EventBus.$on(
      "Finance.Moviments.List.manageSelectedMoviments",
      (moviments) => {
        self.manageSelectedMoviments(moviments);
      }
    );

    EventBus.$on("Finance.Moviments.List.deleteMoviment", (moviment) => {
      self.deleteMoviment(moviment);
    });

    EventBus.$on("Finance.Moviments.List.duplicateMoviment", (moviment) => {
      self.duplicateMoviment(moviment);
    });

    EventBus.$on("Finance.Moviments.List.payAppointment", (moviment) => {
      self.payAppointment(moviment);
    });

    EventBus.$on("Finance.Moviments.List.unpayMoviment", (moviment) => {
      self.unpayMoviment(moviment);
    });

    setTimeout(() => {
      self.applyFilter();
    }, 1000);
  },
};
</script>
<style>
.date-pickers .mx-datepicker {
  padding: none;
  border: none;
}

.date-pickers .mx-datepicker {
  width: 120px;
}

.date-pickers input {
  padding: 20px 15px;
}

.date-pickers .fromPickedDate .mx-datepicker-popup {
  right: inherit !important;
}
</style>
