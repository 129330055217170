<template>
  <div class="pagination pv-2 ph-2">
    <div class="flex ai-c jc-sb fgap2 list-pages">
      <button
          type="button"
          :disabled="currentPage <= 1"
          @click.prevent="changePage(currentPage - 1)"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevrons-left"
        >
          <polyline points="11 17 6 12 11 7"></polyline>
          <polyline points="18 17 13 12 18 7"></polyline>
        </svg>
        <span class="ml-1">Página Anterior</span>
      </button>

      <ul class="flex ai-c fgap1">
        <li
            v-for="(page, index) in getPagesArray"
            :key="index"
            :class="[page === currentPage ? 'active' : '']"
        >
          <button type="button" @click.prevent="changePage(page)">
            {{ page }}
          </button>
        </li>
      </ul>

      <button
          type="button"
          :disabled="currentPage === lastPage"
          @click.prevent="changePage(currentPage + 1)"
      >
        <span class="mr-1">Próxima Página</span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevrons-right"
        >
          <polyline points="13 17 18 12 13 7"></polyline>
          <polyline points="6 17 11 12 6 7"></polyline>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTablePagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
      required: true,
    },
    lastPage: {
      type: Number,
      default: 1,
      required: true,
    },
    total: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  methods: {
    changePage(page) {
      this.$emit('page-change', page);
    },
  },
  computed: {
    getPagesArray() {
      let startPage;
      let endPage;
      if (this.lastPage <= 5) {
        startPage = 1;
        endPage = this.lastPage;
      } else {
        const maxPagesBeforeCurrentPage = 2;
        const maxPagesAfterCurrentPage = 2;
        if (this.currentPage <= maxPagesBeforeCurrentPage) {
          startPage = 1;
          endPage = 5;
        } else if (
          this.currentPage + maxPagesAfterCurrentPage
            >= this.lastPage
        ) {
          startPage = this.lastPage - 5 + 1;
          endPage = this.lastPage;
        } else {
          startPage = this.currentPage - maxPagesBeforeCurrentPage;
          endPage = this.currentPage + maxPagesAfterCurrentPage;
        }
      }
      const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i,
      );
      return pages;
    },
  },
};
</script>

<style scoped>
.pagination {
  display: block;
}

.pagination .list-pages ul {
  border-radius: 6px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination .list-pages ul li button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

.pagination .list-pages ul li.active button,
.pagination .list-pages ul li:hover button {
  background-color: #F0F8FF;
  color: #0080FF;
}

.pagination .list-pages > button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  color: #343A40;
}

.pagination .list-pages > button:hover {
  color: var(--blue);
}
</style>
