<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 36L21 13H27L33 36L24 44L15 36Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M21 4H27L30 6L27 13H21L18 6L21 4Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ConfigMemberIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
