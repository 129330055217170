<template>
  <form id="formInsurance" class="form-layout form-layout-2 form-dates-sidebar">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 d-block">Seguro</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Informações de Seguro do Processo.
        </p>
      </div>
      <div>
        <button class="btn btn-info" @click.prevent="notifyCustomBroker">
          <i class="fa fa-envelope mr-2"></i> INFORMAR SEGURO AO DESPACHANTE
        </button>
      </div>
    </div>
    <div class="alert alert-warning">
      <span><i class="fa fa-exclamation-triangle"></i> <strong>Atenção: </strong>Os valores de <strong>Despesas</strong> e <strong>Lucros</strong> serão calculados somente caso estejam zerados.</span>
    </div>
    <div v-if="insuranceTabIsBlocked">
      <div class="alert alert-warning" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <strong>Campos desabilitados!</strong> Após o preenchimento das informação da Declaração de Importação, não é
        mais possível alterar as informações do seguro.
      </div>
    </div>
    <div class="card processes-container">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a class="nav-link active" @click="tab = 'tab-seguro-dados'" href="#tab-seguro-dados" data-toggle="tab">
              <i class="fas fa-file-invoice-dollar mg-r-2" aria-hidden="true"></i> Seguro
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="tab = 'tab-seguro-historico'" href="#tab-seguro-historico" data-toggle="tab">
              <i class="fa fa-history mg-r-2"></i> Histórico
            </a>
          </li>
        </ul>
      </div>
       <div class="card-body">
        <div class="tab-content ht-100p">
          <div class="tab-pane"  v-bind:class="{ active: tab === 'tab-seguro-dados' }" id="tab-seguro-dados">
            <div class="mt-2">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-r-0-force mg-md-t--1 ">
                    <label class="form-control-label">Porcentagem </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-percent"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <input type="number"
                              class="form-control"
                              name="insurancePercentage"
                              v-model="form.insurancePercentage"
                              :disabled="insuranceTabIsBlocked && !checkIfUserCanViewPercentage"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-r-0-force mg-md-t--1 ">
                    <label class="form-control-label">Valor FOB </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                              class="form-control"
                              name="fobValue"
                              v-model="form.fobValue"
                              :disabled="insuranceTabIsBlocked">
                        </money>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mg-md-t--1 ">
                    <label class="form-control-label">Valor total de Frete </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                              class="form-control"
                              name="freightTotalValue"
                              v-model="form.freightTotalValue"
                              :disabled="insuranceTabIsBlocked">
                        </money>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1 ">
                    <label class="form-control-label">Valor total de impostos</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                              class="form-control"
                              name="taxesTotalValue"
                              v-model="form.taxesTotalValue"
                              :disabled="insuranceTabIsBlocked">
                        </money>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force mg-md-t--1 ">
                    <label class="form-control-label">Despesas </label>
                    <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-money op-5"></i>
                        </div>
                        <div class="input-group-solved-center">
                          <money v-bind="money"
                                  class="form-control"
                                  name="expensesPercentage"
                                  v-model="form.expensesPercentage"
                                  :disabled="insuranceTabIsBlocked">
                          </money>
                        </div>
                        <div class="mg-l-auto text-right" v-if="form.lastExpensesPercentageCalculated && form.lastExpensesPercentageCalculated !== 0">
                          <span style="color: #343a40;">Padrão do Cálculo:</span> <strong style="color: #343a40;">R$ {{ this.formatMoney(form.lastExpensesPercentageCalculated) }}</strong>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force mg-md-t--1">
                    <label class="form-control-label">Lucros </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                                class="form-control"
                                name="profitsPercentage"
                                v-model="form.profitsPercentage"
                                :disabled="insuranceTabIsBlocked">
                        </money>
                      </div>
                      <div class="mg-l-auto text-right" v-if="form.lastProfitsPercentageCalculated && form.lastProfitsPercentageCalculated !== 0">
                        <span style="color: #343a40;">Padrão do Cálculo:</span> <strong style="color: #343a40;">R$ {{ this.formatMoney(form.lastProfitsPercentageCalculated) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force mg-md-t--1 bg-gray-200">
                    <label class="form-control-label">Importância total do Seguro </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                              class="form-control"
                              name="totalAmountInsurance"
                              v-model="form.totalAmountInsurance" disabled>
                        </money>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1 bg-gray-200">
                    <label class="form-control-label">Prêmio </label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money v-bind="money"
                              class="form-control"
                              name="insuranceAward"
                              v-model="form.insuranceAward"
                              disabled>
                        </money>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force mg-md-t--1 ">
                    <label class="form-control-label">Descrição da mercadoria</label>
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-center wd-150">
                        <input class="form-control wd-200" type="text" name="productDescription"
                              v-model="form.productDescription" :disabled="insuranceTabIsBlocked">
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end mt-3 mr-1" v-if="!insuranceTabIsBlocked">
                <button @click.prevent="calculateValues" class="btn btn-success mr-2"><i class="fas fa-calculator mr-1"></i> CALCULAR O SEGURO </button>
              </div>
            </div>
          </div>
          <div class="tab-pane"  v-bind:class="{ active: tab === 'tab-seguro-historico' }" id="tab-seguro-historico">
            <div v-if="logs.content && logs.content.length > 0">
              <config-table-s-s-r
                @pageChange="getLogs"
                :current-page="logs.number + 1"
                :last-page="logs.totalPages"
                :table-config="{
                  useSearch: false,
                  centeredCell: false,
                  showRecords: true,
                  paginationTop: false,
                }"
                :tableData="logs.content"
                :columns="[
                  {
                    label: 'DATA DE CADASTRO',
                    field: 'created',
                  },
                  {
                    label: 'USUÁRIO',
                    field: 'createdBy',
                  },
                  {
                    label: 'AÇÕES',
                    field: 'actions',
                    class: 'text-right',
                  },
                ]"
              >
                <template #created="{ item }">
                  <p>{{ formatDate(item.created) }}</p>
                </template>
                <template #actions="{ item }">
                  <div class="actions-table jc-fe" v-if="item.items && item.items.length > 0">
                    <a class="edit-button" href="#" @click.prevent="viewLog(item)">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="text-right" v-else>Nenhuma alteração identificada nos valores.</div>
                </template>
              </config-table-s-s-r>
              <v-modal
                :width="600"
                :height="0"
                :handler="modalOpen"
                :title="`Alterações Registradas`"
                description=""
                @requestClose="closeModal"
              >
                <template #content>
                  <div v-if="logToView.items.length === 1" class="flex fd-c text-center jc-c" style="flex-flow: column-nowrap;">
                    <div class="mb-5">
                      <NotDateIcon :sizeWidth="250" :sizeHeight="250"/>
                    </div>
                    <h5>Os dados foram salvos, mas nenhuma modificação foi detectada.</h5>
                  </div>
                  <div v-if="logToView.items.length > 1">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Campo</th>
                        <th class="text-right">Valor Anterior</th>
                        <th class="text-right">Valor Atual</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in logToView.items.filter(ob => ob.field !== 'lastExpensesPercentageCalculated' && ob.field !== 'lastProfitsPercentageCalculated')" :key="index">
                        <td>
                          <span v-if="item.field === 'insurancePercentage'">Porcentagem</span>
                          <span v-if="item.field === 'fobValue'">FOB</span>
                          <span v-if="item.field === 'freightTotalValue'">Frete</span>
                          <span v-if="item.field === 'taxesTotalValue'">Impostos</span>
                          <span v-if="item.field === 'expensesPercentage'">Despesas</span>
                          <span v-if="item.field === 'profitsPercentage'">Lucros</span>
                          <span v-if="item.field === 'totalAmountInsurance'">Importância Total</span>
                          <span v-if="item.field === 'insuranceAward'">Prêmio</span>
                          <span v-if="item.field === 'productDescription'">Descrição do produto</span>
                        </td>
                        <td class="text-right">
                          <span v-if="
                            item.field === 'fobValue'
                            || item.field === 'freightTotalValue'
                            || item.field === 'taxesTotalValue'
                            || item.field === 'expensesPercentage'
                            || item.field === 'profitsPercentage'
                            || item.field === 'totalAmountInsurance'
                            || item.field === 'insuranceAward'
                          ">
                            R$ {{ item.oldValue != 'null' ? formatMoney(item.oldValue) : '0,00'}}
                          </span>
                          <span v-else>{{ item.oldValue != 'null' ? item.oldValue : '-' }}</span>
                        </td>
                        <td class="text-right">
                          <span :class="parseInt(item.newValue) !== parseInt(item.oldValue) ? 'text-danger' : ''" 
                            v-if="
                              item.field === 'fobValue'
                              || item.field === 'freightTotalValue'
                              || item.field === 'taxesTotalValue'
                              || item.field === 'expensesPercentage'
                              || item.field === 'profitsPercentage'
                              || item.field === 'totalAmountInsurance'
                              || item.field === 'insuranceAward'
                            ">
                            R$ {{  item.newValue != 'null' ? formatMoney(item.newValue) : '0,00'}}
                          </span>
                          <span v-else>{{ item.newValue != 'null' ? item.newValue : '-' }}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </v-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Axios from "axios";
import {Money} from "v-money";
import {mapState, mapActions} from "vuex";
import Swal from "sweetalert2";
import config from "@/config";
import ConfigTableSSR from "@/components/Configuration/ConfigTableSSR.vue";
import moment from "moment";
import VModal from "@/components/VModal.vue";
import NotDateIcon from '@/components/icons/NotDateIcon.vue';

export default {
  components: {
    VModal,
    Money,
    ConfigTableSSR,
    NotDateIcon
  },
  data() {
    return {
      tab: "tab-seguro-dados",
      money: {decimal: ",", thousands: ".", precision: 2, masked: false},
      model: {},
      logs: {},
      modalOpen: false,
      form: {
        insurancePercentage: null,
        fobValue: 0,
        freightTotalValue: 0,
        taxesTotalValue: 0,
        expensesPercentage: 0,
        lastExpensesPercentageCalculated: null,
        profitsPercentage: 0,
        lastProfitsPercentageCalculated: null,
        totalAmountInsurance: 0,
        insuranceAward: 0,
        productDescription: null,
      }
    }
  },
  computed: {
    ...mapState({
      process: state => state.ProcessStore.activeProcess
    }),
    ...mapActions("ProcessStore", [
      "findProcessById"
    ]),
    insuranceTabIsBlocked() {
      return (this.model.diNumber !== null && this.model.diNumber !== '')
        || (this.model.diRegistryDate !== null && this.model.diRegistryDate !== '')
        || (this.model.diResourcefulnessDate !== null && this.model.diResourcefulnessDate !== '')
        || (this.model.diProtocol !== null && this.model.diProtocol !== '');
    },
    checkIfUserCanViewPercentage() {
      const authUser = this.$store.state.credentials.data.person;

      return authUser.email === 'silvia@primeinternacional.com.br'
        || authUser.email === 'ana.eliza@primeinternacional.com.br'
        || authUser.email === 'thayse@primeinternacional.com.br'
        || authUser.email === 'maicon@primeinternacional.com.br'
        || authUser.email === 'matheus@primeinternacional.com.br'
        || authUser.email === 'allysson@primeinternacional.com.br';
    }
  },
  methods: {
    viewLog(log) {
      this.modalOpen = true;

      this.logToView = log;
    },
    closeModal() {
      this.modalOpen = false;
      this.logToView = null;
    },
    async calculateValues() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Calcular valores do Seguro",
        description: "Aguarde enquanto estamos calculando os valores do seguro...",
      });

      try {

        const response = await Axios.post(config.env.API_DEFAULT.host + '/insurance-registration-automation/calculate', this.form);

        this.$store.commit("setLoading", {
          show: false,
        });

        const result = await Swal.fire({
          title: "Calcular Valores do Seguro",
          width: 500,
          html: `
            <div style="width: 100%;" class="flex jc-c">
              <div>
                <div class="text-left">
                  <p class="mg-b-2 text-nm">Confira os valores abaixo antes de salvar no sistema: </p>
                </div>
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th class="text-left" style="padding: 0.75rem;">Informação</th>
                            <th class="text-right" style="padding: 0.75rem;">Valor</th>
                        </tr>
                    </thead>
                    <tbody class="text-sm">
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Porcentagem</td>
                            <td class="text-right" style="padding: 0.75rem;">${response.data.data.insurancePercentage}%</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Fob</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.fobValue)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Frete</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.freightTotalValue)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Impostos</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.taxesTotalValue)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Despesas</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.expensesPercentage)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Lucros</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.profitsPercentage)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Importância Total</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.totalAmountInsurance)}</td>
                        </tr>
                        <tr>
                            <td class="text-left" style="padding: 0.75rem;">Prêmio</td>
                            <td class="text-right" style="padding: 0.75rem;">R$ ${this.formatMoney(response.data.data.insuranceAward)}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          `,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Salvar",
          cancelButtonText: "Não",
        });

        if (result.value) {
          this.form.fobValue = response.data.data.fobValue;
          this.form.freightTotalValue = response.data.data.freightTotalValue;
          this.form.taxesTotalValue = response.data.data.taxesTotalValue;
          this.form.expensesPercentage = response.data.data.expensesPercentage;
          this.form.lastExpensesPercentageCalculated = response.data.data.lastExpensesPercentageCalculated;
          this.form.profitsPercentage = response.data.data.profitsPercentage;
          this.form.lastProfitsPercentageCalculated = response.data.data.lastProfitsPercentageCalculated;
          this.form.totalAmountInsurance = response.data.data.totalAmountInsurance;
          this.form.insuranceAward = response.data.data.insuranceAward;

          await this.saveValues();
        }

      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire({
          title: "Calcular Valores do Seguro",
          text: "Erro inesperado ao calcular valores do seguro!",
          type: "error",
        });
      }
    },
    formatMoney(value) {
      return new Intl.NumberFormat('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 }).format(value);
    },
    async saveValues() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Salvar valores do Seguro",
          description: "Aguarde enquanto estamos salvando os valores do seguro...",
        });

        const response = await Axios.post(config.env.API_DEFAULT.host + '/insurance-registration-automation/save-values/' + this.model.id, this.form);

        await this.getLogs();
        this.$store.dispatch(
          "ProcessStore/findProcessById",
          this.model.id
        );

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire({
          title: "Salvar valores do Seguro",
          text: "Valores do seguro salvos com sucesso!",
          type: "success",
        });

      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire({
          title: "Salvar valores do Seguro",
          text: "Erro inesperado ao salvar valores do seguro!",
          type: "error",
        });
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY [às] HH:mm:ss");
    },
    async getLogs() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Logs do Seguro",
        description: "Aguarde enquanto estamos buscando os logs de alteração do seguro.",
      });

      try {
        this.$store.commit("setLoading", {
          show: false,
        });

        const response = await Axios.get(config.env.API_DEFAULT.host + '/general-logs', {
          params: {
            processId: this.process.id,
            type: 'PROCESS_DATA_INSURANCE_UPDATE'
          }
        })

        this.logs = response.data.data;
      } catch (e) {
        Swal.fire({
          title: "Logs do Seguro",
          text: "Houve um erro inesperado ao tentar buscar os logs de alteração do seguro!",
          type: "error",
        });

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async notifyCustomBroker() {
      try {
        const result = await Swal.fire({
          title: "Notificar Despachante",
          text: "Você realmente deseja enviar uma notificação ao despachante referente aos valores de seguro?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, enviar!",
          cancelButtonText: "Não",
        });

        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Notificar Despachante",
            description: "Aguarde enquanto estamos enviando as informações do seguro para o despachante...",
          });

          const response = await Axios.post(config.env.API_DEFAULT.host + '/insurance-registration-automation/notify-custom-broker/' + this.process.id);

          Swal.fire({
            title: "Notificar Despachante",
            text: "O Despachante foi notificado com sucesso!",
            type: "success",
          });

          this.$store.commit("setLoading", {
            show: false,
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Notificar Despachante",
          html: `Houve um erro inesperado ao tentar notificar o despachante: <br> <strong>${e.response.data.data}</strong>`,
          type: "error",
        });

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    }
  },
  async mounted() {
    this.form.insurancePercentage = this.model.insurancePercentage ? this.model.insurancePercentage : 0.25;
    this.form.fobValue = this.model.fobValue ? this.model.fobValue : 0;
    this.form.freightTotalValue = this.model.freightTotalValue ? this.model.freightTotalValue : 0;
    this.form.taxesTotalValue = this.model.taxesTotalValue ? this.model.taxesTotalValue : 0;
    this.form.expensesPercentage = this.model.expensesPercentage ? this.model.expensesPercentage : 0;
    this.form.lastExpensesPercentageCalculated = this.model.lastExpensesPercentageCalculated ? this.model.lastExpensesPercentageCalculated : 0;
    this.form.profitsPercentage = this.model.profitsPercentage ? this.model.profitsPercentage : 0;
    this.form.lastProfitsPercentageCalculated = this.model.lastProfitsPercentageCalculated ? this.model.lastProfitsPercentageCalculated : 0;
    this.form.totalAmountInsurance = this.model.totalAmountInsurance ? this.model.totalAmountInsurance : 0;
    this.form.insuranceAward = this.model.insuranceAward ? this.model.insuranceAward : 0;
    this.form.productDescription = this.model.productDescription ? this.model.productDescription : 0;

    await this.getLogs();
  },
  created() {
    this.model = {...this.process}
    this.initialModel = {...this.process};
  },
}
</script>

<style scoped>
.input-group-solved-center {
  max-width: 110px !important;
}
</style>