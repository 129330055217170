<template>
  <div
    class="
      cont-filter
      container
      bg-white
      pd-h-10 pd-t-20 pd-b-20
      d-flex
      align-items-center
    "
  >
    <span class="material-icons-outlined icon"> settings_input_component </span>

    <div class="input-comp input-comp-limited">
      <label class="form-control-label">CLIENTES</label>
      <v-select v-model="selectedTeams" placeholder="Selecione uma opção" label="name" :options="customers" name="team"
                multiple>
        <template slot="option" slot-scope="option">
          {{ option.name }} <small>({{ formatCustomerCnpj(option.federalID) }})</small>
        </template>
      </v-select>
    </div>

    <div class="input-comp">
      <label for="grupo">EQUIPE / GRUPO</label>
      <select v-model="selectedOperationalTeam" name="grupo" id="grupo">
        <option value="" selected>Selecione uma opção</option>
        <option :value="item.id" v-for="item in operationalTeams" :key="item.id">{{ item.name }}</option>
      </select>
    </div>

    <div class="input-comp date" v-if="!modal">
      <label for="etapas">DATA INICIAL</label>
      <date-picker lang="pt-br"
                   name="data-inicial"
                   value=""
                   v-model="firstDate"
                   class="input-date"
                   format="DD/MM/YY"

                   clear-button="true"
                   placeholder="Selecione"
      ></date-picker>
    </div>

    <div class="input-comp date" v-if="!modal">
      <label for="etapas">DATA FINAL</label>
      <date-picker lang="pt-br"
                   name="data-final"
                   value=""
                   v-model="secondDate"
                   class="input-date"
                   format="DD/MM/YY"

                   clear-button="true"
                   placeholder="Selecione"
      ></date-picker>
    </div>

    <div>
      <button type="button" class="filter-btn" @click="filtrarIndicadores">
        <span class="material-icons-outlined"> cached </span>
        ATUALIZAR
      </button>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';

import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'GeneralIndicatorsFilter',
  data() {
    return {
      firstDate: '',
      secondDate: '',
      customers: [],
      operationalTeams: [],
      selectedTeams: [],
      selectedOperationalTeam: 0,
      selectedGroup: '',
      search: '',
    };
  },
  components: {
    DatePicker,
  },
  mounted() {
    this.getCustomers();
    this.getOperationalTeams();

    this.firstDate = this.firstDateFilter ? this.firstDateFilter : `${moment().startOf('month').format('Y-MM-DD')}T00:00:00`;
    this.secondDate = this.secondDateFilter ? this.secondDateFilter : `${moment().format('Y-MM-DD')}T23:59:00`;
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      firstDateFilter: (state) => state.ControlPanelStore.firstDateFilter,
      secondDateFilter: (state) => state.ControlPanelStore.secondDateFilter,
    }),
  },
  methods: {
    ...mapActions(
      'ControlPanelStore',
      [
        'getTotalLiberacao',
        'getTotalRegistroDI',
        'getTotalCarregamento',
        'getTotalFaturamento',
        'getTotalEncerramento',
        'getIndicadoresCanais',
        'getIndicadoresPeriodosArmazenagem',
        'getAberturasPeriodo',
        'defineFirstDateFilter',
        'defineSecondDateFilter',
        'defineCustomersIdFilter',
        'defineTeamIdFilter',
        'getIndicadoresMovimentacaoProcessual',
        'getIndicadorInconsistencias',
        'getIndicadoresOperacionaisProducao',
        'getIndicadoresOperacionaisBooking',
        'getIndicadoresOperacionaisTransito',
        'getIndicadoresOperacionaisDesembaraco',
        'getIndicadoresOperacionaisCarregamento',
        'getIndicadoresOperacionaisPendencias',
        'getIndicadoresOperacionaisEncerramento',
        'getIndicadoresOperacionaisGeral',
      ],
    ),
    filtrarIndicadores() {
      if (this.selectedOperationalTeam != null && this.selectedOperationalTeam != 'undefined' && this.selectedOperationalTeam != '') {
        this.defineTeamIdFilter({teamId: this.selectedOperationalTeam});
      } else {
        this.defineTeamIdFilter({teamId: 0});
      }

      if (this.selectedTeams.length > 0) {
        this.defineCustomersIdFilter({customersId: this.selectedTeams.map((i) => i.id)});
      } else {
        this.defineCustomersIdFilter({customersId: []});
      }

      if (this.firstDate != null && this.firstDate != 'undefined' && this.firstDate != '') {
        this.defineFirstDateFilter({firstDate: `${moment(this.firstDate).format('Y-MM-DDT')}00:00:00`});
      } else {
        this.defineFirstDateFilter({firstDate: `${moment().startOf('month').format('Y-MM-DD')}T00:00:00`});
      }

      if (this.secondDate != null && this.secondDate != 'undefined' && this.secondDate != '') {
        this.defineSecondDateFilter({secondDate: `${moment(this.secondDate).format('Y-MM-DDT')}00:00:00`});
      } else {
        this.defineSecondDateFilter({secondDate: `${moment().format('Y-MM-DD')}T23:59:00`});
      }

      this.getTotalLiberacao({teamId: 0});
      this.getTotalRegistroDI({teamId: 0});
      this.getTotalCarregamento({teamId: 0});
      this.getTotalFaturamento({teamId: 0});
      this.getTotalEncerramento({teamId: 0});
      this.getIndicadoresCanais({teamId: 0});
      this.getIndicadoresPeriodosArmazenagem({teamId: 0});
      this.getAberturasPeriodo({teamId: 0});
      this.getIndicadoresMovimentacaoProcessual({teamId: 0});
      this.getIndicadorInconsistencias({teamId: 0});

      // Filtrar indicadores operacionais
      this.filtroIndicadoresOperacionais();
    },
    filtroIndicadoresOperacionais() {
      this.getIndicadoresOperacionaisProducao();
      this.getIndicadoresOperacionaisBooking();
      this.getIndicadoresOperacionaisTransito();
      this.getIndicadoresOperacionaisDesembaraco();
      this.getIndicadoresOperacionaisCarregamento();
      this.getIndicadoresOperacionaisPendencias();
      this.getIndicadoresOperacionaisEncerramento();
      this.getIndicadoresOperacionaisGeral();
    },
    getOperationalTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
        this.operationalTeams = response.data._embedded.teams;
      });
    },
    formatCustomerCnpj(value) {
      return String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },
    async getCustomers() {
      const response = await Axios.get(
        `${config.env.API_DEFAULT.host
        }/companies/search/findAllByIsCustomerAndNameContainingIgnoreCase?isCustomer=true&name=${
          this.search}`,
      );
      this.customers = response.data._embedded.companies;
    },
  },
};
</script>

<style scoped>
.cont-filter {
  gap: 20px;
}

.input-comp {
  flex: 1 1 auto;
}

.input-comp-limited {
  flex: 0 1 33%;
}

.input-comp.date {
  flex: 0 1 auto;
}

.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}

.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}

.icon {
  color: var(--gray-dark);
}
</style>

<style>
body .input-comp .dropdown-toggle {
  display: flex;
  border: 0 !important;
  border-bottom: 1px solid var(--gray-dark) !important;
  min-height: 35px !important;
  border-radius: 0 !important;
}

.input-comp .v-select .open-indicator:before {
  border-color: black;
  border-width: 2px 2px 0 0;
  width: 7px;
  height: 7px;
}

.input-comp .dropdown-toggle input::placeholder {
  color: black;
}

.input-comp .mx-input-append::before {
  display: none;
}

.input-comp .mx-input-append {
  line-height: 0.5;
}

.input-comp .mx-input-wrapper .mx-clear-wrapper {
  margin-top: -3px !important;
  background-color: white !important;
  z-index: 9;
}

body .input-comp .mx-input-append svg {
  display: block !important;
}
</style>
