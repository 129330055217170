export default class Receipt {
  constructor(
    id,
    index,
    description,
    typeOfMovimentation,
    originalValue,
    discountValue,
    fineValue,
    additionsValue,
    paymentDate,
    paidValue,
    typeOfPayment,
    bankAccountSource,
    bankAccountDestination,
    company,
    account,
    appointment,
    moviment,
  ) {
    this.id = id;
    this.index = index;
    this.description = description;
    this.typeOfMovimentation = typeOfMovimentation;
    this.originalValue = originalValue;
    this.discountValue = discountValue;
    this.fineValue = fineValue;
    this.additionsValue = additionsValue;
    this.paymentDate = paymentDate;
    this.paidValue = paidValue;
    this.typeOfPayment = typeOfPayment;
    this.bankAccountSource = bankAccountSource;
    this.bankAccountDestination = bankAccountDestination;
    this.company = company;
    this.account = account;
    this.appointment = appointment;
    this.moviment = moviment;
  }
}
