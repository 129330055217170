<template>
  <Drawer @close="$emit('closeDrawer')">
    <template slot="header">Log Follow UP</template>

    <template slot="body">
      <div class="card card-activities">
        <h6 class="slim-card-title">Log dos Follow UPs Enviados</h6>
        <p>Foram encontrados {{ listOfLog.length }} logs de Follow UPs</p>

        <perfect-scrollbar class="imp-form-drawers-followups-logs-scrollbar">
          <div class="media-list" v-for="log in listOfLog" :key="log.id">
            <div class="media">
              <div
                class="activity-icon"
                :class="{
                  'bg-success': log.viewedDate != null,
                  'bg-warning': log.viewedDate == null,
                }"
              >
                <i class="icon ion-email"></i>
              </div>
              <div class="media-body">
                <h6>
                  {{ log.person }} <small>&lt;{{ log.emailTo }}&gt;</small>
                </h6>
                <p class="mg-b-0 tx-medium tx-gray-700">
                  {{ log.description }}
                </p>
                <p class="mg-b-0 tx-gray-700" v-if="log.viewedDate !== null">
                  <i class="icon ion-eye mg-r-3"></i> Aberto em
                  {{ log.viewedDate | moment("DD \\d\\e MMMM \\d\\e YYYY") }} às
                  {{ log.viewedDate | moment("H:mm:ss") }}
                  <small v-if="log.viewedTimes > 1"
                    >(Visualizado {{ log.viewedTimes }} vezes, última
                    visualização
                    {{ log.lastViewedDate | moment("from", "now") }} )</small
                  >
                </p>
                <span
                  ><i class="icon ion-calendar mg-r-3"></i> Enviado em
                  {{ log.created | moment("DD \\d\\e MMMM \\d\\e YYYY") }} às
                  {{ log.created | moment("H:mm:ss") }} por {{ log.createdBy }}
                </span>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </template>

    <template slot="footer">
      <button
        type="button"
        @click="$emit('closeDrawer')"
        class="btn btn-primary"
      >
        Fechar
      </button>
    </template>
  </Drawer>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import Drawer from "@/components/Drawer.vue";
import { EventBus } from "@/events/EventBus.js";

export default {
  name: "imp-form-drawers-followups-logs",
  props: [],
  components: {
    Drawer,
  },
  data() {
    return {
      model: null,
      listOfLog: [],
    };
  },
  methods: {
    load() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Log dos Follow UPs",
        description: "Carregando...",
      });

      if (this.process.id !== null && this.process.id !== undefined) {
        Axios.get(
          // config.env.API_DEFAULT.host + "/followUPLogs/search/findAllByImportProcess_IdByOrderByCreatedDesc?id=" + this.process.id
          `${config.env.API_DEFAULT.host}/follow-up-logs/process/${this.process.id}`
        )
          .then((response) => {
            // this.listOfLog = response.data._embedded.followUPLogs;
            this.listOfLog = response.data.data;
          })
          .catch((error) => {
            Swal.fire(
              "Log de Follow UPs",
              "Não foi possível carregar o Log de Follow UPs.",
              "error"
            );
          });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };

      this.load();
    },
  },
  created() {
    this.model = {
      ...this.process,
    };

    EventBus.$on("Operational.imp.drawers.FollowUPLogs.load", () => {
      this.load();
    });
  },
  mounted() {
    if (this.process.id !== null && this.process.id !== undefined) {
      this.load();
    }
  },
};
</script>

<style scoped>
.imp-form-drawers-followups-logs-scrollbar {
  height: 70vh;
  overflow: auto;
}
</style>
