<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2922 21.6755C15.2922 21.6755 22.5415 14.4263 24.3538 12.614C26.1661 10.8016 27.0722 6.27088 23.9007 3.09931C20.7291 -0.0722346 16.1984 0.83392 14.386 2.64623C12.5737 4.45852 3.0591 13.9732 2.15294 14.8794C1.24678 15.7855 -0.112451 18.9571 2.60602 21.6755C5.32448 24.394 8.49607 23.0348 9.40221 22.1286C10.3084 21.2225 20.2761 11.2547 21.1822 10.3486C22.0884 9.4424 22.5415 7.17703 21.1822 5.81781C19.823 4.45852 17.5576 4.9116 16.6515 5.81781C15.7453 6.72396 8.04299 14.4263 8.04299 14.4263"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AttachmentIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
