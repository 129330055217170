<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Procedimentos RFB</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div
        class="card-header bg-grandeur d-flex align-items-center justify-content-between pd-y-5 tx-white bd bd-b-0"
        v-if="model.numeroRegistro === null"
      >
        <h6 class="mg-b-0 tx-16 tx-uppercase">
          Declaração de Importação #{{
            model.identificacaoDeclaracaoImportacao
          }}
          <i class="fa fa-calendar mg-r-3 tx-white mg-l-15 op-9"></i>
          <span class="tx-thin"> Digitação: </span>
          {{ model.criacao | moment("DD/MM/YYYY") }}
        </h6>
        <div class="card-option tx-18 tx-thin justify-content-between">
          <div class="btn-group pd-0" role="group" aria-label="Ações da DI">
            <button
              v-if="model.numeroRegistro === null"
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10"
              @click.prevent="gerarCalculos()"
            >
              <i class="icon ion-refresh mg-r-5"></i> ATUALIZAR
            </button>
            <!-- <button v-if="model.numeroRegistro === null" type="button" class="btn btn-sm mg-r-10 mg-t-10 mg-b-10" @click.prevent="sincronizarIntegracao()"> <i class="fa fa-upload mg-r-5"></i> SINCRONIZAR </button> -->
            <button
              v-if="model.numeroRegistro === null"
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10 tx-danger"
              @click.prevent="gerarXMLTransmissao('2')"
            >
              <i class="fa fa-file-code-o mg-r-5"></i> XML REGISTRO
            </button>
            <button
              v-if="model.numeroRegistro === null"
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10"
              @click.prevent="gerarXMLTransmissao('1')"
            >
              <i class="fa fa-file-code-o mg-r-5"></i> XML DIAGNÓSTICO
            </button>
            <button
              v-if="model.numeroRegistro === null"
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10"
              @click.prevent="printDemonstrative()"
            >
              <i class="fa fa-file-pdf-o mg-r-5"></i> PDF
            </button>
            <!-- <button v-if="model.numeroRegistro === null" type="button" class="btn btn-sm mg-r-10 mg-t-10 mg-b-10" @click.prevent="onSubmit()"> <i class="fa fa-save v"></i> SALVAR </button> -->
          </div>
        </div>
      </div>
      <div
        v-else
        class="card-header bg-teal d-flex align-items-center justify-content-between pd-y-5 tx-white bd bd-b-0"
      >
        <h6 class="mg-b-0 tx-16 tx-uppercase">
          Declaração de Importação #{{
            model.identificacaoDeclaracaoImportacao
          }}
          <i class="fa fa-calendar mg-r-3 tx-white mg-l-15 op-9"></i>
          <span class="tx-thin"> Digitação: </span>
          {{ model.criacao | moment("DD/MM/YYYY") }}
          <i class="icon ion-flag mg-r-3 tx-white mg-l-15 op-9"></i>
          <span class="tx-thin">Nº de Registro: </span>
          {{ model.numeroRegistro }}
          <i class="fa fa-calendar mg-r-3 tx-white mg-l-15 op-9"></i>
          <span class="tx-thin"> Registro: </span>
          {{ model.dataRegistro | moment("DD/MM/YYYY") }}
          <i class="fa fa-calendar mg-r-3 tx-white mg-l-15 op-9"></i>
          <span class="tx-thin"> Desembaraço: </span>
          {{ model.dataDesembaraco | moment("DD/MM/YYYY") }}
        </h6>
        <div class="card-option tx-18 tx-thin justify-content-between">
          <div class="btn-group pd-0" role="group" aria-label="Ações da DI">
            <button
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10"
              @click.prevent="gerarDemonstrativo()"
            >
              <i class="fa fa-file-pdf-o mg-r-5"></i> PDF
            </button>
            <button
              type="button"
              class="btn btn-sm mg-r-10 mg-t-10 mg-b-10 mg-r-10"
              @click.prevent="gerarXMLTransmissao('1')"
            >
              <i class="fa fa-file-code-o mg-r-5"></i> XML DIAGNÓSTICO
            </button>
          </div>
        </div>
      </div>

      <div class="card">
        <!-- <ul class="list-group mg-10">
                <li class="list-group-item">
                    <p class="mg-b-0"><i class="fa fa-check tx-danger mg-r-8"></i><strong class="tx-inverse tx-medium"> Inconsistência no VMLE: </strong> <span class="text-muted">O valor digitado na aba VMLE está diferente do valor total das adições.</span> </p>
                </li>
                <li class="list-group-item">
                    <p class="mg-b-0"><i class="fa fa-check tx-danger mg-r-8"></i><strong class="tx-inverse tx-medium"> Inconsistência nos Acréscimos: </strong> <span class="text-muted"> Existem Acréscimos cadastrados na Aba Carga, mas não estão cadastrados nas adições ou estão incorretores. </span> </p>
                </li>
            </ul> -->

        <div class="card-header bg-gray-200">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#importer" data-toggle="tab">
                <i class="icon ion-home mg-r-5"></i>
                Importador / Basicas
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#transporte" data-toggle="tab">
                <i class="fa fa-truck mg-r-5"></i>
                Transporte
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#carga" data-toggle="tab">
                <i class="fa fa-archive mg-r-5"></i>
                Carga
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#adicoes" data-toggle="tab">
                <i class="fa fa-plus-square mg-r-5"></i>
                Adições
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#pagamento" data-toggle="tab">
                <i class="fa fa-money mg-r-5"></i>
                Pagamento
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#complementares" data-toggle="tab">
                <i class="fa fa-list mg-r-5"></i>
                Complementares
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#upload" data-toggle="tab">
                <i class="icon ion-archive mg-r-5"></i>
                Upload
              </a>
            </li>
          </ul>
        </div>

        <div
          class="mg-x-20 mg-t-20 alert alert-solid alert-danger mg-b-0"
          role="alert"
          v-if="
            model.processoImportacao !== undefined &&
            model.processoImportacao !== null &&
            (model.processoImportacao.integrationIdentification === null ||
              model.processoImportacao.integrationIdentification === '') &&
            model.processoImportacao.customer !== undefined &&
            model.processoImportacao.customer.federalID === '84430149000109'
          "
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <strong>Referência de Embarque!</strong> O Processo de Importação Nº
          {{ model.indentification }} ainda não tem uma Referência de Embarque
          da Duas Rodas.
        </div>

        <div class="card-body mg-0 pd-0">
          <div class="tab-content">
            <div class="tab-pane pd-20 active" id="importer">
              <di-form-importador
                :isNewRecord="isNewRecord()"
                :model="model"
                :noClick="noClick"
              />
            </div>
            <!-- tab-pane -->

            <div class="tab-pane pd-20" id="transporte">
              <di-form-transporte
                :isNewRecord="isNewRecord()"
                :model="model"
                :noClick="noClick"
              />
            </div>

            <div class="tab-pane pd-20" id="carga">
              <di-form-carga
                :isNewRecord="isNewRecord()"
                :model="model"
                :noClick="noClick"
              />
            </div>

            <div class="tab-pane pd-20" id="adicoes">
              <di-form-adicoes
                :isNewRecord="isNewRecord()"
                :model="model"
                :noClick="noClick"
              />
            </div>

            <div class="tab-pane pd-20" id="pagamento">
              <di-form-pagamento
                :isNewRecord="isNewRecord()"
                :noClick="noClick"
              />
            </div>

            <div class="tab-pane pd-20" id="complementares">
              <di-form-complementares
                :isNewRecord="isNewRecord()"
                :model="model"
                :noClick="noClick"
              />
            </div>

            <div class="tab-pane pd-20" id="upload">
              <di-form-upload />
            </div>
          </div>
          <!-- tab-content -->
        </div>
        <!-- card-body -->
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { EventBus } from "@/events/EventBus.js";
import { mapState } from "vuex";

import Swal from "sweetalert2";
import DuasRodasService from "@/services/integration/DuasRodasService.js";

// Children
// import DI from '@/models/DI.js';
import DiImportador from "@/views/operational/import-declaration/Form.Importador.vue";
import DiTransporte from "@/views/operational/import-declaration/Form.Transporte.vue";
import DiCarga from "@/views/operational/import-declaration/Form.Carga.vue";
import DiAdicoes from "@/views/operational/import-declaration/Form.Adicoes.vue";
import DiPagamento from "@/views/operational/import-declaration/Form.Pagamento.vue";
import DiComplementares from "@/views/operational/import-declaration/Form.Complementares.vue";
import DIUpload from "@/views/operational/import-declaration/Form.Upload.vue";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";

export default {
  name: "di-form",
  components: {
    "di-form-importador": DiImportador,
    "di-form-transporte": DiTransporte,
    "di-form-carga": DiCarga,
    "di-form-adicoes": DiAdicoes,
    "di-form-pagamento": DiPagamento,
    "di-form-complementares": DiComplementares,
    "di-form-upload": DIUpload,
  },
  data() {
    return {
      mainTitle: this.isNewRecord()
        ? "Adicionar Declaração de Importação "
        : "Editar Declaração de Importação ",
      initialModel: null,
      model: {
        numeroRegistro: null,
        importador: null,
        adquirente: null,
        exportador: null,
        fabricante: null,
        processoImportacao: null,
        identificacaoProcessoImportacao: "",
        codigoOrigemDI: "2",
        indicadorOperacaoFundap: "N",
        indicadorMultimodal: "N",
        codigoTipoConsignatario: 1,
        codigoModalidadeDespacho: 1,
        codigoTipoDeclaracao: "01",
        codigoUtilizacaoDocumentoCarga: 1,
        codigoTipoManifesto: 3,
        processo: [],
        documentoInstrucaoDespacho: [],
        armazem: [],
        embalagem: [],
        informacaoMercosul: [],
        pagamento: [],
        adicoes: [],
        acrescimos: [],
        adicao: {},
        dataTaxaCambial: null,
        in_moeda_unica: true,
        // carga
        cargaPesoBruto: 0.0,
        cargaPesoLiquido: 0.0,
        valorTotalDespesasMoedaNegociada: 0.0,
        valorTotalDespesasMoedaNacional: 0.0,
        valorTotalFreteCollect: 0.0,
        valorTotalFretePrepaid: 0.0,
        valorTotalFreteMoedaNacional: 0.0,
        valorTotalFreteTotalEmReal: 0.0,
        valorTotalSeguroMoedaNacional: 0.0,
        valorTotalSeguroMoedaNegociada: 0.0,
        valorTotalAcrescimosMoedaNegociada: 0.0,
        valorTotalAcrescimosMoedaNacional: 0.0,
        valorTotalDespesasMoedaNacionalTaxaConversao: 0.0,
        valorTotalFreteTaxaConversao: 0.0,
        valorTotalSeguroMoedaNacionalTaxaConversao: 0.0,
        valorTotalAcrescimosMoedaNacionalTaxaConversao: 0.0,

        valorTotalII: 0.0,
        valorTotalIPI: 0.0,
        valorTotalPIS: 0.0,
        valorTotalCofins: 0.0,
        valorTotalAntidumping: 0.0,
        valorTotalTaxaUtilizacao: 0.0,
        pagamentoBanco: "",
        pagamentoAgencia: "",
        pagamentoContaCorrente: "",
        complementares: "",

        codigoTipoDocumentoCarga: "",
        codigoTipoImportador: "1",
        numeroDocumentoCargaMaster: "",
        codigoTipoAgenteCarga: "",
        numeroAgenteCarga: "",
      },
      codigosPaises: [...importDeclarationLists.codigosPaises],
      componentKey: 0,
      noClick: false,
    };
  },
  methods: {
    sincronizarIntegracao() {
      if (
        this.model.numeroRegistro === null &&
        this.model.processoImportacao !== null &&
        this.model.processoImportacao !== undefined
      ) {
        Swal.fire({
          title: "Sincronizar Integração",
          html: "Você realmente deseja sincronizar os dados do Processo de Importacão com esta Declaração de Importação?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, continuar!",
          cancelButtonText: "Cancelar",
        }).then((response) => {
          if (response.value == true) {
            Axios.get(
              `${config.env.API_DEFAULT.host}/company/findAllCompaniesWithIntegration`
            )
              .then((response) => {
                const empresas = response.data.data;

                if (
                  empresas !== null &&
                  empresas !== undefined &&
                  empresas.length > 0
                ) {
                  const empresa = empresas.find(
                    (c) => c.federalID === this.model.adquirente.federalID
                  );

                  if (empresa !== null) {
                    DuasRodasService.sincronizarDI(
                      this.$store,
                      this.model
                    ).then((response) => {
                      if (response) {
                        this.model = response;
                        this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
                      } else
                        Swal.fire(
                          "Sincronizar",
                          "Não foi possível sincronizar os dados da integração.",
                          "error"
                        );
                    });
                  } else
                    Swal.fire(
                      "Sincronizar",
                      `Não existe integração disponível para o processo ${this.model.processoImportacao.identification} `,
                      "info"
                    );
                } else
                  Swal.fire(
                    "Sincronizar",
                    "Não existem empresas que possuem código de integração cadastrado.",
                    "error"
                  );
              })
              .catch((error) => {
                Swal.fire(
                  "Sincronizar",
                  `Não foi possível carregar a listagem de empresas que possuem Código de Integração cadastrado: ${error}`,
                  "error"
                );
              });
          }
        });
      } else {
        Swal.fire(
          "Sincronizar",
          "Para sincronizar os dados do processo com a DI, é necessário selecionar um Processo de Importação.",
          "error"
        );
      }
    },
    forceRerender() {
      // this.componentKey += 1;
    },
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (
        this.$route.params.id !== undefined &&
        this.$route.params.id !== null
      ) {
        return false;
      }
      return true;
    },
    async diffObjects(fieldName) {
      const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
        fieldName,
      });

      if (response) {
        this.initialModel = {
          ...this.model,
        };
      }
    },
    onSubmit() {
      if (
        this.model.processoImportacao === null ||
        this.model.processoImportacao === "" ||
        this.model.processoImportacao === undefined
      ) {
        Swal.fire(
          "Declaração de Importação",
          "Você precisa selecionar um Processo de Importação para salvar a DI que você está começando a digitar.",
          "error"
        );
      } else {
        this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
        this.$store.dispatch("MyIMPStore/patch", {
          diff: this.model,
          newModel: this.model,
          fieldName: null,
        });
      }
    },
    async loadModel() {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarDI",
        this.$route.params.id
      );
      if (response) {
        this.model = {
          ...this.activeDI,
        };
        this.initialModel = {
          ...this.activeDI,
        };
      }

      // Axios.get(this.model.processoImportacao._links.self.href).then((response) => {
      //     this.$set(this.model, "processoImportacao", response.data);
      // }).catch(function (error) {
      //     Swal.fire('Filial da Empresa', 'Não foi possível buscar a Matriz/Filial da Empresa que está cadastrada no Processo de Importação.', "error");
      // });
    },
    setActiveAdicao(adicao) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", {});
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", adicao);
    },
    gerarCalculos() {
      this.$store.commit("MyIMPStore/SET_ACTIVE", {});
      if (this.model.numeroRegistro === null) {
        this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
        EventBus.$emit(
          "operational.import-declaration.Form.Carga.gerarCalculos"
        );
      }
    },
    gerarXMLTransmissao(codigoMotivoTrasmissao) {
      this.$store.dispatch("MyIMPStore/gerarXMLTransmisso", {
        id: this.model.id,
        identification: this.model.identificacaoDeclaracaoImportacao,
        codigoMotivoTrasmissao,
      });
    },
    printDemonstrative() {
      const requestConfiguration = {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.env.API_JSREPORTONLINE.credentials.token,
          responseType: "arraybuffer",
        },
      };

      this.$store
        .dispatch(
          "ProcessStore/findProcessByIdentification",
          this.model.identificacaoProcessoImportacao
        )
        .then((response) => {
          if (response.success) {
            const { account } = response.data.data;

            delete account.logo;
            const requestData = {
              template: {
                shortid: "OF47ywd",
                recipe: "chrome-pdf",
              },
              data: {
                account: {
                  ...account,
                  logo: "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png",
                },
                di: {
                  ...this.model,
                },
              },
            };

            this.$store.commit("setLoading", {
              show: true,
              label: "Demonstrativo",
              description: "Gerando demonstrativo da DI...",
            });
            jsreport.serverUrl = config.env.API_JSREPORTONLINE.host;
            jsreport.headers.Authorization =
              config.env.API_JSREPORTONLINE.credentials.token;

            jsreport
              .renderAsync(requestData)
              .then((res) => {
                // open download dialog
                res.download(
                  `Demonstrativo - Declaração de Importação ${this.model.identificacaoDeclaracaoImportacao}.pdf`
                );
                res.xhr.getResponseHeader("Content-Type");
                this.$store.commit("setLoading", {
                  show: false,
                });
              })
              .catch((error) => {
                this.$store.commit("setLoading", {
                  show: false,
                });
                Swal.fire(
                  "Gerar Demonstrativo",
                  `Houve algum erro ao tentar gerar o demonstrativo desta DI: <strong>${error.response.data.message}</strong>`,
                  "error"
                );
              });
          } else {
            this.$store.commit("setLoading", {
              show: false,
            });
            Swal.fire(
              "Processos de Importação",
              "Não foi possível consultar o Processo de Importação desta DI.",
              "error"
            );
          }
        });
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),
  },
  created() {
    this.$store.commit("MyIMPStore/SET_ACTIVE", null);
    this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);
  },
  mounted() {
    this.$store.commit("MyIMPStore/SET_ACTIVE", null);
    this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);

    if (this.isNewRecord() === true || this.isNewRecord() === "true") {
      this.model.dataDigitacao = new Date();
      this.initialModel = {
        ...this.model,
      };
      this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
    } else {
      this.loadModel();

      this.initialModel = {
        ...this.activeDI,
      };

      this.model = {
        ...this.activeDI,
      };
    }

    this.$store.commit("setLoading", {
      show: true,
      label: "Declaração de Importacão",
      description: "Carregando os dados da DI...",
    });

    setTimeout(() => {
      if (
        this.model.numeroRegistro !== null &&
        this.model.numeroRegistro !== undefined &&
        this.model.numeroRegistro !== ""
      )
        this.noClick = true;
      else this.noClick = false;
      this.$store.commit("setLoading", {
        show: false,
      });
    }, 3000);
  },
  watch: {
    activeDI() {
      this.initialModel = {
        ...this.activeDI,
      };
      this.model = {
        ...this.activeDI,
      };
    },
  },
};
</script>
