<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Utilitários</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Retrospectiva anual do Cliente
          </li>
        </ol>
        <h6 class="slim-pagetitle">Retrospectiva anual do cliente</h6>
      </div>

      <div class="card mb-4">
        <report-filters :modal="false" @generate="getData" />
      </div>

      <div class="card mb-4" v-if="Object.keys(reportData).length > 0">
        <div class="row p-4">
          <div class="col-12">
            <h4>Média de frete nas principais origens</h4>
          </div>
          <div
            class="col-12 col-md-3"
            v-for="(item, index) in reportData.frete"
            :key="index"
          >
            <v-input
              :name="item.country"
              :label="item.country"
              v-model="item.value"
              type="money"
            />
          </div>
        </div>
      </div>

      <div class="card mb-4" v-if="Object.keys(reportData).length > 0">
        <div class="row p-4">
          <div class="col-12">
            <h4>Dólar Médio do Ano</h4>
          </div>
          <div class="col-12 col-md-3">
            <v-input
              name="dolarMedio"
              label="Dólar medio do ano"
              v-model="reportData.dolarMedio"
              type="money"
            />
          </div>
        </div>
      </div>

      <div class="card" v-if="Object.keys(reportData).length > 0">
        <div class="card-header">
          <div class="flex ai-c jc-sb">
            <h2>Preview</h2>
            <button class="btn btn-success" @click.prevent="generateNewPDF">
              Exportar PDF
            </button>
            <!-- <button class="btn btn-success" @click.prevent="generatePDF('retrospectiva-pdf')">Exportar PDF</button> -->
          </div>
        </div>

        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :preview-modal="true"
          :enable-download="false"
          :filename="'retrospectiva_anual'"
          pdf-orientation="portrait"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-content-width="100%"
          ref="html2Pdf"
          :html-to-pdf-options="{
            html2canvas: {
              dpi: 300,
              width: 940,
              scale: 2,
            },
            jsPDF: {
              compress: false,
              unit: 'px',
              format: [940, 1440],
            },
          }"
        >
          <section slot="pdf-content">
            <div style="width: 100%">
              <annual-report
                container-class="retrospectiva-pdf"
                :report-data="reportData"
              />
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import ReportFilters from "@/components/AnnualReport/ReportFilters";
import AnnualReport from "@/components/AnnualReport/AnnualReport";
import VInput from "@/components/VInput";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "RetrospectiveComponent",
  components: {
    AnnualReport,
    ReportFilters,
    VInput,
    VueHtml2pdf,
  },
  data() {
    return {
      reportData: {},
    };
  },
  methods: {
    getData(e) {
      this.reportData = {};
      setTimeout(() => {
        this.reportData = e;
      }, 200);
    },
    generateNewPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    // generatePDF(className) {
    //   html2pdf(document.querySelector(`.${className}`), {
    //     html2canvas:  {
    //       dpi: 500,
    //       letterRendering: true,
    //       width: 940,
    //       height: 1470
    //     },
    //     jsPDF: {
    //       orientation: 'portrait',
    //       unit: 'px',
    //       format: [940, 1470]
    //     }
    //   });
    // }
  },
};
</script>
