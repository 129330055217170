var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"calendar-item",class:{
    active: _vm.active,
    other: _vm.otherMonth,
    past: _vm.past,
    future: _vm.future,
    long: _vm.long,
    overview: _vm.overview,
    'no-border': _vm.noBorder
  }},[_c('p',{staticClass:"day"},[_vm._v(" "+_vm._s(_vm.day)+" "),(_vm.active)?_c('span',{staticClass:"text-normal"},[_vm._v(" hoje")]):_vm._e(),(_vm.day)?_c('span',{staticClass:"totals"},[_vm._v(_vm._s(_vm.getTotals))]):_vm._e()]),_c('div',{staticClass:"calendar-item-content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }