<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Importação</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório de Percentual de Canais
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório de Percentual de Canais</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="
                      filter.date.start !== '' &&
                      filter.date.end !== '' &&
                      filter.date.field !== ''
                    "
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar tx-info"></i> Período:
                      <span
                        class="pd-x-5 badge badge-info mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.field.label }} de
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período dos Processos
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um campo e uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group bd-t-0-force">
                          <label class="form-control-label">
                            Campo do Filtro
                          </label>
                          <v-select
                            v-model="filter.date.field"
                            :options="listOfDatesFields"
                            name="label"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.customers.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-danger mg-r-5"></i>Clientes:
                      <span
                        class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.customers"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-building tx-medium mg-r-5"></i> Clientes
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Clientes
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais clientes ({{
                    this.listOfCustomers.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Clientes </label>
                          <v-select
                            v-model="filter.customers"
                            label="name"
                            :options="listOfCustomers"
                            name="customers"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20"
                  @click.prevent="generate('HTML')"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> GERAR
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta
            </label>
            <p class="mg-b-0">
              Foram encontrados {{ listOfResults.total }} Processos de
              Importação de acordo com o filtro acima.
            </p>
            <hr/>

            <div class="row row-sm">
              <div class="col-lg-6">
                <div class="card card-popular-product">
                  <label class="section-title mg-t-10 tx-16 tx-center">
                    Gráfico de Percentual de Canais
                  </label>
                  <div
                    class="hello"
                    ref="chartdiv"
                    style="width: 100%; height: 400px"
                  ></div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card card-popular-product">
                  <label class="section-title mg-t-10 tx-16 tx-center">
                    Quantidade de Processos de Importação por Canal
                  </label>
                  <h5 class="prod-name">
                    <a href="">Total de Processos: {{ listOfResults.total }}</a>
                  </h5>
                  <p
                    class="prod-by"
                    v-if="
                      filter.date.field.label !== null &&
                      filter.date.field.label !== ''
                    "
                  >
                    Período: {{ filter.date.field.label }} de
                    {{ filter.date.start | moment("DD/MM/YYYY") }} até
                    {{ filter.date.end | moment("DD/MM/YYYY") }}
                  </p>

                  <div class="row">
                    <div class="col-6 tx-center">
                      <h1 v-if="listOfResults.totalVerde > 0">
                        {{ listOfResults.totalVerde }}
                      </h1>
                      <h1 v-else>0</h1>
                      <p class="tx-success tx-medium tx-uppercase">
                        Canal Verde
                      </p>
                    </div>
                    <div class="col-6 tx-center">
                      <h1 v-if="listOfResults.totalAmarelo > 0">
                        {{ listOfResults.totalAmarelo }}
                      </h1>
                      <h1 v-else>0</h1>
                      <p class="tx-warning tx-medium tx-uppercase">
                        Canal Amarelo
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 tx-center">
                      <br/>
                      <h1 class="" v-if="listOfResults.totalVermelho > 0">
                        {{ listOfResults.totalVermelho }}
                      </h1>
                      <h1 class="" v-else>0</h1>
                      <p class="tx-danger tx-medium tx-uppercase">
                        Canal Vermelho
                      </p>
                    </div>
                    <div class="col-6 tx-center">
                      <br/>
                      <h1 v-if="listOfResults.totalCinza > 0">
                        {{ listOfResults.totalCinza }}
                      </h1>
                      <h1 v-else>0</h1>
                      <p class="tx-secondary tx-medium tx-uppercase">
                        Canal Cinza
                      </p>
                    </div>
                  </div>
                  <!-- row -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import config from "@/config.js";
import * as labelsProcesses from "@/labels/labels.processes.js";

import {mapState} from "vuex";

import moment from "moment";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "OperationalChannel",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      filter: {
        steps: [],
        exporters: [],
        customers: [],
        customsBrokers: [],
        date: {
          field: "",
          start: moment().startOf("week").toDate(),
          end: moment().endOf("week").toDate(),
        },
      },
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      listOfCustomsBrokers: [],
      listOfCustomers: [],
      listOfExporters: [],
      listOfResults: [],
    };
  },

  methods: {
    generate(type) {
      const {filter} = this;

      if (
        filter.date.field === "" ||
        filter.date.start === "" ||
        filter.date.end === ""
      ) {
        Swal.fire(
          "Relatório de Percentual de Canais",
          "Você precisa selecionar um período para conseguir gerar o relatório.",
          "error"
        );
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Relatório de Percentual de Canais",
          description: "Aguarde enquanto o relatório está sendo gerado...",
        });

        let params = "";
        const startDate = `${this.$moment(filter.date.start).format(
          "Y-MM-DDT"
        )}00:00:00`;
        const endDate = `${this.$moment(filter.date.end).format(
          "Y-MM-DDT"
        )}23:59:59`;

        params += `${filter.date.field.attribute}&from=${startDate}&to=${endDate}`;

        if (filter.customers.length > 0) {
          const customers = new Array();
          filter.customers.filter((o) => {
            customers.push(o.id);
          });
          params += `&customersIds=${customers.join()}`;
        }

        Axios.get(
          `${config.env.API_DEFAULT.host}/process/import/report/channels?field=${params}`
        )
          .then((response) => {
            if (response.data.code === "SUCCESS") {
              this.listOfResults = response.data.data;

              const chart = am4core.create(
                this.$refs.chartdiv,
                am4charts.PieChart
              );
              chart.data = [
                {
                  channel: "Verde",
                  total: response.data.data.totalVerde,
                  color: am4core.color("#00A068"),
                },
                {
                  channel: "Amarelo",
                  total: response.data.data.totalAmarelo,
                  color: am4core.color("#F49917"),
                },
                {
                  channel: "Vermelho",
                  total: response.data.data.totalVermelho,
                  color: am4core.color("#dc3545"),
                },
                {
                  channel: "Cinza",
                  total: response.data.data.totalCinza,
                  color: am4core.color("#6c757d"),
                },
              ];

              // Add and configure Series
              const pieSeries = chart.series.push(new am4charts.PieSeries());
              pieSeries.slices.template.propertyFields.fill = "color";
              pieSeries.dataFields.value = "total";
              pieSeries.dataFields.category = "channel";

              chart.innerRadius = am4core.percent(30);

              this.chart = chart;

              this.$store.commit("setLoading", {
                show: false,
              });
              Swal.fire(
                "Relatório de Percentual de Canais",
                response.data.message,
                "success"
              );
            } else {
              this.$store.commit("setLoading", {
                show: false,
              });
              Swal.fire(
                "Relatório de Percentual de Canais",
                response.data.message,
                "error"
              );
            }
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
            });
          });
      }
    },

    async findProcessByIdentificationSearch(identification) {
      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        identification
      );
      if (response !== undefined && response !== null && response.success) {
        // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
        this.$router.push("/operational/imp/desk?showProcess=true");
      } else Swal.fire("Processo de Importação", response.message, "error");
    },
  },
  watch: {},
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
    }),
    listOfDatesFields() {
      const data = new Array();

      if (this.labels !== undefined) {
        for (const prop in this.labels) {
          if (this.labels[prop].type === "date") {
            data.push(this.labels[prop]);
          }
        }
      }

      return data;
    },
  },
  mounted() {
    this.$store.dispatch("ProcessStore/getSteps");

    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde...",
      description: "Carregando informações para filtro do relatório...",
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`
    ).then((response) => {
      this.listOfCustomers = response.data._embedded.companies;
    });

    this.$store.commit("setLoading", {
      show: false,
    });
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}
</style>
