<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 25C5.5975 25 0 19.4025 0 12.5C0 5.5975 5.5975 0 12.5 0C19.4025 0 25 5.5975 25 12.5C25 19.4025 19.4025 25 12.5 25Z"
      fill="#104375"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3475 6.68C16.7975 5.1275 14.7325 4.2725 12.535 4.27C8.0075 4.27 4.32 7.955 4.32 12.485C4.32 13.9325 4.6975 15.3475 5.4175 16.5925L4.2525 20.85L8.6075 19.7075C9.8075 20.3625 11.1575 20.7075 12.5325 20.7075H12.535C17.0625 20.7075 20.7475 17.0225 20.75 12.4925C20.7525 10.2975 19.9 8.2325 18.3475 6.68ZM12.5375 19.32H12.535C11.31 19.32 10.1075 18.99 9.06 18.3675L8.81 18.22L6.225 18.8975L6.915 16.3775L6.7525 16.12C6.07 15.0325 5.7075 13.7775 5.7075 12.485C5.71 8.72 8.7725 5.6575 12.54 5.6575C14.365 5.6575 16.0775 6.37 17.3675 7.66C18.6575 8.95 19.3675 10.6675 19.365 12.49C19.365 16.2575 16.3 19.32 12.5375 19.32ZM16.2825 14.205C16.0775 14.1025 15.0675 13.605 14.88 13.5375C14.6925 13.47 14.555 13.435 14.4175 13.64C14.28 13.845 13.8875 14.3075 13.7675 14.445C13.6475 14.5825 13.5275 14.6 13.3225 14.4975C13.1175 14.395 12.455 14.1775 11.6725 13.4775C11.0625 12.9325 10.65 12.26 10.53 12.055C10.41 11.85 10.5175 11.7375 10.62 11.635C10.7125 11.5425 10.825 11.395 10.9275 11.275C11.03 11.155 11.065 11.07 11.1325 10.9325C11.2 10.795 11.1675 10.675 11.115 10.5725C11.0625 10.47 10.6525 9.46 10.4825 9.0475C10.315 8.6475 10.1475 8.7025 10.02 8.695C9.9 8.69 9.7625 8.6875 9.6275 8.6875C9.49 8.6875 9.2675 8.74 9.08 8.945C8.8925 9.15 8.3625 9.6475 8.3625 10.6575C8.3625 11.6675 9.0975 12.645 9.2 12.78C9.3025 12.9175 10.6475 14.99 12.7075 15.88C13.1975 16.0925 13.58 16.2175 13.8775 16.3125C14.37 16.47 14.8175 16.4475 15.17 16.395C15.565 16.335 16.385 15.8975 16.555 15.42C16.725 14.94 16.725 14.53 16.675 14.445C16.625 14.36 16.4875 14.31 16.2825 14.205Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'WhatsappIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
