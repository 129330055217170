<template>
  <transition name="modal">
    <div id="modal-register-procedures">
      <div class="modal-mask ht-100v">
        <div class="modal-wrapper ht-100v">
          <div class="modal-container">
            <div class="modal-header">
              <h5 class="tx-xs-20 tx-inverse mg-b-5">REQUISITOS</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
                @click="$emit('close')"
              >
                <span aria-hidden="true"> X </span>
              </button>
            </div>

            <div class="modal-body">
              <div class="form-layout form-layout-2">
                <br />
                <label class="section-title"
                  >Informações da Listagem de Requisitos</label
                >
                <div class="row no-gutters">
                  <div class="col-lg-6">
                    <div class="form-group mg-md-l--0">
                      <label class="form-control-label">
                        Categoria dos Requisitos
                        <span class="tx-danger">*</span>
                      </label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-navicon op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          <template
                            v-if="model.id !== undefined && model.id !== null"
                          >
                            <select
                              class="custom-select form-control wd-100p"
                              v-model="model.type.value"
                              v-validate="'required'"
                              name="type"
                            >
                              <option value="">Selecione</option>
                              <option
                                v-for="type in listOfProceduresType"
                                :key="type.key"
                                :value="type.key"
                              >
                                {{ type.value }}
                              </option>
                            </select>
                          </template>
                          <template v-else>
                            <select
                              class="custom-select form-control wd-100p"
                              v-model="model.type"
                              v-validate="'required'"
                              name="type"
                            >
                              <option value="">Selecione</option>
                              <option
                                v-for="type in listOfProceduresType"
                                :key="type.key"
                                :value="type.key"
                              >
                                {{ type.value }}
                              </option>
                            </select>
                          </template>
                        </div>
                        <div class="input-group-solved-after"></div>
                      </div>
                      <span
                        v-show="errors.has('type')"
                        class="parsley-errors-list filled"
                        >Selecione o tipo de requisito</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group bd-l-0-force">
                      <label class="form-control-label">
                        Descrição <span class="tx-danger">*</span></label
                      >
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          <input
                            class="form-control"
                            type="text"
                            v-model="model.description"
                            name="description"
                            v-validate="'required'"
                          />
                        </div>
                      </div>
                      <span
                        v-show="errors.has('description')"
                        class="parsley-errors-list filled"
                        >Digite uma descrição</span
                      >
                    </div>
                  </div>
                </div>
                <br />
                <div class="row no-gutters scroll">
                  <table
                    class="table table-striped mg-b-0"
                    v-if="listOfItems.length > 0"
                  >
                    <thead>
                      <tr>
                        <th class="tx-center">Descrição</th>
                        <th class="tx-center">Data Início</th>
                        <th class="tx-center">Data Término</th>
                        <th class="tx-center wd-170">Criação</th>
                        <th class="tx-center wd-170">Última Atualização</th>
                        <th class="tx-center wd-150">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr-inline
                        v-for="item in listOfItems"
                        :key="item.id"
                        :row="item"
                        :afterSaveUpdate="afterSaveItem"
                        @save="saveListItens"
                      >
                        <td>{{ item.description }}</td>
                        <td>
                          <i class="fa fa-calendar" />

                          {{ item.startDate | moment("DD/MM/YYYY") }}
                        </td>
                        <td>
                          <i class="fa fa-calendar" />

                          {{ item.finishDate | moment("DD/MM/YYYY") }}
                        </td>
                        <td>
                          {{ item.created | moment("DD/MM/YYYY") }} às
                          {{ item.created | moment("H:mm") }} por
                          {{ item.createdBy }}
                        </td>
                        <td>
                          {{ item.modified | moment("DD/MM/YYYY") }} às
                          {{ item.modified | moment("H:mm") }} por
                          {{ item.modifiedBy }}
                        </td>

                        <template slot="actions">
                          <div class="actions">
                            <a
                              href="#"
                              @click.prevent="editListItems(item)"
                              class="btn btn-primary btn-icon rounded-circle mr-1"
                            >
                              <div><i class="icon ion-edit"></i></div>
                            </a>
                            <a
                              href="#"
                              @click.prevent="removeListItems(item)"
                              class="btn btn-danger btn-icon rounded-circle"
                              v-if="
                                hasPrivilege('CRM_EMPRESAS_REQUISITOS_REMOVER')
                              "
                            >
                              <div><i class="icon ion-trash-a"></i></div>
                            </a>
                          </div>
                        </template>
                      </tr-inline>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="form-layout-footer bd pd-y-15 pd-x-15">
                <div class="row">
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-danger btn-block"
                      @click.prevent="$emit('close')"
                    >
                      <i class="fa fa-reply mg-r-5"></i> VOLTAR
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      type="button"
                      v-on:click="newListItems"
                      v-if="
                        model.id !== null &&
                        hasPrivilege('CRM_EMPRESAS_REQUISITOS_ADICIONAR')
                      "
                    >
                      <i class="fa fa-plus mg-r-5"></i> ADICIONAR
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-success btn-block"
                      @click.prevent="submit()"
                      v-if="
                        hasPrivilege('CRM_EMPRESAS_REQUISITOS_EDITAR') ||
                        hasPrivilege('CRM_EMPRESAS_REQUISITOS_ADICIONAR')
                      "
                    >
                      <i class="fa fa-edit mg-r-5"></i> SALVAR
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <collapse-procedures
              @close="handleCollapse"
              @save="saveListItens"
              :open="collapse"
              :modelUpdate="item"
              :procedure="model"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
import { EventBus } from "@/events/EventBus.js";
import config from "@/config.js";
import * as constants from "@/consts.js";

import inlineRow from "@/components/InlineRow.vue";

import Swal from "sweetalert2";
import CredentialService from "@/services/CredentialService.js";
import Collapse from "./Collapse";

export default {
  name: "general-companies-procedures-modal",
  props: ["modelUpdate", "finish", "company"],
  components: {
    "tr-inline": inlineRow,
    "collapse-procedures": Collapse,
  },

  data() {
    return {
      collapse: false,
      oldModel: null,
      model: {
        id: null,
        type: "",
        description: "",
        content: "",
        company: null,
        itens: [],
      },
      item: {
        id: null,
        startDate: "",
        finishDate: "",
        description: "",
        content: "",
        procedure: null,
        log: [],
      },
      afterSaveItem: {
        id: null,
        startDate: "",
        finishDate: "",
        description: "",
        content: "",
        procedure: null,
      },
      customToolbar: [
        [
          {
            header: 1,
          },
          {
            header: 2,
          },
        ],
        ["bold", "italic", "underline"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
      ],
    };
  },

  methods: {
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    handleCollapse(param = false) {
      this.collapse = param;
    },
    newListItems() {
      this.item = {
        startDate: "",
        finishDate: "",
        description: "",
        content: "",
        procedure: "",
      };
      this.collapse = true;
    },
    saveListItens(value) {
      const indexItem = this.model.itens.findIndex(
        (item) => item.id === value.id
      );

      if (
        indexItem > -1 &&
        this.model.itens[indexItem].finishDate !== value.finishDate &&
        value.finishDate !== null
      ) {
        this.saveListItensChangeFinalDate(value, indexItem);
      } else {
        this.saveListItensNormal(value, indexItem);
      }
    },
    async saveListItensNormal(value, indexItem) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Itens dos Procedimentos",
          description: "Salvando dados, aguarde...",
        });

        let response;

        if (value.id) {
          response = await Axios.patch(
            `${config.env.API_DEFAULT.host}/companyProceduresItems/${value.id}`,
            value
          );

          if (response.status === 200) {
            const newItem = {
              ...this.model.itens[indexItem],
              ...response.data,
            };

            this.$set(this.model.itens, indexItem, newItem);

            this.afterSaveItem = newItem;
            this.item = newItem;

            Swal.fire(
              "Item do Requisito",
              `O item do requisito ${value.description} foi atualizado com sucesso.`,
              "success"
            );
          }
        } else {
          value.procedure = `${config.env.API_DEFAULT.host}/companyProcedureses/${this.model.id}`;

          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/companyProceduresItems/`,
            value
          );

          if (response.status === 201) {
            if (Array.isArray(this.model.itens)) {
              this.model.itens.push(response.data);
            } else {
              this.model = {
                ...this.model,
                itens: [response.data],
              };
            }
            this.item = response.data;
            this.afterSaveItem = response.data;

            Swal.fire(
              "Item  do Requisito",
              `O item do requisito ${value.description} foi cadastrado com sucesso.`,
              "success"
            );
          }
        }

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        this.collapse = false;
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", err, "error");
      }
    },
    saveListItensChangeFinalDate(value, indexItem) {
      const swalWithBootstrapButtons = Swal.mixin({
        buttonsStyling: true,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Você tem certeza que deseja continuar?",
          html: `Se você continuar, <strong>${value.description}</strong> será desabilitado a partir da Data de Término informada e não será mais importado para os novos Processos de Importação.`,
          showCancelButton: true,
          confirmButtonText: "Sim, continuar!",
          cancelButtonText: "Não, cancelar!",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            swalWithBootstrapButtons.fire({
              title: "Motivo da Data de Término",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Salvar",
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading(),
              preConfirm: (text) => {
                if (!text || text === "") {
                  Swal.showValidationMessage(
                    "Você precisa digitar um Motivo da Data de Término."
                  );
                } else {
                  return Axios.post(
                    `${config.env.API_DEFAULT.host}/companyProceduresItemLogs/`,
                    {
                      description: text,
                      item: `${config.env.API_DEFAULT.host}/companyProceduresItems/${value.id}`,
                      type: "DISABLE",
                    }
                  )
                    .then((response) =>
                      Axios.patch(
                        `${config.env.API_DEFAULT.host}/companyProceduresItems/${value.id}`,
                        value
                      )
                        .then((response) => {
                          const newItem = {
                            ...this.model.itens[indexItem],
                            ...response.data,
                          };

                          this.$set(this.model.itens, indexItem, newItem);

                          this.afterSaveItem = newItem;
                          this.item = newItem;
                          this.collapse = false;

                          return Swal.fire(
                            "Requisito Desabilitado",
                            "O requisito foi desabilitado com sucesso.",
                            "success"
                          );
                        })
                        .catch((error) =>
                          Swal.showValidationMessage(
                            `Não foi possível salvar o Motivo da Data de Término: ${error}`
                          )
                        )
                    )
                    .catch((error) =>
                      Swal.showValidationMessage(
                        `Não foi possível salvar o Motivo da Data de Término: ${error}`
                      )
                    );
                }
              },
            });
          }
        });
    },
    async editListItems(param) {
      const res = await Axios.get(
        `${config.env.API_DEFAULT.host}/companyProceduresItems/${param.id}/log`
      );
      if (res) {
        this.item = {
          ...param,
        };
        this.item.log = res.data._embedded.companyProceduresItemLogs;
        this.collapse = true;
      }
    },
    removeListItems(value) {
      Swal.fire({
        title: "Você tem certeza?",
        html: `Se você excluir o item do procedimento ${value.description} não será possível recuperá-lo para uso posterior.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, continuar!",
        cancelButtonText: "Não, cancelar!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Item do Procedimento",
            description: "Processando sua requisição, aguarde...",
          });

          Axios.delete(
            `${config.env.API_DEFAULT.host}/companyProceduresItems/${value.id}`,
            value
          )
            .then((r) => {
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              });
              Swal.fire(
                "Item do Requisito",
                `O item do requisito <strong>${value.description}</strong> foi removido com sucesso.`,
                "success"
              );

              const { itens } = this.model;

              const index = itens.findIndex((item) => item.id === value.id);

              this.$delete(itens, index);

              this.model.itens = itens;

              this.$emit("removeListItems", {
                id: this.model.id,
                itens: this.model.itens,
              });
            })
            .catch((error) => {
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              });

              if (error.response.status === 409) {
                Swal.fire(
                  "Item do Requisito",
                  `O item do requisito <strong>${value.description}</strong> não pode ser removido porque já foi utilizado.`,
                  "error"
                );
              } else {
                Swal.fire(self.modelTitle, error.message, "error");
              }
            });
        }
      });
    },
    isNewRecord() {
      if (this.model.id !== undefined && this.model.id !== null) return false;
      return true;
    },
    submit() {
      this.$validator.validateAll().then((response) => {
        if (response) this.save();
        else {
          Swal.fire(
            "Procedimentos",
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
        }
      });
    },
    async save() {
      const value = this.model;
      this.oldModel = value;

      const { itens } = value;
      const { content } = value;

      delete value.items;
      delete value.itens;
      delete value.content;

      if (this.model.company === undefined || this.model.company === null)
        value.company = `${config.env.API_DEFAULT.host}/companies/${this.company.id}`;

      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Procedimentos",
          description: "Salvando dados, aguarde...",
        });

        let response;

        if (this.model.id) {
          if (value.type.value) {
            value.type = value.type.value;
          }

          response = await Axios.patch(
            `${config.env.API_DEFAULT.host}/companyProcedureses/${value.id}`,
            value
          );

          if (response.status === 200) {
            EventBus.$emit("general.companies.Form.NewProcedures.load");
            Swal.fire(
              "Requisito",
              `O requisito ${value.description} foi atualizada com sucesso.`,
              "success"
            );
            this.model = {
              ...this.model,
              id: response.data.id,
              content: response.data.content,
              description: response.data.description,
              type: response.data.type,
              itens,
            };
          }

          this.$emit("load-procedures");
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/companyProcedureses/`,
            value
          );

          if (response.status === 201) {
            Swal.fire(
              "Requisito",
              `O requisito ${value.description} foi cadastrado com sucesso.`,
              "success"
            );
            this.model = {
              ...this.model,
              id: response.data.id,
              content: response.data.content,
              description: response.data.description,
              type: response.data.type,
              itens,
            };
          }
        }

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        this.$emit("load-procedures");
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", "", "error");
      }
    },
  },

  computed: {
    listOfProceduresType() {
      return constants.GENERAL_COMPANIES_PROCEDURES_TYPES;
    },
    listOfItems() {
      return this.model.itens;
    },
  },
  mounted() {
    if (this.modelUpdate !== undefined && this.modelUpdate !== null) {
      this.model = this.modelUpdate;
    }
  },
};
</script>

<style scoped>
@import "@/assets/css/modal.css";
@import "./styles.css";
</style>
