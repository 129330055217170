var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-striped mg-b-0 tx-12",attrs:{"id":"desk-table"}},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column,index){return _c('th',{key:`${column}-${index}`,staticClass:"tx-center pd-y-10 pd-x-5 tx-12",class:{
            ordering: _vm.orderBy == column,
            'wd-105': _vm.labels[column].attribute == 'diNumber',
          },on:{"click":function($event){return _vm.changeOrderBy(column)},"dblclick":_vm.action}},[(_vm.labels[column].type === 'ico')?[_c('span',{staticClass:"tx-center"},[_c('i',{class:_vm.labels[column].iconClass})])]:[_vm._v(" "+_vm._s(_vm.labels[column].alt !== undefined ? _vm.labels[column].alt : _vm.labels[column].label)+" "),(_vm.orderBy == column)?_c('img',{class:{ asc: _vm.orderAsc },attrs:{"src":require("@/assets/img/table_sort.png"),"alt":""}}):_vm._e()]],2)}),0)]),_c('tbody',[(_vm.data !== null && _vm.data.length > 0)?_vm._l((_vm.data),function(processItem,i){return _c('tr',{key:`${processItem.id}-${i}`,class:{
            'tb-tr-red-highlight': processItem.urgent,
            'tb-tr-today-highlight': _vm.isTodayDate(processItem),
          }},[(_vm.openedProcesses[processItem.id])?_vm._l((_vm.columns),function(column,index){return _c('table-cell',{key:`${processItem.id}-${column}-${index}`,attrs:{"column":column,"data":processItem,"fieldInfo":_vm.labels[column],"opennedBy":_vm.openedProcesses[processItem.id].user}})}):_vm._l((_vm.columns),function(column,index){return _c('table-cell',{key:`${processItem.id}-${column}-${index}`,attrs:{"column":column,"data":processItem,"fieldInfo":_vm.labels[column]}})})],2)}):[_c('tr',[_c('td',{staticClass:"tx-center",attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"card bd-0 bg-gray-100 ht-100p pd-y-150"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('h5',{staticClass:"tx-inverse mg-b-20 tx-danger"},[_vm._v(" NENHUM PROCESSO DE IMPORTAÇÃO FOI ENCONTRADO ")]),_vm._m(1),_c('a',{staticClass:"btn btn-primary wd-400",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('ProcessStore/NEW_PROCESS')}}},[_vm._v("ADICIONAR NOVO PROCESSO")])])])])])]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mg-b-30"},[_c('img',{staticClass:"wd-100",attrs:{"src":require("@/assets/img/icon1.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mg-b-20 tx-16"},[_vm._v(" Selecione alguma etapa, e se desejar faça algum filtro clicando em "),_c('span',{staticClass:"tx-primary"},[_c('i',{staticClass:"icon ion-funnel tx-bold"}),_vm._v(" Filtrar")]),_vm._v(" na parte superior do Telão. ")])
}]

export { render, staticRenderFns }