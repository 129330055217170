<template>
  <transition name="modal-procedures">
    <div id="form-procedures">
      <div class="modal-mask ht-100v">
        <div class="modal-wrapper ht-100v">
          <div class="modal-container">
            <div class="modal-header">
              <h5 class="tx-xs-20 tx-inverse mg-b-5">REQUISITOS</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
                @click="$emit('close')"
              >
                <span aria-hidden="true"> X </span>
              </button>
            </div>

            <div class="modal-body">
              <div class="card processes-container">
                <div class="card-header">
                  <ul class="nav nav-tabs card-header-tabs">
                    <li
                      class="nav-item"
                      v-for="(item, index) in procedures"
                      :key="'procedure-tab-id-' + item.id"
                    >
                      <a
                        class="nav-link"
                        v-bind:class="{
                          active:
                            tab === null || tab === ''
                              ? index === Object.keys(procedures)[0]
                              : tab === 'procedure-id-' + item.id,
                        }"
                        v-bind:href="'#procedure-id-' + item.id"
                        @click="tab = 'procedure-id-' + item.id"
                        data-toggle="tab"
                      >
                        {{ item.description }}
                        <span
                          class="badge"
                          v-bind:class="{ success: item.count === 0 }"
                        >{{ item.count }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="card-body">
                  <div class="tab-content ht-100p">
                    <div
                      v-for="(item, index) in procedures"
                      :key="'procedure-id-' + item.id"
                      class="tab-pane"
                      v-bind:class="{
                        active:
                          tab === null || tab === ''
                            ? index === Object.keys(procedures)[0]
                            : tab === 'procedure-id-' + item.id,
                      }"
                      v-bind:id="'procedure-id-' + item.id"
                    >
                      <div class="row no-gutters scroll">
                        <table class="table table-striped mg-b-0">
                          <thead>
                          <tr>
                            <th class="tx-center">Descrição</th>
                            <th class="tx-center">Data Início</th>
                            <th class="tx-center wd-170">Quem Concluiu?</th>
                            <th class="tx-center wd-170">
                              Data de Conclusão
                            </th>
                            <th class="tx-center wd-50">Ações</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            v-for="itemItens in item.itens"
                            :key="itemItens.id"
                            v-bind:class="{ completed: itemItens.checked }"
                          >
                            <td>
                              <label
                                class="cb"
                                v-bind:class="{ 'no-click': noClick }"
                              >
                                <input
                                  type="checkbox"
                                  :checked="itemItens.checked"
                                  v-on:input="
                                      handleChecked(
                                        itemItens.id,
                                        itemItens.checked
                                      )
                                    "
                                />
                                {{ itemItens.item.description }}
                              </label>
                            </td>
                            <td class="tx-center">
                              {{
                                itemItens.item.startDate
                                  | moment("DD/MM/YYYY")
                              }}
                            </td>
                            <td class="tx-center">
                              <template
                                v-if="
                                    itemItens.checkedDate && itemItens.checked
                                  "
                              >
                                {{ itemItens.checkedBy }}
                              </template>
                            </td>
                            <td class="tx-center">
                              <template
                                v-if="
                                    itemItens.checkedDate && itemItens.checked
                                  "
                              >
                                {{
                                  itemItens.checkedDate | moment("DD/MM/YYYY")
                                }}
                                às
                                {{ itemItens.checkedDate | moment("H:mm") }}
                              </template>
                            </td>
                            <td class="actions">
                              <button
                                class="btn btn-info btn-icon rounded-circle mg-l-10"
                                type="button"
                                @click="
                                    showItem({
                                      ...itemItens.item,
                                      logs: itemItens.logs,
                                    })
                                  "
                              >
                                <i class="icon ion-eye"></i>
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <collapse-procedures
              @close="handleCollapse"
              :open="collapse"
              :modelUpdate="item"
              :procedure="model"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";

import Collapse from "./Collapse";

export default {
  name: "general-companies-procedures-modal",
  props: ["processUpdate", "proceduresUpdate", "noClick"],
  components: {
    "collapse-procedures": Collapse,
  },
  watch: {
    processUpdate() {
      this.process = this.processUpdate;
    },
    proceduresUpdate() {
      this.procedures = this.proceduresUpdate;
    },
  },

  data() {
    return {
      collapse: false,
      item: {
        id: null,
        startDate: "",
        finishDate: "",
        description: "",
        content: "",
        lastModification: "",
        logs: [],
        procedure: null,
      },
      model: {},
      tab: null,
      process: {},
      procedures: [],
      customToolbar: [
        [
          {
            header: 1,
          },
          {
            header: 2,
          },
        ],
        ["bold", "italic", "underline"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
      ],
    };
  },

  methods: {
    handleCollapse(param = false) {
      this.collapse = param;
    },
    showItem(param) {
      this.collapse = true;
      this.item = param;
    },

    async handleChecked(id, status) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Requisitos",
        description: "Alterando status do requisito.",
      });

      try {
        const response = await Axios.post(
          `${
            config.env.API_DEFAULT.host
          }/procedures/setStatus/${id}/checked/${!status}`
        );

        if (response.data.code === "SUCCESS") {
          this.$emit("handleStatusProcedure", response.data.data);
        }

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", e, "error");
      }
    },
  },

  created() {
    if (this.processUpdate !== undefined && this.processUpdate !== null) {
      this.process = this.processUpdate;
    }

    if (this.proceduresUpdate !== undefined && this.proceduresUpdate !== null) {
      this.procedures = this.proceduresUpdate;
    }
  },
};
</script>

<style scoped>
@import "./../../../../../../../assets/css/modal.css";
@import "./styles.css";

.no-click {
  pointer-events: none;
}
</style>
