<template>
  <form @submit.prevent="saveActivity" class="contact-form">

    <div class="sp-3 light-bg p-3 bd-b" style="overflow: auto;">
      <div :class="[
        'flex ai-c jc-sb fw-w fgap2',
      ]">
        <div>
          <h4 class="mb-0">ATIVIDADES</h4>
          <p class="mb-0">
            {{contactActivities.length}} atividades cadastradas
          </p>
        </div>
        <div>
          <button
            type="button"
            @click="addDialog = !addDialog"
            class="plus-button"
          >
            <plus-icon color="#ffffff" />
          </button>
        </div>
      </div>

      <transition name="activity" mode="out-in">
        <div class="contact-form mt-4 mb-2" v-if="addDialog">
          <div>
            <v-input
              name="assunto"
              label="Assunto"
              v-model="formData.subject"
              hint="Assunto abordado na atividade"
            ></v-input>
            <div class="field-error">
              {{ fieldErrors.find((err) => err.path == 'subject') ? fieldErrors.find((err) => err.path == 'subject').message : '' }}
            </div>
          </div>

          <div>
            <select-wrapper
              v-model="formData.typeId"
              :options="activityTypes"
              label="Tipo"
            ></select-wrapper>
            <div class="field-error">
              {{ fieldErrors.find((err) => err.path == 'typeId') ? fieldErrors.find((err) => err.path == 'typeId').message : '' }}
            </div>
          </div>

          <div>
            <v-input
              name="data"
              label="Data"
              type="date"
              v-model="formData.dateOfActivity"
              hint="Data em que a atividade foi realizada"
            ></v-input>
            <div class="field-error">
              {{ fieldErrors.find((err) => err.path == 'dateOfActivity') ? fieldErrors.find((err) => err.path == 'dateOfActivity').message : '' }}
            </div>
          </div>

          <div class="sp-3">
            <v-textarea
              name="description"
              label="Descrição"
              v-model="formData.description"
              :editor="true"
            ></v-textarea>
            <div class="field-error">
              {{ fieldErrors.find((err) => err.path == 'description') ? fieldErrors.find((err) => err.path == 'description').message : '' }}
            </div>
            <div>
              {{ 5000 - Number(this.formData.description.length) }} caracteres restantes.
            </div>
          </div>

          <v-input-file-drag class="sp-3" v-model="documents" ref="upload"> </v-input-file-drag>

          <div class="sp-3 flex jc-fe">
            <button class="activity-submit">Adicionar Atividade</button>
          </div>
        </div>
      </transition>
    </div>

    <div class="card-body pd-40 sp-3 text-center" v-if="contactActivities.length == 0">
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="">
      <h6 class="tx-md-20 tx-inverse ">Nenhuma Atividade Cadastrada</h6>
      <p>Adicione uma atividade clicando no botão + que está acima</p>
    </div>

    <div class="contact-form ph-2 sp-3" v-else>
      <activity-card
        v-for="activity in contactActivities"
        :key="activity.id"
        class="sp-3"
        :data="{
          id: activity.id,
          title: activity.subject,
          author: activity.createdBy,
          description: activity.description,
          documents: activity.documents,
          date: activity.dateOfActivity,
          type: activity.type,
          userWhoCreated: activity.userWhoCreated
        }"
        @remove="cardActionDelete"
      ></activity-card>
    </div>

  </form>
</template>

<script>
import config from '@/config.js';

import {
  PlusIcon,
} from '@/components/crm/Icons';

import VInput from '@/components/VInput.vue';
import VTextarea from '@/components/VTextarea.vue';
import VInputFileDrag from '@/components/VInputFileDrag.vue';
import ActivityCard from '@/components/crm/ActivityCard.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import Axios from 'axios';

export default {
  name: 'ContactActivities',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    owner: {
      type: String,
      default: '',
    },
    contactDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PlusIcon,
    VInput,
    VTextarea,
    VInputFileDrag,
    ActivityCard,
    SelectWrapper,
  },
  data() {
    return {
      activityTypes: [],
      contactActivities: [],
      addDialog: false,
      formData: {
        subject: '',
        typeId: '',
        dateOfActivity: '',
        description: '',
      },
      documents: [],
      fieldErrors: [],
    };
  },
  mounted() {
    this.getActivities();
    this.getActivityTypes();
  },
  methods: {
    async cardActionDelete(id) {
      Swal.fire({
        title: 'Excluir Atividade',
        html: '<p>Tem certeza que deseja excluir esta atividade?</p>',
        type: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Excluir',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Excluindo a atividade do contato',
          });
          const response = await Axios.delete(`${config.env.API_DEFAULT.host}/activities/delete-activity/${id}`);

          if (response.data.data) {
            Swal.fire('Excluir Atividade', 'Atividade excluída com sucesso!', 'success');

            this.getActivities();
          } else {
            Swal.fire('Excluir Atividade', 'Erro ao excluir atividade!', 'error');
          }

          this.$emit('updating', this.contactDetails.id);
          this.$store.commit('setLoading', { show: false });
        }
      });
    },
    async saveActivity() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Salvando atividade do contato.',
      });

      this.fieldErrors = [];

      const schema = yup.object().shape({
        subject: yup.string().min(3, 'O assunto deve conter no mínimo 3 caracteres!').required('O assunto é obrigatório!'),
        typeId: yup.string().required('O tipo é obrigatório!'),
        dateOfActivity: yup.string().required('A data é obrigatória!'),
        description: yup.string().min(3, 'A descrição deve conter no mínimo 3 caracteres!').max(5000, 'A descrição deve conter no máximo 5000 caracteres!').required('A descrição é obrigatória!'),
      });

      try {
        await schema.validate(this.formData, {
          abortEarly: false,
        });

        const now = moment();
        const hours = now.hour() < 10 ? `0${now.hour()}` : now.hour();
        const minutes = now.minutes() < 10 ? `0${now.minutes()}` : now.minutes();
        const seconds = now.seconds() < 10 ? `0${now.seconds()}` : now.seconds();
        this.formData.dateOfActivity = `${moment(this.formData.dateOfActivity, 'Y-MM-DD').format('Y-MM-DD')}T${hours}:${minutes}:${seconds}`;

        const response = await Axios.post(`${config.env.API_DEFAULT.host}/activities/save-contact-activity/${this.id}`, this.formData);

        if (response.data.data) {
          this.$store.commit('setLoading', { show: false });
          const activitySaved = response.data.data;

          if (this.documents.length > 0) {
            this.$store.commit('setLoading', {
              show: true,
              label: 'Carregando...',
              description: 'Salvando documentos da atividade do contato.',
            });
            const formData = new FormData();

            let index = 0;
            for (const doc of this.documents) {
              formData.append(`files['${index}']`, doc);
              index++;
            }

            const requestConfig = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            };

            const responseDocuments = await Axios.post(`${config.env.API_DEFAULT.host}/activityDocuments/uploadFile/${activitySaved.id}/CRM1`, formData, requestConfig);
            if (responseDocuments) {
              this.$store.commit('setLoading', { show: false });
              this.formData = {
                subject: '', typeId: '', dateOfActivity: '', description: '',
              };
              this.documents = [];
              Swal.fire({
                title: 'Adicionar Atividade',
                html: `Atividade e documentos adicionados com sucesso no cadastro de <strong>${this.owner}</strong>`,
                type: 'success',
              });
            } else {
              this.$store.commit('setLoading', { show: false });
              Swal.fire({
                title: 'Adicionar Atividade',
                html: 'Não foi possível adicionar esta nova atividade, porque houve algum problema ao tentar salvar os documentos. Por favor, tente novamente!',
                type: 'error',
              });
            }
          } else {
            this.$store.commit('setLoading', { show: false });
            Swal.fire({
              title: 'Adicionar Atividade',
              html: `Atividade adicionada com sucesso no cadastro de <strong>${this.owner}</strong>`,
              type: 'success',
            });
          }

          this.formData = {
            subject: '',
            typeId: '',
            dateOfActivity: '',
            description: '',
          };
          this.addDialog = false;

          this.$emit('updating', this.contactDetails.id);
          this.getActivities();
        }
      } catch (err) {
        this.$store.commit('setLoading', { show: false });
        if (err.name == 'ValidationError') {
          this.fieldErrors = err.inner;
          Swal.fire({
            title: 'Adicionar Atividade',
            html: '<p>Verifique os campos preenchidos no formulário.</p>',
            type: 'error',
          });
        } else {
          Swal.fire({
            title: 'Adicionar Atividade',
            html: `Houve algum problema ao tentar salvar a atividade: ${err}`,
            type: 'error',
          });
        }
      }
    },
    async getActivities() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando atividades do contato.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/activities/get-contact-activities/${this.id}`);
        this.contactActivities = response.data.data;
        this.$store.commit('setLoading', { show: false });
      } catch (err) {
        this.$store.commit('setLoading', { show: false });
        Swal.fire({
          title: 'Listar Atividades',
          html: `<p>Não foi possível recuperar as atividades do contato</p> <div>${error}</div>`,
          type: 'error',
        });
      }
    },
    async getActivityTypes() {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/activity-types`);
        const types = response.data.data;
        if (types.length > 0) {
          types.forEach((type) => {
            this.activityTypes.push({ label: type.name, value: type.id });
          });
        }
      } catch (err) {
        Swal.fire({
          title: 'Listar Tipos de Atividades',
          html: `<p>Não foi possível recuperar os tipos de atividade<p> <div>${error}</div>`,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
  .plus-button {
    appearance: none;
    --webkit-appearance: none;
    --moz-appearance: none;
    outline: none;
    --webkit-outline: none;
    --moz-outline: none;
    border: 0;
    background-color: #80B7E2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 0;
    border-radius: 2px;
  }

  .plus-button:hover {
    background-color: #1768a7;
  }

  .activity-submit {
    appearance: none;
    --webkit-appearance: none;
    --moz-appearance: none;
    outline: none;
    --webkit-outline: none;
    --moz-outline: none;
    border: 0;
    background-color: #104375;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    border-radius: 2px;
    color: white;
    padding: 10px 25px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all .2s ease-in-out 0s;
  }

  .activity-submit:hover {
    background-color: #1768a7;
  }

  .activity-enter-active,
  .activity-leave-active {
    transition: all .5s ease-in-out 0s;
    overflow: hidden;
  }

  .activity-enter,
  .activity-leave-to {
    max-height: 0px;
    overflow: hidden;
  }

  .activity-enter-to,
  .activity-leave {
    transition: all .5s ease-in-out 0s;
    max-height: 1000px;
    overflow: hidden;
  }
</style>
