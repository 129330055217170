var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-sidebar"},[_c('ContactListFilter',{on:{"filterCompanies":_vm.filterContacts}}),_c('form',{staticClass:"search-contacts",on:{"submit":function($event){$event.preventDefault();}}},[_c('search-icon'),_c('input',{attrs:{"type":"text","placeholder":"Pesquisar Contato"},domProps:{"value":_vm.crmContactsSearch},on:{"input":function($event){return _vm.updateCrmContactsSearch($event.target.value)}}}),_c('div',{staticClass:"dropdown dropleft"},[_vm._m(0),_c('div',{staticClass:"dropdown-menu wd-300 pd-5"},[_c('nav',{staticClass:"nav dropdown-nav"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'CrmAddContact' })}}},[_c('i',{staticClass:"icon ion-ios-person"}),_vm._v(" Adicionar Contato")]),_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'CrmAddCustomer' })}}},[_c('i',{staticClass:"icon ion-ios-briefcase"}),_vm._v(" Adicionar Empresa")])])])])],1),_c('div',{staticClass:"contacts-list"},[_c('ul',{staticClass:"contacts-letters"},_vm._l((_vm.alphabet),function(letter){return _c('li',{key:letter},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToLetter(letter)}}},[_vm._v(_vm._s(letter))])])}),0),_c('ul',{ref:"contactList",staticClass:"contacts"},_vm._l((_vm.orderedContacts),function(contacts,index){return _c('div',{key:index},[_c('li',{staticClass:"contact-letter-separator",attrs:{"data-letter":contacts[0].name.split('')[0].toUpperCase()}},[_c('span',[_vm._v(_vm._s(contacts[0].name.split("")[0].toUpperCase()))])]),_vm._l((contacts),function(contact){return _c('li',{key:contact.id,class:[
            'contact-item',
            contact.id == _vm.$route.params.id ? ' active' : '',
          ],on:{"click":function($event){$event.preventDefault();return _vm.openContact(contact.id)}}},[_c('span',{staticClass:"contact-letter"},[_vm._v(_vm._s(_vm.getFirstLetter(contact.name)))]),_c('div',{staticClass:"contact-content"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(contact.name.slice(0, 23)))]),_c('span',{staticClass:"email"},[_vm._v(_vm._s(contact.email1 ? contact.email1 : contact.email2))]),_c('span',{staticClass:"email"},[_vm._v(_vm._s(contact.city ? contact.city.toUpperCase() : "")+" "+_vm._s(contact.federalUnity ? "/ " + contact.federalUnity.toUpperCase() : ""))]),(contact.companiesCount > 0)?_c('div',{directives:[{name:"popover",rawName:"v-popover.right",value:({ name: 'popover-companies' }),expression:"{ name: 'popover-companies' }",modifiers:{"right":true}}]},[(contact.companiesCount === 0)?_c('span',{staticClass:"mt-2"},[_vm._v("Nenhum vinculo com empresas")]):_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getCompanies(contact.id, contact.name)}}},[_c('i',{staticClass:"fa fa-building mt-2"}),_vm._v(" "+_vm._s(_vm.getCountCompaniesTextValue(contact.companiesCount))+" ")]),_c('transition',{attrs:{"name":"fade"}},[_c('popover',{attrs:{"name":"popover-companies","width":340}},[_c('div',{staticClass:"popover-content"},[(_vm.inLoadCompanies)?_c('span',[_vm._v("Carregando...")]):_c('div',[_c('h5',[_vm._v("Empresas de "+_vm._s(_vm.contactName))]),_c('ul',_vm._l((_vm.contactCompanies),function(company){return _c('li',{key:company.id},[_vm._v(" "+_vm._s(company.completeName)+" ")])}),0)])])])],1)],1):_vm._e()]),(
              contact.dateOfBirth &&
              _vm.checkBirthday(contact.dateOfBirth).birthday === true
            )?_c('div',{staticClass:"contact-birthday"},[_c('cake-icon',{attrs:{"color":"#80B7E2","size":36}})],1):_vm._e(),(
              contact.openingDate &&
              _vm.checkBirthday(contact.openingDate).birthday === true
            )?_c('div',{staticClass:"contact-birthday"},[_c('cake-icon',{attrs:{"color":"#80B7E2","size":36}})],1):_vm._e()])})],2)}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dd-link",attrs:{"href":"#","data-toggle":"dropdown"}},[_c('div',[_c('i',{staticClass:"icon ion-more mg-r-10 tx-30"})])])
}]

export { render, staticRenderFns }