<template>
  <div
    style="width: 100%; overflow: hidden"
    class="contact-container"
    v-if="customerDetails !== null"
  >
    <div class="contact-tabs-container">
      <div class="scroll-tabs left" @click.prevent="scrollTabs('left')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </div>
      <ul class="contact-tabs" ref="tabs">
        <li
          :class="tab === 'geral' ? 'active' : ''"
          v-if="
            this.hasPrivilege('CRM_EMPRESAS_GERAL_VISUALIZAR') ||
            this.hasPrivilege('CRM_EMPRESAS_EDITAR')
          "
        >
          <a href="#" @click.prevent="tab = 'geral'">GERAL</a>
        </li>
        <li
          :class="tab === 'contatos' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_CONTATOS_VISUALIZAR')"
        >
          <a href="#" @click.prevent="tab = 'contatos'">CONTATOS</a>
        </li>
        <li
          :class="tab === 'comercial' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_COMERCIAL_VISUALIZAR')"
        >
          <a href="#" @click.prevent="tab = 'comercial'">COMERCIAL</a>
        </li>
        <li
          :class="tab === 'financeiro' ? 'active' : ''"
          v-if="
            this.hasPrivilege('CRM_EMPRESAS_FINANCEIRO_VISUALIZAR') ||
            this.hasPrivilege('CRM_EMPRESAS_FINANCEIRO_EDITAR')
          "
        >
          <a href="#" @click.prevent="tab = 'financeiro'">FINANCEIRO</a>
        </li>
        <li
          :class="tab === 'fornecedores' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_FORNECEDORES_VISUALIZAR')"
        >
          <a href="#" @click.prevent="tab = 'fornecedores'">FORNECEDORES</a>
        </li>
        <li
          :class="tab === 'atividades' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_ATIVIDADES_CRUD')"
        >
          <a href="#" @click.prevent="tab = 'atividades'">ATIVIDADES</a>
        </li>
        <li
          :class="tab === 'requisitos' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_REQUISITOS_VISUALIZAR')"
        >
          <a href="#" @click.prevent="tab = 'requisitos'">REQUISITOS</a>
        </li>
        <li
          :class="tab === 'inconsistencias' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_INCONSISTENCIAS_CRUD')"
        >
          <a href="#" @click.prevent="tab = 'inconsistencias'"
            >INCONSISTÊNCIAS</a
          >
        </li>
        <li
          :class="tab === 'conquistas' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_CONQUISTAS_CRUD')"
        >
          <a href="#" @click.prevent="tab = 'conquistas'">CONQUISTAS</a>
        </li>
        <li
          :class="tab === 'nps' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_NPS_CRUD')"
        >
          <a href="#" @click.prevent="tab = 'nps'">NPS</a>
        </li>
        <li
          :class="tab === 'documentos' ? 'active' : ''"
          v-if="this.hasPrivilege('CRM_EMPRESAS_DOCUMENTOS_CRUD')"
        >
          <a href="#" @click.prevent="tab = 'documentos'">DOCUMENTOS</a>
        </li>
      </ul>
      <div class="scroll-tabs right" @click.prevent="scrollTabs('right')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-right"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'geral'">
      <div class="contact-container-overflow">
        <customer-details :customer-details="customerDetails" />
        <!-- @reloadCustomerDetails="getCustomerDetails($route.params.id)" -->
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'contatos'">
      <div class="contact-container-overflow no-pd">
        <customer-contacts
          :customer-details="customerDetails"
        ></customer-contacts>
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'fornecedores'">
      <div class="contact-container-overflow no-pd">
        <customer-providers :customer-details="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'requisitos'">
      <div class="contact-container-overflow no-pd">
        <!-- <customer-requirements :customer-details="customerDetails" /> -->
        <companies-form-new-procedures :company="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'comercial'">
      <div class="contact-container-overflow no-pd">
        <customer-commercial
          :customer-details="customerDetails"
          @update="updateCustomerDetails"
        />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'atividades'">
      <div class="contact-container-overflow no-pd">
        <customer-activities
          :id="customerDetails.id"
          :owner="customerDetails.name"
          :customerDetails="customerDetails"
        ></customer-activities>
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'inconsistencias'">
      <div class="contact-container-overflow no-pd">
        <customer-inconsistences :customer-details="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'conquistas'">
      <div class="contact-container-overflow no-pd">
        <customer-achievements :customer-details="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'documentos'">
      <div class="contact-container-overflow no-pd">
        <customer-documents :customer-details="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'nps'">
      <div class="contact-container-overflow no-pd">
        <customer-nps :customer-details="customerDetails" />
      </div>
    </div>

    <div class="tab-item" v-if="tab === 'financeiro'">
      <div class="contact-container-overflow no-pd">
        <customer-finances :customer-details="customerDetails" />
      </div>
    </div>

    <!--      <div class="tab-item" v-if="tab === 'nps'">-->
    <!--        <div class="contact-container-overflow">-->
    <!--          <customer-nps-->
    <!--              :customer-details="customerDetails"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
  </div>
</template>

<script>
import config from "@/config.js";

import { mapActions } from "vuex";
import CustomerDetails from "@/components/crm/CustomerDetails.vue";
import CustomerActivities from "@/components/crm/CustomerActivities.vue";
import CustomerInconsistences from "@/components/crm/CustomerInconsistences.vue";
import CustomerAchievements from "@/components/crm/CustomerAchievements.vue";
import CustomerContacts from "@/components/crm/CustomerContacts.vue";
import CustomerNps from "@/components/crm/CustomerNps.vue";
import CustomerProviders from "@/components/crm/CustomerProviders.vue";
import CustomerCommercial from "@/components/crm/CustomerCommercial.vue";
import CompaniesFormNewProcedures from "@/components/crm/customer/procedures";
import CustomerDocuments from "@/components/crm/CustomerDocuments.vue";
import CustomerFinances from "@/components/crm/CustomerFinances.vue";

import Swal from "sweetalert2";
import Axios from "axios";
import CredentialService from "@/services/CredentialService.js";

export default {
  name: "CrmCustomerWrapper",
  components: {
    // Icons
    // Components
    CustomerDetails,
    CustomerActivities,
    CustomerInconsistences,
    CustomerAchievements,
    CustomerNps,
    CustomerContacts,
    CustomerProviders,
    CustomerCommercial,
    CustomerDocuments,
    CustomerFinances,
    "companies-form-new-procedures": CompaniesFormNewProcedures,
  },
  data() {
    return {
      tab: "geral",
      customerDetails: null,
    };
  },
  async mounted() {
    await this.getCustomerDetails(this.$route.params.id);
    this.checkPrivilege({
      privilege: "CRM_EMPRESAS_GERAL_VISUALIZAR",
      router: this.$router,
    });
  },
  methods: {
    ...mapActions(["checkPrivilege"]),
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    async getCustomerDetails(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando dados do cliente.",
      });

      this.customerDetails = null;
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/companies/${id}`
        );
        this.customerDetails = response.data;
        this.contactId = id;
      } catch (err) {
        Swal.fire({
          title: "Erro interno",
          html: `<p>Não foi possível recuperar os dados do cliente</p> <div>${err}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    updateCustomerDetails(e) {
      this.customerDetails = e;
    },
    scrollTabs(direction) {
      if (direction === "right") {
        this.$refs.tabs.scrollLeft += 300;
      }
      if (direction === "left") {
        this.$refs.tabs.scrollLeft -= 300;
      }
    },
  },
};
</script>

<style>
.contact-tabs {
  margin-bottom: 0px;
}
.tab-item {
  height: calc(100% - 65px);
}
</style>
