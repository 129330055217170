<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.25 1.75H1.75001C1.42784 1.75 1.16667 2.01117 1.16667 2.33333V11.6667C1.16667 11.9888 1.42784 12.25 1.75001 12.25H12.25C12.5722 12.25 12.8333 11.9888 12.8333 11.6667V2.33333C12.8333 2.01117 12.5722 1.75 12.25 1.75Z"
        stroke="currentColor" stroke-linejoin="round"/>
    <path d="M1.16667 5.25H12.8333" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.10417 5.25V12.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.89583 5.25V12.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.16667 8.75H12.8333" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M12.8333 2.33325V11.6666C12.8333 11.9888 12.5722 12.2499 12.25 12.2499H1.75001C1.42784 12.2499 1.16667 11.9888 1.16667 11.6666V2.33325"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TableIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
