export default class BankAccount {
  constructor(
    id,
    enabled,
    description,
    bank,
    checking,
    saving,
    investment,
    agencyNumber,
    accountNumber,
    openingBalance,
    openingBalanceDate,
    account,
  ) {
    this.id = id;
    this.enabled = enabled;
    this.description = description;
    this.bank = bank;
    this.checking = checking;
    this.saving = saving;
    this.investment = investment;
    this.agencyNumber = agencyNumber;
    this.accountNumber = accountNumber;
    this.openingBalance = openingBalance;
    this.openingBalanceDate = openingBalanceDate;
    this.account = account;
  }
}
