import Swal from 'sweetalert2';
import Axios from 'axios';
import * as constants from '../consts.js';

export default {
  getUser() {
    return JSON.parse(window.localStorage.getItem('user'));
  },

  getAccount() {
    return JSON.parse(window.localStorage.getItem('principal'));
  },

  getAccounts() {
    // return Axios.get(config.env.API_DEFAULT.host+"/credential/accounts");
  },

  hasPrivilege(privilege) {
    const { privilegies } = JSON.parse(window.localStorage.getItem('user')).data.person.group;
    if (privilegies !== undefined && privilege !== undefined) {
      return privilegies.filter((p) => p.name === privilege || 'SUPERADMINISTRADOR').length > 0;
    }

    if (privilege === undefined || privilege === '') {
      Swal.fire('Privilégio de Acesso', 'Não foi possível checar se o usuário tem acesso, pois nenhum privilégio foi informado para ser verificado!', 'error');
      return false;
    }
  },
};
