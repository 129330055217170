<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>
        <!-- card-header -->

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <invoice-form-general
                :invoice="invoice"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>
            <!-- tab-pane -->
          </div>
          <!-- tab-content -->
        </div>
        <!-- card-body -->
      </div>
      <!-- card -->
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import InvoicesFormGeneral from "@/views/billing/invoices/Form.General.vue";

export default {
  name: "InvoiceForm",
  components: {
    "invoice-form-general": InvoicesFormGeneral,
  },
  data() {
    return {
      mainTitle: "Adicionar Fatura ",
      invoice: {
        id: null,
        enabled: true,
        identification: "0000",
        type: "SINGLE_INVOICE",
        process: null,
        items: [],
        customer: null,
        account: null,
        bankAccountDestination: null,
        nfsIdentification: null,
        nfsDate: null,
        nfsTotalValue: 0.0,
        rpsNfsIdentification: null,
        rpsNfsDate: null,
        step: "OPEN",
        dueDate: null,
        value: 0.0,
        discountValue: 0.0,
        closeUser: null,
        closeDate: null,
        paidValue: 0.0,
        paymentDate: null,
        processTotalInputs: 0.0,
        processTotalOutputs: 0.0,
        processTotalBalance: 0.0,
        observations: null,
      },
      oldModel: {},
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
        }
      });
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/invoices/${this.$route.params.id}`
      )
        .then((response) => {
          this.invoice = response.data;

          Axios.get(this.invoice._links.bank.href)
            .then((response) => {
              this.$set(this.invoice, "bank", response.data);
            })
            .catch((error) => {
              this.$swal("Bancos", `Ops, houve algum erro: ${error}`, "error");
            });

          Axios.get(this.invoice._links.account.href)
            .then((response) => {
              this.$set(this.invoice, "account", response.data);
            })
            .catch((error) => {
              this.$swal("Filiais", `Ops, houve algum erro: ${error}`, "error");
            });
        })
        .catch((error) => {
          this.$swal(
            "Contas Bancarárias",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
