<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0012 9.35999C11.6544 9.35999 11.3109 9.4283 10.9905 9.56103C10.67 9.69377 10.3789 9.88832 10.1336 10.1336C9.88835 10.3788 9.6938 10.67 9.56107 10.9904C9.42834 11.3109 9.36002 11.6543 9.36002 12.0012C9.36002 12.348 9.42834 12.6915 9.56107 13.0119C9.6938 13.3324 9.88835 13.6235 10.1336 13.8688C10.3789 14.1141 10.67 14.3086 10.9905 14.4413C11.3109 14.5741 11.6544 14.6424 12.0012 14.6424C12.7017 14.6422 13.3734 14.3638 13.8687 13.8684C14.3639 13.3729 14.642 12.7011 14.6418 12.0006C14.6417 11.3001 14.3632 10.6284 13.8678 10.1332C13.3724 9.63794 12.7005 9.35983 12 9.35999H12.0012ZM3.60122 9.35999C3.25437 9.35999 2.91092 9.4283 2.59048 9.56103C2.27003 9.69377 1.97887 9.88832 1.73361 10.1336C1.48835 10.3788 1.2938 10.67 1.16107 10.9904C1.02834 11.3109 0.960022 11.6543 0.960022 12.0012C0.960022 12.348 1.02834 12.6915 1.16107 13.0119C1.2938 13.3324 1.48835 13.6235 1.73361 13.8688C1.97887 14.1141 2.27003 14.3086 2.59048 14.4413C2.91092 14.5741 3.25437 14.6424 3.60122 14.6424C4.30171 14.6422 4.97345 14.3638 5.46866 13.8684C5.96387 13.3729 6.24198 12.7011 6.24182 12.0006C6.24166 11.3001 5.96324 10.6284 5.46781 10.1332C4.97237 9.63794 4.30051 9.35983 3.60002 9.35999H3.60122ZM20.4012 9.35999C20.0544 9.35999 19.7109 9.4283 19.3905 9.56103C19.07 9.69377 18.7789 9.88832 18.5336 10.1336C18.2884 10.3788 18.0938 10.67 17.9611 10.9904C17.8283 11.3109 17.76 11.6543 17.76 12.0012C17.76 12.348 17.8283 12.6915 17.9611 13.0119C18.0938 13.3324 18.2884 13.6235 18.5336 13.8688C18.7789 14.1141 19.07 14.3086 19.3905 14.4413C19.7109 14.5741 20.0544 14.6424 20.4012 14.6424C21.1017 14.6422 21.7734 14.3638 22.2687 13.8684C22.7639 13.3729 23.042 12.7011 23.0418 12.0006C23.0417 11.3001 22.7632 10.6284 22.2678 10.1332C21.7724 9.63794 21.1005 9.35983 20.4 9.35999H20.4012Z"
      fill="#343A40"
    />
  </svg>
</template>

<script>
export default {
  name: 'DotsIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
