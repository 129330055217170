var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-mainpanel"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content p-4"},[_c('form',{staticClass:"groupForm columns md-gap",staticStyle:{"align-items":"start"},on:{"submit":function($event){$event.preventDefault();return _vm.storeGroup.apply(null, arguments)}}},[_c('div',{staticClass:"columns md-gap span-3"},[_vm._m(1),_c('v-input',{staticClass:"span-8",attrs:{"name":"nome","type":"text","label":"Nome"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-input',{staticClass:"span-8",attrs:{"name":"code","type":"text","label":"Código"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('select-wrapper',{staticClass:"span-8",attrs:{"options":[
                {
                  label: 'Sim',
                  value: true,
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ],"label":"Habilitado"},model:{value:(_vm.formData.enabled),callback:function ($$v) {_vm.$set(_vm.formData, "enabled", $$v)},expression:"formData.enabled"}})],1),_c('div',{staticClass:"columns md-gap span-5"},[_vm._m(2),_c('div',{staticClass:"span-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v(" PRIVILÉGIOS DISPONÍVEIS ")]),_c('div',{staticClass:"documents-search"},[_c('i',{staticClass:"fa fa-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscaPrivilegios),expression:"buscaPrivilegios"}],attrs:{"type":"text","name":"buscaPrivilegios","id":"buscaPrivilegios"},domProps:{"value":(_vm.buscaPrivilegios)},on:{"input":function($event){if($event.target.composing)return;_vm.buscaPrivilegios=$event.target.value}}})]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.filteredPrivileges),function(privilege){return [(
                            privilege.selected !== null &&
                            privilege.selected !== true
                          )?_c('li',{key:privilege.id,on:{"click":function($event){$event.preventDefault();privilege.selected = true}}},[_c('span',[_c('strong',[_vm._v(_vm._s(privilege.name))])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-right"})])]):_vm._e()]})],2)])]),_c('div',{staticClass:"col-2 d-flex flex-column justify-content-center align-items-center",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"documents-button",on:{"click":function($event){$event.preventDefault();return _vm.selectAll.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-double-right"})]),_c('div',{staticClass:"documents-button",on:{"click":function($event){$event.preventDefault();return _vm.deselectAll.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"documents-container"},[_c('div',{staticClass:"documents-header"},[_vm._v(" PRIVILÉGIOS SELECIONADOS ")]),_c('div',{staticClass:"documents-search"},[_c('p',[_vm._v(" "+_vm._s(_vm.selectedPrivileges)+" privilégio(s) selecionado(s) ")])]),_c('ul',{staticClass:"documents-list"},[_vm._l((_vm.privileges),function(privilege){return [(
                            privilege.selected !== null &&
                            privilege.selected !== false
                          )?_c('li',{key:privilege.id,on:{"click":function($event){$event.preventDefault();privilege.selected = false}}},[_c('span',[_c('strong',[_vm._v(_vm._s(privilege.name))])]),_c('a',{staticClass:"documents-button small",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa fa-angle-double-left"})])]):_vm._e()]})],2)])])])])]),_c('hr',{staticClass:"span-8 row justify-content-center"}),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-pageheader"},[_c('ol',{staticClass:"breadcrumb slim-breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Configuracões")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Configuracões de Acesso")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Grupos de Acesso")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" Adicionar Grupo de Acesso ")])]),_c('h6',{staticClass:"slim-pagetitle"},[_vm._v("Adicionar de Grupo de Acesso")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-8"},[_c('h4',{staticClass:"tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18"},[_vm._v("Informações do Grupo")]),_c('p',{staticClass:"mg-b-3"},[_vm._v("Informações principais para identificação deste Grupo de Acesso.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-8"},[_c('h4',{staticClass:"tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18"},[_vm._v("Privilégios")]),_c('p',{staticClass:"mg-b-3"},[_vm._v("Privilégios que os usuários que estão neste Grupo de Acesso podem ter.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-gap span-8 row justify-content-center"},[_c('button',{staticClass:"crm-button mb-4 wd-300",attrs:{"type":"submit"}},[_vm._v(" ADICIONAR GRUPO DE ACESSO ")])])
}]

export { render, staticRenderFns }