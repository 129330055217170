<template >
  <transition name="collapse-procedures">
    <div id="collapse-procedures">
      <div class="collapse" v-bind:class="{ active: open }">
        <button
          type="button"
          class="collapse-close"
          v-on:click="$emit('close', false)"
        ></button>

        <div class="collapse-content" v-bind:class="{ active: open }">
          <div class="collapse-opacity form-layout-2">
            <template v-if="open">
              <button
                class="btn btn-sm btn-primary btn-close"
                type="button"
                v-on:click="$emit('close', false)"
              >
                <i class="fa fa-chevron-right"></i>
              </button>

              <form class="scrolled">
                <h3 class="title">Informações do Requisito</h3>

                <div class="row no-gutters">
                  <div class="col-lg-6">
                    <div class="form-group bd-b-0-force">
                      <label class="form-control-label"> Descrição </label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          {{ model.description }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group bd-l-0-force bd-b-0-force">
                      <label class="form-control-label">Data de Início</label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-calendar op-5"></i>
                        </div>
                        <div class="input-group-solved-center">
                          {{ model.startDate | moment("DD/MM/YYYY") }}
                        </div>
                        <div class="input-group-solved-after"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group bd-l-0-force bd-b-0-force">
                      <label class="form-control-label">Data de Término</label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-calendar op-5"></i>
                        </div>
                        <div class="input-group-solved-center">
                          {{ model.finishDate | moment("DD/MM/YYYY") }}
                        </div>
                        <div class="input-group-solved-after"></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label"> Conteúdo </label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div
                          class="input-group-solved-after mg-l-10 wd-100p"
                          v-html="model.content"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row no-gutters" v-if="!!model.created">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label"> Criação </label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          {{ model.created | moment("DD/MM/YYYY") }} às
                          {{ model.created | moment("H:mm") }} por
                          {{ model.createdBy }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group bd-t-0-force">
                      <label class="form-control-label"> Atualização </label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          {{ model.modified | moment("DD/MM/YYYY") }} às
                          {{ model.modified | moment("H:mm") }} por
                          {{ model.modifiedBy }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div class="row no-gutters box-table">
                  <div class="card card-table w-100">
                    <div class="card-header">
                      <h6 class="slim-card-title">Log do Requisito</h6>
                    </div>
                    <!-- card-header -->
                    <div class="table-responsive">
                      <table class="table mg-b-0 tx-13">
                        <thead>
                          <tr class="tx-10">
                            <th class="pd-y-5">Usuário</th>
                            <th class="pd-y-5">Situação</th>
                            <th class="pd-y-5">Data</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-if="
                              model.logs !== undefined && model.logs !== null
                            "
                          >
                            <tr v-for="log in model.logs" :key="log.id">
                              <td>
                                <span
                                  class="tx-inverse tx-14 tx-medium d-block"
                                  >{{ log.createdBy }}</span
                                >
                              </td>
                              <td class="tx-12" v-if="log.checked">
                                <span
                                  class="square-8 mg-r-5 rounded-circle bg-success"
                                ></span>
                                Concluído
                              </td>
                              <td class="tx-12" v-else>
                                <span
                                  class="square-8 mg-r-5 rounded-circle bg-pink"
                                ></span>
                                Desmarcado
                              </td>
                              <td>
                                {{ log.created | moment("DD/MM/YYYY") }} às
                                {{ log.created | moment("H:mm") }}
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td colspan="3" class="text-center">
                                Até o momento houve nenhuma ação neste
                                requisito.
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "collapse-procedures",
  props: ["open", "modelUpdate"],
  components: {},

  watch: {
    modelUpdate() {
      this.model = this.modelUpdate;
    },
  },

  data() {
    return {
      model: {
        id: null,
        startDate: "",
        description: "",
        content: "",
        lastModification: "",
        logs: [],
        procedure: null,
      },
      oldModel: {},
      customToolbar: [
        [
          {
            header: 1,
          },
          {
            header: 2,
          },
        ],
        ["bold", "italic", "underline"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
        // ["image", "code-block"]
      ],
    };
  },
};
</script>

<style>
@import "./styles.css";
</style>
