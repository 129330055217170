<template>
  <div class="page-error-wrapper bg-white">
    <div>
      <h1 class="pd-b-10 tx-60 tx-black bd-b">Acesso não autorizado</h1>
      <img
        src="@/assets/img/gifs/acesso_negado.gif"
        class="wd-400 mb-4 mg-t-100"
        alt=""
      />
      <h5 class="tx-sm-24 tx-inverse mg-b-15">
        Usuário sem permissão (ERRO 403)
      </h5>
      <p class="mg-b-50 tx-sm-18">
        Atualmente você não tem permissão para acessar este Recurso / Página do
        Gescomex.
      </p>
      <p class="mg-b-50">
        <router-link :to="{ name: 'dashboard' }" class="btn btn-error"
          >VOLTAR</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage403",
};
</script>
