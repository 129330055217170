<template>
  <div class="row row-sm mg-t-0">
    <div class="col-md-6">
      <div class="card card-quick-post mg-t-10">
        <h6 class="slim-card-title">Adicionar Histórico</h6>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="3"
                placeholder="Digite o que você deseja apontar no histórico."
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFile" />
              <label
                class="custom-file-label custom-file-label-primary"
                for="customFile"
                >Escolher Arquivo</label
              >
            </div>
            <!-- custom-file -->
          </div>
          <!-- col -->
        </div>

        <br />
        <!-- form-group -->
        <div class="card-footer">
          <button class="btn btn-primary">ADICIONAR</button>
          <nav>
            <a href=""><i class="icon ion-archive"></i></a>
            <a href=""><i class="icon ion-calendar"></i></a>
          </nav>
        </div>
        <!-- card-footer -->
      </div>
    </div>
    <div class="timeline-group">
      <div class="timeline-item timeline-day">
        <div class="timeline-time">&nbsp;</div>
        <div class="timeline-body">
          <p class="timeline-date">02 de Janeiro de 2010</p>
        </div>
        <!-- timeline-body -->
      </div>
      <!-- timeline-item -->
      <div class="timeline-item">
        <div class="timeline-time">10:30pm</div>
        <div class="timeline-body">
          <p class="timeline-title"><a href="">Cliente Cadastrado</a></p>
          <p class="timeline-author">
            <a href="">Wanessa da Mata</a> | Automático
          </p>
        </div>
        <!-- timeline-body -->
      </div>

      <!-- timeline-item -->
      <div class="timeline-item">
        <div class="timeline-time">3:30pm</div>
        <div class="timeline-body">
          <p class="timeline-title">
            <a href="">Contrato de Prestação de Serviço de Importação</a>
          </p>
          <p class="timeline-author">
            <a href="">Lucas Lucco</a> | Envio de Documento
          </p>
        </div>
        <!-- timeline-body -->
      </div>
      <!-- timeline-item -->
      <div class="timeline-item timeline-day">
        <div class="timeline-time">&nbsp;</div>
        <div class="timeline-body">
          <p class="timeline-date">03 de Janeiro de 2010</p>
        </div>
        <!-- timeline-body -->
      </div>

      <div class="timeline-item">
        <div class="timeline-time">1:15pm</div>
        <div class="timeline-body">
          <p class="timeline-title"><a href="">Procuração Cadastrada</a></p>
          <p class="timeline-author"><a href="">Daniel Uriarte</a> | aaa</p>
        </div>
        <!-- timeline-body -->
      </div>
    </div>
    <!-- timeline-group -->
  </div>
</template>

<script>
import { EventBus } from "@/events/EventBus.js";

export default {
  name: "companies-form-history",
  props: ["company", "oldModel", "isNewRecord"],
  components: {},
  data() {
    return {};
  },
  methods: {
    onSubmit() {
      EventBus.$emit("companies.Form.onSubmit");
    },
  },
};
</script>
