<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li
            class="breadcrumb-item"
            v-if="$route.params.type !== 'categories'"
          >
            <a href="#">Financeiro</a>
          </li>
          <li class="breadcrumb-item" v-else><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <movimentTerm-form-general
                :movimentTerm="movimentTerm"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
                :mainTitle="mainTitle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import MovimentTermsFormGeneral from "@/views/finances/movimentTerms/Form.General.vue";
import Swal from "sweetalert2";

export default {
  name: "MovimentTerms",
  components: {
    "movimentTerm-form-general": MovimentTermsFormGeneral,
  },
  data() {
    return {
      mainTitle: "",
      // movimentTerm: new MovimentTerms(),
      movimentTerm: {},
      isDocuments: false,
      isPayments: false,
      isCostsCenters: false,
      // oldModel: new MovimentTerms(),
      oldModel: {},
      mappingFromToIntegration: [],
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    onSubmit() {
      const self = this;
      this.$validator.validate().then((result) => {
        if (result) {
        }
      });
    },
    loadModel() {
      const self = this;

      Axios.get(
        `${config.env.API_DEFAULT.host}/movimentTerms/${self.$route.params.id}`
      )
        .then((response) => {
          self.movimentTerm = response.data;
        })
        .catch((error) => {
          Swal.fire(
            "Movimentações",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },

  mounted() {
    // TYPE: documents, payments, costsCenters
    if (this.$route.params.type === "documents") {
      this.mainTitle = "Tipos de Documentos";
    } else if (this.$route.params.type === "payments") {
      this.mainTitle = "Formas de Pagamentos";
    } else if (this.$route.params.type === "costsCenters") {
      this.mainTitle = "Centro de Custos";
    } else if (this.$route.params.type === "categories") {
      this.mainTitle = "Categorias de Despesas/Numerário";
    }

    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
