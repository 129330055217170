<template>
  <div>
    <div class="signin-wrapper">
      <form @submit.prevent="submitForm">
        <div
          class="signin-box bd-0 wd-450 tx-center"
          style="background: none !important"
        >
          <h2 class="signin-title-primary mg-t-40 tx-normal mg-b-20">
            Recuperação de Senha
          </h2>
          <h4 class="signin-title-secondary lh-4 mg-b-20">
            Defina uma nova senha para o seu Usuaário.
          </h4>
          <div class="form-group">
            <input
              type="password"
              name="newPassword"
              class="form-control"
              v-model="form.newPassword"
              placeholder="Nova Senha"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              name="newPasswordConfirm"
              class="form-control"
              v-model="form.newPasswordConfirm"
              placeholder="Confirmar Nova Senha"
            />
          </div>
          <button class="btn btn-primary btn-block btn-signin bg-grandeur">
            Enviar código
          </button>
          <p class="mg-t-20 mg-b-0 tx-black tx-normal">
            <router-link :to="{ name: 'Login' }"
              >Voltar para o login</router-link
            >
          </p>
        </div>

        <p class="mg-t-20 mg-b-0 tx-12 tx-center">
          Copyright © {{ new Date() | moment("YYYY") }}
        </p>
        <p class="tx-11 tx-center">
          Todos os direitos reservados à {{ tenantName }}.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tenantName: config.env.setup.company.name,
      form: {
        pin: null,
        newPassword: null,
        newPasswordConfirm: null,
      },
    };
  },
  methods: {
    async submitForm() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Email de recuperação de senha!',
        description: 'Aguarde o email de recuperação de senha com o código!',
      });

      this.form.pin = this.$router.currentRoute.params.pin;

      await Axios.post(
        `${config.env.API_DEFAULT.host}/credential/recovery/changePassword`,
        this.form,
      )
        .then((response) => {
          Swal.fire({
            title: 'Recuperar Senha',
            html: '<p>Sua senha foi redefinida com sucesso, você será redirecionado á tela de Login</p>',
            type: 'success',
            showConfirmButton: true,
            confirmButtonText: 'OK',
          }).then(async (result) => {
            if (result.value) {
              this.$router.push({ name: 'login' });
            }
          });
        })
        .catch((error) => {
          Swal.fire(
            'Recuperacão de senha',
            error.response.data.message,
            'error',
          );

          this.$store.commit('setLoading', {
            show: false,
          });
        });

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
.card-change-password {
  margin: auto;
  margin-top: 150px;
}
</style>
