<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'operational-events-list' }"
              >Eventos de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edição de Evento
          </li>
        </ol>
        <h6 class="slim-pagetitle">Atualizar Evento</h6>
      </div>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="form-layout form-layout-2">
              <label for="" class="section-title mg-t-0"
                >Disparar eventos quando</label
              >
              <div class="row no-gutters">
                <div class="col-md-2">
                  <div class="form-group mg-md-l--1">
                    <label for="enabled" class="form-control-label"
                      >Habilitado <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      id="enabled"
                      v-model="formData.enabled"
                      required
                    >
                      <option value="">Selecione</option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2 col-sm-2">
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label"
                      >Código <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="formData.code"
                      class="form-control"
                    />
                    <span
                      v-show="errors.has('code')"
                      class="parsley-errors-list filled"
                      >Digite um código</span
                    >
                  </div>
                </div>
                <div class="col-md-8 col-sm-8">
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label"
                      >Descrição <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="formData.description"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <label for="" class="section-title mg-t-20"
                >Condições do Evento</label
              >
              <table
                class="table display responsive nowrap dataTable no-footer dtr-inline tx-13 bd-0"
              >
                <tbody>
                  <tr
                    v-for="(condition, index) in formData.conditions"
                    :key="index"
                  >
                    <td>Quando</td>
                    <td>
                      <select class="form-control" v-model="condition.whenType">
                        <option value="">Escolha uma Opção</option>
                        <option value="QUANDO_PREENCHER_CAMPO">
                          Preencher o Campo
                        </option>
                        <option value="QUANDO_UPLOAD_DOCUMENTO">
                          Fize upload de Documento
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        v-model="condition.whenField"
                        v-if="condition.whenType === 'QUANDO_PREENCHER_CAMPO'"
                      >
                        <option value="">Escolha um Campo</option>
                        <option
                          v-for="(value, key, index) in labels"
                          :value="key"
                          :key="index"
                        >
                          <template v-if="value.type === 'date'">
                            {{ value.label }}
                          </template>
                        </option>
                      </select>
                      <select
                        class="form-control"
                        v-model="condition.whenDocumentType"
                        v-else
                      >
                        <option value="">Escolha um Documento</option>
                        <option
                          v-for="document in documentsTypes"
                          :value="document.id"
                          :key="document.id"
                        >
                          {{ document.description }}
                        </option>
                      </select>
                    </td>

                    <td>, se o</td>
                    <td>
                      <select class="form-control" v-model="condition.ifType">
                        <option value="SE_NENHUMA_OPCAO">Nenhuma Opção</option>
                        <option value="SE_CAMPO">Campo</option>
                        <option value="SE_DOCUMETO">Documento</option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        v-model="condition.ifField"
                        v-if="condition.ifType === 'SE_CAMPO'"
                      >
                        <option value="">Escolha um Campo</option>
                        <option
                          v-for="(value, key, index) in labels"
                          :value="key"
                          :key="index"
                        >
                          <template v-if="value.type === 'date'">
                            {{ value.label }}
                          </template>
                        </option>
                      </select>
                      <select
                        class="form-control"
                        v-model="condition.ifDocumentType"
                        v-else
                      >
                        <option value="">Escolha um Documento</option>
                        <option
                          v-for="document in documentsTypes"
                          :value="document.id"
                          :key="document.id"
                        >
                          {{ document.description }}
                        </option>
                      </select>
                    </td>
                    <td>estiver</td>
                    <td>
                      <select class="form-control" v-model="condition.stayType">
                        <option value="ESTIVER_NENHUMA_OPCAO">
                          Nenhuma Opção
                        </option>
                        <option value="ESTIVER_PREENCHIDO">Preenchido</option>
                        <option value="ESTIVER_VAZIO">Vazio</option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        v-model="condition.condition"
                      >
                        <option
                          value="AND"
                          :disabled="shouldAllow('AND', index)"
                        >
                          , e
                        </option>
                        <option value="OR" :disabled="shouldAllow('OR', index)">
                          , ou
                        </option>
                      </select>
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-white"
                        @click.prevent="addCondition"
                      >
                        <div><i class="icon ion-plus"></i></div>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger ml-2"
                        v-if="formData.conditions.length > 1 && index !== 0"
                        @click.prevent="removeCondition(index)"
                      >
                        <div><i class="icon ion-trash-a"></i></div>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <label class="section-title mg-t-20">Executar</label>
              <div class="row no-gutters">
                <div class="col-md-3 col-sm-12">
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label"
                      >Alterar título do Follow-up
                      <span class="tx-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      v-model="formData.changeFollowUpTitle"
                      required
                    >
                      <option value="">Selecione</option>
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-md-3 col-sm-12"
                  v-if="formData.changeFollowUpTitle"
                >
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label">Título </label>
                    <input
                      type="text"
                      v-model="formData.followUpTitle"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-12">
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label"
                      >Alterar Etapa do processo
                      <span class="tx-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      v-model="formData.changeProcessStep"
                    >
                      <option value="">Selecione</option>
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-md-3 col-sm-12"
                  v-if="formData.changeProcessStep"
                >
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label">Etapa do Processo</label>
                    <v-select
                      v-model="formData.processStep"
                      name="processStep"
                      :options="steps"
                      label="description"
                      v-validate="'required'"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.description }}
                      </template>
                      <span slot="no-options"
                        >Nenhuma etapa foi encontrada!</span
                      >
                    </v-select>
                  </div>
                </div>
              </div>
              <label class="section-title mg-t-20"
                >Histórico e documentos</label
              >
              <div class="row no-gutters">
                <div class="col-md-3 col-sm-12">
                  <div class="form-group mg-md-l--1 bd-b-0-force">
                    <label class="form-control-label"
                      >Gerar histórico no processo
                      <span class="tx-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      v-model="formData.generateHistory"
                    >
                      <option value="">Selecione</option>
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 col-sm-12">
                  <div class="form-group mg-md-l--1 bd-b-0-force">
                    <label class="form-control-label"
                      >Histórico visível ao cliente
                    </label>
                    <select
                      class="form-control"
                      v-model="formData.isHistoryVisibleToClient"
                    >
                      <option value="">Selecione</option>
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group mg-md-l--1 bd-b-0-force">
                    <label class="form-control-label">Documentos </label>
                    <v-select
                      class="form-control"
                      v-model="formData.jsonDocuments"
                      label="description"
                      :options="documentsTypes"
                      multiple
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.code }} | {{ option.description }}
                      </template>
                      <span slot="no-options"
                        >Nenhuma Categoria / Plano de Contas foi
                        encontrada!</span
                      >
                    </v-select>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12">
                  <div class="form-group mg-md-l--1">
                    <label class="form-control-label"
                      >Descrição do histórico
                    </label>
                    <vue-tribute :options="options">
                      <textarea
                        class="form-control"
                        v-model="formData.history"
                        placeholder="@..."
                      ></textarea>
                    </vue-tribute>
                    <span
                      v-show="errors.has('description')"
                      class="parsley-errors-list filled"
                      >Selecione uma opção</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mg-t-15">
              <div class="col-md-3">
                <button class="btn btn-primary btn-block" type="submit">
                  <i class="fa fa-floppy-o mg-r-5"></i>
                  SALVAR
                </button>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-secondary btn-block"
                  type="button"
                  @click.prevent="$router.go(-1)"
                >
                  <i class="fa fa-reply mg-r-5"></i>
                  VOLTAR
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import config from "@/config.js";
import VueTribute from "vue-tribute";
import * as labelsProcesses from "@/labels/labels.processes.js";
import * as labelsProcessesHistoryEvent from "@/labels/labels.processes.history.events.js";

export default {
  data() {
    return {
      event: null,
      formData: {
        enabled: true,
        description: "Novo Evento",
        conditions: [],
        changeFollowUpTitle: false,
        followUpTitle: "",
        code: "",
        changeProcessStep: false,
        processStep: "",
        generateHistory: false,
        isHistoryVisibleToClient: false,
        history: "",
        jsonDocuments: null,
      },
      labels: {
        ...labelsProcesses.labels[0],
      },
      options: {
        trigger: "@",
        values: labelsProcessesHistoryEvent.labels,
      },
    };
  },
  components: {
    VueTribute,
  },
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
      documentsTypes: (state) => state.ProcessStore.documentsTypes,
    }),
  },
  methods: {
    addCondition() {
      this.formData.conditions.push({
        whenType: "",
        whenField: "",
        whenDocumentType: null,
        whenDocumentTypeLabel: null,
        ifType: "SE_NENHUMA_OPCAO",
        ifField: "",
        ifDocumentType: "",
        stayType: "ESTIVER_NENHUMA_OPCAO",
        condition: "AND",
      });
    },
    removeCondition(index) {
      this.formData.conditions.splice(index, 1);
    },
    async submitForm() {
      try {
        if (this.formData.processStep !== null) {
          this.formData.processStep = this.formData.processStep.id;
        }

        if (this.formData.conditions.length > 0) {
          this.formData.conditions = this.formData.conditions.map(
            (condition) => ({
              whenType: condition.whenType,
              whenField: condition.whenField,
              whenDocumentType: condition.whenDocumentType,
              whenDocumentTypeLabel: condition.whenDocumentTypeLabel,
              ifType: condition.ifType,
              ifField: condition.ifField,
              ifDocumentType: condition.ifDocumentType,
              stayType: condition.stayType,
              condition: condition.condition,
            })
          );
        }

        const response = await Axios.put(
          `${config.env.API_DEFAULT.host}/processEvents/${this.$route.params.id}`,
          this.formData
        );

        if (response.data.data) {
          Swal.fire({
            type: "success",
            title: "Cadastro de Evento",
            html: "Evento cadastrado com sucesso!",
          });

          this.getEvent();
        }
      } catch (e) {
        Swal.fire({
          type: "error",
          title: "Cadastro de Evento",
          html: "Houve um erro inesperado ao tentar cadastrar o Evento",
        });
      }
    },
    async getEvent() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando dados do Evento.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/processEvents/${this.$route.params.id}`
        );

        if (response.data.data) {
          this.event = response.data.data;

          this.formData.enabled = this.event.enabled;
          this.formData.code = this.event.code;
          this.formData.description = this.event.description;
          this.formData.changeFollowUpTitle = this.event.changeFollowUpTitle;
          this.formData.followUpTitle = this.event.followUpTitle;
          this.formData.changeProcessStep = this.event.changeProcessStep;
          this.formData.processStep = this.event.processStep;
          this.formData.generateHistory = this.event.generateHistory;
          this.formData.isHistoryVisibleToClient =
            this.event.isHistoryVisibleToClient;
          this.formData.jsonDocuments = this.event.jsonDocuments;
          this.formData.history = this.event.history;
          // this.formData.conditions = this.event.conditions;
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        Swal.fire({
          type: "error",
          title: "Buscar Evento",
          html: "Houve um erro inesperado ao tentar buscar o Evento",
        });

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    shouldAllow(value, index) {
      if (this.formData.conditions.length > 1 && index > 0) {
        if (value == this.formData.conditions[0].condition) {
          return false;
        }
        return true;
      }
      return false;
    },
    async getConditions() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando as condições do Evento.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/processEvents/getConditions/${this.$route.params.id}`
        );

        this.formData.conditions = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        Swal.fire({
          type: "error",
          title: "Buscar condições do Evento",
          html: "Houve um erro inesperado ao tentar buscar as condições do Evento",
        });

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("ProcessStore/getSteps");
    await this.$store.dispatch("ProcessStore/getDocumentsType");

    await this.getEvent();
    await this.getConditions();
  },
};
</script>
