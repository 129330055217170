<template>
  <div
    class="cont-filter container bg-white pd-h-10 pd-t-20 pd-b-20 d-flex align-items-center"
  >
    <span class="material-icons-outlined icon"> settings_input_component </span>

    <div class="input-comp input-comp-limited">
      <label class="form-control-label">CLIENTES</label>
      <v-select
        v-model="filters.customer"
        placeholder="Selecione uma opção"
        label="name"
        :options="customers"
        name="team"
      >
        <template slot="option" slot-scope="option">
          {{ option.completeName }}
        </template>
      </v-select>
    </div>

    <div class="input-comp">
      <label for="grupo">EQUIPE / GRUPO</label>
      <select v-model="filters.team" name="team" id="grupo">
        <option value="">Selecione um time</option>
        <option v-for="team in teams" :value="team.id" :key="team.id" selected>
          {{ team.name }}
        </option>
      </select>
    </div>

    <div class="input-comp">
      <label for="grupo">ETAPA</label>
      <select v-model="filters.step" name="team" id="grupo">
        <option value="">Selecione uma etapa</option>
        <option v-for="step in steps" :value="step.id" :key="step.id" selected>
          {{ step.alias }}
        </option>
      </select>
    </div>

    <!--    <div class="input-comp date">-->
    <!--      <label for="etapas">DATA INICIAL</label>-->
    <!--      <date-picker lang="pt-br" -->
    <!--        name="data-inicial"-->
    <!--        value=""-->
    <!--        v-model="firstDate"-->
    <!--        class="input-date"-->
    <!--        format="DD/MM/YY"-->
    <!--        -->
    <!--        clear-button="true"-->
    <!--        placeholder="Selecione"-->
    <!--      ></date-picker>-->
    <!--    </div>-->

    <!--    <div class="input-comp date">-->
    <!--      <label for="etapas">DATA FINAL</label>-->
    <!--      <date-picker lang="pt-br" -->
    <!--        name="data-final"-->
    <!--        value=""-->
    <!--        v-model="secondDate"-->
    <!--        class="input-date"-->
    <!--        format="DD/MM/YY"-->
    <!--        -->
    <!--        clear-button="true"-->
    <!--        placeholder="Selecione"-->
    <!--      ></date-picker>-->
    <!--    </div>-->

    <div>
      <button
        type="button"
        class="filter-btn"
        @click.prevent="findProcessesWithData"
      >
        <span class="material-icons-outlined"> cached </span>
        ATUALIZAR
      </button>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";

import {mapState} from "vuex";

export default {
  name: "PartnerIntegrationsFilter",
  data() {
    return {
      customers: [],
      teams: [],
      firstDate: null,
      secondDate: null,
      filters: {
        customer: null,
        team: null,
        step: null,
      },
    };
  },
  components: {},
  mounted() {
    this.getCustomers();
    this.getOperationalTeams();
    this.$store.dispatch("ProcessStore/getSteps");
  },
  props: {},
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
    }),
  },
  methods: {
    findProcessesWithData() {
      const filters = {
        customerId: this.filters.customer ? this.filters.customer.id : null,
        teamId: this.filters.team ? this.filters.team : null,
        stepId: this.filters.step ? this.filters.step : null,
      };

      this.$emit("find-processes-with-data", filters);
    },
    getCustomers() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`
      ).then((response) => {
        this.customers = response.data._embedded.companies;
      });
    },
    getOperationalTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
        this.teams = response.data._embedded.teams;
      });
    },
  },
};
</script>

<style scoped>
.cont-filter {
  gap: 20px;
}

.input-comp {
  flex: 1 1 auto;
}

.input-comp-limited {
  flex: 0 1 33%;
}

.input-comp.date {
  flex: 0 1 auto;
}

.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}

.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}

.icon {
  color: var(--gray-dark);
}
</style>

<style>
body .input-comp .dropdown-toggle {
  display: flex;
  border: 0 !important;
  border-bottom: 1px solid var(--gray-dark) !important;
  min-height: 35px !important;
  border-radius: 0 !important;
}

.input-comp .v-select .open-indicator:before {
  border-color: black;
  border-width: 2px 2px 0 0;
  width: 7px;
  height: 7px;
}

.input-comp .dropdown-toggle input::placeholder {
  color: black;
}

.input-comp .mx-input-append::before {
  display: none;
}

.input-comp .mx-input-append {
  line-height: 0.5;
}

.input-comp .mx-input-wrapper .mx-clear-wrapper {
  margin-top: -3px !important;
  background-color: white !important;
  z-index: 9;
}

body .input-comp .mx-input-append svg {
  display: block !important;
}
</style>
