<template>
  <button v-if="type === 'button'" class="table-button" :class="color">
    <slot name="icon" v-if="$slots.icon" />
    <span>
      <slot />
    </span>
  </button>

  <RouterLink :to="link" v-else-if="type === 'link'" class="table-button" :class="color">
    <slot name="icon" v-if="$slots.icon" />
    <span>
      <slot />
    </span>
  </RouterLink>
</template>

<script>
export default {
  name: 'VTableButton',
  props: {
    color: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'danger', 'dark', 'success'].includes(value);
      },
    },
    type: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'link'].includes(value);
      },
    },
    link: {
      type: String,
      default: '#',
    },
  },
};
</script>

<style scoped>
.table-button {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
  background-color: transparent;
  padding: 0.75rem;
  border: 0;
  transition: all .2s ease-in-out 0s;
  cursor: pointer;
  white-space: nowrap;
}

.info {
  color: #0080FF;
}

.info:hover {
  color: #0067ce;
}

.danger {
  color: #A80005;
}

.danger:hover {
  color: #730004;
}

.dark {
  color: #104375;
}

.dark:hover {
  color: #061f38;
}

.success {
  color: #33941F;
}

.success:hover {
  color: #205d14;
}
</style>
