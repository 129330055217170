<script>
export default {
  name: 'DropdownComponent',
  props: {
    label: {
      type: String,
      default: 'Selecione',
    },
    contentPosition: {
      type: String,
      default: 'right',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    minWidth: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    outsideClick(e) {
      if (!e.target.closest('.dropdown-component')) {
        this.open = false;
      }
    },
    escapeKey(e) {
      if (e.key == 'Escape') {
        this.open = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.outsideClick);
    window.addEventListener('keyup', this.escapeKey);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.outsideClick);
    window.removeEventListener('keyup', this.escapeKey);
  },
};
</script>

<template>
  <div class="dropdown-component" :class="contentPosition">
    <button class="crm-button sm" @click.prevent="open = !open">
      <div class="flex ai-c fgap1">
        <span>{{ label }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
    </button>

    <transition name="dropdown">
      <ul
        v-if="open"
        class="dropdown-component-content"
        :style="`min-width: ${minWidth}px`"
      >
        <slot name="content"></slot>
      </ul>
    </transition>
  </div>
</template>

<style>
.dropdown-component {
  position: relative;
  z-index: 999;
}

.dropdown-component .dropdown-component-content {
  position: absolute;
  top: calc(100% + 10px);
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  z-index: 999;
  list-style: none;
  background-color: white;
  padding: 0;
  overflow: hidden;
}

.dropdown-component.right .dropdown-component-content {
  right: 0;
}

.dropdown-component.left .dropdown-component-content {
  left: 0;
}

.dropdown-component .dropdown-component-content li {
  padding: 10px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

.dropdown-component .dropdown-component-content li a {
  color: inherit;
}

.dropdown-component .dropdown-component-content li:hover {
  background-color: #f5f6f7;
}

.dropdown-component .dropdown-component-content li:not(:last-of-type) {
  border-bottom: 1px solid #e2e2e2;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.2s ease-in-out 0s;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}

.dropdown-enter-to,
.dropdown-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
