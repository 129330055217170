<template>
  <div class="form-layout form-layout-2 form-dates-sidebar">
    <div class="row no-gutters">
      <div class="col-md-12">
        <div class="card card-quick-post pd-15-force mg-t-10 mg-b-20">
          <h6 class="slim-card-title mg-b-10-force">Adicionar Histórico</h6>
          <div class="row">
            <div class="col-lg-12 mg-b-10">
              <div class="form-group rounded-10">
                <textarea
                  v-model="history.description"
                  class="form-control"
                  rows="3"
                  placeholder="Digite o que você deseja apontar no histórico."
                ></textarea>
              </div>
            </div>
          </div>
          <div class="mg-t-0">
            <select-wrapper
              v-model="history.priority"
              :options="priorityOptions"
              label="Prioridade"
            />
          </div>
          <div
            class="card-footer mg-t-10 d-flex align-items-center justify-content-between"
          >
            <button class="btn btn-primary" @click="addHistory()">
              ADICIONAR
            </button>

            <label class="ckbox mg-b-0">
              <input
                type="checkbox"
                v-model="history.isVisibleToClient"
                value="true"
              />
              <span class="pd-r-0-force">Visível ao cliente</span>
            </label>
          </div>
        </div>

        <div class="post-group">
          <perfect-scrollbar class="operational-form-sidebar-timeline">
            <template v-for="(history, index) in listOfHistories">
              <div class="post-item" :key="history.id" v-if="index === 0">
                <hr />

                <span class="post-date" v-if="history.legacyCreated"
                  >Adicionado
                  {{ history.legacyCreated | moment("from", "now") }}</span
                >
                <span class="post-date" v-else
                  >Adicionado
                  {{ history.created | moment("from", "now") }}</span
                >

                <p class="post-title tx-16">
                  <a href="#" class="pd-r-5">{{ history.description }}</a>
                  <small class="tx-normal" v-if="history.stepDescription">({{ history.stepDescription }})</small>
                </p>
                <p class="tx-12 mg-b-10">
                  <template v-if="history.legacyCreatedBy">
                    Criado por:
                    <a href>{{ history.legacyCreatedBy }}</a>
                  </template>
                  <template v-else>
                    Criado por:
                    <a href>{{ history.createdBy }}</a>
                  </template>
                  <i
                    class="icon ion-person-add tx-15 mg-l-10 tx-danger"
                    v-if="history.isVisibleToClient"
                  ></i>
                </p>

                <hr />
              </div>
              <div class="post-item" :key="history.id" v-else>
                <span class="post-date" v-if="history.legacyCreated"
                  >{{
                    history.legacyCreated | moment("DD \\d\\e MMMM \\d\\e YYYY")
                  }}
                  às {{ history.legacyCreated | moment("H:mm:ss") }}</span
                >
                <span class="post-date" v-else
                  >{{
                    history.created | moment("DD \\d\\e MMMM \\d\\e YYYY")
                  }}
                  às {{ history.created | moment("H:mm:ss") }}</span
                >
                <p class="tx-14 tx-normal mg-b-0 tx-black" v-html="history.description">
                </p>
                <template v-if="history.legacyCreatedBy">
                  Criado por:
                  <a href>{{ history.legacyCreatedBy }}</a>
                </template>
                <template v-else>
                  Criado por:
                  <a href>{{ history.createdBy }}</a>
                </template>
                <i
                  class="icon ion-person-add tx-15 mg-l-10 tx-danger"
                  v-if="history.isVisibleToClient"
                ></i>
              </div>
            </template>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import { EventBus } from "@/events/EventBus.js";

import Swal from "sweetalert2";
import SelectWrapper from "@/components/SelectWrapper.vue";

export default {
  name: "FormSidebar",
  components: {
    SelectWrapper,
  },
  data() {
    return {
      model: {},
      modelTitle: "Histórico do Processo",
      priorityOptions: [
        {
          label: "Normal",
          value: "NORMAL",
        },
        {
          label: "Importante",
          value: "IMPORTANTE",
        },
        {
          label: "Urgente",
          value: "URGENTE",
        },
      ],
      history: {
        processType: "IMPORT",
        isVisibleToClient: false,
        priority: "NORMAL",
      },
      listOfHistories: [],
    };
  },
  methods: {
    load() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Histórico",
        description: "Carregando...",
      });

      if (
        this.process !== null &&
        this.process.id !== null &&
        this.process.id !== undefined
      ) {
        Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/history`
        )
          .then((response) => {
            this.listOfHistories = response.data._embedded.processHistories;
          })
          .catch((error) => {
            Swal.fire(
              this.modelTitle,
              "Não foi possível carregar o histórico do processo.",
              "error"
            );
          });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    addHistory() {
      const self = this;
      this.$store.commit("setLoading", {
        show: true,
        label: this.modelTitle,
        description: "Adicionando novo histórico ao processo...",
      });

      this.history.stepDescription = this.model.step.description;

      Axios.post(
        `${config.env.API_DEFAULT.host}/history/process/${this.model.id}/save`,
        this.history
      )
        .then((response) => {
          if (response) {
            if (this.history.isVisibleToClient) {
              Swal.fire({
                title: "Enviar Follow UP",
                text: "Você acabou de adicionar um Histórico visível ao cliente, você deseja enviar o Follow-UP agora?",
                type: "success",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, enviar!",
                cancelButtonText: "Não",
              }).then((result) => {
                if (result.value) {
                  self.$store.dispatch("ProcessStore/sendFollowUP", {
                    id: self.process.id,
                    identification: self.process.identification,
                  });
                }
              });
            } else {
              Swal.fire({
                title: this.modelTitle,
                text: response.data.message,
                type: "success",
              });
            }

            this.load();
            this.history.description = "";
            this.history.isVisibleToClient = false;
            this.history.stepDescription = null;
          } else {
            this.$swal(this.modelTitle, response.data.message, "error");
          }

          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });

          let errors = "";
          error.response.data.data.errors.forEach((e) => {
            errors += ` - ${e}<br/>`;
          });
          errors += "";

          Swal.fire(
            this.modelTitle,
            `${error.response.data.message}: <br/><br/>${errors}`,
            "error"
          );
        });
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
  },
  mounted() {
    if (this.process.id !== null && this.process.id !== undefined) {
      this.load();
    }
  },
  created() {
    this.model = {
      ...this.process,
    };

    EventBus.$on("Operational.imp.Form.Sidebar.Timeline.load", () => {
      this.load();
    });
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.form-layout-2.form-dates-sidebar {
  .form-group {
    height: initial;

    & > label {
      min-width: 120px;
      margin: 0 20px 0 0;
    }
  }
}

.operational-form-sidebar-timeline {
  height: 100vh;
  overflow: auto;
}
</style>
