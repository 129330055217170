export const inputFields = [
  {
    name: 'Caixa de Texto',
    type: 'DISSERTATION',
  },
  {
    name: 'Nota (0 à 10)',
    type: 'MULTIPLE_CHOICE',
  },
];

export function getTypeName(type) {
  const result = inputFields.find((field) => field.type === type);
  if (result) {
    return result.name;
  }
  return undefined;
}
