<template>
  <svg
    width="61"
    height="56"
    viewBox="0 0 61 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_331_871)">
      <path
        d="M59.3826 11.9742H57.5657C56.6137 11.9742 55.8368 12.6712 55.8197 13.5333H54.423C53.9319 13.5333 53.5335 13.8957 53.5335 14.3424C53.5335 14.7895 53.9319 15.1515 54.423 15.1515H55.8516C56.8036 15.1515 57.5805 14.4549 57.5981 13.5923H58.9411V41.6902C58.9411 41.7066 58.9263 41.7201 58.9082 41.7201H1.8118C1.79373 41.7201 1.77891 41.7066 1.77891 41.6902V13.5923H3.11216C3.12976 14.4549 3.90618 15.1515 4.85864 15.1515H50.1846C50.6757 15.1515 51.0741 14.7895 51.0741 14.3424C51.0741 13.8957 50.6757 13.5333 50.1846 13.5333H4.8906C4.87346 12.6712 4.09658 11.9742 3.14412 11.9742H1.33742C0.599918 11.9742 0 12.5199 0 13.1907V41.6902C0 42.5987 0.813016 43.3382 1.8118 43.3382H58.9082C59.907 43.3382 60.72 42.5987 60.72 41.6902V13.1907C60.72 12.5199 60.1201 11.9742 59.3826 11.9742V11.9742Z"
        fill="#004385"
      />
      <path
        d="M5.10881 17.8513C4.61776 17.8513 4.21936 18.2137 4.21936 18.6604V38.5757C4.21936 39.0224 4.61776 39.3848 5.10881 39.3848H55.8748C56.3659 39.3848 56.7643 39.0224 56.7643 38.5757V18.6604C56.7643 18.2137 56.3659 17.8513 55.8748 17.8513H5.10881ZM45.755 37.7666H42.9189V34.1435C42.9189 33.6964 42.5205 33.3344 42.0295 33.3344C41.5384 33.3344 41.14 33.6964 41.14 34.1435V37.7666H38.3039V19.4695H41.14V30.174C41.14 30.6207 41.5384 30.9831 42.0295 30.9831C42.5205 30.9831 42.9189 30.6207 42.9189 30.174V19.4695H45.755V37.7666ZM47.5339 19.4695H50.3704V37.7666H47.5339V19.4695ZM19.8436 19.4695H22.6797V37.7666H19.8436V19.4695ZM18.0647 37.7666H15.2287V19.4695H18.0647V37.7666ZM24.4586 19.4695H27.2946V37.7666H24.4586V19.4695ZM29.0736 19.4695H31.9101V37.7666H29.0736V19.4695ZM33.689 19.4695H36.525V37.7666H33.689V19.4695ZM5.99827 19.4695H8.83432V20.8432C8.83432 21.2899 9.23273 21.6523 9.72378 21.6523C10.2153 21.6523 10.6132 21.2899 10.6132 20.8432V19.4695H13.4498V37.7666H10.6132V24.9496C10.6132 24.503 10.2153 24.1406 9.72378 24.1406C9.23273 24.1406 8.83432 24.503 8.83432 24.9496V37.7666H5.99827V19.4695ZM54.9854 37.7666H52.1493V19.4695H54.9854V37.7666Z"
        fill="#004385"
      />
    </g>
    <defs>
      <clipPath id="clip0_331_871">
        <rect width="60.72" height="55.2324" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FclsIcon',
};
</script>

<style>
</style>
