export default class Appointment {
  constructor(
    id,
    index,
    // userRegistry,
    // registryDate,
    dueDate,
    description,
    typeOfMovimentation,
    originalValue,
    irAliquot,
    pccAliquot,
    discountValue,
    fineValue,
    additionsValue,
    totalValue,
    receipts,
    moviment,
    account,
    importProcess,
    exportProcess,
  ) {
    this.id = id;
    this.index = index;
    // this.userRegistry = userRegistry;
    // this.registryDate = registryDate;
    this.dueDate = dueDate;
    this.description = description;
    this.typeOfMovimentation = typeOfMovimentation;
    this.originalValue = originalValue;
    this.irAliquot = irAliquot;
    this.pccAliquot = pccAliquot;
    this.discountValue = discountValue;
    this.fineValue = fineValue;
    this.additionsValue = additionsValue;
    this.totalValue = totalValue;
    this.receipts = receipts;
    this.moviment = moviment;
    this.account = account;
    this.importProcess = importProcess;
    this.exportProcess = exportProcess;
  }
}
