<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2852 15.1474L9.05126 9.91345C8.63459 10.2575 8.15542 10.5267 7.61376 10.7211C7.07209 10.9156 6.51173 11.0128 5.93267 11.0128C4.50837 11.0128 3.30294 10.5197 2.31638 9.53335C1.32983 8.54704 0.836548 7.34192 0.836548 5.91799C0.836548 4.49405 1.3297 3.28849 2.31601 2.30131C3.30231 1.31414 4.50744 0.820557 5.93138 0.820557C7.35531 0.820557 8.56087 1.31384 9.54805 2.30039C10.5352 3.28695 11.0288 4.49238 11.0288 5.91668C11.0288 6.51177 10.9289 7.08014 10.7291 7.62181C10.5293 8.16347 10.2628 8.63463 9.92944 9.03527L15.1634 14.2692L14.2852 15.1474ZM5.93267 9.76285C7.0064 9.76285 7.91585 9.39025 8.66105 8.64506C9.40624 7.89986 9.77884 6.9904 9.77884 5.91668C9.77884 4.84296 9.40624 3.9335 8.66105 3.18831C7.91585 2.44311 7.0064 2.07051 5.93267 2.07051C4.85895 2.07051 3.94949 2.44311 3.2043 3.18831C2.45912 3.9335 2.08653 4.84296 2.08653 5.91668C2.08653 6.9904 2.45912 7.89986 3.2043 8.64506C3.94949 9.39025 4.85895 9.76285 5.93267 9.76285Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
