import Axios from 'axios';
import * as importDeclarationLists from '@/lists/import-declaration-lists.js';
import * as importDeclarationStructures from '@/lists/import-declaration-structures.js';

import {
  EventBus,
} from '@/events/EventBus.js';

import {
  mapState,
} from 'vuex';
import * as constants from '../../consts.js';

export default {
  getCustomer(id) {
    return Axios.get(`${config.env.API_DEFAULT.host}/processes/${id}/customer`);
  },

  sincronizarDI(store, di) {
    let jsonDataCustomer = null;
    return Axios.get(di.processoImportacao._links.self.href).then((response) => {
      if (response.data.jsonDataCustomer !== null) {
        jsonDataCustomer = JSON.parse(response.data.jsonDataCustomer);

        // di.codigoMoedaSeguro = jsonDataCustomer.DI.INSWR; // USD" Código da moeda do seguro
        di.valorTotalSeguroMoedaNegociada = jsonDataCustomer.DI.INSUR; //  2.58 Valor do seguro internacional
        // di.codigoMoedaSeguro = jsonDataCustomer.DI.TPINTERMD; // 1 Forma de importação
        // di.codigoUrfCargaEntrada = jsonDataCustomer.DI.URFENTR; // 0817600
        di.codigoTipoDeclaracao = jsonDataCustomer.DI.IMPTYP; // 01 Tipo de importação
        di.codigoModalidadeDespacho = jsonDataCustomer.DI.MODDESP; // 1 Modalidade de despacho
        // di.codigoRecintoAlfandegado = jsonDataCustomer.DI.RECALF; //0817600 Recinto Alfandegado
        // di.codigoMoedaSeguro = jsonDataCustomer.DI.PERM_DAYS //0 Dias de Permanência
        // di.codigoUrfDespacho = jsonDataCustomer.MAIN.URFDESP //0920200 URF de Despacho (SISCOMEX)

        return di;
      }
      return false;
    }).then((response) => // Carrega Pais
      store.dispatch('findObjectByKey', {
        array: importDeclarationLists.codigosPaises,
        key: 'key',
        value: '013',
      }).then((resp) => {
        if (resp) di.codigoPaisProcedenciaCarga = resp;
        else return false;
      })).then((response) => // Carrega URF Etrada

      store.dispatch('MyIMPStore/consultarCodigoURF', jsonDataCustomer.DI.URFENTR).then((resp) => {
        if (resp && resp !== null && resp[0] !== null && resp[0] !== undefined) {
          di.codigoUrfCargaEntrada = resp[0];
        } else return false;
      }))
      .then((response) => // Carrega URF Despacho
        store.dispatch('MyIMPStore/consultarCodigoURF', jsonDataCustomer.DI.URFDESP).then((resp) => {
          if (resp && resp !== null && resp[0] !== null && resp[0] !== undefined) {
            di.codigoUrfDespacho = resp[0];
          } else return false;
        }))
      .then((response) => // Carrega Recinto Aduaneiro
        store.dispatch('MyIMPStore/consultarCampoDenominacaoAction', {
          formRetorno: 'ElaborarDIForm',
          campoRetorno: 'recAduaneiro',
          subAction: 'autoCompleteCodigo',
          parametro: di.codigoUrfDespacho.codigo,
          tipoDI: null,
          q: jsonDataCustomer.DI.RECALF,
        }).then((resp) => {
          if (resp && resp !== null && resp[0] !== null && resp[0] !== undefined) {
            di.codigoRecintoAlfandegado = resp[0];
          } else return false;
        }))
      .then((response) => // Codigo Moeda
        Axios.get(`${config.env.API_DEFAULT.host}/coins`).then((resp) => {
          if (resp) {
            return store.dispatch('findObjectByKey', {
              array: resp.data._embedded.coins,
              key: 'swiftCode',
              value: jsonDataCustomer.DI.INSWR,
            }).then((resp) => {
              di.codigoMoedaSeguro = resp;

              return di;
            });
          } return false;
        }))
      .then((response) => // Codigo Moeda
        this.criarAdicao(di, jsonDataCustomer, store))
      .then((response) => // Codigo Moeda
        response);
  },

  criarAdicao(di, jsonDataCustomer, store) {
    const itens = jsonDataCustomer.SHP;

    return new Promise((resolve, reject) => {
      if (itens !== null && itens !== undefined && itens.length > 0) {
        di.adicoes = [];
        const ncmsEncontrados = [];

        itens.forEach((item) => {
          // Checa se item já foi incluído em outra adição
          if (!ncmsEncontrados.includes(item.J_1BNBM)) {
            let newAdicao = {
              ...importDeclarationStructures.newAdicao,
            };

            newAdicao.mercadoria = [];

            newAdicao.numeroAdicao = (di.adicoes.length + 1).toString();
            if (newAdicao.numeroAdicao.length == 1) {
              newAdicao.numeroAdicao = `00${newAdicao.numeroAdicao.toString()}`;
            } else if (newAdicao.numeroAdicao.length == 2) {
              newAdicao.numeroAdicao = `0${newAdicao.numeroAdicao.toString()}`;
            }

            newAdicao.codigoMercadoriaNCM = item.J_1BNBM;
            newAdicao.pesoLiquidoMercadoria = item.NTGCV; // NTGCV Peso líquido total (campo NTGEW x campo MENGEE)
            newAdicao.quantidadeUnidadeEstatistica = item.MENGEE; // MENGEE Quantidade embarcada

            // Analisa se a lista de itens tem itens com o mesmo NCM
            let numeroItem = 1;
            for (let i = 0; i < itens.length; i++) {
              if (itens[i].J_1BNBM === item.J_1BNBM
                                && itens[i].SBELP !== item.SBELP) {
                const itemAtual = itens[i];
                ncmsEncontrados.push(itemAtual.J_1BNBM);

                newAdicao.pesoLiquidoMercadoria += itemAtual.NTGCV;
                newAdicao.quantidadeUnidadeEstatistica += itemAtual.MENGEE;

                if ((numeroItem).toString().length == 1) numeroItem = `00${(numeroItem).toString()}`;
                else if ((numeroItem).toString().length == 2) numeroItem = `0${(numeroItem).toString()}`;

                let mercadoria = {
                  numeroItem,
                  numeroItemIntegracao: itemAtual.SBELP,
                  nomeUnidadeMedidaComercializada: itemAtual.MEINS,
                  quantidadeMercadoriaUnidadeComercializada: itemAtual.MENGEE,
                  textoDetalhamentoMercadoria: `${itemAtual.MAKTX}; COD. ${itemAtual.MATNR}`,
                  valorUnidadeLocalEmbarque: itemAtual.NETPR,
                  valorUnidadeMedidaCondicaoVenda: itemAtual.NETPR,
                  moeda: '',
                };
                newAdicao.mercadoria.push(mercadoria);
                mercadoria = {};
                numeroItem = parseInt(numeroItem) + 1;
              }
            }

            if ((numeroItem).toString().length == 1) numeroItem = `00${(numeroItem).toString()}`;
            else if ((numeroItem).toString().length == 2) numeroItem = `0${(numeroItem).toString()}`;

            let mercadoria = {
              numeroItem,
              numeroItemIntegracao: item.SBELP,
              nomeUnidadeMedidaComercializada: item.MEINS,
              quantidadeMercadoriaUnidadeComercializada: item.MENGEE,
              textoDetalhamentoMercadoria: `${item.MAKTX}; COD. ${item.MATNR}`,
              valorUnidadeLocalEmbarque: item.NETPR,
              valorUnidadeMedidaCondicaoVenda: item.NETPR,
              moeda: '',
            };

            if (di.exportador !== null
                            && di.fabricante !== null
                            && di.exportador.name === di.fabricante.name
                            && di.exportador.city === di.fabricante.city
                            && di.exportador.country === di.fabricante.country) {
              newAdicao.codigoAusenciaFabricante = 1;
            } else if (
              di.exportador !== null
                            && di.fabricante !== null
                            && di.exportador.name !== di.fabricante.name
                            && di.exportador.city !== di.fabricante.city
                            && di.exportador.country !== di.fabricante.country) {
              newAdicao.codigoAusenciaFabricante = 2;
            } else {
              newAdicao.codigoAusenciaFabricante = 1;
            }

            newAdicao.nomeFornecedorEstrangeiro = di.exportador.name;
            newAdicao.enderecoLogradouroFornecedorEstrangeiro = di.exportador.address;
            newAdicao.enderecoNumeroFornecedorEstrangeiro = di.exportador.addressNumber;
            newAdicao.enderecoComplementoFornecedorEstrangeiro = di.exportador.complement;
            newAdicao.enderecoCidadeFornecedorEstrangeiro = di.exportador.city;
            newAdicao.enderecoEstadoFornecedorEstrangeiro = di.exportador.federalUnity;
            newAdicao.codigoPaisAquisicaoMercadoria = null;

            newAdicao.codigoIncotermsVenda = {
              key: jsonDataCustomer.MAIN.INCO1,
              value: jsonDataCustomer.MAIN.INCO1,
            };

            newAdicao.codigoMetodoValoracao = {
              key: '01',
              value: '01 - METODO 1',
              description: '01 - METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)',
            };

            newAdicao.nomeLocalCondicaoVenda = di.processoImportacao.placeOfLoading;

            newAdicao.mercadoria.push(mercadoria);
            mercadoria = {};

            di.adicoes.push(newAdicao);
            newAdicao = {};
          }
        });

        resolve();
      } else {
        resolve();
      }
    }).then((response) => store.dispatch('MyIMPStore/patch', {
      diff: di,
      newModel: di,
      fieldName: null,
    })).then((response) => di);
  },
};
