<template>
  <div class="card-grid" :style="`grid-template-columns: repeat(${columns}, 1fr);`">
    <div :class="['card-grid-head', headColor, !headColor && 'bordered']" :style="`grid-column: span ${columns}`">
      <span class="material-icons-outlined" v-if="icon">
        {{ icon }}
      </span>
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardGrid',
  props: ['title', 'icon', 'headColor', 'columns'],
};
</script>

<style scoped>
  .card-grid {
    display: grid;
    grid-gap: 5px;
    margin-bottom: 5px;
  }

  /* .card-grid-large {
    width: 253px;
  } */

  .card-grid-head {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .card-grid-head .material-icons-outlined {
    font-size: 30px;
  }

  .card-grid-head {
    border: 1px solid var(--border);
    color: var(--gray-dark);
  }

  .card-grid-head.blue {
    background-color: var(--blue);
    color: white;
    border: 0;
  }

  .card-grid-head.dark {
    background-color: var(--gray-dark);
    color: white;
    border: 0;
  }

  .card-grid-head.light {
    background-color: var(--border);
    color: var(--gray-dark);
    border: 0;
  }
</style>
