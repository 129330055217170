// export const HOSTAPI = "http://gescomex.com:9090";
export const HOSTAPI = 'http://api.prm.gescomex.com.br:9090';

export const APIUSERNAME = 'gescomexclient';
export const APIPASSWORD = 'gescomexclient';

export const CURRENT_TENANT_ID = 'Prime Internacional Comércio Exterior';
export const CURRENT_TENANT_NAME = 'Prime Internacional Comércio Exterior';

export const HUBDODESENVOLVEDOR_HOST = 'http://ws.hubdodesenvolvedor.com.br/v2/';
export const HUBDODESENVOLVEDOR_HOST_TOKEN = '&token=16567700tVguhdkECR29912480';

export const CADASTROS_UF = [{
  sigla: 'AC',
  nome: 'Acre',
},
{
  sigla: 'AL',
  nome: 'Alagoas',
},
{
  sigla: 'AM',
  nome: 'Amazonas',
},
{
  sigla: 'AP',
  nome: 'Amapá',
},
{
  sigla: 'BA',
  nome: 'Bahia',
},
{
  sigla: 'CE',
  nome: 'Ceará',
},
{
  sigla: 'DF',
  nome: 'Distrito Federal',
},
{
  sigla: 'ES',
  nome: 'Espírito Santo',
},
{
  sigla: 'GO',
  nome: 'Goiás',
},
{
  sigla: 'MA',
  nome: 'Maranhão',
},
{
  sigla: 'MG',
  nome: 'Minas Gerais',
},
{
  sigla: 'MS',
  nome: 'Mato Grosso do Sul',
},
{
  sigla: 'MT',
  nome: 'Mato Grosso',
},
{
  sigla: 'PA',
  nome: 'Pará',
},
{
  sigla: 'PB',
  nome: 'Paraíba',
},
{
  sigla: 'PE',
  nome: 'Pernambuco',
},
{
  sigla: 'PI',
  nome: 'Piauí',
},
{
  sigla: 'PR',
  nome: 'Paraná',
},
{
  sigla: 'RJ',
  nome: 'Rio de Janeiro',
},
{
  sigla: 'RN',
  nome: 'Rio Grande do Norte',
},
{
  sigla: 'RO',
  nome: 'Rondônia',
},
{
  sigla: 'RR',
  nome: 'Roraima',
},
{
  sigla: 'RS',
  nome: 'Rio Grande do Sul',
},
{
  sigla: 'SC',
  nome: 'Santa Catarina',
},
{
  sigla: 'SE',
  nome: 'Sergipe',
},
{
  sigla: 'SP',
  nome: 'São Paulo',
},
{
  sigla: 'TO',
  nome: 'Tocantins',
},
];

export const CADASTROS_SETORES = [{
  key: 'GERAL',
  value: 'Geral',
},
{
  key: 'ADMINISTRATIVO',
  value: 'Administrativo',
},
{
  key: 'EXPORTACAO',
  value: 'Exportação',
},
{
  key: 'IMPORTACAO',
  value: 'Importação',
},
{
  key: 'VENDAS',
  value: 'Vendas',
},
{
  key: 'COMPRAS',
  value: 'Compras',
},
{
  key: 'FATURAMENTO',
  value: 'Faturamento',
},
{
  key: 'FINANCEIRO',
  value: 'Financeiro',
},
{
  key: 'FISCAL',
  value: 'Fiscal',
},
{
  key: 'JURIDICO',
  value: 'Jurídico',
},
{
  key: 'LOGISTICA',
  value: 'Logística',
},
{
  key: 'OPERACIONAL',
  value: 'Operacional',
},
{
  key: 'PRODUCAO',
  value: 'Produção',
},
];

export const CADASTROS_DIAS_DA_SEMANA = [{
  key: 'MONDAY',
  value: 'Segunda Feira',
},
{
  key: 'TUESDAY',
  value: 'Terça Feira',
},
{
  key: 'WEDNESDAY',
  value: 'Quarta Feira',
},
{
  key: 'THURSDAY',
  value: 'Quinta Feira',
},
{
  key: 'FRIDAY',
  value: 'Sexta Feira',
},
{
  key: 'SATURDAY',
  value: 'Sábado',
},
{
  key: 'SUNDAY',
  value: 'Domingo',
},
];

export const PERIODOS_TEMPO = [{
  key: 'SEMANAL',
  value: 'Semanal',
}, {
  key: 'QUINZENAL',
  value: 'Quinzenal',
},
{
  key: 'MENSAL',
  value: 'Mensal',
},
{
  key: 'BIMESTRAL',
  value: 'Bimestral',
},
{
  key: 'SEMESTRAL',
  value: 'Semestral',
},
];

export const CHART_OF_ACCOUNTS_PROJECT_STATUS = [{
  key: 'PLANNING',
  value: 'Planejamento',
},
{
  key: 'BUDGET',
  value: 'Orçamento',
},
{
  key: 'PROGRESS',
  value: 'Progresso',
},
{
  key: 'CANCELED',
  value: 'Cancelado',
},
{
  key: 'DONE',
  value: 'Finalizado',
},
];

export const MOVIMENT_OF_PROCESS_CLASSIFICATIONS = [{
  key: 'ADIANTAMENTO',
  value: 'Adiantamento',
},
{
  key: 'REEMBOLSO',
  value: 'Reembolso',
},
{
  key: 'DUPLICATA',
  value: 'Duplicata',
},
{
  key: 'RATEIO',
  value: 'Rateio',
},
{
  key: 'NENHUMA',
  value: 'Nenhuma',
},
];

export const MOVIMENT_TERMS_CATEGORY = [{
  key: 'FRETE',
  value: 'Frete',
},
{
  key: 'ARMAZENAGEM',
  value: 'Armazenagem',
},
];

export const OPERATIONAL_INCOTERMS = [{
  key: 'EXW',
  value: 'EXW',
},
{
  key: 'FCA',
  value: 'FCA',
},
{
  key: 'FOB',
  value: 'FOB',
},
{
  key: 'FAS',
  value: 'FAS',
},
{
  key: 'CFR',
  value: 'CFR',
},
{
  key: 'CIF',
  value: 'CIF',
},
{
  key: 'CPT',
  value: 'CPT',
},
{
  key: 'CIP',
  value: 'CIP',
},
{
  key: 'DAT',
  value: 'DAT',
},
{
  key: 'DAP',
  value: 'DAP',
},
{
  key: 'DDP',
  value: 'DDP',
},
];

export const OPERATIONAL_WAY_OF_TRANSPORTE = [{
  key: 'MARITIMA',
  value: '(1) Marítima',
},
{
  key: 'FLUVIAL',
  value: '(2) Fluvial',
},
{
  key: 'LACUSTRE',
  value: '(3) Lacustre',
},
{
  key: 'AEREA',
  value: '(4) Áerea',
},
{
  key: 'POSTAL',
  value: '(5) Postal',
},
{
  key: 'FERROVIARIA',
  value: '(6) Ferroviária',
},
{
  key: 'RODOVIARIA',
  value: '(7) Rodoviária',
},
{
  key: 'TUBO_CONDUTO',
  value: '(8) Tubo Conduto',
},
{
  key: 'MEIOS_PROPRIOS',
  value: '(9) Meios Próprios',
},
{
  key: 'ENTRADA_FICTA',
  value: '(10) Entrada Ficta',
},
];

export const OPERATIONAL_TYPE_OF_BOARDING = [{
  key: 'FCL',
  value: 'FCL',
},
{
  key: 'LCL',
  value: 'LCL',
},
{
  key: 'BKB',
  value: 'BKB',
},
];

export const GENERAL_COMPANIES_PROCEDURES_TYPES = [{
  key: 'PROCESSO_IMPORTACAO',
  value: 'PI - Processo de Importação',
},
{
  key: 'PROCESSO_EXPORTACAO',
  value: 'PE - Processo de Exportação',
},
{
  key: 'FINANCEIRO',
  value: 'FIN - Financeiro',
},
{
  key: 'FATURAMENTO',
  value: 'FAT - Faturamento',
},
{
  key: 'GERAL',
  value: 'Geral',
},
{
  key: 'ENCERRAMENTO',
  value: 'Encerramento',
},
];

export const USE_OF_GOODS = [{
  key: 'ATIVO',
  value: 'Ativo',
},
{
  key: 'MATERIA_PRIMA',
  value: 'Matéria Prima',
},
{
  key: 'CONSUMO',
  value: 'Consumo',
},
{
  key: 'REVENDA',
  value: 'Revenda',
},
{
  key: 'INTERMEDIARIO',
  value: 'Intermediário',
},
];

export const LIST_TYPE_OF_PROCESS = [
  { key: 'CONTA_E_ORDEM', value: 'Conta e Ordem' },
  { key: 'ASSESSORIA', value: 'Assessoria' },
  { key: 'ENCOMENDA', value: 'Encomenda' },
];
