<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>
        <!-- card-header -->

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <receipts-form-general
                :receipt="receipt"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>
            <!-- tab-pane -->
          </div>
          <!-- tab-content -->
        </div>
        <!-- card-body -->
      </div>
      <!-- card -->
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import Receipt from "@/models/Receipt.js";
import ReceiptFormGeneral from "@/views/finances/moviments/transfers/Form.General.vue";

export default {
  name: "ReceiptsForm",
  components: {
    "receipts-form-general": ReceiptFormGeneral,
  },
  data() {
    return {
      mainTitle: "Transferência Bancária",
      receipt: new Receipt(),
      oldModel: new Receipt(),
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },

  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isNewRecord()) this.addModel();
          else this.updateModel();
        } else
          swal(
            this.mainTitle,
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
      });
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/receipts/${this.$route.params.id}`
      )
        .then((response) => {
          this.receipt = response.data;

          // ACCOUNT
          Axios.get(this.receipt._links.account.href)
            .then((response) => {
              this.$set(this.receipt, "account", response.data);
            })
            .catch((error) => {
              swal(
                this.modelTitle,
                "Não foi possível carregar as informações do Banco de Origem.",
                "error"
              );
            });

          // BANK SOURCE
          Axios.get(this.receipt._links.bankAccountSource.href)
            .then((response) => {
              this.$set(this.receipt, "bankAccountSource", response.data);
            })
            .catch((error) => {
              swal(
                this.modelTitle,
                "Não foi possível carregar as informações do Banco de Origem.",
                "error"
              );
            });

          // BANK DESTINATION
          Axios.get(this.receipt._links.bankAccountDestination.href)
            .then((response) => {
              this.$set(this.receipt, "bankAccountDestination", response.data);
            })
            .catch((error) => {
              swal(
                this.modelTitle,
                "Não foi possível carregar as informações do Banco de Destino.",
                "error"
              );
            });
        })
        .catch((error) => {
          swal(
            this.modelTitle,
            `Não foi possível carregar as informações da ${this.mainTitle}.`,
            "error"
          );
        });
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
