<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li
            class="breadcrumb-item"
            v-if="$route.params.type !== 'categories'"
          >
            <a href="#">Financeiro</a>
          </li>
          <li class="breadcrumb-item" v-else><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ modelTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">Listagem de {{ modelTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
              <p class="mg-b-0">
                Foram encontrados {{ data.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas
              </p>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'movimentTerms-form',
                  params: { action: 'create' },
                }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <v-client-table :columns="columns" :data="data" :options="options">
          <template slot="enabled" slot-scope="props">
            <div class="enabled">
              <span v-if="props.row.enabled" class="badge badge-success">
                SIM
              </span>
              <span v-if="!props.row.enabled" class="badge badge-danger">
                NÃO
              </span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'movimentTerms-form',
                params: { action: 'update', id: props.row.id },
              }"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div><i class="icon ion-edit"></i></div>
            </router-link>

            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div><i class="icon ion-trash-a"></i></div>
            </a>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import Swal from "sweetalert2";

export default {
  name: "movimentTerms-list",
  components: {},
  data() {
    return {
      modelTitle: "",
      columns: ["enabled", "code", "description", "actions"],
      data: new Array(),
      options: {
        listColumns: {
          enabled: [
            { id: true, text: "Sim" },
            { id: false, text: "Não" },
          ],
        },
        headings: {
          code: "Código",
          description: "Descrição",
          enabled: "Habilitado",
          actions: "",
        },
        sortable: ["description", "code", "enabled"],
        filterable: ["description", "code", "enabled"],

        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "{column}",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 25,
        perPageValues: [25, 50, 100, 500],
      },
    };
  },
  methods: {
    loadList() {
      const self = this;
      Axios.get(config.env.API_DEFAULT.host + this.modelURl)
        .then((response) => {
          self.data = response.data._embedded.movimentTerms;
        })
        .catch((error) => {
          swal(
            self.modelTitle,
            " Não foi possível carregar listagem.",
            "error"
          );
        });
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    deleteModel(row) {
      const self = this;

      swal({
        title: "Você tem certeza?",
        text: `Se você excluir a ${self.modelTitle}, não será possível recuperá-la para uso posterior.`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          Axios.delete(`${config.env.API_DEFAULT.host}/movimentTerms/${row.id}`)
            .then((response) => {
              Swal.fire(
                self.modelTitle,
                `${self.modelTitle} foi removida c/ sucesso!`,
                "success"
              );
              self.loadList();
            })
            .catch((error) => {
              if (error.response.status === 409) {
                Swal.fire(
                  self.modelTitle,
                  `Não foi possível excluir a ${self.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                  "error"
                );
              } else {
                Swal.fire(self.modelTitle, error.message, "error");
              }
            });
        }
      });
    },
    changeType() {
      const { type } = this.$route.params;

      if (type !== null) {
        switch (type) {
          case "costsCenters":
            this.modelTitle = "Centro de Custos";
            this.addTitle = "Novo Centro de Custos";
            this.modelURl =
              "/movimentTerms/search/findAllByType?type=COST_CENTER";
            break;
          case "documents":
            this.modelTitle = "Tipos de Documentos";
            this.addTitle = "Novo Tipo de Documento";
            this.modelURl = "/movimentTerms/search/findAllByType?type=DOCUMENT";
            break;
          case "payments":
            this.modelTitle = "Formas de Pagamentos";
            this.addTitle = "Nova Forma de Pagamento";
            this.modelURl = "/movimentTerms/search/findAllByType?type=PAYMENT";
            break;
          case "categories":
            this.modelTitle = "Categorias de Despesas/Numerário";
            this.addTitle = "Nova Categoria";
            this.modelURl = "/movimentTerms/search/findAllByType?type=CATEGORY";
            break;
        }
      }
    },
  },

  mounted() {
    this.changeType();
    this.loadList();
  },

  watch: {
    "$route.params.type": function (id) {
      this.changeType();
      this.loadList();
    },
  },
};
</script>
<style>
#posicao {
  margin-left: auto;
  margin-right: auto;
}
</style>
