export default class AppointmentType {
  constructor(
    id,
    code,
    enabled,
    description,
    income,
    expense,
    enabledToImportProcess,
    enabledToExportProcess,
    chartOfAccount,

  ) {
    this.id = id;
    this.code = code;
    this.enabled = enabled;
    this.description = description;
    this.income = income;
    this.expense = expense;
    this.enabledToImportProcess = enabledToImportProcess;
    this.enabledToExportProcess = enabledToExportProcess;
    this.chartOfAccount = chartOfAccount;
  }
}
