<template>
  <form class="form-layout form-layout-2" id="#list-register-procedures">
    <companies-list-new-procedures-modal
      v-if="modal.show"
      @close="closeModal('close')"
      @save="save()"
      :modelUpdate="this.procedure"
      :isForm="false"
      :company="company"
      @removeListItems="removeListItems"
    ></companies-list-new-procedures-modal>

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Categoria dos Requisitos do Cliente</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Categorização dos
          requisitos dos clientes nos processos.
        </p>
      </div>

      <div>
        <!-- <a href="#" class="btn btn-secondary" @click="print"><i class="icon ion-document-text tx-22"></i> Extrato </a> -->
        <a href="#" class="btn btn-info" @click="newRegister"
        ><i class="icon ion-plus tx-22"></i> ADICIONAR
        </a>
      </div>
    </div>

    <div class="table-responsive">
      <table
        class="table display responsive nowrap dataTable no-footer dtr-inline tx-13"
      >
        <thead>
        <tr class="tx-10">
          <th class="pd-y-5 tx-center wd-250">Categoria</th>
          <th class="pd-y-5 tx-center">Descrição</th>
          <th class="pd-y-5 tx-center">Requisitos Habilitados</th>
          <th class="pd-y-5 tx-center">Data de Criação</th>
          <th class="pd-y-5 tx-center">Data de Atualização</th>
          <th class="pd-y-5 tx-center wd-150">Ações</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="procedures !== undefined && procedures.length > 0">
          <tr v-for="item in procedures" :key="'procedure-id-' + item.id">
            <td class="valign-middle tx-center tx-primary tx-bold">
                <span class="" v-if="item.type.value === 'PROCESSO_IMPORTACAO'">
                  PI - Processo de Importação
                </span>
              <span
                class=""
                v-else-if="item.type.value === 'PROCESSO_EXPORTACAO'"
              >
                  PE - Processo de Exportação
                </span>
              <span class="" v-else-if="item.type.value === 'FINANCEIRO'">
                  FIN - Financeiro
                </span>
              <span class="" v-else-if="item.type.value === 'FATURAMENTO'">
                  FAT - Faturamento
                </span>
              <span class="" v-else-if="item.type.value === 'GERAL'">
                  Geral
                </span>
              <span class="" v-else-if="item.type.value === 'ENCERRAMENTO'">
                  Encerramento
                </span>
            </td>
            <td
              class="valign-middle tx-center tx-danger tx-bold"
              v-if="item.itens.length === qtdOfDisabledProcedures(item.itens)"
            >
              {{ item.description }}
              <br/>
              <small>Todos os itens desabilitados</small>
            </td>
            <td class="valign-middle tx-center" v-else>
              {{ item.description }}
            </td>
            <td class="valign-middle tx-center">
                <span
                  v-if="
                    item.itens === undefined ||
                    qtdOfEnabledProcedures(item.itens) === 0
                  "
                  class="tx-danger tx-bold"
                >
                  Nenhum requisito
                </span>
              <span v-else-if="qtdOfEnabledProcedures(item.itens) === 1">
                  1 requisito
                </span>
              <span v-else
              >{{ qtdOfEnabledProcedures(item.itens) }} requisitos
                </span>
            </td>
            <td class="valign-middle tx-center">
              {{ item.created | moment("DD/MM/YYYY") }} às
              {{ item.created | moment("H:mm") }}
              <br/>
              <small>Por {{ item.createdBy }}</small>
            </td>
            <td class="valign-middle tx-center">
              {{ item.modified | moment("DD/MM/YYYY") }} às
              {{ item.modified | moment("H:mm") }}
              <br/>
              <small>Por {{ item.modifiedBy }}</small>
            </td>
            <td class="valign-middle tx-center">
              <a
                href="#"
                @click.prevent="remove(item)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a"></i></div>
              </a>

              <a
                href="#"
                @click.prevent="edit(item)"
                class="btn btn-warning btn-icon rounded-circle mg-l-10"
              >
                <div><i class="icon ion-edit"></i></div>
              </a>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="6" class="valign-middle tx-center pd-t-5">
              Nenhum requisito foi cadastrado
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script>
import Axios from "axios";
import {EventBus} from "@/events/EventBus.js";
import config from "@/config.js";
import Swal from "sweetalert2";
import ModalNewProcedures from "./Modal";

export default {
  name: "companies-list-new-procedures",
  props: ["company"],
  components: {
    "companies-list-new-procedures-modal": ModalNewProcedures,
  },
  watch: {
    company() {
      if (
        this.company._embedded !== undefined &&
        this.company._embedded.procedures !== null &&
        this.company._embedded.procedures !== undefined
      ) {
        this.procedures = this.company._embedded.procedures;
        this.procedures.sort((a, b) => {
          if (
            this.qtdOfDisabledProcedures(a.itens) <
            this.qtdOfDisabledProcedures(b.itens)
          )
            return -1;
          if (
            this.qtdOfDisabledProcedures(a.itens) >
            this.qtdOfDisabledProcedures(b.itens)
          )
            return 1;
          return 0;
        });
      } else {
        this.procedures = [];
      }
    },
  },
  data() {
    return {
      modal: {
        show: false,
      },
      procedure: null,
      procedures: [],
    };
  },
  computed: {},
  methods: {
    qtdOfDisabledProcedures(itens) {
      return itens.filter((i) => i.finishDate !== null).length;
    },
    qtdOfEnabledProcedures(itens) {
      return itens.filter((i) => i.finishDate === null).length;
    },
    closeModal() {
      this.modal.show = false;
      this.loadData();
    },
    showModal() {
      this.modal.show = true;
    },
    newRegister() {
      this.modal.show = true;
      this.procedure = null;
    },
    print() {
      this.$htmlToPaper("print");
    },
    changeShowAdd(showAdd) {
      this.showAdd = showAdd;
    },

    edit(value) {
      this.procedure = value;
      this.showModal();
    },
    remove(value) {
      Swal.fire({
        title: "Você tem certeza?",
        icon: "info",
        html: `Se você excluir o procedimento ${value.description} não será possível recuperá-lo para uso posterior.`,
        showCancelButton: true,
        confirmButtonText: "Sim, continuar!",
        cancelButtonText: "Não, cancelar!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then((willContinue) => {
        if (willContinue.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Procedimentos",
            description: "Processando sua requisição, aguarde...",
          });

          Axios.delete(
            `${config.env.API_DEFAULT.host}/companyProcedureses/${value.id}`,
            value
          )
            .then((r) => {
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              });
              this.$swal(
                "Requisitos",
                `O requisito ${value.description} foi removido com sucesso.`,
                "success"
              );
              const index = this.procedures.findIndex(
                (item) => item._links.self.href === value._links.self.href
              );
              this.procedures.splice(index, 1);
            })
            .catch((error) => {
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              });

              if (error.response.status === 409) {
                this.$swal(
                  value.description,
                  `Não foi possível excluir a ${value.description}, porque ela já está em uso por algum Processo de Importação.`,
                  "error"
                );
              } else {
                this.$swal(self.modelTitle, error.message, "error");
              }
            });
        }
      });
    },

    loadData() {
      if (this.company._links !== undefined) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Procedimentos",
          description: "Carregando os procedimentos da empresa.",
        });
        Axios.get(this.company._links.self.href)
          .then((response) => {
            this.procedures = response.data._embedded.procedures;
            this.$set(this.procedures, response.data._embedded.procedures);
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          })
          .catch((e) => {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.$swal("Ops", e, "error");
          });
      }
    },

    removeListItems(param) {
      const index = this.procedures.findIndex((item) => item.id === param.id);

      this.procedures[index].itens = param.itens;
    },
  },
  created() {
    const self = this;

    EventBus.$on("general.companies.Form.NewProcedures.load", () => {
      self.loadData();
    });
  },
};
</script>

<style scoped>
@import "./styles.css";
</style>
