import { render, staticRenderFns } from "./PartnerIntegrations.vue?vue&type=template&id=2e16c5e3&scoped=true"
import script from "./PartnerIntegrations.vue?vue&type=script&lang=js"
export * from "./PartnerIntegrations.vue?vue&type=script&lang=js"
import style0 from "./PartnerIntegrations.vue?vue&type=style&index=0&id=2e16c5e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e16c5e3",
  null
  
)

export default component.exports