<template>
  <div class="card-body pd-40 sp-3 bg-white" ref="configbody">
    <div class="config-title">
      <h2 class="text-dark text-thin">
        Listagem de Banco de Usuários
      </h2>

      <div>
        <button @click.prevent="modalOpen = true" class="crm-button">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor"/>
            Adicionar
          </div>
        </button>
      </div>
    </div>

    <hr class="mb-4"/>

    <config-table :table-config="tableConfig" :tableData="users" :columns="tableColumns">
      <!-- Columns Username -->
      <template #username="{ item }">
        <div class="flex ai-c fgap1">
          <span>{{ item.username }}</span>
          <a href="#" @click.prevent="copyToClipboard(item.username)">
            <config-copy-icon :size="20"/>
          </a>
        </div>
      </template>

      <!-- Columns Password -->
      <template #password="{ item }">
        <div class="flex ai-c fgap1">
          <span>{{ item.password }}</span>
          <a href="#" @click.prevent="copyToClipboard(item.password)">
            <config-copy-icon :size="20"/>
          </a>
        </div>
      </template>

      <!-- Columns Password -->
      <template #url="{ item }">
        <div class="flex ai-c fgap1">
          <span>{{ item.url }}</span>
          <a :href="item.url" target="_blank" rel="nofollow noopener noreferrer">
            <config-link-icon :size="20"/>
          </a>
          <a href="#" @click.prevent="copyToClipboard(item.url)">
            <config-copy-icon :size="20"/>
          </a>
        </div>
      </template>

      <!-- Column Actions -->
      <template #actions="{ item }">
        <div class="actions-table">
          <a class="edit-button" href="#" @click.prevent="editUser(item.id)">
            <config-edit-icon :size="16"/>
          </a>
          <a class="remove-button" href="#" @click.prevent="destroyUser(item.id)">
            <config-trash-icon :size="16"/>
          </a>
        </div>
      </template>
    </config-table>

    <v-modal
        :width="1600"
        :handler="modalOpen"
        :title="`${modalData.id ? 'Atualizar Usuário' : 'Adicionar Usuário'}`"
        description=""
        @requestClose="closeModal"
    >
      <template #content>
        <div class="config-user-form">
          <div class="span-12">
            <h4 class="text-dark">Dados Gerais do Usuário</h4>
          </div>

          <div class="span-6">
            <v-input
                type="text"
                name="description"
                label="Descrição"
                v-model="modalData.description"
            ></v-input>
            <div class="field-error" v-if="getError('description')">
              {{getError('description')}}
            </div>
          </div>

          <div class="span-6">
            <v-input
                type="text"
                name="url"
                label="URL"
                v-model="modalData.url"
            ></v-input>
            <div class="field-error" v-if="getError('url')">
              {{getError('url')}}
            </div>
          </div>

          <div class="span-6">
            <v-input
                type="text"
                name="username"
                label="Usuário"
                v-model="modalData.username"
            ></v-input>
            <div class="field-error" v-if="getError('username')">
              {{getError('username')}}
            </div>
          </div>

          <div class="span-6">
            <v-input
                type="text"
                name="password"
                label="Senha"
                v-model="modalData.password"
            ></v-input>
            <div class="field-error" v-if="getError('password')">
              {{getError('password')}}
            </div>
          </div>

          <div class="span-12">
            <v-textarea label="Observações" name="observation" v-model="modalData.observation"/>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="closeModal">Cancelar</button>
          <button class="crm-button" v-if="modalData.id" @click.prevent="updateUser">Salvar</button>
          <button class="crm-button" v-else @click.prevent="storeUser">Adicionar</button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import PlusIcon from '@/components/crm/Icons/Plus.vue';
import ConfigTable from '@/components/Configuration/ConfigTable.vue';
import CredentialService from '@/services/CredentialService';
import Axios from 'axios';
import config from '@/config';
import Swal from 'sweetalert2';
import ConfigEditIcon from '@/components/Configuration/icons/ConfigEditIcon.vue';
import ConfigTrashIcon from '@/components/Configuration/icons/ConfigTrashIson.vue';
import VModal from '@/components/VModal.vue';
import VInput from '@/components/VInput.vue';
import VTextarea from '@/components/VTextarea.vue';
import ConfigCopyIcon from '@/components/Configuration/icons/ConfigCopyIcon.vue';
import ConfigLinkIcon from '@/components/Configuration/icons/ConfigLinkIcon.vue';
import * as yup from 'yup';

const databaseUserSchema = yup.object().shape({
  username: yup.string().required('O usuário é obrigatório').typeError('Campo inválido'),
  password: yup.string().required('A senha é obrigatória').typeError('Campo inválido'),
  url: yup.string().required('O URL é obrigatório').typeError('Campo inválido'),
  description: yup.string().required('A descrição é obrigatória').typeError('Campo inválido'),
});

export default {
  name: 'ConfigDatabaseUsersTab',
  components: {
    ConfigLinkIcon,
    ConfigCopyIcon,
    VTextarea,
    VInput,
    VModal,
    ConfigTrashIcon,
    ConfigEditIcon,
    ConfigTable,
    PlusIcon,
  },
  data() {
    return {
      users: [],
      modalOpen: false,
      modalData: {
        description: '',
        username: '',
        password: '',
        url: '',
        observation: '',
      },
      formErrors: [],
      tableConfig: {
        perPage: 10,
        useSearch: true,
        centeredCell: false,
        showRecords: true,
        showPerPage: true,
      },
      tableColumns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Descrição',
          field: 'description',
        },
        {
          label: 'Nome',
          field: 'username',
        },
        {
          label: 'Senha',
          field: 'password',
        },
        {
          label: 'URL',
          field: 'url',
        },
        {
          label: 'Ações',
          field: 'actions',
        },
      ],
    };
  },
  methods: {
    getError(path) {
      const err = this.formErrors.find((er) => er.path === path);
      if (err) {
        return err.message;
      }
      return '';
    },
    async getUsers() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando listagem de Banco de Usuários.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/userbanks`);
        this.users = response.data._embedded.userbanks;
      } catch (e) {
        Swal.fire({
          title: 'Banco de Usuários',
          text: 'Erro ao recuperar banco de usuários!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async storeUser() {
      this.formErrors = [];
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Cadastrando usuário.',
      });

      try {
        await databaseUserSchema.validate(this.modalData, {
          abortEarly: false,
        });
        await Axios.post(`${config.env.API_DEFAULT.host}/userbanks`, this.modalData);

        Swal.fire({
          title: 'Banco de Usuários',
          text: 'Usuário cadastrado com sucesso!',
          type: 'success',
        });

        await this.closeModal(true);
      } catch (e) {
        if (e.name === 'ValidationError') {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Banco de Usuários',
            text: 'Erro ao cadastrar usuário!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async editUser(id) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando usuário.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/userbanks/${id}`);
        this.modalData = response.data;
        this.modalOpen = true;
      } catch (e) {
        Swal.fire({
          title: 'Banco de Usuários',
          text: 'Erro ao recuperar usuário!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async updateUser() {
      this.formErrors = [];
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Atualizando usuário.',
      });

      try {
        await databaseUserSchema.validate(this.modalData, {
          abortEarly: false,
        });

        await Axios.patch(`${config.env.API_DEFAULT.host}/userbanks/${this.modalData.id}`, this.modalData);

        Swal.fire({
          title: 'Banco de Usuários',
          text: 'Usuário atualizado com sucesso!',
          type: 'success',
        });

        await this.closeModal(true);
      } catch (e) {
        if (e.name === 'ValidationError') {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Banco de Usuários',
            text: 'Erro ao atualizar usuário!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async destroyUser(id) {
      try {
        const confirm = await this.$swal({
          title: 'Você tem certeza?',
          text: 'Se você excluir o usuário, não será possível recuperá-lo para uso posterior.',
          icon: 'warning',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        });

        if (confirm) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Removendo usuários.',
          });

          await Axios.delete(`${config.env.API_DEFAULT.host}/userbanks/${id}`);

          Swal.fire({
            title: 'Banco de Usuários',
            text: 'Usuário removido com sucesso!',
            type: 'success',
          });

          await this.getUsers();
        }
      } catch (e) {
        Swal.fire({
          title: 'Banco de Usuários',
          text: 'Erro ao remover usuário!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async closeModal(refresh = false) {
      this.formErrors = [];
      this.modalData = {
        description: '',
        username: '',
        password: '',
        url: '',
        observation: '',
      };

      this.modalOpen = false;

      if (refresh) {
        await this.getUsers();
      }
    },
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    copyToClipboard(value) {
      const storage = document.createElement('textarea');
      storage.value = value;
      this.$refs.configbody.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.configbody.removeChild(storage);

      this.$toasted.success('Copiado!', {
        duration: 600,
        position: 'top-center',
      });
    },
  },
  async mounted() {
    await this.getUsers();
  },
};
</script>

<style scoped>
.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.config-user-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  align-content: start;
}

.config-user-form .span-4 {
  grid-column: span 4;
}

.config-user-form .span-8 {
  grid-column: span 8;
}

.config-user-form .span-12 {
  grid-column: span 12;
}
</style>
