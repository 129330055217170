<template>
  <nav class="crm-sidebar">
    <router-link
      :to="{ name: 'crm-home' }"
      class="crm-sidebar-link"
      v-if="this.hasPrivilege('CRM_CONTATOS')"
    >
      <contacts-icon :size="32" />
    </router-link>
    <router-link
      :to="{ name: 'crm-clientes' }"
      class="crm-sidebar-link"
      v-if="this.hasPrivilege('CRM_EMPRESAS')"
    >
      <companies-icon :size="60" />
    </router-link>
    <router-link
      :to="{ name: 'crm-relatorios' }"
      class="crm-sidebar-link"
      v-if="this.hasPrivilege('CRM_RELATORIOS')"
    >
      <excel-icon :size="30" />
    </router-link>
    <router-link
      :to="{ name: 'crm-nps' }"
      class="crm-sidebar-link"
      v-if="this.hasPrivilege('CRM_EMPRESAS_NPS_CRUD')"
    >
      <nps-icon :size="30" />
    </router-link>
    <!-- <router-link to="#3" class="crm-sidebar-link">
      <report-icon :size="32" />
    </router-link> -->
  </nav>
</template>

<script>
import {
  ContactsIcon,
  CompaniesIcon,
  ExcelIcon,
  NpsIcon,
} from "@/components/crm/Icons";
import { mapActions } from "vuex";

import CredentialService from "@/services/CredentialService.js";

export default {
  name: "CRMSidebar",
  components: {
    ContactsIcon,
    CompaniesIcon,
    ExcelIcon,
    NpsIcon,
  },
  methods: {
    ...mapActions(["checkPrivilege"]),
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.checkPrivilege({ privilege: "CRM", router: this.$router });
  },
};
</script>

<style scoped>
.crm-sidebar {
  height: calc(100vh - 152px);
  overflow: hidden;
  flex: 0 0 60px;
  width: 60px;
  border-right: 1px solid #dee2e6;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.crm-sidebar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.crm-sidebar-link:hover,
.crm-sidebar-link.router-link-active {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
</style>
