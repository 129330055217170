<template>
  <div :class="[
    'v-select-wrapper',
    active ? 'active' : ''
  ]" @mouseenter="emitter = true">
    <label>
      {{ label }} <span v-if="requiredFlag" class="tx-danger">*</span>
    </label>

    <v-select :disabled="disabled" :options="options" :clearable="clearable" :multiple="multiple" v-model="inputValue"
      :close-on-select="!multiple" :index="index" @search="(search) => $emit('search', search)"
      v-on:search:focus="() => whileSearch()" v-on:search:blur="() => closeSearch()"
      @change="(value) => $emit('change', value)"></v-select>
  </div>
</template>

<script>
export default {
  name: 'SelectWrapper',
  data() {
    return {
      active: false,
      emitter: false,
      searching: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
      default: 'label',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {},
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    index: {
      type: String,
      default: 'value',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    requiredFlag: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    whileSearch() {
      this.searching = true;
      this.active = true;
    },
    closeSearch() {
      this.searching = false;
      this.checkInput(this.value);
    },
    checkInput(value) {
      if (this.searching) {
        this.active = true;
      } else {
        this.active = !!value;
      }
    },
    onChange(e) {
      this.$emit('input', e);
      if (this.emitter) {
        this.$emit('blur', e);
      }
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === 'false') {
          this.$emit('input', false);
          if (this.emitter) {
            this.$emit('blur', false);
          }
        }
        this.$emit('input', value);
        this.$emit('change', true);
        if (this.emitter) {
          this.$emit('blur', value);
        }
      },
    },
  },
  updated() {
    if (!this.searching) {
      this.checkInput(this.value);
    }
  },
  mounted() {
    if (!this.searching) {
      this.checkInput(this.value);
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (value) {
          this.active = true;
        }
      },
    },
  },
};
</script>

<style>
.v-select-wrapper {
  position: relative;
  background-color: white;
}

body .v-select-wrapper .v-select {
  width: 100%;
  min-height: 45px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 10px 10px 10px 20px;
}

.v-select-wrapper .v-select.single .selected-tag {
  max-width: unset !important;
}

.v-select-wrapper .dropdown-toggle .clear {
  left: 5px;
}

.v-select-wrapper .v-select input[type=search],
.v-select input[type=search]:focus {
  height: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}

.v-select-wrapper label {
  font-size: 0.9rem;
  position: absolute;
  top: 10px;
  left: 20px;
  transition: all .2s ease-in-out 0s;
  background-color: white;
  z-index: 2;
  padding: 1px 4px;
}

.v-select-wrapper.active label {
  top: -8px;
  font-size: 0.7rem;
  background-color: white;
  padding: 0 7px;
  border-radius: 6px;
  z-index: 9;
}

.v-select-wrapper .vs__selected-options {
  overflow: hidden;
}

.v-select-wrapper .v-select.single .selected-tag {
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.mx-input-wrapper .mx-clear-wrapper {
  margin-top: 0 !important;
}

.input-danger .dropdown {
  border: 1px solid var(--red) !important;
}

.input-danger .dropdown input {
  border: unset !important;
}
</style>
