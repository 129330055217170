export default class Contact {
  constructor(
    oldID,
    enabled,
    registryDate,
    name,
    federalID,
    dateOfBirth,
    gender,
    sector,
    jobRole,
    email1,
    email2,
    telephone1,
    telephone2,
    postalCode,
    address,
    addressNumber,
    complement,
    district,
    city,
    federalUnity,
    commissioned,
    followUP,
    followUPSynthetic,
    followUPAnalytical,
    followUPAnalyticalWeekDay,
    role,
    password,
    account,
    companies,
  ) {
    this.oldID = oldID;
    this.enabled = enabled;
    this.registryDate = registryDate;
    this.name = name;
    this.federalID = federalID;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.sector = sector;
    this.jobRole = jobRole;
    this.email1 = email1;
    this.email2 = email2;
    this.telephone1 = telephone1;
    this.telephone2 = telephone2;
    this.postalCode = postalCode;
    this.address = address;
    this.addressNumber = addressNumber;
    this.complement = complement;
    this.district = district;
    this.city = city;
    this.federalUnity = federalUnity;
    this.commissioned = commissioned;
    this.followUP = followUP;
    this.followUPSynthetic = followUPSynthetic;
    this.followUPAnalytical = followUPAnalytical;
    this.followUPAnalyticalWeekDay = followUPAnalyticalWeekDay;
    this.role = role;
    this.password = password;
    this.account = account;
    this.companies = companies;
  }
}
