<template>
  <div class="slim-mainpanel">
    <control-panel-headline
        v-on:quote-button="quoteModal = true"
        v-if="!modal"
    />
    <general-indicators-filter :modal="modal"/>

    <div class="container mg-t-20 pd-l-0 pd-r-0">
      <div class="filter-tabs">
        <ul class="tabs">
          <li v-if="!modal">
            <a
                href="#"
                data-tab="tab1"
                :class="activeTab === 'tab1' && 'active'"
                @click.prevent="(e) => (activeTab = e.target.dataset.tab)"
            >
              INDICADORES GERAIS
            </a>
          </li>
          <li>
            <a
                href="#"
                data-tab="tab2"
                :class="activeTab === 'tab2' && 'active'"
                @click.prevent="
                  (e) => {
                    activeTab = e.target.dataset.tab;
                    getIndicadoresOperacionais();
                  }
                "
            >
              INDICADORES OPERACIONAIS
            </a>
          </li>
        </ul>
      </div>

      <div class="tab-content">
        <div v-if="activeTab === 'tab1'">
          <div class="tab-title">
            <h3 class="mg-b-0">INDICADORES GERAIS</h3>
          </div>
          <div class="tab-wrapper">
            <div class="columns gap1">
              <div class="span-5">
                <card-grid
                    title="LEAD TIMES"
                    icon="timer"
                    headColor="blue"
                    columns="5"
                >
                  <card-grid-item
                      icon="playlist_add_check"
                      :bigInfo="totalLiberacao"
                      bigInfoSub="DIAS"
                      title="LIBERAÇÃO"
                      content="dias entre data de presença de carga X data de liberação"
                  />
                  <card-grid-item
                      icon="playlist_add_check"
                      :bigInfo="totalRegistroDI"
                      bigInfoSub="DIAS"
                      title="REGISTRO DI"
                      content="dias entre a Data de presença de carga X data de registro"
                  />
                  <card-grid-item
                      icon="corporate_fare"
                      :bigInfo="totalCarregamento"
                      bigInfoSub="DIAS"
                      title="CARREGAMENTO"
                      content="dias entre data de chegada X data de entrega"
                  />
                  <card-grid-item
                      icon="request_page"
                      :bigInfo="totalFaturamento"
                      bigInfoSub="DIAS"
                      title="FATURAMENTO"
                      content="Data das Notas Fiscais x Data de Faturamento do Cliente"
                  />
                  <card-grid-item
                      icon="attach_email"
                      :bigInfo="totalEncerramento"
                      bigInfoSub="DIAS"
                      title="ENCERRAMENTO"
                      content="dias entre data de entrega X data de encerramento"
                  />
                </card-grid>
              </div>
              <div class="span-3">
                <card-grid
                    title="GERAIS"
                    icon="outlined_flag"
                    headColor="dark"
                    columns="3"
                >
                  <card-grid-item
                      :bigInfo="indicadoresMovimentacaoProcessual.totalAbertas"
                      bigInfoSub="PROCESSOS"
                      title="ABERTURAS NO PERÍODO"
                      content="processos abertos de acordo com o filtro realizado"
                      topBarColor="light"
                      bg="light"
                  />
                  <card-grid-item
                      :bigInfo="indicadoresEtapas['total']"
                      bigInfoSub="PROCESSOS"
                      title="EM ANDAMENTO"
                      content="Processos em andamento atualmente"
                      topBarColor="green"
                      bg="light"
                  />
                  <FabModal title="INCONSISTÊNCIAS">
                    <template #activator="{ on }">
                      <card-grid-item
                          :bigInfo="indicadorInconsistencias"
                          class="cursor-pointer"
                          @click="
                            on();
                            getInconsistencies();
                          "
                          :customEventOnClick="true"
                          bigInfoSub="APONTAMENTOS"
                          title="INCONSISTÊNCIAS"
                          content="quantidade total de apontamentos de inconsistências"
                          topBarColor="red"
                          bg="red"
                      />
                    </template>

                    <template #content>
                      <div class="container">
                        <div class="table-responsive mt-5">
                          <table class="table">
                            <thead>
                            <tr class="tx-10">
                              <th class="pd-y-t tx-center">PROCESSO</th>
                              <th class="pd-y-t tx-center">DATA</th>
                              <th class="pd-y-t tx-center">ORIGEM</th>
                              <th class="pd-y-t tx-center">
                                DESCRIÇÃO / TIPO
                              </th>
                              <th class="pd-y-t tx-center">VALOR R$</th>
                              <th class="pd-y-t tx-center">PESO</th>
                              <th class="pd-y-t tx-center">ETAPA</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td
                                  class="valign-middle tx-center"
                                  colspan="4"
                                  v-if="inconsistencias.length == 0"
                              >
                                ...
                              </td>
                            </tr>
                            <tr
                                v-for="item in inconsistencias"
                                :key="item.id"
                            >
                              <td class="valign-middle tx-center">
                                {{
                                  getTypeOfProcessReferenceByProcess(
                                      item.importProcess,
                                  )
                                }}
                              </td>
                              <td class="valign-middle tx-center">
                                {{ formatDate(item.date) }}
                              </td>
                              <td class="valign-middle tx-center">
                                  <span
                                      :class="
                                      item.source === 'INTERNA'
                                        ? 'text-danger'
                                        : 'text-success'
                                    "
                                  >
                                    {{
                                      item.source === "INTERNA"
                                          ? "Interna"
                                          : "Externa"
                                    }}
                                  </span>
                                <br/>
                                <span>{{ item.createdBy }}</span>
                              </td>
                              <td class="valign-middle tx-center">
                                {{ item.type.description }}
                              </td>
                              <td class="valign-middle tx-center">
                                {{ item.value | numeralFormat("0,0.00") }}
                              </td>
                              <td class="valign-middle tx-center">
                                {{ item.weight }}
                              </td>
                              <td class="valign-middle tx-center">
                                {{ item.step.name }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </template>
                  </FabModal>
                </card-grid>
              </div>
            </div>
            <card-grid
                title="PROCESSOS POR ETAPA"
                icon="bar_chart"
                headColor="light"
                columns="7"
                style="margin: 20px 0"
            >
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inProduction']"
                  bigInfoSub="PROCESSOS"
                  title="PRODUÇÃO"
                  topBarColor="pink"
                  bigIcon="producao"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inBooking']"
                  bigInfoSub="PROCESSOS"
                  title="BOOKING"
                  topBarColor="green"
                  bigIcon="booking"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inTraffic']"
                  bigInfoSub="PROCESSOS"
                  title="TRÂNSITO"
                  topBarColor="yellow"
                  bigIcon="transito"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inClearence']"
                  bigInfoSub="PROCESSOS"
                  title="DESEMBARAÇO"
                  topBarColor="blue"
                  bigIcon="desembaraco"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inLoading']"
                  bigInfoSub="PROCESSOS"
                  title="CARREGAMENTO"
                  topBarColor="purple"
                  bigIcon="carregamento"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inClosure']"
                  bigInfoSub="PROCESSOS"
                  title="ENCERRAMENTO"
                  topBarColor="orange"
                  bigIcon="encerramento"
                  :step="true"
              />
              <card-grid-item
                  :bigInfo="indicadoresEtapas['inPendencies']"
                  bigInfoSub="PROCESSOS"
                  title="PENDÊNCIAS"
                  topBarColor="darkorange"
                  bigIcon="pendencias"
                  :step="true"
              />
            </card-grid>

            <div class="process-grid">
              <div class="span-2 process-item">
                <div class="text-left">
                  <h4
                      class="text-uppercase text-medium flex fw-w ai-c jc-sb fgap2"
                  >
                    <span>PROCESSOS ABERTOS</span>
                    <span
                    >{{ indicadoresMovimentacaoProcessual.total }}
                      <span class="lower">TOTAL</span></span
                    >
                  </h4>

                  <p class="text-lighter">
                    Aberturas no Período {{ firstDatePeriod }} até
                    {{ secondDatePeriod }}
                  </p>
                </div>
                <HorizontalInfoCard
                    :numero="indicadoresMovimentacaoProcessual.totalBKB"
                    sigla="BKBs"
                    descricao="processos"
                    :animated="true"
                    style="margin-bottom: 5px"
                    background="#013884"
                >
                  <template #icon>
                    <bkbs size="80px"/>
                  </template>
                </HorizontalInfoCard>
                <HorizontalInfoCard
                    :numero="indicadoresMovimentacaoProcessual.totalFcl"
                    sigla="FCLs"
                    descricao="processos"
                    :animated="true"
                    style="margin-bottom: 5px"
                    background="#004385"
                >
                  <template #icon>
                    <fcls size="80px"/>
                  </template>
                </HorizontalInfoCard>
                <HorizontalInfoCard
                    :numero="indicadoresMovimentacaoProcessual.totalLcl"
                    sigla="LCLs"
                    descricao="processos"
                    :animated="true"
                    style="margin-bottom: 5px"
                    background="#17335A"
                >
                  <template #icon>
                    <lcls size="80px"/>
                  </template>
                </HorizontalInfoCard>
                <HorizontalInfoCard
                    :numero="indicadoresMovimentacaoProcessual.totalAereo"
                    sigla="Aereos"
                    descricao="processos"
                    :animated="true"
                    style="margin-bottom: 5px"
                    background="#798C9F"
                >
                  <template #icon>
                    <aereos size="80px"/>
                  </template>
                </HorizontalInfoCard>
                <HorizontalInfoCard
                    :numero="indicadoresMovimentacaoProcessual.totalRodoviario"
                    sigla="Rodoviários"
                    descricao="processos"
                    :animated="true"
                    background="#818B96"
                >
                  <template #icon>
                    <rodoviarios size="80px"/>
                  </template>
                </HorizontalInfoCard>
              </div>
              <div class="span-3 process-item">
                <div class="text-left">
                  <h4
                      class="text-uppercase text-medium flex fw-w ai-c jc-sb fgap2"
                  >
                    <span>GRÁFICO DE PERÍODO DE ARMAZENAGEM</span>
                    <span>
                      {{ indicadoresPeriodosArmazenagem.total }}
                      <span class="lower">TOTAL</span>
                    </span>
                  </h4>

                  <p class="text-lighter">
                    Percentual de Armazenagem no Período de
                    {{ firstDatePeriod }} até {{ secondDatePeriod }}
                  </p>
                </div>
                <Chartist
                    chart-name="chart-one"
                    :series="[
                      {
                        value: indicadoresPeriodosArmazenagem.PRIMEIRO_PERIODO,
                        meta: 'Primeiro período',
                      },
                      {
                        value: indicadoresPeriodosArmazenagem.SEGUNDO_PERIODO,
                        meta: 'Segundo período',
                      },
                      {
                        value: indicadoresPeriodosArmazenagem.TERCEIRO_PERIODO,
                        meta: 'Terceiro período',
                      },
                      {
                        value: indicadoresPeriodosArmazenagem.QUARTO_PERIODO,
                        meta: 'Quarto período',
                      },
                      {
                        value: indicadoresPeriodosArmazenagem.OUTROS,
                        meta: 'Outros',
                      },
                    ]"
                    :colors="[
                      '#004385',
                      '#17335a',
                      '#798c9f',
                      '#818b96',
                      '#a1a1a1',
                    ]"
                    :showLegends="true"
                />
              </div>
              <div class="span-3 process-item">
                <div class="text-left">
                  <h4
                      class="text-uppercase text-medium flex fw-w ai-c jc-sb fgap2"
                  >
                    <span>REGISTROS DE DECLARAÇÕES DE IMPORTAÇÃO</span>
                    <span
                    >
                      {{ indicadoresCanais.total }}
                      <span class="lower">TOTAL</span>
                    </span>
                  </h4>

                  <p class="text-lighter">
                    Percentual de Canais no Período de {{ firstDatePeriod }} até
                    {{ secondDatePeriod }}
                  </p>
                </div>
                <Chartist
                    chart-name="chart-two"
                    :series="[
                      {
                        value: indicadoresCanais.CINZA,
                        meta: 'Canal Cinza',
                      },
                      {
                        value: indicadoresCanais.VERDE,
                        meta: 'Canal Verde',
                      },
                      {
                        value: indicadoresCanais.AMARELO,
                        meta: 'Canal Amarelo',
                      },
                      {
                        value: indicadoresCanais.VERMELHO,
                        meta: 'Canal Vermelho',
                      },
                    ]"
                    :colors="['#818b96', '#3B9B28', '#D6B200', '#bd0808']"
                    :showLegends="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'tab2'">
          <div class="tab-title">
            <h3 class="mg-b-0">INDICADORES OPERACIONAIS</h3>
          </div>

          <div class="tab-wrapper">
            <div class="columns-7 gap1">
              <div>
                <card-grid title="PRODUÇÃO" columns="1" class="card-grid-large">
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisProducao.semProntidao"
                      bigInfoSub="PROCESSOS"
                      title="SEM PRONTIDÃO"
                      content="Sem prontidão preenchida"
                      topBarColor="pink"
                      :step="7"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisProducao.prontasEm7Dias"
                      bigInfoSub="PROCESSOS"
                      title="PRONTOS EM 7 DIAS"
                      content="Ficarão prontos em 7 dias"
                      topBarColor="pink"
                      :step="7"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisProducao.atrasados"
                      bigInfoSub="PROCESSOS"
                      title="ATRASADOS"
                      content="Prontidão com vencimento para hoje ou a mais dias "
                      topBarColor="pink"
                      :step="7"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisProducao.semPlanilha"
                      bigInfoSub="PROCESSOS"
                      title="SEM PLANILHA"
                      content="Planilha não preenchida"
                      topBarColor="pink"
                      :step="7"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid title="BOOKING" columns="1" class="card-grid-large">
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisBooking.semForwarder"
                      bigInfoSub="PROCESSOS"
                      title="SEM FORWARDER"
                      content="Forwarder não preenchido"
                      topBarColor="green"
                      :step="1"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisBooking.semPlanilha"
                      bigInfoSub="PROCESSOS"
                      title="SEM PLANILHA"
                      content="Planilha não preenchida"
                      topBarColor="green"
                      :step="1"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisBooking.embarcamEm7Dias"
                      bigInfoSub="PROCESSOS"
                      title="EMBARCAM EM 7 DIAS"
                      content="Embarcaram em 7 dias"
                      topBarColor="green"
                      :step="1"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisBooking.etdVencida"
                      bigInfoSub="PROCESSOS"
                      title="ETD VENCIDA"
                      content="ETD menor que a data atual"
                      topBarColor="green"
                      :step="1"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisBooking.semSeguro"
                      bigInfoSub="PROCESSOS"
                      title="SEM SEGURO"
                      content="Seguro não preenchido"
                      topBarColor="green"
                      :step="1"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid title="TRÂNSITO" columns="1" class="card-grid-large">
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semSegurao"
                      bigInfoSub="PROCESSOS"
                      title="SEM SEGURO"
                      content="Sem seguro preenchido"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semHblAwb"
                      bigInfoSub="PROCESSOS"
                      title="SEM Nº HBL / AWB"
                      content="Embarcados há mais de 5 dias"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semInvoice"
                      bigInfoSub="PROCESSOS"
                      title="SEM INVOICE"
                      content="Sem Invoice e embarcados a mais de 5 dias"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semNumerario"
                      bigInfoSub="PROCESSOS"
                      title="SEM NUMERÁRIO"
                      content="Chegando em 10 dias"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.etaVencida"
                      bigInfoSub="PROCESSOS"
                      title="CHEGADA HOJE / ANTES"
                      content="Chegada menor ou igual a data do dia de hoje"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semPlanilha"
                      bigInfoSub="PROCESSOS"
                      title="SEM PLANILHA"
                      content="Sem planilha preenchida"
                      topBarColor="yellow"
                      :step="2"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisTransito.semOriginais"
                      bigInfoSub="PROCESSOS"
                      title="SEM ORIGINAIS"
                      content="Chega em 10 dias e não possuem documentos"
                      topBarColor="yellow"
                      :step="2"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid
                    title="DESEMBARAÇO"
                    columns="1"
                    class="card-grid-large"
                >
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisDesembaraco.semOriginais"
                      bigInfoSub="PROCESSOS"
                      title="SEM ORIGINAIS"
                      content="Chegada confirmada e sem originais preenchidos"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisDesembaraco.semTransportadora
                      "
                      bigInfoSub="PROCESSOS"
                      title="S/ TRANSPORTADORA"
                      content="DI registrada e sem transportadora preenchida"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisDesembaraco.semNumerarioEnviado
                      "
                      bigInfoSub="PROCESSOS"
                      title="SEM NUMERÁRIO"
                      content="Chegou e não tem numerário preenchido"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisDesembaraco.semRegistroDi"
                      bigInfoSub="PROCESSOS"
                      title="SEM DI REGISTRADA"
                      content="Presença a mais de 2 dias e sem registro de DI"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisDesembaraco.numerarioRecebido
                      "
                      bigInfoSub="PROCESSOS"
                      title="NUMERÁRIO PAGO"
                      content="Com numerário pago e sem registro de DI"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisDesembaraco.numerarioPendente
                      "
                      bigInfoSub="PROCESSOS"
                      title="NUMERÁRIO PENDENTE"
                      content="Chegou e não tem numerário pago"
                      topBarColor="blue"
                      :step="3"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisDesembaraco.semTFA"
                      bigInfoSub="PROCESSOS"
                      title="SEM TFA"
                      content="Processos que não possuem TFA"
                      topBarColor="blue"
                      :step="3"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid
                    title="CARREGAMENTO"
                    columns="1"
                    class="card-grid-large"
                >
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisCarregamento.semOriginais"
                      bigInfoSub="PROCESSOS"
                      title="SEM ORIGINAIS"
                      content="Chegada confirmada e sem originais preenchidos"
                      topBarColor="purple"
                      :step="4"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisCarregamento.semTransportadora
                      "
                      bigInfoSub="PROCESSOS"
                      title="SEM TRANSPORTADORA"
                      content="DI registrada sem transportadora preenchida"
                      topBarColor="purple"
                      :step="4"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisCarregamento.aguardandoCarregamento
                      "
                      bigInfoSub="PROCESSOS"
                      title="AGUARDANDO CARREGAMENTO"
                      content="Carregamento não preenchido com desembaraço a 1 dia"
                      topBarColor="purple"
                      :step="4"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisCarregamento.semNF"
                      bigInfoSub="PROCESSOS"
                      title="SEM NOTA FISCAL"
                      content="Data de carregamento sem Nota Fiscal"
                      topBarColor="purple"
                      :step="4"
                  />
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisCarregamento.semTFA"
                      bigInfoSub="PROCESSOS"
                      title="SEM TFA"
                      content="Processos que não possuem TFA"
                      topBarColor="purple"
                      :step="4"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid
                    title="PENDÊNCIAS"
                    columns="1"
                    class="card-grid-large"
                >
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="
                        indicadoresOperacionaisPendencias.semRicPreenchida
                      "
                      bigInfoSub="PROCESSOS"
                      title="SEM RIC PREENCHIDA"
                      content="FCL e não possui containers cadastrados"
                      topBarColor="red"
                      :step="10"
                  />
                </card-grid>
                <card-grid
                    title="ENCERRAMENTO"
                    columns="1"
                    class="card-grid-large"
                >
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisEncerramento.atrasados"
                      bigInfoSub="PROCESSOS"
                      title="ATRASO"
                      content="Entregues sem encerramento"
                      topBarColor="orange"
                      :step="5"
                  />
                </card-grid>
              </div>

              <div>
                <card-grid title="GERAL" columns="1" class="card-grid-large">
                  <card-grid-item
                      :equalHeight="true"
                      :bigInfo="indicadoresOperacionaisGerais.numerarioPago"
                      bigInfoSub="PROCESSOS"
                      title="NUMERÁRIO PAGO"
                      content="Com numerário pago e sem registro de DI"
                      topBarColor="black"
                      :step="8"
                  />
                </card-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <styled-modal v-if="quoteModal" v-on:close-modal="quoteModal = false"/>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ControlPanelHeadline from '@/components/ControlPanelHeadline.vue';
import GeneralIndicatorsFilter from '@/components/GeneralIndicatorsFilter.vue';
import CardGrid from '@/components/CardGrid.vue';
import CardGridItem from '@/components/CardGridItem.vue';
import StyledModal from '@/components/QuoteModal.vue';
import Chartist from '@/components/Chartist.vue';
import HorizontalInfoCard from '@/components/HorizontalInfoCard.vue';
import Fcls from '@/components/icons/Fcls.vue';
import Lcls from '@/components/icons/Lcls.vue';
import Aereos from '@/components/icons/Aereos.vue';
import Rodoviarios from '@/components/icons/Rodoviarios.vue';
import FabModal from '@/components/FabModal.vue';
import moment from 'moment';
import Axios from 'axios';
import config from '@/config.js';
import ProcessService from '@/services/ProcessService.js';
import Bkbs from '@/components/icons/Bkbs.vue';

export default {
  name: 'ControlPanel',
  components: {
    ControlPanelHeadline,
    GeneralIndicatorsFilter,
    CardGrid,
    CardGridItem,
    StyledModal,
    Chartist,
    HorizontalInfoCard,
    Fcls,
    Lcls,
    Aereos,
    Rodoviarios,
    FabModal,
    Bkbs,
  },
  data() {
    return {
      activeTab: this.modal ? 'tab2' : 'tab1',
      quoteModal: false,
      indicadoresOperacionalPendencias: {},
      indicadoresOperacionalEncerramento: {},
      modalInco: false,
      inconsistencias: [],
    };
  },
  computed: {
    ...mapState({
      totalLiberacao: (state) => state.ControlPanelStore.totalLiberacao,
      totalRegistroDI: (state) => state.ControlPanelStore.totalRegistroDI,
      totalCarregamento: (state) => state.ControlPanelStore.totalCarregamento,
      totalFaturamento: (state) => state.ControlPanelStore.totalFaturamento,
      totalEncerramento: (state) => state.ControlPanelStore.totalEncerramento,
      indicadoresEtapas: (state) => state.ControlPanelStore.indicadoresEtapas,
      indicadoresCanais: (state) => state.ControlPanelStore.indicadoresCanais,
      indicadoresPeriodosArmazenagem: (state) => state.ControlPanelStore.indicadoresPeriodosArmazenagem,
      aberturasPeriodo: (state) => state.ControlPanelStore.aberturasPeriodo,
      indicadoresMovimentacaoProcessual: (state) => state.ControlPanelStore.indicadoresMovimentacaoProcessual,
      indicadorInconsistencias: (state) => state.ControlPanelStore.indicadorInconsistencias,
      firstDateFilter: (state) => state.ControlPanelStore.firstDateFilter,
      secondDateFilter: (state) => state.ControlPanelStore.secondDateFilter,
      customersIdFilter: (state) => state.ControlPanelStore.customersIdFilter,
      indicadoresOperacionaisProducao: (state) => state.ControlPanelStore.indicadoresOperacionaisProducao,
      indicadoresOperacionaisBooking: (state) => state.ControlPanelStore.indicadoresOperacionaisBooking,
      indicadoresOperacionaisTransito: (state) => state.ControlPanelStore.indicadoresOperacionaisTransito,
      indicadoresOperacionaisDesembaraco: (state) => state.ControlPanelStore.indicadoresOperacionaisDesembaraco,
      indicadoresOperacionaisCarregamento: (state) => state.ControlPanelStore.indicadoresOperacionaisCarregamento,
      indicadoresOperacionaisPendencias: (state) => state.ControlPanelStore.indicadoresOperacionaisPendencias,
      indicadoresOperacionaisEncerramento: (state) => state.ControlPanelStore.indicadoresOperacionaisEncerramento,
      indicadoresOperacionaisGerais: (state) => state.ControlPanelStore.indicadoresOperacionaisGerais,
    }),
    firstDatePeriod() {
      return moment(this.firstDateFilter).format('DD/MM/Y');
    },
    secondDatePeriod() {
      return moment(this.secondDateFilter).format('DD/MM/Y');
    },
    indicadoresAndamento() {
      return (
          parseInt(this.indicadoresEtapas.total)
          - parseInt(this.indicadoresEtapas.inLicensing)
      );
    },
  },
  methods: {
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    ...mapActions('ControlPanelStore', [
      'getTotalLiberacao',
      'getTotalRegistroDI',
      'getTotalCarregamento',
      'getTotalFaturamento',
      'getTotalEncerramento',
      'getIndicadoresEtapas',
      'getIndicadoresCanais',
      'getIndicadoresPeriodosArmazenagem',
      'getAberturasPeriodo',
      'getIndicadoresMovimentacaoProcessual',
      'getIndicadorInconsistencias',
      'defineFirstDateFilter',
      'defineSecondDateFilter',
      'defineCustomersIdFilter',
      'getIndicadoresOperacionaisProducao',
      'getIndicadoresOperacionaisBooking',
      'getIndicadoresOperacionaisTransito',
      'getIndicadoresOperacionaisDesembaraco',
      'getIndicadoresOperacionaisCarregamento',
      'getIndicadoresOperacionaisPendencias',
      'getIndicadoresOperacionaisGeral',
      'getIndicadoresOperacionaisEncerramento',
    ]),
    getInconsistencies() {
      Axios.get(
          `${config.env.API_DEFAULT.host
          }/inconsistency/getInconsistenciesById?ids=${
              this.indicadorInconsistencias.join()}`,
      ).then((response) => {
        this.inconsistencias = response.data.data;
      });
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY');
    },
    getIndicadoresOperacionais() {
      try {
        this.getIndicadoresOperacionaisProducao();
        this.getIndicadoresOperacionaisBooking();
        this.getIndicadoresOperacionaisTransito();
        this.getIndicadoresOperacionaisDesembaraco();
        this.getIndicadoresOperacionaisCarregamento();
        this.getIndicadoresOperacionaisPendencias();
        this.getIndicadoresOperacionaisEncerramento();
        this.getIndicadoresOperacionaisGeral();
      } catch (e) {
        console.error(e);
      }
    },
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.$store.commit('setLoading', {
      show: false,
    });
    this.defineSecondDateFilter({
      secondDate: `${moment().format('Y-MM-DD')}T23:59:00`,
    });

    try {
      await Promise.all([
        this.getTotalLiberacao(),
        this.getTotalRegistroDI(),
        this.getTotalCarregamento(),
        this.getTotalFaturamento(),
        this.getTotalEncerramento(),
        this.getIndicadoresEtapas(),
        this.getIndicadoresCanais(),
        this.getIndicadoresPeriodosArmazenagem(),
        this.getAberturasPeriodo(),
        this.getIndicadoresMovimentacaoProcessual(),
        this.getIndicadorInconsistencias(),
      ]);
    } catch (e) {
      console.error(e)
    }
  },
  destroyed() {
    this.defineFirstDateFilter({
      firstDate: `${moment().startOf('month').format('Y-MM-DD')}T00:00:00`,
    });
    this.defineSecondDateFilter({
      secondDate: `${moment().format('Y-MM-DD')}T23:59:00`,
    });
    this.defineCustomersIdFilter({customersId: []});
  },
};
</script>

<style scoped>
.filter-tabs {
  border-bottom: 2px solid #ced4da;
}

.filter-tabs .tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-tabs .tabs li a {
  position: relative;
  display: block;
  padding: 10px;
  color: var(--body);
  transition: none;
}

.filter-tabs .tabs li:hover a,
.filter-tabs .tabs li a.active {
  font-weight: bold;
}

.filter-tabs .tabs li:hover a::after,
.filter-tabs .tabs li a.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--gray-dark);
}

.tab-content {
  margin-top: 10px;
  border: 1px solid var(--border);
}

.tab-wrapper {
  padding: 10px;
  background-color: var(--light);
}

.tab-title {
  color: var(--gray-dark);
  padding: 20px 10px;
  background-color: white;
  border-bottom: 2px solid var(--border);
}

.tab-title h3 {
  font-weight: bold;
}

.process-grid {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(8, 1fr);
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

@media screen and (max-width: 1020px) {
  .process-grid {
    grid-template-columns: 1fr;
  }

  .span-2,
  .span-3 {
    grid-column: span 1;
  }
}

.process-item {
  position: relative;
  border: 1px solid var(--border);
  padding: 10px;
  background-color: white;
  text-align: center;
  color: var(--gray-dark);
}

.text-lighter {
  color: var(--gray);
  font-size: 16px;
}

span.lower {
  font-size: 0.9rem;
}

.columns-7 {
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
</style>
