<template>
  <div class="original-documents-body">
    <vue-good-table
        :fixed-header="true"
        :columns="columns"
        :rows="documents.content"
        :isLoading="loading"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionText: 'documento(s) selecionados',
          clearSelectionText: 'Limpar',
        }"
        :sort-options="{
          enabled: false,
        }"
        @on-selected-rows-change="selectionChanged"
        styleClass="vgt-table"
    >
      <!-- Loading State -->
      <template slot="loadingContent">
        <div class="btn btn-primary br-10">Carregando...</div>
      </template>

      <!-- Empty Table -->
      <div slot="emptystate">
        <h4 class="text-center">Nenhum documento</h4>
      </div>

      <!-- Selected Rows Slot Action -->
      <div slot="selected-row-actions">
        <div class="flex ai-c gap-1">
          <v-table-button color="success" @click.native="$emit('massivePoAction', rows)">
            <template #icon>
              <new-file-icon color="currentColor" size="14"/>
            </template>
            Criar PO
          </v-table-button>

          <v-table-button color="success" @click.native="$emit('massiveInvoiceAction', rows)">
            <template #icon>
              <new-file-icon color="currentColor" size="14"/>
            </template>
            Criar Invoice
          </v-table-button>
          <v-table-button @click.native="$emit('massiveRevisionAction', rows)">
            <template #icon>
              <review-icon size="14"/>
            </template>
            Revisar
          </v-table-button>
          <v-table-button color="danger" @click.native="$emit('massiveRejectionAction', rows)">
            <template #icon>
              <reject-icon size="14"/>
            </template>
            Rejeitar
          </v-table-button>
        </div>
      </div>

      <!-- Custom Columns -->
      <template slot="table-row" slot-scope="props">
        <!-- ID -->
        <span v-if="props.column.field == 'id'">
          #{{ props.row.id }}
        </span>
        <!-- Created At -->
        <div class="text-center" v-else-if="props.column.field == 'created_at'">
          <p class="mb-0">{{ new Date(props.row.created_at) | date('dd/MM/yyyy') }}</p>
          <p class="mb-0" style="color: #5D5D5D !important; font-size: 0.8rem;">{{ props.row.sent_by }}</p>
        </div>
        <!-- Buyer Name -->
        <div class="text-center" v-else-if="props.column.field == 'extractionResult.buyerName'">
          <span v-if="props.row.extractionResult">
            {{ props.row.extractionResult.buyerName !== null ? (companies.find((comp) => String(comp.value) === String(props.row.extractionResult.buyerName)) || {}).label  : '-' }}
          </span>
          <span v-else>-</span>
        </div>
        <!-- File Name -->
        <div class="text-center" v-else-if="props.column.field == 'fileName'">
          <p class="mb-0">{{ props.row.fileName }}</p>
          <p class="mb-0" style="color: #5D5D5D !important; font-size: 0.8rem;">{{ props.row.documentType | documentType }}</p>
        </div>
        <!-- Document Type -->
        <div class="text-center" v-else-if="props.column.field == 'documentType'">
          <span>{{ props.row.documentType | documentType }}</span>
        </div>
        <!-- Extraction Status -->
        <span v-else-if="props.column.field == 'extractionStatus'">
          <PillComponent :status="props.row.extractionStatus" :type="props.row.documentType"/>
        </span>
        <!-- Actions -->
        <div v-else-if="props.column.field == 'actions'">
          <div class="flex ai-c jc-c gap-1" v-if="props.row.extractionStatus === 'COMPLETE'">
            <v-table-button @click.native="$emit('revisionAction', props.row.id)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Revisar
            </v-table-button>

            <v-table-button color="danger" @click.native="$emit('rejectionAction', props.row.id)">
              <template #icon>
                <reject-icon size="14"/>
              </template>
              Rejeitar
            </v-table-button>
          </div>

          <div class="flex ai-c jc-c gap-1" v-else-if="props.row.extractionStatus === 'APPROVED'">
            <v-table-button v-if="props.row.documentType === 'invoices'" @click.native="$emit('showRejectedAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver Invoice
            </v-table-button>

            <v-table-button v-else @click.native="$emit('showRejectedPoAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver PO
            </v-table-button>

            <v-table-button v-if="props.row.documentType === 'invoices'" color="dark" @click.native="$emit('invoiceAction', props.row.id)">
              <template #icon>
                <new-file-icon size="14"/>
              </template>
              Criar Invoice
            </v-table-button>

            <v-table-button v-else color="dark" @click.native="$emit('poAction', props.row.id)">
              <template #icon>
                <new-file-icon size="14"/>
              </template>
              Criar PO
            </v-table-button>
          </div>

          <div class="flex ai-c jc-c gap-1" v-else-if="props.row.extractionStatus === 'REJECTED'">
            <v-table-button v-if="props.row.documentType === 'invoices'" @click.native="$emit('showRejectedAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver Invoice
            </v-table-button>

            <v-table-button v-else @click.native="$emit('showRejectedPoAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver PO
            </v-table-button>

            <v-table-button color="danger" @click.native="$emit('destroyAction', props.row.id)">
              <template #icon>
                <reject-icon size="14"/>
              </template>
              Remover
            </v-table-button>
          </div>

          <div class="flex ai-c jc-c gap-1" v-else-if="props.row.extractionStatus === 'AWAITING'">
            <v-table-button color="danger" @click.native="$emit('destroyAction', props.row.id)">
              <template #icon>
                <reject-icon size="14"/>
              </template>
              Cancelar
            </v-table-button>
          </div>

          <div class="flex ai-c jc-c gap-1" v-else-if="props.row.extractionStatus === 'CREATED'">
            <v-table-button v-if="props.row.documentType === 'invoices'" @click.native="$emit('showCreatedAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver Invoice
            </v-table-button>

            <v-table-button v-else @click.native="$emit('showCreatedPoAction', props.row.id, true)">
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver PO
            </v-table-button>
          </div>
        </div>
        <!-- Generic -->
        <div v-else>
          <span v-if="props.formattedRow[props.column.field]">
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else>-</span>
        </div>
      </template>
    </vue-good-table>

    <VTablePagination
        :total="documents.totalElements"
        :last-page="documents.totalPages"
        :current-page="documents.number + 1"
        @page-change="(page) => $emit('pageChange', page)"
    />
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

import originalDocumentsTableColumns from '@/utils/OriginalDocumentsTableColumns';
import NewFileIcon from '@/components/icons/NewFileIcon.vue';
import VTablePagination from '@/components/VTablePagination.vue';
import VTableButton from '@/components/VTableButton.vue';
import PillComponent from '@/components/OriginalDocuments/Pill.vue';
import ReviewIcon from '@/components/icons/ReviewIcon.vue';
import RejectIcon from '@/components/icons/RejectIcon.vue';
import { dateFilter } from 'vue-date-fns';

export default {
  name: 'OriginalDocumentsTable',
  components: {
    RejectIcon,
    ReviewIcon,
    PillComponent,
    VTableButton,
    VTablePagination,
    NewFileIcon,
    'vue-good-table': VueGoodTable,
  },
  filters: {
    date: dateFilter,
    documentType: (value) => {
      switch (value) {
        case 'invoices':
          return 'Invoice / Proforma Invoice';
        case 'purchase_orders':
          return 'Ordem de Compra';
        default:
          return 'N/I';
      }
    },
  },
  props: {
    documents: {
      type: Object,
      default: () => ({
        content: [],
        totalElements: 0,
        totalPages: 0,
        number: 0,
        numberOfElements: 0,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: originalDocumentsTableColumns,
      rows: [],
    };
  },
  methods: {
    // handle table selection
    selectionChanged({ selectedRows }) {
      if (selectedRows.length > 0) {
        this.rows = selectedRows.map((r) => r.id);
      } else {
        this.rows = [];
      }
    },
  },
};
</script>

<style>
body .original-documents-body .vgt-table thead th,
body .original-documents-body .vgt-wrap__footer,
body .original-documents-body .vgt-wrap__footer select,
body .original-documents-body .footer__navigation > * {
  background: #F8F9FA !important;
  color: #343A40 !important;
}

body .original-documents-body .vgt-table th.vgt-checkbox-col {
  border-right: 0;
}

body .original-documents-body .vgt-table th.line-numbers,
body .original-documents-body .vgt-table tbody tr th.vgt-checkbox-col {
  background: white !important;
  border-bottom: 1px solid #11437440;
}

body .original-documents-body .vgt-table tbody tr:hover th.vgt-checkbox-col {
  background-color: #f5f6f7 !important;
}

body .original-documents-body table.vgt-table td {
  color: #2E2E2E;
  font-weight: 300;
}

body .vgt-table th.sortable button:before {
  border-top: 5px solid rgba(0, 0, 0, 0.2)
}

body .vgt-table th.sortable button:after {
  border-bottom: 5px solid rgba(0, 0, 0, 0.2)
}

body .vgt-table thead th.sorting-asc button:after {
  border-bottom: 5px solid rgba(0, 0, 0, 0.6)
}

body .vgt-table thead th.sorting-desc button:before {
  border-top: 5px solid rgba(0, 0, 0, 0.6)
}
</style>
