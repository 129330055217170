import axios from 'axios';
import config from '@/config.js';
import moment from 'moment';

const apiHost = config.env.API_DEFAULT.host;

const ControlPanelStore = {
  namespaced: true,
  state: {
    totalLiberacao: 0,
    totalRegistroDI: 0,
    totalCarregamento: 0,
    totalFaturamento: 0,
    totalEncerramento: 0,
    indicadoresEtapas: {},
    indicadoresCanais: {},
    indicadoresPeriodosArmazenagem: {},
    aberturasPeriodo: 0,
    indicadoresMovimentacaoProcessual: {},
    indicadorInconsistencias: 0,
    firstDateFilter: `${moment().startOf('month').format('Y-MM-DD')}T00:00:00`,
    secondDateFilter: `${moment().format('Y-MM-DD')}T23:59:00`,
    customersIdFilter: [],
    teamIdFilter: 0,
    indicadoresOperacionaisProducao: {},
    indicadoresOperacionaisBooking: {},
    indicadoresOperacionaisTransito: {},
    indicadoresOperacionaisDesembaraco: {},
    indicadoresOperacionaisCarregamento: {},
    indicadoresOperacionaisPendencias: {},
    indicadoresOperacionaisEncerramento: {},
    indicadoresOperacionaisGerais: {},
  },
  mutations: {
    SET_TOTAL_LIBERACAO(state, payload) {
      state.totalLiberacao = payload;
    },
    SET_TOTAL_REGISTRO_DI(state, payload) {
      state.totalRegistroDI = payload;
    },
    SET_TOTAL_CARREGAMENTO(state, payload) {
      state.totalCarregamento = payload;
    },
    SET_TOTAL_FATURAMENTO(state, payload) {
      state.totalFaturamento = payload;
    },
    SET_TOTAL_ENCERRAMENTO(state, payload) {
      state.totalEncerramento = payload;
    },
    SET_INDICADORES_ETAPAS(state, payload) {
      state.indicadoresEtapas = payload;
    },
    SET_INDICADORES_CANAIS(state, payload) {
      state.indicadoresCanais = payload;
    },
    SET_INDICADORES_PERIODOS_ARMAZENAGEM(state, payload) {
      state.indicadoresPeriodosArmazenagem = payload;
    },
    SET_ABERTURAS_PERIODO(state, payload) {
      state.aberturasPeriodo = payload;
    },
    SET_INDICADORES_MOVIMENTACAO_PROCESSUAL(state, payload) {
      state.indicadoresMovimentacaoProcessual = payload;
    },
    SET_FIRST_DATE_FILTER(state, payload) {
      state.firstDateFilter = payload;
    },
    SET_SECOND_DATE_FILTER(state, payload) {
      state.secondDateFilter = payload;
    },
    SET_CUSTOMERS_ID_FILTER(state, payload) {
      state.customersIdFilter = payload;
    },
    SET_TEAM_ID_FILTER(state, payload) {
      state.teamIdFilter = payload;
    },
    SET_INDICADOR_INCONSISTENCIAS(state, payload) {
      state.indicadorInconsistencias = payload;
    },
    SET_INDICADORES_OPERACIONAIS_PRODUCAO(state, payload) {
      state.indicadoresOperacionaisProducao = payload;
    },
    SET_INDICADORES_OPERACIONAIS_BOOKING(state, payload) {
      state.indicadoresOperacionaisBooking = payload;
    },
    SET_INDICADORES_OPERACIONAIS_TRANSITO(state, payload) {
      state.indicadoresOperacionaisTransito = payload;
    },
    SET_INDICADORES_OPERACIONAIS_DESEMBARACO(state, payload) {
      state.indicadoresOperacionaisDesembaraco = payload;
    },
    SET_INDICADORES_OPERACIONAIS_CARREGAMENTO(state, payload) {
      state.indicadoresOperacionaisCarregamento = payload;
    },
    SET_INDICADORES_OPERACIONAIS_PENDENCIAS(state, payload) {
      state.indicadoresOperacionaisPendencias = payload;
    },
    SET_INDICADORES_OPERACIONAIS_ENCERRAMENTO(state, payload) {
      state.indicadoresOperacionaisEncerramento = payload;
    },
    SET_INDICADORES_OPERACIONAIS_GERAIS(state, payload) {
      state.indicadoresOperacionaisGerais = payload;
    },
  },
  actions: {
    getTotalIndicador({ commit }, payload) {
      let commitAction = '';

      switch (payload.indicador) {
        case 'LIBERACAO':
          commitAction = 'SET_TOTAL_LIBERACAO';
          break;

        case 'REGISTRO_DI':
          commitAction = 'SET_TOTAL_REGISTRO_DI';
          break;

        case 'CARREGAMENTO':
          commitAction = 'SET_TOTAL_CARREGAMENTO';
          break;

        case 'FATURAMENTO':
          commitAction = 'SET_TOTAL_FATURAMENTO';
          break;

        case 'ENCERRAMENTO':
          commitAction = 'SET_TOTAL_ENCERRAMENTO';
      }

      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${payload.indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit(commitAction, result.data.data);
        });
    },
    getTotalLiberacao({ commit }, payload) {
      const indicador = 'LIBERACAO';
      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_TOTAL_LIBERACAO', result.data.data);
        });
    },
    getTotalRegistroDI({ commit }, payload) {
      const indicador = 'REGISTRO_DI';
      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_TOTAL_REGISTRO_DI', result.data.data);
        });
    },
    getTotalCarregamento({ commit }, payload) {
      const indicador = 'CARREGAMENTO';
      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_TOTAL_CARREGAMENTO', result.data.data);
        });
    },
    getTotalFaturamento({ commit }, payload) {
      const indicador = 'FATURAMENTO';
      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_TOTAL_FATURAMENTO', result.data.data);
        });
    },
    getTotalEncerramento({ commit }, payload) {
      const indicador = 'ENCERRAMENTO';
      axios.get(`${apiHost}/painelControle/leadtime/indicadorEspecifico/?indicador=${indicador}&customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_TOTAL_ENCERRAMENTO', result.data.data);
        });
    },
    getIndicadoresEtapas({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/etapas/todosIndicadores/?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_INDICADORES_ETAPAS', result.data.data);
        });
    },
    getIndicadoresCanais({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/canais/todosIndicadores/?customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_INDICADORES_CANAIS', result.data.data);
        });
    },
    getIndicadoresPeriodosArmazenagem({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/periodosArmazenagem/todosIndicadores/?customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_INDICADORES_PERIODOS_ARMAZENAGEM', result.data.data);
        });
    },
    getAberturasPeriodo({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/aberturasNoPeriodo/?customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_ABERTURAS_PERIODO', result.data.data);
        });
    },
    getIndicadoresMovimentacaoProcessual({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/movimentacaoProcessual/?customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`)
        .then((result) => {
          commit('SET_INDICADORES_MOVIMENTACAO_PROCESSUAL', result.data.data);
        });
    },
    getIndicadorInconsistencias({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/inconsistencias/?customersId=${this.state.ControlPanelStore.customersIdFilter}&firstDate=${this.state.ControlPanelStore.firstDateFilter}&secondDate=${this.state.ControlPanelStore.secondDateFilter}`)
        .then((result) => {
          commit('SET_INDICADOR_INCONSISTENCIAS', result.data.data);
        });
    },

    // Indicadores operacionais
    getIndicadoresOperacionaisProducao({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/producao?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_PRODUCAO', result.data.data);
      });
    },
    getIndicadoresOperacionaisBooking({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/booking?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_BOOKING', result.data.data);
      });
    },
    getIndicadoresOperacionaisTransito({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/transito?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_TRANSITO', result.data.data);
      });
    },
    getIndicadoresOperacionaisDesembaraco({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/desembaraco?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_DESEMBARACO', result.data.data);
      });
    },
    getIndicadoresOperacionaisGeral({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/gerais?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_GERAIS', result.data.data);
      });
    },
    getIndicadoresOperacionaisCarregamento({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/carregamento?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_CARREGAMENTO', result.data.data);
      });
    },
    getIndicadoresOperacionaisPendencias({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/pendencias?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_PENDENCIAS', result.data.data);
      });
    },
    getIndicadoresOperacionaisEncerramento({ commit }, payload) {
      axios.get(`${apiHost}/painelControle/operacional/encerramento?customersId=${this.state.ControlPanelStore.customersIdFilter}&teamId=${this.state.ControlPanelStore.teamIdFilter}`).then((result) => {
        commit('SET_INDICADORES_OPERACIONAIS_ENCERRAMENTO', result.data.data);
      });
    },
    defineFirstDateFilter({ commit }, payload) {
      commit('SET_FIRST_DATE_FILTER', payload.firstDate);
    },
    defineSecondDateFilter({ commit }, payload) {
      commit('SET_SECOND_DATE_FILTER', payload.secondDate);
    },
    defineCustomersIdFilter({ commit }, payload) {
      commit('SET_CUSTOMERS_ID_FILTER', payload.customersId);
    },
    defineTeamIdFilter({ commit }, payload) {
      commit('SET_TEAM_ID_FILTER', payload.teamId);
    },
  },
};

export default ControlPanelStore;
