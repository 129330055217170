import * as yup from 'yup';

const storeSchema = yup.object().shape({
  name: yup.string().min(3, 'Deve conter pelo menos 3 caracteres!').max(100, 'Deve conter no máximo 100 caracteres!').required('O nome é obrigatório!')
    .typeError('Formato inválido!'),
  dateOfBirth: yup.string().required('A data de nascimento é obrigatória!').transform((cur, ori) => (ori === null ? '' : cur)).typeError('Formato inválido!'),
  gender: yup.string().oneOf(['m', 'f'], 'O gênero é obrigatório!').typeError('Formato inválido!'),
  federalID: yup.string().required('O CPF é obrigatório').typeError('Formato inválido!'),
  jobRole: yup.string().min(3, 'Deve conter pelo menos 3 caracteres!').max(200, 'Deve conter no máximo 200 caracteres!').required('O cargo é obrigatório!')
    .typeError('Formato inválido!'),
  enabled: yup.boolean().required('A situação do usuário é obrigatória!').typeError('Formato inválido!'),
  sector: yup.string().required('O setor é obrigatório!').typeError('Formato inválido!'),
  employee: yup.boolean().required('O campo colaborador é obrigatória!').transform((cur, ori) => (ori === null ? false : cur)).typeError('Formato inválido!'),
  role: yup.string().required('O perfil/papel é obrigatório!').typeError('Formato inválido!'),
  groupId: yup.string().required('O grupo é obrigatório!').transform((cur, ori) => (ori === null ? '' : cur)).typeError('Formato inválido!'),
  email1: yup.string().email('E-mail inválido').required('O e-mail é obrigatório!').typeError('Formato inválido!'),
  generatePassword: yup.boolean().typeError('Formato inválido!'),
  password: yup.string().when('generatePassword', {
    is: false,
    then: yup.string().min(6, 'Deve conter pelo menos 6 caracteres!').max(100, 'Deve conter no máximo 100 caracteres!').required('A senha é obrigatória!'),
  }).typeError('Formato inválido!'),
  confPassword: yup.string().when('generatePassword', {
    is: false,
    then: yup.string().oneOf([yup.ref('password'), null], 'As senhas não conferem!').required('A confirmação de senha é obrigatória!'),
  }).typeError('Formato inválido!'),
});

const updateSchema = yup.object().shape({
  name: yup.string().min(3, 'Deve conter pelo menos 3 caracteres!').max(100, 'Deve conter no máximo 100 caracteres!').required('O nome é obrigatório!')
    .typeError('Formato inválido!'),
  dateOfBirth: yup.string().required('A data de nascimento é obrigatória!').transform((cur, ori) => (ori === null ? '' : cur)).typeError('Formato inválido!'),
  gender: yup.mixed().oneOf(['m', 'f'], 'O gênero é obrigatório!').typeError('Formato inválido!'),
  federalID: yup.string().required('O CPF é obrigatório').typeError('Formato inválido!'),
  jobRole: yup.string().min(3, 'Deve conter pelo menos 3 caracteres!').max(200, 'Deve conter no máximo 200 caracteres!').required('O cargo é obrigatório!')
    .typeError('Formato inválido!'),
  enabled: yup.boolean().required('A situação do usuário é obrigatória!').typeError('Formato inválido!'),
  sector: yup.string().required('O setor é obrigatório!').typeError('Formato inválido!'),
  employee: yup.boolean().required('O campo colaborador é obrigatória!').transform((cur, ori) => (ori === null ? false : cur)).typeError('Formato inválido!'),
  role: yup.string().required('O perfil/papel é obrigatório!').typeError('Formato inválido!'),
  groupId: yup.string().required('O grupo é obrigatório!').transform((cur, ori) => (ori === null ? '' : cur)).typeError('Formato inválido!'),
  email1: yup.string().email('E-mail inválido').required('O e-mail é obrigatório!').typeError('Formato inválido!'),
  password: yup.string().min(6, 'Deve conter pelo menos 6 caracteres!').max(100, 'Deve conter no máximo 100 caracteres!').transform((cur, ori) => (ori === '' ? null : cur))
    .nullable()
    .typeError('Formato inválido!'),
  confPassword: yup.string().when('password', (password, schema) => {
    if (password) {
      return schema.oneOf([yup.ref('password'), null], 'As senhas não conferem!').required('A confirmação de senha é obrigatória!');
    }
    return schema;
  }).typeError('Formato inválido!'),
});

export {storeSchema, updateSchema};
