<template>
  <div class="crm-page">
    <customer-list />
    <router-view></router-view>
    <div class="contact-container" v-if="!this.$route.params.id && this.$route.name === 'crm-clientes'" >
      <div class="card-body pd-40 sp-3 text-center">
        <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
        <h6 class="tx-md-20 tx-inverse">Nenhum Cliente Selecionado</h6>
        <p>Selecione um CLIENTE através da barra lateral</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerList from '@/components/crm/CustomerList';

export default {
  name: 'CrmCustomers',
  components: {
    CustomerList,
  },
};
</script>
