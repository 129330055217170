<template>
  <div>
    <div class="light-bg p-3 bd-b">
      <div class="flex ai-c jc-sb fw-w fgap2">
        <div>
          <h4 class="mb-0">SOLICITAÇÕES DE PREENCHIMENTO DE DADOS</h4>
          <p class="mb-0">
            Listagem das solicitações de dados segundo o filtro
          </p>
        </div>
        <div class="flex ai-c fgap1">
          <button
            class="btn btn-info"
            @click="addDialog = !addDialog"
            type="button"
          >
            Filtros aplicados
          </button>
          <button
            class="btn btn-success"
            @click.prevent="openMassiveRequestModal()"
          >
            Enviar solicitações em massa
          </button>
        </div>
      </div>
      <transition name="activity" mode="out-in">
        <div v-if="addDialog">
          <div class="four-grid mt-4">
            <div class="four-grid">
              <div class="fg-4">
                <v-input
                  name="startDate"
                  type="date"
                  label="Data inicial de envio"
                  v-model="formFilters.startDate"
                ></v-input>
              </div>
            </div>
            <div class="four-grid">
              <div class="fg-4">
                <v-input
                  name="endDate"
                  type="date"
                  label="Data final de envio"
                  v-model="formFilters.endDate"
                ></v-input>
              </div>
            </div>
            <div class="four-grid">
              <div class="fg-4">
                <select-wrapper
                  name="answered"
                  label="Respondido"
                  :options="[
                    { value: 'null', label: 'Todas as solicitações' },
                    { value: true, label: 'Sim' },
                    { value: 'false', label: 'Não' },
                  ]"
                  v-model="formFilters.answered"
                ></select-wrapper>
              </div>
            </div>
          </div>
          <div class="flex ai-c jc-c fgap2 mt-4">
            <button class="report-button" @click.prevent="getRequests">
              GERAR EM TELA
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div class="ph-2 sp-3 mt-4">
      <div class="table-container">
        <div class="table-overflow">
          <table>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Contato</th>
                <th>Data de envio</th>
                <th>Validade</th>
                <th>Respondido</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in requests" :key="request.id">
                <td>{{ request.id }}</td>
                <td>{{ request.contact.name }}</td>
                <td>
                  {{ request.createdAt ? formatDate(request.createdAt) : "-" }}
                </td>
                <td>
                  {{
                    request.expiration ? formatDate(request.expiration) : "-"
                  }}
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    v-if="request.answers && request.answers.length > 0"
                  ></i>
                  <i class="fa fa-times text-danger" v-else></i>
                </td>
                <td>
                  <button
                    class="btn btn-sm rounded btn-success"
                    v-if="request.answers.length > 0"
                    @click.prevent="openAnswerModal(request.answers[0].id)"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr v-if="requests.length == 0">
                <td colspan="6" class="text-center">
                  Nenhuma solicitação encotrada!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <v-modal
      :title="`Dados da solicitação #${
        answerData ? answerData.request.id : ''
      }`"
      description="Dados enviados pelo contato"
      :width="800"
      :handler="answerModalOpen"
      @requestClose="closeAnswerModal"
    >
      <template #content>
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="details-label">Nome</p>
              <p>{{ answerDataContent.name }}</p>
            </div>
            <div class="col">
              <p class="details-label">Telefone</p>
              <p>{{ answerDataContent.telephone2 }}</p>
            </div>
            <div class="col">
              <p class="details-label">Data de Nascimento</p>
              <p>{{ formatDate(answerDataContent.dateOfBirth) }}</p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <p class="details-label">Data de Contratação</p>
              <p>{{ formatDate(answerDataContent.hiringDate) }}</p>
            </div>
            <div class="col">
              <p class="details-label">Cargo</p>
              <p>{{ answerDataContent.jobRole }}</p>
            </div>
            <div class="col">
              <p class="details-label">Setor</p>
              <p>{{ answerDataContent.sector }}</p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <p class="text-center">
          Envio em {{ formatDate(answerData.sendedDate) }}
        </p>
      </template>
    </v-modal>
    <v-modal
      :title="`Envio massivo do solicitações`"
      description="Enviar solicitação de preenchimento para os usuários abaixo"
      :width="1000"
      :handler="massiveRequestModalOpen"
      @requestClose="closeMassiveRequestModal"
    >
      <template #content>
        <div class="container">
          <div class="alert alert-warning">
            <span
              ><i class="fa fa-exclamation-triangle mr-1"></i> Confira os
              contatos abaixo antes de enviar as solicitações!</span
            >
          </div>
          <div class="table-container">
            <div class="table-overflow">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Cliente</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contact in massiveContacts" :key="contact.id">
                    <td>{{ contact.id }}</td>
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.email1 }}</td>
                    <td>
                      {{
                        contact.companies && contact.companies.length > 0
                          ? getCompaniesNames(contact.companies)
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr v-if="massiveContacts.length == 0">
                    <td colspan="4" class="text-center">
                      Nenhum contato encontrado!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row justify-content-center">
          <button class="report-button" @click.prevent="sendMassiveRequest">
            Enviar
          </button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import VInput from "@/components/VInput";
import SelectWrapper from "@/components/SelectWrapper";
import VModal from "@/components/VModal.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      requests: [],
      massiveContacts: [],
      answerModalOpen: false,
      massiveRequestModalOpen: false,
      answerData: null,
      answerDataContent: null,
      addDialog: true,
      formFilters: {
        startDate: null,
        endDate: null,
        answered: null,
        contactId: null,
      },
    };
  },
  components: {
    VModal,
    VInput,
    SelectWrapper,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY [às] HH:mm:ss");
    },
    getCompaniesNames(companies) {
      const companiesMapped = companies.map((company) => company.name);

      return companiesMapped.join(", ");
    },
    async sendMassiveRequest() {
      Swal.fire({
        title: "Ateção!",
        html: "<p>Tem certeza que deseja enviar as solicitações? Após confirmar esta ação, os contatos listados receberam um email com o link para preencher os dados!</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Enviar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Enviando solicitações em massa",
          });

          try {
            const response = await Axios.post(
              `${config.env.API_DEFAULT.host}/contactBasicDataRequests/sendMassiveRequest`
            );

            this.getRequests();
            this.closeMassiveRequestModal();

            this.$store.commit("setLoading", {
              show: false,
            });

            Swal.fire({
              title: "Solicitações de Dados!",
              text: "Enviado solicitações em massa com sucesso!",
              type: "success",
            });
          } catch (e) {
            this.$store.commit("setLoading", {
              show: false,
            });

            Swal.fire({
              title: "Solicitações de Dados!",
              text: "Erro ao enviar solicitações em massa!",
              type: "error",
            });
          }
        }
      });
    },
    async openAnswerModal(answerId) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Buscando Resposta",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactBasicDataAnswers/${answerId}`
        );
        this.answerData = response.data.data;
        this.answerDataContent = JSON.parse(this.answerData.content);
        this.answerModalOpen = true;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    closeAnswerModal() {
      this.answerModalOpen = false;
      this.answerData = null;
      this.answerDataContent = null;
    },
    closeMassiveRequestModal() {
      (this.massiveRequestModalOpen = false), (this.massiveContacts = []);
    },
    async openMassiveRequestModal() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Buscando Contatos",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactBasicDataRequests/contactsWithoutRequest`
        );
        this.massiveRequestModalOpen = true;

        this.massiveContacts = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async getRequests() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Solicitações de Dados",
        description: "Aguarde, estamos buscando as solicitações enviadas...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactBasicDataRequests`,
          {
            params: {
              startDate: this.formFilters.startDate
                ? `${this.formFilters.startDate}T00:00:00`
                : null,
              endDate: this.formFilters.endDate
                ? `${this.formFilters.endDate}T23:59:00`
                : null,
              answered: this.formFilters.answered,
            },
          }
        );

        this.requests = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        console.error(e);

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  async mounted() {
    await this.getRequests();
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

.plus-button:hover {
  background-color: #1768a7;
}

.plus-button.success {
  background-color: #80b7e2;
}

.four-grid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.fg-2 {
  grid-column: span 2;
}

.fg-3 {
  grid-column: span 3;
}

.fg-4 {
  grid-column: span 4;
}

fieldset {
  display: block;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

fieldset legend {
  background-color: transparent;
  font-size: 14px;
  width: auto;
  padding: 0 5px;
}

.columns-list {
  list-style: none;
  padding: 0;
  max-height: 270px;
  overflow: auto;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.columns-list::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.columns-list::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.columns-list li a {
  display: block;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e2e2e2;
  color: inherit;
}

.columns-list li a:hover {
  background-color: #dbdbdb;
}

.columns-list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.columns-list.selecteds li a {
  color: black;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 10px 5px;
  color: #343a40;
  text-align: center;
  font-size: 0.8rem;
}

table tr th {
  background-color: #8392a51a;
  min-width: 150px;
}

.table-container {
  width: 100%;
  overflow: auto;
  height: 500px;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

.details-label {
  font-size: 1rem;
  font-weight: 700;
  color: #343a40;
  margin-bottom: 3px;
}
</style>
