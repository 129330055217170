<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Conta</label>

      <div class="row no-gutters">
        <div class="col-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Número da Fatura </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <input
                  type="text"
                  class="form-control m-input wd-200"
                  v-model="invoice.identification"
                  name="identification"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group mg-md-l--1 bd-l-0-force">
            <label class="form-control-label"> Tipo de Fatura </label>
            <div class="row">
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-primary mg-t-0-force">
                  <input
                    name="type"
                    type="radio"
                    v-model="invoice.type"
                    :value="'IMPORT_PROCESS'"
                  />
                  <span>Imp</span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-teal mg-t-0-force">
                  <input
                    name="type"
                    type="radio"
                    v-model="invoice.type"
                    :value="'EXPORT_PROCESS'"
                  />
                  <span>Exp</span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="type"
                    type="radio"
                    v-model="invoice.type"
                    :value="'SINGLE_INVOICE'"
                  />
                  <span>Avulsa</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Cliente </label>
            <v-select
              v-model="invoice.customer"
              :on-search="getCompanies"
              label="name"
              :options="this.companies"
              name="customer"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
              <span slot="no-options">Nenhuma empresa foi encontrada!</span>
            </v-select>
          </div>
        </div>

        <div
          class="col-3"
          :class="{ 'has-danger': errors.has('bankAccountSource') }"
        >
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Conta Bancária de Destino
            </label>
            <v-select
              v-model="invoice.bankAccountDestination"
              :on-search="getBankAccounts"
              label="description"
              :options="this.bankAccounts"
              name="bankAccountDestination"
            >
              <template slot="option" slot-scope="option">
                Agência: {{ option.agencyNumber }} | Conta:
                {{ option.accountNumber }} | {{ option.description }}
              </template>
            </v-select>
            <div
              v-show="errors.has('bankAccountDestination')"
              class="form-control-feedback"
            >
              Conta Bancária de Destino é um campo obrigatório.
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Filial </label>
            <v-select
              v-model="invoice.account"
              :on-search="getAccounts"
              label="name"
              :options="accounts"
              name="account"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
            <span
              v-show="errors.has('accounts')"
              class="parsley-errors-list filled"
            >Selecione o Banco</span
            >
          </div>
        </div>

        <div class="col-3">
          <div
            class="form-group mg-md-l--1 bd-l-0-force"
            v-if="
              this.invoiceType === 'IMPORT_PROCESS' ||
              this.invoice.type === 'IMPORT_PROCESS'
            "
          >
            <label class="form-control-label"> Processo de Importação </label>
            <v-select
              v-model="invoice.process"
              :on-search="getImportProcesses"
              label="process"
              :options="this.importProcesses"
            >
              <span slot="no-options">Nenhuma Processo foi encontrado!</span>
            </v-select>
          </div>
          <div
            class="form-group mg-md-l--1 bd-l-0-force"
            v-else-if="
              this.invoiceType === 'EXPORT_PROCESS' ||
              this.invoice.type === 'EXPORT_PROCESS'
            "
          >
            <label class="form-control-label">Processo de Importação </label>
            <v-select
              v-model="invoice.process"
              :on-search="getImportProcesses"
              label="process"
              :options="this.exportProcesses"
            >
              <span slot="no-options">Nenhuma Processo foi encontrado!</span>
            </v-select>
          </div>
        </div>
      </div>
      <!-- Serviços da fatura -->
      <label class="section-title mg-t-20">Serviços da Fatura</label>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-colored bg-primary">
          <tr>
            <th style="width: 100px">
              <span>#</span>
            </th>
            <th style="width: 400px">
              <span> SERVIÇO </span>
            </th>
            <th style="width: 600px">
              <span> DESCRIÇÃO </span>
            </th>
            <th style="width: 200px">
              <span> VALOR (R$) </span>
            </th>
            <th style="max-width: 50px !important"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              {{ item.index }}
            </td>
            <td>
              <v-select
                v-model="item.type"
                :on-search="getAppointmentTypes"
                label="description"
                :options="appointmentTypes"
              >
                <template slot="option" slot-scope="option">
                  {{ option.description }}
                </template>
                <span slot="no-options"
                >Nenhum tipo de serviço foi encontrado!</span
                >
              </v-select>
              <div
                v-show="errors.has('description')"
                class="parsley-errors-list filled"
              >
                Tipo de Serviço é um campo obrigatório.
              </div>
            </td>
            <td>
              <div class="form-group has-success mg-b-0">
                  <textarea
                    rows="1"
                    type="text"
                    class="form-control is-valid mg-t-20"
                    placeholder="Descreva a fatura"
                    v-model="item.description"
                    name="description"
                  ></textarea>
              </div>
              <div
                v-show="errors.has('description')"
                class="parsley-errors-list filled"
              >
                Descrição é um campo obrigatório.
              </div>
            </td>
            <td>
              <money
                class="form-control"
                v-model="item.value"
                v-bind="money"
                name="money"
              ></money>
              <div
                v-show="errors.has('money')"
                class="parsley-errors-list filled"
              >
                Valor é um campo obrigatório.
              </div>
            </td>
            <td>
              <a
                href="#"
                class="btn btn-danger btn-icon rounded-circle"
                id="botaoLixo"
                @click.prevent="removeItem(item)"
              ><i class="icon ion-trash-a tx-22"></i
              ></a>
            </td>
          </tr>
          <tr v-if="items === null || items.length === 0">
            <td colspan="5">
              <br/>
              <div class="alert m-alert m-alert--default">
                <center>Nenhum item cadastrado.</center>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3" align="right">Valor Total da Fatura</td>
            <td>
                <span class="m--font-boldest">
                  R$ {{ total | numeralFormat("0,0.00") }}
                </span>
            </td>
            <td>
              <a
                href="#"
                class="btn btn-oblong btn-[primary]"
                id="botaoAdd"
                @click.prevent="addItem()"
              >
                <i class="icon ion-plus tx-22"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Fim de Serviços da fatura -->

    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-20">Detalhes da Fatura</label>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 pd-r-0">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label">Desconto </label>
                <money
                  type="text"
                  placeholder="Valor do Desconto"
                  name="discountValue"
                  class="form-control"
                  v-model="invoice.discountValue"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('discountValue')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>
            <div class="col-md-4 pd-l-0 pd-r-0">
              <div class="form-group mg-md-l-0 bd-l-0-force">
                <label class="form-control-label">Vencimento </label><br/>
                <date-picker lang="pt-br"
                             v-model="invoice.dueDate"
                             name="dueDate"
                             class="form-control"
                             format="DD/MM/YYYY"
                ></date-picker>
                <span
                  v-show="errors.has('dueDate')"
                  class="parsley-errors-list filled"
                >Selecione uma Data</span
                >
              </div>
            </div>
            <div class="col-md-4 pd-l-0">
              <div class="form-group mg-md-l-0 bd-l-0-force">
                <label class="form-control-label">Valor da Fatura" </label>
                <money
                  type="text"
                  placeholder="Valor da Fatura"
                  name="value"
                  class="form-control"
                  v-model="invoice.value"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('value')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>
          </div>
        </div>
        <br/>

        <div class="col-md-12">
          <label class="section-title mg-t-20">Notas Fiscal de Serviços</label>
          <div class="row">
            <div class="col-md-4 pd-r-0">
              <div class="form-group mg-md-l-0">
                <label class="form-control-label">Nº da NFS-e </label>
                <input
                  type="text"
                  placeholder="Nº da NFS-e"
                  name="discountValue"
                  class="form-control"
                  v-model="invoice.rpsNfsIdentification"
                />
                <span
                  v-show="errors.has('discountValue')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>
            <div class="col-md-4 pd-l-0 pd-r-0">
              <div class="form-group mg-md-l-0 bd-l-0-force">
                <label class="form-control-label"
                >Data de Emissão da NFS-e </label
                ><br/>
                <date-picker lang="pt-br"
                             v-model="invoice.rpsNfsDate"
                             name="rpsNfsDate"
                             class="form-control"
                             format="DD/MM/YYYY"
                ></date-picker>
                <span
                  v-show="errors.has('rpsNfsDate')"
                  class="parsley-errors-list filled"
                >Selecione uma Data</span
                >
              </div>
            </div>
            <div class="col-md-4 pd-l-0">
              <div class="form-group mg-md-l-0 bd-l-0-force">
                <label class="form-control-label">Valor da NFS-e </label>
                <money
                  type="text"
                  placeholder="Valor da NFS-e"
                  name="value"
                  class="form-control"
                  v-model="invoice.nfsTotalValue"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('nfsTotalValue')"
                  class="parsley-errors-list filled"
                >
                  Valor da NFS-e</span
                >
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="col-md-12" v-if="!getSingleInvoice()">
          <label class="section-title mg-t-20"
          >Valores totais do Processo</label
          >

          <div class="row">
            <div class="col-md-4 pd-r-0">
              <div class="form-group mg-md-l--1 bd-r-0-force">
                <label class="form-control-label"
                ><span class="tx-success">Valor de Créditos"</span>
                </label>
                <money
                  type="text"
                  placeholder="Saldo do Processo"
                  name="processTotalInputs"
                  class="form-control"
                  v-model="invoice.processTotalInputs"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('processTotalInputs')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>

            <div class="col-md-4 pd-l-0 pd-r-0">
              <div class="form-group mg-md-l--1 bd-r-0-force">
                <label class="form-control-label"
                ><span class="tx-danger"> Valor de Débitos</span>
                </label>
                <money
                  type="text"
                  placeholder="Saldo do Processo"
                  name="processTotalOutputs"
                  class="form-control"
                  v-model="invoice.processTotalOutputs"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('processTotalOutputs')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>
            <div class="col-md-4 pd-l-0">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label">Saldo Total </label>
                <money
                  type="text"
                  placeholder="Saldo do Processo"
                  name="processTotalBalance"
                  class="form-control"
                  v-model="invoice.processTotalBalance"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('processTotalBalance')"
                  class="parsley-errors-list filled"
                >
                  Desconto</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>

<script>
import config from "@/config.js";
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {Money} from "v-money";
import InvoiceItem from "@/models/InvoiceItem.js";

export default {
  name: "invoice-form-general",
  props: ["invoice", , "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
    DatePicker,
    Money,
  },
  data() {
    return {
      bankAccounts: [],
      accounts: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      chartOfAccounts: [],
      companies: [],
      invoiceID: "00001",
      invoiceType: "",
      importProcess: null,
      importProcesses: [],
      exportProcess: null,
      exportProcesses: [],
      items: [],
      index: 0,
      appointmentTypes: [],
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) {
            this.addModel();
          } else {
            this.updateModel();
          }
        }
      });
    },

    addModel() {
      this.manageModel();

      Axios.post(`${config.env.API_DEFAULT.host}/invoices`, this.invoice)
        .then((response) => {
          if (response.status === 201) {
            this.invoice = response.data;
            this.$swal({
              title: `O Banco ${this.invoice.description} foi cadastrada com sucesso.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            this.$router.push({
              name: "billing-invoices-form",
              params: {
                type: "update",
                id: response.data.id,
              },
            });
          } else {
            this.$swal({
              title: `A empresa ${this.invoice.description} não foi cadastrada.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: `A empresa ${this.invoice.description} não foi cadastrada. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },

    updateModel() {
      this.manageModel();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/invoices/${this.invoice.id}`,
        this.invoice
      )
        .then((response) => {
          this.$swal(
            "Contas Bancárias",
            `A empresa ${this.invoice.description} foi atualizado com sucesso no banco de dados.`,
            "success"
          );
          this.invoice.bank = this.oldModel.bank;
          this.invoice.account = this.oldModel.account;
        })
        .catch((error) => {
          this.$swal(
            "Contas Bancárias",
            `Não foi possível atualizar a empresa ${this.invoice.description} no banco de dados.`,
            "error"
          );
        });
    },

    getBankModel() {
      if (this.invoice.account !== undefined && this.invoice.account !== null) {
        this.invoice.account = this.invoice.account._links.self.href;
      }
    },

    manageModel() {
      this.invoice.openingBalanceDate = this.$moment(
        this.invoice.openingBalanceDate
      ).format("Y-MM-DDTLTS");
      this.oldModel.bank = this.invoice.bank;
      this.oldModel.account = this.invoice.account;

      if (this.invoice.bank !== undefined && this.invoice.bank !== null) {
        this.invoice.bank = this.invoice.bank._links.self.href;
      }

      if (this.invoice.account !== undefined && this.invoice.account !== null) {
        this.invoice.account = this.v.account._links.self.href;
      }
    },

    getBanks(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/banks/search/findByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.banks = response.data._embedded.banks;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Bancos",
            `Não foi possível carregar a listagem de Bancos disponíveis: ${error}`,
            "error"
          );
        });
    },

    getAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/accounts/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.accounts = response.data._embedded.accounts;
          loading(false);
        })
        .catch((error) => {
          swal(
            `Não foi possível carregar a listagem de Filiais disponíveis: ${error}`,
            "error"
          );
        });
    },

    changeInvoiceType() {
      if (this.invoice.type === "IMPORT_PROCESS") {
      } else if (this.invoice.type === "EXPORT_PROCESS") {
      } else if (this.invoice.type === "SINGLE_INVOICE") {
      }
      this.invoiceType = this.invoice.type;
    },
    nextInvoiceNumber() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/billing/invoices/nextInvoiceNumber`
      )
        .then((response) => {
          this.$set(this.model, "id", response.data);
        })
        .catch((error) => {
          swal(
            this.modelTitle,
            `Não foi possível carregar a Filial da ${this.modelTitle}.`,
            "error"
          );
        });
    },

    getImportProcesses(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/search/findAllByIdentificationContainingIgnoreCase?identification=${search}`
      )
        .then((response) => {
          this.importProcesses = response.data._embedded.importProcesses;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Processos de Importação",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },

    getExportProcesses(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/exportProcesses/search/findAllByIdentificationContainingIgnoreCase?identification=${search}`
      )
        .then((response) => {
          this.exportProcesses = response.data._embedded.exportProcesses;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Processos de Exportação",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },

    getCompanies(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.companies = response.data._embedded.companies;
          loading(false);
        })
        .catch((error) => {
          swal("Empresas", `Ops, houve algum erro: ${error}`, "error");
        });
    },

    getBankAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/bankAccounts/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          this.bankAccounts = response.data._embedded.bankAccounts;
          loading(false);
        })
        .catch((error) => {
          swal("Contas Bancárias", `Ops, houve algum erro: ${error}`, "error");
        });
    },

    getAppointmentTypes(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/appointmentTypes/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          self.appointmentTypes = response.data._embedded.appointmentTypes;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Serviços de Fatura",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },

    getSingleInvoice() {
      if (
        this.invoiceType === "SINGLE_INVOICE" ||
        this.invoice.type === "SINGLE_INVOICE" ||
        this.invoice.type == null
      )
        return true;
    },

    addItem() {
      this.index += 1;
      const item = new InvoiceItem();
      item.index = this.index;

      try {
        if (this.items === null) {
          this.items = [item];
        } else {
          this.items.push(item);
        }
      } catch (e) {
        console.error(e);
      }
    },
    removeItem(invoice) {
      if (invoice.id !== null && invoice.id !== undefined) {
        swal({
          title: "Excluir Item",
          html: `Você realmente deseja excluir o item <b>${invoice.description}</b>?`,
          type: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Não, cancelar!",
        }).then((result) => {
          if (result.value) {
            Axios.delete(
              `${config.env.API_DEFAULT.host}/invoiceItems/${invoice.id}`
            )
              .then((response) => {
                swal({
                  position: "top-right",
                  type: "success",
                  title: "Item excluído com sucesso!",
                  showConfirmButton: false,
                  timer: 4000,
                });
              })
              .catch((error) => {
                swal(
                  this.modelTitle,
                  `Ops, houve algum erro: ${error}`,
                  "error"
                );
              });
          }
        });
      }
      this.index = 0;
      this.items.splice(invoice.index - 1, 1);
      this.items.map((item) => {
        this.index += 1;
        item.index = this.index;
      });
    },
    total() {
      let sum = 0;
      if (this.items !== undefined && this.items !== null) {
        return this.items.reduce((prev, item) => (sum += item.value), 0);
      }
      return sum;
    },
  },

  mounted() {
    this.nextInvoiceNumber();
  },
};
</script>
