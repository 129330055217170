<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M19.6779 7.32624L18.2722 5.92505L12.6992 11.4801L7.12628 5.92505L5.72058 7.32624L11.2935 12.8813L5.72058 18.4364L7.12628 19.8375L12.6992 14.2825L18.2722 19.8375L19.6779 18.4364L14.1049 12.8813L19.6779 7.32624Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'XIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
