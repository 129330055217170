<template>
  <div class="form-layout form-layout-2 pd-b-40">
    <div class="report-summary-header mg-t-0 mg-b-10">
      <div>
        <h4 class="tx-inverse mg-b-3">Integração Angeloni</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Importação de
          arquivos para gerar numerário.
        </p>
      </div>
      <div></div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-6">
        <h6 class="slim-pagetitle mg-b-10">
          Importação de Despesas p/ Numerário
        </h6>
        <div class="list-group">
          <div class="list-group-item" v-if="model !== undefined">
            <upload-area
              :uniqueID="'upload-area-angeloni-txt-importacao'"
              :url="'/processAppointment/angeloni/importar/IMPORT/' + model.id"
              class="pd-20"
              :loadData="null"
            >
              <div class="media">
                <div class="d-flex mg-r-10 wd-50">
                  <i class="fa fa-file-code-o tx-primary tx-40-force"></i>
                </div>
                <div class="media-body">
                  <h6 class="tx-inverse">
                    Arquivo TXT do Numerário / Despesa Final
                  </h6>
                  <p class="mg-b-0">
                    Recurso responsável pela Importação do arquivo TXT do
                    Numerário / Despesa Final recebidos de Despachantes
                    Aduaneiros. Após a importação do arquivo ser realizada, o
                    sistema automaticamente incluirá todos os itens que
                    estiverem cadastrados no arquivo.
                  </p>
                </div>
              </div>
            </upload-area>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <h6 class="slim-pagetitle mg-b-10">
          Inclusão de Despesas do Numerário no Arquivo
        </h6>
        <div class="list-group">
          <div class="list-group-item">
            <upload-area-download
              :uniqueID="'upload-area-angeloni-txt-transformacao'"
              :url="'/processAppointment/angeloni/importar/INSERT/' + model.id"
              class="pd-20"
              :loadData="null"
            >
              <div class="media">
                <div class="d-flex mg-r-10 wd-50">
                  <i class="fa fa-file-code-o tx-danger tx-40-force"></i>
                </div>
                <!-- d-flex -->
                <div class="media-body">
                  <h6 class="tx-inverse">
                    Transformação do Arquivo TXT do Numerário / Despesa Final
                  </h6>
                  <p class="mg-b-0">
                    Recurso responsável pela Importação do Arquivo TXT do
                    Numerário / Despesa Final recebidos de Despachantes
                    Aduaneiros. Após a importação do arquivo ser realizada um
                    novo arquivo com os itens digitamos manualmente no numerário
                    será disponibilizado para download nesta tela.
                  </p>
                </div>
              </div>
            </upload-area-download>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UploadArea from "@/components/utils/upload-area";
import UploadAreaDownload from "@/components/utils/upload-area-download";

export default {
  name: "operational-integration",

  components: {
    "upload-area": UploadArea,
    "upload-area-download": UploadAreaDownload,
  },
  data() {
    return {
      model: null,
      initialModel: null,
    };
  },

  methods: {},
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    loading() {
      return this.$store.state.loading.show;
    },
  },
  watch: {
    process() {
      this.initialModel = {
        ...this.process,
      };
      this.model = {
        ...this.process,
      };
    },
  },

  created() {
    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };
  },
  mounted() {
    this.showProcessList = true;
    this.showTab = true;
  },
};
</script>

<style>
</style>
