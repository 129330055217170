<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Informações Fiscais</label>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">Regime de Apuração</label>
            <div class="row pd-l-15">
              <label class="rdiobox rdiobox-warning mg-t-0-force pd-r-15">
                <input
                  id="taxRegime-LUCRO_PRESUMIDO"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="LUCRO_PRESUMIDO"
                  @change.prevent="changeTaxRegime('LUCRO_PRESUMIDO')"
                />
                <span> Lucro Presumido </span>
              </label>
              <label class="rdiobox rdiobox-primary mg-t-0-force pd-r-15">
                <input
                  id="taxRegime-LUCRO_REAL"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="LUCRO_REAL"
                  @change.prevent="changeTaxRegime('LUCRO_REAL')"
                />
                <span> Lucro Real </span>
              </label>
              <label class="rdiobox rdiobox-success mg-t-0-force">
                <input
                  id="taxRegime-SIMPLES"
                  name="taxRegime"
                  type="radio"
                  v-model="company.taxRegime"
                  value="SIMPLES"
                  @change.prevent="changeTaxRegime('SIMPLES')"
                />
                <span> Simples </span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Inscrição Estadual?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="company.stateRegistrationExempt"
                    :value="false"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="company.stateRegistrationExempt"
                    :value="true"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Inscrição Estadual</label>
            <input
              class="form-control"
              type="text"
              name="stateRegistration"
              placeholder="Inscrição Estadual"
              v-model="company.stateRegistration"
            />
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Inscrição Municipal</label>
            <input
              class="form-control"
              type="text"
              name="municipalRegistration"
              placeholder="Inscrição Municipal"
              v-model="company.municipalRegistration"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Comissão (%)</label>
            <money
              v-model="company.comissionPercentual"
              v-bind="money"
              class="form-control"
              name="comissionPercentual"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Comissão Mínima?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="isMinimalComissionPercentual"
                    type="radio"
                    v-model="company.isMinimalComissionPercentual"
                    :value="true"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="isMinimalComissionPercentual"
                    type="radio"
                    v-model="company.isMinimalComissionPercentual"
                    :value="false"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Valor SDA</label>
            <money
              v-model="company.sdaValue"
              v-bind="money"
              class="form-control"
              name="sdaValue"
            ></money>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">TTD</label>
            <input
              class="form-control"
              type="text"
              name="ttdRegistry"
              placeholder="TTD"
              v-model="company.ttdRegistry"
            />
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Cadastro MAPA</label>
            <input
              class="form-control"
              type="text"
              name="mapaRegistry"
              placeholder="Cadastro MAPA"
              v-model="company.mapaRegistry"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Siscoserv</label>
            <div class="row">
              <div class="col-3">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    id="siscoserv-CLIENTE"
                    name="siscoserv"
                    type="radio"
                    v-model="company.siscoserv"
                    value="CLIENTE"
                  />
                  <span>Cliente</span>
                </label>
              </div>
              <div class="col-3">
                <label class="rdiobox rdiobox-primary mg-t-0-force">
                  <input
                    id="siscoserv-TRADING"
                    name="siscoserv"
                    type="radio"
                    v-model="company.siscoserv"
                    value="TRADING"
                  />
                  <span>Trading</span>
                </label>
              </div>
              <!-- <div class="col-lg-2">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">
                                <input id="siscoserv-AVISAR" name="siscoserv" type="radio" v-model="company.siscoserv" value="AVISAR" >
                                <span>Avisar</span>
                            </label>
                        </div> -->
              <div class="col-3">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    id="siscoserv-TERCEIROS"
                    name="siscoserv"
                    type="radio"
                    v-model="company.siscoserv"
                    value="TERCEIROS"
                  />
                  <span>Terceiros</span>
                </label>
              </div>
              <div class="col-3">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    id="siscoserv-NA"
                    name="siscoserv"
                    type="radio"
                    v-model="company.siscoserv"
                    value="NA"
                  />
                  <span>N/A</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Aviso de Siscoserv?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    id="aviso-siscoserv-sim"
                    name="siscoservAlert"
                    type="radio"
                    v-model="company.siscoservAlert"
                    value="true"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    id="aviso-siscoserv-nao"
                    name="siscoservAlert"
                    type="radio"
                    v-model="company.siscoservAlert"
                    value="false"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Faturar Siscoserv?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    id="siscoservBilled-sim"
                    name="siscoservBilled"
                    type="radio"
                    v-model="company.siscoservBilled"
                    value="true"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    id="siscoservBilled-nao"
                    name="siscoservBilled"
                    type="radio"
                    v-model="company.siscoservBilled"
                    value="false"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Venc Proc Siscoserv</label>
            <date-picker lang="pt-br"
                         v-model="company.siscoservAttorneyExpirationDate"
                         name="siscoservAttorneyExpirationDate"
                         class="form-control"
                         format="DD/MM/YY"
                         v-validate="'required'"
                         placeholder=""
            ></date-picker>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">ICMS Importação</label>
            <select
              class="custom-select form-control"
              v-model="company.icmsImport"
            >
              <option selected="">Selecione</option>
              <option
                v-for="icmsImport in icmsImports"
                :value="icmsImport.key"
                :key="icmsImport.key"
              >
                {{ icmsImport.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">ICMS Sub. Tributária</label>
            <select
              class="custom-select form-control"
              v-model="company.icmsSubstitutionTributary"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="icmsSubstitutionTributary in icmsSubstitutionTributaries"
                :value="icmsSubstitutionTributary.key"
                :key="icmsSubstitutionTributary.key"
              >
                {{ icmsSubstitutionTributary.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Alíquota ICMS</label>
            <money
              v-model="company.icmsAliquot"
              v-bind="percent"
              class="form-control"
              name="icmsAliquot"
            ></money>
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-t-20-force">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i> SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-secondary btn-block mg-l-10-force" type="button">
          <i class="fa fa-reply mg-r-5"></i> VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import {Money} from "v-money";
import DatePicker from "vue2-datepicker";
import {EventBus} from "@/events/EventBus.js";

export default {
  name: "companies-form-fiscal",
  props: ["company", "oldModel", "isNewRecord"],
  components: {
    Money,
    DatePicker,
  },
  data() {
    return {
      icmsImports: [
        {
          key: "TRADING",
          value: "Trading",
        },
        {
          key: "TTD_COMERCIALIZACAO",
          value: "TTD Comercialização",
        },
        {
          key: "TTD_MATERIA_PRIMA",
          value: "TTD Matéria Prima",
        },
      ],
      icmsSubstitutionTributaries: [
        {
          key: "NORMAL",
          value: "Normal",
        },
        {
          key: "TTD_NAO_INCIDENCIA",
          value: "TTD Não Incidência",
        },
        {
          key: "NAO_APLICAVEL",
          value: "Não Aplicável",
        },
      ],
      proceduresFreightQuotations: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime s/ Aprovação",
          value: "Prime s/ Aprovação",
        },
        {
          key: "INOVA c/ aprovação",
          value: "INOVA c/ aprovação",
        },
      ],
      proceduresFreightAgents: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova c/ Aprovação",
          value: "Inova c/ Aprovação",
        },
      ],
      proceduresInsuranceQuotations: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
        {
          key: "Sem Seguro",
          value: "Sem Seguro",
        },
      ],
      appointmentTributes: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Débito Cliente",
          value: "Débito Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
      ],
      genericalList: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
      ],
      appointmentFees: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Encerramento",
          value: "Encerramento",
        },
        {
          key: "Numerário",
          value: "Numerário",
        },
      ],
      cargoConveyors: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime c/ Aprovação",
          value: "Prime c/ Aprovação",
        },
        {
          key: "Prime s/ Aprovação",
          value: "Prime s/ Aprovação",
        },
        {
          key: "Inova c/ Aprovação",
          value: "Inova c/ Aprovação",
        },
        {
          key: "Inova s/ Aprovação",
          value: "Inova s/ Aprovação",
        },
      ],
      diICMSs: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Via Prime",
          value: "Via Prime",
        },
        {
          key: "TTD c/ Recolhimento",
          value: "TTD c/ Recolhimento",
        },
        {
          key: "TTD s/ Recolhimento",
          value: "TTD s/ Recolhimento",
        },
        {
          key: "Integral",
          value: "Integral",
        },
      ],
      diDebits: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Numerário",
          value: "Numerário",
        },
      ],
      billingSends: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Email",
          value: "Email",
        },
        {
          key: "Físico",
          value: "Físico",
        },
        {
          key: "Email + Físico",
          value: "Email + Físico",
        },
      ],
      exporterResponsibles: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Responsabilidade do Cliente",
          value: "Responsabilidade do Cliente",
        },
        {
          key: "Responsabilidade da Prime",
          value: "Responsabilidade da Prime",
        },
        {
          key: "Responsabilidade da Inova",
          value: "Responsabilidade da Inova",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      url: `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContaining?name=`,
      optionsCompanies: [],
    };
  },
  methods: {
    onSubmit() {
      EventBus.$emit("companies.Form.onSubmit");
    },
    changeTaxRegime(type) {
      if (type === "SIMPLES") {
        this.company.siscoserv = "NA";
        EventBus.$emit("companies.Form.onSubmit");
      }
    },

    getCompanies(search) {
      const self = this;

      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          self.optionsCompanies = response.data._embedded.companies;
        })
        .catch((error) => {
          swal(
            "Cadastro de Empresa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
};
</script>
