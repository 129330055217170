<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22.8682 24.2982C25.4105 26.7935 26.4138 30.4526 25.4971 33.8863C24.5805 37.32 21.8844 40.0019 18.4325 40.9137C14.9806 41.8256 11.3022 40.8276 8.79375 38.2986C5.02208 34.4141 5.07602 28.2394 8.91499 24.4206C12.754 20.6019 18.9613 20.5482 22.8664 24.3L22.8682 24.2982Z"
        fill="none" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    <path d="M23 24L40 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.3052 16.9001L35.7337 22.3001L42.0671 16.0001L36.6385 10.6001L30.3052 16.9001Z" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ConfigKeyIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
