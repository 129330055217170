var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("filter",null,{"data":{
      week: _vm.week,
      year: _vm.year,
      month: _vm.month,
      months: _vm.months,
      nextMonth: _vm.nextMonth,
      prevMonth: _vm.prevMonth,
      nextWeek: _vm.nextWeek,
      prevWeek: _vm.prevWeek,
      calendarItems: _vm.calendarItems,
      weekData: _vm.weekData,
      newMonth: _vm.newMonth,
      newWeek: _vm.newWeek,
      dateSelectCalendar: _vm.dateSelectCalendar,
      dateSelectCalendarWeek: _vm.dateSelectCalendarWeek,
      clear: _vm.clear,
    }}),(!_vm.hideCalendar)?_c('div',{staticClass:"container mt-3"},[_c('div',{staticClass:"light-bg p-3 relative"},[_vm._t("header",null,{"data":{
          week: _vm.week,
          year: _vm.year,
          month: _vm.month,
          months: _vm.months,
          nextMonth: _vm.nextMonth,
          prevMonth: _vm.prevMonth,
          nextWeek: _vm.nextWeek,
          prevWeek: _vm.prevWeek,
          calendarItems: _vm.calendarItems,
          weekData: _vm.weekData,
          newMonth: _vm.newMonth,
          newWeek: _vm.newWeek,
          dateSelectCalendar: _vm.dateSelectCalendar,
          dateSelectCalendarWeek: _vm.dateSelectCalendarWeek,
          clear: _vm.clear,
        }})],2)]):_vm._e(),_c('div',{staticClass:"container mt-3 height-calc"},[_c('div',{staticClass:"light-bg p-3 bd-b relative"},[(_vm.hideCalendar)?_c('div',{staticClass:"calendar-overlay-no-content"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"flex mb-3 jc-sb"},[_c('div',{staticClass:"flex ai-c fgap1 jc-l mb-0"},[(_vm.resultsCount > 0)?_c('div',{staticClass:"text-sm text-center mr-2"},[_c('p',{staticClass:"text-thin",staticStyle:{"margin-bottom":"unset"}},[_vm._v(" Estão sendo exibidos "),_c('span',{staticClass:"text-medium"},[_vm._v(" "+_vm._s(_vm.resultsCount)+" lançamentos ")]),_vm._v(" com a previsão total de "),_c('span',{staticClass:"text-medium"},[_vm._v(_vm._s(_vm.resultsTotal))])])]):_vm._e()]),_vm._m(1)]),_c('div',{staticClass:"calendar-body"},[_vm._m(2),_c('ul',{staticClass:"calendar-dates relative flex fw-w"},[_vm._l((_vm.calendarItems),function(item,index){return _c('NewGsCalendarItem',{key:index,attrs:{"day":item.day,"active":item.active,"other-month":item.otherMonth,"past":item.past,"future":item.future,"long":_vm.week,"overview":!!item.overview,"content":item.content}},[(_vm.$scopedSlots.item && item.content && !item.overview)?_vm._t("item",null,null,item):_vm._e(),(_vm.$scopedSlots.item && !!item.overview)?_vm._t("week",null,null,item):_vm._e()],2)}),(_vm.week)?_vm._l((_vm.calendarItems),function(item,index){return _c('div',{key:(index + 1) * 10,staticClass:"calendar-item-footer"},[_vm._t("itemFooter",null,null,item)],2)}):_vm._e(),_vm._t("overlay")],2)]),_vm._t("footer",null,{"data":{
          week: _vm.week,
          year: _vm.year,
          month: _vm.month,
          months: _vm.months,
          nextMonth: _vm.nextMonth,
          prevMonth: _vm.prevMonth,
          nextWeek: _vm.nextWeek,
          prevWeek: _vm.prevWeek,
          calendarItems: _vm.calendarItems,
          weekData: _vm.weekData
        }})],2)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body pd-40 sp-3 text-center"},[_c('img',{staticClass:"wd-100 mb-4",attrs:{"src":require("@/assets/img/icon1.svg"),"alt":""}}),_c('h6',{staticClass:"tx-md-20 tx-inverse"},[_vm._v("Nenhum Cliente Selecionado")]),_c('p',[_vm._v(" Selecione um cliente no menu acima para visualizar o calendário financeiro. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex ai-c fgap2 fw-w calendar-legends"},[_c('div',{staticClass:"flex ai-c gap1 jc-c"},[_c('div',{staticClass:"legend-square",staticStyle:{"--bg":"#137700","--border":"#137700"}}),_c('span',[_vm._v("Numerário")])]),_c('div',{staticClass:"flex ai-c fgap1"},[_c('div',{staticClass:"legend-square",staticStyle:{"--bg":"#ffffff","--border":"#137700"}}),_c('span',[_vm._v("Numerário Previsto")])]),_c('div',{staticClass:"flex ai-c fgap1 jc-ge"},[_c('div',{staticClass:"legend-square",staticStyle:{"--bg":"#104375","--border":"#104375"}}),_c('span',[_vm._v("Parcela de Invoice")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"calendar-weekdays flex ai-c"},[_c('li',{staticClass:"text-center"},[_vm._v("Domingo")]),_c('li',{staticClass:"text-center"},[_vm._v("Segunda")]),_c('li',{staticClass:"text-center"},[_vm._v("Terça")]),_c('li',{staticClass:"text-center"},[_vm._v("Quarta")]),_c('li',{staticClass:"text-center"},[_vm._v("Quinta")]),_c('li',{staticClass:"text-center"},[_vm._v("Sexta")]),_c('li',{staticClass:"text-center"},[_vm._v("Sábado")]),_c('li',{staticClass:"text-bold text-center text-accent"},[_vm._v("Previsão Semanal")])])
}]

export { render, staticRenderFns }