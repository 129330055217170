export default class Moviment {
  constructor(
    id,
    originalCreatedDate,
    canceled,
    userCancelling,
    cancellingDate,
    typeOfMovimentation,
    enabledMovimentCheckingAccountOfProcess,
    movimentOfProcessClassification,
    groupedItens,
    showIntoProcess,
    typeOfProcess,
    process,
    chartOfAccount,
    appointments,
    receipts,
    quantityOfRepetitions,
    description,
    account,
    movimentDate,
    grossValue,
    irAliquot,
    pccAliquot,
    netValue,
    company,
    typeOfDocument,
    typeOfDocumentValue,
    documentDate,
    typeOfCostCenter,
    observations,
  ) {
    this.id = id;
    this.originalCreatedDate = originalCreatedDate;
    this.canceled = canceled;
    this.userCancelling = userCancelling;
    this.cancellingDate = cancellingDate;
    this.typeOfMovimentation = typeOfMovimentation;
    this.enabledMovimentCheckingAccountOfProcess = enabledMovimentCheckingAccountOfProcess;
    this.movimentOfProcessClassification = movimentOfProcessClassification;
    this.groupedItens = groupedItens;
    this.showIntoProcess = showIntoProcess;
    this.typeOfProcess = typeOfProcess;
    this.process = process;
    this.chartOfAccount = chartOfAccount;
    this.appointments = appointments;
    this.receipts = receipts;
    this.quantityOfRepetitions = quantityOfRepetitions;
    this.description = description;
    this.account = account;
    this.movimentDate = movimentDate;
    this.grossValue = grossValue;
    this.irAliquot = irAliquot;
    this.pccAliquot = pccAliquot;
    this.netValue = netValue;
    this.company = company;
    this.typeOfDocument = typeOfDocument;
    this.typeOfDocumentValue = typeOfDocumentValue;
    this.documentDate = documentDate;
    this.typeOfCostCenter = typeOfCostCenter;
    this.observations = observations;
  }
}
