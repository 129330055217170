<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configuracões</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configuracões de Acesso</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">Listagem de {{ modelTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
              <p class="mg-b-0">
                Foram encontrados {{ data.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas.
              </p>
            </div>
            <div>
              <router-link
                :to="{ name: 'users-form', params: { action: 'create' } }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <v-client-table :columns="columns" :data="data" :options="options">
          <template slot="followUP" slot-scope="props">
            <div class="enabled">
              <div class="tx-center">
                <span
                  v-if="props.row.followUPAnalytical"
                  class="badge badge-success pd-x-10 pd-y-5 tx-light"
                  >Analítico</span
                >
                <span
                  v-if="props.row.followUPSynthetic"
                  class="badge badge-info mg-x-10 pd-x-10 pd-y-5 tx-light"
                  >Sintético</span
                >
                <span
                  v-if="!props.row.followUP"
                  class="badge badge-danger pd-x-10 pd-y-5 tx-light"
                  >Não</span
                >
              </div>
            </div>
          </template>

          <template slot="role" slot-scope="props">
            <div class="enabled">
              <div class="tx-center">
                <span
                  v-if="props.row.role === 'ADMINISTRATOR'"
                  class="badge badge-primary pd-x-10 pd-y-5 tx-light"
                >
                  ADMINISTRADOR
                </span>
                <span
                  v-else-if="props.row.role === 'CUSTOMER'"
                  class="badge badge-warning pd-x-10 pd-y-5 tx-light"
                >
                  CLIENTE
                </span>
                <span
                  v-else-if="props.row.role === 'MANAGER'"
                  class="badge badge-indigo pd-x-10 pd-y-5 tx-light"
                >
                  GERENTE
                </span>
                <span
                  v-else-if="props.row.role === 'OPERATIONAL'"
                  class="badge badge-indigo pd-x-10 pd-y-5 tx-light"
                >
                  OPERACIONAL
                </span>
                <span
                  v-else-if="props.row.role === 'PARTNER'"
                  class="badge badge-teal pd-x-10 pd-y-5 tx-light"
                >
                  PARCEIRO
                </span>
                <span
                  v-else-if="props.row.role === 'CUSTOM_BROKER'"
                  class="badge badge-orange pd-x-10 pd-y-5 tx-light"
                >
                  DESPACHANTE
                </span>
              </div>
            </div>
          </template>

          <template slot="enabled" slot-scope="props">
            <div class="enabled">
              <span
                v-if="props.row.enabled"
                class="badge badge-success pd-x-10 pd-y-5"
                >SIM</span
              >
              <span
                v-if="!props.row.enabled"
                class="badge badge-danger pd-x-10 pd-y-5"
                >NÃO</span
              >
            </div>
          </template>

          <template slot="employee" slot-scope="props">
            <div class="enabled">
              <span
                v-if="props.row.employee"
                class="badge badge-success pd-x-10 pd-y-5"
                >SIM</span
              >
              <span
                v-if="!props.row.employee"
                class="badge badge-danger pd-x-10 pd-y-5"
                >NÃO</span
              >
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'users-form',
                params: { action: 'update', id: props.row.id },
              }"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div>
                <i class="icon ion-edit"></i>
              </div>
            </router-link>
            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div>
                <i class="icon ion-trash-a"></i>
              </div>
            </a>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import * as constants from "@/consts.js";
import config from "@/config.js";
import Axios from "axios";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";

export default {
  name: "configuration-accounts-list",

  data() {
    return {
      modelTitle: "Usuários de Acesso",
      mainTitle: "Usuários de Acesso",
      columns: [
        "enabled",
        "employee",
        "name",
        "email1",
        "followUP",
        "role",
        "actions",
      ],
      data: new Array(),
      options: {
        listColumns: {
          enabled: [
            {
              id: true,
              text: "Sim",
            },
            {
              id: false,
              text: "Não",
            },
            {
              id: "",
              text: "Todos",
            },
          ],
          employee: [
            {
              id: true,
              text: "Sim",
            },
            {
              id: false,
              text: "Não",
            },
            {
              id: "",
              text: "Todos",
            },
          ],
        },
        headings: {
          enabled: "Habilitado",
          employee: " Colaborador ",
          name: "Nome",
          email1: "Email",
          followUP: "Follow-UP",
          followUPAnalytical: "Analítico",
          followUPSynthetic: "Sintético",
          role: "Papel",
          action: "Ações",
        },
        sortable: [
          "enabled",
          "employee",
          "name",
          "email1",
          "telephone1",
          "followUP",
          "followUPAnalytical",
          "followUPSynthetic",
          "role",
        ],
        filterable: [
          "enabled",
          "employee",
          "name",
          "email1",
          "followUP",
          "followUPAnalytical",
          "followUPSynthetic",
          "role",
        ],

        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "{column}",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 25,
        perPageValues: [10, 25, 50, 100, 500],
      },
    };
  },
  methods: {
    loadList() {
      Axios.get(`${config.env.API_DEFAULT.host}/users`)
        .then((response) => {
          this.data = response.data._embedded.users;
        })
        .catch((error) => {
          this.$swal(
            this.modelTitle,
            " Não foi possível carregar listagem.",
            "error"
          );
        });
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    deleteModel(row) {
      this.$swal({
        title: "Você tem certeza?",
        text: `Se você excluir a ${this.modelTitle}, não será possível recuperá-la para uso posterior.`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          Axios.delete(`${config.env.API_DEFAULT.host}/users/${row.id}`)
            .then((response) => {
              this.$swal(
                this.modelTitle,
                `${this.modelTitle} foi removida c/ sucesso!`,
                "success"
              );
              this.loadList();
            })
            .catch((error) => {
              if (error.response.status === 409)
                this.$swal(
                  this.modelTitle,
                  `Não foi possível excluir a ${this.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                  "error"
                );
              else this.$swal(this.modelTitle, error.message, "error");
            });
        }
      });
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>
