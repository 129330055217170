<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Geral</a></li>
          <li class="breadcrumb-item">
            <a href="#">Cadastros Administrativos</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0 mg-l-100">
            <div>
              <h4 class="tx-inverse mg-b-3">{{ mainTitle }}</h4>
              <p class="mg-b-0">
                Foram encontrados {{ data.length }} registros de
                <b>{{ modelTitle }}</b> cadastradas
              </p>
            </div>
            <div>
              <router-link
                :to="{ name: 'companies-form', params: { action: 'create' } }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
              <!-- <button class="btn btn-teal" type="button" @click.prevent="$store.dispatch('IntegrationStore/synchronizeClientsAberta');"> <i class="fa fa-refresh mg-r-5"></i> SINCRONIZAR </button> -->
            </div>
          </div>
        </div>

        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          id="table"
        >
          <template slot="name" slot-scope="props">
            {{ props.row.name }}
          </template>
          <template slot="completeName" slot-scope="props">
            {{ props.row.completeName }}
          </template>
          <template slot="enabled" slot-scope="props">
            <div class="enabled">
              <span v-if="props.row.enabled" class="badge badge-success">
                SIM
              </span>
              <span v-if="!props.row.enabled" class="badge badge-danger">
                NÃO
              </span>
            </div>
          </template>

          <template slot="relationship" slot-scope="props">
            <span v-if="props.row.customer" class="badge badge-success mg-l-3">
              C
            </span>
            <span v-if="props.row.provider" class="badge badge-primary mg-l-3">
              F
            </span>
            <span v-if="props.row.exporter" class="badge badge-warning mg-l-3">
              E
            </span>
            <span
              v-if="props.row.customBroker"
              class="badge badge-danger mg-l-3"
            >
              D
            </span>
            <span
              v-if="props.row.customsEnclosure"
              class="badge badge-info mg-l-3"
            >
              R
            </span>
            <span v-if="props.row.conveyor" class="badge badge-teal mg-l-3">
              T
            </span>
            <span v-if="props.row.forwarder" class="badge badge-indigo mg-l-3">
              FF
            </span>
            <span v-if="props.row.shipowner" class="badge badge-pink mg-l-3">
              A
            </span>
          </template>

          <template slot="city" slot-scope="props">
            <template v-if="props.row.city != null">
              {{ props.row.city }} / {{ props.row.federalUnity }}
            </template>
          </template>

          <template slot="actions" slot-scope="props">
            <router-link
              :to="{
                name: 'companies-form',
                params: { action: 'update', id: props.row.id },
              }"
              class="btn btn-primary btn-icon rounded-circle mg-r-5"
            >
              <div><i class="icon ion-edit"></i></div>
            </router-link>

            <a
              href="#"
              @click="deleteModel(props.row)"
              class="btn btn-danger btn-icon rounded-circle"
            >
              <div><i class="icon ion-trash-a"></i></div>
            </a>
          </template>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

export default {
  name: "companies-list",
  components: {},
  data() {
    return {
      modelTitle: "Empresas",
      mainTitle: "Listagem de Empresas",
      columns: [
        "enabled",
        "name",
        "completeName",
        "federalID",
        "telephone1",
        "email",
        "city",
        "relationship",
        "actions",
      ],
      data: new Array(),
      options: {
        headings: {
          enabled: "Habilitado",
          name: "Nome",
          completeName: "Razão Social",
          federalID: "CNPJ",
          city: "Cidade",
          telephone1: "Telefone",
          email: "Email",
          relationship: "Relacionamento",
          actions: "",
        },
        pagination: {
          chunk: 20,
          edge: true,
          align: "center",
          nav: "fixed",
        },
        highlightMatches: true,
        texts: {
          count:
            "Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado",
          filter: "Filtrar resultados:",
          first: "Primeira",
          last: "Última",
          filterPlaceholder: "Buscar",
          limit: "Exibir: ",
          page: "Página:",
          noResults: "Nenhum resultado foi encontrado.",
          filterBy: "Filtrar por {column}",
          loading: "Carregando...",
          defaultOption: "Selecionar {column}",
          columns: "Colunas",
        },
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        sortable: [
          "name",
          "federalID",
          "completeName",
          "relationship",
          "telephone1",
          "email",
          "city",
        ],
        filterable: [
          "name",
          "federalID",
          "completeName",
          "relationship",
          "telephone1",
          "email",
          "city",
        ],
        filterByColumn: true,
        footerHeadings: false,
        perPage: 100,
        perPageValues: [10, 25, 50, 100, 500],
      },
    };
  },
  methods: {
    loadList() {
      const self = this;

      this.$store.commit("setLoading", {
        show: true,
        label: this.mainTitle,
        description: `Carregando ${this.mainTitle}`,
      });

      let url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`;

      if (self.$route.params.type === "isProvider") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsProvider?isProvider=true`;
      } else if (self.$route.params.type === "isExporter") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsExporter?isExporter=true`;
      } else if (self.$route.params.type === "isManufacturer") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsManufacturer?isManufacturer=true`;
      } else if (self.$route.params.type === "isCustomBroker") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomBroker?isCustomBroker=true`;
      } else if (self.$route.params.type === "isCustomsEnclosure") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomsEnclosure?isCustomsEnclosure=true`;
      } else if (self.$route.params.type === "isConveyor") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsConveyor?isConveyor=true`;
      } else if (self.$route.params.type === "isShipowner") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsShipowner?isShipowner=true`;
      } else if (self.$route.params.type === "isForwarder") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsForwarder?isForwarder=true`;
      } else if (self.$route.params.type === "isAcquirer") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsAcquirer?isAcquirer=true`;
      } else if (self.$route.params.type === "isImporter") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsImporter?isImporter=true`;
      } else if (self.$route.params.type === "isSeller") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsSeller?isSeller=true`;
      } else if (self.$route.params.type === "isGeneralWarehouse") {
        url = `${config.env.API_DEFAULT.host}/companies/search/findAllByIsGeneralWarehouse?isGeneralWarehouse=true`;
      }

      Axios.get(url)
        .then((response) => {
          self.data = response.data._embedded.companies;
          this.$store.commit("setLoading", {show: false});
        })
        .catch((error) => {
          this.$store.commit("setLoading", {show: false});
          swal(
            self.modelTitle,
            " Não foi possível carregar listagem.",
            "error"
          );
        });
    },
    edit() {
      this.$router.push(`/${response.data.id}`);
    },
    deleteModel(row) {
      const self = this;

      self
        .$swal({
          title: "Você tem certeza?",
          text: `Se você excluir a ${self.modelTitle}, não será possível recuperá-la para uso posterior.`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        })
        .then((willContinue) => {
          if (willContinue) {
            Axios.delete(`${config.env.API_DEFAULT.host}/companies/${row.id}`)
              .then((response) => {
                self.$swal(
                  self.modelTitle,
                  `${row.name} foi removida c/ sucesso!`,
                  "success"
                );
                self.loadList();
              })
              .catch((error) => {
                this.$store.commit("setLoading", {show: false});
                if (error.response.status === 409) {
                  self.$swal(
                    self.modelTitle,
                    `Não foi possível excluir a ${self.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                    "error"
                  );
                } else {
                  self.$swal(self.modelTitle, error.message, "error");
                }
              });
          }
        });
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>
