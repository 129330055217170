<template>
  <svg
    :width="`${size}px`"
    :height="`${size}px`"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 4.16667L12.5 7.5H15C15 10.2583 12.7583 12.5 9.99999 12.5C9.15833 12.5 8.35833 12.2917 7.66666 11.9167L6.44999 13.1333C7.47499 13.7833 8.69166 14.1667 9.99999 14.1667C13.6833 14.1667 16.6667 11.1833 16.6667 7.5H19.1667L15.8333 4.16667ZM4.99999 7.5C4.99999 4.74167 7.24166 2.5 9.99999 2.5C10.8417 2.5 11.6417 2.70833 12.3333 3.08333L13.55 1.86667C12.525 1.21667 11.3083 0.833334 9.99999 0.833334C6.31666 0.833334 3.33333 3.81667 3.33333 7.5H0.833328L4.16666 10.8333L7.49999 7.5H4.99999Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'RefreshIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: '#6E706D',
    },
  },
};
</script>

<style></style>
