<template >
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais do Time</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Habilitado <span class="tx-danger">*</span></label
            >
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="model.enabled"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="model.enabled"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Não</span>
                </label>
              </div>

              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
                >Selecione</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Descrição <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="name"
              placeholder="Descrição"
              v-model="model.name"
              v-validate="'required'"
            />
            <span v-show="errors.has('name')" class="parsley-errors-list filled"
              >Digite a Descrição</span
            >
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Email <span class="tx-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="email"
              placeholder="Email"
              v-model="model.email"
              v-validate="'required|email'"
            />
            <span
              v-show="errors.has('email')"
              class="parsley-errors-list filled"
              >Digite o Email</span
            >
          </div>
        </div>
      </div>

      <div class="row mg-t-20-force">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
            <i class="fa fa-floppy-o mg-r-5"></i> SALVAR
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-secondary btn-block mg-l-10-force"
            type="button"
            @click.prevent="redirect"
          >
            <i class="fa fa-reply mg-r-5"></i> VOLTAR
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

import Swal from "sweetalert2";

export default {
  name: "configuration-teams-form-general",
  props: ["model", "isNewRecord"],
  components: {},
  data() {
    return {};
  },
  methods: {
    redirect() {
      this.$router.push({ name: "teams-list" });
    },
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) this.addModel();
          else this.updateModel();
        }
      });
    },
    addModel() {
      Axios.post(`${config.env.API_DEFAULT.host}/teams`, this.model)
        .then((response) => {
          this.$router.push({
            name: "teams-form",
            params: { action: "update", id: response.data.id },
          });
          Swal.fire(
            "Times Operacionais",
            `O Time Operacional ${this.model.name} foi adicionado com sucesso.`,
            "success"
          );
        })
        .catch((error) => {
          Swal.fire(
            "Times Operacionais",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    updateModel() {
      Axios.patch(this.model._links.self.href, this.model)
        .then((response) => {
          Swal.fire(
            "Times Operacionais",
            `O Time Operacional ${this.model.name} foi atualizado com sucesso.`,
            "success"
          );
        })
        .catch((error) => {
          Swal.fire(
            "Times Operacionais",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {},
};
</script>
