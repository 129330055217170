<template>
  <div>
    <div class="report-summary-header mg-t-0 mg-b-5">
      <div>
        <h4 class="tx-inverse mg-b-3">Fornecedores</h4>
        <p class="mg-b-0">
          <i class="fa fa-users mg-r-3"></i> Foram encontrados
          {{ filteredProviders.length }} fornecedores.
        </p>
      </div>
      <div>
        <input
          type="text"
          v-model="search"
          class="form-control mg-r-20"
          placeholder="Buscar Fornecedores..."
        />
        <router-link
          :to="{ name: 'companies-form', params: { action: 'create' } }"
          class="btn btn-primary"
          target="_blank"
        >
          <i class="icon ion-plus tx-22"></i> ADICIONAR
        </router-link>
      </div>
    </div>

    <div v-if="providersToAdd !== undefined && providersToAdd.length > 0">
      <div class="bd-l bd-3 bd-danger bg-gray-200 pd-x-20 pd-y-25">
        <h5 class="tx-danger">
          Você gostaria de adicionar um dos fornecedores abaixo?
        </h5>
        <p class="mg-b-0">
          Nenhum contato com que contenha <strong>{{ search }}</strong> no nome
          está associado a {{ company.name }}.
        </p>
      </div>

      <table class="table mg-b-0 tx-13">
        <thead>
          <tr class="tx-10">
            <td class="tx-center">Habilitado</td>
            <td class="tx-center">Nome</td>
            <td class="tx-center">CNPJ</td>
            <td class="pd-y-5 tx-center">Telefone</td>
            <td class="pd-y-5 tx-center">Celular</td>
            <td class="pd-y-5 tx-center">E-mail</td>
            <td class="pd-y-5 tx-center">Cidade</td>
            <th class="pd-y-5">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="provider in providersToAdd" :key="provider.id">
            <td class="tx-center">
              <span v-if="provider.enabled" class="badge badge-success">
                SIM
              </span>
              <span v-if="!provider.enabled" class="badge badge-danger">
                NÃO
              </span>
            </td>

            <td class="tx-left">
              <a href="" class="tx-inverse tx-medium d-block"
                >{{ provider.name }} <br />
                <small>{{ provider.completeName }}</small>
              </a>
            </td>

            <td class="tx-center">{{ provider.federalID }}</td>

            <td class="tx-center">
              {{ provider.telephone1 }}
            </td>
            <td class="tx-center">
              {{ provider.telephone2 }}
            </td>
            <td class="tx-center">
              {{ provider.email }}
            </td>
            <td class="tx-center">
              <template v-if="provider.city != null">
                {{ provider.city }} / {{ provider.federalUnity }}
              </template>
            </td>
            <td>
              <a
                href="#"
                @click="add(provider)"
                class="btn btn-success btn-icon rounded-circle mg-r-5"
              >
                <div class="tx-20">
                  <i class="icon ion-person-add tx-20"></i>
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card card-table mg-t-0" v-if="providersToAdd.length === 0">
      <div class="table-responsive">
        <table class="table mg-b-0 tx-13">
          <thead>
            <tr>
              <th class="tx-center">Habilitado</th>
              <th class="tx-center">Nome</th>
              <th class="tx-center">CNPJ</th>
              <th class="pd-y-5 tx-center">Telefone</th>
              <th class="pd-y-5 tx-center">Celular</th>
              <th class="pd-y-5 tx-center">E-mail</th>
              <th class="pd-y-5 tx-center">Cidade</th>
              <th class="pd-y-5 tx-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                filteredProviders != undefined &&
                filteredProviders.length === 0 &&
                providersToAdd.length === 0
              "
            >
              <td class="wd-10p pd-y-5 tx-center" colspan="9">
                <div class="pd-20">
                  Nenhum fornecedor associado a empresa. <a></a>
                </div>
              </td>
            </tr>

            <tr v-for="provider in filteredProviders" :key="provider.oldID">
              <td class="tx-center">
                <span v-if="provider.enabled" class="badge badge-success">
                  SIM
                </span>
                <span v-if="!provider.enabled" class="badge badge-danger">
                  NÃO
                </span>
              </td>

              <td class="tx-left">
                <a href="" class="tx-inverse tx-medium d-block"
                  >{{ provider.name }} <br />
                  <small>{{ provider.completeName }}</small>
                </a>
              </td>

              <td class="tx-center">{{ provider.federalID }}</td>

              <td class="tx-center">
                {{ provider.telephone1 }}
              </td>

              <td class="tx-center">
                {{ provider.telephone2 }}
              </td>

              <td class="tx-center">
                {{ provider.email }}
              </td>

              <td class="tx-center">
                <template v-if="provider.city != null">
                  {{ provider.city }} / {{ provider.federalUnity }}
                </template>
              </td>

              <td class="tx-center">
                <router-link
                  :to="{
                    name: 'companies-form',
                    params: { action: 'update', id: provider.id },
                  }"
                  class="btn btn-primary btn-icon rounded-circle mg-r-5"
                >
                  <div><i class="icon ion-edit"></i></div>
                </router-link>
                <a
                  href="#"
                  @click="deleteModel(provider)"
                  class="btn btn-danger btn-icon rounded-circle"
                >
                  <div><i class="icon ion-trash-a tx-20"></i></div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { EventBus } from "@/events/EventBus.js";
import Swal from "sweetalert2";

export default {
  name: "FormProviders",
  props: ["company", "providers"],
  components: {},
  data() {
    return {
      currentProvider: null,
      search: "",
      filteredProviders: [],
      providersToAdd: [],
    };
  },
  methods: {
    isNewRecord() {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return true;
      }
      return false;
    },
    deleteModel(model) {
      const description = model.name;

      this.$swal({
        title: "Excluir Fornecedor?",
        text: `Se você excluir o fornecedor '${description}', não será possível recuperá-lo para uso posterior. Você realmente tem certeza disso?`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          Axios.get(
            `${config.env.API_DEFAULT.host}/customers/${this.company.id}/remove/provider/${model.id}`
          )
            .then((response) => {
              EventBus.$emit("companies.Form.loadModel");
              Swal.fire(
                "Cadastro excluído!",
                `O fornecedor ${description} foi excluída com sucesso!`,
                "success"
              );
            })
            .catch((error) => {
              Swal.fire(
                "Cadastro não excluído!",
                `Ops, houve um erro! \n\n${error}`,
                "error"
              );
            });
        }
      });
    },
    add(model) {
      const description = model.name;

      this.$swal({
        title: "Adicionar Fornecedor?",
        text: `Você realmente deseja associar o fornecedor '${description}' a empresa ${this.company.name}?`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          if (this.company !== null) {
            Axios.get(
              `${config.env.API_DEFAULT.host}/customers/${this.company.id}/add/provider/${model.id}`
            )
              .then((response) => {
                this.$emit("updateProviders", response.data.data);
                this.filteredProviders = response.data.data;

                this.search = "";
                this.providersToAdd = [];
                EventBus.$emit("companies.Form.loadModel");
                Swal.fire(
                  "Cadastro de Empresas",
                  `${description} foi associado a empresa com sucesso.`,
                  "success"
                );
              })
              .catch((error) => {
                Swal.fire(
                  "Cadastro de Empresas",
                  `Ops, houve algum erro ao tentar associar o contato: ${error}`,
                  "error"
                );
              });
          }
        }
      });
    },
    onHandleSearch(newValue) {
      this.filteredProviders = this.providers.filter((provider) =>
        provider.name.toLowerCase().includes(newValue.toLowerCase())
      );

      if (this.filteredProviders.length === 0 && String(newValue).length >= 3) {
        Axios.get(
          `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${newValue}`
        )
          .then((response) => {
            this.providersToAdd = response.data._embedded.companies;
          })
          .catch((error) => {
            swal(
              "Cadastro de Empresa",
              `Ops, houve algum erro: ${error}`,
              "error"
            );
          });
      } else {
        this.providersToAdd = [];
      }
    },
    onLoadData() {
      if (this.company._links !== undefined) {
        Axios.get(this.company._links.providers.href)
          .then((response) => {
            this.filteredProviders = response.data._embedded.companies;

            this.providers = response.data._embedded.companies;
            this.$emit("updateProviders", response.data._embedded.companies);
          })
          .catch((error) => {
            swal(
              "Buscar Fornecedores",
              `Ops, houve algum erro: ${error}`,
              "error"
            );
          });
      }
    },
  },
  mounted() {
    this.search = "";
    this.providersToAdd = [];
    this.filteredProviders = [];

    this.onHandleSearch("");
    this.onLoadData();
  },
  watch: {
    search(newValue) {
      this.onHandleSearch(newValue);
    },
    company() {
      this.onLoadData();
    },
  },
};
</script>
