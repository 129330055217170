<template>
  <transition name="vdrawer">
    <div v-if="open" class="vdrawer" @click.self="closeDrawer">
      <div class="vdrawer-wrapper" :style="`max-width: ${minWidth}px; width: 100%;`">
        <div class="vdrawer-header flex ai-c jc-sb ">
          <h5 class="mb-0 text-dark">
            <slot name="header">
              Título
            </slot>
          </h5>

          <button class="close-vdrawer" @click.prevent="closeDrawer">
            <x-mark color="#343a40" />
          </button>
        </div>

        <div class="vdrawer-content">
          <slot name="content" />
        </div>

        <div v-if="$scopedSlots.footer" class="vdrawer-footer">
          <slot name="footer" :closeDrawer="closeDrawer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import XMark from './icons/XMark.vue';

export default {
  components: { XMark },
  name: 'VDrawer',
  props: {
    handler: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      open: this.handler,
    };
  },
  methods: {
    closeDrawer() {
      if (this.open) {
        this.open = false;
        this.$emit('requestClose', true);
      }
    },
    requestClose(e) {
      if (this.open) {
        if (e.keyCode === 27) {
          this.open = false;
          this.$emit('requestClose', true);
        }
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.requestClose);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.requestClose);
  },
  watch: {
    handler(value) {
      this.open = value;
    },
  },
};
</script>

<style scoped>
/* Transition */
.vdrawer-enter-active,
.vdrawer-leave-active {
  transition: all 0.2s ease-in-out 0s;
}

.vdrawer-enter,
.vdrawer-leave-to {
  transform: translateX(100vw);
}

.vdrawer-enter-to,
.vdrawer-leave {
  transform: translateX(0);
}
/* Transition */

.vdrawer {
  position: fixed;
  z-index: 999999;
  inset: 0;
  display: flex;
  justify-content: flex-end;
}

.vdrawer-wrapper {
  height: 100%;
  background-color: white;
  box-shadow: -15px 0px 20px rgba(0,0,0,0.05);
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.vdrawer-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.vdrawer-header,
.vdrawer-content,
.vdrawer-footer {
  padding: 1rem;
}

.vdrawer-content {
  flex: 1;
  overflow: auto;
  background-color: #fcfcfc;
}

.vdrawer-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.close-vdrawer {
  background-color: transparent;
  border: 0;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

</style>
