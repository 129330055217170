<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Importação</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório de Encerramentos
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório de Encerramentos</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="
                      filters.start !== '' &&
                      filters.end !== ''
                    "
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar tx-info"></i> Período:
                      <span
                        class="pd-x-5 badge badge-info mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        de {{ filters.start | moment("DD/MM/YYYY") }}
                        até {{ filters.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>

                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um campo e uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span>
                          </label>
                          <date-picker
                            lang="pt-br"
                            v-model="filters.start"
                            name="toPickedDate"
                            format="DD/MM/YYYY"
                            placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span>
                          </label>
                          <date-picker
                            lang="pt-br"
                            v-model="filters.end"
                            name="toPickedDate"
                            format="DD/MM/YYYY"
                            placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="mg-t-5 mg-l-10">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div>
                    <span>
                      <i class="fa fa-calendar mg-r-5"></i>
                      Agrupar por
                      <span
                        v-if="filters.groupBy"
                      >
                        {{
                          filters.groupBy == 'customer' ? 'Cliente' : (filters.groupBy == 'user' ? 'Usuário' : '')
                        }}
                      </span>
                      <span v-else>Tudo</span>
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>

                <div class="dropdown-menu pd-15 wd-300">
                  <div class="form-layout form-layout-2">
                    <div class="flex fd-c">
                      <label class="flex ai-c" style="gap: 0.3rem; padding: 0.5rem 0;">
                        <input type="radio" name="groupBy" id="tudo" value="" v-model="filters.groupBy"/>
                        <span>Tudo</span>
                      </label>
                      <label class="flex ai-c" style="gap: 0.3rem; padding: 0.5rem 0;">
                        <input type="radio" name="groupBy" id="clientes" value="customer" v-model="filters.groupBy"/>
                        <span>Clientes</span>
                      </label>
                      <label class="flex ai-c" style="gap: 0.3rem; padding: 0.5rem 0;">
                        <input type="radio" name="groupBy" id="usuarios" value="user" v-model="filters.groupBy"/>
                        <span>Usuários</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20 mg-r-10"
                  @click.prevent="getClosures"
                >
                  <i class="fa fa-play mg-r-5"></i> GERAR
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div
          v-if="results && results.closings"
          class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <div>
              <label class="section-title mg-t-10 tx-16">
                Resultado da Consulta
              </label>
              <p>
                Total de <strong>{{ results.closings.length }}</strong> encerramento(s) no período
              </p>
            </div>

            <hr/>

            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th>Processo</th>
                  <th>Tipo de Processo</th>
                  <th>Tipo</th>
                  <th>Cliente</th>
                  <th>Usuário</th>
                  <th>Data</th>
                </tr>
                </thead>

                <tbody>
                <tr
                  v-for="(closing, index) in results.closings"
                  :key="`closing-${index}-${closing.processIdentification}`"
                >
                  <td>
                    <a href="#" @click.prevent="findProcessByIdentificationSearch(closing.processIdentification)">
                      {{ closing.processIdentification }}
                    </a>
                  </td>
                  <td>
                    {{ formatTypeOfProcess(closing.typeOfProcess) }}
                  </td>
                  <td>{{ closeTypes[closing.closingType] }}</td>
                  <td>{{ closing.customerName }}</td>
                  <td>{{ closing.userName }}</td>
                  <td>{{ formatDate(closing.date.split('T')[0]) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          v-if="results && results.customers_closings"
          class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <div>
              <label class="section-title mg-t-10 tx-16">
                Resultado da Consulta
              </label>
              <p>
                Total de <strong>{{ totalCustomerClosings }}</strong> encerramento(s) no período
              </p>
            </div>

            <hr/>

            <ul class="list-group">
              <template v-for="customer in Object.keys(results.customers_closings)">
                <li
                  class="list-group-item flex ai-c jc-sb gap2 fw-w cursor-pointer transition"
                  :key="customer"
                  :style="{
                    padding: '0.5rem',
                    backgroundColor: openedTabs.indexOf(customer) >= 0 ? '#f0f2f7' : '',
                  }"
                  @click.prevent="toggleTab(customer)"
                >
                  <h5 class="mg-b-0 text-dark">
                    {{ customer }} ({{ results.customers_closings[customer].length }})
                  </h5>
                  <span
                    :style="{
                      transition: 'all .1s ease-in-out 0s',
                      transform: openedTabs.indexOf(customer) >= 0 ? 'rotate(180deg)' : 'rotate(0)'
                    }"
                  >
                    <chevron-down :size="16"/>
                  </span>
                </li>

                <li
                  v-show="openedTabs.indexOf(customer) >= 0"
                  class="list-group-item flex ai-c jc-sb gap2 fw-w transition"
                  :key="`content-${customer}`"
                  style="padding: 0.5rem"
                >
                  <div class="table-responsive">
                    <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                      <thead>
                      <tr>
                        <th>Processo</th>
                        <th>Tipo de Processo</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Usuário</th>
                        <th>Data</th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr
                        v-for="(closing, index) in results.customers_closings[customer]"
                        :key="`closing-${index}-${closing.processIdentification}`"
                      >
                        <td style="width: auto">
                          <a href="#" @click.prevent="findProcessByIdentificationSearch(closing.processIdentification)">
                            {{ closing.processIdentification }}
                          </a>
                        </td>
                        <td>
                          {{ formatTypeOfProcess(closing.typeOfProcess) }}
                        </td>
                        <td style="width: auto">{{ closeTypes[closing.closingType] }}</td>
                        <td style="width: auto">{{ closing.customerName }}</td>
                        <td style="width: auto">{{ closing.userName }}</td>
                        <td style="width: auto">{{ formatDate(closing.date.split('T')[0]) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div
          v-if="results && results.users_closings"
          class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <div>
              <label class="section-title mg-t-10 tx-16">
                Resultado da Consulta
              </label>
              <p>
                Total de <strong>{{ totalUsersClosings }}</strong> encerramento(s) no período
              </p>
            </div>

            <hr/>

            <ul class="list-group">
              <template v-for="customer in Object.keys(results.users_closings)">
                <li
                  class="list-group-item flex ai-c jc-sb gap2 fw-w cursor-pointer transition"
                  :key="customer"
                  :style="{
                    padding: '0.5rem',
                    backgroundColor: openedTabs.indexOf(customer) >= 0 ? '#f0f2f7' : '',
                  }"
                  @click.prevent="toggleTab(customer)"
                >
                  <h5 class="mg-b-0 text-dark">
                    {{ customer }} ({{ results.users_closings[customer].length }})
                  </h5>
                  <span
                    :style="{
                      transition: 'all .1s ease-in-out 0s',
                      transform: openedTabs.indexOf(customer) >= 0 ? 'rotate(180deg)' : 'rotate(0)'
                    }"
                  >
                    <chevron-down :size="16"/>
                  </span>
                </li>

                <li
                  v-show="openedTabs.indexOf(customer) >= 0"
                  class="list-group-item flex ai-c jc-sb gap2 fw-w transition"
                  :key="`content-${customer}`"
                  style="padding: 0.5rem"
                >
                  <div class="table-responsive">
                    <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                      <thead>
                      <tr>
                        <th>Processo</th>
                        <th>Tipo de Processo</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Usuário</th>
                        <th>Data</th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr
                        v-for="(closing, index) in results.users_closings[customer]"
                        :key="`closing-${index}-${closing.processIdentification}`"
                      >
                        <td style="width: auto">
                          <a href="#" @click.prevent="findProcessByIdentificationSearch(closing.processIdentification)">
                            {{ closing.processIdentification }}
                          </a>
                        </td>
                        <td>
                          {{ formatTypeOfProcess(closing.typeOfProcess) }}
                        </td>
                        <td style="width: auto">{{ closeTypes[closing.closingType] }}</td>
                        <td style="width: auto">{{ closing.customerName }}</td>
                        <td style="width: auto">{{ closing.userName }}</td>
                        <td style="width: auto">{{ formatDate(closing.date.split('T')[0]) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import DatePicker from "vue2-datepicker";
import moment from "moment";
// import VirtualSelect from "@/components/VirtualSelect.vue";
import Swal from "sweetalert2";
import Axios from "axios";
import ChevronDown from "@/components/MainHeader/icons/ChevronDown.vue";

export default {
  name: "OperationalGeneral",
  props: [],
  components: {
    ChevronDown,
    // VirtualSelect,
    DatePicker,
  },
  data() {
    return {
      openedTabs: [],
      results: {},
      filters: {
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
        groupBy: ''
      },
      closeTypes: {
        CLOSING_WITH_DEBIT: "Encerramento c/ Débito",
        CLOSING_WITH_CREDIT: "Encerramento c/ Crédito",
        NEW_CUSTOMERS_WITHOUT_BANK_DATA_AND_NATHOR: "Clientes novos sem dados bancários e nathor",
        CASH_DEVOLUTION: "Devolução de numerário",
      },
    };
  },

  methods: {
    toggleTab(tab) {
      if (this.openedTabs.includes(tab)) {
        this.openedTabs = this.openedTabs.filter((t) => t !== tab);
      } else {
        this.openedTabs = [...this.openedTabs, tab];
      }
    },
    async getClosures() {
      this.openedTabs = [];
      this.$store.commit("setLoading", {
        show: true,
        label: "Relatório de Encerramentos",
        description: "Aguarde enquanto o relatório está sendo gerado...",
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/process-financial-closing/report`, {
          params: {
            startDate: moment(this.filters.start).format("YYYY-MM-DD"),
            endDate: moment(this.filters.end).format("YYYY-MM-DD"),
            groupBy: this.filters.groupBy
          }
        });

        this.results = response.data.data;
      } catch (e) {
        Swal.fire(
          "Relatório de Encerramentos",
          "Não foi possível gerar o relatório de encerramento:" + e.message,
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async findProcessByIdentificationSearch(id) {
      try {
        const response = await this.$store.dispatch(
          "ProcessStore/findProcessById",
          id
        );
        if (response) {
          // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
          this.$router.push(
            "/operational/imp/desk?showProcess=true&filteredByReport=true"
          );
        }
      } catch (error) {
        Swal.fire("Processo de Importação", error.response, "error");
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatTypeOfProcess(type) {
      switch (type) {
        case 'CONTA_E_ORDEM':
          return 'Conta e Ordem';
        case 'ASSESSORIA':
          return 'Assessoria';
        case 'ENCOMENDA':
          return 'Encomenda';
      }
    }
  },
  watch: {},
  computed: {
    totalCustomerClosings() {
      let total = 0;

      let keys = Object.keys(this.results.customers_closings)

      for (let i = 0; i < keys.length; i++) {
        let closings = this.results.customers_closings[keys[i]];

        total += closings.length;
      }

      return total
    },
    totalUsersClosings() {
      let total = 0;

      let keys = Object.keys(this.results.users_closings)

      for (let i = 0; i < keys.length; i++) {
        let closings = this.results.users_closings[keys[i]];

        total += closings.length;
      }

      return total
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.report-box table {
  width: 100%;
}

.report-header-right small {
  font-size: 10px;
}

.report-list th {
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.report-list td {
  font-size: 11px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.report-footer small {
  font-size: 9px;
}

.columns-list li {
  padding: 0.35rem 0.75rem;
  border-radius: 2px;
  font-weight: 500;
}

.columns-list.active li {
  border-color: #106dc4;
  color: #106dc4;
  background-color: rgba(16, 109, 196, 0.1);
}

.columns-list.active li:hover {
  background-color: #106dc4;
  color: white;
}

.columns-list li:hover {
  cursor: pointer;
}

.columns-list .list-group-item.active {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
