<template>
  <div>
    <div class="config-title">
      <h2 class="text-dark text-thin">Auditoria Geral do Sistema</h2>

      <div>
        <button @click.prevent="showFilterDrawer = true" class="crm-button">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor" />
            Filtros avançados
          </div>
        </button>
      </div>
    </div>

    <hr class="mb-4" />

    <config-table-s-s-r
      v-if="logsData.content.length > 0"
      @pageChange="getLogs"
      :current-page="logsData.number + 1"
      :last-page="logsData.totalPages"
      :table-config="{
        useSearch: true,
        centeredCell: false,
        showRecords: true,
      }"
      :tableData="logsData.content"
      :columns="[
        {
          label: 'Entidade',
          field: 'entityName',
        },
        {
          label: 'ID da Entidade',
          field: 'entityId',
        },
        {
          label: 'Tipo',
          field: 'type',
        },
        {
          label: 'Criado por',
          field: 'createdBy',
        },
        {
          label: 'Data de Cadastro',
          field: 'created',
        },
        {
          label: 'Processo',
          field: 'process',
        },
        {
          label: 'Qtd. Alterações',
          field: 'updates',
        },
        {
          label: 'Ações',
          field: 'actions',
        },
      ]"
    >
      <template #entityName="{ item }">
        <div v-if="item.entityName === 'Company'">
          <p class="text-bold">Cliente</p>
          <p class="text-sm">{{ item.customer.name }}</p>
        </div>
        <div v-if="item.entityName === 'Document'">
          <p class="text-bold">Documento</p>
          <p class="text-sm">{{ item.documentType.description }}</p>
        </div>
      </template>
      <template #type="{ item }">
        <p v-if="item.type === 'DELETE'">
          <span class="badge badge-danger p-2 rounded-20">Exclusão</span>
        </p>
        <p v-if="item.type === 'UPDATE'">
          <span class="badge badge-primary p-2 rounded-20">Edição</span>
        </p>
      </template>
      <template #created="{ item }">
        <p>{{ formatDate(item.created) }}</p>
      </template>
      <template #process="{ item }">
        <p v-if="item.process">PRI{{ item.process.identification }}</p>
        <p v-else>-</p>
      </template>
      <template #updates="{ item }">
        <p class="text-center">{{ item.items.length }}</p>
      </template>
      <template #actions="{ item }">
        <div class="actions-table" v-if="item.items.length > 0">
          <a class="edit-button" href="#" @click.prevent="viewLog(item)">
            <i class="fa fa-eye"></i>
          </a>
        </div>
        <div v-else>-</div>
      </template>
    </config-table-s-s-r>
    <div v-else>
      <p class="text-center">Nenhuma alteração encontrada.</p>
    </div>
    <v-modal
      :width="1000"
      :handler="modalOpen"
      :title="`Alterações Registradas`"
      description=""
      @requestClose="closeModal"
    >
      <template #content>
        <div v-if="logToView.items.length === 1">
          <p>{{ logToView.items[0].description }}</p>
        </div>
        <div v-if="logToView.items.length > 1">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor Anterior</th>
                <th>Valor Atual</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in logToView.items" :key="index">
                <td>{{ item.field }}</td>
                <td>{{ item.oldValue }}</td>
                <td>{{ item.newValue }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </v-modal>
    <Drawer v-if="showFilterDrawer" @close="showFilterDrawer = false">
      <template slot="header">
        <div>Filtros Avançados</div>
      </template>
      <template slot="body">
        <div class="mg-y-20 mg-x-10">
          <form action="" class="config-user-form">
            <div class="span-12">
              <select-wrapper
                v-model="filters.entityName"
                :options="[
                  {
                    label: 'Cliente',
                    value: 'Company',
                  },
                  {
                    label: 'Documento',
                    value: 'Document',
                  },
                ]"
                label="Entidade"
              ></select-wrapper>
            </div>
            <div class="span-12" v-if="filters.entityName === 'Company'">
              <select-wrapper
                v-model="filters.companyId"
                :options="customers"
                label="Cliente"
              ></select-wrapper>
            </div>
            <div class="span-12">
              <select-wrapper
                v-model="filters.type"
                :options="[
                  {
                    label: 'Exclusão',
                    value: 'DELETE',
                  },
                  {
                    label: 'Edição',
                    value: 'Document',
                  },
                ]"
                label="Tipo"
              ></select-wrapper>
            </div>
            <div class="span-6">
              <v-input
                type="date"
                name="startDate"
                label="Data Inicial"
                v-model="filters.startDate"
              ></v-input>
            </div>

            <div class="span-6">
              <v-input
                type="date"
                name="endDate"
                label="Data Final"
                v-model="filters.endDate"
              ></v-input>
            </div>
          </form>
        </div>
      </template>
      <template #footer>
        <button @click.prevent="clearFilters" class="crm-button bg-secondary">
          <div class="flex ai-c fgap1">Limpar Filtros</div>
        </button>
        <button @click.prevent="getLogs" class="crm-button">
          <div class="flex ai-c fgap1">Filtrar</div>
        </button>
      </template>
    </Drawer>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config";
import ConfigTableSSR from "@/components/Configuration/ConfigTableSSR.vue";
import moment from "moment/moment";
import Swal from "sweetalert2";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import Drawer from "@/components/Drawer.vue";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";

export default {
  name: "GeneralLogsTab",
  components: {
    SelectWrapper,
    VInput,
    Drawer,
    PlusIcon,
    ConfigTableSSR,
    VModal,
  },
  data() {
    return {
      logsData: {},
      currentPage: 0,
      modalOpen: false,
      showFilterDrawer: false,
      customers: [],
      logToView: null,
      filters: {
        entityName: null,
        companyId: null,
        type: null,
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    viewLog(log) {
      this.modalOpen = true;

      this.logToView = log;
    },
    async getLogs(page = 1) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando listagem de unidades da empresa.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/general-logs`,
          {
            params: {
              page: page - 1,
              entityName: this.filters.entityName,
              type: this.filters.type,
              companyId:
                this.filters.entityName === "Company"
                  ? this.filters.companyId
                  : null,
              startDate: this.filters.startDate,
              endDate: this.filters.endDate,
            },
          }
        );

        this.logsData = response.data.data;
      } catch (e) {
        Swal.fire({
          title: "Auditoria Geral",
          text: "Erro ao listar logs gerais do sistema!",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY [às] HH:mm:ss");
    },
    closeModal() {
      this.modalOpen = false;
      this.logToView = null;
    },
    async clearFilters() {
      this.filters.entityName = null;
      this.filters.companyId = null;
      this.filters.type = null;
      this.filters.startDate = null;
      this.filters.endDate = null;

      await this.getLogs();
    },
    async getCustomers() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/customers/allEnabledCustomers`
        );

        response.data.data.forEach((item) => {
          this.customers.push({
            value: item[0],
            label: item[1]
              ? `${item[1]} - ${item[3]}`
              : `${item[2]} - ${item[3]}`,
          });
        });
      } catch (e) {
        Swal.fire(
          "Relatório de inconsitências",
          `Houve um erro ao buscar os clientes ativos! ${e.response.data.data}`,
          "error"
        );
      }
    },
  },
  async mounted() {
    await this.getLogs();
    await this.getCustomers();
  },
};
</script>

<style scoped>
.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.config-user-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  align-content: start;
}

.config-user-form .span-4 {
  grid-column: span 4;
}

.config-user-form .span-6 {
  grid-column: span 6;
}

.config-user-form .span-8 {
  grid-column: span 8;
}

.config-user-form .span-12 {
  grid-column: span 12;
}
</style>
