<template>
  <div>
    <div class="card-content p-4">
      <form @submit.prevent="updateGroup" class="groupForm columns md-gap" style="align-items: start">
        <div class="columns md-gap span-3">
          <div class="span-8">
            <h4 class="tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18">
              Informações do Grupo
            </h4>
            <p class="mg-b-3">
              Informações principais para identificação deste Grupo de
              Acesso.
            </p>
          </div>

          <v-input class="span-8" v-model="formData.name" name="nome" type="text" label="Nome" />

          <v-input class="span-8" v-model="formData.code" name="code" type="text" label="Código" />

          <select-wrapper class="span-8" v-model="formData.enabled" :options="[
            {
              label: 'Sim',
              value: true,
            },
            {
              label: 'Não',
              value: 'false',
            },
          ]" label="Habilitado"></select-wrapper>
        </div>

        <div class="columns md-gap span-5">
          <div class="span-8">
            <h4 class="tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18">
              Privilégios
            </h4>
            <p class="mg-b-3">
              Privilégios que os usuários que estão neste Grupo de Acesso
              podem ter.
            </p>
          </div>

          <div class="span-8">
            <div class="row">
              <div class="col-5">
                <div class="documents-container">
                  <div class="documents-header">
                    PRIVILÉGIOS DISPONÍVEIS
                  </div>
                  <div class="documents-search">
                    <i class="fa fa-search"></i>
                    <input type="text" name="buscaPrivilegios" id="buscaPrivilegios" v-model="buscaPrivilegios" />
                  </div>
                  <ul class="documents-list">
                    <template v-for="privilege in filteredPrivileges">
                      <li :key="privilege.id" v-if="privilege.selected !== null &&
                        privilege.selected !== true
                        " @click.prevent="privilege.selected = true">
                        <span><strong>{{ privilege.name }}</strong></span>
                        <a href="#" @click.prevent class="documents-button small">
                          <i class="fa fa-angle-double-right"></i>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>

              <div class="col-2 d-flex flex-column justify-content-center align-items-center" style="gap: 10px">
                <div class="documents-button" @click.prevent="selectAll">
                  <i class="fa fa-angle-double-right"></i>
                </div>
                <div class="documents-button" @click.prevent="deselectAll">
                  <i class="fa fa-angle-double-left"></i>
                </div>
              </div>

              <div class="col-5">
                <div class="documents-container">
                  <div class="documents-header">
                    PRIVILÉGIOS SELECIONADOS
                  </div>
                  <div class="documents-search">
                    <p>
                      {{ selectedPrivileges }} privilégio(s) selecionado(s)
                    </p>
                  </div>
                  <ul class="documents-list">
                    <template v-for="privilege in privileges">
                      <li :key="privilege.id" v-if="privilege.selected !== null &&
                        privilege.selected !== false
                        " @click.prevent="privilege.selected = false">
                        <span><strong>{{ privilege.name }}</strong></span>
                        <a href="#" @click.prevent class="documents-button small">
                          <i class="fa fa-angle-double-left"></i>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="md-gap span-8 row justify-content-center">
          <button type="submit" class="crm-button mb-4 mt-3 wd-300">
            ATUALIZAR GRUPO DE ACESSO
          </button>
        </div>
      </form>
    </div>

    <div class="card">
      <div class="card-content p-4">
        <div class="columns md-gap">
          <div class="span-8">
            <h4 class="tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18">
              Usuário do Grupo de Acesso
            </h4>
            <p class="mg-b-3">
              Facilitador para Vincular ou Remover Usuários neste Grupo de
              Acesso.
            </p>
          </div>

          <div class="span-8">
            <div class="row">
              <div class="col-6">
                <div class="documents-container">
                  <div class="documents-header">USUÁRIOS DISPONÍVEIS</div>
                  <div class="documents-search">
                    <i class="fa fa-search"></i>
                    <input type="text" name="buscaPrivilegios" id="buscaPrivilegios" v-model="buscaUsuarios" />
                  </div>
                  <ul class="documents-list">
                    <template v-for="user in filteredUsers">
                      <li :key="user.id" v-if="user.selected !== null && user.selected !== true
                        " @click.prevent="linkUserToGroup(user.id)">
                        <span class="tx-uppercase">
                          <span v-if="user.enabled" class="square-8 bg-success mg-r-5 rounded-circle"></span>
                          <span v-else class="square-8 bg-danger mg-r-5 rounded-circle"></span>
                          <span class="tx-semibold">{{ user.name }}</span>
                          <small class="mg-l-5">({{ user.email1 }})</small>
                        </span>
                        <a href="#" @click.prevent class="documents-button small">
                          <i class="fa fa-angle-double-right"></i>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>

              <div class="col-6">
                <div class="documents-container">
                  <div class="documents-header">USUÁRIOS VINCULADOS</div>
                  <div class="documents-search">
                    <p>{{ selectedUsers }} usuários(s) vinculados(s)</p>
                  </div>
                  <ul class="documents-list">
                    <template v-for="user in users">
                      <li :key="user.id" v-if="user.selected !== null && user.selected !== false
                        " @click.prevent="unlinkUserFromGroup(user.id)">
                        <span class="tx-uppercase">
                          <span class="tx-normal">{{ user.name }}</span>
                          <small class="mg-l-5">({{ user.email1 }})</small>
                        </span>
                        <a href="#" @click.prevent class="documents-button small">
                          <i class="fa fa-angle-double-left"></i>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import Axios from 'axios';

import VInput from '@/components/VInput.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';

export default {
  name: 'ConfigAccessUpdateGroupModal',
  components: {
    VInput,
    SelectWrapper,
  },
  props: {
    currentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        enabled: '',
        code: '',
      },
      privileges: [],
      buscaPrivilegios: '',
      users: [],
      buscaUsuarios: '',
    };
  },
  computed: {
    selectedPrivileges() {
      return this.privileges.reduce(
        (acc, cur) => (cur.selected == true ? (acc += 1) : (acc += 0)),
        0,
      );
    },
    filteredPrivileges() {
      if (this.buscaPrivilegios !== '') {
        const regExp = new RegExp(this.buscaPrivilegios, 'gi');
        const privs = this.privileges.filter((priv) => regExp.test(priv.name));
        return privs;
      }
      return this.privileges;
    },
    selectedUsers() {
      return this.users.reduce(
        (acc, cur) => (cur.selected == true ? (acc += 1) : (acc += 0)),
        0,
      );
    },
    filteredUsers() {
      if (this.buscaUsuarios !== '') {
        const regExp = new RegExp(this.buscaUsuarios, 'gi');
        const users = this.users.filter((user) => regExp.test(user.name));
        return users;
      }
      return this.users;
    },
  },
  methods: {
    async getGroupData() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando privilégios de grupo de acesso.',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/groups/${this.currentId}`,
        );

        this.formData = response.data;

        const groupPrivileges = response.data.privilegies.map(
          (priv) => priv.id,
        );
        this.privileges = this.privileges.map((priv) => {
          if (groupPrivileges.includes(priv.id)) {
            return {
              ...priv,
              selected: true,
            };
          }
          return {
            ...priv,
            selected: false,
          };
        });
      } catch (err) {
        this.$swal(
          'Grupo de acesso',
          'Não foi possível carregar o grupo de acesso.',
          'error',
        );
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async updateGroup() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Atualizando grupo de acesso.',
      });

      try {
        const privs = this.privileges.filter((priv) => priv.selected === true).map((priv) => priv.id);
        const response = await Axios.put(`${config.env.API_DEFAULT.host}/groups/newUpdate/${this.formData.id}`, {
          ...this.formData,
          privileges: privs,
        });

        if (response.data.code === 'SUCCESS') {
          this.$swal('Atualizar Grupo de Acesso', response.data.message, 'success');
          this.$emit('updated', true);
        } else {
          this.$swal('Atualizar Grupo de Acesso', response.data.message, 'error');
        }
      } catch (err) {
        this.$swal('Atualizar Grupo de Acesso', err.response.data.message, 'error');
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async getPrivileges() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando privilégios do Grupo de Acesso.',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/user-privileges`,
        );
        this.privileges = response.data.data.map((priv) => ({
          ...priv,
          selected: false,
        }));
      } catch (err) {
        this.$swal(
          'Privilégios de grupo',
          'Não foi possível carregar os privilégios.',
          'error',
        );
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    selectAll() {
      this.privileges = this.privileges.map((priv) => {
        priv.selected = true;
        return priv;
      });
    },
    deselectAll() {
      this.privileges = this.privileges.map((priv) => {
        priv.selected = false;
        return priv;
      });
    },
    async getUsers() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando usuários de grupo de acesso.',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/users-can-vinculate-group`,
        );
        this.users = response.data.data.map((user) => ({
          ...user,
          selected: false,
        }));
      } catch (err) {
        this.$swal(
          'Usuários de grupo',
          'Não foi possível carregar os usuários.',
          'error',
        );
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async getGroupUsers() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando usuários de grupo de acesso.',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host
          }/groups/${
            this.currentId
          }/users`,
        );
        const selUsers = response.data.data.map((user) => user.id);

        this.users = this.users.map((user) => {
          if (selUsers.includes(user.id)) {
            return {
              ...user,
              selected: true,
            };
          }
          return {
            ...user,
            selected: false,
          };
        });
      } catch (err) {
        this.$swal(
          'Usuários de grupo',
          'Não foi possível carregar os usuários.',
          'error',
        );
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async linkUserToGroup(id) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Vinculando usuário ao grupo de acesso.',
      });

      try {
        await Axios.post(
          `${config.env.API_DEFAULT.host
          }/groups/${
            this.formData.id
          }/users/${
            id}`,
        );

        this.users = this.users.map((user) => {
          if (user.id === id) {
            return {
              ...user,
              selected: true,
            };
          }
          return user;
        });
      } catch (err) {
        this.$swal(
          'Atualizar de grupo de acesso',
          `Não foi possível vincular os usuário ao grupo de acesso: ${
            err.message}`,
          'error',
        );
      }
      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async unlinkUserFromGroup(id) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Desvinculando usuário do grupo de acesso.',
      });

      try {
        const response = await Axios.put(
          `${config.env.API_DEFAULT.host}/users/remove-group/${id}`,
        );

        this.users = this.users.map((user) => {
          if (user.id === id) {
            return {
              ...user,
              selected: false,
            };
          }
          return user;
        });
      } catch (err) {
        this.$swal(
          'Desvincular usuário de grupo de acesso',
          `Não foi possível desvincular o usuário ao grupo de acesso: ${
            err.message}`,
          'error',
        );
      }
      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
  async mounted() {
    await this.getPrivileges();
    await this.getUsers();
    await this.getGroupUsers();
    await this.getGroupData();
  },
};
</script>

<style scoped>
.documents-container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 300px;
  padding: 15px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.documents-header {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.documents-search {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}

.documents-search input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding-left: 45px;
}

.documents-search input:focus {
  background-color: white;
}

.documents-search i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.documents-list {
  padding: 0;
  list-style: none;
  margin: 0;
  overflow: auto;
  height: 100%;
}

.documents-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  word-break: break-all;
  gap: 10px;
}

.documents-list li a {
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}

.documents-list li:hover {
  background-color: #d2d2d2;
  color: black;
}

.documents-list li:hover a {
  opacity: 1;
}

.documents-list li:not(:last-of-type) {
  margin-bottom: 6px;
}

.documents-list li:not(:last-of-type)::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  right: 0;
}

.documents-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 45px;
  font-size: 24px;
  cursor: pointer;
}

.documents-button.small {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
}
</style>
