<template>
  <div :style="`height: ${height}px; min-width: 100%;`">
    <v-chart class="chart" :options="options" />
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/pie';

export default {
  name: 'ApacheChart',
  props: {
    graphData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300,
    },
    colors: {
      type: Array,
      default: () => ['#92b9e2', '#456f99', '#12477d'],
    },
    animation: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    'v-chart': ECharts,
  },
  data() {
    return {
      options: {
        color: this.colors,
        series: [
          {
            type: 'pie',
            radius: ['25%', '55%'],
            label: {
              show: true,
              position: 'outside',
              overflow: 'break',
              align: 'center',
              formatter: '{b} \n {d}%',
            },
            labelLine: {
              show: false,
            },
            data: this.graphData,
            animation: this.animation,
          },
        ],
      },
    };
  },
};
</script>

<style>
  .echarts {
    width: 100%;
    height: 100%;
  }
</style>
