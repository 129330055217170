<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">CRM</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório Simplificado do Cliente
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório Simplificado do Cliente</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="
                      filter.date.start !== '' &&
                      filter.date.end !== '' &&
                      filter.date.field !== ''
                    "
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar tx-info"></i> Período:
                      <span
                        class="pd-x-5 badge badge-info mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.field.label }} de
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um campo e uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group bd-t-0-force">
                          <label class="form-control-label">
                            Campo do Filtro
                          </label>
                          <v-select
                            v-model="filter.date.field"
                            :options="listOfDatesFields"
                            name="label"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.taxRegimes.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-flag tx-teal mg-r-5"></i>Regime Fiscal:
                      <span
                        class="pd-x-5 badge badge-teal mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.taxRegimes"
                        :key="c.attribute"
                      >
                        {{ c.label }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-flag tx-medium mg-r-5"></i> Regime Fiscal
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Regime Fiscal
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais regime fiscal
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">
                            Regime Fiscal
                          </label>
                          <v-select
                            v-model="filter.taxRegimes"
                            :options="listOfTaxRegime"
                            name="label"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.enabled.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-flag tx-warning mg-r-5"></i>Habilitado:
                      <span
                        class="pd-x-5 badge badge-warning mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.enabled"
                        :key="c.attribute"
                      >
                        {{ c.label }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-flag tx-medium mg-r-5"></i> Habilitado
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Habilitado
                  </h6>
                  <p class="tx-gray-600 mg-b-10">Selecione as opções</p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Habilitado </label>
                          <v-select
                            v-model="filter.enabled"
                            :options="listOfEnabled"
                            name="label"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <download-excel
                :data="listOfResults"
                :fields="excelFields"
                class="btn btn-success pd-x-20"
                v-if="listOfResults.length > 0"
                :name="`relatorio-simplificado-cliente.xls`"
              >
                <i class="fa fa-file-excel-o mr-1"></i> EXPORTAR EXCEL
              </download-excel>
            </li>
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20"
                  @click.prevent="generate('HTML')"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> GERAR
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta
            </label>
            <p class="mg-b-0">
              Foram encontrados
              <strong>{{ listOfResults.length }} clientes</strong> de acordo com
              o filtro acima.
            </p>
            <hr/>
            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th class="tx-center">Habilitado</th>
                  <th class="tx-center">Razão Social</th>
                  <th class="tx-center">Fantasia</th>
                  <th class="tx-center">CNPJ</th>
                  <th class="tx-center">Regime Fiscal</th>
                  <th class="tx-center">Inscrição Estadual</th>
                  <th class="tx-center">Inscrição Municipal</th>
                  <th class="tx-center">Cidade</th>
                  <th class="tx-center">Fundação</th>
                  <th class="tx-center">Procuração</th>
                  <th class="tx-center">Envio de comunicados - TTD</th>
                  <th class="tx-center">
                    Confirmação de recebimento (AR Correio)
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in listOfResults" :key="result.id">
                  <td class="tx-center">
                    <template v-if="result.enabled">
                        <span
                          class="pd-x-5 badge badge-success mg-b-0 mg-r-2-force tx-thin-force tx-bold tx-uppercase tx-10"
                        >
                          Sim
                        </span>
                    </template>
                    <template v-else>
                        <span
                          class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force tx-bold tx-uppercase tx-10"
                        >
                          Não
                        </span>
                    </template>
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    <router-link
                      :to="{ name: 'CrmCustomer', params: { id: result.id } }"
                      target="_blank"
                      rel="noopener"
                    >
                      {{ result.completeName }}
                    </router-link>
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    {{ result.name }}
                  </td>
                  <td class="tx-center">
                    {{ result.federalID }}
                  </td>
                  <td class="tx-center">
                    {{ result.taxRegime }}
                  </td>
                  <td class="tx-center">
                    {{ result.municipalRegistration }}
                  </td>
                  <td class="tx-center">
                    {{ result.stateRegistration }}
                  </td>
                  <td class="tx-center">
                    <template
                      v-if="
                          result.city !== null && result.federalUnity !== null
                        "
                    >
                      {{ result.city }} / {{ result.federalUnity }}
                    </template>
                    <template
                      v-else-if="
                          result.city !== null && result.federalUnity === null
                        "
                    >
                      {{ result.city }}
                    </template>
                    <template
                      v-else-if="
                          result.city === null && result.federalUnity !== null
                        "
                    >
                      {{ result.federalUnity }}
                    </template>
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    {{ result.openingDate | moment("DD/MM/YYYY") }}
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    {{ result.salesDateOfDocumentOperationalValidation }}
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    {{ result.ttdAnnouncementsDate }}
                  </td>
                  <td class="tx-center tx-bold" scope="row">
                    {{ result.arReceiptConfirmationDate }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import JsonExcel from "vue-json-excel";

import config from "@/config.js";

import moment from "moment";

export default {
  name: "CompaniesReportCustomers",
  props: [],
  components: {
    "download-excel": JsonExcel,
    DatePicker,
  },
  data() {
    return {
      filter: {
        enabled: [],
        taxRegimes: [],
        exporters: [],
        customsBrokers: [],
        date: {
          field: "",
          start: moment().startOf("week").toDate(),
          end: moment().endOf("week").toDate(),
        },
      },
      excelFields: {
        "Razão Social": "completeName",
        Fantasia: "name",
        CNPJ: "federalID",
        Regime: {
          field: "taxRegime",
          callback: (value) => {
            switch (value) {
              case "LUCRO_PRESUMIDO":
                return "Lucro Presumido";
                break;
              case "LUCRO_REAL":
                return "Lucro Real";
                break;
              case "SIMPLES":
                return "Simples";
                break;
            }

            return "";
          },
        },
        "Inscrição Municipal": "municipalRegistration",
        "Inscrição Estadual": "stateRegistration",
        Cidade: "city",
        Estado: "federalUnity",
        Fundação: {
          field: "openingDate",
          callback: (value) => {
            if (value && value !== null && value != "") {
              // const newDate = moment(value, 'YYYY-MM-DD hh:mm:ss');
              // return newDate.format('DD/MM/YYYY');

              return value;
            }

            return "";
          },
        },
        Procuração: {
          field: "salesDateOfDocumentOperationalValidation",
          callback: (value) => {
            if (value && value !== null && value != "") {
              // const newDate = moment(value);
              // return newDate.format('DD/MM/YYYY');

              return value;
            }

            return "";
          },
        },
        "Envio de comunicados - TTD": {
          field: "ttdAnnouncementsDate",
          callback: (value) => {
            if (value && value !== null && value != "") {
              // const newDate = moment(value);
              // return newDate.format('DD/MM/YYYY');

              return value;
            }

            return "";
          },
        },
        "Confirmação de recebimento (AR Correio)": {
          field: "arReceiptConfirmationDate",
          callback: (value) => {
            if (value && value !== null && value != "") {
              // const newDate = moment(value);
              // return newDate.format('DD/MM/YYYY');

              return value;
            }

            return "";
          },
        },
      },
      labels: {},
      listOfResults: [],
    };
  },

  methods: {
    generate(type) {
      let params = "";
      const {filter} = this;

      this.$store.commit("setLoading", {
        show: true,
        label: "Relatório Simplificado de Clientes",
        description: "Aguarde enquanto o relatório está sendo gerado...",
      });

      if (
        filter.date.field !== "" &&
        filter.date.start !== "" &&
        filter.date.end !== ""
      ) {
        const startDate = `${this.$moment(filter.date.start).format(
          "Y-MM-DDT"
        )}00:00:00`;
        const endDate = `${this.$moment(filter.date.end).format(
          "Y-MM-DDT"
        )}23:59:59`;
        params += `field=${filter.date.field.attribute}&from=${startDate}&to=${endDate}`;
      }

      if (filter.enabled.length > 0) {
        const enabled = new Array();
        filter.enabled.filter((e) => {
          enabled.push(e.value);
        });
        params += `&enabled=${enabled.join()}`;
      }

      if (filter.taxRegimes.length > 0) {
        const taxRegimes = new Array();
        filter.taxRegimes.filter((tr) => {
          taxRegimes.push(tr.value);
        });
        params += `&taxRegimes=${taxRegimes.join()}`;
      }

      Axios.get(
        `${config.env.API_DEFAULT.host}/company/report/general?${encodeURI(
          params
        )}`
      )
        .then((response) => {
          if (response.data.code === "SUCCESS") {
            this.listOfResults = response.data.data;
            this.$store.commit("setLoading", {show: false});
            Swal.fire(
              "Relatório Simplificado de Clientes",
              response.data.message,
              "success"
            );
          } else {
            this.$store.commit("setLoading", {show: false});
            Swal.fire(
              "Relatório Simplificado de Clientes",
              response.data.message,
              "error"
            );
          }
        })
        .catch((error) => {
          this.$store.commit("setLoading", {show: false});
        });
    },
  },
  watch: {},
  computed: {
    listOfDatesFields() {
      const data = [
        {
          attribute: "createdAt",
          label: "Data de Cadastro no Gescomex",
        },
        {
          attribute: "openingDate",
          label: "Data de Fundação",
        },
        {
          attribute: "salesDateOfFirstBusiness",
          label: "Data do Primeiro Negócio",
        },
        {
          attribute: "salesDateOfDocumentOperationalValidation",
          label: "Data de Vencimento da Procuração",
        },
      ];

      return data;
    },
    listOfTaxRegime() {
      const data = [
        {
          value: "0",
          label: "Lucro Presumido",
        },
        {
          value: "1",
          label: "Lucro Real",
        },
        {
          value: "2",
          label: "Simples",
        },
      ];

      return data;
    },
    listOfEnabled() {
      const data = [
        {
          value: "true",
          label: "Sim",
        },
        {
          value: "false",
          label: "Não",
        },
      ];

      return data;
    },
  },
  mounted() {
    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde...",
      description: "Carregando informações para filtro do relatório...",
    });

    this.$store.commit("setLoading", {
      show: false,
    });
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}
</style>
