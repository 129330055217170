<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados do Plano de Contas</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Habilitado <span class="tx-danger">*</span></label
            >
            <select class="form-control" v-model="data.enabled" required>
              <option value="">Selecione</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        <div class="col-md-2 col-sm-12">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Ano <span class="tx-danger">*</span></label
            >
            <input type="number" v-model="data.year" class="form-control" />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-md-8 col-sm-12">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Status <span class="tx-danger">*</span></label
            >

            <div class="d-flex">
              <label class="rdiobox mg-r-30">
                <input
                  name="rdio"
                  value="PLANNING"
                  v-model="data.status"
                  type="radio"
                  checked=""
                />
                <span>Planejamento</span>
              </label>

              <label class="rdiobox rdiobox-info mg-r-30">
                <input
                  name="rdio"
                  value="BUDGET"
                  v-model="data.status"
                  type="radio"
                  checked=""
                />
                <span>Orçamento</span>
              </label>

              <label class="rdiobox rdiobox-warning mg-r-30">
                <input
                  name="rdio"
                  value="PROGRESS"
                  v-model="data.status"
                  type="radio"
                  checked=""
                />
                <span>Execução</span>
              </label>

              <label class="rdiobox rdiobox-danger mg-r-30">
                <input
                  name="rdio"
                  value="CANCELED"
                  v-model="data.status"
                  type="radio"
                  checked=""
                />
                <span>Cancelado</span>
              </label>

              <label class="rdiobox rdiobox-success mg-r-30">
                <input
                  name="rdio"
                  value="DONE"
                  v-model="data.status"
                  type="radio"
                  checked=""
                />
                <span>Concluído</span>
              </label>
            </div>

            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Filial <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="data.accountLabel"
              :on-search="getAccounts"
              label="name"
              :options="accounts"
              name="account"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                <span>{{ option.federalID }} | {{ option.name }}</span>
              </template>
            </v-select>
            <span
              v-show="errors.has('accounts')"
              class="parsley-errors-list filled"
              >Selecione a Filial</span
            >
          </div>
        </div>

        <div class="col-md-8 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Descrição <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              v-model="data.description"
              class="form-control"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Selecione uma opção</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";

import vSelect from "vue-select";

export default {
  name: "chartOfAccounts-general",
  components: {
    "v-select": vSelect,
  },
  props: ["isNewRecord"],
  data() {
    return {
      accounts: [],
      data: {
        enabled: true,
        description: "",
        year: 2019,
        status: "PLANNING",
        accountLabel: "",
        account: "",
      },
    };
  },
  computed: {
    accountLabel() {
      return this.data.accountLabel;
    },
  },
  methods: {
    async onSubmit() {
      this.account = this.accountLabel._links.self.href;

      if (this.isNewRecord) {
        this.addModel();
      } else {
        this.updateModel();
      }
    },
    async addModel() {
      try {
        const response = await this.$store.dispatch(
          "FinancesStore/setChartOfAccountProjects",
          this.data
        );
        if (response) {
          this.$router.push({
            name: "chartOfAccounts-form",
            params: { action: "update", id: response.id },
          });
        } else {
          this.$swal({
            title: "Erro na criação do Projeto",
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        }
      } catch (err) {
        console.error(err);
        this.$swal({
          title: "Erro na criação do Projeto",
          showConfirmButton: false,
          timer: 4000,
          position: "top-right",
          icon: "error",
        });
      }
    },
    async updateModel() {
      try {
        const response = await this.$store.dispatch(
          "FinancesStore/setChartOfAccountProjects",
          this.data
        );
        if (!response) {
          this.$swal({
            title: "Erro na criação do Projeto",
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        }
      } catch (err) {
        console.error(err);
        this.$swal({
          title: "Erro na atualização do Projeto",
          showConfirmButton: false,
          timer: 4000,
          position: "top-right",
          icon: "error",
        });
      }
    },
    async loadModel(item, index) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Aguarde",
        description: "Carregando dados...",
      });
      const dataId = this.$route.params.id;
      const response = await this.$store.dispatch(
        "FinancesStore/getSingleChartOfAccountProject",
        dataId
      );

      this.data = response;

      this.$store.commit("setLoading", {
        show: false,
        label: "",
        description: "",
      });
    },
    getAccounts(search, loading) {
      Axios.get(
        `${config.env.API_DEFAULT.host}/accounts/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.accounts = response.data._embedded.accounts;
        })
        .catch((error) => {
          swal(
            `Não foi possível carregar a listagem de Filiais disponíveis: ${error}`,
            "error"
          );
        });
    },
  },
  async created() {
    await this.getAccounts();

    if (!this.isNewRecord) {
      this.loadModel();
    }
  },
  watch: {
    accountLabel(newVal) {
      if (this.data.accountLabel && this.data.accountLabel._links) {
        this.data.account = this.data.accountLabel._links.self.href;
      } else {
        console.error("Algo errado!");
      }
    },
  },
};
</script>

<style scoped></style>
