<template>
  <div class="crm-wrapper">
    <crm-sidebar />
    <div class="crm-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import CrmSidebar from '@/components/crm/Sidebar.vue';

export default {
  name: 'CrmWrapper',
  components: {
    CrmSidebar,
  },
};
</script>

<style scoped>
  .crm-wrapper {
    width: 100%;
    display: flex;
  }
  .crm-content {
    flex: 1 1 auto;
  }
</style>
