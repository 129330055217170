<template>
  <div class="card mg-0 pd-0">
    <div
      class="report-summary-header pd-x-25 mg-t-0 mg-b-0 pd-t-10 pd-b-10 bd bd-b-1 bd-l-0 bd-r-0 bd-t-0"
    >
      <div>
        <p class="mg-b-0">
          <span class="tx-normal tx-24 tx-gray-700">
            <i class="icon ion-document-text tx-primary mg-r-3"></i>
            Adição
            <span class="tx-thin tx-24 tx-gray-700">
              Nº {{ this.activeAdicao.numeroAdicao }}
            </span>
          </span>

          <template
            v-if="
              this.activeAdicao.codigoMercadoriaNCM !== undefined &&
              this.activeAdicao.codigoMercadoriaNCM !== null &&
              this.activeAdicao.codigoMercadoriaNCM !== ''
            "
          >
            <i class="icon ion-bookmark mg-r-3 tx-teal mg-l-15"></i>
            <strong> NCM: </strong>
            {{ this.activeAdicao.codigoMercadoriaNCM }}
          </template>

          <template
            v-if="
              this.activeAdicao.numeroLI !== undefined &&
              this.activeAdicao.numeroLI !== null &&
              this.activeAdicao.numeroLI !== ''
            "
          >
            <i class="icon ion-flag mg-r-3 tx-danger mg-l-15"></i>
            <strong> LI: </strong>
            {{ this.activeAdicao.numeroLI }}
          </template>

          <i class="fa fa-truck mg-r-3 tx-primary mg-l-15"></i>
          <strong> Exportador: </strong>
          {{ this.activeAdicao.nomeFornecedorEstrangeiro }}
        </p>
      </div>
      <div>
        <div class="card-option tx-24">
          <a
            href="#"
            class="tx-primary mg-l-10 tx-20"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><i class="fa fa-search lh-0"></i
          ></a>
          <div class="dropdown-menu pd-10">
            <h6 class="tx-gray-800 tx-uppercase tx-bold mg-t-10">
              Adições Cadastradas
            </h6>
            <hr />
            <div class="search-box wd-100p">
              <input
                type="text"
                placeholder="Pesquisar Adição"
                class="form-control"
              />
              <button class="btn btn-primary">
                <i class="fa fa-search" style="margin-left: -5px"></i>
              </button>
            </div>
            <hr />

            <perfect-scrollbar :settings="{ maxScrollbarLength: 100 }">
              <a
                class="dropdown-item nav-link"
                href="#adicao#fornecedor"
                data-toggle="tab"
                v-for="(adicao, index) in this.di.adicoes"
                :key="`adicao-busca-${index}`"
                @click="abrirAdicao(adicao)"
              >
                <strong> {{ adicao.numeroAdicao }} </strong>
                <small> NCM: </small>
                <strong>{{ adicao.codigoMercadoriaNCM }} </strong>
                <small> LI: </small> <strong>{{ adicao.numeroLI }} </strong>
              </a>
            </perfect-scrollbar>
          </div>

          <template
            v-if="
              activeAdicao !== null &&
              adicoes[parseInt(activeAdicao.numeroAdicao) - 2] !== null &&
              adicoes[parseInt(activeAdicao.numeroAdicao) - 2] !== undefined
            "
          >
            <a
              href="#"
              class="tx-info mg-l-20 tx-20"
              @click.prevent="
                abrirAdicao(adicoes[parseInt(activeAdicao.numeroAdicao) - 2])
              "
            >
              <i class="icon ion-arrow-left-a lh-0"></i>
            </a>
          </template>

          <span class="pd-x-5 tx-thin tx-24 tx-gray-700">
            Nº {{ this.activeAdicao.numeroAdicao }}
          </span>

          <template
            v-if="
              activeAdicao !== null &&
              adicoes[parseInt(activeAdicao.numeroAdicao)] !== null &&
              adicoes[parseInt(activeAdicao.numeroAdicao)] !== undefined
            "
          >
            <a
              href="#"
              class="tx-info tx-20"
              @click.prevent="
                abrirAdicao(adicoes[parseInt(activeAdicao.numeroAdicao)])
              "
            >
              <i class="icon ion-arrow-right-a lh-0"></i>
            </a>
          </template>

          <a
            href="#"
            class="tx-danger mg-l-20 tx-20"
            @click.prevent="fecharAdicao()"
          >
            <i class="icon ion-close lh-0"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="card-header bg-gray-100 tx-gray-900">
      <ul class="nav nav-tabs nav-justified card-header-tabs mg-l-10">
        <li class="nav-item">
          <a
            class="nav-link active tx-info"
            href="#fornecedor"
            data-toggle="tab"
            @click.prevent="forceRerender"
          >
            <i class="fa fa-building mg-r-5"></i>
            Fornecedor
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link tx-info"
            href="#mercadoria"
            data-toggle="tab"
            @click.prevent="forceRerender"
          >
            <i class="fa fa-truck mg-r-5"></i>
            Mercadoria
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link tx-info"
            href="#valor"
            data-toggle="tab"
            @click.prevent="forceRerender"
          >
            <i class="fa fa-ship mg-r-5"></i>
            Valor Aduaneiro
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link tx-info"
            href="#tributos"
            data-toggle="tab"
            @click.prevent="forceRerender"
          >
            <i class="fa fa-bank mg-r-5"></i>
            Tributos
          </a>
        </li>
      </ul>
    </div>

    <div class="card-body mg-x-0 pd-x-0 pd-y-0">
      <div class="tab-content pd-25">
        <div class="tab-pane active" id="fornecedor">
          <adicao-fornecedor :key="componentKey" :noClick="noClick" />
        </div>

        <div class="tab-pane" id="mercadoria">
          <adicao-mercadoria :key="componentKey" :noClick="noClick" />
        </div>

        <div class="tab-pane" id="valor">
          <adicao-valor-aduaneiro :key="componentKey" :noClick="noClick" />
        </div>

        <div class="tab-pane" id="tributos">
          <adicao-tributos :key="componentKey" :noClick="noClick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AdicaoFornecedor from "@/views/operational/import-declaration/adition/Form.Fornecedor.vue";
import AdicaoMercadoria from "@/views/operational/import-declaration/adition/Form.Mercadoria.vue";
import AdicaoValorAduaneiro from "@/views/operational/import-declaration/adition/Form.ValorAduaneiro.vue";
import AdicaoTributos from "@/views/operational/import-declaration/adition/Form.Tributos.vue";

export default {
  name: "di-adicao-form",
  props: ["noClick"],
  components: {
    "adicao-fornecedor": AdicaoFornecedor,
    "adicao-mercadoria": AdicaoMercadoria,
    "adicao-valor-aduaneiro": AdicaoValorAduaneiro,
    "adicao-tributos": AdicaoTributos,
  },
  data() {
    return {
      liIdentification: "",
      initialDI: {},
      di: {},
      initialModel: {},
      model: {},
      componentKey: 0,
    };
  },
  methods: {
    abrirMercadoria() {},
    forceRerender() {
      this.componentKey += 1;
    },
    onSubmit() {
      return true;
    },
    fecharAdicao() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Fechando Adição",
        description:
          "Por favor, aguarde enquanto a adição está sendo finalizada...",
      });

      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);
      this.$store.dispatch("MyIMPStore/patch", {
        diff: this.di,
        newModel: this.di,
        fieldName: null,
      });

      this.forceRerender();

      setTimeout(() => {
        new Promise((resolve, reject) => {
          resolve(this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null));
        }).then(() => {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        });
      }, 1000);
    },
    abrirAdicao(adicao) {
      this.forceRerender();
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", adicao);
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),
    adicoes() {
      if (
        this.di !== null &&
        this.di !== undefined &&
        this.di.adicoes !== null &&
        this.di.adicoes !== undefined
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.di.adicoes.sort((a, b) => {
          if (a.numeroAdicao < b.numeroAdicao) return -1;
          if (b.numeroAdicao < a.numeroAdicao) return 1;
          return 0;
        });
      }
      return [];
    },
  },
  watch: {
    activeDI() {
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };
    },
  },
  mounted() {
    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
