<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30 0H0V30H30V0Z" fill="white" fill-opacity="0.01" />
    <path
      d="M3.125 11.875H26.875V25C26.875 25.6904 26.3154 26.25 25.625 26.25H4.375C3.68464 26.25 3.125 25.6904 3.125 25V11.875Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path
      d="M3.125 5.625C3.125 4.93464 3.68464 4.375 4.375 4.375H25.625C26.3154 4.375 26.875 4.93464 26.875 5.625V11.875H3.125V5.625Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path
      d="M10 2.5V7.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 2.5V7.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 21.25H21.25"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.75 21.25H12.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 16.25H21.25"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.75 16.25H12.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
