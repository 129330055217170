<template>
  <div
    class="h-card text-white flex jc-sb"
    :style="`background-color: ${background}; position: relative;`"
  >
    <div class="main-info text-left pb-2 ph-2">
      <span class="text-xg text-medium" style="margin-right: 5px">
        <span v-if="animated">
          <number
            ref="number1"
            :from="0"
            :to="numero"
            :duration="5"
            :delay="1"
            easing="Power1.easeOut"
          />
        </span>
        <span v-else>{{ numero }}</span>
      </span>
      <span class="text-md text-medium">{{ sigla }}</span>
      <p class="text-thin" style="margin-bottom: 0; margin-top: -10px;">{{ descricao }}</p>
    </div>

    <div class="icon flex ai-c jc-c">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalInfoCard',
  props: {
    numero: {
      type: [Number, String],
      default: 99,
    },
    sigla: {
      type: String,
      default: 'EX',
    },
    descricao: {
      type: String,
      default: 'exemplo',
    },
    background: {
      type: String,
      default: '#004385',
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.icon {
  background-color: rgba(255, 255, 255, 0.7);
  flex: 0 0 30%;
}

.main-info {
  flex: 0 0 70%;
}

</style>
