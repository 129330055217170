var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-dialog mg-0"},[_c('div',{staticClass:"modal-content bd-0"},[_c('div',{staticClass:"modal-body pd-10"},[_c('div',{staticClass:"light-bg p-2 bd-b"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('button',{staticClass:"report-button small",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.filterOpen = !_vm.filterOpen}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" Filtros ")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","width":"16px","height":"16px"}},[_c('path',{attrs:{"fill":"currentColor","d":"M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"}})])])]),_c('div',{staticClass:"col-8 pd-5-force pd-r-20-force tx-right tx-light"},[(_vm.customersList.length > 0 && _vm.customersList.length > 1)?_c('span',{staticClass:"tx-uppercase tx-13"},[_vm._v(" Exibindo "+_vm._s(_vm.customersList.length)+" empresas ")]):(
                _vm.customersList.length > 0 && _vm.customersList.length == 1
              )?_c('span',{staticClass:"tx-uppercase tx-13"},[_vm._v(" Apenas "+_vm._s(_vm.customersList.length)+" empresa foi encontrada ")]):_vm._e()])]),(_vm.filterOpen)?_c('div',[_c('div',{staticClass:"mt-3 mb-4 bd-t pd-t-30"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('select-wrapper',{attrs:{"name":"enabled","label":"Situação","clearable":true,"value":_vm.crmCustomersFilters.enabled,"options":[
                    {
                      label: 'Habilitada',
                      value: true,
                    },
                    {
                      label: 'Desabilitada',
                      value: 'false',
                    },
                  ]},on:{"input":function($event){return _vm.inputChange($event, 'enabled')}}})],1)])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-3"}),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-oblong btn-small bg-teal wd-100p tx-white",on:{"click":function($event){$event.preventDefault();return _vm.generate.apply(null, arguments)}}},[_vm._v(" CARREGAR ")])]),_c('div',{staticClass:"col-3"})])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }