<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30 0H0V30H30V0Z" fill="white" fill-opacity="0.01" />
    <path
      d="M2.5 26.25H27.5"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.75 17.5H5V26.25H8.75V17.5Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linejoin="round"
    />
    <path
      d="M16.875 11.25H13.125V26.25H16.875V11.25Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linejoin="round"
    />
    <path
      d="M25 3.75H21.25V26.25H25V3.75Z"
      fill="#DEE2E6"
      stroke="#343A40"
      stroke-width="1.875"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ReportIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
