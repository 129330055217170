<template>
  <div :class="[
    'v-select-wrapper',
    active ? 'active' : ''
  ]">
    <label>
      {{ label }}
    </label>
    <date-picker
      lang="pt-br"
      :value="value"
      @input="$emit('input', $event)"
      :name="name"
      :format="format"
      placeholder=""
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'DateWrapper',
  components: {
    DatePicker,
  },
  data() {
    return {
      active: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
      default: 'label',
    },
    index: {
      type: String,
      default: 'value',
    },
    value: {},
    name: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  methods: {
    checkInput(value) {
      if (value) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
  updated() {
    this.checkInput(this.value);
  },
  mounted() {
    this.checkInput(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (value) {
          this.active = true;
        }
      },
    },
  },
};
</script>

<style>
.v-select-wrapper {
  position: relative;
  background-color: white;
}

.v-select-wrapper label {
  pointer-events: none;
}

.v-select-wrapper .mx-datepicker {
  width: 100%;
}

.v-select-wrapper .mx-datepicker .mx-input-append {
  line-height: 45px;
}

.v-select-wrapper .mx-input-wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 0 18px;
}

.v-select-wrapper .mx-input-wrapper,
.v-select-wrapper .mx-input-wrapper input {
  width: 100% !important;
  height: 45px !important;
}

.v-select-wrapper .mx-input-wrapper input {
  background-color: transparent;
  border: 0;
}

.v-select-wrapper .mx-clear-wrapper {
  margin-top: -8px !important;
}

.v-select-wrapper label {
  font-size: 1rem;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: all .2s ease-in-out 0s;
  background-color: white;
  z-index: 2;
  padding: 1px 4px;
}

.v-select-wrapper.active label {
  top: -8px;
  font-size: 0.7rem;
}
</style>
