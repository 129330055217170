<template>
  <svg :width="size" :height="size" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M16.5961 12.6009L10.4231 6.42791L16.5961 0.254883L22.7692 6.42791L16.5961 12.6009ZM0.759689 10.5096V1.7597H9.5096V10.5096H0.759689ZM12.4904 22.2403V13.4904H21.2403V22.2403H12.4904ZM0.759689 22.2403V13.4904H9.5096V22.2403H0.759689ZM2.63466 8.63466H7.63466V3.63466H2.63466V8.63466ZM16.6274 9.99041L20.1586 6.45916L16.6274 2.92791L13.0961 6.45916L16.6274 9.99041ZM14.3653 20.3654H19.3653V15.3654H14.3653V20.3654ZM2.63466 20.3654H7.63466V15.3654H2.63466V20.3654Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'AppIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
