import Swal from 'sweetalert2';

const FieldsValidation = [
  {
    field: 'cifValue',
    label: 'VALOR CIF',
    type: 'number',
    typeLabel: 'Numérico',
  },
  // {
  //     field: "cifValueCurrency",
  //     label: "MOEDA VALOR CIF",
  //     type: "number",
  //     typeLabel: "Numérico",
  // },
  {
    field: 'freetime',
    label: 'FREETIME',
    type: 'number',
    typeLabel: 'Numérico',
  },
  {
    field: 'diProtocol',
    label: 'NÚMERO DO PROTOCOLO DE REGISTRO',
    type: 'number',
    typeLabel: 'Numérico',
  },
  {
    field: 'diNumber',
    label: 'NÚMERO DO REGISTRO',
    type: 'number',
    typeLabel: 'Numérico',
  },
  {
    field: 'diIdentification',
    label: 'IDENTIFICAÇÃO',
    type: 'number',
    typeLabel: 'Numérico',
  },
  {
    field: 'integrationIdentification',
    label: 'REFERÊNCIA',
    type: 'number',
    typeLabel: 'Numérico',
  },
];

export function validatePatchImportProcess(data) {
  const fieldFinded = FieldsValidation.filter(
    (item) => item.field === data.fieldName,
  );
  const value = Object.values(data.diff)[0];

  if (fieldFinded.length > 0 && fieldFinded[0] !== null && value !== null && value !== '') {
    const regexNumberContainsLetters = /[a-zA-Z]/g;

    if (
      fieldFinded[0].type === 'number'
            && regexNumberContainsLetters.test(value)
    ) {
      Swal.fire(
        'Atualiazação do Processo de Importação',
        `O Campo ${fieldFinded[0].label} precisa ser ${fieldFinded[0].typeLabel}`,
        'error',
      );

      return false;
    }

    return true;
  }

  return true;
}
