var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns-12 no-gap",staticStyle:{"height":"100%","overflow":"hidden"}},[(_vm.selectedDocuments.length > 1)?_c('div',{staticClass:"span-3 documents-sidebar-left",staticStyle:{"overflow":"auto"}},[_c('ul',{staticClass:"selected-documents-list"},_vm._l((_vm.selectedDocuments),function(document,index){return _c('li',{key:document.id,class:{
          active: _vm.selectedDocument.id === document.id,
          approved: document.extractionStatus === 'APPROVED',
          rejected: document.extractionStatus === 'REJECTED',
        },on:{"click":function($event){$event.preventDefault();return _vm.selectDocument(index)}}},[_c('div',{staticClass:"flex ai-c jc-sb fgap1 fw-w"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("#"+_vm._s(document.id))]),_c('p',{staticClass:"mb-2 doc-text-max"},[_vm._v("Arquivo: "+_vm._s(document.fileName))]),_c('p',{staticClass:"mb-0 text-medium doc-text-max"},[_vm._v(" Cliente: "+_vm._s(document.extractionResult.buyerName !== null ? ( _vm.companies.find( (comp) => String(comp.value) === String(document.extractionResult.buyerName) ) || {} ).label : "-")+" ")]),_c('p',{staticClass:"mb-0 doc-text-max"},[_vm._v(" Exportador: "+_vm._s(document.extractionResult.vendorName !== null ? ( _vm.exporters.find( (comp) => String(comp.value) === String(document.extractionResult.vendorName) ) || {} ).label : "-")+" ")])]),_c('div',[(document.documentType === 'invoices')?_c('div',{staticClass:"blue-pill"},[_vm._v(" Invoice "),_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(document.extractionResult.invoiceNumber))])]):_vm._e(),(document.documentType === 'purchase_orders')?_c('div',{staticClass:"yellow-pill"},[_vm._v(" Pedido ")]):_vm._e()])])])}),0)]):_vm._e(),_c('div',{staticClass:"pv-2 ph-2",class:{
      'span-9': _vm.selectedDocuments.length > 1,
      'span-12': _vm.selectedDocuments.length <= 1,
    },staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"columns-12 sm-gap"},[_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Status","name":"extractionStatus","value":_vm._f("status")(_vm.selectedDocument.extractionStatus)}})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Tipo de Documento","name":"documentType","value":_vm._f("documentType")(_vm.selectedDocument.documentType),"hint":"Tipo de documento extraido"}})],1),_c('div',{staticClass:"span-12"},[_c('p',{staticClass:"text-dark mb-0"},[_vm._v(" Marque com as "),_c('span',{staticClass:"check-span"},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_c('b',[_vm._v(" informações extraídas corretamente")]),_vm._v(" e deixe sem marcação as incorretas "),_c('span',{staticClass:"text-sm",staticStyle:{"color":"#868ba1","text-transform":"uppercase"}},[_vm._v("(e as que você não deseja enviar para Invoice)")])])]),_c('div',{staticClass:"span-3 document-card columns-12 sm-gap"},[_c('div',{staticClass:"span-12 flex ai-c jc-sb fgap2"},[_c('p',{staticClass:"text-dark text-uppercase text-bold text-lt mb-0"},[_vm._v(" Informações Gerais ")]),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveGeneralData()}}},[_c('span',{staticClass:"check-span light",class:{ active: _vm.getGeneralApprovedStatus }},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_vm._v(" Aprovar tudo ")]):_vm._e()]),(_vm.selectedDocument.documentType === 'invoices')?_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Número da Invoice","name":"invoiceNumber","value":_vm.selectedDocument.extractionResult.invoiceNumber
                ? _vm.selectedDocument.extractionResult.invoiceNumber
                : ''},on:{"input":(value) => _vm.updateField('invoiceNumber', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('invoiceNumberApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.invoiceNumberApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}],null,false,2001063479)})],1):_vm._e(),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":_vm.selectedDocument.documentType === 'invoices'
                ? 'Data da Invoice'
                : 'Data da PO',"date-no-icon":"","type":"date","name":"invoiceDate","value":_vm.selectedDocument.extractionResult.invoiceDate
                ? _vm.selectedDocument.extractionResult.invoiceDate
                : ''},on:{"input":(value) => _vm.updateField('invoiceDate', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('invoiceDateApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.invoiceDateApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Número da PO","name":"poNumber","value":_vm.selectedDocument.extractionResult.poNumber
                ? _vm.selectedDocument.extractionResult.poNumber
                : ''},on:{"input":(value) => _vm.updateField('poNumber', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('poNumberApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.poNumberApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),(_vm.selectedDocument.documentType === 'invoices')?_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Vencimento","type":"date","date-no-icon":"","name":"invoiceDue","value":_vm.selectedDocument.extractionResult.invoiceDue
                ? _vm.selectedDocument.extractionResult.invoiceDue
                : ''},on:{"input":(value) => _vm.updateField('invoiceDue', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('invoiceDueApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.invoiceDueApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}],null,false,992519895)})],1):_vm._e(),_c('div',{class:{
            'span-6': _vm.selectedDocument.documentType === 'invoices',
            'span-12': _vm.selectedDocument.documentType === 'purchase_orders',
          }},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"Total","name":"invoiceAmount","value":_vm.selectedDocument.extractionResult.invoiceAmount
                ? _vm.selectedDocument.extractionResult.invoiceAmount
                : ''},on:{"input":(value) => _vm.updateField('invoiceAmount', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('invoiceAmountApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.invoiceAmountApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Condições de Pagamento","name":"paymentTerms","value":_vm.selectedDocument.extractionResult.paymentTerms
                ? _vm.selectedDocument.extractionResult.paymentTerms
                : ''},on:{"input":(value) => _vm.updateField('paymentTerms', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('paymentTermsApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.paymentTermsApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Moeda","name":"currency","value":_vm.selectedDocument.extractionResult.currency
                ? _vm.selectedDocument.extractionResult.currency
                : ''},on:{"input":(value) => _vm.updateField('currency', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('currencyApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.currencyApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Incoterm","name":"incoterm","value":_vm.selectedDocument.extractionResult.incoterm
                ? _vm.selectedDocument.extractionResult.incoterm
                : ''},on:{"input":(value) => _vm.updateField('incoterm', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('incotermApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.incotermApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Origem","name":"vendorCountry","value":_vm.selectedDocument.extractionResult.vendorCountry
                ? _vm.selectedDocument.extractionResult.vendorCountry
                : ''},on:{"input":(value) => _vm.updateField('vendorCountry', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('vendorCountryApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.vendorCountryApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"Peso Líquido","name":"netWeight","value":_vm.selectedDocument.extractionResult.netWeight
                ? _vm.selectedDocument.extractionResult.netWeight
                : ''},on:{"input":(value) => _vm.updateField('netWeight', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('netWeightApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.netWeightApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-6"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"Peso Bruto","name":"grossWeight","value":_vm.selectedDocument.extractionResult.grossWeight
                ? _vm.selectedDocument.extractionResult.grossWeight
                : ''},on:{"input":(value) => _vm.updateField('grossWeight', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('grossWeightApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.grossWeightApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12 flex ai-c jc-sb fgap2 mt-2"},[_c('p',{staticClass:"text-dark text-uppercase text-bold text-lt mb-0"},[_vm._v(" Dados do Cliente ")]),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveBuyerData.apply(null, arguments)}}},[_c('span',{staticClass:"check-span light",class:{ active: _vm.getCustomerApprovedStatus }},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_vm._v(" Aprovar tudo ")]):_vm._e()]),_c('div',{staticClass:"span-12"},[_c('virtual-select',{attrs:{"tabindex":1,"placeholder":"Cliente","disabled":_vm.readOnly,"options":_vm.companies},model:{value:(_vm.selectedDocument.extractionResult.buyerName),callback:function ($$v) {_vm.$set(_vm.selectedDocument.extractionResult, "buyerName", $$v)},expression:"selectedDocument.extractionResult.buyerName"}}),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c jc-fe fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('buyerNameApproved')}}},[_c('span',[_vm._v("Aprovar Cliente")]),_c('span',{staticClass:"check-span lg light",class:{
                active: _vm.selectedDocument.extractionResult.buyerNameApproved,
              }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"CNPJ","name":"buyerFederalID","value":_vm.selectedDocument.extractionResult.buyerFederalID
                ? _vm.selectedDocument.extractionResult.buyerFederalID
                : ''},on:{"input":(value) => _vm.updateField('buyerFederalID', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('buyerFederalIDApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult
                        .buyerFederalIDApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Endereço / Localização","name":"buyerLocation","value":_vm.selectedDocument.extractionResult.buyerLocation
                ? _vm.selectedDocument.extractionResult.buyerLocation
                : ''},on:{"input":(value) => _vm.updateField('buyerLocation', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('buyerLocationApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.buyerLocationApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12 flex ai-c jc-sb fgap2 mt-2"},[_c('p',{staticClass:"text-dark text-uppercase text-bold text-lt mb-0"},[_vm._v(" Dados do Exportador ")]),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveExporterData.apply(null, arguments)}}},[_c('span',{staticClass:"check-span light",class:{ active: _vm.getExporterApprovedStatus }},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_vm._v(" Aprovar tudo ")]):_vm._e()]),_c('div',{staticClass:"span-12"},[_c('virtual-select',{attrs:{"tabindex":2,"placeholder":"Exportador","disabled":_vm.readOnly,"options":_vm.exporters,"invert":""},model:{value:(_vm.selectedDocument.extractionResult.vendorName),callback:function ($$v) {_vm.$set(_vm.selectedDocument.extractionResult, "vendorName", $$v)},expression:"selectedDocument.extractionResult.vendorName"}}),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c jc-fe fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('vendorNameApproved')}}},[_c('span',[_vm._v("Aprovar Exportador")]),_c('span',{staticClass:"check-span lg light",class:{
                active: _vm.selectedDocument.extractionResult.vendorNameApproved,
              }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"Endereço / Localização","name":"vendorLocation","value":_vm.selectedDocument.extractionResult.vendorLocation
                ? _vm.selectedDocument.extractionResult.vendorLocation
                : ''},on:{"input":(value) => _vm.updateField('vendorLocation', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('vendorLocationApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult
                        .vendorLocationApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"span-12"},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"País de Origem","name":"vendorCountry","value":_vm.selectedDocument.extractionResult.vendorCountry
                ? _vm.selectedDocument.extractionResult.vendorCountry
                : ''},on:{"input":(value) => _vm.updateField('vendorCountry', value)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('vendorCountryApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                    active:
                      _vm.selectedDocument.extractionResult.vendorCountryApproved,
                  }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1)]):_vm._e()]},proxy:true}])})],1)]),_c('div',{staticClass:"span-9 flex fd-c fgap1"},[_c('div',{staticClass:"document-card with-footer"},[_c('div',{staticClass:"columns-12 sm-gap"},[_c('div',{staticClass:"span-12 flex ai-c jc-sb fgap2"},[(_vm.selectedDocument.documentType === 'invoices')?_c('p',{staticClass:"text-dark text-uppercase text-bold text-lt mb-0"},[_vm._v(" Itens da Invoice ")]):_vm._e(),(_vm.selectedDocument.documentType === 'purchase_orders')?_c('p',{staticClass:"text-dark text-uppercase text-bold text-lt mb-0"},[_vm._v(" Itens da PO ")]):_vm._e()]),_c('div',{staticClass:"span-12"},[_c('div',{staticClass:"table-overflow",staticStyle:{"max-height":"580px","overflow":"auto"}},[_c('table',{staticClass:"original-documents-table",staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Descrição da Mercadoria")]),_c('th',[_vm._v("Quantidade")]),_c('th',[_vm._v("Unidade")]),_c('th',[_vm._v("Preço Unitário")]),_c('th',[_vm._v("Valor Total")]),_c('th',[(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveInvoiceItems.apply(null, arguments)}}},[_c('span',{staticClass:"check-span light",class:{ active: _vm.getInvoiceItemsApprovedStatus }},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_vm._v(" Aprovar tudo ")]):_vm._e()])])]),_c('tbody',[_vm._l((_vm.selectedDocument
                        .extractionResult.items),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center",staticStyle:{"width":"7%"}},[_c('p',{staticClass:"mb-0"},[_vm._v("ITEM")]),_c('p',{staticClass:"mb-0"},[_vm._v("# "+_vm._s(index + 1))])]),_c('td',{staticStyle:{"width":"28%"}},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"","name":"description","value":item.description},on:{"input":(value) => (item.description = value)}})],1),_c('td',{staticStyle:{"width":"10%"}},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"","name":"quantity","value":item.quantity},on:{"input":(value) => (item.quantity = value)}})],1),_c('td',{staticStyle:{"width":"10%"}},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"label":"","name":"uom","value":item.uom},on:{"input":(value) => (item.uom = value)}})],1),_c('td',{staticStyle:{"width":"10%"}},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"","name":"unitPrice","value":item.unitPrice},on:{"input":(value) => (item.unitPrice = value)}})],1),_c('td',{staticStyle:{"width":"10%"}},[_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"","name":"unitTotal","value":item.unitTotal},on:{"input":(value) => (item.unitTotal = value)}})],1),_c('td',{staticStyle:{"width":"15%"}},[(!_vm.readOnly)?_c('div',{staticClass:"flex ai-c fgap1"},[_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => (item.approved = !item.approved)).apply(null, arguments)}}},[_c('span',{staticClass:"check-span light",class:{
                                active: item.approved,
                              }},[_c('check-icon',{attrs:{"size":6,"color":"#ffffff"}})],1),_vm._v(" Aprovar ")]),_c('v-table-button',{attrs:{"color":"danger"},nativeOn:{"click":function($event){return _vm.removeLineItem(index)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('reject-icon',{attrs:{"size":"14"}})]},proxy:true}],null,true)},[_vm._v(" Remover ")])],1):_vm._e()])])}),_c('tr',[(!_vm.readOnly)?_c('td',{staticStyle:{"border-bottom":"1px solid #dee2e6"},attrs:{"colspan":"7"}},[_c('div',{staticClass:"flex ai-c jc-fe fgap2"},[_c('span',[_vm._v("Clique no botão para adicionar mais parcelas")]),_c('button',{staticClass:"crm-button iconed",on:{"click":function($event){$event.preventDefault();return _vm.addLineItem.apply(null, arguments)}}},[_c('span',{staticClass:"flex ai-c jc-c"},[_c('plus-icon',{attrs:{"color":"currentColor"}})],1)])])]):_vm._e()])],2)])])]),_c('div',{staticClass:"span-12 document-card-footer"},[_c('div',{staticClass:"flex jc-fe ai-c fgap1"},[(_vm.selectedDocument.documentType === 'invoices')?_c('label',[_vm._v("Valor total da Invoice")]):_vm._e(),(_vm.selectedDocument.documentType === 'purchase_orders')?_c('label',[_vm._v("Valor total da PO")]):_vm._e(),_c('v-input',{attrs:{"disabled":_vm.readOnly,"money-mask":"","label":"","name":"invoiceAmount","value":_vm.selectedDocument.extractionResult.invoiceAmount},on:{"input":(value) => _vm.updateField('invoiceAmount', value)}}),(!_vm.readOnly)?_c('a',{staticClass:"text-uppercase text-lt text-gray flex ai-c fgap1 mt-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkField('invoiceAmountApproved')}}},[_c('span',{staticClass:"check-span lg light",class:{
                      active:
                        _vm.selectedDocument.extractionResult
                          .invoiceAmountApproved,
                    }},[_c('check-icon',{attrs:{"size":12,"color":"#ffffff"}})],1),_vm._v(" Aprovar ")]):_vm._e()],1)])])]),(_vm.selectedDocument.extractionStatus === 'COMPLETE')?_c('div',[_c('div',{staticClass:"flex ai-c fgap2 jc-c"},[_c('a',{staticClass:"btn-approve",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveDocument(_vm.selectedDocument)}}},[_vm._v("Aprovar Documento")]),_c('a',{staticClass:"btn-cancel",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelRevision(_vm.selectedDocument.id)}}},[_vm._v("Cancelar Revisão")]),_c('a',{staticClass:"btn-reject",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rejectDocumentInModal(_vm.selectedDocument)}}},[_vm._v("Rejeitar Documento")])])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }