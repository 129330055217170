<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_427_809)">
      <path
        d="M31.5041 0H0.478577V30H31.5041V0Z"
        fill="white"
        fill-opacity="0.01"
      />
      <path
        d="M12.7595 12.5C15.2584 12.5 17.2841 10.5412 17.2841 8.125C17.2841 5.70876 15.2584 3.75 12.7595 3.75C10.2607 3.75 8.23499 5.70876 8.23499 8.125C8.23499 10.5412 10.2607 12.5 12.7595 12.5Z"
        fill="#DEE2E6"
        stroke="#343A40"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5551 4.375C22.869 5.14056 23.7478 6.53356 23.7478 8.125C23.7478 9.71644 22.869 11.1094 21.5551 11.875"
        stroke="#343A40"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.06403 25.5V26.25H22.455V25.5C22.455 22.6997 22.455 21.2996 21.8914 20.2301C21.3957 19.2892 20.6046 18.5243 19.6316 18.0449C18.5255 17.5 17.0775 17.5 14.1815 17.5H11.3375C8.44153 17.5 6.99354 17.5 5.8874 18.0449C4.91443 18.5243 4.12337 19.2892 3.62762 20.2301C3.06403 21.2996 3.06403 22.6997 3.06403 25.5Z"
        fill="#DEE2E6"
        stroke="#343A40"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.9186 26.25V25.5C28.9186 22.6997 28.9186 21.2996 28.355 20.2301C27.8593 19.2892 27.0682 18.5243 26.0952 18.0449"
        stroke="#343A40"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_427_809">
        <rect
          width="31.0256"
          height="30"
          fill="white"
          transform="translate(0.478577)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ContactsIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
