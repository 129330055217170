<template>
  <transition name="freight-quote-modal-sended">
    <div id="freight-quote-modal">
      <div class="modal-mask ht-100v">
        <div class="modal-wrapper ht-100v">
          <div class="modal-container">
            <div class="modal-header">
              <h5 class="tx-xs-20 tx-inverse mg-b-5">VISUALIZAÇÃO DE E-MAIL</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
                @click="$emit('close')"
              >
                <span aria-hidden="true"> X </span>
              </button>
            </div>

            <div class="modal-body">
              <div class="content scroll" v-html="html"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";

export default {
  name: "freight-quote-modal",
  props: {
    html: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
@import "../../../../../../../assets/css/modal.css";
@import "./styles.css";
</style>
