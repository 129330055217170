<template>
  <transition name="modal">
    <div class="modal-mask">
      <!-- <Loading ref="loading" label="Baixar Parcela" description="Processando sua requisação."></Loading> -->
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="tx-xs-20 tx-inverse mg-b-5">INCONSISTÊNCIA</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="$emit('close')"
            >
              <span aria-hidden="true"> X </span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-layout form-layout-2">
              <div class="row no-gutters">
                <div class="col-lg-3">
                  <div class="form-group mg-md-l--0 bd-t-0-force">
                    <label class="form-control-label">
                      Data <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-calendar op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <date-picker lang="pt-br"
                                     v-model="inconsistency.date"
                                     name="date"
                                     class="form-control"
                                     format="DD/MM/YY"
                                     :class="{ 'parsley-error': errors.has('date') }"
                                     v-validate="'required'"
                                     placeholder="Selecione"
                        ></date-picker>
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                    <span
                      v-show="errors.has('date')"
                      class="parsley-errors-list filled"
                    >Selecione uma Data</span
                    >
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">
                      Origem <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-navicon op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <select
                          class="custom-select form-control wd-100p"
                          v-model="inconsistency.source"
                          name="source"
                          :class="{ 'parsley-error': errors.has('source') }"
                          v-validate="'required'"
                          placeholder="Selecione"
                        >
                          <option value="">Selecione</option>
                          <option value="INTERNA">Interna</option>
                          <option value="FORNECEDOR">Fornecedor</option>
                          <option value="CLIENTE">Cliente</option>
                        </select>
                        <span
                          v-show="errors.has('source')"
                          class="parsley-errors-list filled"
                        >Selecione uma Origem</span
                        >
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <div
                      class="input-group-solved-after mg-l-10 wd-100p"
                      v-if="this.inconsistency.source === 'FORNECEDOR'"
                    >
                      <label class="form-control-label"> Fornecedor </label>
                      <v-select
                        v-model="inconsistency.company"
                        :on-search="getCompanies"
                        label="name"
                        :options="optionsCompanies"
                        name="company"
                        :class="{ 'parsley-error': errors.has('company') }"
                        v-validate="
                          this.inconsistency.source === 'FORNECEDOR'
                            ? 'required'
                            : ''
                        "
                      >
                        <template slot="option" slot-scope="option"
                        >{{ option.federalID }} | {{ option.name }}
                        </template
                        >
                      </v-select>
                      <span
                        v-show="errors.has('company')"
                        class="parsley-errors-list filled"
                      >Selecione um Fornecedor</span
                      >
                    </div>
                    <div
                      class="input-group-solved-after mg-l-10 wd-100p"
                      v-else-if="this.inconsistency.source === 'INTERNA'"
                    >
                      <label class="form-control-label"> Colaborador </label>
                      <v-select
                        v-model="inconsistency.employee"
                        label="name"
                        :options="employess"
                        name="employee"
                        :class="{ 'parsley-error': errors.has('employee') }"
                        v-validate="
                          this.inconsistency.source === 'INTERNA'
                            ? 'required'
                            : ''
                        "
                      >
                        <template slot="option" slot-scope="option"
                        >{{ option.federalID }} | {{ option.name }}
                        </template
                        >
                      </v-select>
                      <span
                        v-show="errors.has('employee')"
                        class="parsley-errors-list filled"
                      >Selecione um Colaborador</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group mg-md-l--0 bd-t-0-force">
                    <label class="form-control-label">
                      Tipo <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-navicon op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <select
                          class="custom-select form-control wd-100p"
                          v-model="inconsistency.type"
                          name="type"
                          :class="{ 'parsley-error': errors.has('source') }"
                          v-validate="'required'"
                        >
                          <option value="">Selecione</option>
                          <option value="OPERACIONAL">Operacional</option>
                          <option value="COBRANCA">Cobrança</option>
                          <option value="ATENDIMENTO">Atendimento</option>
                          <option value="FINANCEIRO">Financeiro</option>
                        </select>
                        <span
                          v-show="errors.has('type')"
                          class="parsley-errors-list filled"
                        >Selecione um Tipo</span
                        >
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">
                      Valor R$ <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-money op-5"></i>
                      </div>
                      <div class="input-group-solved-center">
                        <money
                          v-model="inconsistency.value"
                          v-bind="money"
                          class="form-control"
                          name="value"
                        ></money>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">
                      Peso <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-navicon op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <select
                          class="custom-select form-control wd-100p"
                          v-model="inconsistency.weight"
                          name="weight"
                        >
                          <option value="">Selecione</option>
                          <option value="BAIXO">Baixo</option>
                          <option value="MODERADO">Moderado</option>
                          <option value="GRAVE">Grave</option>
                        </select>
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group mg-md-l--0 bd-t-0-force">
                    <label class="form-control-label">
                      Etapa <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-walking op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <select
                          class="custom-select form-control wd-100p"
                          v-model="inconsistency.step"
                          name="step"
                          :class="{ 'parsley-error': errors.has('step') }"
                          v-validate="'required'"
                        >
                          <option
                            :value="step._links.self.href"
                            v-for="step in steps"
                            :key="step._links.self.href"
                          >
                            {{ step.description }}
                          </option>
                        </select>
                        <span
                          v-show="errors.has('step')"
                          class="parsley-errors-list filled"
                        >Selecione uma Etapa</span
                        >
                      </div>
                      <div class="input-group-solved-after"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group mg-md-l--0 bd-t-0-force">
                    <label class="form-control-label">
                      Descrição <small>(Obrigatório)</small></label
                    >
                    <div class="input-group-solved">
                      <div class="input-group-solved-before">
                        <i class="fa fa-info op-5"></i>
                      </div>
                      <div class="input-group-solved-after mg-l-10 wd-100p">
                        <textarea
                          class="form-control"
                          type="text"
                          v-model="inconsistency.description"
                          name="description"
                          :class="{
                            'parsley-error': errors.has('description'),
                          }"
                          v-validate="'required'"
                          rows="5"
                        >
                        </textarea>
                        <span
                          v-show="errors.has('description')"
                          class="parsley-errors-list filled"
                        >Digite uma Descrição</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-layout-footer bd pd-y-20 pd-x-40 bd-t-0">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click.prevent="submit()"
                    >
                      <i class="fa fa-edit mg-r-5"></i> SALVAR
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-sm btn-danger btn-block"
                      @click.prevent="$emit('close')"
                    >
                      <i class="fa fa-reply mg-r-5"></i> VOLTAR
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
import {mapState} from "vuex";
import {EventBus} from "@/events/EventBus.js";
import {Money} from "v-money";
import DatePicker from "vue2-datepicker";
import config from "@/config.js";

export default {
  name: "imp-form-inconsistencies-modal",
  props: ["model", "isForm", "finish"],
  components: {
    "date-picker": DatePicker,
    Money,
  },
  watch: {
    model(newVal, oldVal) {
      this.inconsistency = newVal;
    },
  },

  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      employess: [],
      oldModel: null,
      inconsistency: {
        responsability: "",
        company:
          this.model.customer && this.model.customer !== undefined
            ? this.model.customer
            : null,
        employee: null,
        description: null,
        weight: null,
        type: null,
        value: 0.0,
        step: null,
        importProcess: null,
        exportProcess: null,
        date: null,
      },
    };
  },

  methods: {
    handleClick() {
      this.$emit("clickedSomething", param1, param2);
    },
    isNewRecord() {
      if (this.model.id !== undefined && this.model.id !== null) return false;
      return true;
    },
    submit() {
      this.$validator.validateAll().then((response) => {
        if (response) this.save();
        else
          this.$swal(
            "Inconsistências",
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
      });
    },
    manageModel() {
      this.inconsistency.employee = this.oldModel.employee.name;
      this.inconsistency.company = this.oldModel.company.name;
    },
    async save() {
      const method = this.inconsistency.id !== undefined ? "put" : "post";
      const value = this.inconsistency;
      this.oldModel = value;

      value.importProcess = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;
      if (
        this.inconsistency.employee !== null &&
        this.inconsistency.employee !== null
      )
        value.employee = `${config.env.API_DEFAULT.host}/users/${this.inconsistency.employee.id}`;
      if (
        this.inconsistency.company !== null &&
        this.inconsistency.company !== null
      )
        value.company = `${config.env.API_DEFAULT.host}/companies/${this.inconsistency.company.id}`;

      if (
        this.inconsistency.employee !== null &&
        this.inconsistency.source === "INTERNA"
      )
        value.responsability = this.inconsistency.employee.name;
      else if (
        this.inconsistency.company !== null &&
        (this.inconsistency.source === "FORNECEDOR" ||
          this.inconsistency.source === "CLIENTE")
      )
        value.responsability = this.inconsistency.company.name;

      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Inconsistências",
          description: "Salvando dados, aguarde...",
        });

        let response;
        if (method == "put")
          response = await Axios.patch(value._links.self.href, value);
        else
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/processInconsistencies/`,
            value
          );

        if (response) {
          EventBus.$emit("Operational.imp.Form.Inconsistencies.load");

          this.inconsistency = {
            responsability: "",
            company: null,
            employee: null,
            description: null,
            weight: null,
            type: null,
            value: 0.0,
            step: null,
            importProcess: null,
            exportProcess: null,
            date: null,
          };
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          // this.manageModel();
          this.$emit("finish");
          this.$emit("close");
        }
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", "", "error");
      }
    },
    async getCompanies(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getCompanies",
        search
      );

      if (!response) {
        swal(
          "Empresas",
          "Ops, houve algum erro no carregamento das empresas! ",
          "error"
        );
      }
    },
    async getUsers(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getUsers",
        search
      );

      if (!response) {
        swal(
          "Colaboradores",
          "Ops, houve algum erro no carregamento dos colaboradores! ",
          "error"
        );
      }
    },
    async getEmployees() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/search/findUsersToInconsistencies`
        );

        this.employess = response.data._embedded.users;
      } catch (e) {
        swal(
          "Colaboradores",
          "Ops!, houve um erro inesperado ao buscar os colaboradores para vinculo de inconsistências! ",
          "error"
        );
      }
    },
    async getCustomers(search) {
      const response = await this.$store.dispatch(
        "ProcessStore/getCustomers",
        search
      );

      if (!response) {
        swal(
          "Empresas",
          "Ops, houve algum erro no carregamento dos clientes! ",
          "error"
        );
      }
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      optionsCustomers: (state) => state.ProcessStore.optionsCustomers,
      optionsCompanies: (state) => state.ProcessStore.optionsCompanies,
      optionsUsers: (state) => state.ProcessStore.optionsUsers,
      steps: (state) => state.ProcessStore.steps,
    }),
  },
  mounted() {
    if (this.inconsistency !== undefined && this.inconsistency !== null) {
      this.inconsistency = this.model;
      if (
        this.inconsistency.step !== null &&
        this.inconsistency.step !== undefined
      )
        this.inconsistency.step = `${config.env.API_DEFAULT.host}/processSteps/${this.inconsistency.step.id}`;
      // if(this.inconsistency.employee !== null && this.inconsistency.employee !== undefined) this.employee = config.env.API_DEFAULT.host + "/users/" + this.inconsistency.employee.id;
      // if(this.inconsistency.company !== null && this.inconsistency.company !== undefined) this.company = config.env.API_DEFAULT.host + "/companies/" + this.inconsistency.company.id;
    }

    this.getEmployees();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  background-color: #f8f9fa;
  padding: 20px 15px 10px 15px;
}

.modal-header .close {
  padding: 18px 20px 0px 20px !important;
}

.modal-body {
  margin: 0px !important;
  padding: 0px !important;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
