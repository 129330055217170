<template>
  <div class="skeleton-body">
    <ul>
      <li v-for="i in lines" :key="i" class="skeleton-list-item">
        <div>
          <div class="bar bar-title"></div>
          <div class="bar bar-description"></div>
        </div>

        <div>
          <div class="bar bar-button"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SkeletonComponent",
  props: {
    lines: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style scoped>
.skeleton-body {
  width: 100%;
  border: 2px solid #f5f6f7;
  border-radius: 15px;
  background-color: white;
  padding: 0 1rem;
  animation: pulse 1s ease-in-out 0s infinite;
}

.skeleton-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skeleton-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 0;
}

.skeleton-list-item:not(:last-of-type) {
  border-bottom: 2px solid #f5f6f7;
}

.skeleton-list-item > div {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.skeleton-body .bar {
  background-color: #f1f1f1;
  border-radius: 100px;
  height: 10px;
  width: 35%;
}

.skeleton-body .bar-title {
  background-color: #e2e2e2;
  height: 13px;
}

.skeleton-body .bar-description {
  width: 50%;
}

.skeleton-body .bar-button {
  align-self: flex-end;
  width: 100px;
  height: 18px;
  background-color: #e2e2e2;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
</style>
