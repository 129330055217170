<template>
  <div class="invoice-header">
    <h1 class="invoice-title">
      <img :src="this.$store.state.credentials.data.account.logo" />
    </h1>

    <div class="billed-from">
      <h6 class="tx-semibold">
        {{ this.$store.state.credentials.data.account.name }} ({{
          this.$store.state.credentials.data.account.federalID
        }})
      </h6>
      <p class="mg-b-0">
        {{ this.$store.state.credentials.data.account.address }}, Nº
        {{ this.$store.state.credentials.data.account.addressNumber }}
        <template v-if="this.$store.state.credentials.data.account.complement">
          , {{ this.$store.state.credentials.data.account.complement }}
        </template>

        <br />

        {{ this.$store.state.credentials.data.account.district }},
        {{ this.$store.state.credentials.data.account.city }} /
        {{ this.$store.state.credentials.data.account.federalUnity }} -
        {{ this.$store.state.credentials.data.account.postalCode }}

        <template v-if="this.$store.state.credentials.data.account.telephone1">
          <br />
          Telefone: {{ this.$store.state.credentials.data.account.telephone1 }}
        </template>

        <template v-if="this.$store.state.credentials.data.account.email">
          | Email: {{ this.$store.state.credentials.data.account.email }}
        </template>
        <br />
      </p>
    </div>
    <!-- billed-from -->
  </div>
  <!-- invoice-header -->
</template>
<script>
export default {
  name: "PrintableHead",
  props: ["model", "title", "items"],
  components: {},
};
</script>
