import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VeeValidate from 'vee-validate';
import Swal from 'sweetalert2';
import VueSwal from 'vue-swal';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueResource from 'vue-resource';
import {ClientTable} from 'vue-tables-2';
import VueNumerals from 'vue-numerals';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import vSelect from 'vue-select';
import Loading from '@/components/utils/Loading.vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueCurrencyFilter from 'vue-currency-filter';
import VueMask from 'v-mask';
import VueContentPlaceholders from 'vue-content-placeholders';
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import Toasted from 'vue-toasted';
import ViaCep from 'vue-viacep';
import VueNumber from 'vue-number-animation';
import Popover from 'vue-js-popover';
import store from './store';
import JsonExcel from 'vue-json-excel';

require('moment/locale/pt-br');

Vue.use(ViaCep);

Vue.use(VueMask);
Vue.use(Popover, {tooltip: true});
Vue.use(Toasted, {
  iconPack: 'fontawesome',
});
Vue.use(Tooltip);
Vue.use(VueContentPlaceholders);
Vue.component('v-select', vSelect);
Vue.component('LoadingComponent', Loading);
Vue.use(require('vue-shortkey'));
Vue.component('downloadExcel', JsonExcel);

Vue.use(VeeValidate);
Vue.use(VueMoment, {moment});
Vue.use(VueResource);
Vue.use(VueSwal);
Vue.use(ClientTable);
Vue.use(VueNumerals, {locale: 'vi'});
Vue.use(PerfectScrollbar);
Vue.use(VueNumber);
Vue.use(VueCurrencyFilter, {
  symbol: '',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
});

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css',
    `${window.location.protocol
    }//${window.location.hostname
    }:8080`
    + '/slim.css',
  ],
};

Vue.use(VueHtmlToPaper, options);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code !== null && error.code === 'ERR_NETWORK') {
      router.push({name: 'InactiveAPI'});
    } else if (
      (error.message
        !== "Cannot read properties of undefined (reading 'protocol')"
        && !error.response)
      || (error
        === "SyntaxError: Failed to execute 'open' on 'XMLHttpRequest': Invalid URL"
        && error
        !== "TypeError: Cannot read properties of undefined (reading 'protocol')")
    ) {
      Swal.fire(
        'Problema no Servidor',
        'Não está sendo possível estabelecer comunicação com o servidor de dados. Por favor, aguarde um pouco e tente novamente.',
        'error',
      );
    } else if (
      (error.response.status !== undefined
        && error.response.status !== null
        && error.response.status === 401)
      || error.response.data.error === 'invalid_token'
    ) {
      store.commit('includeCredentials', {
        userAuthenticated: false,
        accessToken: null,
        data: null,
      });
      store.commit('defaultLayoutDisabled');
      if (router.currentRoute.name !== 'login') {
        router.push('/login');
      }
    } else if (error.response.status === 503) {
      router.push('/error/503');
    } else if (error.response.status === 403) {
      router.push('/error/403');
    }

    return Promise.reject(error);
  },
);

router.beforeEach((to, from, next) => {
  if (
    to.path !== '/login'
    && to.path !== '/registro/empresa'
    && to.name !== 'RecoveryPassword'
    && to.name !== 'VerifyCodeRecoveryPassword'
    && to.name !== 'ChangePassword'
    && to.path !== '/credentials/info'
    && to.path !== '/operational/imp/desk'
    && to.path !== '/error/503'
    && to.path !== '/recuperarSenha'
    && to.path !== '/verificarCodigo'
  ) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.state.credentials.accessToken}`;

    if (store.state.credentials.data !== null && store.state.credentials.userAuthenticated === true && store.state.credentials.accessToken !== null) {
      store.commit('defaultLayoutEnabled');
      next();
    } else {
      store.commit('defaultLayoutDisabled');
      next('/login');
    }
  } else if (to.path == '/operational/imp/desk') {
    axios.defaults.headers.common.Authorization = `Bearer ${store.state.credentials.accessToken}`;
    next();
  } else {
    next();
  }
});

Vue.filter('formatSize', (size) => {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
  }
  if (size > 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }
  if (size > 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }
  if (size > 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${size.toString()} B`;
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
