<template>
  <div class="crm-page">
    <div class="contacts-sidebar">
      <ul class="report-routes">
        <li>
          <a
            href="#"
            @click.prevent="tab = 'Contatos de Clientes'"
            :class="tab === 'Contatos de Clientes' ? 'active' : ''"
          >
            <p class="title text-bold">Contatos de Clientes</p>
            <p class="mb-0 text-sm">Exportação de contatos de clientes</p>
          </a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="tab = 'Clientes'"
            :class="tab === 'Clientes' ? 'active' : ''"
          >
            <p class="title text-bold">Clientes</p>
            <p class="mb-0 text-sm">Exportação de clientes</p>
          </a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="tab = 'Contatos s/ Vinculação de Empresas'"
            :class="tab === 'Contatos s/ Vinculação de Empresas' ? 'active' : ''"
          >
            <p class="title text-bold">Contatos s/ Vinculação de Empresas</p>
            <p class="mb-0 text-sm">Exportação de contatos sem empresa</p>
          </a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="tab = 'Empresas em Geral'"
            :class="tab === 'Empresas em Geral' ? 'active' : ''"
          >
            <p class="title text-bold">Empresas em Geral</p>
            <p class="mb-0 text-sm">Exportação de empresas em geral</p>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="tab = 'Atividade em Geral'" :class="tab === 'Atividade em Geral' ? 'active' : ''">
            <p class="title text-bold">Relatório de Atividades</p>
            <p class="mb-0 text-sm">Exportação de atividades em geral</p>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="tab = 'Inconsistências em Geral'" :class="tab === 'Inconsistências em Geral' ? 'active' : ''">
            <p class="title text-bold">Relatório de Inconsistências</p>
            <p class="mb-0 text-sm">Exportação de inconsistências em geral</p>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="tab = 'Conquistas em Geral'" :class="tab === 'Conquistas em Geral' ? 'active' : ''">
            <p class="title text-bold">Relatório de Conquistas</p>
            <p class="mb-0 text-sm">Exportação de conquistas em geral</p>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="tab = 'Solicitações de dados'" :class="tab === 'Solicitações de dados' ? 'active' : ''">
            <p class="title text-bold">Solicitações de Dados</p>
            <p class="mb-0 text-sm">Solicitações de preenchimento de dados do contato</p>
          </a>
        </li>
      </ul>
    </div>

    <div class="contact-container">
      <div v-if="tab === 'Contatos de Clientes'" class="contact-container-overflow" style="padding: 0!important;">
        <customer-contact-report />
      </div>

      <div v-if="tab === 'Clientes'" class="contact-container-overflow" style="padding: 0!important;">
        <customer-report />
      </div>

      <div v-if="tab === 'Contatos s/ Vinculação de Empresas'" class="contact-container-overflow" style="padding: 0!important;">
        <customer-without-company-report />
      </div>

      <div v-if="tab === 'Empresas em Geral'" class="contact-container-overflow" style="padding: 0!important;">
        <company-report />
      </div>
      <div v-if="tab === 'Atividade em Geral'" class="contact-container-overflow" style="padding: 0!important;">
        <activity-report />
      </div>
      <div v-if="tab === 'Inconsistências em Geral'" class="contact-container-overflow" style="padding: 0!important;">
        <inconsistencies-report />
      </div>
      <div v-if="tab === 'Conquistas em Geral'" class="contact-container-overflow" style="padding: 0!important;">
        <conquests-report />
      </div>
      <div v-if="tab === 'Solicitações de dados'" class="contact-container-overflow" style="padding: 0!important;">
        <request-basic-data-report />
      </div>
    </div>
  </div>
</template>

<script>

import CustomerContactReport from '@/components/crm/CustomerContactReport.vue';
import CustomerReport from '@/components/crm/CustomerReport.vue';
import CustomerWithoutCompanyReport from '@/components/crm/CustomerWithoutCompanyReport.vue';
import CompanyReport from '@/components/crm/CompanyReport.vue';
import ActivityReport from '@/components/crm/ActivityReport.vue';
import InconsistenciesReport from '@/components/crm/InconsistenciesReport.vue';
import ConquestsReport from '@/components/crm/ConquestsReport.vue';
import RequestBasicDataReport from '@/components/crm/RequestBasicDataReport.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CrmReport',
  components: {
    CustomerContactReport,
    CustomerReport,
    CustomerWithoutCompanyReport,
    CompanyReport,
    ActivityReport,
    InconsistenciesReport,
    ConquestsReport,
    RequestBasicDataReport,
  },
  methods: {
    ...mapActions(['checkPrivilege']),
  },
  data() {
    return {
      tab: 'Contatos de Clientes',
    };
  },
  mounted() {
    this.checkPrivilege({ privilege: 'CRM_RELATORIOS', router: this.$router });
  },
};
</script>

<style scoped>
  .report-routes {
    list-style: none;
    padding: 0;
  }

  .report-routes li a {
    display: block;
    color: inherit;
    padding: 10px 15px;
    border-bottom: 1px solid #e2e2e2;
  }

  .report-routes li a:hover,
  .report-routes li a.active {
    background-color: #F8F9FA;
  }

  .report-routes .title {
    color: black;
    margin-bottom: -5px;
  }

  .four-grid {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  .fg-2 {
    grid-column: span 2;
  }

  .fg-3 {
    grid-column: span 3;
  }

  .fg-4 {
    grid-column: span 4;
  }

  fieldset {
    display: block;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    padding: 0 10px 10px 10px;
    margin-top: -10px;
  }

  fieldset legend {
    background-color: transparent;
    font-size: 14px;
    width: auto;
    padding: 0 5px;
  }
</style>
