import Axios from 'axios';
import config from '@/config';
import Swal from 'sweetalert2';
import { dateFilter } from 'vue-date-fns';
import moment from 'moment/moment';
import { document } from 'pdfjs-dist/lib/examples/node/domstubs';

export const originalDocumentsMixin = {
  data() {
    return {
      readOnly: false,
      modalOpen: false,
      modalInvoiceOpen: false,
      modalPoOpen: false,
      selectedDocuments: [],
      selectedDocument: {},
      tableLoading: false,
      documents: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        number: 0,
        numberOfElements: 0,
      },
      timer: null,
      filters: {
        fileName: '',
        user: null,
        extractionStatus: null,
        startDate: null,
        endDate: null,
      },
      companies: [],
    };
  },
  filters: {
    date: dateFilter,
    status: (value) => {
      switch (value) {
        case 'APPROVED':
          return 'Aprovado';
        case 'CREATED':
          return 'Invoice Criada';
        case 'REJECTED':
          return 'Rejeitado';
        case 'COMPLETE':
          return 'Pronto p/ Revisão';
        case 'AWAITING':
          return 'Extraindo Dados';
        default:
          return 'N/I';
      }
    },
    documentType: (value) => {
      switch (value) {
        case 'invoices':
          return 'Invoice / Proforma Invoice';
        case 'purchase_orders':
          return 'Ordem de Compra';
        default:
          return 'N/I';
      }
    },
  },
  async mounted() {
    if (this.$route.name !== 'IndexInvoices') {
      await this.getCompanies();
    }
    if (this.$route.name !== 'SearchOriginalDocuments') {
      await this.getDocuments();
    }
    window.addEventListener('reloadDocuments', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('reloadDocuments', this.listener);
  },
  methods: {
    // Get Companies (Customers)
    async getCompanies() {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/company/findAllCustomers`);
        this.companies = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: 'Carregar Clientes',
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: 'error',
        });
      }
    },
    listener() {
      this.getDocuments(this.documents.number);
    },
    async showInvoice(id) {
      const response = await Axios.get(
        `${config.env.API_DEFAULT.host}/invoices/${id}`,
      );

      this.selectedDocuments = [];
      this.selectedDocuments.push(response.data.data);
      this.selectedDocument = this.selectedDocuments[0];

      this.modalInvoiceOpen = true;
    },
    async showPo(id) {
      const response = await Axios.get(`${config.env.API_DEFAULT.host}/purchase-orders/${id}`);

      this.selectedDocuments = [];
      this.selectedDocuments.push(response.data.data);
      this.selectedDocument = this.selectedDocuments[0];

      this.modalInvoiceOpen = true;
    },
    // Create Invoice
    async createInvoice(id, readOnly = false) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando documento.',
      });

      this.readOnly = readOnly;

      if (typeof id === 'object') {
        const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'APPROVED')).map((doc) => doc.id);

        if (enabledIds.length <= 0) {
          Swal.fire({
            title: 'Criar invoice de documento original',
            html: '<p>Nenhum documento selecionado pode gerar invoices!</p>',
            type: 'error',
          });
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/original-documents/by-id-in`, {
            ids: enabledIds,
          });

          this.selectedDocuments = response.data.data;
          this.sanitizeOriginalDocument();
          this.selectedDocument = this.selectedDocuments[0];

          this.modalInvoiceOpen = true;
        }
      } else {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/original-documents/by-id/${id}`,
        );

        this.selectedDocuments.push(response.data.data);
        this.sanitizeOriginalDocument();
        this.selectedDocument = this.selectedDocuments[0];

        this.modalInvoiceOpen = true;
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    // Create PO
    async createPO(id, readOnly = false) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando documento.',
      });

      this.readOnly = readOnly;

      if (typeof id === 'object') {
        const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'APPROVED')).map((doc) => doc.id);

        if (enabledIds.length <= 0) {
          Swal.fire({
            title: 'Criar PO de documento original',
            html: '<p>Nenhum documento selecionado pode gerar PO\'s!</p>',
            type: 'error',
          });
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/original-documents/by-id-in`, {
            ids: enabledIds,
          });

          this.selectedDocuments = response.data.data;
          this.sanitizeOriginalDocument();
          this.selectedDocument = this.selectedDocuments[0];

          this.modalPoOpen = true;
        }
      } else {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/original-documents/by-id/${id}`,
        );

        this.selectedDocuments.push(response.data.data);
        this.sanitizeOriginalDocument();
        this.selectedDocument = this.selectedDocuments[0];

        this.modalPoOpen = true;
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    stringToFloat(item) {
      const result = typeof item === 'string' ? parseFloat(item.replace('.', '').replace(',', '.')) : item;
      return result > 0 ? result : null;
    },
    async storeInvoice(selectedDocument) {
      Swal.fire({
        title: 'Criar invoice',
        html: '<p>Tem certeza que deseja criar a invoice?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Criar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Cadastro de invoice...',
            description: 'Aguarde a criação do documento.',
          });

          try {
            const invoiceData = {
              buyerName: selectedDocument.extractionResult.buyerName,
              buyerAddress: selectedDocument.extractionResult.buyerLocation,
              buyerCity: selectedDocument.extractionResult.buyerCity,
              buyerCountry: selectedDocument.extractionResult.buyerCountry,
              vendorName: selectedDocument.extractionResult.vendorName,
              vendorAddress: selectedDocument.extractionResult.vendorLocation,
              vendorCity: selectedDocument.extractionResult.vendorCity,
              vendorCountry: selectedDocument.extractionResult.vendorCountry,
              number: selectedDocument.extractionResult.invoiceNumber,
              invoiceDate: selectedDocument.extractionResult.invoiceDate,
              invoiceDue: selectedDocument.extractionResult.invoiceDue,
              poNumber: selectedDocument.extractionResult.poNumber,
              incoterm: selectedDocument.extractionResult.incoterm,
              invoiceAmount: this.stringToFloat(selectedDocument.extractionResult.invoiceAmount),
              currency: selectedDocument.extractionResult.currency,
              paymentTerms: selectedDocument.extractionResult.paymentTerms,
              comments: selectedDocument.invoice.comments,
              originalDocumentId: selectedDocument.id,
              items: selectedDocument.extractionResult.items.map((item) => ({
                description: item.description,
                ncm: item.ncm,
                quantity: this.stringToFloat(item.quantity),
                unitValue: this.stringToFloat(item.unitPrice),
                totalValue: this.stringToFloat(item.unitTotal),
              })),
              installments: selectedDocument.invoice.installments.map((installment) => ({
                condition: installment.condition,
                conditionDate: installment.conditionDate,
                currency: installment.currency,
                dueDate: installment.dueDate,
                parcel: parseInt(installment.parcel),
                term: installment.term,
                value: this.stringToFloat(installment.value),
              })),
              grossWeight: this.stringToFloat(selectedDocument.extractionResult.grossWeight),
              netWeight: this.stringToFloat(selectedDocument.extractionResult.netWeight),
              volumes: this.stringToFloat(selectedDocument.extractionResult.volumes),
              volumeType: selectedDocument.extractionResult.volume_type,
            };

            await Axios.post(`${config.env.API_DEFAULT.host}/invoices`, invoiceData);

            const sanitizedData = this.sanitizeApprovedDocument(selectedDocument);

            await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/approve/${sanitizedData.id}`, {
              ...sanitizedData,
              extractionResult: JSON.stringify(sanitizedData.extractionResult),
              invoice: JSON.stringify(sanitizedData.invoice),
              extractionStatus: 'CREATED',
            });

            if (this.selectedDocuments.length > 1) {
              this.selectedDocuments = this.selectedDocuments.filter((doc) => doc.id !== selectedDocument.id);
              this.selectedDocument = this.selectedDocuments[0];
            } else {
              const event = new Event('reloadDocuments');
              dispatchEvent(event);
              this.closeInvoiceModal();
            }
          } catch (e) {
            Swal.fire({
              title: 'Cadastro de invoice',
              text: e.response.data.message,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    async storePo(selectedDocument) {
      Swal.fire({
        title: 'Criar PO',
        html: '<p>Tem certeza que deseja criar a PO?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Criar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Cadastro de PO...',
            description: 'Aguarde a criação do documento.',
          });

          try {
            const poData = {
              number: selectedDocument.extractionResult.poNumber,
              date: selectedDocument.extractionResult.invoiceDate ? moment(selectedDocument.extractionResult.invoiceDate).format('YYYY-MM-DD') : null,
              originalDocument: selectedDocument.id,
              termsOfPayment: selectedDocument.extractionResult.paymentTerms,
              incoterm: selectedDocument.extractionResult.incoterm,
              proforma: selectedDocument.invoice.proforma,
              termsOfDelivery: selectedDocument.invoice.termsOfDelivery,
              shipmentDate: selectedDocument.invoice.shipmentDate ? moment(selectedDocument.invoice.shipmentDate).format('YYYY-MM-DD') : null,
              placeOfDeparture: selectedDocument.invoice.placeOfDeparture,
              placeOfDischarge: selectedDocument.invoice.placeOfDischarge,
              amount: this.stringToFloat(selectedDocument.extractionResult.invoiceAmount),
              grossWeight: this.stringToFloat(selectedDocument.extractionResult.grossWeight),
              netWeight: this.stringToFloat(selectedDocument.extractionResult.netWeight),
              items: selectedDocument.extractionResult.items.map((item) => ({
                description: item.description,
                ncm: item.ncm,
                quantity: this.stringToFloat(item.quantity),
                unitValue: this.stringToFloat(item.unitPrice),
                totalValue: this.stringToFloat(item.unitTotal),
              })),
              buyer: parseInt(selectedDocument.extractionResult.buyerName),
              buyerAddress: selectedDocument.extractionResult.buyerLocation,
              buyerCity: selectedDocument.extractionResult.buyerCity,
              buyerCountry: selectedDocument.extractionResult.buyerCountry,
              vendor: parseInt(selectedDocument.extractionResult.vendorName),
              vendorAddress: selectedDocument.extractionResult.vendorLocation,
              vendorCity: selectedDocument.extractionResult.vendorCity,
              vendorCountry: selectedDocument.extractionResult.vendorCountry,
            };

            await Axios.post(`${config.env.API_DEFAULT.host}/purchase-orders`, poData);

            const sanitizedData = this.sanitizeApprovedDocument(selectedDocument);

            await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/approve/${sanitizedData.id}`, {
              ...sanitizedData,
              extractionResult: JSON.stringify(sanitizedData.extractionResult),
              invoice: JSON.stringify(sanitizedData.invoice),
              extractionStatus: 'CREATED',
            });

            if (this.selectedDocuments.length > 1) {
              this.selectedDocuments = this.selectedDocuments.filter((doc) => doc.id !== selectedDocument.id);
              this.selectedDocument = this.selectedDocuments[0];
            } else {
              const event = new Event('reloadDocuments');
              dispatchEvent(event);
              this.closePoModal();
            }
          } catch (e) {
            Swal.fire({
              title: 'Cadastro de PO',
              text: e.response.data.message,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    cancelInvoice(id) {
      Swal.fire({
        title: 'Cancelar Criação de invoice?',
        html: '<p>Tem certeza que deseja cancelar?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          if (this.selectedDocuments.length > 1) {
            this.selectedDocuments = this.selectedDocuments.filter((doc) => doc.id !== id);
            this.selectedDocument = this.selectedDocuments[0];
          } else {
            const event = new Event('reloadDocuments');
            dispatchEvent(event);
            this.closeInvoiceModal();
          }
        }
      });
    },
    cancelPo(id) {
      Swal.fire({
        title: 'Cancelar Criação de PO?',
        html: '<p>Tem certeza que deseja cancelar?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          if (this.selectedDocuments.length > 1) {
            this.selectedDocuments = this.selectedDocuments.filter((doc) => doc.id !== id);
            this.selectedDocument = this.selectedDocuments[0];
          } else {
            const event = new Event('reloadDocuments');
            dispatchEvent(event);
            this.closePoModal();
          }
        }
      });
    },
    // Cancel Revision
    cancelRevision(id) {
      Swal.fire({
        title: 'Cancelar Revisão de Documento Original',
        html: '<p>Tem certeza que deseja cancelar a revisão?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.value) {
          if (this.selectedDocuments.length > 1) {
            this.selectedDocuments = this.selectedDocuments.filter((doc) => doc.id !== id);
            this.selectedDocument = this.selectedDocuments[0];
          } else {
            const event = new Event('reloadDocuments');
            dispatchEvent(event);
            this.closeModal();
          }
        }
      });
    },
    // Reject Invioce in Revision
    async rejectDocumentInModal(document) {
      Swal.fire({
        title: 'Rejeitar Documento Original',
        html: '<p>Tem certeza que deseja rejeitar o documento?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Rejeitar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Rejeitando documento.',
          });

          try {
            await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/update-status/${document.id}`, {
              status: 'REJECTED',
            });

            document.extractionStatus = 'REJECTED';

            if (this.selectedDocuments.length <= 1) {
              const event = new Event('reloadDocuments');
              dispatchEvent(event);
              this.closeModal();
            }
          } catch (e) {
            Swal.fire({
              title: 'Reprovar Documento Original',
              text: e.response.data.message,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    sanitizeApprovedDocument(document) {
      const sanitized = { ...document };
      sanitized.documentType = document.documentType === 'invoices' ? 'INVOICE' : 'PURCHASE_ORDER';
      sanitized.extractionResult.items = sanitized.extractionResult.items.map((item) => ({
        ...item,
        quantity: this.stringToFloat(item.quantity),
        unitPrice: this.stringToFloat(item.unitPrice),
        unitTotal: this.stringToFloat(item.unitTotal),
      }));
      sanitized.invoice.installments = sanitized.invoice.installments.map((item) => ({
        ...item,
        value: this.stringToFloat(item.value),
      }));

      sanitized.invoice.installmentsNumber = this.stringToFloat(sanitized.invoice.installmentsNumber);

      sanitized.extractionResult.invoiceAmount = this.stringToFloat(sanitized.extractionResult.invoiceAmount);
      sanitized.extractionResult.grossWeight = this.stringToFloat(sanitized.extractionResult.grossWeight);
      sanitized.extractionResult.netWeight = this.stringToFloat(sanitized.extractionResult.netWeight);
      sanitized.extractionResult.invoiceAmount = this.stringToFloat(sanitized.extractionResult.invoiceAmount);
      sanitized.extractionResult.invoiceItemsCount = this.stringToFloat(sanitized.extractionResult.invoiceItemsCount);
      sanitized.extractionResult.volumes = this.stringToFloat(sanitized.extractionResult.volumes);
      sanitized.extractionResult.installmentsTotal = this.stringToFloat(sanitized.extractionResult.installmentsTotal);

      return sanitized;
    },
    async approveDocument(document) {
      Swal.fire({
        title: 'Aprovar Documento Original',
        html: '<p>Tem certeza que deseja aprovar o documento?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Aprovar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Aprovando Documento...',
          });

          try {
            const sanitizedData = this.sanitizeApprovedDocument(document);
            const response = await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/approve/${document.id}`, {
              ...sanitizedData,
              extractionResult: JSON.stringify(sanitizedData.extractionResult),
              invoice: JSON.stringify(sanitizedData.invoice),
              extractionStatus: 'APPROVED',
            });

            this.selectedDocument = response.data.data;
            this.selectedDocuments = this.selectedDocuments.map((document) => {
              if (document.id === this.selectedDocument.id) {
                return response.data.data;
              }
              return document;
            });
          } catch (e) {
            Swal.fire({
              title: 'Aprovar Documento Original',
              text: e.response.data.message,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    // Destroy Invoice
    async destroyOriginalDocument(id) {
      Swal.fire({
        title: 'Remover Documento Original',
        html: '<p>Tem certeza que deseja remover o documento?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Remover',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Removendo documento.',
          });

          try {
            if (typeof id === 'object') {
              const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'REJECTED')).map((doc) => doc.id);

              if (enabledIds.length <= 0) {
                Swal.fire({
                  title: 'Remover documento original',
                  html: '<p>Nenhum documento selecionado pode ser removido!</p>',
                  type: 'error',
                });
              } else {
                for (let i = 0; i < enabledIds.length; i++) {
                  await Axios.delete(`${config.env.API_DEFAULT.host}/original-documents/${enabledIds[i]}`);
                  this.documents.content = this.documents.content.filter((od) => od.id !== enabledIds[i]);
                }

                Swal.fire({
                  title: 'Remover documentos originais',
                  html: '<p>Documentos removidos com sucesso!</p>',
                  type: 'success',
                });
              }
            } else {
              await Axios.delete(`${config.env.API_DEFAULT.host}/original-documents/${id}`);

              this.documents.content = this.documents.content.filter((od) => od.id !== id);

              Swal.fire({
                title: 'Remover documento original',
                html: '<p>Documento removido com sucesso!</p>',
                type: 'success',
              });
            }
          } catch (e) {
            Swal.fire({
              title: 'Remover documento original',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    // Reject Invoice
    async rejectOriginalDocument(id) {
      Swal.fire({
        title: 'Rejeitar Documento Original',
        html: '<p>Tem certeza que deseja rejeitar o documento?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Rejeitar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Rejeitando documento.',
          });

          try {
            if (typeof id === 'object') {
              const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'COMPLETE')).map((doc) => doc.id);

              if (enabledIds.length <= 0) {
                Swal.fire({
                  title: 'Rejeitar documento original',
                  html: '<p>Nenhum documento selecionado pode ser rejeitado!</p>',
                  type: 'error',
                });
              } else {
                for (let i = 0; i < enabledIds.length; i++) {
                  const theDoc = this.documents.content.find((od) => od.id === enabledIds[i]);

                  await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/update-status/${enabledIds[i]}`, {
                    status: 'REJECTED',
                  });

                  theDoc.extractionStatus = 'REJECTED';
                }

                Swal.fire({
                  title: 'Rejeitar documentos originais',
                  html: '<p>Documentos Rejeitados com sucesso!</p>',
                  type: 'success',
                });
              }
            } else {
              const theDoc = this.documents.content.find((od) => od.id === id);

              await Axios.put(`${config.env.API_DEFAULT.host}/original-documents/update-status/${id}`, {
                status: 'REJECTED',
              });

              theDoc.extractionStatus = 'REJECTED';

              Swal.fire({
                title: 'Rejeitar documento original',
                html: '<p>Documento Rejeitado com sucesso!</p>',
                type: 'success',
              });
            }
          } catch (e) {
            Swal.fire({
              title: 'Rejeitar documento original',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    // Close Modal
    closeModal() {
      this.readOnly = false;
      this.modalOpen = false;
      this.selectedDocuments = [];
    },
    closeInvoiceModal() {
      this.readOnly = false;
      this.modalInvoiceOpen = false;
      this.selectedDocuments = [];
    },
    closePoModal() {
      this.readOnly = false;
      this.modalPoOpen = false;
      this.selectedDocuments = [];
    },
    // Sanitize Original Document Data
    sanitizeOriginalDocument() {
      this.selectedDocuments = this.selectedDocuments.map((document) => {
        if (document.extractionResult.invoiceDate) {
          document.extractionResult.invoiceDate = moment(document.extractionResult.invoiceDate).format('YYYY-MM-DD');
        }
        if (document.extractionResult.invoiceDue) {
          document.extractionResult.invoiceDue = moment(document.extractionResult.invoiceDue).format('YYYY-MM-DD');
        }
        document.extractionResult.volumes = document.extractionResult.volumes ? document.extractionResult.volumes : '';
        document.extractionResult.volume_type = document.extractionResult.volume_type ? document.extractionResult.volume_type : '';
        if (!document.invoice) {
          document.invoice = {
            installments: [],
            installmentsNumber: '',
            notifyCustomer: false,
            notifyDays: '',
            installmentsTotal: '',
            comments: '',
            placeOfDeparture: '',
            placeOfDischarge: '',
            proforma: '',
            shipmentDate: '',
            termsOfDelivery: '',
          };
        }
        return document;
      });
    },
    // Revision Modal
    async revisionModal(id, readOnly = false) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando documento.',
      });

      this.readOnly = readOnly;

      if (typeof id === 'object') {
        const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'COMPLETE')).map((doc) => doc.id);

        if (enabledIds.length <= 0) {
          Swal.fire({
            title: 'Revisar documento original',
            html: '<p>Nenhum documento selecionado pode ser revisado!</p>',
            type: 'error',
          });
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/original-documents/by-id-in`, {
            ids: enabledIds,
          });

          this.selectedDocuments = response.data.data;
          this.sanitizeOriginalDocument();
          this.selectedDocument = this.selectedDocuments[0];

          this.modalOpen = true;
        }
      } else {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/original-documents/by-id/${id}`,
        );

        this.modalOpen = true;
        this.selectedDocuments.push(response.data.data);
        this.sanitizeOriginalDocument();
        this.selectedDocument = this.selectedDocuments[0];
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    // Revision Modal
    async revisionPoModal(id, readOnly = false) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando documento.',
      });

      this.readOnly = readOnly;

      if (typeof id === 'object') {
        const enabledIds = this.documents.content.filter((doc) => !!(id.includes(doc.id) && doc.extractionStatus === 'COMPLETE')).map((doc) => doc.id);

        if (enabledIds.length <= 0) {
          Swal.fire({
            title: 'Revisar documento original',
            html: '<p>Nenhum documento selecionado pode ser revisado!</p>',
            type: 'error',
          });
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/original-documents/by-id-in`, {
            ids: enabledIds,
          });

          this.selectedDocuments = response.data.data;
          this.sanitizeOriginalDocument();
          this.selectedDocument = this.selectedDocuments[0];

          this.modalPoOpen = true;
        }
      } else {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/original-documents/by-id/${id}`,
        );

        this.modalPoOpen = true;
        this.selectedDocuments.push(response.data.data);
        this.sanitizeOriginalDocument();
        this.selectedDocument = this.selectedDocuments[0];
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    // Get Original Documents
    async getDocuments(page = 0) {
      if (this.$route.meta.disableSearch === false) {
        return;
      }
      this.tableLoading = true;
      try {
        let type = null;
        if (this.$route.meta.documentType !== null) {
          type = this.$route.meta.documentType;
        }

        let response = null;

        if (type) {
          response = await Axios.get(`${config.env.API_DEFAULT.host}/original-documents/by-status/${type}`, {
            params: {
              page,
              sort: 'id,desc',
            },
          });
        } else {
          response = await Axios.get(`${config.env.API_DEFAULT.host}/original-documents/search`, {
            params: {
              page,
              sort: 'id,desc',
              startDate: moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            },
          });
        }

        this.documents = response.data.data;
      } catch (e) {
        Swal.fire({
          title: 'Recuperação de documentos originais',
          html: `<p>${e.response.data.message}</p>`,
          type: 'error',
        });
      }
      this.tableLoading = false;
    },
    // Update field of document in revision
    updateField(field, value) {
      this.selectedDocument.extractionResult[field] = value;
    },
    updateInvoiceField(field, value) {
      if (value === false) {
        this.selectedDocument.invoice[field] = false;
      } else {
        this.selectedDocument.invoice[field] = value;
      }
      this.$nextTick(() => {
        this.selectedDocument = {
          ...this.selectedDocument,
        };
      });
    },
    // Toggle Field status of document in revision
    checkField(field) {
      this.selectedDocument.extractionResult[field] = !this.selectedDocument.extractionResult[field];
    },
    // Check all general fields
    approveGeneralData() {
      this.selectedDocument = {
        ...this.selectedDocument,
        extractionResult: {
          ...this.selectedDocument.extractionResult,
          invoiceNumberApproved: true,
          invoiceDateApproved: true,
          poNumberApproved: true,
          invoiceDueApproved: true,
          invoiceAmountApproved: true,
          paymentTermsApproved: true,
          currencyApproved: true,
          incotermApproved: true,
          vendorCountryApproved: true,
          netWeightApproved: true,
          grossWeightApproved: true,
        },
      };
    },
    // Check all exporter fields
    approveExporterData() {
      this.selectedDocument = {
        ...this.selectedDocument,
        extractionResult: {
          ...this.selectedDocument.extractionResult,
          vendorNameApproved: true,
          vendorLocationApproved: true,
          vendorCountryApproved: true,
        },
      };
    },
    // Check all invoice items
    approveInvoiceItems() {
      this.selectedDocument = {
        ...this.selectedDocument,
        extractionResult: {
          ...this.selectedDocument.extractionResult,
          items: this.selectedDocument.extractionResult.items.map((item) => ({
            ...item,
            approved: true,
          })),
        },
      };
    },
    // Check all Buyer items
    approveBuyerData() {
      this.selectedDocument = {
        ...this.selectedDocument,
        extractionResult: {
          ...this.selectedDocument.extractionResult,
          buyerLocationApproved: true,
          buyerFederalIDApproved: true,
          buyerNameApproved: true,
        },
      };
    },
    // Search for Original Documents
    async getSearchDocuments(page = 0) {
      this.tableLoading = true;
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/original-documents/search`, {
          params: {
            fileName: this.$route.query.fileName ? this.$route.query.fileName : '',
            extractionStatus: this.$route.query.extractionStatus ? this.$route.query.extractionStatus : '',
            startDate: this.$route.query.startDate ? this.$route.query.startDate : '',
            endDate: this.$route.query.endDate ? this.$route.query.endDate : '',
            page,
            sort: 'id,desc',
          },
        });

        this.documents = response.data.data;
      } catch (e) {
        Swal.fire({
          title: 'Recuperação de documentos originais',
          html: `<p>${e.response.data.message}</p>`,
          type: 'error',
        });
      }
      this.tableLoading = false;
    },
    addLineItem() {
      this.selectedDocument.extractionResult.items.push({
        description: '',
        quantity: '',
        uom: '',
        unitPrice: '',
        unitTotal: '',
      });
    },
    removeLineItem(index) {
      this.selectedDocument.extractionResult.items.splice(index, 1);
    },
    addInstallmentItem() {
      this.selectedDocument.invoice.installments.push({
        parcel: this.selectedDocument.invoice.installments.length + 1,
        condition: '',
        conditionDate: '',
        term: '',
        dueDate: '',
        currency: '',
        value: '',
      });

      this.$nextTick(() => {
        this.selectedDocument = {
          ...this.selectedDocument,
        };
      });
    },
    removeInstallmentItem(index) {
      this.selectedDocument.invoice.installments.splice(index, 1);
      this.$nextTick(() => {
        this.selectedDocument = {
          ...this.selectedDocument,
        };
      });
    },

    // Invoices Actions
    invoiceUpdateField(field, value) {
      this.selectedDocument[field] = value;
    },
    invoiceUpdateBuyerField(buyerData) {
      this.selectedDocument.buyer = {
        id: buyerData.value,
        completeName: buyerData.label,
      };
    },
    invoiceUpdateVendorField(vendorData) {
      this.selectedDocument.vendor = {
        id: vendorData.value,
        completeName: vendorData.label,
      };
    },
    invoiceAddLineItem() {
      this.selectedDocument.items.push({
        description: '',
        ncm: '',
        quantity: '',
        unitValue: 0,
        totalValue: 0,
      });
    },
    invoiceRemoveLineItem(index) {
      this.selectedDocument.items.splice(index, 1);
    },
    invoiceAddInstallmentItem() {
      this.selectedDocument.installments.push({
        parcel: this.selectedDocument.installments.length + 1,
        condition: '',
        conditionDate: '',
        term: '',
        dueDate: '',
        currency: '',
        value: '',
      });
    },
    invoiceRemoveInstallmentItem(index) {
      this.selectedDocument.installments.splice(index, 1);
    },
    async updateInvoice(invoice) {
      Swal.fire({
        title: 'Atualizar Invoice',
        html: '<p>Tem certeza que deseja atualizar a invoice?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Atualizar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Atualizando Invoice...',
          });

          try {
            const formattedData = {
              ...invoice,
              number: invoice.invoiceNumber,
              originalDocumentId: invoice.originalDocument.id,
              invoiceAmount: this.stringToFloat(invoice.invoiceAmount),
              netWeight: this.stringToFloat(invoice.netWeight),
              grossWeight: this.stringToFloat(invoice.grossWeight),
              buyer: invoice.buyer.id,
              vendor: invoice.vendor.id,
              items: invoice.items.map((item) => ({
                ...item,
                unitValue: this.stringToFloat(item.unitValue),
                totalValue: this.stringToFloat(invoice.totalValue),
              })),
              installments: invoice.installments.map((item) => ({
                ...item,
                value: this.stringToFloat(item.value),
              })),
            };

            await Axios.put(`${config.env.API_DEFAULT.host}/invoices/${invoice.id}`, formattedData);

            Swal.fire({
              title: 'Invoice Atualizada',
              type: 'success',
            });
          } catch (e) {
            Swal.fire({
              title: 'Atualizar Invoice',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    async destroyInvoice(id) {
      Swal.fire({
        title: 'Remover Invoice',
        html: '<p>Tem certeza que deseja remover a invoice?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Remover',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Removendo Invoice...',
          });

          try {
            await Axios.delete(`${config.env.API_DEFAULT.host}/invoices/${id}`);

            Swal.fire({
              title: 'Invoice Removida!',
              type: 'success',
            });

            this.documents.content = this.documents.content.filter((od) => od.id !== id);

            const event = new Event('reloadDocuments');
            dispatchEvent(event);

            this.closeInvoiceModal();
          } catch (e) {
            Swal.fire({
              title: 'Erro ao remover invoice',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    async destroyPo(id) {
      Swal.fire({
        title: 'Remover PO',
        html: '<p>Tem certeza que deseja remover a PO?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Remover',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Removendo PO...',
          });

          try {
            await Axios.delete(`${config.env.API_DEFAULT.host}/purchase-orders/${id}`);

            Swal.fire({
              title: 'PO Removida!',
              type: 'success',
            });

            this.documents.content = this.documents.content.filter((od) => od.id !== id);

            const event = new Event('reloadDocuments');
            dispatchEvent(event);

            this.closeInvoiceModal();
          } catch (e) {
            Swal.fire({
              title: 'Erro ao remover PO',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
    async updatePo(po) {
      Swal.fire({
        title: 'Atualizar PO',
        html: '<p>Tem certeza que deseja atualizar a PO?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Atualizar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Atualizando PO...',
          });

          try {
            const poData = {
              ...po,
              amount: this.stringToFloat(po.amount),
              invoice: (po.invoice != null && po.invoice.length > 0 && po.invoice !== []) ? parseInt(po.invoice.id) : null,
              originalDocument: parseInt(po.originalDocument.id),
              buyer: parseInt(po.buyer.id),
              vendor: parseInt(po.vendor.id),
              date: po.date ? moment(po.date).format('YYYY-MM-DD') : null,
              shipmentDate: po.shipmentDate ? moment(po.shipmentDate).format('YYYY-MM-DD') : null,
              grossWeight: this.stringToFloat(po.grossWeight),
              netWeight: this.stringToFloat(po.netWeight),
              items: po.items.map((item) => ({
                description: item.description,
                ncm: item.ncm,
                quantity: this.stringToFloat(item.quantity),
                unitValue: this.stringToFloat(item.unitValue),
                totalValue: this.stringToFloat(item.totalValue),
              })),
            };

            await Axios.put(`${config.env.API_DEFAULT.host}/purchase-orders/${po.id}`, poData);

            Swal.fire({
              title: 'PO Atualizada',
              type: 'success',
            });
          } catch (e) {
            Swal.fire({
              title: 'Atualizar PO',
              html: `<p>${e.response.data.message}</p>`,
              type: 'error',
            });
          }

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      });
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.name === 'SearchOriginalDocuments') {
          this.getSearchDocuments(this.$route.query.page ? this.$route.query.page : 0, {
            fileName: this.$route.query.fileName ? this.$route.query.fileName : '',
            extractionStatus: this.$route.query.extractionStatus ? this.$route.query.extractionStatus : '',
            startDate: this.$route.query.startDate ? this.$route.query.startDate : '',
            endDate: this.$route.query.endDate ? this.$route.query.endDate : '',
          });
        }
      },
    },
  },
};
