<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <bankAccount-form-general
                :bankAccount="bankAccount"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import BankAccount from "@/models/BankAccount.js";
import BankAccountsFormGeneral from "@/views/finances/bankAccounts/Form.General.vue";

export default {
  name: "BankAccountForm",
  components: {
    "bankAccount-form-general": BankAccountsFormGeneral,
  },
  data() {
    return {
      mainTitle: "Contas Bancárias / Nova Conta",
      modelTitle: "Conta Bancária",
      bankAccount: new BankAccount(),
      oldModel: new BankAccount(),
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/bankAccounts/${this.$route.params.id}`
      )
        .then((response) => {
          this.bankAccount = response.data;

          Axios.get(this.bankAccount._links.bank.href)
            .then((response) => {
              this.$set(this.bankAccount, "bank", response.data);
            })
            .catch((error) => {
              this.$swal("Bancos", `Ops, houve algum erro: ${error}`, "error");
            });

          Axios.get(this.bankAccount._links.account.href)
            .then((response) => {
              this.$set(this.bankAccount, "account", response.data);
            })
            .catch((error) => {
              this.$swal("Filiais", `Ops, houve algum erro: ${error}`, "error");
            });
        })
        .catch((error) => {
          this.$swal(
            "Contas Bancarárias",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
