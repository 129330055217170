var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-layout form-layout-2"},[(_vm.modal.show)?_c('imp-form-inconsistencies-modal',{attrs:{"model":_vm.inconsistency,"isForm":false},on:{"close":function($event){return _vm.closeModal('close')},"save":function($event){return _vm.save()},"finish":function($event){return _vm.closeModal('finish')}}}):_vm._e(),_c('div',{staticClass:"report-summary-header mg-b-5 mg-t-0"},[_vm._m(0),_c('div',[_c('a',{staticClass:"btn btn-info",attrs:{"href":"#"},on:{"click":_vm.showModal}},[_c('i',{staticClass:"icon ion-plus tx-22"}),_vm._v(" ADICIONAR ")])])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table display responsive nowrap dataTable no-footer dtr-inline tx-13"},[_vm._m(1),(this.inconsistencies.length > 0)?[_c('tbody',_vm._l((_vm.inconsistencies),function(item){return _c('tr',{key:'inconsistency-id-' + item.id},[_c('td',{staticClass:"valign-middle tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"valign-middle tx-center"},[(item.source === 'INTERNA')?_c('span',{staticClass:"tx-danger tx-bold tx-lato"},[_vm._v(" Interna ")]):(item.source === 'FORNECEDOR')?_c('span',{staticClass:"tx-warning tx-bold tx-lato"},[_vm._v(" Fornecedor ")]):(item.source === 'CLIENTE')?_c('span',{staticClass:"tx-primary tx-bold tx-lato"},[_vm._v(" Cliente ")]):_vm._e(),_c('br'),(
                  item.employee !== undefined &&
                  item.employee !== null &&
                  item.source === 'INTERNA'
                )?_c('small',[_vm._v(" "+_vm._s(item.employee.name))]):(
                  item.company !== undefined &&
                  item.company !== null &&
                  item.source === 'FORNECEDOR'
                )?_c('small',[_vm._v(" "+_vm._s(item.company.name))]):(
                  item.company !== undefined &&
                  item.company !== null &&
                  item.source === 'CLIENTE'
                )?_c('small',[_vm._v(" "+_vm._s(item.company.name))]):_vm._e()]),_c('td',{staticClass:"valign-middle tx-center"},[(item.description != null)?_c('span',[_vm._v(" "+_vm._s(item.description)+" "),_c('br')]):_vm._e(),_c('strong',[_vm._v(_vm._s(item.type))])]),_c('td',{staticClass:"valign-middle tx-center tx-right tx-12 tx-bold"},[_vm._v(" "+_vm._s(_vm._f("numeralFormat")(item.value,"0,0.00"))+" ")]),_c('td',{staticClass:"valign-middle tx-center tx-center tx-12 tx-bold"},[_vm._v(" "+_vm._s(item.weight)+" ")]),_c('td',{staticClass:"valign-middle tx-center"},[(item.step != null)?_c('span',[_vm._v(" "+_vm._s(item.step.description)+" ")]):_c('span',[_vm._v(" Nenhuma Etapa ")])]),_c('td',{staticClass:"valign-middle tx-center"},[_c('a',{staticClass:"btn btn-danger btn-icon rounded-circle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(item)}}},[_vm._m(2,true)]),_c('a',{staticClass:"btn btn-warning btn-icon rounded-circle mg-l-10",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(item)}}},[_vm._m(3,true)])])])}),0)]:[_vm._m(4)]],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3"},[_vm._v("Inconsistências do Processo")]),_c('p',{staticClass:"mg-b-0"},[_c('i',{staticClass:"icon ion-information-circled mg-r-3"}),_vm._v(" Lançamento de inconsistências do processo. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"tx-10"},[_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Data")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Origem")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Descrição / Tipo")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Valor R$")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Peso")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Etapa")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Ações")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon ion-trash-a"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon ion-edit"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',[_c('td',{staticClass:"valign-middle tx-center",attrs:{"colspan":"7"}},[_vm._v(" Nenhuma inconsistência foi cadastrada. ")])])])
}]

export { render, staticRenderFns }