var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"light-bg p-3 bd-b"},[_c('div',[_c('div',{class:[
          'flex ai-c jc-sb fw-w fgap2',
        ]},[_vm._m(0),_c('div',{staticClass:"flex ai-c fgap1"},[(_vm.generatedInTableCompleted)?_c('download-excel',{staticClass:"plus-button bg-success",attrs:{"data":_vm.excelContacts,"fields":_vm.excelFields,"name":`empresas-em-geral-${_vm.formatDateMethod(new Date())}.xls`}},[_vm._v(" EXCEL ")]):_vm._e(),_c('button',{staticClass:"plus-button bg-info",attrs:{"type":"button"},on:{"click":function($event){_vm.addDialog = !_vm.addDialog}}},[_vm._v(" FILTROS ")])],1)]),_c('transition',{attrs:{"name":"activity","mode":"out-in"}},[(_vm.addDialog)?_c('div',[_c('div',{staticClass:"four-grid mt-4"},[_c('div',{staticClass:"four-grid"},[_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"enabled","label":"Situação","clearable":true,"options":[
                        {
                          label: 'Habilitado',
                          value: true,
                        },
                        {
                          label: 'Desabilitado',
                          value: 'false',
                        },
                      ]},model:{value:(_vm.model.enabled),callback:function ($$v) {_vm.$set(_vm.model, "enabled", $$v)},expression:"model.enabled"}})],1),_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"compliance","label":"Compliance","clearable":true,"options":[
                        {
                          label: 'Sim',
                          value: true,
                        },
                        {
                          label: 'Não',
                          value: 'false',
                        },
                      ]},model:{value:(_vm.model.compliance),callback:function ($$v) {_vm.$set(_vm.model, "compliance", $$v)},expression:"model.compliance"}})],1),_c('div',{staticClass:"fg-4"},[_c('v-input',{attrs:{"name":"initialOpeningDate","label":"Data de Fundação Inicial","type":"date"},model:{value:(_vm.model.initialOpeningDate),callback:function ($$v) {_vm.$set(_vm.model, "initialOpeningDate", $$v)},expression:"model.initialOpeningDate"}})],1),_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"minimalComission","label":"Comissão Mínima?","clearable":true,"options":[
                        {
                          label: 'Sim',
                          value: true,
                        },
                        {
                          label: 'Não',
                          value: 'false',
                        },
                      ]},model:{value:(_vm.model.minimalComission),callback:function ($$v) {_vm.$set(_vm.model, "minimalComission", $$v)},expression:"model.minimalComission"}})],1)]),_c('div',{staticClass:"four-grid"},[_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"taxRegime","label":"Regime Fiscal","clearable":true,"options":[
                        {
                          label: 'Lucro Presumido',
                          value: '0',
                        },
                        {
                          label: 'Lucro Real',
                          value: '1',
                        },
                        {
                          label: 'Simples',
                          value: '2',
                        },
                      ]},model:{value:(_vm.model.taxRegime),callback:function ($$v) {_vm.$set(_vm.model, "taxRegime", $$v)},expression:"model.taxRegime"}})],1),_c('div',{staticClass:"fg-4"},[_c('v-input',{attrs:{"name":"city","label":"Cidade"},model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})],1),_c('div',{staticClass:"fg-4"},[_c('v-input',{attrs:{"name":"finalOpeningDate","label":"Data de Fundação Final","type":"date"},model:{value:(_vm.model.finalOpeningDate),callback:function ($$v) {_vm.$set(_vm.model, "finalOpeningDate", $$v)},expression:"model.finalOpeningDate"}})],1),_c('div',{staticClass:"fg-4"},[_c('select-wrapper',{attrs:{"name":"radarModality","label":"Modalidade do Radar","clearable":true,"options":[
                        {
                          label: 'Ilimitado',
                          value: 'ILIMITED'
                        },
                        {
                          label: 'Limitado USD 50.000',
                          value: 'LIMITED',
                        },
                        {
                          label: 'Limitado USD 150.000',
                          value: 'LIMITED'
                        },
                        {
                          label: 'Expressa',
                          value: 'EXPRESS'
                        },
                      ]},model:{value:(_vm.model.radarModality),callback:function ($$v) {_vm.$set(_vm.model, "radarModality", $$v)},expression:"model.radarModality"}})],1)]),_c('div',{staticClass:"fg-2 four-grid"},[_c('div',{staticClass:"fg-2"},[_c('fieldset',[_c('legend',[_vm._v("Colunas disponíveis")]),_c('ul',{staticClass:"columns-list"},_vm._l((_vm.availableColumns),function(column){return _c('li',{key:column.field},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();column.hide = false}}},[_vm._v(" "+_vm._s(column.name)+" ")])])}),0)])]),_c('div',{staticClass:"fg-2"},[_c('fieldset',[_c('legend',[_vm._v("Colunas selecionadas")]),_c('ul',{staticClass:"columns-list selecteds"},_vm._l((_vm.selectedColumns),function(column){return _c('li',{key:column.field},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();column.hide = true}}},[_vm._v(" "+_vm._s(column.name)+" ")])])}),0)])])])]),_c('div',{staticClass:"four-grid mt-4"},[_c('div',[_c('v-switch',{attrs:{"name":"isProvider","labels":{
                    checked: 'Fornecedor da Empresa',
                    unchecked: 'Fornecedor da Empresa',
                  }},model:{value:(_vm.model.isProvider),callback:function ($$v) {_vm.$set(_vm.model, "isProvider", $$v)},expression:"model.isProvider"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isCustomBroker","labels":{
                    checked: 'Despachante Aduaneiro',
                    unchecked: 'Despachante Aduaneiro',
                  }},model:{value:(_vm.model.isCustomBroker),callback:function ($$v) {_vm.$set(_vm.model, "isCustomBroker", $$v)},expression:"model.isCustomBroker"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isForwarder","labels":{
                    checked: 'Forwarder',
                    unchecked: 'Forwarder',
                  }},model:{value:(_vm.model.isForwarder),callback:function ($$v) {_vm.$set(_vm.model, "isForwarder", $$v)},expression:"model.isForwarder"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isCommissioned","labels":{
                    checked: 'Comissionado',
                    unchecked: 'Comissionado',
                  }},model:{value:(_vm.model.isCommissioned),callback:function ($$v) {_vm.$set(_vm.model, "isCommissioned", $$v)},expression:"model.isCommissioned"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isExporter","labels":{
                    checked: 'Exportador / Fabricante',
                    unchecked: 'Exportador / Fabricante',
                  }},model:{value:(_vm.model.isExporter),callback:function ($$v) {_vm.$set(_vm.model, "isExporter", $$v)},expression:"model.isExporter"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isConveyor","labels":{
                    checked: 'Transportador',
                    unchecked: 'Transportador',
                  }},model:{value:(_vm.model.isConveyor),callback:function ($$v) {_vm.$set(_vm.model, "isConveyor", $$v)},expression:"model.isConveyor"}})],1),_c('div',[_c('v-switch',{attrs:{"name":"isShipowner","labels":{
                    checked: 'Armador',
                    unchecked: 'Armador',
                  }},model:{value:(_vm.model.isShipowner),callback:function ($$v) {_vm.$set(_vm.model, "isShipowner", $$v)},expression:"model.isShipowner"}})],1)]),_c('div',{staticClass:"flex ai-c jc-c fgap2 mt-4"},[_c('button',{staticClass:"report-button",on:{"click":function($event){$event.preventDefault();return _vm.generateInTable.apply(null, arguments)}}},[_vm._v("GERAR EM TELA")])])]):_vm._e()])],1)]),(_vm.generatedInTableCompleted)?_c('div',{staticClass:"ph-2 sp-3 mt-4"},[_c('h6',{staticClass:"slim-card-title tx-info"},[_vm._v("Foram encontrados "+_vm._s(_vm.contacts.length)+" empresas")]),_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-overflow"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.selectedColumns),function(column){return _c('th',{key:column.field},[_vm._v(" "+_vm._s(column.name)+" ")])}),0)]),_c('tbody',[(_vm.contacts.length == 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.selectedColumns.length}},[_vm._m(1),_c('h4',{staticClass:"tx-gray-800"},[_vm._v("Nenhuma empresa foi encontrada")]),_c('p',[_vm._v("Verifique se o filtro está correto e tente realizar um novo filtro.")])])]):_vm._l((_vm.contacts),function(contact){return _c('tr',{key:contact.id},_vm._l((_vm.selectedColumns),function(column){return _c('td',{key:column.field},[(column.type === 'boolean')?_c('span',[_vm._v(" "+_vm._s(contact[column.field])+" ")]):(column.type === 'date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(contact[column.field]))+" ")]):(column.format === 'cnpj')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCustomerCnpj(contact[column.field]))+" ")]):(contact[column.field] === null || contact[column.field] === '')?_c('span',[_vm._v(" N/I ")]):_c('span',[_vm._v(" "+_vm._s(contact[column.field])+" ")])])}),0)})],2)])])])]):_c('div',{staticClass:"ph-2 sp-3 mt-4 bd-0 pd-50 tx-center"},[_vm._m(2),_c('h4',{staticClass:"tx-gray-800"},[_vm._v("APLIQUE UM FILTRO")]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb-0"},[_vm._v("EXPORTAR EMPRESAS EM GERAL")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Exportação de Empresas em Geral em Tela e Excel ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon-wrapper info mg-t-40"},[_c('i',{staticClass:"icon ion-person-stalker"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon-wrapper info"},[_c('i',{staticClass:"icon ion-funnel"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mg-b-25"},[_vm._v("Preencha os campos acima para filtrar o registros de acordo com o que"),_c('br'),_vm._v("você deseja, para que seja possível gerar o relatório!")])
}]

export { render, staticRenderFns }