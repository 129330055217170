const originalDocumentsTableColumns = [
  {
    label: 'Código',
    field: 'id',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Data de Envio',
    field: 'createdAt',
    type: 'date',
    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
    dateOutputFormat: 'dd/MM/yyyy HH:mm',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Nome do Documento',
    field: 'fileName',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Nº do Documento',
    field: 'extractionResult.invoiceNumber',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Cliente',
    field: 'extractionResult.buyerName',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  // {
  //     label: 'Fornecedor / Exportador / Emitente',
  //     field: 'extractionResult.vendorName',
  //     thClass: 'text-center',
  //     tdClass: 'text-center',
  //     sortable: false,
  // },
  {
    label: 'Status do Envio',
    field: 'extractionStatus',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Tipo de Documento',
    field: 'documentType',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Ações',
    field: 'actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
];

export default originalDocumentsTableColumns;
