<template>
  <div
    class="
      cont-filter
      container
      bg-white
      pd-h-10 pd-t-20 pd-b-20
      d-flex
      align-items-center
    "
  >
    <span class="material-icons-outlined icon"> settings_input_component </span>

    <div class="input-comp">
      <label class="form-control-label">CLIENTE</label>
      <v-select v-model="selectedCustomer" placeholder="Selecione uma opção" label="name" :options="customers"
                name="team">
        <template slot="option" slot-scope="option">
          {{ option.name }} <small>({{ formatCustomerCnpj(option.federalID) }})</small>
        </template>
      </v-select>
    </div>

    <div class="input-comp date" v-if="!modal">
      <label for="etapas">ANO DA RETROSPECTIVA</label>
      <date-picker lang="pt-br"
                   name="year"
                   type="year"
                   v-model="year"
                   class="input-date"
                   format="YYYY"

                   clear-button="true"
                   placeholder="Selecione"
      ></date-picker>
    </div>

    <div>
      <button type="button" class="filter-btn" @click="generateReport">
        <span class="material-icons-outlined"> cached </span>
        GERAR RETROSPECTIVA
      </button>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';
import moment from 'moment';

import DatePicker from 'vue2-datepicker';
import axios from 'axios';

export default {
  name: 'ReportFilters',
  data() {
    return {
      year: null,
      customers: [],
      selectedCustomer: null,
      search: '',
    };
  },
  components: {
    DatePicker,
  },
  async created() {
    await this.getCustomers();
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    async generateReport() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Gerando retrospectiva.',
      });

      const year = moment(this.year).year();

      const processosAbertos = await axios.get(`${config.env.API_DEFAULT.host}/painelControle/aberturasNoPeriodo/?customersId=${this.selectedCustomer.id}&firstDate=${year}-01-01T00:00:00&secondDate=${year}-12-31T23:59:59&teamId=0`)
        .then((result) => result.data.data);

      const processosRegistrados = await axios.get(`${config.env.API_DEFAULT.host}/painelControle/registrosNoPeriodo/?customersId=${this.selectedCustomer.id}&firstDate=${year}-01-01T00:00:00&secondDate=${year}-12-31T23:59:59`)
        .then((result) => result.data.data);

      const canaisDeLiberacao = await axios.get(`${config.env.API_DEFAULT.host}/painelControle/canais/todosIndicadores/?customersId=${this.selectedCustomer.id}&firstDate=${year}-01-01T00:00:00&secondDate=${year}-12-31T23:59:59&teamId=0`)
        .then((result) => result.data.data);

      const periodoDeArmazenagem = await axios.get(`${config.env.API_DEFAULT.host}/painelControle/periodosArmazenagem/todosIndicadores/?customersId=${this.selectedCustomer.id}&firstDate=${year}-01-01T00:00:00&secondDate=${year}-12-31T23:59:59&teamId=0`)
        .then((result) => result.data.data);

      const leadTimes = await axios.get(`${config.env.API_DEFAULT.host}/retrospective/leadtimesIndicators/${this.selectedCustomer.id}/${year}`)
        .then((result) => result.data.data);

      const data = {
        year,
        processosAbertos,
        processosRegistrados,
        canaisDeLiberacao,
        periodoDeArmazenagem,
        registroDeDI: leadTimes.importDeclaration,
        carregamento: leadTimes.loading,
        entrega: leadTimes.delivery,
        encerramento: leadTimes.closing,
        // dolarMedio: 5.22,
        dolarMedio: 5.00,
        frete: [
          {
            country: 'China',
            // value: 34.858
            value: 9052,
          },
          {
            country: 'Estados Unidos',
            // value: 9.113
            value: 8729,
          },
          {
            country: 'União Europeia',
            // value: 11.992
            value: 6323,
          },
        ],
      };

      this.$emit('generate', data);

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async getCustomers() {
      const response = await Axios.get(
        `${config.env.API_DEFAULT.host
        }/companies/search/findAllByIsCustomerAndNameContainingIgnoreCase?isCustomer=true&name=${
          this.search}`,
      );
      this.customers = response.data._embedded.companies;
    },
    formatCustomerCnpj(value) {
      return String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style scoped>
.cont-filter {
  gap: 20px;
}

.input-comp {
  flex: 1 1 auto;
}

.input-comp-limited {
  flex: 0 1 33%;
}

.input-comp.date {
  flex: 0 1 auto;
}

.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}

.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}

.icon {
  color: var(--gray-dark);
}
</style>

<style>
body .input-comp .dropdown-toggle {
  display: flex;
  border: 0 !important;
  border-bottom: 1px solid var(--gray-dark) !important;
  min-height: 35px !important;
  border-radius: 0 !important;
}

.input-comp .v-select .open-indicator:before {
  border-color: black;
  border-width: 2px 2px 0 0;
  width: 7px;
  height: 7px;
}

.input-comp .dropdown-toggle input::placeholder {
  color: black;
}

.input-comp .mx-input-append::before {
  display: none;
}

.input-comp .mx-input-append {
  line-height: 0.5;
}

.input-comp .mx-input-wrapper .mx-clear-wrapper {
  margin-top: -3px !important;
  background-color: white !important;
  z-index: 9;
}

body .input-comp .mx-input-append svg {
  display: block !important;
}
</style>
