<template>
  <div style="width: 100%; display: flex" v-if="contactDetails !== null">
    <div class="contact-container">
      <div class="contact-tabs-container">
        <div class="scroll-tabs left" @click.prevent="scrollTabs('left')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </div>

        <ul class="contact-tabs" ref="tabs">
          <li
            :class="tab === 'detalhes' ? 'active' : ''"
            v-if="
              this.hasPrivilege('CRM_CONTATOS_DETALHES_VISUALIZAR') ||
              this.hasPrivilege('CRM_CONTATOS_EDITAR')
            "
          >
            <a href="#" @click.prevent="tab = 'detalhes'">DETALHES</a>
          </li>
          <li
            :class="tab === 'atividades' ? 'active' : ''"
            v-if="this.hasPrivilege('CRM_CONTATOS_ATIVIDADES_CRUD')"
          >
            <a href="#" @click.prevent="tab = 'atividades'">ATIVIDADES</a>
          </li>
          <li
            :class="tab === 'empresas' ? 'active' : ''"
            v-if="this.hasPrivilege('CRM_CONTATOS_EMPRESAS_VISUALIZAR')"
          >
            <a href="#" @click.prevent="tab = 'empresas'">EMPRESAS</a>
          </li>
          <li
            :class="tab === 'basic-data-requests' ? 'active' : ''"
            v-if="this.hasPrivilege('CRM_CONTATOS_EMPRESAS_VISUALIZAR')"
          >
            <a href="#" @click.prevent="tab = 'basic-data-requests'"
              >PREENCHIMENTO DE DADOS</a
            >
          </li>
        </ul>

        <div class="scroll-tabs right" @click.prevent="scrollTabs('right')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </div>

      <div class="tab-item" v-if="tab === 'detalhes'">
        <div class="contact-container-overflow">
          <contact-details
            :contact-details="contactDetails"
            @get-contact-details="getContactDetails"
          />
        </div>
      </div>

      <div class="tab-item" v-if="tab === 'atividades'">
        <div class="contact-container-overflow no-pd">
          <contact-activities
            :id="contactDetails.id"
            :contact-details="contactDetails"
            :owner="contactDetails.name"
            @updating="getContactActivities"
          ></contact-activities>
        </div>
      </div>

      <div class="tab-item" v-if="tab === 'empresas'">
        <div class="contact-container-overflow no-pd">
          <contact-companies
            :contact-details="contactDetails"
          ></contact-companies>
        </div>
      </div>

      <div class="tab-item" v-if="tab === 'basic-data-requests'">
        <div class="contact-container-overflow no-pd">
          <contact-basic-data-requests
            :contact-details="contactDetails"
          ></contact-basic-data-requests>
        </div>
      </div>
    </div>

    <contact-info
      :contact-details="contactDetails"
      :last-activities="contactActivities"
    />

    <!-- <div
      class="contact-container"
      v-if="contactDetails === null && !newContact"
    >
      <div class="card-body pd-40 sp-3 text-center">
        <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
        <h6 class="tx-md-20 tx-inverse">Nenhum Contato Selecionado</h6>
        <p>Selecione um contato através da barra lateral</p>
      </div>
    </div> -->
    <!-- v-if="!newContact" -->
  </div>
</template>

<script>
import config from "@/config.js";

import Swal from "sweetalert2";
import Axios from "axios";
import { mapActions } from "vuex";
import ContactInfo from "@/components/crm/ContactInfo.vue";
import ContactDetails from "@/components/crm/ContactDetails.vue";
import ContactActivities from "@/components/crm/ContactActivities.vue";
import ContactCompanies from "@/components/crm/ContactCompanies.vue";
import ContactBasicDataRequests from "@/components/crm/ContactBasicDataRequests.vue";
import CredentialService from "@/services/CredentialService.js";

export default {
  name: "CrmContactWrapper",
  components: {
    ContactInfo,
    ContactDetails,
    ContactActivities,
    ContactCompanies,
    ContactBasicDataRequests,
  },
  data() {
    return {
      tab: "detalhes",
      contactDetails: null,
      contactActivities: [],
    };
  },
  async mounted() {
    this.checkPrivilege({
      privilege: "CRM_CONTATOS_DETALHES_VISUALIZAR",
      router: this.$router,
    });
    await this.getContactDetails(this.$route.params.id);
  },
  methods: {
    ...mapActions(["checkPrivilege"]),
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    async getContactDetails(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando dados do contato.",
      });

      this.contactDetails = null;
      this.contactActivities = null;
      // this.contactId = id;

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/${id}`
        );
        this.contactDetails = response.data;

        await this.getContactActivities(id);

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (err) {
        Swal.fire({
          title: "Erro interno",
          html: "<p>Não foi possível recuperar os dados do contato</p>",
          type: "error",
        });

        console.error(err);
        this.$store.commit("setLoading", { show: false });
      }
    },
    async getContactActivities(id) {
      const actResponse = await Axios.get(
        `${config.env.API_DEFAULT.host}/activities/getLast5ActivitiesOfContact/${id}`
      );
      this.contactActivities = actResponse.data.data;
    },
    scrollTabs(direction) {
      if (direction === "right") {
        this.$refs.tabs.scrollLeft += 300;
      }
      if (direction === "left") {
        this.$refs.tabs.scrollLeft -= 300;
      }
    },
  },
};
</script>

<style>
.contact-tabs {
  margin-bottom: 0px;
}
.tab-item {
  height: calc(100% - 65px);
}
</style>
