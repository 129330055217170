<template>
    <transition name="modal">
        <div class="modal-mask">

            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="row no-gutters">

                        <div class="col-lg-12 bg-white">
                            <div class="pd-t-30 pd-xl-x-30">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar" @click="$emit('close')">
                                    <span aria-hidden="true">×</span>
                                </button>

                                <div class="pd-x-30 pd-y-10">
                                    <h3 class="signin-title-primary">Esqueci minha senha!</h3>
                                    <h5 class="signin-title-secondary lh-4 mg-b-20">Por favor, digite o seu email para que consigamos localizar seu usuário e enviar uma nova senha.</h5>

                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control pd-y-12" v-model="contact.email1" placeholder="Digite o seu email">
                                    </div>

                                    <button class="btn btn-primary btn-block btn-signin" @click.prevent="submit">ENVIAR</button>
                                </div>
                            </div><!-- pd-20 -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import Axios from 'axios';
import { EventBus } from '@/events/EventBus.js';
import config from '@/config.js';
import Contact from '@/models/Contact.js';

export default {
  name: 'configuration-users-recovery-password',
  props: [],
  components: { },
  watch: { },

  data() {
    return {
      credential: null,
      loginError: null,
      contact: new Contact(),
    };
  },

  methods: {
    submit() {
      this.$store.commit('setLoading', { show: true, label: 'Recuperação de Senha', description: 'Aguarde sua requisição está sendo processada...' });
      if (this.contact !== undefined && this.contact !== null && this.contact.email1 !== '') {
        Axios({
          method: 'post', url: `${config.env.API_DEFAULT.host}/credential/recovery`, headers: { Authorization: null }, data: this.contact,
        }).then((response) => {
          if (response.data.code === 'ERROR') {
            this.$store.commit('setLoading', { show: false, label: '', description: '' });
            this.loginError = response.data.message;
            this.$swal('Recuperação de Senha', response.data.message, 'warning');
          } else {
            this.$store.commit('setLoading', { show: false, label: '', description: '' });
            this.$swal('Recuperação de Senha', response.data.message, 'warning');
            this.$router.push('/login');
            this.$emit('close');
          }
        }).catch((error) => {
          this.loginError = error.message;
          this.$store.commit('setLoading', { show: false, label: '', description: '' });
          this.$swal('Recuperação de Senha', error.data.response.message, 'error');
        });
      } else {
        this.$store.commit('setLoading', { show: false, label: '', description: '' });
        this.loginError = 'Digite sua credencial de acesso!';
        this.$swal('Recuperação de Senha', error.data.response.message, 'error');
      }
    },

  },
  computed: {
  },
  mounted() {

  },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;}

.modal-container {
    width: 600px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

</style>
