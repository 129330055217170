<template>
  <div class="row d-flex align-items-center list-group-subitem">
    <div class="col-md-3">
      <p class="mg-b-0">
        <i class="fa fa-file-text-o tx-primary mg-r-8"></i>
        <strong class="tx-inverse tx-medium pd-r-60"
          >Cod. {{ item.code }}</strong
        >
      </p>
    </div>
    <div class="col-md-6">
      <p class="mg-b-0">
        <span class="text-muted">{{ item.description }}</span>
      </p>
    </div>
    <div class="col-md-3 d-flex justify-content-end">
      <a
        href="#"
        @click.prevent="$emit('remove', item)"
        class="btn btn-danger btn-icon rounded-circle mg-r-5"
      >
        <div>
          <i class="icon ion-trash-a"></i>
        </div>
      </a>
      <a
        href="#"
        @click.prevent="$emit('edit', item)"
        class="btn btn-primary btn-icon rounded-circle mg-r-5"
      >
        <div>
          <i class="icon ion-edit"></i>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormCategoriesItem",
  props: ["item"],
};
</script>

<style scoped>
</style>
