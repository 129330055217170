
<template>
  <div class="h-100">
    <div class="row justify-content-between mg-b-5 mg-t-0">
      <div class="col-6">
        <h4 class="tx-inverse mg-b-3 valign-bottom-force">
          Análise das Cotações Recebidas
        </h4>
        <p class="mg-b-0 valign-bottom-force">
          <i class="icon ion-information-circled mg-r-3"></i>A análise abaixa
          está sendo baseada nas cotações atuais.
        </p>
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-success" @click.prevent="generateExcel">
          <i class="fas fa-file-excel-o mr-1"></i> Gerar Planilha
        </button>
      </div>
      <br />
    </div>

    <div class="row mg-b-10" v-if="model.wayOfTransport !== 'RODOVIARIA'">
      <div class="col-6">
        <div class="card card-earning-summary pd-10">
          <h6>Taxa Dolar</h6>
          <h5 class="tx-black mg-b-0">
            {{
              freightQuotationsResponses.total.usdRate.sell
                | numeralFormat("0,0.000000")
            }}
          </h5>
          <span
            >Atualizado em
            {{
              freightQuotationsResponses.total.usdRate.modified
                | moment("DD/MM/YYYY")
            }}
            às
            {{
              freightQuotationsResponses.total.usdRate.modified | moment("H:mm")
            }}</span
          >
        </div>
      </div>
      <div class="col-6">
        <div class="card card-earning-summary pd-10">
          <h6>Taxa Euro</h6>
          <h5 class="tx-black mg-b-0">
            {{
              freightQuotationsResponses.total.euroRate.sell
                | numeralFormat("0,0.000000")
            }}
          </h5>
          <span
            >Atualizado em
            {{
              freightQuotationsResponses.total.euroRate.modified
                | moment("DD/MM/YYYY")
            }}
            às
            {{
              freightQuotationsResponses.total.euroRate.modified
                | moment("H:mm")
            }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="row no-gutters mb-0"
      v-if="
        freightQuotationsResponses.freightQuotations.length > 0 &&
        model.wayOfTransport !== 'RODOVIARIA'
      "
    >
      <div class="col-4">
        <div
          class="card card-revenue tx-center bg-gray-100 pd-10"
          v-if="freightQuotationsResponses.freightQuotations.length > 0"
        >
          <span class="tx-16 tx-uppercase tx-bold tx-gray-800">
            <span class="tx-13">MELHOR</span> <br />
            Cotação Recebida #{{ freightQuotationsResponses.total.id }}
          </span>
          <p class="tx-black tx-light">
            {{ freightQuotationsResponses.total.company }}
          </p>
          <h4 class="tx-black tx-center tx-24">
            R$
            {{
              freightQuotationsResponses.total.value | numeralFormat("0,0.00")
            }}
          </h4>
        </div>
      </div>
      <div class="col-4">
        <div
          class="card card-revenue tx-center bg-gray-200 bd-l-0 pd-10"
          v-if="freightQuotationsResponses.totalEUR"
        >
          <span class="tx-16 tx-uppercase tx-bold tx-gray-800">
            <span class="tx-13">MELHOR</span> <br />
            Transit Time</span
          >
          <p class="tx-black tx-light">
            {{ freightQuotationsResponses.transitTime.company }}
          </p>
          <h4 class="tx-black tx-center tx-24">
            {{ freightQuotationsResponses.transitTime.value }} dias
          </h4>
        </div>
      </div>
      <div class="col-4">
        <div
          class="card card-revenue tx-center bg-gray-300 pd-10 bd-l-0"
          v-if="freightQuotationsResponses.totalEUR"
        >
          <span class="tx-16 tx-uppercase tx-bold tx-gray-800">
            <span class="tx-13">MELHOR</span> <br />
            Freetime
          </span>
          <p class="tx-black tx-light">
            {{ freightQuotationsResponses.freetime.company }}
          </p>
          <h4 class="tx-black tx-center tx-24">
            {{ freightQuotationsResponses.freetime.value }} dias
          </h4>
        </div>
      </div>
    </div>

    <hr />

    <div class="col-md-12">
      <div class="row justify-content-between">
        <div class="mb-3">
          <label data-v-08141dce="" class="section-title mg-t-15 mb-10"
            >Ranking das Cotações Recebidas</label
          >
          <span class="text-sm"
            ><i class="fas fa-info-circle"></i> As cotações são ordenadas
            principalmente pelo valor total.</span
          >
        </div>
        <div
          class="col-md-3 form-group mg-md-t--1"
          v-if="model.wayOfTransport === 'RODOVIARIA'"
        >
          <label class="form-control-label">Valor da NF (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before mt-2">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="ml-2 mt-2 mr-2">
              <money
                v-model="model.quotationNfValue"
                v-bind="money"
                class="form-control"
                name="quotationNfValue"
              ></money>
            </div>
            <a
              @click.prevent="setQuotationFreightValue"
              class="btn btn-primary btn-icon rounded-circle"
            >
              <div>
                <i class="fa fa-save text-white"></i>
              </div>
            </a>
          </div>
        </div>
        <div
          v-if="
            freightQuotationsResponses.freightQuotations.filter(
              (quotation) =>
                quotation.rejectReason != null && quotation.rejectReason !== ''
            ).length > 0
          "
        >
          <button
            class="btn btn-sm btn-primary mt-2"
            type="button"
            @click.prevent="getJustificationsList"
          >
            Listar justificativas
          </button>
        </div>
      </div>
    </div>

    <div class="card card-table mg-t-5">
      <div class="table-responsive">
        <table class="table mg-b-0 tx-13">
          <thead>
            <tr class="tx-10">
              <th class="pd-y-5 tx-center">Ranking</th>
              <th class="pd-y-5 tx-center">Dados da Cotação</th>
            </tr>
          </thead>
          <tbody v-if="freightQuotationsResponses.freightQuotations.length > 0">
            <template
              v-for="(
                log, index
              ) in freightQuotationsResponses.freightQuotations"
            >
              <tr
                :key="`quote-1st-row-${log.id}`"
                :class="log.approved ? 'quotation-approved' : ''"
              >
                <td class="tx-center tx-black align-middle">
                  {{ index + 1 }}º
                </td>
                <td class="align-middle">
                  <div class="row">
                    <div class="col">
                      <label class="section-label-sm tx-gray-500"
                        >Cotação #{{ log.id }}</label
                      >
                      <div class="billed-to">
                        <h6 class="tx-gray-800">
                          {{ log.freightForwarder.name }}
                        </h6>
                        <p>
                          {{ log.person }}
                          <span class="tx-12 d-block">
                            <span
                              v-if="log.status.value === 'COTADO'"
                              class="square-8 bg-success mg-r-5 rounded-circle"
                            ></span>
                            <span
                              v-else-if="log.status.value === 'ENVIADO'"
                              class="square-8 bg-warning mg-r-5 rounded-circle"
                            ></span>
                            <span
                              v-else-if="log.status.value === 'VISUALIZADO'"
                              class="square-8 bg-primary mg-r-5 rounded-circle"
                            ></span>
                            {{ log.status.description }}
                            <br />
                            {{ log.created | moment("DD/MM/YYYY") }} às
                            {{ log.created | moment("H:mm") }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div
                      class="col"
                      v-if="model.wayOfTransport !== 'RODOVIARIA'"
                    >
                      <p class="invoice-info-row">
                        <span>
                          Total Dolar
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.totalUSD.id === log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span v-if="log.totalUSDReais" class="tx-right">
                          R$ {{ log.totalUSDReais | numeralFormat("0,0.00") }} /
                          <small
                            >USD
                            {{ log.totalUSD | numeralFormat("0,0.00") }}</small
                          >
                        </span>
                        <span
                          v-else-if="log.totalUSD !== null"
                          class="tx-right"
                        >
                          $ {{ log.totalUSD | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span>
                          Total Euro
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.totalEUR.id === log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span v-if="log.totalEURReais" class="tx-right">
                          R$ {{ log.totalEURReais | numeralFormat("0,0.00") }} /
                          <small
                            >EUR
                            {{ log.totalEUR | numeralFormat("0,0.00") }}</small
                          >
                        </span>
                        <span v-else-if="log.totalEURO !== null">
                          EUR {{ log.totalEUR | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span>
                          Total Reais
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.totalReais.id ===
                              log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span v-if="log.totalReais !== null">
                          R$ {{ log.totalReais | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span>
                          Total Outra ({{ log.totalOthersDescription }})
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.totalOthers.id ===
                              log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span
                          v-if="log.totalOthersReais !== null"
                          class="tx-right"
                        >
                          R$
                          {{ log.totalOthersReais | numeralFormat("0,0.00") }} /
                          <small
                            >{{ log.totalOthersDescription }}
                            {{
                              log.totalOthers | numeralFormat("0,0.00")
                            }}</small
                          >
                        </span>
                        <span
                          v-else-if="log.totalOthers !== null"
                          class="tx-rigth"
                        >
                          {{ log.totalOthers | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                    </div>
                    <div
                      class="col"
                      v-if="model.wayOfTransport !== 'RODOVIARIA'"
                    >
                      <p class="invoice-info-row">
                        <span>
                          Transit Time
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.transitTime.id ===
                              log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span v-if="log.transitTime !== null">
                          {{ log.transitTime }} dias
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span>
                          Freetime
                          <span
                            class=""
                            v-if="
                              freightQuotationsResponses.freetime.id === log.id
                            "
                            ><i class="icon ion-star tx-primary"></i
                          ></span>
                        </span>
                        <span v-if="log.freetime !== null">
                          {{ log.freetime }} dias
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span> VALOR TOTAL </span>
                        <span class="tx-black tx-16">
                          R$ {{ log.total | numeralFormat("0,0.00") }}</span
                        >
                      </p>
                      <div class="invoice-info-row row justify-content-end p-3">
                        <button
                          class="btn btn-sm btn-success"
                          @click.prevent="openModalApproval(log)"
                          v-if="log.approved == null"
                        >
                          Aprovar
                        </button>
                        <button
                          class="btn btn-sm btn-warning"
                          v-else-if="log.approved"
                          @click.prevent="cancelApproval(log)"
                        >
                          Cancelar aprovação
                        </button>
                        <a
                          href="#"
                          class="text-danger"
                          v-else-if="
                            !log.approved &&
                            (log.rejectReason == null ||
                              log.rejectReason === '')
                          "
                          @click.prevent="openJustificationModal(log)"
                          >Justificar rejeição</a
                        >
                        <a
                          href="#"
                          class="text-danger"
                          v-else-if="
                            !log.approved &&
                            !(
                              log.rejectReason == null ||
                              log.rejectReason === ''
                            )
                          "
                          @click.prevent="openJustificationModal(log)"
                          >Visualizar justificativa</a
                        >
                      </div>
                    </div>

                    <!-- DADOS DO RODOVIÁRIO -->
                    <div
                      class="col"
                      v-if="model.wayOfTransport === 'RODOVIARIA'"
                    >
                      <p class="invoice-info-row">
                        <span> Valor da NF </span>
                        <span v-if="log.nfValue !== null">
                          R$ {{ log.nfValue | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                        <!-- <span><money class="form-control border" v-bind="money"></money></span> -->
                      </p>
                      <p class="invoice-info-row">
                        <span> Valor total de Frete </span>
                        <span v-if="log.freightTotalValue !== null">
                          R$
                          {{ log.freightTotalValue | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span> AD Valorem </span>
                        <span v-if="log.freightTotalValue !== null">
                          %
                          {{
                            log.adValoremPercentage | numeralFormat("0,0.00")
                          }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span> Mão de obra </span>
                        <span v-if="log.laborValue !== null">
                          R$ {{ log.laborValue | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <p class="invoice-info-row">
                        <span> Total </span>
                        <span v-if="log.roadTotalValue !== null">
                          R$ {{ log.roadTotalValue | numeralFormat("0,0.00") }}
                        </span>
                        <span v-else> - </span>
                      </p>
                      <div class="invoice-info-row row justify-content-end p-3">
                        <button
                          class="btn btn-sm btn-success"
                          @click.prevent="openModalApproval(log)"
                          v-if="log.approved == null"
                        >
                          Aprovar
                        </button>
                        <button
                          class="btn btn-sm btn-warning"
                          v-else-if="log.approved"
                          @click.prevent="cancelApproval(log)"
                        >
                          Cancelar aprovação
                        </button>
                        <a
                          href="#"
                          class="text-danger"
                          v-else-if="
                            !log.approved &&
                            (log.rejectReason == null ||
                              log.rejectReason === '')
                          "
                          @click.prevent="openJustificationModal(log)"
                          >Justificar rejeição</a
                        >
                        <a
                          href="#"
                          class="text-danger"
                          v-else-if="
                            !log.approved &&
                            !(
                              log.rejectReason == null ||
                              log.rejectReason === ''
                            )
                          "
                          @click.prevent="openJustificationModal(log)"
                          >Visualizar justificativa</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters" v-if="log.observation !== null">
                    <div class="col-md-12">
                      <div class="form-group bd-b-0-force">
                        <label class="form-control-label">Observações</label>
                        <textarea
                          name="observation"
                          placeholder="Observações"
                          class="form-control is-valid mg-t-20"
                          v-model="log.observation"
                          rows="4"
                          disabled
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div
                        class="card-footer pd-y-5 pd-x-10 bd-t bd-gray-300 bd"
                        data-toggle="dropdown"
                      >
                        <a href="#" class="tx-11 tx-uppercase"
                          >Visualizar Documentos ({{
                            log.documents !== undefined
                              ? log.documents.length
                              : "0"
                          }}) <i class="fa fa-angle-down"></i
                        ></a>
                      </div>

                      <div
                        class="file-group dropdown-menu dropdown-menu-left pd-0 wd-600 bd-0"
                      >
                        <div
                          class="card-header d-flex align-items-center justify-content-between pd-y-20 bd-gray-400 bd-2 bd-b-gray-800 bg-gray-400"
                        >
                          <h6 class="mg-b-0 tx-13 tx-inverse">
                            Documentos da Cotação
                          </h6>
                        </div>

                        <template v-if="log.documents.length > 0">
                          <perfect-scrollbar
                            style="max-height: 300px !important"
                          >
                            <div
                              class="file-item bd-1 bd-gray-200 bg-gray-100 pd-t-10 pd-b-10 ht-70"
                              v-for="doc in log.documents"
                              :key="'doc-' + doc.id"
                            >
                              <div class="row no-gutters wd-100p">
                                <div
                                  class="col-10 mg-t-5 mp-hand"
                                  @click="download(doc)"
                                >
                                  <i class="fa fa-file"></i>
                                  <a href="#"> {{ doc.description }}</a>
                                  <span class="tx-right tx-12 tx-thin mg-l-10"
                                    >({{ doc.documentTypeName }})</span
                                  >
                                </div>

                                <div class="col-2 tx-right tx-26">
                                  <a
                                    href="#"
                                    class="mg-r-5"
                                    @click.stop="download(doc)"
                                    ><i class="fa fa-download tx-primary"></i
                                  ></a>
                                  <a href="#" @click.stop="remover(doc)"
                                    ><i class="icon ion-trash-b tx-danger"></i
                                  ></a>
                                </div>

                                <small
                                  class="col-12"
                                  v-if="
                                    doc.legacyCreated !== null &&
                                    doc.legacyCreatedBy !== null
                                  "
                                >
                                  Enviando em
                                  {{
                                    doc.legacyCreated
                                      | moment("DD/MM/YY à\\s H:MM:ss")
                                  }}
                                  por {{ doc.legacyCreatedBy }}
                                </small>
                                <small class="col-12" v-else>
                                  Enviando em
                                  {{
                                    doc.created
                                      | moment("DD/MM/YY à\\s H:MM:ss")
                                  }}
                                  por {{ doc.createdBy }}
                                </small>
                              </div>
                            </div>
                          </perfect-scrollbar>
                        </template>
                        <template v-else>
                          <div
                            class="file-item bd-1 bd-gray-200 bg-gray-100 pd-t-10 pd-b-10 ht-70"
                          >
                            Nenhum documento foi enviado.
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <VModal
      title="Justificar rejeição"
      :description="
        quotationToJustificate
          ? `Informe a justifica de rejeição da cotação #${quotationToJustificate.id}`
          : ''
      "
      :height="40"
      :width="800"
      :handler="isOpenModal"
      @requestClose="closeModal"
    >
      <template #content>
        <div>
          <div class="form-group">
            <label for="justification" class="label">Justificativa</label>
            <textarea
              name="justification"
              class="form-control"
              id="justification"
              cols="30"
              rows="10"
              v-model="formJustification.justification"
            ></textarea>
          </div>
          <div
            v-if="
              quotationToJustificate &&
              quotationToJustificate.justifiedBy &&
              quotationToJustificate.justifiedAt
            "
            class="mt-3"
          >
            Justificado por
            <strong>{{ quotationToJustificate.justifiedBy }}</strong> em
            <strong>{{
              formatDate(quotationToJustificate.justifiedAt)
            }}</strong>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row justify-content-center">
          <button
            class="btn btn-secondary mr-2"
            type="button"
            @click.prevent="closeModal"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click.prevent="justifyRejection(quotationToJustificate.id)"
          >
            Salvar
          </button>
        </div>
      </template>
    </VModal>
    <VModal
      title="Listagem de justificativas"
      :description="'Justificativas enviadas para as cotações do processo'"
      :height="40"
      :width="800"
      :handler="isOpenModalListJustifications"
      @requestClose="closeModal"
    >
      <template #content>
        <div>
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Cotação</th>
                  <th>Justificativa</th>
                  <th>Usuário</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(justification, index) in justificationsList"
                  :key="index"
                >
                  <td>#{{ justification.quotation.id }}</td>
                  <td>{{ justification.justification }}</td>
                  <td>{{ justification.justifiedBy }}</td>
                  <td>{{ formatDate(justification.justifiedAt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row justify-content-center">
          <button
            class="btn btn-secondary mr-2"
            type="button"
            @click.prevent="closeModal"
          >
            Fechar
          </button>
        </div>
      </template>
    </VModal>
    <v-modal
      :handler="modalApprove"
      :title="`Aprovar cotação #${
        quotationToApprove ? quotationToApprove.id : ''
      }`"
      description=""
      :width="600"
      id="modalApprove"
      @requestClose="closeModalApprove"
    >
      <template #content>
        <div>
          <p>
            <i class="fa fa-exclamation-triangle mr-1"></i> Você tem certeza que
            deseja aprovar esta cotação?
          </p>
          <div class="card rounded-5 mb-3">
            <div class="card-body">
              <strong
                ><i class="fa fa-info-circle mr-1"></i> informações da
                cotação:</strong
              >
              <div class="billed-to mt-3" v-if="quotationToApprove">
                <h6 class="tx-gray-800">
                  {{ quotationToApprove.freightForwarder.name }}
                </h6>
                <p>
                  {{ quotationToApprove.person }}
                  <span class="tx-12 d-block">
                    <span
                      v-if="quotationToApprove.status.value === 'COTADO'"
                      class="square-8 bg-success mg-r-5 rounded-circle"
                    ></span>
                    <span
                      v-else-if="quotationToApprove.status.value === 'ENVIADO'"
                      class="square-8 bg-warning mg-r-5 rounded-circle"
                    ></span>
                    <span
                      v-else-if="
                        quotationToApprove.status.value === 'VISUALIZADO'
                      "
                      class="square-8 bg-primary mg-r-5 rounded-circle"
                    ></span>
                    {{ quotationToApprove.status.description }}
                    <br />
                    {{ quotationToApprove.created | moment("DD/MM/YYYY") }} às
                    {{ quotationToApprove.created | moment("H:mm") }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <v-checkbox
            label="Notificar por email?"
            name="notifyByEmail"
            v-model="notifyByEmail"
          ></v-checkbox>
          <v-textarea
            label="Texto de observação (Opcional)"
            class="mt-3"
            name="approvalObservationText"
            v-if="notifyByEmail"
            v-model="approvalObservationText"
          ></v-textarea>
        </div>
      </template>
      <template #footer>
        <div class="row justify-content-center">
          <button
            class="crm-button mr-2"
            type="button"
            @click.prevent="newApprove"
          >
            Aprovar
          </button>
          <button
            class="crm-button gray"
            type="button"
            @click.prevent="closeModalApprove"
          >
            Fechar
          </button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions } from "vuex";
import Axios from "axios";
import config from "@/config.js";
import VModal from "@/components/VModal.vue";
import { Money } from "v-money";
import VCheckbox from "@/components/VCheckbox.vue";
import VTextarea from "@/components/VTextarea.vue";

export default {
  name: "freight-quote",
  props: {
    model: {
      required: true,
    },
    freightQuotationsResponses: {
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      quotationToJustificate: null,
      openModalPreview: false,
      isOpenModal: false,
      isOpenModalListJustifications: false,
      justificationsList: [],
      justification: null,
      formJustification: {
        justification: null,
      },
      quotationFreightValue: null,
      modalApprove: false,
      quotationToApprove: null,
      notifyByEmail: false,
      approvalObservationText: "",
    };
  },
  components: {
    VTextarea,
    VCheckbox,
    VModal,
    Money,
  },
  methods: {
    ...mapActions("DocumentsStore", ["download"]),
    calculateRoadTotal(log) {
      const nfValue = this.model.revenuesRoadValueOfTransportationNF;
      const { adValoremPercentage } = log;
      const { freightTotalValue } = log;

      return (nfValue * adValoremPercentage) / 100 + freightTotalValue;
    },
    closeModalApprove() {
      this.modalApprove = false;
      this.approvalObservationText = "";
      this.quotationToApprove = null;
      this.notifyByEmail = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY [às] HH:mm:ss");
    },
    remover() {
      Swal.fire(
        "Remover Documento de Cotação",
        "Para remover um documento de cotação, você precisa acessar <strong>Documentos do Processo -> Documentos Enviados ->  Cotação Frete</strong>, selecionar o documento e então clicar na lixeira para remover.",
        "error"
      );
    },
    openJustificationModal(quotation) {
      this.quotationToJustificate = quotation;
      this.isOpenModal = true;
      this.formJustification.justification = quotation.rejectReason;
    },
    async getJustificationsList() {
      this.isOpenModalListJustifications = true;

      this.$store.commit("setLoading", {
        show: true,
        label: "Listagem de justificativas",
        description: "Aguarde estamos buscando as informações..",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/freightQuotation/get-all-justifications/${this.model.id}`
        );

        this.justificationsList = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async generateExcel() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Gerando planilha de cotações",
        description: "Aguarde...",
      });

      try {
        const response = await Axios.post(`${config.env.API_DEFAULT.host}/freightQuotation/process/${this.model.id}/excel`);

        const binaryString = window.atob(response.data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        const type = "xls";

        const blob = new Blob([bytes], { type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `cotacoes-processo-${this.model.identification}.xls`;
        link.click();

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire(
          "Planilha das Cotações",
          "Planilha gerada com sucesso!",
          "success"
        );
      } catch (e) {
        console.error(e);

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire(
          "Planilha das Cotações",
          "Erro inesperado ao testar gerar planilha das cotações!",
          "error"
        );
      }
    },
    justifyRejection(id) {
      Swal.fire({
        title: "Justificar rejeição",
        html: `<p>Você realmente deseja justificar a rejeição da cotação #${id}</p>`,
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Justificar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Justificar rejeição",
            description: "Aguarde estamos salvando as informações..",
          });

          try {
            const response = await Axios.post(
              `${config.env.API_DEFAULT.host}/freightQuotation/justify-rejection/${id}`,
              this.formJustification
            );

            this.$store.commit("setLoading", {
              show: false,
            });

            Swal.fire(
              "Justificar rejeição",
              "Justificação enviada com sucesso!",
              "success"
            );

            this.closeModal();

            this.$emit("load-quotations");
          } catch (e) {
            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
    closeModal() {
      this.formJustification.justification = null;
      this.quotationToJustificate = null;
      this.isOpenModal = false;
      this.isOpenModalListJustifications = false;
    },
    async setQuotationFreightValue() {
      Swal.fire({
        title: "Valor da NF",
        html: "Você realmente deseja alterar o valor da NF? Isso irá influenciar no ranking das cotações!",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Atualizando valor da NF",
            description: "Aguarde estamos salvando as informações..",
          });

          try {
            const response = await Axios.post(
              `${config.env.API_DEFAULT.host}/newImportProcess/set-quotation-nf-value/${this.model.id}`,
              {
                quotationNfValue: this.model.quotationNfValue,
              }
            );

            this.$emit("load-quotations");

            Swal.fire({
              title: "Valor da NF",
              html: "Valor da NF alterado com sucesso!",
              type: "success",
            });

            this.$store.commit("setLoading", {
              show: false,
            });
          } catch (e) {
            Swal.fire({
              title: "Valor da NF",
              html: `Houve um erro inesperado ao atualizar o valor da NF: ${e}`,
              type: "error",
            });

            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
    async openModalApproval(quotation) {
      this.quotationToApprove = quotation;
      this.modalApprove = true;
    },
    async newApprove() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Aprovando cotação",
        description: "Aguarde estamos salvando as informações..",
      });

      try {
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/freightQuotation/approve/${this.quotationToApprove.id}/${this.notifyByEmail}`,
          {
            text: this.approvalObservationText,
          }
        );

        await this.$store.dispatch(
          "ProcessStore/findProcessById",
          this.model.id
        );

        this.$store.commit("setLoading", {
          show: false,
        });

        this.$emit("load-quotations");

        this.approvalObservationText = "";
        this.closeModalApprove();
        this.quotationToApprove = null;
      } catch (e) {
        console.error(e);
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    cancelApproval(quotation) {
      Swal.fire({
        title: "Cancelar aprovação",
        html: "<p>Você realmente deseja cancelar a aprovação desta cotação? Ao confirmar todas as outros serão disponibilizadas para aprovação!</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Sim",
        showCancelButton: true,
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Cancelando aprovação",
            description: "Aguarde estamos salvando as informações..",
          });

          try {
            const response = await Axios.post(
              `${config.env.API_DEFAULT.host}/freightQuotation/cancel-approval/${quotation.id}`
            );

            this.$store.commit("setLoading", {
              show: false,
            });

            this.$emit("load-quotations");
          } catch (e) {
            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
@import "./styles.css";

.quotation-approved {
  background-color: rgb(237, 255, 237);
}
</style>
