<template>
  <div>
    <InvoicesTable
      @viewInvoice="(id) => showInvoice(id)"
      @destroyInvoice="destroyInvoice"
    />

    <fab-modal
      :width="2560"
      :height="100"
      title="VISUALIZAR INVOICE"
      description=""
      :handler="modalInvoiceOpen"
      @requestClose="closeInvoiceModal"
      :contentPadding="false"
    >
      <template #header>
        <div class="flex ai-c fgap2">
          <div v-if="selectedDocument.id">
            <p class="text-md mb-0">
              Código do Documento
              <span class="text-bold">#{{ selectedDocument.id }}</span>
            </p>
            <p class="sub-gray mb-0">
              Enviado em
              {{
                new Date(selectedDocument.createdAt) | date("dd/MM/yyyy HH:mm")
              }}
            </p>
          </div>
        </div>
      </template>

      <template #content>
        <OriginalDocumentsInvoiceModal
          :selected-document="selectedDocument"
          :selected-documents="selectedDocuments"
          :read-only="false"
          @updateField="([field, value]) => invoiceUpdateField(field, value)"
          @updateBuyerField="invoiceUpdateBuyerField"
          @updateVendorField="invoiceUpdateVendorField"
          @updateInvoice="updateInvoice"
          @destroyInvoice="destroyInvoice"
          @addLineItem="invoiceAddLineItem"
          @removeLineItem="invoiceRemoveLineItem"
          @addInstallmentItem="invoiceAddInstallmentItem"
          @removeInstallmentItem="invoiceRemoveInstallmentItem"
        />
      </template>
    </fab-modal>
  </div>
</template>

<script>
import InvoicesTable from "@/components/OriginalDocuments/Invoices/InvoicesTable.vue";
import OriginalDocumentsInvoiceModal from "@/components/OriginalDocuments/Invoices/OriginalDocumentInvoiceModal.vue";
import FabModal from "@/components/VModal.vue";
import { originalDocumentsMixin } from "@/mixins/originalDocuments/originalDocumentsMixin";

export default {
  name: "IndexInvoices",
  mixins: [originalDocumentsMixin],
  components: {
    FabModal,
    OriginalDocumentsInvoiceModal,
    InvoicesTable,
  },
};
</script>
