<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.0625 3.88487V2.44141C4.0625 1.956 4.456 1.5625 4.94141 1.5625H12.5586C13.044 1.5625 13.4375 1.956 13.4375 2.44141V10.0586C13.4375 10.544 13.044 10.9375 12.5586 10.9375H11.0988"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0586 4.0625H2.44141C1.956 4.0625 1.5625 4.456 1.5625 4.94141V12.5586C1.5625 13.044 1.956 13.4375 2.44141 13.4375H10.0586C10.544 13.4375 10.9375 13.044 10.9375 12.5586V4.94141C10.9375 4.456 10.544 4.0625 10.0586 4.0625Z"
      stroke="#343A40"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CopyIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
