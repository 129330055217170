<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Financeiro</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ modelTitle }}</h6>
      </div>

      <div class="card card-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'chartOfAccounts-form',
                  params: { action: 'create' },
                }"
                class="btn btn-primary"
                @click="modal = true"
              >
                <i class="icon ion-edit tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <div class="card-body">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="project in projects"
              :key="project.id"
            >
              <div class="row d-flex align-items-center list-group-subitem">
                <div class="col-md-2">
                  <p class="mg-b-0">
                    <i class="fa fa-file-text-o tx-primary mg-r-8"></i>
                    <strong class="tx-inverse tx-medium pd-r-60">{{
                      project.year
                    }}</strong>
                  </p>
                </div>

                <div class="col-md-3">
                  <p class="mg-b-0">
                    <i
                      class="fa fa-circle mg-r-8"
                      :class="projectStatusColor(project.status)"
                    ></i>
                    <strong class="tx-inverse tx-medium pd-r-60">{{
                      projectStatusText(project.status)
                    }}</strong>
                  </p>
                </div>

                <div class="col-md-4">
                  <p class="mg-b-0">
                    <span class="text-muted">{{ project.description }}</span>
                  </p>
                </div>

                <div class="col-md-3 d-flex justify-content-end">
                  <router-link
                    :to="{
                      name: 'chartOfAccounts-form',
                      params: { action: 'update', id: project.id },
                    }"
                    class="btn btn-primary btn-icon rounded-circle mg-r-5"
                  >
                    <div>
                      <i class="icon ion-edit"></i>
                    </div>
                  </router-link>

                  <a
                    href="#"
                    @click.prevent="duplicate(project.id)"
                    class="btn btn-warning btn-icon rounded-circle mg-r-5"
                  >
                    <div>
                      <i class="fa fa-clone"></i>
                    </div>
                  </a>

                  <a
                    href="#"
                    @click.prevent="remove(project.id)"
                    class="btn btn-danger btn-icon rounded-circle mg-r-5"
                  >
                    <div>
                      <i class="icon ion-trash-a"></i>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";

export default {
  name: "chartOfAccounts-list",
  components: {},
  data() {
    return {
      modelTitle: "Plano de Contas",
      mainTitle: "Projetos de Planos de Contas",
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.FinancesStore.chartOfAccountProjects,
    }),
  },
  methods: {
    projectStatusColor(status) {
      const typesStatus = {
        PLANNING: "tx-primary",
        BUDGET: "tx-gray-300",
        PROGRESS: "tx-warning",
        CANCELED: "tx-danger",
        DONE: "tx-success",
      };
      return typesStatus[status];
    },
    projectStatusText(status) {
      const typesStatus = {
        PLANNING: "Planejamento",
        BUDGET: "Orçamento",
        PROGRESS: "Execução",
        CANCELED: "Cancelado",
        DONE: "Concluído",
      };
      return typesStatus[status];
    },

    async duplicate(projectId) {
      swal({
        title: "Atenção!",
        text: "Tem certeza que deseja duplicar o projeto?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDuplicate) => {
        if (willDuplicate) {
          await this.$store.dispatch("FinancesStore/getChartOfAccountProjects");
          swal("Projeto duplicado com sucesso!", {
            icon: "success",
          });
        }
      });
    },

    async remove(projectId) {
      swal({
        title: "Você tem certeza que quer deletar o projeto?",
        text: "Não é possível desfazer essa ação!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal({
            title: "Atenção!",
            text: "Depois que o projeto é apagado, não é possível recuperá-lo! Deseja continuar?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              await this.$store.dispatch(
                "FinancesStore/deleteSingleChartOfAccountProject",
                projectId
              );
              await this.$store.dispatch(
                "FinancesStore/getChartOfAccountProjects"
              );

              swal("Projeto deletado com sucesso!", {
                icon: "success",
              });
            }
          });
        }
      });
    },
  },
  async created() {
    try {
      const response = await this.$store.dispatch(
        "FinancesStore/getChartOfAccountProjects"
      );
      if (!response) {
        this.$swal("Ops, aconteceu um erro!", "", "error");
      }
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style scoped>
.sub-list-group {
  position: relative;
  top: 13px;
  left: 21px;
}

.sub-list-group > li {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-left: 0px;
  background: none !important;
}

.list-group-item > div.list-group-subitem:hover {
  background: #f9f9f9;
  box-shadow: 0 0 0 3px #f9f9f9;
}
</style>
