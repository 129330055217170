<template>
  <svg width="52" height="49" viewBox="0 0 52 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.6111 26.6944H2.38889C1.62183 26.6944 1 27.3163 1 28.0833V46.1389C1 46.906 1.62183 47.5278 2.38889 47.5278H24.6111C25.3782 47.5278 26 46.906 26 46.1389V28.0833C26 27.3163 25.3782 26.6944 24.6111 26.6944Z" stroke="#333333" stroke-linejoin="round"/>
    <path d="M9.2984 35.0335H17.6317" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 27.3889L5.86111 21.8333H13.5" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M49.6111 26.6944H27.3889C26.6218 26.6944 26 27.3163 26 28.0833V46.1389C26 46.906 26.6218 47.5278 27.3889 47.5278H49.6111C50.3782 47.5278 51 46.906 51 46.1389V28.0833C51 27.3163 50.3782 26.6944 49.6111 26.6944Z" stroke="#333333" stroke-linejoin="round"/>
    <path d="M34.2984 35.0335H42.6317" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M38.5 21.8333H46.1389L51 27.3889" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M37.1111 5.86111H14.8889C14.1218 5.86111 13.5 6.48294 13.5 7.25V25.3056C13.5 26.0726 14.1218 26.6944 14.8889 26.6944H37.1111C37.8782 26.6944 38.5 26.0726 38.5 25.3056V7.25C38.5 6.48294 37.8782 5.86111 37.1111 5.86111Z" stroke="#333333" stroke-linejoin="round"/>
    <path d="M21.7984 14.2002H30.1317" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 6.55556L18.3611 1H33.6389L38.5 6.55556" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BbkIcon',
};
</script>

<style>
</style>
