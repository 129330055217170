<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.00002 5.83341C8.28868 5.83341 9.33335 4.78875 9.33335 3.50008C9.33335 2.21142 8.28868 1.16675 7.00002 1.16675C5.71136 1.16675 4.66669 2.21142 4.66669 3.50008C4.66669 4.78875 5.71136 5.83341 7.00002 5.83341Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.25 12.8333C12.25 9.93376 9.89949 7.58325 7 7.58325C4.10051 7.58325 1.75 9.93376 1.75 12.8333"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.99998 12.8333L8.16665 11.3749L6.99998 7.58325L5.83331 11.3749L6.99998 12.8333Z" stroke="currentColor"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'AddCustomer',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
