<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1737_824)">
      <path
          d="M1.45833 2.04175C1.45833 1.5585 1.85008 1.16675 2.33333 1.16675H9.33333C9.81659 1.16675 10.2083 1.5585 10.2083 2.04175V12.8334H2.33333C1.85008 12.8334 1.45833 12.4417 1.45833 11.9584V2.04175Z"
          stroke="currentColor" stroke-linejoin="round"/>
      <path
          d="M10.2083 7.00008C10.2083 6.67791 10.4695 6.41675 10.7917 6.41675H11.9583C12.2805 6.41675 12.5417 6.67791 12.5417 7.00008V11.9584C12.5417 12.4417 12.1499 12.8334 11.6667 12.8334H10.2083V7.00008Z"
          stroke="currentColor" stroke-linejoin="round"/>
      <path d="M3.20833 3.5H5.54166" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.20833 5.54175H6.70833" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1737_824">
        <rect :width="size" :height="size" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DocumentIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
