<template>
  <div class="original-documents-body">
    <form
      @submit.prevent="search"
      class="cont-filter bg-white pd-x-20 pd-t-20 pd-b-20 mb-2 d-flex align-items-center"
    >
      <span class="material-icons-outlined icon">
        settings_input_component
      </span>

      <div class="input-comp">
        <label class="form-control-label">CLIENTE</label>
        <virtual-select
          :tabindex="1"
          class="lined-virtual-select"
          placeholder="Selecione o Cliente"
          :options="companies"
          v-model="filters.buyer"
        />
      </div>

      <div class="input-comp">
        <label class="form-control-label">EXPORTADOR</label>
        <virtual-select
          :tabindex="2"
          class="lined-virtual-select"
          placeholder="Selecione o Exportador"
          :options="exporters"
          v-model="filters.vendor"
        />
      </div>

      <div class="input-comp">
        <label class="form-control-label">NÚMERO DA PO</label>
        <input
          @keydown="verifyEnter"
          v-model="filters.number"
          type="text"
          name="poNumber"
          id="poNumber"
          placeholder="Número da PO"
        />
      </div>

      <div class="input-comp">
        <label for="etapas">DATA DA PO</label>
        <date-picker lang="pt-br"
                     v-model="filters.date"
                     name="invoiceDate"
                     class="input-date"
                     format="DD/MM/YY"
                     clear-button="true"
                     placeholder="Selecione"
        ></date-picker>
      </div>

      <div>
        <button type="button" class="filter-btn" @click.prevent="search">
          <span class="material-icons-outlined"> cached </span>
          PESQUISAR
        </button>
      </div>
    </form>

    <vue-good-table
      :fixed-header="true"
      :columns="columns"
      :rows="invoices.content"
      :isLoading="loading"
      :select-options="{
        enabled: false,
      }"
      :sort-options="{
        enabled: false,
      }"
      styleClass="vgt-table"
    >
      <!-- Loading State -->
      <template slot="loadingContent">
        <div class="btn btn-primary br-10">Carregando...</div>
      </template>

      <!-- Empty Table -->
      <div slot="emptystate">
        <h4 class="text-center">Nenhuma Ordem de Compra</h4>
      </div>

      <!-- Custom Columns -->
      <template slot="table-row" slot-scope="props">
        <!-- ID -->
        <span v-if="props.column.field === 'id'"> #{{ props.row.id }} </span>
        <!-- Actions -->

        <div v-else-if="props.column.field === 'actions'">
          <div class="flex ai-c gap-1">
            <v-table-button
              @click.native.prevent="$emit('viewPo', props.row.id)"
            >
              <template #icon>
                <review-icon size="14"/>
              </template>
              Ver PO
            </v-table-button>

            <v-table-button
              color="danger"
              @click.native.prevent="$emit('destroyPo', props.row.id)"
            >
              <template #icon>
                <reject-icon size="14"/>
              </template>
              Remover
            </v-table-button>
          </div>
        </div>

        <!-- Generic -->
        <div v-else>
          <span v-if="props.formattedRow[props.column.field]">
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else>-</span>
        </div>
      </template>
    </vue-good-table>

    <VTablePagination
      :total="invoices.totalElements"
      :last-page="invoices.totalPages"
      :current-page="invoices.number + 1"
      @page-change="(page) => getPos(page - 1)"
    />
  </div>
</template>

<script>
import RejectIcon from "@/components/icons/RejectIcon.vue";
import ReviewIcon from "@/components/icons/ReviewIcon.vue";
import VTableButton from "@/components/VTableButton.vue";
import VTablePagination from "@/components/VTablePagination.vue";
import {VueGoodTable} from "vue-good-table";
import poTableColumns from "@/utils/PoTableColumns";
import {dateFilter} from "vue-date-fns";
import Axios from "axios";
import config from "@/config";
import DatePicker from "vue2-datepicker";
import moment from "moment/moment";
import VirtualSelect from "@/components/VirtualSelect.vue";
import Swal from "sweetalert2";

export default {
  name: "PosTable",
  components: {
    VirtualSelect,
    RejectIcon,
    ReviewIcon,
    VTableButton,
    VTablePagination,
    "vue-good-table": VueGoodTable,
    "date-picker": DatePicker,
  },
  filters: {
    date: dateFilter,
    status: (value) => {
      switch (value) {
        case "APPROVED":
          return "Aprovado";
        case "CREATED":
          return "Invoice Criada";
        case "REJECTED":
          return "Rejeitado";
        case "COMPLETE":
          return "Pronto p/ Revisão";
        case "AWAITING":
          return "Extraindo Dados";
        default:
          return "N/I";
      }
    },
    documentType: (value) => {
      switch (value) {
        case "invoices":
          return "Invoice / Proforma Invoice";
        case "purchase_orders":
          return "Ordem de Compra";
        default:
          return "N/I";
      }
    },
  },
  props: {},
  data() {
    return {
      columns: poTableColumns,
      loading: false,
      companies: [],
      exporters: [],
      invoices: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        number: 0,
        numberOfElements: 0,
      },
      filters: {
        number: "",
        buyer: null,
        vendor: null,
        date: null,
      },
    };
  },
  methods: {
    listener() {
      this.getPos(this.invoices.number);
    },
    async getCompanies() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllCustomers`
        );
        this.companies = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));

        const exResponse = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllExporters`
        );
        this.exporters = exResponse.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Clientes",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
    async getPos(page = 0, filters) {
      this.loading = true;

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/purchase-orders`,
          {
            params: {
              page,
              ...filters,
            },
          }
        );

        this.invoices = response.data.data;
      } catch (e) {
        Swal.fire({
          title: "Carregar Ordens de Compra",
          html: `<p>Não foi possível recuperar as ordens de compra</p> <div>${e.response.data.message}</div>`,
          type: "error",
        });
      }

      this.loading = false;
    },
    search() {
      this.$router.replace({
        name: "IndexPos",
        query: {
          ...this.filters,
          date: this.filters.date
            ? `${moment(this.filters.date).format(
              "YYYY-MM-DD"
            )} 00:00:00.000000`
            : "",
        },
      });
    },
    verifyEnter(e) {
      if (e.key === "Enter") {
        this.search();
      }
    },
  },
  async mounted() {
    await this.getCompanies();
    window.addEventListener("reloadDocuments", this.listener);
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler() {
        this.getPos(0, {
          ...this.filters,
          darte: this.$route.query.date
            ? `${moment(this.$route.query.date).format(
              "YYYY-MM-DD"
            )} 00:00:00.000000`
            : "",
        });
      },
    },
  },
};
</script>

<style>
.cont-filter {
  gap: 20px;
}

.cont-filter input {
  height: 36px !important;
}

.cont-filter select,
.cont-filter .mx-input-wrapper input {
  height: 40px !important;
}

.input-comp {
  flex: 1 1 auto;
}

.input-comp-limited {
  flex: 0 1 250px;
}

.input-comp.date {
  flex: 0 1 auto;
}

.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}

.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}

.cont-filter .icon {
  color: var(--gray-dark);
}

.lined-virtual-select fieldset {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
}
</style>
