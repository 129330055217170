<template>
  <div class="slim-mainpanel">
    <!-- <Loading ref="loading" label="Lançamento Financeiro" description="Carregando as informações do Lançamento Financeiro."></Loading> -->

    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item">
            <a href="#">{{ title1 }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ title2 }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ title2 }} / {{ modelTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>
        <!-- card-header -->

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <moviment-form-general
                :model="moviment"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
                :modelTitle="modelTitle"
                :url="url"
              />
            </div>
            <!-- tab-pane -->
          </div>
          <!-- tab-content -->
        </div>
        <!-- card-body -->
      </div>
      <!-- card -->
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";
import { EventBus } from "@/events/EventBus.js";

// Children
import Moviment from "@/models/Moviment.js";
import BankAccount from "@/models/BankAccount.js";
import MovimentFormGeneral from "@/views/finances/moviments/Form.General.vue";

export default {
  name: "MovimentsForm",
  components: {
    "moviment-form-general": MovimentFormGeneral,
  },
  data() {
    return {
      url:
        this.$route.params.moviments === "incomes"
          ? "/finances/moviments/incomes/"
          : "/finances/moviments/expenses/",
      title1:
        this.$route.params.moviments === "incomes"
          ? "Contas a Receber"
          : "Contas a Pagar",
      title2: this.isNewRecord()
        ? "Adicionar Lançamento"
        : " Editar Lançamento",
      modelTitle:
        this.$route.params.moviments === "incomes"
          ? "Conta a Receber"
          : "Conta a Pagar",
      moviment: new Moviment(),
      bankAccount: new BankAccount(),
      oldModel: new BankAccount(),
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    loadModel() {
      const self = this;
      this.$store.commit("setLoading", {
        show: true,
        label: "Lançamento Financeiro",
        description: "Carregando as informações do Lançamento Financeiro.",
      });
      Axios.get(
        `${config.env.API_DEFAULT.host}/moviments/${self.$route.params.id}`
      )
        .then((response) => {
          self.moviment = response.data;

          Axios.get(self.moviment._links.typeOfCostCenter.href).then(
            (response) => {
              self.$set(self.moviment, "typeOfCostCenter", response.data);
            }
          );
          Axios.get(self.moviment._links.typeOfDocument.href).then(
            (response) => {
              self.$set(self.moviment, "typeOfDocument", response.data);
            }
          );
          Axios.get(self.moviment._links.company.href).then((response) => {
            self.$set(self.moviment, "company", response.data);
          });
          Axios.get(self.moviment._links.account.href).then((response) => {
            self.$set(self.moviment, "account", response.data);
          });

          if (
            self.moviment._embedded !== null &&
            self.moviment._embedded !== undefined
          )
            self.$set(self.moviment, "_embedded", {
              appointments: self.moviment._embedded.appointments,
              receipts: self.moviment._embedded.receipts,
            });

          if (
            self.moviment.typeOfMovimentation === "PROCESS_INCOME" ||
            self.moviment.typeOfMovimentation === "PROCESS_EXPENSE"
          ) {
            Axios.get(self.moviment._links.process.href).then((response) => {
              self.$set(self.moviment, "process", response.data);
            });
          } else {
            Axios.get(self.moviment._links.chartOfAccount.href).then(
              (response) => {
                self.$set(self.moviment, "chartOfAccount", response.data);
              }
            );
          }

          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          self.$swal(
            "Movimentações",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        });
    },
  },
  mounted() {
    const self = this;

    EventBus.$on(
      "Finance.Moviments.Appointments.Form.General.loadModel",
      () => {
        self.loadModel();
      }
    );

    if (!this.isNewRecord()) {
      self.$set(self.moviment, "_embedded", { appointments: [], receipts: [] });
      self.loadModel();
    } else {
      self.$set(self.moviment, "_embedded", { appointments: [], receipts: [] });
      if (this.$route.params.moviments === "incomes")
        self.moviment.typeOfMovimentation = "FINANCE_INCOME";
      self.moviment.enabledMovimentCheckingAccountOfProcess = false;
    }
  },
};
</script>
