<template>
  <div class="columns-12 no-gap" style="height: 100%; overflow: hidden">
    <div
      v-if="selectedDocuments.length > 1"
      class="span-3 documents-sidebar-left"
      style="overflow: auto"
    >
      <ul class="selected-documents-list">
        <li
          v-for="(document, index) in selectedDocuments"
          :key="document.id"
          :class="{
            active: selectedDocument.id === document.id,
          }"
          @click.prevent="selectDocument(index)"
        >
          <div class="flex ai-c fgap1 fw-w">
            <div>
              <p class="mb-0">#{{ document.id }}</p>
              <p class="mb-0 text-medium doc-text-max">
                {{ document.extractionResult.buyerName }}
              </p>
              <p class="mb-0 doc-text-max">
                {{ document.extractionResult.vendorName }}
              </p>
            </div>

            <div>
              <div
                class="blue-pill"
                v-if="document.documentType === 'invoices'"
                :type="document.document.type"
              >
                Invoice
                <span class="text-bold">
                  {{ document.extractionResult.invoiceNumber }}</span
                >
              </div>
              <div
                class="yellow-pill"
                v-if="document.documentType === 'purchase_orders'"
                :type="document.document.type"
              >
                Pedido
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      class="m-3 bg-white flex fd-c"
      :class="{
        'span-9': selectedDocuments.length > 1,
        'span-12': selectedDocuments.length <= 1,
      }"
      style="overflow: auto; border: 1px solid #dee2e6"
    >
      <ul class="original-documents-invoice-tabs flex ai-c">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          :class="{ active: tab.active }"
          @click.prevent="changeTab(tab.id)"
        >
          {{ tab.title }}
        </li>
      </ul>

      <div v-if="activeTab === 1" class="columns-12 md-gap pv-1 ph-1 ac-gs">
        <div class="span-12">
          <div class="flex ai-c fgap2">
            <span class="text-bold">Informações Gerais</span>
            <hr class="styled-hr" />
          </div>
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Data da PO"
            date-no-icon
            type="date"
            name="invoiceDate"
            :value="selectedDocument.extractionResult.invoiceDate"
            @input="(value) => updateField('invoiceDate', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Número da PO / Ordem de Compra"
            date-no-icon
            type="string"
            name="poNumber"
            :value="selectedDocument.extractionResult.poNumber"
            @input="(value) => updateField('poNumber', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Incoterm"
            date-no-icon
            type="string"
            name="incoterm"
            :value="selectedDocument.extractionResult.incoterm"
            @input="(value) => updateField('incoterm', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            money-mask
            label="Valor Total"
            date-no-icon
            name="invoiceAmount"
            :value="selectedDocument.extractionResult.invoiceAmount"
            @input="(value) => updateField('invoiceAmount', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Moeda"
            date-no-icon
            type="string"
            name="currency"
            :value="selectedDocument.extractionResult.currency"
            @input="(value) => updateField('currency', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Termos de Entrega"
            date-no-icon
            type="string"
            name="termsOfDelivery"
            :value="selectedDocument.invoice.termsOfDelivery"
            @input="(value) => updateInvoiceField('termsOfDelivery', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Termos de Pagamento"
            date-no-icon
            type="string"
            name="paymentTerms"
            :value="selectedDocument.extractionResult.paymentTerms"
            @input="(value) => updateField('paymentTerms', value)"
          />
        </div>

        <div class="span-3"></div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Local de Embarque"
            date-no-icon
            type="string"
            name="placeOfDeparture"
            :value="selectedDocument.invoice.placeOfDeparture"
            @input="(value) => updateInvoiceField('placeOfDeparture', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Local de Desembarque"
            date-no-icon
            type="string"
            name="placeOfDischarge"
            :value="selectedDocument.invoice.placeOfDischarge"
            @input="(value) => updateInvoiceField('placeOfDischarge', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Proforma"
            date-no-icon
            type="string"
            name="proforma"
            :value="selectedDocument.invoice.proforma"
            @input="(value) => updateInvoiceField('proforma', value)"
          />
        </div>

        <div class="span-3">
          <v-input
            :disabled="readOnly"
            label="Data de Embarque"
            date-no-icon
            type="date"
            name="shipmentDate"
            :value="selectedDocument.invoice.shipmentDate"
            @input="(value) => updateInvoiceField('shipmentDate', value)"
          />
        </div>

        <div class="span-12"></div>

        <div class="span-6">
          <div class="columns-12 md-gap">
            <div class="span-12">
              <div class="flex ai-c fgap2">
                <span class="text-bold">Exportador / Fabricante</span>
                <hr class="styled-hr" />
              </div>
            </div>

            <div class="span-12">
              <virtual-select
                :tabindex="1"
                placeholder="Exportador"
                :disabled="readOnly"
                :options="exporters"
                :value="selectedDocument.extractionResult.vendorName"
                @input="(value) => updateField('vendorName', value)"
              />
            </div>

            <div class="span-12">
              <v-input
                :disabled="readOnly"
                label="Endereço"
                date-no-icon
                type="string"
                name="vendorLocation"
                :value="selectedDocument.extractionResult.vendorLocation"
                @input="(value) => updateField('vendorLocation', value)"
              />
            </div>

            <div class="span-6">
              <v-input
                :disabled="readOnly"
                label="Cidade"
                date-no-icon
                type="string"
                name="vendorCity"
                :value="selectedDocument.extractionResult.vendorCity"
                @input="(value) => updateField('vendorCity', value)"
              />
            </div>

            <div class="span-6">
              <v-input
                :disabled="readOnly"
                label="País"
                date-no-icon
                type="string"
                name="vendorCountry"
                :value="selectedDocument.extractionResult.vendorCountry"
                @input="(value) => updateField('vendorCountry', value)"
              />
            </div>
          </div>
        </div>

        <div class="span-6">
          <div class="columns-12 md-gap">
            <div class="span-12">
              <div class="flex ai-c fgap2">
                <span class="text-bold">Cliente / Importador</span>
                <hr class="styled-hr" />
              </div>
            </div>

            <div class="span-12">
              <virtual-select
                :tabindex="2"
                placeholder="Cliente"
                :disabled="readOnly"
                :options="companies"
                :value="selectedDocument.extractionResult.buyerName"
                @input="(value) => updateField('buyerName', value)"
              />
            </div>

            <div class="span-12">
              <v-input
                :disabled="readOnly"
                label="Endereço"
                date-no-icon
                type="string"
                name="buyerLocation"
                :value="selectedDocument.extractionResult.buyerLocation"
                @input="(value) => updateField('buyerLocation', value)"
              />
            </div>

            <div class="span-6">
              <v-input
                :disabled="readOnly"
                label="Cidade"
                date-no-icon
                type="string"
                name="buyerCity"
                :value="selectedDocument.extractionResult.buyerCity"
                @input="(value) => updateField('buyerCity', value)"
              />
            </div>

            <div class="span-6">
              <v-input
                :disabled="readOnly"
                label="País"
                date-no-icon
                type="string"
                name="buyerCountry"
                :value="selectedDocument.extractionResult.buyerCountry"
                @input="(value) => updateField('buyerCountry', value)"
              />
            </div>
          </div>
        </div>

        <div class="span-12">
          <div class="flex ai-c fgap2 jc-c" v-if="!readOnly">
            <a
              href="#"
              @click.prevent="storePo(selectedDocument)"
              class="btn-approve"
              >Salvar</a
            >
            <a
              href="#"
              @click.prevent="cancelPo(selectedDocument.id)"
              class="btn-cancel"
              >Cancelar</a
            >
          </div>
        </div>
      </div>

      <div v-if="activeTab === 2" class="pv-1 ph-1 flex1">
        <div class="flex fd-c fgap2 h-100">
          <div class="flex ai-c fgap2">
            <span class="text-bold">Informações Gerais</span>
            <hr class="styled-hr" />
          </div>

          <div class="columns-12 md-gap">
            <div class="span-6">
              <v-input
                :disabled="readOnly"
                money-mask
                label="Peso Líquido"
                type="string"
                name="netWeight"
                :value="selectedDocument.extractionResult.netWeight"
                @input="(value) => updateField('netWeight', value)"
              />
            </div>

            <div class="span-6">
              <v-input
                :disabled="readOnly"
                money-mask
                label="Peso Bruto"
                type="string"
                name="grossWeight"
                :value="selectedDocument.extractionResult.grossWeight"
                @input="(value) => updateField('grossWeight', value)"
              />
            </div>

            <!--              <div class="span-3">-->
            <!--                <v-input-->
            <!--                    :disabled="readOnly"-->
            <!--                    money-mask-->
            <!--                    label="Volumes"-->
            <!--                    type="string"-->
            <!--                    name="volumes"-->
            <!--                    :value="selectedDocument.extractionResult.volumes"-->
            <!--                    @input="(value) => updateField('volumes', value)"-->
            <!--                />-->
            <!--              </div>-->

            <!--              <div class="span-3">-->
            <!--                <v-input-->
            <!--                    :disabled="readOnly"-->
            <!--                    label="Tipo de Volume"-->
            <!--                    type="string"-->
            <!--                    name="volume_type"-->
            <!--                    :value="selectedDocument.extractionResult.volume_type"-->
            <!--                    @input="(value) => updateField('volume_type', value)"-->
            <!--                />-->
            <!--              </div>-->
          </div>

          <div class="flex ai-c fgap2">
            <span class="text-bold">Itens / Mercadorias</span>
            <hr class="styled-hr" />
          </div>

          <div class="h-100">
            <div class="table-overflow">
              <table class="original-documents-table" style="width: 100%">
                <thead>
                  <tr>
                    <th></th>
                    <th>Descrição da Mercadoria</th>
                    <th>NCM</th>
                    <th>Quantidade</th>
                    <th>Valor Unitário</th>
                    <th>Valor Total</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in selectedDocument.extractionResult
                      .items"
                    :key="index"
                  >
                    <td style="width: 7%" class="text-center">
                      <p class="mb-0">ITEM</p>
                      <p class="mb-0"># {{ index }}</p>
                    </td>
                    <td style="width: 28%">
                      <v-input
                        :disabled="readOnly"
                        label=""
                        name="description"
                        :value="item.description"
                        @input="(value) => (item.description = value)"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-input
                        :disabled="readOnly"
                        label=""
                        name="ncm"
                        :value="item.ncm"
                        @input="(value) => (item.ncm = value)"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-input
                        :disabled="readOnly"
                        money-mask
                        label=""
                        name="quantity"
                        :value="item.quantity"
                        @input="(value) => (item.quantity = value)"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-input
                        :disabled="readOnly"
                        money-mask
                        label=""
                        name="unitPrice"
                        :value="item.unitPrice"
                        @input="(value) => (item.unitPrice = value)"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-input
                        :disabled="readOnly"
                        money-mask
                        label=""
                        name="unitTotal"
                        :value="item.unitTotal"
                        @input="(value) => (item.unitTotal = value)"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-table-button
                        v-if="!readOnly"
                        color="danger"
                        @click.native="removeLineItem(index)"
                      >
                        <template #icon>
                          <reject-icon size="14" />
                        </template>
                        Remover
                      </v-table-button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7" style="border-bottom: 1px solid #dee2e6">
                      <div v-if="!readOnly" class="flex ai-c jc-fe fgap2">
                        <span
                          >Clique no botão para adicionar mais parcelas</span
                        >
                        <button
                          @click.prevent="addLineItem"
                          class="crm-button iconed"
                        >
                          <span class="flex ai-c jc-c">
                            <plus-icon color="currentColor" />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="document-card-footer">
            <div class="flex jc-fe ai-c fgap1">
              <label>Valor total da Invoice</label>

              <v-input
                :disabled="readOnly"
                v-if="companies.length > 0"
                money-mask
                type="text"
                label=""
                name="invoiceAmount"
                :value="selectedDocument.extractionResult.invoiceAmount"
                @input="(value) => updateField('invoiceAmount', value)"
              />
            </div>
          </div>

          <div>
            <div v-if="!readOnly" class="flex ai-c fgap2 jc-c">
              <a
                href="#"
                @click.prevent="storePo(selectedDocument)"
                class="btn-approve"
                >Salvar</a
              >
              <a
                href="#"
                @click.prevent="cancelPo(selectedDocument.id)"
                class="btn-cancel"
                >Cancelar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from "@/components/VInput.vue";
import { dateFilter } from "vue-date-fns";
import VTableButton from "@/components/VTableButton.vue";
import RejectIcon from "@/components/icons/RejectIcon.vue";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import Axios from "axios";
import config from "@/config";
import Swal from "sweetalert2";
import VirtualSelect from "@/components/VirtualSelect.vue";

export default {
  name: "OriginalDocumentsInvoice",
  components: {
    VirtualSelect,
    PlusIcon,
    RejectIcon,
    VTableButton,
    VInput,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: "Informações",
          active: true,
        },
        {
          id: 2,
          title: "Itens / Mercadorias",
          active: false,
        },
        // {
        //   id: 3,
        //   title: "Parcelas / Vencimentos",
        //   active: false,
        // },
        // {
        //   id: 4,
        //   title: "PO",
        //   active: false,
        // },
      ],
      companies: [],
      exporters: [],
    };
  },
  filters: {
    date: dateFilter,
    documentType: (value) => {
      switch (value) {
        case "invoices":
          return "Invoice / Proforma Invoice";
        case "purchase_orders":
          return "Ordem de Compra";
        default:
          return "N/I";
      }
    },
  },
  props: {
    selectedDocuments: {
      type: Array,
      default: () => [],
    },
    selectedDocument: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    activeTab() {
      if (this.tabs.find((tab) => tab.active === true)) {
        return this.tabs.find((tab) => tab.active === true).id;
      }
    },
  },
  methods: {
    changeTab(tabId) {
      this.tabs = this.tabs.map((tab) => {
        if (tab.id === tabId) {
          return {
            ...tab,
            active: true,
          };
        }
        return {
          ...tab,
          active: false,
        };
      });
    },
    async getCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando clientes.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllCustomers`
        );
        this.companies = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Clientes",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getExporters() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando exportadores.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllExporters`
        );
        this.exporters = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Exportadores",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    updateField(field, value) {
      this.$emit("updateField", [field, value]);
    },
    updateInvoiceField(field, value) {
      this.$emit("updateInvoiceField", [field, value]);
    },
    selectDocument(index) {
      this.$emit("selectDocument", index);
    },
    addLineItem() {
      this.$emit("addLineItem", true);
    },
    removeLineItem(index) {
      this.$emit("removeLineItem", index);
    },
    addInstallmentItem() {
      this.$emit("addInstallmentItem", true);
    },
    removeInstallmentItem(index) {
      this.$emit("removeInstallmentItem", index);
    },
    storePo(selectedDocument) {
      this.$emit("storePo", selectedDocument);
    },
    cancelPo(id) {
      this.$emit("cancelPo", id);
    },
  },
  async mounted() {
    await this.getCompanies();
    await this.getExporters();
    this.updateInvoiceField("comments", this.selectedDocument.invoice.comments);
  },
};
</script>

<style scoped>
.selected-documents-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.selected-documents-list li {
  padding: 0.5rem 1rem;
  color: #343a40;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
  background-color: white;
}

.selected-documents-list li:hover,
.selected-documents-list li.active {
  background-color: #f8f9fa;
}

.selected-documents-list li.approved {
  background-color: #ebffee;
}

.selected-documents-list li.rejected {
  background-color: #fff1f1;
}

.documents-sidebar-left {
  background-color: white;
  border-right: 1px solid #e2e2e2;
}

.doc-text-max {
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blue-pill,
.yellow-pill {
  padding: 0.25rem 0.5rem;
  border-radius: 100px;
  font-size: 12px;
  color: white;
}

.blue-pill {
  background-color: #104375;
}

.yellow-pill {
  background-color: #dbb058;
}

.check-span {
  background-color: #1b84e7;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.check-span.lg {
  width: 20px;
  height: 20px;
  line-height: 25px;
  vertical-align: middle;
}

.check-span.light {
  background-color: #eaecf0;
}

.check-span.active,
.check-span.light.active {
  background-color: #3398ff;
}

.document-card {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #dee2e6;
}

.document-card.with-footer {
  padding: 10px 10px 0px 10px;
}

.document-card-footer {
  background-color: #f8f9fa;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
}

.original-documents-table {
  border-collapse: separate;
  border-spacing: 0;
}

.original-documents-table thead tr th {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  overflow: hidden;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #343a40;
}

.original-documents-table thead tr th:first-of-type {
  border-left: 1px solid #e2e2e2;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.original-documents-table thead tr th:last-of-type {
  border-right: 1px solid #e2e2e2;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.original-documents-table td {
  padding: 10px;
}

.btn-approve,
.btn-reject,
.btn-cancel {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0.5rem 3rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  border-radius: 10px;
  font-size: 0.8rem;
}

.btn-approve {
  background-color: #3b9b28;
}

.btn-reject {
  background-color: #a80005;
}

.btn-cancel {
  background-color: #104375;
}

.styled-hr {
  border: 0;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  flex: 1;
}

.original-documents-invoice-tabs {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #dee2e6;
}

.original-documents-invoice-tabs li {
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #343a40;
  cursor: pointer;
  position: relative;
}

.original-documents-invoice-tabs li::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #104375;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}

.original-documents-invoice-tabs li.active::before,
.original-documents-invoice-tabs li:hover::before {
  opacity: 1;
}
</style>
