<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.91669 3.5V5.83333V6.125C9.05478 6.125 8.2802 6.49886 7.74625 7.09327C7.2823 7.60976 7.00002 8.29275 7.00002 9.04167C7.00002 9.21051 7.01437 9.376 7.0419 9.537C7.19191 10.4138 7.73336 11.1571 8.47997 11.5805C7.7034 11.8153 6.67265 11.9583 5.54169 11.9583C3.12543 11.9583 1.16669 11.3054 1.16669 10.5V8.16667V5.83333V3.5"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M12.8333 9.04167C12.8333 10.6525 11.5275 11.9583 9.91667 11.9583C9.39432 11.9583 8.90403 11.821 8.47995 11.5805C7.73334 11.1571 7.19189 10.4138 7.04188 9.537C7.01435 9.376 7 9.21051 7 9.04167C7 8.29275 7.28228 7.60976 7.74623 7.09327C8.28018 6.49886 9.05476 6.125 9.91667 6.125C11.5275 6.125 12.8333 7.43085 12.8333 9.04167Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M9.91669 3.50008C9.91669 4.30549 7.95794 4.95841 5.54169 4.95841C3.12543 4.95841 1.16669 4.30549 1.16669 3.50008C1.16669 2.69467 3.12543 2.04175 5.54169 2.04175C7.95794 2.04175 9.91669 2.69467 9.91669 3.50008Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M1.16669 8.16675C1.16669 8.97216 3.12543 9.62508 5.54169 9.62508C6.06873 9.62508 6.57398 9.59402 7.0419 9.53709"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M1.16669 5.83325C1.16669 6.63866 3.12543 7.29159 5.54169 7.29159C6.34561 7.29159 7.0989 7.21931 7.74625 7.09319"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7415 9.86646L11.9582 11.0832" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M11.0833 9.04167C11.0833 9.36384 10.9528 9.65551 10.7416 9.86662C10.5305 10.0778 10.2388 10.2083 9.91667 10.2083C9.27235 10.2083 8.75 9.68599 8.75 9.04167C8.75 8.39735 9.27235 7.875 9.91667 7.875C10.561 7.875 11.0833 8.39735 11.0833 9.04167Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8334 9.04175C12.8334 10.6526 11.5275 11.9584 9.91669 11.9584" stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DatabaseIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
