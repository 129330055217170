import Axios from 'axios';
import config from '@/config.js';

export default {
  findCNPJ(cnpj) {
    return new Promise((resolve, reject) => {
      delete Axios.defaults.headers.common.Authorization;

      Axios.get(`${config.env.HUBDODESENVOLVEDOR.host}/cnpj/?cnpj=${cnpj}${config.env.HUBDODESENVOLVEDOR.token}`)
        .then((response) => {
          resolve(response);
        });

      Axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('accessToken')}`;
    });
  },
  convertPTStringToDate(dateString) {
    const reggie = /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}).(\d{1})/;
    const [, day, month, year, hours, minutes, seconds, miliseconds] = reggie.exec(dateString);
    const dateObject = new Date(year, month - 1, day, hours, minutes, seconds, miliseconds);

    return dateObject;
  },
};
