
<template >
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Etapa</label>

      <div class="row no-gutters">
        <div class="col-lg-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Stiuação <span class="tx-danger">*</span>
            </label>

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="model.enabled"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Habilitada</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="enabled"
                    type="radio"
                    v-model="model.enabled"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Desabilitada</span>
                </label>
              </div>
              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
                >Selecione uma opção</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Etapa Inicial <span class="tx-danger">*</span>
            </label>

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="first"
                    type="radio"
                    v-model="model.first"
                    :value="true"
                    v-validate="'required'"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="first"
                    type="radio"
                    v-model="model.first"
                    :value="false"
                    v-validate="'required'"
                  />
                  <span>Não</span>
                </label>
              </div>
              <span
                v-show="errors.has('enabled')"
                class="parsley-errors-list filled"
                >Selecione uma opção</span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Código <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              v-model="model.code"
              class="form-control"
              name="code"
              v-validate="'required'"
            />
            <span v-show="errors.has('code')" class="parsley-errors-list filled"
              >Digite um código</span
            >
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Ordem <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              v-model="model.position"
              class="form-control tx-right"
              name="position"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('position')"
              class="parsley-errors-list filled"
              >Digite a Ordem</span
            >
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Cor do Fundo <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              v-model="model.color"
              class="form-control"
              :style="'background-color: ' + model.color + ';'"
              name="color"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('color')"
              class="parsley-errors-list filled"
              >Digite a Cor</span
            >
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Cor do Texto <span class="tx-danger">*</span></label
            >
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="textColor"
                    v-model="model.textColor"
                    value="#000000"
                  />
                  <span> Preto <small></small></span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="textColor"
                    v-model="model.textColor"
                    value="#FFFFFF"
                  />
                  <span> Branco </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Tipo de Processo </label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="typeOfProcess"
                    v-model="model.typeOfProcess"
                    value="IMPORT"
                  />
                  <span> Imp <small></small></span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="typeOfProcess"
                    v-model="model.typeOfProcess"
                    value="EXPORT"
                  />
                  <span> Exp </span>
                </label>
              </div>
            </div>
            <span
              v-show="errors.has('typeOfProcess')"
              class="parsley-errors-list filled"
              >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Alias <span class="tx-danger">*</span></label
            >
            <input type="text" v-model="model.alias" class="form-control" />
            <span
              v-show="errors.has('alias')"
              class="parsley-errors-list filled"
              >Digite um alias</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-6 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Nome <span class="tx-danger">*</span></label
            >
            <input type="text" v-model="model.name" class="form-control" />
            <span v-show="errors.has('name')" class="parsley-errors-list filled"
              >Digite um nome</span
            >
          </div>
        </div>

        <div class="col-md-6 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
              >Descrição <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              v-model="model.description"
              class="form-control"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Selecione uma opção</span
            >
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20"
        >Configuração de Campos do Telão Operacional</label
      >

      <div class="row no-gutters mg-b-40">
        <div class="col-lg-4">
          <div class="card bd">
            <div
              class="card-header d-flex align-items-center justify-content-between pd-y-5 bd-b tx-medium"
            >
              <label class="section-title mg-l-15 mg-b-0 mg-t-10"
                >Campos Disponíveis</label
              >
              <div class="card-option tx-24">
                <!-- <div class="input-group">
                                <input v-model="searchField" type="text" class="form-control bg-white" placeholder="Buscar campo..." style="border: 1px solid #ced4da !important; padding: 0.594rem 0.75rem;">
                                <span class="input-group-btn">
                                    <button class="btn bd bd-l-0 bg-white tx-gray-600" type="button"><i class="fa fa-search"></i></button>
                                </span>
                            </div> -->
              </div>
            </div>

            <div class="card-body pd-0">
              <perfect-scrollbar
                class="bd-0-force col-md-12 mg-b-10"
                style="max-height: 300px !important"
              >
                <ul class="list-group bd-0 bd-t-0">
                  <draggable v-model="fieldsToViewSorted">
                    <li
                      class="bd-l-0 bd-r-0 list-group-item"
                      v-for="(value, key) in filteredLabels"
                      :key="'fieldsToViewSorted' + key"
                    >
                      <p class="mg-b-0">
                        <strong class="tx-inverse tx-medium">
                          <input
                            v-model="fieldsToView"
                            :value="{ key: key, value: value }"
                            type="checkbox"
                            checked=""
                          />
                        </strong>
                        <label class="text-muted mg-b-0">
                          {{ value.label }}
                        </label>
                      </p>
                    </li>
                  </draggable>
                </ul>
              </perfect-scrollbar>

              <div class="card-footer bd-t tx-center">
                <a
                  href="#"
                  class="btn btn-danger btn-sm btn-block tx-uppercase"
                  @click.prevent="
                    fieldsToView = null;
                    model.fieldsToView = null;
                  "
                  >Limpar Seleção</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card bd bd-l-0-force">
            <div
              class="card-header d-flex align-items-center justify-content-between pd-y-5 bd-b tx-medium"
            >
              <label class="section-title mg-l-15 mg-b-0 mg-t-10"
                >Ordenação dos Campos</label
              >
              <div class="card-option tx-24">
                <!-- <div class="input-group">
                                <input v-model="searchField" type="text" class="form-control bg-white" placeholder="Buscar campo..." style="border: 1px solid #ced4da !important; padding: 0.594rem 0.75rem;">
                                <span class="input-group-btn">
                                    <button class="btn bd bd-l-0 bg-white tx-gray-600 " type="button"><i class="fa fa-search"></i></button>
                                </span>
                            </div> -->
              </div>
            </div>

            <div class="card-body pd-0">
              <perfect-scrollbar
                class="bd-0-force col-md-12 mg-b-10"
                style="max-height: 300px !important"
              >
                <ul class="list-group bd-0 bd-t-0">
                  <draggable v-model="sortedFields" :sortable="true">
                    <template v-for="(value, key) in filteredLabels">
                      <li
                        class="bd-l-0 bd-r-0 list-group-item"
                        :key="key + ' ASC'"
                      >
                        <p class="mg-b-0">
                          <strong class="tx-inverse tx-medium">
                            <input
                              v-model="sortedFields"
                              :value="'sortedFields' + key + ' ASC'"
                              type="checkbox"
                              checked=""
                            />
                          </strong>
                          <span class="text-muted">
                            {{ value.label }} ASC
                          </span>
                        </p>
                      </li>
                      <li
                        class="bd-l-0 bd-r-0 list-group-item"
                        :key="key + ' DESC'"
                      >
                        <p class="mg-b-0">
                          <strong class="tx-inverse tx-medium">
                            <input
                              v-model="sortedFields"
                              :value="key + ' DESC'"
                              type="checkbox"
                              checked=""
                            />
                          </strong>
                          <span class="text-muted">
                            {{ value.label }} DESC
                          </span>
                        </p>
                      </li>
                    </template>
                  </draggable>
                </ul>
              </perfect-scrollbar>

              <div class="card-footer bd-t tx-center">
                <a
                  href="#"
                  class="btn btn-danger btn-sm btn-block tx-uppercase"
                  @click.prevent="
                    sortedFields = null;
                    model.sortedFields = null;
                  "
                  >Limpar Seleção</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card bd bd-l-0-force">
            <div
              class="card-header d-flex align-items-center justify-content-between pd-y-5 bd-b tx-medium"
            >
              <label class="section-title mg-l-15 mg-b-0 mg-t-10"
                >Campos Obrigatórios</label
              >
              <div class="card-option tx-24">
                <!-- <div class="input-group">
                                <input v-model="searchField" type="text" class="form-control bg-white" placeholder="Buscar campo..." style="border: 1px solid #ced4da !important; padding: 0.594rem 0.75rem;">
                                <span class="input-group-btn">
                                    <button class="btn bd bd-l-0 bg-white tx-gray-600" type="button"><i class="fa fa-search"></i></button>
                                </span>
                            </div> -->
              </div>
            </div>

            <div class="card-body pd-0">
              <perfect-scrollbar
                class="bd-0-force col-md-12 mg-b-10"
                style="max-height: 300px !important"
              >
                <ul class="list-group bd-0 bd-t-0">
                  <draggable v-model="requiredFieldsSorted" :sortable="true">
                    <li
                      class="bd-l-0 bd-r-0 list-group-item"
                      v-for="(value, key) in filteredLabels"
                      :key="'requiredFieldsSorted-' + key"
                    >
                      <p class="mg-b-0">
                        <strong class="tx-inverse tx-medium">
                          <input
                            :id="'requiredFields-' + key"
                            v-model="requiredFields"
                            :value="key"
                            type="checkbox"
                            checked=""
                          />
                        </strong>
                        <label
                          class="text-muted mg-b-0 mg-l-5"
                          :for="'requiredFields-' + key"
                        >
                          {{ value.label }}</label
                        >
                      </p>
                    </li>
                  </draggable>
                </ul>
              </perfect-scrollbar>

              <div class="card-footer bd-t tx-center">
                <a
                  href="#"
                  class="btn btn-danger btn-sm btn-block tx-uppercase"
                  @click.prevent="
                    requiredFields = [];
                    model.requiredFields = [];
                  "
                  >Limpar Seleção</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i> SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="redirectToMainPage()"
        >
          <i class="fa fa-reply mg-r-5"></i> VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import * as labelsProcesses from "@/labels/labels.processes.js";
import Draggable from "vuedraggable";

export default {
  name: "operational-steps-form-general",
  props: ["model", "processesFields", "oldModel", "isNewRecord"],
  components: {
    Draggable,
  },
  data() {
    return {
      banks: [],
      accounts: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      searchField: "",

      fieldsToView: [],
      fieldsToViewSorted: [],

      sortedFields: [],
      sortedFieldsSorted: [],

      requiredFields: [],
      requiredFieldsSorted: [],

      labels: {
        ...labelsProcesses.labels[0],
      },
      drag: true,
    };
  },
  methods: {
    redirectToMainPage() {
      this.$router.push({
        name: "operational-steps-list",
      });
    },
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) this.addModel();
          else this.updateModel();
        }
      });
    },
    manageListsBeforeSave() {
      if (this.fieldsToView !== null && this.fieldsToView.length > 0)
        this.model.fieldsToView = JSON.stringify(this.fieldsToView);
      else this.model.fieldsToView = null;

      if (this.sortedFields !== null && this.sortedFields.length > 0)
        this.model.sortedFields = JSON.stringify(this.sortedFields);
      else this.model.sortedFields = null;

      if (this.requiredFields !== null && this.requiredFields.length > 0)
        this.model.requiredFields = JSON.stringify(this.requiredFields);
      else this.model.requiredFields = null;
    },
    addModel() {
      this.manageListsBeforeSave();

      Axios.post(`${config.env.API_DEFAULT.host}/processSteps`, this.model)
        .then((response) => {
          if (response.status === 201) {
            this.model = response.data;
            this.$swal({
              title: `O passo ${this.model.description} foi cadastrado com sucesso.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            this.$router.push({
              name: "operational-steps-form",
              params: {
                action: "update",
                id: response.data.id,
              },
            });
          } else {
            this.$swal({
              title: `O passo ${this.model.description} não foi cadastrado.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: `O passo ${this.model.description} não foi cadastrado. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    updateModel() {
      this.manageListsBeforeSave();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/processSteps/${this.model.id}`,
        this.model
      )
        .then((response) => {
          this.$swal({
            title: `O passo ${this.model.description} foi cadastrado com sucesso.`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "success",
          });
        })
        .catch((error) => {
          this.$swal({
            title: `O passo ${this.model.description} não foi cadastrado.`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    sortTable() {
      this.fieldsToView = [
        ...this.fieldsToViewSorted.filter((item) =>
          this.fieldsToView.includes(item)
        ),
      ];
    },
  },
  watch: {
    model() {
      if (
        this.model.fieldsToView !== null &&
        this.model.fieldsToView !== undefined
      )
        this.fieldsToView = JSON.parse(this.model.fieldsToView);
      if (
        this.model.sortedFields !== null &&
        this.model.sortedFields !== undefined
      )
        this.sortedFields = JSON.parse(this.model.sortedFields);
      if (
        this.model.requiredFields !== null &&
        this.model.requiredFields !== undefined
      )
        this.requiredFields = JSON.parse(this.model.requiredFields);
    },
  },
  computed: {
    filteredLabels() {
      if (this.searchField != "")
        return Object.keys(this.labels)
          .map((key) => this.labels[key])
          .filter(
            (item) => item.label.toString().search(this.searchField) > -1
          );
      return this.labels;
    },
  },
};
</script>
