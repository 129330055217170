<template>
  <form class="contact-form" @submit.prevent="addDocument">
    <div class="sp-3 light-bg p-3 bd-b pd-2">
      <div :class="['flex ai-c jc-sb fw-w fgap2',]">
        <div>
          <h4 class="mb-0">DOCUMENTOS DO CLIENTE</h4>
          <p class="mb-0">
            <strong>{{ savedDocuments.length }}</strong> Documento que <strong>{{ customerDetails.name }}</strong> tem
            cadastrado
          </p>
        </div>
        <div>
          <button type="button" class="plus-button" @click="addDialog = !addDialog">
            <plus-icon color="#ffffff"/>
          </button>
        </div>
      </div>
      <transition name="activity" mode="out-in">
        <div class="contact-form mt-4 mb-2" v-if="addDialog">
          <div>
            <v-input name="description" label="Descrição" v-model="formData.description"
                     hint="Digite a descrição do Documento"></v-input>

            <div class="field-error"
                 v-if="validationErrors.length > 0 && validationErrors.find(item => item.field === 'description') !== null">
              {{ validationErrors.find(item => item.field === 'description').message }}
            </div>
          </div>
          <div>
            <select-wrapper label="Tipo de Documento" :options="typesOptions" v-model="formData.type">
            </select-wrapper>

            <div class="field-error"
                 v-if="validationErrors.length > 0 && validationErrors.find(item => item.field === 'type') !== null">
              {{ validationErrors.find(item => item.field === 'type').message }}
            </div>
          </div>
          <div>
            <v-input name="validity" label="Validade" v-model="formData.validity" type="date"
                     hint="Informe a validade do Documento"></v-input>
          </div>
          <v-input-file-drag :multiple="false" class="sp-3" v-model="documents" ref="upload"></v-input-file-drag>
          <div class="sp-3 flex jc-fe">
            <button class="document-submit">Adicionar Documento</button>
          </div>
        </div>
      </transition>
    </div>
    <div class="sp-3 ph-2">
      <div class="table-overflow">
        <table>
          <thead>
          <tr>
            <th>Descrição</th>
            <th>Tipo de Documento</th>
            <th>Validade</th>
            <th>Data de criação</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="savedDocuments.length === 0">
            <td colspan="5" class="text-center">Nenhum Documento cadastrado!</td>
          </tr>
          <template v-else>
            <tr v-for="document in savedDocuments" :key="document.id">
              <td>{{ document.description }}</td>
              <td>{{ document.documentTypeName }}</td>
              <td>{{ document.validity | formatDate }}</td>
              <td>{{ document.created | formatDate }}</td>
              <td>
                <a href="#" class="action-option mr-4" @click.prevent="download(document)"><i
                  class="fa fa-download text-primary"></i></a>
                <a href="#" class="action-option" @click.prevent="deleteFile(document.id)"><i
                  class="icon ion-trash-a text-danger"></i></a>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</template>

<script>
import {
  PlusIcon,
} from '@/components/crm/Icons';

import Axios from 'axios';
import config from '@/config.js';
import VInput from '@/components/VInput.vue';
import VInputFileDrag from '@/components/VInputFileDrag.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import Swal from 'sweetalert2';
import moment from 'moment';
import downloadjs from 'downloadjs';

export default {
  data() {
    return {
      addDialog: false,
      savedDocuments: [],
      documents: [],
      typesOptions: [],
      validationErrors: [],
      formData: {
        description: null,
        type: null,
        validity: null,
      },
    };
  },
  props: {
    customerDetails: {
      type: Object,
      default: () => {
      },
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  methods: {
    async getTypes() {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/finances/moviments/findAllTypesCrmClientes`);

        response.data.data.forEach((type) => {
          this.typesOptions.push({label: type.description, value: type.id});
        });
      } catch (error) {
        Swal.fire({
          title: 'Buscar Tipos de Documento de Empresa',
          html: 'Houve um erro ao buscar os Tipos de Documento de Empresa!',
          type: 'error',
        });
      }
    },
    async addDocument() {
      if (this.documents.length > 0) {
        this.$store.commit('setLoading', {
          show: true,
          label: 'Carregando...',
          description: 'Salvando dados do Documento.',
        });

        this.validateForm();

        if (this.validationErrors.length > 0) {
          Swal.fire({
            title: 'Validação de Formulário',
            html: '<p>Por favor, preencha os campos corretamente!</p>',
            type: 'error',
          });

          this.$store.commit('setLoading', {
            show: false,
          });
        } else {
          try {
            const response = await Axios.post(`${config.env.API_DEFAULT.host}/customerDocuments/saveDocumentModel/${this.customerDetails.id}`, this.formData);

            const document = response.data.data;

            if (document !== null && document !== 'undefined') {
              this.uploadDocument(document.id);
            }

            this.$store.commit('setLoading', {
              show: false,
            });
          } catch (error) {
            Swal.fire({
              title: 'Cadastro de Documentos da Empresa',
              html: error.response.data.message,
              type: 'error',
            });

            this.$store.commit('setLoading', {
              show: false,
            });
          }
        }
      } else {
        Swal.fire({
          title: 'Cadastro de Documentos da Empresa',
          html: 'Você precisa adicionar algum documento!',
          type: 'error',
        });
      }
    },
    validateForm() {
      if (this.formData.description === null || this.formData.description === '') {
        this.validationErrors.push({
          field: 'description',
          message: 'O Campo de Descrição é obrigatório!',
        });
      }

      if (this.formData.type === null || this.formData.type === '') {
        this.validationErrors.push({
          field: 'type',
          message: 'O Tipo de Documento é obrigatório!',
        });
      }
    },
    async uploadDocument(documentId) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Enviando Documento para a Nuvem.',
      });

      const formDocuments = new FormData();

      let index = 0;

      for (const doc of this.documents) {
        formDocuments.append(`files['${index}']`, doc);
        index++;
      }

      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      try {
        await Axios.post(`${config.env.API_DEFAULT.host}/customerDocuments/uploadFile/${documentId}`, formDocuments, requestConfig);

        this.$store.commit('setLoading', {
          show: false,
        });

        this.formData = {description: null, type: null, validity: null};
        this.documents = [];
        this.addDialog = false;

        Swal.fire({
          title: 'Cadastrar Documento da Empresa',
          html: 'Documento cadastrado com sucesso!',
          type: 'success',
        });

        this.getSavedDocuments();
      } catch (error) {
        this.$store.commit('setLoading', {
          show: false,
        });

        Swal.fire('Cadastrar Documento da Empresa', 'Erro ao cadastrar o Documento', 'error');
      }
    },
    async getSavedDocuments() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Buscando documentos da Empresa',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/customerDocuments/${this.customerDetails.id}`);

        if (response != null) {
          this.savedDocuments = response.data.data;
        }

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (error) {
        Swal.fire('Listagem de documentos da Empresa', 'Houve um erro ao listar os documentos da empresa', 'error');

        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    download(doc) {
      let filename = '';
      if (doc.originalFilename !== null && !doc.originalFilename) {
        filename = doc.originalFilename;
      } else {
        filename = doc.description;
      }
      this.downloadManager(doc.id, filename, doc.fileType);
    },
    downloadManager(id, name, type) {
      if (type === 'application/pdf' || type === 'application/octet-stream') {
        Axios.post(`${config.env.API_DEFAULT.host}/documents/download/${id}`)
          .then((response) => {
            const binaryString = window.atob(response.data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            const blob = new Blob([bytes], {type});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            link.click();
          })
          .catch((error) => {
            Swal.fire('Download de Documento', error.response.data.message, 'error');
          });
      } else {
        Axios.post(
          `${config.env.API_DEFAULT.host}/documents/download/${id}`,
          null,
          {responseType: 'blob'},
        ).then((response) => {
          downloadjs(response.data, name);
        }).catch((error) => {
          Swal.fire('Download de Documento', error.response.data.message, 'error');
        });
      }
    },
    async deleteFile(documentId) {
      const conf = await Swal.fire({
        title: 'Excluir Documento',
        html: '<p>Tem certeza que deseja excluir o documento?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Excluir',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      });

      if (conf) {
        this.$store.commit('setLoading', {
          show: true,
          label: 'Carregando...',
          description: 'Buscando documentos da Empresa',
        });

        try {
          const response = await Axios.delete(`${config.env.API_DEFAULT.host}/customerDocuments/deleteFile/${documentId}`);

          this.$store.commit('setLoading', {
            show: false,
          });

          Swal.fire({
            title: 'Excluir Documento da Empresa',
            html: 'Documento excluido com sucesso!',
            type: 'success',
          });

          this.getSavedDocuments();
        } catch (error) {
          this.$store.commit('setLoading', {
            show: false,
          });

          Swal.fire('Download de Documento', error.response.data.message, 'error');
        }
      }
    },
  },
  components: {
    VInput,
    PlusIcon,
    SelectWrapper,
    VInputFileDrag,
  },
  mounted() {
    this.getTypes();
    this.getSavedDocuments();
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80B7E2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.document-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all .2s ease-in-out 0s;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392A51A;
}

.action-option {
  font-size: 22px;
}

.activity-enter-active, .activity-leave-active {
  transition: all .5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter, .activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to, .activity-leave {
  transition: all .5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}
</style>
