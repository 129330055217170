import Axios from 'axios';
import config from '@/config.js';

export default {
  getCustomer(id) {
    return Axios.get(`${config.env.API_DEFAULT.host}/processes/${id}/customer`);
  },
  verifyIfIdentificationExists(id, identification, typeOfProcess) {
    return Axios.get(`${config.env.API_DEFAULT.host}/process/import/verifyIfIdentificationExists?id=${id}&identification=${identification}&typeOfProcess=${typeOfProcess}`);
  },

  verifyIfExportProcessExists(identification) {
    return Axios.get(`${config.env.API_DEFAULT.host}/exportProcesses/search/findByIdentification?identification=${identification}`);
  },

  getTypeOfProcessReference(process) {
    if (process === null || process.identification === null) return 'N/I';

    let ref = '';
    let typeOfProcess = '';
    const zerosToLeft = 5;

    if (process.typeOfProcess !== null) {
      typeOfProcess = process.typeOfProcess;
    }

    if (typeOfProcess === 'ASSESSORIA') {
      ref = `A${this.zeroPad(process.identification, zerosToLeft)}`;
    } else if (typeOfProcess === 'ENCOMENDA') {
      ref = `E${this.zeroPad(process.identification, zerosToLeft)}`;
    } else {
      ref = `PRI${this.zeroPad(process.identification, zerosToLeft)}`;
    }

    return ref;
  },

  zeroPad(num, places) {
    const zero = places - num.toString().length + 1;

    return Array(+(zero > 0 && zero)).join('0') + num;
  },
};
