<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <div></div>
        <h6 class="slim-pagetitle">Meus Dados</h6>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <form @submit.prevent="updateUserData" class="row">
              <div class="col-md-4 py-3">
                <v-input
                    name="name"
                    label="Nome"
                    v-model="userData.name"
                ></v-input>
                <div class="field-error" v-if="formErrors.find((error) => error.path === 'name')">
                  errou
                </div>
              </div>
              <div class="col-md-4 py-3">
                <v-input
                    name="email"
                    label="E-mail"
                    type="email"
                    v-model="userData.email1"
                ></v-input>
                <div class="field-error" v-if="formErrors.find((error) => error.path === 'email')">
                  {{ formErrors.find((error) => error.path === 'email').message }}
                </div>
              </div>
              <div class="col-md-4 py-3">
                <v-checkbox
                    name="changePassword"
                    label="Alterar senha"
                    v-model="changePassword"
                ></v-checkbox>
              </div>
              <div class="col-md-4 py-3" v-if="changePassword">
                <v-input
                    name="password"
                    type="password"
                    label="Senha"
                    v-model="userData.password"
                ></v-input>
                <div class="field-error" v-if="formErrors.find((error) => error.path === 'password')">
                  {{ formErrors.find((error) => error.path === 'password').message }}
                </div>
              </div>
              <div class="col-md-4 py-3" v-if="changePassword">
                <v-input
                    name="confirm_password"
                    type="password"
                    label="Confirmar Senha"
                    v-model="userData.confirm_password"
                ></v-input>
                <div class="field-error" v-if="formErrors.find((error) => error.path === 'confirm_password')">
                  {{ formErrors.find((error) => error.path === 'confirm_password').message }}
                </div>
              </div>

              <div class="col-12 py-3">
                <input type="submit" value="Atualizar Meus Dados" class="crm-button">
              </div>
            </form>

            <div class="row">
              <div class="col-12 py-3">
                <h5 class="mb-4">Empresas Vinculadas</h5>

                <table class="crm-table">
                  <tbody>
                    <tr v-for="company in userData.companies" :key="company.id">
                      <td>
                        <div class="crm-table-item flex ai-c fgap2">
                          <div class="crm-table-letter flex ai-c jc-c">
                            {{ company.name.split("")[0].toUpperCase() }}
                          </div>
                          <div class="crm-table-info">
                            <h5 class="mb-0">
                              {{ company.completeName }}</h5>
                            <span class="mb-0" v-if="company.email1">{{ contact.email1 }}</span>
                            <span class="tx-12 d-block" v-if="company.enabled"><span
                                class="square-8 bg-success mg-r-5 rounded-circle"></span> Empresa Habilitada</span>
                            <span class="tx-12 d-block" v-else><span
                                class="square-8 bg-danger mg-r-5 rounded-circle"></span> Empresa Desabilitada</span>
                            <span class="tx-12" v-if="!company.archived"><span
                                class="square-8 bg-success mg-r-5 rounded-circle"></span> Ativo </span>
                            <span class="tx-12" v-else><span class="square-8 bg-danger mg-r-5 rounded-circle"></span> Arquivado </span>
                          </div>
                          <div class="company-type flex ai-c fgap2"></div>
                        </div>
                      </td>
                      <td>
                        <span class="mb-0 tx-14 tx-bold">{{ company.email1 }}</span>
                        <p class="mb-0">
                            <span v-if="company.telephone1">
                              {{ company.telephone1 }}
                            </span>
                          <span v-if="company.telephone2">
                              {{ company.telephone2 }}
                            </span>
                        </p>
                      </td>
                      <td>
                        <div class="crm-table-actions">
                          <a href="#" @click.prevent="toggleActions(company.id)">
                            <dots-icon :size="24" />
                          </a>

                          <transition name="fade" mode="out-in">
                            <div class="submenu" v-if="actionsSubmenu === company.id">
                              <ul>
                                <li>
                                  <router-link :to="{ name: 'CrmCustomer', params: { id: company.id } }">
                                    Ver Empresa
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                          </transition>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';

import { mapState, mapActions } from 'vuex';
import Axios from 'axios';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import VInput from '@/components/VInput';
import VCheckbox from '@/components/VCheckbox';
import { DotsIcon } from '@/components/crm/Icons';

export default {
  name: 'MyAccount',
  components: {
    VInput,
    VCheckbox,
    DotsIcon,
  },
  data() {
    return {
      userData: {},
      changePassword: false,
      actionsSubmenu: null,
      formErrors: [],
    };
  },

  computed: {
    ...mapState(['credentials']),
  },
  async mounted() {
    await this.getUserData();
  },
  methods: {
    async getUserData() {
      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/users/get-profile/${this.credentials.data.person.id}`);
        this.userData = {
          ...response.data,
          password: '',
          confirm_password: '',
        };
      } catch (e) {
        Swal.fire(
          {
            title: 'Meus Dados',
            text: 'Não foi possível recuperar seus dados',
            type: 'error',
          },
        );
      }
    },
    async updateUserData() {
      let schema;
      if (this.changePassword) {
        schema = yup.object().shape({
          name: yup.string().required('O nome é obrigatório!').min(3, 'O nome deve conter no mínimo 3 caracteres!').max(191, 'O nome deve conter no máximo 191 caracteres!'),
          email1: yup.string().required('O e-mail é obrigatório!').email('O e-mail é inválido!'),
          password: yup.string()
            .nullable()
            .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
          // .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[_()@!?$%*#&])[A-Za-z\d_()@!?$%*#&]{8,}$/, 'A senha deve conter letras maiúsculas, minúsculas e pelo menos 1 caracter especial.'),
          confirm_password: yup.string()
            .oneOf([yup.ref('password'), null], 'As senhas não são iguais.'),
        });
      } else {
        schema = yup.object().shape({
          name: yup.string().required('O nome é obrigatório!').min(3, 'O nome deve conter no mínimo 3 caracteres!').max(191, 'O nome deve conter no máximo 191 caracteres!'),
          email1: yup.string().required('O e-mail é obrigatório!').email('O e-mail é inválido!'),
        });
      }

      try {
        await schema.validate(this.userData, {
          abortEarly: false,
        });

        const formData = {
          name: this.userData.name,
          email: this.userData.email1,
          enabled: true,
        };

        if (this.changePassword) {
          formData.password = this.userData.password;
          formData.confirmPassword = this.userData.confirm_password;
        }

        await Axios.put(`${config.env.API_DEFAULT.host}/credential/user/${this.userData.id}`, formData);

        Swal.fire(
          {
            title: 'Meus Dados',
            text: 'Dados atualizados com sucesso!',
            type: 'success',
          },
        );
      } catch (e) {
        if (e.inner) {
          this.formErrors = e.inner;
        } else {
          Swal.fire({ title: 'Meus Dados', text: 'Não foi possível atualizar os dados!', type: 'error' });
        }
      }
    },
    toggleActions(contactID) {
      if (this.actionsSubmenu === contactID) {
        this.actionsSubmenu = null;
      } else {
        this.actionsSubmenu = contactID;
      }
    },
  },
};
</script>

<style scoped>

</style>
