<template>
  <form id="di-form-pagamento" v-bind:class="{ 'no-click': noClick }">
    <div class="form-layout form-layout-2 pd-b-40">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            Valores de Pagamento da Declaração de Importação
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Adições da
            declaração de importação com licença de importação e sem licença de
            importação.
          </p>
        </div>
        <div>
          <template v-if="model.numeroRegistro === null">
            <a
              href="#"
              class="btn btn-primary"
              @click.prevent="calcularValorTotalImpostos()"
            >
              <i class="icon fa fa-calculator tx-22"></i> CALCULAR IMPOSTOS
            </a>
            <a
              href="#"
              class="btn btn-success"
              @click.prevent="gerarLancamentoImpostos()"
            >
              <i class="icon fa fa-signing tx-22"></i> GERAR PAGAMENTOS
            </a>
            <a href="#" class="btn btn-danger">
              <i class="icon fa fa-money tx-22"></i> ENVIAR P/ CONTAS A PAGAR
            </a>
          </template>
        </div>
      </div>

      <hr/>

      <label class="section-title mg-t-0"> Valores a Recolher em Reais </label>
      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> I.I.</label>
            <money
              class="form-control"
              name="valorTotalII"
              v-model="model.valorTotalII"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> IPI</label>
            <money
              class="form-control"
              name="valorTotalIPI"
              v-model="model.valorTotalIPI"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> PIS/PASEP</label>
            <money
              class="form-control"
              name="valorTotalPIS"
              v-model="model.valorTotalPIS"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> COFINS</label>
            <money
              class="form-control"
              name="valorTotalCofins"
              v-model="model.valorTotalCofins"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Antidumping</label>
            <money
              class="form-control"
              name="valorTotalAntidumping"
              v-model="model.valorTotalAntidumping"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label class="form-control-label"> Taxa de Utilização</label>
            <money
              class="form-control"
              name="valorTotalTaxaUtilizacao"
              v-model="model.valorTotalTaxaUtilizacao"
              v-bind="money"
            ></money>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-r-0-force bd-t-0-force">
            <label class="form-control-label"> Banco </label>
            <input
              class="form-control"
              type="text"
              v-model="model.pagamentoBanco"
              name="pagamentoBanco"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-r-0-force bd-t-0-force">
            <label class="form-control-label"> Agência </label>
            <input
              class="form-control"
              type="text"
              v-model="model.pagamentoAgencia"
              name="pagamentoAgencia"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label"> Conta Corrente </label>
            <input
              class="form-control"
              type="text"
              v-model="model.pagamentoContaCorrente"
              name="pagamentoContaCorrente"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <label class="section-title mg-t-20">
          Lançamento de Pagamento - Débito em Conta / Valores a Pagar em Reais
        </label>
        <table class="table mg-b-0 bd">
          <thead class="thead-colored bg-info">
          <tr>
            <th class="tx-center">Banco</th>
            <th class="tx-center">Agência</th>
            <th class="tx-center">Conta Corrente</th>
            <th class="tx-center wd-400">Receita</th>
            <th class="tx-center">Valor (R$)</th>
            <th class="tx-center wd-100">Ações</th>
          </tr>
          </thead>

          <tbody>
          <template
            v-if="
                model.pagamento !== null &&
                model.pagamento !== undefined &&
                model.pagamento.length > 0
              "
          >
            <tr-inline
              v-for="(item, index) in model.pagamento"
              :key="'key-pagamento-' + index"
              :row="item"
              @save="updatePagamento"
            >
              <td-inline
                placeholder="Banco"
                v-model="item.codigoBancoPagamentoTributo"
                type="string"
              />
              <td-inline
                placeholder="Agência"
                v-model="item.numeroAgenciaPagamentoTributo"
                type="string"
              />
              <td-inline
                placeholder="Conta Corrente"
                v-model="item.numeroContaCorrente"
                type="string"
              />
              <td-inline
                placeholder="Receita"
                v-model="item.codigoReceitaPagamento"
                type="select"
                selectType="key|value"
                :options="codigosReceitas"
              />
              <td-inline
                placeholder="Valor"
                type="money"
                v-model="item.valorTributoPago"
              />

              <template slot="actions">
                <a
                  v-if="model.numeroRegistro === null"
                  href="#"
                  @click.prevent="removePagamento(item)"
                  class="btn btn-danger btn-icon rounded-circle"
                >
                  <div><i class="icon ion-trash-a"></i></div>
                </a>
              </template>
            </tr-inline>
          </template>

          <template
            v-if="
                this.model.numeroRegistro == null ||
                this.model.numeroRegistro == undefined
              "
          >
            <tr-inline
              class="new-item"
              :isNew="true"
              :row="newReceita"
              @save="createPagamento"
              v-if="model.numeroRegistro === null"
            >
              <td-inline
                placeholder="Banco"
                v-model="newReceita.codigoBancoPagamentoTributo"
                type="string"
              />
              <td-inline
                placeholder="Agência"
                v-model="newReceita.numeroAgenciaPagamentoTributo"
                type="string"
              />
              <td-inline
                placeholder="Conta Corrente"
                v-model="newReceita.numeroContaCorrente"
                type="string"
              />
              <td-inline
                placeholder="Selecione a receita"
                v-model="newReceita.codigoReceitaPagamento"
                type="select"
                selectType="key|value"
                :options="codigosReceitas"
              />
              <td-inline
                placeholder="Valor"
                type="money"
                v-model="newReceita.valorTributoPago"
              />
            </tr-inline>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</template>

<script>
import {Money} from "v-money";
import {mapState} from "vuex";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import * as importDeclarationLists from "@/lists/import-declaration-lists.js";

import Swal from "sweetalert2";

export default {
  name: "di-form-pagamento",
  props: ["noClick"],
  components: {
    Money,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      model: {},
      initialModel: {},
      newReceita: {
        codigoBancoPagamentoTributo: "",
        numeroAgenciaPagamentoTributo: "",
        numeroContaCorrente: "",
        codigoReceitaPagamento: "",
        valorTributoPago: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      codigosReceitas: [...importDeclarationLists.codigosReceitas],
    };
  },
  methods: {
    onSubmit() {
      return true;
    },
    async updatePagamento(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.submitPagamento();
      }
    },
    async createPagamento(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.model.pagamento.push(value);
        this.submitPagamento();
      }
    },
    async removePagamento(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        Swal.fire({
          title: "Excluir Lançamento de Pagamento",
          html: `Você realmente deseja excluir o Lançamento de Pagamento <strong>${value.codigoReceitaPagamento}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            const index = this.model.pagamento.findIndex(
              (item) =>
                item.codigoReceitaPagamento === value.codigoReceitaPagamento &&
                item.valorTributoPago === value.valorTributoPago
            );

            this.model.pagamento.splice(index, 1);

            this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
            this.submitPagamento();

            Swal.fire(
              "Excluir Lançamento de Pagamento",
              ` O Lançamento <strong>${value.codigoReceitaPagamento}</strong> foi excluído com sucesso da lista de Lançamentos de Pagamento.`,
              "success"
            );
          }
        });
      }
    },
    async submitPagamento() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: {
            pagamento: this.model.pagamento,
          },
          newModel: this.model,
          fieldName: "pagamento",
        });

        if (response) {
          this.newReceita = {
            codigoBancoPagamentoTributo: "",
            numeroAgenciaPagamentoTributo: "",
            numeroContaCorrente: "",
            codigoReceitaPagamento: "",
            valorTributoPago: 0,
          };
        }
      }
    },
    calcularTaxaUtilizacaoSiscomex() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const valorTaxaSiscomex = 185.0;
        let taxaAdicoes = 0;

        if (
          this.model.adicoes !== null &&
          this.model.adicoes !== undefined &&
          this.model.adicoes.length > 0
        ) {
          const qtdAdicoes = this.model.adicoes.length;

          for (let index = 1; index <= qtdAdicoes; index++) {
            if (index <= 2) {
              taxaAdicoes += 29.5;
            } else if (index >= 3 && index <= 5) {
              taxaAdicoes += 23.6;
            } else if (index >= 6 && index <= 10) {
              taxaAdicoes += 17.7;
            } else if (index >= 11 && index <= 20) {
              taxaAdicoes += 11.8;
            } else if (index >= 21 && index <= 50) {
              taxaAdicoes += 5.9;
            } else if (index >= 51) {
              taxaAdicoes += 2.95;
            }
          }

          return valorTaxaSiscomex + taxaAdicoes;
        }

        return valorTaxaSiscomex;
      }
    },
    gerarLancamentoImpostos() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        try {
          if (
            this.model.pagamento !== null &&
            this.model.pagamento !== undefined &&
            this.model.pagamento.length > 0
          ) {
            this.model.pagamento = [];
          }

          // 0086	II - IMPOSTO IMPORTAÇÃO - OUTROS
          if (this.model.valorTotalII > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "0086",
              valorTributoPago: this.model.valorTotalII.toFixed(2),
            });
          }

          // 1038	IPI - VINCULADO IMPORTAÇÃO
          if (this.model.valorTotalIPI > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "1038",
              valorTributoPago: this.model.valorTotalIPI.toFixed(2),
            });
          }

          // 5602	PIS - IMPORTAÇÃO
          if (this.model.valorTotalPIS > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "5602",
              valorTributoPago: this.model.valorTotalPIS.toFixed(2),
            });
          }

          // 5629	COFINS - IMPORTAÇÃO
          if (this.model.valorTotalCofins > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "5629",
              valorTributoPago: this.model.valorTotalCofins.toFixed(2),
            });
          }

          // 7811	TAXA UTILIZAÇÃO SISCOMEX (ART. 3 MP 1725-98)
          if (this.model.valorTotalTaxaUtilizacao > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "7811",
              valorTributoPago: this.model.valorTotalTaxaUtilizacao.toFixed(2),
            });
          }

          // 5529	RECEITA DIREITOS ANTIDUMPING
          if (this.model.valorTotalAntidumping > 0) {
            this.model.pagamento.push({
              codigoBancoPagamentoTributo: this.model.pagamentoBanco,
              numeroAgenciaPagamentoTributo: this.model.pagamentoAgencia,
              numeroContaCorrente: this.model.pagamentoContaCorrente,
              codigoReceitaPagamento: "5529",
              valorTributoPago: this.model.valorTotalAntidumping.toFixed(2),
            });
          }

          this.submitPagamento();
        } catch (error) {
          Swal.fire(
            "Lançamento de Pagamentos",
            `Não foi possível gerar os pagmaentos por causa do erro abaixo:<br />${error}`,
            "success"
          );
        }
      }
    },
    async diffObjects(fieldName) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
          initialModel: this.initialModel,
          model: this.model,
          fieldName,
        });

        if (response) {
          this.initialModel = {
            ...this.model,
          };
        }
      }
    },
    calcularValorTotalImpostos() {
      if (
        (this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined) &&
        this.model.adicoes !== null &&
        this.model.adicoes !== undefined &&
        this.model.adicoes.length > 0
      ) {
        this.model.valorTotalII = this.model.adicoes.reduce(
          (value, adicao) => (value += adicao.iiValorIPTaRecolher),
          0
        );

        this.model.valorTotalIPI = this.model.adicoes.reduce(
          (value, adicao) => (value += adicao.ipiValorIPTaRecolher),
          0
        );

        this.model.valorTotalPIS = this.model.adicoes.reduce(
          (value, adicao) => (value += adicao.pisValorIPTaRecolher),
          0
        );

        this.model.valorTotalCofins = this.model.adicoes.reduce(
          (value, adicao) => (value += adicao.cofinsValorIPTaRecolher),
          0
        );

        this.model.valorTotalAntidumping = this.model.adicoes.reduce(
          (value, adicao) => (value += adicao.antidumpingValorIPTaRecolher),
          0
        );

        this.model.valorTotalTaxaUtilizacao =
          this.calcularTaxaUtilizacaoSiscomex();

        this.initialModel.valorTotalII = 0;
        this.initialModel.valorTotalIPI = 0;
        this.initialModel.valorTotalPIS = 0;
        this.initialModel.valorTotalCofins = 0;
        this.initialModel.valorTotalAntidumping = 0;
        this.initialModel.valorTotalTaxaUtilizacao = 0;

        this.diffObjects("valorTotalTaxaUtilizacao");
      }
    },
  },

  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  watch: {
    activeDI() {
      this.model = {
        ...this.activeDI,
      };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-pagamento");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.activeDI,
    };

    this.initialModel = {
      ...this.activeDI,
    };
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
