<template>
  <div v-if="grouped">
    <div
      class="calendar-day calendar-round"
      :class="{
        past: past,
        future: future,
        active: active,
      }"
    >
      <template v-for="(item, index) in data">
        <div
          @click="$emit('innerAction', item)"
          :key="index"
          class="calendar-day calendar-round"
          v-if="data.length == 4 ? index < 4 : index < 3"
        >
          <div class="calendar-day-tooltip">
            <p v-if="item.identification">Ref. Prime:</p>
            <p v-if="item.identification" class="mb-2">
              <span class="text-bold">
                {{ item.typeOfProcess }}
                {{ item.identification }}
              </span>
            </p>
            <p v-if="item.customerReference">Ref. Cliente:</p>
            <p v-if="item.customerReference" :class="{ 'mb-2': item.category === 'PARCELA_INVOICE' }">
              <span class="text-bold">{{ item.customerReference }}</span>
            </p>
            <p v-if="item.category === 'PARCELA_INVOICE'">
              Total na Moeda:
            </p>
            <p v-if="item.category === 'PARCELA_INVOICE'" class="mb-2" >
              <span class="text-bold">
                {{ item.invoiceCurrency }}
                {{ item.invoiceInstallmentValue  | fractionFilter }}
              </span>
            </p>
            <p v-if="item.category === 'PARCELA_INVOICE'">
              Total em R$:
            </p>
            <p class="mb-2" v-if="item.category === 'PARCELA_INVOICE'">
              <span class="text-bold">
                {{ item.invoiceInstallmentValueInBrl | moneyFilter }}
              </span>
            </p>
            <p v-if="item.category === 'PARCELA_INVOICE' && item.lastExchangeRate">
              <span class="text-italic text-xs">
                Câmbio atualizado em {{item.lastExchangeUpdate | dateFormat }} com a taxa
                {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(item.lastExchangeRate) }}
              </span>
            </p>
          </div>
          <div
            class="calendar-day-content"
           :class="{
            'numerario': item.category === 'NUMERARIO',
            'numerario-previsto': item.category === 'NUMERARIO_PREVISTO',
            'invoice': item.category === 'PARCELA_INVOICE',
            }"
          >
            <div class="flex1">
              <p class="customer-ref">
                <span v-if="useField == 'identification'">
                  <span class="text-xs">{{ getCalendarType(item) }}</span>
                  <br />
                  {{ item[useField] ?? 'S/I' }}
                </span>
                <span v-else>
                   <span class="text-xs">{{ getCalendarType(item) }}</span>
                  <br />
                  {{ item[useField] ?? 'S/I' }}
                </span>
              </p>
              <p>{{ item.value | moneyFilter }}</p>
            </div>
            <div
              v-if="item.cashRequestDone && item.category === 'NUMERARIO'" class="ribbon"
            >
              Pago
            </div>
          </div>
        </div>
      </template>
      <div class="calendar-day-footer" v-if="data.length > 4">
        <button @click.prevent="$emit('action', data)">
          <span>VER MAIS ITENS</span>
          <br />
          <span>{{ data.length - 3 }} itens ocultos</span>
        </button>
      </div>
    </div>
  </div>
  <div class="calendar-list-day" v-else>
    <template v-for="(item, index) in data">
      <div
        @click="$emit('innerAction', item)"
        :key="index"
        class="calendar-day calendar-round"
      >
        <div class="calendar-day-tooltip">
          <p v-if="item.identification" class="mb-2">
            Ref. Prime:
            <span class="text-bold">{{ item.identification }}</span>
          </p>
          <p v-if="item.customerReference" class="mb-2">
            Ref. Cliente: <span class="text-bold">{{ item.customerReference }}</span>
          </p>
          <p v-if="item.category === 'PARCELA_INVOICE'">
            Total na Moeda:
          </p>
          <p class="mb-2" v-if="item.category === 'PARCELA_INVOICE'">
            <span class="text-bold">
              {{ item.invoiceCurrency }}
              {{ item.invoiceInstallmentValue | fractionFilter }}
            </span>
          </p>
          <p v-if="item.category === 'PARCELA_INVOICE'">
            Total em R$:
          </p>
          <p class="mb-2" v-if="item.category === 'PARCELA_INVOICE'">
            <span class="text-bold">
              {{ item.invoiceInstallmentValueInBrl | moneyFilter }}
            </span>
          </p>
          <p v-if="item.category === 'PARCELA_INVOICE' && item.lastExchangeRate">
            <span class="text-italic text-xs">
              Câmbio atualizado em {{item.lastExchangeUpdate | dateFormat }} com a taxa
              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(item.lastExchangeRate) }}
            </span>
          </p>
        </div>
        <div
          class="calendar-day-content"
          :class="{
            'numerario': item.category === 'NUMERARIO',
            'numerario-previsto': item.category === 'NUMERARIO_PREVISTO',
            'invoice': item.category === 'PARCELA_INVOICE',
          }"
        >
          <div class="flex1">
            <p class="customer-ref">
              <span v-if="useField == 'identification'">
                <span class="text-xs">{{ getCalendarType(item) }}</span>
                <br />
                {{ item[useField] ?? 'S/I' }}
              </span>
              <span v-else>
                  <span class="text-xs">{{ getCalendarType(item) }}</span>
                <br />
                {{ item[useField] ?? 'S/I' }}
              </span>
            </p>
            <p>{{ item.value | moneyFilter }}</p>
          </div>
          <div v-if="item.cashRequestDone && item.category === 'NUMERARIO'" class="ribbon">
            Pago
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "NewGsCalendarDayContent",
  props: {
    date: {
      type: Date,
    },
    data: {
      type: Array,
      default: () => [],
    },
    past: {
      type: Boolean,
      default: false,
    },
    future: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: true,
    },
    useField: {
      type: String,
      default: 'invoice',
    },
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    fractionFilter(value) {
      return new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    typeOfProcess(value) {
      switch (value) {
        case "CONTA_E_ORDEM":
          return "PRI";
        case "ENCOMENDA":
          return "E";
        case "ACESSORIA":
          return "A";
        default:
          return "PRI";
      }
    },
  },
  methods: {
    getCalendarType(item) {
      const types = {
        NUMERARIO: 'Numerário',
        NUMERARIO_PREVISTO: 'Numerário Previsto',
        PARCELA_INVOICE: 'Invoice',
      };
      return types[item.category];
    },
    getInvoiceCurrency(item, invoiceId) {
      return item.invoices.find((inv) => inv.id === invoiceId).currency;
    },
    itemTotal(item, date) {
      let total = 0;
      let totalOtherCoin = 0;
      let lastExchangeRate = null;
      let lastExchangeUpdate = null;
      if (item.category === "estimatedStepCashRequest") {
        total += item.estimatedStepCashRequestEstimatedValue;
      }
      if (item.category === "datesETA") {
        const stepCashRequestDate = item.datesETA.split("T")[0];
        const itemDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        if (stepCashRequestDate === itemDate) {
          if (!item.stepCashRequestEstimatedValue) {
            total += item.estimatedStepCashRequestEstimatedValue;
          } else {
            total += item.stepCashRequestEstimatedValue;
          }
        }
      }
      if (item.category === "invoice") {
        for (let i = 0; i < item.invoices.length; i += 1) {
          const invoice = item.invoices[i];
          if (item.itemInvoice === invoice.id) {
            if (invoice.installments && invoice.installments.length > 0) {
              for (let j = 0; j < invoice.installments.length; j += 1) {
                const parcel = invoice.installments[j];
                const itemDate = `${date.getFullYear()}-${String(
                  date.getMonth() + 1
                ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
                if (parcel.dueDate.split("T")[0] === itemDate) {
                  total += parcel.valueInBrl;
                  totalOtherCoin += parcel.value;
                  lastExchangeRate = parcel.lastExchangeRate;
                  lastExchangeUpdate = parcel.lastExchangeUpdate;
                }
              }
            }
          }
        }
      }
      return {
        total,
        totalOtherCoin,
        lastExchangeRate,
        lastExchangeUpdate,
      };
    },
  },
};
</script>
<style scoped>
.calendar-day {
  cursor: pointer;
  /* overflow: hidden; */
  margin-bottom: 0.25rem;
  position: relative;
}
.calendar-day p {
  margin-bottom: 0;
}
.calendar-day-tooltip {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: calc(100% + 5px);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  z-index: 999999;
  pointer-events: none;
  transition: all .1s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 15px, 0);
}
.calendar-day:hover > .calendar-day-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.calendar-day-content {
  padding: 3px 0.5rem;
  font-size: 0.8rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}
.calendar-day-content p:last-of-type {
  font-weight: bold;
}
.calendar-day-content,
.calendar-day-content p {
  transition: all 0.2s ease-in-out 0s;
}
/* Numerário Previsto */
.calendar-day-content.numerario-previsto {
  background-color: white;
  border-color: #147702;
}
.calendar-day-content.numerario-previsto p {
  color: #147702;
}
.calendar-day-content.numerario-previsto:hover {
  background-color: #147702;
}
.calendar-day-content.numerario-previsto:hover p {
  color: white;
}
/* Numerário Previsto */
/* Numerário */
.calendar-day-content.numerario {
  background-color: #147702;
  border-color: #147702;
}
.calendar-day-content.numerario p {
  color: white;
}
.calendar-day-content.numerario:hover {
  background-color: white;
}
.calendar-day-content.numerario:hover p {
  color: #147702;
}
/* Numerário */
/* Invoice */
.calendar-day-content.invoice {
  background-color: #09233c;
  border-color: #09233c;
}
.calendar-day-content.invoice p {
  color: white;
}
.calendar-day-content.invoice:hover {
  background-color: white;
}
.calendar-day-content.invoice:hover p {
  color: #09233c;
}
/* Invoice */
.calendar-list-day .calendar-day {
  cursor: pointer;
}
.calendar-day-header {
  padding: 0.5rem;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: var(--primary-bg-header);
  text-transform: uppercase;
}
.calendar-list-day .calendar-day-header {
  margin-bottom: 0;
}
.calendar-day-header.past {
  background-color: #2b6a17;
}
.calendar-day-header.future {
  background-color: var(--primary-bg-header);
}
.calendar-day-header.active {
  background-color: #e2665e;
}
.calendar-day-footer {
  /* padding: 0.75rem; */
  font-size: 0.9rem;
}
.calendar-day-footer {
  padding-top: 0;
}
.calendar-day-footer button {
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 10px;
  font-weight: 900;
  border: 0;
  background-color: transparent;
  color: var(--primary-bg-header);
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  margin-top: 0.5rem;
}
.calendar-day-footer button span:last-of-type {
  font-weight: 300;
}
.calendar-list-day {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.calendar-day-content .ribbon {
  position: absolute;
  top: 1px;
  left: -17px;
  transform: rotate(-45deg);
  background-color: red;
  color: white;
  padding: 0.05rem 1rem;
  font-size: 0.6rem;
}
.customer-ref {
  word-break: break-all;
}
.calendar-list-day .calendar-day:first-child .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(2) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(3) .calendar-day-tooltip {
  top: calc(100% + 5px);
  bottom: unset;
}
</style>