<template>
  <div :class="['card-grid-item', bg, equalHeight ? 'equal-height' : '', Array.isArray(bigInfo) && bigInfo.length > 0 ? 'cursor-pointer' : '']"
    @click="(Array.isArray(bigInfo) && bigInfo.length && !customEventOnClick) ? abrirProcessosNoTelao() : emitClick()"
  >
    <div :class="['top-bar', topBarColor]" v-if="topBarColor"></div>
    <div class="icon" v-if="icon">
      <span class="material-icons-outlined">
        {{ icon }}
      </span>
    </div>

    <number
      v-if="bigInfo >= 0 || Array.isArray(bigInfo)"
      class="big-info"
      ref="number1"
      :from="0"
      :to="Array.isArray(bigInfo) ? bigInfo.length : bigInfo"
      :duration="3"
      :delay="1"
      easing="Power1.easeOut" />

    <p
      :class="[
        'big-info-sub',
        step ? 'no-margin' : ''
      ]"
      v-if="bigInfoSub"
    >
      {{ bigInfoSub }}
    </p>

    <p class="title mt-1" v-if="title">
      <span
        v-if="Array.isArray(bigInfo) && bigInfo.length > 0"
        @click="abrirProcessosNoTelao"
        target="_blank"
        class="text-dark cursor-pointer">
        {{ title }}
      </span>
      <span v-else>{{ title }}</span>
    </p>
    <p class="content" v-if="content">{{ content }}</p>

    <div v-if="bigIcon" class="big-icon">
      <Booking v-if="bigIcon === 'booking'" />
      <Carregamento v-if="bigIcon === 'carregamento'" />
      <Desembaraco v-if="bigIcon === 'desembaraco'" />
      <Encerramento v-if="bigIcon === 'encerramento'" />
      <Licenciamento v-if="bigIcon === 'licenciamento'" />
      <Producao v-if="bigIcon === 'producao'" />
      <Transito v-if="bigIcon === 'transito'" />
      <Pendencias v-if="bigIcon === 'pendencias'" />
    </div>
  </div>
</template>

<script>
import Booking from '@/components/icons/Booking.vue';
import Carregamento from '@/components/icons/Carregamento.vue';
import Desembaraco from '@/components/icons/Desembaraco.vue';
import Encerramento from '@/components/icons/Encerramento.vue';
import Licenciamento from '@/components/icons/Licenciamento.vue';
import Producao from '@/components/icons/Producao.vue';
import Transito from '@/components/icons/Transito.vue';
import Pendencias from '@/components/icons/Pendencias.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CardGridItem',
  components: {
    Booking,
    Carregamento,
    Desembaraco,
    Encerramento,
    Licenciamento,
    Producao,
    Transito,
    Pendencias,
  },
  props: [
    'bigInfo',
    'bigInfoSub',
    'icon',
    'bg',
    'topBarColor',
    'title',
    'content',
    'bigIcon',
    'step',
    'equalHeight',
    'callback',
    'customEventOnClick',
  ],
  computed: {
    ...mapState(
      {
        steps: (state) => state.ProcessStore.steps,
        processesFilterIds: (state) => state.ProcessStore.processesFilterIds,
      },
    ),
  },
  data() {
    return {
      stepsColumns: {
        /* producao */
        7: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'invoice', 'incoterm', 'typeOfBoarding', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate'],
        /* licenciamento */
        11: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'customBroker', 'invoice', 'incoterm', 'wayOfTransport', 'typeOfBoarding', 'billOfLading', 'freightForwarder', 'customsEnclosure', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate', 'datesETD', 'datesETA'],
        /* booking */
        1: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'customBroker', 'invoice', 'incoterm', 'wayOfTransport', 'typeOfBoarding', 'billOfLading', 'freightForwarder', 'customsEnclosure', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate', 'datesETD', 'datesETA'],
        /* transito */
        2: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'customBroker', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'vehicle', 'freightForwarder', 'customsEnclosure', 'stepCashRequest', 'originalsDocumentsDate', 'datesETA', 'freetime', 'datesDepartureDate', 'datesIsThereDTCDTATransfer'],
        /* desembaraco */
        3: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'customBroker', 'invoice', 'typeOfBoarding', 'billOfLading', 'customsEnclosure', 'conveyor', 'stepCashRequest', 'diNumber', 'diRegistryDate', 'diChannel', 'datesArrivalDate', 'freetime', 'datesGoodsPresenceDate'],
        /* carregamento */
        4: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'invoice', 'typeOfBoarding', 'customsEnclosure', 'conveyor', 'diResourcefulnessDate', 'billingNFsDate', 'billingNFsEmissionDate', 'datesArrivalDate', 'freetime', 'datesSiscargaState', 'datesComexDate'],
        /* encerramento */
        5: ['identification', 'step', 'customer', 'status', 'customBroker', 'typeOfBoarding', 'customsEnclosure', 'datesCustomerDeliverDate'],
        /* pendencias */
        10: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'invoice', 'incoterm', 'typeOfBoarding', 'conveyor'],
        /* concluido */
        6: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'diNumber', 'diResourcefulnessDate', 'diChannel', 'billingBillCustomerDate', 'datesDepartureDate', 'datesArrivalDate', 'datesGoodsLoadingDate', 'datesCustomerDeliverDate'],
        /* cancelamento */
        9: ['identification', 'step', 'status', 'customerRef', 'customer', 'exporter', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'datesDepartureDate', 'datesArrivalDate', 'billOfLading', 'diNumber', 'diResourcefulnessDate', 'diChannel', 'datesGoodsLoadingDate', 'datesCustomerDeliverDate', 'billingBillCustomerDate'],
      },
    };
  },
  methods: {
    ...mapActions(
      'ProcessStore',
      [
        'setProcessesFilterIds',
        'setStep',
      ],
    ),
    emitClick() {
      this.$emit('click', true);
    },
    abrirProcessosNoTelao() {
      const values = this.bigInfo;
      let processesId = '';
      let columns = '';

      if (!this.customEventOnClick) {
        if (Array.isArray(values) && values.length > 0) {
          if (typeof values === 'object') {
            this.setProcessesFilterIds({ processesIds: Object.values(values).join() });
          } else {
            this.setProcessesFilterIds({ processesIds: values.join() });
          }

          this.setStep({ step: this.step });

          if (typeof values === 'object') {
            processesId = Object.values(this.bigInfo).join();
          } else {
            processesId = this.bigInfo.join();
          }

          if (this.step === 8) {
            columns = this.stepsColumns[3].join();
          } else {
            columns = this.stepsColumns[this.step].join();
          }

          const router = this.$router.resolve(`/operational/imp/desk?stepsIds=${this.step}&processesIds=${processesId}&columns=${columns}&showProcess=true&indicatorProcess=true`);

          window.open(router.href, '_blank');
        }
      }
    },
  },
};
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
    transition: 0.3s;
  }

  .cursor-pointer:hover {
    background-color: rgb(243, 243, 243);
  }

  .card-grid-item {
    position: relative;
    border: 1px solid var(--border);
    padding: 30px 10px 10px 10px;
    background-color: white;
    text-align: center;
    color: var(--gray-dark);
  }

  .card-grid-item.light {
    background-color: #EDEDED;
  }

  .card-grid-item.red {
    background-color: #981E1E;
    border: 1px solid #981E1E;
  }

  .card-grid-item.red > * {
    color: white;
  }

  .card-grid-item .top-bar {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    height: 5px;
  }

  .big-info {
    font-size: 3rem;
    margin-bottom: 0;
    line-height: 3rem;
  }

  .big-info-sub {
    font-size: 0.8rem;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .big-info-sub.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 0;
    font-weight: bold;
  }

  .content {
    margin-bottom: 0;
    color: var(--gray);
  }

  .big-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 92px;
    height: 64px;
    width: 100%;
    margin: 20px auto;
  }

  .big-icon svg {
    width: 100%;
  }

  .card-grid-item .top-bar.light {
    background-color: #868BA1;
  }

  .card-grid-item .top-bar.green {
    background-color: #3B9B28;
  }

  .card-grid-item .top-bar.red {
    background-color: #A80005;
  }

  .card-grid-item .top-bar.dark {
    background-color: #343A40;
  }

  .card-grid-item .top-bar.blue {
    background-color: #1B84E7;
  }

  .card-grid-item .top-bar.yellow {
    background-color: #D6B200;
  }

  .card-grid-item .top-bar.pink {
    background-color: #F65694;
  }

  .card-grid-item .top-bar.darkred {
    background-color: #981E1E;
  }

  .card-grid-item .top-bar.purple {
    background-color: #A569BD;
  }

  .card-grid-item .top-bar.orange {
    background-color: #D07107;
  }

  .card-grid-item .top-bar.darkorange {
    background-color: #CD6155;
  }

  .card-grid-item .top-bar.black {
    background-color: #000;
  }

  .card-grid-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--gray-dark);
  }

  .card-grid-item .icon span {
    font-size: 28px;
  }

  .equal-height {
    min-height: 220px;
    display: grid;
    align-content: start;
    height: 100%;
  }

  .equal-height .big-info-sub {
    margin-bottom: 20px;
  }
</style>
