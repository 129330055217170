<template>
  <div class="contact-form">
    <div
      class="sp-3 light-bg p-3 bd-b pd-2"
      v-if="this.hasPrivilege('CRM_EMPRESAS_CONTATOS_VINCULAR')"
    >
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">CONTATOS VINCULADOS</h4>
          <p class="mb-0">
            <strong>{{ contacts.length }}</strong> contatos que
            <strong>{{ customerDetails.name }}</strong> tem vinculado
          </p>
        </div>
        <div>
          <button
            type="button"
            @click="addDialog = !addDialog"
            class="plus-button"
          >
            <plus-icon color="#ffffff" />
          </button>
        </div>
      </div>

      <transition name="activity" mode="out-in">
        <div class="contact-form mt-4 mb-2" v-if="addDialog">
          <virtual-select
            :tabindex="1"
            placeholder="Selecione uma opção"
            :options="users"
            v-model="selectedContact"
          />
          <!-- <div class="sp-1 select-wrapper-int">
            <v-select
              v-model="selectedContact"
              placeholder="Selecione uma opção"
              label="name"
              :options="users"
              name="customer"
            >
              <template slot="option" slot-scope="option">
                {{ option.name }} <small>({{ option.email1 }})</small>
              </template>
            </v-select>
          </div> -->
          <div class="sp-1 jc-fe ai-fs">
            <button class="activity-submit" @click.prevent="linkContact">
              Vincular Contato
            </button>
          </div>
        </div>
      </transition>
    </div>

    <div class="card-body pd-40 sp-3 text-center" v-if="users.length == 0">
      <!-- <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt=""> -->
      <h6 class="tx-md-20 tx-inverse">Nenhum Contato Cadastrado</h6>
      <p>Vincule um contato clicando no botão + que está acima</p>
    </div>

    <div class="sp-3 table-overflow pd-x-10" v-else>
      <table>
        <tbody>
          <template v-for="(item, index) in filteredContacts">
            <tr v-for="contact in item" :key="index + '-' + contact.id">
              <td>
                <div class="company-item flex ai-c fgap2">
                  <div class="company-letter flex ai-c jc-c">
                    {{ contact.name.split("")[0].toUpperCase() }}
                  </div>
                  <div class="company-info">
                    <h5
                      class="mb-0 cursor-pointer"
                      @click.prevent="
                        $router.push('/crm/contatos/' + contact.id)
                      "
                    >
                      {{ contact.name }}
                    </h5>
                    <span class="mb-0" v-if="contact.email1">{{
                      contact.email1
                    }}</span>
                    <span class="tx-12 d-block" v-if="contact.enabled"
                      ><span
                        class="square-8 bg-success mg-r-5 rounded-circle"
                      ></span>
                      Usuário Habilitado</span
                    >
                    <span class="tx-12 d-block" v-else
                      ><span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                      ></span>
                      Usuário Desabilitado</span
                    >
                    <span class="tx-12" v-if="!contact.archived"
                      ><span
                        class="square-8 bg-success mg-r-5 rounded-circle"
                      ></span>
                      Ativo
                    </span>
                    <span class="tx-12" v-else
                      ><span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                      ></span>
                      Arquivado
                    </span>
                  </div>
                  <div class="company-type flex ai-c fgap2"></div>
                </div>
              </td>
              <td>
                <span class="mb-0 tx-14 tx-bold">{{ contact.email1 }}</span>
                <p class="mb-0">
                  <span v-if="contact.telephone1">
                    {{ contact.telephone1 }}
                  </span>
                  <span v-if="contact.telephone2">
                    {{ contact.telephone2 }}
                  </span>
                </p>
              </td>
              <td>
                <div class="actions">
                  <a href="#" @click.prevent="toggleActions(contact.id)">
                    <dots-icon :size="24" />
                  </a>

                  <transition name="fade" mode="out-in">
                    <div
                      class="actions-submenu"
                      v-if="actionsSubmenu == contact.id"
                    >
                      <ul>
                        <li>
                          <a href="#" @click.prevent="removeContact(contact.id)"
                            >Desvincular</a
                          >
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";

import { DotsIcon, PlusIcon } from "@/components/crm/Icons";
import moment from "moment";
import Swal from "sweetalert2";
import Axios from "axios";
import CredentialService from "@/services/CredentialService.js";
import VirtualSelect from "../VirtualSelect.vue";

export default {
  name: "CustomerContacts",
  components: {
    DotsIcon,
    PlusIcon,
    VirtualSelect,
  },
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  // async mounted() {
  //   await this.getContacts();
  //   await this.getLinkedContacts();
  // },
  data() {
    return {
      search: "",
      addDialog: false,
      users: [],
      selectedContact: null,
      contacts: [],
      actionsSubmenu: null,
    };
  },
  computed: {
    filteredContacts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let orderedCompanies = this.contacts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (this.search !== "") {
        orderedCompanies = orderedCompanies.filter((com) =>
          this.searchObject(com, this.search)
        );
      }

      const grouped = orderedCompanies.reduce((rv, x) => {
        (rv[x.name.split("")[0]] = rv[x.name.split("")[0]] || []).push(x);
        return rv;
      }, {});

      return grouped;
    },
    companyNotFind() {
      return !(Object.keys(this.filteredContacts).length > 0);
    },
  },
  methods: {
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    toggleActions(contactID) {
      if (this.actionsSubmenu === contactID) {
        this.actionsSubmenu = null;
      } else {
        this.actionsSubmenu = contactID;
      }
    },
    formatCustomerCnpj(value) {
      return String(value).replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    async getLinkedContacts() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando contatos vinculadas.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/getContacts/${this.customerDetails.id}`
        );
        this.contacts = response.data.data;
      } catch (error) {
        Swal.fire({
          title: "Contatos Vinculados",
          html: `<p>Não foi possível recuperar os contatos vinculados</p> <div>${error}</div>`,
          type: "error",
        });
      }
      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getContacts() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando contatos.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/getUnlinkedContacts/${this.customerDetails.id}`
        );
        this.users = response.data.data.map((user) => ({
          label: `${user.name} | ${user.email1 ? user.email1 : "Sem E-mail"}`,
          value: user.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Erro interno",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async linkContact() {
      Swal.fire({
        title: "Vincular Contato",
        html: "<p>Tem certeza que deseja vincular a empresa?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Vincular",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (response) => {
        if (response.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Vinculando empresa.",
          });

          try {
            await Axios.get(
              `${config.env.API_DEFAULT.host}/company/vinculateCompanyToContact/${this.customerDetails.id}/${this.selectedContact}`
            );
            Swal.fire({
              title: "Vincular Contato",
              html: "<p>O contato foi vinculadao com sucesso!</p>",
              type: "success",
            });
          } catch (error) {
            console.error(error);
            Swal.fire({
              title: "Vincular Contato",
              html: `<div>${error.response.data.message}</div>`,
              type: "error",
            });
          }

          await this.getLinkedContacts();
          this.selectedContact = null;

          this.$store.commit("setLoading", {
            show: false,
          });
        } else {
          Swal.fire({
            title: "Vincular Contato",
            html: "<p>A empresa não foi vinculada.</p>",
            type: "error",
          });
        }
      });
    },
    searchObject(object, terms) {
      let shouldReturn = false;
      // eslint-disable-next-line
      for (const key in object) {
        const value = object[key];

        if (!shouldReturn && typeof value === "string") {
          shouldReturn = value.toLowerCase().search(terms.toLowerCase()) > -1;
        } else if (!shouldReturn && typeof value === "object") {
          shouldReturn = this.searchObject(value, terms);
        } else if (!shouldReturn && typeof value === "number") {
          shouldReturn =
            value
              .toString()
              .toLowerCase()
              .search(terms.toString().toLowerCase()) > -1;
        }
      }
      return shouldReturn;
    },
    async removeContact(contactID) {
      Swal.fire({
        title: "Desvincular Contato",
        html: "<p>Tem certeza que deseja desvincular o contato desta empresa?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Desvincular",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Desvinculado o contato da empresa.",
          });

          try {
            await Axios.get(
              `${config.env.API_DEFAULT.host}/company/removeContact/${this.customerDetails.id}/${contactID}`
            );
            this.contacts = this.contacts.filter(
              (comp) => comp.id !== contactID
            );
          } catch (error) {
            Swal.fire({
              title: "Desvincular Contato",
              html: `<p>Não foi possível desvincular o contato da empresa</p> <div>${error}</div>`,
              type: "error",
            });
          }

          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire({
            title: "Desvincular Contato",
            html: "<p>Contato desvinculado com sucesso!</p>",
            type: "success",
          });
        } else {
          Swal.fire({
            title: "Desvincular Contato",
            html: "<p>Contato não foi desvinculada!</p>",
            type: "error",
          });
        }
      });
    },
  },
  filters: {
    dateFormat(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  async mounted() {
    await this.getContacts();
    await this.getLinkedContacts();
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td {
  padding: 20px 10px;
  color: #343a40;
}

.input-alert {
  background-color: #a80005;
  border-radius: 6px;
  color: white;
  padding: 8px 20px;
}

.input-alert a {
  font-weight: bold;
  color: white;
}

.letter-item {
  font-size: 1.1rem;
  font-weight: bold;
  color: #868ba1;
}

.letter-line {
  display: block;
  max-width: 200px;
  flex: 0 1 200px;
  width: 100%;
  height: 1px;
  background-color: #868ba1;
}

.company-item .company-letter {
  width: 36px;
  height: 36px;
  border-radius: 64px;
  background-color: #80b7e2;
  color: white;
}

.company-item h5 {
  font-weight: bold;
}

.company-item a {
  color: inherit;
}

.company-type .tag {
  display: block;
  padding: 10px 20px;
  background-color: green;
  color: white;
  border-radius: 100px;
  font-weight: 300;
}

.actions {
  all: unset;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.add-submit:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

.actions {
  position: relative;
}

.actions-submenu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.actions-submenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.actions-submenu ul a {
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

.actions-submenu ul a:hover {
  background-color: #f5f6f7;
}

.select-wrapper-int {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding-left: 15px;
}
</style>

<style>
.select-wrapper-int input {
  height: 40px !important;
  margin-top: 0 !important;
}
</style>
