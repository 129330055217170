<template>
  <form
    id="di-form-transporte"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            <i class="fa fa-truck mg-r-3"></i> Informações de Transporte
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Informações da
            Via de Transporte, Transportador e Conhecimento.
          </p>
        </div>
        <div>
          <div class="d-h-t-right"></div>
        </div>
      </div>

      <hr/>

      <label class="section-title mg-t-0"
      >Informações da Via de Transporte
      </label>
      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Modal </label>
            <select
              v-model="model.codigoViaTransporte"
              name="codigoViaTransporte"
              class="form-control"
              @change="setModal()"
            >
              <option value="01">01 - Marítima</option>
              <option value="04">04 - Aérea</option>
              <option value="07">07 - Rodoviária</option>
              <option value="06">06 - Ferroviária</option>
              <option value="02">02 - Fluvial</option>
              <option value="05">05 - Postal</option>
              <option value="03">03 - Lacustre</option>
              <option value="09">09 - Meios Próprios</option>
              <option value="08">08 - Conduto/Rede de Transmissão</option>
              <option value="10">10 - Entrada Ficta</option>
              <option value="11">11 - Outros Meios</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Transporte Multimodal </label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="indicadorMultimodal"
                    type="radio"
                    v-model="model.indicadorMultimodal"
                    value="S"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="indicadorMultimodal"
                    type="radio"
                    v-model="model.indicadorMultimodal"
                    value="N"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Doc. Chegada da Carga </label>
            <select
              v-model="model.codigoTipoManifesto"
              name="codigoTipoManifesto"
              class="form-control"
            >
              <template v-if="model.codigoViaTransporte === '04'">
                <option value="2">Termo de Entrada</option>
                <option value="3">DTA</option>
              </template>

              <template v-else-if="model.codigoViaTransporte === '01'">
                <option value="1">Manifesto de Carga</option>
                <option value="3">DTA</option>
              </template>

              <template v-else-if="model.codigoViaTransporte === '07'">
                <option value="1">Manifesto de Carga</option>
                <option value="3">DTA</option>
                <option value="4">MIC/DTA</option>
              </template>

              <template v-else-if="model.codigoViaTransporte === '06'">
                <option value="1">Manifesto de Carga</option>
                <option value="3">DTA</option>
              </template>
            </select>
          </div>
        </div>
        <div class="col-md-2" v-if="model.codigoTipoManifesto">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Identificação </label>
            <input
              type="text"
              class="form-control m-input"
              v-model="model.numeroManifesto"
              name="numeroManifesto"
            />
          </div>
        </div>
        <div class="col-md-4" v-if="model.codigoViaTransporte !== '01'">
          <div class="form-group bd-r-0-force bd-t-0-force bd-b-0-force"></div>
        </div>
        <div class="col-md-4" v-else>
          <div class="form-group">
            <label class="form-control-label">
              Nome do Veículo / Embarcação
            </label>
            <input
              type="text"
              class="form-control m-input"
              v-model="model.nomeVeiculoViaTransporte"
              name="nomeVeiculoViaTransporte"
            />
          </div>
        </div>
      </div>

      <template
        v-if="
          model.codigoViaTransporte === '04' ||
          model.codigoViaTransporte === '01' ||
          model.codigoViaTransporte === '07' ||
          model.codigoViaTransporte === '0'
        "
      >
        <label class="section-title mg-t-20"> Transportador </label>
        <div class="row no-gutters">
          <div class="col-md-4">
            <div class="form-group bd-r-0-force">
              <label class="form-control-label"> Nome </label>
              <input
                type="text"
                class="form-control m-input"
                v-model="model.nomeTransportadorViaTransporte"
                name="nomeTransportadorViaTransporte"
              />
            </div>
          </div>
          <div class="col-md-4" v-if="model.codigoViaTransporte === '07'">
            <div class="form-group">
              <label class="form-control-label"> Placa de Veículo </label>
              <input
                type="text"
                class="form-control m-input"
                v-model="model.numeroVeiculoViaTransporte"
                name="numeroVeiculoViaTransporte"
              />
            </div>
          </div>
          <div class="col-md-4" v-else>
            <div class="form-group">
              <label class="form-control-label"> Bandeira </label>
              <v-select
                v-model="model.codigoBandeiraTransporte"
                label="value"
                :options="codigosPaises"
                name="codigoBandeiraTransporte"
              >
                <template slot="option" slot-scope="option">
                  {{ option.value }}
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </template>

      <label class="section-title mg-t-20"> Conhecimento de Transporte </label>
      <div class="row no-gutters">
        <div
          class="col-md-4"
          v-if="
            model.codigoViaTransporte !== '07' &&
            model.codigoViaTransporte !== '01'
          "
        >
          <div class="form-group bd-r-0-force bd-b-0-force">
            <label class="form-control-label"> Tipo </label>
            <select
              v-model="model.codigoTipoDocumentoCarga"
              name="codigoTipoDocumentoCarga"
              class="form-control"
            >
              <option
                value="01"
                v-show="model.codigoViaTransporte === '04'"
                key="codigoTipoDocumentoCarga-01"
              >
                AWB
              </option>
              <option
                value="03"
                v-show="model.codigoViaTransporte === '04'"
                key="codigoTipoDocumentoCarga-03"
              >
                House AWB
              </option>
              <option
                value="14"
                v-show="model.codigoViaTransporte === '04'"
                key="codigoTipoDocumentoCarga-04"
              >
                DISC
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-b-0-force">
            <template v-if="model.codigoViaTransporte === '01'">
              <label class="form-control-label"> CE Mercante </label>
              <input
                type="text"
                class="form-control m-input"
                v-model="model.numeroDocumentoCargaMaster"
                name="numeroDocumentoCargaMaster"
              />
              <input
                type="hidden"
                v-model="model.numeroDocumentoCarga"
                name="numeroDocumentoCarga"
                value="CEMERCANTE31032008"
              />
              <input
                type="hidden"
                v-model="model.codigoTipoDocumentoCarga"
                name="codigoTipoDocumentoCarga"
                value="12"
              />
              <span
                v-show="errors.has('numeroDocumentoCargaMaster')"
                class="parsley-errors-list filled"
              >CE Mercante deve ser digitado corretamente.</span
              >
            </template>
            <template v-else-if="model.codigoViaTransporte === '07'">
              <label class="form-control-label"> Identificação do CRT </label>
              <input
                type="hidden"
                v-model="model.codigoTipoDocumentoCarga"
                name="codigoTipoDocumentoCarga"
                value="13"
              />
              <input
                type="text"
                class="form-control m-input"
                v-model="model.numeroDocumentoCarga"
                name="numeroDocumentoCarga"
                v-validate="'length:16|required'"
                maxlength="16"
              />
              <span
                v-show="errors.has('numeroDocumentoCarga')"
                class="parsley-errors-list filled"
              >Identificação do CRT deve ser digitado corretamente.</span
              >
            </template>
            <template v-else>
              <label class="form-control-label"> Identificação </label>
              <input
                type="text"
                class="form-control m-input"
                v-model="model.numeroDocumentoCarga"
                name="numeroDocumentoCarga"
              />
              <span
                v-show="errors.has('numeroDocumentoCarga')"
                class="parsley-errors-list filled"
              >Identificação é um campo obrigatório.</span
              >
            </template>
          </div>
        </div>

        <div
          class="col-md-8"
          v-if="
            model.codigoViaTransporte === '01' ||
            model.codigoViaTransporte === '07'
          "
        >
          <div
            class="form-group bd-l-0-force bd-b-0-force bd-r-0-force bd-t-0-force"
          ></div>
        </div>

        <div class="col-md-4" v-else>
          <div
            class="form-group bd-l-0-force bd-b-0-force"
            v-if="model.codigoTipoDocumentoCarga === '03'"
          >
            <label class="form-control-label"> Id. do Master </label>
            <input
              type="text"
              class="form-control m-input"
              v-model="model.numeroDocumentoCargaMaster"
              name="numeroDocumentoCargaMaster"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Local de Embarque </label>
            <input
              type="text"
              class="form-control m-input"
              v-model="model.nomeLocalEmbarque"
              name="nomeLocalEmbarque"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Data de Embarque </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker lang="pt-br"
                             v-model="model.dataEmbarque"
                             name="dataEmbarque"
                             class="form-control"
                             format="DD/MM/YYYY"
                             placeholder=""
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Utilização </label>
            <select
              v-model="model.codigoUtilizacaoDocumentoCarga"
              name="codigoUtilizacaoDocumentoCarga"
              class="form-control"
            >
              <option value="1">Total</option>
              <option value="2">Parcial</option>
              <option value="3">Mais de um</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <hr class="mg-t-40"/>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {mapState} from "vuex";
import * as importDeclarationLists from "@/lists/import-declaration-lists.js";

export default {
  name: "di-form-transporte",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    DatePicker,
  },
  data() {
    return {
      model: {},
      initialModel: {},
      codigosPaises: [...importDeclarationLists.codigosPaises],
    };
  },
  methods: {
    async diffObjects(fieldName) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
          initialModel: this.initialModel,
          model: this.model,
          fieldName,
        });

        if (response) {
          this.initialModel = {
            ...this.model,
          };
        }
      }
    },
    setModal() {
      this.model.numeroDocumentoCarga = "";
      this.model.numeroDocumentoCargaMaster = "";

      if (this.model.codigoViaTransporte === "01") {
        this.model.numeroDocumentoCarga = "CEMERCANTE31032008";
        this.model.codigoTipoDocumentoCarga = "12";
      } else if (this.model.codigoViaTransporte === "04") {
      } else if (this.model.codigoViaTransporte === "07") {
      }
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-transporte");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.activeDI,
    };
  },
  beforeDestroy() {
  },
  watch: {
    activeDI() {
      this.initialModel = {
        ...this.activeDI,
      };
      this.model = {
        ...this.activeDI,
      };
    },
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
