<template>
<div class="two-select h-100">
    <h6 class="slim-pagetitle"> Colunas Disponíveis </h6>
    <span class="subtitle">Selecione na coluna direita e ordene na coluna esquerda.</span>
    <br />

    <div class="row h-100">
        <div class="col-md-6">

            <span class="title-list">Itens selecionados</span>
            <perfect-scrollbar class="ht-80p bd bd-1 pd-10 mg-y-5">
                <draggable v-model="selectedItems" class="list-two-select">
                    <label class="active" v-for="item in selectedItems" :key="item" @click="unSelectItem(item)">
                        <span class="checked">{{ labels[item] && labels[item].label ? labels[item].label : labels[item.label].label }}</span>
                    </label>
                </draggable>
            </perfect-scrollbar>
        </div>

        <div class="col-md-6 h-100">
            <span class="title-list">Itens à selecionar</span>

            <perfect-scrollbar class="ht-80p bd bd-1 pd-10 mg-y-5">
                    <input type="text" v-model="textSearchColumns" placeholder="Pesquisar coluna" class="wd-100p form-control">

                <div class="list-two-select">

                    <label v-for="item in computedUnselectedItems" :key="item" @click="selectItem(item)">
                        <span class="checked">{{ labels[item].label }}</span>
                    </label>
                </div>
            </perfect-scrollbar>

        </div>

    </div>

</div>
</template>

<script>
import Draggable from 'vuedraggable';
import * as labelsProcesses from '@/labels/labels.processes.js';

export default {
  name: 'two-select',
  components: {
    draggable: Draggable,
  },
  props: {
    originalItems: Array,
    originalSelectedItems: Array,
  },

  data() {
    return {
      selectedItems: this.originalSelectedItems,
      textSearchColumns: '',
      labels: {
        id: {
          label: 'ID',
        },
        ...labelsProcesses.labels[0],
      },
    };
  },
  methods: {
    selectItem(val) {
      this.selectedItems.push(val);
    },

    unSelectItem(val) {
      const index = this.selectedItems.findIndex((item) => item === val);

      if (index > -1) {
        this.$delete(this.selectedItems, index);
      }
    },
  },

  watch: {
    selectedItems(newVal) {
      this.$emit('onChangeSelected', newVal);
    },
  },

  computed: {
    computedUnselectedItems() {
      return this.originalItems.filter((item) => {
        const itemContent = this.labels[item]; // Acessa label do item

        // verifica se existe label, se não tive o item não é retornado
        // se item estiver no vetor de selecionados também retorna false
        if (itemContent.label !== undefined && !this.selectedItems.includes(item)) {
          return (
            itemContent.label
              .toLowerCase()
              .indexOf(this.textSearchColumns.toLowerCase()) > -1
          );
        }

        return false;
      });
    },
  },
};
</script>

<style scoped>
@import "./styles.css";
</style>
