<template>
  <div>
    <modal-document-item
      v-if="modal.show"
      @close="closeModal('close')"
      @finish="closeModal('finish')"
      @reloadDocuments="loadDocumentos"
      :model="currentItem"
      :isForm="false"
    ></modal-document-item>

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Integração Duas Rodas</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Abaixo estão todos
          os dados
        </p>
      </div>
      <div>
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="operationalIntegrationDuasRodasDropdownDados"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-cog mg-r-5"></i> AÇÕES DO EMBARQUE
          </button>
          <div
            class="dropdown-menu"
            aria-labelledby="operationalIntegrationDuasRodasDropdownDados"
            x-placement="bottom-start"
            style="
              position: absolute;
              transform: translate3d(0px, 42px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
          >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="newQueryListOfShipments"
            >
              <i class="fa fa-ship mg-r-5"></i> Listar Embarques Cadastrados
            </a>
            <a class="dropdown-item" href="#" @click.prevent="newQueryShipment">
              <!--            <a class="dropdown-item" href="#" @click.prevent="queryShipment">-->
              <i class="fa fa-search mg-r-5"></i> Consultar Dados do Embarque
              {{ model.integrationIdentification }}
            </a>
            <a class="dropdown-item" href="#" @click.prevent="newSendShipment">
              <i class="fa fa-arrow-up mg-r-5"></i> Enviar Dados do Processo p/
              Duas Rodas</a
            >
            <a
              class="dropdown-item tx-danger"
              href="#"
              @click.prevent="removeShipment"
            >
              <i class="fa fa-trash mg-r-5"></i> Desvincular o Embarque do
              Processo
            </a>
          </div>
        </div>

        <div class="dropdown mg-l-10">
          <button
            class="btn btn-teal dropdown-toggle"
            type="button"
            id="operationalIntegrationDuasRodasDropdownPrestacaoContas"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-usd mg-r-5"></i> ENCERRAMENTO
          </button>
          <div
            class="dropdown-menu"
            aria-labelledby="operationalIntegrationDuasRodasDropdownPrestacaoContas"
            x-placement="bottom-start"
            style="
              position: absolute;
              transform: translate3d(0px, 42px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
          >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="newSendAccountProvision"
            >
              <i class="fa fa-arrow-up mg-r-5"></i> Enviar Prestação de Contas
              p/ Duas Rodas</a
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="alert alert-danger mt-1 m-b-0 tx-left"
      v-if="needIntegrationCustomValue"
    >
      <i class="fa fa-exclamation-circle mr-1" style="font-size: 15px"></i>
      <strong>Atenção: </strong> O Campo de
      <strong>Despesas Aduaneiras</strong> deve ser preenchido, pois já possui a
      data de NF
    </div>

    <div
      class="alert alert-warning mg-b-0 mg-t-10 tx-left"
      role="alert"
      v-if="isAddictionWithLI"
    >
      <strong>Essa DI contém LI:</strong> Por favor, verifique se as Adições
      desta DI tem Número de Ato Concessório para informar na integração com a
      Duas Rodas.
    </div>

    <br />

    <form
      id="operational-integration-duas-rodas-form"
      class="form-layout form-layout-2"
    >
      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1">
            <label class="form-control-label">Referência</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <input
                  class="form-control"
                  type="text"
                  v-model="model.integrationIdentification"
                  name="integrationIdentification"
                  @keyup.enter="newQueryShipment"
                  v-mask="'500000####'"
                  maxlength="10"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group bd-r-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label">Despesas Aduaneiras (NF)</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money
                  v-model="model.integrationCustomsValue"
                  v-bind="money"
                  class="form-control"
                  name="integrationCustomsValue"
                ></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group bd-r-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label">AFRMM</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money
                  v-model="model.integrationAFRMMValue"
                  v-bind="money"
                  class="form-control"
                  name="integrationAFRMMValue"
                ></money>
              </div>
              <div class="input-group-solved-after wd-200">
                <toggle-button
                  :sync="true"
                  :labels="{
                    checked: 'AFRMM ISENTO',
                    unchecked: 'AFRMM OBRIGATÓRIO',
                  }"
                  :height="18"
                  :width="140"
                  :color="{ unchecked: '#bf0909' }"
                  v-model="model.afrmmExempt"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div
            class="form-group bd-r-0-force bd-t-0-force mg-md-t--1 bg-gray-100"
          >
            <label class="form-control-label">Data da Última Consulta</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <!-- <input class="form-control " type="text" v-model="model.integrationLastReceivedDatetime" name="integrationLastReceivedDatetime" disabled> -->
                {{
                  model.integrationLastReceivedDatetime | formatLocalDateTime
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div
            class="form-group bd-r-0-force bd-t-0-force mg-md-t--1 bg-gray-100"
          >
            <label class="form-control-label">Usuário da Última Consulta</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-user op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <input
                  class="form-control"
                  type="text"
                  v-model="model.integrationLastReceivedBy"
                  name="integrationLastReceivedBy"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div
            class="form-group bd-r-0-force bd-t-0-force mg-md-t--1 bg-gray-100"
          >
            <label class="form-control-label">Data do Último Envio</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <!-- <input class="form-control " type="text" v-model="model.integrationLastSendedDatetime" name="integrationLastSendedDatetime" disabled> -->
                {{ model.integrationLastSendedDatetime | formatLocalDateTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group bd-t-0-force mg-md-t--1 bg-gray-100">
            <label class="form-control-label">Usuário do Último Envio</label>

            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-user op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <input
                  class="form-control"
                  type="text"
                  v-model="model.integrationLastSendedBy"
                  name="integrationLastSendedBy"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <hr class="" />

    <div
      class="card pd-b-20 pd-l-0-force"
      v-if="
        (this.shipment !== undefined && this.shipment !== null) ||
        this.jsonDataCustomer !== null ||
        this.shipments.length > 0
      "
    >
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs tx-uppercase">
          <li class="nav-item">
            <a
              class="nav-link active"
              @click="tab = 'operation-integratio-duasrodas-general'"
              href="#operation-integratio-duasrodas-general"
              data-toggle="tab"
            >
              <i class="fa fa-file-text-o mg-r-2"></i>
              Detalhes do Embarque
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'operation-integratio-duasrodas-itens'"
              href="#operation-integratio-duasrodas-itens"
              data-toggle="tab"
            >
              <i class="fa fa-bars mg-r-2"></i>
              Itens do Embarque
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'operation-integratio-duasrodas-pc'"
              href="#operation-integratio-duasrodas-pc"
              data-toggle="tab"
            >
              <i class="fa fa-money mg-r-2"></i>
              Prestação de Contas
            </a>
          </li>
          <li
            class="nav-item"
            v-if="
              process.importDeclaration !== null &&
              process.importDeclaration.additions !== null
            "
          >
            <a
              class="nav-link"
              @click="tab = 'operation-integration-duasrodas-outras'"
              href="#operation-integration-duasrodas-outras"
              data-toggle="tab"
            >
              <i class="fa fa-pencil mg-r-2"></i>
              Outras Ações
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'operation-integratio-duasrodas-log'"
              href="#operation-integratio-duasrodas-log"
              data-toggle="tab"
            >
              <i class="fa fa-clock mg-r-2"></i>
              Histórico
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="tab-content ht-100p">
          <div
            class="tab-pane active"
            id="operation-integratio-duasrodas-general"
          >
            <template
              v-if="
                (shipment === undefined || shipment === null) &&
                jsonDataCustomer !== undefined &&
                jsonDataCustomer !== null &&
                jsonDataCustomer !== null &&
                shipments.length <= 0
              "
            >
              <div class="report-summary-header mg-t-0 mg-b-0">
                <div>
                  <h4 class="tx-inverse mg-b-3 mg-t-20">
                    Embarque (Processo) #{{ jsonDataCustomer.MAIN.SBELN }}
                  </h4>
                  <p class="mg-b-0">
                    <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                    estão sendo exibidos os dados do embarque da Duas Rodas que
                    estão cadastrados neste processo.
                  </p>
                </div>
                <div></div>
              </div>

              <hr />

              <div class="row mg-t-20">
                <div class="col-md">
                  <label class="section-label-sm tx-gray-800 tx-14"
                    >Datalhes do Embarque</label
                  >
                  <p class="invoice-info-row">
                    <span>Referência do Embarque</span>
                    <span class="tx-black">{{
                      this.jsonDataCustomer.MAIN.SBELN
                    }}</span>
                  </p>
                  <p
                    class="invoice-info-row"
                    v-if="
                      this.jsonDataCustomer.SHP !== null &&
                      this.jsonDataCustomer.SHP !== undefined &&
                      Array.isArray(this.jsonDataCustomer.SHP)
                    "
                  >
                    <span>PO da Duas Rodas</span>
                    <span class="tx-danger tx-bold">{{
                      this.jsonDataCustomer.SHP[0] !== null &&
                      this.jsonDataCustomer.SHP[0] !== undefined
                        ? this.jsonDataCustomer.SHP[0].XBELN
                        : "-"
                    }}</span>
                  </p>
                  <p
                    class="invoice-info-row"
                    v-if="
                      this.jsonDataCustomer.SHP !== null &&
                      this.jsonDataCustomer.SHP !== undefined &&
                      typeof this.jsonDataCustomer.SHP === 'object'
                    "
                  >
                    <span>PO da Duas Rodas</span>
                    <span class="tx-danger tx-bold">{{
                      this.jsonDataCustomer.SHP.XBELN
                        ? this.jsonDataCustomer.SHP.XBELN
                        : "-"
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Tipo de Embarque</span>
                    <span>{{ this.jsonDataCustomer.MAIN.SHIPT }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Incoterm</span>
                    <span>{{ this.jsonDataCustomer.MAIN.INCO1 }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Modal</span>
                    <span>{{ this.jsonDataCustomer.MAIN.BFMAR }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>País procedência</span>
                    <span>{{ this.jsonDataCustomer.MAIN.ZLANDO }}</span>
                  </p>
                </div>
                <!-- col -->

                <div class="col-md">
                  <label class="section-label-sm tx-gray-800 tx-14"
                    >Datas</label
                  >
                  <p class="invoice-info-row">
                    <span>Data ETD Prevista</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.ETDPDT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETD </span>
                    <span>{{
                      this.jsonDataCustomer.DAT.ETDDAT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETD Transbordo</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.OVRDDT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETA Transbordo</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.OVRADT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Atracação</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.ATRDAT | formatDate
                    }}</span>
                  </p>
                <p class="invoice-info-row">
                    <span>
                      Data Alcance (<small>Email</small>)
                    </span>
                    <span>{{
                      this.jsonDataCustomer.DAT.ALCDAT | formatDate
                    }}</span>
                  </p>
                </div>
                <!-- col -->
                <div class="col-md">
                  <label class="section-label-sm tx-gray-500"> &nbsp; </label>
                  <p class="invoice-info-row">
                    <span>Data Registro DI</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.DIRDAT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Nº da DI</span>
                    <span>{{
                      this.jsonDataCustomer.DI.DINUM
                        ? this.jsonDataCustomer.DI.DINUM
                        : "N/I"
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Desembaraço</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.CLRDAT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Carregamento</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.LOADAT | formatDate
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Entrega</span>
                    <span>{{
                      this.jsonDataCustomer.DAT.DELDAT | formatDate
                    }}</span>
                  </p>
                </div>
                <!-- col -->
              </div>

              <div class="section-wrapper mg-t-20 pd-0 bd-0">
                <label class="section-title">Fornecedores</label>
                <p class="mg-b-20 mg-sm-b-0">
                  Fornecedores que foram cadastrados no embarque.
                </p>

                <div class="table-responsive">
                  <table class="table table-striped mg-b-0 tx-12">
                    <thead>
                      <tr>
                        <th class="tx-center">Código</th>
                        <th class="tx-center">Nome</th>
                        <th class="tx-center">CNPJ / Identificação</th>
                        <th class="tx-center">Endereço</th>
                        <th class="tx-center">País</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        jsonDataCustomer.PARS !== null &&
                        jsonDataCustomer.PARS !== undefined &&
                        jsonDataCustomer.PARS.length > 0
                      "
                    >
                      <tr
                        v-for="par in jsonDataCustomer.PARS"
                        :key="`par-${par.PARID}`"
                      >
                        <td class="tx-center">{{ par.PARID }}</td>
                        <td class="tx-bold tx-left">
                          {{ par.NAME1 }}
                          <template v-if="par.VTXTK">
                            <br />
                            <span
                              class="square-8 bg-warning mg-r-5 rounded-circle"
                            ></span>
                            <small>{{ par.VTXTK }}</small>
                          </template>
                        </td>
                        <td class="tx-center">{{ par.STCD1 }}</td>
                        <td class="tx-left">
                          {{ par.STREET }}, {{ par.HOUSE_NUM1 }}
                          <template v-if="par.HOUSE_NUM2">
                            - {{ par.HOUSE_NUM2 }}
                          </template>
                          , {{ par.CITY2 }}
                          <br />
                          <template v-if="par.PSTLZ">
                            ({{ par.PSTLZ }})
                          </template>
                          - {{ par.CITY1 }} / {{ par.REGION }}
                        </td>
                        <td class="tx-center">{{ par.COUNTRY }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>

            <template
              v-else-if="
                shipment !== undefined &&
                shipment !== null &&
                this.shipment.SHP !== null &&
                this.shipment.SHP !== undefined
              "
            >
              <!-- <div class="alert alert-solid alert-warning" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <strong>Atenção!</strong> Este embarque foi atualizado pela Duas Rodas. Clique no botão Atualizar, para incluir as novas informações neste processo.
                            </div> -->

              <div class="report-summary-header mg-t-0 mg-b-0">
                <div>
                  <h4 class="tx-inverse mg-b-3 mg-t-20">
                    Detalhes do Embarque <strong>(Consulta)</strong>
                  </h4>
                  <p class="mg-b-0">
                    <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                    estão sendo exibidos alguns detalhes do embarque.
                  </p>
                </div>
                <div>
                  <a
                    href="#"
                    class="btn btn-success"
                    @click.prevent="updateShipmentInProcess"
                  >
                    <i class="icon fa fa-save tx-22"></i> SALVAR
                  </a>
                  <a
                    href="#"
                    class="btn btn-danger"
                    @click.prevent="removeShipment"
                  >
                    <i class="icon fa fa-trash tx-22"></i> DESVINCULAR
                  </a>
                  <a
                    href="#"
                    class="btn btn-info"
                    @click.prevent="closeShipmentInProcess"
                  >
                    <i class="icon fa fa-close tx-22"></i> FECHAR
                  </a>
                </div>
              </div>

              <hr class="mg-b-25 mg-t-25" />

              <div class="row mg-t-20">
                <div class="col-md">
                  <label class="section-label-sm tx-gray-500"
                    >Datalhes do Embarque</label
                  >
                  <p class="invoice-info-row">
                    <span>Referência do Embarque</span>
                    <span class="tx-black">{{ this.shipment.MAIN.SBELN }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>PO da Duas Rodas</span>
                    <span class="tx-danger tx-bold">{{
                      Array.isArray(this.shipment.SHP) &&
                      this.shipment.SHP.length > 0
                        ? this.shipment.SHP[0].XBELN
                        : this.shipment.SHP.XBELN
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Tipo de Embarque</span>
                    <span>{{ this.shipment.MAIN.SHIPT }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Incoterm</span>
                    <span>{{ this.shipment.MAIN.INCO1 }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Modal</span>
                    <span>{{ this.shipment.MAIN.BFMAR }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>País procedência</span>
                    <span>{{ this.shipment.MAIN.ZLANDO }}</span>
                  </p>
                </div>
                <!-- col -->
                <div class="col-md">
                  <label class="section-label-sm tx-gray-500"
                    >Informações do Fornecedor</label
                  >
                  <p class="invoice-info-row">
                    <span>Código</span>
                    <span>{{ this.shipment.PAR.PARID }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Nome</span>
                    <span
                      >{{ this.shipment.PAR.NAME1 }} /
                      {{ this.shipment.PAR.NAME2 }}</span
                    >
                  </p>
                  <p classintegrationIdentification="invoice-info-row">
                    <span>CNPJ</span>
                    <span>{{ this.shipment.PAR.STCD1 }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Endereço 1</span>
                    <span
                      >{{ this.shipment.PAR.HOUSE_NUM1 }},
                      {{ this.shipment.PAR.STREET }}</span
                    >
                  </p>
                  <p class="invoice-info-row">
                    <span>Endereço 2</span>
                    <span>
                      {{ this.shipment.PAR.REGION }}
                      {{ this.shipment.PAR.PSTLZ }} /
                      {{ this.shipment.PAR.COUNTRY }}</span
                    >
                  </p>
                </div>
                <!-- col -->
                <div class="col-md">
                  <label class="section-label-sm tx-gray-500">Datas</label>
                  <p class="invoice-info-row">
                    <span>Data ETD Prevista</span>
                    <span>{{ this.shipment.DAT.ETDPDT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETD </span>
                    <span>{{ this.shipment.DAT.ETDDAT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETD Transbordo</span>
                    <span>{{ this.shipment.DAT.OVRDDT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data ETA Transbordo</span>
                    <span>{{ this.shipment.DAT.OVRADT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Atracação</span>
                    <span>{{ this.shipment.DAT.ATRDAT | formatDate }}</span>
                  </p>
                </div>
                <!-- col -->
                <div class="col-md">
                  <label class="section-label-sm tx-gray-500"> &nbsp; </label>
                  <p class="invoice-info-row">
                    <span>Data Registro DI</span>
                    <span>{{ this.shipment.DAT.DIRDAT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Nº da DI</span>
                    <span>{{ this.shipment.DI.DINUM }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Desembaraço</span>
                    <span>{{ this.shipment.DAT.CLRDAT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Carregamento</span>
                    <span>{{ this.shipment.DAT.LOADAT | formatDate }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Data Entrega</span>
                    <span>{{ this.shipment.DAT.DELDAT | formatDate }}</span>
                  </p>
                </div>
                <!-- col -->
              </div>
            </template>

            <template v-else></template>

            <template
              v-if="
                this.shipments !== undefined &&
                this.shipments !== null &&
                this.shipments.length > 0
              "
            >
              <div class="report-summary-header mg-b-5 mg-t-0">
                <div>
                  <h4 class="tx-inverse mg-b-3 mg-t-20">
                    Listagem de Embarques
                  </h4>
                  <p class="mg-b-0">
                    <i class="icon ion-information-circled mg-r-3"></i> Foram
                    encontrados {{ this.shipments.length }} embarques
                    disponíveis na Duas Rodas.
                  </p>
                </div>
                <div>
                  <a
                    href="#"
                    class="btn btn-danger"
                    @click.prevent="closeQueryListOfShipments"
                  >
                    <i class="icon fa fa-close tx-22"></i> FECHAR
                  </a>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="card card-table wd-100p">
                  <div class="table-responsive">
                    <table class="table table-striped mg-b-0">
                      <thead>
                        <tr>
                          <th class="tx-center">Referência</th>
                          <th class="tx-center">Modal</th>
                          <th class="tx-center">Status do Embarque</th>
                          <th class="tx-center">Descrição do Status</th>
                          <th class="tx-center">Última Atualização</th>
                          <th class="tx-center">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="this.shipments.length < 1">
                          <tr>
                            <td colspan="6" class="tx-center">
                              Nenhum carregamento sendo exibido!
                              <a href="#" @click="newQueryListOfShipments"
                                >Clique Aqui</a
                              >
                              para pesquisar carregamentos disponíveis.
                            </td>
                          </tr>
                        </template>

                        <tr
                          v-for="(shipment, index) in this.shipments"
                          :key="`ship-${index}`"
                        >
                          <template
                            v-if="
                              model.integrationIdentification !== null &&
                              model.integrationIdentification === shipment.sbeln
                            "
                          >
                            <th
                              scope="row"
                              class="valign-middle tx-14 tx-center tx-danger"
                            >
                              {{ shipment.sbeln }}
                              <br />
                              <small
                                ><span class="tx-danger"
                                  ><i
                                    class="icon ion-android-arrow-up mg-r-5"
                                  ></i>
                                  Processo Atual</span
                                ></small
                              >
                            </th>
                          </template>

                          <template v-else>
                            <th
                              scope="row"
                              class="valign-middle tx-14 tx-center tx-bold"
                            >
                              {{ shipment.sbeln }}
                            </th>
                          </template>

                          <td class="valign-middle tx-center">
                            {{ shipment.bfmar }}
                          </td>
                          <td class="valign-middle tx-center">
                            {{ shipment.stcod }}
                          </td>
                          <td class="valign-middle tx-center">
                            {{ shipment.descr }}
                          </td>
                          <td class="valign-middle tx-center">
                            {{ shipment.lastUpdate }}
                          </td>
                          <td class="valign-middle tx-center">
                            <template
                              v-if="
                                model.integrationIdentification === null ||
                                model.integrationIdentification === null
                              "
                            >
                              <a
                                href="#"
                                class="tx-14 btn btn-primary btn-sm btn-icon rounded-circle"
                                @click="addShipment(shipment)"
                                title="Incluir embarque neste processo."
                              >
                                <div><i class="fa fa-plus"></i></div>
                              </a>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="tab-pane" id="operation-integratio-duasrodas-outro">
            <div class="report-summary-header mg-t-0 mg-b-0">
              <div>
                <h4 class="tx-inverse mg-b-3 mg-t-20">Outras Ações</h4>
                <p class="mg-b-0">
                  <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                  estão sendo exibidos outras ações desta integração.
                </p>
              </div>
              <div></div>
            </div>

            <hr />

            <div class="table-responsive">
              <table class="table table-striped mg-b-0">
                <thead>
                  <tr>
                    <th class="tx-center">Código</th>
                    <th>Data</th>
                    <th>Usuário</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="this.model.logIntegrationTransation">
                    <tr
                      v-for="log in this.model.logIntegrationTransation"
                      :key="'logIntegrationTransation-' + log.id"
                    >
                      <td class="tx-center">#{{ log.id }}</td>
                      <td>
                        {{ log.created | moment("DD/MM/YYYY \\à\\s HH:mm:ss") }}
                      </td>
                      <td>{{ log.createdBy }}</td>
                      <td>{{ log.description }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <th scope="row" class="tx-center" colspan="4">
                        Nenhum histórico de ação foi criado.
                      </th>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <OperationalIntegrationDuasRodasOutras
            class="tab-pane"
            id="operation-integration-duasrodas-outras"
            v-if="
              process.importDeclaration !== null &&
              process.importDeclaration.additions !== null
            "
          />

          <div class="tab-pane" id="operation-integratio-duasrodas-log">
            <div class="report-summary-header mg-t-0 mg-b-0">
              <div>
                <h4 class="tx-inverse mg-b-3 mg-t-20">Histórico de Ações</h4>
                <p class="mg-b-0">
                  <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                  estão sendo exibidos o histórico das ações realizadas na
                  integração.
                </p>
              </div>
              <div></div>
            </div>

            <hr />

            <div class="table-responsive">
              <table class="table table-striped mg-b-0">
                <thead>
                  <tr>
                    <th class="tx-center">Código</th>
                    <th>Data</th>
                    <th>Usuário</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="this.logs.length > 0">
                    <tr
                      v-for="log in this.logs"
                      :key="'logIntegrationTransation2-' + log.id"
                    >
                      <td class="tx-center">#{{ log.id }}</td>
                      <td>
                        {{ log.created | moment("DD/MM/YYYY \\à\\s HH:mm:ss") }}
                      </td>
                      <td>{{ log.createdBy }}</td>
                      <td>{{ log.description }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <th scope="row" class="tx-center" colspan="4">
                        Nenhum histórico de ação foi criado.
                      </th>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="tab-pane" id="operation-integratio-duasrodas-itens">
            <div class="report-summary-header mg-t-0 mg-b-0">
              <div>
                <h4 class="tx-inverse mg-b-3 mg-t-20">Itens do Embarque</h4>
                <p class="mg-b-0">
                  <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                  estão sendo exibidos os itens que estão disponíveis neste
                  embarque.
                </p>
              </div>
              <div></div>
            </div>

            <hr />

            <template
              v-if="
                jsonDataCustomer !== null &&
                jsonDataCustomer.SHP !== undefined &&
                jsonDataCustomer.SHP !== null &&
                jsonDataCustomer.SHP.length > 0
              "
            >
              <div class="row mg-t-20">
                <div class="col-md">
                  <label class="section-label-sm tx-gray-800 tx-14"
                    >Informações dos Itens</label
                  >
                  <p class="invoice-info-row">
                    <span>Quantidade de Itens</span>
                    <span>{{ jsonDataCustomer.SHP.length }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Quantidade de Subitens</span>
                    <span>{{ this.itensTemSubitem }}</span>
                  </p>
                </div>
                <div class="col-md">
                  <label class="section-label-sm tx-gray-800 tx-14"
                    >&nbsp;</label
                  >
                  <p class="invoice-info-row">
                    <span>Quantidade / Peso Total</span>
                    <span>{{
                      this.itensQuantidadeTotal | numeralFormat("0,0.000")
                    }}</span>
                  </p>
                  <p class="invoice-info-row">
                    <span>Valor Total</span>
                    <span>{{
                      this.itensSomarValorTotal | numeralFormat("0,0.00")
                    }}</span>
                  </p>
                </div>
                <div class="col-md"></div>
              </div>

              <hr />
            </template>

            <div class="section-wrapper mg-t-20 pd-0 bd-0">
              <label class="section-title">Itens do Embarque</label>
              <p class="mg-b-20 mg-sm-b-0">
                Itens que foram cadastrados no embarque.
              </p>

              <div class="table-responsive">
                <table class="table table-hover mg-b-0 tx-12">
                  <thead>
                    <tr>
                      <th class="tx-center" rowspan="2">#</th>
                      <th class="tx-center" rowspan="2">Subitem</th>
                      <th class="tx-center" colspan="2">Mercadoria</th>
                      <th class="tx-center" colspan="2">
                        Documento de Compras
                      </th>
                      <th class="tx-center" rowspan="2">Quantidade</th>
                      <th class="tx-center" colspan="2">Preço</th>
                    </tr>
                    <tr>
                      <th class="tx-center">Material</th>
                      <th class="tx-center">Descrição</th>
                      <th class="tx-center">Nº Doc</th>
                      <th class="tx-center">Nº Item</th>
                      <th class="tx-center">Unitário</th>
                      <th class="tx-center">Total</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      jsonDataCustomer !== null &&
                      jsonDataCustomer.SHP !== undefined &&
                      jsonDataCustomer.SHP !== null
                    "
                  >
                    <template v-if="Array.isArray(jsonDataCustomer.SHP)">
                      <tr
                        v-for="item in jsonDataCustomer.SHP"
                        :key="'jdc-' + item.SBELP + '' + item.NBELP"
                      >
                        <td class="tx-center">{{ item.SBELP }}</td>
                        <td class="tx-center">
                          <template v-if="item.NBELP !== '00000'">
                            {{ Number(item.NBELP) }}
                          </template>
                        </td>
                        <td class="tx-center">{{ item.MATNR }}</td>
                        <td class="tx-left">{{ item.MAKTX }}</td>
                        <td class="tx-center">{{ item.XBELN }}</td>
                        <td class="tx-center">{{ item.XBELP }}</td>
                        <td class="tx-right">
                          {{ item.MENGEE | numeralFormat("0,0.000") }}
                          {{ item.MEINS }}
                        </td>
                        <td class="tx-right">
                          {{ item.NETPR | numeralFormat("0,0.00") }}
                        </td>
                        <td class="tx-right">
                          {{ item.NETWR | numeralFormat("0,0.00") }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td class="tx-center">
                          {{ jsonDataCustomer.SHP.SBELP }}
                        </td>
                        <td class="tx-center">
                          <template
                            v-if="jsonDataCustomer.SHP.NBELP !== '00000'"
                          >
                            {{ Number(jsonDataCustomer.SHP.NBELP) }}
                          </template>
                        </td>
                        <td class="tx-center">
                          {{ jsonDataCustomer.SHP.MATNR }}
                        </td>
                        <td class="tx-left">
                          {{ jsonDataCustomer.SHP.MAKTX }}
                        </td>
                        <td class="tx-center">
                          {{ jsonDataCustomer.SHP.XBELN }}
                        </td>
                        <td class="tx-center">
                          {{ jsonDataCustomer.SHP.XBELP }}
                        </td>
                        <td class="tx-right">
                          {{
                            jsonDataCustomer.SHP.MENGEE
                              | numeralFormat("0,0.000")
                          }}
                          {{ jsonDataCustomer.SHP.MEINS }}
                        </td>
                        <td class="tx-right">
                          {{
                            jsonDataCustomer.SHP.NETPR | numeralFormat("0,0.00")
                          }}
                        </td>
                        <td class="tx-right">
                          {{
                            jsonDataCustomer.SHP.NETWR | numeralFormat("0,0.00")
                          }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="tab-pane pd-l-0-force mg-l-0-force"
            id="operation-integratio-duasrodas-pc"
          >
            <table class="table table-striped mg-b-0 pd-l-0-force mg-l-0-force">
              <thead>
                <tr>
                  <th class="tx-center">Identificação</th>
                  <th class="tx-center">Fornecedor</th>
                  <th class="tx-center">Nº Documento</th>
                  <th class="tx-center">Serviço?</th>
                  <th class="tx-center">Adiantamento?</th>
                  <th class="tx-center">Vencimento</th>
                  <th class="tx-center">Valor</th>
                  <th class="tx-center wd-150-force">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr-inline
                  class="new-item"
                  :isNew="true"
                  :row="newProcessAppointmentDocument"
                  @save="createProcessAppointmentDocument"
                >
                  <td-inline
                    v-model="newProcessAppointmentDocument.typeOfDocument"
                    placeholder=" Identificação"
                    type="v-select"
                    :options="categories"
                    label="description"
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.provider"
                    type="v-select"
                    :options="listOfPars"
                    label="SHORTNAME"
                    searchReturnKey="NAME1"
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.documentReference"
                    placeholder=" Nº NF / NFS "
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.service"
                    type="toggle"
                    :options="{ checked: 'SIM', unchecked: 'NÃO' }"
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.advance"
                    type="toggle"
                    :options="{ checked: 'SIM', unchecked: 'NÃO' }"
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.dueDate"
                    type="date"
                    icon="fa-calendar"
                    :class="'tx-center'"
                  />
                  <td-inline
                    v-model="newProcessAppointmentDocument.totalValue"
                    type="money"
                  />
                </tr-inline>
              </tbody>
            </table>

            <hr />

            <div class="report-summary-header mg-t-0 mg-b-10">
              <div>
                <h4 class="tx-inverse mg-b-3 mg-t-0">
                  Documentos de Adiantamento
                </h4>
                <p class="mg-b-0">
                  <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                  estão sendo exibidos os documentos relacionados ao
                  adiantamento.
                </p>
              </div>
              <div>
                <a
                  href="#"
                  class="btn btn-secondary btn-sm"
                  @click="generateAppointmentPdf"
                  ><i class="icon ion-document-text tx-22"></i> DEMONSTRATIVO
                </a>
              </div>
            </div>

            <template
              v-if="
                valolTotalPrestacaoContas.toFixed(2) >
                valolTotalAdiantamento.toFixed(2)
              "
            >
              <div class="alert alert-danger mg-b-0" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>ADIANTAMENTO: </strong> O valor total do
                <strong>ADIANTAMENTO</strong> é menor do que o valor total da
                Prestação de Contas.
              </div>

              <hr />
            </template>

            <table class="table table-striped mg-b-0 pd-l-0-force mg-l-0-force">
              <thead>
                <tr>
                  <th class="tx-center">Identificação</th>
                  <th class="tx-center">Fornecedor</th>
                  <th class="tx-center">Nº Documento</th>
                  <th class="tx-center">Serviço?</th>
                  <th class="tx-center">Vencimento</th>
                  <th class="tx-center">Valor</th>
                  <th class="tx-center">Validação dos Valores</th>
                  <th class="tx-center wd-200-force">Ações</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in documentosAD">
                  <tr-inline
                    v-if="item.advance === true"
                    :key="'integration-duas-rodas-ad-' + index"
                    :row="item"
                    @save="updateProcessAppointmentDocument"
                  >
                    <td-inline
                      v-model="item.typeOfDocument"
                      placeholder=" Identificação"
                      type="v-select"
                      :options="categories"
                      label="shortname"
                      searchReturnKey="description"
                    />
                    <td-inline
                      v-model="item.provider"
                      type="v-select"
                      :options="listOfPars"
                      label="SHORTNAME"
                      searchReturnKey="SHORTNAME"
                    />
                    <td-inline
                      v-model="item.documentReference"
                      placeholder=" Nº NF / NFS "
                    />
                    <td-inline
                      v-model="item.service"
                      type="toggle"
                      :options="{ checked: 'SIM', unchecked: 'NÃO' }"
                    />
                    <td-inline
                      v-model="item.dueDate"
                      type="date"
                      icon="fa-calendar"
                      :class="'tx-center'"
                    />
                    <td-inline v-model="item.totalValue" type="money" />
                    <td class="text-center text-dark">
                        <span>
                          <i v-if="getItemsValues(item) !== roundVal(item.totalValue)" class="fa fa-times text-danger"></i>
                          <i v-else class="fa fa-check text-success"></i>
                        </span> <br>
                      <span>Valor da soma dos itens R$ {{ getItemsValues(item) | numeralFormat("0,0.00") }} </span> <br>
                      <span v-if="getItemsValues(item) !== roundVal(item.totalValue)" class="text-danger">
                          <i class="fa fa-exclamation-triangle"></i> Inconsistências encontradas na soma dos itens do documentos!
                        </span>
                    </td>

                    <template slot="actions">
                      <a
                        href="#"
                        @click.prevent="abrirItens(item)"
                        class="btn btn-primary btn-icon rounded-circle mg-r-5"
                      >
                        <div><i class="icon ion-navicon-round"></i></div>
                      </a>

                      <a
                        href="#"
                        @click.prevent="newSendAccountProvisionDocument(item)"
                        class="btn btn-warning btn-icon rounded-circle mg-r-5"
                      >
                        <div><i class="fa fa-exchange"></i></div>
                      </a>

                      <a
                        href="#"
                        @click.prevent="removeProcessAppointmentDocument(item)"
                        class="btn btn-danger btn-icon rounded-circle"
                      >
                        <div><i class="icon ion-trash-a"></i></div>
                      </a>
                    </template>
                  </tr-inline>
                </template>

                <template v-if="totalItensAdiantamento === 0">
                  <tr>
                    <td colspan="7" class="tx-center tx-thin">
                      Nenhum documento foi cadastrado.
                    </td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="6" class="tx-right">Valores Totais</th>
                  <th class="tx-right">
                    R$ {{ valolTotalAdiantamento | numeralFormat("0,0.00") }}
                  </th>
                  <th></th>
                </tr>
              </tfoot>
            </table>

            <hr />

            <div class="report-summary-header mg-t-0 mg-b-10">
              <div>
                <h4 class="tx-inverse mg-b-3 mg-t-0">
                  Documentos da Prestação de Contas
                </h4>
                <p class="mg-b-0">
                  <i class="icon ion-information-circled mg-r-3"></i> Abaixo
                  estão sendo exibidos os documento relacionados a prestaçao de
                  contas.
                </p>
              </div>
              <div>
                <a
                  href="#"
                  class="btn btn-secondary btn-sm"
                  @click="generateAppointmentPdf"
                  ><i class="icon ion-document-text tx-22"></i> DEMONSTRATIVO
                </a>
              </div>
            </div>

            <div style="overflow: auto">
              <table
                class="table table-striped mg-b-0 pd-l-0-force mg-l-0-force"
              >
                <thead>
                  <tr>
                    <th class="tx-center">Identificação</th>
                    <th class="tx-center">Fornecedor</th>
                    <th class="tx-center">Nº Documento</th>
                    <th class="tx-center">Itens</th>
                    <th class="tx-center">Serviço?</th>
                    <th class="tx-center">Vencimento</th>
                    <th class="tx-center">Valor</th>
                    <th class="tx-center">Validação dos Valores</th>
                    <th class="tx-center wd-200-force">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in documentosPC">
                    <tr-inline
                      v-if="item.advance === false"
                      :key="'integration-duas-rodas-ps-' + item.id"
                      :row="item"
                      @save="updateProcessAppointmentDocument"
                    >
                      <td-inline
                        v-model="item.typeOfDocument"
                        placeholder=" Identificação"
                        type="v-select"
                        :options="categories"
                        label="shortname"
                        searchReturnKey="description"
                      />
                      <td-inline
                        v-model="item.provider"
                        type="v-select"
                        :options="listOfPars"
                        label="SHORTNAME"
                        searchReturnKey="SHORTNAME"
                      />
                      <td-inline
                        v-model="item.documentReference"
                        placeholder=" Nº NF / NFS "
                      />
                      <td class="tx-center tx-bold">{{ item.items.length }}</td>
                      <td-inline
                        v-model="item.service"
                        type="toggle"
                        :options="{ checked: 'SIM', unchecked: 'NÃO' }"
                      />
                      <td-inline
                        v-model="item.dueDate"
                        type="date"
                        icon="fa-calendar"
                        :class="'tx-center'"
                      />
                      <td-inline v-model="item.totalValue" type="money" />
                      <td class="text-center text-dark">
                        <span>
                          <i v-if="getItemsValues(item) !== roundVal(item.totalValue)" class="fa fa-times text-danger"></i>
                          <i v-else class="fa fa-check text-success"></i>
                        </span> <br>
                        <span>Valor da soma dos itens R$ {{ getItemsValues(item) | numeralFormat("0,0.00") }} </span> <br>
                        <span v-if="getItemsValues(item) !== roundVal(item.totalValue)" class="text-danger">
                          <i class="fa fa-exclamation-triangle"></i> Inconsistências encontradas na soma dos itens do documentos!
                        </span>
                      </td>

                      <template slot="actions">
                        <a
                          href="#"
                          @click.prevent="abrirItens(item)"
                          class="btn btn-primary btn-icon rounded-circle mg-r-5"
                        >
                          <div><i class="icon ion-navicon-round"></i></div>
                        </a>

                        <a
                          href="#"
                          @click.prevent="newSendAccountProvisionDocument(item)"
                          class="btn btn-warning btn-icon rounded-circle mg-r-5"
                        >
                          <div><i class="fa fa-exchange"></i></div>
                        </a>

                        <a
                          href="#"
                          @click.prevent="
                            removeProcessAppointmentDocument(item)
                          "
                          class="btn btn-danger btn-icon rounded-circle"
                        >
                          <div><i class="icon ion-trash-a"></i></div>
                        </a>
                      </template>
                    </tr-inline>
                  </template>

                  <template v-if="totalItensPrestacaoContas === 0">
                    <tr>
                      <td colspan="8" class="tx-center tx-thin">
                        Nenhum documento foi cadastrado.
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="7" class="tx-right">Valores Totais</th>
                    <th class="tx-right">
                      R$
                      {{ valolTotalPrestacaoContas | numeralFormat("0,0.00") }}
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="col-lg-12 mg-lg-t-0 mg-0-force pd-0-force">
        <div class="card card-dash-headline pd-40-force">
          <h4 class="mg-b-0-force">
            Nenhum embarque da Duas Rodas foi vinculado ao processo.
          </h4>
          <p>
            Para vincular um embarque da Duas Rodas para este processo, você
            primeiro precisa listar os embarques que foram abertos e então
            <br />
            visualizar se o embarque referente a este processo está disponível
            na listagem. Caso esteja, clique no botão
            <i class="fa fa-plus"></i> para vinculá-lo ao processo.
          </p>
          <div class="row row-sm">
            <div class="col-sm-12">
              <a
                class="btn btn-primary btn-block tx-uppercase"
                href="#"
                @click.prevent="newQueryListOfShipments"
              >
                <i class="fa fa-ship mg-r-5"></i> Listar Embarques Cadastrados
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="'Relatório_De_Atividades'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="100%"
      ref="appointmentPdf"
    >
      <section slot="pdf-content">
        <div class="report-box">
          <table class="wd-100p" cellpadding="0" cellspacing="0">
            <tr class="report-top">
              <td colspan="2">
                <table class="report-header wd-100p">
                  <tr>
                    <td class="report-header-left">
                      <div>
                        <span
                          style="
                            line-height: 30px;
                            font-size: 11px;
                            text-transform: uppercase;
                            background: #343a40;
                            color: #ffffff;
                            padding: 5px;
                          "
                        >
                          Processo de Importação
                        </span>
                        <br />
                        <span
                          style="
                            line-height: 45px;
                            font-size: 60px;
                            font-weight: 400;
                          "
                        >
                          {{ process.identification }}
                        </span>
                        <br />
                      </div>
                    </td>
                    <td class="report-header-middle">
                      <strong>
                        Prime Internacional Comércio Exterior LTDA
                      </strong>
                      <br />
                      CNPJ: 08214494000119
                      <br />
                      Inscrição Municipal: 285459-0 / IE: 255.232.322
                      <br />
                      Matriz: Itajaí / SC
                      <br />
                    </td>
                    <td
                      class="report-header-right"
                      align="right"
                      valign="middle"
                    >
                      <img
                        src="@/assets/img/logo_prime.png"
                        style="width: 175px"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <div class="pdf-report-data">
              <h5
                style="
                  margin-top: 50px;
                  text-transform: uppercase;
                  font-weight: bold;
                "
              >
                Demonstrativo final de prestação de contas
              </h5>
              <table style="width: 100%; margin-top: 20px; font-size: small">
                <thead>
                  <tr style="padding: 10px">
                    <th>Cliente</th>
                    <th width="50%">Ref / Cliente</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ process.customer.completeName }}</td>
                    <td width="50%">{{ process.customerRef }}</td>
                  </tr>
                </tbody>
              </table>
              <table style="width: 100%; margin-top: 20px; font-size: small">
                <thead>
                  <tr style="padding: 10px">
                    <th>Num /Ref embarque</th>
                    <th width="50%">Nº DI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ process.anotherRef }}</td>
                    <td width="50%">{{ process.diNumber }}</td>
                  </tr>
                </tbody>
              </table>
              <h5
                style="
                  margin-top: 35px;
                  text-transform: uppercase;
                  font-weight: bold;
                "
              >
                Documentos
              </h5>
              <table class="new-report-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Tipo</th>
                    <th style="text-align: end">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(document, index) in documentos">
                    <!--                    <div class="html2pdf__page-break" v-if="(index !== 0 && (index % 8 === 0 || index % 7 === 0 || index % 6 === 0 || index % 5 === 0 || index % 4 === 0)) && document.items.length >= 3" style="margin-top: 10px;"/>-->
                    <tr :key="`doc-${index}`">
                      <td>
                        <p style="font-weight: bold; margin: 0; padding: 0">
                          {{ document.provider.PARID }} |
                          {{ document.provider.NAME1 }} -
                          {{ document.documentReference }}
                        </p>
                        <span
                          >Data de emissão:
                          {{ document.dueDate | formatLocalDateTime }}</span
                        >
                      </td>
                      <td
                        :style="{ color: !document.advance ? 'red' : 'blue' }"
                      >
                        {{ !document.advance ? "DB" : "CR" }}
                      </td>
                      <td style="text-align: end">
                        R$
                        {{
                          (!document.advance ? "-" : "") + document.totalValue
                        }}
                      </td>
                    </tr>
                    <tr
                      class="sub-row"
                      v-for="(item, index2) in document.items"
                      :key="'sub-row-' + index + '-' + index2"
                    >
                      <td>{{ item.typeOfDocument.description }}</td>
                      <td colspan="2" style="text-align: end">
                        R$ {{ item.value }}
                      </td>
                    </tr>
                    <tr
                      :key="`doc2-${index}`"
                      style="
                        border: 0;
                        border-top: 1px solid lightgray;
                        background-color: white;
                      "
                    >
                      <td colspan="3"></td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </table>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import { Money } from "v-money";
import config from "@/config.js";
import Swal from "sweetalert2";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import ModalProcessAppointmentDocument from "@/views/operational/integration/Operational.Integration.DuasRodas.Document.Item.Modal.vue";
import OperationalIntegrationDuasRodasOutras from "@/views/operational/integration/Operational.Integration.DuasRodas.Outras.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "operational-integration-duas-rodas",
  props: [],
  components: {
    Money,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
    "modal-document-item": ModalProcessAppointmentDocument,
    OperationalIntegrationDuasRodasOutras,
    "toggle-button": ToggleButton,
    VueHtml2pdf,
  },

  data() {
    return {
      importDeclaration: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      tab: "",
      initialModel: {},
      model: {},
      modal: {
        show: false,
      },
      shipmentData: {
        integrationLastMessageStatus: "",
        integrationIdentification: "",
        integrationLastReceivedDatetime: "",
        integrationLastReceivedBy: "",
        integrationLastSendedDatetime: "",
        integrationLastSendedBy: "",
      },
      needIntegrationCustomValue: false,
      credential: null,
      shipments: [],
      shipment: null,
      di: null,
      newProcessAppointmentDocument: {
        typeOfDocument: {},
        advance: false,
        service: false,
        providerID: null,
        provider: "",
        documentReference: "",
        description: "",
        observation: "",
        dueDate: null,
        totalValue: 0.0,
        confirmed: false,
        process: null,
        _links: null,
      },
      newProcessAppointmentDocumentItem: {
        typeOfDocument: null,
        value: 0.0,
        document: null,
      },
      documentos: [],
      documentosPC: [],
      documentosAD: [],
      currentItem: null,
      categories: [],
      logs: [],
    };
  },

  filters: {
    formatDate(value) {
      if (
        !value ||
        value === null ||
        value === undefined ||
        value === "00000000"
      )
        return "N/I";
      value = value.toString();

      return `${value.charAt(6) + value.charAt(7)}/${value.charAt(
        4
      )}${value.charAt(5)}/${value.charAt(0)}${value.charAt(1)}${value.charAt(
        2
      )}${value.charAt(3)}`;
    },
    formatLocalDateTime(value) {
      return moment(value, "YYYY-MM-DDTLTS").format("DD/MM/YYYY HH:mm:ss");
    },
  },
  methods: {
    async generateAppointmentPdf() {
      this.$refs.appointmentPdf.generatePdf();
    },
    roundVal(val) {
      return (Math.round(val * 100) / 100);
    },
    getItemsValues(item) {
      return this.roundVal(item.items.map(item => item.value).reduce((val1, val2) => (this.roundVal(val1) + this.roundVal(val2)), 0));
    },
    formatCustomerCnpj(value) {
      return String(value).replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    printAppointmentDocument() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Demonstrativo",
        description: "Gerando demonstrativo da Prestação de Contas...",
      });

      const requestConfiguration = {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.env.API_JSREPORTONLINE.credentials.token,
          responseType: "arraybuffer",
        },
      };

      const requestData = {
        template: {
          shortid: "JNmq~i1",
          recipe: "chrome-pdf",
        },
        data: {
          account: {
            ...this.process.account,
            logo: "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png",
          },
          customer: {
            completeName: this.process.customer.completeName,
            federalID: this.process.customer.federalID,
          },
          process: {
            exporter: this.process.exporter.name,
            wayOfTransport: this.process.wayOfTransport,
            incoterm: this.process.incoterm,
            identification: this.process.identification,
            billOfLading: this.process.billOfLading,
            customerRef: this.process.customerRef,
            anotherRef: this.process.anotherRef,
            invoice: this.process.invoice,
            invoiceDate: this.process.invoiceDate,
            diNumber: this.process.diNumber,
            integrationIdentification: this.process.integrationIdentification,
            vehicle: this.process.vehicle,
          },
          documents: {
            processAppointmentDocuments: {
              ...this.documentos,
            },
          },
        },
      };

      jsreport.serverUrl = config.env.API_JSREPORTONLINE.host;
      jsreport.headers.Authorization =
        config.env.API_JSREPORTONLINE.credentials.token;

      jsreport
        .renderAsync(requestData)
        .then((res) => {
          this.$store.commit("setLoading", {
            show: false,
          }); // open download dialog
          res.download(
            `${this.process.identification} - Demonstrativo Final de Prestação de Contas.pdf`
          );
          res.xhr.getResponseHeader("Content-Type");
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Gerar Demonstrativo",
            `Houve algum erro ao tentar gerar o demonstrativo do processo de importação: <strong>${error.response.data.message}</strong>`,
            "error"
          );
        });
    },
    loadDocumentos() {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.identification}`,
        description: "Buscando dados...",
      });

      this.documentos = [];
      this.documentosPC = [];
      this.documentosAD = [];

      Axios.get(
        `${config.env.API_DEFAULT.host}/newProcessAppointmentDocuments/${this.process.id}`
      )
        .then((response) => {
          const docs = response.data.data;
          this.documentos = [];
          this.documentos = docs;
          this.documentosPC = [];
          this.documentosAD = [];

          if (docs !== undefined && docs !== null && docs.length > 0) {
            for (let i = 0; i < docs.length; i++) {
              var doc = docs[i];

              doc.provider = JSON.parse(doc.provider);
              // var provider = JSON.parse(doc.provider);
              // if(provider !== undefined && this.jsonDataCustomer.PARS[i] !== undefined){
              //     doc.provider = {
              //         "PARID": doc.providerID,
              //         "NAME1": provider.NAME1,
              //         "SHORTNAME": this.jsonDataCustomer.PARS[i].NAME1.substring(0, 10) + "..."
              //     };
              // }

              doc.typeOfDocument = JSON.parse(doc.typeOfDocument);
              // var typeOfDocument = JSON.parse(doc.typeOfDocument);
              // doc.typeOfDocument = {
              //     "id": typeOfDocument.id,
              //     "description": typeOfDocument.description,
              //     "shortname": typeOfDocument.description.substring(0, 20) + "..."
              // };

              if (doc.advance === true) {
                this.documentosAD.push(doc);
              } else {
                this.documentosPC.push(doc);
              }

              var doc = null;
            }
          }

          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          this.documentos = [];

          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });

          Swal.fire(
            "Erro",
            `Houve algum erro ao tentar realizar a operação: ${error}`,
            "error"
          );
        });
    },

    abrirItens(value) {
      this.currentItem = value;
      this.showModal();
    },

    closeModal(type) {
      this.modal.show = false;
    },

    showModal() {
      this.modal.show = true;
    },

    createProcessAppointmentDocument(value) {
      this.submitProcessAppointmentDocument(value, "post");
    },

    updateProcessAppointmentDocument(value) {
      this.submitProcessAppointmentDocument(value, "put");
    },

    async submitProcessAppointmentDocument(value, method) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Documentos",
        description: "Salvando dados, aguarde...",
      });

      value.dueDate = value.dueDate
        ? moment(value.dueDate).format("YYYY-MM-DD[T]hh:mm:ss")
        : "";

      if (typeof value.provider === "object") {
        value.providerID = value.provider.PARID;
        value.provider = JSON.stringify({
          PARID: value.provider.PARID,
          NAME1: value.provider.NAME1,
          SHORTNAME: value.provider.SHORTNAME,
        });

        if (
          value.typeOfDocument !== undefined &&
          value.typeOfDocument !== null
        ) {
          value.observation = value.typeOfDocument.description;
          value.typeOfDocument = JSON.stringify({
            id: value.typeOfDocument.id,
            description: value.typeOfDocument.description,
            shortname: `${value.typeOfDocument.description.substring(
              0,
              20
            )}...`,
          });
        }

        value.description = value.observation;
      }

      try {
        if (method == "put") {
          try {
            const response = await Axios.put(
              `${config.env.API_DEFAULT.host}/newProcessAppointmentDocuments/${value.id}`,
              value
            );

            if (response) this.loadDocumentos();

            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          } catch (e) {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });

            Swal.fire(
              "Erro",
              `Houve algum erro ao tentar realizar a operação: ${err}`,
              "error"
            );
          }
        } else {
          this.$set(
            value,
            "process",
            `${config.env.API_DEFAULT.host}/processes/${this.process.id}`
          );
          const response = await Axios.post(
            `${config.env.API_DEFAULT.host}/newProcessAppointmentDocuments/${this.process.id}`,
            value
          );
          if (response) this.loadDocumentos();
          this.newProcessAppointmentDocument = {
            typeOfDocument: {},
            advance: value.advance,
            service: value.service,
            // advance: false,
            // service: false,
            providerID: null,
            provider: "",
            documentReference: "",
            description: "",
            observation: "",
            dueDate: null,
            totalValue: 0.0,
            confirmed: false,
            process: null,
            _links: null,
          };
        }

        value.provider = JSON.parse(value.provider);

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        Swal.fire(
          "Erro",
          `Houve algum erro ao tentar realizar a operação: ${err}`,
          "error"
        );
      }
    },

    async removeProcessAppointmentDocument(value) {
      try {
        const willContinue = await Swal.fire({
          title: "Remover Documento",
          html: `Você realmente deseja excluir o documento <strong>${value.observation}</strong> e seus itens? Se você excluir, precisará adicioná-lo novamente.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        });

        if (willContinue.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: `Processo ${this.process.id}`,
            description: "Processando requisição, aguarde...",
          });

          const response = await Axios.delete(
            `${config.env.API_DEFAULT.host}/newProcessAppointmentDocuments/${value.id}`
          );

          this.loadDocumentos();
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        }
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", err, "error");
      }
    },

    async queryListOfShipments() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Consultando Lista de Embarques",
          description:
            "Carregando os embarques que estão disponíveis na Duas Rodas para vinculação.",
        });

        const data = {
          acao: "CONSULTA_LISTA",
          ...config.env.API_GESCOMEX_INTEGRATOR.configs.duasrodas,
          filtro: "NOVO",
        };

        delete Axios.defaults.headers.common.Authorization;
        Axios.defaults.headers.common.Authorization =
          config.env.API_GESCOMEX_INTEGRATOR.credentials.token;

        const response = await Axios.post(
          `${config.env.API_GESCOMEX_INTEGRATOR.host}/edx/import/queryListOfShipments`,
          data
        );

        if (response) {
          this.shipments = response.data.data.list;
          this.model.integrationLastReceivedDatetime = this.$moment(
            new Date()
          ).format("DD/MM/YY LTS");
          this.model.integrationLastReceivedBy =
            this.$store.state.credentials.data.person.name;
          this.shipmentData.integrationLastReceivedDatetime =
            this.model.integrationLastReceivedDatetime;
          this.shipmentData.integrationLastReceivedBy =
            this.model.integrationLastReceivedBy;

          const logIntegrationTransation = {
            description: "Listagem de Embarques",
            type: "INTEGRACAO_DUAS_RODAS",
            action: "ACAO_LISTAR_EMBARQUES",
            importProcess: `${config.env.API_GESCOMEX_INTEGRATOR.host}/importProcesses/${this.model.id}`,
          };

          delete Axios.defaults.headers.common.Authorization;
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
          Axios.post(
            `${config.env.API_DEFAULT.host}/logIntegrationTransations/`,
            logIntegrationTransation
          );
        }

        // Axios.post(config.env.API_GESCOMEX_INTEGRATOR.host + "/edx/import/queryListOfShipments", data).then(response => {
        //     this.shipments = response.data.data.list;

        //     this.model.integrationLastReceivedDatetime = this.$moment(new Date()).format("DD/MM/YY LTS");
        //     this.model.integrationLastReceivedBy = this.$store.state.credentials.data.person.name;

        //     this.shipmentData.integrationLastReceivedDatetime = this.model.integrationLastReceivedDatetime
        //     this.shipmentData.integrationLastReceivedBy = this.model.integrationLastReceivedBy;
        // }).then(() => {
        //     this.$store.commit('setLoading', {
        //         show: false,
        //         label: ``,
        //         description: ''
        //     });
        // }).catch(e => {
        //     Swal.fire("Listar Embarques", "Não foi possível Listas os Embarques." + error.response, "error");
        //     Axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.credentials.accessToken;
        //     this.$store.commit('setLoading', {
        //         show: false
        //     });
        // });

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        delete Axios.defaults.headers.common.Authorization;
        Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
      } catch (error) {
        if (error === "Error: Network Error") {
          Swal.fire(
            "Listar Embarques",
            "Não foi possível Listar os Embarques, pois não foi possível estabelecer comunicação com a integração.",
            "error"
          );
        } else {
          Swal.fire(
            "Listar Embarques",
            `Não foi possível Listar os Embarques! <br />${error}`,
            "error"
          );
        }

        Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async newQueryListOfShipments() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Listar embarques",
        description: "Aguarde, estamos buscando as informações",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/integrations/duasRodas/listarEmbarques/${this.model.id}`
        );

        this.shipments = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    queryShipment(manual = false) {
      this.shipment = null;

      if (!manual) {
        if (
          this.jsonDataCustomer !== undefined &&
          this.jsonDataCustomer !== null &&
          this.jsonDataCustomer.MAIN !== undefined &&
          this.jsonDataCustomer.MAIN !== null
        ) {
          this.jsonDataCustomer.MAIN.SBELN;
        }
      }

      this.$store.commit("setLoading", {
        show: true,
        label: "Consulta Detalhes de Embarques",
        description: `Carregando detalhes do embarque ${this.model.integrationIdentification}`,
      });

      const data = {
        acao: "CONSULTA_EMBARQUE",
        ...config.env.API_GESCOMEX_INTEGRATOR.configs.duasrodas,
        sbeln: this.model.integrationIdentification,
      };

      delete Axios.defaults.headers.common.Authorization;
      Axios.defaults.headers.common.Authorization =
        config.env.API_GESCOMEX_INTEGRATOR.credentials.token;

      Axios.post(
        `${config.env.API_GESCOMEX_INTEGRATOR.host}/edx/import/queryShipment`,
        data
      )
        .then((response) => {
          this.shipment = JSON.parse(response.data.data).EDX.RESPONSE;

          if (
            this.shipment.MAIN.SBELN === "" ||
            this.shipment.MAIN.SBELN === undefined ||
            this.shipment.MAIN.SBELN === null
          ) {
            Swal.fire({
              title: "Embarque não encontrado",
              html: `Não foi possível encontrar o embarque ${this.model.integrationIdentification} na Duas Rodas.`,
              type: "error",
            });

            this.model.integrationIdentification = null;
            this.model.integrationLastReceivedDatetime = null;
            this.model.integrationLastReceivedBy = null;
            this.model.integrationLastSendedBy = null;
            this.model.integrationLastSendedDatetime = null;
            this.model.jsonDataCustomer = null;
            this.jsonDataCustomer = null;
            this.shipment = null;

            delete Axios.defaults.headers.common.Authorization;
            Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;

            return false;
          }

          this.model.integrationIdentification = this.shipment.MAIN.SBELN;
          this.model.integrationLastReceivedDatetime = this.$moment(
            new Date()
          ).format("DD/MM/YY LTS");
          this.model.integrationLastReceivedBy =
            this.$store.state.credentials.data.person.name;

          if (
            this.shipment.SHP !== undefined &&
            this.shipment.SHP !== null &&
            this.shipment.SHP.length > 0
          ) {
          } else {
            const shp = this.shipment.SHP;
            delete this.shipment.SHP;
            this.shipment = {
              ...this.shipment,
              SHP: [shp],
            };
          }

          this.model.jsonDataCustomer = JSON.stringify(this.shipment);

          this.shipmentData.integrationLastReceivedDatetime = this.$moment(
            new Date()
          ).format("DD/MM/YY LTS");
          this.shipmentData.integrationLastReceivedBy =
            this.$store.state.credentials.data.person.name;
          this.shipmentData.jsonDataCustomer = this.model.jsonDataCustomer;
          this.shipmentData.integrationIdentification =
            this.shipment.MAIN.SBELN;

          // Datas Previstas
          // this.model.datesETD = this.model.jsonDataCustomer.DAT.ETDPDT;
          // this.model.datesETA = this.model.jsonDataCustomer.DAT.ETPDAT;

          // Datas Confirmadas
          // this.model.diRegistryDate = this.model.jsonDataCustomer.DAT.DIRDAT;
          // this.model.datesArrivalDate = this.model.jsonDataCustomer.DAT.ETADAT;
          // this.model.datesDepartureDate = this.model.jsonDataCustomer.DAT.ETDDAT;
          // this.model.mooringDate = this.model.jsonDataCustomer.DAT.ATRDAT;
          // this.model.datesGoodsLoadingDate = this.model.jsonDataCustomer.DAT.LOADAT;
          // this.model.billingNFsDate = this.model.jsonDataCustomer.DAT.NFGDAT;

          const logIntegrationTransation = {
            description: `Consultou Embarque ${this.shipment.MAIN.SBELN}`,
            type: "INTEGRACAO_DUAS_RODAS",
            action: "ACAO_CONSULTAR_DADOS_EMBARQUE",
            importProcess: `${config.env.API_GESCOMEX_INTEGRATOR.host}/importProcesses/${this.model.id}`,
          };

          delete Axios.defaults.headers.common.Authorization;
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
          Axios.post(
            `${config.env.API_DEFAULT.host}/logIntegrationTransations/`,
            logIntegrationTransation
          );

          this.updateShipmentInProcess();
        })
        .then(() => {
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        });

      delete Axios.defaults.headers.common.Authorization;
      Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
    },
    async newQueryShipment() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Consulta Detalhes de Embarques",
        description: `Carregando detalhes do embarque ${this.model.integrationIdentification}`,
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/integrations/duasRodas/consultarEmbarque/${this.model.id}`
        );

        this.model = response.data.data;

        Swal.fire(
          "Consulta de embarque",
          "Dados do embarque consultados com sucesso!",
          "success"
        );

        this.$store.commit("setLoading", {
          show: false,
        });

        await this.consultLogs();
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire("Consulta de embarque", response.data.message, "error");
      }
    },
    async consultLogs() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Consulta de histórico",
        description: "Carregando logs de integração ",
      });

      try {
        const result = await Axios.get(
          `${config.env.API_DEFAULT.host}/integrations/duasRodas/logs/${this.model.id}`
        );

        this.logs = result.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire("Consulta de histórico", response.data.message, "error");
      }
    },
    updateShipmentInProcess() {
      const { integrationIdentification } = this.model;

      Axios.patch(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.model.id}`,
        this.shipmentData
      )
        .then((response) => {
          if (response) {
            Swal.fire({
              title: "Integração de Processo",
              html: "Os dados do processo da Duas Rodas foram atualizados com sucesso para o Gescomex.",
              type: "success",
            });
            this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
            this.$store.commit("ProcessStore/SET_SINGLE_PROCESS", this.model);
            this.shipment = null;

            const logIntegrationTransation = {
              description: `Atualizou Embarque ${integrationIdentification}`,
              type: "INTEGRACAO_DUAS_RODAS",
              action: "ACAO_ATUALIZAR_EMBARQUE",
              importProcess: `${config.env.API_GESCOMEX_INTEGRATOR.host}/importProcesses/${this.model.id}`,
            };

            delete Axios.defaults.headers.common.Authorization;
            Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
            Axios.post(
              `${config.env.API_DEFAULT.host}/logIntegrationTransations/`,
              logIntegrationTransation
            );
          }
        })
        .catch((error) => {
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
        });

      this.$store.commit("setLoading", {
        show: false,
      });
    },

    closeQueryListOfShipments() {
      this.shipments = [];
    },

    closeShipmentInProcess() {
      this.shipment = null;
      this.shipments = [];
    },

    async removeShipment() {
      try {
        const { integrationIdentification } = this.model;

        const willContinue = await Swal.fire({
          title: "Desvincular Embarque",
          html: `Você realmente deseja desvincular o embarque <strong>${this.model.integrationIdentification}</strong>? Se você desvincular, precisará vinculá-lo novamente.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, desvincular!",
          cancelButtonText: "Cancelar",
        });

        if (willContinue.value) {
          this.model.integrationIdentification = null;
          this.model.integrationLastReceivedDatetime = null;
          this.model.integrationLastReceivedBy = null;
          this.model.integrationLastSendedBy = null;
          this.model.integrationLastSendedDatetime = null;
          this.model.jsonDataCustomer = null;
          this.jsonDataCustomer = null;
          this.shipment = null;

          this.shipmentData.integrationIdentification = null;
          this.shipmentData.integrationLastReceivedDatetime = null;
          this.shipmentData.integrationLastReceivedBy = null;
          this.shipmentData.integrationLastSendedBy = null;
          this.shipmentData.integrationLastSendedDatetime = null;
          this.shipmentData.jsonDataCustomer = null;

          Axios.patch(
            `${config.env.API_DEFAULT.host}/importProcesses/${this.model.id}`,
            this.shipmentData
          )
            .then((response) => {
              this.model.jsonDataCustomer = null;
              this.shipment = null;
              this.shipments = [];
              this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
              this.$store.commit("ProcessStore/SET_SINGLE_PROCESS", this.model);

              const logIntegrationTransation = {
                description: `Desvinculou Embarque ${integrationIdentification}`,
                type: "INTEGRACAO_DUAS_RODAS",
                action: "ACAO_DESVINCULAR_EMBARQUE",
                importProcess: `${config.env.API_GESCOMEX_INTEGRATOR.host}/importProcesses/${this.model.id}`,
              };

              delete Axios.defaults.headers.common.Authorization;
              Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
              Axios.post(
                `${config.env.API_DEFAULT.host}/logIntegrationTransations/`,
                logIntegrationTransation
              );
            })
            .catch((error) => {
              Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
              Swal.fire("Ops", error.response, "error");
            });
          this.$store.commit("setLoading", {
            show: false,
          });
          this.queryShipment();
        }
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
        });
        Swal.fire("Ops", err, "error");
      }
    },

    addShipment(shipment) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Adicionar Embarque",
        description: "Adicionando embarques...",
      });

      this.model.integrationIdentification = shipment.sbeln;
      this.shipmentData.integrationIdentification =
        this.model.integrationIdentification;
      Axios.patch(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.model.id}`,
        this.shipmentData
      )
        .then((response) => {
          this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
          this.$store.commit("ProcessStore/SET_SINGLE_PROCESS", this.model);
          this.shipments = [];
          // this.shipment = null;

          const logIntegrationTransation = {
            description: `Adicionou Embarque ${this.model.integrationIdentification}`,
            type: "INTEGRACAO_DUAS_RODAS",
            action: "ACAO_ADICIONAR_EMBARQUE",
            importProcess: `${config.env.API_GESCOMEX_INTEGRATOR.host}/importProcesses/${this.model.id}`,
          };

          delete Axios.defaults.headers.common.Authorization;
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
          Axios.post(
            `${config.env.API_DEFAULT.host}/logIntegrationTransations/`,
            logIntegrationTransation
          );
        })
        .catch((error) => {
          Axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.credentials.accessToken}`;
        });

      this.$store.commit("setLoading", {
        show: false,
        label: "",
        description: "",
      });

      this.queryShipment();
    },
    async newSendShipment() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Envio de Dados",
        description: "Enviando para a Duas Rodas...",
      });

      try {
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/integrations/duasRodas/enviarEmbarque/${this.model.id}`
        );
        if (response) {
          if (response.data.code === "SUCCESS") {
            Swal.fire(
              "Envio de Dados",
              "Dados enviados com sucesso para a Duas Rodas",
              "success"
            );

            if (response.data.data !== null) {
              this.model.integrationLastSendedDatetime =
                response.data.data.integrationLastSendedDatetime;
              this.model.integrationLastSendedBy =
                response.data.data.integrationLastSendedBy;
              this.model.integrationLastReceivedDatetime =
                response.data.data.integrationLastReceivedDatetime;
              this.model.integrationLastReceivedBy =
                response.data.data.integrationLastReceivedBy;
              this.model.jsonDataCustomer = response.data.data.jsonDataCustomer;
              this.model.integrationIdentification =
                response.data.data.integrationIdentification;
              this.shipment = null;
            }

            await this.consultLogs();
          } else {
            Swal.fire("Envio de Dados", response.data.message, "error");
          }
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (error) {
        console.error(error);

        if (error.response) {
          Swal.fire("Envio de Dados", error.response.data.message, "error");
        } else {
          Swal.fire("Envio de Dados", error.message, "error");
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async newSendAccountProvisionDocument(doc) {
      const willContinue = await Swal.fire({
        title: "Envio de Prestação de Contas",
        html: `Você realmente deseja enviar o documento <strong>${doc.description}Nº ${doc.documentReference}</strong> ? Verifique se realmente é este documento para que a informação não seja enviada em duplicidade para a Duas Rodas.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Cancelar",
      });

      if (willContinue.value) {
        try {
          const response = await Axios.post(
            `${config.env.API_DEFAULT.host}/integrations/duasRodas/enviar-documento-prestacao-de-contas/${doc.id}`
          );

          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire(
            "Envio de Documento de Prestação de Contas",
            "Documento de Prestação de contas enviado com sucesso!",
            "success"
          );

          await this.consultLogs();
        } catch (e) {
          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire("Envio de Prestação de Contas", e, "error");
        }
      }
    },
    async newSendAccountProvision() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Prestação de Contas",
        description: "Verificando dados cadastrados...",
      });

      try {
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/integrations/duasRodas/enviar-prestacao-de-contas/${this.model.id}`
        );

        if (response.data.data.length > 0) {
          for (let i = 0; i < response.data.data.length; i++) {
            const doc = response.data.data[i];

            this.$toasted.show(
              `Prestação de Contas do Documento Nº ${doc} foi enviada com sucesso.`,
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 6000,
              }
            );
          }
        }

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire(
          "Envio de Prestação de Contas",
          "Prestação de contas enviada com sucesso!",
          "success"
        );

        await this.consultLogs();
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire(
          "Envio de Prestação de Contas",
          `Erro inesperado ao enviar prestação de contas: ${e}`,
          "error"
        );
      }
    },
    convertKeysToLowerCase(obj) {
      const json = JSON.stringify(obj);
      const newJson = json.replace(
        /"([\w]+)":/g,
        ($0, $1) => `"${$1.toLowerCase()}":`
      );

      return JSON.parse(newJson);
    },

    async checkIfThereIsDI() {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarDIByIdentification",
        this.model.identification
      );
      if (response) {
        this.di = response.data;
      }
    },
    async diffObjects(fieldName) {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
        fieldName,
      });

      if (response) {
        this.initialModel = {
          ...this.model,
        };
      }
    },
    checkNeedIntegrationCustomValue() {
      const integrationCustomValueIsEmpty =
        this.model.integrationCustomsValue === null ||
        this.model.integrationCustomsValue === "" ||
        this.model.integrationCustomsValue === 0;

      if (
        this.model.billingNFsDate !== null &&
        this.model.billingNFsDate !== "" &&
        this.process.customer &&
        this.process.customer.id == 100 &&
        integrationCustomValueIsEmpty &&
        this.process.wayOfTransport === "MARITIMA"
      ) {
        this.needIntegrationCustomValue = true;
      }
    },
  },
  computed: {
    isAddictionWithLI() {
      const li =
        this.process.importDeclaration !== null
          ? this.process.importDeclaration.additions.filter(
              (a) => a.liNumber !== null && a.liNumber !== "0000000000"
            )
          : [];
      return li.length > 0;
    },
    itensTemSubitem() {
      let quantidade = 0;
      if (this.jsonDataCustomer !== undefined && this.jsonDataCustomer) {
        this.jsonDataCustomer.SHP.forEach((item) => {
          if (item.NBELP !== "00000") {
            quantidade += 1;
          }
        });
      }

      return quantidade;
    },
    itensQuantidadeTotal() {
      if (this.jsonDataCustomer !== undefined && this.jsonDataCustomer) {
        return this.jsonDataCustomer.SHP.reduce(
          (value, item) => new Number(value) + new Number(item.MENGEE),
          0
        );
      }
      return 0;
    },
    itensSomarValorTotal() {
      if (this.jsonDataCustomer !== undefined && this.jsonDataCustomer) {
        return this.jsonDataCustomer.SHP.reduce(
          (value, item) => new Number(value) + new Number(item.NETWR),
          0
        );
      }
      return 0;
    },
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      movimentTermsCATEGORY: (state) =>
        state.FinancesStore.movimentTermsCATEGORY,
    }),
    jsonDataCustomer() {
      if (
        this.model.jsonDataCustomer !== null &&
        this.model.jsonDataCustomer !== undefined
      ) {
        return JSON.parse(this.model.jsonDataCustomer);
      }
      return null;
    },
    valolTotalAdiantamento() {
      return this.documentosAD.reduce((value, item) => {
        if (item.advance)
          return new Number(value) + new Number(item.totalValue);
        return value + 0;
      }, 0);
    },
    valolTotalPrestacaoContas() {
      return this.documentosPC.reduce((value, item) => {
        if (!item.advance)
          return new Number(value) + new Number(item.totalValue);
        return value + 0;
      }, 0);
    },
    totalItensAdiantamento() {
      return this.documentosAD.length;
    },
    totalItensPrestacaoContas() {
      return this.documentosPC.length;
    },
    listOfPars() {
      if (
        this.jsonDataCustomer !== undefined &&
        this.jsonDataCustomer !== null &&
        this.jsonDataCustomer.PARS !== null &&
        this.jsonDataCustomer.PARS !== undefined &&
        this.jsonDataCustomer.PARS.length > 0
      ) {
        const pars = [];
        if (this.jsonDataCustomer.PARS.length > 0) {
          for (let i = 0; i < this.jsonDataCustomer.PARS.length; i++) {
            const data = {
              ...this.jsonDataCustomer.PARS[i],
              SHORTNAME: `${this.jsonDataCustomer.PARS[i].NAME1.substring(
                0,
                10
              )}...`,
              NAME1:
                (this.jsonDataCustomer.PARS[i].STCD1
                  ? `${this.formatCustomerCnpj(
                      this.jsonDataCustomer.PARS[i].STCD1
                    )} - `
                  : "") + this.jsonDataCustomer.PARS[i].NAME1,
            };

            pars.push(data);
          }
        }
        return pars;
      }
      return undefined;
    },
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          // if (e.path[0] !== undefined) {
          //     fieldName = e.path[0].name;
          // }

          if (e.srcElement.attributes[1] !== undefined) {
            fieldName = e.srcElement.attributes[1].nodeValue.toString();
          } else if (e.path !== undefined && e.srcElement !== undefined) {
            fieldName = e.path[0].name;
          }

          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  async mounted() {
    this.checkNeedIntegrationCustomValue();

    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById(
          "operational-integration-duas-rodas-form"
        );
        form.addEventListener(...this.options);
      }, 200);
    }

    this.categories = this.movimentTermsCATEGORY;

    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };

    this.shipments = [];

    this.loadDocumentos();
    await this.consultLogs();

    Axios.get(
      `${config.env.API_DEFAULT.host}/importDeclaration/getImportDeclarationById/${this.process.importDeclaration.id}`
    ).then((result) => {
      this.importDeclaration = result.data.data;
    });
  },
  watch: {
    movimentTermsCATEGORY() {
      this.categories = this.movimentTermsCATEGORY;
    },
    process() {
      this.initialModel = {
        ...this.process,
      };
      this.model = {
        ...this.process,
      };
    },
  },
  created() {
    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };
  },
};
</script>

<style scoped>
.table th {
  padding: 0.3rem;
  border: 1px solid #dee2e6;
}

#printAppointmentDocument {
  background: #ffffff;
}

/** CSS PDF */
.report-slim-pagetitle {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #343a40;
  padding-left: 10px;
  border-left: 4px solid #4662d4;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #343a40;
  padding: 5px;
}

.report-box {
  max-width: 800px;
  padding: 20px;
  margin: 20px;
  font-size: 12px;
  line-height: 15px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #343a40;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.report-header {
  font-size: 12px;
}

.report-header-left {
  text-align: center;
  border-left: 6px solid #4662d4;
}

.report-header-middle {
  padding-left: 20px;
  text-align: left !important;
  line-height: 20px;
}

.report-header-right {
  vertical-align: middle;
  line-height: 10px;
}

.report-header-right small {
  font-size: 10px;
}

.report-info-row td {
  border-bottom: 1px dashed #dee2e6;
}

.report-info-row td.label {
  text-align: left;
  font-weight: bold;
}

.report-info-row td.value {
  text-align: left;
}

.report-finance {
  font-size: 11px;
}

.report-finance thead tr th {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.report-finance tbody tr td,
.report-finance thead tr th {
  border-bottom: 1px dashed #dee2e6;
}

.report-finance-sub {
  margin-bottom: 10px !important;
}

.report-finance-sub td,
.report-finance-sub th {
  border-bottom: none !important;
}

.report-tx-left {
  text-align: left !important;
}

.report-tx-right {
  text-align: right !important;
}

.report-tx-center {
  text-align: center !important;
}

.report-tx-bold {
  font-weight: bold;
}

.report-wd-100p {
  width: 100%;
}

.report-wd-80p {
  width: 80%;
}

.report-mg-0 {
  margin: 0px !important;
}

.pdf-report-data {
  color: #3b4248;
}

/** NEW REPORT CSS */

.new-report-table {
  width: 100%;
}

.new-report-table thead {
  background-color: #eff2f6;
  border: 1px solid lightgray;
}

.new-report-table th {
  padding: 7px;
}

.new-report-table tbody tr {
  border: 1px solid lightgray;
  border-bottom: 0px;
}

.new-report-table tbody tr:first-child {
  border-top: 0;
}

.new-report-table tbody tr:last-child {
  border-bottom: 1px solid lightgrey;
}

.new-report-table td {
  padding: 6px;
}

.new-report-table .sub-row {
  background-color: #f7f7f7;
}
</style>
