<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1737_742)">
      <path
          d="M4.08333 9.91667C4.88875 9.91667 5.54167 9.26375 5.54167 8.45833C5.54167 7.65292 4.88875 7 4.08333 7C3.27792 7 2.625 7.65292 2.625 8.45833C2.625 9.26375 3.27792 9.91667 4.08333 9.91667Z"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M9.91665 9.91667C10.7221 9.91667 11.375 9.26375 11.375 8.45833C11.375 7.65292 10.7221 7 9.91665 7C9.11123 7 8.45831 7.65292 8.45831 8.45833C8.45831 9.26375 9.11123 9.91667 9.91665 9.91667Z"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M7.00002 4.08329C7.80544 4.08329 8.45835 3.43037 8.45835 2.62496C8.45835 1.81954 7.80544 1.16663 7.00002 1.16663C6.19461 1.16663 5.54169 1.81954 5.54169 2.62496C5.54169 3.43037 6.19461 4.08329 7.00002 4.08329Z"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M7.00002 12.8333C7.00002 11.2225 5.69417 9.91663 4.08335 9.91663C2.47252 9.91663 1.16669 11.2225 1.16669 12.8333"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.8333 12.8333C12.8333 11.2225 11.5275 9.91663 9.91667 9.91663C8.30585 9.91663 7 11.2225 7 12.8333"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M9.91665 7.00004C9.91665 5.38922 8.6108 4.08337 6.99998 4.08337C5.38916 4.08337 4.08331 5.38922 4.08331 7.00004"
          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1737_742">
        <rect :width="size" :height="size" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ListContact',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
