import { render, staticRenderFns } from "./ControlPanelHeadline.vue?vue&type=template&id=4263277a&scoped=true"
import script from "./ControlPanelHeadline.vue?vue&type=script&lang=js"
export * from "./ControlPanelHeadline.vue?vue&type=script&lang=js"
import style0 from "./ControlPanelHeadline.vue?vue&type=style&index=0&id=4263277a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4263277a",
  null
  
)

export default component.exports