<template>
  <div class="config-container">
    <config-sidebar :current="currentTab" @changeTab="(tab) => currentTab = tab"/>

    <config-main :current="currentTab"/>
  </div>
</template>

<script>
import ConfigSidebar from '@/components/Configuration/ConfigSidebar.vue';
import ConfigMain from '@/components/Configuration/ConfigMain.vue';

export default {
  name: 'SystemConfiguration',
  components: {
    ConfigMain,
    ConfigSidebar,
  },
  data() {
    return {
      currentTab: '',
    };
  },
};
</script>

<style scoped>
.config-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: white;
  height: calc(100vh - 105px);
  overflow: hidden;
}
</style>
