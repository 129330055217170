<template>
  <div class="container">
    <div class="dash-headline-two mt-4">
      <div class="d-flex align-items-center">
        <h2 class="tx-inverse mg-b-0">{{ this.$route.meta.pageTitle }}</h2>
        <p class="mg-b-0 mg-l-20">
          Operacional / Documentos Originais /
          <strong> {{ this.$route.meta.pageTitle }} </strong>
        </p>
      </div>

      <div v-if="this.$route.meta.showUploadButton">
        <button
          @click.prevent="modalOpen = true"
          class="btn btn-primary pd-l-30 pd-r-30"
        >
          ENVIAR DOCUMENTO
        </button>
      </div>
    </div>

    <router-view />

    <fab-modal
      :handler="modalOpen"
      title="Enviar Documentos Originais"
      description=""
      @requestClose="closeModal"
    >
      <template #content>
        <div class="columns-12" style="align-content: start">
          <div class="span-12" v-if="!uploadComplete">
            <v-drag-and-drop-files min-height="120px" @input="handleFiles" />
          </div>

          <div class="span-12">
            <h4 v-if="files.length > 0" class="text-dark mb-2">
              Documentos Selecionados
            </h4>

            <div class="alert alert-info" v-if="uploading">
              Carregando documentos...
            </div>

            <ul class="file-list">
              <li
                v-for="(file, index) in files"
                :key="index"
                class="columns-12 ai-c"
                :class="{
                  complete: file.uploadComplete,
                }"
              >
                <div class="span-6">
                  {{ file.name }}
                </div>

                <div class="span-4">
                  <select
                    :disabled="file.uploadComplete"
                    class="form-control"
                    v-model="file.documentType"
                    name="document_type"
                    id="document_type"
                  >
                    <option value="undefined" disabled>
                      Selecione uma opção
                    </option>
                    <option value="INVOICE">Invoice</option>
                    <option value="PURCHASE_ORDER">Purchase Order</option>
                  </select>
                </div>

                <div class="span-2 flex jc-fe" v-if="!file.uploadComplete">
                  <VTableButton
                    color="danger"
                    @click.native="removeFile(index)"
                  >
                    <template #icon>
                      <reject-icon />
                    </template>
                  </VTableButton>
                </div>

                <div class="span-2 flex jc-fe" v-else>
                  <VTableButton color="success">
                    <template #icon>
                      <check-square-icon />
                    </template>
                  </VTableButton>
                </div>

                <div
                  class="loading"
                  :style="`--loading: ${file.progress ? file.progress : 0}%`"
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex jc-c fgap2" v-if="files.length > 0">
          <button
            class="btn btn-primary"
            @click.prevent="uploadFiles"
            v-if="files.length > 0 && !uploadComplete & !uploading"
          >
            Enviar Documentos Originais
          </button>

          <button
            class="btn btn-danger"
            @click.prevent="
              () => {
                files = [];
                uploadComplete = false;
              }
            "
            v-if="files.length > 0 && !uploading"
          >
            Limpar documentos enviados
          </button>
        </div>

        <div v-if="!uploading && files.length === 0">
          <p class="text-center mb-0">Escolha arquivos para poder enviar</p>
        </div>
      </template>
    </fab-modal>
  </div>
</template>

<script>
import FabModal from "@/components/VModal.vue";
import VDragAndDropFiles from "@/components/VDragAndDropFiles.vue";
import VTableButton from "@/components/VTableButton.vue";
import RejectIcon from "@/components/icons/RejectIcon.vue";

import Swal from "sweetalert2";
import Axios from "axios";
import config from "@/config";
import CheckSquareIcon from "@/components/icons/CheckSquareIcon.vue";

export default {
  name: "OcrWrapper",
  components: {
    CheckSquareIcon,
    RejectIcon,
    VTableButton,
    VDragAndDropFiles,
    FabModal,
  },
  data() {
    return {
      modalOpen: false,
      files: [],
      uploadComplete: false,
      uploading: false,
    };
  },
  methods: {
    // Handle Files
    handleFiles(files) {
      this.files = [...this.files, ...files];
    },
    // Handle Close Modal
    closeModal() {
      this.uploading = false;
      this.uploadComplete = false;
      this.modalOpen = false;
      this.files = [];
    },
    // Handle upload files
    async uploadFiles() {
      try {
        const validated = await this.validateDocuments();

        if (validated) {
          this.uploading = true;

          for (let i = 0; i < this.files.length; i++) {
            const uploadFile = this.files[i];

            const data = new FormData();
            data.append("file", uploadFile);
            data.append("type", uploadFile.documentType);

            await Axios.post(
              `${config.env.API_DEFAULT.host}/original-documents/upload`,
              data
            );

            uploadFile.progress = 100;
            uploadFile.uploadComplete = true;
          }

          this.uploading = false;
          this.uploadComplete = true;

          Swal.fire({
            title: "Envio de documentos originais",
            html: "<p>Documentos enviados com sucesso!</p>",
            type: "success",
          });
        }
      } catch (e) {
        this.uploading = false;
        this.uploadComplete = true;
        Swal.fire({
          title: "Envio de documentos originais",
          html: `<p>${e.response.message}</p>`,
          type: "error",
        });
      }
    },
    // Validate Sent Documents
    validateDocuments() {
      return new Promise((resolve) => {
        let validated = false;

        for (let i = 0; i < this.files.length; i++) {
          const uploadFile = this.files[i];

          if (uploadFile.type !== "application/pdf") {
            Swal.fire({
              title: "Documento inválido",
              html: "<p>Apenas arquivos em formato PDF são permitidos</p>",
              type: "error",
            });
            resolve(validated);
          }

          if (
            !["INVOICE", "PURCHASE_ORDER"].includes(uploadFile.documentType)
          ) {
            Swal.fire({
              title: "Documento inválido",
              html: "<p>Verifique o tipo de documento dos arquivos enviados.</p>",
              type: "error",
            });
            resolve(validated);
          }

          validated = true;
        }

        resolve(validated);
      });
    },
    // Handle Remove Files
    removeFile(ind) {
      this.files.splice(ind, 1);
    },
  },
};
</script>

<style scoped>
.file-list {
  padding: 0;
  margin: 0;
}

.file-list li {
  background-color: #f5f6f7;
  padding-left: 1rem;
  border-radius: 6px;
  transition: all 0.2s ease-in-out 0s;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
}

.file-list li .loading {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  width: var(--loading);
  background-color: #0a7900;
}

.loading-icon {
  animation: loading-ring 1.2s linear infinite;
}

.file-list li .form-control {
  background-color: transparent;
  border: 0;
}

.file-list li:hover {
  border: 1px solid #c0c0c0;
}

.file-list li.complete {
  background-color: #daffd6;
}

.file-list li.complete:hover {
  border: 1px solid #b8ffb3;
}

.file-list li + li {
  margin-top: 1rem;
}

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
