<template>
  <div class="contact-form">
    <div
      class="sp-3 light-bg p-3 bd-b"
      v-if="this.hasPrivilege('CRM_CONTATOS_EMPRESAS_VINCULAR')"
    >
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">EMPRESAS HABILITADAS</h4>
          <p class="mb-0">
            <strong>{{ companies.length }}</strong> empresas que
            <strong>{{ contactDetails.name }}</strong> está vinculado
          </p>
        </div>
        <div>
          <button
            type="button"
            @click="addDialog = !addDialog"
            class="plus-button"
          >
            <plus-icon color="#ffffff" />
          </button>
        </div>
      </div>

      <transition name="activity" mode="out-in">
        <div class="contact-form mt-4 mb-2" v-if="addDialog">
          <div class="sp-2 select-wrapper-int">
            <v-select
              v-model="selectedCustomer"
              placeholder="Selecione uma opção"
              label="name"
              :options="filteredCustomers"
              name="customer"
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <small>({{ formatCustomerCnpj(option.federalID) }})</small>
              </template>
            </v-select>
          </div>

          <div class="flex jc-fe ai-fs">
            <button class="activity-submit" @click.prevent="linkCompany">
              Vincular Empresa
            </button>
          </div>
        </div>
      </transition>
    </div>

    <div class="card-body pd-40 sp-3 text-center" v-if="companies.length == 0">
      <!-- <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt=""> -->
      <h6 class="tx-md-20 tx-inverse">Nenhuma Empresa Vinculada</h6>
      <p>Vincule uma empresa clicando no botão + que está acima</p>
    </div>

    <div class="sp-3 table-overflow pd-x-10" v-else>
      <table>
        <tbody>
          <template v-for="(item, index) in filteredCompanies">
            <tr v-for="contact in item" :key="index + '-' + contact.id">
              <td>
                <div class="company-item flex ai-c fgap2">
                  <div class="company-letter flex ai-c jc-c">
                    {{ contact.name.split("")[0].toUpperCase() }}
                  </div>
                  <div class="company-info">
                    <h5
                      class="mb-0 cursor-pointer"
                      @click.prevent="
                        $router.push('/crm/clientes/' + contact.id)
                      "
                    >
                      {{ contact.name }}
                      <span class="tx-10">({{ contact.completeName }})</span>
                    </h5>
                    <span class="mb-0" v-if="contact.email1">{{
                      contact.email1
                    }}</span>
                    <span class="tx-12 d-block" v-if="contact.enabled"
                      ><span
                        class="square-8 bg-success mg-r-5 rounded-circle"
                      ></span>
                      Empresa Habilitada</span
                    >
                    <span class="tx-12 d-block" v-else
                      ><span
                        class="square-8 bg-danger mg-r-5 rounded-circle"
                      ></span>
                      Empresa Desabilitada</span
                    >
                  </div>
                  <div class="company-type flex ai-c fgap2"></div>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="tx-black"
                  @click.prevent="copyCnpj(contact.federalID)"
                >
                  {{ contact.federalID }}
                  <copy-icon :size="16" />
                </a>
              </td>
              <td>
                <div class="actions">
                  <a href="#" @click.prevent="toggleActions(contact.id)">
                    <dots-icon :size="24" />
                  </a>

                  <transition name="fade" mode="out-in">
                    <div
                      class="actions-submenu wd-200"
                      v-if="actionsSubmenu == contact.id"
                    >
                      <ul>
                        <li>
                          <a href="#" @click.prevent="removeCompany(contact.id)"
                            >Desvincular Empresa</a
                          >
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";

import { CopyIcon, DotsIcon, PlusIcon } from "@/components/crm/Icons";
import moment from "moment";
import Swal from "sweetalert2";

import Axios from "axios";
import CredentialService from "@/services/CredentialService.js";

export default {
  name: "ContactCompanies",
  components: {
    CopyIcon,
    DotsIcon,
    PlusIcon,
  },
  props: {
    contactDetails: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await this.getCompanies();
    await this.getLinkedCompanies();
  },
  data() {
    return {
      search: "",
      addDialog: false,
      customers: [],
      selectedCustomer: null,
      companies: [],
      actionsSubmenu: null,
    };
  },
  computed: {
    filteredCustomers() {
      const customers = this.customers.filter((customer) => {
        if (!this.companies.find((company) => company.id === customer.id)) {
          return customer;
        }
      });
      return customers;
    },
    filteredCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Carregando empresas vinculadas.",
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let orderedCompanies = this.companies.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (this.search !== "") {
        orderedCompanies = orderedCompanies.filter((com) =>
          this.searchObject(com, this.search)
        );
      }

      const grouped = orderedCompanies.reduce((rv, x) => {
        (rv[x.name.split("")[0]] = rv[x.name.split("")[0]] || []).push(x);
        return rv;
      }, {});

      this.$store.commit("setLoading", {
        show: false,
      });

      return grouped;
    },
    companyNotFind() {
      return !(Object.keys(this.filteredCompanies).length > 0);
    },
  },
  methods: {
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    toggleActions(companyId) {
      if (this.actionsSubmenu === companyId) {
        this.actionsSubmenu = null;
      } else {
        this.actionsSubmenu = companyId;
      }
    },
    formatCustomerCnpj(value) {
      return String(value).replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    async getLinkedCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Carregando empresas vinculadas.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactData/get-companies-crm/${this.contactDetails.id}`
        );
        this.companies = response.data.data;
        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (error) {
        Swal.fire({
          title: "Listar Empresas Vinculadas",
          html: `<p>Não foi possível carregar as empresas vinculadas</p> <div>${error.response.data.message}</div>`,
          type: "error",
        });
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async removeCompany(companyId) {
      Swal.fire({
        title: "Desvincular Empresa",
        html: "<p>Tem certeza que deseja desvincular a empresa deste contato?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Desvincular",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Desvinculado o contato da empresa.",
          });

          try {
            await Axios.get(
              `${config.env.API_DEFAULT.host}/company/removeContact/${companyId}/${this.contactDetails.id}`
            );

            this.companies = this.companies.filter(
              (comp) => comp.id !== companyId
            );
          } catch (error) {
            Swal.fire({
              title: "Desvincular Empresa",
              html: "<p>Não foi possível desvincular a empresa deste contato:</p>",
              type: "error",
            });
            console.error(error);
          }

          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire({
            title: "Desvincular Empresa",
            html: "<p>Empresa desvinculada com sucesso!</p>",
            type: "success",
          });
        }
      });
    },
    async getCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Carregando empresas.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllCustomers`
        );
        // const response = await Axios.get(config.env.API_DEFAULT.host + `/contactData/get-companies-crm/${this.contactDetails.id}`);
        this.customers = response.data.data;
        this.$store.commit("setLoading", { show: false });
      } catch (error) {
        this.$store.commit("setLoading", { show: false });
        Swal.fire({
          title: "Listar Empresas",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
    async linkCompany() {
      if (!this.selectedCustomer) {
        Swal.fire({
          title: "Vincular Empresa",
          html: "<p>Selecione uma empresa anter de fazer o vínculo.</p>",
          type: "error",
        });
        return;
      }

      Swal.fire({
        title: "Vincular Empresa",
        html: "<p>Tem certeza que deseja vincular a empresa?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Vincular",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (response) => {
        if (response.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Vinculando empresa.",
          });

          try {
            const response = await Axios.get(
              `${config.env.API_DEFAULT.host}/company/vinculateCompanyToContact/${this.selectedCustomer.id}/${this.contactDetails.id}`
            );
            this.$store.commit("setLoading", { show: false });
            Swal.fire({
              title: "Vincular Empresa",
              html: "<p>A empresa foi vinculada com sucesso!</p>",
              type: "success",
            });
          } catch (error) {
            this.$store.commit("setLoading", { show: false });
            Swal.fire({
              title: "Vincular Empresa",
              html: `Não foi possível vincular a empresa: <div>${error.response.data.message}</div>`,
              type: "error",
            });
          }

          await this.getLinkedCompanies();
          this.selectedCustomer = null;
        }
      });
    },
    searchObject(object, terms) {
      let shouldReturn = false;
      // eslint-disable-next-line
      for (const key in object) {
        const value = object[key];

        if (!shouldReturn && typeof value === "string") {
          shouldReturn = value.toLowerCase().search(terms.toLowerCase()) > -1;
        } else if (!shouldReturn && typeof value === "object") {
          shouldReturn = this.searchObject(value, terms);
        } else if (!shouldReturn && typeof value === "number") {
          shouldReturn =
            value
              .toString()
              .toLowerCase()
              .search(terms.toString().toLowerCase()) > -1;
        }
      }
      return shouldReturn;
    },
    copyCnpj(value) {
      navigator.clipboard.writeText(value);
      this.$toasted.success("CNPJ Copiado para a área de transferência!", {
        position: "top-right",
        duration: 3000,
        icon: "copy",
        singleton: true,
      });
    },
  },
  filters: {
    dateFormat(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td {
  padding: 20px 10px;
  color: #343a40;
}

.input-alert {
  background-color: #a80005;
  border-radius: 6px;
  color: white;
  padding: 8px 20px;
}

.input-alert a {
  font-weight: bold;
  color: white;
}

.letter-item {
  font-size: 1.1rem;
  font-weight: bold;
  color: #868ba1;
}

.letter-line {
  display: block;
  max-width: 200px;
  flex: 0 1 200px;
  width: 100%;
  height: 1px;
  background-color: #868ba1;
}

.company-item .company-letter {
  width: 36px;
  height: 36px;
  border-radius: 64px;
  background-color: #80b7e2;
  color: white;
}

.company-item h5 {
  font-weight: bold;
}

.company-item a {
  color: inherit;
}

.company-type .tag {
  display: block;
  padding: 10px 20px;
  background-color: green;
  color: white;
  border-radius: 100px;
  font-weight: 300;
}

.actions {
  all: unset;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.add-submit:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

.actions {
  position: relative;
}

.actions-submenu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.actions-submenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.actions-submenu ul a {
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;
}

.actions-submenu ul a:hover {
  background-color: #f5f6f7;
}
</style>

<style>
.select-wrapper-int {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding-left: 15px;
}

.select-wrapper-int .dropdown-toggle {
  padding-left: 10px !important;
}

.select-wrapper-int input {
  height: 40px !important;
  margin-top: 0 !important;
}
</style>
