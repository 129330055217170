<template>
  <div>
    <form @submit.prevent="saveActivity" class="contact-form">
      <div class="sp-3 light-bg p-3 bd-b">
        <div :class="['flex ai-c jc-sb fw-w fgap2']">
          <div>
            <h4 class="mb-0">ATIVIDADES</h4>
            <p class="mb-0">
              {{ customerActivites.length }} atividades cadastradas
            </p>
          </div>
          <div class="row">
            <v-modal
              :title="'Relatório de Atividades'"
              :description="'Filtre por data ou tipo para gerar o relatório'"
              :width="600"
            >
              <template #activator="{ on }" v-if="customerActivites.length > 0">
                <button
                  type="button"
                  class="report-button mr-2"
                  @click.prevent="on"
                >
                  <i class="fas fa-file-pdf mr-1"></i> Gerar Relatório
                </button>
              </template>
              <template #content>
                <div class="content-form">
                  <div class="col-12">
                    <select-wrapper
                      :multiple="true"
                      v-model="formFilterReport.types"
                      :options="activityTypes"
                      label="Tipo"
                    ></select-wrapper>
                  </div>
                  <div class="col-12">
                    <div class="row mt-3">
                      <div class="col-6">
                        <v-input
                          name="start_date"
                          type="date"
                          label="Data Inicial"
                          v-model="formFilterReport.start_date"
                        ></v-input>
                      </div>
                      <div class="col-6">
                        <v-input
                          name="end_date"
                          type="date"
                          label="Data Final"
                          v-model="formFilterReport.end_date"
                        ></v-input>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer="{ closeModal }">
                <div class="d-flex align-items-center justify-content-end">
                  <button
                    class="btn btn-primary rounded"
                    @click.prevent="generateReport(closeModal)"
                  >
                    Gerar Relatório
                  </button>
                </div>
              </template>
            </v-modal>
            <button
              type="button"
              @click="addDialog = !addDialog"
              class="plus-button mr-2"
            >
              <plus-icon color="#ffffff" />
            </button>
          </div>
        </div>

        <transition name="activity" mode="out-in">
          <div class="contact-form mt-4 mb-2" v-if="addDialog">
            <div>
              <v-input
                name="assunto"
                label="Assunto"
                v-model="formData.subject"
                hint="Assunto abordado na atividade"
              ></v-input>
              <div class="field-error">
                {{
                  fieldErrors.find((err) => err.path == "subject")
                    ? fieldErrors.find((err) => err.path == "subject").message
                    : ""
                }}
              </div>
            </div>

            <div>
              <select-wrapper
                v-model="formData.typeId"
                :options="activityTypes"
                label="Tipo"
              ></select-wrapper>
              <div class="field-error">
                {{
                  fieldErrors.find((err) => err.path == "typeId")
                    ? fieldErrors.find((err) => err.path == "typeId").message
                    : ""
                }}
              </div>
            </div>

            <div>
              <v-input
                name="data"
                label="Data"
                type="date"
                v-model="formData.dateOfActivity"
                hint="Data em que a atividade foi realizada"
              ></v-input>
              <div class="field-error">
                {{
                  fieldErrors.find((err) => err.path == "dateOfActivity")
                    ? fieldErrors.find((err) => err.path == "dateOfActivity")
                        .message
                    : ""
                }}
              </div>
            </div>

            <div class="sp-3">
              <v-textarea
                name="description"
                label="Descrição"
                v-model="formData.description"
                :editor="true"
              ></v-textarea>
              <div class="field-error">
                {{
                  fieldErrors.find((err) => err.path == "description")
                    ? fieldErrors.find((err) => err.path == "description")
                        .message
                    : ""
                }}
              </div>
              <div>
                {{ 5000 - Number(formData.description.length) }} caracteres
                restantes.
              </div>
            </div>

            <v-input-file-drag class="sp-3" v-model="documents" ref="upload">
            </v-input-file-drag>

            <div class="sp-3 flex jc-fe">
              <button class="activity-submit">Adicionar Atividade</button>
            </div>
          </div>
        </transition>
      </div>

      <div
        class="card-body pd-40 sp-3 text-center"
        v-if="customerActivites.length == 0"
      >
        <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
        <h6 class="tx-md-20 tx-inverse">Nenhuma Atividade Cadastrada</h6>
        <p>Adicione uma atividade clicando no botão + que está acima</p>
      </div>

      <div class="contact-form ph-2 sp-3" v-else>
        <customer-activity-card
          v-for="activity in customerActivites"
          :key="activity.id"
          class="sp-3"
          :data="{
            id: activity.id,
            title: activity.subject,
            author: activity.createdBy,
            description: activity.description,
            documents: activity.documents,
            date: activity.dateOfActivity,
            type: activity.type,
            documents: activity.documents,
            userWhoCreated: activity.userWhoCreated,
          }"
          @remove="cardActionDelete"
        ></customer-activity-card>
      </div>
    </form>
    <div class="col-md-12" v-if="customerActivites.length > 0">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :preview-modal="true"
        :enable-download="false"
        :paginate-elements-by-height="1400"
        :filename="'Relatório_De_Atividades'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="p-2 pdf-content">
            <div class="col-md-12">
              <div class="row justify-content-between align-items-center">
                <div class="col-md-6">
                  <div class="prime-identification p-2">
                    <p class="mb-2">
                      <strong
                        >Prime Internacional Comércio Exterior LTDA</strong
                      >
                    </p>
                    <p class="mb-2">CNPJ: 08214494000119</p>
                    <p class="mb-2">
                      Inscrição Municipal: 285459-0 / IE: 255.232.322
                    </p>
                    <p class="mb-0">Matriz: Itajaí / SC</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="float-right">
                    <img
                      src="@/assets/img/logo_prime.png"
                      width="180"
                      class="mt-3"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <span style="font-size: 25px"
                  ><strong>Relatório de Atividades</strong></span
                >

                <div class="report-divider"></div>

                <div style="width: 100%">
                  <div class="row">
                    <div class="col-md-12 d-flex">
                      <div
                        style="
                          width: 460px;
                          padding: 0 10px;
                          overflow: hidden;
                          margin-right: 10px;
                        "
                        v-if="reportChart.length > 1"
                      >
                        <apache-chart
                          v-if="chartDataIsFilled"
                          :height="300"
                          :graph-data="reportChart"
                          :animation="false"
                          :colors="[
                            '#92b9e2',
                            '#456f99',
                            '#12477d',
                            '#dcb058',
                            '#456f99',
                            '#12477d',
                          ]"
                        />
                      </div>
                      <div
                        :style="{
                          width: reportChart.length > 1 ? '340px' : '100%',
                          padding:
                            reportChart.length > 1 ? '50px 10px 0' : '20px 0 0',
                        }"
                        class="text-left"
                      >
                        <h5>Percentual de atividades por tipo</h5>
                        <p>
                          O maior número de atividades do Cliente
                          <strong>{{ customerDetails.completeName }}</strong> é
                          referente á
                          <strong>{{
                            reportChart.length > 0 && bestTypeReport !== null
                              ? bestTypeReport.name
                              : "-"
                          }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <h4>Listagem das atividades</h4>

                <div class="report-divider"></div>

                <table class="table table-striped mt-4">
                  <thead class="bg-secondary">
                    <tr>
                      <th>Assunto</th>
                      <th class="text-center">Tipo</th>
                      <th class="text-center">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="activity in reportActivities" :key="activity.id">
                      <td>{{ activity.subject }}</td>
                      <td class="text-center">{{ activity.type.name }}</td>
                      <td class="text-center">
                        {{
                          activity.dateOfActivity
                            | moment("DD/MM/YYYY à\\s HH:mm")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="activity-list-report mt-3">
                  <!-- <div class="col-md-12" v-for="(activity, key) in reportActivities" :key="activity.id">
                    <div class="card-report-activity">
                      <span class="date-activity-report"><strong style="font-size: 12px">{{ activity.dateOfActivity | moment("DD/MM/YYYY à\\s HH:mm") }} - {{ activity.type.name }}</strong></span> <br>
                      <h5 class="mt-2">{{ activity.subject }}</h5>
                      <span v-html="activity.description"></span>
                    </div>
                    <div class="html2pdf__page-break" v-if="key === 6"></div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";

import { PlusIcon } from "@/components/crm/Icons";

import VInput from "@/components/VInput.vue";
import VTextarea from "@/components/VTextarea.vue";
import VInputFileDrag from "@/components/VInputFileDrag.vue";
import CustomerActivityCard from "@/components/crm/CustomerActivityCard.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import moment from "moment";
import Swal from "sweetalert2";
import * as yup from "yup";
import VueHtml2pdf from "vue-html2pdf";
import VModal from "@/components/VModal.vue";
import ApacheChart from "@/components/ApacheChart";

import Axios from "axios";

export default {
  name: "CustomerActivities",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    owner: {
      type: String,
      default: "",
    },
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PlusIcon,
    VInput,
    VTextarea,
    VInputFileDrag,
    CustomerActivityCard,
    SelectWrapper,
    VueHtml2pdf,
    VModal,
    ApacheChart,
  },
  data() {
    return {
      activityTypes: [],
      customerActivites: [],
      reportActivities: [],
      chartDataIsFilled: false,
      reportChart: [],
      addDialog: false,
      formData: {
        subject: "",
        typeId: "",
        dateOfActivity: "",
        description: "",
      },
      formFilterReport: {
        type: null,
        types: [],
        start_date: null,
        end_date: null,
      },
      documents: [],
      fieldErrors: [],
    };
  },
  mounted() {
    this.getActivities();
    this.getActivityTypes();
  },
  computed: {
    typesForFilter() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.activityTypes.push({
        label: "Todos os tipos",
        value: "",
      });
    },
    bestTypeReport() {
      const maxValue = Math.max.apply(
        null,
        this.reportChart.map((data) => data.value)
      );

      return this.reportChart.find((report) => report.value === maxValue);
    },
  },
  methods: {
    getError(path) {
      if (this.fieldErrors.length > 0) {
        const error = this.fieldErrors.find((err) => err.path === path);
        return error.message;
      }
      return "";
    },
    async cardActionDelete(id) {
      Swal.fire({
        title: "Excluir Atividade",
        html: "<p>Tem certeza que você deseja excluir esta Atividade?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Excluir",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Excluindo a atividade do cliente",
          });
          const response = await Axios.delete(
            `${config.env.API_DEFAULT.host}/activities/delete-activity/${id}`
          );

          if (response.data.data) {
            Swal.fire(
              "Excluir Atividade",
              "Atividade excluída com sucesso!",
              "success"
            );

            this.getActivities();
          } else {
            Swal.fire(
              "Excluir Atividade",
              "Erro ao excluir atividade!",
              "error"
            );
          }
        }
      });
    },
    async saveActivity() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Salvando atividade do cliente.",
      });

      this.fieldErrors = [];

      const schema = yup.object().shape({
        subject: yup
          .string()
          .min(3, "O assunto deve conter no mínimo 3 caracteres!")
          .required("O assunto é obrigatório!"),
        typeId: yup.string().required("O tipo é obrigatório!"),
        dateOfActivity: yup.string().required("A data é obrigatória!"),
        description: yup
          .string()
          .min(3, "A descrição deve conter no mínimo 3 caracteres!")
          .max(5000, "A descrição deve conter no máximo 5000 caracteres!")
          .required("A descrição é obrigatória!"),
      });

      try {
        await schema.validate(this.formData, {
          abortEarly: false,
        });

        const now = moment();
        const hours = now.hour() < 10 ? `0${now.hour()}` : now.hour();
        const minutes =
          now.minutes() < 10 ? `0${now.minutes()}` : now.minutes();
        const seconds =
          now.seconds() < 10 ? `0${now.seconds()}` : now.seconds();
        this.formData.dateOfActivity = `${moment(
          this.formData.dateOfActivity,
          "Y-MM-DD"
        ).format("Y-MM-DD")}T${hours}:${minutes}:${seconds}`;

        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/activities/save-customer-activity/${this.id}`,
          this.formData
        );

        if (response.data.data) {
          this.$store.commit("setLoading", { show: false });
          const activitySaved = response.data.data;

          if (this.documents.length > 0) {
            this.$store.commit("setLoading", {
              show: true,
              label: "Carregando...",
              description: "Salvando documentos da atividade do cliente.",
            });

            const formData = new FormData();

            let index = 0;
            for (const doc of this.documents) {
              formData.append(`files['${index}']`, doc);
              index++;
            }

            const requestConfig = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };

            const responseDocuments = await Axios.post(
              `${config.env.API_DEFAULT.host}/activityDocuments/uploadFile/${activitySaved.id}/CRM1`,
              formData,
              requestConfig
            );
            if (responseDocuments) {
              this.$store.commit("setLoading", { show: false });

              this.documents = [];
              Swal.fire({
                title: "Adicionar Atividade",
                html: `Atividade e documentos adicionados com sucesso no cadastro de <strong>${this.owner}</strong>`,
                type: "success",
              });
            } else {
              this.$store.commit("setLoading", { show: false });
              Swal.fire({
                title: "Adicionar Atividade",
                html: "Não foi possível adicionar esta nova atividade, porque houve algum problema ao tentar salvar os documentos. Por favor, tente novamente!",
                type: "error",
              });
            }
          } else {
            this.$store.commit("setLoading", { show: false });
            Swal.fire({
              title: "Adicionar Atividade",
              html: `Atividade adicionada com sucesso no cadastro de <strong>${this.owner}</strong>`,
              type: "success",
            });
          }

          this.addDialog = false;
          this.formData = {
            subject: "",
            typeId: "",
            dateOfActivity: "",
            description: "",
          };

          this.getActivities();
        }
      } catch (err) {
        this.$store.commit("setLoading", { show: false });
        this.fieldErrors = err.inner;
        Swal.fire({
          title: "Adicionar Atividade",
          html: "Não foi possível adicionar a Atividade!",
          type: "error",
        });
      }
    },
    async getActivities() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando atividades do cliente.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/activities/get-customer-activities/${this.id}`
        );
        this.customerActivites = response.data.data;
        this.$store.commit("setLoading", { show: false });
      } catch (error) {
        this.$store.commit("setLoading", { show: false });
        Swal.fire({
          title: "Carregar atividades",
          html: `<p>Não foi possível recuperar as atividades do cliente</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
    async generateReport(action) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Salvando atividade do cliente.",
      });

      this.reportChart = [];

      this.chartDataIsFilled = false;

      try {
        const data = {
          type: "",
          types: "",
          start_date:
            this.formFilterReport.start_date !== null &&
            this.formFilterReport.start_date !== ""
              ? `${this.formFilterReport.start_date}T00:00:00`
              : null,
          end_date:
            this.formFilterReport.end_date !== null &&
            this.formFilterReport.end_date !== ""
              ? `${this.formFilterReport.end_date}T23:59:00`
              : null,
        };

        if (this.formFilterReport.types.length > 0) {
          delete data.type;
          data.types = this.formFilterReport.types.join(",");
        } else {
          delete data.types;
        }

        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/activities/filterActivities/${this.id}`,
          { params: data }
        );

        if (response.data.data) {
          this.reportActivities = response.data.data;

          response.data.data.map((item) => {
            const split = item.type.name.split(" ");
            const name = split[split.length - 1];

            const exists = this.reportChart.find((it) => it.name === name);

            if (exists) {
              exists.value += 1;
              return null;
            }

            this.reportChart.push({
              name,
              value: 1,
            });

            return null;
          });

          this.chartDataIsFilled = true;
        }

        this.$refs.html2Pdf.generatePdf();

        action();

        this.formFilterReport = {
          types: [],
          type: null,
          start_date: null,
          end_date: null,
        };
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire({
          title: "Relatório de atividades",
          html: `<p>Não foi possível gerar o relatório de atividades</p> <div>${e}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getActivityTypes() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/activity-types`
        );
        const types = response.data.data;
        if (types.length > 0) {
          types.forEach((type) => {
            this.activityTypes.push({ label: type.name, value: type.id });
          });
        }
      } catch (err) {
        Swal.fire({
          title: "Carregar tipos de atividades",
          html: `<p>Não foi possível recuperar os tipos de atividade</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.report-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #3d9d43;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
  padding: 10px;
  color: white;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

.prime-identification {
  border-left: 7px solid #185a8d;
}

.card-report-activity {
  padding: 20px 20px 5px 20px;
  background-color: white;
  border: 1px solid rgb(206, 206, 206);
  border-bottom: 0px;
  /* border-radius: 5px; */
}

.card-report-activity:last-child {
  border-bottom: 1px solid rgb(206, 206, 206);
}

.date-activity-report {
  color: #114d7b;
}

.pdf-content {
  color: black;
  background-color: white;
}

.report-divider {
  height: 4px;
  background-color: #dcb058;
  border-radius: 100px;
  width: 50%;
}
</style>
