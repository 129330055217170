<template>
<div class="form-layout form-layout-2">
    <operational-integration-angeloni v-if="this.isAngeloni" />
    <operational-integration-duas-rodas v-else-if="this.isDuasRodas" />

</div>
</template>

<script>
import config from '@/config.js';
import {
  mapState,
} from 'vuex';
import OperationalIntegrationDuasRodas from '@/views/operational/integration/Operational.Integration.DuasRodas.vue';
import OperationalIntegrationAngeloni from '@/views/operational/integration/Operational.Integration.Angeloni.vue';

export default {
  name: 'operational-integration',

  components: {
    'operational-integration-duas-rodas': OperationalIntegrationDuasRodas,
    'operational-integration-angeloni': OperationalIntegrationAngeloni,
  },
  data() {
    return {
      model: null,
      initialModel: null,
    };
  },

  methods: {},
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    isAngeloni() {
      if (this.model !== undefined && this.model !== null && this.model.customer !== undefined && this.model.customer !== null) {
        return this.model.customer.federalID.replace(/[^\w\s]/gi, '') === '83646984000614' || this.model.customer.federalID.replace(/[^\w\s]/gi, '') === '83646984006906';
      }

      return false;
    },
    isDuasRodas() {
      if (this.model !== undefined && this.model !== null && this.model.customer !== undefined && this.model.customer !== null) {
        return this.model.customer.federalID.replace(/[^\w\s]/gi, '') === '84430149000109';
      }

      return false;
    },
  },
  watch: {
    process() {
      this.initialModel = {
        ...this.process,
      };
      this.model = {
        ...this.process,
      };
    },
  },
  created() {
    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };
  },
  mounted() {
    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };
  },
};
</script>

<style>

</style>
