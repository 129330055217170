var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-mainpanel"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"card mb-4"},[_c('report-filters',{attrs:{"modal":false},on:{"generate":_vm.getData}})],1),(Object.keys(_vm.reportData).length > 0)?_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"row p-4"},[_vm._m(1),_vm._l((_vm.reportData.frete),function(item,index){return _c('div',{key:index,staticClass:"col-12 col-md-3"},[_c('v-input',{attrs:{"name":item.country,"label":item.country,"type":"money"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)})],2)]):_vm._e(),(Object.keys(_vm.reportData).length > 0)?_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"row p-4"},[_vm._m(2),_c('div',{staticClass:"col-12 col-md-3"},[_c('v-input',{attrs:{"name":"dolarMedio","label":"Dólar medio do ano","type":"money"},model:{value:(_vm.reportData.dolarMedio),callback:function ($$v) {_vm.$set(_vm.reportData, "dolarMedio", $$v)},expression:"reportData.dolarMedio"}})],1)])]):_vm._e(),(Object.keys(_vm.reportData).length > 0)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"flex ai-c jc-sb"},[_c('h2',[_vm._v("Preview")]),_c('button',{staticClass:"btn btn-success",on:{"click":function($event){$event.preventDefault();return _vm.generateNewPDF.apply(null, arguments)}}},[_vm._v(" Exportar PDF ")])])]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"preview-modal":true,"enable-download":false,"filename":'retrospectiva_anual',"pdf-orientation":"portrait","pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-content-width":"100%","html-to-pdf-options":{
          html2canvas: {
            dpi: 300,
            width: 940,
            scale: 2,
          },
          jsPDF: {
            compress: false,
            unit: 'px',
            format: [940, 1440],
          },
        }}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticStyle:{"width":"100%"}},[_c('annual-report',{attrs:{"container-class":"retrospectiva-pdf","report-data":_vm.reportData}})],1)])])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-pageheader"},[_c('ol',{staticClass:"breadcrumb slim-breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Utilitários")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" Retrospectiva anual do Cliente ")])]),_c('h6',{staticClass:"slim-pagetitle"},[_vm._v("Retrospectiva anual do cliente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Média de frete nas principais origens")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Dólar Médio do Ano")])])
}]

export { render, staticRenderFns }