import Axios from 'axios';
import config from '@/config.js';
import Swal from 'sweetalert2';

const IntegrationStore = {
  namespaced: true,
  state: {
    chartOfAccountProjects: [],
  },

  getters: {},

  mutations: {
    SET_CHART_OF_ACCOUNT_PROJECTS(state, payload) {
      state.chartOfAccountProjects = payload;
    },
  },

  actions: {

    async synchronizeClientsAberta({
      commit,
    }) {
      try {
        const result = await Swal.fire({
          title: 'Sincronizar Clientes',
          text: 'Você tem certeza que deseja fazer a sincronia dos clientes do Aberta? Esta operação poderá demorar um pouco.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '##23BF08',
          cancelButtonColor: '#dc3545',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar',
        });

        if (result) {
          commit('setLoading', {
            show: true,
            label: 'Sincronizar Clientes',
            description: 'Sincornizando clientes do Aberta com o Gescomex',
          }, {
            root: true,
          });

          // config.env.API_DEFAULT.host
          const responseAberta = await Axios.get(`${config.env.API_GESCOMEX_INTEGRATOR.host}/api/aberta/clientes`);
          if (responseAberta) {
            const response = await Axios.post(`${config.env.API_DEFAULT.host}/customers/synchronize/aberta`, responseAberta.data.data);

            if (response) {
              commit('setLoading', { show: false }, { root: true });
              Swal.fire('Sincronizar Clientes', response.data.message, 'success');
            } else {
              commit('setLoading', { show: false }, { root: true });
              Swal.fire('Sincronizar Clientes', response.data.message, 'error');
            }
          } else {
            commit('setLoading', { show: false }, { root: true });
            Swal.fire('Sincronizar Clientes', responseAberta.data.message, 'error');
          }

          return true;
        }
        commit('setLoading', { show: false }, { root: true });
      } catch (error) {
        commit('setLoading', { show: false }, { root: true });
        console.error('error: ', error);
        Swal.fire('Sincronizar Clientes', error, 'error');
      }
    },
  },
};

export default IntegrationStore;
