<template>
  <div class="columns gap2 ai-gc" v-if="getLegends().length > 0">
    <div :class="showLegends ? 'span-7' : 'span-12'">
      <div
        :class="['ct-chart ct-perfect-fourth', chartName]"
        @mouseover="hoverChart"
      ></div>
    </div>
    <div class="span-5" v-if="showLegends">
      <div class="legends">
        <h5 class="mb-3">Legendas do gráfico</h5>
        <ul class="chart-legends">
          <li
            v-for="(legend, index) in getLegends()"
            :key="legend.label"
            :class="[
              'flex ai-c jc-sb fgap2 pointer',
              activeLegends.find((legend) => String(legend) === String(index))
                ? 'active'
                : '',
            ]"
            @mouseenter="zoomSvg(index)"
            @mouseleave="unzoomSvg()"
            @click="
              activeSvg(index);
              toggleListActive(index);
            "
          >
            <div class="flex ai-c fgap1">
              <span
                class="legend-indicator"
                :style="`background-color: ${legend.color};`"
                >&nbsp;</span
              >
              <span>{{ legend.label }}</span>
            </div>
            <span class="bold">{{ legend.value }}</span>
          </li>
          <li class="flex ai-c jc-sb fgap2" v-if="getLegends().length > 0">
            <div class="flex ai-c fgap1">
              <span
                class="legend-indicator"
                :style="`background-color: #000000;`"
                >&nbsp;</span
              >
              <span>Total</span>
            </div>
            <span class="bold">{{ getTotal() }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="span-12 alert-chart" v-else>Nenhuma informação disponível</div>
</template>

<script>
import Chartist from "chartist";
import "chartist/dist/chartist.min.css";

export default {
  name: "ChartistComponent",
  props: ["series", "colors", "showLegends", "chartName"],
  data() {
    return {
      activeLegends: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.renderChart();
    }, 500);
  },
  methods: {
    renderChart() {
      let showChart = false;

      for (let i = 0; i < this.series.length; i++) {
        const item = this.series[i];
        if (item.value > 0) {
          showChart = true;
        }
      }

      if (showChart) {
        // eslint-disable-next-line prefer-const
        let chart = Chartist.Pie(
          `.${this.chartName}`,
          {
            series: this.series,
            labels: this.getLabels(),
          },
          {
            donut: true,
            donutWidth: 50,
            donutSolid: false,
            startAngle: 270,
            showLabel: true,
            chartPadding: 20,
          }
        );

        chart.on("draw", (data) => {
          if (data.type === "slice") {
            if (this.colors[data.index]) {
              // eslint-disable-next-line no-underscore-dangle
              data.element._node.setAttribute(
                "style",
                `stroke: ${this.colors[data.index]};`
              );
              // eslint-disable-next-line no-underscore-dangle
              data.element._node.classList.add(`slice-${[data.index]}`);
              // eslint-disable-next-line no-underscore-dangle
              data.element._node.setAttribute("data-slice", `${data.index}`);
            }
          }
          if (data.type === "label") {
            // eslint-disable-next-line no-underscore-dangle
            data.element._node.classList.add(`label-${[data.index]}`);
            // eslint-disable-next-line no-underscore-dangle
            data.element._node.setAttribute("data-label", `${data.index}`);
          }
        });
      }
    },
    getTotal() {
      let total = 0;
      this.series.map((item) => {
        total += item.value;
        return false;
      });
      return parseInt(total, 0);
    },
    getLegends() {
      let showLegends = false;

      for (let i = 0; i < this.series.length; i++) {
        const item = this.series[i];
        if (item.value > 0) {
          showLegends = true;
        }
      }

      const legends = [];

      if (showLegends) {
        this.series.map((item, index) => {
          legends.push({
            label: item.meta,
            value: item.value,
            color: this.colors[index],
          });
          return false;
        });
      }
      return legends;
    },
    getLabels() {
      const labels = [];
      this.series.map((item) => {
        if (item.value > 0) {
          labels.push(
            `${parseFloat(((item.value / this.getTotal()) * 100).toFixed(2))}%`
          );
        } else {
          labels.push("0%");
        }
        return false;
      });
      return labels;
    },
    zoomSvg(index) {
      const svgs = document.querySelectorAll(
        `.${this.chartName} .ct-slice-donut`
      );
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        if (!svg.classList.contains(`slice-${index}`)) {
          svg.classList.add("transparent");
          svg.classList.remove("active");
          svg
            .closest("svg")
            .querySelector(`[data-label="${svg.dataset.slice}"]`)
            .classList.remove("active");
        } else {
          svg.classList.remove("transparent");
          svg.classList.add("active");
          svg
            .closest("svg")
            .querySelector(`[data-label="${svg.dataset.slice}"]`)
            .classList.add("active");
        }
      }
    },
    unzoomSvg() {
      const svgs = document.querySelectorAll(
        `.${this.chartName} .ct-slice-donut`
      );
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        svg
          .closest("svg")
          .querySelector(`[data-label="${svg.dataset.slice}"]`)
          .classList.remove("active");
        svg.classList.remove("transparent");
        svg.classList.remove("active");
      }
    },
    activeSvg(index) {
      const svg = document.querySelector(`.${this.chartName} .slice-${index}`);
      if (svg.classList.contains("activePersistent")) {
        svg.classList.remove("activePersistent");
        svg
          .closest("svg")
          .querySelector(
            `.${this.chartName} [data-label="${svg.dataset.slice}"]`
          )
          .classList.remove("activePersistent");
      } else {
        svg.classList.add("activePersistent");
        svg
          .closest("svg")
          .querySelector(
            `.${this.chartName} [data-label="${svg.dataset.slice}"]`
          )
          .classList.add("activePersistent");
      }
    },
    hoverChart() {
      const svgs = document.querySelectorAll(
        `.${this.chartName} .ct-slice-donut`
      );
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        svg.addEventListener("mouseenter", () => {
          svg.classList.add("active");
          svg
            .closest("svg")
            .querySelector(
              `.${this.chartName} [data-label="${svg.dataset.slice}"]`
            )
            .classList.add("active");
        });
        svg.addEventListener("mouseleave", () => {
          svg.classList.remove("active");
          svg
            .closest("svg")
            .querySelector(
              `.${this.chartName} [data-label="${svg.dataset.slice}"]`
            )
            .classList.remove("active");
        });
      }
    },
    toggleListActive(index) {
      if (
        this.activeLegends.find((legend) => String(legend) === String(index))
      ) {
        this.activeLegends = this.activeLegends.filter(
          (legend) => String(legend) !== String(index)
        );
      } else {
        this.activeLegends.push(String(index));
      }
    },
  },
  watch: {
    series: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.renderChart();
        }, 500);
      },
    },
  },
};
</script>

<style>
.ct-slice-donut,
.ct-label {
  transition: all 0.1s ease-in-out 0s;
}

.ct-slice-donut.active,
.ct-slice-donut.activePersistent {
  stroke-width: 90px !important;
}

.ct-slice-donut.transparent {
  opacity: 0.3;
}

.ct-label {
  font-weight: bold !important;
  font-size: 20px;
  fill: white !important;
  pointer-events: none;
  opacity: 0;
}

.ct-label.active,
.ct-label.activePersistent {
  opacity: 1;
}

.chart-legends li {
  padding: 0.5rem;
  border-radius: var(--small-border-radius);
}

.chart-legends .legend-indicator {
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
}

.chart-legends li.active {
  background-color: var(--light-bg-color);
  font-weight: bold;
}

.alert-chart {
  background-color: rgba(255, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #ff0000;
  font-size: 1.2rem;
}
</style>

<style scoped>
/* Columns Structure */

.columns,
.grid {
  display: grid;
}

.columns {
  grid-template-columns: repeat(12, 1fr);
}

/* Gapping */

.gap1 {
  grid-gap: 0.625rem;
}

.gap2 {
  grid-gap: 1.25rem;
}

.gap3 {
  grid-gap: 1.875rem;
}

.gap4 {
  grid-gap: 2.5rem;
}

.gap5 {
  grid-gap: 3.125rem;
}

.gap6 {
  grid-gap: 3.75rem;
}

.fgap1 {
  gap: 0.625rem;
}

.fgap2 {
  gap: 1.25rem;
}

.fgap3 {
  gap: 1.875rem;
}

.fgap4 {
  gap: 2.5rem;
}

.fgap5 {
  gap: 3.125rem;
}

.fgap6 {
  gap: 3.75rem;
}

/* Gapping */

/* Columns Span */

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.span-4 {
  grid-column: span 4;
}

.span-5 {
  grid-column: span 5;
}

.span-6 {
  grid-column: span 6;
}

.span-7 {
  grid-column: span 7;
}

.span-8 {
  grid-column: span 8;
}

.span-9 {
  grid-column: span 9;
}

.span-10 {
  grid-column: span 10;
}

.span-11 {
  grid-column: span 11;
}

.span-12 {
  grid-column: span 12;
}

/* Columns Span */

@media screen and (max-width: 1020px) {
  /* Columns */

  .columns {
    grid-template-columns: 1fr 1fr;
  }

  /* Columns */

  /* Columns Span */

  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6 {
    grid-column: span 2;
  }

  .span-5,
  .span-4,
  .span-3,
  .span-2 {
    grid-column: span 1;
  }

  /* Columns Span */
}

@media screen and (max-width: 768px) {
  /* Columns */

  .columns {
    grid-template-columns: 1fr;
  }

  /* Columns */

  /* Columns Span */

  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6 {
    grid-column: span 1;
  }

  /* Columns Span */
}

/* Columns Alignment */

.ai-gs {
  align-items: start;
}

.ai-ge {
  align-items: end;
}

.ai-gc {
  align-items: center;
}

.ai-gst {
  align-items: stretch;
}

.ji-gs {
  justify-items: start;
}

.ji-ge {
  justify-items: end;
}

.ji-gc {
  justify-items: center;
}

.ji-gst {
  justify-items: stretch;
}

.ac-gs {
  align-content: start;
}

.ac-ge {
  align-content: end;
}

.ac-gc {
  align-content: center;
}

.ac-gst {
  align-content: stretch;
}

.ac-gsa {
  align-content: space-around;
}

.ac-gsb {
  align-content: space-between;
}

.ac-gse {
  align-content: space-evenly;
}

.jc-gs {
  justify-content: start;
}

.jc-ge {
  justify-content: end;
}

.jc-gc {
  justify-content: center;
}

.jc-gst {
  justify-content: stretch;
}

.jc-gsa {
  justify-content: space-around;
}

.jc-gsb {
  justify-content: space-between;
}

.jc-gse {
  justify-content: space-evenly;
}

/* Columns Alignment */

/* Columns Structure */
</style>
