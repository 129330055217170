<template>
  <form
    id="di-adicao-valor-aduaneiro-form"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">
        Condição de Venda da Mercadoria, Frete e Seguro
      </label>
      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Incoterm </label>
            <div class="wd-100p">
              <v-select
                v-model="model.codigoIncotermsVenda"
                label="value"
                :options="codigosInconterms"
                name="codigoIncotermsVenda"
                :disabled="disableFields"
                class="float-left wd-100"
              >
                <template slot="option" slot-scope="option">
                  {{ option.value }}
                </template>
              </v-select>
              <a
                href="#"
                class="float-right"
                @click.prevent="calcularValorAduaneiro()"
                v-if="
                  activeAdicao.codigoIncotermsVenda !== null &&
                  activeAdicao.codigoIncotermsVenda !== '' &&
                  activeAdicao.codigoMetodoValoracao !== null &&
                  activeAdicao.codigoMetodoValoracao !== ''
                "
              >
                <i class="icon fa fa-refresh tx-22 mg-r-5"></i> CALCULAR VALORES
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Método de Valoração </label>
            <v-select
              v-model="model.codigoMetodoValoracao"
              label="value"
              :options="codigoMetodosValoracao"
              name="codigoMetodoValoracao"
            >
              <template slot="option" slot-scope="option">
                {{ option.description }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Local da Condição </label>
            <input
              class="form-control"
              type="text"
              v-model="model.nomeLocalCondicaoVenda"
              name="nomeLocalCondicaoVenda"
            />
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Valor Aduaneiro </label>
            <money
              name="valorAduaneiro"
              class="form-control"
              v-model="model.valorAduaneiro"
              v-bind="money"
              disabled
            ></money>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Valor na Moeda </label>
            <money
              name="valorMercadoriaCondicaoVenda"
              class="form-control"
              v-model="model.valorMercadoriaCondicaoVenda"
              v-bind="money"
              disabled
            ></money>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Valor em Reais </label>
            <money
              name="valorMercadoriaVendaMoedaNacional"
              class="form-control"
              v-model="model.valorMercadoriaVendaMoedaNacional"
              v-bind="money"
              disabled
            ></money>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Frete Internacional </label>
            <money
              name="valorFreteMercadoriaMoedaNacional"
              class="form-control"
              v-model="model.valorFreteMercadoriaMoedaNacional"
              v-bind="money"
              disabled
            ></money>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Seguro Internacional </label>
            <money
              name="valorSeguroMercadoriaMoedaNacional"
              class="form-control"
              v-model="model.valorSeguroMercadoriaMoedaNacional"
              v-bind="money"
              disabled
            ></money>
          </div>
        </div>
      </div>

      <hr />

      <div class="report-summary-header mg-t-20 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">Acréscimos</h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i>
            Rateios dos acréscimos para as adições das declaração de importação.
          </p>
        </div>
        <div>
          <!-- <a href="#" class="btn btn-primary">
                    <i class="icon fa fa-save tx-22"></i> CRIAR RATEIO
                </a>
                <a href="#" class="btn btn-success">
                    <i class="icon fa fa-refresh tx-22"></i> ATUALIZAR CÂMBIO
                </a>
                <a href="#" class="btn btn-danger">
                    <i class="icon ion-trash-a tx-22"></i> REMOVER ACRÉSCIMOS
                </a> -->
        </div>
      </div>
      <table class="table mg-b-0 bd">
        <thead class="thead-colored bg-primary">
          <tr>
            <th class="tx-center">Denominação</th>
            <th class="tx-center">Moeda</th>
            <th class="tx-center">Valor na Moeda</th>
            <th class="tx-center">Valor em Reais</th>
            <th class="tx-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-if="
              model.acrescimo !== null &&
              model.acrescimo !== undefined &&
              model.acrescimo.length > 0
            "
          >
            <tr-inline
              v-for="item in model.acrescimo"
              :key="item.id"
              :row="item"
              @save="updateAcrescimo"
            >
              <td-inline
                placeholder="Código"
                v-model="item.codigoMetodoAcrescimoValor"
                type="select"
                selectType="key|value"
                :options="codigosMetodoAcrescimos"
              />
              <td-inline
                placeholder="Moeda"
                v-model="item.codigoMoedaNegociadaAcrescimo"
                type="select"
                :options="[...coinsMap]"
              />
              <td-inline
                type="money"
                v-model="item.valorAcrescimoMoedaNegociada"
                @change="updateTaxaCambioAcrescimo(item)"
              ></td-inline>
              <td-inline
                type="money"
                v-model="item.valorAcrescimoMoedaNacional"
              ></td-inline>

              <template slot="actions">
                <a
                  href="#"
                  @click.prevent="removeAcrescimo(item)"
                  class="btn btn-danger btn-icon rounded-circle"
                >
                  <div><i class="icon ion-trash-a"></i></div>
                </a>
              </template>
            </tr-inline>
          </template>

          <tr-inline
            class="new-item"
            :isNew="true"
            :row="newAcrescimo"
            @save="createAcrescimo"
          >
            <td-inline
              placeholder="Código"
              v-model="newAcrescimo.codigoMetodoAcrescimoValor"
              type="select"
              selectType="key|value"
              :options="codigosMetodoAcrescimos"
            />
            <td-inline
              placeholder="Moeda"
              v-model="newAcrescimo.codigoMoedaNegociadaAcrescimo"
              type="select"
              :options="[...coinsMap]"
            />
            <td-inline
              type="money"
              v-model="newAcrescimo.valorAcrescimoMoedaNegociada"
              @change="updateTaxaCambioAcrescimo(newAcrescimo)"
            ></td-inline>
            <td-inline
              type="money"
              v-model="newAcrescimo.valorAcrescimoMoedaNacional"
            ></td-inline>
          </tr-inline>
        </tbody>
      </table>

      <hr />

      <label class="section-title mg-t-0"> Complemento </label>
      <div class="row mg-t-20">
        <div class="col-lg-12">
          <textarea
            v-model="model.textoComplementoValorAduaneiro"
            rows="5"
            class="form-control"
            placeholder="Digite as informações complementares da adição"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import { Money } from "v-money";
import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

import Swal from "sweetalert2";

export default {
  name: "di-adicao-valor-aduaneiro",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    Money,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      initialDI: {},
      di: {},
      model: {},
      modelInitial: {},
      newAcrescimo: {
        codigoMetodoAcrescimoValor: "",
        codigoMoedaNegociadaAcrescimo: "",
        valorAcrescimoMoedaNacional: "",
        valorAcrescimoMoedaNegociada: "",
      },
      codigosInconterms: [...importDeclarationLists.INCOTERMS],
      codigoMetodosValoracao: [
        ...importDeclarationLists.codigoMetodosValoracao,
      ],
      codigosMetodoAcrescimos: [
        ...importDeclarationLists.codigosMetodoAcrescimos,
      ],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 7,
        masked: false,
      },
      money2: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    onSubmit() {
      return true;
    },
    async updateAcrescimo(value) {
      // this.model.processo.push(value);
      this.submitAcrescimo();
    },
    async createAcrescimo(value) {
      this.model.processo.push(value);

      this.submitAcrescimo();
    },
    async removeAcrescimo(value) {
      Swal.fire({
        title: "Excluir Acréscimo",
        html: `Você realmente deseja excluir o Acréscimo <strong>${value.codigoMetodoAcrescimoValor}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.acrescimo.findIndex(
            (item) =>
              item.codigoMetodoAcrescimoValor ===
              value.codigoMetodoAcrescimoValor
          );
          this.model.acrescimo.splice(index, 1);

          this.$store.dispatch("MyIMPStore/patch", {
            diff: this.di,
            newModel: this.di,
            fieldName: null,
          });

          Swal.fire(
            "Excluir Acréscimo ",
            ` O Acréscimo <strong>${value.codigoMetodoAcrescimoValor}</strong> foi excluído com sucesso da lista de Processos Vinculados.`,
            "success"
          );
        }
      });
    },

    async submitProcesso() {
      this.newAcrescimo = {
        codigoMetodoAcrescimoValor: "",
        codigoMoedaNegociadaAcrescimo: "",
        valorAcrescimoMoedaNacional: "",
        valorAcrescimoMoedaNegociada: "",
      };
    },

    calcularValorAduaneiro() {
      this.$store.commit("setLoading", {
        show: false,
        label: "Calcular Valor Aduaneiro",
        description: "Processando informacoes para calcular valor aduaneiro...",
      });

      setTimeout(() => {
        this.$store.dispatch("MyIMPStore/calcularValorAduaneiro", {
          id: this.di.id,
          activeAdicao: null,
        });
      }, 1000);
    },
    async diffObjects(fieldName) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      await this.$store.dispatch("MyIMPStore/diffObjectsAdicao");
    },
    async updateTaxaCambioAcrescimo(item, target) {
      if (
        item.codigoMoedaNegociadaAcrescimo !== undefined &&
        item.codigoMoedaNegociadaAcrescimo !== null &&
        item.codigoMoedaNegociadaAcrescimo !== "" &&
        item.valorAcrescimoMoedaNegociada !== null
      ) {
        const codigo = item.codigoMoedaNegociadaAcrescimo;
        const data = new Date();

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarTaxaCambio",
          {
            codigoMoeda: codigo,
            date: this.$moment(data).format("Y-MM-DD"),
            auto: true,
          }
        );

        if (response != null) {
          const value =
            item.valorAcrescimoMoedaNegociada * response.taxaConversao;
          item.valorAcrescimoMoedaNacional = value;
        }
      }
    },
  },

  computed: {
    ...mapState({
      coins: (state) => state.ProcessStore.coins,
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),
    coinsMap() {
      return this.coins.map((item) => ({
        label: `${item.code} - (${item.swiftCode}) ${item.name}`,
        value: item.code,
      }));
    },
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;

          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    disableFields() {
      if (this.model.numeroLI !== null && this.model.numeroLI !== "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    activeDI() {
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-adicao-valor-aduaneiro-form");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
