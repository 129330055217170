<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Conta</label>

      <div class="row no-gutters">
        <div class="col-md-6 col-sm-12">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Descrição <span class="tx-danger">*</span></label
            >
            <input
              type="text"
              placeholder="Descrição"
              name="description"
              class="form-control"
              v-model="receipt.description"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
            >Digite a Descrição</span
            >
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="form-group mg-md-l--1">
            <label>Valor <span class="tx-danger">*</span></label>
            <money
              v-model="receipt.paidValue"
              v-bind="this.money"
              class="form-control m-input"
              name="paidValue"
              v-validate="'required'"
            ></money>
            <div v-show="errors.has('paidValue')" class="form-control-feedback">
              Valor é um campo obrigatório.
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Data <span class="tx-danger">*</span>
            </label>
            <date-picker lang="pt-br"
                         v-model="receipt.paymentDate"
                         name="paymentDate"
                         class="form-control"
                         format="DD/MM/YYYY"
                         v-validate="'required'"
            ></date-picker>
            <span
              v-show="errors.has('paymentDate')"
              class="parsley-errors-list filled"
            >Selecione uma Data</span
            >
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label>Conta Bancária de Origem </label>
            <v-select
              v-model="receipt.bankAccountSource"
              :on-search="getBankAccounts"
              label="description"
              :options="this.bankAccounts"
              name="bankAccountSource"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                Agência: {{ option.agencyNumber }} | Conta:
                {{ option.accountNumber }} | {{ option.description }}
              </template>
            </v-select>
            <div
              v-show="errors.has('bankAccountSource')"
              class="parsley-errors-list filled"
            >
              Conta Bancária de Origem é um campo obrigatório.
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label>Conta Bancária de Destino </label>
            <v-select
              v-model="receipt.bankAccountDestination"
              :on-search="getBankAccounts"
              label="description"
              :options="this.bankAccounts"
              name="bankAccountDestination"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                Agência: {{ option.agencyNumber }} | Conta:
                {{ option.accountNumber }} | {{ option.description }}
              </template>
            </v-select>
            <div
              v-show="errors.has('bankAccountDestination')"
              class="parsley-errors-list filled"
            >
              Conta Bancária de Destino é um campo obrigatório.
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"
            >Filial <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="receipt.account"
              :on-search="getAccounts"
              label="name"
              :options="accounts"
              name="account"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
            <span
              v-show="errors.has('account')"
              class="parsley-errors-list filled"
            >Selecione a Filial</span
            >
          </div>
        </div>
      </div>

      <div class="row mg-t-15">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
            <i class="fa fa-floppy-o mg-r-5"></i> SALVAR
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-secondary btn-block mg-l-10-force"
            type="button"
            @click.prevent="$router.go(-1)"
          >
            <i class="fa fa-reply mg-r-5"></i> VOLTAR
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </form>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {Money} from "v-money";

export default {
  name: "receipts-form-general",
  props: ["receipt", "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
    DatePicker,
    Money,
  },

  data() {
    return {
      banks: [],
      accounts: [],
      bankAccount: null,
      bankAccounts: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.isNewRecord) {
            this.addModel();
          } else {
            this.updateModel();
          }
        }
      });
    },
    addModel() {
      this.manageModel();

      Axios.post(`${config.env.API_DEFAULT.host}/receipts`, this.receipt)
        .then((response) => {
          if (response.status === 201) {
            this.receipt = response.data;
            this.$swal({
              title: `A transferência ${this.receipt.description} foi cadastrada com sucesso.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "success",
            });
            this.$router.push({
              name: "moviments-transfers-form",
              params: {action: "update", id: response.data.id},
            });
          } else {
            this.$swal({
              title: `A transferência ${this.receipt.description} não foi cadastrada.`,
              showConfirmButton: false,
              timer: 4000,
              position: "top-right",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: `A transferência ${this.receipt.description} não foi cadastrada. \n\n${error}`,
            showConfirmButton: false,
            timer: 4000,
            position: "top-right",
            icon: "error",
          });
        });
    },
    updateModel() {
      this.manageModel();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/receipts/${this.receipt.id}`,
        this.receipt
      )
        .then((response) => {
          this.$swal(
            "Tranferência",
            `A transferência ${this.receipt.description} foi atualizado com sucesso no banco de dados.`,
            "success"
          );
          this.receipt.bank = this.oldModel.bank;
          this.receipt.account = this.oldModel.account;
          this.receipt.bankAccountSource = this.oldModel.bankAccountSource;
          this.receipt.bankAccountDestination =
            this.oldModel.bankAccountDestination;
        })
        .catch((error) => {
          this.$swal(
            "Contas Bancárias",
            `Não foi possível atualizar a transferência ${this.receipt.description} no banco de dados.`,
            "error"
          );
        });
    },
    getBankModel() {
      if (this.receipt.account !== undefined && this.receipt.account !== null) {
        this.receipt.account = this.receipt.account._links.self.href;
      }
    },
    manageModel() {
      this.receipt.index = 0;
      this.receipt.registryDate = this.$moment(new Date()).format(
        "Y-MM-DDTLTS"
      );
      this.receipt.paymentDate = this.$moment(this.receipt.paymentDate).format(
        "Y-MM-DDTLTS"
      );
      // this.receipt.userRegistry = this.$store.state.credentials.data.person.href;
      this.receipt.typeOfMovimentation = "FINANCE_BANK_TRANSFER";
      this.oldModel.account = this.receipt.account;
      this.oldModel.bankAccountSource = this.receipt.bankAccountSource;
      this.oldModel.bankAccountDestination =
        this.receipt.bankAccountDestination;

      this.receipt.paid = true;
      this.receipt.paidValue = this.receipt.paidValue;
      this.receipt.originalValue = this.receipt.originalValue;
      this.receipt.paymentDate = this.receipt.paymentDate;

      this.receipt.account = this.receipt.account._links.self.href;
      this.receipt.bankAccountSource =
        this.receipt.bankAccountSource._links.self.href;
      this.receipt.bankAccountDestination =
        this.receipt.bankAccountDestination._links.self.href;
    },
    getBanks(search, loading) {
      Axios.get(
        `${config.env.API_DEFAULT.host}/banks/search/findByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.banks = response.data._embedded.banks;
        })
        .catch((error) => {
          this.$swal(
            "Transferência ",
            `Não foi possível carregar a listagem de Transferências disponíveis: ${error}`,
            "error"
          );
        });
    },
    getAccounts(search, loading) {
      Axios.get(
        `${config.env.API_DEFAULT.host}/accounts/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.accounts = response.data._embedded.accounts;
        })
        .catch((error) => {
          this.$swal(
            `Não foi possível carregar a listagem de Filiais disponíveis: ${error}`,
            "error"
          );
        });
    },
    getBankAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/bankAccounts/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          this.bankAccounts = response.data._embedded.bankAccounts;
          loading(false);
        })
        .catch((error) => {
          this.$swal(
            "Transferência! ",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
  },
};
</script>
