import { render, staticRenderFns } from "./ContactBasicDataRequests.vue?vue&type=template&id=3637e4cf&scoped=true"
import script from "./ContactBasicDataRequests.vue?vue&type=script&lang=js"
export * from "./ContactBasicDataRequests.vue?vue&type=script&lang=js"
import style0 from "./ContactBasicDataRequests.vue?vue&type=style&index=0&id=3637e4cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3637e4cf",
  null
  
)

export default component.exports