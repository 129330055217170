<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2727 4H4V11.2727H11.2727V4Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M43.9998 36.7271H36.7271V43.9998H43.9998V36.7271Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M11.2727 24H4V31.2727H11.2727V24Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M23.9998 36.7271H16.7271V43.9998H23.9998V36.7271Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M31.2727 4H24V11.2727H31.2727V4Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M43.9998 16.7271H36.7271V23.9998H43.9998V16.7271Z" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linejoin="round"/>
    <path d="M11.2729 7.63623H24.0002" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M24 40.3638H36.7273" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M11.2729 27.6366H27.6366V11.2729" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M28.8275 20.3633H36.7269M20.3633 36.7269V27.6282V36.7269Z" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.63672 11.2725V23.9997" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M40.3633 24V36.7273" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ConfigGroupIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
