<template>
  <div class="slim-mainpanel">
    <div class="container pd-t-30">
      <div class="dash-headline-two">
        <div class="d-flex align-items-center">
          <h2 class="tx-inverse mg-b-0">Calendário Financeiro</h2>
          <p class="mg-b-0 mg-l-20">
            Operacional /
            <strong>Calendário Financeiro</strong>
          </p>
        </div>
      </div>
    </div>
    <div>
      <new-gs-calendar
        :week="isWeek"
        :content="filteredCalendarContent"
        @change="({ year, month, endDay }) => getProcessesMonth(year, month, endDay)"
        @changeWeek="({ start, end }) => getProcessWeek(start, end)"
        :hideCalendar="hideCalendar"
        :resultsCount="getResultsCount"
        :resultsTotal="getResultsTotal"
      >
        <template #filter="{ data }">
          <div class="container">
          <div class="cont-filter bg-white pd-x-10 pd-t-20 pd-b-20 d-flex align-items-center">
            <span class="material-icons-outlined icon">
              settings_input_component
            </span>
            <div class="input-comp">
              <select-wrapper
                v-model="selectedCompany"
                :options="companies"
                :multiple="true"
                label="Selecione um ou mais clientes"
              ></select-wrapper>
            </div>
            <div v-if="selectedCompany.length > 0">
              <button class="crm-button" @click.prevent="generateCalendar(data)">
                Gerar
              </button>
            </div>
          </div>
        </div>
        </template>
        <template #header="{ data }">
          <div class="flex ai-c jc-sb fgap2">
            <div style="flex: 0 1 30%; display: flex; align-items: center; gap: 1rem;">
              <drop-down contentPosition="left" label="Filtros" :contentWidth="600" class-drop-down-menu="filtro">
                <template #content>
                  <div class="calendar-filters">
                    <div class="calendar-filter-col">
                      <h5>Visualização</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Semanal
                            <input
                              type="radio"
                              name="calendarView"
                              id="calendarView"
                              :value="true"
                              v-model="isWeek"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Mensal
                            <input
                              type="radio"
                              name="calendarView"
                              id="calendarView"
                              :value="false"
                              v-model="isWeek"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="calendar-filter-col">
                      <h5>Tipo</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Numerários
                            <input
                              type="checkbox"
                              name="numerarios"
                              id="numerarios"
                              value="NUMERARIO"
                              v-model="calendarFilters"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Invoices
                            <input
                              type="checkbox"
                              name="invoices"
                              id="invoices"
                              value="PARCELA_INVOICE"
                              v-model="calendarFilters"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="calendar-filter-col">
                      <h5>Status Numerário</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Pago
                            <input
                              type="checkbox"
                              name="pago"
                              id="pago"
                              value="PAGO"
                              v-model="calendarStatus"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            À Pagar
                            <input
                              type="checkbox"
                              name="a_pagar"
                              id="a_pagar"
                              value="A_PAGAR"
                              v-model="calendarStatus"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </drop-down>
              <drop-down
                contentPosition="left"
                label="Exibição"
                :contentWidth="300"
                class-drop-down-menu="exibicao"
              >
                <template #content>
                  <div class="calendar-filters modify">
                    <div class="calendar-filter-col">
                      <h5>Tipo de Referência</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Número da Invoice
                            <input
                              type="radio"
                              name="invoiceNumber"
                              id="invoiceNumber"
                              value="invoiceNumber"
                              v-model="calendarFieldView"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Sua Referência
                            <input
                              type="radio"
                              name="customerReference"
                              id="customerReference"
                              value="customerReference"
                              v-model="calendarFieldView"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Referência da Prime
                            <input
                              type="radio"
                              name="identification"
                              id="identification"
                              value="identification"
                              v-model="calendarFieldView"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </drop-down>
               <button type="button" class="btn-refresh" @click.prevent="clearFilter(data)" @mouseover="hoverClearFilter = 'white'" @mouseleave="hoverClearFilter = '#104375'">
                  <ClearIcon :size="24" :color="hoverClearFilter"/>
                </button>
              <button type="button" class="btn-refresh" @click.prevent="refresh(data)" @mouseover="hoverRefresh = 'white'" @mouseleave="hoverRefresh = '#104375'">
                <RefreshIcon :size="24" :color="hoverRefresh"/>
              </button>

            </div>
            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-if="!data.week">
              <button class="prev-month" @click.prevent="data.prevMonth()">
                <ChevronLeftIcon />
              </button>
              <div class="text-dark text-center text-md mb-0 text-bold refresh calendar-select-month" style="width: 210px" @click.prevent="showDatePicker">
                <div class="calendar-month">
                  <span class="text-medium">{{ data.months[data.month] }}</span> {{ data.year }}
                </div>
                <date-picker-vue
                  ref="datepicker"
                  v-model="data.dateSelectCalendar"
                  type="month"
                  lang="pt-br"
                  placeholder=""
                  :popup-style="popupStyle"
                  class="new-calendar-view-datepicker"
                  @input="selectDateInDatePicker($event, data)"
                />
              </div>
              <button class="next-month" @click.prevent="data.nextMonth()">
                <ChevronRightIcon />
              </button>
            </div>
            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-else>
              <button class="prev-month" @click.prevent="data.prevWeek()">
                <ChevronLeftIcon />
              </button>
              <div class="refresh calendar-select-week" @click.prevent="showDatePickerWeek">
                <p class="text-center text-dark text-md ph-2 mb-0 calendar-week">
                  <span class="text-bold">
                    {{ data.weekData.start.getDate() }}
                    {{ months[data.weekData.start.getMonth()] }}
                    {{
                      String(data.weekData.start.getFullYear()).substring(2)
                    }}
                  </span>
                  <span>Até</span>
                  <span class="text-bold">
                    {{ data.weekData.end.getDate() }}
                    {{ months[data.weekData.end.getMonth()] }}
                    {{ String(data.weekData.end.getFullYear()).substring(2) }}
                  </span>
                </p>
                <date-picker-vue
                  ref="datepickerWeek"
                  v-model="data.dateSelectCalendarWeek"
                  lang="pt-br"
                  :popup-style="popupStyleWeek"
                  class="new-calendar-view-datepicker-week"
                  @input="selectDateInDatePickerWeek($event, data)"
                />
              </div>
              <button class="next-month" @click.prevent="data.nextWeek()">
                <ChevronRightIcon />
              </button>
            </div>
            <div style="flex: 0 1 30%" class="flex ai-c fgap2 jc-ge">
              <button
                type="button" 
                v-if="userCanExportTxt"
                class="toggle-calendar-type-btn dark"
                @click.prevent="openExportModal('TXT_CASH_CALENDAR_RUDOLPH')"
              >
                GERAR TXT
              </button>
              <button
                type="button"
                class="toggle-calendar-type-btn dark"
                @click.prevent="openExportModal('EXCEL')"
              >
                GERAR EXCEL
              </button>
            </div>
          </div>
        </template>
        <template #item="{ past, future, active, content, index, date }">
          <new-gs-calendar-day-content
            :ref="index"
            :data="content"
            :past="past"
            :future="future"
            :active="active"
            :grouped="!isWeek"
            :date="date"
            :useField="calendarFieldView"
            @action="(item) => calendarItemClick(item, date)"
            @innerAction="(item) => openProcess(item)"
          />
        </template>
        <template #itemFooter="{ content, overview }">
          <div v-if="content && !overview" class="text-right pv-2 ph-1">
            <p class="text-dark text-sm">TOTAL</p>
            <p class="text-dark text-bold">{{ getWeekTotal(content) }}</p>
          </div>
          <div v-if="!content && !overview" class="text-right pv-2 ph-1">
            <p class="text-dark text-sm">TOTAL</p>
            <p class="text-dark text-bold">R$ 0,00</p>
          </div>
          <div v-if="content && overview" class="text-right pv-2 ph-1">
            <p class="text-dark text-sm">Valor previsto da semana</p>
            <p class="text-dark text-bold">{{ getWeekTotal(content) }}</p>
          </div>
        </template>
        <template #week="{ content }">
          <div
            v-if="content"
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-dark">TOTAL</p>
            <p class="text-dark text-bold mb-1">
              {{ getWeekTotal(content) }}
            </p>
            <p class="text-dark text-sm">
              Soma total de todos os processos na semana.
            </p>
          </div>
          <div
            v-else
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-dark">TOTAL</p>
            <p class="text-dark text-bold mb-1">R$ 0,00</p>
            <p class="text-dark text-sm">
              Soma total de todos os processos na semana.
            </p>
          </div>
        </template>
        <template #footer>
          <div class="flex jc-fe" v-if="!isWeek">
            <new-gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-accent text-bold">Previsão Mensal</p>
              </div>
            </new-gs-calendar-item>
            <new-gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-dark">TOTAL</p>
                <p class="text-dark text-bold mb-1">
                  {{ getResultsTotal }}
                </p>
                <p class="text-dark text-sm">
                  Soma total de todos os processos do mês.
                </p>
              </div>
            </new-gs-calendar-item>
          </div>
        </template>
      </new-gs-calendar>
    </div>
    <transition name="calendar">
      <div
        @click.self.prevent="closeOverlay"
        class="modal-calendar"
        v-if="modalCalendarOpen"
      >
        <div class="modal-calendar-container">
          <div class="modal-calendar-header">
            <div>
              <p class="text-sm text-natural text-normal mb-0">
                Dia: {{ selectedDayNumber }} /
                {{ selectedDay.length }} lançamentos
              </p>
              <h4 class="text-dark text-bold mb-1">
                Total: {{ getWeekTotal(selectedDay) }}
              </h4>
            </div>
            <button
              @click.prevent="closeOverlay"
              class="close-modal-calendar"
            >
              X
            </button>
          </div>
          <div class="modal-calendar-content">
            <new-gs-calendar-day-content
              :date="selectedDate"
              :data="selectedDay"
              :useField="calendarFieldView"
              :grouped="false"
              @innerAction="(item) => openProcess(item)"
            />
          </div>
        </div>
      </div>
    </transition>
    <Modal
      title="Configurações da exportação"
      description=""
      :width="800"
      :height="20"
      :handler="modalExportOpen"
      @requestClose="modalExportOpen = false"
      classModify="remove-overflow"
    >
      <template #head> Configurações da exportação </template>
      <template #content>
        <div
          class="calendar-filters"
          :class="{ 'filter-reduced': modalExportType === 'TXT_CASH_CALENDAR_RUDOLPH' }"
          style="min-width: unset; max-width: 900px; width: 100%"
        >
          <div class="calendar-filter-col">
            <h5>Datas</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  <span style="width: 80px">Início</span>
                  <v-input
                    name="startDate"
                    type="date"
                    label=""
                    v-model="exportFilters.startDate"
                  />
                </label>
              </li>
              <li>
                <label>
                  <span style="width: 80px">Fim</span>
                  <v-input
                    name="endDate"
                    type="date"
                    label=""
                    v-model="exportFilters.endDate"
                  />
                </label>
              </li>
            </ul>
          </div>
          <div class="calendar-filter-col" v-if="modalExportType !== 'TXT_CASH_CALENDAR_RUDOLPH'">
            <h5>Tipo</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Todos
                  <input
                    type="radio"
                    name="numerarios"
                    id="numerarios"
                    value="TODOS"
                    v-model="exportFilters.category"
                    style="height: 45px"
                  />
                </label>
              </li>
              <li>
                <label>
                  Numerários
                  <input
                    type="radio"
                    name="numerarios"
                    id="numerarios"
                    value="numerarios"
                    v-model="exportFilters.category"
                    style="height: 45px"
                  />
                </label>
              </li>
              <li>
                <label>
                  Invoices
                  <input
                    type="radio"
                    name="invoices"
                    id="invoices"
                    value="invoices"
                    v-model="exportFilters.category"
                    style="height: 45px"
                  />
                </label>
              </li>
            </ul>
          </div>
          <div class="calendar-filter-col" v-if="modalExportType !== 'TXT_CASH_CALENDAR_RUDOLPH'">
            <h5>Status Numerário</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Todos
                  <input
                    type="radio"
                    name="pago"
                    id="pago"
                    value="TODOS"
                    v-model="exportFilters.status"
                    style="height: 45px"
                  />
                </label>
              </li>
              <li>
                <label>
                  Pago
                  <input
                    type="radio"
                    name="pago"
                    id="pago"
                    value="PAGO"
                    v-model="exportFilters.status"
                    style="height: 45px"
                  />
                </label>
              </li>
              <li>
                <label>
                  À Pagar
                  <input
                    type="radio"
                    name="a_pagar"
                    id="a_pagar"
                    value="A_PAGAR"
                    v-model="exportFilters.status"
                    style="height: 45px"
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex jc-c">
          <a
            class="toggle-calendar-type-btn dark"
            href="#"
            @click.prevent="generateExport"
          >
            EXPORTAR
          </a>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import GsCalendar from '@/components/GsCalendar/GsCalendar.vue';
import DropDown from '@/components/DropDown.vue';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue';
import ClearIcon from '@/components/icons/ClearIcon.vue';
import NewGsCalendarDayContent from '@/components/GsCalendar/NewGsCalendarDayContent.vue';
import NewGsCalendarItem from '@/components/GsCalendar/NewGsCalendarItem.vue';
import NewGsCalendar from '@/components/GsCalendar/NewGsCalendar.vue';
import RefreshIcon from '@/components/icons/Refresh.vue';
import { CalendarColumnsExcel } from '@/utils/ProcessReportColumnsExcel';
import Swal from 'sweetalert2';
import Axios from 'axios';
import config from '@/config';
import Modal from '@/components/VModal.vue';
import VInput from '@/components/VInput.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import DatePickerVue from 'vue2-datepicker';
import moment from 'moment/moment';
export default {
  name: "NewCalendarView",
  components: {
    DropDown,
    NewGsCalendar,
    NewGsCalendarItem,
    NewGsCalendarDayContent,
    ChevronRightIcon,
    ChevronLeftIcon,
    RefreshIcon,
    Modal,
    VInput,
    SelectWrapper,
    DatePickerVue,
    ClearIcon,
  },
  data() {
    return {
      hideCalendar: true,
      modalExportType: 'EXCEL',
      modalExportOpen: false,
      companies: [],
      selectedCompany: [],
      modalCalendarOpen: false,
      fieldsExcel: CalendarColumnsExcel,
      modalProcessOpen: false,
      modalData: {},
      overlayProps: {
        left: 0,
        width: 0,
      },
      exportFilters: {
        startDate: null,
        endDate: null,
        category: 'TODOS',
        status: 'TODOS',
      },
      selectedDay: [],
      selectedDayNumber: null,
      selectedDate: null,
      selectedProcess: {},
      calendarContent: {},
      calendarFilters: ['NUMERARIO', 'PARCELA_INVOICE'],
      calendarFieldView: 'customerReference',
      calendarStatus: ['A_PAGAR', 'PAGO'],
      isWeek: false,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      hoverRefresh: '#104375',
      hoverClearFilter: '#104375',
      userCanExportTxt: false,
      popupStyle: { top: '0px', left: '-21px' },
      isPopupOpen: false,
      isPopupOpenWeek: false,
      popupStyleWeek: { top: '0px', left: '7px' },
    };
  },
  methods: {
    openExportModal(type) {
      this.modalExportOpen = true;
      this.modalExportType = type;
    },
    async openProcess(item) {
      const identification = item.identification.replace(/^PRI/, '');
      window.open(
        `/operational/imp/desk?showProcess=true&identification=${identification}`
      );
    },
    showDatePicker() {
      if (this.isPopupOpen) {
        this.$refs.datepicker.closePopup(); // Fecha o popup
      } else {
        this.$refs.datepicker.showPopup(); // Abre o popup
      }
      this.isPopupOpen = !this.isPopupOpen; // Alterna o estado
    },
    showDatePickerWeek() {
      if (this.isPopupOpenWeek) {
        this.$refs.datepickerWeek.closePopup(); // Fecha o popup
      } else {
        this.$refs.datepickerWeek.showPopup(); // Abre o popup
      }
      this.isPopupOpenWeek = !this.isPopupOpenWeek; // Alterna o estado
    },
    selectDateInDatePicker(event, data) {
      this.isPopupOpen = false;
      const date = new Date(event);
      const month = date.getMonth();
      const year = date.getFullYear();
      data.newMonth(month, year);
    },
    selectDateInDatePickerWeek(event, data) {
      this.isPopupOpenWeek = false;
      if (event) {
        const date = moment(event).endOf('week').toDate();
        data.newWeek(date, event);
      }
    },
    handleClickOutside(event) {
      const calendarSelectMonth = event.target.closest('.calendar-month');
      const calendarMonth = event.target.closest('.calendar-select-month');
      if (!calendarSelectMonth && !calendarMonth) {
        this.isPopupOpen = false;
      }
    },
    handleClickOutsideWeek(event) {
      const calendarSelectWeek = event.target.closest('.calendar-week');
      const calendarWeek = event.target.closest('.calendar-select-week');
      if (!calendarSelectWeek && !calendarWeek) {
        this.isPopupOpenWeek = false;
      }
    },
    async getCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Listar Clientes",
        description: "Carregando...",
      });
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllByFilters?enabled=true&isCustomer=true`
        );
        this.companies = response.data.data.map((comp) => ({
          label: comp.name,
          value: comp.id,
        }));
      } catch (e) {
        Swal.fire("Listar Clientes", e.message, "error");
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async getProcessesMonth(year, month) {
      this.closeOverlay();
      if (this.selectedCompany) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Calendário Financeiro",
          description: "Carregando Dados...",
        });
        // const companiesIds = this.user.companies.map((c) => c.id).join(',');
        try {
          let response;
          if (!year || !month) {
            const date = new Date();
            const ld = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
            const imonth = String(date.getMonth() + 1).padStart(2, "0");
            response = await Axios.get(
              `${config.env.API_DEFAULT.host}/cash-calendar?customerIds=${this.selectedCompany}&startDate=${date.getFullYear()}-${imonth}-01T00:00:00&endDate=${date.getFullYear()}-${imonth}-${ld}T23:59:59&type=SCREEN&category=TODOS`
            );
          } else {
            const imonth = String(month).padStart(2, "0");
            const ld = new Date(year, month, 0).getDate();
            response = await Axios.get(
              `${config.env.API_DEFAULT.host}/cash-calendar?customerIds=${this.selectedCompany}&startDate=${year}-${imonth}-01T00:00:00&endDate=${year}-${imonth}-${ld}T23:59:59&type=SCREEN&category=TODOS`
            );
          }

          if (!response.data.data) {
            Swal.fire("Calendário Financeiro", response.data.message, "error");
            this.calendarContent = {};
            return;
          }

          this.calendarContent = response.data.data;
        } catch (e) {
          const messageError = e?.response?.data?.message || e.message;
          Swal.fire("Calendário Financeiro", messageError, "error");
        } finally {
          this.$store.commit("setLoading", {
            show: false,
          });
        }
      }
    },
    async getProcessWeek(start, end) {
      if (this.selectedCompany) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Calendário Financeiro",
          description: "Carregando Dados...",
        });
        // const companiesIds = this.user.companies.map((c) => c.id).join(',');
        try {
          let response;
          const date = new Date();
          const startD = new Date(date.setDate(date.getDate() - date.getDay()));
          const endD = new Date(
            date.setDate(date.getDate() - date.getDay() + 6)
          );
          if (!start || !end) {
            response = await Axios.get(
              `${config.env.API_DEFAULT.host}/cash-calendar?customerIds=${this.selectedCompany}&startDate=${startD.getFullYear()}-${String(startD.getMonth() + 1).padStart(2, "0")}-${String(startD.getDate()).padStart(2,"0")}T00:00:00&endDate=${endD.getFullYear()}-${String(endD.getMonth() + 1).padStart(2, "0")}-${String(endD.getDate()).padStart(2,"0")}T23:59:59&type=SCREEN&category=TODOS`
            );
          } else {
            response = await Axios.get(
              `${config.env.API_DEFAULT.host}/cash-calendar?customerIds=${this.selectedCompany}&startDate=${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, "0")}-${String(start.getDate()).padStart(2,"0")}T00:00:00&endDate=${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, "0")}-${String(end.getDate()).padStart(2,"0")}T23:59:59&type=SCREEN&category=TODOS`
            );
          }

          if (!response.data.data) {
            Swal.fire("Calendário Financeiro", response.data.message, "error");
            this.calendarContent = {};
            return;
          }

          this.calendarContent = response.data.data;
        } catch (e) {
          const messageError = e?.response?.data?.message || e.message;
          Swal.fire("Calendário Financeiro", messageError, "error");
        } finally {
          this.$store.commit("setLoading", {
            show: false,
          });
        }
      }
    },
    getWeekTotal(content) {
      const result = content.reduce((acc, cur) => acc + cur.value, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    calendarItemClick(data, date) {
      if (!this.isWeek) {
        this.selectedDayNumber = String(date.getDate()).padStart(2, "0");
        this.selectedDate = date;
        this.selectedDay = data;
        this.modalCalendarOpen = true;
      }
    },
    closeOverlay() {
      this.modalProcessOpen = false;
      this.selectedDayNumber = null;
      this.selectedDay = [];
      this.modalCalendarOpen = false;
    },
    toggleCalendarView() {
      this.isWeek = !this.isWeek;
      this.closeOverlay();
    },
    checkEscape(e) {
      if (e.keyCode === 27) {
        this.closeOverlay();
      }
    },
    async generateCalendar(data) {
      if (this.selectedCompany.length == 0) {
        this.hideCalendar = true;
        return;
      }
      this.userCanExportTxt = Object.keys(this.selectedCompany).every(key => [102, 2397].includes(this.selectedCompany[key]));
      this.hideCalendar = true;
      this.calendarContent = {};
      this.isWeek = false;
      await this.getProcessesMonth(data.year, data.month + 1);
      this.hideCalendar = false;
    },
    refresh(data) {
      if (data.week) {
        this.getProcessWeek(data.weekData.start, data.weekData.end);
      } else {
        this.getProcessesMonth(data.year, data.month + 1);
      }
    },
    clearFilter(data) {
      this.calendarFilters = ['NUMERARIO', 'PARCELA_INVOICE'];
      this.calendarFieldView = 'customerReference';
      this.calendarStatus = ['A_PAGAR', 'PAGO'];
      if (this.isWeek) {
        this.isWeek = false;
      } else {
        data.clear();
      }
    },
    async generateExport() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Gerando Excel",
        description: "Carregando Dados...",
      });
      if (
        !this.exportFilters.startDate
        || this.exportFilters.startDate == null
        || this.exportFilters.startDate === ''
      ) {
        Swal.fire("Gerar Excel", 'A data de início e de fim devem estar preenchidas!', "error");
        this.$store.commit("setLoading", {show: false,});
        return false;
      }
      if (
        !this.exportFilters.endDate
        || this.exportFilters.endDate == null
        || this.exportFilters.endDate === ''
      ) {
        Swal.fire("Gerar Excel", 'A data de início e de fim devem estar preenchidas!', "error");
        this.$store.commit("setLoading", {show: false,});
        return false;
      }
      try {
        const startDateReq = `${this.exportFilters.startDate.split('T')[0]}T00:00:00`;
        const endDateReq = `${this.exportFilters.endDate.split('T')[0]}T23:59:59`;
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/cash-calendar?customerIds=${this.selectedCompany}&startDate=${startDateReq}&endDate=${endDateReq}&type=${this.modalExportType}&category=${this.exportFilters.category}&status=${this.exportFilters.status}`, {responseType: 'blob'});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `calendario-financeiro.${this.modalExportType === 'EXCEL' ? 'xlsx' : 'txt'}`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        if (e.response) {
          if (e.response.data instanceof Blob) {
            const res = await e.response.data.text();
            const parsedRes = JSON.parse(res);
            Swal.fire("Gerar Excel", parsedRes.message, "error");
          } else {
            Swal.fire("Gerar Excel", e.response.data.message, "error");
          }
        } else {
          Swal.fire("Gerar Excel", 'Não foi possível exportar os dados.', "error");
        }
      } finally {
        this.$store.commit("setLoading", {show: false,});
      }
      return true;
    },
  },
  computed: {
    filteredCalendarContent() {
      const res = {};
      const dates = Object.keys(this.calendarContent);
      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        res[date] = [];
        for (let j = 0; j < this.calendarContent[date].length; j += 1) {
          const item = this.calendarContent[date][j];
          if (this.calendarFilters.includes('PARCELA_INVOICE') && item.category === 'PARCELA_INVOICE') {
            res[date].push(item);
          }
          if (this.calendarFilters.includes('NUMERARIO') && item.category !== 'PARCELA_INVOICE') {
            res[date].push(item);
          }
        }
      }
      if (this.calendarStatus.length === 2) {
        return res;
      }
      if (this.calendarStatus.length === 0) {
        return [];
      }
      const resDates = Object.keys(res);
      for (let i = 0; i < resDates.length; i += 1) {
        const date = resDates[i];
        if (this.calendarStatus.includes('PAGO')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => item.cashRequestDone === true);
          }
        }
        if (this.calendarStatus.includes('A_PAGAR')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => item.cashRequestDone !== true);
          }
        }
      }
      return res;
    },
     getResultsTotal() {
      const content = [];
      for (let i = 0; i < Object.keys(this.filteredCalendarContent).length; i += 1) {
        const itemI = Object.values(this.filteredCalendarContent)[i];
        for (let j = 0; j < itemI.length; j += 1) {
          content.push(itemI[j]);
        }
      }
      const result = content.reduce((acc, cur) => acc + cur.value, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    getResultsCount() {
      let res = 0;
      for (let i = 0; i < Object.keys(this.calendarContent).length; i += 1) {
        res += Object.values(this.calendarContent)[i].length;
      }
      return res;
    },
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  async mounted() {
    await this.getCompanies();
    window.addEventListener("keyup", this.checkEscape);
    window.addEventListener('click', this.handleClickOutside);
    window.addEventListener('click', this.handleClickOutsideWeek);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.checkEscape);
    window.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('click', this.handleClickOutsideWeek);
  },
};
</script>
<style scoped>
.next-month,
.prev-month {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--header-text-color-hover);
  color: var(--primary-bg-header);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out 0s;
}
.next-month:hover,
.prev-month:hover {
  color: white;
  background: var(--header-text-color-hover);
}
.refresh{
  cursor: pointer
}
.btn-refresh {
  display: flex;
  flex: 0 0 45px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--header-text-color-hover);
  color: var(--primary-bg-header);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all .2s ease-in-out 0s;
}
.btn-refresh:hover {
  background: var(--header-text-color-hover);
}
.toggle-calendar-type-btn {
  padding: 1rem 1.5rem;
  font-family: Poppins, sans-serif;
  border-radius: 100px;
  background-color: #f4f7f9;
  color: var(--primary-bg-header);
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  text-transform: uppercase;
}
.toggle-calendar-type-btn:hover {
  background-color: var(--primary-bg-header);
  color: #f4f7f9;
}
.calendar-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.calendar-modal {
  position: absolute;
  top: 0;
  bottom: 1rem;
  z-index: 5;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0.75rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cont-filter {
  gap: 20px;
}
.input-comp {
  flex: 1 1 auto;
}
.input-comp-limited {
  flex: 0 1 33%;
}
.input-comp.date {
  flex: 0 1 auto;
}
.input-comp select,
.input-comp input {
  border: 0;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  height: 35px;
}
.input-comp .mx-datepicker {
  display: block;
  width: 150px;
  max-width: 100%;
}
.filter-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  background-color: var(--blue);
  color: #ffffff;
}
.icon {
  color: var(--gray-dark);
}
.v-modal-container {
  overflow-y: unset !important;
  overflow-x: unset !important;
}
</style>
<style>

.new-calendar-view-datepicker-week,
.new-calendar-view-datepicker {
  position: relative;
  top: 0.6rem;
}

.new-calendar-view-datepicker-week .mx-input-append,
.new-calendar-view-datepicker .mx-input-append {
  display: none;
}

.new-calendar-view-datepicker-week .mx-input,
.new-calendar-view-datepicker .mx-input {
  display: none;
}

.new-calendar-view-datepicker-week .mx-datepicker-popup,
.new-calendar-view-datepicker .mx-datepicker-popup {
  border-radius: 10px;
  border: unset;
}

.calendar-week{
  min-width: 260px;
  position: relative;
  top: 0.7rem;
}

.calendar-month{
  position: relative;
  top: 1rem;
}

.calendar-enter-active,
.calendar-leave-active {
  transition: all 0.2s ease-in-out 0s;
}
.calendar-enter,
.calendar-leave-to {
  opacity: 0;
}
.calendar-enter-to,
.calendar-leave {
  opacity: 1;
}
.calendar-views-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.calendar-views-list label {
  color: var(--primary-bg-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.85rem 1rem;
  margin-bottom: 0;
}
.calendar-views-list li:not(:last-of-type) {
  border-bottom: 1px solid #e2e2e2;
}
.modal-calendar {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.modal-calendar-container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  max-width: 300px;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}
.modal-calendar-container.w-35 {
  max-width: 35%;
  width: 100%;
}
.modal-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}
.close-modal-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--shortcut-round-bg);
  border: 0;
  cursor: pointer;
  font-weight: 900;
  transition: all 0.2s ease-in-out 0s;
  color: var(--primary-bg-header);
}
.close-modal-calendar:hover {
  background-color: var(--primary-bg-header);
  color: white;
}
.calendar-overlay-no-content {
  position: absolute;
  inset: 0;
  background-color: rgba(255 255 255 / 0.8);
  backdrop-filter: blur(3px);
  padding: 2rem;
  z-index: 99;
}
.calendar-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: 600px;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  background-color: white;
}

.calendar-filters.filter-reduced{
  grid-template-columns: unset
}


.calendar-filters.modify {
  grid-template-columns: repeat(1, 1fr);
  min-width: 300px;
}
.calendar-filter-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.calendar-filter-col h5 {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-color);
}
.calendar-legends .legend-square {
  background-color: var(--bg);
  border: 2px solid var(--border);
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .calendar-filters {
    grid-template-columns: 1fr;
    min-width: unset;
  }
}
</style>
<style>
body .vs__selected-options .selected-tag {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
body .input-comp .dropdown-toggle {
  border-bottom: 0 !important;
}
</style>