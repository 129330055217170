<template>
  <label
      :class="['upload-placeholder flex ai-c jc-c text-center mb-2 pointer', dragging && 'active']"
      :style="{
        minHeight: minHeight
      }"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      @drop.prevent="drop"
  >
    <cloud-icon size="100" style="color: #104375" class="mr-4"/>

    <input @input="inputFiles" type="file" name="files" id="files" multiple style="display: none;">

    <div style="color: #104375">
      <h2 class="mb-1">{{ title }}</h2>
      <p class="mb-0 text-thin text-md">Puxe e Arraste ou Clique Aqui</p>
    </div>
  </label>
</template>

<script>
import CloudIcon from '@/components/icons/CloudIcon.vue';

export default {
  name: 'VDragAndDropFiles',
  components: { CloudIcon },
  props: {
    minHeight: {
      type: String,
      default: 'unset',
    },
    title: {
      type: String,
      default: 'Enviar Documentos',
    },
  },
  data() {
    return {
      dragging: false,
    };
  },
  methods: {
    inputFiles(e) {
      this.$emit('input', e.target.files);
    },
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      this.$emit('input', e.dataTransfer.files);
      this.dragging = false;
    },
    dragleave() {
      this.dragging = false;
    },
  },
};
</script>

<style scoped>
.upload-placeholder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23104375' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 25px;

  padding: 1rem 1rem;
  width: 100%;
}

.upload-placeholder.active {
  background-color: rgba(16, 67, 117, 0.7);
}
</style>
