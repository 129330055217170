<template>
  <form id="di-form-upload">
    <div class="form-layout form-layout-2 pd-b-40">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            <i class="icon ion-archive mg-r-3"></i> Upload ( Importação de
            Arquivos )
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Importação de
            arquivos para a Declaração de Importação.
          </p>
        </div>

        <div></div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-6">
          <h6 class="slim-pagetitle mg-b-10">Importação de XMLs</h6>
          <div class="list-group">
            <div class="list-group-item">
              <upload-area-myimp
                uniqueID="upload-area-xml-retorno-registro"
                :url="
                  '/typed/dis/importarXML/' +
                  model.id +
                  '/tipo/RETORNO-REGISTRO'
                "
                :loadData="redirect"
                class="pd-20"
              >
                <div class="media">
                  <div class="d-flex mg-r-10 wd-50">
                    <i class="fa fa-file-code-o tx-primary tx-40-force"></i>
                  </div>
                  <!-- d-flex -->
                  <div class="media-body">
                    <h6 class="tx-inverse">
                      XML de Retorno do Registro da Declaração de Importacão
                    </h6>
                    <p class="mg-b-0">
                      Envio do XML de Retorno do Registro da Declaração de
                      Importacão. Neste XMl contém informações como número de
                      protocolos, datas e registro da DI.
                    </p>
                  </div>
                </div>
              </upload-area-myimp>
            </div>
            <div class="list-group-item">
              <upload-area-myimp
                uniqueID="upload-area-xml-di"
                :url="'/typed/dis/importarXML/' + model.id + '/tipo/REGISTRADA'"
                :loadData="redirect"
                class="pd-20"
              >
                <div class="media">
                  <div class="d-flex mg-r-10 wd-50">
                    <i class="fa fa-file-code-o tx-teal tx-40-force"></i>
                  </div>
                  <!-- d-flex -->
                  <div class="media-body">
                    <h6 class="tx-inverse">
                      XML da Declaração de Importação Registrada
                    </h6>
                    <p class="mg-b-0">
                      Envio do XML de DIs que já foram registradas. É o XML que
                      você encontrar no Siscomex Importacão Web quando entrada
                      em um DI que já foi registrada.
                    </p>
                  </div>
                </div>
              </upload-area-myimp>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <h6 class="slim-pagetitle mg-b-10">Importação de Arquivos</h6>
          <div class="list-group">
            <div class="list-group-item">
              <upload-area-myimp
                uniqueID="upload-area-xml-retorno-registro"
                :url="
                  '/typed/dis/importarXML/' + model.id + '/tipo/EXCEL-ADICAO'
                "
                :loadData="redirect"
                class="pd-20"
              >
                <div class="media">
                  <div class="d-flex mg-r-10 wd-50">
                    <i class="fa fa-file-excel-o tx-success tx-40-force"></i>
                  </div>
                  <!-- d-flex -->
                  <div class="media-body">
                    <h6 class="tx-inverse">Arquivo do Excel com Adições</h6>
                    <p class="mg-b-0">
                      Envio de arquivos do Excel a partir dos modelos pré
                      definidos:
                      <a href="#" class="tx-success"
                        >Excel com NCM Único (Vários itens com mesmo NCM)
                      </a>
                      e o arquivo
                      <a href="#" class="tx-success"
                        >Excel para várias NCMs (Vários itens com vários
                        NCMs)</a
                      >.
                    </p>
                  </div>
                </div>
              </upload-area-myimp>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

import UploadArea from "@/components/utils/upload-area-myimp";

import Swal from "sweetalert2";

export default {
  name: "di-form-complementares",
  props: ["isNewRecord"],
  components: {
    "upload-area-myimp": UploadArea,
  },
  data() {
    return {
      model: {},
      initialModel: {},
    };
  },
  methods: {
    redirect() {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    async diffObjects(fieldName) {
      const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
        fieldName,
      });

      if (response) {
        this.initialModel = {
          ...this.model,
        };
      }
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  watch: {
    activeDI() {
      this.initialModel = {
        ...this.activeDI,
      };
      this.model = {
        ...this.activeDI,
      };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-upload");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.activeDI,
    };
  },
};
</script>

<style scoped>
textarea {
  font-size: 13px !important;
  font-weight: 100 !important;
  font-family: monospace !important;
}

#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
