<template>
  <div>
    <slot name="activator" :on="clickActivator"></slot>

    <transition name="fabmodal">
      <div v-if="open" class="fab-modal">
        <div class="fab-modal-header">
          <div class="fab-modal-title">
            <h3>
              {{ title }}
            </h3>
          </div>
          <div class="fab-modal-close" @click.prevent="open = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>


        <div class="fab-modal-content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'FabModal',
  data() {
    return {
      open: this.handler,
      quoteModal: false,
      indicadoresOperacionalPendencias: {},
      indicadoresOperacionalEncerramento: {},
    };
  },
  props: {
    handler: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    clickActivator() {
      this.open = true;
    },
    requestClose(e) {
      if (e.keyCode === 27) {
        this.open = false;
      }
    },
    ...mapActions('ControlPanelStore', [
      'getTotalLiberacao',
      'getTotalRegistroDI',
      'getTotalCarregamento',
      'getTotalFaturamento',
      'getTotalEncerramento',
      'getIndicadoresEtapas',
      'getIndicadoresCanais',
      'getIndicadoresPeriodosArmazenagem',
      'getAberturasPeriodo',
      'getIndicadoresMovimentacaoProcessual',
      'getIndicadorInconsistencias',
      'defineFirstDateFilter',
      'defineSecondDateFilter',
      'defineCustomersIdFilter',
      'getIndicadoresOperacionaisProducao',
      'getIndicadoresOperacionaisBooking',
      'getIndicadoresOperacionaisTransito',
      'getIndicadoresOperacionaisDesembaraco',
      'getIndicadoresOperacionaisCarregamento',
      'getIndicadoresOperacionaisPendencias',
      'getIndicadoresOperacionaisEncerramento',
    ]),
    getIndicadoresOperacionais() {
      this.getIndicadoresOperacionaisProducao();
      this.getIndicadoresOperacionaisBooking();
      this.getIndicadoresOperacionaisTransito();
      this.getIndicadoresOperacionaisDesembaraco();
      this.getIndicadoresOperacionaisCarregamento();
      this.getIndicadoresOperacionaisPendencias();
      this.getIndicadoresOperacionaisEncerramento();
    },
  },
  computed: {
    ...mapState({
      totalLiberacao: (state) => state.ControlPanelStore.totalLiberacao,
      totalRegistroDI: (state) => state.ControlPanelStore.totalRegistroDI,
      totalCarregamento: (state) => state.ControlPanelStore.totalCarregamento,
      totalFaturamento: (state) => state.ControlPanelStore.totalFaturamento,
      totalEncerramento: (state) => state.ControlPanelStore.totalEncerramento,
      indicadoresEtapas: (state) => state.ControlPanelStore.indicadoresEtapas,
      indicadoresCanais: (state) => state.ControlPanelStore.indicadoresCanais,
      indicadoresPeriodosArmazenagem: (state) => state.ControlPanelStore.indicadoresPeriodosArmazenagem,
      aberturasPeriodo: (state) => state.ControlPanelStore.aberturasPeriodo,
      indicadoresMovimentacaoProcessual: (state) => state.ControlPanelStore.indicadoresMovimentacaoProcessual,
      indicadorInconsistencias: (state) => state.ControlPanelStore.indicadorInconsistencias,
      firstDateFilter: (state) => state.ControlPanelStore.firstDateFilter,
      secondDateFilter: (state) => state.ControlPanelStore.secondDateFilter,
      customersIdFilter: (state) => state.ControlPanelStore.customersIdFilter,
      indicadoresOperacionaisProducao: (state) => state.ControlPanelStore.indicadoresOperacionaisProducao,
      indicadoresOperacionaisBooking: (state) => state.ControlPanelStore.indicadoresOperacionaisBooking,
      indicadoresOperacionaisTransito: (state) => state.ControlPanelStore.indicadoresOperacionaisTransito,
      indicadoresOperacionaisDesembaraco: (state) => state.ControlPanelStore.indicadoresOperacionaisDesembaraco,
      indicadoresOperacionaisCarregamento: (state) => state.ControlPanelStore.indicadoresOperacionaisCarregamento,
      indicadoresOperacionaisPendencias: (state) => state.ControlPanelStore.indicadoresOperacionaisPendencias,
      indicadoresOperacionaisEncerramento: (state) => state.ControlPanelStore.indicadoresOperacionaisEncerramento,
    }),
    firstDatePeriod() {
      return moment(this.firstDateFilter).format('DD/MM/Y');
    },
    secondDatePeriod() {
      return moment(this.secondDateFilter).format('DD/MM/Y');
    },
    indicadoresAndamento() {
      return (
        parseInt(this.indicadoresEtapas.total)
        - parseInt(this.indicadoresEtapas.inLicensing)
      );
    },
  },
  mounted() {
    window.addEventListener('keydown', this.requestClose);
    this.defineSecondDateFilter({
      secondDate: `${moment().format('Y-MM-DD')}T23:59:00`,
    });

    this.getIndicadoresOperacionais();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.requestClose);
  },
  destroyed() {
    this.defineFirstDateFilter({
      firstDate: `${moment().startOf('month').format('Y-MM-DD')}T00:00:00`,
    });
    this.defineSecondDateFilter({
      secondDate: `${moment().format('Y-MM-DD')}T23:59:00`,
    });
    this.defineCustomersIdFilter({ customersId: [] });
  },
};
</script>

<style>
/* Transition */
.fabmodal-enter-active,
.fabmodal-leave-active {
  opacity: 1;
  transition: transform 0.3s ease-in-out 0s;
}

.fabmodal-enter,
.fabmodal-leave-to {
  transform: translate3d(100%, 0, 0);
}

.fabmodal-enter-to,
.fabmodal-leave {
  transform: translate3d(0, 0, 0);
}
/* Transition */

/* Modal */

.fab-modal {
  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 999;
}

.fab-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #1b84e7;
  padding: 10px 20px;
  color: white;
}

.fab-modal-title h3 {
  margin: 0;
}

.fab-modal-close {
  cursor: pointer;
}

.fab-modal-content {
  /* padding: 20px; */
  height: calc(100vh - 80px);
  overflow: auto;
}

/* Modal */
</style>
