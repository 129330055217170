import { render, staticRenderFns } from "./RecentOriginalDocuments.vue?vue&type=template&id=c34067c6&scoped=true"
import script from "./RecentOriginalDocuments.vue?vue&type=script&lang=js"
export * from "./RecentOriginalDocuments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c34067c6",
  null
  
)

export default component.exports