<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24"
       fill="none"
       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
       class="feather feather-chevron-left">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'ChevronLeftIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
