<template>
  <form
    id="di-adicao-form-fornecedor-form"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-20"> Fonecedor / Exportador </label>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Vinculação entre o Comprador e o Vendedor
            </label>

            <div class="row">
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-primary mg-t-0-force">
                  <input
                    name="codigoVinculoImportadorExportador"
                    type="radio"
                    v-model="model.codigoVinculoImportadorExportador"
                    :value="1"
                    v-validate="'required'"
                  />
                  <span> Sem Vinculação </span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-teal mg-t-0-force">
                  <input
                    name="codigoVinculoImportadorExportador"
                    type="radio"
                    v-model="model.codigoVinculoImportadorExportador"
                    :value="2"
                    v-validate="'required'"
                  />
                  <span> Com Vinculação, sem influência no preço </span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="codigoVinculoImportadorExportador"
                    type="radio"
                    v-model="model.codigoVinculoImportadorExportador"
                    :value="3"
                    v-validate="'required'"
                  />
                  <span> Com Vinculação, com influência no preço </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Tipo de Fornecedor </label>

            <div class="row">
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-primary mg-t-0-force">
                  <input
                    name="codigoAusenciaFabricante"
                    type="radio"
                    v-model="model.codigoAusenciaFabricante"
                    :value="1"
                    :disabled="disableFields"
                  />
                  <span> O Fabricante / Produtor é o exportador </span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-teal mg-t-0-force">
                  <input
                    name="codigoAusenciaFabricante"
                    type="radio"
                    v-model="model.codigoAusenciaFabricante"
                    :value="2"
                    :disabled="disableFields"
                  />
                  <span> O Fabricante / Produtor não é o exportador </span>
                </label>
              </div>
              <div class="col-lg-4">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="codigoAusenciaFabricante"
                    type="radio"
                    v-model="model.codigoAusenciaFabricante"
                    :value="3"
                    :disabled="disableFields"
                  />
                  <span> O Fabricante / Produtor é desconhecido </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20"> Exportador / Fornecedor </label>
      <div class="row no-gutters" v-if="!disableFields">
        <div class="col-4">
          <div class="form-group mg-md-l--1 bd-b-0-force bg-gray-200">
            <label class="form-control-label"
            >Pesquisar Exportador / Fornecedor</label
            >
            <v-select
              v-model="model.fabricante"
              :on-search="getExporters"
              label="name"
              :options="optionsExporters"
              name="fabricante"
              @input="setSelectedExportador"
            >
              <template slot="option" slot-scope="option"
              >{{ option.federalID }} | {{ option.name }}
              </template
              >
              <template slot="no-options"> Não encontrado</template>
            </v-select>
          </div>
        </div>
        <div class="col-8"></div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Nome </label>
            <input
              class="form-control"
              type="text"
              v-model="model.nomeFornecedorEstrangeiro"
              name="nomeFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="60"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.nomeFornecedorEstrangeiro !== undefined &&
                model.nomeFornecedorEstrangeiro !== null &&
                model.nomeFornecedorEstrangeiro.length > 60
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Nome</strong> acima de 60 caracteres.</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Logradouro </label>
            <input
              class="form-control"
              type="text"
              v-model="model.enderecoLogradouroFornecedorEstrangeiro"
              name="enderecoLogradouroFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="40"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.enderecoLogradouroFornecedorEstrangeiro !== undefined &&
                model.enderecoLogradouroFornecedorEstrangeiro !== null &&
                model.enderecoLogradouroFornecedorEstrangeiro.length > 40
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Logradouro</strong> acima de 40 caracteres.</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Número </label>
            <input
              class="form-control"
              type="text"
              v-model="model.enderecoNumeroFornecedorEstrangeiro"
              name="enderecoNumeroFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="6"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.enderecoNumeroFornecedorEstrangeiro !== undefined &&
                model.enderecoNumeroFornecedorEstrangeiro !== null &&
                model.enderecoNumeroFornecedorEstrangeiro.length > 6
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Número</strong> acima de 6 caracteres.</span
            >
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Complemento </label>
            <input
              class="form-control"
              type="text"
              v-model="model.enderecoComplementoFornecedorEstrangeiro"
              name="enderecoComplementoFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="21"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.enderecoComplementoFornecedorEstrangeiro !== undefined &&
                model.enderecoComplementoFornecedorEstrangeiro !== null &&
                model.enderecoComplementoFornecedorEstrangeiro.length > 21
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Complemento</strong> acima de 21 caracteres.</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Cidade </label>
            <input
              class="form-control"
              type="text"
              v-model="model.enderecoCidadeFornecedorEstrangeiro"
              name="enderecoCidadeFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="25"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.enderecoCidadeFornecedorEstrangeiro !== undefined &&
                model.enderecoCidadeFornecedorEstrangeiro !== null &&
                model.enderecoCidadeFornecedorEstrangeiro.length > 25
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Cidade</strong> acima de 25 caracteres.</span
            >
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Estado </label>
            <input
              class="form-control"
              type="text"
              v-model="model.enderecoEstadoFornecedorEstrangeiro"
              name="enderecoEstadoFornecedorEstrangeiro"
              :disabled="disableFields"
              maxlength="25"
            />
            <span
              class="parsley-errors-list filled"
              v-if="
                model.enderecoEstadoFornecedorEstrangeiro !== undefined &&
                model.enderecoEstadoFornecedorEstrangeiro !== null &&
                model.enderecoEstadoFornecedorEstrangeiro.length > 25
              "
            >
              <i class="icon ion-flag tx-danger mg-r-5"></i>
              <strong>Estado</strong> acima de 25 caracteres.</span
            >
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force bd-l-0-force">
            <label class="form-control-label"> País de Aquisição </label>
            <v-select
              v-model="model.codigoPaisAquisicaoMercadoria"
              label="value"
              :options="codigosPaises"
              name="codigoPaisAquisicaoMercadoria"
              :disabled="disableFields"
            >
              <template slot="option" slot-scope="option">
                {{ option.value }}
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <template v-if="mostrarFabricante">
        <label class="section-title mg-t-20"> Fabricante / Produtor </label>
        <div class="row no-gutters" v-if="!disableFields">
          <div class="col-4">
            <div class="form-group mg-md-l--1 bd-b-0-force">
              <label class="form-control-label"
              >Pesquisar Fabricante / Produtor</label
              >
              <v-select
                v-model="model.exportador"
                :on-search="getExporters"
                label="name"
                :options="optionsExporters"
                name="exportador"
                @input="setSelectedFabricante"
              >
                <template slot="option" slot-scope="option"
                >{{ option.federalID }} | {{ option.name }}
                </template
                >
                <template slot="no-options"> Não encontrado</template>
              </v-select>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-md-4">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"> Nome </label>
              <input
                class="form-control"
                type="text"
                v-model="model.nomeFabricanteMercadoria"
                name="nomeFabricanteMercadoria"
                :disabled="disableFields"
                maxlength="60"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.nomeFabricanteMercadoria !== undefined &&
                  model.nomeFabricanteMercadoria !== null &&
                  model.nomeFabricanteMercadoria.length > 60
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Nome</strong> acima de 60 caracteres.</span
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"> Logradouro </label>
              <input
                class="form-control"
                type="text"
                v-model="model.enderecoLogradouroFabricante"
                name="enderecoLogradouroFabricante"
                :disabled="disableFields"
                maxlength="40"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.enderecoLogradouroFabricante !== undefined &&
                  model.enderecoLogradouroFabricante !== null &&
                  model.enderecoLogradouroFabricante.length > 40
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Logradouro</strong> acima de 40 caracteres.</span
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"> Número </label>
              <input
                class="form-control"
                type="text"
                v-model="model.enderecoNumeroFabricante"
                name="enderecoNumeroFabricante"
                :disabled="disableFields"
                maxlength="6"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.enderecoNumeroFabricante !== undefined &&
                  model.enderecoNumeroFabricante !== null &&
                  model.enderecoNumeroFabricante.length > 6
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Número</strong> acima de 6 caracteres.</span
              >
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"> Complemento </label>
              <input
                class="form-control"
                type="text"
                v-model="model.enderecoComplementoFabricante"
                name="enderecoComplementoFabricante"
                :disabled="disableFields"
                maxlength="21"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.enderecoComplementoFabricante !== undefined &&
                  model.enderecoComplementoFabricante !== null &&
                  model.enderecoComplementoFabricante.length > 21
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Complemento</strong> acima de 21 caracteres.</span
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"> Cidade </label>
              <input
                class="form-control"
                type="text"
                v-model="model.fabricanteEstrangeiroCidade"
                name="fabricanteEstrangeiroCidade"
                :disabled="disableFields"
                maxlength="25"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.fabricanteEstrangeiroCidade !== undefined &&
                  model.fabricanteEstrangeiroCidade !== null &&
                  model.fabricanteEstrangeiroCidade.length > 25
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Cidade</strong> acima de 25 caracteres.</span
              >
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"> Estado </label>
              <input
                class="form-control"
                type="text"
                v-model="model.enderecoEstadoFabricante"
                name="enderecoEstadoFabricante"
                :disabled="disableFields"
                maxlength="25"
              />
              <span
                class="parsley-errors-list filled"
                v-if="
                  model.enderecoEstadoFabricante !== undefined &&
                  model.enderecoEstadoFabricante !== null &&
                  model.enderecoEstadoFabricante.length > 25
                "
              >
                <i class="icon ion-flag tx-danger mg-r-5"></i>
                <strong>Estado</strong> acima de 25 caracteres.</span
              >
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mg-md-l--1 bd-t-0-force bd-l-0-force">
              <label class="form-control-label"> País de Origem </label>
              <v-select
                v-model="model.codigoPaisOrigemMercadoria"
                label="value"
                :options="codigosPaises"
                name="codigoPaisOrigemMercadoria"
                :disabled="disableFields"
              >
                <template slot="option" slot-scope="option">
                  {{ option.value }}
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </template>

      <hr/>

      <div class="row no-gutters">
        <div class="col-md-6">
          <label class="section-title mg-t-20"> Dados Cambiais </label>
          <div class="row no-gutters">
            <div class="col-md-4">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"> Cobertura Cambial </label>
                <v-select
                  v-model="model.codigoCoberturaCambial"
                  label="value"
                  :options="codigosCoberturaCambial"
                  name="codigoCoberturaCambial"
                  :disabled="disableFields"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>
            <div
              class="col-md-8"
              v-if="
                model.codigoCoberturaCambial !== null &&
                model.codigoCoberturaCambial !== undefined &&
                model.codigoCoberturaCambial.key === '3'
              "
            >
              <div class="form-group bd-l-0-force">
                <label class="form-control-label">
                  Instituição Financiadora
                </label>
                <v-select
                  v-model="model.financiadora"
                  :on-search="getFinanciadoras"
                  label="texto"
                  :options="listOfFinanciadoras"
                  name="financiadora"
                  class="tx-12"
                  :disabled="disableFields"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.codigo }}</strong>
                    {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>
            <div
              class="col-md-8"
              v-if="
                model.codigoCoberturaCambial !== null &&
                model.codigoCoberturaCambial !== undefined &&
                model.codigoCoberturaCambial.key === '4'
              "
            >
              <div class="form-group bd-l-0-force">
                <label class="form-control-label"> Motivo </label>
                <v-select
                  v-model="model.motivo"
                  :on-search="getMotivosDadosCambiais"
                  label="texto"
                  :options="listOfMotivosDadosCambiais"
                  name="motivo"
                  :disabled="disableFields"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.codigo }}</strong>
                    {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6"
          v-if="
            model.codigoCoberturaCambial !== null &&
            model.codigoCoberturaCambial !== undefined &&
            model.codigoCoberturaCambial.key === '3'
          "
        >
          <label class="section-title mg-t-20">
            Prazo superior a 360 dias
          </label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group mg-md-l--1 bd-l-0-force">
                <label class="form-control-label"> Valor </label>
                <money
                  name="valorRof"
                  class="form-control"
                  v-model="model.valorRof"
                  v-bind="money"
                  :disabled="disableFields"
                ></money>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-l-0-force">
                <label class="form-control-label"> Número do ROF/BACEN </label>
                <the-mask
                  mask="###/#####"
                  class="form-control"
                  type="text"
                  v-model="model.nrRof"
                  name="nrRof"
                  :disabled="disableFields"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6"
          v-if="
            model.codigoCoberturaCambial !== null &&
            model.codigoCoberturaCambial !== undefined &&
            model.codigoCoberturaCambial.key === '4'
          "
        >
          <label class="section-title mg-t-20">
            Importação Sem Cobertura Cambial
          </label>
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group bd-l-0-force">
                <label class="form-control-label"> Número do ROF/BACEN </label>
                <the-mask
                  mask="###/#####"
                  class="form-control"
                  type="text"
                  v-model="model.nrRof"
                  name="nrRof"
                  :disabled="disableFields"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {TheMask} from "vue-the-mask";
import {mapState} from "vuex";
import vSelect from "vue-select";
import {Money} from "v-money";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";

export default {
  name: "di-adicao-form-fornecedor",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    TheMask,
    Money,
  },
  data() {
    return {
      initialDI: {},
      di: {},
      initialModel: {},
      model: {},
      codigosPaises: [...importDeclarationLists.codigosPaises],
      codigosCoberturaCambial: [
        ...importDeclarationLists.codigosCoberturaCambial,
      ],
      listOfFinanciadoras: [],
      listOfMotivosDadosCambiais: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    setSelectedExportador(value) {
      if (value !== null && value.id !== null) {
        this.model.nomeFornecedorEstrangeiro = value.name;
        this.model.enderecoLogradouroFornecedorEstrangeiro = value.address;
        this.model.enderecoNumeroFornecedorEstrangeiro = value.addressNumber;
        this.model.enderecoComplementoFornecedorEstrangeiro = value.complement;
        this.model.enderecoCidadeFornecedorEstrangeiro = value.city;
        this.model.enderecoEstadoFornecedorEstrangeiro = value.federalUnity;
        this.model.codigoPaisAquisicaoMercadoria = this.$store.dispatch(
          "findObjectByKey",
          {
            array: this.codigosPaises,
            key: "key",
            value: value.country,
          }
        );
      }
    },
    setSelectedFabricante(value) {
      if (value !== null && value.id !== null) {
        this.model.nomeFabricanteMercadoria = value.name;
        this.model.enderecoLogradouroFabricante = value.address;
        this.model.enderecoNumeroFabricante = value.addressNumber;
        this.model.enderecoComplementoFabricante = value.complement;
        this.model.fabricanteEstrangeiroCidade = value.city;
        this.model.enderecoEstadoFabricante = value.federalUnity;
        this.model.codigoPaisOrigemMercadoria = value.country;
      }
    },
    async diffObjects(fieldName) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      const response = await this.$store.dispatch(
        "MyIMPStore/diffObjectsAdicao"
      );

      if (response) {
        this.initialDI = {
          ...this.di,
        };
      }
    },
    async getFinanciadoras(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarDIForm",
          campoRetorno: "financiadora",
          subAction: "autoCompleteCodigo",
          q: search,
        }
      );

      if (response) {
        this.listOfFinanciadoras = response;
        this.$set(this, "listOfFinanciadoras", response);
      }
    },
    async getMotivosDadosCambiais(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarDIForm",
          campoRetorno: "motivo",
          subAction: "autoCompleteDescricao",
          q: search,
        }
      );

      if (response) {
        this.listOfMotivosDadosCambiais = response;
        this.$set(this, "listOfMotivosDadosCambiais", response);
      }
    },
    getExporters: _.debounce(function (search) {
      this.$store
        .dispatch("ProcessStore/getExporters", search)
        .then((response) => {
        })
        .catch((error) => {
          Swal.fire(
            "Exportadores / Fabricantes",
            "Ops, houve algum erro no carregamento das empresas! ",
            "error"
          );
        });
    }, 500),
  },

  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
      optionsExporters: (state) => state.ProcessStore.optionsExporters,
    }),
    mostrarFabricante() {
      if (
        this.model.codigoAusenciaFabricante === "2" ||
        this.model.codigoAusenciaFabricante === 2
      ) {
        return true;
      }
      return false;
    },
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    disableFields() {
      if (this.model.numeroLI !== null && this.model.numeroLI !== "") {
        return true;
      }
      return false;

      // return false;
    },
  },
  watch: {
    activeDI() {
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-adicao-form-fornecedor-form");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
