<template>
  <div class="contact-form">
    <div class="sp-3 light-bg p-3 bd-b">
      <div :class="[
        'flex ai-c jc-sb fw-w fgap2',
      ]">
        <div>
          <h4 class="mb-0">NPS</h4>
          <p class="mb-0">
            Descrição
          </p>
        </div>
        <div>
          <v-modal
            title="CADASTRAR NPS"
            description="Descrição"
            :width="800"
          >
            <template #activator="{ on }">
              <button
                @click.prevent="on"
                type="button"
                class="plus-button"
              >
                <plus-icon color="#ffffff" />
              </button>
            </template>

            <template #content>
              <div class="contact-form">
                <div class="sp-2">
                  <v-input
                      name="data"
                      type="date"
                      label="Data"
                      v-model="formData.dateNps"
                  ></v-input>
                </div>

                <v-input
                  name="nota"
                  type="number"
                  label="Nota"
                  v-model="formData.nota"
                ></v-input>

                <div class="sp-3">
                  <v-textarea
                    name="motivo"
                    label="Motivo"
                    v-model="formData.motivo"
                    :editor="true"
                  ></v-textarea>
                </div>
              </div>
            </template>

            <template #footer="{ closeModal }">
              <div class="d-flex align-items-center justify-content-end">
                <button class="btn btn-primary" @click.prevent="saveNps(closeModal)">SALVAR</button>
              </div>
            </template>
          </v-modal>
        </div>
      </div>
    </div>

    <div class="sp-3 ph-2" v-if="nps.length > 0">
      <div class="table-overflow">
        <table>
          <thead>
            <tr>
              <th>
                Data
              </th>
              <th>
                Nota
              </th>
              <th>
                Motivo
              </th>
              <th>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in nps" :key="item.id">
              <td>
                {{ item.date | moment('DD/MM/YYYY') }}
              </td>
              <td>
                {{ item.nota }}
              </td>
              <td v-html="item.motivo"></td>
              <td>
                <button class="btn btn-danger btn-sm rounded" @click.prevent="deleteNps(item.id)">Excluir</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card-body pd-40 sp-3 text-center" v-else>
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="">
      <h6 class="tx-md-20 tx-inverse ">Nenhum NPS cadastrado</h6>
      <p>Adicione um NPS clicando no botão + que está acima</p>
    </div>
  </div>
</template>

<script>
import {
  PlusIcon,
} from '@/components/crm/Icons';

import moment from 'moment';

import VModal from '@/components/VModal.vue';
import VInput from '@/components/VInput.vue';
import VTextarea from '@/components/VTextarea.vue';
import config from '@/config.js';
import Swal from 'sweetalert2';

import Axios from 'axios';

export default {
  name: 'CustomerNps',
  components: {
    PlusIcon,

    VModal,
    VInput,
    VTextarea,
  },
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        dateNps: null,
        nota: null,
        motivo: '',
      },
      nps: [],
    };
  },
  async mounted() {
    await this.getNps();
  },
  methods: {
    async getNps() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando NPS do cliente.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/nps/customerNps/${this.customerDetails.id}`);
        this.nps = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Erro interno',
          html: `<p>Não foi possível recuperar as NPS's do cliente</p> <div>${error}</div>`,
          type: 'error',
        });
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async deleteNps(npsId) {
      Swal.fire({
        title: 'Confirme a ação',
        html: '<p>Tem certeza que deseja excluir o NPS?</p>',
        type: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Excluir',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (response) => {
        if (response.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Excluir NPS',
          });

          const response = await Axios.delete(`${config.env.API_DEFAULT.host}/nps/deleteCustomerNps/${npsId}`);
          this.getNps();

          Swal.fire({
            title: 'NPS Excluído!',
            html: '<p>O NPS foi excluído com sucesso!</p>',
            type: 'success',
          });

          this.$store.commit('setLoading', {
            show: false,
          });
        } else {
          Swal.fire({
            title: 'Cancelado!',
            html: '<p>NPS não foi excluído</p>',
            type: 'error',
          });
        }
      });
    },
    async saveNps(action) {
      // Activating Loading
      this.$store.commit('setLoading', {
        show: true,
        label: 'Salvando',
        description: 'Salvando NPS, aguarde...',
      });

      // Formatting data
      const form = this.formData;
      form.dateNps = moment(form.dateNps).format('YYYY-MM-DDTHH:mm:ss');

      try {
        await Axios.post(`${config.env.API_DEFAULT.host}/nps/customerNps/${this.customerDetails.id}`, form);
        await this.getNps();

        action();

        Swal.fire({
          title: 'NPS Adicionada com sucesso!',
          type: 'success',
        });

        this.formData = {
          dateNps: null,
          nota: null,
          motivo: '',
        };
      } catch (error) {
        Swal.fire({
          title: 'Erro interno',
          html: `<p>Não foi possível adicionar o NPS</p> <div>${error}</div>`,
          type: 'error',
        });
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
  .plus-button {
    appearance: none;
    --webkit-appearance: none;
    --moz-appearance: none;
    outline: none;
    --webkit-outline: none;
    --moz-outline: none;
    border: 0;
    background-color: #80B7E2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 0;
    border-radius: 2px;
  }

  .plus-button:hover {
    background-color: #1768a7;
  }

  table {
    width: 100%;
  }

  table tr:not(:last-of-type) td {
    border-bottom: 1px solid #e2e2e2;
  }

  table tr td,
  table tr th {
    padding: 20px 10px;
    color: #343a40;
    text-align: center;
  }

  table tr th {
    background-color: #8392A51A;
  }
</style>
