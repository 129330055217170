var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-mainpanel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"slim-pageheader"},[_c('ol',{staticClass:"breadcrumb slim-breadcrumb"},[_vm._m(0),_vm._m(1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.mainTitle)+" ")])]),_c('h6',{staticClass:"slim-pagetitle"},[_vm._v(_vm._s(_vm.modelTitle))])]),_c('div',{staticClass:"card card-table"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"report-summary-header mg-t-0 mg-b-0"},[_c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3"},[_vm._v("Listagem de "+_vm._s(_vm.modelTitle))])]),_c('div',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'chartOfAccounts-form',
                params: { action: 'create' },
              }},on:{"click":function($event){_vm.modal = true}}},[_c('i',{staticClass:"icon ion-edit tx-22"}),_vm._v(" ADICIONAR ")])],1)])]),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.projects),function(project){return _c('li',{key:project.id,staticClass:"list-group-item"},[_c('div',{staticClass:"row d-flex align-items-center list-group-subitem"},[_c('div',{staticClass:"col-md-2"},[_c('p',{staticClass:"mg-b-0"},[_c('i',{staticClass:"fa fa-file-text-o tx-primary mg-r-8"}),_c('strong',{staticClass:"tx-inverse tx-medium pd-r-60"},[_vm._v(_vm._s(project.year))])])]),_c('div',{staticClass:"col-md-3"},[_c('p',{staticClass:"mg-b-0"},[_c('i',{staticClass:"fa fa-circle mg-r-8",class:_vm.projectStatusColor(project.status)}),_c('strong',{staticClass:"tx-inverse tx-medium pd-r-60"},[_vm._v(_vm._s(_vm.projectStatusText(project.status)))])])]),_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"mg-b-0"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(project.description))])])]),_c('div',{staticClass:"col-md-3 d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-primary btn-icon rounded-circle mg-r-5",attrs:{"to":{
                    name: 'chartOfAccounts-form',
                    params: { action: 'update', id: project.id },
                  }}},[_c('div',[_c('i',{staticClass:"icon ion-edit"})])]),_c('a',{staticClass:"btn btn-warning btn-icon rounded-circle mg-r-5",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.duplicate(project.id)}}},[_vm._m(2,true)]),_c('a',{staticClass:"btn btn-danger btn-icon rounded-circle mg-r-5",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(project.id)}}},[_vm._m(3,true)])],1)])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Financeiro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-clone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon ion-trash-a"})])
}]

export { render, staticRenderFns }