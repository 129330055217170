import moment from 'moment';
import Axios from 'axios';
import config from '@/config.js';
import Receipt from '@/models/Receipt.js';

export default {

  createNewModelReceipt(appointment) {
    return new Receipt(
      null,
      null,
      appointment.description,
      appointment.typeOfMovimentation,
      appointment.totalValue !== null ? appointment.totalValue : 0.00,
      appointment.discountValue !== null ? appointment.discountValue : 0.00,
      appointment.fineValue !== null ? appointment.fineValue : 0.00,
      appointment.additionsValue !== null ? appointment.additionsValue : 0.00,
      moment(new Date()).add(1, 'days'),
      appointment.totalValue !== null ? appointment.totalValue : 0.00,
      null,
      null,
      null,
      appointment.company,
      appointment.account,
      appointment,
      appointment.moviment,
    );
  },

  removeReceipt(receipt, swal, loading, success, error) {
    const self = this;

    swal({
      title: 'Excluir Baixa',
      text: `Você realmente deseja excluir a baixa de parcela: ${receipt.description}?`,
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        loading();

        if (receipt.id !== null && receipt.id !== undefined) {
          Axios.delete(`${config.env.API_DEFAULT.host}/receipts/${receipt.id}`)
            .then((r) => {
              success();
            })
            .catch((e) => {
              error();
            });
        }
      }
    });
  },
};
