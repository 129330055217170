<template>
  <Drawer @close="$emit('closeDrawer')">
    <template slot="header">Auditoria do Processo</template>

    <template slot="body">
      <div class="card card-activities">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              @click="getHistoricoEtapas()"
              href="#historico-etapas"
              data-toggle="tab"
            >
              Histórico das Etapas
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'auditoria'"
              href="#auditoria"
              data-toggle="tab"
            >
              Auditoria do Processo
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="getLogEnvioEncerramentos()"
              href="#encerramentos"
              data-toggle="tab"
            >
              Envio de Encerramentos
            </a>
          </li>
        </ul>
        <div
          class="tab-pane mt-4"
          v-if="tab == 'encerramentos'"
          id="encerramentos"
        >
          <h6 class="slim-card-title">Envio de Encerramentos</h6>
          <p>
            Foram encontrados {{ listOfFinacialClosingLog.length }} logs de
            envio de encerramento
          </p>
          <div class="table-responsive">
            <perfect-scrollbar
              class="imp-form-drawers-followups-logs-scrollbar h100p"
            >
              <table class="table mg-b-0 tx-13">
                <thead>
                <tr class="tx-10">
                  <th class="pd-y-5 tx-center">Data</th>
                  <th class="pd-y-5 tx-center">Contatos</th>
                  <th class="pd-y-5 tx-center">Contatos (cc)</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(log, index) in listOfFinacialClosingLog">
                  <tr :key="'encerramentos-log-' + index">
                    <td>
                      {{ log.created | moment("DD \\d\\e MMMM \\d\\e YYYY") }}
                      às {{ log.created | moment("H:mm:ss") }}
                      <span class="tx-11 d-block">{{ log.createdBy }}</span>
                    </td>
                    <td class="">{{ log.contacts }}</td>
                    <td class="">{{ log.contactsCC }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="tab-pane mt-4" v-if="tab == 'auditoria'" id="auditoria">
          <h6 class="slim-card-title">Auditoria do Processo</h6>
          <p>Foram encontrados {{ listOfLog.length }} logs de processos</p>
          <div class="table-responsive">
            <perfect-scrollbar
              class="imp-form-drawers-followups-logs-scrollbar h100p"
            >
              <table class="table mg-b-0 tx-13">
                <thead>
                <tr class="tx-10">
                  <th class="pd-y-5 tx-center">Data</th>
                  <th class="pd-y-5 tx-center">Campo / Atributo</th>
                  <th class="pd-y-5 tx-center">Valor Anterior</th>
                  <th class="pd-y-5 tx-center">Valor Atual</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingHistories">
                  <td class="text-center text-success" colspan="4">
                    Carregando...
                  </td>
                </tr>
                <tr v-else-if="histories.length === 0">
                  <td class="text-center" colspan="4">
                    Nenhuma alteração de etapa encontrada.
                  </td>
                </tr>
                <template v-for="(log, index) in listOfLog" v-else>
                  <tr
                    :key="'log-changes-' + index"
                    v-if="!listChanges.includes(log.propertyName)"
                  >
                    <td>
                      {{
                        log.commitMetadata.commitDate
                          | moment("DD \\d\\e MMMM \\d\\e YYYY")
                      }}
                      às
                      {{ log.commitMetadata.commitDate | moment("H:mm:ss") }}
                      <span class="tx-11 d-block">{{
                          log.commitMetadata.author
                        }}</span>
                    </td>
                    <td class="tx-bold tx-center">{{ log.propertyName }}</td>
                    <td class="">{{ log.left }}</td>
                    <td class="">{{ log.right }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </div>
        <div
          class="tab-pane mt-4"
          v-if="tab == 'historico-etapas'"
          id="historico-etapas"
        >
          <h6 class="slim-card-title">Histórico de alteração das etapas</h6>
          <p>Foram encontradas {{ histories.length }} alterações nas etapas</p>
          <div class="table-responsive">
            <perfect-scrollbar
              class="imp-form-drawers-followups-logs-scrollbar h100p"
            >
              <table class="table table-hover mg-b-0 tx-13">
                <thead>
                <tr class="tx-10">
                  <th class="pd-y-5 tx-center">Data</th>
                  <th class="pd-y-5 tx-center">Etapa anterior</th>
                  <th class="pd-y-5 tx-center">Alterado para</th>
                  <th class="pd-y-5 tx-center">Usuário</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingHistories">
                  <td class="text-center text-success" colspan="4">
                    Carregando...
                  </td>
                </tr>
                <tr v-else-if="histories.length === 0">
                  <td class="text-center" colspan="4">
                    Nenhuma alteração de etapa encontrada.
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="history in histories" :key="history.id">
                    <td>
                      {{ history.changeDate | moment("DD/MM/YYYY H:mm:ss") }}
                    </td>
                    <td>{{ history.previousStep.alias }}</td>
                    <td>{{ history.currentStep.alias }}</td>
                    <td>{{ history.user.name }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <button
        type="button"
        @click="$emit('closeDrawer')"
        class="btn btn-primary"
      >
        Fechar
      </button>
    </template>
  </Drawer>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import moment from "moment";
import {mapState} from "vuex";
import Drawer from "@/components/Drawer.vue";
import {EventBus} from "@/events/EventBus.js";

export default {
  name: "imp-form-drawers-followups-logs",
  props: [],
  components: {
    Drawer,
  },
  data() {
    return {
      tab: "historico-etapas",
      listOfLog: [],
      listOfFinacialClosingLog: [],
      histories: [],
      loadingHistories: false,
      listChanges: [
        "statusDateOfLastModify",
        "jsonDeclaracaoImportacaoDigitada",
        "jsonDataCustomer",
        "importDeclaration",
        "documents",
      ],
    };
  },
  methods: {
    load() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Log dos Processos",
        description: "Carregando...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/audit/processes/import/${this.process.id}`
      )
        .then((response) => {
          this.listOfLog = response.data.data;
        })
        .catch((error) => {
          Swal.fire(
            "Log dos Processos",
            `Não foi possível carregar o Log dos Processos: ${error}`,
            "error"
          );
        });

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    getHistoricoEtapas() {
      this.tab = "historico-etapas";
      this.loadingHistories = true;
      Axios.get(
        `${config.env.API_DEFAULT.host}/processStepHistory/getHistoriesByProcessId/${this.process.id}`
      )
        .then((response) => {
          this.loadingHistories = false;

          this.histories = response.data.data;
        })
        .catch((error) => {
          this.loadingHistories = false;
          Swal.fire(
            "Log dos Processos",
            `Não foi possível carregar o Log dos Processos: ${error}`,
            "error"
          );
        });
    },
    getLogEnvioEncerramentos() {
      this.tab = "encerramentos";

      this.loadingHistories = true;
      Axios.get(
        `${config.env.API_DEFAULT.host}/audit/processes/import/${this.process.id}/finacial-closing`
      )
        .then((response) => {
          this.loadingHistories = false;
          this.listOfFinacialClosingLog = response.data.data;
        })
        .catch((error) => {
          this.loadingHistories = false;
          Swal.fire(
            "Log de Envio de Emails do Encerramento",
            `Não foi possível carregar o Log de Envio de Emails do Encerramento ${error}`,
            "error"
          );
        });
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
  },
  watch: {
    process() {
      this.load();
    },
  },
  created() {
    this.load();
  },
  mounted() {
    this.getHistoricoEtapas();

    if (this.process.id !== null && this.process.id !== undefined) {
      this.load();
    }
  },
};
</script>

<style scoped>
.imp-form-drawers-followups-logs-scrollbar {
  height: 70vh;
  overflow: auto;
}
</style>
