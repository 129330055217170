<template>
  <div>
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Documentos do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Visualização do
          documentos enviados e upload de novos documentos para o processo.
        </p>
      </div>
      <div></div>
    </div>

    <div class="row">
      <div class="card wd-100p">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#documentos-enviados"
                data-toggle="tab"
                @click="loadDocuments()"
              >
                <i class="fa fa-file-text-o mg-r-2"></i> Documentos Enviados
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#documentos-enviar" data-toggle="tab">
                <i class="fa fa-upload mg-r-2"></i> Enviar Documentos
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content ht-100p">
            <div class="tab-pane active" id="documentos-enviados">
              <div class="report-summary-header mg-b-5 mg-t-0">
                <div>
                  <h4 class="tx-inverse mg-b-3">Documentos Enviados</h4>
                  <p class="mg-b-0">
                    <i class="icon ion-information-circled mg-r-3"></i> Foram
                    encontrados {{ documents.length }} documentos que foram
                    enviados para este processo.
                  </p>
                </div>
                <div>
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pesquisar"
                      v-model="documentSearch"
                    />
                    <button class="btn btn-primary">
                      <i class="fa fa-search" style="margin-left: -5px"></i>
                    </button>
                  </div>
                </div>
              </div>

              <hr />

              <div class="mg-l-0 row">
                <template v-if="documentSearch != ''">
                  <div class="col-12" v-if="listOfDocuments.length == 0">
                    <div
                      class="bd-l bd-3 bd-danger bg-gray-200 pd-x-20 pd-y-25"
                    >
                      <h5 class="tx-danger">Pesquisa dos Documentos</h5>
                      <p class="mg-b-0">
                        Nenhum documento que contenha a Descrição ou Tipo
                        <strong>{{ documentSearch }}</strong> foi encontrado.
                      </p>
                    </div>
                  </div>
                  <div
                    class="list-group list-group-striped list-group-user col-12"
                  >
                    <div
                      v-for="document in listOfDocuments"
                      :key="document.id"
                      class="list-group-item"
                    >
                      <div
                        class="user-name-address"
                        @click="download(document)"
                      >
                        <p>
                          <i class="icon ion-android-attach"></i>
                          {{ document.description }}
                        </p>
                        <span>
                          <i class="fa fa-flag tx-gray-500"></i>
                          {{ document.documentTypeName }}
                        </span>
                        <small
                          v-if="
                            document.legacyCreated !== null &&
                            document.legacyCreatedBy !== null
                          "
                        >
                          Enviando em
                          {{
                            document.legacyCreated
                              | moment("DD/MM/YY à\\s H:MM:ss")
                          }}
                          por {{ document.legacyCreatedBy }}
                        </small>
                        <small v-else>
                          Enviando em
                          {{
                            document.created | moment("DD/MM/YY à\\s H:MM:ss")
                          }}
                          por {{ document.createdBy }}
                        </small>
                      </div>
                      <div class="user-btn-wrapper tx-26">
                        <a
                          href="#"
                          class="mg-r-10"
                          @click.stop="download(document)"
                          ><i class="fa fa-download tx-primary"></i
                        ></a>
                        <a href="#" @click.stop="remover(document)"
                          ><i class="icon ion-trash-b tx-danger"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    v-for="documentType in listOfDocuments"
                    :key="documentType.id"
                    class="pd-t-10 dropdown dropdown-c col-4 mg-t-20 mg-b-0 mg-md-t-0-force bd-b pd-b-10"
                  >
                    <label class="section-label tx-13 tx-gray-700">
                      <i
                        class="fa fa-check tx-success mg-r-8"
                        v-if="
                          documentType.docs !== undefined &&
                          documentType.docs.length > 0
                        "
                      ></i>
                      <i class="fa fa-check tx-danger mg-r-8" v-else></i>
                      {{ documentType.description }}
                    </label>

                    <div
                      class="card-footer pd-y-5 pd-x-10 bd-t bd-gray-300 bd"
                      data-toggle="dropdown"
                    >
                      <a href="#" class="tx-11 tx-uppercase"
                        >Visualizar Documentos ({{
                          documentType.docs !== undefined
                            ? documentType.docs.length
                            : "0"
                        }}) <i class="fa fa-angle-down"></i
                      ></a>
                    </div>

                    <div
                      class="file-group dropdown-menu dropdown-menu-left pd-0 wd-600 bd-0"
                    >
                      <div
                        class="card-header d-flex align-items-center justify-content-between pd-y-20 bd-gray-400 bd-2 bd-b-gray-800 bg-gray-400"
                      >
                        <h6 class="mg-b-0 tx-13 tx-inverse">
                          {{ documentType.description }}
                        </h6>
                      </div>

                      <template
                        v-if="
                          documentType.docs !== undefined &&
                          documentType.docs.length > 0
                        "
                      >
                        <perfect-scrollbar style="max-height: 300px !important">
                          <div
                            class="file-item bd-1 bd-gray-200 bg-gray-100 pd-t-10 pd-b-10 ht-70"
                            v-for="doc in documentType.docs"
                            :key="'doc-' + doc.id"
                          >
                            <div class="row no-gutters wd-100p">
                              <div
                                class="col-10 mg-t-5 mp-hand"
                                @click="download(doc)"
                              >
                                <i class="fa fa-file"></i>
                                <a href="#"> {{ doc.description }}</a>
                                <span class="tx-right tx-12 tx-thin mg-l-10"
                                  >({{ doc.documentTypeName }})</span
                                >
                              </div>

                              <div class="col-2 tx-right tx-26">
                                <a
                                  href="#"
                                  class="mg-r-5"
                                  @click.stop="download(doc)"
                                  ><i class="fa fa-download tx-primary"></i
                                ></a>
                                <a href="#" @click.stop="remover(doc)"
                                  ><i class="icon ion-trash-b tx-danger"></i
                                ></a>
                              </div>

                              <small
                                class="col-12"
                                v-if="
                                  doc.legacyCreated !== null &&
                                  doc.legacyCreatedBy !== null
                                "
                              >
                                Enviando em
                                {{
                                  doc.legacyCreated
                                    | moment("DD/MM/YY à\\s H:MM:ss")
                                }}
                                por {{ doc.legacyCreatedBy }}
                              </small>
                              <small class="col-12" v-else>
                                Enviando em
                                {{
                                  doc.created | moment("DD/MM/YY à\\s H:MM:ss")
                                }}
                                por {{ doc.createdBy }}
                              </small>
                            </div>
                          </div>
                        </perfect-scrollbar>
                      </template>
                      <template v-else>
                        <div
                          class="file-item bd-1 bd-gray-200 bg-gray-100 pd-t-10 pd-b-10 ht-70"
                        >
                          Nenhum documento foi enviado.
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="tab-pane" id="documentos-enviar">
              <upload-documentos></upload-documentos>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import { EventBus } from "@/events/EventBus.js";
import Swal from "sweetalert2";

import FormDocs from "@/views/operational/imp/Form.Docs.Upload.vue";
import downloadjs from "downloadjs";

export default {
  name: "operational-importation-form-documents",
  components: {
    "upload-documentos": FormDocs,
  },
  data() {
    return {
      documents: [],
      headers: {
        Authorization: `Bearer ${this.$store.state.credentials.accessToken}`,
      },
      documentSearch: "",
    };
  },
  methods: {
    loadDocuments() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Documentos",
        description: "Carregando...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/documents`
      )
        .then((response) => {
          this.documents = response.data._embedded.documents.filter(doc => doc.deletedAt === null && (doc.deletedBy === null || doc.deletedBy === ''));
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
        });

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    downloadManager(id, name, type) {
      if (type === "application/pdf" || type === "application/octet-stream") {
        Axios.post(`${config.env.API_DEFAULT.host}/documents/download/${id}`)
          .then((response) => {
            if (response.data.code === "ERROR") {
              Swal.fire(
                "Download de Documento",
                response.data.message,
                "error"
              );
            } else {
              const binaryString = window.atob(response.data);
              const binaryLen = binaryString.length;
              const bytes = new Uint8Array(binaryLen);

              for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
              }

              const blob = new Blob([bytes], { type });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
            }
          })
          .catch((error) => {
            Swal.fire("Download de Documento", error.message, "error");
          });
      } else {
        Axios.post(
          `${config.env.API_DEFAULT.host}/documents/download/${id}`,
          null,
          { responseType: "blob" }
        )
          .then((response) => {
            if (response.data.code === "ERROR") {
              Swal.fire(
                "Download de Documento",
                response.data.message,
                "error"
              );
            } else {
              downloadjs(response.data, name);
            }
          })
          .catch((error) => {
            Swal.fire("Download de Documento", error.message, "error");
          });
      }
    },
    download(doc) {
      let filename = "";
      if (doc.originalFilename !== null && !doc.originalFilename) {
        filename = doc.originalFilename;
      } else {
        filename = doc.description;
      }

      this.downloadManager(doc.id, filename, doc.fileType);
    },
    remover(documento) {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Se você excluir o documento  ${documento.description}, não será possível recuperá-lo para uso posterior.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((response) => {
        if (response.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Excluindo Documento",
            description: "Excluindo...",
          });

          Axios.post(
            `${config.env.API_DEFAULT.host}/documents/delete/${documento.id}`
          )
            .then((result) => {
              this.$store.commit("setLoading", { show: false });
              this.loadDocuments();
              if (result.data.code === "SUCCESS") {
                Swal.fire("Excluir Documento", result.data.message, "success");
              } else if (result.data.code === "ERROR") {
                Swal.fire("Excluir Documento", result.data.message, "error");
              } else {
                Swal.fire("Excluir Documento", result.data.message, "error");
              }
            })
            .catch((error) => {
              this.$store.commit("setLoading", { show: false });
              Swal.fire("Excluir Documento", error.data.message, "error");
            });
        }
      });
    },
  },

  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      documentsTypes: (state) => state.ProcessStore.documentsTypes,
    }),
    listOfDocuments() {
      let documents = [];

      const { documentsTypes } = this;

      if (documentsTypes !== undefined && documentsTypes.length > 0) {
        if (this.documents !== undefined && this.documents.length > 0) {
          documents = documentsTypes.flatMap((type) => {
            const docs = this.documents.filter(
              (doc) => type.description === doc.documentTypeName
            );

            type.docs = docs;

            return type;
          });
        } else {
          documents = documentsTypes.filter((type) => type);
        }
      }

      if (this.documents.length > 0 && this.documentSearch != "") {
        return this.documents.filter(
          (item) =>
            item.description.toString().search(this.documentSearch) > -1 ||
            item.documentTypeName.toString().search(this.documentSearch) > -1
        );
      }
      return documents.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
    },
  },
  mounted() {
    const self = this;

    try {
      this.model = {
        ...this.process,
      };

      this.$store.dispatch("ProcessStore/getDocumentsType");
      this.loadDocuments();
    } catch (err) {
      this.$swal(
        "Documentos",
        "Não foi possível carregar a lista de documentos.",
        "error"
      );
    }
  },
  created() {
    this.model = {
      ...this.process,
    };

    this.documents = [];

    EventBus.$on("Operational.imp.Form.Docs.load", () => {
      this.loadDocuments();
    });
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.example-full {
  height: 100vh;
}

.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}

.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}

.example-full label.dropdown-item {
  margin-bottom: 0;
}

.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}

.example-full .filename {
  margin-bottom: 0.3rem;
}

.example-full .btn-is-option {
  margin-top: 0.25rem;
}

.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.example-full .edit-image img {
  max-width: 100%;
}

.example-full .edit-image-tool {
  margin-top: 0.6rem;
}

.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}

.example-full .footer-status {
  padding-top: 0.4rem;
}

.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
