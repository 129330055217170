<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0"
        >Dados Gerais de {{ mainTitle }}</label
      >

      <div class="row no-gutters">
        <div class="col-md-1">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Habilitada </label>
            <div class="form-control">
              <toggle-button
                :sync="true"
                :labels="{ checked: 'SIM', unchecked: 'NÃO' }"
                :height="14"
                :color="{ unchecked: '#bf0909' }"
                v-model="movimentTerm.enabled"
              />
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3">
                <div class="form-group mg-md-l--1">
                    <label class="form-control-label"> Descrição Curta <span class="tx-danger">*</span></label>
                    <input v-model="movimentTerm.shortname" name="shortname" type="text" placeholder="Digite uma descrição" class="form-control" v-validate="'required'" maxlength="20">
                    <span v-show="errors.has('shortname')" class="parsley-errors-list filled">Descrição Curta é um campo obrigatório.</span>
                </div>
            </div> -->
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Código<span class="tx-danger">*</span></label
            >
            <input
              v-model="movimentTerm.code"
              name="code"
              type="text"
              placeholder="Digite um código"
              class="form-control"
              v-validate="'required'"
            />
            <span v-show="errors.has('code')" class="parsley-errors-list filled"
              >Código é um campo obrigatório.</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Descrição<span class="tx-danger">*</span></label
            >
            <input
              v-model="movimentTerm.description"
              name="description"
              type="text"
              placeholder="Digite uma descrição"
              class="form-control"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('description')"
              class="parsley-errors-list filled"
              >Descrição é um campo obrigatório.</span
            >
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Disponível em </label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-orange" v-if="!isCategories">
                <input
                  type="checkbox"
                  id="enabledToIncome"
                  name="enabledToIncome"
                  v-model="movimentTerm.enabledToIncome"
                /><span>Recebimento</span>
              </label>

              <label class="ckbox ckbox-primary" v-if="!isCategories">
                <input
                  type="checkbox"
                  id="enabledToExpense"
                  name="enabledToExpense"
                  v-model="movimentTerm.enabledToExpense"
                /><span>Pagamento</span>
              </label>
              <label class="ckbox ckbox-success" v-if="isDocuments">
                <input
                  type="checkbox"
                  id="enabledToEvent"
                  name="enabledToEvent"
                  v-model="movimentTerm.enabledToEvent"
                /><span>Eventos</span>
              </label>

              <label
                class="ckbox ckbox-danger"
                v-if="isDocuments || isCategories"
              >
                <input
                  type="checkbox"
                  id="enabledToImport"
                  name="enabledToImport"
                  v-model="movimentTerm.enabledToImport"
                /><span>Importação</span>
              </label>
              <label
                class="ckbox ckbox-info"
                v-if="isDocuments || isCategories"
              >
                <input
                  type="checkbox"
                  id="enabledToExport"
                  name="enabledToExport"
                  v-model="movimentTerm.enabledToExport"
                /><span>Exportação</span>
              </label>
              <label class="ckbox ckbox-info" v-if="isDocuments">
                <input
                  type="checkbox"
                  id="enabledToExport"
                  name="enabledToExport"
                  v-model="movimentTerm.enabledToCRM"
                /><span>CRM</span>
              </label>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-4 col-sm-4">
                <div class="form-group mg-md-l--1 bd-b-0-force">
                    <label class="form-control-label"> Unidade</label>
                    <v-select v-model="movimentTerm.account" :on-search="getAccounts" label="name" :options="accounts" name="account" v-validate="'required'">
                        <template slot="option" slot-scope="option">
                            {{ option.federalID }} | {{ option.name }}
                        </template>
                    </v-select>
                    <span v-show="errors.has('account')" class="parsley-errors-list filled">Filial é um campo obrigatório.</span>

                    <select v-model="movimentTerm.account">
                        <option v-bind:value="account" v-for="account in listOfAccounts" :key="account.id"> {{account.federalUnity}} - {{account.name}} ({{account.federalID}})</option>
                    </select>
                </div>
            </div> -->

        <!--
            <div class="col-md-4 col-sm-4">
                <div class="form-group mg-md-l--1 ">
                    <label class="form-control-label">Categoria:</label>
                    <select>
                        <option value="" v-for="category in categories" :key="category.key"> {{category.value}}</option>
                    </select>
                    <span v-show="errors.has('category')" class="parsley-errors-list filled">Selecione a Categoria</span>
                </div>
            </div> -->
      </div>

      <label class="section-title mg-t-20"
        >Mapeamento Integração c/ Empresas ( DE / PARA )</label
      >
      <div class="row no-gutters">
        <table class="table table-striped mg-b-0">
          <thead>
            <tr>
              <th class="tx-center wd-100">Código da Empresa</th>
              <th class="tx-center">Código do Item</th>
              <th class="tx-center">Descrição do Item</th>
              <th class="tx-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr-inline
              v-for="item in mappingFromToIntegration"
              :key="item"
              :row="item"
              @save="updateFromToIntegration"
            >
              <td-inline
                v-model="item.integrationCode"
                type="select"
                selectType="key|value"
                :options="[...companiesWithIntegration]"
                :width="400"
              />
              <td-inline v-model="item.code" />
              <td-inline v-model="item.description" />
              <template slot="actions">
                <a
                  href="#"
                  @click.prevent="removeFromToIntegration(item)"
                  class="btn btn-danger btn-icon rounded-circle"
                >
                  <div><i class="icon ion-trash-a"></i></div>
                </a>
              </template>
            </tr-inline>
            <tr-inline
              class="new-item"
              :isNew="true"
              :row="newFromToIntegration"
              @save="createFromToIntegration"
            >
              <td-inline
                v-model="newFromToIntegration.integrationCode"
                type="select"
                selectType="key|value"
                :options="[...companiesWithIntegration]"
                :width="400"
              />
              <td-inline v-model="newFromToIntegration.code" type="string" />
              <td-inline
                v-model="newFromToIntegration.description"
                type="string"
              />
            </tr-inline>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mg-t-15">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click="redirect"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import { mapState } from "vuex";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import Swal from "sweetalert2";

import * as constants from "@/consts.js";

export default {
  name: "movimentTerms-form-general",
  props: ["movimentTerm", "isNewRecord", "mainTitle", "oldModel", "toMap"],
  components: {
    ToggleButton,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      btnSaveDisabled: true,
      accounts: [],
      isDocuments: false,
      isPayments: false,
      isCostsCenters: false,
      isCategories: false,
      categories: [],
      companiesWithIntegration: [],
      mappingFromToIntegration: [],
      newFromToIntegration: {
        integrationCode: null,
        code: null,
        description: null,
      },
    };
  },
  methods: {
    redirect() {
      if (this.isDocuments) {
        this.$router.push({
          name: "movimentTerms-list",
          params: { type: "documents" },
        });
      } else if (this.isPayments) {
        this.$router.push({
          name: "movimentTerms-list",
          params: { type: "payments" },
        });
      } else if (this.isCostsCenters) {
        this.$router.push({
          name: "movimentTerms-list",
          params: { type: "costsCenters" },
        });
      } else if (this.isCategories) {
        this.$router.push({
          name: "movimentTerms-list",
          params: { type: "categories" },
        });
      }
    },
    onSubmit() {
      const self = this;
      self.$validator.validate().then((result) => {
        if (result) {
          if (self.isNewRecord) {
            self.addModel();
          } else {
            self.updateModel();
          }
        }
      });
    },
    addModel() {
      const self = this;

      if (self.isDocuments) {
        self.movimentTerm.type = "DOCUMENT";
      } else if (self.isPayments) {
        self.movimentTerm.type = "PAYMENT";
      } else if (self.isCostsCenters) {
        self.movimentTerm.type = "COST_CENTER";
      } else if (self.isCategories) {
        self.movimentTerm.type = "CATEGORY";
      }
      self.manageModel();

      this.$store.commit("setLoading", {
        show: true,
        label: this.mainTitle,
        description: "Atualizando, aguarde...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/documents/checkValidDocumentCode/${this.movimentTerm.code}`
      )
        .then((response) => {
          Axios.post(
            `${config.env.API_DEFAULT.host}/movimentTerms`,
            self.movimentTerm
          )
            .then((response) => {
              this.$store.commit("setLoading", {
                show: false,
              });

              if (response.status === 201) {
                Swal.fire(
                  self.mainTitle,
                  `${response.data.description} foi cadastrada com sucesso no banco de dados.`,
                  "success"
                );
                self.$router.push({
                  name: "movimentTerms-form",
                  params: {
                    type: self.$route.params.type,
                    action: "update",
                    id: response.data.id,
                  },
                });
              } else {
                Swal.fire(
                  self.mainTitle,
                  `${response.data.description} não foi cadastrada no banco de dados.`,
                  "error"
                );
              }

              this.$store.commit("setLoading", {
                show: false,
              });
            })
            .catch((error) => {
              this.$store.commit("setLoading", {
                show: false,
              });

              Swal.fire({
                title: `Não foi possível cadastrar ${self.movimentTerm.description}., \n\n${error}`,
                showConfirmButton: false,
                timer: 4000,
                position: "top-right",
                icon: "error",
              });
            });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire(
            "Cadastro de Tipo de Documento",
            error.response.data.message,
            "error"
          );
        });
    },
    updateModel() {
      const self = this;

      this.$store.commit("setLoading", {
        show: true,
        label: this.mainTitle,
        description: "Atualizando, aguarde...",
      });

      self.manageModel();

      Axios.patch(
        `${config.env.API_DEFAULT.host}/movimentTerms/${self.movimentTerm.id}`,
        self.movimentTerm
      )
        .then((response) => {
          Swal.fire(
            self.mainTitle,
            `${self.movimentTerm.description} foi atualizado com sucesso no banco de dados.`,
            "success"
          );

          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          Swal.fire(
            self.mainTitle,
            ` Não foi possível atualizar ${self.movimentTerm.description} no banco de dados.`,
            "error"
          );

          this.$store.commit("setLoading", {
            show: false,
          });
        });
    },
    manageMovimentTerms() {
      if (this.$route.params.type === "documents") {
        this.isPayments = false;
        this.isCostsCenters = false;
        this.isCategories = false;
        this.isDocuments = true;
      } else if (this.$route.params.type === "payments") {
        this.isDocuments = false;
        this.isCostsCenters = false;
        this.isCategories = false;
        this.isPayments = true;
      } else if (this.$route.params.type === "costsCenters") {
        this.isDocuments = false;
        this.isPayments = false;
        this.isCategories = false;
        this.isCostsCenters = true;
      } else if (this.$route.params.type === "categories") {
        this.isDocuments = false;
        this.isPayments = false;
        this.isCostsCenters = false;
        this.isCategories = true;
      }

      if (
        this.movimentTerm.mappingFromToIntegration !== null &&
        this.movimentTerm.mappingFromToIntegration !== undefined &&
        this.movimentTerm.mappingFromToIntegration.length > 0
      ) {
        this.mappingFromToIntegration = JSON.parse(
          this.movimentTerm.mappingFromToIntegration
        );
      }
    },

    manageModel() {
      const self = this;

      if (
        this.mappingFromToIntegration !== null &&
        this.mappingFromToIntegration !== undefined &&
        this.mappingFromToIntegration.length > 0
      ) {
        this.movimentTerm.mappingFromToIntegration = JSON.stringify(
          this.mappingFromToIntegration
        );
      }
    },
    findAllCompaniesWithIntegration() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/company/findAllCompaniesWithIntegration`
      )
        .then((response) => {
          const companies = response.data.data;

          if (
            companies !== null &&
            companies !== undefined &&
            companies.length > 0
          ) {
            companies.forEach((c) => {
              this.companiesWithIntegration.push({
                key: c.integrationCode,
                value: `(${c.integrationCode}) ${c.name} - ${c.federalID}`,
                federalID: c.federalID,
              });
            });
          }
        })
        .catch((error) => {
          swal(
            `Não foi possível carregar a listagem de empresas que possuem Código de Integração cadastrado.${error}`,
            "error"
          );
        });
    },
    async createFromToIntegration(value) {
      this.mappingFromToIntegration.push(value);
      this.newFromToIntegration = {
        integrationCode: "",
        code: "",
        description: "",
      };
      this.onSubmit();
    },
    async updateFromToIntegration(value) {
      this.newFromToIntegration = {
        integrationCode: "",
        code: "",
        description: "",
      };
      this.onSubmit();
    },
    async removeFromToIntegration(value) {
      Swal.fire({
        title: "Excluir Item",
        html: "Você realmente deseja excluir o item? Se você excluir, precisará adicioná-lo novamente no cadastro.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.mappingFromToIntegration.findIndex(
            (item) => item.code === value.code
          );

          if (index !== "" && index !== null) {
            this.mappingFromToIntegration.splice(index, 1);
            Swal.fire(
              "Excluir Item",
              " O item foi excluído com sucesso da lista de mapemamentos.",
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Item",
              " O item não pode ser excluído da lista de mapemamentos.",
              "danger"
            );
          }
        }
      });
    },
  },
  computed: {
    ...mapState({
      listOfAccounts: (state) => state.accounts,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("setLoading", {
        show: true,
        label: this.mainTitle,
        description: "Carregando, aguarde...",
      });

      this.$store.dispatch("FinancesStore/getMovimentTerms", "CATEGORY");

      this.findAllCompaniesWithIntegration();
      this.manageMovimentTerms();
      this.categories = constants.MOVIMENT_TERMS_CATEGORY;

      setTimeout(() => {
        if (
          this.movimentTerm.mappingFromToIntegration !== null &&
          this.movimentTerm.mappingFromToIntegration !== undefined &&
          this.movimentTerm.mappingFromToIntegration.length > 0
        ) {
          this.mappingFromToIntegration = JSON.parse(
            this.movimentTerm.mappingFromToIntegration
          );
        }

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      }, 1000);
    }, 200);
  },
};
</script>
