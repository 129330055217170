<template>
  <div class="card card-invoice bd-0">
    <div class="card-body">
      <printable-header></printable-header>

      <br />
      <br />

      <h1 class="slim-pagetitle">
        Solicitação de Numerário do Processo de Importação #{{
          model.identification
        }}
        <span class="float-right tx-14 tx-normal">
          {{ new Date() | moment("DD/MM/YYYY") }}
        </span>
      </h1>

      <hr />

      <printable-operational-header :model="model" />

      <h6 class="slim-card-title tx-primary mg-t-20">Resumo de Lançamentos</h6>
      <hr />
      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th class="tx-center wd-300">Descrição</th>
              <th class="tx-center">Data</th>
              <th class="tx-center">Previsto (R$)</th>
              <th class="tx-center">Data</th>
              <th class="tx-center">Realizado</th>
              <th class="tx-center">Saldo</th>
              <th class="tx-center">Variação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <template>
                <td class="tx-12">{{ item.description }}</td>
                <td class="tx-center tx-13">
                  {{ item.estimatedValueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tx-right tx-13">
                  R$ {{ item.estimatedValue | currency }}
                </td>
                <td class="tx-center tx-13">
                  {{ item.paidValueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tx-right tx-13">
                  R$ {{ item.paidValue | currency }}
                </td>
                <td class="tx-right tx-13">
                  R$ {{ (item.estimatedValue - item.paidValue) | currency }}
                </td>
                <td class="tx-right tx-13">
                  {{
                    (
                      ((parseFloat(item.paidValue) -
                        parseFloat(item.estimatedValue)) *
                        100) /
                      parseFloat(item.estimatedValue)
                    ).toFixed(2) | currency
                  }}
                  %
                </td>
              </template>
            </tr>

            <tr>
              <td colspan="2" class="tx-right tx-normal"></td>
              <td colspan="1" class="tx-right tx-13">
                <span>R$ {{ balance | currency }}</span>
              </td>
              <td colspan="2" class="tx-right tx-13">
                <span>R$ {{ balanceRealized | currency }}</span>
              </td>
              <td class="tx-right"></td>
              <td class="tx-right"></td>
            </tr>

            <tr>
              <td colspan="2" rowspan="4" class="valign-middle">
                <div class="invoice-notes">
                  <label class="section-label-sm tx-gray-500"
                    >Informações Complementares</label
                  >
                  <p>{{ model.processAppointmentObservations }}</p>
                </div>
                <!-- invoice-notes -->
              </td>
              <td
                colspan="3"
                class="tx-right tx-uppercase tx-bold tx-inverse valign-middle tx-normal"
              >
                Valor Total Previsto
              </td>
              <td colspan="2" class="tx-right valign-middle">
                R$ {{ balance | currency }}
              </td>
            </tr>

            <tr>
              <td
                colspan="3"
                class="tx-right tx-uppercase tx-bold tx-inverse valign-middle tx-normal"
              >
                Valor Total Realizado
              </td>
              <td colspan="2" class="tx-right valign-middle">
                R$ {{ balanceRealized | currency }}
              </td>
            </tr>

            <tr>
              <td
                colspan="3"
                class="tx-right tx-uppercase tx-bold tx-inverse valign-middle"
                v-if="estimatedValueTotal - paidValueTotal > 0"
              >
                Valor Total de Reembolso ao Cliente
              </td>
              <td
                colspan="3"
                class="tx-right tx-uppercase tx-bold tx-inverse valign-middle"
                v-else
              >
                Valor Total a Depositar
              </td>
              <td colspan="2" class="tx-right valign-middle">
                <h4
                  class="tx-18"
                  :class="{
                    'tx-success': estimatedValueTotal - paidValueTotal > 0,
                    'tx-danger': estimatedValueTotal - paidValueTotal < 0,
                  }"
                >
                  R$ {{ (paidValueTotal - estimatedValueTotal) | currency }}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      <!-- table-responsive -->

      <div class="row">
        <div class="col-md-6">
          <h6 class="slim-card-title tx-primary mg-t-20">Taxas de Câmbio</h6>
          <hr />
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th class="tx-center">Data</th>
                  <th class="tx-center">Moeda</th>
                  <th class="tx-center">Taxa (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in exchangeRateHistories" :key="item.id">
                  <template>
                    <td class="tx-center">
                      {{ item.date | moment("DD/MM/YYYY") }}
                    </td>
                    <td class="tx-center">
                      {{
                        item.coin.code +
                        " - (" +
                        item.coin.swiftCode +
                        ") " +
                        item.coin.name
                      }}
                    </td>
                    <td class="tx-right">{{ item.value }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-3">
          <h6 class="slim-card-title tx-primary mg-t-20">
            Conta Bancária p/ Depósito
          </h6>
          <hr />
          <label class="section-label-sm tx-gray-600">Dados da Conta</label>
          <p class="invoice-info-row">
            <span>Banco</span>
            <span class="mg-l-15">{{
              model.processAppointmentPaymentBank
            }}</span>
          </p>

          <p class="invoice-info-row">
            <span>Agência</span>
            <span class="mg-l-15">{{
              model.processAppointmentPaymentAgencyNumber
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Número da Conta </span>
            <span class="mg-l-15">{{
              model.processAppointmentPaymentAccountNumber
            }}</span>
          </p>
        </div>

        <div class="col-md-3">
          <h6 class="slim-card-title tx-primary mg-t-20">&#160;</h6>
          <hr />
          <label class="section-label-sm tx-gray-600"
            >Dados do Favorecido
          </label>

          <p class="invoice-info-row">
            <span>CNPJ </span>
            <span class="mg-l-15">{{
              model.processAppointmentPaymentFederalID
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Favorecido </span>
            <span class="mg-l-15">{{
              model.processAppointmentPaymentName
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- card-body -->

    <span class="tx-12 float-right">
      Demonstrativo gerado em
      {{ new Date() | moment("DD/MM/YYYY \\à \\s H:m:s") }} por
      {{ this.$store.state.credentials.data.person.name }}.
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PrintableOperationalHeader from "./Printable.Operational.Import.Header.vue";
import PrintableHeader from "./Printable.Header.vue";

export default {
  name: "PrintableAppointment",
  props: [
    "model",
    "title",
    "items",
    "estimatedValueTotal",
    "paidValueTotal",
    "exchangeRateHistories",
    "coinsMap",
    "coinsMapURI",
  ],
  components: {
    "printable-header": PrintableHeader,
    "printable-operational-header": PrintableOperationalHeader,
  },
  data() {
    return {
      // model: {},
    };
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    inputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    outputsTotal() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    balance() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.estimatedValue;
        return value + 0;
      }, 0);
    },
    inputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    outputsTotalRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    balanceRealized() {
      return this.items.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.paidValue;
        return value + 0;
      }, 0);
    },
  },
  watch: {
    process() {
      this.model = { ...this.process };
      this.loadData();
    },
  },
};
</script>
