<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configurações</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configurações Administrativas</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">{{ mainTitle }}</a>
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            {{ modelTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ modelTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <form-general :company="account" :isNewRecord="isNewRecord()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

import FormGeneral from "./Form.General.vue";

export default {
  name: "configuration-accounts-form",
  components: {
    "form-general": FormGeneral,
  },
  data() {
    return {
      modelTitle: "Editar Unidade da Empresa",
      mainTitle: "Unidades da Empresa",
      account: null,
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      return !(
        this.$route.params.id !== undefined && this.$route.params.id !== null
      );
    },
    loadModel() {
      try {
        if (this.$route.params.id) {
          Axios.get(
            `${config.env.API_DEFAULT.host}/accounts/${this.$route.params.id}`
          ).then((response) => {
            if (response) {
              this.account = response.data;
            }
          });
        } else {
          this.account = {
            federalID: null,
            id: null,
          };
        }
      } catch (err) {
        console.error(err);
        this.$swal(this.mainTitle, `Ops, houve algum erro: ${error}`, "error");
      }
    },
  },
  mounted() {
    // if (!this.isNewRecord()) {
    this.loadModel();
    // }
  },
};
</script>
