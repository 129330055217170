<template>
  <div>
    <contact-modal-form-component
      v-if="showModalFormContact"
      @close="() => {
          showModalFormContact = false;
          load();
      }"
      title="CADASTRO DE CONTATO"
      :modelUpdated="this.currentContact"
      :company="this.company"
      :profileScreen="false"
      :contactScreen="true"
    ></contact-modal-form-component>

    <div
      class="report-summary-header mg-t-0 mg-b-5"
      v-if="!showModalFormContact"
    >
      <div>
        <h4 class="tx-inverse mg-b-3">Contatos (Usuários)</h4>
        <p class="mg-b-0">
          <i class="fa fa-users mg-r-3"></i> Foram encontrados
          {{ contacts.length }} contatos.
        </p>
      </div>
      <div>
        <input
          type="text"
          v-model="search"
          class="form-control mg-r-20"
          placeholder="Buscar Contato..."
        />
        <a
          href="#"
          id="show-modal"
          @click="openModal(newContact)"
          class="btn btn-primary"
        ><i class="icon ion-plus tx-22"></i> ADICIONAR</a
        >
      </div>
    </div>

    <div v-if="listContactsToAdd != undefined && listContactsToAdd.length > 0">
      <div class="bd-l bd-3 bd-danger bg-gray-200 pd-x-20 pd-y-25">
        <h5 class="tx-danger">
          Você gostaria de adicionar um dos contatos abaixo?
        </h5>
        <p class="mg-b-0">
          Nenhum contato com que contenha <strong>{{ search }}</strong> no nome
          está associado a {{ company.name }}.
        </p>
      </div>

      <table class="table mg-b-0 tx-13">
        <thead>
        <tr class="tx-10">
          <th class="wd-10p pd-y-5">&nbsp;</th>
          <th class="pd-y-5">Nome</th>
          <th class="pd-y-5">CPF</th>
          <th class="pd-y-5">Email</th>
          <th class="pd-y-5">Telefone</th>
          <th class="pd-y-5">Ações</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="contact in listContactsToAdd">
          <tr :key="contact.id" v-if="listContactsToAdd.length > 0">
            <td class="pd-l-20">
              <template
                v-if="contact.photo !== null && contact.photo !== undefined"
              >
                <img
                  :src="`data:image/png;base64,${contact.photo}`"
                  alt="Foto do Usuário"
                  class="wd-36 rounded-circle"
                />
              </template>
              <template v-else>
                <img
                  src="http://via.placeholder.com/500x500"
                  alt="Foto do Usuário"
                  class="wd-36 rounded-circle"
                />
              </template>
            </td>
            <td>
              <a href="" class="tx-inverse tx-14 tx-medium d-block">{{
                  contact.name
                }}</a>
              <span class="tx-11 d-block">{{ contact.jobRole }}</span>
            </td>
            <td>{{ contact.federalID }}</td>
            <td>{{ contact.email1 }}</td>
            <td>{{ contact.telephone1 }}</td>
            <td>
              <a
                href="#"
                @click="add(contact)"
                class="btn btn-success btn-icon rounded-circle mg-r-5"
              >
                <div class="tx-20">
                  <i class="icon ion-person-add tx-20"></i>
                </div>
              </a>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

    <div
      class="card card-table mg-t-0"
      v-if="!showModalFormContact && listContactsToAdd.length == 0"
    >
      <div class="table-responsive">
        <table class="table mg-b-0 tx-13">
          <thead>
          <tr>
            <td class="tx-center" colspan="1">Nome</td>
            <td class="tx-center">Endereço de Email</td>
            <td class="pd-y-5 tx-center">Telefone</td>
            <td class="pd-y-5 tx-center">Celular</td>
            <td class="pd-y-5 tx-center">Follow UP</td>
            <td class="pd-y-5 tx-center">Analítico</td>
            <td class="pd-y-5 tx-center">Sintético</td>
            <td class="pd-y-5 tx-center">Ações</td>
          </tr>
          </thead>
          <tbody>
          <tr
            v-if="
                listOfContacts != undefined &&
                listOfContacts.length === 0 &&
                listContactsToAdd.length == 0
              "
          >
            <td class="wd-10p pd-y-5 tx-center" colspan="9">
              <div class="pd-20">
                Nenhum contato associado a empresa. <a></a>
              </div>
            </td>
          </tr>

          <tr v-for="contact in listOfContacts" :key="contact.oldID">
            <td class="pd-l-20-force tx-left">
              <a href="" class="tx-inverse tx-medium d-block">{{
                  contact.name
                }}</a>
              <small>{{ contact.jobRole }}</small>
              <span class="tx-12 d-block" v-if="contact.enabled"
              ><span
                class="square-8 bg-success mg-r-5 rounded-circle"
              ></span>
                  Habilitado</span
              >
              <span class="tx-12 d-block" v-else
              ><span
                class="square-8 bg-danger mg-r-5 rounded-circle"
              ></span>
                  Desabilitado</span
              >
            </td>
            <td class="tx-left">
              {{ contact.email1 }}
            </td>
            <td class="tx-center">
              {{ contact.telephone1 }}
            </td>
            <td class="tx-center">
              {{ contact.telephone2 }}
            </td>
            <td class="tx-center">
                <span class="tx-12 d-block" v-if="contact.followUP"
                ><span
                  class="square-8 bg-success mg-r-5 rounded-circle"
                ></span>
                  Sim</span
                >
              <span class="tx-12 d-block" v-else
              ><span
                class="square-8 bg-danger mg-r-5 rounded-circle"
              ></span>
                  Não</span
              >
            </td>

            <td class="tx-center">
                <span class="tx-12 d-block" v-if="contact.followUPAnalytical"
                ><span
                  class="square-8 bg-success mg-r-5 rounded-circle"
                ></span>
                  Sim</span
                >
              <span class="tx-12 d-block" v-else
              ><span
                class="square-8 bg-danger mg-r-5 rounded-circle"
              ></span>
                  Não</span
              >
            </td>

            <td class="tx-center">
                <span class="tx-12 d-block" v-if="contact.followUPSynthetic"
                ><span
                  class="square-8 bg-success mg-r-5 rounded-circle"
                ></span>
                  Sim</span
                >
              <span class="tx-12 d-block" v-else
              ><span
                class="square-8 bg-danger mg-r-5 rounded-circle"
              ></span>
                  Não
                </span>
            </td>

            <td class="tx-center">
              <a
                href="#"
                @click="openModal(contact)"
                class="btn btn-primary btn-icon rounded-circle mg-r-5"
              >
                <div><i class="icon ion-edit tx-20"></i></div>
              </a>
              <a
                href="#"
                class="btn btn-warning btn-icon rounded-circle mg-r-5"
              >
                <div><i class="icon ion-key tx-20"></i></div>
              </a>
              <a
                href="#"
                @click="deleteModel(contact)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a tx-20"></i></div>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import ContactModalForm from "@/views/general/contacts/Form.Modal.vue";
import {EventBus} from "@/events/EventBus.js";
import Swal from "sweetalert2";

export default {
  name: "ListPartial",
  props: ["company", "contacts"],
  components: {
    "contact-modal-form-component": ContactModalForm,
  },
  data() {
    return {
      newContact: {
        oldID: null,
        enabled: true,
        name: null,
        federalID: null,
        dateOfBirth: null,
        gender: "m",
        sector: null,
        jobRole: null,
        email1: null,
        email2: null,
        telephone1: null,
        telephone2: null,
        postalCode: null,
        address: null,
        addressNumber: null,
        complement: null,
        district: null,
        city: null,
        federalUnity: null,
        commissioned: null,
        followUP: false,
        followUPSynthetic: null,
        followUPAnalytical: null,
        followUPAnalyticalWeekDay: null,
      },
      currentContact: null,
      showModalFormContact: false,
      search: "",
      contactsToAdd: [],
    };
  },
  methods: {
    load() {
      Axios.get(this.company._links.users.href)
        .then((response) => {
          this.contacts = response.data._embedded.users;
        })
        .catch((error) => {
        });
    },
    isNewRecord() {
      if (this.$route.params.id === null || this.$route.params.id === undefined)
        return true;
      return false;
    },
    openModal(model) {
      this.showModalFormContact = true;
      this.currentContact = model;
    },
    deleteModel(model) {
      const self = this;
      const description = model.name;

      self
        .$swal({
          title: "Excluir Contato?",
          text: `Se você excluir o contato '${description}', não será possível recuperá-lo para uso posterior. Você realmente tem certeza disso?`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        })
        .then((willContinue) => {
          if (willContinue) {
            Axios.delete(`${self.company._links.self.href}/users/${model.id}`)
              .then((response) => {
                EventBus.$emit("companies.Form.loadModel");
                Swal.fire(
                  "Cadastro excluído!",
                  `O contato ${description} foi excluída com sucesso!`,
                  "success"
                );
              })
              .catch((error) => {
                Swal.fire(
                  "Cadastro não excluído!",
                  `Ops, houve um erro! \n\n${error}`,
                  "error"
                );
              });
          }
        });
    },
    add(model) {
      const self = this;
      const description = model.name;

      self
        .$swal({
          title: "Adicionar Contato?",
          text: `Você realmente deseja associar o contato '${description}' a empresa ${self.company.name}?`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        })
        .then((willContinue) => {
          if (willContinue) {
            if (self.company !== null) {
              Axios.patch(
                `${config.env.API_DEFAULT.host}/companies/${self.company.id}/users`,
                `${config.env.API_DEFAULT.host}/users/${model.id}`,
                {
                  headers: {
                    "Content-Type": "text/uri-list",
                  },
                }
              )
                .then((response) => {
                  self.search = "";
                  self.contactsToAdd = [];
                  EventBus.$emit("companies.Form.loadModel");
                  Swal.fire(
                    "Cadastro de Empresas",
                    `${description} foi associado a empresa com sucesso.`,
                    "success"
                  );
                })
                .catch((error) => {
                  Swal.fire(
                    "Cadastro de Empresas",
                    `Ops, houve algum erro ao tentar associar o contato: ${error}`,
                    "error"
                  );
                });
            }
          }
        });
    },
  },
  mounted() {
    this.search = "";
    this.contactsToAdd = [];
  },
  computed: {
    listOfContacts() {
      const self = this;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      self.contactsToAdd = [];

      const filteredContacts = this.contacts.filter((contact) =>
        contact.name.toLowerCase().includes(self.search.toLowerCase())
      );

      if (filteredContacts.length > 0 && self.search.length > 0) {
        return filteredContacts;
      }
      if (filteredContacts.length === 0 && self.search.length >= 3) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        Axios.get(
          `${config.env.API_DEFAULT.host}/users/search/findAllByNameContainingIgnoreCase?name=${self.search}`
        )
          .then((response) => {
            self.contactsToAdd = response.data._embedded.users;
          })
          .catch((error) => {
            swal(
              "Cadastro de Empresa",
              `Ops, houve algum erro: ${error}`,
              "error"
            );
          });
      } else {
        return this.contacts;
      }
      return undefined;
    },
    listContactsToAdd() {
      return this.contactsToAdd;
    },
  },
};
</script>
