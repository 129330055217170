import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Axios from 'axios';
import config from '@/config.js';
import CredentialService from '@/services/CredentialService.js';
import ProcessStore from './ProcessStore.js';
import FinancesStore from './FinancesStore.js';
import MyIMPStore from './MyIMPStore.js';
import IntegrationStore from './IntegrationStore.js';

import DocumentsStore from './DocumentsStore.js';
import ControlPanelStore from './ControlPanelStore.js';

Vue.use(Vuex);

const persistMutations = ['includeCredentials'];

export default new Vuex.Store({
  modules: {
    ProcessStore,
    FinancesStore,
    MyIMPStore,
    IntegrationStore,
    DocumentsStore,
    ControlPanelStore,
  },
  state: {
    defaultLayout: false,
    credentials: {
      data: null,
      accessToken: null,
      scope: null,
      expiresIn: null,
      userAuthenticated: false,
    },
    loading: {
      show: false,
      label: '',
      description: '',
    },
    accounts: [],
    files: [],
    customersList: [],
    contactsList: [],
    crmCustomersSearch: '',
    crmCustomersFilters: {
      enabled: true,
      isCustomer: true,
      isProvider: false,
      isForwarder: false,
      isCommissioned: false,
      isCustomBroker: false,
      isExporter: false,
      isConveyor: false,
      isShipowner: false,
    },
    crmContactsSearch: '',
    crmContactsFilters: {
      enabled: null,
      archived: null,
      categories: [],
      roles: [],
      followUP: null,
      hasCompanies: null,
      hasActivities: null,
    },
  },
  mutations: {
    defaultLayoutEnabled(state) {
      state.defaultLayout = true;
    },
    defaultLayoutDisabled(state) {
      state.defaultLayout = false;
    },
    includeCredentials(state, credential) {
      state.credentials = credential;
    },
    includeAccounts(state, payload) {
      state.accounts = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    updateFiles(state, files) {
      state.files = files;
    },
    updateContactsList(state, payload) {
      state.contactsList = payload;
    },
    updateCustomersList(state, payload) {
      state.customersList = payload;
    },
    UPDATE_CRM_CUSTOMERS_FILTER(state, payload) {
      state.crmCustomersFilters = payload;
    },
    UPDATE_CRM_CONTACTS_FILTER(state, payload) {
      state.crmContactsFilters = payload;
    },
    UPDATE_CRM_CUSTOMERS_SEARCH(state, payload) {
      state.crmCustomersSearch = payload;
    },
    UPDATE_CRM_CONTACTS_SEARCH(state, payload) {
      state.crmContactsSearch = payload;
    },
  },
  actions: {
    checkPrivilege({ }, payload) {
      if (!CredentialService.hasPrivilege(payload.privilege)) {
        payload.router.push('/error/403');
      }
    },
    updateContactsList({ commit }, payload) {
      commit('updateContactsList', payload);
    },
    updateCustomersList({ commit }, payload) {
      commit('updateCustomersList', payload);
    },
    updateCrmCustomersSearch({ commit }, payload) {
      commit('UPDATE_CRM_CUSTOMERS_SEARCH', payload);
    },
    updateCrmContactsSearch({ commit }, payload) {
      commit('UPDATE_CRM_CONTACTS_SEARCH', payload);
    },
    findObjectByKey({ state, dispatch, commit }, data) {
      const { array } = data;
      const { key } = data;
      const { value } = data;

      for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          const val = array[i];
          return val;
        }
      }

      return null;
    },
    getAccounts({ commit }) {
      try {
        const { host } = config.env.API_DEFAULT;
        Axios.get(`${config.env.API_DEFAULT.host}/credential/accounts`)
          .then((response) => {
            if (response) {
              const list = [];

              response.data.forEach((item) => {
                const data = {
                  ...item,
                  _links: {
                    self: {
                      href: `${host}/accounts/${item.id}`,
                    },
                  },
                  href: `${host}/accounts/${item.id}`,
                };

                list.push(data);
              });

              commit('includeAccounts', list);

              return true;
            }
            return false;
          }).catch((error) => {

          });
      } catch (error) {
        return false;
      }
    },
    updateCrmCustomersFilter({ commit }, payload) {
      commit('UPDATE_CRM_CUSTOMERS_FILTER', payload);
    },
    updateCrmContactsFilter({ commit }, payload) {
      commit('UPDATE_CRM_CONTACTS_FILTER', payload);
    },
  },
  plugins: [
    createPersistedState({
      key: 'default-storage',
      filter: (mutation) => persistMutations.indexOf(mutation.type) === 0,
    }),
  ],
});
