<template>
  <form id="formRevenues" class="form-layout form-layout-2 form-dates-sidebar">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Faturamento</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Informações de
          Liberação HBL/AWB e Transporte Rodoviário.
        </p>
      </div>
      <!--        <div>-->
      <!--            <a href="" class="btn btn-secondary"><i class="icon ion-printer tx-22"></i> Imprimir </a>-->
      <!--        </div>-->
    </div>

    <label class="section-title mg-t-20">Liberação HBL/AWB</label>

    <div class="row no-gutters">
      <div class="col-md-4">
        <div class="form-group bd-r-0-force mg-md-t--1">
          <label class="form-control-label">Valor Total (USD) </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalUsd"
                v-bind="money"
                class="form-control"
                name="revenuesReleaseBLAWBTotalUsd"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group bd-r-0-force mg-md-t--1">
          <label class="form-control-label">Valor da Taxa (USD)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalUsdRate"
                v-bind="dolar"
                class="form-control"
                name="revenuesReleaseBLAWBTotalUsdRate"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mg-md-t--1">
          <label class="form-control-label">Valor total (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalUsdRateReal"
                v-bind="money"
                class="form-control"
                name="revenuesReleaseBLAWBTotalUsdRateReal"
                readonly="readonly"
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-md-4">
        <div class="form-group bd-r-0-force bd-t-0-force mg-md-t--1">
          <label class="form-control-label">Valor Total (outras moedas)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalOtherCoin"
                v-bind="money"
                class="form-control"
                name="steprevenuesReleaseBLAWBTotalOtherCoinInsuranceValue"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group bd-r-0-force bd-t-0-force mg-md-t--1">
          <label class="form-control-label"
          >Valor da Taxa (outras moedas)</label
          >
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalOtherCoinRate"
                v-bind="dolar"
                class="form-control"
                name="revrevenuesReleaseBLAWBTotalOtherCoinRateenuesReleaseBLAWBTotalOtherCoinRate"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mg-md-t--1 bd-t-0-force">
          <label class="form-control-label">Valor total (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalOtherCoinRateReal"
                v-bind="money"
                class="form-control"
                name="revenuesReleaseBLAWBTotalOtherCoinRateReal"
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" style="margin-top: -1px !important">
      <div class="col-md-4">
        <div class="form-group bd-t-0-force bd-r-0-force">
          <label class="form-control-label">Total (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesReleaseBLAWBTotalRealCoin"
                v-bind="money"
                class="form-control"
                name="revenuesReleaseBLAWBTotalRealCoin"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 bg-gray-300">
        <div class="form-group bd-t-0-force bd-r-0-force">
          <a
            href="#"
            @click="changeRevenuesRelease"
            class="btn btn-primary btn-icon rounded-circle"
          >
            <div><i class="fa fa-calculator"></i></div
            >
          </a>
          <input
            v-if="model.revenuesReleaseBLAWBTotalGeneralRealCoin !== null"
            class="form-control tx-34 tx-center"
            type="text"
            name="revenuesReleaseBLAWBTotalGeneralRealCoin"
            :value="
              'R$ ' + model.revenuesReleaseBLAWBTotalGeneralRealCoin.toFixed(2)
            "
            readonly
          />
        </div>
      </div>
      <div class="form-group col-md-4 bd-t-0-force">
        <label class="form-control-label">
          Situação da Liberação HBL/AWB
        </label>
        <div class="input-group-solved">
          <div class="input-group-solved-before mg-r-5 mg-t-3 wd-100">
            <toggle-button
              v-model="model.revenuesReleaseBLAWBState"
              name="revenuesReleaseBLAWBState"
              :sync="true"
              :labels="{ checked: 'ENVIADO', unchecked: ' PENDENTE' }"
              :color="{ unchecked: '#bf0909' }"
              :height="16"
              :width="90"
            />
          </div>
          <div class="input-group-solved-after">
            <date-picker lang="pt-br"
                         v-model="model.revenuesReleaseBLAWBDate"
                         name="revenuesReleaseBLAWBDate"
                         inputName="revenuesReleaseBLAWBDate"
                         class="form-control"
                         format="DD/MM/YY"
                         v-validate="'required'"
                         placeholder="Selecione"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>

    <label class="section-title mg-t-20">Rodoviário</label>

    <div class="row no-gutters" style="margin-bottom: -1px !important">
      <div class="col-md-4">
        <div class="form-group bd-r-0-force mg-md-t--1">
          <label class="form-control-label">Valor do Transporte (R$) </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesRoadValueOfTransportation"
                v-bind="money"
                class="form-control"
                name="revenuesRoadValueOfTransportation"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group bd-r-0-force mg-md-t--1">
          <label class="form-control-label">Ad Valorem (%)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesRoadValueOfTransportationAdValorem"
                v-bind="percent"
                class="form-control"
                name="revenuesRoadValueOfTransportationAdValorem"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mg-md-t--1">
          <label class="form-control-label">Valor da NF (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesRoadValueOfTransportationNF"
                v-bind="money"
                class="form-control"
                name="revenuesRoadValueOfTransportationNF"
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-md-4">
        <div class="form-group bd-t-0-force bd-r-0-force">
          <label class="form-control-label">Outros Valores (R$)</label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              <i class="fa fa-money op-5"></i>
            </div>
            <div class="input-group-solved-center">
              <money
                v-model="model.revenuesRoadOtherValues"
                v-bind="money"
                class="form-control"
                name="revenuesRoadOtherValues"
              ></money>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 bg-gray-300">
        <div class="form-group bd-t-0-force bd-r-0-force">
          <a
            href="#"
            @click="changeRevenuesRoad"
            class="btn btn-primary btn-icon rounded-circle"
          >
            <div><i class="fa fa-calculator"></i></div
            >
          </a>
          <input
            v-if="model.revenuesRoadTotal !== null"
            class="form-control tx-34 tx-center"
            type="text"
            name="revenuesRoadTotal"
            :value="'R$ ' + model.revenuesRoadTotal.toFixed(2)"
            readonly
          />
        </div>
      </div>
    </div>

    <label class="section-title mg-t-20">Armazenagem</label>
    <div class="row no-gutters">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-control-label"> Situação da Armazenagem </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before mg-r-5 mg-t-3 wd-100">
              <toggle-button
                v-model="model.revenuesStorageState"
                name="revenuesStorageState"
                :sync="true"
                :labels="{ checked: 'ENVIADO', unchecked: ' PENDENTE' }"
                :color="{ unchecked: '#bf0909' }"
                :height="16"
                :width="90"
              />
            </div>
            <div class="input-group-solved-after">
              <date-picker lang="pt-br"
                           v-model="model.revenuesStorageDate"
                           name="revenuesStorageDate"
                           inputName="revenuesStorageDate"
                           class="form-control"
                           format="DD/MM/YY"
                           v-validate="'required'"
                           placeholder="Selecione"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <label class="section-title mg-t-20">Transporte</label>
    <div class="row no-gutters">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-control-label"> Situação do Transporte </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before mg-r-5 mg-t-3 wd-100">
              <toggle-button
                v-model="model.revenuesTransportState"
                name="revenuesTransportState"
                :sync="true"
                :labels="{ checked: 'ENVIADO', unchecked: ' PENDENTE' }"
                :color="{ unchecked: '#bf0909' }"
                :height="16"
                :width="90"
              />
            </div>
            <div class="input-group-solved-after">
              <date-picker lang="pt-br"
                           v-model="model.revenuesTransportDate"
                           name="revenuesTransportDate"
                           inputName="datesSiscargaDate"
                           class="form-control"
                           format="DD/MM/YY"
                           v-validate="'required'"
                           placeholder="Selecione"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <label class="section-title mg-t-20"
    >Outras Despesas
      <toggle-button
        v-model="model.revenuesOtherExpenses"
        name="revenuesOtherExpenses"
        :sync="true"
        :labels="{
          checked: '   TEM OUTRAS DESPESAS ',
          unchecked: '   NÃO TEM OUTRAS DESPESAS ',
        }"
        :color="{ unchecked: '#bf0909' }"
        :height="16"
        :width="175"
      />
    </label>

    <div class="row no-gutters" v-if="model.revenuesOtherExpenses">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-control-label">Descrição</label>
          <div class="input-group-solved">
            <input
              class="form-control"
              type="text"
              name="revenuesOtherExpensesDescription"
              v-model="model.revenuesOtherExpensesDescription"
            />
          </div>
          <div class="input-group-solved-after"></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group bd-l-0-force">
          <label class="form-control-label">
            Situação das Outras Despesas
          </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before mg-r-5 mg-t-3 wd-100">
              <toggle-button
                v-model="model.revenuesOtherExpensesState"
                name="revenuesOtherExpensesState"
                :sync="true"
                :labels="{ checked: 'ENVIADO', unchecked: ' PENDENTE' }"
                :color="{ unchecked: '#bf0909' }"
                :height="16"
                :width="90"
              />
            </div>
            <div class="input-group-solved-after">
              <date-picker lang="pt-br"
                           v-model="model.revenuesOtherExpensesDate"
                           name="revenuesOtherExpensesDate"
                           inputName="revenuesOtherExpensesDate"
                           class="form-control"
                           format="DD/MM/YY"
                           v-validate="'required'"
                           placeholder="Selecione"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {mapState} from "vuex";
import DatePicker from "vue2-datepicker";
import {Money} from "v-money";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";

export default {
  name: "FormRevenues",
  props: [],
  components: {
    DatePicker,
    Money,
    ToggleButton,
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      dolar: {
        decimal: ",",
        thousands: ".",
        precision: 4,
        masked: false,
      },
      model: {},
    };
  },

  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    options() {
      return [
        "blur",
        (e) => {
          setTimeout(() => {
            this.diffObjects();
          }, 200);
        },
        true,
      ];
    },
  },
  methods: {
    async diffObjects() {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
      });

      if (response) this.initialModel = {...this.model};
    },

    changeRevenuesRelease() {
      if (
        this.model.revenuesReleaseBLAWBTotalUsd !== null &&
        this.model.revenuesReleaseBLAWBTotalUsdRate !== null &&
        this.model.revenuesReleaseBLAWBTotalUsd > 0 &&
        this.model.revenuesReleaseBLAWBTotalUsdRate > 0
      ) {
        this.model.revenuesReleaseBLAWBTotalUsdRateReal =
          this.model.revenuesReleaseBLAWBTotalUsd *
          this.model.revenuesReleaseBLAWBTotalUsdRate;
      } else {
        this.model.revenuesReleaseBLAWBTotalUsdRateReal = 0;
      }

      if (
        this.model.revenuesReleaseBLAWBTotalOtherCoin !== null &&
        this.model.revenuesReleaseBLAWBTotalOtherCoinRate !== null &&
        this.model.revenuesReleaseBLAWBTotalOtherCoin > 0 &&
        this.model.revenuesReleaseBLAWBTotalOtherCoinRate > 0
      ) {
        this.model.revenuesReleaseBLAWBTotalOtherCoinRateReal =
          this.model.revenuesReleaseBLAWBTotalOtherCoinRate *
          this.model.revenuesReleaseBLAWBTotalOtherCoin;
      } else {
        this.model.revenuesReleaseBLAWBTotalOtherCoinRateReal = 0;
      }

      this.model.revenuesReleaseBLAWBTotalGeneralRealCoin =
        this.model.revenuesReleaseBLAWBTotalRealCoin +
        this.model.revenuesReleaseBLAWBTotalUsdRateReal +
        this.model.revenuesReleaseBLAWBTotalOtherCoinRateReal;
    },
    changeRevenuesRoad() {
      this.model.revenuesRoadTotal =
        this.model.revenuesRoadValueOfTransportation +
        this.model.revenuesRoadOtherValues;

      if (
        this.model.revenuesRoadValueOfTransportationAdValorem !== null &&
        this.model.revenuesRoadValueOfTransportationAdValorem > 0
      ) {
        this.model.revenuesRoadTotal =
          (this.model.revenuesRoadValueOfTransportationNF / 100) *
          this.model.revenuesRoadValueOfTransportationAdValorem +
          this.model.revenuesRoadTotal;
      }
    },
  },
  created() {
    this.model = {...this.process};
    this.initialModel = {...this.process};
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("formRevenues");
        form.addEventListener(...this.options);
      }, 200);
    }
  },
  beforeDestroy() {
  },
  watch: {
    process() {
      this.model = {...this.process};
    },
  },
};
</script>

<style scoped>
.total {
  font-size: 20px;
  line-height: 17px;
  font-weight: bold;
}
</style>
