<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="29" r="5" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    <circle cx="34" cy="29" r="5" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    <circle cx="24" cy="9" r="5" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    <path d="M24 44C24 38.4772 19.5228 34 14 34C8.47715 34 4 38.4772 4 44" stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44 44C44 38.4772 39.5228 34 34 34C28.4772 34 24 38.4772 24 44" stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24" stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ConfigUsersIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
