<template>
  <div class="page-error-wrapper">
    <div>
      <h1 class="pd-b-10 tx-60 tx-black bd-b">503</h1>
      <h5 class="tx-sm-24 tx-normal">Serviço temporariamente indisponível.</h5>
      <p class="mg-b-50">
        Houve algum problema ao tentar nos comunicar com os servidores.
      </p>
      <p class="mg-b-50">
        <router-link :to="{ name: 'contacts-list' }" class="btn btn-error"
          >VOLTAR</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage503",
};
</script>
