<script>
import config from '@/config.js';
import Axios from 'axios';
import Swal from 'sweetalert2';
import VModal from '@/components/VModal.vue';

export default {
  name: 'AccessGroups',
  components: {
    VModal,
  },
  data() {
    return {
      groups: [],
      linkedUsers: [],
      linkedUsersModal: false,
      columns: ['id', 'enabled', 'code', 'name', 'actions'],
      options: {
        listColumns: {
          enabled: [
            {
              id: true,
              text: 'Sim',
            },
            {
              id: false,
              text: 'Não',
            },
          ],
        },
        headings: {
          id: 'ID',
          enabled: 'Habilitado',
          name: 'Nome',
          code: 'Código',
          actions: 'Ações',
        },
        sortable: ['id', 'enabled', 'code', 'name'],
        filterable: ['id', 'enabled', 'code', 'name'],
        texts: {
          count:
            'Mostrando página {from} de {to} páginas que contém um total de {count}  resultados.|{count} resultados.|Um resultado',
          filter: 'Filtrar resultados:',
          filterPlaceholder: 'Buscar',
          limit: 'Exibir: ',
          page: 'Página:',
          noResults: 'Nenhum resultado foi encontrado.',
          filterBy: 'Filtrar por {column}',
          loading: 'Carregando...',
          defaultOption: '{column}',
        },
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        filterByColumn: true,
        highlightMatches: true,
        footerHeadings: false,
        pagination: {
          edge: true,
        },
        perPage: 10,
      },
    };
  },
  methods: {
    async getGroups() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando listagem de grupo de acesso.',
      });
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/groups`,
        );
        this.groups = response.data;
      } catch (error) {
        this.$swal(
          'Grupos de Acessso',
          'Não foi possível carregar listagem.',
          'error',
        );
      }
      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async destroyGroup(id) {
      try {
        Swal.fire({
          title: 'Grupos de Acesso',
          html: '<p>Você tem certeza que deseja excluir este Grupo de Acesso?</p>',
          type: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'EXCLUIR',
          showCancelButton: true,
          cancelButtonText: 'CANCELAR',
        }).then(async (result) => {
          if (result.value) {
            this.$store.commit('setLoading', {
              show: true,
              label: 'Carregando...',
              description: 'Excluindo grupo de acesso',
            });

            try {
              const response = await Axios.delete(
                `${config.env.API_DEFAULT.host}/groups/${id}`,
              );

              Swal.fire(
                'Excluir Grupo de Acesso',
                'Grupo excluído com sucesso!',
                'success',
              );

              await this.getGroups();

              this.$store.commit('setLoading', { show: false });
            } catch (e) {
              console.error(e);

              Swal.fire(
                'Excluir Grupo de Acesso!',
                e.response.data.data,
                'error',
              );

              this.$store.commit('setLoading', { show: false });
            }
          }
        });
      } catch (error) {
        this.$swal(
          'Grupos de Acessso',
          'Não foi possível remove o grupo.',
          'error',
        );
      }
    },
    async getLinkedUsers(id) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando usuários vinculados ao grupo de acesso',
      });
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/groups/${id}/users`,
        );
        this.linkedUsers = response.data.data;
        this.linkedUsersModal = true;
      } catch (error) {
        this.$swal(
          'Usuários vinculados ao grupo de acesso',
          'Não foi possível carregar os usuários.',
          'error',
        );
      }
      this.$store.commit('setLoading', {
        show: false,
      });
    },
    closeLinkedUsersModal() {
      this.linkedUsersModal = false;
      this.linkedUsers = [];
    },
  },
  async mounted() {
    await this.getGroups();
  },
};
</script>

<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configuracões</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configuracões de Acesso</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Grupos de Acesso
          </li>
        </ol>
        <h6 class="slim-pagetitle">Listagem de Grupos de Acesso</h6>
      </div>

      <div class="card card-table groups-table">
        <div class="card-header">
          <div class="report-summary-header mg-t-0 mg-b-0">
            <div>
              <h4 class="tx-inverse mg-b-3">Listagem de Grupos de Acesso</h4>
              <p class="mg-b-0">
                Foram encontrados {{ groups.length }} registros de
                <b>Grupos de Acesso</b> cadastrados
              </p>
            </div>
            <div>
              <router-link
                :to="{ name: 'add-access-groups' }"
                class="btn btn-primary"
              >
                <i class="icon ion-plus tx-22"></i> ADICIONAR
              </router-link>
            </div>
          </div>
        </div>

        <v-client-table :columns="columns" :data="groups" :options="options">
          <template slot="enabled" slot-scope="props">
            <span v-if="props.row.enabled" class="badge badge-success">
              SIM
            </span>
            <span v-if="!props.row.enabled" class="badge badge-danger">
              NÃO
            </span>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex ai-c jc-c">
              <div class="tooltip-container">
                <div class="tooltip-activator">
                  <a
                    class="btn btn-warning btn-icon rounded-circle mg-r-5"
                    href="#"
                    @click.prevent
                  >
                    <div>
                      <i class="icon ion-key"></i>
                    </div>
                  </a>
                </div>

                <div class="tooltip-message">
                  <h6>Privilégios</h6>
                  <ul class="privileges-list">
                    <li
                      v-for="privilege in props.row.privilegies"
                      :key="privilege.id"
                    >
                      {{ privilege.name }}
                    </li>
                  </ul>
                </div>
              </div>

              <a
                class="btn btn-info btn-icon rounded-circle mg-r-5"
                href="#"
                @click.prevent="getLinkedUsers(props.row.id)"
              >
                <div>
                  <i class="icon ion-person"></i>
                </div>
              </a>

              <router-link
                :to="{
                  name: 'update-access-groups',
                  params: { id: props.row.id },
                }"
                class="btn btn-primary btn-icon rounded-circle mg-r-5"
              >
                <div>
                  <i class="icon ion-edit"></i>
                </div>
              </router-link>

              <a
                href="#"
                @click.prevent="destroyGroup(props.row.id)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div>
                  <i class="icon ion-trash-a"></i>
                </div>
              </a>
            </div>
          </template>
        </v-client-table>
      </div>
      <!-- card -->
    </div>

    <v-modal
      title="Usuários vinculados ao grupo de acesso"
      description="Visualize a lista de usuários que pertencem ao grupo"
      :width="800"
      :handler="linkedUsersModal"
      @requestClose="closeLinkedUsersModal"
    >
      <template #content>
        <div class="table-overflow pd-x-10" v-if="linkedUsers.length > 0">
          <table class="user-groups-table">
            <tbody>
              <tr v-for="contact in linkedUsers" :key="contact.id">
                <td>
                  <div class="company-item flex ai-c fgap2">
                    <div
                      class="company-letter flex ai-c jc-c"
                      v-if="contact.name"
                    >
                      {{ contact.name.split("")[0].toUpperCase() }}
                    </div>
                    <div class="company-info">
                      <h5 class="mb-0 cursor-pointer">{{ contact.name }}</h5>
                      <span class="mb-0" v-if="contact.email1">{{
                        contact.email1
                      }}</span>
                      <span class="tx-12 d-block" v-if="contact.enabled"
                        ><span
                          class="square-8 bg-success mg-r-5 rounded-circle"
                        ></span>
                        Usuário Habilitado</span
                      >
                      <span class="tx-12 d-block" v-else
                        ><span
                          class="square-8 bg-danger mg-r-5 rounded-circle"
                        ></span>
                        Usuário Desabilitado</span
                      >
                      <span class="tx-12" v-if="!contact.archived"
                        ><span
                          class="square-8 bg-success mg-r-5 rounded-circle"
                        ></span>
                        Ativo
                      </span>
                      <span class="tx-12" v-else
                        ><span
                          class="square-8 bg-danger mg-r-5 rounded-circle"
                        ></span>
                        Arquivado
                      </span>
                    </div>
                    <div class="company-type flex ai-c fgap2"></div>
                  </div>
                </td>
                <td>
                  <span class="mb-0 tx-14 tx-bold">{{ contact.email1 }}</span>
                  <p class="mb-0">
                    <span v-if="contact.telephone1">
                      {{ contact.telephone1 }}
                    </span>
                    <span v-if="contact.telephone2">
                      {{ contact.telephone2 }}
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body pd-40 sp-3 text-center" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            width="220"
            height="200"
            viewBox="0 0 647.63626 632.17383"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#f2f2f2"
            />
            <path
              d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#3f3d56"
            />
            <path
              d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#1b84e7"
            />
            <circle cx="190.15351" cy="24.95465" r="20" fill="#1b84e7" />
            <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
            <path
              d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#e6e6e6"
            />
            <path
              d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#3f3d56"
            />
            <path
              d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#1b84e7"
            />
            <circle cx="433.63626" cy="105.17383" r="20" fill="#1b84e7" />
            <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
          </svg>
          <h6 class="tx-md-20 tx-inverse mt-4">Nenhum Usuário Vinculado</h6>
        </div>
      </template>

      <template #footer>
        <div class="text-center">
          <button
            type="button"
            class="crm-button"
            @click.prevent="closeLinkedUsersModal"
          >
            Fechar
          </button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style>
.user-groups-table {
  width: 100%;
}

.user-groups-table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

.user-groups-table tr td {
  padding: 20px 10px;
  color: #343a40;
}

.user-groups-table .company-item .company-letter {
  width: 36px;
  height: 36px;
  border-radius: 64px;
  background-color: #80b7e2;
  color: white;
}

.user-groups-table .company-item h5 {
  font-weight: bold;
}

.user-groups-table .company-item a {
  color: inherit;
}

.user-groups-table .company-type .tag {
  display: block;
  padding: 10px 20px;
  background-color: green;
  color: white;
  border-radius: 100px;
  font-weight: 300;
}

.groups-table .table thead tr th,
.groups-table .table thead tr td {
  width: auto !important;
  min-width: 100px !important;
  max-width: unset !important;
}

.groups-table .table thead tr th:first-of-type,
.groups-table .table thead tr td:first-of-type,
.groups-table .table tbody tr td:first-of-type {
  width: 10% !important;
}

.groups-table .table thead tr th:nth-of-type(2),
.groups-table .table thead tr td:nth-of-type(2),
.groups-table .table tbody tr td:nth-of-type(2) {
  width: 20% !important;
}

.groups-table .table thead tr th:nth-of-type(3),
.groups-table .table thead tr td:nth-of-type(3),
.groups-table .table tbody tr td:nth-of-type(3) {
  width: 20% !important;
}

.groups-table .table thead tr th:nth-of-type(4),
.groups-table .table thead tr td:nth-of-type(4),
.groups-table .table tbody tr td:nth-of-type(4) {
  width: 30% !important;
}

.groups-table .table thead tr th:nth-of-type(5),
.groups-table .table thead tr td:nth-of-type(5),
.groups-table .table tbody tr td:nth-of-type(5) {
  width: 20% !important;
}

.tooltip-container {
  position: relative;
}

.tooltip-message {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 240px;
  height: 120px;
  overflow: auto;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0s;
}

.tooltip-container:hover .tooltip-message {
  opacity: 1;
  visibility: visible;
}

.privileges-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.privileges-list li {
  padding: 0.25rem;
  font-size: 0.8rem;
}

.privileges-list li:not(:last-of-type) {
  /* background-color: #f5f6f7; */
  border-bottom: 1px solid #e2e2e2;
}
</style>
