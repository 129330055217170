var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-sidebar"},[_c('CustomerListFilter',{on:{"filterCompanies":_vm.filterCompanies}}),_c('form',{staticClass:"search-contacts",on:{"submit":function($event){$event.preventDefault();}}},[_c('search-icon'),_c('input',{attrs:{"type":"text","placeholder":"Pesquisar Empresa"},domProps:{"value":_vm.crmCustomersSearch},on:{"input":function($event){return _vm.updateCrmCustomersSearch($event.target.value)}}}),_c('div',{staticClass:"dropdown dropleft"},[_vm._m(0),_c('div',{staticClass:"dropdown-menu wd-300 pd-5"},[_c('nav',{staticClass:"nav dropdown-nav"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'CrmAddContact' })}}},[_c('i',{staticClass:"icon ion-ios-person"}),_vm._v(" Adicionar Contato")]),_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'CrmAddCustomer' })}}},[_c('i',{staticClass:"icon ion-ios-briefcase"}),_vm._v(" Adicionar Empresa")])])])])],1),_c('div',{staticClass:"contacts-list"},[_c('ul',{staticClass:"contacts-letters"},_vm._l((_vm.alphabet),function(letter){return _c('li',{key:letter},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToLetter(letter)}}},[_vm._v(_vm._s(letter))])])}),0),_c('ul',{ref:"contactList",staticClass:"contacts"},_vm._l((_vm.orderedContacts),function(contacts,index){return _c('div',{key:index},[_c('li',{staticClass:"contact-letter-separator",attrs:{"data-letter":contacts[0].completeName.split('')[0]
              ? contacts[0].completeName.split('')[0].toUpperCase()
              : ''}},[_c('span',[_vm._v(_vm._s(contacts[0].completeName.split("")[0] ? contacts[0].completeName.split("")[0].toUpperCase() : ""))])]),_vm._l((contacts),function(contact){return _c('li',{key:contact.id,class:[
            'contact-item',
            contact.id == _vm.$route.params.id ? ' active' : '',
          ],on:{"click":function($event){$event.preventDefault();return _vm.openContact(contact.id)}}},[_c('span',{staticClass:"contact-letter"},[_vm._v(_vm._s(_vm.getFirstLetter(contact.completeName)))]),_c('div',{staticClass:"contact-content"},[(contact.completeName !== null)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(contact.completeName.slice(0, 20)))]):_c('span',{staticClass:"name"},[_vm._v(_vm._s(contact.name.slice(0, 20)))]),_c('span',{staticClass:"email tx-14"},[_vm._v(_vm._s(contact.name ? contact.name.slice(0, 15) : "")+" ")]),_c('span',{staticClass:"email tx-12 tx-uppercase tx-light"},[_vm._v(_vm._s(contact.federalID))]),(_vm.checkIfIsOutOfDate(contact.modified))?_c('span',{staticClass:"text-warning tx-12 mt-1"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" Cadastro desatualizado")]):_vm._e()]),(
              contact.dateOfBirth &&
              _vm.checkBirthday(contact.dateOfBirth).birthday === true
            )?_c('div',{staticClass:"contact-birthday"},[_c('cake-icon',{attrs:{"color":"#80B7E2","size":36}})],1):_vm._e(),(
              contact.openingDate &&
              _vm.checkBirthday(contact.openingDate).birthday === true
            )?_c('div',{staticClass:"contact-birthday"},[_c('cake-icon',{attrs:{"color":"#80B7E2","size":36}})],1):_vm._e()])})],2)}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dd-link",attrs:{"href":"#","data-toggle":"dropdown"}},[_c('div',[_c('i',{staticClass:"icon ion-more mg-r-10 tx-30"})])])
}]

export { render, staticRenderFns }