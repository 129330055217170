<template>
  <form
    id="di-adicao-tributos-form"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">II - Imposto de Importação</h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i>
            Cálculo de Imposto de Importação da Adição
          </p>
        </div>
        <div>
          <div class="d-h-t-right">
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.valorBaseCalculoAdval | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Base de <br />
                Cálculo</span
              >
            </div>
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.iiValorCalculoIptAdval | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Valor <br />
                Calculado
              </span>
            </div>
            <button
              class="btn btn-oblong btn-primary mg-l-20"
              @click.prevent="calcularIIAdicao"
            >
              <i class="icon ion-calculator tx-22"></i>
              Calcular
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div class="row no-gutters">
        <div class="col-md-12">
          <div
            class="alert alert-warning mg-b-20"
            role="alert"
            v-if="
              activeAdicao.adValorem < activeAdicao.aliquotaii ||
              activeAdicao.adValorem > activeAdicao.aliquotaii
            "
          >
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <strong>Alíquota Ad Valorem (%): </strong>

            <template v-if="model.adValorem < model.aliquotaii">
              A alíquota digitada é <strong>MENOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotaii }}</strong
              >%.
            </template>
            <template v-else-if="model.adValorem > model.aliquotaii">
              A alíquota digitada é <strong>MAIOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotaii }}</strong
              >%.
            </template>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 ht-65-force">
            <label class="form-control-label"> Regime de Tributação </label>
            <v-select
              v-model="model.codRegTributacaoII"
              label="value"
              :options="codigosRegTributacaoII"
              @change="setCodRegTributacaoII"
              name="codRegTributacaoII"
              :disabled="disableFields"
            >
              <template slot="option" slot-scope="option">
                {{ option.value }}
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="col-md-3"
          v-if="
            model.codRegTributacaoII !== undefined &&
            model.codRegTributacaoII !== null &&
            model.codRegTributacaoII.key !== '1'
          "
        >
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Fundamentação Legal </label>
            <v-select
              v-model="model.fundamentoLegalII"
              :on-search="getFundamentoLegalII"
              label="texto"
              :options="listOfFundamentoLegalII"
              name="fundamentoLegalII"
            >
              <template slot="option" slot-scope="option">{{
                option.texto
              }}</template>
            </v-select>
          </div>
        </div>
        <div
          class="col-md-2 bg-gray-100"
          v-if="
            model.codRegTributacaoII !== null &&
            model.codRegTributacaoII !== undefined &&
            model.codRegTributacaoII.key !== '2' &&
            model.codRegTributacaoII.key !== '6' &&
            model.codRegTributacaoII.key !== '9'
          "
        >
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Alíquota Ad Valorem (%) </label>
            <money
              class="form-control"
              v-model="model.adValorem"
              v-bind="percent"
              @input.native="calcularIIAdicao"
            ></money>
          </div>
        </div>
        <div
          class="col-md-2 bg-gray-100"
          v-if="
            model.tipoAcordoTarifario !== null &&
            model.tipoAcordoTarifario !== undefined
          "
        >
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"> Alíquota Acordo(%) </label>
            <money
              class="form-control"
              v-model="model.aliquotaAcordoII"
              v-bind="percent"
              @input.native="calcularIIAdicao"
            ></money>
          </div>
        </div>
        <div
          class="col-md-2 bg-gray-100"
          v-if="
            model.codRegTributacaoII !== null &&
            model.codRegTributacaoII !== undefined &&
            model.codRegTributacaoII.key !== undefined &&
            (model.codRegTributacaoII.key === '4' ||
              model.codRegTributacaoII.key === '5')
          "
        >
          <div class="form-group mg-md-l--1 bd-l-0-force">
            <label class="form-control-label"> Alíquota Reduzida (%) </label>
            <money
              class="form-control"
              v-model="model.reduzida"
              v-bind="percent"
              @input.native="calcularIIAdicao"
            ></money>
            <span
              v-show="errors.has('reduzida')"
              class="parsley-errors-list filled"
              >Alíquota Reduzida (%) é um campo obrigatório.</span
            >
          </div>
        </div>
        <div
          class="col-md-2 bg-gray-100"
          v-if="
            model.codRegTributacaoII !== null &&
            model.codRegTributacaoII !== undefined &&
            (model.codRegTributacaoII.key === '4' ||
              model.codRegTributacaoII.key === '5')
          "
        >
          <div class="form-group mg-md-l--1 bd-l-0-force">
            <label class="form-control-label">
              Percentual de Redução (%)
            </label>
            <money
              class="form-control"
              v-model="model.reducaoIi"
              v-bind="percent"
              @input.native="calcularIIAdicao"
            ></money>
            <span
              v-show="errors.has('reducaoIi')"
              class="parsley-errors-list filled"
              >Percentual de Redução (%) é um campo obrigatório.</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-6">
          <label class="section-title mg-t-10"> Ex Tarifário II </label>
          <div class="row no-gutters">
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Número</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.numeroEXAtoVinculadoExII"
                  name="numeroEXAtoVinculadoExII"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Ato Legal</label>
                <v-select
                  v-model="model.siglaTipoAtoVinculadoExII"
                  label="value"
                  :options="tiposAtoLegalNcm"
                  name="siglaTipoAtoVinculadoExII"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Órgão Emissor</label>
                <v-select
                  v-model="model.siglaOrgaoAtoVinculadoExII"
                  :on-search="getOrgaosEmissores"
                  label="descricao"
                  :options="listOfOrgaosEmissores"
                  name="siglaOrgaoAtoVinculadoExII"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.codigo }}</strong> {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Número do Ato</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="model.numeroAtoVinculadoExII"
                  name="numeroAtoVinculadoExII"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Ano</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="model.dataAnoAtoVinculadoExII"
                  name="dataAnoAtoVinculadoExII"
                  min="0"
                  max="2050"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-6"
          v-if="
            activeAdicao.codRegTributacaoII !== null &&
            activeAdicao.codRegTributacaoII !== undefined &&
            activeAdicao.codRegTributacaoII.key !== undefined &&
            (activeAdicao.codRegTributacaoII.key === '1' ||
              activeAdicao.codRegTributacaoII.key === '4' ||
              activeAdicao.codRegTributacaoII.key === '5')
          "
        >
          <label class="section-title mg-t-10"> Acordo Tarifário </label>
          <div class="row no-gutters">
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Tipo</label>
                <v-select
                  v-model="model.tipoAcordoTarifario"
                  label="value"
                  :options="tiposAcordos"
                  name="tipoAcordoTarifario"
                  @change="setTipoAcordoTarifario"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Ato Legal</label>
                <v-select
                  v-model="model.siglaTipoAtoVinculadoAcordoII"
                  label="value"
                  :options="tiposAtoLegalNcm"
                  name="siglaTipoAtoVinculadoAcordoII"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Órgão Emissor</label>
                <v-select
                  v-model="model.siglaOrgaoAtoVinculadoAcordoII"
                  :on-search="getOrgaosEmissores"
                  label="texto"
                  :options="listOfOrgaosEmissores"
                  name="siglaOrgaoAtoVinculadoAcordoII"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.codigo }}</strong> {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>
            <template v-if="showAcordoAladiAcordoII">
              <div class="col-12">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Acordo ALADI </label>
                  <v-select
                    v-model="model.codigoAcordoAladi"
                    :on-search="getAcordoAladiII"
                    label="texto"
                    :options="listOfAcordosAladi"
                    name="codigoAcordoAladi"
                  >
                    <template slot="option" slot-scope="option">
                      <strong>{{ option.codigo }}</strong>
                      {{ option.descricao }}
                    </template>
                  </v-select>
                </div>
              </div>
            </template>

            <div class="col-4">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Número do Ato</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.numeroAtoVinculadoAcordoII"
                  name="numeroAtoVinculadoAcordoII"
                />
              </div>
            </div>
            <div class="col-4">
              <div
                class="form-group mg-md-l--1 bd-t-0-force bd-l-0-force ht-65-force"
              >
                <label class="form-control-label"> Ano</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.dataAnoAtoVinculadoAcordoII"
                  name="dataAnoAtoVinculadoAcordoII"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                <label class="form-control-label"> Ex</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.numeroEXAtoVinculadoAcordoII"
                  name="numeroEXAtoVinculadoAcordoII"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="report-summary-header mg-t-20 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            IPI - Imposto Sobre Produtos Industrializados
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i>
            Cálculo de Imposto Sobre Produtos Industrializados da Adição.
          </p>
        </div>
        <div>
          <div class="d-h-t-right">
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.valorBaseCalculoAdvalIPI
                    | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Base de <br />
                Cálculo</span
              >
            </div>
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.ipiValorCalculoIptAdval | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Valor <br />
                Calculado
              </span>
            </div>
            <button
              class="btn btn-oblong btn-primary mg-l-20"
              @click.prevent="calcularIPIAdicao"
            >
              <i class="icon ion-calculator tx-22"></i>
              Calcular
            </button>
          </div>
        </div>
      </div>
      <hr />

      <template
        v-if="
          activeAdicao.codRegTributacaoII !== null &&
          activeAdicao.codRegTributacaoII !== undefined &&
          (activeAdicao.codRegTributacaoII.key === '2' ||
            activeAdicao.codRegTributacaoII.key === '6')
        "
      >
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="alert alert-warning mg-b-20" role="alert">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>IPI: </strong> Para o regime de tributação escolhido não é
              necessário informar dados do IPI.
            </div>
          </div>
        </div>

        <hr />
      </template>
      <template v-else>
        <div class="row no-gutters">
          <div class="col-md-12">
            <div
              class="alert alert-warning mg-b-20"
              role="alert"
              v-if="
                activeAdicao.adValoremIpi < activeAdicao.aliquotaipi ||
                activeAdicao.adValoremIpi > activeAdicao.aliquotaipi
              "
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>IPI - Alíquota Ad Valorem (%): </strong>

              <template v-if="model.adValoremIpi < model.aliquotaipi">
                A alíquota digitada é <strong>MENOR</strong> do que a que está
                cadastrada para este NCM. A alíquota original é
                <strong>{{ model.aliquotaipi }}</strong
                >%.
              </template>
              <template v-else-if="model.adValoremIpi > model.aliquotaipi">
                A alíquota digitada é <strong>MAIOR</strong> do que a que está
                cadastrada para este NCM. A alíquota original é
                <strong>{{ model.aliquotaipi }}</strong
                >%.
              </template>
            </div>
          </div>

          <template
            v-if="
              activeAdicao.ipiTributavel !== null &&
              activeAdicao.ipiTributavel !== undefined &&
              activeAdicao.ipiTributavel
            "
          >
            <div class="col-md-3">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Regime de Tributação </label>
                <v-select
                  v-model="model.tipoRegTributacao"
                  label="value"
                  :options="tiposRegTributacaoIPI"
                  @change="setTipoRegTributacaoIPI()"
                  name="tipoRegTributacao"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Nota Complementar TIPI
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.notaComplementarTIPI"
                  name="notaComplementarTIPI"
                />
              </div>
            </div>
            <div class="col-md-2 bg-gray-100">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Alíquota Ad Valorem (%)
                </label>
                <money
                  class="form-control"
                  v-model="model.adValoremIpi"
                  v-bind="percent"
                  @change.native="calcularIPIAdicao"
                ></money>
                <span
                  v-show="errors.has('adValoremIpi')"
                  class="parsley-errors-list filled"
                  >Alíquota Ad Valorem (%) é um campo obrigatório.</span
                >
              </div>
            </div>
            <div
              class="col-md-2 bg-gray-100"
              v-if="
                activeAdicao.tipoRegTributacao !== null &&
                activeAdicao.tipoRegTributacao !== undefined &&
                activeAdicao.tipoRegTributacao.key !== undefined &&
                activeAdicao.tipoRegTributacao.key === '2'
              "
            >
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Reduzida (%) </label>
                <money
                  class="form-control"
                  v-model="model.reduzidaIpi"
                  v-bind="percent"
                  @change.native="calcularIPIAdicao"
                ></money>
                <span
                  v-show="errors.has('reduzidaIpi')"
                  class="parsley-errors-list filled"
                  >Reduzida (%) é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-2">
              <div class="input-group-solved pd-l-20 pd-t-15">
                <toggle-button
                  :labels="{
                    checked: 'IPI Tributável',
                    unchecked: 'IPI não Tributável',
                  }"
                  :sync="true"
                  :height="30"
                  :width="120"
                  :color="{ unchecked: '#bf0909' }"
                  v-model="model.ipiTributavel"
                />
              </div>
            </div>
          </template>
          <div class="col-md-2" v-else>
            <div class="input-group-solved pd-l-0">
              <toggle-button
                :labels="{
                  checked: 'IPI Tributável',
                  unchecked: 'IPI não Tributável',
                }"
                :sync="true"
                :height="30"
                :width="120"
                :color="{ unchecked: '#bf0909' }"
                v-model="model.ipiTributavel"
              />
            </div>
          </div>
        </div>

        <div
          class="row no-gutters"
          v-if="
            model.ipiTributavel !== null &&
            model.ipiTributavel !== undefined &&
            model.ipiTributavel &&
            model.tipoRegTributacao.key !== undefined &&
            model.tipoRegTributacao !== null &&
            model.tipoRegTributacao !== undefined &&
            model.tipoRegTributacao.key !== '2'
          "
        >
          <div class="col-12">
            <label class="section-title mg-t-10"> Alíquota Unitária </label>
          </div>
          <div class="col-2">
            <div class="form-group mg-md-l--1 ht-65-force">
              <label class="form-control-label">
                Valor Unidade de Medida (R$)
              </label>
              <money
                class="form-control"
                v-model="model.valorUnMedidaIpi"
                name="valorUnMedidaIpi"
                v-bind="money4"
                @change.native="calcularIPIAdicao"
              ></money>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
              <label class="form-control-label"> Unidade de Medida </label>
              <input
                class="form-control"
                type="text"
                v-model="model.unMedidaIpi"
                name="unMedidaIpi"
              />
            </div>
          </div>
          <div class="col-2">
            <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
              <label class="form-control-label"> Quantidade na Unidade </label>
              <input
                class="form-control"
                type="number"
                v-model="model.qtdeUnIpi"
                name="qtdeUnIpi"
                @change.native="calcularIPIAdicao"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group mg-md-l--1 ht-65-force">
              <label class="form-control-label"> Tipo do Recipiente </label>
              <input
                class="form-control"
                type="text"
                v-model="model.tipoRecIpi"
                name="tipoRecIpi"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group mg-md-l--1 ht-65-force">
              <label class="form-control-label"> Capacidade </label>
              <input
                class="form-control"
                type="number"
                v-model="model.capacidadeRecIpi"
                name="capacidadeRecIpi"
              />
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-6">
            <label class="section-title mg-t-10"> EX Tarifário IPI </label>
            <div class="row no-gutters">
              <div class="col-6">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Número </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.numeroEXAtoVinculadoExIPI"
                    name="numeroEXAtoVinculadoExIPI"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Ato Legal </label>
                  <v-select
                    v-model="model.siglaTipoAtoVinculadoExIPI"
                    label="value"
                    :options="tiposAtoLegalNcm"
                    name="siglaTipoAtoVinculadoExIPI"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.value }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Órgão Emissor </label>
                  <v-select
                    v-model="model.siglaOrgaoAtoVinculadoExIPI"
                    :on-search="getOrgaosEmissores"
                    label="descricao"
                    :options="listOfOrgaosEmissores"
                    name="siglaOrgaoAtoVinculadoExIPI"
                  >
                    <template slot="option" slot-scope="option">
                      <strong>{{ option.codigo }}</strong>
                      {{ option.descricao }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Número do Ato </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.numeroAtoVinculadoExIPI"
                    name="numeroAtoVinculadoExIPI"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Ano </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.dataAnoAtoVinculadoExIPI"
                    name="dataAnoAtoVinculadoExIPI"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-6"
            v-if="
              model.tipoRegTributacao !== null &&
              model.tipoRegTributacao !== undefined &&
              model.tipoRegTributacao.key !== undefined &&
              model.tipoRegTributacao.key !== '4'
            "
          >
            <label class="section-title mg-t-10">
              Fundamento Legal do Regime de Tributação
            </label>
            <div class="row no-gutters">
              <div class="col-6">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Ato Legal </label>
                  <v-select
                    v-model="model.siglaTipoAtoVinculadoAcordoIPI"
                    label="value"
                    :options="tiposAtoLegalNcm"
                    name="siglaTipoAtoVinculadoAcordoIPI"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.value }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Número do Ato </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.numeroAtoVinculadoAcordoIPI"
                    name="numeroAtoVinculadoAcordoIPI"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Órgão Emissor </label>
                  <v-select
                    v-model="model.siglaOrgaoAtoVinculadoAcordoIPI"
                    :on-search="getOrgaosEmissores"
                    label="descricao"
                    :options="listOfOrgaosEmissores"
                    name="siglaOrgaoAtoVinculadoAcordoIPI"
                  >
                    <template slot="option" slot-scope="option">
                      <strong>{{ option.codigo }}</strong>
                      {{ option.descricao }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Ano </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.dataAnoAtoVinculadoAcordoIPI"
                    name="dataAnoAtoVinculadoAcordoIPI"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mg-md-l--1 bd-t-0-force ht-65-force">
                  <label class="form-control-label"> Ex </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.numeroEXAtoVinculadoAcordoIPI"
                    name="numeroEXAtoVinculadoAcordoIPI"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <hr />

      <div class="report-summary-header mg-t-20 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">PIS / COFINS</h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i>
            Cálculo de PIS e COFINS da Adição.
          </p>
        </div>
        <div>
          <div class="d-h-t-right">
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.valorBaseCalculoAdvalPIS
                    | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Base de <br />
                Cálculo</span
              >
            </div>

            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.pisValorCalculoIptAdval | numeralFormat("0,0.00")
                }}
              </h1>
              <span>Valor PIS<br />Calculado </span>
            </div>

            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.cofinsValorCalculoIptAdval
                    | numeralFormat("0,0.00")
                }}
              </h1>
              <span
                >Valor COFINS<br />
                Calculado</span
              >
            </div>

            <button
              class="btn btn-oblong btn-primary mg-l-20"
              @click.prevent="calcularPisAdicao()"
            >
              <i class="icon ion-calculator tx-22"></i>
              Calcular
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div class="row no-gutters">
        <div class="col-md-12">
          <div
            class="alert alert-warning mg-b-20"
            role="alert"
            v-if="
              model.aliquotaAdValoremPIS < model.aliquotapis ||
              model.aliquotaAdValoremPIS > model.aliquotapis
            "
          >
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <strong>PIS/PASEP - Alíquota Ad Valorem (%): </strong>

            <template
              v-if="activeAdicao.aliquotaAdValoremPIS < model.aliquotapis"
            >
              A alíquota digitada é <strong>MENOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotapis }}</strong
              >%.
            </template>
            <template
              v-else-if="activeAdicao.aliquotaAdValoremPIS > model.aliquotapis"
            >
              A alíquota digitada é <strong>MAIOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotapis }}</strong
              >%.
            </template>
          </div>
        </div>
        <div class="col-md-12">
          <div
            class="alert alert-warning mg-b-20"
            role="alert"
            v-if="
              model.aliquotaAdValoremCOFINS < model.aliquotacofins ||
              model.aliquotaAdValoremCOFINS > model.aliquotacofins
            "
          >
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <strong>COFINS - Alíquota Ad Valorem (%): </strong>

            <template
              v-if="model.aliquotaAdValoremCOFINS < model.aliquotacofins"
            >
              A alíquota digitada é <strong>MENOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotacofins }}</strong
              >%.
            </template>
            <template
              v-else-if="model.aliquotaAdValoremCOFINS > model.aliquotacofins"
            >
              A alíquota digitada é <strong>MAIOR</strong> do que a que está
              cadastrada para este NCM. A alíquota original é
              <strong>{{ model.aliquotacofins }}</strong
              >%.
            </template>
          </div>
        </div>
        <div class="col-6">
          <label class="section-title mg-t-10"> Tributação </label>
          <div class="row no-gutters">
            <div class="col-10">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Regime de Tributação </label>
                <v-select
                  v-model="model.codRegTributacaoPIS"
                  label="value"
                  :options="codigosRegTributacaoPIS"
                  @change="setCodRegTributacaoPIS()"
                  name="codRegTributacaoPIS"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.value }}
                  </template>
                </v-select>
              </div>
            </div>
            <div
              class="col-10"
              v-if="
                this.model.codRegTributacaoPIS !== null &&
                this.model.codRegTributacaoPIS !== undefined &&
                this.model.codRegTributacaoPIS.key !== undefined &&
                this.model.codRegTributacaoPIS.key !== '1'
              "
            >
              <div class="form-group mg-md-l--1 ht-65-force bd-t-0-force">
                <label class="form-control-label"> Fundamentação Legal </label>
                <v-select
                  v-model="model.fundamentoLegalPisCofins"
                  :on-search="getFundamentoLegalPisCofins"
                  label="texto"
                  :options="listOfFundamentoLegalpisCofins"
                  name="fundamentoLegalPisCofins"
                  class="tx-12"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.codigo }} | {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <label class="section-title mg-t-10">
            Redução da Base de Cálculo
          </label>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Fundamentação Legal </label>
                <v-select
                  v-model="model.fundamentoLegalBC"
                  :on-search="getFundamentoLegalPisCofinsReducao"
                  label="texto"
                  :options="listOfFundamentoLegalpisCofinsReducaoBaseCalculo"
                  name="fundamentoLegalBC"
                  class="tx-12"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.codigo }} | {{ option.descricao }}
                  </template>
                </v-select>
              </div>
            </div>

            <div
              class="col-6 bg-gray-100"
              v-if="
                this.model.codRegTributacaoPIS !== null &&
                this.model.codRegTributacaoPIS !== undefined &&
                this.model.codRegTributacaoPIS.key !== undefined &&
                this.model.codRegTributacaoPIS.key !== '1'
              "
            >
              <div class="form-group mg-md-l--1 ht-65-force bd-t-0-force">
                <label class="form-control-label">
                  Percentual de Redução
                </label>
                <money
                  class="form-control"
                  v-model="model.percentualReducao"
                  name="percentualReducao"
                  v-bind="percent"
                  @input.native="calcularPisAdicao"
                ></money>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row no-gutters"
        v-if="
          this.model.codRegTributacaoPIS !== null &&
          this.model.codRegTributacaoPIS !== undefined &&
          this.model.codRegTributacaoPIS.key !== undefined &&
          this.model.codRegTributacaoPIS.key !== '2' &&
          this.model.codRegTributacaoPIS.key !== '6'
        "
      >
        <div class="col-6">
          <label class="section-title mg-t-10"> PIS / PASEP </label>
          <div class="row no-gutters">
            <div class="col-6 bg-gray-100">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Alíquota Ad Valorem PIS/PASEP (%)
                </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaAdValoremPIS"
                  name="aliquotaAdValoremPIS"
                  v-bind="percent"
                  @input.native="calcularPisAdicao"
                ></money>
              </div>
            </div>
            <div
              class="col-6 bg-gray-100"
              v-if="
                this.model.codRegTributacaoPIS !== null &&
                this.model.codRegTributacaoPIS !== undefined &&
                this.model.codRegTributacaoPIS.key !== undefined &&
                this.model.codRegTributacaoPIS.key === '4'
              "
            >
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Alíquota Reduzida PIS/PASEP (%)
                </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaReduzidaPIS"
                  name="aliquotaReduzidaPIS"
                  v-bind="percent"
                  @input.native="calcularPisAdicao"
                ></money>
              </div>
            </div>
          </div>

          <template
            v-if="
              this.model.codRegTributacaoPIS !== null &&
              this.model.codRegTributacaoPIS !== undefined &&
              this.model.codRegTributacaoPIS.key !== undefined &&
              this.model.codRegTributacaoPIS.key !== '2' &&
              this.model.codRegTributacaoPIS.key !== '4' &&
              this.model.codRegTributacaoPIS.key !== '6'
            "
          >
            <label class="section-title mg-t-10 tx-normal">
              Alíquota Específica
            </label>
            <div class="row no-gutters">
              <div class="col-4">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Valor em Reais </label>
                  <money
                    class="form-control"
                    v-model="model.aliquotaEspecificaPIS"
                    name="aliquotaEspecificaPIS"
                    v-bind="percent"
                    @input.native="calcularPisAdicao"
                  ></money>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                  <label class="form-control-label"> Unidade de Medida </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.unMedidaEspecificaPIS"
                    name="unMedidaEspecificaPIS"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                  <label class="form-control-label"> Quantidade </label>
                  <input
                    class="form-control"
                    type="number"
                    v-model="model.qtdeMedidaPIS"
                    name="qtdeMedidaPIS"
                    @input.native="calcularPisAdicao"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="col-6">
          <label class="section-title mg-t-10"> COFINS </label>
          <div class="row no-gutters">
            <div class="col-6 bg-gray-100">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Alíquota Ad Valorem COFINS (%)
                </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaAdValoremCOFINS"
                  name="aliquotaAdValoremCOFINS"
                  v-bind="percent"
                  @input.native="calcularCofinsAdicao"
                ></money>
              </div>
            </div>
            <div
              class="col-6 bg-gray-100"
              v-if="
                this.model.codRegTributacaoPIS !== null &&
                this.model.codRegTributacaoPIS !== undefined &&
                this.model.codRegTributacaoPIS.key !== undefined &&
                this.model.codRegTributacaoPIS.key === '4'
              "
            >
              <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                <label class="form-control-label">
                  Alíquota Reduzida COFINS (%)
                </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaReduzidaCOFINS"
                  name="aliquotaReduzidaCOFINS"
                  v-bind="percent"
                  @input.native="calcularCofinsAdicao"
                ></money>
              </div>
            </div>
          </div>

          <template
            v-if="
              this.model.codRegTributacaoPIS !== null &&
              this.model.codRegTributacaoPIS !== undefined &&
              this.model.codRegTributacaoPIS.key !== undefined &&
              this.model.codRegTributacaoPIS.key !== '2' &&
              this.model.codRegTributacaoPIS.key !== '4' &&
              this.model.codRegTributacaoPIS.key !== '6'
            "
          >
            <label class="section-title mg-t-10 tx-normal">
              Alíquota Específica
            </label>
            <div class="row no-gutters">
              <div class="col-4">
                <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                  <label class="form-control-label"> Valor em Reais </label>
                  <money
                    class="form-control"
                    v-model="model.aliquotaEspecificaCOFINS"
                    name="aliquotaEspecificaCOFINS"
                    v-bind="percent"
                    @input.native="calcularCofinsAdicao"
                  ></money>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Unidade de Medida </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="model.unMedidaEspecificaCOFINS"
                    name="unMedidaEspecificaCOFINS"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group mg-md-l--1 ht-65-force">
                  <label class="form-control-label"> Quantidade </label>
                  <input
                    class="form-control"
                    type="number"
                    v-model="model.qtdeMedidaCOFINS"
                    name="qtdeMedidaCOFINS"
                    @input.native="calcularCofinsAdicao"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <hr />

      <div class="report-summary-header mg-t-20 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            Direitos Antidumping ou Compensatórios
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i>
            Cálculo de Direitos Antidumping ou Compensatórios da Adição.
          </p>
        </div>
        <div>
          <div class="d-h-t-right">
            <div class="summary-item">
              <h1>
                R$
                {{
                  activeAdicao.valorBaseCalculoAdvalAntidumping
                    | numeralFormat("0,0.00")
                }}
              </h1>
              <span>Base de Cálculo<br />Antidumping</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-12">
          <div class="form-group mg-md-l--1 bd-b-0-force">
            <label class="form-control-label">
              Tipo de Alíquota do Antidumping
            </label>
            <div class="row">
              <div class="col-lg-3 mg-b-0">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="codigoTipoAliquotaIPTAntidumping"
                    type="radio"
                    v-model="model.codigoTipoAliquotaIPTAntidumping"
                    :value="'0'"
                    v-validate="'required'"
                    @change="setCodigoTipoAliquotaIPTAntidumping(0)"
                  />
                  <span> Nenhum / Sem Antidumping</span>
                </label>
              </div>
              <div class="col-lg-3 mg-b-0 bd-b-0-force">
                <label class="rdiobox rdiobox-warning mg-t-0-force">
                  <input
                    name="codigoTipoAliquotaIPTAntidumping"
                    type="radio"
                    v-model="model.codigoTipoAliquotaIPTAntidumping"
                    :value="'1'"
                    v-validate="'required'"
                    @change="setCodigoTipoAliquotaIPTAntidumping(1)"
                  />
                  <span> 1 - Ad Valorem</span>
                </label>
              </div>
              <div class="col-lg-3 mg-b-0 bd-b-0-force">
                <label class="rdiobox rdiobox-primary mg-t-0-force">
                  <input
                    name="codigoTipoAliquotaIPTAntidumping"
                    type="radio"
                    v-model="model.codigoTipoAliquotaIPTAntidumping"
                    :value="'2'"
                    v-validate="'required'"
                    @change="setCodigoTipoAliquotaIPTAntidumping(2)"
                  />
                  <span>2 - Específica</span>
                </label>
              </div>
              <div class="col-lg-3 mg-b-0 bd-b-0-force">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="codigoTipoAliquotaIPTAntidumping"
                    type="radio"
                    v-model="model.codigoTipoAliquotaIPTAntidumping"
                    :value="'3'"
                    v-validate="'required'"
                    @change="setCodigoTipoAliquotaIPTAntidumping(3)"
                  />
                  <span>3 - Mista (Ad Valorem + Específica)</span>
                </label>
              </div>

              <span
                v-show="errors.has('codigoAplicacaoMercadoria')"
                class="parsley-errors-list filled"
                >Selecione uma Aplicação</span
              >
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group mg-md-l--1 ht-65-force">
            <label class="form-control-label"> Ato Legal </label>
            <v-select
              v-model="model.siglaTipoAtoVinculadoAcordoAntidumping"
              label="value"
              :options="tiposAtoLegalNcm"
              name="siglaTipoAtoVinculadoAcordoAntidumping"
            >
              <template slot="option" slot-scope="option">
                {{ option.value }}
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
            <label class="form-control-label"> Órgão Emissor </label>
            <v-select
              v-model="model.siglaOrgaoAtoVinculadoAcordoAntidumping"
              :on-search="getOrgaosEmissores"
              label="descricao"
              :options="listOfOrgaosEmissores"
              name="siglaOrgaoAtoVinculadoAcordoAntidumping"
            >
              <template slot="option" slot-scope="option">
                <strong>{{ option.codigo }}</strong> {{ option.descricao }}
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
            <label class="form-control-label"> Número do Ato </label>
            <input
              class="form-control"
              type="text"
              v-model="model.numeroAtoVinculadoAntidumping"
              name="numeroAtoVinculadoAntidumping"
            />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
            <label class="form-control-label"> Ano </label>
            <input
              class="form-control"
              type="text"
              v-model="model.dataAnoAtoVinculadoAcordoAntidumping"
              name="dataAnoAtoVinculadoAcordoAntidumping"
            />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
            <label class="form-control-label"> Ex </label>
            <input
              class="form-control"
              type="text"
              v-model="model.numeroEXAtoVinculadoAcordoAntidumping"
              name="numeroEXAtoVinculadoAcordoAntidumping"
            />
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-6">
          <label class="section-title mg-t-10"> Ad Valorem </label>
          <div class="row no-gutters">
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Ad Valorem (%) </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaAdValoremAntidumping"
                  v-bind="percent"
                  @input.native="calcularAntidumpingAdicao"
                ></money>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label"> Base de Cálculo (R$) </label>
                <money
                  class="form-control"
                  v-model="model.valorBaseCalculoAdvalAntidumping"
                  v-bind="percent"
                  @input.native="calcularAntidumpingAdicao"
                ></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <label class="section-title mg-t-10"> Alíquota Específica </label>
          <div class="row no-gutters">
            <div class="col-4">
              <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                <label class="form-control-label"> Valor (R$) </label>
                <money
                  class="form-control"
                  v-model="model.aliquotaEspecificaAntidumping"
                  name="aliquotaEspecificaAntidumping"
                  v-bind="money5"
                  @input.native="calcularAntidumpingAdicao"
                ></money>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group mg-md-l--1 bd-l-0-force ht-65-force">
                <label class="form-control-label"> Unidade de Medida </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.unMedidaEspecificaAntidumping"
                  name="unMedidaEspecificaAntidumping"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group mg-md-l--1 ht-65-force">
                <label class="form-control-label">
                  Quantidade na Unidade
                </label>
                <input
                  class="form-control"
                  type="number"
                  v-model="model.qtdeMedidaAntidumping"
                  name="qtdeMedidaAntidumping"
                  @input.native="calcularAntidumpingAdicao"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">Cálculo dos Tributos</h4>
          <p class="mg-b-0">
            <i class="icon ion-calculator mg-r-3"></i>
            Cálculo de Valores Totais dos Tributos.
          </p>
        </div>
        <div>
          <div class="d-h-t-right"></div>
        </div>
      </div>

      <div class="row mg-t-0">
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"> I.I. </label>
          <p class="invoice-info-row">
            <span>Valor Calculado</span>
            <span>{{
              activeAdicao.iiValorCalculoIptAdval | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Reduzido</span>
            <span>{{
              activeAdicao.iiValorCalculadoIIACTarifario
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Devido</span>
            <span>{{
              activeAdicao.iiValorImpostoDevido | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor a Recolher</span>
            <span>{{
              activeAdicao.iiValorIPTaRecolher | numeralFormat("0,0.00")
            }}</span>
          </p>
        </div>
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"> IPI </label>
          <p class="invoice-info-row">
            <span>Valor Calculado</span>
            <span>{{
              activeAdicao.ipiValorCalculoIptAdval | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Reduzido</span>
            <span>{{
              activeAdicao.ipiValorCalculadoIIACTarifario
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Devido</span>
            <span>{{
              activeAdicao.ipiValorImpostoDevido | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor a Recolher</span>
            <span>{{
              activeAdicao.ipiValorIPTaRecolher | numeralFormat("0,0.00")
            }}</span>
          </p>
        </div>
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"> PIS/PASEP </label>
          <p class="invoice-info-row">
            <span>Valor Calculado</span>
            <span>{{
              activeAdicao.pisValorCalculoIptAdval | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Reduzido</span>
            <span>{{
              activeAdicao.pisValorCalculadoIIACTarifario
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Devido</span>
            <span>{{
              activeAdicao.pisValorImpostoDevido | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor a Recolher</span>
            <span>{{
              activeAdicao.pisValorIPTaRecolher | numeralFormat("0,0.00")
            }}</span>
          </p>
        </div>
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"> COFINS </label>
          <p class="invoice-info-row">
            <span>Valor Calculado</span>
            <span>{{
              activeAdicao.cofinsValorCalculoIptAdval | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Reduzido</span>
            <span>{{
              activeAdicao.cofinsValorCalculadoIIACTarifario
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Devido</span>
            <span>{{
              activeAdicao.cofinsValorImpostoDevido | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor a Recolher</span>
            <span>{{
              activeAdicao.cofinsValorIPTaRecolher | numeralFormat("0,0.00")
            }}</span>
          </p>
        </div>
        <div class="col-md">
          <label class="section-label-sm tx-gray-800"> Antidumping </label>
          <p class="invoice-info-row">
            <span>Valor Calculado</span>
            <span>{{
              activeAdicao.antidumpingValorCalculoIptAdval
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Reduzido</span>
            <span>{{
              activeAdicao.antidumpingValorCalculadoIIACTarifario
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor Devido</span>
            <span>{{
              activeAdicao.antidumpingValorImpostoDevido
                | numeralFormat("0,0.00")
            }}</span>
          </p>
          <p class="invoice-info-row">
            <span>Valor a Recolher</span>
            <span>{{
              activeAdicao.antidumpingValorIPTaRecolher
                | numeralFormat("0,0.00")
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import { Money } from "v-money";
import { EventBus } from "@/events/EventBus.js";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";

export default {
  name: "di-adicao-form-tributos",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    Money,
    ToggleButton,
  },
  data() {
    return {
      initialDI: {},
      di: {},
      initialModel: {},
      model: {},
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      money4: {
        decimal: ",",
        thousands: ".",
        precision: 4,
        masked: false,
      },
      money5: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
      },
      listOfAcordosAladi: [],
      listOfOrgaosEmissores: [],
      listOfFundamentoLegalII: [],
      listOfFundamentoLegalpisCofins: [],
      listOfFundamentoLegalpisCofinsReducaoBaseCalculo: [],
      codigosPaises: [...importDeclarationLists.codigosPaises],
      codigosRegTributacaoII: [
        ...importDeclarationLists.codigosRegTributacaoII,
      ],
      codigosRegTributacaoPIS: [
        ...importDeclarationLists.codigosRegTributacaoPIS,
      ],
      tiposAcordos: [...importDeclarationLists.tiposAcordos],
      tiposAtoLegalNcm: [...importDeclarationLists.tiposAtoLegalNcm],
      tiposRegTributacaoIPI: [...importDeclarationLists.tiposRegTributacaoIPI],
      showAcordoAladiAcordoII: false,
      clicksOnComponent: 0,
    };
  },
  methods: {
    onSubmit() {
      return true;
    },
    getOrgaosEmissores(search, loading) {
      this.$store
        .dispatch("MyIMPStore/consultarCampoDenominacaoAction", {
          formRetorno: "ElaborarDIForm",
          campoRetorno: "orgaoEmissorII",
          subAction: "autoCompleteCodigo",
          q: search,
        })
        .then((response) => {
          if (response) {
            this.listOfOrgaosEmissores = response;
          }
        })
        .catch((e) => {
          this.listOfOrgaosEmissores = [];
        });
    },
    async getAcordoAladiII(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarDIAdicaoForm",
          campoRetorno: "acordoAladi",
          subAction: "autoCompleteDescricao",
          q: search,
        }
      );
      if (response) {
        this.listOfAcordosAladi = response;
        this.$set(this, "listOfAcordosAladi", response);
      }
    },
    async getFundamentoLegalII(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarAdicaoDI",
          campoRetorno: "fundamentoLegalII",
          subAction: "autoCompleteDescricao",
          parametro:
            this.model.codRegTributacaoII !== null &&
            this.model.codRegTributacaoII.key !== null
              ? this.model.codRegTributacaoII.key
              : "3",
          tipoDI: "01",
          q: search,
        }
      );
      if (response) {
        this.listOfFundamentoLegalII = response;
        this.$set(this, "listOfFundamentoLegalII", response);
      }
    },

    async getFundamentoLegalPisCofins(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarAdicaoDI",
          campoRetorno: "fundamentoLegalPisCofins",
          subAction: "autoCompleteCodigo",
          tipoDI: "01",
          parametro: "3",
          q: search,
        }
      );
      if (response) {
        this.listOfFundamentoLegalpisCofins = response;
        this.$set(this, "listOfFundamentoLegalpisCofins", response);
      }
    },
    async getFundamentoLegalPisCofinsReducao(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarAdicaoDI",
          campoRetorno: "fundamentoLegalBC",
          subAction: "autoCompleteCodigo",
          tipoDI: "01",
          q: search,
        }
      );
      if (response) {
        this.listOfFundamentoLegalpisCofinsReducaoBaseCalculo = response;
        this.$set(
          this,
          "listOfFundamentoLegalpisCofinsReducaoBaseCalculo",
          response
        );
      }
    },
    async getFundamentoLegalBC(search) {
      const response = await this.$store.dispatch(
        "MyIMPStore/consultarCampoDenominacaoAction",
        {
          formRetorno: "ElaborarAdicaoDI",
          campoRetorno: "fundamentoLegalBC",
          subAction: "autoCompleteCodigo",
          tipoDI: "01",
          q: search,
        }
      );
      if (response) {
        this.listOfFundamentoLegalII = response;
        this.$set(this, "listOfFundamentoLegalII", response);
      }
    },
    setCodigoTipoAliquotaIPTAntidumping(tipo) {
      if (tipo === 0 || tipo === "0") {
        this.model.aliquotaAdValoremAntidumping = 0.0;
        this.model.valorBaseCalculoAdvalAntidumping = 0.0;
        this.model.aliquotaEspecificaAntidumping = 0.0;
        this.model.unMedidaEspecificaAntidumping = "";
        this.model.qtdeMedidaAntidumping = 0;
      } else if (tipo === 1 || tipo === "1") {
        this.model.aliquotaEspecificaAntidumping = 0.0;
        this.model.unMedidaEspecificaAntidumping = "";
        this.model.qtdeMedidaAntidumping = 0;
      } else if (tipo === 2 || tipo === "2") {
        this.model.aliquotaAdValoremAntidumping = 0.0;
        this.model.valorBaseCalculoAdvalAntidumping = 0.0;
        this.model.aliquotaEspecificaAntidumping = 0.0;
        this.model.unMedidaEspecificaAntidumping = "";
        this.model.qtdeMedidaAntidumping = 0;
      } else if (tipo === 3 || tipo === "3") {
        this.model.aliquotaAdValoremAntidumping = 0.0;
        this.model.valorBaseCalculoAdvalAntidumping =
          this.model.valorBaseCalculoAdval;
        this.model.aliquotaEspecificaAntidumping = 0.0;
        this.model.unMedidaEspecificaAntidumping = "";
        this.model.qtdeMedidaAntidumping = 0;
      }
    },
    setTipoRegTributacaoIPI() {
      if (
        this.model !== undefined &&
        this.model.tipoRegTributacao !== undefined &&
        this.model.tipoRegTributacao.key !== undefined
      ) {
        const tipoRegTributacao = this.model.tipoRegTributacao.key;

        if (tipoRegTributacao === "2") {
          this.model.valorUnMedidaIpi = 0.0;
          this.model.unMedidaIpi = null;
          this.model.qtdeUnIpi = null;
          this.model.tipoRecIpi = null;
          this.model.capacidadeRecIpi = null;
        } else {
          this.model.reduzidaIpi = 0.0;
        }

        if (tipoRegTributacao == "4") {
          this.model.siglaTipoAtoVinculadoAcordoIPI = null;
          this.model.numeroAtoVinculadoAcordoIPI = null;
          this.model.siglaOrgaoAtoVinculadoAcordoIPI = null;
          this.model.dataAnoAtoVinculadoAcordoIPI = null;
          this.model.numeroEXAtoVinculadoAcordoIPI = null;
        } else if (tipoRegTributacao == "5") {
        } else if (tipoRegTributacao == "2") {
        } else if (tipoRegTributacao == "1") {
        }

        this.calcularIPIAdicao();
      }
    },
    setCodRegTributacaoPIS() {
      if (
        this.model !== undefined &&
        this.model.codRegTributacaoPIS !== undefined &&
        this.model.codRegTributacaoPIS.key !== undefined
      ) {
        const tipoRegTributacao = this.model.codRegTributacaoPIS.key;

        if (tipoRegTributacao === "1") {
        } else if (tipoRegTributacao == "2") {
          this.model.aliquotaAdValoremPIS = 0.0;
          this.model.aliquotaReduzidaPIS = 0.0;
          this.model.aliquotaEspecificaPIS = 0.0;
          this.model.unMedidaEspecificaPIS = null;
          this.model.qtdeMedidaPIS = null;

          this.model.aliquotaAdValoremCOFINS = 0.0;
          this.model.aliquotaReduzidaCOFINS = 0.0;
          this.model.aliquotaEspecificaCOFINS = 0.0;
          this.model.unMedidaEspecificaCOFINS = null;
          this.model.qtdeMedidaCOFINS = null;
        } else if (tipoRegTributacao == "3") {
        } else if (tipoRegTributacao == "4") {
        } else if (tipoRegTributacao == "5") {
        } else if (tipoRegTributacao == "6") {
        } else if (tipoRegTributacao == "9") {
        }

        this.calcularPisAdicao();
        this.calcularCofinsAdicao();
      }
    },
    setCodRegTributacaoII() {
      if (
        this.model !== undefined &&
        this.model.codRegTributacaoII !== undefined &&
        this.model.codRegTributacaoII.key !== undefined
      ) {
        let codRegTributacaoII = null;

        if (
          this.model !== undefined &&
          this.model !== null &&
          this.model.codRegTributacaoII !== undefined &&
          this.model.codRegTributacaoII !== null
        ) {
          codRegTributacaoII = this.model.codRegTributacaoII.key;
        }

        if (codRegTributacaoII == "1") {
          this.showAdValorem = true;
          this.showReduzida = false;
          this.showReducaoIi = false;
          this.showTipoAtoLegalNcm = false;

          if (this.model.adValorem === null || this.model.adValorem === "")
            this.model.adValorem = this.model.aliquotaii;
          if (
            this.model.adValoremIpi === null ||
            this.model.adValoremIpi === ""
          )
            this.model.adValoremIpi = this.model.aliquotaipi;
        } else if (codRegTributacaoII == "2") {
          this.zerarAcordoTarifarioII();
          this.model.adValorem = 0.0;
          this.model.adValoremIpi = 0.0;
          this.model.ipiValorCalculoIptAdval = 0.0;
        } else if (codRegTributacaoII == "3") {
          this.zerarAcordoTarifarioII();
        } else if (codRegTributacaoII == "4") {
        } else if (codRegTributacaoII == "5") {
        } else if (codRegTributacaoII == "6") {
          this.model.adValorem = 0.0;
          this.zerarAcordoTarifarioII();
          this.model.adValoremIpi = 0.0;
          this.model.ipiValorCalculoIptAdval = 0.0;
        } else if (codRegTributacaoII == "9") {
          this.model.adValorem = 0.0;
          this.zerarAcordoTarifarioII();
        }

        this.calcularIIAdicao();
      }
    },
    zerarAcordoTarifarioII() {
      this.model.tipoAcordoTarifario = null;
      this.model.siglaTipoAtoVinculadoAcordoII = null;
      this.model.siglaOrgaoAtoVinculadoAcordoII = null;
      this.model.codigoAcordoAladi = null;
      this.model.numeroAtoVinculadoAcordoII = null;
      this.model.dataAnoAtoVinculadoAcordoII = null;
      this.model.numeroEXAtoVinculadoAcordoII = null;
    },
    setTipoAcordoTarifario() {
      if (
        (this.model.tipoAcordoTarifario !== null &&
          this.model.tipoAcordoTarifario !== undefined &&
          this.model.tipoAcordoTarifario.key !== undefined &&
          this.model.tipoAcordoTarifario.key === 2) ||
        this.model.tipoAcordoTarifario.key === "2"
      ) {
        this.showAcordoAladiAcordoII = true;
      } else {
        this.showAcordoAladiAcordoII = false;
      }
    },

    async diffObjects(fieldName) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      const response = await this.$store.dispatch(
        "MyIMPStore/diffObjectsAdicao"
      );

      if (response) {
        this.initialDI = {
          ...this.di,
        };
      }
    },

    // Calculos
    async calcularIIAdicao() {
      await this.$store.dispatch("MyIMPStore/calcularIIAdicao", this.model);
      await this.$store.dispatch("MyIMPStore/calcularIPIAdicao");
    },
    async calcularIPIAdicao() {
      await this.$store.dispatch("MyIMPStore/calcularIPIAdicao");
    },
    async calcularPisAdicao() {
      await this.$store.dispatch("MyIMPStore/calcularPisAdicao");
      await this.$store.dispatch("MyIMPStore/calcularCofinsAdicao");
    },
    async calcularCofinsAdicao() {
      await this.$store.dispatch("MyIMPStore/calcularCofinsAdicao");
      await this.$store.dispatch("MyIMPStore/calcularPisAdicao");
    },
    async calcularAntidumpingAdicao() {
      await this.$store.dispatch("MyIMPStore/calcularAntidumpingAdicao");
    },
  },

  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    disableFields() {
      if (this.model.numeroLI !== null && this.model.numeroLI !== "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    activeDI() {
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };
    },
  },

  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-adicao-tributos-form");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };

    EventBus.$on(
      "operational.import-declaration.adition.Form.Tributos.gerarImpostos",
      () => {
        this.$store
          .dispatch("MyIMPStore/calcularIIAdicao")
          .then((response) =>
            this.$store.dispatch("MyIMPStore/calcularIIAdicao")
          )
          .then((response) =>
            this.$store.dispatch("MyIMPStore/calcularIPIAdicao")
          )
          .then((response) =>
            this.$store.dispatch("MyIMPStore/calcularCofinsAdicao")
          )
          .then((response) =>
            this.$store.dispatch("MyIMPStore/calcularPisAdicao")
          );
      }
    );
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
