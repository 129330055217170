<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório de Comissionados
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório de Comissionados</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown"></a>
              </div>
            </li>
            <li class="mg-t-5 mr-2">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.comissionado.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-danger mg-r-5"></i
                      >Comissionados:
                      <span
                        class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.comissionado"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-user tx-medium mg-r-5"></i>
                      Comissionado</span
                    >
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Comissionado
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais comissionados ({{
                      this.usuariosComissionados.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">
                            Comissionados
                          </label>
                          <v-select
                            v-model="filter.comissionado"
                            label="name"
                            :options="usuariosComissionados"
                            name="customers"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="filter.date.start !== '' && filter.date.end !== ''"
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar"></i> Período de Apuração:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-300">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <JsonExcel
                  v-if="
                    this.processosRelatorio != null &&
                    this.processosRelatorio.length > 0
                  "
                  :data="processosRelatorio"
                  :fields="fieldsExcel"
                  class="btn btn-success mr-2"
                >
                  <i class="fa fa-file-excel mr-1"></i> Gerar Excel
                </JsonExcel>
                <button
                  type="button"
                  class="btn btn-primary pd-x-20"
                  @click.prevent="filtrarProcessos"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> GERAR
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta
            </label>
            <p class="mg-b-0">
              Foram encontrados {{ processosRelatorio.length }} processos de
              acordo com o filtro acima.
            </p>
            <hr/>
            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th class="tx-center">Ref</th>
                  <th class="tx-center">Nome do Cliente</th>
                  <th class="tx-center">Percentual de comissão</th>
                  <th class="tx-center">Nome do comissionado</th>
                </tr>
                </thead>
                <tbody
                  v-if="
                    this.processosRelatorio != null &&
                    this.processosRelatorio.length > 0
                  "
                >
                <tr v-for="result in processosRelatorio" :key="result.id">
                  <td class="tx-center tx-uppercase tx-bold">
                    <a
                      href="#"
                      @click.prevent="
                          findProcessByIdentificationSearch(
                            result.identification
                          )
                        "
                    >
                      {{ getTypeOfProcessReferenceByProcess(result) }}
                    </a>
                  </td>

                  <td class="tx-center">{{ result.customerName }}</td>

                  <td class="valign-middle tx-center tx-uppercase">
                    %
                    {{
                      result.percetualOfComission | numeralFormat("0,0.00")
                    }}
                  </td>

                  <td class="valign-middle tx-center">
                    {{ result.commissionedName }}
                  </td>
                </tr>
                </tbody>

                <tbody v-else>
                <tr>
                  <td
                    class="valign-middle tx-center tx-center tx-uppercase tx-bold pd-5"
                    colspan="4"
                  >
                    Nenhuma inconsistência foi encontrada!
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import config from "@/config.js";
import * as labelsProcesses from "@/labels/labels.processes.js";
import JsonExcel from "vue-json-excel";
import ProcessService from "@/services/ProcessService.js";

import {mapState} from "vuex";

import moment from "moment";

export default {
  name: "OperationalComissioned",
  props: [],
  components: {
    DatePicker,
    JsonExcel,
  },
  data() {
    return {
      dataExcel: [],
      filter: {
        steps: [],
        exporters: [],
        customers: [],
        companies: [],
        contacts: [],
        customsBrokers: [],
        date: {
          start: moment().startOf("month"),
          end: moment(),
        },
        comissionado: "",
      },
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      fieldsExcel: {
        Refência: "identification",
        "Nome do cliente": "customerName",
        "Percentual da comissão": {
          field: "percetualOfComission",
          callback: (value) => `% ${parseFloat(value).toFixed(2)}`,
        },
        "Nome do comissionado": "commissionedName",
      },
      listOfCompanies: [],
      listOfCustomers: [],
      listOfContacts: [],
      listOfResults: [],
      count: 0,
      totalValuesOfInconsistencies: 0.0,
      usuariosComissionados: [],
      processosRelatorio: [],
    };
  },

  methods: {
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    filtrarProcessos() {
      const fromDate = `${moment(this.filter.date.start)
        .startOf("month")
        .format("YYYY-MM-DD")}T00:00:00`;
      const toDate = `${moment(this.filter.date.end).format(
        "YYYY-MM-DD"
      )}T23:59:00`;

      const idComissionado =
        this.filter.comissionado.length == 0
          ? ""
          : this.filter.comissionado.map((item) => item.id).join();

      this.$store.commit("setLoading", {
        show: true,
        label: "Relatório Geral de Importações",
        description: "Aguarde enquanto o relatório está sendo gerado...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/commissioned/generalListOfProcessInPeriodByCommissioned?from=${fromDate}&to=${toDate}&commissionedId=${idComissionado}`
      ).then((results) => {
        this.processosRelatorio = results.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      });
    },
    async findProcessByIdentificationSearch(identification) {
      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        identification
      );
      if (response !== undefined && response !== null && response.success) {
        // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
        this.$router.push("/operational/imp/desk?showProcess=true");
      } else Swal.fire("Processo de Importação", response.message, "error");
    },
    getUsersCommissioned() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/commissioned/usuariosComissionados`
      ).then((result) => {
        this.usuariosComissionados = result.data.data;
      });
    },
  },
  watch: {},
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
      optionsCustomers: (state) => state.ProcessStore.optionsCustomers,
      optionsCompanies: (state) => state.ProcessStore.optionsCompanies,
      optionsUsers: (state) => state.ProcessStore.optionsUsers,
    }),
  },
  mounted() {
    this.$store.dispatch("ProcessStore/getSteps");
    this.getUsersCommissioned();
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}
</style>
