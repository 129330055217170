<template>
  <div class="contacts-sidebar">
    <CustomerListFilter v-on:filterCompanies="filterCompanies" />
    <!-- <CustomerListFilter v-if="showModalCustomerListFilter" @close="showModalCustomerListFilter = true"/> -->

    <form @submit.prevent class="search-contacts">
      <search-icon />
      <input
        :value="crmCustomersSearch"
        @input="updateCrmCustomersSearch($event.target.value)"
        type="text"
        placeholder="Pesquisar Empresa"
      />

      <div class="dropdown dropleft">
        <a href="#" class="dd-link" data-toggle="dropdown">
          <div>
            <!-- <i class="fa fa-angle-left mg-r-10"></i> -->
            <i class="icon ion-more mg-r-10 tx-30"></i>
            <!-- <span>AÇÕES</span> -->
          </div>
        </a>
        <div class="dropdown-menu wd-300 pd-5">
          <nav class="nav dropdown-nav">
            <a
              href="#"
              class="nav-link"
              @click.prevent="$router.push({ name: 'CrmAddContact' })"
              ><i class="icon ion-ios-person"></i> Adicionar Contato</a
            >
            <a
              href="#"
              class="nav-link"
              @click.prevent="$router.push({ name: 'CrmAddCustomer' })"
              ><i class="icon ion-ios-briefcase"></i> Adicionar Empresa</a
            >
          </nav>
        </div>
      </div>
    </form>

    <div class="contacts-list">
      <ul class="contacts-letters">
        <li v-for="letter in alphabet" :key="letter">
          <a @click.prevent="goToLetter(letter)" href="#">{{ letter }}</a>
        </li>
      </ul>
      <!-- {{ orderedContacts }} -->
      <ul class="contacts" ref="contactList">
        <div v-for="(contacts, index) in orderedContacts" :key="index">
          <!-- {{ contact[0] }} -->
          <li
            class="contact-letter-separator"
            :data-letter="
              contacts[0].completeName.split('')[0]
                ? contacts[0].completeName.split('')[0].toUpperCase()
                : ''
            "
          >
            <span>{{
              contacts[0].completeName.split("")[0]
                ? contacts[0].completeName.split("")[0].toUpperCase()
                : ""
            }}</span>
          </li>
          <li
            v-for="contact in contacts"
            :key="contact.id"
            @click.prevent="openContact(contact.id)"
            :class="[
              'contact-item',
              contact.id == $route.params.id ? ' active' : '',
            ]"
          >
            <span class="contact-letter">{{
              getFirstLetter(contact.completeName)
            }}</span>
            <div class="contact-content">
              <span class="name" v-if="contact.completeName !== null">{{
                contact.completeName.slice(0, 20)
              }}</span>
              <span class="name" v-else>{{ contact.name.slice(0, 20) }}</span>
              <span class="email tx-14"
                >{{ contact.name ? contact.name.slice(0, 15) : "" }}
              </span>
              <span class="email tx-12 tx-uppercase tx-light">{{
                contact.federalID
              }}</span>
              <span
                class="text-warning tx-12 mt-1"
                v-if="checkIfIsOutOfDate(contact.modified)"
                ><i class="fa fa-exclamation-triangle"></i> Cadastro
                desatualizado</span
              >
            </div>
            <div
              class="contact-birthday"
              v-if="
                contact.dateOfBirth &&
                checkBirthday(contact.dateOfBirth).birthday === true
              "
            >
              <cake-icon color="#80B7E2" :size="36" />
            </div>
            <div
              class="contact-birthday"
              v-if="
                contact.openingDate &&
                checkBirthday(contact.openingDate).birthday === true
              "
            >
              <cake-icon color="#80B7E2" :size="36" />
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import config from "@/config.js";
import Swal from "sweetalert2";
import Axios from "axios";

import CustomerListFilter from "@/components/crm/CustomerListFilter.vue";

import { CakeIcon, SearchIcon } from "@/components/crm/Icons";

import { mapState, mapActions } from "vuex";

export default {
  name: "CustomerList",
  components: {
    // Icons
    CakeIcon,
    SearchIcon,
    CustomerListFilter,
  },
  data() {
    return {
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      relationTypes: [
        {
          label: "Cliente",
          value: "customer",
        },
        {
          label: "Fornecedor da Empresa",
          value: "provider",
        },
        {
          label: "Exportador / Fabricante",
          value: "exporter",
        },
        {
          label: "Despachante Aduaneiro",
          value: "customBroker",
        },
        {
          label: "Recinto",
          value: "customsEnclosure",
        },
        {
          label: "Transportador",
          value: "conveyor",
        },
        {
          label: "Forwarder",
          value: "forwarder",
        },
        {
          label: "Armador",
          value: "shipowner",
        },
        {
          label: "Comissionado",
          value: "isCommissioned",
        },
      ],
      contacts: [],
      companiesType: [],
      showModalCustomerListFilter: false,
    };
  },
  async created() {
    await this.getContacts();
  },
  methods: {
    ...mapActions(["updateCustomersList", "updateCrmCustomersSearch"]),
    openContact(id) {
      this.$router.push({ name: "CrmCustomer", params: { id } });
    },
    async getContacts() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando clientes.",
      });

      if (this.crmCustomersFilters) {
        await this.filterCompanies();
        this.getSidebarPosition();
      } else {
        try {
          const response = await Axios.get(
            `${config.env.API_DEFAULT.host}/company/findAllActives`
          );
          const companies = response.data.data;
          this.updateCustomersList(companies);
          this.getSidebarPosition();
        } catch (err) {
          Swal.fire({
            title: "Listar Empresas",
            html: `<p>Não foi possível recuperar as Empresa</p> <div>${err}</div>`,
            type: "error",
          });
        }
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    checkIfIsOutOfDate(date) {
      return moment().diff(moment(date), "months") >= 6;
    },
    getSidebarPosition() {
      const active = document.querySelector(".contacts li.active");
      const list = this.$refs.contactList;

      if (active) {
        list.scrollTo({
          top: active.offsetTop - 400,
        });
      }
    },

    async filterCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando clientes.",
      });

      try {
        const params = {};
        for (
          let i = 0;
          i < Object.entries(this.crmCustomersFilters).length;
          i += 1
        ) {
          const item = Object.entries(this.crmCustomersFilters)[i];
          const key = item[0];

          if (item[1]) {
            if (typeof item[1] === "object" && item[1].length > 0) {
              params[key] = item[1].join(",");
            } else {
              params[key] = item[1];
            }
          }
          // if (item[1] === false || item[1] === 'false') {
          //   params[key] = null;
          // } else {
          //   params[key] = item[1];
          // }
        }

        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllByFilters`,
          { params }
        );
        this.updateCustomersList(response.data.data);
      } catch (e) {
        Swal.fire({
          title: "Relatório de contatos",
          message: `Houve um erro interno ao filtrar as empresas! ${e}`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    getFirstLetter(name) {
      return name !== null ? name.split("")[0] : " - Vazio";
    },
    goToLetter(letter) {
      const letterItem = this.$refs.contactList.querySelector(
        `li[data-letter="${letter}"]`
      );
      if (letterItem) {
        const scrollTop =
          letterItem.offsetTop - this.$refs.contactList.offsetTop;

        this.$refs.contactList.scroll({
          behavior: "smooth",
          top: scrollTop,
        });
      }
    },
    checkBirthday(date) {
      const today = moment();
      const birth = moment(date);

      if (birth.isValid()) {
        if (today.month() == birth.month() && today.date() == birth.date()) {
          return {
            birthday: true,
            years: today.diff(birth, "years"),
          };
        }
      }

      return {
        birthday: false,
      };
    },
  },
  computed: {
    ...mapState(["customersList", "crmCustomersFilters", "crmCustomersSearch"]),
    orderedContacts() {
      let orderedContacts = this.customersList;

      if (this.crmCustomersSearch !== "") {
        orderedContacts = orderedContacts.filter((contact) => {
          const completeName =
            contact.completeName != null
              ? contact.completeName.toLowerCase()
              : "";
          const name = contact.name != null ? contact.name.toLowerCase() : "";
          const federalID =
            contact.federalID != null ? contact.federalID.toLowerCase() : "";
          const reg = new RegExp(this.crmCustomersSearch, "gi");

          if (reg.test(name) || reg.test(completeName) || reg.test(federalID)) {
            return contact;
          }

          return false;
        });
      }

      const grouped = orderedContacts.reduce((rv, x) => {
        const xVal = x.completeName == null ? x.name : x.completeName;
        (rv[xVal.split("")[0]] = rv[xVal.split("")[0]] || []).push(x);
        return rv;
      }, {});
      return grouped;
    },
  },
};
</script>

<style scoped>
.contacts-list {
  display: flex;
  overflow: auto;
  height: calc(100% - 108px);
}

.contacts-letters {
  flex: 0 0 45px;
  width: 45px;
  padding: 0;
  margin: 0;
  list-style: none;
  border-right: 1px solid #dee2e6;
  height: 100%;
  overflow: auto;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.contacts-letters::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.contacts-letters::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 4px;
}

.contacts-letters li:not(:last-of-type) {
  border-bottom: 1px solid #dee2e6;
}

.contacts-letters li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: white;
  color: #868ba1;
}

.contacts-letters li a:hover {
  background-color: #f8f9fa;
}

.contacts {
  padding: 0 10px;
  margin: 0;
  list-style: none;
  flex: 1 1 auto;
  overflow: auto;
  height: 100%;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.contacts::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.contacts::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 4px;
}

.contact-letter-separator {
  position: relative;
  padding: 10px 0;
}

.contact-letter-separator span {
  display: inline-block;
  background-color: white;
  padding: 0 10px 0 20px;
  position: relative;
}

.contact-letter-separator::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  margin: auto 0;
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-item:hover,
.contact-item.active {
  background-color: #8392a51a;
}

.contact-item .contact-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b84e7;
  color: white;
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  border-radius: 70px;
}

.contact-content {
  flex: 1 1 auto;
  line-height: 1.2;
}

.contact-content span {
  display: block;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}

.contact-content .name {
  font-weight: bold;
  color: black;
  font-size: 1rem;
}
</style>
