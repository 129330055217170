<template>
  <div class="columns-12 no-gap" style="height: 100%; overflow: hidden">
    <div
      v-if="selectedDocuments.length > 1"
      class="span-3 documents-sidebar-left"
      style="overflow: auto"
    >
      <ul class="selected-documents-list">
        <li
          v-for="(document, index) in selectedDocuments"
          :key="document.id"
          :class="{
            active: selectedDocument.id === document.id,
            approved: document.extractionStatus === 'APPROVED',
            rejected: document.extractionStatus === 'REJECTED',
          }"
          @click.prevent="selectDocument(index)"
        >
          <div class="flex ai-c jc-sb fgap1 fw-w">
            <div>
              <p class="mb-0">#{{ document.id }}</p>
              <p class="mb-2 doc-text-max">Arquivo: {{ document.fileName }}</p>
              <p class="mb-0 text-medium doc-text-max">
                Cliente:
                {{
                  document.extractionResult.buyerName !== null
                    ? (
                        companies.find(
                          (comp) =>
                            String(comp.value) ===
                            String(document.extractionResult.buyerName)
                        ) || {}
                      ).label
                    : "-"
                }}
              </p>
              <p class="mb-0 doc-text-max">
                Exportador:
                {{
                  document.extractionResult.vendorName !== null
                    ? (
                        exporters.find(
                          (comp) =>
                            String(comp.value) ===
                            String(document.extractionResult.vendorName)
                        ) || {}
                      ).label
                    : "-"
                }}
              </p>
            </div>

            <div>
              <div
                class="blue-pill"
                v-if="document.documentType === 'invoices'"
              >
                Invoice
                <span class="text-bold">
                  {{ document.extractionResult.invoiceNumber }}</span
                >
              </div>
              <div
                class="yellow-pill"
                v-if="document.documentType === 'purchase_orders'"
              >
                Pedido
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      class="pv-2 ph-2"
      :class="{
        'span-9': selectedDocuments.length > 1,
        'span-12': selectedDocuments.length <= 1,
      }"
      style="overflow: auto"
    >
      <div class="columns-12 sm-gap">
        <div class="span-6">
          <v-input
            :disabled="readOnly"
            label="Status"
            name="extractionStatus"
            :value="selectedDocument.extractionStatus | status"
          />
        </div>

        <div class="span-6">
          <v-input
            :disabled="readOnly"
            label="Tipo de Documento"
            name="documentType"
            :value="selectedDocument.documentType | documentType"
            hint="Tipo de documento extraido"
          />
        </div>

        <!--        <div class="span-3">-->
        <!--          <v-input-->
        <!--            :disabled="readOnly"-->
        <!--            label="Cliente"-->
        <!--            name="buyer_name"-->
        <!--            :value="selectedDocument.extractionResult.buyerName"-->
        <!--            disabled-->
        <!--            hint="Cliente encontrado na extração do documento"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="span-3">-->
        <!--          <v-input-->
        <!--            :disabled="readOnly"-->
        <!--            label="Exportador"-->
        <!--            name="vendor_name"-->
        <!--            :value="selectedDocument.extractionResult.vendorName"-->
        <!--            disabled-->
        <!--            hint="Exportador encontrado na extração do documento"-->
        <!--          />-->
        <!--        </div>-->

        <div class="span-12">
          <p class="text-dark mb-0">
            Marque com as
            <span class="check-span"
              ><check-icon :size="6" color="#ffffff"
            /></span>
            <b> informações extraídas corretamente</b> e deixe sem marcação as
            incorretas
            <span
              style="color: #868ba1; text-transform: uppercase"
              class="text-sm"
              >(e as que você não deseja enviar para Invoice)</span
            >
          </p>
        </div>

        <div class="span-3 document-card columns-12 sm-gap">
          <!-- Informações Gerais -->
          <!-- Informações Gerais -->
          <!-- Informações Gerais -->
          <div class="span-12 flex ai-c jc-sb fgap2">
            <p class="text-dark text-uppercase text-bold text-lt mb-0">
              Informações Gerais
            </p>
            <a
              v-if="!readOnly"
              href="#"
              @click.prevent="approveGeneralData()"
              class="text-uppercase text-lt text-gray flex ai-c fgap1"
            >
              <span
                :class="{ active: getGeneralApprovedStatus }"
                class="check-span light"
                ><check-icon :size="6" color="#ffffff"
              /></span>
              Aprovar tudo
            </a>
          </div>

          <div
            class="span-12"
            v-if="selectedDocument.documentType === 'invoices'"
          >
            <v-input
              :disabled="readOnly"
              label="Número da Invoice"
              name="invoiceNumber"
              :value="
                selectedDocument.extractionResult.invoiceNumber
                  ? selectedDocument.extractionResult.invoiceNumber
                  : ''
              "
              @input="(value) => updateField('invoiceNumber', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('invoiceNumberApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.invoiceNumberApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              :label="
                selectedDocument.documentType === 'invoices'
                  ? 'Data da Invoice'
                  : 'Data da PO'
              "
              date-no-icon
              type="date"
              name="invoiceDate"
              :value="
                selectedDocument.extractionResult.invoiceDate
                  ? selectedDocument.extractionResult.invoiceDate
                  : ''
              "
              @input="(value) => updateField('invoiceDate', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('invoiceDateApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.invoiceDateApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              label="Número da PO"
              name="poNumber"
              :value="
                selectedDocument.extractionResult.poNumber
                  ? selectedDocument.extractionResult.poNumber
                  : ''
              "
              @input="(value) => updateField('poNumber', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('poNumberApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.poNumberApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div
            class="span-6"
            v-if="selectedDocument.documentType === 'invoices'"
          >
            <v-input
              :disabled="readOnly"
              label="Vencimento"
              type="date"
              date-no-icon
              name="invoiceDue"
              :value="
                selectedDocument.extractionResult.invoiceDue
                  ? selectedDocument.extractionResult.invoiceDue
                  : ''
              "
              @input="(value) => updateField('invoiceDue', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('invoiceDueApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.invoiceDueApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div
            :class="{
              'span-6': selectedDocument.documentType === 'invoices',
              'span-12': selectedDocument.documentType === 'purchase_orders',
            }"
          >
            <v-input
              :disabled="readOnly"
              money-mask
              label="Total"
              name="invoiceAmount"
              :value="
                selectedDocument.extractionResult.invoiceAmount
                  ? selectedDocument.extractionResult.invoiceAmount
                  : ''
              "
              @input="(value) => updateField('invoiceAmount', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('invoiceAmountApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.invoiceAmountApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="Condições de Pagamento"
              name="paymentTerms"
              :value="
                selectedDocument.extractionResult.paymentTerms
                  ? selectedDocument.extractionResult.paymentTerms
                  : ''
              "
              @input="(value) => updateField('paymentTerms', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('paymentTermsApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.paymentTermsApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              label="Moeda"
              name="currency"
              :value="
                selectedDocument.extractionResult.currency
                  ? selectedDocument.extractionResult.currency
                  : ''
              "
              @input="(value) => updateField('currency', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('currencyApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.currencyApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              label="Incoterm"
              name="incoterm"
              :value="
                selectedDocument.extractionResult.incoterm
                  ? selectedDocument.extractionResult.incoterm
                  : ''
              "
              @input="(value) => updateField('incoterm', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('incotermApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.incotermApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="Origem"
              name="vendorCountry"
              :value="
                selectedDocument.extractionResult.vendorCountry
                  ? selectedDocument.extractionResult.vendorCountry
                  : ''
              "
              @input="(value) => updateField('vendorCountry', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('vendorCountryApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.vendorCountryApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              money-mask
              label="Peso Líquido"
              name="netWeight"
              :value="
                selectedDocument.extractionResult.netWeight
                  ? selectedDocument.extractionResult.netWeight
                  : ''
              "
              @input="(value) => updateField('netWeight', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('netWeightApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.netWeightApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-6">
            <v-input
              :disabled="readOnly"
              money-mask
              label="Peso Bruto"
              name="grossWeight"
              :value="
                selectedDocument.extractionResult.grossWeight
                  ? selectedDocument.extractionResult.grossWeight
                  : ''
              "
              @input="(value) => updateField('grossWeight', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('grossWeightApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.grossWeightApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <!-- Dados do Cliente -->
          <!-- Dados do Cliente -->
          <!-- Dados do Cliente -->
          <div class="span-12 flex ai-c jc-sb fgap2 mt-2">
            <p class="text-dark text-uppercase text-bold text-lt mb-0">
              Dados do Cliente
            </p>
            <a
              v-if="!readOnly"
              href="#"
              @click.prevent="approveBuyerData"
              class="text-uppercase text-lt text-gray flex ai-c fgap1"
            >
              <span
                :class="{ active: getCustomerApprovedStatus }"
                class="check-span light"
                ><check-icon :size="6" color="#ffffff"
              /></span>
              Aprovar tudo
            </a>
          </div>

          <div class="span-12">
            <virtual-select
              :tabindex="1"
              placeholder="Cliente"
              :disabled="readOnly"
              :options="companies"
              v-model="selectedDocument.extractionResult.buyerName"
            />
            <a
              v-if="!readOnly"
              href="#"
              @click.prevent="checkField('buyerNameApproved')"
              class="text-uppercase text-lt text-gray flex ai-c jc-fe fgap1 mt-1"
            >
              <span>Aprovar Cliente</span>
              <span
                :class="{
                  active: selectedDocument.extractionResult.buyerNameApproved,
                }"
                class="check-span lg light"
              >
                <check-icon :size="12" color="#ffffff" />
              </span>
            </a>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="CNPJ"
              name="buyerFederalID"
              :value="
                selectedDocument.extractionResult.buyerFederalID
                  ? selectedDocument.extractionResult.buyerFederalID
                  : ''
              "
              @input="(value) => updateField('buyerFederalID', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('buyerFederalIDApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult
                          .buyerFederalIDApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="Endereço / Localização"
              name="buyerLocation"
              :value="
                selectedDocument.extractionResult.buyerLocation
                  ? selectedDocument.extractionResult.buyerLocation
                  : ''
              "
              @input="(value) => updateField('buyerLocation', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('buyerLocationApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.buyerLocationApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <!-- Dados do Exportador -->
          <!-- Dados do Exportador -->
          <!-- Dados do Exportador -->
          <div class="span-12 flex ai-c jc-sb fgap2 mt-2">
            <p class="text-dark text-uppercase text-bold text-lt mb-0">
              Dados do Exportador
            </p>
            <a
              v-if="!readOnly"
              href="#"
              @click.prevent="approveExporterData"
              class="text-uppercase text-lt text-gray flex ai-c fgap1"
            >
              <span
                :class="{ active: getExporterApprovedStatus }"
                class="check-span light"
                ><check-icon :size="6" color="#ffffff"
              /></span>
              Aprovar tudo
            </a>
          </div>

          <div class="span-12">
            <virtual-select
              :tabindex="2"
              placeholder="Exportador"
              :disabled="readOnly"
              :options="exporters"
              v-model="selectedDocument.extractionResult.vendorName"
              invert
            />
            <a
              v-if="!readOnly"
              href="#"
              @click.prevent="checkField('vendorNameApproved')"
              class="text-uppercase text-lt text-gray flex ai-c jc-fe fgap1 mt-1"
            >
              <span>Aprovar Exportador</span>
              <span
                :class="{
                  active: selectedDocument.extractionResult.vendorNameApproved,
                }"
                class="check-span lg light"
              >
                <check-icon :size="12" color="#ffffff" />
              </span>
            </a>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="Endereço / Localização"
              name="vendorLocation"
              :value="
                selectedDocument.extractionResult.vendorLocation
                  ? selectedDocument.extractionResult.vendorLocation
                  : ''
              "
              @input="(value) => updateField('vendorLocation', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('vendorLocationApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult
                          .vendorLocationApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>

          <div class="span-12">
            <v-input
              :disabled="readOnly"
              label="País de Origem"
              name="vendorCountry"
              :value="
                selectedDocument.extractionResult.vendorCountry
                  ? selectedDocument.extractionResult.vendorCountry
                  : ''
              "
              @input="(value) => updateField('vendorCountry', value)"
            >
              <template #icon>
                <a
                  v-if="!readOnly"
                  href="#"
                  @click.prevent="checkField('vendorCountryApproved')"
                  class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                >
                  <span
                    :class="{
                      active:
                        selectedDocument.extractionResult.vendorCountryApproved,
                    }"
                    class="check-span lg light"
                  >
                    <check-icon :size="12" color="#ffffff" />
                  </span>
                </a>
              </template>
            </v-input>
          </div>
        </div>

        <div class="span-9 flex fd-c fgap1">
          <div class="document-card with-footer">
            <div class="columns-12 sm-gap">
              <div class="span-12 flex ai-c jc-sb fgap2">
                <p
                  v-if="selectedDocument.documentType === 'invoices'"
                  class="text-dark text-uppercase text-bold text-lt mb-0"
                >
                  Itens da Invoice
                </p>
                <p
                  v-if="selectedDocument.documentType === 'purchase_orders'"
                  class="text-dark text-uppercase text-bold text-lt mb-0"
                >
                  Itens da PO
                </p>
              </div>

              <div class="span-12">
                <div
                  class="table-overflow"
                  style="max-height: 580px; overflow: auto"
                >
                  <table class="original-documents-table" style="width: 100%">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Descrição da Mercadoria</th>
                        <th>Quantidade</th>
                        <th>Unidade</th>
                        <th>Preço Unitário</th>
                        <th>Valor Total</th>
                        <th>
                          <a
                            v-if="!readOnly"
                            href="#"
                            @click.prevent="approveInvoiceItems"
                            class="text-uppercase text-lt text-gray flex ai-c fgap1"
                          >
                            <span
                              :class="{ active: getInvoiceItemsApprovedStatus }"
                              class="check-span light"
                              ><check-icon :size="6" color="#ffffff"
                            /></span>
                            Aprovar tudo
                          </a>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in selectedDocument
                          .extractionResult.items"
                        :key="index"
                      >
                        <td style="width: 7%" class="text-center">
                          <p class="mb-0">ITEM</p>
                          <p class="mb-0"># {{ index + 1 }}</p>
                        </td>
                        <td style="width: 28%">
                          <v-input
                            :disabled="readOnly"
                            label=""
                            name="description"
                            :value="item.description"
                            @input="(value) => (item.description = value)"
                          />
                        </td>
                        <td style="width: 10%">
                          <v-input
                            :disabled="readOnly"
                            money-mask
                            label=""
                            name="quantity"
                            :value="item.quantity"
                            @input="(value) => (item.quantity = value)"
                          />
                        </td>
                        <td style="width: 10%">
                          <v-input
                            :disabled="readOnly"
                            label=""
                            name="uom"
                            :value="item.uom"
                            @input="(value) => (item.uom = value)"
                          />
                        </td>
                        <td style="width: 10%">
                          <v-input
                            :disabled="readOnly"
                            money-mask
                            label=""
                            name="unitPrice"
                            :value="item.unitPrice"
                            @input="(value) => (item.unitPrice = value)"
                          />
                        </td>
                        <td style="width: 10%">
                          <v-input
                            :disabled="readOnly"
                            money-mask
                            label=""
                            name="unitTotal"
                            :value="item.unitTotal"
                            @input="(value) => (item.unitTotal = value)"
                          />
                        </td>
                        <td style="width: 15%">
                          <div class="flex ai-c fgap1" v-if="!readOnly">
                            <a
                              href="#"
                              @click.prevent="
                                () => (item.approved = !item.approved)
                              "
                              class="text-uppercase text-lt text-gray flex ai-c fgap1"
                            >
                              <span
                                :class="{
                                  active: item.approved,
                                }"
                                class="check-span light"
                              >
                                <check-icon :size="6" color="#ffffff" />
                              </span>
                              Aprovar
                            </a>
                            <v-table-button
                              color="danger"
                              @click.native="removeLineItem(index)"
                            >
                              <template #icon>
                                <reject-icon size="14" />
                              </template>
                              Remover
                            </v-table-button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          v-if="!readOnly"
                          colspan="7"
                          style="border-bottom: 1px solid #dee2e6"
                        >
                          <div class="flex ai-c jc-fe fgap2">
                            <span
                              >Clique no botão para adicionar mais
                              parcelas</span
                            >
                            <button
                              @click.prevent="addLineItem"
                              class="crm-button iconed"
                            >
                              <span class="flex ai-c jc-c">
                                <plus-icon color="currentColor" />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="span-12 document-card-footer">
                <div class="flex jc-fe ai-c fgap1">
                  <label v-if="selectedDocument.documentType === 'invoices'"
                    >Valor total da Invoice</label
                  >
                  <label
                    v-if="selectedDocument.documentType === 'purchase_orders'"
                    >Valor total da PO</label
                  >

                  <v-input
                    :disabled="readOnly"
                    money-mask
                    label=""
                    name="invoiceAmount"
                    :value="selectedDocument.extractionResult.invoiceAmount"
                    @input="(value) => updateField('invoiceAmount', value)"
                  />

                  <a
                    v-if="!readOnly"
                    href="#"
                    @click.prevent="checkField('invoiceAmountApproved')"
                    class="text-uppercase text-lt text-gray flex ai-c fgap1 mt-1"
                  >
                    <span
                      :class="{
                        active:
                          selectedDocument.extractionResult
                            .invoiceAmountApproved,
                      }"
                      class="check-span lg light"
                    >
                      <check-icon :size="12" color="#ffffff" />
                    </span>
                    Aprovar
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedDocument.extractionStatus === 'COMPLETE'">
            <div class="flex ai-c fgap2 jc-c">
              <a
                @click.prevent="approveDocument(selectedDocument)"
                href="#"
                class="btn-approve"
                >Aprovar Documento</a
              >
              <a
                href="#"
                @click.prevent="cancelRevision(selectedDocument.id)"
                class="btn-cancel"
                >Cancelar Revisão</a
              >
              <a
                href="#"
                @click.prevent="rejectDocumentInModal(selectedDocument)"
                class="btn-reject"
                >Rejeitar Documento</a
              >
            </div>
          </div>

          <!--          <div v-if="selectedDocument.extractionStatus === 'APPROVED'">-->
          <!--            <div class="flex ai-c fgap2 jc-c">-->
          <!--              <a href="#" @click.prevent="createInvoice(selectedDocument.id)" class="btn-cancel">Criar Invoice</a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from "@/components/VInput.vue";
import CheckIcon from "@/components/icons/Check.vue";
import { dateFilter } from "vue-date-fns";
import Axios from "axios";
import config from "@/config";
import Swal from "sweetalert2";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import RejectIcon from "@/components/icons/RejectIcon.vue";
import VTableButton from "@/components/VTableButton.vue";
import VirtualSelect from "@/components/VirtualSelect.vue";

export default {
  name: "OriginalDocumentsValidation",
  components: {
    VirtualSelect,
    VTableButton,
    RejectIcon,
    PlusIcon,
    CheckIcon,
    VInput,
  },
  filters: {
    date: dateFilter,
    status: (value) => {
      switch (value) {
        case "APPROVED":
          return "Aprovado";
        case "CREATED":
          return "Invoice Criada";
        case "REJECTED":
          return "Rejeitado";
        case "COMPLETE":
          return "Pronto p/ Revisão";
        case "AWAITING":
          return "Extraindo Dados";
        default:
          return "N/I";
      }
    },
    documentType: (value) => {
      switch (value) {
        case "invoices":
          return "Invoice / Proforma Invoice";
        case "purchase_orders":
          return "Ordem de Compra";
        default:
          return "N/I";
      }
    },
  },
  data() {
    return {
      companies: [],
      exporters: [],
    };
  },
  props: {
    selectedDocuments: {
      type: Array,
      default: () => [],
    },
    selectedDocument: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
    },
  },
  computed: {
    getGeneralApprovedStatus() {
      let status = true;

      const keys = [
        "invoiceNumberApproved",
        "invoiceDateApproved",
        "poNumberApproved",
        "invoiceDueApproved",
        "invoiceAmountApproved",
        "paymentTermsApproved",
        "currencyApproved",
        "incotermApproved",
        "vendorCountryApproved",
        "netWeightApproved",
        "grossWeightApproved",
      ];

      for (let i = 0; i < keys.length; i++) {
        if (!this.selectedDocument.extractionResult[keys[i]]) {
          status = false;
        }
      }

      return status;
    },
    getCustomerApprovedStatus() {
      let status = true;

      const keys = [
        "buyerNameApproved",
        "buyerLocationApproved",
        "buyerFederalIDApproved",
      ];

      for (let i = 0; i < keys.length; i++) {
        if (!this.selectedDocument.extractionResult[keys[i]]) {
          status = false;
        }
      }
      return status;
    },
    getExporterApprovedStatus() {
      let status = true;

      const keys = [
        "vendorNameApproved",
        "vendorLocationApproved",
        "vendorCountryApproved",
      ];

      for (let i = 0; i < keys.length; i++) {
        if (!this.selectedDocument.extractionResult[keys[i]]) {
          status = false;
        }
      }
      return status;
    },
    getInvoiceItemsApprovedStatus() {
      let status = true;

      for (
        let i = 0;
        i < this.selectedDocument.extractionResult.items.length;
        i++
      ) {
        if (!this.selectedDocument.extractionResult.items[i].approved) {
          status = false;
        }
      }

      return status;
    },
  },
  methods: {
    async getCompanies() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando clientes.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllCustomers`
        );
        this.companies = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Clientes",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getExporters() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando exportadores.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllExporters`
        );
        this.exporters = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Exportadores",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    validateApprovedFields() {
      let valid = true;
      const fieldsNotApproved = [];

      if (this.selectedDocument.documentType === "invoices") {
        if (
          this.selectedDocument.extractionResult.invoiceNumberApproved === false
        ) {
          valid = false;
          fieldsNotApproved.push("Número da Invoice");
        }
      }

      if (this.selectedDocument.documentType === "purchase_orders") {
        if (this.selectedDocument.extractionResult.poNumberApproved === false) {
          valid = false;
          fieldsNotApproved.push("Número da PO");
        }
      }

      if (this.selectedDocument.extractionResult.vendorNameApproved === false) {
        valid = false;
        fieldsNotApproved.push("Nome do Exportador");
      }

      if (this.selectedDocument.extractionResult.buyerNameApproved === false) {
        valid = false;
        fieldsNotApproved.push("Nome do Cliente");
      }

      if (
        this.selectedDocument.extractionResult.invoiceDateApproved === false
      ) {
        valid = false;
        fieldsNotApproved.push(
          this.selectDocument.documentType === "purchase_orders"
            ? "Data da PO"
            : "Data da Invoice"
        );
      }

      return {
        valid,
        fieldsNotApproved,
      };
    },
    updateField(field, value) {
      this.$emit("updateField", [field, value]);
    },
    checkField(field, value) {
      this.$emit("checkField", [field, value]);
    },
    approveDocument(document) {
      const validationResponse = this.validateApprovedFields();
      if (validationResponse.valid) {
        this.$emit("approveDocument", document);
      } else {
        let html = "";
        validationResponse.fieldsNotApproved.forEach(
          (item) => (html += `<p>${item}</p>`)
        );

        Swal.fire({
          title: "Não foi possível aprovar o documento original",
          html: `
            <p>Existem campos obrigatórios para a aprovação do documento que não foram validados:</p>
            ${html}
          `,
          type: "error",
        });
      }
    },
    cancelRevision(id) {
      this.$emit("cancelRevision", id);
    },
    rejectDocumentInModal(document) {
      this.$emit("rejectDocument", document);
    },
    approveGeneralData() {
      this.$emit("approveGeneralData", true);
    },
    approveExporterData() {
      this.$emit("approveExporterData", true);
    },
    approveInvoiceItems() {
      this.$emit("approveInvoiceItems", true);
    },
    approveBuyerData() {
      this.$emit("approveBuyerData", true);
    },
    selectDocument(index) {
      this.$emit("selectDocument", index);
    },
    addLineItem() {
      this.$emit("addLineItem", true);
    },
    removeLineItem(index) {
      this.$emit("removeLineItem", index);
    },
  },
  async mounted() {
    await this.getCompanies();
    await this.getExporters();
  },
};
</script>

<style scoped>
.selected-documents-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.selected-documents-list li {
  padding: 0.5rem 1rem;
  color: #343a40;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
  background-color: white;
}

.selected-documents-list li:hover,
.selected-documents-list li.active {
  background-color: #f8f9fa;
}

.selected-documents-list li.approved {
  background-color: #ebffee;
}

.selected-documents-list li.rejected {
  background-color: #fff1f1;
}

.documents-sidebar-left {
  background-color: white;
  border-right: 1px solid #e2e2e2;
}

.doc-text-max {
  overflow: hidden;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blue-pill,
.yellow-pill {
  padding: 0.25rem 0.5rem;
  border-radius: 100px;
  font-size: 12px;
  color: white;
}

.blue-pill {
  background-color: #104375;
}

.yellow-pill {
  background-color: #dbb058;
}

.check-span {
  background-color: #1b84e7;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.check-span.lg {
  width: 20px;
  height: 20px;
  line-height: 25px;
  vertical-align: middle;
}

.check-span.light {
  background-color: #eaecf0;
}

.check-span.active,
.check-span.light.active {
  background-color: #3398ff;
}

.document-card {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #dee2e6;
}

.document-card.with-footer {
  padding: 10px 10px 0px 10px;
}

.document-card-footer {
  background-color: #f8f9fa;
  margin: 0 -10px;
  width: calc(100% + 20px);
  padding: 10px;
  border-top: 1px solid #dee2e6;
}

.original-documents-table {
  border-collapse: separate;
  border-spacing: 0;
}

.original-documents-table thead tr th {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  overflow: hidden;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #343a40;
}

.original-documents-table thead tr th:first-of-type {
  border-left: 1px solid #e2e2e2;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.original-documents-table thead tr th:last-of-type {
  border-right: 1px solid #e2e2e2;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.original-documents-table td {
  padding: 10px;
}

.btn-approve,
.btn-reject,
.btn-cancel {
  display: flex;
  text-align: center;
  justify-content: center;
  flex: 1;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  border-radius: 10px;
  font-size: 0.8rem;
}

.btn-approve {
  background-color: #3b9b28;
}

.btn-reject {
  background-color: #a80005;
}

.btn-cancel {
  background-color: #104375;
}
</style>
