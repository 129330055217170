<template>
  <form>
    <transition
      v-if="
        activeAdicao !== null &&
        activeAdicao !== undefined &&
        activeAdicao.numeroAdicao > 0
      "
    >
      <adicao :noClick="this.noClick"/>
    </transition>
    <transition v-else>
      <div>
        <div class="report-summary-header mg-t-0 mg-b-10">
          <div>
            <h4 class="tx-inverse mg-b-3">
              Adições da Declaração de Importação
            </h4>
            <p class="mg-b-0">
              <i class="icon ion-information-circled mg-r-3"></i> Adições da
              declaração de importação com licença de importação e sem licença
              de importação.
            </p>
          </div>
          <div></div>
        </div>

        <hr
          v-if="
            model.numeroRegistro === undefined || model.numeroRegistro === null
          "
        />

        <div
          class="row"
          v-if="
            model.numeroRegistro === undefined || model.numeroRegistro === null
          "
          v-bind:class="{ 'no-click': noClick }"
        >
          <div class="mg-l-10 mg-t-20 mg-lg-t-0">
            <div class="input-group">
              <div class="input-group-prepend">
                <template
                  v-if="
                    this.licencaImportacao !== null &&
                    this.licencaImportacao !== undefined &&
                    this.licencaImportacao !== {}
                  "
                >
                  <button
                    class="btn btn-info pd-x-15"
                    type="button"
                    @click.prevent="adicionarAdicaoLI()"
                  >
                    <i class="fa fa-plus"></i> INCLUIR ADIÇÃO c/ LI
                  </button>
                </template>
                <template v-else>
                  <button class="btn btn-info pd-x-15" type="button" disabled>
                    <i class="fa fa-plus"></i> INCLUIR ADIÇÃO c/ LI
                  </button>
                </template>
              </div>
              <the-mask
                :mask="['##/#######-#']"
                class="wd-400 form-control tx-left"
                v-model="liIdentification"
              />
              <span class="input-group-btn">
                <button
                  class="btn bd bg-white tx-gray-600 bd-l-0 pd-x-15"
                  type="button"
                  @click.prevent="
                    currentLI = {};
                    liIdentification = '';
                  "
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  class="btn bd bg-white tx-gray-600 bd-l-0 pd-x-15"
                  type="button"
                  @click.prevent="findLI()"
                >
                  <i class="fa fa-search"></i> Pesquisar
                </button>
              </span>
            </div>
          </div>
          <button
            class="btn btn-info mg-l-10 pd-x-15"
            type="button"
            @click.prevent="incluirAdicao"
          >
            <i class="fa fa-plus"></i> INCLUIR ADIÇÃO
          </button>
          <button class="btn btn-teal mg-l-10 pd-x-15" type="button">
            <i class="fa fa-edit"></i> ALTERAR DADOS
          </button>
          <button
            class="btn btn-danger mg-l-10 pd-x-15"
            type="button"
            @click.prevent="deleteAdicoes"
          >
            <i class="fa fa-trash"></i> EXCLUIR ADIÇÕES
          </button>
        </div>

        <div
          class="card mg-0 mg-t-10"
          v-if="currentLI !== null && currentLI.situacao !== undefined"
        >
          <div class="card-header tx-medium bd-0 tx-white bg-mantle">
            Licença de Importação | Nº {{ currentLI.nrLicenciamento }}
          </div>

          <div class="card-body bd-0">
            <div class="row">
              <div class="col-md">
                <label class="section-label-sm tx-gray-800"
                >Dados da Lincença de Importação</label
                >
                <p class="invoice-info-row">
                  <span>Nº da LI</span>
                  <span>{{ currentLI.nrLicenciamento }}</span>
                </p>

                <p class="invoice-info-row">
                  <span> Situação </span>
                  <span>{{ currentLI.situacao }}</span>
                </p>
                <!--
                        <p class="invoice-info-row">
                            <span>Nº da LI Substituído</span>
                            <span>{{currentLI.nrLISubstituido}}</span>
                        </p>
                        <p class="invoice-info-row">
                            <span> Nº da LI Substitutivo</span>
                            <span>{{currentLI.nrLISubstitutivo}}</span>
                        </p> -->

                <p class="invoice-info-row">
                  <span> Declaração Vinculada </span>
                  <span>{{ currentLI.declaracaoVinculada }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> CNPJ do Importador </span>
                  <span>{{ currentLI.numeroIdentImportador }}</span>
                </p>
              </div>

              <div class="col-md">
                <label class="section-label-sm tx-gray-800"> Datas </label>

                <p class="invoice-info-row">
                  <span> Data de Registro </span>
                  <span>{{
                      currentLI.dataRegistro | moment("DD/MM/YYYY")
                    }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> Data da Situação </span>
                  <span>{{
                      currentLI.dataSituacao | moment("DD/MM/YYYY")
                    }}</span>
                </p>

                <p class="invoice-info-row">
                  <span> Validade da LI para Embarque</span>
                  <span class="tx-danger">{{
                      currentLI.dataValidadeEmbarque | moment("DD/MM/YYYY")
                    }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> Validade da LI para Despacho </span>
                  <span class="tx-danger">{{
                      currentLI.dataValidadeDespacho | moment("DD/MM/YYYY")
                    }}</span>
                </p>
                <p class="invoice-info-row">
                  <template
                    v-if="
                      currentLI.dataRestricaoEmbarque !== null &&
                      currentLI.dataRestricaoEmbarque !== ''
                    "
                  >
                    <span> Restrição de Embarque </span>
                    <span class="tx-danger">{{
                        currentLI.dataRestricaoEmbarque
                      }}</span>
                  </template>
                  <template v-else>
                    <span class="tx-bold">{{
                        currentLI.dataRestricaoEmbarqueDescricao
                      }}</span>
                  </template>
                </p>
              </div>

              <div class="col-md">
                <label class="section-label-sm tx-gray-800"
                >Dados da Mercadoria
                </label>
                <p class="invoice-info-row">
                  <span> NCM </span>
                  <span>{{ currentLI.cdSubItemNCM }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> Descrição do NCM </span>
                  <span>{{ currentLI.dmMercadoriaNCM }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> País </span>
                  <span>{{ currentLI.dmPaisAquisMerc }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> Fornecedor </span>
                  <span>{{ currentLI.nomeFornecEstr }}</span>
                </p>
                <p class="invoice-info-row">
                  <span> INCOTERM </span>
                  <span>{{ currentLI.dmIncotermsVenda }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr class="mg-b-0 mg-t-25"/>

        <div class="report-summary-header mg-b-5 mg-t-0">
          <div>
            <h4 class="tx-inverse mg-b-3 mg-t-20">Adições Cadastradas</h4>
            <p
              class="mg-b-0"
              v-if="this.di.adicoes !== null && this.di.adicoes !== undefined"
            >
              <i class="icon ion-information-circled mg-r-3"></i> Foram
              encontradas {{ this.di.adicoes.length }} adições.
            </p>
          </div>
          <div></div>
        </div>

        <div class="row no-gutters">
          <div class="card card-table wd-100p">
            <div class="table-responsive">
              <table class="table mg-b-0 tx-13 tx-gray-800 tx-normal">
                <thead>
                <tr class="tx-10">
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    Nº
                  </th>
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    NCM
                  </th>
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    Nº da LI
                  </th>
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    INCOTERM
                  </th>
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    Total Itens
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" rowspan="2">
                    Quantidade
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" rowspan="2">
                    Peso Líquido
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" rowspan="2">
                    Total Moeda Neg
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" colspan="2">
                    II
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" colspan="2">
                    IPI
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" colspan="2">
                    PIS
                  </th>
                  <th class="pd-y-5 tx-center valign-middle bd" colspan="2">
                    COFINS
                  </th>
                  <th class="pd-y-5 tx-center valign-middle" rowspan="2">
                    Ações
                  </th>
                </tr>
                <tr class="tx-10">
                  <th class="pd-y-5 tx-center valign-middle bd">%</th>
                  <th class="pd-y-5 tx-center valign-middle bd">R$</th>
                  <th class="pd-y-5 tx-center valign-middle bd">%</th>
                  <th class="pd-y-5 tx-center valign-middle bd">R$</th>
                  <th class="pd-y-5 tx-center valign-middle bd">%</th>
                  <th class="pd-y-5 tx-center valign-middle bd">R$</th>
                  <th class="pd-y-5 tx-center valign-middle bd">%</th>
                  <th class="pd-y-5 tx-center valign-middle bd bd-t-1">R$</th>
                </tr>
                </thead>
                <tbody>
                <template
                  v-if="
                      this.di.adicoes !== null &&
                      this.di.adicoes !== undefined &&
                      this.di.adicoes.length > 0
                    "
                >
                  <tr
                    v-for="(adicao, index) in adicoes"
                    :key="`adicao-${index}`"
                  >
                    <td
                      class="valign-middle tx-center"
                      contenteditable="true"
                    >
                      {{ adicao.numeroAdicao }}
                    </td>
                    <td class="valign-middle tx-center">
                      {{ adicao.codigoMercadoriaNCM }}
                    </td>
                    <td class="valign-middle tx-center">
                      {{ adicao.numeroLI }}
                    </td>
                    <td class="valign-middle tx-center">
                      <template v-if="adicao.codigoIncotermsVenda !== null">
                        {{ adicao.codigoIncotermsVenda.value }}
                      </template>
                    </td>
                    <td class="valign-middle tx-center">
                      {{ adicao.mercadoria.length }}
                    </td>
                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.quantidadeUnidadeEstatistica
                          | numeralFormat("0,0.00000")
                      }}
                    </td>

                    <td class="valign-middle tx-right bd">
                      <template
                        v-if="
                            adicao.pesoLiquidoMercadoria > di.cargaPesoLiquido
                          "
                      >
                        <i
                          class="icon ion-flag tx-bold tx-danger mg-r-2"
                          v-tooltip="'Peso Líquido maior do que o da Carga.'"
                        ></i>
                      </template>
                      {{
                        adicao.pesoLiquidoMercadoria
                          | numeralFormat("0,0.00000")
                      }}
                    </td>

                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.valorMercadoriaCondicaoVenda
                          | numeralFormat("0,0.0000000")
                      }}
                    </td>

                    <td class="valign-middle tx-right">
                      <template v-if="adicao.aliquotaii !== adicao.adValorem">
                        <i
                          class="icon ion-flag tx-bold tx-danger mg-r-2"
                          v-tooltip="
                              'Alíquota diferente do NCM | NCM ' +
                              adicao.aliquotaii +
                              ' '
                            "
                        ></i>
                      </template>
                      {{ adicao.adValorem | numeralFormat("0,0.00") }}
                    </td>
                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.iiValorIPTaRecolher | numeralFormat("0,0.00")
                      }}
                    </td>

                    <td class="valign-middle tx-right bd">
                      <template
                        v-if="adicao.aliquotaipi !== adicao.adValoremIpi"
                      >
                        <i class="icon ion-flag tx-bold tx-danger mg-r-2"></i>
                      </template>
                      {{ adicao.adValoremIpi | numeralFormat("0,0.00") }}
                    </td>
                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.ipiValorIPTaRecolher | numeralFormat("0,0.00")
                      }}
                    </td>

                    <td class="valign-middle tx-right bd">
                      <template
                        v-if="
                            adicao.aliquotapis !== adicao.aliquotaAdValoremPIS
                          "
                      >
                        <i class="icon ion-flag tx-bold tx-danger mg-r-2"></i>
                      </template>
                      {{
                        adicao.aliquotaAdValoremPIS | numeralFormat("0,0.00")
                      }}
                    </td>
                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.pisValorIPTaRecolher | numeralFormat("0,0.00")
                      }}
                    </td>

                    <td class="valign-middle tx-right bd">
                      <template
                        v-if="
                            adicao.aliquotacofins !==
                            adicao.aliquotaAdValoremCOFINS
                          "
                      >
                        <i class="icon ion-flag tx-bold tx-danger mg-r-2"></i>
                      </template>
                      {{
                        adicao.aliquotaAdValoremCOFINS
                          | numeralFormat("0,0.00")
                      }}
                    </td>
                    <td class="valign-middle tx-right bd">
                      {{
                        adicao.cofinsValorIPTaRecolher
                          | numeralFormat("0,0.00")
                      }}
                    </td>

                    <td class="tx-center">
                      <a
                        href="#"
                        @click="abrirAdicao(adicao)"
                        class="btn btn-primary btn-icon rounded-circle mg-r-5"
                      >
                        <div><i class="icon ion-edit tx-20"></i></div>
                      </a>
                      <a
                        v-if="di.numeroRegistro === null"
                        href="#"
                        @click="deleteAdicao(adicao)"
                        class="btn btn-danger btn-icon rounded-circle"
                      >
                        <div><i class="icon ion-trash-a tx-20"></i></div>
                      </a>
                    </td>
                  </tr>
                  <tr class="tx-14 tx-bold">
                    <td class="bg-gray-100"></td>
                    <td class="bg-gray-100"></td>
                    <td class="bg-gray-100"></td>
                    <td class="bg-gray-100"></td>
                    <td class="bg-gray-100"></td>
                    <td class="bg-gray-100 tx-right bd bd-b-0">
                      {{ this.totalQTD | numeralFormat("0,0.00000") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0">
                      KG {{ this.totalPL | numeralFormat("0,0.00000") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0">
                      {{ this.totalVMLE | numeralFormat("0,0.0000000") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0" colspan="2">
                      R$ {{ this.totalII | numeralFormat("0,0.00") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0" colspan="2">
                      R$ {{ this.totalIPI | numeralFormat("0,0.00") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0" colspan="2">
                      R$ {{ this.totalPIS | numeralFormat("0,0.00") }}
                    </td>
                    <td class="bg-gray-100 tx-right bd bd-b-0" colspan="2">
                      R$ {{ this.totalCOFINS | numeralFormat("0,0.00") }}
                    </td>

                    <td class="bg-gray-100" colspan="2"></td>
                  </tr>
                </template>

                <template v-else>
                  <tr>
                    <td
                      colspan="17"
                      class="tx-center tx-danger bd-1-force pd-30-force tx-14"
                    >
                      Nenhuma adição foi cadastrada nesta declaracão de
                      importação.
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import {TheMask} from "vue-the-mask";
import {mapState} from "vuex";
import Swal from "sweetalert2";
import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import AdicaoForm from "@/views/operational/import-declaration/adition/Form.vue";

export default {
  name: "di-form-adicoes",
  props: ["noClick"],
  components: {
    TheMask,
    adicao: AdicaoForm,
  },
  data() {
    return {
      liIdentification: "",
      currentLI: {},
      licencaImportacao: null,
      initialDI: {},
      di: {},
      initialModel: {},
      model: {},
      codigosRegTributacaoII: [
        ...importDeclarationLists.codigosRegTributacaoII,
      ],
      codigosCoberturaCambial: [
        ...importDeclarationLists.codigosCoberturaCambial,
      ],
      codigosPaises: [...importDeclarationLists.codigosPaises],
    };
  },
  methods: {
    incluirAdicao() {
      if (
        this.model.numeroRegistro === undefined ||
        (this.model.numeroRegistro === null &&
          this.di.adicoes !== null &&
          this.di.adicoes !== undefined)
      ) {
        if (this.di.valorTotalDespesasMoedaNegociada < 0.01) {
          Swal.fire(
            "Nova Adição",
            "O Valor da Mercadoria no Local de Embarque precisa ser maior do que 0,00.",
            "error"
          );
        } else if (this.di.codigoMoedaLocalEmbarque === null) {
          Swal.fire(
            "Nova Adição",
            "A Moeda da Mercadoria no Local de Embarque precisa ser preenchida.",
            "error"
          );
        } else if (this.di.cargaPesoBruto < 0.01) {
          Swal.fire(
            "Nova Adição",
            "O Peso Bruto precisar ser maior do que 0,00000 KGs.",
            "error"
          );
        } else if (this.di.cargaPesoLiquido < 0.01) {
          Swal.fire(
            "Nova Adição",
            "O Peso Líquido precisar ser maior do que 0,00000 KGs.",
            "error"
          );
        } else if (this.di.cargaPesoLiquido > this.di.cargaPesoBruto) {
          Swal.fire(
            "Nova Adição",
            "O Peso Líquido não pode ser maior do que o Peso Bruto.",
            "error"
          );
        } else {
          let newAdicao = {
            numeroAdicao: 0,
            criacao: new Date(),
            ultimaAtualizacao: new Date(),
            licencaImportacao: null,
            numeroLI: null,
            codigoVinculoImportadorExportador: 1,
            codigoAusenciaFabricante: 2,

            nomeFornecedorEstrangeiro: "",
            enderecoLogradouroFornecedorEstrangeiro: "",
            enderecoNumeroFornecedorEstrangeiro: "",
            enderecoComplementoFornecedorEstrangeiro: "",
            enderecoCidadeFornecedorEstrangeiro: "",
            enderecoEstadoFornecedorEstrangeiro: "",
            exportador: {},

            nomeFabricanteMercadoria: "",
            enderecoLogradouroFabricante: "",
            enderecoNumeroFabricante: "",
            enderecoComplementoFabricante: "",
            fabricanteEstrangeiroCidade: "",
            enderecoEstadoFabricante: "",
            codigoPaisOrigemMercadoria: null,
            fabricante: {},

            descricaoMercadoriaNCM: null,
            codigoMercadoriaNCM: "",
            indicadorBemEncomenda: "N",
            indicadorMaterialUsado: "N",
            indicadorMultimodal: "N",

            codigoIncotermsVenda: null,
            nomeLocalCondicaoVenda: "",
            valorMercadoriaCondicaoVenda: 0.0,
            valorMercadoriaVendaMoedaNacional: 0.0,
            valorFreteMercadoriaMoedaNacional: 0.0,
            valorSeguroMercadoriaMoedaNacional: 0.0,

            textoComplementoValorAduaneiro: "",

            codRegTributacaoII: {
              key: "1",
              value: "1 - RECOLHIMENTO INTEGRAL",
            },
            tipoAtoLegalNcmII: null,
            aliquotaAcordoII: 0.0,
            adValorem: 0.0,
            reduzida: 0.0,
            reducaoIi: 0.0,
            valorBaseCalculoAdval: 0.0,

            codigoAssuntoVinculadoExII: 1,
            numeroEXAtoVinculadoExII: "",
            siglaTipoAtoVinculadoExII: null,
            siglaOrgaoAtoVinculadoExII: null,
            numeroAtoVinculadoExII: "",
            dataAnoAtoVinculadoExII: "",

            tipoAcordoTarifario: null,
            codigoAcordoAladi: null,
            codigoAssuntoVinculadoAcordoII: 3,
            numeroEXAtoVinculadoAcordoII: "",
            siglaTipoAtoVinculadoAcordoII: null,
            siglaOrgaoAtoVinculadoAcordoII: null,
            numeroAtoVinculadoAcordoII: "",
            dataAnoAtoVinculadoAcordoII: "",
            tipoRegTributacao: {
              key: "4",
              value: "4 - Recolhimento Integral",
            },
            notaComplementarTIPI: "",
            valorBaseCalculoAdvalIPI: 0.0,
            adValoremIpi: 0.0,
            reduzidaIpi: 0.0,
            ipiTributavel: true,

            valorUnMedidaIpi: 0.0,
            unMedidaIpi: null,
            qtdeUnIpi: null,
            tipoRecIpi: null,
            capacidadeRecIpi: null,

            codigoAssuntoVinculadoExIPI: 2,
            numeroEXAtoVinculadoExIPI: null,
            siglaTipoAtoVinculadoExIPI: null,
            siglaOrgaoAtoVinculadoExIPI: null,
            numeroAtoVinculadoExIPI: null,
            dataAnoAtoVinculadoExIPI: null,

            siglaTipoAtoVinculadoAcordoIPI: null,
            numeroAtoVinculadoAcordoIPI: null,
            siglaOrgaoAtoVinculadoAcordoIPI: null,
            dataAnoAtoVinculadoAcordoIPI: null,
            numeroEXAtoVinculadoAcordoIPI: null,

            codRegTributacaoPIS: {
              key: "1",
              value: "1 - RECOLHIMENTO INTEGRAL",
            },
            fundamentoLegalPisCofins: null,
            percentualReducao: 0.0,

            valorBaseCalculoAdvalPIS: 0.0,
            aliquotaAdValoremPIS: 0.0,
            aliquotaEspecificaPIS: 0.0,
            aliquotaReduzidaPIS: 0.0,
            unMedidaEspecificaPIS: "",
            qtdeMedidaPIS: "",

            valorBaseCalculoAdvalCOFINS: 0.0,
            aliquotaAdValoremCOFINS: 0.0,
            aliquotaEspecificaCOFINS: 0.0,
            aliquotaReduzidaCOFINS: 0.0,
            unMedidaEspecificaCOFINS: null,
            qtdeMedidaCOFINS: null,

            valorBaseCalculoAdvalAntidumping: 0.0,
            aliquotaAdValoremAntidumping: 0.0,
            aliquotaEspecificaAntidumping: 0.0,
            unMedidaEspecificaAntidumping: "",
            qtdeMedidaAntidumping: "",

            siglaTipoAtoVinculadoAcordoAntidumping: null,
            numeroAtoVinculadoAntidumping: null,
            siglaOrgaoAtoVinculadoAcordoAntidumping: null,
            dataAnoAtoVinculadoAcordoAntidumping: null,
            numeroEXAtoVinculadoAcordoAntidumping: null,

            baseCalculo: 0.0,
            baseCalculoIpi: 0.0,
            baseCalculoPIS: 0.0,
            numeroIdentificacaoLI: null,
            codigoAplicacaoMercadoria: "1",
            codigoMetodoValoracao: {
              key: "01",
              value: "01 - METODO 1",
              description:
                "01 - METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)",
            },
            codigoMoedaNegociada: null,

            mercadoria: [],
            nve: [],
            destaque: [],
            valoracaoAduaneira: [],
            acrescimo: [],
            deducao: [],
            documento: [],
            documentoMercosul: [],

            quantidadeUnidadeEstatistica: 0.0,
            pesoLiquidoMercadoria: 0.0,

            codigoCoberturaCambial: {
              key: "1",
              value: "Até 180 dias",
            },
            financiadora: null,
            valorRof: 0.0,
            nrRof: null,
            aliquotaii: 0.0,
            aliquotaipi: 0.0,
            aliquotapis: 0.0,
            aliquotacofins: 0.0,
            valorAduaneiro: 0.0,

            iiValorCalculoIptAdval: 0.0,
            iiValorCalculadoIIACTarifario: 0.0,
            iiValorImpostoDevido: 0.0,
            iiValorIPTaRecolher: 0.0,

            ipiValorCalculoIptAdval: 0.0,
            ipiValorCalculadoIIACTarifario: 0.0,
            ipiValorImpostoDevido: 0.0,
            ipiValorIPTaRecolher: 0.0,

            pisValorCalculoIptAdval: 0.0,
            pisValorCalculadoIIACTarifario: 0.0,
            pisValorImpostoDevido: 0.0,
            pisValorIPTaRecolher: 0.0,

            cofinsValorCalculoIptAdval: 0.0,
            cofinsValorCalculadoIIACTarifario: 0.0,
            cofinsValorImpostoDevido: 0.0,
            cofinsValorIPTaRecolher: 0.0,

            antidumpingValorCalculoIptAdval: 0.0,
            antidumpingValorCalculadoIIACTarifario: 0.0,
            antidumpingValorImpostoDevido: 0.0,
            antidumpingValorIPTaRecolher: 0.0,
            codigoTipoAliquotaIPTAntidumping: 0,
          };

          this.$store.commit("setLoading", {
            show: true,
            label: "Nova Adição",
            description: "Aguarde enquanto a adição está sendo criada...",
          });

          const numeraAdicao = new Promise((resolve, reject) => {
            newAdicao.numeroAdicao = (this.di.adicoes.length + 1).toString();
            if (newAdicao.numeroAdicao.length == 1) {
              newAdicao.numeroAdicao = `00${newAdicao.numeroAdicao.toString()}`;
            } else if (newAdicao.numeroAdicao.length == 2) {
              newAdicao.numeroAdicao = `0${newAdicao.numeroAdicao.toString()}`;
            }

            if (this.di.in_moeda_unica) {
              newAdicao.cdMoedaNegociada =
                this.di.codigoMoedaLocalEmbarque.codigo;
            }

            resolve();
          });

          numeraAdicao
            .then(() => {
              if (
                this.di.exportador !== null &&
                this.di.exportador !== undefined
              ) {
                newAdicao.codigoAusenciaFabricante = 1;
                newAdicao.nomeFornecedorEstrangeiro = this.di.exportador.name;
                newAdicao.enderecoLogradouroFornecedorEstrangeiro =
                  this.di.exportador.address;
                newAdicao.enderecoNumeroFornecedorEstrangeiro =
                  this.di.exportador.addressNumber;
                newAdicao.enderecoComplementoFornecedorEstrangeiro =
                  this.di.exportador.complement;
                newAdicao.enderecoCidadeFornecedorEstrangeiro =
                  this.di.exportador.city;
                newAdicao.enderecoEstadoFornecedorEstrangeiro =
                  this.di.exportador.federalUnity;
                newAdicao.codigoPaisAquisicaoMercadoria = null;

                return this.$store.dispatch("findObjectByKey", {
                  array: this.codigosPaises,
                  key: "key",
                  value: this.di.exportador.country,
                });
              }
              return false;
            })
            .then((response) => {
              if (response) newAdicao.codigoPaisAquisicaoMercadoria = response;
            })
            .then(() => {
              newAdicao.nomeLocalCondicaoVenda = this.di.nomeLocalEmbarque;

              if (this.di.processoImportacao.incoterm !== null) {
                newAdicao.codigoIncotermsVenda = {
                  key: this.di.processoImportacao.incoterm,
                  value: this.di.processoImportacao.incoterm,
                };
              }

              this.di.adicoes.push(newAdicao);
              this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", newAdicao);
              newAdicao = {};
            })
            .then(() =>
              this.$store.dispatch("MyIMPStore/patch", {
                diff: this.di,
                newModel: this.di,
                fieldName: null,
              })
            )
            .then(() =>
              this.$store.commit("setLoading", {
                show: false,
              })
            );
        }
      } else {
        Swal.fire(
          "Não Permitida",
          "Está ação não é permitida porque está DI já foi registrada.",
          "error"
        );
      }
    },
    async adicionarAdicaoLI() {
      if (
        this.licencaImportacao !== null &&
        this.licencaImportacao !== undefined
      ) {
        if (this.di.adicoes !== null && this.di.adicoes !== undefined) {
          const {licencaImportacao} = this;
          const {currentLI} = this;

          let newAdicao = {
            numeroAdicao: 0,
            criacao: new Date(),
            ultimaAtualizacao: new Date(),
            numeroLI: licencaImportacao.numeroLI,
            codigoVinculoImportadorExportador: 1,
            codigoAusenciaFabricante: licencaImportacao.cdAusenciaFabric,

            nomeFornecedorEstrangeiro: licencaImportacao.nomeFornecEstr,
            enderecoLogradouroFornecedorEstrangeiro:
            licencaImportacao.edLogrFornecEstr,
            enderecoNumeroFornecedorEstrangeiro:
            licencaImportacao.edNumeroFornecEstr,
            enderecoComplementoFornecedorEstrangeiro:
            licencaImportacao.edComplFornecEstr,
            enderecoCidadeFornecedorEstrangeiro:
            licencaImportacao.edCidadeFornecEstr,
            enderecoEstadoFornecedorEstrangeiro:
            licencaImportacao.edEstFornecEstr,
            codigoPaisAquisicaoMercadoria: licencaImportacao.cdPaisAquisMerc,

            nomeFabricanteMercadoria: licencaImportacao.nomeFabricMerc,
            enderecoLogradouroFabricante: licencaImportacao.edLogrFabric,
            enderecoNumeroFabricante: licencaImportacao.edNumeroFabric,
            enderecoComplementoFabricante: licencaImportacao.edComplFabric,
            fabricanteEstrangeiroCidade: licencaImportacao.edCidadeFabric,
            enderecoEstadoFabricante: licencaImportacao.edEstadoFabric,
            codigoPaisOrigemMercadoria: licencaImportacao.cdPaisOrigMerc,

            descricaoMercadoriaNCM: licencaImportacao.cdSubItemNCM,
            codigoMercadoriaNCM: licencaImportacao.cdSubItemNCM,
            codigoMercadoriaNaladiSH: licencaImportacao.cdNaladiSh,
            codigoMercadoriaNaladiNCC: licencaImportacao.cdNaladiNcc,
            indicadorBemEncomenda: licencaImportacao.inBemEncomenda,
            indicadorMaterialUsado: licencaImportacao.inMaterialUsado,
            indicadorMultimodal: "N",

            codigoIncotermsVenda: {
              key: licencaImportacao.cdIncontermsVenda,
              value: licencaImportacao.cdIncontermsVenda,
            },

            nomeLocalCondicaoVenda: "",
            valorMercadoriaCondicaoVenda: licencaImportacao.valorTotalCondVenda
              .replace(".", "")
              .replace(",", "."),
            valorMercadoriaVendaMoedaNacional: 0.0,
            valorFreteMercadoriaMoedaNacional: 0.0,
            valorSeguroMercadoriaMoedaNacional: 0.0,
            textoComplementoValorAduaneiro: "",

            codRegTributacaoII: null,
            tipoAtoLegalNcmII: null,
            adValorem: 0.0,
            reduzida: 0.0,
            reducaoIi: 0.0,

            codigoAssuntoVinculadoExII: 1,
            numeroEXAtoVinculadoExII: "",
            siglaTipoAtoVinculadoExII: null,
            siglaOrgaoAtoVinculadoExII: null,
            numeroAtoVinculadoExII: "",
            dataAnoAtoVinculadoExII: "",

            tipoAcordoTarifario: null,
            codigoAcordoAladi: null,
            codigoAssuntoVinculadoAcordoII: 3,
            numeroEXAtoVinculadoAcordoII: "",
            siglaTipoAtoVinculadoAcordoII: null,
            siglaOrgaoAtoVinculadoAcordoII: null,
            numeroAtoVinculadoAcordoII: "",
            dataAnoAtoVinculadoAcordoII: "",

            tipoRegTributacao: {
              key: "4",
              value: "4 - Recolhimento Integral",
            },

            notaComplementarTIPI: "",
            adValoremIpi: 0.0,
            reduzidaIpi: 0.0,
            ipiTributavel: true,

            valorUnMedidaIpi: 0.0,
            unMedidaIpi: null,
            qtdeUnIpi: null,
            tipoRecIpi: null,
            capacidadeRecIpi: null,

            codigoAssuntoVinculadoExIPI: 2,
            numeroEXAtoVinculadoExIPI: null,
            siglaTipoAtoVinculadoExIPI: null,
            siglaOrgaoAtoVinculadoExIPI: null,
            numeroAtoVinculadoExIPI: null,
            dataAnoAtoVinculadoExIPI: null,

            siglaTipoAtoVinculadoAcordoIPI: null,
            numeroAtoVinculadoAcordoIPI: null,
            siglaOrgaoAtoVinculadoAcordoIPI: null,
            dataAnoAtoVinculadoAcordoIPI: null,
            numeroEXAtoVinculadoAcordoIPI: null,

            codRegTributacaoPIS: {
              key: "1",
              value: "1 - RECOLHIMENTO INTEGRAL",
            },
            fundamentoLegalPisCofins: null,
            percentualReducao: 0.0,

            aliquotaAdValoremPIS: 0.0,
            aliquotaEspecificaPIS: 0.0,
            unMedidaEspecificaPIS: "",
            qtdeMedidaPIS: "",

            aliquotaAdValoremCOFINS: 0.0,
            aliquotaEspecificaCOFINS: 0.0,
            unMedidaEspecificaCOFINS: null,
            qtdeMedidaCOFINS: null,

            baseCalculo: 0.0,
            baseCalculoIpi: 0.0,
            baseCalculoPIS: 0.0,
            numeroIdentificacaoLI: null,
            codigoAplicacaoMercadoria: "1",
            codigoMetodoValoracao: {
              key: "01",
              value: "01 - METODO 1",
              description:
                "01 - METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)",
            },
            codigoMoedaNegociada: null,

            mercadoria: [],
            nve: [],
            destaque: [],
            valoracaoAduaneira: [],
            acrescimo: [],
            deducao: [],
            documento: [],
            documentoMercosul: [],

            cdMoedaNegociada: licencaImportacao.cdMoedaNegociada,
            quantidadeUnidadeEstatistica:
              licencaImportacao.quantidadeUnidEstatistica
                .replace(".", "")
                .replace(",", "."),
            pesoLiquidoMercadoria: licencaImportacao.numeroPesoLiquidoMerc
              .replace(".", "")
              .replace(",", "."),

            codigoCoberturaCambial: {
              key: "1",
              value: "Até 180 dias",
            },
            financiadora: null,
            valorRof: 0.0,
            nrRof: null,

            numeroAtoDrawback: licencaImportacao.numeroAtoDrawback,
            numeroRegDrawback: licencaImportacao.numeroRegDrawback,
            dmModalidadeDrawback: licencaImportacao.dmModalidadeDrawback,

            aliquotaii: 0.0,
            aliquotaipi: 0.0,
            aliquotapis: 0.0,
            aliquotacofins: 0.0,
            valorAduaneiro: 0.0,

            iiValorCalculoIptAdval: 0.0,
            iiValorCalculadoIIACTarifario: 0.0,
            iiValorImpostoDevido: 0.0,
            iiValorIPTaRecolher: 0.0,

            ipiValorCalculoIptAdval: 0.0,
            ipiValorCalculadoIIACTarifario: 0.0,
            ipiValorImpostoDevido: 0.0,
            ipiValorIPTaRecolher: 0.0,

            pisValorCalculoIptAdval: 0.0,
            pisValorCalculadoIIACTarifario: 0.0,
            pisValorImpostoDevido: 0.0,
            pisValorIPTaRecolher: 0.0,

            cofinsValorCalculoIptAdval: 0.0,
            cofinsValorCalculadoIIACTarifario: 0.0,
            cofinsValorImpostoDevido: 0.0,
            cofinsValorIPTaRecolher: 0.0,

            antidumpingValorCalculoIptAdval: 0.0,
            antidumpingValorCalculadoIIACTarifario: 0.0,
            antidumpingValorImpostoDevido: 0.0,
            antidumpingValorIPTaRecolher: 0.0,
            valorBaseCalculoAdval: 0.0,

            codigoTipoAliquotaIPTAntidumping: 0,
          };

          // if (licencaImportacao.cdRegimeTrib !== null && licencaImportacao.cdRegimeTrib !== '') {
          //     newAdicao.codRegTributacaoII = {
          //         "key": licencaImportacao.cdRegimeTrib,
          //         "value": licencaImportacao.cdRegimeTrib
          //     };
          // }

          if (
            licencaImportacao.liDestaqueNCM !== undefined &&
            licencaImportacao.liDestaqueNCM !== null &&
            licencaImportacao.liDestaqueNCM.length > 0
          ) {
            licencaImportacao.liDestaqueNCM.forEach((d) => {
              newAdicao.destaque.push({
                numeroDestaqueNCM: d.numeroDestaqueNCM,
              });
            });
          }

          this.$store.commit("setLoading", {
            show: true,
            label: "Nova Adição",
            description: "Aguarde enquanto a adição está sendo criada...",
          });

          this.$store
            .dispatch("findObjectByKey", {
              array: this.codigosRegTributacaoII,
              key: "key",
              value: licencaImportacao.cdRegimeTrib,
            })
            .then((response) => {
              if (response) {
                newAdicao.codRegTributacaoII = response;
              }

              return newAdicao;
            })
            .then(() => {
              if (
                licencaImportacao.cdAcordoAladi !== undefined &&
                licencaImportacao.cdAcordoAladi !== null &&
                licencaImportacao.cdAcordoAladi !== ""
              ) {
                newAdicao.tipoAcordoTarifario = {
                  key: "2",
                  value: "2 - ALADI",
                };

                return this.$store
                  .dispatch("MyIMPStore/consultarCampoDenominacaoAction", {
                    formRetorno: "ElaborarDIAdicaoForm",
                    campoRetorno: "acordoAladi",
                    subAction: "autoCompleteCodigo",
                    q: licencaImportacao.cdAcordoAladi,
                  })
                  .then((response) => {
                    if (response) {
                      newAdicao.codigoAcordoAladi = response[0];
                    }
                  });
              }
              return true;
            })
            .then(() => {
              newAdicao.nomeLocalCondicaoVenda =
                this.di.processoImportacao.placeOfLoading;
              if (
                licencaImportacao.liDetalheNCM !== null &&
                licencaImportacao.liDetalheNCM.length > 0
              ) {
                licencaImportacao.liDetalheNCM.forEach((item) => {
                  let mercadoria = {
                    id: Math.floor(Math.random() * new Date().valueOf()),
                    numeroItem: item.numeroSeqProduto,
                    nomeUnidadeMedidaComercializada:
                    item.nomeUnidadeMedidaComerc,
                    quantidadeMercadoriaUnidadeComercializada:
                      item.quantidadeMercComerc
                        .replace(".", "")
                        .replace(",", "."),
                    textoDetalhamentoMercadoria: item.descricaoProduto,
                    valorUnidadeLocalEmbarque: item.valorUnidadeCondVenda
                      .replace(".", "")
                      .replace(",", "."),
                    valorUnidadeMedidaCondicaoVenda: item.valorUnidadeCondVenda
                      .replace(".", "")
                      .replace(",", "."),
                    moeda: item.cdMoedaNegociada,
                  };
                  newAdicao.mercadoria.push(mercadoria);
                  mercadoria = null;
                });
              }
            })
            .then(() => {
              if (
                licencaImportacao.cdPaisOrigMerc !== null &&
                licencaImportacao.cdPaisOrigMerc !== undefined
              ) {
                return this.$store.dispatch("findObjectByKey", {
                  array: this.codigosPaises,
                  key: "key",
                  value: licencaImportacao.cdPaisOrigMerc,
                });
              }
              return false;
            })
            .then((response) => {
              if (response) newAdicao.codigoPaisOrigemMercadoria = response;
            })
            .then(() => {
              if (
                licencaImportacao.cdPaisAquisMerc !== null &&
                licencaImportacao.cdPaisAquisMerc !== undefined
              ) {
                return this.$store.dispatch("findObjectByKey", {
                  array: this.codigosPaises,
                  key: "key",
                  value: licencaImportacao.cdPaisAquisMerc,
                });
              }
              return false;
            })
            .then((response) => {
              if (response) newAdicao.codigoPaisAquisicaoMercadoria = response;
            })
            .then(() => {
              if (licencaImportacao.cdCobertura !== null) {
                this.$store
                  .dispatch("findObjectByKey", {
                    array: this.codigosCoberturaCambial,
                    key: "key",
                    value: licencaImportacao.cdCobertura,
                  })
                  .then((responseCodigoCoberturaCambial) => {
                    newAdicao.codigoCoberturaCambial =
                      responseCodigoCoberturaCambial;
                  });
              }
            })
            .then(() => {
              if (
                licencaImportacao.cdFundLegal !== null &&
                licencaImportacao.cdFundLegal !== ""
              ) {
                return this.$store.dispatch(
                  "MyIMPStore/consultarCampoDenominacaoAction",
                  {
                    formRetorno: "ElaborarDIForm",
                    campoRetorno: "fundamentoLegalII",
                    subAction: "autoCompleteCodigo",
                    parametro: "4",
                    tipoDI: "01",
                    q: licencaImportacao.cdFundLegal,
                  }
                );
              }
              return false;
            })
            .then((response) => {
              if (response) {
                const fundamentoLegalII = response[0];

                if (
                  fundamentoLegalII !== null &&
                  fundamentoLegalII !== undefined
                ) {
                  newAdicao.fundamentoLegalII = response[0];
                }
              }
            })
            .then(() =>
              this.$store.dispatch(
                "MyIMPStore/consultarDadosMercadoria",
                licencaImportacao.cdSubItemNCM
              )
            )
            .then((ncmResponse) => {
              const ii = Number(
                ncmResponse.aliquotaAdValoremIIPercentualAdValorem
              ).toFixed(2);
              const ipi = Number(
                ncmResponse.aliquotaAdValoremIPIPercentualAdValorem
              ).toFixed(2);
              const pis = Number(
                ncmResponse.aliquotaAdValoremPisPercentualAdValorem
              ).toFixed(2);
              const cofins = Number(
                ncmResponse.aliquotaAdValoremCofinsPercentualAdValorem
              ).toFixed(2);

              newAdicao.descricaoMercadoriaNCM = ncmResponse.descricao;
              newAdicao.aliquotaii = ii;
              newAdicao.aliquotaipi = ipi;
              newAdicao.aliquotapis = pis;
              newAdicao.aliquotacofins = cofins;
              newAdicao.descricaoUnidadeMedidaEstatistica =
                ncmResponse.unidadeMedidaMercosul.descricao;

              newAdicao.adValorem = ii;
              newAdicao.adValoremIpi = ipi;
              newAdicao.aliquotaAdValoremPIS = pis;
              newAdicao.aliquotaAdValoremCOFINS = cofins;

              // if (newAdicao.adValorem === null || newAdicao.adValorem === '') newAdicao.adValorem = ii;
              // if (newAdicao.adValoremIpi === null || newAdicao.adValoremIpi === '') newAdicao.adValoremIpi = ipi;
              // if (newAdicao.aliquotaAdValoremPIS === null || newAdicao.aliquotaAdValoremPIS === '') newAdicao.aliquotaAdValoremPIS = pis;
              // if (newAdicao.aliquotaAdValoremCOFINS === null || newAdicao.aliquotaAdValoremCOFINS === '') newAdicao.aliquotaAdValoremCOFINS = cofins;

              newAdicao.numeroAdicao = (this.di.adicoes.length + 1).toString();
              if (newAdicao.numeroAdicao.length == 1) {
                newAdicao.numeroAdicao = `00${newAdicao.numeroAdicao.toString()}`;
              } else if (newAdicao.numeroAdicao.length == 2) {
                newAdicao.numeroAdicao = `0${newAdicao.numeroAdicao.toString()}`;
              }

              // Inclui a adição nas adicoes da DI
              this.di.adicoes.push(newAdicao);

              // this.$store.commit('MyIMPStore/SET_ACTIVE_ADICAO', newAdicao);
              // Limpa novas adicoes
              newAdicao = {};

              this.currentLI = null;
            })
            .then(() =>
              this.$store.dispatch("MyIMPStore/patch", {
                diff: this.di,
                newModel: this.di,
                fieldName: null,
              })
            )
            .then(() =>
              this.$store.dispatch("MyIMPStore/calcularRateiosDI", {
                id: this.di.id,
                activeAdicao: null,
              })
            )
            .then(() =>
              this.$store.commit("setLoading", {
                show: false,
                label: "",
                description: "",
              })
            );
        }
      } else {
        Swal.fire(
          "Adicionar Adicão c/ LI",
          "Não é possível cadastrar novas adições para esta Declaração de Importação.",
          "error"
        );
      }
    },
    abrirAdicao(adicao) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", adicao);
    },
    async deleteAdicao(value) {
      const willContinue = await Swal.fire({
        title: "Excluir Adição",
        html: `Você realmente deseja excluir a adição <strong>${value.numeroAdicao}</strong>? Se você excluir, precisará adicioná-la novamente na DI.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      if (willContinue.value) {
        const index = this.di.adicoes.findIndex(
          (item) =>
            item.numeroAdicao === value.numeroAdicao &&
            item.codigoPaisOrigemMercadoria === value.codigoPaisOrigemMercadoria
        );
        this.di.adicoes.splice(index, 1);

        let numeroAdicao = 0;
        this.di.adicoes.forEach((a) => {
          numeroAdicao += 1;

          numeroAdicao = numeroAdicao.toString();
          if (numeroAdicao.length == 1) {
            numeroAdicao = `00${numeroAdicao.toString()}`;
          } else if (numeroAdicao.length == 2) {
            numeroAdicao = `0${numeroAdicao.toString()}`;
          }

          a.numeroAdicao = numeroAdicao;
          numeroAdicao = parseInt(numeroAdicao);
        });

        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: this.di,
          newModel: this.di,
          fieldName: null,
        });

        // if (response) {

        //     this.initialDI = {
        //         ...this.di
        //     };
        // }
        Swal.fire(
          "Excluir Adição",
          ` A adição <strong>${value.numeroAdicao}</strong> foi excluída com sucesso.`,
          "success"
        );
      }
    },
    async deleteAdicoes(value) {
      const willContinue = await Swal.fire({
        title: "Excluir Adições",
        html: "Você realmente deseja excluir todas as adições desta DI?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      if (willContinue.value) {
        this.di.adicoes = [];

        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: this.di,
          newModel: this.di,
          fieldName: null,
        });

        if (response) {
          this.initialDI = {
            ...this.di,
          };
          Swal.fire(
            "Excluir Adições",
            "Todas as adiçõe desta DI foram excluídas com sucesso.",
            "success"
          );
        }
      }
    },
    async findLI() {
      if (this.liIdentification === "" || this.liIdentification === null) {
        Swal.fire(
          "Consultar LI",
          "Você precisa digitar o número da LI para a mesa seja consultada no Siscomex.",
          "danger"
        );
        return;
      }

      const response = await this.$store.dispatch(
        "MyIMPStore/consultarAndamentoLI",
        this.liIdentification
      );
      if (response) this.currentLI = response;

      const response1 = await this.$store.dispatch(
        "MyIMPStore/consultarLI",
        this.liIdentification
      );
      if (response1) this.licencaImportacao = response1;
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),
    adicoes() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.di.adicoes.sort((a, b) => {
        if (a.numeroAdicao < b.numeroAdicao) return -1;
        if (b.numeroAdicao < a.numeroAdicao) return 1;
        return 0;
      });
    },
    totalVMLE() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce((value, item) => {
          const val = item.valorMercadoriaCondicaoVenda;
          return value + val;
        }, 0);
      }
      return 0;
    },
    totalPL() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce((value, item) => {
          const val = item.pesoLiquidoMercadoria;
          return value + val;
        }, 0);
      }
      return 0;
    },
    totalQTD() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce((value, item) => {
          const val = item.quantidadeUnidadeEstatistica;
          return value + val;
        }, 0);
      }
      return 0;
    },
    totalII() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce((value, item) => {
          const val = item.iiValorIPTaRecolher;
          return value + val;
        }, 0);
      }
      return 0;
    },
    totalIPI() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce(
          (value, item) => value + item.ipiValorIPTaRecolher,
          0
        );
      }

      return 0;
    },
    totalPIS() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce(
          (value, item) => value + item.pisValorIPTaRecolher,
          0
        );
      }
      return 0;
    },
    totalCOFINS() {
      if (this.adicoes !== null && this.adicoes !== undefined) {
        return this.adicoes.reduce(
          (value, item) => value + item.cofinsValorIPTaRecolher,
          0
        );
      }
      return 0;
    },
  },
  watch: {
    activeDI() {
      this.initialDI = {
        ...this.activeDI,
      };
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };
      this.modelInitial = {
        ...this.activeAdicao,
      };
    },
  },
  mounted() {
    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
