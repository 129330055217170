var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-layout form-layout-2",attrs:{"id":"#list-register-procedures"}},[(_vm.modal.show)?_c('companies-list-new-procedures-modal',{attrs:{"modelUpdate":this.procedure,"isForm":false,"company":_vm.company},on:{"close":function($event){return _vm.closeModal('close')},"save":function($event){return _vm.save()},"removeListItems":_vm.removeListItems}}):_vm._e(),_c('div',{staticClass:"report-summary-header mg-b-5 mg-t-0"},[_vm._m(0),_c('div',[_c('a',{staticClass:"btn btn-info",attrs:{"href":"#"},on:{"click":_vm.newRegister}},[_c('i',{staticClass:"icon ion-plus tx-22"}),_vm._v(" ADICIONAR ")])])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table display responsive nowrap dataTable no-footer dtr-inline tx-13"},[_vm._m(1),_c('tbody',[(_vm.procedures !== undefined && _vm.procedures.length > 0)?_vm._l((_vm.procedures),function(item){return _c('tr',{key:'procedure-id-' + item.id},[_c('td',{staticClass:"valign-middle tx-center tx-primary tx-bold"},[(item.type.value === 'PROCESSO_IMPORTACAO')?_c('span',{},[_vm._v(" PI - Processo de Importação ")]):(item.type.value === 'PROCESSO_EXPORTACAO')?_c('span',{},[_vm._v(" PE - Processo de Exportação ")]):(item.type.value === 'FINANCEIRO')?_c('span',{},[_vm._v(" FIN - Financeiro ")]):(item.type.value === 'FATURAMENTO')?_c('span',{},[_vm._v(" FAT - Faturamento ")]):(item.type.value === 'GERAL')?_c('span',{},[_vm._v(" Geral ")]):(item.type.value === 'ENCERRAMENTO')?_c('span',{},[_vm._v(" Encerramento ")]):_vm._e()]),(item.itens.length === _vm.qtdOfDisabledProcedures(item.itens))?_c('td',{staticClass:"valign-middle tx-center tx-danger tx-bold"},[_vm._v(" "+_vm._s(item.description)+" "),_c('br'),_c('small',[_vm._v("Todos os itens desabilitados")])]):_c('td',{staticClass:"valign-middle tx-center"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',{staticClass:"valign-middle tx-center"},[(
                  item.itens === undefined ||
                  _vm.qtdOfEnabledProcedures(item.itens) === 0
                )?_c('span',{staticClass:"tx-danger tx-bold"},[_vm._v(" Nenhum requisito ")]):(_vm.qtdOfEnabledProcedures(item.itens) === 1)?_c('span',[_vm._v(" 1 requisito ")]):_c('span',[_vm._v(_vm._s(_vm.qtdOfEnabledProcedures(item.itens))+" requisitos ")])]),_c('td',{staticClass:"valign-middle tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"DD/MM/YYYY"))+" às "+_vm._s(_vm._f("moment")(item.created,"H:mm"))+" "),_c('br'),_c('small',[_vm._v("Por "+_vm._s(item.createdBy))])]),_c('td',{staticClass:"valign-middle tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.modified,"DD/MM/YYYY"))+" às "+_vm._s(_vm._f("moment")(item.modified,"H:mm"))+" "),_c('br'),_c('small',[_vm._v("Por "+_vm._s(item.modifiedBy))])]),_c('td',{staticClass:"valign-middle tx-center"},[_c('a',{staticClass:"btn btn-danger btn-icon rounded-circle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(item)}}},[_vm._m(2,true)]),_c('a',{staticClass:"btn btn-warning btn-icon rounded-circle mg-l-10",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(item)}}},[_vm._m(3,true)])])])}):[_vm._m(4)]],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3"},[_vm._v("Categoria dos Requisitos do Cliente")]),_c('p',{staticClass:"mg-b-0"},[_c('i',{staticClass:"icon ion-information-circled mg-r-3"}),_vm._v(" Categorização dos requisitos dos clientes nos processos. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"tx-10"},[_c('th',{staticClass:"pd-y-5 tx-center wd-250"},[_vm._v("Categoria")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Descrição")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Requisitos Habilitados")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Data de Criação")]),_c('th',{staticClass:"pd-y-5 tx-center"},[_vm._v("Data de Atualização")]),_c('th',{staticClass:"pd-y-5 tx-center wd-150"},[_vm._v("Ações")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon ion-trash-a"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon ion-edit"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"valign-middle tx-center pd-t-5",attrs:{"colspan":"6"}},[_vm._v(" Nenhum requisito foi cadastrado ")])])
}]

export { render, staticRenderFns }