<template>
  <div>
    <div class="light-bg p-3 bd-b">
      <div>

        <div :class="[
          'flex ai-c jc-sb fw-w fgap2',
        ]">
          <div>
            <h4 class="mb-0">EXPORTAR EMPRESAS EM GERAL</h4>
            <p class="mb-0">
              Exportação de Empresas em Geral em Tela e Excel
            </p>
          </div>
          <div class="flex ai-c fgap1">
            <download-excel
                :data="excelContacts"
                :fields="excelFields"
                class="plus-button bg-success"
                v-if="generatedInTableCompleted"
                :name="`empresas-em-geral-${formatDateMethod(new Date())}.xls`"
            >
              EXCEL
            </download-excel>

            <button type="button" @click="addDialog = !addDialog" class="plus-button bg-info">
              FILTROS
            </button>
          </div>
        </div>

        <transition name="activity" mode="out-in">
          <div v-if="addDialog">
            <div class="four-grid mt-4">
              <div class="four-grid">
                <div class="fg-4">
                  <select-wrapper
                      name="enabled"
                      label="Situação"
                      v-model="model.enabled"
                      :clearable="true"
                      :options="[
                        {
                          label: 'Habilitado',
                          value: true,
                        },
                        {
                          label: 'Desabilitado',
                          value: 'false',
                        },
                      ]"
                  ></select-wrapper>
                </div>

                <div class="fg-4">
                  <select-wrapper
                      name="compliance"
                      label="Compliance"
                      v-model="model.compliance"
                      :clearable="true"
                      :options="[
                        {
                          label: 'Sim',
                          value: true,
                        },
                        {
                          label: 'Não',
                          value: 'false',
                        },
                      ]"
                  ></select-wrapper>
                </div>

                <div class="fg-4">
                  <v-input
                      name="initialOpeningDate"
                      label="Data de Fundação Inicial"
                      v-model="model.initialOpeningDate"
                      type="date"
                  ></v-input>
                </div>

                <div class="fg-4">
                  <select-wrapper
                      name="minimalComission"
                      label="Comissão Mínima?"
                      v-model="model.minimalComission"
                      :clearable="true"
                      :options="[
                        {
                          label: 'Sim',
                          value: true,
                        },
                        {
                          label: 'Não',
                          value: 'false',
                        },
                      ]"
                  ></select-wrapper>
                </div>
              </div>

              <div class="four-grid">
                <div class="fg-4">
                  <select-wrapper
                      name="taxRegime"
                      label="Regime Fiscal"
                      v-model="model.taxRegime"
                      :clearable="true"
                      :options="[
                        {
                          label: 'Lucro Presumido',
                          value: '0',
                        },
                        {
                          label: 'Lucro Real',
                          value: '1',
                        },
                        {
                          label: 'Simples',
                          value: '2',
                        },
                      ]"
                  ></select-wrapper>
                </div>

                <div class="fg-4">
                  <v-input
                    name="city"
                    label="Cidade"
                    v-model="model.city"
                  ></v-input>
                </div>

                <div class="fg-4">
                  <v-input
                      name="finalOpeningDate"
                      label="Data de Fundação Final"
                      v-model="model.finalOpeningDate"
                      type="date"
                  ></v-input>
                </div>

                <div class="fg-4">
                  <select-wrapper
                      name="radarModality"
                      label="Modalidade do Radar"
                      v-model="model.radarModality"
                      :clearable="true"
                      :options="[
                        {
                          label: 'Ilimitado',
                          value: 'ILIMITED'
                        },
                        {
                          label: 'Limitado USD 50.000',
                          value: 'LIMITED',
                        },
                        {
                          label: 'Limitado USD 150.000',
                          value: 'LIMITED'
                        },
                        {
                          label: 'Expressa',
                          value: 'EXPRESS'
                        },
                      ]"
                  ></select-wrapper>
                </div>
              </div>

              <div class="fg-2 four-grid">
                <div class="fg-2">
                  <fieldset>
                    <legend>Colunas disponíveis</legend>
                    <ul class="columns-list">
                      <li v-for="column in availableColumns" :key="column.field">
                        <a href="#" @click.prevent="column.hide = false">
                          {{ column.name }}
                        </a>
                      </li>
                    </ul>
                  </fieldset>
                </div>
                <div class="fg-2">
                  <fieldset>
                    <legend>Colunas selecionadas</legend>
                    <ul class="columns-list selecteds">
                      <li v-for="column in selectedColumns" :key="column.field">
                        <a href="#" @click.prevent="column.hide = true">
                          {{ column.name }}
                        </a>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="four-grid mt-4">
              <div>
                <v-switch
                  name="isProvider"
                  :labels="{
                    checked: 'Fornecedor da Empresa',
                    unchecked: 'Fornecedor da Empresa',
                  }"
                  v-model="model.isProvider"
                />
              </div>
              <div>
                <v-switch
                  name="isCustomBroker"
                  :labels="{
                    checked: 'Despachante Aduaneiro',
                    unchecked: 'Despachante Aduaneiro',
                  }"
                  v-model="model.isCustomBroker"
                />
              </div>

              <div>
                <v-switch
                  name="isForwarder"
                  :labels="{
                    checked: 'Forwarder',
                    unchecked: 'Forwarder',
                  }"
                  v-model="model.isForwarder"
                />
              </div>

              <div>
                <v-switch
                  name="isCommissioned"
                  :labels="{
                    checked: 'Comissionado',
                    unchecked: 'Comissionado',
                  }"
                  v-model="model.isCommissioned"
                />
              </div>

              <div>
                <v-switch
                  name="isExporter"
                  :labels="{
                    checked: 'Exportador / Fabricante',
                    unchecked: 'Exportador / Fabricante',
                  }"
                  v-model="model.isExporter"
                />
              </div>

              <div>
                <v-switch
                  name="isConveyor"
                  :labels="{
                    checked: 'Transportador',
                    unchecked: 'Transportador',
                  }"
                  v-model="model.isConveyor"
                />
              </div>

              <div>
                <v-switch
                  name="isShipowner"
                  :labels="{
                    checked: 'Armador',
                    unchecked: 'Armador',
                  }"
                  v-model="model.isShipowner"
                />
              </div>
            </div>

            <div class="flex ai-c jc-c fgap2 mt-4">
              <button class="report-button" @click.prevent="generateInTable">GERAR EM TELA</button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="ph-2 sp-3 mt-4 " v-if="generatedInTableCompleted">
      <h6 class="slim-card-title tx-info">Foram encontrados {{contacts.length}} empresas</h6>
      <div class="table-container" >
        <div class="table-overflow">
          <table>
            <thead>
            <tr>
              <th v-for="column in selectedColumns" :key="column.field">
                {{ column.name }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="contacts.length == 0">
              <td :colspan="selectedColumns.length" class="text-center">
                <div class="card-icon-wrapper info mg-t-40">
                  <i class="icon ion-person-stalker"></i>
                </div>
                <h4 class="tx-gray-800">Nenhuma empresa foi encontrada</h4>
                <p>Verifique se o filtro está correto e tente realizar um novo filtro.</p>
              </td>
            </tr>

            <template v-else>
              <tr v-for="contact in contacts" :key="contact.id">
                <td v-for="column in selectedColumns" :key="column.field">
                    <span v-if="column.type === 'boolean'">
                      {{contact[column.field]}}
                    </span>
                    <span v-else-if="column.type === 'date'">
                      {{ contact[column.field] | formatDate }}
                    </span>
                    <span v-else-if="column.format === 'cnpj'">
                      {{ formatCustomerCnpj(contact[column.field]) }}
                    </span>
<!--                    <span v-else-if="column.format === 'taxRegime'">-->
<!--                      <span v-if="contact[column.field] === 'LUCRO_PRESUMIDO'">Lucro Presumido</span>-->
<!--                      <span v-if="contact[column.field] === 'LUCRO_REAL'">Lucro Real</span>-->
<!--                      <span v-if="contact[column.field] === 'SIMPLES'">Simple</span>-->
<!--                    </span>-->
                    <span v-else-if="contact[column.field] === null || contact[column.field] === ''">
                      N/I
                    </span>
                    <span v-else>
                      {{ contact[column.field] }}
                    </span>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="ph-2 sp-3 mt-4 bd-0 pd-50 tx-center" v-else>
      <div class="card-icon-wrapper info">
          <i class="icon ion-funnel"></i>
        </div>
        <h4 class="tx-gray-800">APLIQUE UM FILTRO</h4>
        <p class="mg-b-25">Preencha os campos acima para filtrar o registros de acordo com o que<br />você deseja, para que seja possível gerar o relatório!</p>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';

import VInput from '@/components/VInput';
import SelectWrapper from '@/components/SelectWrapper';
import VSwitch from '@/components/VSwitch.vue';
import Swal from 'sweetalert2';

import Axios from 'axios';
import moment from 'moment';

import JsonExcel from 'vue-json-excel';

import contactColumns from '@/utils/customerColumns';

export default {
  name: 'CompanyReport',
  components: {
    VInput,
    SelectWrapper,
    VSwitch,
    'download-excel': JsonExcel,
  },
  data() {
    return {
      addDialog: true,
      generatedInTableCompleted: false,
      contacts: [],
      customers: [],
      model: {
        initialOpeningDate: null,
        finalOpeningDate: null,
      },
      timer: null,
      search: '',
      searching: false,
      columns: contactColumns,
    };
  },
  methods: {
    formatCustomerCnpj(value) {
      if (value) {
        return String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
      return 'N/I';
    },
    async generateInTable() {
      this.contacts = [];
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando dados do relatório.',
      });

      const params = {
        enabled: this.model.enabled,
        compliance: this.model.compliance,
        minimalComission: this.model.minimalComission,
        taxRegime: this.model.taxRegime,
        radarModality: this.model.radarModality,
        city: this.model.city,
        initialOpeningDate: this.model.initialOpeningDate,
        finalOpeningDate: this.model.finalOpeningDate,
        isProvider: this.model.isProvider,
        isCustomBroker: this.model.isCustomBroker,
        isForwarder: this.model.isForwarder,
        isCommissioned: this.model.isCommissioned,
        isExporter: this.model.isExporter,
        isConveyor: this.model.isConveyor,
        isShipowner: this.model.isShipowner,
        isCustomer: this.model.isCustomer,
      };

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/company/listWithFilters`, { params });

        this.contacts = response.data.data;
        this.generatedInTableCompleted = true;
        this.addDialog = false;
      } catch (e) {
        Swal.fire(
          {
            title: 'Relatório de contatos',
            message: `Houve um erro interno ao gerar o relatório de contatos! ${e}`,
            type: 'error',
          },
        );

        this.generatedInTableCompleted = false;
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    formatDateMethod(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format('DD/MM/YYYY');
      }
      return 'N/I';
    },
    formatTaxRegimeMethod(regime) {
      switch (regime) {
        case 'LUCRO_PRESUMIDO':
          return 'Lucro Presumido';
        case 'LUCRO_REAL':
          return 'Lucro Real';
        case 'SIMPLES':
          return 'Simples';
        default:
          return '';
      }
    },
  },
  filters: {
    formatDate(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format('DD/MM/YYYY');
      }
      return 'N/I';
    },
  },
  computed: {
    selectedColumns() {
      return this.columns.filter((col) => col.hide === false);
    },
    availableColumns() {
      return this.columns.filter((col) => col.hide === true);
    },
    excelFields() {
      const obj = {};
      this.selectedColumns.map((col) => {
        Object.assign(obj, { [col.name]: col.field });
        return null;
      });
      return obj;
    },
    excelContacts() {
      return this.contacts.map((contact) => {
        const info = contact;

        for (let i = 0; i < Object.keys(contact).length; i++) {
          const key = Object.keys(contact)[i];
          const cKey = this.selectedColumns.find((sel) => sel.field == key);

          if (cKey && cKey.type === 'date') {
            info[key] = this.formatDateMethod(info[key]);
          }

          if (cKey && cKey.type === 'boolean') {
            info[key] = info[key] ? 'Sim' : 'Não';
          }

          if (cKey && cKey.format === 'taxRegime') {
            info[key] = this.formatTaxRegimeMethod(info[key]);
          }
        }

        return info;
      });
    },
  },
};
</script>

<style scoped>

.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80B7E2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

.plus-button:hover {
  background-color: #1768a7;
}

.plus-button.success {
  background-color: #80B7E2;
}

.four-grid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.fg-2 {
  grid-column: span 2;
}

.fg-3 {
  grid-column: span 3;
}

.fg-4 {
  grid-column: span 4;
}

fieldset {
  display: block;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

fieldset legend {
  background-color: transparent;
  font-size: 14px;
  width: auto;
  padding: 0 5px;
}

.columns-list {
  list-style: none;
  padding: 0;
  max-height: 270px;
  overflow: auto;
  scrollbar-color: #868BA1 transparent;
  scrollbar-width: thin;
}

.columns-list::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.columns-list::-webkit-scrollbar-thumb {
  background-color: #868BA1;
  border-radius: 5px;
}

.columns-list li a {
  display: block;
  padding: 10px;
  background-color: #F8F9FA;
  border: 1px solid #e2e2e2;
  color: inherit;
}

.columns-list li a:hover {
  background-color: #dbdbdb;
}

.columns-list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.columns-list.selecteds li a {
  color: black;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all .2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 10px 5px;
  color: #343a40;
  text-align: center;
  font-size: 0.8rem;
}

table tr th {
  background-color: #8392A51A;
  min-width: 150px;
}

.table-container {
  width: 100%;
  overflow: auto;
  height: 500px;
  scrollbar-color: #868BA1 transparent;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #868BA1;
  border-radius: 5px;
}

.activity-enter-active,
.activity-leave-active {
  transition: all .5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all .5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}
</style>
