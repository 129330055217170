<template>
  <transition name="collapse-procedures">
    <div id="collapse-register-procedures">
      <div class="collapse" v-bind:class="{ active: open }">
        <button type="button" class="collapse-close" v-on:click="$emit('close', false);"></button>

        <div class="collapse-content" v-bind:class="{ active: open }">
          <div class="collapse-opacity">
            <template v-if="open">
              <button class="btn btn-sm btn-primary btn-close" type="button" v-on:click="$emit('close', false);">
                <i class="fa fa-chevron-right"></i>
              </button>

              <form>
                <h3 class="title">Informações do Requisito</h3>
                <div class="row no-gutters">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label"> Descrição <span class="tx-danger">*</span></label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-info op-5"></i>
                        </div>
                        <div class="input-group-solved-after mg-l-10 wd-100p">
                          <input class="form-control " type="text" v-model="model.description" name="description"
                                 v-validate="'required'"/>
                        </div>
                      </div>
                      <span v-show="errors.has('description')"
                            class="parsley-errors-list filled">Digite uma descrição</span>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group bd-l-0-force">
                      <label class="form-control-label">Data de Início</label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-calendar op-5"></i>
                        </div>
                        <div class="input-group-solved-center">
                          <date-picker lang="pt-br" name="startDate" v-model="model.startDate" class="form-control"
                                       format="DD/MM/YY"></date-picker>
                        </div>
                        <div class="input-group-solved-after"></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group bd-l-0-force">
                      <label class="form-control-label">Data de Término</label>
                      <div class="input-group-solved">
                        <div class="input-group-solved-before">
                          <i class="fa fa-calendar op-5"></i>
                        </div>
                        <div class="input-group-solved-center">
                          <date-picker lang="pt-br" name="finishDate" v-model="model.finishDate" class="form-control"
                                       format="DD/MM/YY"></date-picker>
                        </div>
                        <div class="input-group-solved-after"></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <vue-editor v-model="model.content" class="wd-100p bd-0 "
                                :editorToolbar="customToolbar"></vue-editor>
                  </div>
                </div>
                <br/>

                <div class="card card-recent-messages" v-if="!!model.created">
                  <div class="card-header">
                    <span>Histórico de Alterações</span>
                  </div>

                  <div class="row no-gutters">
                    <perfect-scrollbar style="height: 115px; width: 100%;">

                      <div class="col-lg-12" v-for="log in listOfLog" :key="'log-'+log.id">
                        <div class="form-group bd-l-0-force bd-r-0-force bd-t-0-force">
                          <div class="input-group-solved">
                            <div class="input-group-solved-before">
                              <i class="fa fa-info op-5"></i>
                            </div>
                            <div class="input-group-solved-after mg-l-10 wd-100p">
                              {{ log.type === 'DISABLE' ? 'Desabilitado' : ""}} em
                              {{ log.created | moment("DD/MM/YYYY") }} às
                              {{ log.created | moment("H:mm:ss") }} por
                              {{ log.createdBy }}
                            </div>
                          </div>
                          <label class="form-control-label"> <strong>Motivo:</strong> {{ log.description}} </label>
                        </div>
                      </div>

                    </perfect-scrollbar>

                    <div class="col-lg-6">
                      <div class="form-group bd-l-0-force bd-b-0-force">
                        <label class="form-control-label"> Criação </label>
                        <div class="input-group-solved">
                          <div class="input-group-solved-before">
                            <i class="fa fa-info op-5"></i>
                          </div>
                          <div class="input-group-solved-after mg-l-10 wd-100p">
                            {{ model.created | moment("DD/MM/YYYY") }} às
                            {{ model.created | moment("H:mm") }} por
                            {{ model.createdBy }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group bd-l-0-force bd-r-0-force bd-b-0-force">
                        <label class="form-control-label"> Atualização </label>
                        <div class="input-group-solved">
                          <div class="input-group-solved-before">
                            <i class="fa fa-info op-5"></i>
                          </div>
                          <div class="input-group-solved-after mg-l-10 wd-100p">
                            {{ model.modified | moment("DD/MM/YYYY") }} às
                            {{ model.modified | moment("H:mm") }} por
                            {{ model.modifiedBy }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row form-layout-footer">
                  <div class="col-lg-8"></div>
                  <div class="col-lg-4 mg-t-30">
                    <button class="btn btn-sm btn-success btn-block" @click.prevent="submit()"
                            v-if="hasPrivilege('CRM_EMPRESAS_REQUISITOS_EDITAR') || hasPrivilege('CRM_EMPRESAS_REQUISITOS_ADICIONAR')">
                      <i class="fa fa-edit mg-r-5" aria-hidden="true"></i>
                      SALVAR
                    </button>
                  </div>
                </div>
              </form>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';
import * as constants from '@/consts.js';
import {
  EventBus,
} from '@/events/EventBus.js';

import {
  VueEditor,
} from 'vue2-editor';
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2';
import CredentialService from '@/services/CredentialService.js';

export default {
  name: 'collapse-procedures',
  props: ['open', 'modelUpdate'],
  components: {
    VueEditor,
    DatePicker,
  },

  watch: {
    modelUpdate() {
      this.model = this.modelUpdate;
    },
  },

  data() {
    return {
      model: {
        id: null,
        startDate: null,
        finishDate: null,
        description: '',
        content: '',
        procedure: null,
      },
      log: [],
      oldModel: {},
      customToolbar: [
        [{
          header: 1,
        },
          {
            header: 2,
          },
        ],
        ['bold', 'italic', 'underline'],
        [{
          list: 'ordered',
        },
          {
            list: 'bullet',
          },
        ],
        // ["image", "code-block"]
      ],
    };
  },
  mounted() {
  },

  methods: {
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    submit() {
      this.$validator.validateAll().then((response) => {
        if (response) {
          const value = {
            id: this.model.id,
            startDate: this.model.startDate,
            finishDate: this.model.finishDate,
            description: this.model.description,
            content: this.model.content,
          };

          this.oldModel = value;

          this.$emit('save', value);
        } else {
          this.$swal(
            'Itens dos Procedimentos',
            'Você não preencheu todos os campos obrigatórios.',
            'error',
          );
        }
      });
    },
  },
  computed: {
    listOfLog() {
      return this.modelUpdate.log;
    },
  },
};
</script>

<style scoped>
@import "./styles.css";
</style>
