var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-invoice bd-0"},[_c('div',{staticClass:"card-body"},[_c('printable-header'),_c('br'),_c('br'),_c('h1',{staticClass:"slim-pagetitle"},[_vm._v(" Demonstrativo de Conta Corrente do Processo #"+_vm._s(_vm.model.identification)+" "),_c('span',{staticClass:"float-right tx-14 tx-normal"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD/MM/YYYY"))+" ")])]),_c('hr'),_c('printable-operational-header',{attrs:{"model":_vm.model}}),_c('h6',{staticClass:"slim-card-title tx-primary mg-t-20"},[_vm._v(" Lançamentos Financeiros na Conta Corrente ")]),_c('hr'),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-invoice"},[_vm._m(0),_c('tbody',[_vm._l((_vm.items),function(appointment){return _c('tr',{key:'appointment-' + appointment.id},[(
                appointment.moviment
                  .enabledMovimentCheckingAccountOfProcess === true
              )?[_c('td',{staticClass:"valign-middle"},[_c('a',{staticClass:"tx-inverse tx-14 tx-medium d-block",attrs:{"href":""}},[_vm._v(_vm._s(appointment.description)+" ")]),_c('span',{staticClass:"tx-11 d-block"},[_vm._v(" "+_vm._s(appointment.moviment.company.name)+" ")])]),_c('td',{staticClass:"valign-middle"},[(appointment.typeOfMovimentation === 'PROCESS_EXPENSE')?[_c('span',{staticClass:"square-8 bg-danger mg-r-5 rounded-circle"}),_vm._v(" Saída ")]:[_c('span',{staticClass:"square-8 bg-success mg-r-5 rounded-circle"}),_vm._v(" Entrada ")]],2),_c('td',{staticClass:"valign-middle tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(appointment.dueDate,"DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"valign-middle tx-right"},[_vm._v(" "+_vm._s(_vm._f("numeralFormat")(appointment.originalValue,"0,0.00"))+" ")]),_c('td',{staticClass:"valign-middle",attrs:{"colspan":"3"}},[_c('table',{staticClass:"wd-100p bd-t-0"},[(appointment.receipts.length > 0)?_vm._l((appointment.receipts),function(receipt){return _c('tr',{key:'receipt-' + receipt.id},[_c('td',{staticClass:"wd-100p bd-t-0--force"},[_vm._v(" "+_vm._s(_vm._f("moment")(receipt.paymentDate,"DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"wd-100p bd-t-0--force"},[_vm._v(" "+_vm._s(_vm._f("numeralFormat")(receipt.paidValue,"0,0.00"))+" ")])])}):void 0],2)])]:_vm._e()],2)}),_c('tr',[_c('td',{staticClass:"tx-right tx-normal",attrs:{"colspan":"3"}},[_vm._v("TOTAIS")]),_c('td',{staticClass:"tx-right",attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.balance)))])]),_c('td',{staticClass:"tx-right",attrs:{"colspan":"2"}},[_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.balanceRealized)))])])]),_c('tr',[_c('td',{staticClass:"tx-right tx-uppercase tx-bold tx-inverse",attrs:{"colspan":"5"}},[_vm._v(" Saldo do Processo ")]),_c('td',{staticClass:"tx-right"},[_c('h4',{staticClass:"tx-bold tx-18",class:{
                  'tx-success': _vm.balanceRealized > 0,
                  'tx-danger': _vm.balanceRealized < 0,
                }},[_vm._v(" R$ "+_vm._s(_vm._f("currency")(_vm.balanceRealized))+" ")])])])],2)]),_c('hr')])],1),_c('span',{staticClass:"tx-12 float-right"},[_vm._v(" Demonstrativo gerado em "+_vm._s(_vm._f("moment")(new Date(),"DD/MM/YYYY \\à\\s H:m:s"))+" por "+_vm._s(this.$store.state.credentials.data.person.name)+". ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"wd-200"},[_vm._v("Descrição")]),_c('th',{staticClass:"wd-120"},[_vm._v("Movimento")]),_c('th',[_vm._v("Vencimento")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Pagamento")]),_c('th',[_vm._v("Valor")])])])
}]

export { render, staticRenderFns }