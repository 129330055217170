<template>
  <div class="form-layout form-layout-2">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Lançamento Financeiros do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Lançamento
          integrados com o financeiro.
        </p>
      </div>
      <div>
        <a href="#" class="btn btn-secondary" @click="printFinancial"
          ><i class="icon ion-document-text tx-22"></i> Extrato
        </a>
      </div>
    </div>

    <div class="row pd-x-15">
      <div class="card card-sales mg-b-15 pd-b-0 col-sm-6 bg-gray-100">
        <h6 class="slim-card-title tx-danger">VALORES PREVISTOS</h6>
        <div class="row mg-b-0 mg-b-0-force">
          <div class="col mg-b-0 pd-b-0">
            <label class="tx-12">ENTRADA</label>
            <p>R$ {{ inputsTotal | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SAÍDA</label>
            <p>R$ {{ outputsTotal | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SALDO</label>
            <p>R$ {{ balance | numeralFormat("0,0.00") }}</p>
          </div>
        </div>
      </div>

      <div class="card card-sales mg-l--1 mg-b-15 col-sm-6 bg-gray-100">
        <h6 class="slim-card-title tx-success">VALORES REALIZADOS</h6>
        <div class="row mg-b-0 mg-b-0-force">
          <div class="col mg-b-0 pd-b-0">
            <label class="tx-12">ENTRADA</label>
            <p>R$ {{ inputsTotalRealized | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SAÍDA</label>
            <p>R$ {{ outputsTotalRealized | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SALDO</label>
            <p class="tx-danger">
              R$ {{ balanceRealized | numeralFormat("0,0.00") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters bg-gray-100">
      <table class="table mg-b-0">
        <thead>
          <tr>
            <th class="wd-200">Descrição</th>
            <th class="wd-120">Movimento</th>
            <th>Vencimento</th>
            <th>Valor</th>
            <th>Pagamento</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="appointment in movimentAppointments"
            :key="'appointment-' + appointment.id"
          >
            <template
              v-if="
                appointment.moviment.enabledMovimentCheckingAccountOfProcess ===
                true
              "
            >
              <td class="valign-middle">
                <a href="" class="tx-inverse tx-14 tx-medium d-block"
                  >{{ appointment.description }}
                </a>
                <span class="tx-11 d-block">
                  {{ appointment.moviment.company.name }}
                </span>
              </td>

              <td class="valign-middle">
                <template
                  v-if="appointment.typeOfMovimentation === 'PROCESS_EXPENSE'"
                >
                  <span class="square-8 bg-danger mg-r-5 rounded-circle"></span>
                  Saída
                </template>
                <template v-else>
                  <span
                    class="square-8 bg-success mg-r-5 rounded-circle"
                  ></span>
                  Entrada
                </template>
              </td>
              <td class="valign-middle tx-center">
                {{ appointment.dueDate | moment("DD/MM/YYYY") }}
              </td>
              <td class="valign-middle">
                {{ appointment.originalValue | numeralFormat("0,0.00") }}
              </td>
              <td colspan="3" class="valign-middle">
                <table class="able mg-b-0 wd-100p">
                  <template v-if="appointment.receipts.length > 0">
                    <tr
                      v-for="receipt in appointment.receipts"
                      :key="'receipt-' + receipt.id"
                    >
                      <td class="wd-100p">
                        {{ receipt.paymentDate | moment("DD/MM/YYYY") }}
                      </td>
                      <td class="wd-100p">
                        {{ receipt.paidValue | numeralFormat("0,0.00") }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="">
                      <td class="tx-center tx-11 d-block" colspan="2">
                        Nenhum
                      </td>
                    </tr>
                  </template>
                </table>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <financial-cc-list
      id="printFinancial"
      :model="process"
      :items="movimentAppointments"
      style="display: none"
    >
    </financial-cc-list>

    <hr class="mg-b-25" />

    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Lançamentos Manuais do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Lançamentos do
          processo (Não integrados com o financeiro).
        </p>
      </div>
      <div>
        <a href="#" class="btn btn-secondary" @click="printManual"
          ><i class="icon ion-document-text tx-22"></i> Extrato
        </a>
      </div>
    </div>

    <div class="row pd-x-15">
      <div class="card card-sales mg-b-15 pd-b-0 col-sm-6 bg-gray-100">
        <h6 class="slim-card-title tx-danger">VALORES PREVISTOS</h6>
        <div class="row mg-b-0 mg-b-0-force">
          <div class="col mg-b-0 pd-b-0">
            <label class="tx-12">ENTRADA</label>
            <p>R$ {{ inputsTotal | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SAÍDA</label>
            <p>R$ {{ outputsTotal | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SALDO</label>
            <p>R$ {{ balance | numeralFormat("0,0.00") }}</p>
          </div>
        </div>
      </div>

      <div class="card card-sales mg-l--1 mg-b-15 col-sm-6 bg-gray-100">
        <h6 class="slim-card-title tx-success">VALORES REALIZADOS</h6>
        <div class="row mg-b-0 mg-b-0-force">
          <div class="col mg-b-0 pd-b-0">
            <label class="tx-12">ENTRADA</label>
            <p>R$ {{ inputsTotalRealized | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SAÍDA</label>
            <p>R$ {{ outputsTotalRealized | numeralFormat("0,0.00") }}</p>
          </div>
          <div class="col mg-b-0">
            <label class="tx-12">SALDO</label>
            <p class="tx-danger">
              R$ {{ balanceRealized | numeralFormat("0,0.00") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="wd-200">Descrição</th>
            <th class="wd-120">Movimento</th>
            <th>Vencimento</th>
            <th>Valor</th>
            <th>Pagamento</th>
            <th>Valor</th>
            <th class="text-right">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr-inline
            v-for="item in data"
            :key="item.created"
            :row="item"
            @save="update"
          >
            <td-inline v-model="item.description"></td-inline>
            <td-inline
              v-model="item.typeOfMovimentation"
              type="select"
              :options="[
                { value: 'PROCESS_INCOME', label: 'Entrada' },
                { value: 'PROCESS_EXPENSE', label: 'Saída' },
              ]"
            />
            <td-inline
              icon="fa-calendar"
              v-model="item.estimatedValueDate"
              type="date"
              placeholder="Vence em"
            ></td-inline>
            <td-inline type="money" v-model="item.estimatedValue"></td-inline>

            <td-inline
              icon="fa-calendar"
              v-model="item.paidValueDate"
              type="date"
              placeholder="Pago em"
            ></td-inline>
            <td-inline type="money" v-model="item.paidValue"></td-inline>
            <template slot="actions">
              <a
                href="#"
                @click.prevent="remove(item)"
                class="btn btn-danger btn-icon rounded-circle"
              >
                <div><i class="icon ion-trash-a"></i></div>
              </a>
            </template>
          </tr-inline>
          <tr-inline
            class="new-item"
            :isNew="true"
            :row="newData"
            @save="create"
          >
            <td-inline placeholder="Descrição" v-model="newData.description" />
            <td-inline
              v-model="newData.typeOfMovimentation"
              type="select"
              :options="[
                { value: 'PROCESS_INCOME', label: 'Entrada' },
                { value: 'PROCESS_EXPENSE', label: 'Saída' },
              ]"
            />
            <td-inline
              icon="fa-calendar"
              v-model="newData.estimatedValueDate"
              type="date"
              placeholder="Vence em"
            ></td-inline>
            <td-inline
              type="money"
              v-model="newData.estimatedValue"
            ></td-inline>
            <td-inline
              icon="fa-calendar"
              v-model="newData.paidValueDate"
              type="date"
              placeholder="Pago em"
            ></td-inline>
            <td-inline type="money" v-model="newData.paidValue"></td-inline>
          </tr-inline>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" class="tx-right">Valores Totais</th>
            <th class="tx-right">R$ {{ balance | numeralFormat("0,0.00") }}</th>
            <th></th>
            <th class="tx-right">
              R$ {{ balanceRealized | numeralFormat("0,0.00") }}
            </th>
            <th class="wd-150"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <hr class="mg-b-25" />

    <manual-cc-list
      id="printManual"
      :model="process"
      :items="data"
      style="display: none"
    >
    </manual-cc-list>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import config from "@/config.js";
import { mapState } from "vuex";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import ManualCC from "@/views/operational/imp/printable/ManualCC.vue";
import FinancialCC from "@/views/operational/imp/printable/FinancialCC.vue";

export default {
  name: "FormCc",
  props: [],
  components: {
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
    "manual-cc-list": ManualCC,
    "financial-cc-list": FinancialCC,
  },
  data() {
    return {
      model: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      data: [],
      movimentAppointments: [],
      newData: {
        type: "CONTA_CORRENTE",
        typeOfMovimentation: "PROCESS_INCOME",
        description: "",
        estimatedValue: 0,
        estimatedValueDate: "",
        isPaid: false,
        paidValue: 0,
        paidValueDate: "",
        process: "",
      },
    };
  },
  methods: {
    printManual() {
      this.$htmlToPaper("printManual");
    },
    printFinancial() {
      this.$htmlToPaper("printFinancial");
    },
    async update(value) {
      this.submit(value, "put");
    },
    async create(value) {
      this.submit(value, "post");
    },
    async submit(value, method) {
      this.$store.commit("setLoading", {
        show: true,
        label: `Processo ${this.process.id}`,
        description: "Salvando dados, aguarde...",
      });

      // converte para formato correto de data
      this.estimatedValueDate = moment(this.estimatedValueDate).format(
        "YYYY-MM-DD[T]hh:mm:ss"
      );

      if (value.paidValueDate !== "") {
        value.paidValueDate = moment().format("YYYY-MM-DD[T]hh:mm:ss");
      }

      try {
        let response;
        if (method == "put") {
          response = await Axios.put(value._links.self.href, value);
          let item = this.data.find(
            (item) => item._links.self.href === value._links.self.href
          );
          item = response.data;
        } else {
          response = await Axios.post(
            `${config.env.API_DEFAULT.host}/processAppointmentItems/`,
            value
          );
          this.data.push(response.data);
        }

        this.newData = {
          type: "CONTA_CORRENTE",
          typeOfMovimentation: "PROCESS_INCOME",
          description: "",
          estimatedValue: 0,
          estimatedValueDate: "",
          isPaid: false,
          paidValue: 0,
          paidValueDate: "",
          process: `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`,
        };
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", "", "error");
        console.error(err);
      }
    },
    async remove(value) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: `Processo ${this.process.id}`,
          description: "Processando requisição, aguarde...",
        });
        const response = await Axios.delete(value._links.self.href, value);
        const index = this.data.findIndex(
          (item) => item._links.self.href === value._links.self.href
        );
        this.data.splice(index, 1);
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", err, "error");
        console.error(err);
      }
    },

    async loadData() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Conta Corrente do Processo",
        description: "Carregando os lançamentos financeiros do processo",
      });
      this.newData.process = `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}`;
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/importProcesses/${this.process.id}/appointments/`
        );
        this.data = response.data._embedded.processAppointmentItems.filter(
          (item) => item.type == "CONTA_CORRENTE"
        );
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        const response1 = await Axios.get(
          `${config.env.API_DEFAULT.host}/finances/moviments/appointments/importProcess/${this.process.id}`
        );
        this.movimentAppointments = response1.data;
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        this.$swal("Ops", err, "error");
        console.error(err);
      }
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),

    inputsTotal() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    outputsTotal() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.estimatedValue;
        return value + 0;
      }, 0);
    },
    balance() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.estimatedValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.estimatedValue;
        return value + 0;
      }, 0);
    },
    inputsTotalRealized() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    outputsTotalRealized() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value + item.paidValue;
        return value + 0;
      }, 0);
    },
    balanceRealized() {
      return this.data.reduce((value, item) => {
        if (item.typeOfMovimentation === "PROCESS_INCOME")
          return value + item.paidValue;
        if (item.typeOfMovimentation === "PROCESS_EXPENSE")
          return value - item.paidValue;
        return value + 0;
      }, 0);
    },
  },
  async created() {
    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde",
      description: "Carregando dados da página.",
    });

    this.loadData();
  },
  watch: {
    process() {
      this.model = { ...this.process };
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
