<template>
  <div class="modal fade effect-slide-in-right show z-index-100">
    <div
      v-if="show"
      class="modal-dialog modal-dialog-vertical-center"
      role="document"
    >
      <div class="modal-content bd-0 tx-14">
        <div class="modal-header pd-y-20 pd-x-25">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            <slot name="header"></slot>
          </h6>
          <button
            type="button"
            @click="$emit('close')"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-primary">Salvar</button>
            <button type="button" class="btn btn-teal" data-dismiss="modal">
              Fechar
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DrawerComponent",
  data() {
    return {
      show: false,
    };
  },

  methods: {
    submit() {},
  },
  computed: {},
  mounted() {
    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  z-index: 99999 !important;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    min-width: 30vw;
    max-width: initial;
    height: 100vh;
    z-index: 99999 !important;

    .modal-content {
      height: 100vh;
    }

    .modal-body {
      max-height: calc(100vh - 57px - 74px);
    }

    .modal-footer {
      z-index: 999;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s, opacity 0.5s !important;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(50vw) !important;
  opacity: 0 !important;
}
</style>
