export default class InvoiceItem {
  constructor(
    id,
    index,
    description,
    observation,
    value,
    invoice,
    invoicetype,
  ) {
    this.id = id;
    this.index = index;
    this.description = description;
    this.observation = observation;
    this.value = value;
    this.invoice = invoice;
    this.invoicetype = invoicetype;
  }
}
