<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_441_755)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.9456 0C23.6314 0 25 1.36865 25 3.05439V21.9456C25 23.6313 23.6313 25 21.9456 25H16.747V15.5803H19.9989L20.6177 11.5461H16.747V8.92808C16.747 7.82441 17.2876 6.74858 19.0214 6.74858H20.7812V3.31401C20.7812 3.31401 19.1841 3.04146 17.6571 3.04146C14.4692 3.04146 12.3856 4.97354 12.3856 8.47134V11.5461H8.84204V15.5803H12.3856V25H3.05439C1.36865 25 0 23.6313 0 21.9456V3.05439C0 1.36865 1.3686 0 3.05439 0H21.9456V0Z"
        fill="#104375"
      />
    </g>
    <defs>
      <clipPath id="clip0_441_755">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
