<template>
  <svg :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.60824 5.37084L5.37081 6.60827C5.143 6.83608 5.143 7.20543 5.37081 7.43323L6.60824 8.67067C6.83605 8.89847 7.2054 8.89847 7.4332 8.67067L8.67064 7.43323C8.89845 7.20543 8.89845 6.83608 8.67064 6.60827L7.4332 5.37084C7.2054 5.14303 6.83605 5.14303 6.60824 5.37084Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.7017 4.66667C10.8426 2.93799 9.05875 1.75 6.99743 1.75C4.93607 1.75 3.1924 2.93799 2.33333 4.66667"
          stroke="currentColor" stroke-linecap="round"/>
    <path d="M2.33333 2.33325V4.66659" stroke="currentColor" stroke-linecap="round"/>
    <path d="M4.31092 4.66675H2.33337" stroke="currentColor" stroke-linecap="round"/>
    <path
        d="M2.33333 9.33325C3.1924 11.0619 4.97627 12.2499 7.03762 12.2499C9.09895 12.2499 10.8426 11.0619 11.7017 9.33325"
        stroke="currentColor" stroke-linecap="round"/>
    <path d="M11.7017 11.6666V9.33325" stroke="currentColor" stroke-linecap="round"/>
    <path d="M9.72411 9.33325H11.7017" stroke="currentColor" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'RefreshIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
