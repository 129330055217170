<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9375 19.7917C15.8275 19.7917 19.7916 15.8275 19.7916 10.9375C19.7916 6.0475 15.8275 2.08333 10.9375 2.08333C6.04748 2.08333 2.08331 6.0475 2.08331 10.9375C2.08331 15.8275 6.04748 19.7917 10.9375 19.7917Z"
      stroke="#868BA1"
      stroke-width="1.875"
      stroke-linejoin="round"
    />
    <path
      d="M13.8838 7.47036C13.1298 6.71635 12.0881 6.25 10.9375 6.25C9.78694 6.25 8.74527 6.71635 7.99121 7.47036"
      stroke="#868BA1"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.303 17.303L21.7225 21.7225"
      stroke="#868BA1"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style>
</style>
