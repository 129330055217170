<template>
  <Drawer @close="$emit('closeDrawer')">
    <template slot="header">Alteração de Status</template>

    <template slot="body">
      <div class="card card-activities">
        <h6 class="slim-card-title">Logs de Alteração de Status</h6>
        <p>Foram encontrados {{ listOfLog.length }} logs de status</p>

        <perfect-scrollbar class="operational-form-sidebar-timeline">
          <div class="post-item" v-for="log in listOfLog" :key="log.id">
            <hr />
            <span class="post-date"
              >Enviado em
              {{ log.created | moment("DD \\d\\e MMMM \\d\\e YYYY") }} às
              {{ log.created | moment("H:mm:ss") }}</span
            >
            <p class="post-title tx-16" v-html="log.description"></p>
            <p class="tx-12 mg-b-1">
              Alterado por <strong> {{ log.createdBy }} </strong> na etapa
              <strong>{{ log.stepDescription }}</strong>
            </p>
          </div>
        </perfect-scrollbar>
      </div>
    </template>

    <template slot="footer">
      <button
        type="button"
        @click="$emit('closeDrawer')"
        class="btn btn-primary"
      >
        Fechar
      </button>
    </template>
  </Drawer>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import { mapState } from "vuex";
import Drawer from "@/components/Drawer.vue";
import { EventBus } from "@/events/EventBus.js";

export default {
  data() {
    return {
      listOfLog: [],
    };
  },
  components: {
    Drawer,
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
  },
  watch: {
    process() {
      this.model = {
        ...this.process,
      };

      this.load();
    },
  },
  created() {
    this.model = {
      ...this.process,
    };

    EventBus.$on("Operational.imp.drawers.FollowUPLogs.load", () => {
      this.load();
    });
  },
  methods: {
    async load() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Logs de Alteração de Status",
        description: "Carregando...",
      });

      if (this.process.id !== null && this.process.id !== undefined) {
        await Axios.get(
          `${config.env.API_DEFAULT.host}/history/process/${this.process.id}/status-changes`
        )
          .then((response) => {
            this.listOfLog = response.data.data;
          })
          .catch((error) => {
            Swal.fire(
              "Log de Follow UPs",
              "Não foi possível carregar os Logs de alteração de Status",
              "error"
            );
          });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
  },
  mounted() {
    if (this.process.id !== null && this.process.id !== undefined) {
      this.load();
    }
  },
};
</script>

<style scoped>
.imp-form-drawers-followups-logs-scrollbar {
  height: 70vh;
  overflow: auto;
}

.operational-form-sidebar-timeline {
  height: 100vh;
  overflow: auto;
}
</style>
