<template>
  <div class="fab-menu">
    <transition name="fabmenu">
      <ul v-if="opened" class="fab-menu-list">
        <li>
          <router-link :to="{ name: 'companies-list', params: { type: 'isCustomer' } }">Listagem de Clientes</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'operational-imp-desk' }">Telão de Importação</router-link>
        </li>
        <li>
          <FabModal title="Indicadores Operacionais">
            <template #activator="{ on }">
              <a href="#" @click.prevent="on">Indicadores Operacionais</a>
            </template>

            <template #content>
              <ControlPanel :modal="true" />
            </template>
          </FabModal>
        </li>
      </ul>
    </transition>
    <button type="button" @click.prevent="opened = !opened" class="fab-button">
      <svg v-if="!opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
    </button>
  </div>
</template>

<script>
import FabModal from '@/components/FabModal.vue';
import ControlPanel from '@/views/operational/control-panel/ControlPanel.vue';

export default {
  name: 'FabMenu',
  components: {
    FabModal,
    ControlPanel,
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    requestClose(e) {
      if (e.keyCode === 27) {
        this.opened = false;
      }
    },
    clickOut(e) {
      if (!e.target.closest('.fab-menu')) {
        this.opened = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.clickOut);
    window.addEventListener('keydown', this.requestClose);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOut);
    window.removeEventListener('keydown', this.requestClose);
  },
};
</script>

<style>
  /* Transition */
  .fabmenu-enter-active,
  .fabmenu-leave-active {
    transition: all .2s ease-in-out 0s;
  }

  .fabmenu-enter,
  .fabmenu-leave-to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  .fabmenu-enter-to,
  .fabmenu-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  /* Transition */

  .fab-menu {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
  }

  .fab-menu-list {
    position: absolute;
    bottom: calc(100% + 20px);
    right: 0;
    min-width: 200px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .fab-menu-list > li:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .fab-menu-list > li {
    display: flex;
    justify-content: flex-end;
  }

  .fab-menu-list > li > a,
  .fab-menu-list > li > div > a {
    display: block;
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
    transition: all .2s ease-in-out 0s;
    white-space: nowrap;
  }

  .fab-menu-list > li > a:hover {
    background-color: #1b84e7;
    color: white;
  }

  .fab-button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #1b84e7;
    color: white;
    border: 0;
    cursor: pointer;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
  }
</style>
