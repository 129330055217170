<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="tx-xs-20 tx-inverse mg-b-5">SALVAR ESTADO DO TELÃO</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="$emit('close')"
            >
              <span aria-hidden="true"> X </span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-layout mg-x-20 mg-t-20">
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label"
                      >Nome / Descrição <span class="tx-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      type="text"
                      v-model="model.name"
                      name="name"
                      v-validate="'required'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-layout-footer bd pd-y-20 pd-x-40 bd-t-0">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-3">
                  <button
                    class="btn btn-sm btn-primary btn-block"
                    @click.prevent="submit()"
                  >
                    <i class="fa fa-edit mg-r-5"></i> SALVAR
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn btn-sm btn-danger btn-block"
                    @click.prevent="$emit('close')"
                  >
                    <i class="fa fa-reply mg-r-5"></i> VOLTAR
                  </button>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
import { EventBus } from "@/events/EventBus.js";
import config from "@/config.js";
import Swal from "sweetalert2";

export default {
  name: "operational-importation-desk-state-modal",
  props: ["statusFilters", "filter", "finish", "company", "columns"],
  components: {},
  watch: {
    modelUpdate() {
      this.model = this.modelUpdate;
    },
  },

  data() {
    return {
      oldModel: null,
      model: {},
    };
  },

  methods: {
    isNewRecord() {
      if (this.model.id !== undefined && this.model.id !== null) return false;
      return true;
    },
    submit() {
      if (
        !this.model.name ||
        this.model.name === null ||
        this.model.name === ""
      ) {
        this.$emit("finish");
        this.$emit("close");
        Swal.fire(
          "Estado da Mesa",
          "Para você salvar o estado atual da mesa, você precisa preencher o campo Nome / Descrição.",
          "error"
        );
      } else {
        this.save();
      }
    },
    async save() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Estado da Mesa",
          description: "Salvando estado do telão...",
        });

        // this.model.owner = this.$store.state.credentials.data.person.href;
        this.model.processType = "IMPORT";
        this.model.steps = JSON.stringify(this.statusFilters);
        this.model.columns = JSON.stringify(this.columns);
        this.model.filter = JSON.stringify(this.filter);

        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/desk/states`,
          this.model
        );

        EventBus.$emit("operational.imp.Desk.loadDeskStates");

        this.$emit("finish");
        this.$emit("close");

        if (response) {
          Swal.fire(
            "Estado da Mesa",
            `O estado atual da mesa foi salvo com sucesso com o nome de ${this.model.name}.`,
            "success"
          );
          this.model = {};
        } else {
          Swal.fire("Ops", "Não foi possível salvar.", "error");
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (error) {
        this.$store.commit("setLoading", {
          show: false,
        });
        Swal.fire("Ops", error.response, "error");
      }
    },
  },
};
</script>

<style>
@import "./../../../assets/css/modal.css";

.ql-editor {
  color: #000000;
  font-size: 12px;
}

.ql-toolbar.ql-snow {
  border-top: none;
}

.ql-container {
  height: 200px !important;
}
</style>
