<template>
  <form
    id="di-adicao-form-mercadoria-form"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2">
      <div class="row no-gutters">
        <div class="col-lg-3">
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"> NCM (Mercadoria) </label>
                <div class="input-group-solved">
                  <div class="input-group-solved-before">
                    <i class="fa fa-search op-8"></i>
                  </div>
                  <div class="input-group-solved-after mg-l-10 wd-100">
                    <the-mask
                      mask="####.##.##"
                      class="form-control"
                      type="text"
                      v-model="model.codigoMercadoriaNCM"
                      name="codigoMercadoriaNCM"
                      :disabled="disableFields"
                    />
                  </div>
                  <div class="input-group-solved-after wd-150">
                    <a
                      href="#"
                      @click.prevent="buscarNCM()"
                      class="float-right"
                      v-show="
                        this.activeAdicao.codigoMercadoriaNCM !== undefined &&
                        this.activeAdicao.codigoMercadoriaNCM !== null &&
                        this.activeAdicao.codigoMercadoriaNCM !== ''
                      "
                    >
                      <i class="icon fa fa-search tx-22 mg-r-5"></i> BUSCAR
                      DADOS
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Descrição da NCM </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p tx-12"
                  type="text"
                  v-model="model.descricaoMercadoriaNCM"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> NALADI/SH </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.codigoMercadoriaNaladiSH"
                  name="codigoMercadoriaNaladiSH"
                  :disabled="disableFields"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> NALADI/NCC </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="model.codigoMercadoriaNaladiNCC"
                  name="codigoMercadoriaNaladiNCC"
                  :disabled="disableFields"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"> Alíquota II </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p"
                  type="text"
                  v-model="model.aliquotaii"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Alíquota IPI </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p"
                  type="text"
                  v-model="model.aliquotaipi"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> PIS/PASEP </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p"
                  type="text"
                  v-model="model.aliquotapis"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> COFINS </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p"
                  type="text"
                  v-model="model.aliquotacofins"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"> Unidade de Medida </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600"
                  type="text"
                  v-model="model.descricaoUnidadeMedidaEstatistica"
                  name="descricaoUnidadeMedidaEstatistica"
                  disabled
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Quantidade </label>
                <money
                  v-model="model.quantidadeUnidadeEstatistica"
                  name="quantidadeUnidadeEstatistica"
                  v-bind="decimal5"
                  class="form-control"
                  :disabled="disableFields"
                ></money>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Peso Líquido (KG) </label>
                <money
                  v-model="model.pesoLiquidoMercadoria"
                  name="pesoLiquidoMercadoria"
                  v-bind="kg"
                  class="form-control"
                  :disabled="disableFields"
                ></money>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Moeda Negociada </label>
                <input
                  class="pd-0 mg-0 bd-0 tx-gray-600 wd-100p"
                  name="cdMoedaNegociada"
                  type="text"
                  v-model="model.cdMoedaNegociada"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"> Aplicação </label>
                <div class="row">
                  <div class="col-lg-6 mg-b-0">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        name="codigoAplicacaoMercadoria"
                        type="radio"
                        v-model="model.codigoAplicacaoMercadoria"
                        :value="1"
                        v-validate="'required'"
                      />
                      <span> Consumo </span>
                    </label>
                  </div>
                  <div class="col-lg-6 mg-b-0">
                    <label class="rdiobox rdiobox-primary mg-t-0-force">
                      <input
                        name="codigoAplicacaoMercadoria"
                        type="radio"
                        v-model="model.codigoAplicacaoMercadoria"
                        :value="2"
                        v-validate="'required'"
                      />
                      <span> Revenda </span>
                    </label>
                  </div>

                  <span
                    v-show="errors.has('codigoAplicacaoMercadoria')"
                    class="parsley-errors-list filled"
                    >Selecione uma Aplicação</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Bem sob Encomenda? </label>
                <div class="row">
                  <div class="col-lg-5 mg-b-0">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        name="indicadorBemEncomenda"
                        type="radio"
                        v-model="model.indicadorBemEncomenda"
                        :value="'S'"
                        :disabled="disableFields"
                      />
                      <span> Sim </span>
                    </label>
                  </div>
                  <div class="col-lg-5 mg-b-0">
                    <label class="rdiobox rdiobox-danger mg-t-0-force">
                      <input
                        name="indicadorBemEncomenda"
                        type="radio"
                        v-model="model.indicadorBemEncomenda"
                        :value="'N'"
                        :disabled="disableFields"
                      />
                      <span> Não </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Material Usado </label>
                <div class="row">
                  <div class="col-lg-5 mg-b-0">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        name="indicadorMaterialUsado"
                        type="radio"
                        v-model="model.indicadorMaterialUsado"
                        :value="'S'"
                        :disabled="disableFields"
                      />
                      <span> Sim </span>
                    </label>
                  </div>
                  <div class="col-lg-5 mg-b-0">
                    <label class="rdiobox rdiobox-danger mg-t-0-force">
                      <input
                        name="indicadorMaterialUsado"
                        type="radio"
                        v-model="model.indicadorMaterialUsado"
                        :value="'N'"
                        :disabled="disableFields"
                      />
                      <span> Não </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 bg-gray-200 tx-black">
              <div class="form-group bd-t-0-force bg-gray-200">
                <label class="form-control-label">
                  Câmbio Valor Mercadoria Local de Embarque
                </label>
                <money
                  v-model="di.valorTotalDespesasMoedaNacionalTaxaConversao"
                  name="valorTotalDespesasMoedaNacionalTaxaConversao"
                  v-bind="brl"
                  class="form-control"
                  :disabled="disableFields"
                ></money>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="report-summary-header mg-t-20 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">Cadastro de Itens da Adição</h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Descrição
            detalhada das mercadorias / operação comercial por itens.
          </p>
        </div>
        <div>
          <div class="d-h-t-right">
            <div class="summary-item">
              <h1>
                <template
                  v-if="
                    di.codigoMoedaLocalEmbarque !== null &&
                    di.codigoMoedaLocalEmbarque !== undefined
                  "
                >
                  {{ di.codigoMoedaLocalEmbarque.symbol }}
                </template>
                {{
                  model.valorMercadoriaCondicaoVenda
                    | numeralFormat("000,00.00")
                }}
              </h1>
            </div>
            <div class="summary-item">
              <template
                v-if="
                  di.valorTotalDespesasMoedaNacionalTaxaConversao !== null &&
                  di.valorTotalDespesasMoedaNacionalTaxaConversao !== undefined
                "
              >
                <h1>
                  R$
                  {{
                    (model.valorMercadoriaCondicaoVenda *
                      di.valorTotalDespesasMoedaNacionalTaxaConversao)
                      | numeralFormat("000,00.00")
                  }}
                </h1>
              </template>
              <span>Valor Total na <br />Condição de Venda</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <table class="table mg-b-0 bd">
          <thead class="thead-colored bg-primary">
            <tr>
              <!-- <th class="tx-center"> Número do Item </th> -->
              <th class="tx-center">Nº</th>
              <!-- <th class="tx-center"> Item PO </th> -->
              <th class="tx-center">Moeda</th>
              <th class="tx-center">Unidade</th>
              <th class="tx-center">Quantidade</th>
              <th class="tx-center">Valor Unitário</th>
              <th class="tx-center">Total</th>
              <th class="tx-center wd-500">Descrição</th>
              <th class="tx-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="
                this.model.mercadoria !== null &&
                this.model.mercadoria !== undefined &&
                this.model.mercadoria.length > 0
              "
            >
              <tr-inline
                v-for="item in this.model.mercadoria"
                :key="item.id"
                :row="item"
                @save="updateMercadoria"
              >
                <td-inline
                  placeholder="Nº"
                  type="string"
                  :class="'tx-center'"
                  v-model="item.numeroItem"
                />
                <!-- <td-inline placeholder="Item PO" type="string" :class="'tx-center'" v-model="item.numeroItemIntegracao" /> -->
                <td-inline
                  placeholder="Moeda"
                  type="string"
                  v-model="item.moeda"
                />
                <td-inline
                  placeholder="Unidade Comercializada"
                  type="string"
                  v-model="item.nomeUnidadeMedidaComercializada"
                />
                <td-inline
                  placeholder="Quantidade"
                  type="money|#####"
                  v-model="item.quantidadeMercadoriaUnidadeComercializada"
                />
                <td-inline
                  placeholder="Valor Unitário Condição Venda"
                  type="money|#######"
                  v-model="item.valorUnidadeMedidaCondicaoVenda"
                />
                <td class="tx-right" style="vertical-align: middle">
                  {{
                    (new Number(
                      item.quantidadeMercadoriaUnidadeComercializada
                    ) *
                      new Number(item.valorUnidadeMedidaCondicaoVenda))
                      | numeralFormat("0,0.00000")
                  }}
                </td>
                <td-inline
                  placeholder="Descrição da Mercadoria"
                  type="textarea"
                  v-model="item.textoDetalhamentoMercadoria"
                />
                <template slot="actions" v-if="!disableFields">
                  <a
                    href="#"
                    @click.prevent="removeMercadoria(item)"
                    class="btn btn-danger btn-icon rounded-circle"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </template>
              </tr-inline>
            </template>

            <tr-inline
              class="new-item"
              :isNew="true"
              :row="newMercadoria"
              @save="createMercadoria"
              v-if="!disableFields"
            >
              <!-- <td-inline placeholder="Número" :class="'tx-center'" type="string"  v-model="newMercadoria.numeroItem" /> -->
              <td-inline
                placeholder="Nº"
                type="string"
                :class="'tx-center'"
                v-model="newMercadoria.numeroItem"
              />
              <!-- <td-inline placeholder="Item PO" type="string" :class="'tx-center'" v-model="newMercadoria.numeroItemIntegracao" /> -->
              <td-inline
                placeholder="Moeda"
                type="string"
                v-model="newMercadoria.moeda"
              />
              <td-inline
                placeholder="Unidade Comercializada"
                type="string"
                v-model="newMercadoria.nomeUnidadeMedidaComercializada"
              />
              <td-inline
                placeholder="Quantidade"
                type="money|#####"
                v-model="
                  newMercadoria.quantidadeMercadoriaUnidadeComercializada
                "
              />
              <td-inline
                placeholder="Valor Unitário Condição Venda"
                type="money|#######"
                v-model="newMercadoria.valorUnidadeMedidaCondicaoVenda"
              />
              <td></td>
              <td-inline
                placeholder="Descrição da Mercadoria"
                type="textarea"
                v-model="newMercadoria.textoDetalhamentoMercadoria"
              />
            </tr-inline>
          </tbody>
          <tfoot>
            <tr>
              <th class="tx-right"></th>
              <th class="tx-right"></th>
              <th class="tx-right"></th>
              <th class="tx-right">
                {{
                  new Number(totalQuantidadeMercadoriaUnidadeComercializada)
                    | numeralFormat("0,0.0000000")
                }}
              </th>
              <th class="tx-right"></th>
              <th class="tx-right">
                {{
                  new Number(model.valorMercadoriaCondicaoVenda)
                    | numeralFormat("0,0.0000000")
                }}
              </th>
              <th class="tx-right" colspan="2"></th>
            </tr>
          </tfoot>
        </table>
      </div>

      <hr />

      <div class="row">
        <div
          class="col-md-12"
          v-if="
            (this.nvesSelecao !== undefined &&
              this.nvesSelecao !== null &&
              this.nvesSelecao.length > 0) ||
            (this.model.valoracaoAduaneira !== undefined &&
              this.model.valoracaoAduaneira !== null &&
              this.model.valoracaoAduaneira.length > 0)
          "
        >
          <label class="section-title mg-t-20">
            Nomenclatura de Valor Aduaneiro e Estatística
          </label>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored bg-teal">
              <tr>
                <th class="tx-center">Nível</th>
                <th class="tx-center">Atributo</th>
                <th class="tx-center">Especificação</th>
                <th class="tx-center wd-100">Ações</th>
              </tr>
            </thead>
            <tbody>
              <!-- <template v-if="model.valoracaoAduaneira !== undefined && model.valoracaoAduaneira.length > 0 "> -->
              <template
                v-if="
                  model.valoracaoAduaneira !== null &&
                  model.valoracaoAduaneira.length > 0
                "
              >
                <tr
                  v-for="item in model.valoracaoAduaneira"
                  :key="item.id"
                  :row="item"
                >
                  <td>{{ item.codigoAbrangenciaNCM }}</td>
                  <td>{{ item.codigoAtributoNCM }}</td>
                  <td>{{ item.codigoEspecificacaoNCM }}</td>
                  <td>
                    <a
                      href="#"
                      @click.prevent="removeNVE(item)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div><i class="icon ion-trash-a"></i></div>
                    </a>
                  </td>
                </tr>
              </template>

              <!-- <template v-if="model.valoracaoAduaneira.length > 0">
                            <tr v-for="item in model.valoracaoAduaneira" :key="item.id" :row="item">
                                <td>{{item.codigoAbrangenciaNCM}} </td>
                                <td>{{item.codigoAtributoNCM}} </td>
                                <td>{{item.codigoEspecificacaoNCM}} </td>
                                <template slot="actions">
                                    <a href="#" @click.prevent="removeNVE(item)" class="btn btn-danger btn-icon rounded-circle">
                                        <div><i class="icon ion-trash-a"></i></div>
                                    </a>
                                </template>
                            </tr>
                        </template> -->

              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newNVE"
                @save="createNVE"
              >
                <td-inline
                  placeholder="Selecione um nível"
                  v-model="newNVE.codigoAbrangenciaNCM"
                  @change="selecaoCodigoAbrangenciaNCM(newNVE)"
                  type="select"
                  selectType="key|value"
                  :options="nvesNiveis"
                />
                <td-inline
                  placeholder="Selecione um atributo"
                  v-model="newNVE.codigoAtributoNCM"
                  @change="selecaoCodigoAtributoNCM(newNVE)"
                  type="select"
                  selectType="key|value"
                  :options="nvesNiveisAtributos"
                />
                <td-inline
                  placeholder="Selecione uma especificação"
                  v-model="newNVE.codigoEspecificacaoNCM"
                  type="select"
                  selectType="key|value"
                  :options="nvesNiveisEspecificacoes"
                />
              </tr-inline>
            </tbody>
          </table>
        </div>

        <div class="col-md-6">
          <label class="section-title mg-t-20"> DESTAQUE NCM </label>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored bg-danger">
              <tr>
                <th class="tx-center">Número do Destaque</th>
                <th class="tx-center wd-100">Ações</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-if="
                  model.destaque !== null &&
                  model.destaque !== undefined &&
                  model.destaque.length > 0
                "
              >
                <tr-inline
                  v-for="item in model.destaque"
                  :key="item.id"
                  :row="item"
                  @save="updateDestaque"
                >
                  <td-inline
                    placeholder="Número do Destaque"
                    v-model="item.numeroDestaqueNCM"
                    :class="'tx-center'"
                  />

                  <template slot="actions">
                    <a
                      href="#"
                      @click.prevent="removeDestaque(item)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div><i class="icon ion-trash-a"></i></div>
                    </a>
                  </template>
                </tr-inline>
              </template>

              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newDestaque"
                @save="createDestaque"
              >
                <td-inline
                  placeholder="Número do Destaque"
                  v-model="newDestaque.numeroDestaqueNCM"
                  :class="'tx-center'"
                />
              </tr-inline>
            </tbody>
          </table>
        </div>

        <div class="col-md-6">
          <label class="section-title mg-t-20"> DOCUMENTO VINCULADO </label>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored bg-primary">
              <tr>
                <th class="tx-center">Tipo</th>
                <th class="tx-center">Identificação</th>
                <th class="tx-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-if="
                  model.documento !== null &&
                  model.documento !== undefined &&
                  model.documento.length
                "
              >
                <tr-inline
                  v-for="item in model.documento"
                  :key="item.id"
                  :row="item"
                  @save="updateDocumento"
                >
                  <td-inline
                    placeholder="Selecione um tipo"
                    v-model="item.codigoTipoDocumentoVinculado"
                    type="select"
                    selectType="key|value"
                    :options="codigosTipoDocumentVinculado"
                  />
                  <td-inline
                    placeholder="Número"
                    v-model="item.numeroDocumentoVinculado"
                  />

                  <template slot="actions">
                    <a
                      v-if="this.di.numeroRegistro === null"
                      href="#"
                      @click.prevent="removeDocumento(item)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div><i class="icon ion-trash-a"></i></div>
                    </a>
                  </template>
                </tr-inline>
              </template>

              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newDocumento"
                @save="createDocumento"
              >
                <td-inline
                  placeholder="Selecione um tipo"
                  v-model="newDocumento.codigoTipoDocumentoVinculado"
                  type="select"
                  selectType="key|value"
                  :options="codigosTipoDocumentVinculado"
                />
                <td-inline
                  placeholder="Número"
                  v-model="newDocumento.numeroDocumentoVinculado"
                />
              </tr-inline>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import { mapState } from "vuex";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

import Swal from "sweetalert2";

export default {
  name: "di-adicao-form-mercadoria",
  props: ["noClick"],
  components: {
    TheMask,
    Money,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      initialDI: {},
      di: {},
      initialModel: {},
      model: {},
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      kg: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
        suffix: " KGs",
      },
      brl: {
        decimal: ",",
        thousands: ".",
        precision: 7,
        masked: false,
        prefix: "R$ ",
      },
      decimal5: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
      },
      nvesSelecao: [],
      nvesNiveisAtributos: [],
      nvesNiveisEspecificacoes: [],
      newMercadoria: {
        id: Math.floor(Math.random() * new Date().valueOf()),
        numeroItem: 0,
        numeroItemIntegracao: "",
        nomeUnidadeMedidaComercializada: "",
        quantidadeMercadoriaUnidadeComercializada: 0.0,
        textoDetalhamentoMercadoria: "",
        valorUnidadeLocalEmbarque: 0.0,
        valorUnidadeMedidaCondicaoVenda: 0.0,
        moeda: "",
      },
      newDestaque: {
        numeroDestaqueNCM: "",
      },
      newDocumento: {
        codigoTipoDocumentoVinculado: "",
        numeroDocumentoVinculado: "",
      },
      newNVE: {
        codigoAbrangenciaNCM: "",
        codigoAtributoNCM: "",
        codigoEspecificacaoNCM: "",
      },
      codigosPaises: [...importDeclarationLists.codigosPaises],
      codigosMetodoValoracao: [
        ...importDeclarationLists.codigosMetodoValoracao,
      ],
      codigosTipoDocumentVinculado: [
        {
          key: "2",
          value: "DI",
        },
        {
          key: "3",
          value: "RE",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      return true;
    },

    async consultarDadosNCM() {
      const self = this;
      setTimeout(() => {
        new Promise((resolve, reject) =>
          this.$store.dispatch(
            "MyIMPStore/consultarDadosMercadoria",
            self.model.codigoMercadoriaNCM
          )
        ).then((response) => {
          if (
            response.nves !== null &&
            response.nves !== undefined &&
            response.nves.length > 0
          ) {
            // self.nvesSelecao = response.nves;
            return true;
          }
        });
      }, 1000);
    },
    buscarNCM() {
      if (
        this.di.numeroRegistro === null ||
        this.di.numeroRegistro === undefined
      ) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Buscar Dados",
          description: "Processando a busca de dados da NCM da adição...",
        });
        setTimeout(() => {
          this.model.descricaoMercadoriaNCM = null;
          this.model.aliquotaii = null;
          this.model.aliquotaipi = null;
          this.model.aliquotapis = null;
          this.model.aliquotacofins = null;
          this.model.descricaoUnidadeMedidaEstatistica = null;

          new Promise((resolve) => setTimeout(resolve, 1000))
            .then(() =>
              this.$store.dispatch(
                "MyIMPStore/consultarDadosMercadoria",
                this.model.codigoMercadoriaNCM
              )
            )
            .then((response) => {
              if (response) {
                const ii = parseFloat(
                  response.aliquotaAdValoremIIPercentualAdValorem
                ).toFixed(2);
                const ipi = parseFloat(
                  response.aliquotaAdValoremIPIPercentualAdValorem
                ).toFixed(2);
                const pis = parseFloat(
                  response.aliquotaAdValoremPisPercentualAdValorem
                ).toFixed(2);
                const cofins = parseFloat(
                  response.aliquotaAdValoremCofinsPercentualAdValorem
                ).toFixed(2);

                this.model.descricaoMercadoriaNCM = response.descricao;
                this.model.aliquotaii = ii;
                this.model.aliquotaipi = ipi;
                this.model.aliquotapis = pis;
                this.model.aliquotacofins = cofins;
                this.model.descricaoUnidadeMedidaEstatistica =
                  response.unidadeMedidaMercosul.descricao;
                this.model.adValorem = ii;
                this.model.adValoremIpi = ipi;
                this.model.aliquotaAdValoremPIS = pis;
                this.model.aliquotaAdValoremCOFINS = cofins;

                if (
                  ii > 0 &&
                  (this.model.codRegTributacaoII === null ||
                    !this.model.codRegTributacaoII ||
                    this.model.codRegTributacaoII === "")
                ) {
                  this.model.codRegTributacaoII = {
                    key: "1",
                    value: "1 - RECOLHIMENTO INTEGRAL",
                  };
                }

                if (
                  ipi > 0 &&
                  (this.model.tipoRegTributacao === null ||
                    !this.model.tipoRegTributacao ||
                    this.model.tipoRegTributacao === "")
                ) {
                  this.model.tipoRegTributacao = {
                    key: "4",
                    value: "4 - Recolhimento Integral",
                  };
                }

                if (
                  pis > 0 &&
                  (this.model.codRegTributacaoPIS === null ||
                    !this.model.codRegTributacaoPIS ||
                    this.model.codRegTributacaoPIS === "")
                ) {
                  this.model.codRegTributacaoPIS = {
                    key: "1",
                    value: "1 - RECOLHIMENTO INTEGRAL",
                  };
                }

                if (
                  response.nves !== null &&
                  response.nves !== undefined &&
                  response.nves.length > 0
                ) {
                  this.nvesSelecao = response.nves;
                }

                let table =
                  `${
                    '<table class="wd-100p table mg-b-0">' +
                    "<tbody>" +
                    "<tr>" +
                    '<td class="tx-left tx-bold"> II </td> <td>'
                  }${this.model.aliquotaii} % </td>` +
                  `<td class="tx-left tx-bold"> IPI </td> <td>${this.model.aliquotaipi} % </td>` +
                  "</tr>" +
                  "<tr>" +
                  `<td class="tx-left tx-bold"> PIS </td> <td>${this.model.aliquotapis} % </td>` +
                  `<td class="tx-left tx-bold"> COFINS </td> <td>${this.model.aliquotacofins} % </td>` +
                  "</tr>";

                if (
                  response.nves !== null &&
                  response.nves !== undefined &&
                  response.nves.length > 0
                ) {
                  table +=
                    "<tr>" +
                    '<td class="tx-left tx-bold"> NVE </td> <td class="tx-danger tx-bold"> SIM </td><td colspan="2"></td>' +
                    "</tr>";
                } else {
                  table +=
                    "<tr>" +
                    '<td class="tx-left tx-bold"> NVE </td> <td class="tx-danger tx-success"> NÃO </td><td colspan="2"></td>' +
                    "</tr>";
                }

                table += "</tbody>" + "</table>";

                Swal.fire({
                  title: "NCM",
                  html: `Os dados da mercadoria <strong>${this.model.codigoMercadoriaNCM} - ${this.model.descricaoMercadoriaNCM}</strong> foram carregados com sucesso. Veja abaixos as informações: <br /> <br />${table}`,
                  type: "success",
                });

                return true;
              }
              Swal.fire({
                title: "NCM",
                html: `Nenhuma mercadoria foi encontrada com o NCM <strong>${this.model.codigoMercadoriaNCM}</strong> em nossa base de dados e na do Siscomex.`,
                type: "error",
              });

              return false;
            })
            .then((response) => {
              if (response) {
                return this.$store.commit(
                  "MyIMPStore/SET_ACTIVE_ADICAO",
                  this.model
                );
              }
              return false;
            })
            .then((response) => {
              if (response) {
                return this.$store.dispatch("MyIMPStore/diffObjectsAdicao");
              }
              return false;
            })
            .then((response) => {
              if (response) {
                this.initialDI = {
                  ...this.di,
                };
              }
            });
        }, 1000);
      }
    },
    async updateMercadoria(value) {
      this.submitMercadoria();
    },
    async createMercadoria(value) {
      value.numeroItem = (this.model.mercadoria.length + 1).toString();
      if (value.numeroItem.length == 1) {
        value.numeroItem = `00${value.numeroItem.toString()}`;
      } else if (value.numeroItem.length == 2) {
        value.numeroItem = `0${value.numeroItem.toString()}`;
      }

      this.model.mercadoria.push(value);
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      this.submitMercadoria();
    },
    async removeMercadoria(value) {
      Swal.fire({
        title: "Excluir Mercadoria",
        html: `Você realmente deseja excluir a mercadoria <strong>${value.textoDetalhamentoMercadoria}</strong>? Se você excluir, precisará adicioná-la novamente na adição.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.mercadoria.findIndex(
            (item) => item.numeroItem === value.numeroItem
          );

          if (index !== "" && index !== null) {
            this.model.mercadoria.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
            this.submitMercadoria();
            Swal.fire(
              "Excluir Mercadoria",
              ` A mercadoria <strong>${value.textoDetalhamentoMercadoria}</strong> foi excluída com sucesso da lista de mercadorias.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Mercadoria",
              ` A mercadoria <strong>${value.textoDetalhamentoMercadoria}</strong> não foi excluída da lista de mercadorias.`,
              "error"
            );
          }
        }
      });
    },

    async submitMercadoria() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: this.di,
        newModel: this.di,
        fieldName: null,
      });

      if (response) {
        this.calcularValorMercadoriaCondicaoVenda();

        Swal.fire(
          "Mercadoria",
          "A mercadoria foi adicionada com sucesso na lista de mercadorias.",
          "success"
        );

        this.newMercadoria = {
          id: Math.floor(Math.random() * new Date().valueOf()),
          numeroItem: 0,
          numeroItemIntegracao: "",
          nomeUnidadeMedidaComercializada: "",
          quantidadeMercadoriaUnidadeComercializada: 0,
          textoDetalhamentoMercadoria: "",
          valorUnidadeLocalEmbarque: 0.0,
          valorUnidadeMedidaCondicaoVenda: 0.0,
          moeda: "",
        };
      }
    },
    async updateDestaque(value) {
      this.submitDestaque();
    },
    async createDestaque(value) {
      if (this.model.destaque === null) this.model.destaque = [];
      this.model.destaque.push(value);
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      this.submitDestaque();
    },
    async removeDestaque(value) {
      Swal.fire({
        title: "Excluir Destaque NCM",
        html: `Você realmente deseja excluir o Destaque NCM <strong>${value.numeroDestaqueNCM}</strong>? Se você excluir, precisará adicioná-lo novamente na adição.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.destaque.findIndex(
            (item) => item.numeroDestaqueNCM === value.numeroDestaqueNCM
          );

          if (index !== "" && index !== null) {
            this.model.destaque.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
            this.submitMercadoria();
            Swal.fire(
              "Excluir Destaque NCM",
              `O destaque <strong>${value.numeroDestaqueNCM}</strong> foi excluído com sucesso da lista de destaques.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Destaque NCM",
              `O destaque <strong>${value.numeroDestaqueNCM}</strong> não foi excluído com sucesso da lista de destaque.`,
              "error"
            );
          }
        }
      });
    },
    async submitDestaque() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: this.di,
        newModel: this.di,
        fieldName: null,
      });

      if (response) {
        Swal.fire(
          "Destaque NCM",
          `O destaque <strong>${this.newDestaque.numeroDestaqueNCM}</strong> foi adicionado com sucesso na lista de destaques.`,
          "success"
        );
        this.newDestaque = {
          numeroDestaqueNCM: "",
        };
      }
    },
    async updateDocumento(value) {
      this.submitDocumento();
    },

    async createDocumento(value) {
      if (this.model.documento === null) this.model.documento = [];
      this.model.documento.push(value);
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      this.submitDocumento();
    },
    async removeDocumento(value) {
      Swal.fire({
        title: "Excluir Documento Vinculado",
        html: `Você realmente deseja excluir o Documento <strong>${value.numeroDocumento}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.documento.findIndex(
            (item) =>
              item.numeroDocumentoVinculado ===
                value.numeroDocumentoVinculado &&
              item.codigoTipoDocumentoVinculado ===
                value.codigoTipoDocumentoVinculado
          );

          if (index !== "" && index !== null) {
            this.model.documento.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
            this.submitMercadoria();
            Swal.fire(
              "Excluir Documento Vinculado",
              ` O documento <strong>${value.numeroDocumentoVinculado}</strong> foi excluído com sucesso da lista de documentos vinculados.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Documento Vinculado",
              ` O documento <strong>${value.numeroDocumentoVinculado}</strong> não foi excluído com sucesso da lista de documentos vinculados.`,
              "error"
            );
          }
        }
      });
    },

    async submitDocumento() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: this.di,
        newModel: this.di,
        fieldName: null,
      });

      if (response) {
        Swal.fire(
          "Documento Vinculado",
          ` O documento <strong>${this.newDocumento.numeroDocumentoVinculado}</strong> foi adicionado na lista de documentos vinculados.`,
          "success"
        );

        this.newDocumento = {
          codigoTipoDocumentoVinculado: "",
          numeroDocumentoVinculado: "",
        };
      }
    },

    async updateNVE(value) {
      this.submitNVE();
    },

    async createNVE(value) {
      try {
        if (this.model.valoracaoAduaneira === null)
          this.model.valoracaoAduaneira = [];
        this.model.valoracaoAduaneira.push(value);
        this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
        this.submitNVE();
      } catch (error) {
        Swal.fire(
          "NVE",
          ` O NVE <strong>${this.newNVE.codigoEspecificacaoNCM}</strong> não foi adicionado a lista de NVEs porque o seguinte erro foi gerado: ${error}`,
          "success"
        );
      }
    },
    async removeNVE(value) {
      Swal.fire({
        title: "Excluir NVE",
        html: `Você realmente deseja excluir o NVE <strong>${value.codigoAtributoNCM} / ${value.codigoEspecificacaoNCM}</strong>? Se você excluir, precisará adicioná-lo novamente na adição.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const index = this.model.valoracaoAduaneira.findIndex(
            (item) =>
              item.codigoAtributoNCM === value.codigoAtributoNCM &&
              item.codigoEspecificacaoNCM === value.codigoEspecificacaoNCM
          );

          if (index !== "" && index !== null) {
            this.model.valoracaoAduaneira.splice(index, 1);
            this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
            this.submitMercadoria();
            Swal.fire(
              "Excluir Mercadoria",
              ` O NVE <strong>${value.codigoEspecificacaoNCM}</strong> foi excluído com sucesso da lista de nves.`,
              "success"
            );
          } else {
            Swal.fire(
              "Excluir Mercadoria",
              ` O NVE <strong>${value.codigoEspecificacaoNCM}</strong> não foi excluído da lista de nves.`,
              "error"
            );
          }
        }
      });
    },

    async submitNVE() {
      const response = await this.$store.dispatch("MyIMPStore/patch", {
        diff: this.di,
        newModel: this.di,
        fieldName: null,
      });

      if (response) {
        Swal.fire(
          "NVE",
          ` O NVE <strong>${this.newNVE.codigoEspecificacaoNCM}</strong> foi adicionado na lista de nves.`,
          "success"
        );

        this.newNVE = {
          codigoAbrangenciaNCM: "",
          codigoAtributoNCM: "",
          codigoEspecificacaoNCM: "",
        };
      }
    },
    async diffObjects(fieldName) {
      this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", this.model);
      const response = await this.$store.dispatch(
        "MyIMPStore/diffObjectsAdicao"
      );

      if (response) {
        this.initialDI = {
          ...this.di,
        };
      }
    },

    selecaoCodigoAbrangenciaNCM(nve, target) {
      this.nvesNiveisAtributos = [];
      this.nvesNiveis.forEach((item) => {
        if (item.key === nve.codigoAbrangenciaNCM) {
          item.item.atributos.forEach((atributo) => {
            this.nvesNiveisAtributos.push({
              key: atributo.codigo,
              value: `${atributo.codigo} - ${atributo.descricao}`,
              item: atributo.listaEspecificacao,
            });
          });
        }
      });
    },
    selecaoCodigoAtributoNCM(nve) {
      this.nvesNiveisEspecificacoes = [];

      if (
        this.nvesNiveisAtributos !== undefined &&
        this.nvesNiveisAtributos !== null
      ) {
        this.nvesNiveisAtributos.forEach((item) => {
          if (item.key === nve.codigoAtributoNCM) {
            item.item.forEach((especificacao) => {
              this.nvesNiveisEspecificacoes.push({
                key: especificacao.codigo,
                value: `${especificacao.codigo} - ${especificacao.descricao}`,
              });
            });
          }
        });
      }
    },

    calcularValorMercadoriaCondicaoVenda() {
      if (
        this.model !== null &&
        this.model !== undefined &&
        this.model.mercadoria !== null &&
        this.model.mercadoria !== undefined &&
        this.model.mercadoria.length > 0
      ) {
        this.model.valorMercadoriaCondicaoVenda = this.model.mercadoria.reduce(
          (value, item) => {
            let val =
              new Number(item.quantidadeMercadoriaUnidadeComercializada) *
              new Number(item.valorUnidadeMedidaCondicaoVenda);
            val = value + val;

            return val;
          },
          0
        );
      } else {
        return 0;
      }
    },
  },

  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
      activeAdicao: (state) => state.MyIMPStore.activeAdicao,
    }),

    totalQuantidadeMercadoriaUnidadeComercializada() {
      if (
        this.model !== null &&
        this.model !== undefined &&
        this.model.mercadoria !== null &&
        this.model.mercadoria !== undefined &&
        this.model.mercadoria.length > 0
      ) {
        return this.model.mercadoria.reduce((value, item) => {
          const val = item.quantidadeMercadoriaUnidadeComercializada;
          return value + val;
        }, 0);
      }
      return 0;
    },
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;

          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },

    nvesNiveis() {
      const values = [];
      if (this.nvesSelecao !== null && this.nvesSelecao.length > 0) {
        this.nvesSelecao.forEach((item) => {
          values.push({
            key: item.codigo,
            value: `${item.codigo} - ${item.descricao}`,
            item,
          });
        });
      }

      return values;
    },
    disableFields() {
      if (this.model.numeroLI !== null && this.model.numeroLI !== "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    activeDI() {
      this.di = {
        ...this.activeDI,
      };
    },
    activeAdicao() {
      this.model = {
        ...this.activeAdicao,
      };

      // if (this.model.codigoMercadoriaNCM !== null && this.model.codigoMercadoriaNCM !== '') {
      //     this.consultarDadosNCM();
      // }

      this.calcularValorMercadoriaCondicaoVenda();
    },
  },

  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-adicao-form-mercadoria-form");
        form.addEventListener(...this.options);
      }, 200);
    }

    if (this.di.numeroRegistro === null) {
      this.calcularValorMercadoriaCondicaoVenda();
      this.consultarDadosNCM();
    }

    this.initialDI = {
      ...this.activeDI,
    };
    this.di = {
      ...this.activeDI,
    };
    this.model = {
      ...this.activeAdicao,
    };
    this.modelInitial = {
      ...this.activeAdicao,
    };

    // EventBus.$on('operational.import-declaration.adition.Form.Mercadoria.buscarNCM', () => {
    //     if (this.di.numeroRegistro === null)
    //         this.buscarNCM();
    // });

    // EventBus.$on('operational.import-declaration.adition.Form.Mercadoria.consultarDadosNCM', () => {
    //     this.consultarDadosNCM();
    // });
  },
};
</script>

<style>
#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
