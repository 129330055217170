<template>
  <div class="text-editor">
    <iframe name="htmlEditor" class="text-html" width="100%"></iframe>
    <ul class="users-list" ref="usersList">
      <li v-for="(user, index) in filteredUsers" :key="index" @click.prevent="selectUser(user)">
        {{ user.name }}
      </li>
    </ul>
    <div class="toolbar">
      <div class="actions d-flex align-center">
        <button
          type="button"
          @click="execCmd($event, 'bold')"
        >
          <i class="fa fa-bold"></i>
        </button>
        <button
          type="button"
          @click="execCmd($event, 'italic')"
        >
          <i class="fa fa-italic"></i>
        </button>
        <button
          type="button"
          @click="execCmd($event, 'strikeThrough')"
        >
          <i class="fa fa-strikethrough"></i>
        </button>
        <button
          type="button"
          @click="execCmd($event, 'insertOrderedList')"
        >
          <i class="fa fa-list-ol"></i>
        </button>
        <button
          type="button"
          @click="execCmd($event, 'insertUnorderedList')"
        >
          <i class="fa fa-list-ul"></i>
        </button>
      </div>

      <div class="emojis" style="position: relative;">
        <button
          type="button"
          @click.prevent="showEmojis = !showEmojis"
        >
          <i class="fa fa-smile"></i>
        </button>
        <div v-if="showEmojis" class="emojis-menu" ref="emojis">
          <ul>
            <li v-for="(emoji, index) in emojis" :key="index">
              <a href="#" @click.prevent="insertEmoji(emoji)">{{ emoji }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';

export default {
  name: 'TextEditor',
  props: {
    value: {},
  },
  data() {
    return {
      search: '',
      users: [
        {
          id: 1,
          name: 'Luan Kohl',
        },
        {
          id: 2,
          name: 'Allysson Pereira',
        },
        {
          id: 3,
          name: 'Jana Boladona',
        },
      ],
      showEmojis: false,
      emojis: [
        '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', '😘', '😗',
        '😚', '😙', '🥲', '😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶',
        '😶‍🌫️', '😏', '😒', '🙄', '😬', '😮‍💨', '🤥', '😌', '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕', '🤢', '🤮', '🤧',
        '🥵', '🥶', '🥴', '😵', '😵‍💫', '🤯', '🤠', '🥳', '🥸', '😎', '🤓', '🧐', '😕', '😟', '🙁', '☹️', '😮', '😯',
        '😲', '😳', '🥺', '😦', '😧', '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣', '😞', '😓', '😩', '😫', '🥱',
        '😤', '😡', '😠', '🤬', '😈', '👿', '💀', '☠️', '💩', '🤡', '👹', '👺', '👻', '👽', '👾', '🤖', '😺', '😸',
        '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '💋', '👋', '🤚', '🖐️', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞',
        '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍', '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐',
        '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦿', '🦵', '🦶', '👂', '🦻', '👃', '🧠', '🫀', '🫁', '🦷',
        '🦴', '👀', '👁️', '👅', '👄',
      ],
    };
  },
  computed: {
    filteredUsers() {
      if (this.search !== '') {
        const regExp = new RegExp(this.search, 'gi');
        return this.users.filter((user) => regExp.test(user.name));
      }
      return this.users;
    },
  },
  mounted() {
    htmlEditor.document.designMode = 'On';
    htmlEditor.document.addEventListener('keyup', this.keyUpAction);
    htmlEditor.document.addEventListener('keydown', this.keyDownAction);
    htmlEditor.document.body.innerHTML = this.value ? this.value : '';

    htmlEditor.document.head.insertAdjacentHTML('afterbegin', `
      <style>
        @import url("http://fonts.googleapis.com/css?family=Roboto:400,500,700");

        body,html {
          font-family: Roboto, sans-serif;
        }

        .mention-user {
          display: inline-block;
          background-color: #f5f6f7;
          border: 1px solid #e2e2e2;
          padding: 2px 4px;
          border-radius: 10px;
          font-weight: bold;
        }
      </style>
    `);

    Axios.get(`${config.env.API_DEFAULT.host}/users/get-operational-users`).then((response) => {
      const users = response.data.map((user) => ({
        id: user[0],
        name: user[1],
      }));
      this.users = users;
    });
  },
  beforeDestroy() {
    htmlEditor.document.removeEventListener('keyup', this.keyUpAction);
    htmlEditor.document.removeEventListener('keydown', this.keyDownAction);
  },
  methods: {
    updateContent() {
      this.$nextTick(() => {
        htmlEditor.document.body.innerHTML = this.value ? this.value : '';
      });
    },
    updateValue() {
      this.$emit('input', htmlEditor.document.body.innerHTML);
    },
    execCmd(event, command) {
      event.preventDefault();
      htmlEditor.document.execCommand(command, false, null);
      htmlEditor.document.body.focus();
    },
    keyDownAction(e) {
      if (e.key == '@') {
        e.preventDefault();

        this.insertAt();

        this.renderUserSearch();
      }
      this.updateValue();
    },
    keyUpAction(e) {
      const disabledTags = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'LI', 'IMG', 'SPAN', 'P'];
      if (!disabledTags.find((tag) => tag === htmlEditor.document.getSelection().anchorNode.parentNode.tagName)) {
        htmlEditor.document.execCommand('formatBlock', false, 'p');
      }
      if (e.keyCode == '13') {
        if (disabledTags.find((tag) => tag === htmlEditor.document.getSelection().anchorNode.parentNode.tagName)) return;
        htmlEditor.document.execCommand('formatBlock', false, 'p');
      }
      this.updateValue();
    },
    renderUserSearch() {
      const rect = htmlEditor.document.getSelection().getRangeAt(0).getBoundingClientRect();
      this.$refs.usersList.setAttribute('style', `position: absolute; top: ${rect.top + 30}px; left: ${rect.left}px;`);
      this.$refs.usersList.classList.add('active');
    },
    listenAt() {
      htmlEditor.document.addEventListener('keyup', this.listenSearch);
    },
    removeListenAt() {
      htmlEditor.document.removeEventListener('keyup', this.listenSearch);
    },
    listenSearch(e) {
      const listenSpan = htmlEditor.document.querySelector('#search-at');

      if (!listenSpan) {
        this.$refs.usersList.classList.remove('active');
        return this.removeListenAt();
      }

      if (e.code === 'Space') {
        this.removeListenAt();
      } else if (e.code === 'Tab') {
        return this.selectUser(this.filteredUsers[0]);
      }
      const search = listenSpan.textContent.replace('@', '');
      this.search = search;

      this.renderUserSearch();
    },
    insertAt() {
      let sel; let
        range;
      if (htmlEditor.document.getSelection && (sel = htmlEditor.document.getSelection()).rangeCount) {
        range = sel.getRangeAt(0);
        range.collapse(true);
        const span = htmlEditor.document.createElement('span');
        span.id = 'search-at';
        span.appendChild(htmlEditor.document.createTextNode('@'));
        range.insertNode(span);

        // Move the caret immediately after the inserted span
        range.setStartAfter(span);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      this.listenAt();
    },
    insertEmoji(emoji) {
      let sel; let
        range;
      if (htmlEditor.document.getSelection && (sel = htmlEditor.document.getSelection()).rangeCount) {
        range = sel.getRangeAt(0);
        range.collapse(true);
        const emojiNode = htmlEditor.document.createTextNode(emoji);
        range.insertNode(emojiNode);

        // Move the caret immediately after the inserted emojiNode
        range.setStartAfter(emojiNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        htmlEditor.document.body.focus();
      }
      this.showEmojis = false;
      this.updateValue();
    },
    selectUser(user) {
      let sel; let
        range;
      if (htmlEditor.document.getSelection && (sel = htmlEditor.document.getSelection()).rangeCount) {
        htmlEditor.document.querySelector('#search-at').remove();
        range = sel.getRangeAt(0);
        range.collapse(true);

        const space = htmlEditor.document.createElement('span');
        space.appendChild(htmlEditor.document.createTextNode('\u00A0'));
        range.insertNode(space);

        const span = htmlEditor.document.createElement('span');
        span.dataset.id = user.id;
        span.classList.add('mention-user');
        span.appendChild(htmlEditor.document.createTextNode(`@${user.name}`));
        range.insertNode(span);

        // Move the caret immediately after the inserted span
        range.setStartAfter(space);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);

        htmlEditor.document.body.focus();
        this.updateValue();
      }
      this.$refs.usersList.classList.remove('active');
      this.removeListenAt();
    },
  },
};
</script>

<style>
  .text-html {
    border: 1px solid #ced4da;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 10px;
    margin: 0;
    display: block;
    resize: vertical;
    overflow: auto;
  }

  .text-editor {
    position: relative;
  }

  .toolbar {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ced4da;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .toolbar button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: all .2s ease-in-out 0s;
  }

  .toolbar button:hover {
    background-color: #ced4da;
  }

  .users-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 200px;
    max-height: 85px;
    overflow: auto;
    display: none;
  }
  .users-list.active {
    display: block;
  }
  .users-list li {
    display: block;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f5f6f7;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
  }
  .users-list li:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 1px solid #e2e2e2;
  }
  .users-list li:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom: 1px solid #e2e2e2;
  }
  .users-list li:not(:last-of-type) {
    border-bottom: 1px solid #e2e2e2;
  }
  .users-list li:hover {
    background-color: #ced4da;
  }

  .emojis-menu {
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    width: 265px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    overflow: auto;
    font-size: 20px;
  }

  .emojis-menu ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 5px;
  }
</style>
