<template>
  <svg :width="size" viewBox="0 0 111 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M93.24 33.0617C93.9511 31.2645 94.35 29.2992 94.35 27.25C94.35 18.3477 86.8922 11.125 77.7 11.125C74.2833 11.125 71.092 12.1328 68.4558 13.8461C63.6516 5.7836 54.6848 0.375 44.4 0.375C29.0681 0.375 16.65 12.4016 16.65 27.25C16.65 27.7035 16.6673 28.157 16.6847 28.6106C6.97219 31.9196 0 40.8891 0 51.4375C0 64.7911 11.1867 75.625 24.975 75.625H88.8C101.062 75.625 111 66.0004 111 54.125C111 43.7278 103.369 35.0438 93.24 33.0617ZM68.2303 43.375H56.8875V62.1875C56.8875 63.6657 55.6388 64.875 54.1125 64.875H45.7875C44.2613 64.875 43.0125 63.6657 43.0125 62.1875V43.375H31.6697C29.1895 43.375 27.9581 40.486 29.7098 38.7895L47.9902 21.0856C49.0655 20.0442 50.8345 20.0442 51.9098 21.0856L70.1902 38.7895C71.9419 40.486 70.6931 43.375 68.2303 43.375Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CloudIcon',
  props: {
    size: {
      type: String,
      default: '16',
    },
  },
};
</script>
