<template>
  <div class="contact-form">
    <div class="sp-3 light-bg p-3">
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">INCONSISTÊNCIAS</h4>
          <p class="mb-2">{{ inconsistencies.length }} inconsistências cadastradas</p>
          <p class="mb-0 text-info"><i class="fas fa-info-circle"></i> Caso nenhuma data for selecionada, seram exibidas somente as inconsitências do mês</p>
        </div>
      </div>
      <div class="four-grid mt-4">
        <div class="four-grid">
          <div class="fg-4">
            <v-input name="startDate" label="Data inicial (Obrigatório)" v-model="formFilter.startDate"
              type="date"></v-input>
          </div>
        </div>
        <div class="four-grid">
          <div class="fg-4">
            <v-input name="endDate" label="Data final (Obrigatório)" v-model="formFilter.endDate"
              type="date"></v-input>
          </div>
        </div>
        <div class="four-grid">
          <div class="fg-4">
            <button class="report-button" @click.prevent="getInconsistences">Filtrar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body pd-40 sp-3 text-center" v-if="inconsistencies.length == 0">
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="">
      <h6 class="tx-md-20 tx-inverse ">Nenhuma Inconsistência Cadastrada</h6>
    </div>

    <div class="ph-2 sp-3 table-overflow" v-else>
      <table>
        <thead>
          <tr>
            <th>
              Data
            </th>
            <th>
              Origem
            </th>
            <th>
              Descrição / Tipo
            </th>
            <th>
              Valor R$
            </th>
            <th>
              Peso
            </th>
            <th>
              Etapa
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in inconsistencies" :key="item.id">
            <td>
              {{item.date | moment('DD/MM/YYYY')}}
            </td>
            <td>
              <div class="text-danger text-bold">
                {{item.source}}
              </div>
              <div>
                {{item.createdBy}}
              </div>
            </td>
            <td>
              <div>
                {{ item.description }}
              </div>
              <div class="text-bold">
                {{ item.type.description}}
              </div>
            </td>
            <td>
              {{ item.value | number }}
            </td>
            <td class="text-bold">
              {{ item.weight }}
            </td>
            <td>
              {{ item.step.alias }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import Swal from 'sweetalert2';
import VInput from '@/components/VInput';

import Axios from 'axios';

export default {
  name: 'CustomerInconsistences',
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VInput,
  },
  data() {
    return {
      inconsistencies: [],
      formFilter: {
        startDate: null,
        endDate: null,
      },
    };
  },
  filters: {
    number(value) {
      return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    },
  },
  mounted() {
    this.getInconsistences();
  },
  methods: {
    async getInconsistences() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando inconsistências do cliente.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/company/getInconsistencies/${this.customerDetails.id}?startDate=${this.formFilter.startDate && this.formFilter.startDate !== 'NULL' ? `${this.formFilter.startDate} 00:00:00` : null}&endDate=${this.formFilter.endDate && this.formFilter.endDate !== 'NULL' ? `${this.formFilter.endDate} 23:59:00` : null}`);
        this.inconsistencies = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Erro interno',
          html: `<p>Não foi possível recuperar as inconsistências do cliente</p> <div>${error}</div>`,
          type: 'error',
        });
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392A51A;
}

.four-grid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.fg-4 {
  grid-column: span 4;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all .2s ease-in-out 0s;
  font-size: 1rem;
}
</style>
