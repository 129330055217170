export const codigoMetodosValoracao = [
  { key: '01', value: '01 - METODO 1', description: '01 - METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)' },
  { key: '02', value: '02 - METODO 2', description: '02 - METODO 2 - ART. 2 DO ACORDO (DECRETO 92930/86)' },
  { key: '03', value: '03 - METODO 3', description: '03 - METODO 3 - ART. 3 DO ACORDO (DECRETO 92930/86)' },
  { key: '04', value: '04 - METODO 4', description: '04 - METODO 4 - ART. 5 DO ACORDO (DECRETO 92930/86)' },
  { key: '05', value: '05 - METODO 5', description: '05 - METODO 5 - ART. 6 DO ACORDO (DECRETO 92930/86)' },
  { key: '06', value: '06 - METODO 6', description: '06 - METODO 6 - ART. 7 DO ACORDO (DECRETO 92930/86)' },
];

export const codigosReceitas = [
  { key: '0760', value: '0760 - COFINS - REGIME ESPECIAL DE TRIBUTAçãO - CERVEJAS' },
  { key: '1531', value: '1531 - MEDIDA COMPENSATORIA - MULTA DE OFICIO' },
  { key: '1532', value: '1532 - MEDIDA COMPENSATORIA - JUROS DE OFICIO' },
  { key: '3358', value: '3358 - MULTA DE OFICIO - IPI VINC IMPORTACAO' },
  { key: '1533', value: '1533 - MEDIDA DE SALVAGUARDA - MULTA DE OFICIO' },
  { key: '1534', value: '1534 - MEDIDA DE SALVAGUARDA - JUROS DE OFICIO' },
  { key: '4802', value: '4802 - COFINS/IMPORTAÇÃO - JUROS DE OFÍCIO' },
  { key: '2866', value: '2866 - JUROS DE MORA - IPI VINCULADO' },
  { key: '0724', value: '0724 - PIS - IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO - CERVEJAS' },
  { key: '0406', value: '0406 - JUROS DE MORA - IMPOSTO DE IMPORTAÇÃO' },
  { key: '0809', value: '0809 - COFINS - IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO - DEMAISBEBIDAS' },
  { key: '9064', value: '9064 - IMPOSTO DE IMPORTACAO - ADICIONAL' },
  { key: '6393', value: '6393 - MULTA ISOLADA - IPI VINCULADO (ART. 43 LEI 9430)' },
  { key: '3283', value: '3283 - MULTA DE OFÍCIO - IMPOSTO IMPORTAÇÃO - REMESSA POSTAL' },
  { key: '0094', value: '0094 - IMPOSTO DE IMPORTACAO - BAGAGEM ACOMPANHADA' },
  { key: '5661', value: '5661 - JUROS PIS IMPORTACAO' },
  { key: '4693', value: '4693 - COFINS/IMPORTAÇÃO - MULTA DE OFÍCIO' },
  { key: '0691', value: '0691 - PIS - REGIME ESPECIAL DE TRIBUTAçãO - DEMAIS BEBIDAS' },
  { key: '5622', value: '5622 - MEDIDA COMPENSATORIA' },
  { key: '5149', value: '5149 - MULTA APLICADA AO SETOR ADUANEIRO COM REDUçãO' },
  { key: '3005', value: '3005 - MULTA DE OFíCIO DO IMPOSTO DE IMPORTAçãO' },
  { key: '5668', value: '5668 - MEDIDA COMPENSATORIA - LANCAMENTO DE OFICIO' },
  { key: '5503', value: '5503 - IPI VINCULADO - IMPORTACAO VEICULOS' },
  { key: '0850', value: '0850 - IPI - VINCULADO IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO -CERVEJAS' },
  { key: '1587', value: '1587 - IMPOSTO IMPORTAÇÃO - REMESSA EXPRESSA' },
  { key: '3248', value: '3248 - MULTA ADUANEIRA - REMESSA POSTAL' },
  { key: '0776', value: '0776 - COFINS - REGIME ESPECIAL DE TRIBUTAçãO - DEMAIS BEBIDAS' },
  { key: '5629', value: '5629 - COFINS IMPORTACAO' },
  { key: '0338', value: '0338 - MULTA ISOLADA REC DIREITOS ANTIDUMP E COMPENS ART.7 LEI 9019/95' },
  { key: '3928', value: '3928 - IPI VINCULADO - CONVERSAO DEPOSITO JUDICIAL' },
  { key: '3890', value: '3890 - IMPOSTO DE IMPORTACAO - CONVERSAO DEPOSITO JUDICIAL' },
  { key: '5674', value: '5674 - MEDIDA DE SALVAGUARDA - LANCAMENTO DE OFICIO' },
  { key: '1593', value: '1593 - IMPOSTO IMPORTAÇÃO - REMESSA EXPRESSA - LANÇAMENTO DE OFÍCIO' },
  { key: '3254', value: '3254 - IMPOSTO IMPORTAÇÃO - REMESSA POSTAL - LANÇAMENTO DE OFÍCIO' },
  { key: '6324', value: '6324 - MULTA ISOLADA - PIS/PASEP (ART. 43 L.9430)' },
  { key: '9438', value: '9438 - CONTRIBUIÇÃO DE INTERVENÇÃO NO DOMÍNIO ECONÔMICO INCIDENTE SOBRE A IMPORTAÇÃO - CIDE.' },
  { key: '7811', value: '7811 - TAXA DE UTILIZAçãO DO SISCOMEX' },
  { key: '0782', value: '0782 - COFINS - IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO - CERVEJAS' },
  { key: '0300', value: '0300 - RECEITA DIREITOS ANTIDUMPING-LANÇAMENTO DE OFÍCIO' },
  { key: '1038', value: '1038 - IPI VINCULADO A IMPORTACAO' },
  { key: '5637', value: '5637 - MULTA COFINS IMPORTACAO' },
  { key: '5516', value: '5516 - IMPOSTO DE IMPORTACAO - VEICULOS' },
  { key: '4669', value: '4669 - PIS/IMPORTAÇÃO - JUROS DE OFÍCIO' },
  { key: '0148', value: '0148 - CIDE - COMBUSTIVEIS - IMPORTACAO - LANCAMENTO DE OFICIO' },
  { key: '0821', value: '0821 - IPI - REGIME ESPECIAL DE TRIBUTAçãO - CERVEJAS' },
  { key: '1558', value: '1558 - MULTA ADUANEIRA - REMESSA EXPRESSA' },
  { key: '6608', value: '6608 - JUROS DO IPI VINCULADO (ART. 43 LEI 9430)' },
  { key: '0107', value: '0107 - IMPOSTO DE EXPORTACAO - OUTROS' },
  { key: '0867', value: '0867 - IPI - VINCULADO IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO -DEMAIS BEBIDAS' },
  { key: '0150', value: '0150 - MULTA DE OFICIO - CIDE - COMBUSTIVEIS -IMPORTACAO' },
  { key: '4597', value: '4597 - PIS/IMPORTAÇÃO - MULTA DE OFÍCIO' },
  { key: '2892', value: '2892 - IMPOSTO DE IMPORTACAO - LANCAMENTO DE OFICIO' },
  { key: '3101', value: '3101 - JUROS DE OFÍCIO - IMPOSTO DE IMPORTAÇÃO' },
  { key: '1089', value: '1089 - IMPOSTO DE EXPORTACAO - CACAU' },
  { key: '6337', value: '6337 - MULTA ISOLADA - COFINS (ART. 43 L.9430)' },
  { key: '3345', value: '3345 - IPI VINCULADO IMPORTACAO - LANCAM. DE OFICIO' },
  { key: '5645', value: '5645 - JUROS COFINS IMPORTACAO' },
  { key: '5602', value: '5602 - PISPASEP IMPORTACAO' },
  { key: '0158', value: '0158 - JUROS LANCADOS DE OFICIO - CIDE - COMBUSTIVEIS - IMPORTACAO' },
  { key: '1962', value: '1962 - IMPOSTO DE IMPORTACAO - PETROLEO E DERIVADOS' },
  { key: '9806', value: '9806 - MULTA IPI - VINCULADO A IMPORTACAO' },
  { key: '0753', value: '0753 - PIS - IMPORTAçãO - REGIME ESPECIAL DE TRIBUTAçãO - DEMAIS BEBIDAS' },
  { key: '3228', value: '3228 - MULTA IMPOSTO DE IMPORTAçãO' },
  { key: '1524', value: '1524 - MEDIDA COMPENSATORIA - MULTA' },
  { key: '5529', value: '5529 - ANTIDUMPING' },
  { key: '1525', value: '1525 - MEDIDA COMPENSATORIA - JUROS' },
  { key: '2933', value: '2933 - TRIBUTOS FEDERAIS DEVIDOS EM IMPORTACAO REALIZADA AO AMPARO DO REGIME DE TRIBUTACAO UNIFICADA (RTU) - LEI 11898-2009' },
  { key: '0679', value: '0679 - PIS - REGIME ESPECIAL DE TRIBUTAçãO - CERVEJAS' },
  { key: '1603', value: '1603 - MULTA DE OFÍCIO - IMPOSTO DE IMPORTAÇÃO - REMESSA EXPRESSA' },
  { key: '1527', value: '1527 - MEDIDA DE SALVAGUARDA - MULTA' },
  { key: '1528', value: '1528 - MEDIDA DE SALVAGUARDA - JUROS' },
  { key: '0838', value: '0838 - IPI - REGIME ESPECIAL DE TRIBUTAçãO - DEMAIS BEBIDAS' },
  { key: '6340', value: '6340 - MULTA ISOLADA - IMPOSTO DE IMPORTAÇÃO (ART. 43 LEI 9430)' },
  { key: '2185', value: '2185 - MULTA APLICADA NO SETOR ADUANEIRO SEM REDUçãO' },
  { key: '6542', value: '6542 - JUROS DO IMPOSTO DE IMPORTAÇÃO (ART. 43 LEI 9430)' },
  { key: '5651', value: '5651 - MEDIDA DE SALVAGUARDA' },
  { key: '4562', value: '4562 - PIS/IMPORTAÇÃO - LANÇAMENTO DE OFÍCIO' },
  { key: '3231', value: '3231 - IMPOSTO IMPORTAÇÃO - REMESSA POSTAL' },
  { key: '0086', value: '0086 - IMPOSTO DE IMPORTACAO - OUTROS' },
  { key: '5653', value: '5653 - MULTA PIS IMPORTACAO' },
  { key: '4685', value: '4685 - COFINS/IMPORTAÇÃO - LANÇAMENTO DE OFÍCIO' },
];

export const codigosPaises = [
  { key: '994', value: '994 - A DESIGNAR' },
  { key: '013', value: '013 - AFEGANISTAO' },
  { key: '756', value: '756 - AFRICA DO SUL' },
  { key: '015', value: '015 - ALAND, ILHAS' },
  { key: '017', value: '017 - ALBANIA, REPUBLICA  DA' },
  { key: '023', value: '023 - ALEMANHA' },
  { key: '037', value: '037 - ANDORRA' },
  { key: '040', value: '040 - ANGOLA' },
  { key: '041', value: '041 - ANGUILLA' },
  { key: '043', value: '043 - ANTIGUA E BARBUDA' },
  { key: '042', value: '042 - ANTÁRTICA' },
  { key: '053', value: '053 - ARABIA SAUDITA' },
  { key: '059', value: '059 - ARGELIA' },
  { key: '063', value: '063 - ARGENTINA' },
  { key: '064', value: '064 - ARMENIA, REPUBLICA DA' },
  { key: '065', value: '065 - ARUBA' },
  { key: '069', value: '069 - AUSTRALIA' },
  { key: '072', value: '072 - AUSTRIA' },
  { key: '073', value: '073 - AZERBAIJAO, REPUBLICA DO' },
  { key: '077', value: '077 - BAHAMAS, ILHAS' },
  { key: '080', value: '080 - BAHREIN, ILHAS' },
  { key: '995', value: '995 - BANCOS CENTRAIS' },
  { key: '081', value: '081 - BANGLADESH' },
  { key: '083', value: '083 - BARBADOS' },
  { key: '085', value: '085 - BELARUS, REPUBLICA DA' },
  { key: '087', value: '087 - BELGICA' },
  { key: '088', value: '088 - BELIZE' },
  { key: '229', value: '229 - BENIN' },
  { key: '090', value: '090 - BERMUDAS' },
  { key: '097', value: '097 - BOLIVIA, ESTADO PLURINACIONAL DA' },
  { key: '099', value: '099 - BONAIRE, SAINT EUSTATIUS E SABA' },
  { key: '098', value: '098 - BOSNIA-HERZEGOVINA (REPUBLICA DA)' },
  { key: '101', value: '101 - BOTSUANA' },
  { key: '102', value: '102 - BOUVET, ILHA' },
  { key: '105', value: '105 - BRASIL' },
  { key: '108', value: '108 - BRUNEI' },
  { key: '111', value: '111 - BULGARIA, REPUBLICA DA' },
  { key: '031', value: '031 - BURKINA FASO' },
  { key: '115', value: '115 - BURUNDI' },
  { key: '119', value: '119 - BUTAO' },
  { key: '127', value: '127 - CABO VERDE, REPUBLICA DE' },
  { key: '145', value: '145 - CAMAROES' },
  { key: '141', value: '141 - CAMBOJA' },
  { key: '149', value: '149 - CANADA' },
  { key: '154', value: '154 - CATAR' },
  { key: '137', value: '137 - CAYMAN, ILHAS' },
  { key: '153', value: '153 - CAZAQUISTAO, REPUBLICA DO' },
  { key: '788', value: '788 - CHADE' },
  { key: '158', value: '158 - CHILE' },
  { key: '160', value: '160 - CHINA, REPUBLICA POPULAR' },
  { key: '163', value: '163 - CHIPRE' },
  { key: '511', value: '511 - CHRISTMAS,ILHA (NAVIDAD)' },
  { key: '741', value: '741 - CINGAPURA' },
  { key: '165', value: '165 - COCOS(KEELING),ILHAS' },
  { key: '169', value: '169 - COLOMBIA' },
  { key: '173', value: '173 - COMORES, ILHAS' },
  { key: '177', value: '177 - CONGO' },
  { key: '888', value: '888 - CONGO, REPUBLICA DEMOCRATICA DO' },
  { key: '183', value: '183 - COOK, ILHAS' },
  { key: '187', value: '187 - COREIA (DO NORTE), REP.POP.DEMOCRATICA' },
  { key: '190', value: '190 - COREIA (DO SUL), REPUBLICA DA' },
  { key: '193', value: '193 - COSTA DO MARFIM' },
  { key: '196', value: '196 - COSTA RICA' },
  { key: '198', value: '198 - COVEITE' },
  { key: '195', value: '195 - CROACIA (REPUBLICA DA)' },
  { key: '199', value: '199 - CUBA' },
  { key: '200', value: '200 - CURACAO' },
  { key: '232', value: '232 - DINAMARCA' },
  { key: '783', value: '783 - DJIBUTI' },
  { key: '235', value: '235 - DOMINICA,ILHA' },
  { key: '240', value: '240 - EGITO' },
  { key: '687', value: '687 - EL SALVADOR' },
  { key: '244', value: '244 - EMIRADOS ARABES UNIDOS' },
  { key: '239', value: '239 - EQUADOR' },
  { key: '243', value: '243 - ERITREIA' },
  { key: '247', value: '247 - ESLOVACA, REPUBLICA' },
  { key: '246', value: '246 - ESLOVENIA, REPUBLICA DA' },
  { key: '245', value: '245 - ESPANHA' },
  { key: '249', value: '249 - ESTADOS UNIDOS' },
  { key: '251', value: '251 - ESTONIA, REPUBLICA DA' },
  { key: '754', value: '754 - ESWATINI' },
  { key: '253', value: '253 - ETIOPIA' },
  { key: '255', value: '255 - FALKLAND (ILHAS MALVINAS)' },
  { key: '259', value: '259 - FEROE, ILHAS' },
  { key: '870', value: '870 - FIJI' },
  { key: '267', value: '267 - FILIPINAS' },
  { key: '271', value: '271 - FINLANDIA' },
  { key: '161', value: '161 - FORMOSA (TAIWAN)' },
  { key: '275', value: '275 - FRANCA' },
  { key: '281', value: '281 - GABAO' },
  { key: '285', value: '285 - GAMBIA' },
  { key: '289', value: '289 - GANA' },
  { key: '291', value: '291 - GEORGIA, REPUBLICA DA' },
  { key: '292', value: '292 - GEÓRGIA DO SUL E SANDWICH DO SUL, ILHAS' },
  { key: '293', value: '293 - GIBRALTAR' },
  { key: '297', value: '297 - GRANADA' },
  { key: '301', value: '301 - GRECIA' },
  { key: '305', value: '305 - GROENLANDIA' },
  { key: '309', value: '309 - GUADALUPE' },
  { key: '313', value: '313 - GUAM' },
  { key: '317', value: '317 - GUATEMALA' },
  { key: '321', value: '321 - GUERNSEY' },
  { key: '337', value: '337 - GUIANA' },
  { key: '325', value: '325 - GUIANA FRANCESA' },
  { key: '329', value: '329 - GUINE' },
  { key: '334', value: '334 - GUINE-BISSAU' },
  { key: '331', value: '331 - GUINE-EQUATORIAL' },
  { key: '341', value: '341 - HAITI' },
  { key: '343', value: '343 - HEARD E ILHAS MCDONALD, ILHA' },
  { key: '345', value: '345 - HONDURAS' },
  { key: '351', value: '351 - HONG KONG' },
  { key: '355', value: '355 - HUNGRIA, REPUBLICA DA' },
  { key: '357', value: '357 - IEMEN' },
  { key: '361', value: '361 - INDIA' },
  { key: '365', value: '365 - INDONESIA' },
  { key: '372', value: '372 - IRA, REPUBLICA ISLAMICA DO' },
  { key: '369', value: '369 - IRAQUE' },
  { key: '375', value: '375 - IRLANDA' },
  { key: '379', value: '379 - ISLANDIA' },
  { key: '383', value: '383 - ISRAEL' },
  { key: '386', value: '386 - ITALIA' },
  { key: '391', value: '391 - JAMAICA' },
  { key: '399', value: '399 - JAPAO' },
  { key: '393', value: '393 - JERSEY' },
  { key: '403', value: '403 - JORDANIA' },
  { key: '411', value: '411 - KIRIBATI' },
  { key: '420', value: '420 - LAOS, REP.POP.DEMOCR.DO' },
  { key: '426', value: '426 - LESOTO' },
  { key: '427', value: '427 - LETONIA, REPUBLICA DA' },
  { key: '431', value: '431 - LIBANO' },
  { key: '434', value: '434 - LIBERIA' },
  { key: '438', value: '438 - LIBIA' },
  { key: '440', value: '440 - LIECHTENSTEIN' },
  { key: '442', value: '442 - LITUANIA, REPUBLICA DA' },
  { key: '445', value: '445 - LUXEMBURGO' },
  { key: '447', value: '447 - MACAU' },
  { key: '449', value: '449 - MACEDONIA, ANT.REP.IUGOSLAVA' },
  { key: '450', value: '450 - MADAGASCAR' },
  { key: '455', value: '455 - MALASIA' },
  { key: '458', value: '458 - MALAVI' },
  { key: '461', value: '461 - MALDIVAS' },
  { key: '464', value: '464 - MALI' },
  { key: '467', value: '467 - MALTA' },
  { key: '359', value: '359 - MAN, ILHA DE' },
  { key: '472', value: '472 - MARIANAS DO NORTE' },
  { key: '474', value: '474 - MARROCOS' },
  { key: '476', value: '476 - MARSHALL,ILHAS' },
  { key: '477', value: '477 - MARTINICA' },
  { key: '485', value: '485 - MAURICIO' },
  { key: '488', value: '488 - MAURITANIA' },
  { key: '489', value: '489 - MAYOTTE' },
  { key: '493', value: '493 - MEXICO' },
  { key: '093', value: '093 - MIANMAR (BIRMANIA)' },
  { key: '499', value: '499 - MICRONESIA' },
  { key: '505', value: '505 - MOCAMBIQUE' },
  { key: '494', value: '494 - MOLDAVIA, REPUBLICA DA' },
  { key: '495', value: '495 - MONACO' },
  { key: '497', value: '497 - MONGOLIA' },
  { key: '498', value: '498 - MONTENEGRO' },
  { key: '501', value: '501 - MONTSERRAT,ILHAS' },
  { key: '507', value: '507 - NAMIBIA' },
  { key: '508', value: '508 - NAURU' },
  { key: '517', value: '517 - NEPAL' },
  { key: '521', value: '521 - NICARAGUA' },
  { key: '525', value: '525 - NIGER' },
  { key: '528', value: '528 - NIGERIA' },
  { key: '531', value: '531 - NIUE,ILHA' },
  { key: '535', value: '535 - NORFOLK,ILHA' },
  { key: '538', value: '538 - NORUEGA' },
  { key: '542', value: '542 - NOVA CALEDONIA' },
  { key: '548', value: '548 - NOVA ZELANDIA' },
  { key: '556', value: '556 - OMA' },
  { key: '997', value: '997 - ORGANIZACOES INTERNACIONAIS' },
  { key: '566', value: '566 - PACIFICO,ILHAS DO (POSSESSAO DOS EUA)' },
  { key: '573', value: '573 - PAISES BAIXOS (HOLANDA)' },
  { key: '575', value: '575 - PALAU' },
  { key: '578', value: '578 - PALESTINA' },
  { key: '580', value: '580 - PANAMA' },
  { key: '545', value: '545 - PAPUA NOVA GUINE' },
  { key: '576', value: '576 - PAQUISTAO' },
  { key: '586', value: '586 - PARAGUAI' },
  { key: '589', value: '589 - PERU' },
  { key: '593', value: '593 - PITCAIRN,ILHA' },
  { key: '599', value: '599 - POLINESIA FRANCESA' },
  { key: '603', value: '603 - POLONIA, REPUBLICA DA' },
  { key: '611', value: '611 - PORTO RICO' },
  { key: '607', value: '607 - PORTUGAL' },
  { key: '990', value: '990 - PROVISAO DE NAVIOS E AERONAVES' },
  { key: '623', value: '623 - QUENIA' },
  { key: '625', value: '625 - QUIRGUIZ, REPUBLICA' },
  { key: '628', value: '628 - REINO UNIDO' },
  { key: '640', value: '640 - REPUBLICA CENTRO-AFRICANA' },
  { key: '647', value: '647 - REPUBLICA DOMINICANA' },
  { key: '660', value: '660 - REUNIAO, ILHA' },
  { key: '670', value: '670 - ROMENIA' },
  { key: '675', value: '675 - RUANDA' },
  { key: '676', value: '676 - RUSSIA, FEDERACAO DA' },
  { key: '685', value: '685 - SAARA OCIDENTAL' },
  { key: '677', value: '677 - SALOMAO, ILHAS' },
  { key: '690', value: '690 - SAMOA' },
  { key: '691', value: '691 - SAMOA AMERICANA' },
  { key: '697', value: '697 - SAN MARINO' },
  { key: '710', value: '710 - SANTA HELENA' },
  { key: '715', value: '715 - SANTA LUCIA' },
  { key: '693', value: '693 - SAO BARTOLOMEU' },
  { key: '695', value: '695 - SAO CRISTOVAO E NEVES,ILHAS' },
  { key: '700', value: '700 - SAO PEDRO E MIQUELON' },
  { key: '720', value: '720 - SAO TOME E PRINCIPE, ILHAS' },
  { key: '705', value: '705 - SAO VICENTE E GRANADINAS' },
  { key: '728', value: '728 - SENEGAL' },
  { key: '735', value: '735 - SERRA LEOA' },
  { key: '737', value: '737 - SERVIA' },
  { key: '731', value: '731 - SEYCHELLES' },
  { key: '744', value: '744 - SIRIA, REPUBLICA ARABE DA' },
  { key: '748', value: '748 - SOMALIA' },
  { key: '750', value: '750 - SRI LANKA' },
  { key: '759', value: '759 - SUDAO' },
  { key: '760', value: '760 - SUDAO DO SUL' },
  { key: '764', value: '764 - SUECIA' },
  { key: '767', value: '767 - SUICA' },
  { key: '770', value: '770 - SURINAME' },
  { key: '755', value: '755 - SVALBARD E JAN MAYEN' },
  { key: '698', value: '698 - SÃO MARTINHO, ILHA DE (PARTE FRANCESA)' },
  { key: '699', value: '699 - SÃO MARTINHO, ILHA DE (PARTE HOLANDESA)' },
  { key: '772', value: '772 - TADJIQUISTAO, REPUBLICA DO' },
  { key: '776', value: '776 - TAILANDIA' },
  { key: '780', value: '780 - TANZANIA, REP.UNIDA DA' },
  { key: '791', value: '791 - TCHECA, REPUBLICA' },
  { key: '781', value: '781 - TERRAS AUSTRAIS FRANCESAS' },
  { key: '782', value: '782 - TERRITORIO BRIT.OC.INDICO' },
  { key: '795', value: '795 - TIMOR LESTE' },
  { key: '800', value: '800 - TOGO' },
  { key: '810', value: '810 - TONGA' },
  { key: '805', value: '805 - TOQUELAU,ILHAS' },
  { key: '815', value: '815 - TRINIDAD E TOBAGO' },
  { key: '820', value: '820 - TUNISIA' },
  { key: '823', value: '823 - TURCAS E CAICOS,ILHAS' },
  { key: '824', value: '824 - TURCOMENISTAO, REPUBLICA DO' },
  { key: '827', value: '827 - TURQUIA' },
  { key: '828', value: '828 - TUVALU' },
  { key: '831', value: '831 - UCRANIA' },
  { key: '833', value: '833 - UGANDA' },
  { key: '845', value: '845 - URUGUAI' },
  { key: '847', value: '847 - UZBEQUISTAO, REPUBLICA DO' },
  { key: '551', value: '551 - VANUATU' },
  { key: '848', value: '848 - VATICANO, EST.DA CIDADE DO' },
  { key: '850', value: '850 - VENEZUELA' },
  { key: '858', value: '858 - VIETNA' },
  { key: '863', value: '863 - VIRGENS,ILHAS (BRITANICAS)' },
  { key: '866', value: '866 - VIRGENS,ILHAS (E.U.A.)' },
  { key: '875', value: '875 - WALLIS E FUTUNA, ILHAS' },
  { key: '890', value: '890 - ZAMBIA' },
  { key: '665', value: '665 - ZIMBABUE' },
];

export const codigosTipoProcesso = [
  { key: '1', value: '1 - Administrativo' },
  { key: '2', value: '2 - Declaração Preliminar' },
  { key: '3', value: '3 - Judicial' },
  { key: '4', value: '4 - Judicial Exclusivo DARF' },
];

export const codigosTipoDocumentoInstrucaoDespacho = [{
  key: '06',
  value: 'ADICIONAL DE FRETE PARA RENOVACAO DA MARINHA MERCANTE-AFRMM',
},
{
  key: '11',
  value: 'ATO DECLARAT./CONCESSAO DESPACHO ADUANEIRO SIMPLIFICADO - DAS',
},
{
  key: '05',
  value: 'AUTORIZACAO DA COMISSAO NACIONAL DE ENERGIA NUCLEAR - CNEM',
},
{
  key: '07',
  value: 'AUTORIZACAO DE DESPACHO DO MINISTERIO DA AGRICULTURA',
},
{
  key: '27',
  value: 'AUTORIZACAO DE ENTREGA ANTECIPADA DA MERCADORIA',
},
{
  key: '09',
  value: 'CARTA DE DOACAO',
},
{
  key: '04',
  value: 'CERTIDAO DE QUITACAO DE TRIBUTOS FEDERAIS (CQTF), DA SRF',
},
{
  key: '03',
  value: 'CERTIDAO NEGATIVA DE DEBITO (CND), DO INSS',
},
{
  key: '08',
  value: 'CERTIFICADO DE CLASSIFICACAO DO MINISTERIO DA AGRICULTURA',
},
{
  key: '02',
  value: 'CERTIFICADO DE ORIGEM',
},
{
  key: '30',
  value: 'CERTIFICADO DE ORIGEM DIGITAL (COD)',
},
{
  key: '14',
  value: 'CERTIFICADO DE UTILIZACAO DE COTA',
},
{
  key: '15',
  value: 'CERTIFICADO DO INMETRO',
},
{
  key: '13',
  value: 'CERTIFICADO HOMOLOG. EMITIDO P/ DEPART. DE AVIACAO CIVIL-DAC',
},
{
  key: '28',
  value: 'CONHECIMENTO DE CARGA',
},
{
  key: '16',
  value: 'DEMONSTRATIVO DE APURACAO DO VALOR ADUANEIRO',
},
{
  key: '01',
  value: 'FATURA COMERCIAL',
},
{
  key: '17',
  value: 'FATURA PROFORMA',
},
{
  key: '18',
  value: 'LAUDO EMITIDO PELO MINISTERIO DA SAUDE',
},
{
  key: '21',
  value: 'OFICIO DO CONSELHO NACIONAL DE PESQUISA - CNPQ',
},
{
  key: '20',
  value: 'OFICIO DO MINISTERIO DA EDUCACAO',
},
{
  key: '19',
  value: 'OFICIO DO MINISTERIO DA SAUDE',
},
{
  key: '26',
  value: 'OUTROS DOCUMENTOS EXIGIDOS PELA LEGISLACAO',
},
{
  key: '22',
  value: 'RELACAO DE NOTAS FISCAIS',
},
{
  key: '23',
  value: 'REQUISICAO DO MINISTERIO DAS RELACOES EXTERIORES - REDA',
},
{
  key: '29',
  value: 'ROMANEIO DE CARGA',
},
{
  key: '24',
  value: 'TAXA ADICIONAL DE INDENIZ. DO TRABALHO PORTUARIO AVULSO-AITP',
},
{
  key: '12',
  value: 'TAXA DE ORGANIZACAO E REGULAMENT. MERCADO DA BORRACHA-(TORMB)',
},
{
  key: '10',
  value: 'TERMO DE RESPONSABILIDADE',
},
{
  key: '25',
  value: 'TERMO DE VISTORIA ADUANEIRA',
},
];

export const codigosRegTributacaoII = [
  { key: '1', value: '1 - RECOLHIMENTO INTEGRAL' },
  { key: '2', value: '2 - IMUNIDADE' },
  { key: '3', value: '3 - ISENCÃO' },
  { key: '4', value: '4 - REDUCÃO' },
  { key: '5', value: '5 - SUSPENSÃO' },
  { key: '6', value: '6 - NAO INCIDÊNCIA' },
  { key: '9', value: '9 - PAGAMENTO REALIZADO' },
];

export const tiposRegTributacaoIPI = [
  { key: '4', value: '4 - Recolhimento Integral' },
  { key: '5', value: '5 - Suspensão' },
  { key: '2', value: '2 - Redução' },
  { key: '1', value: '1 - Isenção' },
];

export const codigosRegTributacaoPIS = [
  { key: '1', value: '1 - RECOLHIMENTO INTEGRAL' },
  { key: '2', value: '2 - IMUNIDADE' },
  { key: '3', value: '3 - ISENCÃO' },
  { key: '4', value: '4 - REDUCÃO' },
  { key: '5', value: '5 - SUSPENSÃO' },
  { key: '6', value: '6 - NAO INCIDÊNCIA' },
  { key: '9', value: '9 - PAGAMENTO REALIZADO' },
];

export const tiposAcordos = [
  { key: '2', value: '2 - ALADI' },
  { key: '3', value: '4 - OMC/GATTI' },
  { key: '4', value: '4 - SGPC' },

];

export const tiposAtoLegalNcm = [
  { key: 'AD', value: 'ATO DECLARATORIO' },
  { key: 'ADE', value: 'ATO DECLARATORIO EXECUTIVO' },
  { key: 'ADN', value: 'ATO DECLARATORIO NORMATIVO' },
  { key: 'CCIRC', value: 'CARTA-CIRCULAR' },
  { key: 'CIRC', value: 'CIRCULAR' },
  { key: 'COM', value: 'COMUNICADO' },
  { key: 'CF', value: 'CONSTITUICAO FEDERAL' },
  { key: 'DEC', value: 'DECRETO' },
  { key: 'D LEG', value: 'DECRETO LEGISLATIVO' },
  { key: 'DL', value: 'DECRETO-LEI' },
  { key: 'DH', value: 'DESPACHO HOMOLOGATORIO' },
  { key: 'IN', value: 'INSTRUCAO NORMATIVA' },
  { key: 'LEI', value: 'LEI' },
  { key: 'LC', value: 'LEI COMPLEMENTAR' },
  { key: 'MP', value: 'MEDIDA PROVISORIA' },
  { key: 'NE', value: 'NORMA DE EXECUCAO' },
  { key: 'PR', value: 'PARECER DE RECURSO' },
  { key: 'PN', value: 'PARECER NORMATIVO' },
  { key: 'PS', value: 'PARECER SIMPLES' },
  { key: 'PORT', value: 'PORTARIA' },
  { key: 'PROT', value: 'PROTOCOLO' },
  { key: 'RES', value: 'RESOLUCAO' },
];

export const codigosTipoEmbalagens = [
  { key: '01', value: '01 - AMARRADO/ATADO/FEIXE' },
  { key: '02', value: '02 - BARRICA DE FERRO' },
  { key: '03', value: '03 - BARRICA DE FIBRA DE VIDRO' },
  { key: '06', value: '06 - BARRICA DE OUTROS MATERIAIS' },
  { key: '04', value: '04 - BARRICA DE PAPELAO' },
  { key: '05', value: '05 - BARRICA DE PLASTICO' },
  { key: '07', value: '07 - BAU DE MADEIRA' },
  { key: '08', value: '08 - BAU DE METAL' },
  { key: '09', value: '09 - BAU DE OUTROS MATERIAIS' },
  { key: '10', value: '10 - BIG BAG' },
  { key: '11', value: '11 - BLOCO' },
  { key: '12', value: '12 - BOBINA' },
  { key: '13', value: '13 - BOMBONA' },
  { key: '14', value: '14 - BOTIJAO' },
  { key: '15', value: '15 - CAIXA CORRUGADA' },
  { key: '16', value: '16 - CAIXA DE ISOPOR' },
  { key: '17', value: '17 - CAIXA DE MADEIRA' },
  { key: '18', value: '18 - CAIXA DE METAL' },
  { key: '21', value: '21 - CAIXA DE OUTROS MATERIAIS' },
  { key: '19', value: '19 - CAIXA DE PAPELAO' },
  { key: '20', value: '20 - CAIXA DE PLASTICO' },
  { key: '22', value: '22 - CANUDO' },
  { key: '23', value: '23 - CARRETEL' },
  { key: '24', value: '24 - CILINDRO' },
  { key: '25', value: '25 - CINTADO' },
  { key: '26', value: '26 - ENGRADADO DE MADEIRA' },
  { key: '28', value: '28 - ENGRADADO DE OUTROS MATERIAIS' },
  { key: '27', value: '27 - ENGRADADO DE PLASTICO' },
  { key: '29', value: '29 - ENVELOPE' },
  { key: '30', value: '30 - ESTOJO' },
  { key: '31', value: '31 - ESTRADO' },
  { key: '32', value: '32 - FARDO' },
  { key: '33', value: '33 - FRASCO' },
  { key: '34', value: '34 - GALAO DE METAL' },
  { key: '36', value: '36 - GALAO DE OUTROS MATERIAIS' },
  { key: '35', value: '35 - GALAO DE PLASTICO' },
  { key: '37', value: '37 - GRANEL' },
  { key: '38', value: '38 - LATA' },
  { key: '39', value: '39 - MALA' },
  { key: '40', value: '40 - MALETA' },
  { key: '99', value: '99 - OUTROS' },
  { key: '41', value: '41 - PACOTE' },
  { key: '60', value: '60 - PALLETS' },
  { key: '42', value: '42 - PECA' },
  { key: '43', value: '43 - QUARTOLA' },
  { key: '44', value: '44 - ROLO' },
  { key: '45', value: '45 - SACA' },
  { key: '46', value: '46 - SACO DE ANIAGEM' },
  { key: '47', value: '47 - SACO DE COURO' },
  { key: '48', value: '48 - SACO DE LONA' },
  { key: '49', value: '49 - SACO DE NYLON' },
  { key: '53', value: '53 - SACO DE OUTROS MATERIAIS' },
  { key: '50', value: '50 - SACO DE PAPEL' },
  { key: '51', value: '51 - SACO DE PAPELAO' },
  { key: '52', value: '52 - SACO DE PLASTICO' },
  { key: '54', value: '54 - SACOLA' },
  { key: '55', value: '55 - SAN BAG' },
  { key: '56', value: '56 - TAMBOR DE METAL' },
  { key: '59', value: '59 - TAMBOR DE OUTROS MATERIAIS' },
  { key: '57', value: '57 - TAMBOR DE PAPELAO' },
  { key: '58', value: '58 - TAMBOR DE PLASTICO' },
];

export const codigosMetodoAcrescimos = [
  { key: '1', value: '01 - Comissões e Corretagem' },
  { key: '2', value: '02 - Embalagens e recipientes' },
  { key: '3', value: '03 - Custos de Embalar' },
  { key: '4', value: '04 - Materiais, componentes' },
  { key: '5', value: '05 - Ferramentas, matrizes, moldes' },
  { key: '6', value: '06 - Materiais Consumidos na Produção' },
  { key: '7', value: '07 - Projetos de Engenharia' },
  { key: '8', value: '08 - Royalties e Direitos de Licença' },
  { key: '9', value: '09 - Valores de qualquer parcela' },
  { key: '10', value: '10 - Frete Interno - País de Exportação' },
  { key: '11', value: '11 - Seguro Interno - País de Exportação' },
  { key: '12', value: '12 - Carga/descarga/manuseio - País de Exportação' },
  { key: '16', value: '16 - Carga/descarga/manuseio - País de Importação' },
  { key: '17', value: '17 - Outros Acréscimos' },
];

export const codigosMetodoValoracao = [
  { key: '01', value: 'METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)' },
  { key: '02', value: 'METODO 2 - ART. 2 DO ACORDO (DECRETO 92930/86)' },
  { key: '03', value: 'METODO 3 - ART. 3 DO ACORDO (DECRETO 92930/86)' },
  { key: '04', value: 'METODO 4 - ART. 4 DO ACORDO (DECRETO 92930/86)' },
  { key: '05', value: 'METODO 5 - ART. 5 DO ACORDO (DECRETO 92930/86)' },
  { key: '06', value: 'METODO 6 - ART. 6 DO ACORDO (DECRETO 92930/86)' },
];

export const INCOTERMS = [
  { key: 'EXW', value: 'EXW' },
  { key: 'FCA', value: 'FCA' },
  { key: 'FOB', value: 'FOB' },
  { key: 'FAS', value: 'FAS' },
  { key: 'CFR', value: 'CFR' },
  { key: 'CIF', value: 'CIF' },
  { key: 'CPT', value: 'CPT' },
  { key: 'CIP', value: 'CIP' },
  { key: 'DAT', value: 'DAT' },
  { key: 'DAP', value: 'DAP' },
  { key: 'DDP', value: 'DDP' },
];

export const codigosCoberturaCambial = [
  { key: '1', value: '1 - Até 180 dias' },
  { key: '2', value: '2 - De 181 até 360 dias' },
  { key: '3', value: '3 - Acima de 360 dias' },
  { key: '4', value: '4 - Sem Cobertura' },
];

export const codigosModais = [
  { key: '01', value: '01 - Marítima' },
  { key: '04', value: '04 - Aérea' },
  { key: '07', value: '07 - Rodoviária' },
  { key: '06', value: '06 - Ferroviária' },
  { key: '02', value: '02 - Fluvial' },
  { key: '05', value: '05 - Postal' },
  { key: '03', value: '03 - Lacustre' },
  { key: '09', value: '09 - Meios Próprios' },
  { key: '08', value: '08 - Conduto/Rede de Transmissão' },
  { key: '10', value: '10 - Entrada Ficta' },
  { key: '11', value: '11 - Outros Meios' },
];
