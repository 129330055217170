<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>
    <div class="form-layout form-layout-2">
      <div class="row no-gutters">
        <div class="col-md-6">
          <label class="section-title mg-t-0">Operacional</label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Time / Equipe Operacional
                </label>
                <v-select
                  v-model="company.team"
                  label="name"
                  :options="teams"
                  name="team"
                >
                  <template slot="option" slot-scope="option">
                    {{ option.name + " - " + option.email }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label"
                  >Quantidade Dias p/ Notificação do Numerário</label
                >
                <input
                  class="form-control"
                  type="number"
                  name="siscoserv"
                  v-model="company.numberOfDaysForCashNotification"
                  placeholder="Quantidade Dias p/ Notificação do Numerário"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="section-title mg-t-0">Integração de Software</label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group mg-md-l--1">
                <label class="form-control-label">Código de Integração</label>
                <input
                  class="form-control"
                  type="text"
                  name="integrationCode"
                  v-model="company.integrationCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Frete e Seguro</label>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">Frete</label>
            <select
              class="custom-select form-control"
              v-model="company.proceduresFreightQuotation"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="proceduresFreightQuotation in proceduresFreightQuotations"
                :value="proceduresFreightQuotation.key"
                :key="proceduresFreightQuotation.key"
              >
                {{ proceduresFreightQuotation.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Agente</label>
            <select
              class="custom-select form-control"
              v-model="company.proceduresFreightAgent"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="proceduresFreightAgent in proceduresFreightAgents"
                :value="proceduresFreightAgent.key"
                :key="proceduresFreightAgent.key"
              >
                {{ proceduresFreightAgent.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Seguro</label>
            <select
              class="custom-select form-control"
              v-model="company.proceduresInsuranceQuotation"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="proceduresInsuranceQuotation in proceduresInsuranceQuotations"
                :value="proceduresInsuranceQuotation.key"
                :key="proceduresInsuranceQuotation.key"
              >
                {{ proceduresInsuranceQuotation.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="proceduresFreightAndInsuranceObservations"
              placeholder="Observações"
              v-model="company.proceduresFreightAndInsuranceObservations"
            ></textarea>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Numerário</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-control-label">Tributos</label>
            <select
              class="custom-select form-control"
              v-model="company.appointmentTributes"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="appointmentTribute in appointmentTributes"
                :value="appointmentTribute.key"
                :key="appointmentTribute.key"
              >
                {{ appointmentTribute.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Despesas</label>
            <select
              class="custom-select form-control"
              v-model="company.appointmentExpenses"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="appointmentExpense in genericalList"
                :value="appointmentExpense.key"
                :key="appointmentExpense.key"
              >
                {{ appointmentExpense.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Frete</label>
            <select
              class="custom-select form-control"
              v-model="company.appointmentFreight"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="appointmentFreight in genericalList"
                :value="appointmentFreight.key"
                :key="appointmentFreight.key"
              >
                {{ appointmentFreight.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Honorários</label>
            <select
              class="custom-select form-control"
              v-model="company.appointmentFees"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="appointmentFee in appointmentFees"
                :value="appointmentFee.key"
                :key="appointmentFee.key"
              >
                {{ appointmentFee.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">ICMS</label>
            <select
              class="custom-select form-control"
              v-model="company.appointmentICMS"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="appointmentICMS in appointmentFees"
                :value="appointmentICMS.key"
                :key="appointmentICMS.key"
              >
                {{ appointmentICMS.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="appointmentObservations"
              placeholder="Observações"
              v-model="company.appointmentObservations"
            ></textarea>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Carregamento</label>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">Transportador</label>
            <select
              class="custom-select form-control"
              v-model="company.cargoConveyor"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="cargoConveyor in cargoConveyors"
                :value="cargoConveyor.key"
                :key="cargoConveyor.key"
              >
                {{ cargoConveyor.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Armazenamento</label>
            <v-select
              v-model="company.cargoStorage"
              :on-search="getCompanies"
              label="name"
              :options="optionsCompanies"
              name="customBrokerModel"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Nota Fiscal</label>
            <input
              class="form-control"
              type="text"
              name="siscoserv"
              v-model="company.cargoNF"
              placeholder="Nota Fiscal"
            />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="cargoObservations"
              placeholder="Observações"
              v-model="company.cargoObservations"
            ></textarea>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Declaração de Importação</label>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">Despachante Aduaneiro</label>
            <v-select
              v-model="company.customBrokerModel"
              :on-search="getCompanies"
              label="name"
              :options="optionsCompanies"
              name="customBrokerModel"
            >
              <template slot="option" slot-scope="option">
                {{ option.federalID }} | {{ option.name }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">ICMS</label>
            <select class="custom-select form-control" v-model="company.diICMS">
              <option selected="" value="">Selecione</option>
              <option
                v-for="diICMS in diICMSs"
                :value="diICMS.key"
                :key="diICMS.key"
              >
                {{ diICMS.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Débito</label>
            <select
              class="custom-select form-control"
              v-model="company.diDebit"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="diDebit in diDebits"
                :value="diDebit.key"
                :key="diDebit.key"
              >
                {{ diDebit.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Incluir Seguro na DI</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="diIncludeInsurance"
                    type="radio"
                    v-model="company.diIncludeInsurance"
                    :value="true"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="diIncludeInsurance"
                    type="radio"
                    v-model="company.diIncludeInsurance"
                    :value="false"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Debitar na C/C do Cliente?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input
                    name="diDebitCustomerAccount"
                    type="radio"
                    v-model="company.diDebitCustomerAccount"
                    :value="true"
                  />
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input
                    name="diDebitCustomerAccount"
                    type="radio"
                    v-model="company.diDebitCustomerAccount"
                    :value="false"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Complementares</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="complementary"
              placeholder="Complementares"
              v-model="company.complementary"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="diObservations"
              placeholder="Observações"
              v-model="company.diObservations"
            ></textarea>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20"> Faturamento / Encerramento </label>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">Prazo</label>
            <input
              class="form-control"
              type="text"
              name="billingTerm"
              v-model="company.billingTerm"
              placeholder="Prazo"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Pagamento</label>
            <input
              class="form-control"
              type="text"
              name="billingPayment"
              v-model="company.billingPayment"
              placeholder="Pagamento"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Envio</label>
            <select
              class="custom-select form-control"
              v-model="company.billingSend"
            >
              <option selected="" value="">Selecione</option>
              <option
                v-for="billingSend in billingSends"
                :value="billingSend.key"
                :key="billingSend.key"
              >
                {{ billingSend.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="billingObservation"
              placeholder="Observações"
              v-model="company.billingObservation"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="form-layout form-layout-2">
      <div class="row">
        <div class="col-md-6">
          <label class="section-title mg-t-20"> Exportador </label>

          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label">Responsável Contato</label>
                <select
                  class="custom-select form-control"
                  v-model="company.exporterResponsible"
                >
                  <option selected="" value="">Selecione</option>
                  <option
                    v-for="exporterResponsible in exporterResponsibles"
                    :value="exporterResponsible.key"
                    :key="exporterResponsible.key"
                  >
                    {{ exporterResponsible.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group bd-t-0-force">
                <label class="form-control-label">Observações</label>
                <textarea
                  class="form-control is-valid mg-t-20"
                  name="exporterObservations"
                  placeholder="Observações"
                  v-model="company.exporterObservations"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label class="section-title mg-t-20"> Documentos </label>

          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label">Aprovação</label>
                <select
                  class="custom-select form-control"
                  v-model="company.documentsApprobation"
                >
                  <option disabled value="">Selecione</option>
                  <option
                    v-for="documentsApprobation in exporterResponsibles"
                    :value="documentsApprobation.key"
                    :key="documentsApprobation.key"
                  >
                    {{ documentsApprobation.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group bd-t-0-force">
                <label class="form-control-label">Observações</label>
                <textarea
                  class="form-control is-valid mg-t-20"
                  name="documentsObservation"
                  placeholder="Observações"
                  v-model="company.documentsObservation"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Dados Bancários</label>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-control-label">Banco</label>
            <input
              class="form-control"
              type="text"
              name="financesBank"
              v-model="company.financesBank"
              placeholder="Banco"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Agência</label>
            <input
              class="form-control"
              type="text"
              name="financesAgency"
              v-model="company.financesAgency"
              placeholder="Agência"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Tipo de Conta</label>
            <input
              class="form-control"
              type="text"
              name="financesAccount"
              v-model="company.financesAccountType"
              placeholder="Tipo de Conta"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Número da Conta</label>
            <input
              class="form-control"
              type="text"
              name="financesAccount"
              v-model="company.financesAccount"
              placeholder="Número da Conta"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Nome do Favorecido</label>
            <input
              class="form-control"
              type="text"
              name="financesFavoredName"
              v-model="company.financesFavoredName"
              placeholder="Nome do Favorecido"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
              >Identificação do Favorecido</label
            >
            <input
              class="form-control"
              type="text"
              name="financesFavoredIdentification"
              v-model="company.financesFavoredIdentification"
              placeholder="Identificação do Favorecido"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-t-20-force">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="redirect"
        >
          <i class="fa fa-reply mg-r-5"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import vSelect from "vue-select";
import { EventBus } from "@/events/EventBus.js";

export default {
  name: "general-companies-form-instructions",
  props: ["company", "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      proceduresFreightQuotations: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime s/ Aprovação",
          value: "Prime s/ Aprovação",
        },
        {
          key: "INOVA c/ aprovação",
          value: "INOVA c/ aprovação",
        },
      ],
      proceduresFreightAgents: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova c/ Aprovação",
          value: "Inova c/ Aprovação",
        },
      ],
      proceduresInsuranceQuotations: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
        {
          key: "Sem Seguro",
          value: "Sem Seguro",
        },
      ],
      appointmentTributes: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Débito Cliente",
          value: "Débito Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
      ],
      genericalList: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime",
          value: "Prime",
        },
        {
          key: "Inova",
          value: "Inova",
        },
      ],
      appointmentFees: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Encerramento",
          value: "Encerramento",
        },
        {
          key: "Numerário",
          value: "Numerário",
        },
      ],
      cargoConveyors: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Prime c/ Aprovação",
          value: "Prime c/ Aprovação",
        },
        {
          key: "Prime s/ Aprovação",
          value: "Prime s/ Aprovação",
        },
        {
          key: "Inova c/ Aprovação",
          value: "Inova c/ Aprovação",
        },
        {
          key: "Inova s/ Aprovação",
          value: "Inova s/ Aprovação",
        },
      ],
      diICMSs: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Via Prime",
          value: "Via Prime",
        },
        {
          key: "TTD c/ Recolhimento",
          value: "TTD c/ Recolhimento",
        },
        {
          key: "TTD s/ Recolhimento",
          value: "TTD s/ Recolhimento",
        },
        {
          key: "Integral",
          value: "Integral",
        },
      ],
      diDebits: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Cliente",
          value: "Cliente",
        },
        {
          key: "Numerário",
          value: "Numerário",
        },
      ],
      billingSends: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Email",
          value: "Email",
        },
        {
          key: "Físico",
          value: "Físico",
        },
        {
          key: "Email + Físico",
          value: "Email + Físico",
        },
      ],
      exporterResponsibles: [
        {
          key: "Nenhum",
          value: "Nenhum",
        },
        {
          key: "Responsabilidade do Cliente",
          value: "Responsabilidade do Cliente",
        },
        {
          key: "Responsabilidade da Prime",
          value: "Responsabilidade da Prime",
        },
        {
          key: "Responsabilidade da Inova",
          value: "Responsabilidade da Inova",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      url: `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContaining?name=`,
      optionsCompanies: [],
      teams: [],
    };
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "companies-list",
      });
    },
    onSubmit() {
      EventBus.$emit("companies.Form.onSubmit");
    },

    getCompanies(search) {
      const self = this;

      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          self.optionsCompanies = response.data._embedded.companies;
        })
        .catch((error) => {
          swal(
            "Cadastro de Empresa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },

    getTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`)
        .then((response) => {
          this.teams = response.data._embedded.teams;
        })
        .catch((error) => {
          swal(
            "Cadastro de Empresa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
    this.getTeams();
  },
};
</script>
