const invoicesTableColumns = [
  {
    label: 'Código',
    field: 'id',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Cliente',
    field: 'buyer.completeName',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Nº da Invoice',
    field: 'invoiceNumber',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Data da Invoice',
    field: 'invoiceDate',
    type: 'date',
    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
    dateOutputFormat: 'dd/MM/yyyy',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Vencimento da Invoice',
    field: 'invoiceDue',
    type: 'date',
    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
    dateOutputFormat: 'dd/MM/yyyy',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Nº da PO',
    field: 'poNumber',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Incoterms',
    field: 'incoterm',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    label: 'Total da Invoice',
    field: 'invoiceAmount',
    thClass: 'text-center',
    tdClass: 'text-center',
    formatFn: formatMoney,
    sortable: false,
  },
  {
    label: 'Ações',
    field: 'actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
];

function formatMoney(value) {
  return new Intl.NumberFormat('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 }).format(value);
}

export default invoicesTableColumns;
