<template>
  <div class="container pd-t-30">
    <div class="dash-headline-two">
      <div class="d-flex align-items-center">
        <h2 class="tx-inverse mg-b-0">Painel de Controle</h2>
        <p class="mg-b-0 mg-l-20">
          Operacional / <strong>Painel de Controle Operacional</strong>
        </p>
      </div>
      <div class="d-h-t-right">
        <div class="summary-item">
          <h1>USD {{ taxas.dolar | numeralFormat("0,0.00000") }}</h1>
          <span>
            Taxa
            <br />Dolar
          </span>
        </div>
        <div class="summary-item">
          <h1>EUR {{ taxas.euro | numeralFormat("0,0.00000") }}</h1>
          <span>
            Taxa
            <br />Euro
          </span>
        </div>
        <div class="summary-item">
          <button type="button" class="tax-btn" @click.prevent="$emit('quote-button')">
            <i class="fas fa-chart-line"></i>
            VER COTAÇÕES
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import Axios from 'axios';

const apiHost = config.env.API_DEFAULT.host;

export default {
  name: 'ControlPanelHeadline',

  data() {
    return {
      taxas: {
        dolar: 0,
        euro: 0,
        libra: 0,
        iene: 0,
      },
    };
  },

  methods: {
    async consultarTaxas() {
      Axios.get(`${apiHost}/exchangeRates/cotacoesAtuais`).then((response) => {
        const atuais = response.data.data;

        if (atuais.find((item) => item.code == 220)) {
          this.taxas.dolar = atuais.find((item) => item.code == 220).currentBuyValue;
        }

        if (atuais.find((item) => item.code == 978)) {
          this.taxas.euro = atuais.find((item) => item.code == 978).currentBuyValue;
        }
      });
    },
  },

  async created() {
    this.consultarTaxas();
  },
};
</script>

<style scoped>
  .tax-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 15px;
    font-size: 14px;
    border: 1px solid #343a40;
    font-weight: bold;
    cursor: pointer;
    transition: all .2s ease-in-out 0s;
    background-color: transparent;
  }

  .tax-btn:hover {
    background-color: #343a40;
    color: #ffffff;
  }
</style>
