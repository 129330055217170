<template>
    <div>
        <div class="signin-wrapper">
            <form @submit.prevent="submitForm">
                <div class="signin-box bd-0 wd-450 tx-center" style="background: none !important">
                    <h2 class="signin-title-primary mg-t-40 tx-normal mg-b-20">Recuperação de Senha</h2>
                    <h4 class="signin-title-secondary lh-4 mg-b-20">Informe o código de verificação que recebeu em seu email.</h4>

                    <div class="code-container relative flex1 mb-3">
                        <input
                            ref="inputCode"
                            class="input-code"
                            type="number"
                            @input="inputValue"
                            v-model="form.pin"
                            style="opacity: 0;"
                            @focus="showIndicator = true"
                            @blur="showIndicator = false"
                        >
                        <div class="divider one"></div>
                        <div class="divider two"></div>
                        <div class="divider three"></div>
                        <div class="divider four"></div>
                        <div class="divider five"></div>

                        <span class="code-value one">{{ form.pin.split('')[0] }}</span>
                        <span class="code-value two">{{ form.pin.split('')[1] }}</span>
                        <span class="code-value three">{{ form.pin.split('')[2] }}</span>
                        <span class="code-value four">{{ form.pin.split('')[3] }}</span>
                        <span class="code-value five">{{ form.pin.split('')[4] }}</span>
                        <span class="code-value six">{{ form.pin.split('')[5] }}</span>

                        <div v-if="showIndicator" class="text-indicator" :style="`left: ${form.pin.length === 0 ? 'calc(100% / 12)' : `${form.pin.length >= 6 ? `calc(((100% / 6) * ${form.pin.length -1}) + (100% / 12) + 10px)` : `calc(((100% / 6) * ${form.pin.length}) + (100% / 12))`}` }`"></div>
                    </div>

                    <!-- <div class="form-group">
                        <input type="text" name="pin" class="form-control" v-model="form.pin" placeholder="Informe o código de verificação">
                    </div> -->
                    <button class="btn btn-primary btn-block btn-signin bg-grandeur">Enviar código</button>
                    <p class="mg-t-20 mg-b-0 tx-black tx-normal">
                        <router-link :to="{ name: 'login' }">Voltar para o login</router-link>
                    </p>
                </div>

                <p class="mg-t-20 mg-b-0 tx-12 tx-center">Copyright © {{new Date | moment("YYYY")}} </p>
                <p class="tx-11 tx-center">Todos os direitos reservados à {{tenantName}}.</p>
            </form>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tenantName: config.env.setup.company.name,
      route: '/credential/recovery/verifyCode',
      form: {
        pin: '',
      },
      showIndicator: false,
    };
  },
  methods: {
    inputValue(event) {
      if (event.target.value.length > 6) {
        this.form.pin = event.target.value.substring(0, event.target.value.length - 1);
      }
    },
    async submitForm() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Email de recuperação de senha!',
        description: 'Aguarde o email de recuperação de senha com o código!',
      });

      if (this.form.pin) {
        await Axios.post(`${config.env.API_DEFAULT.host}/credential/recovery/verifyCode`, this.form).then((response) => {
          if (response.data.code === 'SUCCESS') {
            this.$router.push({ name: 'ChangePassword', params: { pin: this.form.pin } });
          }
        }).catch((error) => {
          Swal.fire('Recuperacão de senha', error.response.data.message, 'error');
        });
      } else {
        Swal.fire('Recuperação de senha', 'Código de verificação não informado', 'error');
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>

<style scoped>
    .card-verify-code-recovery-password {
        margin: auto;
        margin-top: 150px;
    }

    .code-container {
        border: 1px solid #e2e2e2;
        position: relative;
        background-color: white;
        overflow: hidden;
    }

    .code-container input {
        width: 100%;
    }

    .input-code {
        text-align: center;
        letter-spacing: 100%;
        color: transparent;
        height: 50px;
    }

    .input-code,
    .input-code::-webkit-outer-spin-button,
    .input-code::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .input-code::-webkit-outer-spin-button,
    .input-code::-webkit-inner-spin-button {
        margin: 0;
    }

    .divider {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e2e2e2;
        pointer-events: none;
    }

    .divider.one {
        left: calc(100% / 6);
    }

    .divider.two {
        left: calc((100% / 6) * 2);
    }

    .divider.three {
        left: calc((100% / 6) * 3);
    }

    .divider.four {
        left: calc((100% / 6) * 4);
    }

    .divider.five {
        left: calc((100% / 6) * 5);
    }

    .code-value {
        position: absolute;
        top: 0;
        bottom: 0;
        line-height: 50px;
        font-size: 1.5rem;
        font-weight: 600;
        transform: translateX(-50%);
        pointer-events: none;
    }

    .code-value.one {
        left: calc(100% / 12);
    }

    .code-value.two {
        left: calc((100% / 12) * 3);
    }

    .code-value.three {
        left: calc((100% / 12) * 5);
    }

    .code-value.four {
        left: calc((100% / 12) * 7);
    }

    .code-value.five {
        left: calc((100% / 12) * 9);
    }

    .code-value.six {
        left: calc((100% / 12) * 11);
    }

    .text-indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 40px;
        width: 1px;
        background-color: black;
        animation: .7s ease-in-out 0s infinite inputAnimation;
    }

    @keyframes inputAnimation {
        0% {
        opacity: 0;
        }
        50% {
        opacity: 1;
        }
        100% {
        opacity: 0;
        }
    }
</style>
