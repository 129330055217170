<template>
  <form id="di-form-upload" v-bind:class="{ 'no-click': noClick }">
    <div class="form-layout form-layout-2 pd-b-40">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">Complementares</h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Informações
            complementares baseadas nos dados do Processo de Importação e da
            Declaração de Importação.
          </p>
        </div>
        <div>
          <a
            href="#"
            class="btn btn-primary"
            @click.prevent="gerarComplementares"
          >
            <i class="icon fa fa-signing tx-22"></i> GERAR COMPLEMENTARES
          </a>
        </div>
      </div>

      <hr />

      <div class="row mg-t-20">
        <div class="col-lg-12">
          <textarea
            v-model="model.complementares"
            rows="40"
            class="form-control tx-thin"
            placeholder="Nenhum informação complementar foi gerada até o momento."
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

import Swal from "sweetalert2";

export default {
  name: "di-form-complementares",
  props: ["noClick"],
  components: {},
  data() {
    return {
      model: {},
      initialModel: {},
    };
  },
  methods: {
    async gerarComplementares() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch(
          "MyIMPStore/gerarComplementares",
          { id: this.activeDI.id }
        );

        if (response) {
          this.model.complementares = response.data.toUpperCase();
          if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== " "
          )
            Swal.fire("Gerar Complementares", response.message, "warning");
        } else {
          Swal.fire(
            "Gerar Complementares",
            "Não foi possível gerar as complementares dessta DI",
            "error"
          );
        }
      }
    },
    async diffObjects(fieldName) {
      const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
        fieldName,
      });

      if (response) {
        this.initialModel = {
          ...this.model,
        };
      }
    },
  },
  computed: {
    ...mapState({
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
  },
  watch: {
    activeDI() {
      this.initialModel = {
        ...this.activeDI,
      };
      this.model = {
        ...this.activeDI,
      };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-upload");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.activeDI,
    };
  },
};
</script>

<style scoped>
textarea {
  font-size: 13px !important;
  font-weight: 100 !important;
  font-family: monospace !important;
}

#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
