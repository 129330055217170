const CONTACT_COLUMNS = [
  {
    name: 'Nome', field: 'name', hide: false, type: 'string',
  },
  {
    name: 'Empresa(s)', field: 'companies', hide: false, type: 'string',
  },
  { name: 'Gênero', field: 'gender', hide: true },
  {
    name: 'Data de Nascimento',
    field: 'dateOfBirth',
    hide: false,
    type: 'date',
  },
  {
    name: 'Data de Contratação',
    field: 'hiringDate',
    hide: true,
    type: 'date',
  },
  { name: 'Email Principal', field: 'email1', hide: false },
  { name: 'Email Secundário', field: 'email2', hide: true },
  { name: 'Telefone Fixo', field: 'telephone1', hide: true },
  { name: 'Telefone Celular', field: 'telephone2', hide: true },
  {
    name: 'Permite Contato?',
    field: 'allowsContactViaExternalApplications',
    hide: true,
    type: 'boolean',
  },
  { name: 'Setor / Departamento', field: 'jobRole', hide: true },
  { name: 'Categoria de Contato', field: 'category', hide: true },
  {
    name: 'Contato Arquivado', field: 'archived', hide: true, type: 'boolean',
  },
  {
    name: 'Login Habilitado', field: 'enabled', hide: true, type: 'boolean',
  },
  {
    name: 'Receber FUP', field: 'followUP', hide: true, type: 'boolean',
  },
  {
    name: 'FUP Sintético',
    field: 'followUPSynthetic',
    hide: true,
    type: 'boolean',
  },
  {
    name: 'FUP Sintético Período',
    field: 'followUPSyntheticPeriod',
    hide: true,
    type: 'boolean',
  },
  {
    name: 'FUP Sintético Dia da Semana',
    field: 'followUPSyntheticWeekday',
    hide: true,
    type: 'boolean',
  },
  { name: 'CEP', field: 'postalCode', hide: true },
  { name: 'Logradouro', field: 'address', hide: true },
  { name: 'Número', field: 'addressNumber', hide: true },
  { name: 'Complemento', field: 'complement', hide: true },
  { name: 'Bairro', field: 'district', hide: true },
  { name: 'Cidade', field: 'city', hide: true },
  { name: 'Estado', field: 'federalUnity', hide: true },
  {
    name: 'Recebe NPS', field: 'receiveNps', hide: true, type: 'boolean',
  },
];

export default CONTACT_COLUMNS;
