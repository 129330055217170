var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
    'v-checkbox',
    _vm.small ? 'small' : ''
  ],style:(_vm.checked ? `background-color: ${_vm.color}2f; border: none;` : ``),on:{"mouseenter":_vm.inputFocus,"mouseleave":_vm.inputBlur}},[_c('input',{attrs:{"type":"checkbox","name":_vm.name},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.handleInput($event.target.checked)}}}),(!_vm.checked)?_c('span',{staticClass:"tx-normal"},[_vm._v(_vm._s(_vm.label))]):_c('span',{staticClass:"tx-normal tx-gray-700"},[_vm._v(_vm._s(_vm.label))]),(_vm.hint.length > 0)?_c('div',{class:[
      'hint',
      _vm.hintActive ? 'active' : ''
    ]},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }