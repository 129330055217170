<template>
  <svg :width="size" :height="size * 0.6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L9 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronDown',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
