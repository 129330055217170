<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'operational-steps-list' }"
              >Eventos de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <form-general
                :model="model"
                :processesFields="this.processesFields"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import FormGeneral from "./Form.General.vue";

export default {
  name: "operational-steps-form",
  components: {
    "form-general": FormGeneral,
  },
  data() {
    return {
      mainTitle: "Etapas de Processos",
      modelTitle: "Etapa de Processo",
      model: {
        enabled: true,
      },
      processesFields: [],
      oldModel: {},
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/processSteps/${this.$route.params.id}`
      )
        .then((response) => {
          this.model = response.data;
        })
        .catch((error) => {
          this.$swal(
            "Passo de Processo",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    loadField() {
      Axios.get(`${config.env.API_DEFAULT.host}/process/import/fields`)
        .then((response) => {
          const array = response.data.data;
          this.processesFields = Object.keys(array).map((key) => array[key]);
        })
        .catch((error) => {
          this.$swal(
            "Passo de Processo",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
      this.loadField();
    } else {
    }
  },
};
</script>
