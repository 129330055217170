<template>
  <div class="contact-info">
    <div class="contact-excerpt">
      <div class="contact-resume">
        <h3 class="text-uppercase">{{ contactDetails.name }}</h3>
        <strong class="contact-role" v-if="contactDetails.role === 'CUSTOMER'"
          >Cliente (Ext)</strong
        >
        <strong
          class="contact-role"
          v-else-if="contactDetails.role === 'PARTNER'"
          >Parceiro (Ext)</strong
        >
        <strong
          class="contact-role"
          v-else-if="contactDetails.role === 'CUSTOM_BROKER'"
          >Despachante (Ext)</strong
        >
        <p class="contact-sector">{{ contactDetails.sector }}</p>

        <div
          class="birthday"
          v-if="
            contactDetails.dateOfBirth &&
            checkBirthday(contactDetails.dateOfBirth).birthday === true
          "
        >
          <cake-icon color="#fff" :size="32" />
          <span
            >Está fazendo
            {{ checkBirthday(contactDetails.dateOfBirth).years }} anos
            hoje</span
          >
        </div>

        <div class="contact-information">
          <h5 class="contact-subtitle">Contato Rápido</h5>
          <p class="contact-subtitle-desc">
            Informações de Telefone, Email e Redes Sociais
          </p>
          <ul>
            <li v-if="contactDetails.telephone1">
              <a
                :href="`https://wa.me/+55${clearPhone(
                  contactDetails.telephone1
                )}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <whatsapp-icon />
                {{ contactDetails.telephone1 }}
                <small>Comercial</small>
              </a>
            </li>
            <li v-if="contactDetails.telephone2">
              <a
                :href="`https://wa.me/+55${clearPhone(
                  contactDetails.telephone2
                )}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <whatsapp-icon />
                {{ contactDetails.telephone2 }}
                <small>Whatsapp</small>
              </a>
            </li>
            <li v-if="contactDetails.email1">
              <a :href="`mailto:${contactDetails.email1}`">
                <email-icon />
                {{ contactDetails.email1 }}
              </a>
            </li>
            <li v-if="contactDetails.email2">
              <a :href="`mailto:${contactDetails.email2}`">
                <email-icon />
                {{ contactDetails.email2 }}
              </a>
            </li>
            <!-- {{ contactDetails }} -->
            <li v-if="contactDetails.instagramURL">
              <a
                :href="`https://www.instagram.com/${contactDetails.instagramURL}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <instagram-icon />
                <strong>{{ contactDetails.instagramURL }}</strong>
                <small>(Instagram)</small>
              </a>
            </li>
            <li v-if="contactDetails.facebookURL">
              <a
                :href="`https://www.facebook.com/${contactDetails.facebookURL}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <facebook-icon />
                <strong>{{ contactDetails.facebookURL }}</strong>
                <small>(Facebook)</small>
              </a>
            </li>
            <li v-if="contactDetails.linkedInURL">
              <a
                :href="`https://www.linkedin.com/in/${contactDetails.linkedInURL}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <linkedin-icon />
                <strong>{{ contactDetails.linkedInURL }}</strong>
                <small>(Linkedin)</small>
              </a>
            </li>
            <li v-if="contactDetails.twitterURL">
              <a
                :href="`https://www.twitter.com/${contactDetails.twitterURL}`"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <twitter-icon />
                <strong>{{ contactDetails.twitterURL }}</strong>
                <small>(Twitter)</small>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="divider"></div>

      <div class="contact-card activities">
        <h5 class="contact-subtitle">Últimas atualizações</h5>
        <p class="contact-subtitle-desc mb-4">
          Últimas <span>ATUALIZAÇÕES</span> envolvendo a
          <b>{{ contactDetails.name }}</b> ou algumas das empresas que ela está
          cadastrada.
        </p>

        <ul>
          <li
            class="activity-item"
            v-for="activity in lastActivities"
            :key="activity.id"
          >
            <div
              v-if="activity.userWhoCreated.photo"
              class="letter"
              :style="`background: url('data:image/png;base64,${activity.userWhoCreated.photo}') center center / cover no-repeat;`"
            ></div>
            <div class="letter" v-else>
              {{ activity.userWhoCreated.name.split("")[0] }}
            </div>
            <div class="content">
              <div class="content-head">
                <h6>{{ activity.subject }}</h6>
              </div>
              <div class="text-secondary mb-2">
                <span
                  >Data da atividade:
                  {{ activity.dateOfActivity | formatData }}</span
                >
              </div>

              <div v-html="activity.description"></div>

              <div class="content-footer">
                <p>
                  {{ activity.createdBy }} em
                  {{ activity.created | formatData }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import {
  CakeIcon,
  WhatsappIcon,
  EmailIcon,
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "@/components/crm/Icons";

export default {
  name: "ContactInfo",
  props: {
    contactDetails: {
      type: Object,
      default: () => {},
    },
    lastActivities: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // Icons
    CakeIcon,
    WhatsappIcon,
    EmailIcon,
    InstagramIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
  },
  filters: {
    formatData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  methods: {
    checkBirthday(date) {
      const today = moment();
      const birth = moment(date);

      if (birth.isValid()) {
        if (today.month() == birth.month() && today.date() == birth.date()) {
          return {
            birthday: true,
            years: today.diff(birth, "years"),
          };
        }
      }

      return {
        birthday: false,
      };
    },
    bgImage(url) {
      return `background: url(${url}) center center / cover no-repeat;`;
    },
    clearPhone(phone) {
      if (!phone) return "";
      return phone.replace(/\s|\(|\)|-/g, "");
    },
  },
};
</script>

<style scoped>
.divider {
  height: 1px;
  margin: 0 10px;
  background-color: #e2e2e2;
}

.contact-info {
  height: calc(100vh - 152px);
  overflow: auto;
  flex: 0 0 340px;
  border-left: 1px solid #e2e2e2;
  height: calc(100vh - 152px);
  overflow: auto;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  background-color: #fcfcfc;
}

.contact-info::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.contact-info::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 4px;
}

.contact-excerpt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-excerpt > div:not(.divider) {
  padding: 10px 30px;
}

.contact-resume {
  text-align: center;
  color: #343a40;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/img/bg-contact-info.png") center center / cover
    no-repeat;
}

.contact-resume .birthday {
  background-color: #dbb058;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 20px;
  font-weight: bold;
  color: white;
}

.contact-subtitle {
  font-size: 1rem;
  font-weight: 700;
  color: #343a40;
  margin-bottom: 3px;
}

.contact-subtitle-desc {
  color: #868ba1;
  font-size: 0.8rem;
}

.contact-subtitle-desc span {
  font-weight: bold;
  color: #104375;
}

.contact-information {
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.contact-information ul,
.contact-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-information ul li:not(:last-of-type),
.contact-card ul li:not(:last-of-type) {
  margin-bottom: 10px;
}

.contact-information ul li a,
.contact-card ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #343a40;
}

.contact-card {
  border-bottom: 1px solid #dee2e6;
}

.contact-card.notes strong {
  color: #343a40;
}

.contact-card p {
  margin-bottom: 10px;
}

.activity-item {
  color: #343a40;
  display: flex;
  gap: 10px;
  position: relative;
}

.activity-item::before {
  content: "";
  display: block;
  width: 3px;
  height: calc(100% - 30px);
  position: absolute;
  top: 40px;
  left: 23px;
  z-index: 1;
  border: 2px dashed #dbb058;
}

.activity-item:last-of-type::before {
  height: calc(100% - 60px);
}

.activity-item:last-of-type::after {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23DBB058' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
    center center / cover no-repeat;
  position: absolute;
  left: 7px;
  z-index: 2;
  bottom: 0;
}

.activity-item > * {
  position: relative;
  z-index: 2;
}

.activity-item .letter {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: 3px solid #104375;
  background-color: #104375;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

.activity-item h6 {
  color: #343a40;
  font-weight: bold;
  margin-bottom: 0;
}

.activity-item .content-footer {
  font-size: 0.8rem;
  color: #868ba1;
}
</style>
