<template>
  <div class="original-docs-tabs">
    <ul class="tab-list">
      <li class="flex ai-c" v-if="$route.meta.isDocumentSearch">
        <a href="#" @click.self.prevent class="flex ai-c fgap1 router-link-exact-active">
          <span @click.self.prevent>RESULTADOS DE BUSCA</span>
          <span @click.self.prevent>
            <reject-icon @click.native.prevent="$router.push({ name: 'RecentOriginalDocuments' })" size="14" />
          </span>
        </a>
      </li>
      <li>
        <RouterLink :to="{ name: 'RecentOriginalDocuments' }">ENVIOS RECENTES</RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'ToRevisionOriginalDocuments' }">A REVISAR</RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'ApprovedOriginalDocuments' }">APROVADOS</RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'RejectedOriginalDocuments' }">REPROVADOS</RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'CreatedOriginalDocuments' }">FINALIZADOS</RouterLink>
      </li>
    </ul>
  </div>
</template>

<script>
import RejectIcon from '@/components/icons/RejectIcon.vue';

export default {
  name: 'OriginalDocumentsTabs',
  components: { RejectIcon },
};
</script>

<style scoped>
  .original-docs-tabs {
    border-bottom: 2px solid #CED4DA;
    margin-bottom: .5rem;
  }

  .original-docs-tabs,
  .tab-list {
    display: flex;
    align-items: center;
  }

  .tab-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .tab-list li a {
    display: flex;
    color: #868BA1;
    position: relative;
    padding: 0.5rem 1rem;
  }

  .tab-list li a.router-link-exact-active,
  .tab-list li a:hover {
    color: black;
  }

  .tab-list li a.router-link-exact-active::after,
  .tab-list li a:hover::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    height: 3px;
    background-color: black;
  }
</style>
