<template>
  <div class="config-sidebar">
    <ul>
      <li class="config-sector">
        <h5>Configurações de Acesso</h5>
      </li>

      <li>
        <a
            :class="{ active: current === 'ConfigAccessUsersTab' }"
            href="#"
            @click.prevent="$emit('changeTab', 'ConfigAccessUsersTab')"
            v-if="hasPrivilege('CONFIGURACOES_USUARIOS_CRUD')"
        >
          <div class="config-circle">
            <config-users-icon :size="20"/>
          </div>
          <div>
            <p>Usuários de Acesso</p>
            <span>Gerenciar usuários</span>
          </div>
        </a>

        <a
            class="disabled"
            href="#"
            @click.prevent
            v-else
        >
          <div class="config-circle">
            <config-users-icon :size="20"/>
          </div>
          <div>
            <p>Usuários de Acesso</p>
            <span>Gerenciar usuários</span>
          </div>
        </a>
      </li>
      <li>
        <a
            :class="{ active: current === 'ConfigAccessGroupsTab' }"
            href="#"
            @click.prevent="$emit('changeTab', 'ConfigAccessGroupsTab')"
            v-if="hasPrivilege('CONFIGURACOES_GRUPOS_CRUD')"
        >
          <div class="config-circle">
            <config-group-icon :size="20"/>
          </div>
          <div>
            <p>Grupos de Acesso</p>
            <span>Gerenciar grupos</span>
          </div>
        </a>

        <a
            class="disabled"
            href="#"
            @click.prevent
            v-else
        >
          <div class="config-circle">
            <config-group-icon :size="20"/>
          </div>
          <div>
            <p>Grupos de Acesso</p>
            <span>Gerenciar grupos</span>
          </div>
        </a>
      </li>

      <li class="config-sector">
        <h5>Configurações Operacionais</h5>
      </li>

      <li>
        <a
            :class="{ active: current === 'ConfigTeamTab' }"
            href="#"
            @click.prevent="$emit('changeTab', 'ConfigTeamTab')"
            v-if="hasPrivilege('CONFIGURACOES_TIMES_CRUD')"
        >
          <div class="config-circle">
            <config-member-icon :size="20"/>
          </div>
          <div>
            <p>Time Operacional</p>
            <span>Gerenciar time</span>
          </div>
        </a>

        <a
            class="disabled"
            href="#"
            @click.prevent
            v-else
        >
          <div class="config-circle">
            <config-member-icon :size="20"/>
          </div>
          <div>
            <p>Time Operacional</p>
            <span>Gerenciar time</span>
          </div>
        </a>
      </li>

      <li class="config-sector">
        <h5>Configurações Administrativas</h5>
      </li>

      <li>
        <a
            :class="{ active: current === 'ConfigCompanyTab' }"
            href="#"
            @click.prevent="$emit('changeTab', 'ConfigCompanyTab')"
            v-if="hasPrivilege('CONFIGURACOES_UNIDADES_EMPRESA_CRUD')"
        >
          <div class="config-circle">
            <config-company-icon :size="20"/>
          </div>
          <div>
            <p>Unidades da Empresa</p>
            <span>Gerenciar unidades</span>
          </div>
        </a>

        <a
            class="disabled"
            href="#"
            @click.prevent
            v-else
        >
          <div class="config-circle">
            <config-company-icon :size="20"/>
          </div>
          <div>
            <p>Unidades da Empresa</p>
            <span>Gerenciar unidades</span>
          </div>
        </a>
      </li>
      <li>
        <a
            :class="{ active: current === 'GeneralLogsTab' }"
            href="#"
            @click.prevent="$emit('changeTab', 'GeneralLogsTab')"
            v-if="hasPrivilege('AUDITORIA_GERAL')"
        >
          <div class="config-circle">
            <i class="fas fa-list"></i>
          </div>
          <div>
            <p>Auditoria Geral</p>
            <span>Logs Gerais do Sistema</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

import ConfigUsersIcon from '@/components/Configuration/icons/ConfigUsersIcon.vue';
import ConfigGroupIcon from '@/components/Configuration/icons/ConfigGroupIcon.vue';
import ConfigMemberIcon from '@/components/Configuration/icons/ConfigMemberIcon.vue';
import ConfigCompanyIcon from '@/components/Configuration/icons/ConfigCompanyIcon.vue';
import CredentialService from '@/services/CredentialService';

export default {
  name: 'ConfigSidebar',
  components: {
    ConfigCompanyIcon, ConfigMemberIcon, ConfigGroupIcon, ConfigUsersIcon,
  },
  props: {
    current: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
  },
};
</script>

<style scoped>
.config-sidebar {
  padding: 1.25rem 0.75rem;
  grid-column: span 3;
  border-right: 1px solid #e2e2e2;
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.config-sector:not(:first-of-type) {
  margin-top: 1rem;
}

ul a {
  padding: 0.35rem 0.5rem;
  border-radius: 10px;
  font-size: 0.9rem;
  color: var(--header-text-color-hover);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: bold;

  background: transparent;
  transition: all .2s ease-in-out 0s;
}

ul a:hover,
ul a.active {
  background-color: var(--shortcut-bg-header);
}

ul a.disabled {
  opacity: 0.5;
}

ul a p {
  margin-bottom: -5px;
}

ul a span {
  display: inline-block;
  font-size: 0.8rem;
  color: #6a6a6a;
  font-weight: 400;
  line-height: 0.8rem;
}

h5 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black;
}

.config-circle {
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--shortcut-round-bg);
  color: var(--header-text-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .2s ease-in-out 0s;
}

ul a.active .config-circle,
ul a:hover .config-circle {
  background-color: var(--primary-bg-header);
  color: white;
}
</style>
