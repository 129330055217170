<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Geral</a></li>
          <li class="breadcrumb-item">
            <a href="#">Cadastros Administrativos</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ modelTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i> Geral
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i> Histórico de Acesso
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <form-general
                :modelUpdated="model"
                :isNewRecord="isNewRecord()"
                :usersScreen="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import UtilsService from "@/services/UtilsService.js";

import FormGeneral from "@/views/general/contacts/Form.General.vue";

export default {
  name: "configuration-accounts-form",
  components: {
    "form-general": FormGeneral,
  },
  data() {
    return {
      modelTitle: this.isNewRecord() ? "Adicionar Contato" : "Editar Contato",
      mainTitle: "Contatos",
      model: {},
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    async loadModel() {
      Axios.get(`${config.env.API_DEFAULT.host}/users/${this.$route.params.id}`)
        .then((response) => {
          this.model = response.data;
        })
        .catch((e) => {
          this.$swal(this.mainTitle, `Ops, houve algum erro: ${e}`, "error");
        });
    },
  },
  mounted() {
    if (this.$route.fullPath === "/configuration/profile") {
    }

    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
