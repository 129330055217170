<template>
  <div class="modal-dialog mg-0">
    <div class="modal-content bd-0">
      <div class="modal-body pd-10">
        <div class="light-bg p-2 bd-b">
          <div class="row">
            <div class="col-4">
              <button
                type="button"
                class="report-button small"
                @click.prevent="filterOpen = !filterOpen"
              >
                <span class="mr-2"> Filtros </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="16px"
                  height="16px"
                >
                  <path
                    fill="currentColor"
                    d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
                  />
                </svg>
              </button>
            </div>
            <div class="col-8 pd-5-force pd-r-20-force tx-right tx-light">
              <span
                class="tx-uppercase tx-13"
                v-if="customersList.length > 0 && customersList.length > 1"
              >
                Exibindo {{ customersList.length }} empresas
              </span>
              <span
                class="tx-uppercase tx-13"
                v-else-if="
                  customersList.length > 0 && customersList.length == 1
                "
              >
                Apenas {{ customersList.length }} empresa foi encontrada
              </span>
            </div>
          </div>

          <div v-if="filterOpen">
            <div class="mt-3 mb-4 bd-t pd-t-30">
              <div class="row">
                <div class="col-12">
                  <select-wrapper
                    name="enabled"
                    label="Situação"
                    :clearable="true"
                    :value="crmCustomersFilters.enabled"
                    @input="inputChange($event, 'enabled')"
                    :options="[
                      {
                        label: 'Habilitada',
                        value: true,
                      },
                      {
                        label: 'Desabilitada',
                        value: 'false',
                      },
                    ]"
                  ></select-wrapper>
                </div>
              </div>

              <!-- <div class="row mg-t-10">
                <div class="col-12">
                  <v-switch
                      name="isCustomer"
                      :labels="{
                        checked: 'Cliente',
                        unchecked: 'Cliente',
                      }"
                      :value="crmCustomersFilters.isCustomer"
                      @input="inputChange($event, 'isCustomer')"
                  />
                </div>
                <div class="col-12">
                  <v-switch
                      name="isProvider"
                      :labels="{
                        checked: 'Fornecedor da Empresa',
                        unchecked: 'Fornecedor da Empresa',
                      }"
                      :value="crmCustomersFilters.isProvider"
                      @input="inputChange($event, 'isProvider')"
                  />
                </div>
                <div class="col-12">
                  <v-switch
                      name="isCustomBroker"
                      :labels="{
                        checked: 'Despachante Aduaneiro',
                        unchecked: 'Despachante Aduaneiro',
                      }"
                      :value="crmCustomersFilters.isCustomBroker"
                      @input="inputChange($event, 'isCustomBroker')"
                  />
                </div>

                <div class="col-12">
                  <v-switch
                      name="isForwarder"
                      :labels="{
                        checked: 'Forwarder',
                        unchecked: 'Forwarder',
                      }"
                      :value="crmCustomersFilters.isForwarder"
                      @input="inputChange($event, 'isForwarder')"
                  />
                </div>

                <div class="col-12">
                  <v-switch
                      name="isCommissioned"
                      :labels="{
                        checked: 'Comissionado',
                        unchecked: 'Comissionado',
                      }"
                      :value="crmCustomersFilters.isCommissioned"
                      @input="inputChange($event, 'isCommissioned')"
                  />
                </div>

                <div class="col-12">
                  <v-switch
                      name="isExporter"
                      :labels="{
                        checked: 'Exportador / Fabricante',
                        unchecked: 'Exportador / Fabricante',
                      }"
                      :value="crmCustomersFilters.isExporter"
                      @input="inputChange($event, 'isExporter')"
                  />
                </div>

                <div class="col-12">
                  <v-switch
                      name="isConveyor"
                      :labels="{
                        checked: 'Transportador',
                        unchecked: 'Transportador',
                      }"
                      :value="crmCustomersFilters.isConveyor"
                      @input="inputChange($event, 'isConveyor')"
                  />
                </div>

                <div class="col-12">
                  <v-switch
                      name="isShipowner"
                      :labels="{
                        checked: 'Armador',
                        unchecked: 'Armador',
                      }"
                      :value="crmCustomersFilters.isShipowner"
                      @input="inputChange($event, 'isShipowner')"
                  />
                </div>
              </div> -->
            </div>

            <div class="row mb-4">
              <div class="col-3"></div>
              <div class="col-6">
                <button
                  class="btn btn-oblong btn-small bg-teal wd-100p tx-white"
                  @click.prevent="generate"
                >
                  CARREGAR
                </button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectWrapper from "@/components/SelectWrapper";
import moment from "moment";
import contactColumns from "@/utils/customerColumns";
import { mapActions, mapState } from "vuex";

export default {
  name: "CustomerListFilter",
  components: {
    SelectWrapper,
  },
  data() {
    return {
      filterOpen: false,
      addDialog: true,
      generatedInTableCompleted: false,
      contacts: [],
      customers: [],
      model: {
        initialOpeningDate: null,
        finalOpeningDate: null,
      },
      timer: null,
      search: "",
      searching: false,
      columns: contactColumns,
      filter: {},
    };
  },
  methods: {
    ...mapActions(["updateCrmCustomersFilter"]),
    formatCustomerCnpj(value) {
      if (value) {
        return String(value).replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      }
      return "N/I";
    },
    async generate() {
      this.$emit("filterCompanies");

      this.filterOpen = false;
    },
    inputChange(value, name) {
      this.updateCrmCustomersFilter({
        ...this.crmCustomersFilters,
        [name]: value,
      });
    },
    formatDateMethod(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format("DD/MM/YYYY");
      }
      return "N/I";
    },
  },
  filters: {
    formatDate(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format("DD/MM/YYYY");
      }
      return "N/I";
    },
  },
  computed: {
    ...mapState(["customersList", "crmCustomersFilters"]),
    selectedColumns() {
      return this.columns.filter((col) => col.hide === false);
    },
    availableColumns() {
      return this.columns.filter((col) => col.hide === true);
    },
    excelFields() {
      const obj = {};
      this.selectedColumns.map((col) => {
        Object.assign(obj, { [col.name]: col.field });
        return null;
      });
      return obj;
    },
    excelContacts() {
      return this.contacts.map((contact) => {
        const info = contact;

        for (let i = 0; i < Object.keys(contact).length; i++) {
          const key = Object.keys(contact)[i];
          const cKey = this.selectedColumns.find((sel) => sel.field == key);

          if (cKey && cKey.type === "date") {
            info[key] = this.formatDateMethod(info[key]);
          }

          if (cKey && cKey.type === "boolean") {
            info[key] = info[key] ? "Sim" : "Não";
          }
        }

        return info;
      });
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

.plus-button:hover {
  background-color: #1768a7;
}

.plus-button.success {
  background-color: #80b7e2;
}

.four-grid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.fg-2 {
  grid-column: span 2;
}

.fg-3 {
  grid-column: span 3;
}

.fg-4 {
  grid-column: span 4;
}

fieldset {
  display: block;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

fieldset legend {
  background-color: transparent;
  font-size: 14px;
  width: auto;
  padding: 0 5px;
}

.columns-list {
  list-style: none;
  padding: 0;
  max-height: 270px;
  overflow: auto;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.columns-list::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.columns-list::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.columns-list li a {
  display: block;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e2e2e2;
  color: inherit;
}

.columns-list li a:hover {
  background-color: #dbdbdb;
}

.columns-list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.columns-list.selecteds li a {
  color: black;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}

.report-button.small {
  padding: 5px 15px;
  font-size: 0.8rem;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 10px 5px;
  color: #343a40;
  text-align: center;
  font-size: 0.8rem;
}

table tr th {
  background-color: #8392a51a;
  min-width: 150px;
}

.table-container {
  width: 100%;
  overflow: auto;
  height: 500px;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}
</style>
