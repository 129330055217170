<template>
  <div class="contact-form">
    <div class="sp-3 light-bg p-3 bd-b">
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">SOLICITAÇÕES DE DADOS</h4>
          <p class="mb-0">
            <strong>{{ requests.length }}</strong> solicitações encontradas para
            <strong>{{ contactDetails.name }}</strong>
          </p>
        </div>

        <div>
          <button
            type="button"
            class="btn-sm report-button"
            @click.prevent="makeRequestData"
          >
            Solicitar
          </button>
        </div>
      </div>
    </div>
    <div class="ph-2 sp-3">
      <div class="table-overflow">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Valido até</th>
              <th>Link do formulário</th>
              <th>Respondido</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in requests" :key="request.id">
              <td>{{ request.id }}</td>
              <td>{{ request.sendedDate | formatDateTime }}</td>
              <td>
                <a :href="`${urlCliPages}/contactData/${request.token}`">{{
                  (urlCliPages + "/contactData/" + request.token).slice(0, 30) +
                  "..."
                }}</a>
              </td>
              <td>
                <i
                  class="fa fa-check text-success"
                  v-if="request.answers.length > 0"
                ></i>
                <i class="fa fa-times text-danger" v-else></i>
              </td>
              <td>
                <button
                  class="btn btn-sm rounded btn-success"
                  v-if="request.answers.length > 0"
                  @click.prevent="openAnswerModal(request.answers[0].id)"
                >
                  <i class="fa fa-eye"></i>
                </button>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- TODO: Modal Content Layout -->
    <v-modal
      :title="`Dados da solicitação #${
        answerData ? answerData.request.id : ''
      }`"
      description="Dados enviados pelo contato"
      :width="800"
      :handler="answerModalOpen"
      @requestClose="closeAnswerModal"
    >
      <template #content>
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="details-label">Nome</p>
              <p>{{ answerDataContent.name }}</p>
            </div>
            <div class="col">
              <p class="details-label">Telefone</p>
              <p>{{ answerDataContent.telephone2 }}</p>
            </div>
            <div class="col">
              <p class="details-label">Data de Nascimento</p>
              <p>{{ answerDataContent.dateOfBirth | formatDate }}</p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <p class="details-label">Data de Contratação</p>
              <p>{{ answerDataContent.hiringDate | formatDate }}</p>
            </div>
            <div class="col">
              <p class="details-label">Cargo</p>
              <p>{{ answerDataContent.jobRole }}</p>
            </div>
            <div class="col">
              <p class="details-label">Setor</p>
              <p>{{ answerDataContent.sector }}</p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <p class="text-center">
          Envio em {{ answerData.sendedDate | formatDateTime }}
        </p>
      </template>
    </v-modal>
  </div>
</template>

<script>
import config from '@/config.js';
import Axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import VModal from '@/components/VModal.vue';

export default {
  components: {
    VModal,
  },
  data() {
    return {
      requests: [],
      answerModalOpen: false,
      massiveRequestModalOpen: false,
      answerData: null,
      answerDataContent: null,
      urlCliPages: null,
    };
  },
  props: {
    contactDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async openAnswerModal(answerId) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Buscando Resposta',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactBasicDataAnswers/${answerId}`,
        );
        this.answerData = response.data.data;
        this.answerDataContent = JSON.parse(this.answerData.content);
        this.answerModalOpen = true;

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    closeAnswerModal() {
      this.answerModalOpen = false;
      this.answerData = null;
      this.answerDataContent = null;
    },
    makeRequestData() {
      Swal.fire({
        title: 'Preenchimento de Dados',
        html: '<p>Tem certeza que deseja efetuar a solicitação? Após confirmar o contato receberá um email com acesso ao formulário!</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Solicitar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (response) => {
        if (response.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Enviando solicitação...',
          });

          try {
            const response = await Axios.post(
              `${config.env.API_DEFAULT.host
              }/contactBasicDataRequests/createRequestToContact/${this.contactDetails.id}`,
            );

            await this.getRequests();

            this.$store.commit('setLoading', {
              show: false,
            });

            Swal.fire({
              title: 'Preenchimento de Dados',
              html: '<p>O contato recebeu um email com o link para preencher o formulário!</p>',
              type: 'success',
            });
          } catch (e) {
            this.$store.commit('setLoading', {
              show: false,
            });

            Swal.fire({
              title: 'Preenchimento de Dados',
              html: '<p>Houve um erro inesperado ao enviar a solicitação!</p>',
              type: 'error',
            });
          }
        }
      });
    },
    async getRequests() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Solicitação de Dados',
        description: 'Listando solicitações de dados, aguarde...',
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactBasicDataRequests/contact/${this.contactDetails.id}`,
        );

        this.requests = response.data.data;

        this.$store.commit('setLoading', {
          show: false,
        });
      } catch (e) {
        console.error(e);

        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    formatDateTime(date) {
      return moment(date).format('DD/MM/YYYY [às] HH:mm:ss');
    },
  },
  async mounted() {
    this.urlCliPages = config.env.URL_CLI_PAGES;
    await this.getRequests();
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392a51a;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e2e2e2;
  font-weight: bold;
}

.tag.success {
  background-color: #c4ffc0;
  color: #0f8906;
}

.tag.danger {
  background-color: #ffc0c0;
  color: #890606;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}

.details-label {
  font-size: 1rem;
  font-weight: 700;
  color: #343a40;
  margin-bottom: 3px;
}
</style>
