<template>
  <div class="dragndroparea" :class="event" ref="droparea">
    <file-upload
      :unique-id="uniqueID"
      class="fileUploadButton"
      :url="uploadURL"
      :headers="headers"
      @change="onFileChange"
      @success="uploadSuccess"
      @error="uploadError"
      btn-label=""
      btn-uploading-label=""
      accept="*"
    >
    </file-upload>
    <slot></slot>

    <div class="progressbar" v-if="uploadPercentage > 0">
      <div class="progressbar-bar" :style="`width: ${uploadPercentage}%`"></div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import FileUpload from "vue-upload-component";
import Swal from "sweetalert2";

export default {
  props: {
    uniqueID: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    loadData: {
      type: Function,
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      uploadPercentage: 0,
      event: "",
      hostapi: config.env.API_DEFAULT.host,
    };
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${this.$store.state.credentials.accessToken}`,
        // 'Content-Type': 'multipart/form-data'
      };
    },
    uploadURL() {
      return `${config.env.API_DEFAULT.host}/aa/${this.url}`;
    },
  },
  methods: {
    onFileChange(file) {
      this.files = file;
    },
    uploadSuccess(value) {
      // if (typeof this.loadData === "function") {
      //     this.loadData(value);
      // }

      if (value.currentTarget.response !== undefined) {
        const { response } = value.currentTarget;
        if (response.code === "ERROR") {
          Swal.fire("Envio de Arquivos", response.message, "error");
        } else if (
          response.message !== null &&
          response.message !== undefined
        ) {
          Swal.fire("Envio de Arquivos", response.message, "success");
        } else {
          Swal.fire(
            "Envio de Arquivos",
            "O arquivo foi enviado com sucesso.",
            "success"
          );
        }
      }

      this.files = [];
    },
    uploadError(value) {
      Swal.fire(
        "Upload",
        `Houve um problema ao tentar fazer o upload: ${value}`,
        "error"
      );
    },
    isDropSupported() {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    createListeners() {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach((evt) => {
        this.$refs.droparea.addEventListener(
          evt,
          (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.event = evt;
          },
          false
        );
      });
    },
    dropListener(e) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.files.push(e.dataTransfer.files[i]);
      }
      this.uploadFiles();
    },
    async uploadFiles() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Enviando Arquivos",
        description: "Enviando os arquivos, aguarde...",
      });
      try {
        const params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("username", config.env.API_MYIMP.credentials.user);
        params.append("password", config.env.API_MYIMP.credentials.password);

        const responseLogin = await Axios({
          method: "post",
          url: `${config.env.API_MYIMP.host}/oauth/token`,
          headers: {
            "Content-type": "application/x-www-form-urlencoded;",
            Authorization: config.env.API_MYIMP.credentials.token,
          },
          data: params,
        });

        if (responseLogin.status === 200 || responseLogin.status === "200") {
          const formData = new FormData();
          for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            formData.append(`files[${i}]`, file);
          }

          const configHeaders = {
            headers: {
              Authorization: `Bearer ${responseLogin.data.access_token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) =>
              (this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )),
          };

          const response = await Axios.post(
            config.env.API_MYIMP.host + this.url,
            formData,
            configHeaders
          );

          this.$store.commit("setLoading", {
            show: false,
          });

          this.uploadPercentage = 0;

          if (response.status === 200) {
            Swal.fire("Upload de Arquivos", response.data.message, "success");

            if (typeof this.loadData === "function") {
              this.loadData();
            }
          }
        }
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
        });
        this.uploadPercentage = 0;
        Swal.fire(
          "Upload",
          `Houve um problema ao tentar fazer o upload: ${err}`,
          "error"
        );
      }
    },
  },
  mounted() {
    // referencia: https://serversideup.net/drag-and-drop-file-uploads-with-vuejs-and-axios/
    const dragAndDropCapable = this.isDropSupported();
    if (dragAndDropCapable) {
      this.createListeners();

      this.$refs.droparea.addEventListener("drop", (e) => {
        this.dropListener(e);
      });
    }
  },
};
</script>

<style scoped>
.file-upload.fileUploadButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
  opacity: 0;
}

.dragndroparea {
  position: relative;
}

.dragndroparea::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 4px;
  border: 2px dashed rgba(27, 132, 231, 0.4);
  background: rgba(27, 132, 231, 0.2);
  opacity: 0;
  display: none;
  transition: opacity 0.5s;
}

.dragndroparea.dragover:after {
  display: block;
  opacity: 1;
}

.dragndroparea .progressbar {
  position: absolute;
  bottom: 5px;
  left: 5px;
  height: 5px;
  width: calc(100% - 10px);
  background: rgba(27, 132, 231, 0.2);
}

.dragndroparea .progressbar .progressbar-bar {
  background-color: rgba(27, 132, 231, 1);
  height: 5px;
  transition: width 0.2s;
}
</style>
