import Axios from 'axios';
import config from '@/config.js';
import downloadjs from 'downloadjs';
import Swal from 'sweetalert2';

const DocumentsStore = {
  namespaced: true,
  state: {
    state: {},
  },
  getters: {},
  mutations: {
    SET_(state, payload) {
      state.state = payload;
    },
  },

  actions: {
    async download({
      commit,
    }, doc) {
      try {
        commit('setLoading', { show: true, label: 'Download de Documento', description: 'Processando o donwload do documento!' }, { root: true });

        let filename = '';
        if (doc.originalFilename !== null && !doc.originalFilename) {
          filename = doc.originalFilename;
        } else {
          filename = doc.description;
        }

        if ((doc.fileType === 'application/pdf' || doc.fileType === 'application/octet-stream')) {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/documents/download/${doc.id}`);

          if (response) {
            const binaryString = window.atob(response.data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            const blob = new Blob([bytes], { type: doc.fileType });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          }
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/documents/download/${doc.id}`, null, { responseType: 'blob' });
          if (response) {
            downloadjs(response.data, filename);
          }
        }

        commit('setLoading', { show: false, label: '', description: '' }, { root: true });
      } catch (error) {
        console.error(error);
        Swal.fire('Download de Documento', error, 'error');
        return false;
      }
    },
  },
};

export default DocumentsStore;
