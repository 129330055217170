<template>
  <div class="row mg-t-0">
    <div class="col-md">
      <label class="section-label-sm tx-gray-600">Informações do Cliente</label>
      <div class="billed-to">
        <h6 class="tx-gray-800">
          {{ model.customer.completeName }}
        </h6>
        <p>
          <span class="tx-gray-800 tx-14">{{ model.customer.federalID }}</span
          ><br />
          {{ model.customer.address }}, Nº {{ model.customer.addressNumber }}
          <template v-if="model.customer.city"
            ><br />
            {{ model.customer.city }} /
            {{ model.customer.federalUnity }}</template
          >
          <br />
          Telefone: {{ model.customer.telephone1 }} <br />
          Email: {{ model.customer.email }}
        </p>
      </div>
    </div>
    <!-- col -->

    <div class="col-md">
      <label class="section-label-sm tx-gray-600"
        >Informações do Processo</label
      >
      <p class="invoice-info-row">
        <span>Referência Interna</span>
        <span class="tx-bold">{{ model.identification }}</span>
      </p>
      <p class="invoice-info-row">
        <span>Referência Pedido / Cliente</span>
        <span>{{ model.customerRef }}</span>
      </p>
      <p class="invoice-info-row">
        <span>Fatura / Invoice </span>
        <span
          >{{ model.invoice }}
          <template v-if="model.invoiceDate">
            &#160; &#160; {{ model.invoiceDate | moment("DD/MM/YY") }}
          </template>
        </span>
      </p>
      <p class="invoice-info-row">
        <span>Declaração de Importação </span>
        <span class="tx-bold">{{ model.diNumber }}</span>
      </p>
    </div>
    <!-- col -->

    <div class="col-md">
      <label class="section-label-sm tx-gray-500"> &#160; </label>
      <p class="invoice-info-row">
        <span>Exportador</span>
        <template v-if="model.exporter != null">
          <span>{{ model.exporter.name }}</span>
        </template>
      </p>
      <p class="invoice-info-row">
        <span>Fabricante</span>
        <template v-if="model.manufacturer != null">
          <span>{{ model.manufacturer.name }}</span>
        </template>
      </p>
      <p class="invoice-info-row">
        <span>BL / AWB / CRT</span>
        <span>{{ model.billOfLading }}</span>
      </p>
      <p class="invoice-info-row">
        <span>Local de Embarque </span>
        <span>{{ model.placeOfLoading }}</span>
      </p>
    </div>
    <!-- col -->
  </div>
  <!-- row -->
</template>
<script>
export default {
  name: "OpPrintableHeader",
  props: ["model"],
};
</script>
