<template>
    <div>
        <div class="signin-wrapper">
            <form @submit.prevent="subitForm">
                <div class="signin-box bd-0 wd-450 tx-center" style="background: none !important">
                    <h2 class="signin-title-primary mg-t-40 tx-normal mg-b-20">Recuperação de Senha</h2>
                    <h4 class="signin-title-secondary lh-4 mg-b-20">Informe o seu email para efetuar a recuperação da senha.</h4>
                    <div class="form-group">
                        <input type="email" name="email" class="form-control" v-model="form.email" placeholder="Informe o seu email">
                    </div>
                    <button class="btn btn-primary btn-block btn-signin bg-grandeur">Recuperar Senha</button>
                    <p class="mg-t-20 mg-b-0 tx-black tx-normal">
                        <router-link :to="{ name: 'login' }">Voltar para o login</router-link>
                    </p>
                </div>

                <p class="mg-t-20 mg-b-0 tx-12 tx-center">Copyright © {{new Date | moment("YYYY")}} </p>
                <p class="tx-11 tx-center">Todos os direitos reservados à {{tenantName}}.</p>
            </form>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config.js';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  data() {
    return {
      tenantName: config.env.setup.company.name,
      form: {
        email: null,
      },
    };
  },
  methods: {
    async subitForm() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Aguarde...',
        description: 'Estamos enviando um email com um código de verificação para o endereço informado!',
      });

      if (this.form.email) {
        await Axios.post(`${config.env.API_DEFAULT.host}/credential/recovery/password`, this.form).then((response) => {
          if (response.data.code === 'SUCCESS') {
            this.$router.push({ name: 'VerifyCodeRecoveryPassword' });
          }

          Swal.fire('Recuperacão de senha', '<p>Se o seu email for de um usuário válido, deverá ter recebido um código de verificação para informar na próxima tela.</p>', 'success');
        }).catch((error) => {
          Swal.fire('Recuperacão de senha', 'Erro ao tentar redefinir senha', 'error');
        });
      } else {
        Swal.fire('Recuperação de senha', 'Endereço de email não informado!', 'error');
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
};
</script>
