export const newAdicao = {
  numeroAdicao: 0,
  criacao: new Date(),
  ultimaAtualizacao: new Date(),
  licencaImportacao: null,
  numeroLI: null,
  codigoVinculoImportadorExportador: 1,
  codigoAusenciaFabricante: 2,

  nomeFornecedorEstrangeiro: '',
  enderecoLogradouroFornecedorEstrangeiro: '',
  enderecoNumeroFornecedorEstrangeiro: '',
  enderecoComplementoFornecedorEstrangeiro: '',
  enderecoCidadeFornecedorEstrangeiro: '',
  enderecoEstadoFornecedorEstrangeiro: '',

  nomeFabricanteMercadoria: '',
  enderecoLogradouroFabricante: '',
  enderecoNumeroFabricante: '',
  enderecoComplementoFabricante: '',
  fabricanteEstrangeiroCidade: '',
  enderecoEstadoFabricante: '',
  codigoPaisOrigemMercadoria: null,

  descricaoMercadoriaNCM: null,
  codigoMercadoriaNCM: '',
  indicadorBemEncomenda: 'N',
  indicadorMaterialUsado: 'N',
  indicadorMultimodal: 'N',

  codigoIncotermsVenda: null,
  nomeLocalCondicaoVenda: '',
  valorMercadoriaCondicaoVenda: 0.00,
  valorMercadoriaVendaMoedaNacional: 0.00,
  valorFreteMercadoriaMoedaNacional: 0.00,
  valorSeguroMercadoriaMoedaNacional: 0.00,

  textoComplementoValorAduaneiro: '',

  codRegTributacaoII: {
    key: '1',
    value: '1 - RECOLHIMENTO INTEGRAL',
  },
  tipoAtoLegalNcmII: null,
  aliquotaAcordoII: 0.00,
  adValorem: 0.00,
  reduzida: 0.00,
  reducaoIi: 0.00,
  valorBaseCalculoAdval: 0.00,

  codigoAssuntoVinculadoExII: 1,
  numeroEXAtoVinculadoExII: '',
  siglaTipoAtoVinculadoExII: null,
  siglaOrgaoAtoVinculadoExII: null,
  numeroAtoVinculadoExII: '',
  dataAnoAtoVinculadoExII: '',

  tipoAcordoTarifario: null,
  codigoAcordoAladi: null,
  codigoAssuntoVinculadoAcordoII: 3,
  numeroEXAtoVinculadoAcordoII: '',
  siglaTipoAtoVinculadoAcordoII: null,
  siglaOrgaoAtoVinculadoAcordoII: null,
  numeroAtoVinculadoAcordoII: '',
  dataAnoAtoVinculadoAcordoII: '',
  tipoRegTributacao: {
    key: '4',
    value: '4 - Recolhimento Integral',
  },
  notaComplementarTIPI: '',
  valorBaseCalculoAdvalIPI: 0.00,
  adValoremIpi: 0.00,
  reduzidaIpi: 0.00,
  ipiTributavel: true,

  valorUnMedidaIpi: 0.00,
  unMedidaIpi: null,
  qtdeUnIpi: null,
  tipoRecIpi: null,
  capacidadeRecIpi: null,

  codigoAssuntoVinculadoExIPI: 2,
  numeroEXAtoVinculadoExIPI: null,
  siglaTipoAtoVinculadoExIPI: null,
  siglaOrgaoAtoVinculadoExIPI: null,
  numeroAtoVinculadoExIPI: null,
  dataAnoAtoVinculadoExIPI: null,

  siglaTipoAtoVinculadoAcordoIPI: null,
  numeroAtoVinculadoAcordoIPI: null,
  siglaOrgaoAtoVinculadoAcordoIPI: null,
  dataAnoAtoVinculadoAcordoIPI: null,
  numeroEXAtoVinculadoAcordoIPI: null,

  codRegTributacaoPIS: {
    key: '1',
    value: '1 - RECOLHIMENTO INTEGRAL',
  },
  fundamentoLegalPisCofins: null,
  percentualReducao: 0.00,

  valorBaseCalculoAdvalPIS: 0.00,
  aliquotaAdValoremPIS: 0.00,
  aliquotaEspecificaPIS: 0.00,
  aliquotaReduzidaPIS: 0.00,
  unMedidaEspecificaPIS: '',
  qtdeMedidaPIS: '',

  valorBaseCalculoAdvalCOFINS: 0.00,
  aliquotaAdValoremCOFINS: 0.00,
  aliquotaEspecificaCOFINS: 0.00,
  aliquotaReduzidaCOFINS: 0.00,
  unMedidaEspecificaCOFINS: null,
  qtdeMedidaCOFINS: null,

  valorBaseCalculoAdvalAntidumping: 0.00,
  aliquotaAdValoremAntidumping: 0.00,
  aliquotaEspecificaAntidumping: 0.00,
  unMedidaEspecificaAntidumping: '',
  qtdeMedidaAntidumping: '',

  siglaTipoAtoVinculadoAcordoAntidumping: null,
  numeroAtoVinculadoAntidumping: null,
  siglaOrgaoAtoVinculadoAcordoAntidumping: null,
  dataAnoAtoVinculadoAcordoAntidumping: null,
  numeroEXAtoVinculadoAcordoAntidumping: null,

  baseCalculo: 0.00,
  baseCalculoIpi: 0.00,
  baseCalculoPIS: 0.00,
  numeroIdentificacaoLI: null,
  codigoAplicacaoMercadoria: '1',
  codigoMetodoValoracao: {
    key: '01',
    value: '01 - METODO 1',
    description: '01 - METODO 1 - ART. 1 DO ACORDO (DECRETO 92930/86)',
  },
  codigoMoedaNegociada: null,

  mercadoria: [],
  nve: [],
  destaque: [],
  valoracaoAduaneira: [],
  acrescimo: [],
  deducao: [],
  documento: [],
  documentoMercosul: [],

  quantidadeUnidadeEstatistica: 0.00,
  pesoLiquidoMercadoria: 0.00,

  codigoCoberturaCambial: {
    key: '1',
    value: 'Até 180 dias',
  },
  financiadora: null,
  valorRof: 0.00,
  nrRof: null,
  aliquotaii: 0.00,
  aliquotaipi: 0.00,
  aliquotapis: 0.00,
  aliquotacofins: 0.00,
  valorAduaneiro: 0.00,

  iiValorCalculoIptAdval: 0.00,
  iiValorCalculadoIIACTarifario: 0.00,
  iiValorImpostoDevido: 0.00,
  iiValorIPTaRecolher: 0.00,

  ipiValorCalculoIptAdval: 0.00,
  ipiValorCalculadoIIACTarifario: 0.00,
  ipiValorImpostoDevido: 0.00,
  ipiValorIPTaRecolher: 0.00,

  pisValorCalculoIptAdval: 0.00,
  pisValorCalculadoIIACTarifario: 0.00,
  pisValorImpostoDevido: 0.00,
  pisValorIPTaRecolher: 0.00,

  cofinsValorCalculoIptAdval: 0.00,
  cofinsValorCalculadoIIACTarifario: 0.00,
  cofinsValorImpostoDevido: 0.00,
  cofinsValorIPTaRecolher: 0.00,

  antidumpingValorCalculoIptAdval: 0.00,
  antidumpingValorCalculadoIIACTarifario: 0.00,
  antidumpingValorImpostoDevido: 0.00,
  antidumpingValorIPTaRecolher: 0.00,
};
