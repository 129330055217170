<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configurações</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Configurações Operacionai</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">{{ mainTitle }}</a>
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            {{ modelTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ modelTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i> Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <configuration-teams-form-general
                :model="model"
                :isNewRecord="isNewRecord()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

import Swal from "sweetalert2";
import FormGeneral from "./Form.General.vue";

export default {
  name: "configuration-teams-form",
  components: {
    "configuration-teams-form-general": FormGeneral,
  },
  data() {
    return {
      modelTitle: "Time Operacional",
      mainTitle: "Times Operacionais",
      model: {},
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    async loadModel() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/teams/${this.$route.params.id}`
        );
        this.model = response.data;
      } catch (err) {
        Swal.fire(this.mainTitle, `Ops, houve algum erro: ${error}`, "error");
      }
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
    } else {
      this.model.enabled = true;
    }
  },
};
</script>
