<template>
  <div class="slim-mainpanel mg-b-30">
    <div class="card card-table bd-b-0-force">
      <div class="card-header bd-b-0-force">
        <div class="report-summary-header mg-t-0 mg-b-0">
          <div>
            <h4 class="tx-inverse mg-b-3">Listagem de {{ modelTitle }}</h4>
          </div>
          <div>
            <div class="btn btn-primary" @click="modal = true">
              <i class="icon ion-plus tx-22"></i> ADICIONAR
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul class="list-group">
      <li
        class="list-group-item"
        v-for="item in getByLevelAndParent(0, null)"
        :key="item.id"
      >
        <ListItem :item="item" @edit="edit" @remove="remove" />

        <ul
          class="list-group sub-list-group"
          v-if="getByLevelAndParent(1, item.code).length > 0"
        >
          <li
            class="list-group-item"
            v-for="subItem in getByLevelAndParent(1, item.code)"
            :key="subItem.id"
          >
            <ListItem :item="subItem" @edit="edit" @remove="remove" />

            <ul
              class="list-group sub-list-group"
              v-if="getByLevelAndParent(2, subItem.code).length > 0"
            >
              <li
                class="list-group-item"
                v-for="sub2Item in getByLevelAndParent(2, subItem.code)"
                :key="sub2Item.id"
              >
                <ListItem :item="sub2Item" @edit="edit" @remove="remove" />

                <ul
                  class="list-group sub-list-group"
                  v-if="getByLevelAndParent(3, sub2Item.code).length > 0"
                >
                  <li
                    class="list-group-item"
                    v-for="sub3Item in getByLevelAndParent(3, sub2Item.code)"
                    :key="sub3Item.id"
                  >
                    <ListItem :item="sub3Item" @edit="edit" @remove="remove" />

                    <ul
                      class="list-group sub-list-group"
                      v-if="getByLevelAndParent(4, sub3Item.code).length > 0"
                    >
                      <li
                        class="list-group-item"
                        v-for="sub4Item in getByLevelAndParent(
                          4,
                          sub3Item.code
                        )"
                        :key="sub4Item.id"
                      >
                        <ListItem
                          :item="sub4Item"
                          @edit="edit"
                          @remove="remove"
                        />

                        <ul
                          class="list-group sub-list-group"
                          v-if="
                            getByLevelAndParent(5, sub4Item.code).length > 0
                          "
                        >
                          <li
                            class="list-group-item"
                            v-for="sub5Item in getByLevelAndParent(
                              5,
                              sub4Item.code
                            )"
                            :key="sub5Item.id"
                          >
                            <ListItem
                              :item="sub5Item"
                              @edit="edit"
                              @remove="remove"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    <Modal
      v-if="modal"
      modalTitle="Categorias"
      @close="modal = false"
      class="modal-width"
    >
      <div class="form-layout form-layout-2 mg-b-10">
        <label class="section-title mg-t-0">Informações da categoria</label>

        <div class="row no-gutters">
          <div class="col-md-2">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"
                >Habilitado <span class="tx-danger">*</span></label
              >
              <select
                class="form-control"
                v-model="activeItem.enabled"
                required
              >
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>

          <div class="col-md-10 col-sm-12">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"
                >Grupo pai <span class="tx-danger">*</span></label
              >
              <select
                id="parentList"
                class="form-control"
                v-model="activeItem.parentId"
                @change="calculateCode($event)"
                required
              >
                <option value="">Selecione</option>
                <option value="0">Categoria sem pai</option>
                <option
                  v-for="item in chartOfAccounts"
                  :key="item.id"
                  :value="item.code"
                >
                  {{ item.code }} - {{ item.description }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
                >Código <span class="tx-danger">*</span></label
              >
              <input
                type="text"
                v-model="activeItem.code"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-2 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
                >Natureza <span class="tx-danger">*</span></label
              >
              <select
                class="form-control"
                v-model="activeItem.income"
                @change="activeItem.expense = !activeItem.income"
                required
              >
                <option value="">Selecione</option>
                <option :value="true">Entrada</option>
                <option :value="false">Saída</option>
              </select>
            </div>
          </div>

          <div class="col-md-8 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
                >Descrição <span class="tx-danger">*</span></label
              >
              <input
                type="text"
                v-model="activeItem.description"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-t-5 mg-b-30 d-flex justify-content-center">
        <div class="col-md-3">
          <button
            @click.prevent="onSubmit()"
            class="btn btn-primary btn-block mg-r-10-force"
            type="submit"
          >
            <i class="fa fa-floppy-o mg-r-5"></i>
            SALVAR
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import Modal from "@/components/Modal.vue";
import ListItem from "@/views/finances/chartOfAccounts/Form.Categories.Item.vue";

export default {
  name: "chartOfAccounts-list",
  components: {
    Modal,
    ListItem,
  },
  data() {
    return {
      modelTitle: "Plano de Contas",
      mainTitle: "Plano de Contas",
      modal: false,
      activeItem: {
        enabled: true,
        code: "",
        level: 1,
        description: "",
        expense: true,
        income: false,
        chartOfAccountProject: "",
        parentId: "",
      },
      chartOfAccounts: [],
    };
  },
  methods: {
    getByLevelAndParent(level, parent) {
      return this.chartOfAccounts.filter((item) => {
        if (level > 0) {
          const itemParentPrefixArray = item.code.split(".");
          itemParentPrefixArray.pop();
          const itemParentPrefixJoin = itemParentPrefixArray.join(".");

          return item.level == level && itemParentPrefixJoin == parent;
        }
        return item.level == level;
      });
    },
    calculateCode(event) {
      const parentCode = document.getElementById("parentList").value;

      if (parentCode != 0) {
        const parent = this.chartOfAccounts.find(
          (item) => item.code == parentCode
        );
        const lastOfLevel = this.getByLevelAndParent(
          parent.level + 1,
          parent.code
        ).pop();

        if (lastOfLevel) {
          const splitLastItemCode = lastOfLevel.code.split("."); // break code to array
          const lastItem = splitLastItemCode.pop(); // pop last item
          const newLastItem = (parseInt(lastItem) + 1).toString(); // increase last item value
          splitLastItemCode.push(newLastItem); // add back to array
          const newCode = splitLastItemCode.join("."); // join array to string

          this.activeItem.code = newCode;
          this.activeItem.level = parent.level + 1;
        } else {
          this.activeItem.code = `${parent.code}.1`;
          this.activeItem.level = parent.level + 1;
        }
      } else {
        const lastOfLevel = this.getByLevelAndParent(0, null).pop();
        this.activeItem.code =
          lastOfLevel && lastOfLevel.code ? parseInt(lastOfLevel.code) + 1 : 1;
        this.activeItem.level = 0;
      }
    },
    calculateParent(code) {
      const split = code.split(".");
      split.pop();
      const parentCode = split.join(".");
      this.activeItem.parentId = parentCode;
    },
    async loadModel() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Aguarde",
        description: "Carregando dados...",
      });

      const dataId = this.$route.params.id;
      const response = await this.$store.dispatch(
        "FinancesStore/getSingleChartOfAccountProject",
        dataId
      );
      this.chartOfAccounts = response.chartOfAccounts.sort((a, b) =>
        a.code.localeCompare(b.code)
      );
      this.activeItem.chartOfAccountProject = `${config.env.API_DEFAULT.host}/chartOfAccountProjects/${this.$route.params.id}`;

      this.$store.commit("setLoading", { show: false });
    },
    async onSubmit() {
      const response = await this.$store.dispatch(
        "FinancesStore/setChartOfAccountCategories",
        this.activeItem
      );

      if (response) {
        this.chartOfAccounts = [];

        this.loadModel();
        this.modal = false;

        this.activeItem = {
          enabled: true,
          code: "",
          level: 0,
          description: "",
          expense: true,
          income: false,
          chartOfAccountProject: "",
          parentId: "",
        };
      } else {
        swal(
          `Não foi possível salvar as informações de categoria. ${error}`,
          "error"
        );
      }
    },
    edit(value) {
      this.activeItem = {
        ...value,
        parentId: "",
        chartOfAccountProject: `${config.env.API_DEFAULT.host}/chartOfAccountProjects/${this.$route.params.id}`,
      };
      this.calculateParent(this.activeItem.code);
      this.modal = true;
    },
    async remove(value) {
      swal({
        title: "Você tem certeza?",
        text: "Não é possível desfazer essa alção!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await this.$store.dispatch(
            "FinancesStore/deleteChartOfAccountCategories",
            value.id
          );
          this.loadModel();

          swal("Categoria deletada com sucesso!", {
            icon: "success",
          });
        }
      });
    },
  },
  async created() {
    await this.loadModel();
  },
};
</script>

<style scoped>
.sub-list-group {
  position: relative;
  top: 13px;
  left: 21px;
}

.sub-list-group > li {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-left: 0px;
  background: none !important;
}

.list-group-item > div.list-group-subitem:hover {
  background: #f9f9f9;
  box-shadow: 0 0 0 3px #f9f9f9;
}
</style>

<style>
.modal-width.modal-dialog {
  width: 80%;
  max-width: 900px;
}
</style>
