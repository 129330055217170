<template>
  <div
    :class="['input-control', active ? 'active' : '', editor ? 'editor' : '']"
  >
    <label :for="name">
      {{ label }}
    </label>

    <textarea
      :disabled="disabled"
      v-if="editor === false"
      :name="name"
      :id="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="inputFocus"
      @blur="onBlur($event.target.value)"
    ></textarea>

    <vue-editor
      v-else
      :value="value"
      @input="$emit('input', $event)"
      :placeholder="label"
      :editor-toolbar="editorToolbar"
      id="txt-editor"
      @blur="onBlur(value)"
    ></vue-editor>

    <div v-if="hint.length > 0" :class="['hint', hintActive ? 'active' : '']">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'VTextarea',
  components: {
    VueEditor,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: 'input',
    },
    label: {
      type: String,
      required: true,
      default: 'label',
    },
    hint: {
      type: String,
      default: '',
    },
    editor: {
      type: Boolean,
      default: false,
    },
    value: {},
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      active: false,
      hintActive: false,
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  methods: {
    checkInput(value) {
      if (value) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    inputFocus() {
      this.active = true;
      this.hintActive = true;
    },
    inputBlur() {
      this.hintActive = false;
    },
    onBlur(e) {
      this.checkInput(e);
      this.inputBlur();
      this.$emit('blur', e);
    },
  },
  mounted() {
    this.checkInput(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (value) {
          this.active = true;
        }
        this.checkInput(this.value);
      },
    },
  },
};
</script>

<style scoped>
.input-control {
  position: relative;
}

label {
  font-size: 0.9rem;
  position: absolute;
  top: 15px;
  left: 15px;
  transition: all 0.2s ease-in-out 0s;
}

.input-control.active label {
  top: -8px;
  font-size: 0.7rem;
  background-color: white;
  padding: 0 7px;
  border-radius: 6px;
}

.input-control.editor label {
  display: none;
}

textarea {
  width: 100%;
  resize: vertical;
  outline: none;
  --webkit-ountline: none;
  --moz-outline: none;
  border: 1px solid #e2e2e2;
  min-height: 150px;
  padding: 10px 15px;
  border-radius: 6px;
}

.input-control .hint {
  transform: translate3d(0, -30px, 0);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid #e2e2e2;
}

.input-control .hint.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}
</style>
