<template>
  <div class="quote-modal-overlay" @click.self="$emit('close-modal')">
    <div class="quote-modal-wrapper">
      <div class="quote-modal-header">
        <h2>Cotações do dia</h2>
        <a
          href="#"
          @click.prevent="$emit('close-modal')"
          class="quote-close-modal"
        >
          <span class="material-icons-outlined"> close </span>
        </a>
      </div>

      <div class="quote-modal-content pd-20">
        <div
          class="content-header d-flex align-items-center justify-content-between"
        >
          <!-- <div>
            <h3 class="mg-b-0 text-dark">Cotação do Dia</h3>
            <p class="text-border d-flex align-items-center mg-b-0">
              <span class="material-icons-outlined mg-r-5">
                info
              </span>
              Última atualização da cotação recebida em 31/01/2022 às 14:10
            </p>
          </div>

          <form @submit.prevent>
            <div class="search-box">
              <input type="text" class="form-control" placeholder="Pesquisar">
              <button class="btn btn-primary"><i class="fa fa-search"></i></button>
            </div>
          </form> -->
        </div>

        <table class="quote-content-table">
          <thead>
            <tr>
              <th>CÓDIGO</th>
              <th>MOÉDA</th>
              <th>TAXA REGISTRO SISCOMEX</th>
              <th>TAXA DO PRÓXIMO DIA</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="cotacoes.length == 0">
              <td colspan="4" class="text-center text-success">
                <h5>Carregando...</h5>
              </td>
            </tr>
            <tr v-for="item in cotacoes" :key="item.code">
              <td>{{ item.code }}</td>
              <td>{{ mapNameItem(item.code) }}</td>
              <td>R$ {{ item.currentBuyValue | numeralFormat("0,0.00000") }}</td>
              <td>R$ {{ item.nextBuyValue | numeralFormat("0,0.00000") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import Axios from 'axios';

const apiHost = config.env.API_DEFAULT.host;

export default {
  name: 'QuoteModal',
  data() {
    return {
      cotacoes: [],
    };
  },
  methods: {
    mapNameItem(code) {
      if (code == 220) {
        return 'Dólar americano';
      } if (code == 978) {
        return 'Euro';
      } if (code == 860) {
        return 'Rúpia indiana';
      } if (code == 795) {
        return 'Renminbi';
      } if (code == 540) {
        return 'Libra Esterlina';
      } if (code == 425) {
        return 'Franco suíço';
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
    getCotacoes() {
      Axios.get(`${apiHost}/exchangeRates/cotacoesAtuais`).then((response) => {
        this.cotacoes = response.data.data;
      });
    },
  },

  mounted() {
    function keyCloseModal() {
      const listener = (e) => {
        if (e.code === 'Escape') {
          this.$emit('close-modal');
          return window.removeEventListener('keyup', listener);
        }
      };
      window.addEventListener('keyup', listener);
    }
    const closeListener = keyCloseModal.bind(this);
    closeListener();
    this.getCotacoes();
  },
};
</script>

<style scoped>
.quote-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(73, 80, 87, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-modal-wrapper {
  min-width: 40vw;
  max-width: 80vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.quote-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--border);
  gap: 20px;
  padding: 20px;
}

.quote-close-modal,
.quote-modal-header h2 {
  color: var(--gray-dark);
}

.quote-modal-header h2 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
}

.quote-modal-content {
  background-color: white;
  overflow: auto;
}

.content-header {
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.content-header .search-box {
  display: flex !important;
}

.search-box .form-control {
  width: 100%;
  max-width: 250px;
}

.quote-content-table {
  width: 100%;
}

.quote-content-table thead tr th {
  background-color: var(--border);
}

.quote-content-table tbody tr td,
.quote-content-table thead tr th {
  color: var(--gray-dark);
  padding: 5px 10px;
  text-align: center;
}
</style>
