<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item"><a href="#">Usuários de Acesso</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ modelTitle }}</li>
        </ol>
        <h6 class="slim-pagetitle">{{ modelTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab"> <i class="fa fa-file-text-o mg-r-5"></i>
                Informações Gerais </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">

            <div class="tab-pane active" id="general">
              <form key="form" class="" @submit.prevent="submit()">
                <div class="form-layout form-layout-2">

                  <label class="section-title mg-t-20">Dados Gerais do Contato</label>

                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-control-label">CPF <span class="tx-danger">*</span></label>
                        <the-mask mask="###.###.###-##" class="form-control m-input" v-model="model.federalID"
                                  name="federalID" v-validate="'required'" @keyup.native="verifyIfUserExistsEvent"/>
                        <span v-show="errors.has('federalID')" class="parsley-errors-list filled">Digite o CPF</span>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Nome Completo <span class="tx-danger">*</span></label>
                        <input type="text" class="form-control m-input" v-model="model.name" name="name"
                               v-validate="'required'"/>
                        <span v-show="errors.has('name')" class="parsley-errors-list filled">Nome é um campo obrigatório.</span>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Data de Nascimento</label>
                        <date-picker lang="pt-br" v-model="model.dateOfBirth" :first-day-of-week="1" format="DD/MM/YYYY"
                                     name="dateOfBirth"></date-picker>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">

                          Sexo <span class="tx-danger">*</span>

                        </label>

                        <div class="row">
                          <div class="col-lg-6">
                            <label class="rdiobox rdiobox-success mg-t-0-force">
                              <input id="gender-m" name="gender" type="radio" v-model="model.gender" value="m"
                                     v-validate="'required'">
                              <span> Masculino </span>
                            </label>
                          </div>
                          <div class="col-lg-6">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">
                              <input id="gender-f" name="gender" type="radio" v-model="model.gender" value="f"
                                     v-validate="'required'">
                              <span> Feminino </span>
                            </label>
                          </div>

                          <span v-show="errors.has('gender')" class="parsley-errors-list filled col-lg-12">Selecione uma sexo.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <div class="form-group bd-t-0-force">
                        <label class="form-control-label">Cargo</label>
                        <input class="form-control" type="text" name="district" placeholder="Cargo"
                               v-model="model.jobRole"/>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label">Setor</label>
                        <select class="custom-select form-control" v-model="model.sector" name="sector">
                          <option selected=""> Selecione</option>
                          <option v-for="sector in sectors" :value="sector.key" :key="sector.key"> {{ sector.value }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label">

                          Situação de Usuário <span class="tx-danger">*</span>

                        </label>

                        <div class="row">
                          <div class="col-lg-5">
                            <label class="rdiobox rdiobox-success mg-t-0-force">
                              <input id="contactEnabledTrue" name="enabled" type="radio" v-model="model.enabled"
                                     :value="true" v-validate="'required'">
                              <span>Habilitado</span>
                            </label>
                            <span v-show="errors.has('enabled')"
                                  class="parsley-errors-list filled">Selecione uma opção</span>
                          </div>
                          <div class="col-lg-5">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">
                              <input id="contactEnabledFalse" name="enabled" type="radio" v-model="model.enabled"
                                     :value="false" v-validate="'required'">
                              <span>Desabilitado</span>
                            </label>
                          </div>
                          <span v-show="errors.has('enabled')" class="parsley-errors-list filled">Selecione uma situação.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="section-title mg-t-20">Informações Operacionais</label>

                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label"> Time / Equipe Operacional</label>
                        <v-select v-model="model.team" :options="teams"/>
                      </div>
                    </div>
                  </div>

                  <label class="section-title mg-t-20">Informações de Usuário</label>

                  <div class="row no-gutters">
                    <div class="col-md-9">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label"> Papel / Perfil <span class="tx-danger">*</span> </label>

                        <div class="row">
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-info mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'CUSTOMER'"
                                     v-validate="'required'">

                              <span> Cliente <small>(Ext)</small></span>

                            </label>
                          </div>
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-primary mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'PARTNER'"
                                     v-validate="'required'">

                              <span>Parceiro <small>(Ext)</small></span>

                            </label>
                          </div>
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'CUSTOM_BROKER'"
                                     v-validate="'required'">

                              <span>Despachante <small>(Ext)</small></span>

                            </label>
                          </div>
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-teal mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'OPERATIONAL'"
                                     v-validate="'required'">

                              <span> Operacional <small>(Int)</small> </span>

                            </label>
                          </div>
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-purple mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'MANAGER'"
                                     v-validate="'required'">

                              <span>Gerente <small>(Int)</small></span>

                            </label>
                          </div>
                          <div class="col-lg-2">
                            <label class="rdiobox rdiobox-orange mg-t-0-force">

                              <input name="role" type="radio" v-model="model.role" :value="'ADMINISTRATOR'"
                                     v-validate="'required'">

                              <span>Adminstrador <small>(Int)</small></span>

                            </label>
                          </div>

                          <span v-show="errors.has('enabled')"
                                class="parsley-errors-list filled">Selecione uma opção</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label"> Colaborador <span class="tx-danger">*</span></label>

                        <div class="row">
                          <div class="col-lg-5">
                            <label class="rdiobox rdiobox-success mg-t-0-force">

                              <input name="employee" type="radio" v-model="model.employee" :value="true"
                                     v-validate="'required'">

                              <span>Sim</span>

                            </label>
                          </div>
                          <div class="col-lg-5">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">

                              <input name="employee" type="radio" v-model="model.employee" :value="false"
                                     v-validate="'required'">

                              <span>Não</span>

                            </label>
                          </div>
                          <span v-show="errors.has('enabled')"
                                class="parsley-errors-list filled">Selecione uma opção</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group  mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label">Usuário </label>
                        <input type="text" class="form-control m-input" :value="model.email1" disabled="disabled"
                               v-if="model.email1 != null"/>
                        <span class="filled tx-light tx-10">Sua identificação é o seu email.</span>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="isNewRecord() && !changePassword">
                      <div class="form-group mg-md-l--1 bd-t-0-force bd-t-0-force">
                        <label class="form-control-label">Senha</label>
                        <input type="password" class="form-control" v-model="model.password" name="password"/>
                        <span v-show="errors.has('password')" class="parsley-errors-list filled">As senhas digitadas não são iguais.</span>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="isNewRecord() && !changePassword">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label">Repita a Senha</label>
                        <input type="password" class="form-control" v-model="passwordRepeat" name="passwordRepeat"/>
                        <span v-show="errors.has('password')" class="parsley-errors-list filled">As senhas digitadas não são iguais.</span>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="!isNewRecord() && changePassword">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label"> Nova Senha: </label>
                        <input type="password" class="form-control" v-model="newPassword" name="newPassword"
                               v-validate="{ required: true, confirmed: newPasswordRepeat }" v-if="changePassword"/>
                        <input type="password" class="form-control" v-model="newPassword" name="newPassword" v-else
                               disabled readonly/>
                        <span v-show="errors.has('newPassword')" class="parsley-errors-list filled">Senha é um campo obrigatório.</span>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="!isNewRecord() && changePassword">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label"> Repita a Nova Senha: </label>
                        <input type="password" class="form-control" v-model="newPasswordRepeat" name="newPasswordRepeat"
                               v-validate="{ required: true, confirmed: newPasswordRepeat }" v-if="changePassword"/>
                        <input type="password" class="form-control" v-model="newPasswordRepeat" name="newPasswordRepeat"
                               v-else disabled readonly/>
                        <span v-show="errors.has('newPasswordRepeat')" class="parsley-errors-list filled">Repita a senha é um campo obrigatório.</span>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <div class="row pd-l-30 mg-t-20">
                          <br/>
                          <br/>
                          <label class="ckbox ckbox-success">
                            <input type="checkbox" v-model="model.generatePassword" :value="true"
                                   name="generatePassword"><span>Gerar senha automática e notificar usuário por email </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="!isNewRecord()">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <div class="row pd-l-30 mg-t-20">
                          <br/>
                          <br/>
                          <label class="ckbox ckbox-success">

                            <input type="checkbox" v-model="changePassword" :value="true" name="changePassword"><span> Alterar Senha </span>

                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="section-title mg-t-20">Informações de Follow-UP</label>
                  <div class="row no-gutters">

                    <div class="col-md-3 ">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label"> Recebe Follow UP? <span class="tx-danger">*</span></label>

                        <div class="row">
                          <div class="col-lg-6">
                            <label class="rdiobox rdiobox-success mg-t-0-force">

                              <input id="followUP-true" name="followUP" type="radio" v-model="model.followUP"
                                     :value="true" v-validate="'required'">

                              <span> Sim </span>

                            </label>
                            <span v-show="errors.has('followUP')"
                                  class="parsley-errors-list filled">Selecione uma opção</span>
                          </div>
                          <div class="col-lg-6">
                            <label class="rdiobox rdiobox-danger mg-t-0-force">

                              <input id="followUP-false" name="followUP" type="radio" v-model="model.followUP"
                                     :value="false" v-validate="'required'">

                              <span> Não </span>

                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <template v-if="model.followUP">
                      <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label"> Recebe Follow UP Analítico? <span
                            class="tx-danger">*</span></label>
                          <div class="row">
                            <div class="col-lg-6">
                              <label class="rdiobox rdiobox-success mg-t-0-force">

                                <input id="followUPAnalytical-true" name="followUPAnalytical" type="radio"
                                       v-model="model.followUPAnalytical" :value="true" v-validate="'required'">

                                <span> Sim </span>

                              </label>
                              <span v-show="errors.has('followUPAnalytical')" class="parsley-errors-list filled">Selecione uma opção</span>
                            </div>
                            <div class="col-lg-6">
                              <label class="rdiobox rdiobox-danger mg-t-0-force">

                                <input id="followUPAnalytical-false" name="followUPAnalytical" type="radio"
                                       v-model="model.followUPAnalytical" :value="false" v-validate="'required'">

                                <span> Não </span>

                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label"> Recebe Follow UP Sintético? <span
                            class="tx-danger">*</span></label>
                          <div class="row">
                            <div class="col-lg-6">
                              <label class="rdiobox rdiobox-success mg-t-0-force">

                                <input id="followUPSynthetic-true" name="followUPSynthetic" type="radio"
                                       v-model="model.followUPSynthetic" :value="true" v-validate="'required'">

                                <span> Sim </span>

                              </label>
                              <span v-show="errors.has('followUPSynthetic')" class="parsley-errors-list filled">Selecione uma opção</span>
                            </div>
                            <div class="col-lg-6">
                              <label class="rdiobox rdiobox-danger mg-t-0-force">

                                <input id="followUPSynthetic-false" name="followUPSynthetic" type="radio"
                                       v-model="model.followUPSynthetic" :value="false" v-validate="'required'">

                                <span> Não </span>

                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group mg-md-l--1" v-if="model.followUPSynthetic">
                          <label class="form-control-label"> Dia de Recebimento </label>
                          <select class="form-control" v-model="model.followUPSyntheticWeekday"
                                  name="followUPSyntheticWeekday" v-validate="'required'">
                            <option selected=""> Selecione</option>
                            <option v-for="day in days" :value="day.key" :key="day.key"> {{ day.value }}</option>
                          </select>
                          <span v-show="errors.has('followUPSyntheticWeekday')" class="parsley-errors-list filled">Selecione um dia da semana</span>
                        </div>
                      </div>
                    </template>
                  </div>

                  <label class="section-title mg-t-20">Informações de Contato</label>

                  <div class="row no-gutters">
                    <div class="col-md-3 col-sm-6">
                      <div class="form-group">
                        <label class="form-control-label">Telefone Fixo</label>
                        <the-mask :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
                                  placeholder="Telefone Principal" v-model="model.telephone1" name="telephone1"
                                  class="form-control"/>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Celular <span class="tx-danger">*</span> </label>
                        <the-mask :mask="['(##) ####-####', '(##) #####-####']" :masked="true" placeholder="Celular"
                                  v-model="model.telephone2" class="form-control" v-validate="'required'"/>
                        <span v-show="errors.has('telephone2')"
                              class="parsley-errors-list filled">Digite seu celular</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Email <span class="tx-danger">*</span></label>
                        <input class="form-control" type="text" name="email1" placeholder="Email" v-model="model.email1"
                               v-validate="'required|email'">
                        <span v-show="errors.has('email1')" class="parsley-errors-list filled">Email Principal de ser um email válido. </span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Email Secundário</label>
                        <input class="form-control" type="text" name="email2" placeholder="Email" v-model="model.email2"
                               v-validate="'email'">
                        <span v-show="errors.has('email2')" class="parsley-errors-list filled">Email Secundário de ser um email válido.</span>
                      </div>
                    </div>
                  </div>

                  <label class="section-title mg-t-20">Informações de Endereço</label>

                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-control-label">CEP</label>
                        <the-mask mask="#####-###" placeholder="CEP" v-model="model.postalCode" name="postalCode"
                                  class="form-control" @blur.native="findCEP"/>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Logradouro <span class="tx-danger">*</span></label>
                        <input class="form-control" type="text" name="address" placeholder="Logradouro"
                               v-model="model.address" :class="{'parsley-error': errors.has('address')}"/>

                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Número</label>
                        <input class="form-control" type="text" name="addressNumber" placeholder="Número"
                               v-model="model.addressNumber" :class="{'parsley-error': errors.has('address')}"/>

                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1">
                        <label class="form-control-label">Complemento</label>
                        <input class="form-control" type="text" name="complement" placeholder="Complemento"
                               v-model="model.complement">

                      </div>
                    </div>
                  </div>

                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <div class="form-group bd-t-0-force">
                        <label class="form-control-label">Bairro </label>
                        <input class="form-control" type="text" name="district" placeholder="Bairro"
                               v-model="model.district" :class="{'parsley-error': errors.has('district')}"/>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label">Cidade <span class="tx-danger">*</span></label>
                        <input class="form-control" type="text" name="city" placeholder="Cidade" v-model="model.city"
                               :class="{'parsley-error': errors.has('city')}"/>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group mg-md-l--1 bd-t-0-force">
                        <label class="form-control-label"> UF </label>
                        <select class="form-control" v-model="model.federalUnity" name="federalUnity">

                          <option selected=""> Selecione</option>

                          <option v-for="uf in ufs" :value="uf.sigla" :key="uf.sigla"> {{ uf.nome }}</option>

                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mg-t-20-force">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                      <button class="btn btn-primary btn-block mg-r-10-force" type="submit"><i
                        class="fa fa-floppy-o mg-r-5"></i> SALVAR
                      </button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-secondary btn-block mg-l-10-force" type="button" @click="redirect"><i
                        class="fa fa-reply mg-r-5"></i>VOLTAR
                      </button>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import DatePicker from 'vue2-datepicker';
import config from '@/config.js';
import * as constants from '@/consts.js';

import {
  EventBus,
} from '@/events/EventBus.js';
import {
  TheMask,
} from 'vue-the-mask';
import UtilsService from '@/services/UtilsService.js';
import Swal from 'sweetalert2';

export default {
  name: 'configuration-profile',
  components: {
    DatePicker,
    TheMask,
  },
  data() {
    return {
      modelTitle: 'Meus Dados',
      changePassword: false,
      passwordRepeat: null,
      newPassword: null,
      newPasswordRepeat: null,
      temporaryContact: null,
      teams: [],
      model: {
        federalID: '',
        name: '',
      },
      userTeam: {},
    };
  },
  methods: {
    redirect() {
      this.$router.push('/configuration/users');
    },
    isNewRecord() {
      if (this.model.id === null || this.model.id === undefined || this.model.id === '') {
        return true;
      }

      return false;
    },
    submit() {
      const self = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          self.updateModel();
        } else {
          swal(self.modelTitle, 'Você não preencheu todos os campos obrigatórios.', 'error');
        }
      });
    },
    updateModel() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Salvando dados do usuário',
      });

      if (this.changePassword === true && this.newPassword !== null) {
        this.model.password = this.newPassword;
      }

      if (!this.model.followUP) {
        this.model.followUPAnalytical = false;
        this.model.followUPSynthetic = false;
      }

      this.model.account = this.$store.state.credentials.data.account;

      const urlBase = `${config.env.API_DEFAULT.host}/systemUsers/create/`;
      const urlEnvio = this.model.id ? `${config.env.API_DEFAULT.host}/systemUsers/update/${this.model.id}` : urlBase;

      const method = this.model.id ? 'put' : 'post';

      Axios({
        method,
        url: urlEnvio,
        data: this.model,
      }).then((result) => {
        Swal.fire('Meus Dados', 'Usuário cadastrado com sucesso!', 'success');

        this.$store.commit('setLoading', {
          show: false,
        });

        this.$router.push(`/configuration/users/update/${result.data.data.id}`);
      }).catch((error) => {
        this.$store.commit('setLoading', {
          show: false,
        });

        Swal.fire({
          title: 'Meus Dados',
          html: `<p>${error.response.data.message}</p>`,
          type: 'error',
        });
      });

      this.changePassword = false;
    },
    async findCEP() {
      if (this.model.postalCode) {
        const response = await this.$viaCep.buscarCep(this.model.postalCode);
        if (response) {
          if (response.erro) {
            Swal.fire('Oops!', 'Não foi possivél encontrar este endereço, verifique se o CEP é válido');
          } else {
            this.$set(this.model, 'address', response.logradouro);
            this.$set(this.model, 'district', response.bairro);
            this.$set(this.model, 'city', response.localidade);
            this.$set(this.model, 'complement', response.complemento);
            this.$set(this.model, 'federalUnity', response.uf);
          }
        }
      }
    },
    cancel() {
      EventBus.$emit('Company.Form.loadModel');
      this.$emit('close');
    },
    verifyIfUserExistsEvent(event) {
      this.verifyIfUserExists();
    },
    verifyIfUserExists() {
      const self = this;

      if (self.model.federalID.length === 18) {
        const id = (self.model.id !== null && self.model.id !== undefined ? self.model.id : '');
        const url = `${'/credential/user/verify?' + 'id='}${id}&federalID=${self.model.federalID}`;

        Axios.get(config.env.API_DEFAULT.host + url)
          .then((response) => {
            if (response.data.code === 'ERROR') {
              self.temporaryContact = response.data.data;

              self.model.federalID = '';

              Swal.fire({
                title: 'Contatos',
                text: `${response.data.message} O nome do contato é ${response.data.data.name} e o email é ${response.data.data.email1}.`,
                icon: 'error',
                buttons: ['Não', 'Sim'],
              }).then((result) => {
                if (result.value === true) {
                  if (self.company !== null) {
                    Axios.patch(`${config.env.API_DEFAULT.host}/companies/${self.company.id}/users`, `${config.env.API_DEFAULT.host}/users/${self.temporaryContact.id}`, {
                      headers: {
                        'Content-Type': 'text/uri-list',
                      },
                    })
                      .then((response) => {
                        EventBus.$emit('Company.Form.loadModel');
                        swal('Cadastro de Empresas', `${self.temporaryContact.name} foi associado a empresa com sucesso.`, 'success');
                        self.$emit('close');
                      }).catch((error) => {
                      swal('Cadastro de Empresas', `Ops, houve algum erro ao tentar associar o contato: ${error}`, 'error');
                    });
                  }
                }
              });
            }
          })
          .catch((error) => {
            swal('Cadastro de Contatos', `Ops, houve algum erro: ${error}`, 'error');
          });
      }
    },
    getTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
        const teamsItens = [];
        response.data._embedded.teams.forEach((item) => {
          teamsItens.push(
            {
              value: item.id,
              label: item.email,
            },
          );
        });

        this.teams = teamsItens;
      }).catch((error) => {
        swal('Cadastro de Empresa', `Ops, houve algum erro: ${error}`, 'error');
      });
    },
  },
  mounted() {
    this.changePassword = false;
    this.getTeams();

    if (this.$route.params.id) {
      Axios.get(`${config.env.API_DEFAULT.host}/users/${this.$route.params.id}`).then((response) => {
        this.model.id = response.data.id;
        this.model.federalID = response.data.federalID;
        this.model.name = response.data.name;
        this.model.dateOfBirth = response.data.dateOfBirth;
        this.model.gender = response.data.gender;
        this.model.jobRole = response.data.jobRole;
        this.model.sector = response.data.sector;
        this.model.enabled = response.data.enabled;
        this.model.role = response.data.role;
        this.model.employee = response.data.employee;
        this.model.email1 = response.data.email1;
        this.model.followUP = response.data.followUP;
        this.model.followUPAnalytical = response.data.followUPAnalytical;
        this.model.followUPSynthetic = response.data.followUPSynthetic;
        this.model.followUPSyntheticWeekday = response.data.followUPSyntheticWeekday;
        this.model.telephone1 = response.data.telephone1;
        this.model.telephone2 = response.data.telephone2;
        this.model.email2 = response.data.email2;
        this.model.postalCode = response.data.postalCode;
        this.model.address = response.data.address;
        this.model.addressNumber = response.data.addressNumber;
        this.model.complement = response.data.complement;
        this.model.district = response.data.district;
        this.model.city = response.data.city;
        this.model.federalUnity = response.data.federalUnity;
      });

      Axios.get(`${config.env.API_DEFAULT.host}/teams/getTeamByUser/${this.$route.params.id}`).then((response) => {
        const team = response.data.data;

        this.model.team = {
          value: team.id,
          label: team.email,
        };
      });
    }
  },
  computed: {
    sectors() {
      return constants.CADASTROS_SETORES;
    },
    ufs() {
      return constants.CADASTROS_UF;
    },
    days() {
      return constants.CADASTROS_DIAS_DA_SEMANA;
    },
  },
};
</script>
