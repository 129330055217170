<template>
  <div class="config-main">
    <component v-bind:is="current"></component>

    <div v-if="!current" class="card-body pd-40 sp-3 text-center">
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt=""/>
      <h6 class="tx-md-20 tx-inverse">Nenhuma configuração selecionada</h6>
      <p>Selecione uma opção no menu lateral para gerenciar as configurações</p>
    </div>
  </div>
</template>

<script>
import ConfigAccessUsersTab from '@/components/Configuration/tabs/ConfigAccessUsersTab.vue';
import ConfigAccessGroupsTab from '@/components/Configuration/tabs/ConfigAccessGroupsTab.vue';
import ConfigTeamTab from '@/components/Configuration/tabs/ConfigTeamTab.vue';
import ConfigCompanyTab from '@/components/Configuration/tabs/ConfigCompanyTab.vue';
import GeneralLogsTab from '@/components/Configuration/tabs/GeneralLogsTab.vue';

export default {
  name: 'ConfigMain',
  components: {
    ConfigAccessUsersTab,
    ConfigAccessGroupsTab,
    ConfigTeamTab,
    ConfigCompanyTab,
    GeneralLogsTab,
  },
  props: {
    current: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.config-main {
  padding: 1.25rem 0.75rem;
  grid-column: span 9;
  overflow: auto;
}
</style>
