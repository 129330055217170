<template>
  <div class="loading-container" :style="{ display: 'block' }">
    <div class="loading-loader"></div>
    <div class="loading-content">
      <p>
        {{ label }}
        <br />
        <small> {{ description }} </small>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingComponent",
  data() {
    return { isVisible: "none" };
  },
  computed: {
    label() {
      return this.$store.state.loading.label;
    },
    description() {
      return this.$store.state.loading.description;
    },
  },
  methods: {
    changeState() {
      if (this.isVisible == "none") {
        this.isVisible = "block";
      } else {
        this.isVisible = "none";
      }
    },
  },
};
</script>
<style>
.loading-container {
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
}

.loading-loader {
  border: 20px solid #f3f3f3;
  border-radius: 50%;
  border-top: 20px solid #1b84e7;
  width: 150px;
  height: 150px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  position: fixed;
  z-index: 9999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-content {
  position: relative;
  z-index: 9999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: white;
  font-size: 28px;
  margin: 0;
  top: 65%;
  text-transform: uppercase;
}

.loading-content p small {
  font-size: 15px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
