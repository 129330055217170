<template>
  <transition name="modal">
    <div class="modal fade show" style="padding-right: 17px; display: block">
      <div class="modal-dialog modal-dialog-vertical-center" role="document">
        <div class="modal-content bd-0 tx-14">
          <div class="modal-header">
            <slot name="header">
              <h6 class="tx-14 tx-uppercase tx-inverse tx-bold">
                {{ modalTitle }}
              </h6>
              <button
                type="button"
                @click="$emit('close')"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </slot>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer" v-if="this.showFooter === true">
            <slot name="footer">
              <button
                type="button"
                @click="$emit('save')"
                class="btn btn-primary"
              >
                SALVAR
              </button>
              <button
                type="button"
                @click="$emit('close')"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                FECHAR
              </button>
            </slot>
          </div>
        </div>
      </div>
      <!-- modal-dialog -->
    </div>
  </transition>
</template>
<script>
export default {
  name: "ModalComponent",
  props: ["modalTitle", "showFooter"],

  watch: {},

  data() {
    return {};
  },

  methods: {
    submit() {},
  },
  computed: {},
  created() {
    this.$emit("created");
  },
  mounted() {
    this.$emit("mounted");
  },
};
</script>

<style lang="scss" scoped>
.modal {
  background: rgba(0, 0, 0, 0.35);

  .modal-body {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}
</style>
