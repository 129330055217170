<template>
  <transition name="modal">
    <div class="modal-mask">
      <!-- <Loading ref="loading" label="Baixar Parcela" description="Processando sua requisação."></Loading> -->
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="tx-xs-20 tx-inverse mg-b-5">ITENS DO DOCUMENTO</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="$emit('close')"
            >
              <span aria-hidden="true"> X </span>
            </button>
          </div>
 
          <div class="modal-body">
            <div class="form-layout form-layout-2 mg-x-20 pd-b-40">
              <div class="report-summary-header mg-b-5 mg-t-20">
                <div>
                  <h4 class="tx-inverse mg-b-3">
                    #{{ model.id }}: {{ model.observation }}
                  </h4>
                  <p class="mg-b-0">
                    <i class="icon ion-information-circled mg-r-3"></i> Listagem
                    dos itens do documento da prestação de contas
                  </p>
                </div>
                <div>
                  <div class="d-h-t-right">
                    <div class="summary-item">
                      <h1>
                        R$
                        {{
                          new Number(model.totalValue) | numeralFormat("0,0.00")
                        }}
                      </h1>
                      <span
                      >Valor <br/>
                        Documento</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="table-responsive">
                  <table
                    id="integration-duas-rodas-ps-item-table "
                    class="table table-striped mg-b-0 integration-duas-rodas-ps-item"
                  >
                    <thead>
                    <tr>
                      <th class="tx-center wd-500">Tipo de Despesa</th>
                      <th class="tx-center wd-300">Valor</th>
                      <th class="tx-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody class="tbody">
                    <tr-inline
                      v-for="(item, index) in listOfItems"
                      :key="'integration-duas-rodas-ps-item-' + index"
                      :row="item"
                      @save="updateItem"
                      customClassesActions="import-actions"
                    > 
                      <td-inline
                        v-model="item.typeOfDocument"
                        type="v-select"
                        :options="categories"
                        label="description"
                        :immediateChange="false"
                        inputClass="select-type-document"
                        @change="typeOfDocumentChangeValue($event, item)"
                      />
                      <td-inline
                        v-model="item.value"
                        type="money"
                      />
                      <template slot="actions">
                        <a
                          href="#"
                          @click.prevent="removeItem(item)"
                          class="btn btn-danger btn-icon rounded-circle"
                        >
                          <div><i class="icon ion-trash-a"></i></div>
                        </a>
                      </template>
                    </tr-inline>
                    <tr-inline
                      class="new-item"
                      :isNew="true"
                      :row="newItem"
                      @save="createItem"
                      customClassesActions="import-actions"
                    >
                      <td-inline
                        v-model="newItem.typeOfDocument"
                        type="v-select"
                        :options="categories"
                        label="description"
                        inputClass="select-type-document"
                        @change="typeOfDocumentChangeValue($event, newItem)"
                      />
                      <td-inline
                        v-model="newItem.value"
                        type="money"
                      />
                    </tr-inline>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><span class="total-label">Valor Total</span></td>
                      <th class="tx-right">
                        R$ {{ valolTotal | numeralFormat("0,0.00") }}
                      </th>
                      <th></th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";
import {mapState} from "vuex";
import config from "@/config.js";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";

import Swal from "sweetalert2";
import InvoiceItem from '@/models/InvoiceItem';

export default {
  name: "operational-integration-duas-rodas-document-item-modal",
  props: ["model", "isForm", "finish"],
  components: {
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
  },
  data() {
    return {
      oldModel: null,
      newItem: {
        document: "",
        typeOfDocument: "",
        value: 0.0,
      },
      listOfItems: [],
      typeOfDocuments: [],
      categories: [],
    };
  },

  methods: {
    loadItens() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Item da Prestação de Contas",
        description: "Carregando dados, aguarde...",
      });

      // Axios.get(this.model._links.items.href).then(response => {
      //     this.listOfItems = response.data._embedded.processAppointmentDocumentItems;
      Axios.get(
        `${config.env.API_DEFAULT.host}/newProcessAppointmentDocumentItem/${this.model.id}`
      )
        .then((response) => {
          this.listOfItems = response.data.data;
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });

          Swal.fire(
            "Erro",
            `Houve algum erro ao tentar realizar a operação: ${err}`,
            "error"
          );
        });
    },
    createItem(value) {
      this.submitItem(value, "post");
    },
    updateItem(value) {
      this.submitItem(value, "put");
    },

    /**
     * Verifica se o tipo de documento ja existe na lista de items.
     * Caso exista, limpa o campo typeOfDocument do item e apresenta um alerta.
     * @param {Object} evento - O evento que foi disparado.
     * @param {Object} item - O item que esta sendo manipulado.
     */
    typeOfDocumentChangeValue(evento, item) {
      if(this.listOfItems.length > 0) {
        const objetosFiltrados = this.listOfItems.filter(itemList => {
            if( item.id !== itemList.id && itemList.typeOfDocument.id === evento.id ) {
              return true;
            }
        });

        if(objetosFiltrados.length > 0) { 
          item.typeOfDocument = {};
          Swal.fire(
            "Tipo de Despesa",
            `Tipo de despesa duplicado: '${evento.description}' já existe. `,
            "error"
          );
          return;
        }
      }
    },
    async submitItem(value, method) {
      const data = {
        typeOfDocumentId: value.typeOfDocument.id,
        value: value.value,
      };

      this.$store.commit("setLoading", {
        show: true,
        label: "Item da Prestação de Contas",
        description: "Salvando dados, aguarde...",
      });

      try {
        if (
          value.typeOfDocument === undefined ||
          value.typeOfDocument === null || Object.keys(value.typeOfDocument).length === 0
        ) {
          Swal.fire(
            "Tipo de Despesa",
            "Você precisa selecionar um Tipo de Despesa para continuar. ",
            "error"
          );
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });

          return;
        }

        if (method == "put") {
          const response = await Axios.put(
            `${config.env.API_DEFAULT.host}/newProcessAppointmentDocumentItem/${value.id}`,
            data
          );

          Swal.fire(
            "Item da Prestação de Contas",
            "Dados do item atualizados com sucesso!",
            "success"
          );

          this.loadItens();
        } else {
          const response = await Axios.post(
            `${config.env.API_DEFAULT.host}/newProcessAppointmentDocumentItem/${this.model.id}`,
            data
          );

          this.newItem = {
            document: "",
            typeOfDocument: {},
            value: 0.0,
          };

          this.listOfItems = [];
          this.loadItens();

          Swal.fire(
            "Item da Prestação de Contas",
            "Item cadastrado com sucesso!",
            "success"
          );
        }

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });

        this.$emit("reloadDocuments", true);
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Item do Documento", err.response.data.data, "error");
      }
    },
    async removeItem(value) {
      try {
        const willContinue = await Swal.fire({
          title: "Remover Item",
          html: `Você realmente deseja excluir o documento <strong>${value.typeOfDocument.description}</strong>? Se você excluir, precisará adicioná-lo novamente.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        });

        if (willContinue.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Item da Prestação de Contas",
            description: "Processando requisição, aguarde...",
          });

          const response = await Axios.delete(
            `${config.env.API_DEFAULT.host}/newProcessAppointmentDocumentItem/${value.id}`
          );

          this.loadItens();

          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        }

        this.$emit("reloadDocuments", true);
      } catch (err) {
        console.log(err);

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
        Swal.fire("Ops", err.response.data.data, "error");
      }
    },
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
      movimentTermsCATEGORY: (state) =>
        state.FinancesStore.movimentTermsCATEGORY,
    }),
    searchURL() {
      return `${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByTypeAndDescriptionContainingIgnoreCase?type=CATEGORY&description=:query`;
    },
    valolTotal() {
      return this.listOfItems.reduce(
        (value, item) => new Number(value) + new Number(item.value),
        0
      );
    },
  },
  watch: {
    movimentTermsCATEGORY() {
      this.categories = this.movimentTermsCATEGORY;
    },
  },
  mounted() {
    this.categories = this.movimentTermsCATEGORY;

    if (this.model !== undefined && this.model !== null) {
      this.loadItens();
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 850px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  background-color: #f8f9fa;
  padding: 20px 15px 10px 15px;
}

.modal-header .close {
  padding: 18px 20px 0px 20px !important;
}

.modal-body {
  margin: 0px !important;
  padding: 0px !important;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.overflow {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.integration-duas-rodas-ps-item .table th,
.integration-duas-rodas-ps-item .table td {
  padding: 0.2rem 0.2rem 0.2rem 15px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.dropdown-menu {
  z-index: 99999;
}

.dropdown {
  z-index: 99999;
}

.total-label { 
  margin-left: 28px;
}

.table-responsive { 
  max-height: 75vh; 
  overflow-x: unset;
}

.table-responsive:has(.tbody tr:nth-child(n+6)) {
  overflow-y: auto; 
  overflow-x: auto;
}

</style>
