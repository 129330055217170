<template>
  <transition name="modal">
    <div class="modal-mask">
      <!-- <Loading ref="loading" label="Baixar Parcela" description="Processando sua requisação."></Loading> -->
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h5 class="tx-xs-20 tx-inverse mg-b-5">EDITAR BAIXA</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar" @click="$emit('close')">
              <span aria-hidden="true"> X </span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-layout form-layout-2">
              <div class="row no-gutters">
                <div class="col-md-12 pd-t-10">
                  <div class="form-group bd-t-0-force">
                    <h4 class="slim-card-title tx-primary" v-if="model.appointment !== undefined">
                      #{{model.appointment.index}} - {{model.appointment.description}} </h4>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">Descrição <span class="tx-danger">*</span></label>
                    <input type="text" placeholder="Descrição" name="description" class="form-control tx-bold"
                           v-model="model.description" v-validate="'required'">
                    <span v-show="errors.has('description')"
                          class="parsley-errors-list filled">Digite uma Descrição</span>
                  </div>
                </div>

              </div>

              <div class="row no-gutters">
                <div class="col-md-6">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">Conta Bancária <span class="tx-danger">*</span></label>
                    <select v-model="model.bankAccountSource" name="bankAccountSource"
                            class="form-control select2-hidden-accessible" data-placeholder="Selecione Conta Bancária"
                            v-validate="'required'">
                      <option value="" disabled>Selecione</option>
                      <option :value="bankAccount" v-for="bankAccount in bankAccounts" :key="bankAccount.id">
                        {{bankAccount.description}}
                      </option>
                    </select>
                    <span v-show="errors.has('bankAccountSource')" class="parsley-errors-list filled">Digite uma Conta Bancária</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mg-md-l--1  bd-t-0-force">
                    <label class="form-control-label">Tipo de Pagamento <span class="tx-danger">*</span></label>
                    <select v-model="model.typeOfPayment" name="typeOfPayment"
                            class="form-control select2-hidden-accessible"
                            data-placeholder="Selecione um tipo de Pagamento" v-validate="'required'">
                      <option value="" disabled>Selecione</option>
                      <option :value="typeOfPayment" v-for="typeOfPayment in typeOfPayments" :key="typeOfPayment.id">
                        {{typeOfPayment.description}}
                      </option>
                    </select>
                    <span v-show="errors.has('typeOfPayment')" class="parsley-errors-list filled">Digite um Tipo de Pagamento</span>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">Valor Original <span class="tx-danger">*</span></label>
                    <money v-model="model.originalValue" v-bind="money" class="form-control currency"
                           name="originalValue" v-validate="'required'"></money>
                    <span v-show="errors.has('originalValue')" class="parsley-errors-list filled">Digite o Valor Original.</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">Valor de Desconto <span class="tx-danger">*</span></label>
                    <money v-model="model.discountValue" v-bind="money" class="form-control currency tx-bold"
                           name="discountValue"></money>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">Valor de Multa</label>
                    <money v-model="model.fineValue" v-bind="money" class="form-control currency tx-bold"
                           name="fineValue"></money>
                  </div>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="form-group bd-t-0-force">
                    <label class="form-control-label">Outros Adicionais </label>
                    <money v-model="model.additionsValue" v-bind="money" class="form-control currency tx-bold"
                           name="additionsValue"></money>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">Valor Pago <span class="tx-danger">*</span></label>
                    <money v-model="model.paidValue" v-bind="money" class="form-control currency tx-bold"
                           name="paidValue" v-validate="'required'" :value="calculateTotal()"></money>
                    <span v-show="errors.has('paidValue')"
                          class="parsley-errors-list filled">Digite o Valor Pago.</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mg-md-l--1 bd-t-0-force">
                    <label class="form-control-label">Data de Pagamento <span class="tx-danger">*</span></label>
                    <date-picker lang="pt-br" v-model="model.paymentDate" name="paymentDate"
                                 class="form-control tx-bold" format="DD/MM/YYYY" v-validate="'required'"></date-picker>
                    <span v-show="errors.has('paymentDate')" class="parsley-errors-list filled">Digite a Data de Pagamento.</span>
                  </div>
                </div>

              </div>

              <div class="form-layout-footer bd pd-y-40 pd-x-40 bd-t-0">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <button class="btn btn-sm btn-primary btn-block" @click.prevent="submit()"><i
                      class="fa fa-edit mg-r-5"></i> SALVAR
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-sm btn-danger btn-block" @click.prevent="$emit('close')"><i
                      class="fa fa-reply mg-r-5"></i> VOLTAR
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Axios from 'axios';
import {EventBus} from '@/events/EventBus.js';
import {Money} from 'v-money';
import DatePicker from 'vue2-datepicker';
import config from '@/config.js';
import Receipt from '@/models/Receipt.js';

export default {
  name: 'moviments-receipts-modal',
  props: ['model', 'isForm', 'finish'],
  components: {'date-picker': DatePicker, Money},
  watch: {},

  data() {
    return {
      modelTitle: 'Baixar Parcela',
      oldModel: new Receipt(),
      money: {
        decimal: ',', thousands: '.', prefix: 'R$ ', precision: 2, masked: false,
      },
      receiptAppoitmentDescription: '',
      bankAccounts: [],
      typeOfPayments: [],
    };
  },

  methods: {
    isNewRecord() {
      if (this.model.id !== undefined && this.model.id !== null) return false;
      return true;
    },
    submit() {
      this.$validator.validateAll().then((response) => {
        if (response) this.save();
        else this.$swal(this.modelTitle, 'Você não preencheu todos os campos obrigatórios.', 'error');
      });
    },
    save() {
      this.beforeSave();

      if (this.isNewRecord()) {
        this.$store.commit('setLoading', {
          show: true,
          label: 'Baixar Parcela',
          description: 'Processando sua requisição.'
        });
        Axios.post(`${config.env.API_DEFAULT.host}/receipts`, this.model).then((response) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
          this.$swal({
            title: this.modelTitle,
            text: `A baixa de parcela ${response.data.description} foi adicionada com sucesso.`,
            icon: 'success'
          });
          // if(this.isForm) EventBus.$emit('Finance.Moviments.Appointments.Form.General.loadModel');
          EventBus.$emit('Finance.Moviments.Appointments.Form.General.loadModel');
          this.$emit('finish');
        }).catch((error) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
          this.$swal(this.modelTitle, `Ops, houve algum erro: ${error}`, 'error');
          this.afterSave();
        });
      } else {
        this.$store.commit('setLoading', {
          show: true,
          label: 'Baixar Parcela',
          description: 'Processando sua requisição.'
        });
        Axios.patch(`${config.env.API_DEFAULT.host}/receipts/${this.model.id}`, this.model).then((response) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
          this.$swal({
            title: this.modelTitle,
            text: `A baixa de parcela ${response.data.description} foi atualizada com sucesso.`,
            icon: 'success'
          });
          // if(this.isForm) EventBus.$emit('Finance.Moviments.Appointments.Form.General.loadModel');
          EventBus.$emit('Finance.Moviments.Appointments.Form.General.loadModel');
          this.$emit('finish');
          this.$emit('close');
        }).catch((error) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
          this.$swal(this.modelTitle, `Ops, houve algum erro: ${error}`, 'error');
          this.afterSave();
        });
      }
    },

    beforeSave() {
      this.oldModel.typeOfPayment = this.model.typeOfPayment;
      this.oldModel.bankAccountSource = this.model.bankAccountSource;
      this.oldModel.company = this.model.appointment.company;
      this.oldModel.account = this.model.appointment.account;
      this.oldModel.appointment = this.model.appointment;
      this.oldModel.moviment = this.model.appointment.moviment;

      if (this.model.typeOfPayment !== null && this.model.typeOfPayment !== undefined) {
        this.model.typeOfPayment = this.model.typeOfPayment._links.self.href;
      }

      if (this.model.bankAccountSource !== null && this.model.bankAccountSource !== undefined) {
        this.model.bankAccountSource = this.model.bankAccountSource._links.self.href;
      }
      if (this.model.company !== null && this.model.company !== undefined) {
        this.model.company = this.model.company._links.self.href;
      }
      if (this.model.account !== null && this.model.account !== undefined) {
        this.model.account = `${config.env.API_DEFAULT.host}/accounts/${this.model.account.id}`;
      }
      if (this.model.appointment !== null && this.model.appointment !== undefined) {
        this.model.appointment = `${config.env.API_DEFAULT.host}/appointments/${this.model.appointment.id}`;
      }
      if (this.model.moviment !== null && this.model.moviment !== undefined) {
        this.model.moviment = `${config.env.API_DEFAULT.host}/moviments/${this.model.moviment.id}`;
      }
    },
    afterSave() {
      this.model.typeOfPayment = this.oldModel.typeOfPayment;
      this.model.bankAccountSource = this.oldModel.bankAccountSource;
      this.model.company = this.oldModel.company;
      this.model.account = this.oldModel.account;
      this.model.appointment = this.oldModel.appointment;
      this.model.moviment = this.oldModel.moviment;
    },

    calculateTotal() {
      this.model.paidValue = this.model.originalValue - this.model.discountValue + this.model.fineValue + this.model.additionsValue;
    },

  },
  computed: {},
  mounted() {
    const self = this;

    this.$store.commit('setLoading', {show: true, label: 'Baixar Parcela', description: 'Processando sua requisição.'});
    Axios.get(`${config.env.API_DEFAULT.host}/bankAccounts`).then((result) => {
      this.bankAccounts = result.data._embedded.bankAccounts;

      Axios.get(`${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByType?type=PAYMENT`).then((result) => {
        this.typeOfPayments = result.data._embedded.movimentTerms;
      }).catch((error) => {
        this.$store.commit('setLoading', {show: false, label: '', description: ''});
      });

      this.$store.commit('setLoading', {show: false, label: '', description: ''});
    }).catch((error) => {
      this.$store.commit('setLoading', {show: false, label: '', description: ''});
    });

    if (!this.isNewRecord()) {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Baixar parcela',
        description: 'Processando sua requisição.'
      });

      Axios.get(`${config.env.API_DEFAULT.host}/receipts/${this.model.id}`).then((result) => {
        this.$set(this, 'model', result.data);
        this.$set(this.model, 'appointment', result.data._embedded.appointment);

        Axios.get(`${config.env.API_DEFAULT.host}/receipts/${this.model.id}/typeOfPayment`).then((result) => {
          this.$set(this.model, 'typeOfPayment', result.data);
        }).catch((error) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
        });

        Axios.get(`${config.env.API_DEFAULT.host}/receipts/${this.model.id}/bankAccountSource`).then((result) => {
          this.$set(this.model, 'bankAccountSource', result.data);
        }).catch((error) => {
          this.$store.commit('setLoading', {show: false, label: '', description: ''});
        });

        this.$store.commit('setLoading', {show: false, label: '', description: ''});
      }).catch((error) => {
        this.$store.commit('setLoading', {show: false, label: '', description: ''});
      });
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  background-color: #f8f9fa;
  padding: 20px 15px 10px 15px;
}

.modal-header .close {
  padding: 18px 20px 0px 20px !important;
}

.modal-body {
  margin: 0px !important;
  padding: 0px !important;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container, .modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
