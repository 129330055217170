<template>
  <div>
    <table id="desk-table" class="table table-striped mg-b-0 tx-12">
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :class="{
              ordering: orderBy == column,
              'wd-105': labels[column].attribute == 'diNumber',
            }"
            class="tx-center pd-y-10 pd-x-5 tx-12"
            @click="changeOrderBy(column)"
            @dblclick="action"
            :key="`${column}-${index}`"
          >
            <template v-if="labels[column].type === 'ico'">
              <span class="tx-center">
                <i :class="labels[column].iconClass"></i>
              </span>
            </template>
            <template v-else>
              {{
                labels[column].alt !== undefined
                  ? labels[column].alt
                  : labels[column].label
              }}
              <img
                v-if="orderBy == column"
                :class="{ asc: orderAsc }"
                src="@/assets/img/table_sort.png"
                alt=""
              />
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data !== null && data.length > 0">
          <tr
            v-for="(processItem, i) in data"
            :key="`${processItem.id}-${i}`"
            :class="{
              'tb-tr-red-highlight': processItem.urgent,
              'tb-tr-today-highlight': isTodayDate(processItem),
            }"
          >
            <template v-if="openedProcesses[processItem.id]">
              <table-cell
                v-for="(column, index) in columns"
                :key="`${processItem.id}-${column}-${index}`"
                :column="column"
                :data="processItem"
                :fieldInfo="labels[column]"
                :opennedBy="openedProcesses[processItem.id].user"
              />
            </template>
            <template v-else>
              <table-cell
                v-for="(column, index) in columns"
                :key="`${processItem.id}-${column}-${index}`"
                :column="column"
                :data="processItem"
                :fieldInfo="labels[column]"
              />
            </template>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td :colspan="columns.length" class="tx-center">
              <div class="card bd-0 bg-gray-100 ht-100p pd-y-150">
                <div class="card-body">
                  <div class="d-flex justify-content-center mg-b-30">
                    <img src="@/assets/img/icon1.svg" class="wd-100" alt="" />
                  </div>
                  <h5 class="tx-inverse mg-b-20 tx-danger">
                    NENHUM PROCESSO DE IMPORTAÇÃO FOI ENCONTRADO
                  </h5>
                  <p class="mg-b-20 tx-16">
                    Selecione alguma etapa, e se desejar faça algum filtro
                    clicando em
                    <span class="tx-primary"
                      ><i class="icon ion-funnel tx-bold"></i> Filtrar</span
                    >
                    na parte superior do Telão.
                  </p>
                  <a
                    href="#"
                    class="btn btn-primary wd-400"
                    @click.prevent="$store.commit('ProcessStore/NEW_PROCESS')"
                    >ADICIONAR NOVO PROCESSO</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Axios from "axios";
import config from "@/config.js";
import moment from "moment";
import * as labelsProcesses from "@/labels/labels.processes.js";
import TableCell from "@/views/operational/imp/Desk.TableCell.vue";
import ProcessService from "@/services/ProcessService.js";

export default {
  components: {
    TableCell,
  },
  props: ["columns"],
  data() {
    return {
      orderBy: "default",
      orderAsc: true,
      // data: new Array,
      labels: {
        ...labelsProcesses.labels[0],
      },
      // connections: [],
    };
  },
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
      processes: (state) => state.ProcessStore.processes,
      process: (state) => state.ProcessStore.activeProcess,
      showProcess: (state) => state.ProcessStore.showProcess,
      openedProcesses: (state) => state.ProcessStore.openedProcesses,
    }),
    // listOfConnections() {
    //   return this.connections;
    // },
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    data() {
      if (this.processes && this.processes.length > 0) {
        const orderedData = [...this.processes].sort((a, b) => {
          if (!a[this.orderBy]) return 1;
          if (!b[this.orderBy]) return -1;

          let _a;
          let _b;

          if (this.orderBy == "step") {
            _a = a[this.orderBy].alias;
            _b = b[this.orderBy].alias;
          } else if (this.orderBy == "id" || this.orderBy == "identification") {
            _a = parseInt(a[this.orderBy]);
            _b = parseInt(b[this.orderBy]);

            if (this.orderAsc) {
              return _a
                .toString()
                .localeCompare(_b, undefined, {
                  numeric: true,
                })
                .toString();
            }
            return _b
              .toString()
              .localeCompare(_a, undefined, {
                numeric: true,
              })
              .toString();
          } else if (
            this.orderBy == "customer" ||
            this.orderBy == "exporter" ||
            this.orderBy == "customBroker" ||
            this.orderBy == "account" ||
            this.orderBy == "manufacturer"
          ) {
            if (
              typeof a[this.orderBy] === "object" &&
              a[this.orderBy] !== null &&
              typeof b[this.orderBy] === "object" &&
              b[this.orderBy] !== null
            ) {
              _a = a[this.orderBy].name;
              _b = b[this.orderBy].name;
            } else {
              _a = a[this.orderBy];
              _b = b[this.orderBy];
            }
          } else {
            _a = a[this.orderBy];
            _b = b[this.orderBy];
          }

          if (!_a) return 1;
          if (!_b) return -1;

          if (this.orderAsc) return _a.toString().localeCompare(_b).toString();
          return _b.toString().localeCompare(_a).toString();
        });

        // return [...orderedData.slice(0,50)];
        return [...orderedData];

        // Object.freeze retira a readividade da lista, serve pra deixar tbm menos carregado
        // this.data = Object.freeze(orderedData)
      }
      return [];
    },
  },
  methods: {
    isTodayDate(process) {
      if (
        process.datesETD != null &&
        Math.floor(
          moment.duration(
            moment(process.datesETD).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
      if (
        process.datesETA != null &&
        Math.floor(
          moment.duration(
            moment(process.datesETA).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
      if (
        process.datesEstimatedCustomerDeliverDate != null &&
        Math.floor(
          moment.duration(
            moment(process.datesEstimatedCustomerDeliverDate).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
      if (
        process.datesGoodsReadinesDate != null &&
        Math.floor(
          moment.duration(
            moment(process.datesGoodsReadinesDate).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
      if (
        process.datesDepartureDate != null &&
        Math.floor(
          moment.duration(
            moment(process.datesDepartureDate).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
      if (
        process.datesArrivalDate != null &&
        Math.floor(
          moment.duration(
            moment(process.datesArrivalDate).diff(
              moment(moment().startOf("day")),
              "days",
              true
            )
          )
        ) == 0
      )
        return true;
    },
    action() {
      this.orderBy = null;
    },
    changeOrderBy(target) {
      if (target == this.orderBy) this.orderAsc = !this.orderAsc;
      this.orderBy = target;
    },
    async updateProcess(idProcess) {
      try {
        const { data: response } = await Axios.get(
          `${config.env.API_DEFAULT.host}/process/import/find/${idProcess}`
        );

        const currentSteps =
          this.$router.currentRoute.query.stepsIds.split(",");

        const authUser = this.$store.state.credentials.data.person;

        if (
          response.data.step !== null &&
          currentSteps.includes(response.data.step.id.toString()) &&
          authUser.team.id === response.data.customer.team.id
        ) {
          if (response.code === "SUCCESS") {
            const processes = this.processes.map((item) =>
              item.id === response.data.id ? response.data : item
            );

            this.$store.commit("ProcessStore/SET_PROCESSES", processes);
          }

          this.$toasted.info(
            `O Processo ${this.getTypeOfProcessReferenceByProcess(
              response.data
            )} teve seus dados atualizados!`,
            {
              theme: "toasted-primary",
              position: "top-right",
              icon: "user-edit",
              duration: 5000,
              singleton: true,
            }
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    processes() {},
    $route(newVal, oldVal) {
      this.$forceUpdate;
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style scoped>
table thead th {
  position: sticky;
  top: 0;
  cursor: pointer;
  vertical-align: middle;
}

table thead th.ordering {
  color: #1b84e7;
}

table thead th img {
  position: absolute;
  right: 5px;
  top: calc(50% - 3px);
}

table thead th img.asc {
  transform: rotate(180deg);
}
</style>
