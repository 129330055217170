<template>
  <form @submit.prevent="submit()">
    <!-- <Loading ref="loading" :label="loadingLabel" :description="loadingDescription"></Loading> -->

    <modal-appointment
      ref="modal-appointment"
      v-if="this.modal.appointment.show"
      @close="modal.appointment.show = false"
      :model="this.modal.appointment.model"
      :isForm="false"
    ></modal-appointment>
    <modal-receipt
      ref="modal-receipt"
      v-if="this.modal.receipt.show"
      @close="modal.receipt.show = false"
      :model="this.modal.receipt.model"
      :isForm="false"
      :isAdministrativeMoviment="this.isAdministrativeMoviment"
    ></modal-receipt>

    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Tipo de Lançamento</label>

      <div class="row no-gutters">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="form-control-label"
            >Tipo de Lançamento<span class="tx-danger">*</span></label
            >

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <template v-if="this.$route.params.moviments === 'incomes'">
                    <input
                      type="radio"
                      name="typeOfMovimentation"
                      v-model="model.typeOfMovimentation"
                      value="FINANCE_INCOME"
                      @change.prevent="changeTypeOfMovimentation"
                      checked="checked"
                      v-validate="'required'"
                    />
                  </template>
                  <template v-else>
                    <input
                      type="radio"
                      name="typeOfMovimentation"
                      v-model="model.typeOfMovimentation"
                      value="FINANCE_EXPENSE"
                      @change.prevent="changeTypeOfMovimentation"
                      checked="checked"
                      v-validate="'required'"
                    />
                  </template>
                  <span> Administrativo </span>
                </label>
                <span
                  v-show="errors.has('typeOfMovimentation')"
                  class="parsley-errors-list filled"
                >Selecione o tipo de movimentação</span
                >
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <template v-if="this.$route.params.moviments === 'incomes'">
                    <input
                      type="radio"
                      name="typeOfMovimentation"
                      v-model="model.typeOfMovimentation"
                      value="PROCESS_INCOME"
                      @change.prevent="changeTypeOfMovimentation"
                      v-validate="'required'"
                    />
                  </template>
                  <template v-else>
                    <input
                      type="radio"
                      name="typeOfMovimentation"
                      v-model="model.typeOfMovimentation"
                      value="PROCESS_EXPENSE"
                      @change.prevent="changeTypeOfMovimentation"
                      v-validate="'required'"
                    />
                  </template>
                  <span>Operacional </span>
                </label>
                <span
                  v-show="errors.has('typeOfMovimentation')"
                  class="parsley-errors-list filled"
                >Selecione uma opção</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3" v-if="!isAdministrativeMoviment">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Exibir na Conta Corrente? <span class="tx-danger">*</span></label
            >
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="enabledMovimentCheckingAccountOfProcess"
                    v-model="model.enabledMovimentCheckingAccountOfProcess"
                    value="true"
                    v-validate="'required'"
                  />
                  <span> Sim <small></small></span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="enabledMovimentCheckingAccountOfProcess"
                    v-model="model.enabledMovimentCheckingAccountOfProcess"
                    value="false"
                    v-validate="'required'"
                  />
                  <span> Não </span>
                </label>
              </div>
            </div>
            <span
              v-show="errors.has('enabledMovimentCheckingAccountOfProcess')"
              class="parsley-errors-list filled"
            >Selecione uma opção</span
            >
          </div>
        </div>
        <div class="col-lg-3" v-if="!this.isAdministrativeMoviment">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Classificação <span class="tx-danger">*</span></label
            >

            <select
              v-model="model.movimentOfProcessClassification"
              name="movimentOfProcessClassification"
              class="form-control select2-hidden-accessible"
              data-placeholder="Selecione uma Classificação"
              v-validate="'required'"
            >
              <option value="" disabled>Selecione</option>
              <option
                :value="movimentOfProcessClassification.key"
                v-for="movimentOfProcessClassification in movimentOfProcessClassifications"
                :key="movimentOfProcessClassification.key"
              >
                {{ movimentOfProcessClassification.value }}
              </option>
            </select>
            <span
              v-show="errors.has('movimentOfProcessClassification')"
              class="parsley-errors-list filled"
            >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-md-6 col-sm-6" v-if="this.isAdministrativeMoviment">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label"
            >Categoria / Plano de Contas
              <span class="tx-danger">*</span></label
            >
            <v-select
              v-model="model.chartOfAccount"
              name="chartOfAccount"
              :on-search="getChartOfAccounts"
              label="description"
              :options="this.chartOfAccounts"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.description }}
              </template>
              <span slot="no-options"
              >Nenhuma Categoria / Plano de Contas foi encontrada!</span
              >
            </v-select>
            <span
              v-show="errors.has('chartOfAccount')"
              class="parsley-errors-list filled"
            >Selecione uma categoria</span
            >
          </div>
        </div>
        <div class="col-md-3" v-if="!this.isAdministrativeMoviment">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">
              Desagrupar os Itens? <span class="tx-danger">*</span></label
            >
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="groupedItens"
                    v-model="model.groupedItens"
                    value="true"
                    v-validate="'required'"
                  />
                  <span> Sim </span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="groupedItens"
                    v-model="model.groupedItens"
                    value="false"
                    v-validate="'required'"
                  />
                  <span> Não </span>
                </label>
              </div>
            </div>
            <span
              v-show="errors.has('groupedItens')"
              class="parsley-errors-list filled"
            >Selecione uma opção</span
            >
          </div>
        </div>
      </div>

      <div class="row no-gutters" v-if="!this.isAdministrativeMoviment">
        <div class="col-lg-3">
          <div
            class="form-group bd-t-0-force"
            v-if="!this.isAdministrativeMoviment"
          >
            <label class="form-control-label">
              Exibir no Processo ? <span class="tx-danger">*</span></label
            >
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="showIntoProcess"
                    v-model="model.showIntoProcess"
                    value="true"
                    v-validate="'required'"
                  />
                  <span> Sim </span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="showIntoProcess"
                    v-model="model.showIntoProcess"
                    value="false"
                    v-validate="'required'"
                  />
                  <span> Não </span>
                </label>
              </div>
            </div>
            <span
              v-show="errors.has('showIntoProcess')"
              class="parsley-errors-list filled"
            >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-lg-3" v-if="!this.isAdministrativeMoviment">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> Processo</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="typeOfProcess"
                    v-model="model.typeOfProcess"
                    value="IMPORT"
                    @change.prevent="changeTypeOfProcess"
                  />
                  <span> Importação <small></small></span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox">
                  <input
                    type="radio"
                    name="typeOfProcess"
                    v-model="model.typeOfProcess"
                    value="EXPORT"
                    @change.prevent="changeTypeOfProcess"
                  />
                  <span> Exportação </span>
                </label>
              </div>
            </div>
            <span
              v-show="errors.has('typeOfProcess')"
              class="parsley-errors-list filled"
            >Selecione uma opção</span
            >
          </div>
        </div>

        <div class="col-lg-3" v-if="!this.isAdministrativeMoviment">
          <div
            class="form-group mg-md-l--1 bd-t-0-force"
            v-if="
              this.typeOfProcess === 'IMPORT' ||
              this.model.typeOfProcess === 'IMPORT'
            "
          >
            <label class="form-control-label"> Processo de Importação </label>
            <v-select
              v-model="model.process"
              :on-search="getImportProcesses"
              label="identification"
              :options="this.importProcesses"
            >
              <span slot="no-options">Nenhum Processo foi encontrado!</span>
            </v-select>
            <span
              v-show="errors.has('process')"
              class="parsley-errors-list filled"
            >Selecione um processo</span
            >
          </div>
          <div
            class="form-group mg-md-l--1 bd-t-0-force"
            v-else-if="
              this.typeOfProcess === 'EXPORT' ||
              this.model.typeOfProcess === 'EXPORT'
            "
          >
            <label class="form-control-label"> Processo de Exportação </label>
            <v-select
              v-model="model.process"
              :on-search="getExportProcesses"
              label="identification"
              :options="this.exportProcesses"
            >
              <span slot="no-options">Nenhum Processo foi encontrado!</span>
            </v-select>
            <span
              v-show="errors.has('process')"
              class="parsley-errors-list filled"
            >Selecione um processo</span
            >
          </div>
        </div>

        <div class="col-lg-3" v-if="!this.isAdministrativeMoviment">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <div class="row">
              <div class="col">
                <h6 class="slim-card-title tx-success">Créditos</h6>
                <h4 class="">R$ 0,00</h4>
              </div>
              <!-- col -->
              <div class="col">
                <h6 class="slim-card-title tx-danger">Débitos</h6>
                <h4 class="">R$ 0,00</h4>
              </div>
              <!-- col -->
              <div class="col">
                <h6 class="slim-card-title tx-primary">SALDO</h6>
                <h4 class="">R$ 0,00</h4>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
          </div>
        </div>
      </div>

      <div class="form-layout form-layout-2 mg-t-20">
        <label class="section-title mg-t-0">Informações do Lançamento</label>
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"
              >Descrição <span class="tx-danger">*</span></label
              >
              <input
                type="text"
                placeholder="Descrição"
                name="description"
                class="form-control"
                v-model="model.description"
                v-validate="'required'"
              />
              <span
                v-show="errors.has('description')"
                class="parsley-errors-list filled"
              >Digite uma descrição</span
              >
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"
              >Data do Movimento <span class="tx-danger">*</span></label
              >
              <date-picker lang="pt-br"
                           v-model="model.movimentDate"
                           name="movimentDate"
                           class="form-control"
                           format="DD/MM/YYYY"
                           v-validate="'required'"
              ></date-picker>
              <br/>
              <span
                v-show="errors.has('movimentDate')"
                class="parsley-errors-list filled"
              >Selecione uma data de movimento</span
              >
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label"
              >Centro de Custos <span class="tx-danger">*</span></label
              >
              <v-select
                v-model="model.typeOfCostCenter"
                name="typeOfCostCenter"
                :on-search="getTypeOfCostCenters"
                label="description"
                :options="this.typeOfCostCenters"
                v-validate="'required'"
              >
                <span slot="no-options"
                >Nenhum Centro de Custos foi encontrado!</span
                >
              </v-select>
              <span
                v-show="errors.has('typeOfCostCenter')"
                class="parsley-errors-list filled"
              >Selecione um centro de custos</span
              >
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label
                class="form-control-label"
                v-if="this.$route.params.moviments === 'incomes'"
              >Cliente/Pagador <span class="tx-danger">*</span></label
              >
              <label class="form-control-label" v-else
              >Fornecedor/Recebedor <span class="tx-danger">*</span></label
              >

              <v-select
                v-model="model.company"
                :on-search="getCompanies"
                label="name"
                :options="this.companies"
                name="company"
                v-validate="'required'"
              >
                <template slot="option" slot-scope="option">
                  {{ option.federalID }} | {{ option.name }}
                </template>
                <span slot="no-options">Nenhuma empresa foi encontrada!</span>
              </v-select>
              <span
                v-show="errors.has('company')"
                class="parsley-errors-list filled"
              >Selecione uma empresa</span
              >
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
              >Filial <span class="tx-danger">*</span></label
              >
              <v-select
                v-model="model.account"
                :on-search="getAccounts"
                label="name"
                :options="this.accounts"
                name="account"
                v-validate="'required'"
              >
                <template slot="option" slot-scope="option">
                  {{ option.federalID }} | {{ option.name }}
                </template>
                <span slot="no-options">Nenhuma Filial foi encontrada!</span>
              </v-select>
              <span
                v-show="errors.has('account')"
                class="parsley-errors-list filled"
              >Selecione uma filial</span
              >
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Tipo de Documento</label>
              <v-select
                v-model="model.typeOfDocument"
                :on-search="getTypeOfDocuments"
                label="description"
                :options="this.typeOfDocuments"
              >
                <span slot="no-options"
                >Nenhum Tipo de Documento foi encontrado!</span
                >
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Número de Documento</label>
              <input
                type="text"
                placeholder="Número de Documento"
                name="typeOfDocumentValue"
                class="form-control"
                v-model="model.typeOfDocumentValue"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Data de Emissão</label>
              <date-picker lang="pt-br"
                           v-model="model.documentDate"
                           name="documentDate"
                           class="form-control"
                           format="DD/MM/YYYY"
              ></date-picker>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force"></div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
              >Valor Bruto <span class="tx-danger">*</span></label
              >
              <money
                v-model="model.grossValue"
                v-bind="money"
                class="form-control m-input"
                name="money"
                v-validate="'required'"
              ></money>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Alíquota IR</label>
              <money
                v-model="model.irAliquot"
                v-bind="percent"
                class="form-control m-input"
                name="money"
                v-validate="'required'"
              ></money>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Alíquota PCC</label>
              <money
                v-model="model.pccAliquot"
                v-bind="percent"
                class="form-control m-input"
                name="money"
                v-validate="'required'"
              ></money>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
              >Valor Líquido <span class="tx-danger">*</span></label
              >
              <money
                v-model="calculateNetValue"
                v-bind="money"
                class="form-control m-input"
                name="money"
                v-validate="'required'"
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!isNewRecord">
      <div
        class="d-flex align-items-center bg-gray-100 ht-md-80 bd pd-x-20 mg-t-20 mg-b-20"
      >
        <template v-if="!isOperational">
          <template v-if="appointments !== null && appointments.lenght > 0">
            <input
              type="text"
              class="form-control wd-180 mg-l-10 tx-center"
              placeholder="Quantidade de Parcelas"
              v-model="model.quantityOfRepetitions"
              maxlength="2"
              disabled
            />
            <button
              @click="generateAppointments()"
              type="button"
              class="btn btn-secondary bd-0 mg-md-l-10 mg-t-10 mg-md-t-0"
              disabled
            >
              <i class="fa sync-alt mg-r-5"></i> GERAR
              <b>{{ model.quantityOfRepetitions }}</b> PARCELAS
            </button>
          </template>
          <template v-else>
            <input
              type="text"
              class="form-control wd-180 mg-l-10 tx-center"
              placeholder="Quantidade de Parcelas"
              v-model="model.quantityOfRepetitions"
              maxlength="2"
            />
            <button
              @click="generateAppointments()"
              type="button"
              class="btn btn-secondary bd-0 mg-md-l-10 mg-t-10 mg-md-t-0"
            >
              <i class="fa sync-alt mg-r-5"></i> GERAR
              <b>{{ model.quantityOfRepetitions }}</b> PARCELAS
            </button>
          </template>

          <button
            type="button"
            class="btn btn-success bd-0 mg-md-l-100 mg-t-10 mg-md-t-0"
            @click.prevent="saveAppointments()"
          >
            <i class="fa fa-floppy-o mg-r-5"></i> SALVAR PARCELAS
          </button>
          <button
            @click="generateAppointment()"
            type="button"
            class="btn btn-primary bd-0 mg-md-l-10 mg-t-10 mg-md-t-0"
          >
            <i class="fa fa-plus mg-r-5"></i> ADICIONAR PARCELA
          </button>
        </template>

        <template v-else>
          <button
            @click="generateAppointment()"
            type="button"
            class="btn btn-primary bd-0 mg-md-r-10 mg-t-10 mg-md-t-0"
          >
            <i class="fa fa-plus mg-r-5"></i> ADICIONAR RATEIO
          </button>
          <button
            type="button"
            class="btn btn-success bd-0 mg-md-l-0 mg-t-10 mg-md-t-0"
            @click.prevent="saveAppointments()"
          >
            <i class="fa fa-floppy-o mg-r-5"></i> SALVAR RATEIOS
          </button>
        </template>
      </div>

      <div class="form-layout form-layout-2">
        <label class="section-title mg-t-0"> {{ labelAppointments }} </label>

        <div class="row no-gutters">
          <div class="col-md-12 mb-t-10--force">
            <div class="alert alert-solid alert-warning mg-b-0" role="alert">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Alteração de parcela identificada!</strong> Após terminar
              a alteração, não esqueça de clicar em salvar para que suas
              alterações sejam gravadas no banco de dados.
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <table class="table table-striped mg-b-0 appointments">
            <thead>
            <tr>
              <th scope="row tx-center">#</th>
              <th class="tx-left">Descrição</th>
              <th class="tx-center" v-if="!isAdministrativeMoviment">
                Processo
              </th>
              <th class="tx-center">Vencimento</th>
              <th class="tx-center">Valor Origem</th>
              <th class="tx-center">
                IR
                <small v-if="model.irAliquot > 0"
                >%{{ model.irAliquot | numeralFormat("0.00") }}</small
                >
              </th>
              <th class="tx-center">
                PCC
                <small v-if="model.pccAliquot > 0">
                  %{{ model.pccAliquot | numeralFormat("0.00") }}</small
                >
              </th>
              <th class="tx-center">Desconto</th>
              <th class="tx-center">Multa</th>
              <th class="tx-center">Outros Adicionais</th>
              <th class="tx-center">Total da Parcela</th>
              <th colspan="3"></th>
            </tr>
            </thead>
            <tbody v-if="appointments.length > 0">
            <template
              v-if="
                  appointments !== undefined &&
                  appointments !== null &&
                  appointments.length > 0
                "
            >
              <tr
                v-for="(appointment, appointmentIndex) in appointments"
                :key="'tr-appointment-' + appointment.index"
              >
                <td class="tx-center tx-bold tx-black wd-50">
                  <input
                    type="text"
                    class="form-control tx-center"
                    v-model="appointment.index"
                  />
                </td>
                <td class="tx-left wd-200">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Descrição"
                    v-model="appointment.description"
                  />
                </td>
                <td
                  class="tx-center tx-bold tx-black"
                  v-if="!isAdministrativeMoviment"
                >
                  <template
                    v-if="
                        model.typeOfMovimentation === 'PROCESS_INCOME' ||
                        model.typeOfMovimentation === 'PROCESS_EXPENSE'
                      "
                  >
                    <template
                      v-if="
                          model.typeOfProcess === 'IMPORT' &&
                          appointment.importProcess !== undefined
                        "
                    >
                      {{ appointment.importProcess.identification }}
                    </template>
                    <template
                      v-else-if="
                          model.typeOfProcess === 'EXPORT' &&
                          appointment.exportProcess !== undefined
                        "
                    >
                      {{ appointment.exportProcess.identification }}
                    </template>
                  </template>
                </td>
                <td class="tx-center">
                  <date-picker lang="pt-br"
                               v-model="appointment.dueDate"
                               name="dueDate"
                               class="form-control"
                               format="DD/MM/YYYY"
                               v-validate="'required'"
                  ></date-picker>
                </td>

                <td class="tx-right">
                  <money
                    v-model="appointment.originalValue"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                    v-validate="'required'"
                  ></money>
                </td>

                <td class="tx-right">
                  <money
                    v-model="appointment.irAliquot"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                  ></money>
                </td>

                <td class="tx-right">
                  <money
                    v-model="appointment.pccAliquot"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                  ></money>
                </td>

                <td class="tx-right">
                  <money
                    v-model="appointment.discountValue"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                  ></money>
                </td>

                <td class="tx-right">
                  <money
                    v-model="appointment.fineValue"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                  ></money>
                </td>
                <td class="tx-right">
                  <money
                    v-model="appointment.additionsValue"
                    v-bind="money"
                    class="form-control currency"
                    name="money"
                  ></money>
                </td>
                <td class="tx-right wd-150">
                  R$
                  {{
                  appointmentsTotalRow[appointmentIndex]
                  | numeralFormat("0,0.00")
                  }}
                </td>
                <td class="tx-center pd-r-0-force pd-l-5-force wd-20">
                  <a
                    href="#"
                    class="btn btn-success btn-icon rounded-circle mg-r-5"
                    @click.prevent="payAppointment(appointment)"
                  >
                    <div><i class="icon ion-archive tx-15"></i></div
                    >
                  </a>
                </td>
                <td class="tx-center pd-r-0-force pd-l-5-force wd-20">
                  <a
                    href="#"
                    class="btn btn-info btn-icon rounded-circle mg-r-5"
                    @click.prevent="editAppointment(appointment)"
                  >
                    <div><i class="fa fa-pencil tx-20"></i></div>
                  </a>
                </td>
                <td class="tx-center pd-l-0-force pd-l-5-force wd-20">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon rounded-circle"
                    @click.prevent="removeAppointment(appointment)"
                  >
                    <div><i class="icon ion-trash-a tx-15"></i></div>
                  </a>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th colspan="13" class="tx-bold tx-center">
                  NENHUMA PARCELA FOI CADASTRADA
                </th>
              </tr>
            </template>
            </tbody>
            <tfoot>
            <tr>
              <td class="tx-right" colspan="4">Valores Totais</td>
              <td class="tx-right">
                R$
                {{ appointmentsTotalOriginalValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ appointmentsTotalIrAliquot | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ appointmentsTotalpccAliquot | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$
                {{ appointmentsTotalDiscountValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ appointmentsTotalFineValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$
                {{
                appointmentsTotalAdditionsValue | numeralFormat("0,0.00")
                }}
              </td>
              <td class="tx-right">
                R$ {{ appointmentsTotal | numeralFormat("0,0.00") }}
              </td>
              <td colspan="3"></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="form-layout form-layout-2 mg-t-20">
        <label class="section-title mg-t-0">Baixas / Pagamentos</label>

        <div class="row no-gutters">
          <div class="col-md-12 mb-t-10--force">
            <div class="alert alert-solid alert-warning mg-b-0" role="alert">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Alteração de baixa identificada!</strong> Após terminar a
              alteração, não esqueça de clicar em salvar para que suas
              alterações sejam gravadas no banco de dados.
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <table class="table table-striped mg-b-0 appointments">
            <thead>
            <tr>
              <th scope="row" class="tx-center">Parcela</th>
              <th class="tx-left wd-200">Descrição</th>
              <th class="tx-center">Pagamento</th>
              <th class="tx-center">Original</th>
              <th class="tx-center">Desconto</th>
              <th class="tx-center">Multa</th>
              <th class="tx-center">Outros Adicionais</th>
              <th class="tx-center">Total da Baixa</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <template
              v-if="
                  receipts !== undefined &&
                  receipts !== null &&
                  receipts.length > 0
                "
            >
              <tr
                v-for="(receipt, receiptIndex) in receipts"
                :key="
                    'tr-receipt-' + receipt.appointment.id + '-' + receipt.id
                  "
              >
                <td class="tx-center tx-bold">
                  {{ receipt.appointment.index }}
                </td>
                <td class="tx-left wd-200">{{ receipt.description }}</td>
                <td class="tx-center">
                  {{ receipt.paymentDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tx-right">
                  R$ {{ receipt.originalValue | numeralFormat("0,0.00") }}
                </td>
                <td class="tx-right">
                  R$ {{ receipt.discountValue | numeralFormat("0,0.00") }}
                </td>
                <td class="tx-right">
                  R$ {{ receipt.fineValue | numeralFormat("0,0.00") }}
                </td>
                <td class="tx-right">
                  R$ {{ receipt.additionsValue | numeralFormat("0,0.00") }}
                </td>
                <td class="tx-right wd-150">
                  R$
                  {{
                  receiptsTotalRow[receiptIndex] | numeralFormat("0,0.00")
                  }}
                </td>
                <td class="tx-center pd-r-0-force pd-l-5-force wd-20">
                  <a
                    href="#"
                    class="btn btn-info btn-icon rounded-circle mg-r-5"
                    @click.prevent="editReceipt(receipt)"
                  >
                    <div><i class="fa fa-pencil tx-20"></i></div>
                  </a>
                </td>
                <td class="tx-center pd-l-0-force pd-l-5-force wd-20">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon rounded-circle"
                    @click.prevent="removeReceipt(receipt)"
                  >
                    <div><i class="icon ion-trash-a tx-15"></i></div>
                  </a>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th colspan="10" class="tx-bold tx-center">
                  NENHUMA BAIXA FOI REALIZADA
                </th>
              </tr>
            </template>
            </tbody>
            <tfoot>
            <tr>
              <td class="tx-right" colspan="3">Valores Totais</td>
              <td class="tx-right">
                R$ {{ receiptsTotalPaidValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ receiptsTotalDiscountValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ receiptsTotalFineValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ receiptsTotalAdditionsValue | numeralFormat("0,0.00") }}
              </td>
              <td class="tx-right">
                R$ {{ receiptsTotal | numeralFormat("0,0.00") }}
              </td>
              <td></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>

    <div class="row mg-t-20">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i> SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-secondary btn-block mg-l-10-force"
          type="button"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-reply mg-r-5"></i> VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>
<script>
import config from "@/config.js";
import * as constants from "@/consts.js";
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {Money} from "v-money";
import moment from "moment";
import {EventBus} from "@/events/EventBus.js";

import AppointmentService from "@/services/AppointmentService.js";
import Appointment from "@/models/Appointment.js";
import ModalReceipt from "@/views/finances/moviments/receipts/Modal.vue";
import ModalAppointment from "@/views/finances/moviments/appointments/Modal.vue";

export default {
  name: "moviments-appointments-form-general",
  props: ["model", "modelTitle", "url", "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
    DatePicker,
    Money,
    "modal-receipt": ModalReceipt,
    "modal-appointment": ModalAppointment,
  },
  watch: {
    model(oldVal, newVal) {
      this.changeTypeOfMovimentation();

      if (newVal._embedded !== undefined) {
        this.receipts = newVal._embedded.receipts;
        this.appointments = newVal._embedded.appointments;
      } else {
        this.receipts = [];
        this.appointments = [];
      }
    },
  },
  mounted() {
  },
  data() {
    return {
      modal: {
        appointment: {show: false, model: null},
        receipt: {show: false, model: null},
      },
      validator: null,
      isAdministrativeMoviment: true,
      hasMultipleMoviment: false,
      items: [],
      selected: null,
      query: null,
      typeOfProcess: null,
      importProcess: null,
      importProcesses: [],
      exportProcess: null,
      exportProcesses: [],
      accounts: [],
      chartOfAccounts: [],
      companies: [],
      bankAccounts: [],
      typeOfDocuments: [],
      typeOfCostCenters: [],
      receipts: [],
      appointments: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        prefix: "% ",
        precision: 2,
        masked: false,
      },
      movimentOfProcessClassifications:
      constants.MOVIMENT_OF_PROCESS_CLASSIFICATIONS,
      yesterday: moment().subtract(0, "day").startOf("day").toDate(),
      quantityOfRepetitionsIndex: 0,
      totalValue: 0,
      receiptAppoitmentDescription: "",
      loadingLabel: "",
      loadingDescription: "",
      index: 0,
    };
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isNewRecord) this.addModel();
          else this.updateModel();
        } else
          this.$swal(
            this.modelTitle,
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
      });
    },

    addModel() {
      this.modelConfiguration();
      this.$store.commit("setLoading", {
        show: true,
        label: this.loadingLabel,
        description: this.loadingDescription,
      });
      Axios.post(`${config.env.API_DEFAULT.host}/moviments`, this.model)
        .then((response) => {
          if (response.status == 201) {
            this.$swal({
              title: this.modelTitle,
              text: `A ${this.modelTitle} ${response.data.description} foi adicionada com sucesso.`,
              icon: "success",
            });
            this.$router.push(
              `/finances/moviments/${this.$route.params.moviments}/update/${response.data.id}`
            );
          }
          this.modelReloadAfterPersitence();
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          this.$swal(
            this.modelTitle,
            `Ops, houve algum erro: ${error}`,
            "error"
          );
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          this.modelReloadAfterPersitence();
        });
    },

    updateModel() {
      this.modelConfiguration();
      this.$store.commit("setLoading", {
        show: true,
        label: this.loadingLabel,
        description: this.loadingDescription,
      });
      Axios.patch(
        `${config.env.API_DEFAULT.host}/moviments/${this.model.id}`,
        this.model
      )
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: this.modelTitle,
              text: `A ${this.modelTitle} ${response.data.description} foi atualizada com sucesso.`,
              icon: "success",
            });
          }
          this.modelReloadAfterPersitence();
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        })
        .catch((error) => {
          swal(this.modelTitle, `Ops, houve algum erro: ${error}`, "error");
        });
    },

    modelConfiguration() {
      // if(this.isNewRecord) this.model.userRegistry = config.env.API_DEFAULT.host+"/users/"+this.$store.state.credentials.data.person.id;
      this.oldModel.chartOfAccount = this.model.chartOfAccount;
      this.oldModel.account = this.model.account;
      this.oldModel.company = this.model.company;
      this.oldModel.typeOfDocument = this.model.typeOfDocument;
      this.oldModel.typeOfCostCenter = this.model.typeOfCostCenter;
      this.oldModel.appointments = this.model.appointments;
      this.oldModel.process = this.model.process;
      // this.oldModel.movimentOfProcessClassification = this.model.movimentOfProcessClassification;

      if (
        this.model.typeOfMovimentation !== "PROCESS_INCOME" &&
        this.model.typeOfMovimentation !== "PROCESS_EXPENSE"
      ) {
        this.model.chartOfAccount = this.model.chartOfAccount._links.self.href;
      } else {
        this.oldModel.process = this.model.process;
        if (this.model.process !== null && this.model.process !== undefined)
          this.model.process = `${config.env.API_DEFAULT.host}/process/${this.model.process.id}`;
      }

      if (this.model.account !== null && this.model.account !== undefined)
        this.model.account = this.model.account._links.self.href;
      if (this.model.company !== null && this.model.company !== undefined)
        this.model.company = this.model.company._links.self.href;
      if (
        this.model.typeOfDocument !== null &&
        this.model.typeOfDocument !== undefined
      )
        this.model.typeOfDocument = this.model.typeOfDocument._links.self.href;
      if (
        this.model.typeOfCostCenter !== null &&
        this.model.typeOfCostCenter !== undefined
      )
        this.model.typeOfCostCenter =
          this.model.typeOfCostCenter._links.self.href;
      // if(this.model.movimentOfProcessClassification !== null && this.model.movimentOfProcessClassification !== undefined)	this.model.movimentOfProcessClassification = this.model.movimentOfProcessClassification.key;

      delete this.model.appointments;
    },
    modelReloadAfterPersitence() {
      this.model.chartOfAccount = this.oldModel.chartOfAccount;
      this.model.account = this.oldModel.account;
      this.model.company = this.oldModel.company;
      this.model.typeOfDocument = this.oldModel.typeOfDocument;
      this.model.typeOfCostCenter = this.oldModel.typeOfCostCenter;
      this.model.appointments = this.oldModel.appointments;
      this.model.process = this.oldModel.process;
      // this.model.movimentOfProcessClassification = this.oldModel.movimentOfProcessClassification;
    },
    redirect() {
      this.$router.push(`/finances/moviments/${this.$route.params.moviments}`);
    },
    changeTypeOfMovimentation() {
      if (
        this.model.typeOfMovimentation == "PROCESS_INCOME" ||
        this.model.typeOfMovimentation == "PROCESS_EXPENSE"
      ) {
        this.isAdministrativeMoviment = false;
        this.model.multipleMoviment = false;
        this.hasMultipleMoviment = false;
        this.model.chartOfAccount = null;
        this.model.groupedItens = false;

        if (this.model.typeOfProcess === null) {
          this.model.typeOfProcess = "IMPORT";
        }
      } else {
        this.isAdministrativeMoviment = true;
        this.model.enabledMovimentCheckingAccountOfProcess = false;
        this.model.movimentOfProcessClassification = null;
        this.model.groupedItens = null;
        this.model.typeOfProcess = null;
        this.model.process = null;
      }

      EventBus.$emit(
        "Finance.Moviments.Form.Moviment.changeTypeOfMovimentation",
        this.isAdministrativeMoviment
      );
      EventBus.$emit(
        "Finance.Moviments.Form.Moviment.changeMultipleMoviment",
        this.model.multipleMoviment
      );
    },
    changeMultipleMoviment(event) {
      EventBus.$emit(
        "Finance.Moviments.Form.Moviment.changeMultipleMoviment",
        this.model.multipleMoviment
      );
      this.hasMultipleMoviment = this.model.multipleMoviment;
    },
    changeTypeOfProcess() {
      if (this.model.typeOfProcess === "IMPORT") this.typeOfProcess = "IMPORT";
      else this.typeOfProcess = "EXPORT";
    },

    getImportProcesses(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/search/findAllByIdentification?identification=${search}`
      )
        .then((response) => {
          this.importProcesses = response.data._embedded.importProcesses;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Processos de Importação",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    getExportProcesses(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/exportProcesses/search/findAllByIdentification?identification=${search}`
      )
        .then((response) => {
          this.exportProcesses = response.data._embedded.exportProcesses;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Processos de Exportação",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    getAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/accounts/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.accounts = response.data._embedded.accounts;
          loading(false);
        })
        .catch((error) => {
          swal("Filiais", `Ops, houve algum erro: ${error}`, "error");
        });
    },
    getChartOfAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/chartOfAccounts/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          this.chartOfAccounts = response.data._embedded.chartOfAccounts;
          loading(false);
        })
        .catch((error) => {
          swal("Categorias", `Ops, houve algum erro: ${error}`, "error");
        });
    },
    getCompanies(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${search}`
      )
        .then((response) => {
          this.companies = response.data._embedded.companies;
          loading(false);
        })
        .catch((error) => {
          swal("Empresas", `Ops, houve algum erro: ${error}`, "error");
        });
    },
    getBankAccounts(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/bankAccounts/search/findAllByDescriptionContainingIgnoreCase?description=${search}`
      )
        .then((response) => {
          this.bankAccounts = response.data._embedded.bankAccounts;
          loading(false);
        })
        .catch((error) => {
          swal("Contas Bancárias", `Ops, houve algum erro: ${error}`, "error");
        });
    },
    getTypeOfDocuments(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByTypeAndDescriptionContainingIgnoreCase?type=DOCUMENT&description=${search}`
      )
        .then((response) => {
          this.typeOfDocuments = response.data._embedded.movimentTerms;
          loading(false);
        })
        .catch((error) => {
          swal(
            "Tipos de Documentos",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    getTypeOfCostCenters(search, loading) {
      loading(true);
      Axios.get(
        `${config.env.API_DEFAULT.host}/movimentTerms/search/findAllByTypeAndDescriptionContainingIgnoreCase?type=COST_CENTER&description=${search}`
      )
        .then((response) => {
          this.typeOfCostCenters = response.data._embedded.movimentTerms;
          loading(false);
        })
        .catch((error) => {
          swal("Centros de Custos", `Ops, houve algum erro: ${error}`, "error");
        });
    },

    /**
     * payAppointment
     * START
     * Baixas
     */
    payAppointment(appointment) {
      let text = "Você tem certeza que deseja adicionar uma nova parcela?";
      if (
        this.model.typeOfMovimentation === "PROCESS_INCOME" ||
        this.model.typeOfMovimentation === "PROCESS_PROCESS_EXPENSEINCOME"
      ) {
        text = "Você tem certeza que deseja adicionar um novo rateio?";
      }

      this.$swal({
        title: "Você tem certeza?",
        text,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        this.loadingLabel = "Baixar Pagamento";
        this.loadingDescription = "Processando sua requisição.";

        if (willContinue) {
          this.$store.commit("setLoading", {
            show: true,
            label: this.loadingLabel,
            description: this.loadingDescription,
          });

          if (this.receipts === null) {
            this.receipts = new Array();
          }

          this.receipts.push(this.createNewModelReceipt(appointment));
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        }
      });
    },
    createNewModelReceipt(appointment) {
      return AppointmentService.createNewModelReceipt(appointment);
    },
    removeReceipt(receipt) {
      AppointmentService.removeReceipt(
        receipt,
        this.$swal,
        () => {
          this.$store.commit("setLoading", {
            show: true,
            label: this.loadingLabel,
            description: this.loadingDescription,
          });
        },
        () => {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          this.$swal("Excluir Baixa", "Baixa excluída com sucesso!", "success");
          EventBus.$emit(
            "Finance.Moviments.Appointments.Form.General.loadModel"
          );
        },
        () => {
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
          this.$swal(
            "Excluir Baixa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        }
      );
    },
    editReceipt(receipt) {
      this.modal.receipt.show = true;
      this.modal.receipt.model = receipt;
    },
    editAppointment(appointment) {
      this.modal.appointment.show = true;
      this.modal.appointment.model = appointment;
    },
    /**
     * END
     * Baixas
     */

    /**
     * START
     * Titulos / Parcelas
     */
    removeAppointment(appointment) {
      const remove = true;

      this.$swal({
        title: "Excluir Parcela",
        text: `Você realmente deseja excluir a parcela: ${appointment.description}?`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          // Verificação se já foi salvo no banco de dados a Parcela
          // **** TODO fazer validação para verificar se existem baixas, pois se existirem parcelas o mesmo não poderá ser baixado.
          if (appointment.id !== null && appointment.id !== undefined) {
            this.$store.commit("setLoading", {
              show: true,
              label: this.loadingLabel,
              description: this.loadingDescription,
            });
            Axios.delete(
              `${config.env.API_DEFAULT.host}/appointments/${appointment.id}`
            )
              .then((response) => {
                this.$store.commit("setLoading", {
                  show: false,
                  label: "",
                  description: "",
                });
                this.$swal(
                  "Excluir Parcela",
                  "Parcelas excluída com sucesso!",
                  "success"
                );
                this.removeAppointmentFromArray(appointment);
              })
              .catch((error) => {
                this.$store.commit("setLoading", {
                  show: false,
                  label: "",
                  description: "",
                });
                this.$swal(
                  "Excluir Parcela",
                  `Ops, houve algum erro: ${error}`,
                  "error"
                );
              });
          } else {
            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
            this.removeAppointmentFromArray(appointment);
          }
        }
      });
    },

    removeAppointmentFromArray(appointment) {
      this.index = 0;
      this.appointments.splice(appointment.index - 1, 1);
      this.appointments.map((item) => {
        this.index += 1;
        item.index = this.index;
      });
    },

    saveAppointments() {
      const totalValue = this.appointmentsTotal;

      if (this.appointments.length > 0) {
        this.$swal({
          title: "Salvar Parcelas",
          text: `Você realmente deseja salvar a(s) ${
            this.appointments.length
          } parcela(s) que foram adicionadas no valor total R$ ${parseFloat(
            totalValue
          ).toFixed(2)}?`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        }).then((willContinue) => {
          if (willContinue) {
            if (this.model.grossValue > totalValue) {
              this.$swal(
                "Salvar Parcelas",
                `O valor do Lançamento é maior do que o valor total das Parcelas. Valor do Lançamento: R$ ${parseFloat(
                  this.model.grossValue
                ).toFixed(2)}`,
                "error"
              );
              return;
            }

            if (this.model.id === null || this.model.id === undefined) {
              this.$swal(
                "Salvar Parcelas",
                "Você precisa salvar o seu Lançamento para então conseguir salvar as parcelas.",
                "error"
              );
            } else {
              this.appointments.forEach((element) => {
                let msg = "";

                // element.moviment = this.model;
                // element.account = this.model.account;
                element.registryDate = this.$moment(new Date()).format(
                  "Y-MM-DDT00:00:00"
                );
                element.dueDate = this.$moment(element.dueDate).format(
                  "Y-MM-DDT00:00:00"
                );

                if (
                  element.moviment.appointments != undefined &&
                  element.moviment.appointments !== null &&
                  element.moviment.appointments.length > 0
                ) {
                  delete element.moviment.appointments;
                }

                if (
                  element.description.length === 0 ||
                  element.description.trim() === ""
                ) {
                  msg += "- <b>Descrição deve ser preenchida</b><br />";
                }

                if (element.originalValue <= 0) {
                  msg +=
                    "- <b>Valor</b> precisar ser maior do que R$ 0,00<br />";
                }

                if (!this.$moment(element.dueDate).isValid()) {
                  msg +=
                    "- <b>Vencimento</b> precisar ser uma data valida<br />";
                }

                if (msg.length > 0) {
                  this.$swal(
                    `Salvar Parcelas${element.index}`,
                    `Verifique os erros abaixo: <br />${msg}`,
                    "error"
                  );
                  return false;
                }
              });

              Axios.post(
                `${config.env.API_DEFAULT.host}/finances/appointments/`,
                this.appointments
              )
                .then((response) => {
                  this.$swal({
                    title: "Salvar Parcelas",
                    text: "As Parcelas foram adicionada com sucesso.",
                    icon: "success",
                  });
                  EventBus.$emit(
                    "Finance.Moviments.Appointments.Form.General.loadModel"
                  );
                })
                .catch((error) => {
                  this.$swal({
                    title: "Salvar Parcelas",
                    text: `Ops, houve algum erro: ${error}`,
                    icon: "error",
                  });
                });
            }
          }
        });
      } else {
        swal(
          "Parcelas",
          "Você precisa adicionar parcelas para conseguir salvá-las.",
          "error"
        );
      }
    },
    generateAppointments() {
      if (this.model.quantityOfRepetitions > 0) {
        // var value = this.model.netValue / this.model.quantityOfRepetitions;
        const value = this.model.grossValue / this.model.quantityOfRepetitions;
        let irAliquot = 0.0;
        let pccAliquot = 0.0;

        if (this.model.irAliquot > 0)
          irAliquot = (value / 100) * this.model.irAliquot;
        if (this.model.pccAliquot > 0)
          pccAliquot = (value / 100) * this.model.pccAliquot;

        this.$swal({
          title: "Você tem certeza?",
          text: `Você tem certeza que deseja dividir o valor total em ${
            this.model.quantityOfRepetitions
          }X de R$ ${parseFloat(value).toFixed(2)}?`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        }).then((willContinue) => {
          this.loadingLabel = "Títulos / Parcelas";
          this.loadingDescription = "Processando sua requisição.";

          if (willContinue) {
            this.$store.commit("setLoading", {
              show: true,
              label: this.loadingLabel,
              description: this.loadingDescription,
            });
            this.index = 0;

            let newDate = this.model.movimentDate;
            this.totalValue = value;

            for (let i = 1; i <= this.model.quantityOfRepetitions; i++) {
              const description = `${this.model.description} ${i}/${this.model.quantityOfRepetitions}`;
              this.totalValue += value;
              newDate = moment(newDate).add(1, "days");
              this.appointments.push(
                this.createNewModelAppointment(
                  description,
                  value,
                  irAliquot,
                  pccAliquot,
                  newDate.format("YYYY-MM-DD")
                )
              );
              if (this.model.irAliquot > 0)
                this.appointments.push(
                  this.createNewModelAppointment(
                    `${description}- IR `,
                    (value / 100) * this.model.irAliquot,
                    0.0,
                    0.0,
                    newDate.format("YYYY-MM-DD")
                  )
                );
              if (this.model.pccAliquot > 0)
                this.appointments.push(
                  this.createNewModelAppointment(
                    `${description} - PCC`,
                    (value / 100) * this.model.pccAliquot,
                    0.0,
                    0.0,
                    newDate.format("YYYY-MM-DD")
                  )
                );
              newDate = moment(newDate).add(30, "days");
            }

            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          }
        });
      } else {
        swal(
          "Gerar Parcelas",
          "Você precisa digitar o número de títulos/parcelas que deseja gerar.",
          "warning"
        );
      }
    },
    generateAppointment() {
      let irAliquot = 0.0;
      let pccAliquot = 0.0;
      const value = this.model.grossValue;

      if (this.model.irAliquot > 0)
        irAliquot = (value / 100) * this.model.irAliquot;
      if (this.model.pccAliquot > 0)
        pccAliquot = (value / 100) * this.model.pccAliquot;

      let text = "Você tem certeza que deseja adicionar uma nova parcela?";
      if (
        this.model.typeOfMovimentation === "PROCESS_INCOME" ||
        this.model.typeOfMovimentation === "PROCESS_PROCESS_EXPENSEINCOME"
      ) {
        text = "Você tem certeza que deseja adicionar um novo rateio?";
      }

      this.$swal({
        title: "Você tem certeza?",
        text,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then((willContinue) => {
        this.loadingLabel = "Títulos / Parcelas";
        this.loadingDescription = "Processando sua requisição.";

        if (willContinue) {
          this.$store.commit("setLoading", {
            show: true,
            label: this.loadingLabel,
            description: this.loadingDescription,
          });
          this.index = 0;
          const appointments = new Array();
          let newDate = this.model.movimentDate;
          this.totalValue = value;

          newDate = moment(newDate).add(1, "days");

          this.appointments.push(
            this.createNewModelAppointment(
              this.model.description,
              value,
              irAliquot,
              pccAliquot,
              newDate.format("YYYY-MM-DD")
            )
          );
          this.model.quantityOfRepetitions = this.appointments.lenght;
          this.$store.commit("setLoading", {
            show: false,
            label: "",
            description: "",
          });
        }
      });
    },
    createNewModelAppointment(
      description,
      originalValue,
      irAliquot,
      pccAliquot,
      dueDate
    ) {
      this.index += 1;

      return new Appointment(
        null, // id,
        this.index, // index,
        // null, //config.env.API_DEFAULT.host+"/persons/"+this.$store.state.credentials.data.person.id, //userRegistry,
        // null, //registryDate,
        dueDate, // dueDate,
        description === null
          ? `#${this.index} ${this.modelUpdated.description}`
          : description, // description,
        this.model.typeOfMovimentation, // typeOfMovimentation,
        originalValue === null ? 0 : originalValue, // originalValue,
        irAliquot, // irAliquot,
        pccAliquot, // pccAliquot,
        0.0, // discountValue,
        0.0, // fineValue,
        0.0, // additionsValue,
        originalValue === null ? 0 : originalValue, // totalValue,
        null, // receipts,
        this.model, // moviment,
        this.model.account, // account
        this.model.process
      );
    },

    appointmentsTotalSum(item) {
      let value = item.originalValue;
      if (item.irAliquot > 0) value -= item.irAliquot;
      if (item.pccAliquot > 0) value -= item.pccAliquot;
      if (item.discountValue > 0) value -= item.discountValue;
      if (item.fineValue > 0) value += item.fineValue;
      if (item.additionsValue > 0) value += item.additionsValue;

      return value;
    },

    receiptsTotalSum(item) {
      let value = item.originalValue;

      if (item.discountValue > 0) value -= item.discountValue;
      if (item.fineValue > 0) value += item.fineValue;
      if (item.additionsValue > 0) value += item.additionsValue;

      return value;
    },
    /**
     * END
     * Titulos / Parcelas
     */
  },

  computed: {
    labelAppointments() {
      return this.model.typeOfMovimentation == "PROCESS_INCOME" ||
      this.model.typeOfMovimentation == "PROCESS_EXPENSE"
        ? "Rateio do Lançamento"
        : "Parcelas do Lançamentos";
    },
    isOperational() {
      return (
        this.model.typeOfMovimentation === "PROCESS_INCOME" ||
        this.model.typeOfMovimentation === "PROCESS_EXPENSE"
      );
    },
    // receiptsTotal() {
    //   if (this.appointments !== null && this.appointments !== undefined) {
    //     return this.appointments.reduce((total, item) => {
    //       const value = this.receiptsTotalSum(item);

    //       return total + value;
    //     }, 0);
    //   }
    //   return 0.0;
    // },
    receiptsTotal() {
      let sum = 0;
      if (this.receipts !== undefined && this.receipts !== null) {
        return this.receipts.reduce(
          (prev, item) => (sum += item.receiptsTotal),
          0
        );
      }
      return sum;
    },
    receiptsTotalRow() {
      if (this.receipts !== null && this.receipts !== undefined) {
        return this.receipts.map((item) => {
          const value = this.receiptsTotalSum(item);

          return Number(value);
        });
      }
      return 0.0;
    },
    appointmentsTotal() {
      if (this.appointments !== null && this.appointments !== undefined) {
        return this.appointments.reduce((total, item) => {
          const value = this.appointmentsTotalSum(item);

          return total + value;
        }, 0);
      }
      return 0.0;
    },
    appointmentsTotalRow() {
      if (this.appointments !== null && this.appointments !== undefined) {
        return this.appointments.map((item) => {
          const value = this.appointmentsTotalSum(item);

          return Number(value);
        });
      }
      return 0.0;
    },
    appointmentsTotalOriginalValue() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.originalValue),
          0
        );
      }
      return sum;
    },
    appointmentsTotalIrAliquot() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.irAliquot),
          0
        );
      }
      return sum;
    },
    appointmentsTotalpccAliquot() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.pccAliquot),
          0
        );
      }
      return sum;
    },
    appointmentsTotalDiscountValue() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.discountValue),
          0
        );
      }
      return sum;
    },
    appointmentsTotalFineValue() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.fineValue),
          0
        );
      }
      return sum;
    },
    appointmentsTotalAdditionsValue() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.additionsValue),
          0
        );
      }
      return sum;
    },
    appointmentsTotalTotalValue() {
      let sum = 0;
      if (this.appointments !== undefined && this.appointments !== null) {
        return this.appointments.reduce(
          (prev, item) => (sum += item.totalValue),
          0
        );
      }
      return sum;
    },

    // Receipts
    receiptsTotalPaidValue() {
      let sum = 0;
      if (this.receipts !== undefined && this.receipts !== null) {
        return this.receipts.reduce((prev, item) => (sum += item.paidValue), 0);
      }
      return sum;
    },
    receiptsTotalDiscountValue() {
      let sum = 0;
      if (this.receipts !== undefined && this.receipts !== null) {
        return this.receipts.reduce(
          (prev, item) => (sum += item.discountValue),
          0
        );
      }
      return sum;
    },
    receiptsTotalFineValue() {
      let sum = 0;
      if (this.receipts !== undefined && this.receipts !== null) {
        return this.receipts.reduce((prev, item) => (sum += item.fineValue), 0);
      }
      return sum;
    },
    receiptsTotalAdditionsValue() {
      let sum = 0;

      if (this.receipts !== undefined && this.receipts !== null) {
        return this.receipts.reduce(
          (prev, item) => (sum += item.additionsValue),
          0
        );
      }
      return sum;
    },
    calculateNetValue: {
      get() {
        const irAliquot = (this.model.irAliquot / 100) * this.model.grossValue;
        const pccAliquot =
          (this.model.pccAliquot / 100) * this.model.grossValue;

        return this.model.grossValue - irAliquot - pccAliquot;
      },
      set() {
        const irAliquot = (this.model.irAliquot / 100) * this.model.grossValue;
        const pccAliquot =
          (this.model.pccAliquot / 100) * this.model.grossValue;

        this.model.netValue = this.model.grossValue - irAliquot - pccAliquot;
      },
    },
  },
};
</script>
