<template>
    <div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-5">
                <img src="@/assets/img/website-maintenance.svg" alt="">
            </div>
            <div class="col-md-4">
                <h2 class="text-danger mb-4">ATENÇÃO</h2>
                <p>A API do Gescomex está indisponível no momento. Estamos trabalhando para que os serviços voltem ao normal o mais rápido possível. Qualquer dúvida, entre em contato com a nossa equipe.</p>
                <p class="mt-2">
                    Atenciosamente,
                    <br />
                    Equipe de Tech da Prime
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

};
</script>

<style>
</style>
