<template>
  <div>
    <div
      :class="[
        'upload-placeholder',
        dragging && 'active',
      ]"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      @drop.prevent="drop"
    >
      <div class="flex ai-c jc-c text-center p-3">
        <upload-icon :size="36" />
        <div class="pointer ml-3">
          <p class="mb-0">
            Arraste aqui para dentro os arquivos relacionados a esta atividade
          </p>
        </div>
      </div>
    </div>

    <div v-if="value.length > 0">
      <table class="documents-table">
        <thead>
          <tr>
            <th>Índice</th>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(document, index) in value" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{document.name}}</td>
            <td>
              <button
                type="button"
                @click.prevent="removeFile(index)"
                class="btn-remove"
              >X</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  UploadIcon,
} from '@/components/crm/Icons';

export default {
  name: 'VInputFileDrag',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UploadIcon,
  },
  data() {
    return {
      dragging: false,
    };
  },
  methods: {
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      const documents = this.value;
      if (this.multiple) {
        for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
          const file = e.dataTransfer.files[i];
          documents.push(file);
        }
        this.$emit('input', documents);
      } else {
        documents.splice(0, 1);
        const file = e.dataTransfer.files[0];
        documents.push(file);
        this.$emit('input', documents);
      }
      this.dragging = false;
    },
    dragleave() {
      this.dragging = false;
    },
    removeFile(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>

<style scoped>
  .upload-placeholder {
    border: 2px dashed #e2e2e2;
    border-radius: 20px;;
    background-color: white;
  }

  .upload-placeholder.active {
    background-color: rgba(16, 67, 117, 0.7);
  }

  .upload-placeholder > * {
    pointer-events: none;
  }

  .documents-table {
    width: 100%;
    margin-top: 30px;
  }

  .documents-table tbody tr:nth-of-type(even) {
    background-color: #f5f6f7;
  }

  .documents-table td {
    padding: 10px;
  }

  .btn-remove {
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e74c3c;
    color: white;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    transition: all .2s ease-in-out 0s;
  }

  .btn-remove:hover {
    background-color: #c0392b;
  }
</style>
