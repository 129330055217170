import Axios from 'axios';
import config from '@/config.js';
import downloadjs from 'downloadjs';

import Swal from 'sweetalert2';
import VuexReset from '@ianwalter/vuex-reset';

const MyIMPStore = {
  namespaced: true,
  plugins: [VuexReset()],
  state: {
    lis: [],
    session: null,
    currentLI: {},
    activeDI: {},
    activeAdicao: null,
    listOfDIs: [],
    listOfLIs: [],
    listOfURFs: [],
  },

  getters: {},

  mutations: {
    SET_LOGIN(state, payload) {
      state.session = payload;
    },
    SET_CURRENT_LI(state, payload) {
      state.currentLI = payload;
    },
    SET_ACTIVE(state, payload) {
      state.activeDI = payload;
    },
    SET_ACTIVE_ADICAO(state, payload) {
      state.activeAdicao = payload;
    },
    SET_LIST_OF_URFs(state, payload) {
      state.listOfURFs = payload;
    },
    SET_LIST_OF_DIS(state, payload) {
      state.listOfDIs = payload;
    },
    reset: () => { },
  },

  actions: {
    async login({
      dispatch,
      commit,
    }) {
      try {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', config.env.API_MYIMP.credentials.user);
        params.append('password', config.env.API_MYIMP.credentials.password);

        const response = await Axios({
          method: 'post',
          url: `${config.env.API_MYIMP.host}/oauth/token`,
          headers: {
            'Content-type': 'application/x-www-form-urlencoded;',
            Authorization: config.env.API_MYIMP.credentials.token,
          },
          data: params,
        });

        if (response.status === 200 || response.status === '200') {
          commit('SET_LOGIN', response.data);
          return true;
        }
      } catch (error) {
        Swal.fire('Login MyIMP', `Não foi possível realizar login no MyIMP para realizar operações de integração. Veja o erro que foi encontrado: ${error}`, 'error');
        return false;
      }

      return false;
    },

    async consultarAndamentoLI({
      state,
      dispatch,
      commit,
    }, identification) {
      try {
        const login = await dispatch('login');

        if (login) {
          const data = {
            nrLicencaImportacao: identification,
            nomeOperacao: `Consultar LI - ${identification}`,
          };

          const response = await Axios({
            method: 'post',
            url: `${config.env.API_MYIMP.host}/lis/consultas/consultarAndamentoLI`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
            data,
          });

          if (response.status === 200) {
            commit('SET_CURRENT_LI', response.data);

            if (response.data !== null && response.data.nrLicenciamento !== null && response.data.nrLicenciamento !== '') {
              return response.data;
            }
            Swal.fire('Consultar LI', `Nenhuma Licença de Importação foi encontrada com o número que você tentou consultar: <b>${identification}</b>.`, 'error');
            return null;
          }
          Swal.fire('Consultar LI', `Houve algum problema ao consultar a Licença de Importação informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Consultar LI', `Não foi possível acessar o MyIMP para consultar a Licença de Importação informada <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    async duplicarDI({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Duplicando DI',
          description: `Aguarde enquanto a DI  <b>${data.identification}</b> está sendo duplicada...`,
        }, {
          root: true,
        });

        const login = await dispatch('login');

        if (login) {
          const configRequest = {
            url: `${config.env.API_MYIMP.host}/typed/dis/duplicar/${data.id}`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          };

          const response = await Axios(configRequest);

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });
          if (response) {
            if (response.status === 200) {
              Swal.fire('Duplicar DI', response.data.message, 'success');
            } else {
              Swal.fire('Duplicar DI', `Não foi possível duplicar a DI <b>${data.identification}</b>.`, 'error');
            }
          }
        } else {
          Swal.fire('Duplicar DI', `Não foi possível duplicar a DI <b>${data.identification}</b>.`, 'error');
        }
      } catch (error) {
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        return false;
      }

      return false;
    },
    async gerarXMLTransmisso({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Gerando XML',
          description: `Aguarde enquanto o XML da DI ${data.identification} está sendo gerado...`,
        }, {
          root: true,
        });

        const login = await dispatch('login');

        if (login) {
          const configRequest = {
            url: `${config.env.API_MYIMP.host}/typed/dis/gerarXML/${data.id}/motivo/${data.codigoMotivoTrasmissao}`,
            method: 'POST',
            responseType: 'blob',
            headers: {
              Accept: 'application/octet-stream',
              Authorization: `Bearer ${state.session.access_token}`,
            },
          };

          const response = await Axios(configRequest);

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });
          if (response) {
            if (response.status === 200) {
              const filename = data.codigoMotivoTrasmissao === '1' ? `XML de Diagnóstico - ${data.identification}.xml` : `XML de Registro - ${data.identification}.xml`;
              downloadjs(response.data, filename);
            } else {
              Swal.fire('Gerar XML', `Não foi possível gerar o XML da DI <b>${data.identification}</b>.`, 'error');
            }
          }
        } else {
          Swal.fire('Gerar XML', `Não foi possível gerar o XML da DI <b>${data.identification}</b>.`, 'error');
        }
      } catch (error) {
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        return false;
      }

      return false;
    },
    async consultarLI({
      state,
      dispatch,
      commit,
    }, identification) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consultando LI',
          description: `Aguarde enquanto a LI ${identification} está sendo consultada...`,
        }, {
          root: true,
        });

        const login = await dispatch('login');

        if (login) {
          const data = {
            nrLicencaImportacao: identification,
            nomeOperacao: `Consultar LI - ${identification}`,
          };

          const response = await Axios({
            method: 'post',
            url: `${config.env.API_MYIMP.host}/lis/consultas/consultarLI`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
            data,
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response) {
            commit('SET_CURRENT_LI', response.data);

            if (response !== null && response.data.numeroLI !== '' && response.data.numeroLI !== null) {
              return response.data;
            } if (response !== null && response.data.mensagemLI !== null && response.data.mensagemLI !== '') {
              Swal.fire('Consultar LI', response.data.mensagemLI, 'error');
            } else {
              Swal.fire('Consultar LI', `Nenhuma Licença de Importação foi encontrada com o número que você tentou consultar: <b>${identification}</b>.`, 'error');
              return null;
            }
          } else {
            Swal.fire('Consultar LI', `Houve algum problema ao consultar a Licença de Importação informada <b>${identification}</b>.`, 'error');
          }
        } else {
          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });
          Swal.fire('Consultar LI', `Não foi possível acessar o MyIMP para consultar a Licença de Importação informada <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      return false;
    },
    async consultarRecintos({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        const { codigoURF } = data;
        const { codigoRecinto } = data;

        commit('setLoading', {
          show: true,
          label: 'Consultar URF ',
          description: `Aguarde enquanto o Recinto ${codigoRecinto} está sendo consultado...`,
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/siscomex/consultarRecintos/${codigoURF}/${codigoRecinto}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            return response.data;
          }
          Swal.fire('Consultar Recinto', `Houve algum problema ao consultar o Código da URF informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Consultar Recinto', `Não foi possível encontrar URFs cadastradas com o Código <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Login', error, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },
    async consultarCodigoURF({
      state,
      dispatch,
      commit,
    }, identification) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consultar URF ',
          description: `Aguarde enquanto od Código de URF ${identification} está sendo consultado...`,
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/siscomex/consultarCodigoURF/${identification}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_LIST_OF_URFs', response.data);
            return response.data;
          }
          Swal.fire('Consultar URF', `Houve algum problema ao consultar o Código da URF informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Consultar URF', `Não foi possível encontrar URFs cadastradas com o Código <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Login', error, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },
    async consultarDIRegistradaSiscomex({
      state,
      dispatch,
      commit,
    }, id) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Carregando DIs',
          description: 'Aguarde enquanto as DIs estão sendo carregadas...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typedDIs/${id}/diRegistradaSiscomex`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_ACTIVE', response.data);
            return response.data;
          }
          Swal.fire('Carregando DIs', `Houve algum problema ao consultar o Código da URF informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Carregando DIs', `Não foi possível encontrar URFs cadastradas com o Código <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Login MyIMP', 'Não foi possível realizar login no MyIMP para realizar operações de integração com', 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },

    async consultarCNPJ({
      state,
      dispatch,
      commit,
    }, cnpj) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consultando CNPJ',
          description: 'Aguarde enquanto o CNPJ está sendo carregado...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/serpro/raw/cnpj/consultar/${cnpj.replace(/[^\w\s]/gi, '')}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_ACTIVE', response.data);
            return response.data;
          }
          Swal.fire('Consultando CNPJ', 'Por favor, aguarde enquanto o CNPJ está sendo consultado.', 'error');
        } else {
          Swal.fire('Consultando CNPJ', 'Não foi possível consultar o CNPJ', 'error');
        }
      } catch (error) {
        Swal.fire('Login MyIMP', 'Não foi possível realizar login no MyIMP para realizar operações de integração com', 'error');
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
      }, {
        root: true,
      });

      return false;
    },

    async consultarDI({
      state,
      dispatch,
      commit,
    }, id) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Carregando DIs',
          description: 'Aguarde enquanto as DIs estão sendo carregadas...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typedDIs/${id}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_ACTIVE', response.data);
            return response.data;
          }
          Swal.fire('Carregando DIs', `Houve algum problema ao consultar o Código da URF informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Carregando DIs', `Não foi possível encontrar URFs cadastradas com o Código <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Login MyIMP', 'Não foi possível realizar login no MyIMP para realizar operações de integração com', 'error');
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
      }, {
        root: true,
      });

      return false;
    },
    async findByIdentificacaoDeclaracaoImportacao({
      state,
      dispatch,
      commit,
    }, identification) {
      const login = await dispatch('login');

      if (login) {
        const response = await Axios({
          method: 'get',
          url: `${config.env.API_MYIMP.host}/typed/dis/findByIdentificacaoDeclaracaoImportacao/${identification}`,
          headers: {
            Authorization: `Bearer ${state.session.access_token}`,
          },
        });

        return response;
      }

      return null;
    },
    async findDIByDINumber({
      state,
      dispatch,
      commit,
    }, identification) {
      const login = await dispatch('login');

      commit('setLoading', {
        show: true,
        label: 'Consultando Declaração de Importação',
        description: `Aguarde enquanto a DI ${identification} está sendo consultada no Siscomex.`,
      }, {
        root: true,
      });

      if (login) {
        const response = await Axios({
          method: 'post',
          url: `${config.env.API_MYIMP.host}/dis/siscomex/consultarDI`,
          headers: {
            Authorization: `Bearer ${state.session.access_token}`,
          },
          data: {
            cnpjImportador: '08.214.494/0001-19',
            nrDeclaracao: identification,
          },
        });

        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });

        return response;
      }

      commit('setLoading', {
        show: false,
      }, {
        root: true,
      });

      return null;
    },
    async getDIToImportProcess({
      state,
      dispatch,
      commit,
    }, identification) {
      const login = await dispatch('login');

      if (login) {
        const response = await Axios({
          method: 'get',
          url: `${config.env.API_MYIMP.host}/typed/dis/getDIToImportProcess/${identification}`,
          headers: {
            Authorization: `Bearer ${state.session.access_token}`,
          },
        });

        return response;
      }

      return null;
    },
    async consultarDIs({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Carregando DIs',
          description: 'Aguarde enquanto as DIs estão sendo carregadas...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typedDIs/search/findAllDIs`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_LIST_OF_DIS', response.data);
            return response.data;
          }
          Swal.fire('Listagem de DIs', 'Houve algum problema ao consultar as DIs.', 'error');
        } else {
          Swal.fire('Listagem de DIs', 'Houve algum problema ao consultar as DIs.', 'error');
        }
      } catch (error) {
        Swal.fire('Listagem de DIs', 'Houve algum problema ao consultar as DIs.', 'error');
        commit('setLoading', { show: false }, { root: true });
        return false;
      }

      commit('setLoading', { show: false }, { root: true });

      return false;
    },
    async consultarLIs({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Carregando DIs',
          description: 'Aguarde enquanto as DIs estão sendo carregadas...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typedDIs`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            commit('SET_LIST_OF_DIS', response.data);
            return response.data;
          }
          Swal.fire('Listagem de DIs', `Houve algum problema ao consultar o Código da URF informada <b>${identification}</b>.`, 'error');
        } else {
          Swal.fire('Listagem de DIs', `Não foi possível encontrar URFs cadastradas com o Código <b>${identification}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Listagem de DIs', `Não foi possível carregar a Listagem das DIs: ${error}`, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },
    async consultarListaSetores({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consultar Setores',
          description: 'Aguarde enquanto os setores estão sendo carregados...',
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/siscomex/consultarListaSetores/${data.codigoURF}/${data.recAduaneiro}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            return response.data;
          }
          Swal.fire('Consultar Setores', 'Houve algum problema ao carregar os setores do recinto selecionado.', 'error');
        } else {
          Swal.fire('Consultar Setores', 'Não foi possível encontrar setores cadastrados para este recinto.', 'error');
        }
      } catch (error) {
        Swal.fire('Login', error, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },
    async consultarTaxasCambio({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        const login = await dispatch('login');
        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/siscomex/consultarTaxaCambio/${data.codigoMoeda}/${data.date}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          if (response.status === 200) {
            return response.data;
          }
          Swal.fire('Consultar Taxa de Câmbio', 'Houve algum problema ao carregar a taxa de câmbio.', 'error');
        } else {
          Swal.fire('Consultar Taxa de Câmbio', 'Não foi possível encontrar a taxa de câmbio.', 'error');
        }
      } catch (error) {
        Swal.fire('Consultar Taxa de Câmbio', error, 'error');
        return null;
      }

      return false;
    },
    async consultarTaxaCambio({
      state,
      dispatch,
      commit,
    }, data) {
      if (state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) {
        try {
          commit('setLoading', {
            show: true,
            label: 'Consultar Taxa de Câmbio',
            description: 'Aguarde enquanto a taxa de câmbio está sendo carregada...',
          }, {
            root: true,
          });
          const login = await dispatch('login');

          if (login) {
            const response = await Axios({
              method: 'get',
              url: `${config.env.API_MYIMP.host}/siscomex/consultarTaxaCambio/${data.codigoMoeda}/${data.date}`,
              headers: {
                Authorization: `Bearer ${state.session.access_token}`,
              },
            });

            commit('setLoading', {
              show: false,
              label: '',
              description: '',
            }, {
              root: true,
            });

            if (response.status === 200) {
              return response.data;
            }
            Swal.fire('Consultar Taxa de Câmbio', 'Houve algum problema ao carregar a taxa de câmbio.', 'error');
          } else {
            Swal.fire('Consultar Taxa de Câmbio', 'Não foi possível encontrar a taxa de câmbio.', 'error');
          }
        } catch (error) {
          Swal.fire('Consultar Taxa de Câmbio', error, 'error');
          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          return null;
        }

        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
      }

      return false;
    },
    async gerarComplementares({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Gerar Complementares',
          description: 'Gerando complementares da DI...',
        }, {
          root: true,
        });

        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typed/dis/gerarComplementares/${data.id}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });

          return response.data;
        }
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        Swal.fire('Gerar Complementares', error, 'error');
      } catch (error) {
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
        Swal.fire('Gerar Complementares', error, 'error');
      }

      return false;
    },
    async consultarDadosMercadoria({
      state,
      dispatch,
      commit,
    }, ncm) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consulta de NCM',
          description: `Consulta da NCM ${ncm} está sendo processada.`,
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/siscomex/consultarDadosMercadoria/${ncm}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response) {
            if (response.status === 200) {
              return response.data;
            } if (response.status === 500) {
              Swal.fire('Consultar NCM', response.data.message, 'error');
            } else {
              Swal.fire('Consultar NCM', 'Houve algum problema ao carregar a NCM.', 'error');
            }
          }
        } else {
          Swal.fire('Consultar Taxa de Câmbio', 'Não foi possível encontrar a NCM.', 'error');
        }
      } catch (error) {
        Swal.fire('Consultar NCM', error, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return null;
      }

      return false;
    },
    async consultarCampoDenominacaoAction({
      state,
      dispatch,
      commit,
    }, data) {
      try {
        commit('setLoading', {
          show: true,
          label: 'Consultar Denominação',
          description: `Aguarde enquanto a Denominação ${data.query} está sendo consultada...`,
        }, {
          root: true,
        });
        const login = await dispatch('login');

        if (login) {
          let url = '';

          if (data.parametro !== undefined && data.parametro && data.tipoDI !== undefined && data.tipoDI) {
            url = `${config.env.API_MYIMP.host}/siscomex/consultarCampoDenominacaoAction/formRetorno/${data.formRetorno}/${data.campoRetorno}/${data.subAction}/${data.parametro}/${data.tipoDI}/${data.q}`;
          } else if (data.parametro !== undefined && data.parametro !== null) {
            url = `${config.env.API_MYIMP.host}/siscomex/consultarCampoDenominacaoAction/formRetorno/${data.formRetorno}/${data.campoRetorno}/${data.subAction}/${data.parametro}/_/${data.q}`;
          } else if (data.tipoDI !== undefined && data.tipoDI !== null) {
            url = `${config.env.API_MYIMP.host}/siscomex/consultarCampoDenominacaoAction/formRetorno/${data.formRetorno}/${data.campoRetorno}/${data.subAction}/_/${data.tipoDI}/${data.q}`;
          } else {
            url = `${config.env.API_MYIMP.host}/siscomex/consultarCampoDenominacaoAction/formRetorno/${data.formRetorno}/${data.campoRetorno}/${data.subAction}/_/_/${data.q}`;
          }

          const response = await Axios({
            method: 'get',
            url,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          if (response.status === 200) {
            return response.data;
          }
          Swal.fire('Consultar Denominação', `Houve algum problema ao consultar a Denominação informada <b>${data.query}</b>.`, 'error');
        } else {
          Swal.fire('Consultar Denominação', `Não foi possível encontrar a Denominação cadastradas  <b>${data.query}</b>.`, 'error');
        }
      } catch (error) {
        Swal.fire('Consultar Denominação', `Houve algum problema ao consultar a Denominação informada <b>${error}</b>.`, 'error');
        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });
        return false;
      }

      commit('setLoading', {
        show: false,
        label: '',
        description: '',
      }, {
        root: true,
      });

      return false;
    },
    async diffObjects({
      dispatch,
      commit,
    }, data) {
      const oldVal = data.initialModel;
      const newVal = data.model;
      const { fieldName } = data;
      const keysToIgnore = ['adquirente', 'importador', 'exportador', 'fabricante', 'codigoMoedaLocalEmbarque', 'codigoMoedaFrete', 'codigoMoedaSeguro', 'codigoMoedaAcrescimo'];

      const diff = Object.keys(newVal).reduce((diff, key) => {
        if (oldVal[key] === newVal[key]) {
          return diff;
        }
        if (newVal[key] !== null && newVal[key] !== undefined && newVal[key]._links && !keysToIgnore.includes(key)) {
          const urlValue = newVal[key]._links.self.href;
          return {
            ...diff,
            [key]: urlValue,
          };
        } if (newVal[key] !== null && key === 'adicoes') {
          return {
            ...diff,
            ...newVal[key],
          };
        }
        return {
          ...diff,
          [key]: newVal[key],
        };
      }, {});

      if (Object.keys(diff).length !== 0) {
        await dispatch('patch', {
          diff,
          newModel: newVal,
          fieldName,
        });
        return true;
      }
      return false;
    },
    async diffObjectsAdicao({
      dispatch,
      state,
      commit,
    }) {
      const index = state.activeDI.adicoes.findIndex((item) => item.numeroAdicao === state.activeAdicao.numeroAdicao);
      const oldVal = state.activeDI.adicoes[index];
      const newVal = state.activeAdicao;

      state.activeDI.adicoes.splice(index, 1);

      const keysToIgnore = ['adquirente'];

      const diff = Object.keys(newVal).reduce((diff, key) => {
        if (oldVal[key] === newVal[key]) {
          return diff;
        }
        if (newVal[key] !== null && newVal[key]._links && !keysToIgnore.includes(key)) {
          const urlValue = newVal[key]._links.self.href;
          return {
            ...diff,
            [key]: urlValue,
          };
        }
        return {
          ...diff,
          [key]: newVal[key],
        };
      }, {});

      state.activeDI.adicoes.push(newVal);

      if (Object.keys(diff).length !== 0) {
        await dispatch('patch', {
          diff: state.activeDI,
          newModel: state.activeDI,
          fieldName: null,
        });
        return true;
      }
      return false;
    },
    async patch({
      state,
      commit,
    }, data) {
      if (state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) {
        commit('setLoading', {
          show: true,
          label: 'Declaração de Importação',
          description: 'Aguarde enquanto a DI está sendo atualizada...',
        }, {
          root: true,
        });

        try {
          const response = await Axios({
            method: 'patch',
            url: `${config.env.API_MYIMP.host}/typedDIs/${state.activeDI.id}`,
            data: data.diff,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });

          if (response) {
            commit('SET_ACTIVE', data.newModel);
            return response;
          }
          return false;
        } catch (err) {
          commit('setLoading', {
            show: false,
          }, {
            root: true,
          });

          return false;
        }
      }
    },
    async post({
      state,
      dispatch,
      commit,
    }, data) {
      if (state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) {
        const login = await dispatch('login');

        if (login) {
          const response = await Axios({
            method: 'post',
            url: `${config.env.API_MYIMP.host}/typedDIs`,
            data,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });
          if (response) {
            commit('SET_ACTIVE', response.data);
            return response;
          }
        } else {
          return null;
        }
      }
    },
    async delete({
      state,
      dispatch,
      commit,
    }, id) {
      const login = await dispatch('login');

      if (login) {
        const response = await Axios({
          method: 'delete',
          url: `${config.env.API_MYIMP.host}/typedDIs/${id}`,
          headers: {
            Authorization: `Bearer ${state.session.access_token}`,
          },
        });
        if (response) {
          return response;
        }
      } else {
        return null;
      }
    },
    async calcularRateiosDI({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI.adicoes.length > 0) {
        commit('setLoading', {
          show: true,
          label: 'Calcular Rateios',
          description: 'Aguarde enquanto os rateios estão sendo calculados...',
        }, {
          root: true,
        });

        const login = await dispatch('login');
        if (login) {
          const response = await Axios({
            method: 'get',
            url: `${config.env.API_MYIMP.host}/typed/dis/calcularRateiosDI/${data.id}`,
            headers: {
              Authorization: `Bearer ${state.session.access_token}`,
            },
          });

          if (response) {
            commit('setLoading', {
              show: false,
              label: '',
              description: '',
            }, {
              root: true,
            });

            let mensagem = '';
            mensagem = "<ul class='list-group list-group-striped tx-14'>";
            mensagem += response.data.message;
            mensagem += '</ul>';
            mensagem = `<div class='tx-left'>${mensagem}</div>`;

            if (response.data.code === 'SUCCESS') {
              commit('SET_ACTIVE', response.data.data);
              Swal.fire('Calcular Rateios', mensagem, 'success');
            } else {
              Swal.fire('Calcular Rateios', mensagem, 'error');
            }

            return true;
          }
          return false;
        }

        commit('setLoading', {
          show: false,
          label: '',
          description: '',
        }, {
          root: true,
        });

        return false;
      }
      Swal.fire('Calcular Rateios', 'Não existem adições cadastradas na DI para calcular rateios.', 'error');
    },
    calcularValorAduaneiro({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI.adicoes.length > 0 && state.activeDI !== undefined && state.activeDI !== null) {
        if (state.activeAdicao !== undefined && state.activeAdicao !== null) {
          if (state.activeAdicao.codigoIncotermsVenda === null || state.activeAdicao.codigoIncotermsVenda === undefined) {
            Swal.fire('Calcular Valor Aduaneiro', 'Você precisa selecionar um INCOTERM para calcular o valor aduaneiro desta adição.', 'error');
            return false;
          }

          if (state.activeAdicao.codigoMetodoValoracao === null || state.activeAdicao.codigoMetodoValoracao === undefined) {
            Swal.fire('Calcular Valor Aduaneiro', 'Você precisa selecionar um método de valoração para calcular o valor aduaneiro desta adição.', 'error');
            return false;
          }

          if (state.activeAdicao.pesoLiquidoMercadoria === null || state.activeAdicao.pesoLiquidoMercadoria === undefined) {
            Swal.fire('Calcular Valor Aduaneiro', 'Você precisa preencher o peso líquido para calcular o valor aduaneiro desta adição.', 'error');
            return false;
          }
        }

        commit('setLoading', {
          show: true,
          label: 'Calcular Valor Aduaneiro',
          description: 'Aguarde enquanto o valor aduaneiro está sendo calculado...',
        }, {
          root: true,
        });

        dispatch('login').then((response) => {
          if (response) {
            return Axios({
              method: 'get',
              url: `${config.env.API_MYIMP.host}/typed/dis/calcularValorAduaneiro/${data.id}`,
              headers: {
                Authorization: `Bearer ${state.session.access_token}`,
              },
            });
          }
          commit('setLoading', {
            show: false,
            label: '',
            description: '',
          }, {
            root: true,
          });

          return false;
        }).then((response) => {
          if (response) {
            commit('setLoading', {
              show: false,
              label: '',
              description: '',
            }, {
              root: true,
            });

            let mensagem = '';
            mensagem = "<ul class='list-group list-group-striped tx-14'>";
            mensagem += response.data.message;
            mensagem += '</ul>';
            mensagem = `<div class='tx-left'>${mensagem}</div>`;

            if (response.data.code === 'SUCCESS') {
              Swal.fire('Calcular Valor Aduaneiro', mensagem, 'success');

              const novaDI = response.data.data;
              commit('SET_ACTIVE', novaDI);

              if (state.activeAdicao !== null) {
                const novasAdicoes = novaDI.adicoes;
                const index = novasAdicoes.findIndex((item) => item.numeroAdicao === state.activeAdicao.numeroAdicao);
                const novaAdicao = novasAdicoes[index];
                commit('SET_ACTIVE_ADICAO', novaAdicao);
              }
            } else {
              Swal.fire('Calcular Valor Aduaneiro', mensagem, 'error');
              return false;
            }
          } else return false;
        });
      } else {
        Swal.fire('Calcular Valor Aduaneiro', 'Não existem adições cadastradas na DI para calcular rateios.', 'error');
      }
    },
    async calcularIIAdicao({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI !== undefined && state.activeDI !== null && state.valorAduaneiro !== null && state.activeAdicao.codRegTributacaoII !== null && state.activeAdicao.codRegTributacaoII !== undefined) {
        if (state.activeAdicao.codRegTributacaoII === null || state.activeAdicao.codRegTributacaoII === undefined) {
          Swal.fire('Calcular II', 'Um regime de tributação precisar ser selecionado.', 'error');
          return false;
        } if (state.activeAdicao.valorBaseCalculoAdval === null || state.activeAdicao.valorBaseCalculoAdval === undefined) {
          Swal.fire('Calcular II', 'A base de cálculo para II precisa ser calculada.', 'error');
          return false;
        } if (state.activeAdicao.valorBaseCalculoAdval > 0 && state.activeAdicao.valorBaseCalculoAdval === 0) {
          Swal.fire('Calcular II', 'A base de cálculo para II precisar ser uma valor maior do que R$ 0,00.', 'error');
          return false;
        } if (state.activeAdicao.adValorem === null || state.activeAdicao.adValorem === undefined) {
          Swal.fire('Calcular II', 'Nenhuma alíquota ad valorem foi digitada para o II.', 'error');
          return false;
        }

        commit('setLoading', {
          show: true,
          label: 'Calcular II',
          description: 'Aguarde enquanto o Imposto de Iportação está sendo calculado...',
        }, {
          root: true,
        });

        const codRegTributacaoII = state.activeAdicao.codRegTributacaoII.key;
        if (codRegTributacaoII == '1') {
          if (state.activeAdicao.tipoAcordoTarifario !== null
            && state.activeAdicao.aliquotaAcordoII !== null
            && state.activeAdicao.aliquotaAcordoII !== undefined) {
            let valorAcordoTarifario = 0.00;
            state.activeAdicao.iiValorCalculoIptAdval = state.activeAdicao.adValorem * (state.activeAdicao.valorBaseCalculoAdval / 100);

            if (state.activeAdicao.aliquotaAcordoII > 0) {
              valorAcordoTarifario = state.activeAdicao.aliquotaAcordoII * (state.activeAdicao.valorBaseCalculoAdval / 100);
            }

            state.activeAdicao.iiValorCalculadoIIACTarifario = valorAcordoTarifario;
            state.activeAdicao.iiValorImpostoDevido = valorAcordoTarifario;
            state.activeAdicao.iiValorIPTaRecolher = valorAcordoTarifario;
            state.activeAdicao.valorBaseCalculoAdvalIPI = state.activeAdicao.valorBaseCalculoAdval + state.activeAdicao.iiValorIPTaRecolher;
          } else {
            state.activeAdicao.iiValorCalculoIptAdval = state.activeAdicao.adValorem * (state.activeAdicao.valorBaseCalculoAdval / 100);
            state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.iiValorImpostoDevido = state.activeAdicao.iiValorCalculoIptAdval;
            state.activeAdicao.iiValorIPTaRecolher = state.activeAdicao.iiValorCalculoIptAdval;
            state.activeAdicao.valorBaseCalculoAdvalIPI = state.activeAdicao.valorBaseCalculoAdval + state.activeAdicao.iiValorIPTaRecolher;
          }
        } else if (codRegTributacaoII == '2') {
          state.activeAdicao.iiValorCalculoIptAdval = 0.00;
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = 0.00;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
          state.activeAdicao.valorBaseCalculoAdvalIPI = 0.00;
        } else if (codRegTributacaoII == '3') {
          state.activeAdicao.iiValorCalculoIptAdval = state.activeAdicao.adValorem * (state.activeAdicao.valorBaseCalculoAdval / 100);
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = state.activeAdicao.iiValorCalculoIptAdval;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
          state.activeAdicao.valorBaseCalculoAdvalIPI = state.activeAdicao.valorBaseCalculoAdval + state.activeAdicao.iiValorIPTaRecolher;
        } else if (codRegTributacaoII == '4') {
          if (state.activeAdicao.reducaoIi > 0 && state.activeAdicao.reduzida === 0) {
            state.activeAdicao.iiValorCalculoIptAdval = state.activeAdicao.adValorem * (state.activeAdicao.valorBaseCalculoAdval / 100);
            state.activeAdicao.iiValorIPTaRecolher = state.activeAdicao.iiValorCalculoIptAdval - state.activeAdicao.reducaoIi * (state.activeAdicao.iiValorCalculoIptAdval / 100);
            state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.iiValorImpostoDevido = state.activeAdicao.iiValorIPTaRecolher;
          } else if (state.activeAdicao.reduzida > 0 && state.activeAdicao.reducaoIi === 0) {
            state.activeAdicao.iiValorCalculoIptAdval = state.activeAdicao.adValorem * (state.activeAdicao.valorBaseCalculoAdval / 100);
            state.activeAdicao.iiValorIPTaRecolher = state.activeAdicao.reduzida * (state.activeAdicao.valorBaseCalculoAdval / 100);

            state.activeAdicao.iiValorCalculadoIIACTarifario = state.activeAdicao.iiValorIPTaRecolher;
            state.activeAdicao.iiValorImpostoDevido = state.activeAdicao.iiValorIPTaRecolher;
          }

          state.activeAdicao.valorBaseCalculoAdvalIPI = state.activeAdicao.valorBaseCalculoAdval + state.activeAdicao.iiValorIPTaRecolher;
        } else if (codRegTributacaoII == '5') {
          state.activeAdicao.iiValorCalculoIptAdval = 0.00;
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = 0.00;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
        } else if (codRegTributacaoII == '6') {
          state.activeAdicao.iiValorCalculoIptAdval = 0.00;
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = 0.00;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
          state.activeAdicao.valorBaseCalculoAdvalIPI = 0.00;
        } else if (codRegTributacaoII == '9') {
          state.activeAdicao.iiValorCalculoIptAdval = 0.00;
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = 0.00;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
        } else {
          state.activeAdicao.iiValorCalculoIptAdval = 0.00;
          state.activeAdicao.iiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.iiValorImpostoDevido = 0.00;
          state.activeAdicao.iiValorIPTaRecolher = 0.00;
          state.activeAdicao.valorBaseCalculoAdvalIPI = 0.00;
        }

        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
      }
    },
    async calcularIPIAdicao({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI !== undefined && state.activeDI !== null && state.valorAduaneiro !== null && state.activeAdicao.tipoRegTributacao !== undefined && state.activeAdicao.tipoRegTributacao !== null) {
        if (state.activeAdicao.codRegTributacaoII === null || state.activeAdicao.codRegTributacaoII === undefined) {
          Swal.fire('Calcular IPI', 'Um regime de tributação precisar ser selecionado.', 'error');
          return false;
        }

        commit('setLoading', {
          show: true,
          label: 'Calcular IPI',
          description: 'Aguarde enquanto o IPI está sendo calculado...',
        }, {
          root: true,
        });

        const tipoRegTributacao = state.activeAdicao.tipoRegTributacao.key;

        if (tipoRegTributacao === '4' || tipoRegTributacao === '5') {
          if (state.activeAdicao.valorUnMedidaIpi > 0 && state.activeAdicao.qtdeUnIpi > 0) {
            state.activeAdicao.ipiValorCalculoIptAdval = state.activeAdicao.qtdeUnIpi * state.activeAdicao.valorUnMedidaIpi;
            state.activeAdicao.ipiValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.ipiValorImpostoDevido = state.activeAdicao.ipiValorCalculoIptAdval;
            state.activeAdicao.ipiValorIPTaRecolher = state.activeAdicao.ipiValorCalculoIptAdval;
          } else {
            state.activeAdicao.ipiValorCalculoIptAdval = state.activeAdicao.adValoremIpi * (state.activeAdicao.valorBaseCalculoAdvalIPI / 100);
            state.activeAdicao.ipiValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.ipiValorImpostoDevido = state.activeAdicao.ipiValorCalculoIptAdval;
            state.activeAdicao.ipiValorIPTaRecolher = state.activeAdicao.ipiValorCalculoIptAdval;
          }

          if (tipoRegTributacao === '5') {
            state.activeAdicao.ipiValorIPTaRecolher = 0.00;
          }
        } else if (tipoRegTributacao == '2') {
          const valorCalculoReduzida = state.activeAdicao.reduzidaIpi * (state.activeAdicao.valorBaseCalculoAdvalIPI / 100);

          state.activeAdicao.ipiValorCalculoIptAdval = state.activeAdicao.adValoremIpi * (state.activeAdicao.valorBaseCalculoAdvalIPI / 100);
          state.activeAdicao.ipiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.ipiValorImpostoDevido = valorCalculoReduzida;
          state.activeAdicao.ipiValorIPTaRecolher = valorCalculoReduzida;
        } else if (tipoRegTributacao == '1') {
          state.activeAdicao.ipiValorCalculoIptAdval = state.activeAdicao.adValoremIpi * (state.activeAdicao.valorBaseCalculoAdvalIPI / 100);
          state.activeAdicao.ipiValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.ipiValorImpostoDevido = state.activeAdicao.ipiValorCalculoIptAdval;
          state.activeAdicao.ipiValorIPTaRecolher = 0.00;
        }

        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
      }
    },
    async calcularPisAdicao({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI !== undefined && state.activeDI !== null && state.valorAduaneiro !== null && state.activeAdicao.codRegTributacaoPIS !== null && state.activeAdicao.codRegTributacaoPIS !== undefined) {
        if (state.activeAdicao.codRegTributacaoPIS === null || state.activeAdicao.codRegTributacaoPIS === undefined) {
          Swal.fire('Calcular PIS/PASEP', 'Um regime de tributação precisar ser selecionado.', 'error');
          return false;
        }

        commit('setLoading', {
          show: true,
          label: 'Calcular PIS/PASEP',
          description: 'Aguarde enquanto o PIS/PASEP está sendo calculado...',
        }, {
          root: true,
        });

        const tipoRegTributacao = state.activeAdicao.codRegTributacaoPIS.key;

        if (tipoRegTributacao === '1') {
          if (state.activeAdicao.aliquotaEspecificaPIS > 0 && state.activeAdicao.qtdeMedidaPIS > 0) {
            const valorAliquotaEspecificaPIS = state.activeAdicao.aliquotaEspecificaPIS * state.activeAdicao.qtdeMedidaPIS;
            state.activeAdicao.pisValorCalculoIptAdval = valorAliquotaEspecificaPIS;
            state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.pisValorImpostoDevido = valorAliquotaEspecificaPIS;
            state.activeAdicao.pisValorIPTaRecolher = valorAliquotaEspecificaPIS;
          } else {
            state.activeAdicao.pisValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
            state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.pisValorImpostoDevido = state.activeAdicao.pisValorCalculoIptAdval;
            state.activeAdicao.pisValorIPTaRecolher = state.activeAdicao.pisValorCalculoIptAdval;
          }
        } else if (tipoRegTributacao == '2') {
          state.activeAdicao.pisValorCalculoIptAdval = 0.00;
          state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.pisValorImpostoDevido = 0.00;
          state.activeAdicao.pisValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '3') {
          state.activeAdicao.pisValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.pisValorImpostoDevido = state.activeAdicao.pisValorCalculoIptAdval;
          state.activeAdicao.pisValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '4') {
          const valorAliquotaReduzida = state.activeAdicao.aliquotaReduzidaPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.pisValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.pisValorCalculadoIIACTarifario = valorAliquotaReduzida;
          state.activeAdicao.pisValorImpostoDevido = valorAliquotaReduzida;
          state.activeAdicao.pisValorIPTaRecolher = valorAliquotaReduzida;
        } else if (tipoRegTributacao == '5') {
          state.activeAdicao.pisValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.pisValorImpostoDevido = state.activeAdicao.pisValorCalculoIptAdval;
          state.activeAdicao.pisValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '6') {
          state.activeAdicao.pisValorCalculoIptAdval = 0.00;
          state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.pisValorImpostoDevido = 0.00;
          state.activeAdicao.pisValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '9') {
          state.activeAdicao.pisValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremPIS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.pisValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.pisValorImpostoDevido = 0.00;
          state.activeAdicao.pisValorIPTaRecolher = 0.00;
        }

        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
      }
    },
    async calcularCofinsAdicao({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI !== undefined && state.activeDI !== null && state.valorAduaneiro !== null && state.activeAdicao.codRegTributacaoPIS !== null && state.activeAdicao.codRegTributacaoPIS !== undefined) {
        if (state.activeAdicao.codRegTributacaoPIS === null || state.activeAdicao.codRegTributacaoPIS === undefined) {
          Swal.fire('Calcular COFINS', 'Um regime de tributação precisar ser selecionado.', 'error');
          return false;
        }

        commit('setLoading', {
          show: true,
          label: 'Calcular COFINS',
          description: 'Aguarde enquanto o COFINS está sendo calculado...',
        }, {
          root: true,
        });

        const tipoRegTributacao = state.activeAdicao.codRegTributacaoPIS.key;

        if (tipoRegTributacao === '1') {
          if (state.activeAdicao.aliquotaEspecificaCOFINS > 0 && state.activeAdicao.qtdeMedidaCOFINS > 0) {
            const valorAliquotaEspecificaCOFINS = state.activeAdicao.aliquotaEspecificaCOFINS * state.activeAdicao.qtdeMedidaCOFINS;
            state.activeAdicao.cofinsValorCalculoIptAdval = valorAliquotaEspecificaCOFINS;
            state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.cofinsValorImpostoDevido = valorAliquotaEspecificaCOFINS;
            state.activeAdicao.cofinsValorIPTaRecolher = valorAliquotaEspecificaCOFINS;
          } else {
            state.activeAdicao.cofinsValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
            state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
            state.activeAdicao.cofinsValorImpostoDevido = state.activeAdicao.cofinsValorCalculoIptAdval;
            state.activeAdicao.cofinsValorIPTaRecolher = state.activeAdicao.cofinsValorCalculoIptAdval;
          }
        } else if (tipoRegTributacao == '2') {
          state.activeAdicao.cofinsValorCalculoIptAdval = 0.00;
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.cofinsValorImpostoDevido = 0.00;
          state.activeAdicao.cofinsValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '3') {
          state.activeAdicao.cofinsValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.cofinsValorImpostoDevido = state.activeAdicao.cofinsValorCalculoIptAdval;
          state.activeAdicao.cofinsValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '4') {
          const valorAliquotaReduzida = state.activeAdicao.aliquotaReduzidaCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.cofinsValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = valorAliquotaReduzida;
          state.activeAdicao.cofinsValorImpostoDevido = valorAliquotaReduzida;
          state.activeAdicao.cofinsValorIPTaRecolher = valorAliquotaReduzida;
        } else if (tipoRegTributacao == '5') {
          state.activeAdicao.cofinsValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.cofinsValorImpostoDevido = state.activeAdicao.cofinsValorCalculoIptAdval;
          state.activeAdicao.pisValorIPTcofinsValorIPTaRecolheraRecolher = 0.00;
        } else if (tipoRegTributacao == '6') {
          state.activeAdicao.cofinsValorCalculoIptAdval = 0.00;
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.cofinsValorImpostoDevido = 0.00;
          state.activeAdicao.cofinsValorIPTaRecolher = 0.00;
        } else if (tipoRegTributacao == '9') {
          state.activeAdicao.cofinsValorCalculoIptAdval = state.activeAdicao.aliquotaAdValoremCOFINS * (state.activeAdicao.valorBaseCalculoAdvalPIS / 100);
          state.activeAdicao.cofinsValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.cofinsValorImpostoDevido = 0.00;
          state.activeAdicao.cofinsValorIPTaRecolher = 0.00;
        }
        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
      }
    },
    async calcularAntidumpingAdicao({
      state,
      dispatch,
      commit,
    }, data) {
      if ((state.activeDI.numeroRegistro === undefined || state.activeDI.numeroRegistro === null) && state.activeDI !== undefined && state.activeDI !== null && state.valorAduaneiro !== null) {
        commit('setLoading', {
          show: true,
          label: 'Calcular Antidumping',
          description: 'Aguarde enquanto o Antidumping está sendo calculado...',
        }, {
          root: true,
        });

        if (state.activeAdicao.codigoTipoAliquotaIPTAntidumping === '0') {
          state.activeAdicao.antidumpingValorCalculoIptAdval = 0.00;
          state.activeAdicao.antidumpingValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.antidumpingValorImpostoDevido = state.activeAdicao.antidumpingValorCalculoIptAdval;
          state.activeAdicao.antidumpingValorIPTaRecolher = state.activeAdicao.antidumpingValorCalculoIptAdval;
        } else if (state.activeAdicao.codigoTipoAliquotaIPTAntidumping === '1') {
          var adValorem = state.activeAdicao.aliquotaAdValoremAntidumping * (state.activeAdicao.valorBaseCalculoAdvalAntidumping / 100);
          state.activeAdicao.antidumpingValorCalculoIptAdval = adValorem;
          state.activeAdicao.antidumpingValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.antidumpingValorImpostoDevido = state.activeAdicao.antidumpingValorCalculoIptAdval;
          state.activeAdicao.antidumpingValorIPTaRecolher = state.activeAdicao.antidumpingValorCalculoIptAdval;
        } else if (state.activeAdicao.codigoTipoAliquotaIPTAntidumping === '2') {
          state.activeAdicao.antidumpingValorCalculoIptAdval = state.activeAdicao.aliquotaEspecificaAntidumping * state.activeAdicao.qtdeMedidaAntidumping;
          state.activeAdicao.antidumpingValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.antidumpingValorImpostoDevido = state.activeAdicao.antidumpingValorCalculoIptAdval;
          state.activeAdicao.antidumpingValorIPTaRecolher = state.activeAdicao.antidumpingValorCalculoIptAdval;
        } else if (state.activeAdicao.codigoTipoAliquotaIPTAntidumping === '3') {
          var adValorem = state.activeAdicao.aliquotaAdValoremAntidumping * (state.activeAdicao.valorBaseCalculoAdvalAntidumping / 100);
          state.activeAdicao.antidumpingValorCalculoIptAdval = adValorem + state.activeAdicao.aliquotaEspecificaAntidumping * state.activeAdicao.qtdeMedidaAntidumping;
          state.activeAdicao.antidumpingValorCalculadoIIACTarifario = 0.00;
          state.activeAdicao.antidumpingValorImpostoDevido = state.activeAdicao.antidumpingValorCalculoIptAdval;
          state.activeAdicao.antidumpingValorIPTaRecolher = state.activeAdicao.antidumpingValorCalculoIptAdval;
        }

        commit('setLoading', {
          show: false,
        }, {
          root: true,
        });
      }
    },
  },
};

export default MyIMPStore;
