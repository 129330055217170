<template>
  <div :class="[
      'report-container',
      containerClass
    ]">
    <div class="report-heading">
      <h1 class="text-center">
        <svg viewBox="0 0 380 110" width="380px" height="110px">
          <text y="100" x="0" font-family="'Pluto', sans-serif" style="font-size: 128px;">{{ reportData.year }}</text>
        </svg>
      </h1>
      <h3 class="text-center">
        <svg viewBox="0 0 400 50" width="400px" height="60px">
          <text y="40" x="0" font-family="'Pluto', sans-serif" style="font-size: 48px;">Retrospectiva</text>
        </svg>
      </h3>

      <p>
        Começamos mais um ano juntos, e nada melhor do que planejar o futuro tendo em mãos as informações valiosas
        sobre o passado. Preparamos essa retrospectiva para te ajudar a ter uma visão geral de como vem sendo o
        desempenho dos seus processos com a gente.
      </p>
    </div>

    <hr class="report-hr"/>

    <div class="report-content">
      <div class="rgrid mb-6">
        <div class="rgrid-span-2 text-center">
          <h2>{{ reportData.processosAbertos }}</h2>
          <div class="flex fd-c fgap2 ai-c">
            <div class="report-box">
              <div class="content">
                <h5>Processos Abertos</h5>
              </div>
            </div>

            <div style="width: 100%;">
              <apache-chart
                :graph-data="canaisLiberacao"
                :colors="coresCanais"
              />
            </div>

            <div class="report-box">
              <div class="content">
                <h5>Canais de Parametrização</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="rgrid-span-2 text-center">
          <h2>{{ reportData.processosRegistrados }}</h2>
          <div class="flex fd-c fgap2 ai-c">
            <div class="report-box">
              <div class="content">
                <h5>Processos Registrados</h5>
              </div>
            </div>

            <div style="width: 100%;">
              <apache-chart :graph-data="periodoArmazenagem"/>
            </div>

            <div class="report-box">
              <div class="content">
                <h5>Período de Liberação</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rgrid mb-6">
        <div class="report-box text-center">
          <div class="content sm-pd">
            <p class="mb-0"><i>Lead Time</i></p>
            <h5>Registro de DI</h5>
            <p class="big-info">{{ reportData.registroDeDI }}</p>
            <h5>dias</h5>
          </div>
        </div>
        <div class="report-box text-center">
          <div class="content sm-pd">
            <p class="mb-0"><i>Lead Time</i></p>
            <h5>Carregamento</h5>
            <p class="big-info">{{ reportData.carregamento }}</p>
            <h5>dias</h5>
          </div>
        </div>
        <div class="report-box text-center">
          <div class="content sm-pd">
            <p class="mb-0"><i>Lead Time</i></p>
            <h5>Entrega</h5>
            <p class="big-info">{{ reportData.entrega }}</p>
            <h5>dias</h5>
          </div>
        </div>
        <div class="report-box text-center">
          <div class="content sm-pd">
            <p class="mb-0"><i>Lead Time</i></p>
            <h5>Encerramento</h5>
            <p class="big-info">{{ reportData.encerramento }}</p>
            <h5>dias</h5>
          </div>
        </div>
      </div>

      <div class="rgrid report-footer">
        <div>
          <p class="footer-title">Dolar Médio do Ano</p>

          <div class="flex ai-c fgap1">
            <span class="dollar">$</span>
            <span class="dollar-value">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3, maximumFractionDigits: 4, }).format(reportData.dolarMedio)}}</span>
            <!-- <span class="dollar-value">R$ 5,00</span> -->
          </div>
        </div>
        <div class="rgrid-span-2">
          <p class="footer-title">Média de Frete Principais Origens (FCL)</p>

          <div class="flex ai-c fgap1" v-for="(item, index) in reportData.frete" :key="index">
            <span class="flag mb-2">
              <img src="@/assets/img/flags/china.jpg" alt="China" v-if="item.country === 'China'">
              <img src="@/assets/img/flags/estados-unidos.jpg" alt="Estados Unidos" v-if="item.country === 'Estados Unidos'">
              <img src="@/assets/img/flags/uniao-europeia.jpg" alt="União Europeia" v-if="item.country === 'União Europeia'">
            </span>
            <span>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3, maximumFractionDigits: 4, }).format(item.value)}}</span>
            <!-- <span v-if="item.country === 'China'">R$ 9.052,00</span>
            <span v-if="item.country === 'Estados Unidos'">R$ 8.729,00</span>
            <span v-if="item.country === 'União Europeia'">R$ 6.323,00</span> -->
          </div>
        </div>
        <div>
          <img src="@/assets/img/logo_prime.png" alt="Prime Internacional" width="150" style="margin-top: 110px; margin-left: 50px;">
        </div>
      </div>
    </div>

    <img class="footer-image" src="@/assets/img/dourado.png" alt="&nbsp;">
  </div>
</template>

<script>
import '@/assets/css/customFonts.css';
import ApacheChart from '@/components/ApacheChart';

export default {
  name: 'AnnualReport',
  components: { ApacheChart },
  props: {
    containerClass: {
      type: String,
      default: 'exportar-pdf',
    },
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    periodoArmazenagem() {
      const periodoArmazenagem = this.reportData.periodoDeArmazenagem;
      const data = [];

      for (let i = 0; i < Object.keys(periodoArmazenagem).length; i += 1) {
        const [key, val] = Object.entries(periodoArmazenagem)[i];

        if (val > 0 && key !== 'total') {
          data.push({
            name: this.returnNameFormatted(key),
            value: val,
          });
        }
      }

      return data;
    },
    freteChina() {
      return this.reportData.frete.filter((data) => data.country === 'China')[0].value;
    },
    freteEstadosUnidos() {
      return this.reportData.frete.filter((data) => data.country === 'Estados Unidos')[0].value;
    },
    freteUniaoEuropeia() {
      return this.reportData.frete.filter((data) => data.country === 'União Europeia')[0].value;
    },
    canaisLiberacao() {
      const canaisLiberacao = this.reportData.canaisDeLiberacao;
      const data = [];

      for (let i = 0; i < Object.keys(canaisLiberacao).length; i += 1) {
        const [key, val] = Object.entries(canaisLiberacao)[i];

        if (val > 0 && key !== 'total') {
          data.push({
            name: this.returnNameFormatted(key),
            value: val,
          });
        }
      }

      return data;
    },
    coresCanais() {
      const canaisLiberacao = this.reportData.canaisDeLiberacao;
      const data = [];

      for (let i = 0; i < Object.keys(canaisLiberacao).length; i += 1) {
        const [key, val] = Object.entries(canaisLiberacao)[i];

        if (val > 0 && this.isChannel(key)) {
          data.push(this.getColorChannels(key));
        }
      }

      return data;
    },
  },
  methods: {
    getColorChannels(name) {
      switch (name) {
        case 'VERDE':
          return '#8fc58b';

        case 'AMARELO':
          return '#eddc84';

        case 'VERMELHO':
          return '#ed8584';

        case 'CINZA':
          return '#a6a4a4';
      }
    },
    isChannel(name) {
      if (name === 'VERDE' || name === 'AMARELO' || name === 'VERMELHO') {
        return true;
      }

      return false;
    },
    returnNameFormatted(name) {
      switch (name) {
        case 'total':
          return 'Total';

        case 'PRIMEIRO_PERIODO':
          return '1º PERÍODO';

        case 'SEGUNDO_PERIODO':
          return '2º PERÍODO';

        case 'TERCEIRO_PERIODO':
          return '3º PERÍODO';

        case 'QUARTO_PERIODO':
          return '4º PERÍODO';

        case 'OUTROS':
          return 'Outros';

        case 'VERDE':
          return 'Verde';

        case 'AMARELO':
          return 'Amarelo';

        case 'VERMELHO':
          return 'Vermelho';

        default:
          return 'Sem nome';
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.report-container,
.report-container > * {
  font-family: 'Pluto', sans-serif;
}

.report-container {
  width: 940px;
  margin: 0 auto;
  background-color: white;
  padding-bottom: 60px;
  position: relative;
}

.report-heading {
  margin-top: 20px;;
  padding-top: 40px;
  /* text-align: center; */
}

.report-heading h1 {
  font-size: 64px;
  font-weight: bold;
  line-height: 0.5;
}

.report-heading h1 svg text {
  fill: white;
  paint-order: stroke;
  stroke-width: 8px;
  stroke-linejoin: round;
  stroke: #e2bc59;
}

.report-heading h3 {
  font-size: 25.5px;
  font-weight: 800;
  line-height: 0.5;
  margin-top: -40px;
  margin-bottom: 33px;
}

.report-heading h3 svg text {
  fill: #104277;
  paint-order: stroke;
  stroke-width: 4px;
  stroke-linejoin: round;
  stroke: white;
}

.report-heading p {
  max-width: 700px;
  font-size: 16px;
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: justify;
}

.report-hr {
  border-top: 1px solid #e2bc59;
  margin: 0;
}

.report-content {
  padding: 20px 40px;
}

.report-content h2 {
  font-size: 64px;
  font-weight: bold;
  color: #e2bc59;
}

.report-box {
  position: relative;
}

.report-box .content {
  background-color: white;
  border: 2px solid #e2bc59;
  padding: 10px 40px;
  border-radius: 20px;
  position: relative;
  z-index: 5;
}

.report-box .content.sm-pd {
  padding: 10px 20px;
}

.report-box::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #dfd8c6;
  border: 2px solid #e2bc59;
  padding: 10px 30px;
  border-radius: 20px;

  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2;
}

.report-box h5 {
  font-size: 20px;
  font-weight: bold;
  color: #ababab;
  margin-bottom: 0;
}

.report-box .big-info {
  font-size: 64px;
  font-weight: bold;
  margin: 0;
  color: #e2bc59;
}

.footer-title {
  font-weight: bold;
  color: #e2bc59;
  font-size: 18px;
}

.dollar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  background-color: #0d4a81;
  color: white;
  border-radius: 50%;
}

.dollar-value {
  font-size: 26px;
  color: #0d4a81;
  font-weight: bold;
}

.flag {
  width: 30px;
  height: 30px;
  border-radius: 60px;
  overflow: hidden;
  display: block;
  position: relative;
}

.flag img {
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.footer-image {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Grid System */

.rgrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.rgrid-span-2 {
  grid-column: span 2;
}

.rgrid-span-3 {
  grid-column: span 3;
}

.rgrid-span-4 {
  grid-column: span 4;
}
</style>
