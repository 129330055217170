var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slim-mainpanel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"slim-pageheader"},[_c('ol',{staticClass:"breadcrumb slim-breadcrumb"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.mainTitle)+" ")])]),_c('h6',{staticClass:"slim-pagetitle"},[_vm._v("Listagem de "+_vm._s(_vm.modelTitle))])]),_c('div',{staticClass:"card card-table"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"report-summary-header mg-t-0 mg-b-0 mg-l-100"},[_c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3"},[_vm._v("Listagem de "+_vm._s(_vm.modelTitle))]),_c('p',{staticClass:"mg-b-0"},[_vm._v(" Foram encontrados "+_vm._s(_vm.data.length)+" registros de "),_c('b',[_vm._v(_vm._s(_vm.modelTitle))]),_vm._v(" cadastradas ")])]),_c('div',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'bankAccounts-form',
                params: { action: 'create' },
              }}},[_c('i',{staticClass:"icon ion-plus tx-22"}),_vm._v(" ADICIONAR ")])],1)])]),_c('v-client-table',{attrs:{"columns":_vm.columns,"data":_vm.data,"options":_vm.options},scopedSlots:_vm._u([{key:"enabled",fn:function(props){return [_c('div',{staticClass:"enabled"},[(props.row.enabled)?_c('span',{staticClass:"badge badge-success"},[_vm._v("SIM")]):_vm._e(),(!props.row.enabled)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("NÃO")]):_vm._e()])]}},{key:"actions",fn:function(props){return [_c('router-link',{staticClass:"btn btn-primary btn-icon rounded-circle mg-r-5",attrs:{"to":{
              name: 'bankAccounts-form',
              params: { action: 'update', id: props.row.id },
            }}},[_c('div',[_c('i',{staticClass:"icon ion-edit"})])]),_c('a',{staticClass:"btn btn-danger btn-icon rounded-circle",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteModel(props.row)}}},[_c('div',[_c('i',{staticClass:"icon ion-trash-a"})])])]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Geral")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Cadastros de Contas Bancárias")])])
}]

export { render, staticRenderFns }