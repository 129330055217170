<template>
  <div id="freight-quote">
    <div class="created-text">
      <i class="fa fa-check-circle"></i>
      Cotação enviada em 20/10/2020 às 18:35 por Allysson Luan Pereira
    </div>

    <ul class="box-info">
      <li>
        <span class="slim-card-title tx-primary d-block">Local Embarque</span>
        <span class="tx-12 d-block" v-if="model.placeOfLoading !== ''">{{
          model.placeOfLoading
        }}</span>
        <strong class="tx-12 d-block tx-danger uppercase" v-else
          >Não informado</strong
        >
      </li>
      <li>
        <span class="slim-card-title tx-primary d-block">Incoterm</span>
        <span class="tx-12 d-block" v-if="model.incoterm !== ''">{{
          model.incoterm
        }}</span>
        <strong class="tx-12 d-block tx-danger uppercase" v-else
          >Não informado</strong
        >
      </li>
      <li>
        <span class="slim-card-title tx-primary d-block">Modal</span>
        <span class="tx-12 d-block" v-if="model.wayOfTransport !== ''">{{
          model.wayOfTransport
        }}</span>
        <strong class="tx-12 d-block tx-danger uppercase" v-else
          >Não informado</strong
        >
      </li>
      <li>
        <span class="slim-card-title tx-primary d-block">Tipo de Embarque</span>
        <span class="tx-12 d-block" v-if="model.typeOfBoarding !== ''">{{
          model.typeOfBoarding
        }}</span>
        <strong class="tx-12 d-block tx-danger uppercase" v-else
          >Não informado</strong
        >
      </li>
      <li>
        <span class="slim-card-title tx-primary d-block">Porto de Destino</span>
        <span
          class="tx-12 d-block"
          v-if="
            model.destinationPort !== undefined &&
            model.destinationPort !== null
          "
          >{{ model.destinationPort.alias }}</span
        >
        <strong class="tx-12 d-block tx-danger uppercase" v-else
          >Não informado</strong
        >
      </li>
    </ul>

    <div class="alert alert-warning" v-if="processHasApprovedQuotation">
      <i class="fa fa-info-circle"></i> Este processo já possui uma cotação
      aprovada, por isso não é possível enviar mais cotações!
    </div>
    <form v-else>
      <label class="section-title mg-t-20">Envio de E-mail</label>
      <div class="row no-gutters">
        <div class="col-lg-6">
          <div class="form-group bd-b-0-force bd-r-0-force">
            <label class="form-control-label">DOCUMENTOS ANEXO</label>
            <v-select
              v-model="attachment"
              name="jsonDocuments"
              label="description"
              :options="documentsTypes"
              v-validate="'required'"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.code }} | {{ option.description }}
              </template>
              <span slot="no-options"
                >Nenhum categoria de documento encontrado!</span
              >
            </v-select>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group bd-b-0-force">
            <label class="form-control-label">
              Destinatários - Apenas Forwarders</label
            >
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-after mg-l-10 wd-100p">
                <span
                  v-for="(contact, index) in contactsForwarders"
                  v-bind:key="contact.id"
                >
                  <strong>{{ `${contact.company} - ${contact.name}` }}</strong>
                  {{
                    `<${contact.email}> ${
                      contactsForwarders.length === index + 1 ? "" : ","
                    }`
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">Dados da Cotação de Frete</label>
            <textarea
              class="form-control is-valid mg-t-20"
              name="content"
              placeholder="Dados da Cotação de Frete"
              v-model="content"
            ></textarea>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="row buttons">
            <div class="col-lg-3 col-lg-offset-3">
              <button
                type="button"
                class="btn btn-primary btn-block"
                v-on:click="handleModalPreview(true)"
              >
                <i class="fa fa-eye mg-r-5" aria-hidden="true"></i>
                Pré-Visualizar E-mail
              </button>
            </div>

            <div class="col-lg-3">
              <button
                type="button"
                class="btn btn-danger btn-block"
                v-on:click="sendEmail()"
              >
                <i class="fa fa-paper-plane mg-r-5" aria-hidden="true"></i>
                Enviar Cotação
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <hr />

    <label class="section-title mg-t-20">Log da Cotação</label>

    <div class="row no-gutters">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="tx-center">Agente de Carga</th>
            <th class="tx-center">Situação</th>
            <th class="tx-center">Data de Envio</th>
            <th class="tx-center">Data de Visualização</th>
            <th class="tx-center">Enviado Por</th>
            <th class="tx-center">Visualizar</th>
          </tr>
        </thead>
        <tbody v-if="logs.length > 0">
          <tr v-for="(log, index) in logs" :key="log.id">
            <td class="tx-center">{{ `${log.company} - ${log.person}` }}</td>
            <td class="tx-center">{{ log.status.description }}</td>
            <td class="tx-center">
              {{ log.sendDate | moment("DD/MM/YYYY") }} às
              {{ log.sendDate | moment("H:mm") }}
            </td>
            <td class="tx-center" v-if="log.previewDate !== null">
              {{ log.previewDate | moment("DD/MM/YYYY") }} às
              {{ log.previewDate | moment("H:mm") }}
            </td>
            <td class="tx-center" v-else>Não visualizado</td>
            <td class="tx-center">{{ log.createdBy }}</td>
            <td class="tx-center">
              <button
                class="btn btn-primary btn-icon rounded-circle"
                v-on:click="selectEmailSended(index)"
              >
                <div><i class="fa fa-eye"></i></div>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">Nennhuma cotação foi enviada até o momento!</td>
          </tr>
        </tbody>
      </table>
      <freight-quote-modal-preview
        @close="handleModalPreview(false)"
        v-if="openModalPreview"
        @sendEmail="sendEmail()"
        :html="getFinishedLayoutPreview"
      />
      <freight-quote-modal-sended
        @close="handleModalSended(false)"
        v-if="openModalSended"
        :html="getFinishedLayoutSended"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Axios from "axios";
import config from "@/config.js";

import Swal from "sweetalert2";
import FreightQuoteModalPreview from "./ModalPreview";
import FreightQuoteModalSended from "./ModalSended";

export default {
  name: "freight-quote",
  props: {
    model: {
      required: true,
    },
  },
  components: {
    "freight-quote-modal-preview": FreightQuoteModalPreview,
    "freight-quote-modal-sended": FreightQuoteModalSended,
    "v-select": vSelect,
  },
  data() {
    return {
      openModalPreview: false,
      openModalSended: false,
      content: "",
      emailSended: "",
      attachment: [],
      contactsForwarders: [],
      logs: [],
      processHasApprovedQuotation: false,
      layout: `<!doctype html>
<html >

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title>Prime Internacional</title>

<style>
a {
    text-decoration: none;
    outline: none;
}

@media (max-width: 449px) {
    .o_col-full {
        max-width: 100% !important;
    }

    .o_col-half {
        max-width: 50% !important;
    }

    .o_hide-lg {
        display: inline-block !important;
        font-size: inherit !important;
        max-height: none !important;
        line-height: inherit !important;
        overflow: visible !important;
        width: auto !important;
        visibility: visible !important;
    }

    .o_hide-xs,
    .o_hide-xs.o_col_i {
        display: none !important;
        font-size: 0 !important;
        max-height: 0 !important;
        width: 0 !important;
        line-height: 0 !important;
        overflow: hidden !important;
        visibility: hidden !important;
        height: 0 !important;
    }

    .o_xs-center {
        text-align: center !important;
    }

    .o_xs-left {
        text-align: left !important;
    }

    .o_xs-right {
        text-align: left !important;
    }

    table.o_xs-left {
        margin-left: 0 !important;
        margin-right: auto !important;
        float: none !important;
    }

    table.o_xs-right {
        margin-left: auto !important;
        margin-right: 0 !important;
        float: none !important;
    }

    table.o_xs-center {
        margin-left: auto !important;
        margin-right: auto !important;
        float: none !important;
    }

    h1.o_heading {
        font-size: 32px !important;
        line-height: 41px !important;
    }

    h2.o_heading {
        font-size: 26px !important;
        line-height: 37px !important;
    }

    h3.o_heading {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .o_xs-py-md {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
    }

    .o_xs-pt-xs {
        padding-top: 8px !important;
    }

    .o_xs-pb-xs {
        padding-bottom: 8px !important;
    }
}

@media screen {
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    .o_sans,
    .o_heading {
        font-family: "Roboto", sans-serif !important;
    }

    .o_heading,
    strong,
    b {
        font-weight: 700 !important;
    }

    a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
    }
}
</style>

</head>

<body class="o_body o_bg-light" style="width: 100%;margin: 0px;padding: 0px;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;background-color: #dbe5ea;">

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_hide" align="center" style="display: none;font-size: 0;max-height: 0;width: 0;line-height: 0;overflow: hidden;mso-hide: all;visibility: hidden;">Abertura de Novo Processo na Prime Internacional - Referência: PRI$importProcess.identification - Cliente: $importProcess.customer.name
        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs o_pt-lg o_xs-pt-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;padding-top: 32px;">

            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-dark o_px o_py-md o_br-t o_sans o_text" align="center"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #1C3B70;border-radius: 4px 4px 0px 0px;padding-left: 16px;padding-right: 16px;padding-top: 24px;padding-bottom: 24px;">
                        <p style="margin-top: 0px;margin-bottom: 0px;"><a class="o_text-white" href="https://example.com/" style="text-decoration: none;outline: none;color: #ffffff;"><img
                                src="https://s3-sa-east-1.amazonaws.com/public.prime.com.vc/logo/logo-branca.png" width="136" height="36" alt="Logo Prime Internacional"
                                style="max-width: 136px;-ms-interpolation-mode: bicubic;vertical-align: middle;border: 0;line-height: 100%;height: auto;outline: none;text-decoration: none;"></a>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>

        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">

            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-white o_px-md o_py-xl o_xs-py-md o_sans o_text-md o_text-light" align="center"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 19px;line-height: 28px;background-color: #ffffff;color: #82899a;padding-left: 24px;padding-right: 24px;padding-top: 64px;padding-bottom: 20px;">

                        <h2 class="o_heading o_text-dark o_mb-xxs"
                            style="font-family: Helvetica, Arial, sans-serif;font-weight: bold;margin-top: 0px;margin-bottom: 4px;color: #242b3d;font-size: 30px;line-height: 39px;">
                            Cotação de Frete</h2>
                        <p style="margin-top: 0px;margin-bottom: 0px;">Processo PRI{{PROCESSO_NUMERO}}</p>
                    </td>
                </tr>
                </tbody>
            </table>

        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs" align="left" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
             <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-white o_px-md o_py-md o_sans o_text o_text-secondary" align="left"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;padding-left: 24px;padding-right: 24px;padding-top: 24px;">
                                           <p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 8px;">Olá,</p>
                        <p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 8px;">
                            Poderiam nos auxiliar com a cotação para embarque deste processo?
                            <br />
                            <br />
                            {{CONTEUDO}}
                            <br />
                            <br />
                            Por favor, verifique os dados abaixo:
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>

        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-white o_px-md o_pb-md o_sans o_text o_text-secondary o_left o_xs-center" align="center"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;text-align: left;padding-left: 24px;padding-right: 24px;padding-bottom: 24px;">
                        <table width="100%" role="presentation" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                            <tr>
                                <td class="o_bg-dark o_br o_px o_py" align="left"
                                    style="    background-color: #ebf5fa;
                                    color: #424651;border-radius: 4px;padding-left: 16px;padding-right: 16px;padding-top: 16px;padding-bottom: 16px;">
                                    <p class="o_sans o_text o_text-white"
                                       style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;color: #424651;"><strong>Informações</strong></p>
                                    <table width="100%" role="presentation" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                        <tr>
                                            <td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light"
                                                   style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                   LOCAL EMBARQUE</p>
                                            </td>
                                            <td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light"
                                                   style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                   {{LOCAL_EMBARQUE}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    INCOTERM</p>
                                            </td>
                                            <td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    {{INCOTERM}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    MODAL</p>
                                            </td>
                                            <td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    {{MODAL}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    TIPO DE EMBARQUE</p>
                                            </td>
                                            <td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                    {{TIPO_DE_EMBARQUE}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light"
                                                   style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                   RECINTO</p>
                                            </td>
                                            <td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
                                                <p class="o_sans o_text-xs o_text-dark_light"
                                                   style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
                                                   {{RECINTO}}</p>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>

        </td>
    </tr>
    </tbody>
</table>

        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
      <tbody>
        <tr>
          <td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
            <!--[if mso]><table width="432" cellspacing="0" cellpadding="0" border="0" role="presentation"><tbody><tr><td><![endif]-->
            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
              <tbody>
                <tr>
                  <td class="o_bg-white o_px-md o_py-xs" align="center" style="background-color: #ffffff;padding-left: 24px;padding-right: 24px;padding-top: 8px;padding-bottom: 8px;">
                    <table align="center" cellspacing="0" cellpadding="0" border="0" role="presentation">
                      <tbody>
                        <tr>
                          <td width="400" class="o_btn o_bg-success o_br o_heading o_text" align="center" style="font-family: Helvetica, Arial, sans-serif;font-weight: bold;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;mso-padding-alt: 12px 24px;background-color: #0ec06e;border-radius: 4px;">
                            <a class="o_text-white" href="http://parceiros.prime.com.vc/forwarder/quotes?uuid=$uuid" target="_blank" style="text-decoration: none;outline: none;color: #ffffff;display: block;padding: 12px 24px;mso-text-raise: 3px;">CLIQUE AQUI PARA NOS ENVIAR A COTAÇÃO</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--[if mso]></td></tr></table><![endif]-->
          </td>
        </tr>
      </tbody>
    </table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs" align="left" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">

            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-white o_px-md o_py-md o_sans o_text o_text-secondary" align="left"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;padding-left: 24px;padding-right: 24px;padding-top: 24px;padding-bottom: 24px;">
                        <p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 20px; font-size: 16px;">
                            Qualquer dúvida, nossa equipe está a disposição.
                        </p>
                        <p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 0px;">
                            Atenciosamente,
                            <br />
                            <strong>Equipe de Importação. </strong>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>

        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>
    <tr>
        <td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-white" style="font-size: 24px;line-height: 24px;height: 24px;background-color: #ffffff;">&nbsp; </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    </tbody>
</table>

<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
    <tbody>

    <tr>
        <td class="o_bg-light o_px-xs o_pb-lg o_xs-pb-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;padding-bottom: 32px;">

            <table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
                <tbody>
                <tr>
                    <td class="o_bg-dark o_px-md o_py-lg o_br-b o_sans o_text-xs o_text-dark_light" align="center"
                        style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;background-color: #242b3d;color: #a0a3ab;border-radius: 0px 0px 4px 4px;padding-left: 24px;padding-right: 24px;padding-top: 32px;padding-bottom: 32px;">
                        <p class="o_mb" style="margin-top: 0px;margin-bottom: 16px;">
                            <a class="o_text-dark_light" href="http://www.prime.com.vc" style="text-decoration: none;outline: none;color: #a0a3ab;">
                                <img src="https://s3-sa-east-1.amazonaws.com/public.prime.com.vc/logo/icone-branco.png" width="36" alt="Logo Prime Internacional" style="max-width: 36px;-ms-interpolation-mode: bicubic;vertical-align: middle;border: 0;line-height: 100%;height: auto;outline: none;text-decoration: none;">
                            </a>
                        </p>
                        <p class="o_mb" style="margin-top: 0px;margin-bottom: 16px;">
                            <strong>Prime Internacional Comércio Exterior</strong>
                            <br />
                            R. Dr. Pedro Ferreira Nº 333, Centro - Ed. Absolute Business – Sala 1805
                            <br />
                            Itajaí, Santa Catarina – 88301-030
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="o_hide-xs" style="font-size: 64px; line-height: 64px; height: 64px;">&nbsp; </div>
        </td>
    </tr>
    </tbody>
</table>
</body>

</html>`,
    };
  },
  watch: {
    model() {
      this.getForwardersContacts();
      this.getLogs();
    },
  },
  methods: {
    handleModalPreview(param) {
      this.openModalPreview = param;
    },
    handleModalSended(param) {
      this.openModalSended = param;
    },
    selectEmailSended(index) {
      this.emailSended = this.logs[index];

      this.openModalSended = true;
    },
    async getForwardersContacts() {
      const resultProviders = await Axios.get(
        `${config.env.API_DEFAULT.host}/companies/${this.model.customer.id}/providers`
      );

      const contactForwarders = [];

      if (resultProviders.status === 200) {
        const providers = resultProviders.data._embedded.companies;

        if (providers !== undefined && providers.length > 0) {
          providers.forEach(async (provider) => {
            if (
              provider.forwarder &&
              ((this.model.wayOfTransport === "MARITIMA" &&
                this.model.typeOfBoarding === "FCL" &&
                provider.isFCLAgent) ||
                (this.model.wayOfTransport === "MARITIMA" &&
                  this.model.typeOfBoarding === "LCL" &&
                  provider.isLCLAgent) ||
                (this.model.wayOfTransport === "AEREA" &&
                  provider.isAirAgent) ||
                (this.model.wayOfTransport === "RODOVIARIA" &&
                  provider.isTerrestrialAgent))
            ) {
              const resultContacts = await Axios.get(
                `${provider._links.users.href}`
              );

              if (
                resultContacts.status === 200 &&
                resultContacts.data._embedded.users.length > 0
              ) {
                resultContacts.data._embedded.users.forEach(async (contact) => {
                  contactForwarders.push({
                    companyId: provider.id,
                    company: provider.name,
                    name: contact.name,
                    email: contact.email1,
                  });
                });
              }
            }
          });
        }
      }

      this.contactsForwarders = contactForwarders;
    },
    allReplace(retStr, obj) {
      for (const x in obj) {
        retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
      }
      return retStr;
    },
    validateFields() {
      if (
        this.model.placeOfLoading === null ||
        this.model.placeOfLoading === ""
      ) {
        Swal.fire(
          "Cotação de Frete",
          "Local de Embarque não foi preenchido.",
          "error"
        );
        return false;
      }
      if (this.model.incoterm === null || this.model.incoterm === "") {
        Swal.fire("Cotação de Frete", "Incoterm não foi preenchido.", "error");
        return false;
      }
      if (
        this.model.wayOfTransport === null ||
        this.model.wayOfTransport === ""
      ) {
        Swal.fire(
          "Cotação de Frete",
          "Modal / Via de Trasporte não foi preenchido.",
          "error"
        );
        return false;
      }
      if (
        this.model.wayOfTransport === "MARITIMA" &&
        (this.model.typeOfBoarding === null || this.model.typeOfBoarding === "")
      ) {
        Swal.fire(
          "Cotação de Frete",
          "Tipo de Embarque não foi preenchido.",
          "error"
        );
        return false;
      }
      if (this.model.destinationPort === null) {
        Swal.fire(
          "Cotação de Frete",
          "Porto de Destino não foi preenchido.",
          "error"
        );
        return false;
      }

      return true;
    },
    sendEmail() {
      if (!this.validateFields()) {
        return;
      }

      let persons = "";

      this.contactsForwarders.forEach((contact, index) => {
        persons += `${contact.company} - ${contact.name}${
          this.contactsForwarders.length === index + 1 ? "" : ", "
        }`;
      });

      this.$swal({
        title: "Você tem certeza?",
        text: `Você estará enviando e-mail para: ${persons}`,
        icon: "warning",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          try {
            this.$store.commit("setLoading", {
              show: true,
              label: "Aguarde",
              description: "Estamos enviando os e-mails...",
            });

            this.openModalPreview = false;

            const response = await Axios.post(
              `${config.env.API_DEFAULT.host}/freightQuotation/sendQuotationEmail/importation/${this.model.id}`,
              {
                jsonDocuments: this.attachment,
                // content: this.content.replaceAll("\n", "<br>"),
                content: this.content,
                emailAddressList: this.contactsForwarders,
              }
            );

            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });

            if (response.status === 200) {
              this.logs = [...this.logs, ...response.data.data];
            } else {
              this.$swal(
                "Envio de E-mail",
                "Não foi possível enviar os e-mails.",
                "error"
              );
            }
          } catch (err) {
            this.$swal(
              "Envio de E-mail",
              "Não foi possível enviar os e-mails.",
              "error"
            );

            this.$store.commit("setLoading", {
              show: false,
              label: "",
              description: "",
            });
          }
        }
      });
    },

    async getLogs() {
      const resultLogs = await Axios.get(
        `${config.env.API_DEFAULT.host}/importProcesses/${this.model.id}/quotations`
      );

      if (resultLogs.status === 200) {
        this.logs = resultLogs.data._embedded.freightQuotations;
      }
    },
    async checkProcessHasApprovedQuotation() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Verificando as cotações",
        description: "Aguarde...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/freightQuotation/check-process-has-approved-quotation/${this.model.id}`
        );

        this.processHasApprovedQuotation = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
          label: "",
          description: "",
        });
      } catch (e) {}

      this.$store.commit("setLoading", {
        show: false,
        label: "",
        description: "",
      });
    },
  },
  computed: {
    ...mapState({
      documentsTypes: (state) => state.ProcessStore.documentsTypes,
    }),
    getFinishedLayoutPreview() {
      return this.allReplace(this.layout, {
        "{{PROCESSO_NUMERO}}": this.model.identification,
        "{{CONTEUDO}}": this.content.replaceAll("\n", "<br>"),
        "{{LOCAL_EMBARQUE}}":
          this.model.placeOfLoading !== undefined
            ? this.model.placeOfLoading
            : "Não informado",
        "{{INCOTERM}}":
          this.model.incoterm !== undefined
            ? this.model.incoterm
            : "Não informado",
        "{{MODAL}}":
          this.model.wayOfTransport !== undefined
            ? this.model.wayOfTransport
            : "Não informado",
        "{{TIPO_DE_EMBARQUE}}":
          this.model.typeOfBoarding !== undefined
            ? this.model.typeOfBoarding
            : "Não informado",
        "{{RECINTO}}":
          this.model.destinationPort !== undefined &&
          this.model.destinationPort !== null
            ? this.model.destinationPort.alias
            : "Não informado.",
      });
    },
    getFinishedLayoutSended() {
      return this.allReplace(this.layout, {
        "{{PROCESSO_NUMERO}}": this.model.identification,
        "{{CONTEUDO}}": this.emailSended.content,
        "{{LOCAL_EMBARQUE}}":
          this.model.placeOfLoading !== undefined
            ? this.model.placeOfLoading
            : "Não informado",
        "{{INCOTERM}}":
          this.model.incoterm !== undefined
            ? this.model.incoterm
            : "Não informado",
        "{{MODAL}}":
          this.model.wayOfTransport !== undefined
            ? this.model.wayOfTransport
            : "Não informado",
        "{{TIPO_DE_EMBARQUE}}":
          this.model.typeOfBoarding !== undefined
            ? this.model.typeOfBoarding
            : "Não informado",
        "{{RECINTO}}":
          this.model.destinationPort !== undefined &&
          this.model.destinationPort !== null
            ? this.model.destinationPort.alias
            : "Não informado.",
      });
    },
  },

  async mounted() {
    this.checkProcessHasApprovedQuotation();
    this.getForwardersContacts();

    this.getLogs();
    await this.$store.dispatch("ProcessStore/getDocuments", this.model.id);
  },
};
</script>

<style scoped>
@import "./styles.css";
</style>
