<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.85 11.5C12.8165 11.5 13.6 10.7165 13.6 9.75C13.6 8.7835 12.8165 8 11.85 8C10.8835 8 10.1 8.7835 10.1 9.75C10.1 10.7165 10.8835 11.5 11.85 11.5Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M4.84998 11.5C5.81647 11.5 6.59998 10.7165 6.59998 9.75C6.59998 8.7835 5.81647 8 4.84998 8C3.88348 8 3.09998 8.7835 3.09998 9.75C3.09998 10.7165 3.88348 11.5 4.84998 11.5Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M8.34998 4.5C9.31647 4.5 10.1 3.7165 10.1 2.75C10.1 1.7835 9.31647 1 8.34998 1C7.38348 1 6.59998 1.7835 6.59998 2.75C6.59998 3.7165 7.38348 4.5 8.34998 4.5Z"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 15C15 13.067 13.433 11.5 11.5 11.5C9.56701 11.5 8 13.067 8 15" stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M8 15C8 13.067 6.43299 11.5 4.5 11.5C2.56701 11.5 1 13.067 1 15" stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M11.5 8C11.5 6.06701 9.93299 4.5 8 4.5C6.06701 4.5 4.5 6.06701 4.5 8" stroke="currentColor"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5 15L12.2 14.0278L11.5 11.5L10.8 14.0278L11.5 15Z" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M4.49999 15L5.19999 14.0278L4.49999 11.5L3.79999 14.0278L4.49999 15Z" stroke="currentColor"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.99999 8L8.69999 7.02778L7.99999 4.5L7.29999 7.02778L7.99999 8Z" stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ListCustomer',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
