export const processColumns = [
  {
    field: 'identification',
    label: 'Nº de Referência',
    enabled: true,
    type: 'identification',
  },
  {
    field: 'account.federalUnity',
    label: 'UF',
    enabled: true,
    type: 'default',
  },
  {
    field: 'typeOfProcess',
    label: 'Tipo de Processo',
    enabled: false,
    type: 'default',
  },
  {
    field: 'step.alias',
    label: 'Etapa',
    enabled: true,
    type: 'default',
  },
  {
    field: 'status',
    label: 'Status',
    enabled: false,
    type: 'default',
  },
  {
    field: 'customer.completeName',
    label: 'Cliente',
    enabled: true,
    type: 'default',
  },
  {
    field: 'customerRef',
    label: 'Referência do Cliente',
    enabled: true,
    type: 'default',
  },
  {
    field: 'exporter.completeName',
    label: 'Exportador',
    enabled: true,
    type: 'default',
  },
  {
    field: 'manufacturer.completeName',
    label: 'Fabricante',
    enabled: false,
    type: 'default',
  },
  {
    field: 'customBroker.completeName',
    label: 'Despachante Aduaneiro',
    enabled: false,
    type: 'default',
  },
  {
    field: 'account.completeName',
    label: 'Empresa / Filial',
    enabled: false,
    type: 'default',
  },
  {
    field: 'invoice',
    label: 'Invoice',
    enabled: true,
    type: 'default',
  },
  {
    field: 'invoiceDate',
    label: 'Data da Invoice',
    enabled: false,
    type: 'date',
  },
  {
    field: 'cifValue',
    label: 'Valor CIF',
    enabled: false,
    type: 'money',
  },
  {
    field: 'cifValueCurrency',
    label: 'Moeda do CIF',
    enabled: false,
    type: 'default',
  },
  {
    field: 'datesGoodsReadinesDate',
    label: 'Prontidão',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesDepartureDate',
    label: 'Embarque',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesArrivalDate',
    label: 'Chegada',
    enabled: false,
    type: 'date',
  },
  {
    field: 'mooringDate',
    label: 'Atracação',
    enabled: false,
    type: 'date',
  },
  {
    field: 'importLicense',
    label: 'Licenças',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'wayOfTransport',
    label: 'Via de Transporte',
    enabled: false,
    type: 'default',
  },
  {
    field: 'typeOfBoarding',
    label: 'Tipo de Embarque',
    enabled: false,
    type: 'default',
  },
  {
    field: 'billOfLading',
    label: 'BL/AWB/CRT',
    enabled: false,
    type: 'default',
  },
  {
    field: 'vehicle',
    label: 'Veículo / Navio',
    enabled: false,
    type: 'default',
  },
  {
    field: 'vehicleTranshipment',
    label: 'Navio de Embarque',
    enabled: false,
    type: 'default',
  },
  {
    field: 'placeOfLoading',
    label: 'Local de Embarque',
    enabled: false,
    type: 'default',
  },
  {
    field: 'incoterm',
    label: 'Incoterms',
    enabled: true,
    type: 'default',
  },
  {
    field: 'freightForwarder.completeName',
    label: 'Forwarder',
    enabled: false,
    type: 'default',
  },
  {
    field: 'customsEnclosure.alias',
    label: 'Recinto Alfandegado',
    enabled: false,
    type: 'default',
  },
  {
    field: 'destinationPort.alias',
    label: 'Porto de Destino',
    enabled: false,
    type: 'default',
  },
  {
    field: 'conveyor.completeName',
    label: 'Transportadora',
    enabled: false,
    type: 'default',
  },
  {
    field: 'generalWarehouse.completeName',
    label: 'Armazém Geral',
    enabled: false,
    type: 'default',
  },
  {
    field: 'ceMercante',
    label: 'CE-Mercante',
    enabled: false,
    type: 'default',
  },
  {
    field: 'stepSpreadsheet',
    label: 'Planilha',
    enabled: false,
    type: 'date',
  },
  {
    field: 'stepCashRequest',
    label: 'Numerário',
    enabled: false,
    type: 'date',
  },
  {
    field: 'stepCashRequestSolved',
    label: 'Numerário Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'stepCashRequestEstimatedValue',
    label: 'Valor do Numerário',
    enabled: false,
    type: 'money',
  },
  {
    field: 'estimatedStepCashRequest',
    label: 'Numerário Previsto',
    enabled: false,
    type: 'date',
  },
  {
    field: 'estimatedStepCashRequestEstimatedValue',
    label: 'Valor do Numerário Previsto',
    enabled: false,
    type: 'money',
  },
  {
    field: 'originalsDocumentsDate',
    label: 'Documentos',
    enabled: false,
    type: 'date',
  },
  {
    field: 'originalsDocumentsDateSolved',
    label: 'Documentos Resolvidos',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'originalsBillOfLadingDate',
    label: 'Conhecimento',
    enabled: false,
    type: 'date',
  },
  {
    field: 'originalsBillOfLadingDateSolved',
    label: 'Conhecimento Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'originalsInvoiceDate',
    label: 'Fatura',
    enabled: false,
    type: 'date',
  },
  {
    field: 'originalsInvoiceDateSolved',
    label: 'Fatura Resolvida',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'originalsPackingListDate',
    label: 'Packing List',
    enabled: false,
    type: 'date',
  },
  {
    field: 'originalsPackingListDateSolved',
    label: 'Packing List Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'conferenceDocumentsSolved',
    label: 'Documentos Resolvidos',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'conferencePackingListSolved',
    label: 'Packins List Resolvido',
    enabled: false,
    type: 'default',
  },
  {
    field: 'conferenceBillOfLadingSolved',
    label: 'Conhecimento Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'conferenceInvoiceSolved',
    label: 'Fatura / Invoice Resolvida',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'diNumber',
    label: 'Número da DI',
    enabled: true,
    type: 'default',
  },
  {
    field: 'diIdentification',
    label: 'Identificação',
    enabled: false,
    type: 'default',
  },
  {
    field: 'diProtocol',
    label: 'Número do Protocolo de Registro',
    enabled: false,
    type: 'default',
  },
  {
    field: 'diRegistryDate',
    label: 'Registro',
    enabled: true,
    type: 'date',
  },
  {
    field: 'diTypingDate',
    label: 'Data de Digitação',
    enabled: false,
    type: 'date',
  },
  {
    field: 'diResourcefulnessDate',
    label: 'Data de Desembaraço',
    enabled: false,
    type: 'date',
  },
  {
    field: 'diChannel',
    label: 'Canal',
    enabled: false,
    type: 'default',
  },
  {
    field: 'billingNFsDate',
    label: 'Notas Fiscais',
    enabled: false,
    type: 'date',
  },
  {
    field: 'billingNFsDateSolved',
    label: 'Notas Fiscais Resolvidas',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'billingNFsEmissionDate',
    label: 'NF (Emissão)',
    enabled: false,
    type: 'date',
  },
  {
    field: 'billingNFsEmissionDateSolved',
    label: 'NF (Emissão Resolvida)',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'billingBillCustomerDate',
    label: 'Faturamento do Cliente',
    enabled: false,
    type: 'date',
  },
  {
    field: 'billingBillCustomerDateSolved',
    label: 'Faturamento do Cliente Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'datesEstimatedGoodsReadinesDate',
    label: 'Prontidão Confirmada',
    enabled: false,
    type: 'date',
  },
  {
    field: 'initialDatesETD',
    label: 'ETD Inicial Previsto',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesETD',
    label: 'ETD',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesETA',
    label: 'ETA',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesEstimatedCustomerDeliverDate',
    label: 'Entrega Cliente Prevista',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesGoodsPresenceDate',
    label: 'Presença',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesInspectionOfTheDIDate',
    label: 'Vistoria da DI',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesGoodsLoadingDate',
    label: 'Carregamento',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesCustomerDeliverDate',
    label: 'Entrega Cliente',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesInspectionMAPADate',
    label: 'Vistoria Mapa',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesInspectionMAPADateSolved',
    label: 'Vistoria Mapa Resolvida',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesReleaseMAPADate',
    label: 'Liberação Mapa',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesReleaseMAPADateSolved',
    label: 'Liberação Mapa Resolvida',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'datesLinkInTheEnclosureDate',
    label: 'Vínculo no Recinto',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesLinkInTheEnclosureDateSolved',
    label: 'Vínculo no Recinto Resolvido',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'datesSiscargaState',
    label: 'SISCARGA',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesSiscargaDate',
    label: 'Data SISCARGA',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesBillOfLadingReleaseDate',
    label: 'Liberação AWB/BL',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesTFASolved',
    label: 'Termo de Falta de Avaria',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'datesIsThereDTCDTATransfer',
    label: 'DTC/ DTA',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesDTCDTA',
    label: 'Data DTC/ DTA',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesThereIsDesova',
    label: 'Desova',
    enabled: false,
    type: 'boolean',
  },
  {
    field: 'datesDesova',
    label: 'Data Desova',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesEnclosureBillingDate',
    label: 'FAT Recinto',
    enabled: false,
    type: 'date',
  },
  {
    field: 'datesComexDate',
    label: 'Comex',
    enabled: false,
    type: 'date',
  },
  {
    field: 'fobValue',
    label: 'Valor FOB',
    enabled: false,
    type: 'money',
  },
  {
    field: 'freightTotalValue',
    label: 'Valor Total de Frete',
    enabled: false,
    type: 'money',
  },
  {
    field: 'taxesTotalValue',
    label: 'Valor Total de Impostos',
    enabled: false,
    type: 'money',
  },
  {
    field: 'expensesPercentage',
    label: 'Despesas',
    enabled: false,
    type: 'money',
  },
  {
    field: 'profitsPercentage',
    label: 'Lucros',
    enabled: false,
    type: 'money',
  },
  {
    field: 'productDescription',
    label: 'Descrição da Mercadoria',
    enabled: false,
    type: 'default',
  },
  {
    field: 'totalAmountInsurance',
    label: 'Importância Total do Seguro',
    enabled: false,
    type: 'default',
  },
  {
    field: 'insuranceAward',
    label: 'Prêmio',
    enabled: false,
    type: 'money',
  },
  {
    field: 'freetime',
    label: 'Freetime',
    enabled: false,
    type: 'default',
  },
];

export const processExcelColumns = () => {
  const excelColumns = {};

  for (let i = 0; i < processColumns.length; i++) {
    const column = processColumns[i];

    if (column.enabled === true) {
      Object.assign(excelColumns, {
        [column.label]: column.field,
      });
    }
  }

  return excelColumns;
};
