<template>
  <form @submit.prevent="onSubmit()" data-parsley-validate>

    <div class="form-layout form-layout-2">
      <label class="section-title mg-t-0">Dados Gerais da Empresa</label>

      <div class="row no-gutters">

        <div class="col-md-12">

          <div class="form-group">
            <label class="form-control-label">
              Tipo de Relacionamento
            </label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-success">
                <input type="checkbox" id="isCustomer" name="customer" v-model="company.customer"><span>Cliente</span>
              </label>

              <label class="ckbox ckbox-primary">
                <input type="checkbox" id="isProvider" name="provider"
                       v-model="company.provider"><span>Fornecedor</span>
              </label>

              <label class="ckbox ckbox-orange">
                <input type="checkbox" id="isExporter" name="exporter" v-model="company.exporter"><span>Exportador/Fabricante</span>
              </label>

              <label class="ckbox ckbox-danger">
                <input type="checkbox" id="isCustomBroker" name="customBroker" v-model="company.customBroker"><span>Despachante</span>
              </label>

              <label class="ckbox ckbox-info">
                <input type="checkbox" id="isCustomsEnclosure" name="customsEnclosure"
                       v-model="company.customsEnclosure"><span>Recinto</span>
              </label>

              <label class="ckbox ckbox-teal">
                <input type="checkbox" id="isConveyor" name="conveyor"
                       v-model="company.conveyor"><span>Transportador</span>
              </label>

              <label class="ckbox ckbox-indigo">
                <input type="checkbox" id="isForwarder" name="forwarder"
                       v-model="company.forwarder"><span>Forwarder</span>
              </label>

              <label class="ckbox ckbox-pink">
                <input type="checkbox" id="shipowner" name="shipowner" v-model="company.shipowner"><span>Armador</span>
              </label>
              <label class="ckbox ckbox-secondary">
                <input type="checkbox" id="commissioned" name="commissioned" v-model="company.isCommissioned"><span>Comissionado</span>
              </label>
            </div>
          </div>
        </div>

      </div>

      <div class="row no-gutters" v-if="company.forwarder">
        <div class="col-md-12">

          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Quais tipos de frente o agente fornece?</label>

            <div class="row pd-l-15">
              <label class="ckbox ckbox-success">
                <input type="checkbox" id="isFCLAgent" name="isFCLAgent" v-model="company.isFCLAgent"><span>FCL</span>
              </label>
              <label class="ckbox ckbox-orange">
                <input type="checkbox" id="isLCLAgent" name="isLCLAgent" v-model="company.isLCLAgent"><span>LCL</span>
              </label>
              <label class="ckbox ckbox-indigo">
                <input type="checkbox" id="isAirAgent" name="isAirAgent" v-model="company.isAirAgent"><span>Aéreo</span>
              </label>
              <label class="ckbox ckbox-teal">
                <input type="checkbox" id="isTerrestrialAgent" name="isTerrestrialAgent"
                       v-model="company.isTerrestrialAgent"><span>Rodoviário</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">CNPJ </label>
            <the-mask mask="##.###.###/####-##" name="federalID" placeholder="CNPJ" v-model="company.federalID"
                      class="form-control" @blur.native="findCNPJ()"/>
            <span v-show="errors.has('federalID')" class="parsley-errors-list filled">Digite o CNPJ</span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Razão Social </label>
            <input class="form-control" type="text" name="completeName" placeholder="Razão Social"
                   v-model="company.completeName" :class="{'parsley-error': errors.has('completeName')}"
                   v-validate="'required'">
            <span v-show="errors.has('completeName')" class="parsley-errors-list filled">Digite a Razão Social</span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Nome Fantasia</label>
            <input class="form-control" type="text" name="name" placeholder="Nome Fantasia" v-model="company.name"
                   v-validate="'required'">
            <span v-show="errors.has('name')" class="parsley-errors-list filled">Digite o Nome Fantasia</span>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Data de Fundação </label>
            <date-picker lang="pt-br" v-model="company.openingDate" class="form-control"
                         format="DD/MM/YYYY"></date-picker>
            <span v-show="errors.has('openingDate')" class="parsley-errors-list filled">Digite a Data de Fundação</span>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">
              Habilitado
            </label>

            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input name="enabled" type="radio" v-model="company.enabled" :value="true" v-validate="'required'">
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input name="enabled" type="radio" v-model="company.enabled" :value="false" v-validate="'required'">
                  <span>Não</span>
                </label>
              </div>
              <span v-show="errors.has('enabled')" class="parsley-errors-list filled">Selecione uma opção</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Estadual?</label>
            <div class="row">
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-success mg-t-0-force">
                  <input name="stateRegistrationExempt" type="radio" v-model="company.stateRegistrationExempt"
                         :value="true">
                  <span>Sim</span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="rdiobox rdiobox-danger mg-t-0-force">
                  <input name="stateRegistrationExempt" type="radio" v-model="company.stateRegistrationExempt"
                         :value="false">
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Estadual</label>
            <input class="form-control" type="text" name="stateRegistration" placeholder="Inscrição Estadual"
                   v-model="company.stateRegistration"/>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Inscrição Municipal</label>
            <input class="form-control" type="text" name="municipalRegistration" placeholder="Inscrição Municipal"
                   v-model="company.municipalRegistration"/>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Regime Fiscal</label>
            <div class="row pd-l-15">
              <label class="rdiobox rdiobox-warning mg-t-0-force pd-r-15">
                <input id="taxRegime-LUCRO_PRESUMIDO" name="taxRegime" type="radio" v-model="company.taxRegime"
                       value="LUCRO_PRESUMIDO"/>
                <span> Lucro Presumido </span>
              </label>
              <label class="rdiobox rdiobox-primary mg-t-0-force pd-r-15">
                <input id="taxRegime-LUCRO_REAL" name="taxRegime" type="radio" v-model="company.taxRegime"
                       value="LUCRO_REAL"/>
                <span> Lucro Real </span>
              </label>
              <label class="rdiobox rdiobox-success mg-t-0-force">
                <input id="taxRegime-SIMPLES" name="taxRegime" type="radio" v-model="company.taxRegime"
                       value="SIMPLES"/>
                <span> Simples </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" v-if="company.exporter">
        <div class="col-md-2">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label"> TIN (Trader Identification Number) </label>
            <input class="form-control" type="text" name="traderIdentificationNumber" placeholder="TIN"
                   v-model="company.traderIdentificationNumber"
                   :class="{'parsley-error': errors.has('traderIdentificationNumber')}" v-validate="'required'"
                   maxlength="35">
            <span v-show="errors.has('traderIdentificationNumber')"
                  class="parsley-errors-list filled">Digite o TIN</span>
          </div>
        </div>
      </div>

      <label class="section-title mg-t-20">Informações de Endereço / Contato</label>

      <div class="row no-gutters">
        <div class="col-md-3 col-sm-6">
          <div class="form-group">
            <label class="form-control-label">Telefone Principal </label>
            <the-mask :mask="['(##) ####-####', '(##) #####-####']" placeholder="Telefone Principal"
                      v-model="company.telephone1" name="telephone1" class="form-control" v-if="!isExporterCompany"/>
            <input class="form-control" type="text" name="telephone1" placeholder="Email" v-model="company.telephone1"
                   v-else>
            <span v-show="errors.has('telephone1')"
                  class="parsley-errors-list filled">Digite o Telefone Principal</span>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Telefone Secundário</label>
            <the-mask :mask="['(##) ####-####', '(##) #####-####']" placeholder="Telefone Secundário"
                      v-model="company.telephone2" class="form-control"/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Email <small>Quando tiver mais do que um, separar por
              vírgula</small></label>
            <input class="form-control" type="text" name="email" placeholder="Email" v-model="company.email">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Website</label>
            <input class="form-control" type="text" name="website" placeholder="Website" v-model="company.website">
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">CEP </label>
            <the-mask mask="#####-###" placeholder="CEP" v-model="company.postalCode" name="postalCode"
                      class="form-control" @blur.native="findCEP" v-if="!isExporterCompany"/>
            <input class="form-control" type="text" name="postalCode" placeholder="CEP" v-model="company.postalCode"
                   :class="{'parsley-error': errors.has('postalCode')}" v-validate="'required'" v-else/>
            <span v-show="errors.has('postalCode')" class="parsley-errors-list filled">Digite o CEP</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Logradouro </label>
            <input class="form-control" type="text" name="address" placeholder="Logradouro" v-model="company.address"
                   :class="{'parsley-error': errors.has('address')}" v-validate="'required'"/>
            <span v-show="errors.has('address')" class="parsley-errors-list filled">Digite o Logradouro</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Número </label>
            <input class="form-control" type="text" name="addressNumber" placeholder="Número"
                   v-model="company.addressNumber" :class="{'parsley-error': errors.has('address')}"
                   v-validate="'required'"/>
            <span v-show="errors.has('addressNumber')" class="parsley-errors-list filled">Digite o Número</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Complemento</label>
            <input class="form-control" type="text" name="complement" placeholder="Complemento"
                   v-model="company.complement">
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Bairro </label>
            <input class="form-control" type="text" name="district" placeholder="Bairro" v-model="company.district"
                   :class="{'parsley-error': errors.has('district')}" v-validate="'required'"/>
            <span v-show="errors.has('district')" class="parsley-errors-list filled">Digite o Bairro</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Cidade </label>
            <input class="form-control" type="text" name="city" placeholder="Cidade" v-model="company.city"
                   :class="{'parsley-error': errors.has('city')}" v-validate="'required'"/>
            <span v-show="errors.has('city')" class="parsley-errors-list filled">Digite a Cidade</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">Estado </label>
            <input class="form-control" type="text" name="federalUnity" placeholder="Estado"
                   v-model="company.federalUnity" :class="{'parsley-error': errors.has('federalUnity')}"
                   v-validate="'required'"/>
            <span v-show="errors.has('federalUnity')" class="parsley-errors-list filled">Digite o Estado</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mg-md-l--1 bd-t-0-force">
            <label class="form-control-label">País </label>
            <v-select v-model="company.country" label="value" :options="codigosPaises" name="country">
              <template slot="option" slot-scope="option">
                {{ option.value }}
              </template>
            </v-select>
            <span v-show="errors.has('country')" class="parsley-errors-list filled">País é um campo obrigatório.</span>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label">Observações</label>
            <textarea class="form-control is-valid mg-t-20" name="observation" placeholder="Observações"
                      v-model="company.observation"></textarea>
          </div>
        </div>
      </div>
    </div>

    <label class="section-title mg-t-20">Informações de Integração</label>

      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="form-group mg-md-l--1">
            <label class="form-control-label">Código de Integração <small>(Código Rudolph dos Exportadores)</small></label>
            <input class="form-control" type="text" name="code" placeholder="Código" v-model="company.code">
          </div>
        </div>
      </div>

    <div class="row mg-t-20-force">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button class="btn btn-primary btn-block mg-r-10-force" type="submit">
          <i class="fa fa-floppy-o mg-r-5"></i>
          SALVAR
        </button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-secondary btn-block mg-l-10-force" type="button" @click.prevent="redirect"><i
          class="fa fa-reply mg-r-5"></i> VOLTAR
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </form>
</template>
<script>
import {TheMask} from 'vue-the-mask';
import DatePicker from 'vue2-datepicker';
import {EventBus} from '@/events/EventBus.js';
import Swal from 'sweetalert2';

import * as importDeclarationLists from '@/lists/import-declaration-lists.js';

export default {
  name: 'general-companies-form-general',
  props: ['company', 'isNewRecord'],
  components: {TheMask, DatePicker},
  data() {
    return {
      codigosPaises: [...importDeclarationLists.codigosPaises],
    };
  },
  methods: {
    redirect() {
      this.$router.push({name: 'companies-list', params: {type: this.$route.params.type}});
    },
    onSubmit() {
      EventBus.$emit('companies.Form.onSubmit');
    },

    findCNPJ() {

    },
    async findCEP() {
      if (this.company.postalCode) {
        const response = await this.$viaCep.buscarCep(this.company.postalCode);
        if (response) {
          if (response.erro) {
            Swal.fire('Oops!', 'Não foi possivél encontrar este endereço, verifique se o CEP é válido');
          } else {
            this.$set(this.company, 'address', response.logradouro);
            this.$set(this.company, 'district', response.bairro);
            this.$set(this.company, 'city', response.localidade);
            this.$set(this.company, 'complement', response.complemento);
            this.$set(this.company, 'federalUnity', response.uf);
          }
        }
      }
    },
  },
  computed: {
    isExporterCompany() {
      return this.company.exporter;
    },
  },
  mounted() {

  },
};
</script>
