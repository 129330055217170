<template>
  <svg :width="size" :height="size" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.66667 14.5833V10H7.91663V11.6667H14.5833V12.9166H7.91663V14.5833H6.66667ZM0 12.9166V11.6667H4.58329V12.9166H0ZM3.33333 9.58329V7.91663H0V6.66667H3.33333V5H4.58329V9.58329H3.33333ZM6.66667 7.91663V6.66667H14.5833V7.91663H6.66667ZM10 4.58329V0H11.25V1.66667H14.5833V2.91663H11.25V4.58329H10ZM0 2.91663V1.66667H7.91663V2.91663H0Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SettingsIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
