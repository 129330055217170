<template>
  <div class="container">
    <div class="dash-headline-two mt-4">
      <div class="d-flex align-items-center">
        <h2 class="tx-inverse mg-b-0">{{ this.$route.meta.pageTitle }}</h2>
        <p class="mg-b-0 mg-l-20">
          Operacional / Documentos Originais / <strong>{{ this.$route.meta.pageTitle }}</strong>
        </p>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'PoWrapper',
  methods: {
  },
};
</script>

<style scoped>
</style>
