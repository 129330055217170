<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
          <li class="breadcrumb-item"><a href="#">Configurações</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i>
                Geral
              </a>
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <appointmentType-form-general
                :appointmentType="appointmentType"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import AppointmentType from "@/models/AppointmentType.js";
import AppointmentTypesFormGeneral from "@/views/billing/appointmentTypes/Form.General.vue";

export default {
  name: "appointmentTypesForm",
  components: {
    "appointmentType-form-general": AppointmentTypesFormGeneral,
  },
  data() {
    return {
      mainTitle: "Serviços de Fatura ",
      appointmentType: new AppointmentType(),
      oldModel: new AppointmentType(),
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.action === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    onSubmit() {
      const self = this;
      this.$validator.validate().then((result) => {
        if (result) {
        }
      });
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/appointmentTypes/${this.$route.params.id}`
      ).then((response) => {
        this.appointmentType = response.data;

        Axios.get(this.appointmentType._links.chartOfAccount.href)
          .then((response) => {
            this.$set(this.appointmentType, "chartOfAccount", response.data);
          })
          .catch((error) => {
            this.$swal("Serviços", `Ops, houve algum erro: ${error}`, "error");
          });
      });
    },
  },
  mounted() {
    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
};
</script>
