<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      :enable-download="false"
      :filename="'documents_instructions'"
      :float-layout="true"
      :html-to-pdf-options="{
        enableLinks: true,
      }"
      :htmlToPdfOptions="{
        html2canvas: {
          scale: 2,
        },
      }"
      :manual-pagination="true"
      :pdf-quality="2"
      :preview-modal="true"
      :show-layout="false"
      pdf-content-width="800px"
      pdf-format="a4"
      @beforeDownload="beforeDownload"
    >
      <section id="pdf-content" slot="pdf-content">
        <section class="pdf-item" :class="'pdf-content-prime'">
          <div id="pdf-content-body">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
              v-if="customerDetails.teamOrder === null"
            >
              <div>
                <img
                  :width="350"
                  alt="Importamos com você"
                  src="@/assets/img/documents-instructions/importamos.png"
                />
              </div>

              <div style="padding-right: 40px">
                <img
                  :width="260"
                  alt="Prime Internacional"
                  src="@/assets/img/documents-instructions/logo-prime-vazada-documento.png"
                />
                <img
                  :width="70"
                  alt="Certificação"
                  src="@/assets/img/documents-instructions/bv-certification.png"
                />
              </div>
            </div>
            <div v-else style="margin-top: 60px"></div>

            <div
              style="padding-left: 40px; padding-right: 20px; margin-top: 20px"
            >
              <div style="margin-top: 30px">
                <p>
                  <span
                    class="uppercase"
                    style="color: #325298; font-weight: 450; font-size: 25px"
                    >{{ languageTraductions.title }}</span
                  ><span
                    style="font-size: 22px; color: #d0cece; font-weight: 100"
                  >
                    | {{ languageTraductions.subtitle }}</span
                  >
                </p>
                <span style="font-size: 19px; color: #7f7f7f; font-weight: 430"
                  >Commercial Invoice / Packing List</span
                >
                <hr
                  style="
                    margin: 0 !important;
                    border-width: 1px;
                    background-color: #d9b058;
                    width: 90% !important;
                  "
                />
              </div>
              <div style="display: flex; margin-top: 30px">
                <div>
                  <div>
                    <span
                      style="font-size: 18px; color: #325298; font-weight: 450"
                      >{{ languageTraductions.importer }}</span
                    ><br />
                    <span
                      v-if="customerDetails.teamOrder === null"
                      style="font-size: 15px; color: #7f7f7f; font-weight: 430"
                      >PRIME INTERNACIONAL COMÉRCIO EXTERIOR LTDA</span
                    >
                    <span
                      v-else
                      style="font-size: 15px; color: #7f7f7f; font-weight: 430"
                      >SOWPORT IMPORTACAO E DISTRIBUICAO LTDA</span
                    >
                    <br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >Rua Dr. Pedro Ferreira, 333 - Sala 1805</span
                    ><br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >Itajaí - CEP 88301-030</span
                    ><br />
                    <span
                      v-if="customerDetails.teamOrder === null"
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >CNPJ 08.214.494/0001-19</span
                    >
                    <span
                      v-else
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >CNPJ 49.744.122/0001-81</span
                    >
                  </div>
                  <div style="margin-top: 40px">
                    <span
                      style="font-size: 18px; color: #325298; font-weight: 450"
                      >{{ languageTraductions.finalBuyer }}</span
                    ><br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 430"
                      >{{ customerDetails.completeName }}</span
                    ><br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >{{ customerDetails.address }},
                      {{ customerDetails.addressNumber }}</span
                    ><br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >{{ customerDetails.city }} -
                      {{ customerDetails.postalCode }}</span
                    ><br />
                    <span
                      style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                      >CNPJ {{ customerDetails.federalID }}</span
                    >
                  </div>
                </div>
                <div style="margin-left: 30px">
                  <span
                    class="uppercase"
                    style="font-size: 16px; color: #325298; font-weight: 450"
                    v-html="languageTraductions.invoiceInformations"
                  ></span
                  ><br />
                  <ol
                    :style="{ fontSize: language === 'en' ? '11.9px' : '14px' }"
                    style="color: #7f7f7f; font-weight: 220"
                  >
                    <li
                      v-for="(
                        item, index
                      ) in languageTraductions.invoiceInformationsItems"
                      :key="index"
                      v-html="item"
                    ></li>
                  </ol>
                </div>
              </div>
              <div class="text-center" style="margin-top: 10px">
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 45px;
                    color: red;
                    text-align: center;
                  "
                  >"{{ languageTraductions.documentsSignedByHand }}"</span
                >
              </div>
            </div>
            <div
              style="padding-left: 47px; padding-right: 20px; margin-top: 30px"
            >
              <div style="margin-top: 20px">
                <span style="font-size: 19px; color: #7f7f7f; font-weight: 430"
                  >Bill of Lading (BL) / Air Way Bill (AWB)</span
                >
                <hr
                  style="
                    margin: 0 !important;
                    border-width: 1px;
                    background-color: #d9b058;
                    width: 90% !important;
                  "
                />
              </div>
              <div style="margin-top: 30px">
                <span style="font-size: 16px; color: #325298; font-weight: 450"
                  >CONSIGNEE</span
                ><br />
                <span
                  v-if="customerDetails.teamOrder === null"
                  style="font-size: 14px; color: #7f7f7f; font-weight: 430"
                  >PRIME INTERNACIONAL COMERCIO EXTERIOR LTDA</span
                >
                <span
                  v-else
                  style="font-size: 14px; color: #7f7f7f; font-weight: 430"
                  >SOWPORT IMPORTACAO E DISTRIBUICAO LTDA</span
                >
                <br />
                <span style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >Rua Dr. Pedro Ferreira, 333 – Sala 1805,</span
                ><br />
                <span style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >Itajaí – CEP 88301-030</span
                ><br />
                <span
                  v-if="customerDetails.teamOrder === null"
                  style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >CNPJ: 08.214.494/0001-19</span
                >
                <span
                  v-else
                  style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >CNPJ: 49.744.122/0001-81</span
                >
              </div>
            </div>
            <div
              style="
                padding-left: 47px;
                padding-right: 20px;
                margin-top: 20px;
                margin-bottom: 26px;
              "
            >
              <div>
                <span style="font-size: 16px; color: #325298; font-weight: 450"
                  >NOTIFY</span
                ><br />
                <span
                  style="font-size: 14px; color: #7f7f7f; font-weight: 430"
                  >{{ customerDetails.completeName }}</span
                ><br />
                <span style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >{{ customerDetails.address }},
                  {{ customerDetails.addressNumber }}</span
                ><br />
                <span style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >{{ customerDetails.city }} -
                  {{ customerDetails.postalCode }}</span
                ><br />
                <span style="font-size: 14px; color: #7f7f7f; font-weight: 220"
                  >CNPJ {{ customerDetails.federalID }}</span
                >
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 40px;
              "
            >
              <a
                href="http://prime.com.vc"
                v-if="customerDetails.teamOrder === null"
                style="
                  display: inline-block;
                  padding: 5px 45px;
                  border: 2px solid #ccb776;
                  border-radius: 100px;
                  color: #183d67;
                "
              >
                prime.com.vc
              </a>
              <a
                href="http://prime.com.vc"
                v-else
                style="display: inline-block; padding: 5px 45px"
              >
              </a>

              <div>
                <img
                  v-if="customerDetails.teamOrder === null"
                  :width="205"
                  alt="Prime Internacional"
                  src="@/assets/img/logo_prime.png"
                />
                <img
                  v-else
                  :width="205"
                  alt="Prime Internacional"
                  src="@/assets/img/sow-logo-azul-naval.png"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          class="pdf-item"
          :class="
            customerDetails.teamOrder === null
              ? 'pdf-content-prime'
              : 'pdf-content-sow'
          "
        >
          <div id="pdf-content-body">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
              v-show="customerDetails.teamOrder === null"
            >
              <div>
                <img
                  :width="350"
                  alt="Importamos com você"
                  src="@/assets/img/documents-instructions/importamos.png"
                />
              </div>

              <div style="padding-right: 40px">
                <img
                  :width="260"
                  alt="Prime Internacional"
                  src="@/assets/img/documents-instructions/logo-prime-vazada-documento.png"
                />
                <img
                  :width="70"
                  alt="Certificação"
                  src="@/assets/img/documents-instructions/bv-certification.png"
                />
              </div>
            </div>

            <div
              style="padding-left: 40px; padding-right: 20px; margin-top: 20px"
            >
              <div style="margin-top: 30px">
                <p>
                  <span
                    style="color: #325298; font-weight: 450; font-size: 25px"
                    v-html="languageTraductions.woodTreatment"
                  ></span>
                </p>
                <span
                  style="font-size: 19px; color: #7f7f7f; font-weight: 430"
                  >{{ languageTraductions.art25 }}</span
                >
                <hr
                  style="
                    margin: 0 !important;
                    border-width: 1px;
                    background-color: #d9b058;
                    width: 90% !important;
                  "
                />
              </div>
              <div style="margin-top: 20px">
                <div>
                  <span
                    style="font-size: 16px; color: #7f7f7f; font-weight: 220"
                    >{{ languageTraductions.art25Item1 }}</span
                  ><br />
                  <p
                    style="
                      font-size: 13px;
                      color: red;
                      text-decoration: underline !important;
                      margin-top: 10px;
                    "
                  >
                    {{ languageTraductions.ipccMark }}
                  </p>
                </div>
                <div style="margin-top: 30px">
                  <p
                    style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                    v-html="languageTraductions.countriesHaveInternalized"
                  ></p>
                  <p
                    style="font-size: 15px; color: #7f7f7f; font-weight: 220"
                    v-html="languageTraductions.countriesHaveNotInternalized"
                  ></p>
                  <div style="margin-top: 30px">
                    <span
                      style="font-size: 16px; color: #7f7f7f; font-weight: 430"
                      >{{ languageTraductions.markIpccExample }}</span
                    ><br />
                    <a
                      :href="languageTraductions.markIpccExampleLink"
                      style="text-decoration: underline"
                      >{{ languageTraductions.markIpccExampleLink }}</a
                    >
                  </div>
                  <div style="margin-top: 30px">
                    <p
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                      v-html="languageTraductions.exceptionPlywood"
                    ></p>
                  </div>
                  <div style="margin-top: 40px">
                    <p
                      style="font-size: 17px; color: #325298; font-weight: 500"
                    >
                      {{ languageTraductions.blInformationsTitle }}
                    </p>
                  </div>
                  <div
                    :style="{
                      marginTop: language === 'pt-BR' ? '32px' : '33px',
                    }"
                  >
                    <p
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                    >
                      {{ languageTraductions.blInformationSubtitle }}
                    </p>
                  </div>
                  <div
                    style="
                      margin-top: 17px;
                      background-color: #ffffff;
                      width: 93%;
                    "
                  >
                    <span
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                      >Wooden Packing: Treated and Certified;</span
                    >
                    <br />
                    <span
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                      >Wooden Packing: Processed Wood;</span
                    >
                    <br />
                    <span
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                      >Wooden Packing: Not Treated and not Certified;</span
                    >
                    <br />
                    <span
                      style="font-size: 16px; color: #7f7f7f; font-weight: 200"
                      >Wooden Packing: Not Applicable.</span
                    >
                  </div>
                  <div
                    style="margin-top: 55px; margin-bottom: 60px; width: 93%"
                  >
                    <p
                      style="font-size: 18px; color: red; font-weight: 200"
                      v-html="languageTraductions.cargoReturnedToOrigin"
                    ></p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 40px;
              "
            >
              <a
                href="http://prime.com.vc"
                v-if="customerDetails.teamOrder === null"
                style="
                  display: inline-block;
                  padding: 5px 45px;
                  border: 2px solid #ccb776;
                  border-radius: 100px;
                  color: #183d67;
                "
              >
                prime.com.vc
              </a>
              <a
                href="http://prime.com.vc"
                v-else
                style="display: inline-block; padding: 5px 45px"
              >
              </a>

              <div>
                <img
                  v-if="customerDetails.teamOrder === null"
                  :width="205"
                  alt="Prime Internacional"
                  src="@/assets/img/logo_prime.png"
                />
                <img
                  v-else
                  :width="205"
                  alt="Prime Internacional"
                  src="@/assets/img/sow-logo-azul-naval.png"
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  methods: {
    pdfEvent() {
      this.$refs.html2Pdf.generatePdf();
    },
    beforeDownload() {
      this.$emit("beforeDownload");
    },
  },
  props: {
    language: {
      type: String,
      default: "pt-BR",
    },
    handler: {
      type: Boolean,
      default: false,
      required: false,
    },
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    languageTraductions() {
      return {
        title:
          this.language === "pt-BR" ? "Documentação" : "Documents Instructions",
        subtitle:
          this.language === "pt-BR"
            ? "Importação Marítima ou Aérea"
            : "Import by Sea or Air",
        importer: "IMPORTER",
        finalBuyer: "FINAL BUYER",
        invoiceInformations:
          this.language === "pt-BR"
            ? "Informações obrigatórios na Invoice"
            : "MANDATORY INFORMATION ON INVOICE",
        invoiceInformationsItems:
          this.language === "pt-BR"
            ? [
                "Incoterm",
                "Condições de pagamento e moeda",
                "Preço unitário e total",
                "Valor do frete e seguro destacado<br><i>(para embarques CIF ou CFR)</i>",
                "Peso Bruto total<br><i>(Deve ser igual ao BL ou AWB)</i>",
                "Peso Líquido total",
                "Dados completos do exportador",
                "Dados completos do fabricante<br><i>(se diferente do exportador)</i>",
                "País de origem (fabricação)",
                "País de procedência (embarque)",
                "País de aquisição (exportador)",
                "Número de série e ano de fab.<br><i>(para maquinários)</i>",
              ]
            : [
                "Incoterm",
                "Descriotion of goods",
                "HS code",
                "Payment terms and currency",
                "Unit and Total prices",
                "Freight and Insurance value<br/><i>(CIF or CFR shipments only)</i>",
                "Total Gross weight<br><i>(Should be the same in BL or AWB)</i>",
                "Unit net weight (if possible)",
                "Total net weight",
                'Exporter complete information<br><i style="color: red;">(including TIN or other register number)</i>',
                "Manufacturer information <i>(If is not the exporter)</i>",
                "Country of origin (manufacturing)",
                "Country of shipment",
                "Country of acquisition (exporter)",
                "Serial number and year of manufacture<br><i>(Just for machinery)</i>",
              ],
        documentsSignedByHand:
          this.language === "pt-BR"
            ? "Os documentos dever ser carimbados e assinados a punho"
            : "Documents should be stamped and signed by hand",
        woodTreatment:
          this.language === "pt-BR"
            ? "Mapa - Tratamento da madeira"
            : 'WOOD TREATMENT <span style="font-size: 22px; color: #d0cece; font-weight: 100">| Rules in Brazil</span>',
        art25:
          this.language === "pt-BR"
            ? "Art. 25 da Instrução Normativa 32/2015"
            : "Acoording to Art. 25 of Normative Instruction 32/2015",
        art25Item1:
          this.language === "pt-BR"
            ? "Toda embalagem de madeira, suportes e afins devem ser tratados e conter a marcação IPCC"
            : "According to Agriculture Ministry, all packaging and raw wood supports should be treated and must show IPCC mark as bellow.",
        ipccMark:
          this.language === "pt-BR"
            ? "** MARCA IPCC É OBRIGATÓRIA EM TODA EMBALAGEM DE MADEIRA, EXCETO MADEIRA COMPENSADA **"
            : "** IPCC MARK IS MANDATORY IN ALL WOOD PACKAGE, EXCEPT PLYWOOD **",
        countriesHaveInternalized:
          this.language === "pt-BR"
            ? 'Países que <span style="font-weight: 500; text-decoration: underline">internalizaram</span> a ISPM 15 <br /><span style="margin-left: 45px">Marcação IPCC obrigatória em toda madeira e pallets</span>'
            : 'Countries that <span style="font-weight: 500; text-decoration: underline">HAVE</span> internalized ISPM 15 <br /><span style="margin-left: 45px">IPCC mark on all wood package and pallets</span>',
        countriesHaveNotInternalized:
          this.language === "pt-BR"
            ? 'Países que <span style="font-weight: 500; text-decoration: underline">não internalizaram</span>a ISPM 15 <br /><span style="margin-left: 45px">Marcação IPCC obrigatória em toda madeira e pallets</span><br /><span style="margin-left: 45px">Certificado fitossanitário emitido pelo Ministério da Agricultura do país de origem</span>'
            : 'Countries that <span style="font-weight: 500; text-decoration: underline">HAVE NOT</span> internalized ISPM 15 <br /><span style="margin-left: 45px">IPCC mark on all wood package and pallets</span><br /><span style="margin-left: 45px">Phytosanitary certificate issued by Agriculture Departament of it\'s country</span>',
        markIpccExample:
          this.language === "pt-BR"
            ? "Exemplo de marcação IPCC:"
            : "IPCC mark Example:",
        markIpccExampleLink:
          this.language === "pt-BR"
            ? "http://idg.receita.fazenda.gov.br/orientacao/aduaneira/manuais/despacho-de-importacao/legislacao/outras-normas/anexo.png"
            : "https://www.gov.br/receitafederal/pt-br/assuntos/aduana-e-comercio-exterior/manuais/despacho-de-importacao/legislacao/outras-normas/anexo.png",
        exceptionPlywood:
          this.language === "pt-BR"
            ? '<span style="color: red; font-weight: 700">Exceção: </span>Madeira compensada não exige marcação IPCC ou certificado fitossanitário.'
            : '<span style="color: red; font-weight: 700">Exception: </span>Plywood does not requires any IPCC mark or phytosanitary certificate',
        blInformationsTitle:
          this.language === "pt-BR"
            ? "INFORMAÇÃO NO CONHECIMENTO DE EMBARQUE (BL)"
            : "INFORMATION ON BILL OF LADING (BL)",
        blInformationSubtitle:
          this.language === "pt-BR"
            ? "Deve-se informar em relação as embalagens:"
            : "One of the following options should be informed on Bill of Lading (BL)",
        cargoReturnedToOrigin:
          this.language === "pt-BR"
            ? '*O não atendimento das normas resulta <span style="font-weight: 500; text-decoration: underline">em devolução da carga ao exterior</span> por exigência do Ministério da Agricultura (MAPA).'
            : "* Non-compliance results the cargo to be returned to origin *",
      };
    },
  },
  watch: {
    handler(value) {
      if (value) {
        this.pdfEvent();
      }
    },
  },
};
</script>

<style scoped>
#pdf-content {
  height: 100%;
  background-color: #ffffff;
}

.pdf-content-prime {
  height: 1122px;
}

.pdf-content-sow {
  height: 1000px;
}
</style>
