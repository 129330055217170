var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-procedures"}},[_c('div',{attrs:{"id":"form-procedures"}},[_c('div',{staticClass:"modal-mask ht-100v"},[_c('div',{staticClass:"modal-wrapper ht-100v"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"tx-xs-20 tx-inverse mg-b-5"},[_vm._v("REQUISITOS")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Fechar"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" X ")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card processes-container"},[_c('div',{staticClass:"card-header"},[_c('ul',{staticClass:"nav nav-tabs card-header-tabs"},_vm._l((_vm.procedures),function(item,index){return _c('li',{key:'procedure-tab-id-' + item.id,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                        active:
                          _vm.tab === null || _vm.tab === ''
                            ? index === Object.keys(_vm.procedures)[0]
                            : _vm.tab === 'procedure-id-' + item.id,
                      },attrs:{"href":'#procedure-id-' + item.id,"data-toggle":"tab"},on:{"click":function($event){_vm.tab = 'procedure-id-' + item.id}}},[_vm._v(" "+_vm._s(item.description)+" "),_c('span',{staticClass:"badge",class:{ success: item.count === 0 }},[_vm._v(_vm._s(item.count))])])])}),0)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"tab-content ht-100p"},_vm._l((_vm.procedures),function(item,index){return _c('div',{key:'procedure-id-' + item.id,staticClass:"tab-pane",class:{
                      active:
                        _vm.tab === null || _vm.tab === ''
                          ? index === Object.keys(_vm.procedures)[0]
                          : _vm.tab === 'procedure-id-' + item.id,
                    },attrs:{"id":'procedure-id-' + item.id}},[_c('div',{staticClass:"row no-gutters scroll"},[_c('table',{staticClass:"table table-striped mg-b-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"tx-center"},[_vm._v("Descrição")]),_c('th',{staticClass:"tx-center"},[_vm._v("Data Início")]),_c('th',{staticClass:"tx-center wd-170"},[_vm._v("Quem Concluiu?")]),_c('th',{staticClass:"tx-center wd-170"},[_vm._v(" Data de Conclusão ")]),_c('th',{staticClass:"tx-center wd-50"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((item.itens),function(itemItens){return _c('tr',{key:itemItens.id,class:{ completed: itemItens.checked }},[_c('td',[_c('label',{staticClass:"cb",class:{ 'no-click': _vm.noClick }},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":itemItens.checked},on:{"input":function($event){return _vm.handleChecked(
                                      itemItens.id,
                                      itemItens.checked
                                    )}}}),_vm._v(" "+_vm._s(itemItens.item.description)+" ")])]),_c('td',{staticClass:"tx-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(itemItens.item.startDate,"DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"tx-center"},[(
                                  itemItens.checkedDate && itemItens.checked
                                )?[_vm._v(" "+_vm._s(itemItens.checkedBy)+" ")]:_vm._e()],2),_c('td',{staticClass:"tx-center"},[(
                                  itemItens.checkedDate && itemItens.checked
                                )?[_vm._v(" "+_vm._s(_vm._f("moment")(itemItens.checkedDate,"DD/MM/YYYY"))+" às "+_vm._s(_vm._f("moment")(itemItens.checkedDate,"H:mm"))+" ")]:_vm._e()],2),_c('td',{staticClass:"actions"},[_c('button',{staticClass:"btn btn-info btn-icon rounded-circle mg-l-10",attrs:{"type":"button"},on:{"click":function($event){return _vm.showItem({
                                    ...itemItens.item,
                                    logs: itemItens.logs,
                                  })}}},[_c('i',{staticClass:"icon ion-eye"})])])])}),0)])])])}),0)])])]),_c('collapse-procedures',{attrs:{"open":_vm.collapse,"modelUpdate":_vm.item,"procedure":_vm.model},on:{"close":_vm.handleCollapse}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }