<template>
  <div>
    <div class="config-title">
      <h2 class="text-dark text-thin">
        Listagem de Times Operacionais
      </h2>

      <div>
        <button @click.prevent="storeModal = true" class="crm-button">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor"/>
            Adicionar
          </div>
        </button>
      </div>
    </div>

    <hr class="mb-4"/>

    <config-table :table-config="tableConfig" :tableData="teams" :columns="tableColumns">
      <!-- Column Enabled -->
      <template #enabled="{ item }">
        <span @click.prevent="toggleStatus(item, !item.enabled, 'enabled')" class="config-table-tag cursor-pointer"
              :class="{ success: item.enabled, danger: !item.enabled }">
          {{ item.enabled ? 'Sim' : 'Não' }}
        </span>
      </template>
      <!-- Column Enabled -->

      <!-- Column Actions -->
      <template #actions="{ item }">
        <div class="actions-table">
          <a class="edit-button" href="#" @click.prevent="openEditTeam(item)">
            <config-edit-icon :size="16"/>
          </a>
          <a class="remove-button" href="#" @click.prevent="destroyTeam(item.id)">
            <config-trash-icon :size="16"/>
          </a>
        </div>
      </template>
    </config-table>

    <v-modal
      :title="`${modalData.id ? 'Atualizar time operacional' : 'Adicionar Time Operacional'}`"
      description=""
      :width="1600"
      :handler="storeModal"
      @requestClose="closeStoreModal"
    >
      <template #content>
        <form @submit.prevent class="config-user-form">
          <div class="span-12">
            <h4 class="text-dark">Dados Gerais do Time</h4>
          </div>

          <div class="span-12">
            <label>Habilitado?</label>
            <v-switch
              name="enabled"
              :labels="{
                  checked: `Sim`,
                  unchecked: `Não`,
                }"
              v-model="modalData.enabled"
            />
          </div>

          <div class="span-6">
            <v-input
              type="text"
              name="name"
              label="Descrição"
              v-model="modalData.name"
            ></v-input>
            <div class="field-error" v-if="getError('name')">
              {{ getError('name') }}
            </div>
          </div>

          <div class="span-6">
            <v-input
              type="email"
              name="email"
              label="E-mail"
              v-model="modalData.email"
            ></v-input>
            <div class="field-error" v-if="getError('email')">
              {{ getError('email') }}
            </div>
          </div>
        </form>

        <div class="card mt-6" v-if="modalData.id">
          <div class="card-content p-4">
            <div class="columns md-gap">
              <div class="span-8">
                <h4 class="tx-inverse mg-b-2 tx-semibold tx-uppercase tx-18">
                  Usuários do Time Operacional
                </h4>
                <p class="mg-b-3">
                  Facilitador para Vincular ou Remover Usuários neste Time Operacional
                </p>
              </div>

              <div class="span-8">
                <div class="row">
                  <div class="col-6">
                    <div class="documents-container">
                      <div class="documents-header">USUÁRIOS DISPONÍVEIS</div>
                      <div class="documents-search">
                        <i class="fa fa-search"></i>
                        <input type="text" name="buscaPrivilegios" id="buscaPrivilegios" v-model="search" />
                      </div>
                      <ul class="documents-list">
                        <template v-for="user in filteredAvailableUsers">
                          <li
                            :key="user.id"
                            @click.prevent="user.teamId = modalData.id"
                          >
                            <span class="tx-uppercase">
                              <span class="tx-semibold">{{ user.name }}</span>
                            </span>
                            <a href="#" @click.prevent class="documents-button small">
                              <i class="fa fa-angle-double-right"></i>
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="documents-container">
                      <div class="documents-header">USUÁRIOS VINCULADOS</div>
                      <div class="documents-search">
                        <p>{{ selectedUsers.length }} usuários(s) vinculados(s)</p>
                      </div>
                      <ul class="documents-list">
                        <template v-for="user in selectedUsers">
                          <li
                            :key="user.id"
                            v-if="user.selected !== null && user.selected !== false"
                            @click.prevent="user.teamId = null"
                          >
                            <span class="tx-uppercase">
                              <span class="tx-normal">{{ user.name }}</span>
                            </span>
                            <a href="#" @click.prevent class="documents-button small">
                              <i class="fa fa-angle-double-left"></i>
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="closeStoreModal">Cancelar</button>
          <button class="crm-button" v-if="modalData.id" @click.prevent="updateTeam">Salvar</button>
          <button class="crm-button" v-else @click.prevent="storeTeam">Adicionar</button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import PlusIcon from '@/components/crm/Icons/Plus.vue';
import ConfigTable from '@/components/Configuration/ConfigTable.vue';
import Axios from 'axios';
import config from '@/config';
import ConfigEditIcon from '@/components/Configuration/icons/ConfigEditIcon.vue';
import ConfigTrashIcon from '@/components/Configuration/icons/ConfigTrashIson.vue';
import VModal from '@/components/VModal.vue';
import VSwitch from '@/components/VSwitch.vue';
import VInput from '@/components/VInput.vue';
import Swal from 'sweetalert2';
import * as yup from 'yup';

const teamSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório').min(3, 'Mínimo de 3 caracteres').max(120, 'Máximo de 120 caractéres')
    .typeError('Campo inválido'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório').typeError('Campo inválido'),
});

export default {
  name: 'ConfigTeamTab',
  components: {
    VInput, VSwitch, VModal, ConfigTrashIcon, ConfigEditIcon, ConfigTable, PlusIcon,
  },
  data() {
    return {
      teams: [],
      users: [],
      modalData: {
        enabled: false,
        name: '',
        email: '',
      },
      search: '',
      formErrors: [],
      storeModal: false,
      tableConfig: {
        perPage: 10,
        useSearch: true,
        centeredCell: false,
        showRecords: true,
        showPerPage: true,
      },
      tableColumns: [
        {
          label: 'Habilitado',
          field: 'enabled',
        },
        {
          label: 'Nome',
          field: 'name',
        },
        {
          label: 'E-mail',
          field: 'email',
        },
        {
          label: 'Ações',
          field: 'actions',
        },
      ],
    };
  },
  computed: {
    selectedUsers() {
      if (this.modalData.id) {
        return this.users.filter((user) => user.teamId === this.modalData.id)
      }
      return [];
    },
    availableUsers() {
      if (this.modalData.id) {
        return this.users.filter((user) => user.teamId !== this.modalData.id)
      }
      return this.users;
    },
    filteredAvailableUsers() {
      if (search.length > 0) {
        const regex = new RegExp(this.search, 'gi');
        return this.availableUsers.filter((user) => {
          if (regex.test(user.name)) {
            return true;
          }
          return false;
        });
      }

      return this.availableUsers();
    },
  },
  methods: {
    getError(path) {
      const err = this.formErrors.find((e) => e.path === path);

      if (err) {
        return err.message;
      }

      return '';
    },
    async getTeams() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Recuperando listagem de times operacionais.',
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/teams`);

        this.teams = response.data._embedded.teams;
      } catch (e) {
        Swal.fire({
          title: 'Times Operacionais',
          text: 'Erro ao listar times operacionais!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async storeTeam() {
      this.formErrors = [];

      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Adicionando time operacional.',
      });

      try {
        await teamSchema.validate(this.modalData, {
          abortEarly: false,
        });
        await Axios.post(`${config.env.API_DEFAULT.host}/teams`, this.modalData);

        Swal.fire({
          title: 'Times Operacionais',
          text: 'Time operacional adicionado com sucesso!',
          type: 'success',
        });

        await this.getTeams();

        this.closeStoreModal();
      } catch (e) {
        if (e.name == 'ValidationError') {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Times Operacionais',
            text: 'Erro ao adicionar time operacional!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async updateTeam() {
      this.formErrors = [];

      this.$store.commit('setLoading', {
        show: true,
        label: 'Carregando...',
        description: 'Atualizando time operacional.',
      });

      try {
        await Axios.patch(`${config.env.API_DEFAULT.host}/teams/${this.modalData.id}`, this.modalData);
        await Axios.put(`${config.env.API_DEFAULT.host}/users/vinculate-operational-team/${this.modalData.id}`, {
          userIds: this.selectedUsers.map((user) => user.id)
        });

        Swal.fire({
          title: 'Times Operacionais',
          text: 'Time operacional atualizado com sucesso!',
          type: 'success',
        });

        await this.getTeams();

        this.closeStoreModal();
      } catch (e) {
        if (e.name == 'ValidationError') {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: 'Times Operacionais',
            text: 'Erro ao atualizar time operacional!',
            type: 'error',
          });
        }
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async destroyTeam(id) {
      try {
        const confirm = await this.$swal({
          title: 'Você tem certeza?',
          text: 'Se você excluir o time operacional, não será possível recuperá-lo para uso posterior.',
          icon: 'warning',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        });

        if (confirm) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Carregando...',
            description: 'Removendo time operacional.',
          });

          await Axios.delete(`${config.env.API_DEFAULT.host}/teams/${id}`);

          Swal.fire({
            title: 'Times Operacionais',
            text: 'Time operacional removido com sucesso!',
            type: 'success',
          });

          await this.getTeams();
        }
      } catch (e) {
        Swal.fire({
          title: 'Times Operacionais',
          text: 'Erro ao remover time operacional!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async toggleStatus(team, status, type) {
      const types = {
        enabled: 'Habilitado',
      };

      try {
        const confirm = await this.$swal({
          title: `Você tem certeza que deseja alterar o status ${types[type]}?`,
          icon: 'warning',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        });

        if (confirm) {
          await Axios.patch(`${config.env.API_DEFAULT.host}/teams/${team.id}`, {
            ...team,
            [type]: status,
          });

          await this.getTeams();
        }
      } catch (e) {
        Swal.fire({
          title: 'Times Operacionais!',
          text: 'Erro ao atualizar status do time!',
          type: 'error',
        });
      } finally {
        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    async getUsers() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/get-operational-users-with-team`,
        );
        console.log(response)
        this.users = response.data;
      } catch (err) {
        this.$swal(
          'Time Operacional',
          'Não foi possível carregar os usuários.',
          'error',
        );
      }
    },
    async openEditTeam(team) {
      this.formErrors = [];
      this.modalData = team;
      this.storeModal = true;
    },
    closeStoreModal() {
      this.formErrors = [];
      this.storeModal = false;
      this.modalData = {
        enabled: false,
        name: '',
        email: '',
      };
    },
  },
  async mounted() {
    await this.getTeams();
    await this.getUsers();
  },
};
</script>

<style scoped>
.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.config-user-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  align-content: start;
}

.config-user-form .span-4 {
  grid-column: span 4;
}

.config-user-form .span-6 {
  grid-column: span 6;
}

.config-user-form .span-8 {
  grid-column: span 8;
}

.config-user-form .span-12 {
  grid-column: span 12;
}

.documents-container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 300px;
  padding: 15px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.documents-header {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.documents-search {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}

.documents-search input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding-left: 45px;
}

.documents-search input:focus {
  background-color: white;
}

.documents-search i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.documents-list {
  padding: 0;
  list-style: none;
  margin: 0;
  overflow: auto;
  height: 100%;
}

.documents-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  word-break: break-all;
  gap: 10px;
}

.documents-list li a {
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}

.documents-list li:hover {
  background-color: #d2d2d2;
  color: black;
}

.documents-list li:hover a {
  opacity: 1;
}

.documents-list li:not(:last-of-type) {
  margin-bottom: 6px;
}

.documents-list li:not(:last-of-type)::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  right: 0;
}

.documents-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 45px;
  font-size: 24px;
  cursor: pointer;
}

.documents-button.small {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
}
</style>
